import { connect } from 'react-redux';
import { openConfirmDialog } from 'react-eis-components/dist/actions/uiActions';
import { setPage, updateLayout } from '../../../actions/layoutActions';
import Container from './Container';

function mapStateToProps(state) {
    return {
        translations: state.application.screenData[state.layout.lang].translations.TREC_CONTAINER,
        screenData: state.application.screenData,
        userData: state.application.userData,
        applicationData: state.application.applicationData,
        lang: state.layout.lang,
        constants: state.application.constants,
        masterPage: 'CONTAINER',
        page: 'CONTAINER',
        menu: state.application.screenData[state.layout.lang].menu[state.application.menuType],
        bufferZone: state.application.bufferZone,
    };
}

const mapDispatchToProps = {
    updateLayout,
    setPage,
    openConfirmDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
