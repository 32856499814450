export const EQUIPMENT_KEYS = {
    CODE: 'code',
    CLASS: 'classCode',
    DESCRIPTION: 'description',
    LOCATION: 'location',
    ZONE: 'zone',
    SUB_ZONE: 'subZone',
    CONTAIN_ALPHA_EMITTER: 'containAlphaEmitter',
    HAZARDOUS_CONTENT: 'hazardousContent',
    GROSS_WEIGHT: 'weight',
    LENGTH: 'length',
    WIDTH: 'width',
    HEIGHT: 'height',
    VALUE: 'value',
    CONTAINER_FULL: 'containerFull',
    HAZARDOUS_COMMENT: 'hazardousComment',
    ELIMINATION_PROCESS: 'eliminationProcess',
    CONTENT: 'content',
    COMPLETED_DATE: 'completedDate',
    PERFORMED_BY: 'performedBy',
    AD6_CONTACT: 'measureContactValue',
    AD6_CONTACT_QUALIFIER: 'measureContactQualifier',
    BGO_CONTACT: 'measureBGOValue',
    BGO_CONTACT_QUALIFIER: 'measureBGOQualifier',
    CONTENT_CONTAMINATED: 'contamination',
    BQ_ALPHA: 'measureAlpha',
    BQ_ALPHA_QUALIFIER: 'measureAlphaQualifier',
    BQ_BETA: 'measureBeta',
    BQ_BETA_QUALIFIER: 'measureBetaQualifier',
    FAMILY: 'family',
    SUB_FAMILY: 'rwSubFamily',
    MATERIAL: 'material',
    IRRADIATION_END_DATE: 'irradiationEndDate',
    FACILITY: 'facilities',
    DEVICE_AD6_SN: 'measureContactSerialNumber',
    DEVICE_BGO_SN: 'measureBGOSerialNumber',
    DEVICE_ADK_SN: 'contaminationSerialNumber',
    RPM_CODE: 'workorderCode',
    RW_ZONE: 'rwZone',
    RW_SUBZONE: 'rwSubzone',
    USER_TYPE: 'userTypeCode',
    SYSTEM_TYPE: 'systemTypeCode',
    CONTAINER_TARE_WEIGHT: 'containerTareWeight',
    ASSOCIATED_RISK: 'associatedRisk',
    CONTENT_DESCRIPTION: 'contentDescription',
    RESPONSIBLE: 'respCode',
    FACILITIES: 'facilities',
    IRRADIATION_END_DATE_MAX: 'irradiationEndDateMax',
    IRRADIATION_END_DATE_MIN: 'irradiationEndDateMin',
    RP_CLASSIFICATION: 'rpClassification',
    RW_RECEPTION_DATE: 'rwReceptionDate',
    RW_FAMILY: 'rwFamily',
    RW_SUB_FAMILY: 'rwSubFamily',
    RW_PROCESSING_DATE: 'rwProcessingDate',
    RW_ELIMINATION_DATE: 'rwEliminationDate',
    AREA_CLASSIFICATION: 'areaClassification',
    ORIGIN: 'origin',
    IS_RADIOACTIVE_WASTE: 'isRadioactiveWaste',
    USER_STATUS: 'userStatus',
    HAZARDS: 'hazards',
    HAZARD_CODES: 'hazardCodes',
    HAZARD_COMMENT: 'hazardComment',
    TREATMENT: 'treatment',
};

export const RWRESULT_KEYS = {
    CODE: 'equipmentCode',
    DESCRIPTION: 'equipmentDesc',
    LOCATION: 'locationCode',
    ZONE: 'zone',
    SUB_ZONE: 'subZone',
    CONTAIN_ALPHA_EMITTER: 'containsAlphaEmitter',
    HAZARDOUS_CONTENT: 'hazardousContent',
    GROSS_WEIGHT: 'weight',
    LENGTH: 'length',
    WIDTH: 'width',
    HEIGHT: 'height',
    VALUE: 'value',
    CONTAINER_FULL: 'containerFull',
    HAZARDOUS_COMMENT: 'hazardousComment',
    ELIMINATION_PROCESS: 'eliminationProcess',
    CONTENT: 'content',
    COMPLETED_DATE: 'completedDate',
    PERFORMED_BY: 'performedBy',
    AD6_CONTACT: 'measureContactValue',
    AD6_CONTACT_QUALIFIER: 'measureContactQualifier',
    BGO_CONTACT: 'measureBGOValue',
    BGO_CONTACT_QUALIFIER: 'measureBGOQualifier',
    CONTENT_CONTAMINATED: 'contamination',
    BQ_ALPHA: 'measureAlpha',
    BQ_ALPHA_QUALIFIER: 'measureAlphaQualifier',
    BQ_BETA: 'measureBeta',
    BQ_BETA_QUALIFIER: 'measureBetaQualifier',
    FAMILY: 'family',
    SUB_FAMILY: 'rwSubFamily',
    MATERIAL: 'material',
    IRRADIATION_END_DATE: 'irradiationEndDate',
    FACILITIES: 'facilities',
    DEVICE_AD6_SN: 'measureContactSerialNumber',
    DEVICE_BGO_SN: 'measureBGOSerialNumber',
    DEVICE_ADK_SN: 'contaminationSerialNumber',
    RPM_CODE: 'workorderCode',
    RW_ZONE: 'rwZone',
    RW_SUBZONE: 'rwSubzone',
    USER_TYPE: 'userTypeCode',
    SYSTEM_TYPE: 'systemTypeCode',
    CONTAINER_TARE_WEIGHT: 'containerTareWeight',
    ASSOCIATED_RISK: 'associatedRisk',
    CONTENT_DESCRIPTION: 'contentDescription',
    RESPONSIBLE: 'respCode',
    IRRADIATION_END_DATE_MAX: 'irradiationEndDateMax',
    IRRADIATION_END_DATE_MIN: 'irradiationEndDateMin',
    RP_CLASSIFICATION: 'rpClassification',
    RW_RECEPTION_DATE: 'rwReceptionDate',
    RW_FAMILY: 'wasteFamily',
    RW_SUB_FAMILY: 'wasteSubFamily',
    RW_PROCESSING_DATE: 'rwProcessingDate',
    RW_ELIMINATION_DATE: 'rwEliminationDate',
    AREA_CLASSIFICATION: 'areaClassification',
    ORIGIN: 'origin',
    IS_RADIOACTIVE_WASTE: 'isRadioactiveWaste',
    USER_STATUS: 'userStatus',
    HAZARDS: 'hazards',
    HAZARD_CODES: 'hazardCodes',
    HAZARD_COMMENT: 'hazardComment',
    TREATMENT: 'treatment',
};
