import { Button, Card, CardActions, Typography } from '@material-ui/core';
import { AMMMultiAutocomplete } from 'amm-tools';
import { useEffect, useState } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import { VACUUM_RENTAL_LINE_KEYS } from 'ui/pages/vaccleanerrequest/VacCleanerConstants';

const internalStyles = {
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
    actions: {
        flexDirection: 'column',
        position: 'absolute',
        bottom: 10,
        left: 0,
        right: 0,
    },
};

const AccessoryDetails = ({
    onClose,
    onAdd,
    focusedObject,
    menuPortalTarget,
    styles,
    vacuums,
    getVacCount,
    getTranslation,
    vacuumsData,
    defaultVacuum,
}) => {
    const [vacuumSelection, setVacuumSelection] = useState();

    const focusedAccCode = focusedObject.partCode;

    const vacuumOptions = vacuums
        // Filter vacuums already selected
        .filter((s) => s.vacCleanerRentalAccessories.every((acc) => acc.partCode !== focusedAccCode))
        // Filter vacuums that support the accessory
        .filter((s) =>
            vacuumsData
                .find((vd) => vd.partCode === s.vacTypeCode)
                ?.vacCleanerAccessoryList.some((acc) => acc.partCode === focusedAccCode)
        )
        .map((vacuum) => {
            const vacType = vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE];
            const location = vacuum[VACUUM_RENTAL_LINE_KEYS.LOCATION];
            return {
                label: `${vacType}#${getVacCount(vacuums, vacuum)} at ${location?.code}`,
                value: vacuum,
            };
        });

    useEffect(() => {
        vacuumSelection?.length && onClose();
    }, [defaultVacuum]);

    useEffect(() => {
        setVacuumSelection(
            defaultVacuum
                ? [
                      {
                          label: `${defaultVacuum.vacTypeCode}#${getVacCount(vacuums, defaultVacuum)} at ${
                              defaultVacuum.usageLocation?.code
                          }`,
                          value: defaultVacuum,
                      },
                  ]
                : []
        );
    }, [focusedAccCode]);

    return (
        <EISPanel
            detailsStyle={styles.detailsStyle}
            panelStyle={{ position: 'relative' }}
            heading={focusedObject.vacType}
            headingIcon={focusedObject.warning && 'fa-warning'}
            headingIconStyle={{ color: 'red' }}
            alwaysExpanded
        >
            <div style={styles.panelStyle}>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', height: '30vh' }}>
                    <img
                        src={focusedObject.imageUrl}
                        alt={focusedObject.vacType}
                        style={{ objectFit: 'contain', height: '100%', width: '100%' }}
                    />
                </div>
                <Card style={{ padding: '10px 15px', backgroundColor: '#f6f6f6' }}>
                    <div style={internalStyles.row}>
                        <Typography variant="subtitle1">{focusedObject.description}</Typography>
                    </div>
                    <div style={internalStyles.row}>
                        <Typography variant="subtitle2" color="textSecondary">
                            {focusedObject.partCode}
                        </Typography>
                    </div>
                    {focusedObject.warning && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                backgroundColor: '#f003',
                                border: '1px dashed #f00',
                                padding: 5,
                                margin: '5px 0',
                            }}
                        >
                            <Typography variant="subtitle2" style={{ color: '#b80000' }}>
                                {focusedObject.warning}
                            </Typography>
                        </div>
                    )}
                </Card>
                <CardActions style={internalStyles.actions}>
                    <div style={{ width: '100%', marginBottom: 5 }}>
                        <AMMMultiAutocomplete
                            defaultOptions={vacuumOptions}
                            options={vacuumOptions}
                            forceSync
                            getOptionLabel={(opt) => opt.label}
                            getOptionValue={(opt) => opt.value}
                            menuPortalTarget={menuPortalTarget?.current}
                            placeholder="Add to vacuum cleaner..."
                            onChange={(opt) => {
                                setVacuumSelection(opt);
                            }}
                            value={vacuumSelection}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Button onClick={onClose}>{getTranslation('CLOSE')}</Button>
                        <Button
                            disabled={!vacuumSelection}
                            onClick={() => {
                                onAdd(
                                    focusedObject,
                                    vacuumSelection.map((v) => v.value)
                                );
                                onClose();
                            }}
                        >
                            {getTranslation('ADD')}
                        </Button>
                    </div>
                </CardActions>
            </div>
        </EISPanel>
    );
};

export default AccessoryDetails;
