import WS from '../tools/rest/WS';
import Tools from '../tools/Tools';
import MenuTypes from '../enums/MenuTypes';
import WSRWPProcessing from '../tools/rest/WSRWPProcessing';

export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const SET_LOGIN_TOKEN = 'SET_LOGIN_TOKEN';
export const SET_USERNAME = 'SET_USERNAME';

export function updateApplication(value) {
    return {
        type: UPDATE_APPLICATION,
        value,
    };
}

const SCREENDATA_CACHE_NAME = 'screenData';
const APPDATA_CACHE_NAME = 'applicationData';
const MENU_CACHE_NAME = 'menuData';
const CACHE_VERSION = 'appVersion';
const CACHE_DATE = 'date';
const CACHE_NAME_PROMISE_MAP = {
    [SCREENDATA_CACHE_NAME]: WS.getScreenData,
    [APPDATA_CACHE_NAME]: WS.getApplicationData,
    [MENU_CACHE_NAME]: WS.getMenu,
};

const getCachedData = (cacheName, version, userCode, egroupList) => {
    const cacheId = `${cacheName}#${userCode}`;
    try {
        const localCache = localStorage.getItem(cacheId);
        if (localCache) {
            const cache = JSON.parse(localCache);
            const yesterday = new Date();
            const dateCached = new Date(cache[CACHE_DATE]);

            yesterday.setDate(yesterday.getDate() - 1);
            const recentCache =
                (dateCached - new Date()) / 1000 / 60 / 60 / 24 < 2 && dateCached.getDay() === new Date().getDay();
            const sameVersion = cache[CACHE_VERSION] === version;
            // Disable cache for PROD while icon access (egroups) are cached in screen elements
            if (!egroupList && recentCache && sameVersion && !window.location.href.includes('https://trec.cern.ch')) {
                return Promise.resolve(cache);
            }
        }
    } catch (err) {
        console.error('Error loading cached screenData.');
    }

    const promise = CACHE_NAME_PROMISE_MAP[cacheName].bind(WS);
    return promise(egroupList).then((data) => {
        const cache = data.body.data;
        cache[CACHE_DATE] = new Date();
        cache[CACHE_VERSION] = version;
        try {
            localStorage.setItem(cacheId, JSON.stringify(cache));
        } catch (err) {
            console.error('Error caching screenData.');
        }
        return Promise.resolve(cache);
    });
};

function removeCachedFilters(applicationData) {
    const grigRequest = 'gridRequest';
    localStorage.removeItem(grigRequest + applicationData.gridMyRequestsGridID);
    localStorage.removeItem(grigRequest + applicationData.gridRPMeasurementsGridID);
    localStorage.removeItem(grigRequest + applicationData.radioactiveWasteGridID);
    localStorage.removeItem(grigRequest + applicationData.gridRWChecksGridID);
    localStorage.removeItem(grigRequest + applicationData.rpmedhdocGridID);
}

export function getInitData(egroupList) {
    // Get buffer zone from the URL
    const bufferZoneFromQueryParam = Tools.getURLParameterByName('bufferzone') || '';
    const bufferZoneFromLocalStorage = localStorage.getItem('TREC_BUFFERZONE');
    let bufferZone = null;

    if (bufferZoneFromLocalStorage && !bufferZoneFromQueryParam) {
        bufferZone = bufferZoneFromLocalStorage;
        localStorage.setItem('TREC_BUFFERZONE', bufferZoneFromLocalStorage);
    }

    if (bufferZoneFromQueryParam) {
        localStorage.setItem('TREC_BUFFERZONE', bufferZoneFromQueryParam);
        bufferZone = bufferZoneFromQueryParam;
    }

    const menuType = bufferZone ? MenuTypes.BUFFER_ZONE : MenuTypes.STANDARD;
    return (dispatch) => {
        dispatch(
            updateApplication({
                loading: true,
            })
        );

        WS.getApplicationVersion();
        Promise.all([WS.getApplicationVersion(), WS.getUserData()])
            .then(([versionResp, userDataResp]) => {
                const version = versionResp.body.data;
                const userData = userDataResp.body.data;
                const { userCode } = userData.eamAccount;

                return Promise.all([
                    Promise.resolve(userData),
                    getCachedData(APPDATA_CACHE_NAME, version, userCode),
                    getCachedData(SCREENDATA_CACHE_NAME, version, userCode, egroupList),
                    WS.getBufferZoneData(bufferZone),
                    WS.getConstants(),
                    WSRWPProcessing.getDropdownValues('RWP'),
                    getCachedData(MENU_CACHE_NAME, version, userCode, egroupList),
                    WSRWPProcessing.getDropdownValues('RPAM'),
                ])
                    .then((values) => {
                        dispatch(
                            updateApplication({
                                userData: values[0],
                                applicationData: values[1],
                                screenData: values[2],
                                bufferZone: values[3].body.data,
                                constants: values[4].body.data,
                                rwpDropdowns: values[5].body.data,
                                loading: false,
                                menuType,
                                authorizedMenus: values[6].authorizedMenus,
                                rpaDropdowns: values[7].body.data,
                            })
                        );
                        // Remove cached filters
                        removeCachedFilters(values[1]);
                    })
                    .catch(() => {
                        // Attempt to reload the app in case it has failed
                        setTimeout(() => window.location.reload(true), 60000);
                        dispatch(updateApplication({ userData: { invalidAccount: true }, loading: false }));
                    });
            })
            .catch(() => {
                // Attempt to reload the app in case it has failed
                setTimeout(() => window.location.reload(true), 60000);
                dispatch(updateApplication({ userData: { invalidAccount: true }, loading: false }));
            });
    };
}

export function logout() {
    return (dispatch, getState) => {
        const state = getState().application;

        const isBufferZone = state.bufferZone && state.loginToken;

        if (isBufferZone) {
            dispatch({
                type: SET_LOGIN_TOKEN,
                value: null,
            });
        } else if (state.guestUsername) {
            dispatch({
                type: SET_USERNAME,
                value: null,
            });
            window.location.reload();
        } else {
            document.location = 'https://login.cern.ch/adfs/ls/?wa=wsignout1.0';
        }
    };
}

export function login(token) {
    return {
        type: SET_LOGIN_TOKEN,
        value: token,
    };
}

export function setUser(value) {
    return {
        type: SET_USERNAME,
        value,
    };
}
