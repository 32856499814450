import { Grid, Typography } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import { format } from 'date-fns';
import CommentsContainer from 'eam-components/dist/ui/components/comments/CommentsContainer';
import { DATE_TIME_FORMAT, TABLE_DATA_TYPES } from 'enums/Constants';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import WSComments from 'tools/rest/WSComments';
import {
    TRANSLATION_KEYS,
    VACUUM_RENTAL_ACCESSORY_KEYS,
    VACUUM_RENTAL_KEYS,
    VACUUM_RENTAL_LINE_KEYS,
} from 'ui/pages/vaccleanerrequest/VacCleanerConstants';
import InputGenerator from '../../rpmrequest2/InputGenerator';
import { getLocationFields, getUsageFields, getUserFields } from './VacCleanerUserStep';
import { ENTITY_TRANSL_PREFIX } from '../../wrrequest/WRRConstants';
import { showError } from 'tools/TrecNotifications';

const { ATTRIBUTES } = InputGenerator;

export const RequestDetails = ({
    applicationData,
    assetFields,
    formFields,
    labelStyle,
    vacCleanerGetters,
    vacuumRequest,
    getTranslation,
    dropdowns,
}) => {
    const vacCleanerRental = vacCleanerGetters.getProperties();

    const usageFields = getUsageFields({ vacCleanerRental, getTranslation, applicationData, dropdowns });
    const userFields = getUserFields(assetFields);
    const locationFields = getLocationFields(applicationData, assetFields, getTranslation);

    const allFields = [...usageFields, ...userFields, ...locationFields]; // ...durationFields removed

    return (
        <div style={{ width: '100%', marginTop: 0 }}>
            {allFields
                .filter((s) => vacuumRequest[s.code])
                .map((field) =>
                    InputGenerator.generate({
                        key: `${field.code}`,
                        field: {
                            ...field,
                            getAttribute: () => ATTRIBUTES.READONLY,
                            code: field.valueKey ?? field.code,
                        },
                        object:
                            field.type === TABLE_DATA_TYPES.AUTOCOMPLETE
                                ? vacCleanerRental[field.code] || {}
                                : vacuumRequest,
                        updateObject: () => {},
                        formFields,
                        labelStyle,
                        getTranslation,
                    })
                )}
        </div>
    );
};

class VacCleanerSummaryStep extends WizardStep {
    formFields = [];

    prevValues = null;

    state = {
        isNewEquipment: false,
        newEquipment: {},
    };

    handleSubmit = () => {
        this.canContinue();
    };

    handleSave = () => {
        this.canContinue();
    };

    /**
     * Can continue to the next step on the wizard
     */
    canContinue = () => {
        const isValid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
        if (!isValid) {
            showError('One or more fields are not valid.');
        }

        return isValid;
    };

    /**
     * SaveChanges is used to save the localized state to the redux-store. It can only run synchronous methods.
     */
    saveChanges = () => true;

    /**
     *  CommitChanges is used to trigger any side effects of the step, such as invocations to the back-end to
     * retrieve information about resources. This method can run asynchronous methods/requests but SHALL execute
     * the callback method afterwards to inform the wizard.
     */
    commitChanges = async (callback) => {
        callback();
    };

    /**
     * On true, the wizard automatically jumps this step immediately after it is mounted
     */
    canJumpStep = () => false;

    render() {
        const { userData, vacuumRequest, getTranslation } = this.props;
        return (
            <div style={{ margin: '8px 20px' }}>
                <Grid container spacing={8}>
                    <Grid item md={6} xs={12}>
                        <EISPanel
                            heading={getTranslation('SUMMARY')}
                            detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                            alwaysExpanded
                        >
                            <RequestDetails {...this.props} formFields={this.formFields} />
                        </EISPanel>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <EISPanel
                            heading={`${getTranslation(TRANSLATION_KEYS.VACUUMS)} (${
                                vacuumRequest[VACUUM_RENTAL_KEYS.VACUUMS].length
                            })`}
                            detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                        >
                            {vacuumRequest[VACUUM_RENTAL_KEYS.VACUUMS].map((vac) => (
                                <div
                                    key={vac[VACUUM_RENTAL_LINE_KEYS.CODE]}
                                    style={{
                                        border: '1px solid #ced4da',
                                        borderRadius: 5,
                                        padding: 4,
                                        marginBottom: 5,
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            borderBottom: '1px solid #ced4da',
                                        }}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <Typography color="primary">
                                                <b>
                                                    <span style={{ color: 'primary' }}>
                                                        {vac[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE] || (
                                                            <span style={{ color: 'coral' }}>
                                                                {getTranslation('NOTASSIGNED')}
                                                            </span>
                                                        )}{' '}
                                                    </span>
                                                </b>
                                            </Typography>
                                            <Typography variant="caption" style={{ marginLeft: 5 }}>
                                                ({vac[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE]})
                                            </Typography>
                                            {vac[VACUUM_RENTAL_LINE_KEYS.STATUS] && (
                                                <Typography variant="subtitle2" style={{ marginLeft: 5 }}>
                                                    -{' '}
                                                    {getTranslation(
                                                        ENTITY_TRANSL_PREFIX.RESERVATION_STATUS +
                                                            vac[VACUUM_RENTAL_LINE_KEYS.STATUS]
                                                    )}
                                                </Typography>
                                            )}
                                        </div>
                                        <Typography>
                                            <b>{getTranslation('USAGELOCATION')}</b>{' '}
                                            {vac[VACUUM_RENTAL_LINE_KEYS.LOCATION]?.code}
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        {vac[VACUUM_RENTAL_LINE_KEYS.START_DATE] && (
                                            <div style={{ display: 'flex' }}>
                                                <Typography color="textSecondary">
                                                    <b> {getTranslation('REQUESTED_START_DATE')}</b>
                                                    {format(
                                                        vac[VACUUM_RENTAL_LINE_KEYS.START_DATE],
                                                        DATE_TIME_FORMAT.DATE_FNS
                                                    )}
                                                </Typography>
                                            </div>
                                        )}
                                        {vac[VACUUM_RENTAL_LINE_KEYS.END_DATE] && (
                                            <div style={{ display: 'flex' }}>
                                                <Typography color="textSecondary">
                                                    <b>{getTranslation('REQUESTED_END_DATE')}</b>
                                                    {format(
                                                        vac[VACUUM_RENTAL_LINE_KEYS.END_DATE],
                                                        DATE_TIME_FORMAT.DATE_FNS
                                                    )}
                                                </Typography>
                                            </div>
                                        )}
                                    </div>
                                    {vac[VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES]?.length > 0 && (
                                        <div style={{ borderTop: '1px solid #ced4da' }}>
                                            {vac[VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES].map((acc) => (
                                                <div key={acc[VACUUM_RENTAL_ACCESSORY_KEYS.CODE]}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <Typography color="textSecondary" variant="caption">
                                                                {acc[VACUUM_RENTAL_ACCESSORY_KEYS.PART_CODE]}
                                                            </Typography>
                                                        </div>
                                                        <Typography color="textSecondary">
                                                            {getTranslation('QUANTITY')} {acc.requestedQuantity}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </EISPanel>
                        {vacuumRequest[VACUUM_RENTAL_KEYS.CODE] && (
                            <CommentsContainer
                                title={getTranslation('COMMENTS')}
                                ref={(comments) => {
                                    this.comments = comments;
                                }}
                                entityCode="EVNT"
                                entityKeyCode={vacuumRequest[VACUUM_RENTAL_KEYS.CODE]}
                                userDesc={userData.eamAccount.userDesc}
                                readComments={WSComments.readComments}
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default VacCleanerSummaryStep;
