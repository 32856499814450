import React from 'react';
import { AMMSingleAutocomplete, WizardStep } from 'amm-tools';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import { EQUIPMENT_KEYS, RPSM_KEYS, TRANSLATION_KEYS } from 'enums/Constants';
import { CloseCircle, PlusBox } from 'mdi-material-ui';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable, { GET_KEY_KEY } from 'react-eis-components/dist/ui/components/table';
import WSEquipment from 'tools/rest/WSEquipment';
import WSRPSampling from 'tools/rest/WSRPSampling';
import Tools from 'tools/Tools';
import EquipmentAutocomplete from 'ui/components/autocomplete/EquipmentAutocomplete';
import EquipmentOption from 'ui/components/autocomplete/EquipmentOption';
import { SingleValueContent } from 'ui/pages/wrrequest/steps/WRRActionsStep';
import isEmpty from 'lodash/isEmpty';
import { showError } from 'tools/TrecNotifications';

const styles = {
    sourceEqAutocomplete: {
        width: '100%',
    },
    numberOfEquipmentInput: {
        width: '100px',
    },
    originCodeInput: {
        width: '200px',
    },
    originDescInput: {
        width: '100px',
    },
    addSourceButton: {
        width: '10px',
    },
};

const AUTO_ADDED_KEY = 'autoAdded';

const INPUT_REFS_KEYS = {
    originRef: 'originRef',
    descriptionRef: 'descriptionRef',
    sampleListRef: 'sampleListRef',
};

class RPSMEquipmentSelection extends WizardStep {
    state = {};

    formFields = {};

    mainInputRef = null;

    mainMenuOpen = null;

    allTableInputRefs = {};

    sortedOrigins = null;

    firstTableInputRef = null;

    validate = () =>
        Object.values(this.formFields).reduce((acc, ff) => (!ff || !ff.validate || ff.validate()) && acc, true);

    canAddLine = () => Object.values(this.state.origins).length < this.props.applicationData.rpanalysisMaxEquipments;

    canContinue = () => {
        const { origins } = this.state;
        const { rpanalysisMaxEquipments } = this.props.applicationData;

        const fieldsValidated = this.validate();
        if (!fieldsValidated) {
            showError('Missing fields');
            return false;
        }

        if (
            Object.values(origins).some(
                (eq) => !eq[EQUIPMENT_KEYS.EQUIPMENT_DESC] && !eq[EQUIPMENT_KEYS.EQUIPMENT_CODE]
            )
        ) {
            showError('Every origin must have code or description');
            return false;
        }

        if (Object.keys(origins).length === 0) {
            showError('Please add at least one origin');
            return false;
        }

        if (!Object.values(origins).every((s) => s.sampleList && s.sampleList.length > 0)) {
            showError('Please select at least one sample per origin');
            return false;
        }

        const allSamplesUnique = Object.values(origins)
            .reduce((acc, s) => [...acc, ...(s.sampleList || []).map((obj) => obj.code)], [])
            .every((s, index, lista) => lista.findIndex((o) => o === s) === index);
        if (!allSamplesUnique) {
            showError('Sample(s) selected more than once');
            return false;
        }

        if (Object.values(origins).length > rpanalysisMaxEquipments) {
            showError(`Maximum number of lines is ${rpanalysisMaxEquipments}`);
            return false;
        }

        if (
            Object.values(origins).reduce((acc, orig) => acc + (orig.sampleList ? orig.sampleList.length : 0), 0) >
            rpanalysisMaxEquipments
        ) {
            showError(`Maximum number of samples is ${rpanalysisMaxEquipments}`);
            return false;
        }

        return true;
    };

    saveChanges = () => {
        const { rpsmActions } = this.props;
        const { origins } = this.state;
        rpsmActions.updateOrigins(origins);
        return true;
    };

    commitChanges = (callback) => {
        const { rpsmActions, rpsmGetters, userData } = this.props;
        const { origins } = this.state;
        const originCodes = Object.values(origins)
            .filter((e) => e[EQUIPMENT_KEYS.EQUIPMENT_CODE])
            .map((e) => e[EQUIPMENT_KEYS.EQUIPMENT_CODE]);
        const sampleCodes = Object.values(origins).reduce(
            (acc, origin) => [...acc, ...(origin.sampleList ? origin.sampleList.map((e) => e.code) : [])],
            []
        );

        const storeEquipmentMap = {
            ...Object.values(rpsmGetters.getOrigins())
                .filter((o) => originCodes.includes(o[EQUIPMENT_KEYS.EQUIPMENT_CODE]))
                .reduce(
                    (acc, o) => ({
                        ...acc,
                        [o[EQUIPMENT_KEYS.EQUIPMENT_CODE]]: o,
                    }),
                    {}
                ),
            ...Object.values(rpsmGetters.getSamples())
                .filter((o) => sampleCodes.includes(o[EQUIPMENT_KEYS.EQUIPMENT_CODE]))
                .reduce(
                    (acc, o) => ({
                        ...acc,
                        [o[EQUIPMENT_KEYS.EQUIPMENT_CODE]]: o,
                    }),
                    {}
                ),
        };

        const unfetchedCodes = [...originCodes, ...sampleCodes].filter(
            (code) => !Object.keys(storeEquipmentMap).includes(code)
        );

        function updateSamplesAndOrigins(respArray) {
            const fetchedEquipment = {
                ...respArray.reduce((acc, resp) => {
                    const equipment = resp.body.data;
                    return {
                        ...acc,
                        [equipment[EQUIPMENT_KEYS.EQUIPMENT_CODE]]: equipment,
                    };
                }, {}),
                ...storeEquipmentMap,
            };

            const fullDataOrigins = Object.keys(origins).reduce(
                (acc, key) =>
                    originCodes.includes(origins[key][EQUIPMENT_KEYS.EQUIPMENT_CODE])
                        ? // TODO: this data structure should be redesigned, we are currently mixing origins,
                          // with descritions, and keeping the sample list for each one of them inside the origin object
                          // Merge all information gathered from the API request but keeping the samplelist
                          {
                              ...acc,
                              [key]: {
                                  ...fetchedEquipment[origins[key][EQUIPMENT_KEYS.EQUIPMENT_CODE]],
                                  sampleList: origins[key].sampleList,
                              },
                          }
                        : // if it is only a description, we could not fetch anything about it so the content is kept the same
                          {
                              ...acc,
                              [key]: origins[key],
                          },
                {}
            );
            const fullDataSamples = sampleCodes.reduce((acc, code) => {
                const origin =
                    Object.values(fullDataOrigins).find((fullDataOrigin) =>
                        fullDataOrigin.sampleList.find((e) => e.code === code)
                    ) || {};
                const respCode =
                    (fetchedEquipment[code] && fetchedEquipment[code][RPSM_KEYS.SAMPLING_RESP]) ||
                    userData.eamAccount.employeeCode;
                return {
                    ...acc,
                    [code]: {
                        ...fetchedEquipment[code],
                        // Adding properties of the origin so that it is already inside the sample object
                        // and populated with data introduced in the form
                        [RPSM_KEYS.ORIGIN]: origin[EQUIPMENT_KEYS.EQUIPMENT_CODE],
                        [RPSM_KEYS.ORIGIN_DESCRIPTION]: origin[EQUIPMENT_KEYS.EQUIPMENT_DESC],
                        [RPSM_KEYS.SAMPLING_DATE]: fetchedEquipment[code][RPSM_KEYS.SAMPLING_DATE] || new Date(),
                        [RPSM_KEYS.SAMPLE_PURPOSE]:
                            fetchedEquipment[code][RPSM_KEYS.PURPOSE] || rpsmGetters.getProperties()[RPSM_KEYS.PURPOSE],
                        [RPSM_KEYS.SAMPLING_RESP]: respCode,
                        [RPSM_KEYS.SAMPLE_LOCATION]:
                            fetchedEquipment[code][RPSM_KEYS.SAMPLE_LOCATION] || origin[EQUIPMENT_KEYS.OBJ_LOCATION],
                        [EQUIPMENT_KEYS.RESP_TECHNIQUE_DESC]: respCode,
                    },
                };
            }, {});
            rpsmActions.updateOrigins(fullDataOrigins);
            rpsmActions.updateSamples(fullDataSamples);
            callback();
        }

        if (!unfetchedCodes.length) {
            updateSamplesAndOrigins([]);
            return;
        }

        Promise.all(unfetchedCodes.map((code) => WSRPSampling.getSample(code))).then((respArray) =>
            updateSamplesAndOrigins(respArray)
        );
    };

    static updateOriginsAndSamples = () => {};

    // --------------------------------------------------------------//

    static getDerivedStateFromProps(props, state) {
        return {
            origins: props.rpsmGetters.getOrigins(),
            ...state,
        };
    }

    componentDidUpdate = () => {
        // Save reference to tab to from main input
        if (this.sortedOrigins[0]) {
            const firstKey = this.sortedOrigins[0][0];
            const firstOriginEntry = this.sortedOrigins[0][1];
            const firstRowInputRefs = this.allTableInputRefs[firstKey];

            if (!firstOriginEntry[AUTO_ADDED_KEY]) {
                if (firstOriginEntry[EQUIPMENT_KEYS.EQUIPMENT_DESC]) {
                    this.firstTableInputRef = firstRowInputRefs?.[INPUT_REFS_KEYS.descriptionRef];
                } else {
                    this.firstTableInputRef = firstRowInputRefs?.[INPUT_REFS_KEYS.originRef];
                }
            }

            if (firstOriginEntry[AUTO_ADDED_KEY]) {
                this.firstTableInputRef = firstRowInputRefs?.[INPUT_REFS_KEYS.sampleListRef];
            }
        }
    };

    addRefToAllTableInputRefs = (originKey, refKey, ref) => {
        if (this.allTableInputRefs[originKey]) {
            this.allTableInputRefs[originKey][refKey] = ref;
        }
    };

    tableInputKeyDownHandler = (event) => {
        if (event.key === 'Tab' && event.shiftKey && document.activeElement === this.firstTableInputRef) {
            event.preventDefault();
            this.mainInputRef.focus();
        }
    };

    mapOrigins = (origins) => {
        const pickedOriginSamples = origins.flatMap((origin) => origin[1].sampleList?.map(({ code }) => code));

        return origins.map(([objKey, article]) => ({
            ...article,
            [EQUIPMENT_KEYS.EQUIPMENT_DESC]: article[EQUIPMENT_KEYS.EQUIPMENT_CODE] ? (
                article[EQUIPMENT_KEYS.EQUIPMENT_DESC]
            ) : (
                <div style={{ width: '250px' }}>
                    <EAMInput
                        valueKey={EQUIPMENT_KEYS.EQUIPMENT_DESC}
                        value={article[EQUIPMENT_KEYS.EQUIPMENT_DESC] || ''}
                        placeholder="Description"
                        styles={styles.originDescInput}
                        updateProperty={(key, value) => this.updateThisState(['origins', objKey, key], value)}
                        inputRef={(inputRef) => {
                            this.addRefToAllTableInputRefs(objKey, INPUT_REFS_KEYS.descriptionRef, inputRef);
                        }}
                        onKeyDown={(event) => this.tableInputKeyDownHandler(event, objKey)}
                    />
                </div>
            ),
            [EQUIPMENT_KEYS.EQUIPMENT_CODE]:
                !article[[EQUIPMENT_KEYS.EQUIPMENT_CODE]] && article[EQUIPMENT_KEYS.EQUIPMENT_DESC] ? null : (
                    <div style={{ width: '250px' }}>
                        <AMMSingleAutocomplete
                            autoFocus
                            elementInfo={{
                                readonly: article[AUTO_ADDED_KEY],
                            }}
                            loadOptions={WSEquipment.getAutocompleteOptions}
                            getOptionLabel={(opt) => `${opt.eqCode} ${opt.description}`}
                            getOptionValue={(opt) => opt.eqCode}
                            placeholder={this.props.getTranslation(TRANSLATION_KEYS.ORIGIN)}
                            onChange={(opt) => {
                                this.updateThisState(['origins', objKey, EQUIPMENT_KEYS.EQUIPMENT_CODE], opt?.eqCode);
                            }}
                            value={{
                                code: article[EQUIPMENT_KEYS.EQUIPMENT_CODE],
                            }}
                            components={{
                                Option: EquipmentOption,
                                SingleValue: SingleValueContent,
                            }}
                            innerComponentInputRef={(ref) => {
                                this.addRefToAllTableInputRefs(objKey, INPUT_REFS_KEYS.originRef, ref);
                            }}
                            onKeyDown={(event) => this.tableInputKeyDownHandler(event, objKey)}
                        />
                    </div>
                ),
            remove: (
                <CloseCircle
                    onClick={(_) => {
                        this.removeItem(objKey);
                    }}
                    style={{ color: 'rgba(230, 63, 43, 0.7)' }}
                />
            ),
            sampleList: (
                <div style={{ width: '450px' }}>
                    <EAMAutocomplete
                        elementInfo={{
                            xpath: `${objKey}`,
                            attribute: 'R',
                            maxUpdateRecords: this.props.applicationData.rpanalysisMaxEquipments,
                        }}
                        value={Object.values(article.sampleList || {})
                            .map((e) => e.code)
                            .join(',')}
                        valueKey="sampleList"
                        styles={styles.sourceEqAutocomplete}
                        updateProperty={(key, value) => {
                            this.updateThisState(['origins', objKey, key], value, false);
                        }}
                        loadOptions={(value) => WSRPSampling.getSamples(value)}
                        filterOptions={(options) => options.filter(({ code }) => !pickedOriginSamples.includes(code))}
                        multi
                        // creatable={true}
                        // onChange={value => this.updateThisState(['origins', objKey, 'sampleList'], value, false)}
                        formFields={this.formFields}
                        columnsWidth={['40', '100', '300', '300']}
                        autoSelectSingleElement
                        refToReactSelectInput={(ref) => {
                            this.addRefToAllTableInputRefs(objKey, INPUT_REFS_KEYS.sampleListRef, ref);
                        }}
                        onInputKeyDown={(event) => {
                            this.tableInputKeyDownHandler(event, objKey);
                        }}
                    />
                </div>
            ),
        }));
    };

    removeItem = (objKey) => {
        delete this.allTableInputRefs[objKey];

        if (isEmpty(this.allTableInputRefs)) {
            this.firstTableInputRef = null;
        }

        this.updateThisState(
            ['origins'],
            Tools.filterObjectFields(this.state.origins, (key, _) => `${key}` !== objKey),
            false
        );
    };

    updateThisState = Tools.updateThisState;

    generateNewKey = () => {
        const nextIndex = Math.max(0, ...Object.keys(this.state.origins).map((s) => +s.slice(0, 3))) + 1;
        return `000${nextIndex}`.slice(-3) + `000000${Math.floor(Math.random() * 1000000)}`.slice(-6);
    };

    addEmptyLine = () => {
        const newKey = this.generateNewKey();
        this.allTableInputRefs[newKey] = {};
        this.updateThisState(['origins', newKey], { [AUTO_ADDED_KEY]: false, [GET_KEY_KEY]: () => newKey });
    };

    addAutoLine = (eqCode) => {
        const newKey = this.generateNewKey();
        this.allTableInputRefs[newKey] = {};
        this.updateThisState(['origins', newKey], {
            [EQUIPMENT_KEYS.EQUIPMENT_CODE]: eqCode,
            [AUTO_ADDED_KEY]: true,
            [GET_KEY_KEY]: () => newKey,
        });
    };

    mainInputKeyDownHandler = (event) => {
        // We keep the default behavior if the user has typed something into the input
        if (event.key === 'Tab' && this.firstTableInputRef) {
            if (!this.mainMenuOpen || (this.mainMenuOpen && !this.mainInputRef.state.inputValue)) {
                event.preventDefault();
                this.firstTableInputRef.focus();
            }
        }
    };

    render() {
        const { origins } = this.state;
        const { getTranslation } = this.props;
        const originTranslation = getTranslation(TRANSLATION_KEYS.ORIGIN);
        const pickedOriginEquipments = Object.values(origins).map((origin) => origin.equipmentCode);
        this.sortedOrigins = !isEmpty(origins) ? Object.entries(origins).sort((a, _) => a[1] - a[0]) : [];

        return (
            <div style={{ margin: 0 }}>
                <EISPanel style={{ margin: 0 }} heading={getTranslation('ORIGIN')} alwaysExpanded>
                    <div style={{ display: 'flex', flexWrap: 'nowrap', flex: '1', flexDirection: 'column' }}>
                        <EquipmentAutocomplete
                            autoFocus
                            placeholder={`${Array.from({ length: 4 }, (_, i) => `${originTranslation}${i + 1}`)},...`}
                            onChange={(opt) => {
                                if (opt && !pickedOriginEquipments.includes(opt[0].eqCode)) {
                                    this.addAutoLine(opt[0].eqCode);
                                }
                            }}
                            value={null}
                            filterOption={(option) => !pickedOriginEquipments.includes(option.label)}
                            innerComponentRef={(ref) => {
                                this.mainInputRef = ref;
                            }}
                            onMenuOpen={() => {
                                this.mainMenuOpen = true;
                            }}
                            onMenuClose={() => {
                                this.mainMenuOpen = false;
                            }}
                            onKeyDown={this.mainInputKeyDownHandler}
                        />
                        <EISTable
                            data={(this.sortedOrigins && this.mapOrigins(this.sortedOrigins)) || []}
                            headers={['', RPSM_KEYS.ORIGIN, EQUIPMENT_KEYS.EQUIPMENT_DESC, 'sampleList'].map((e) =>
                                getTranslation(e)
                            )}
                            propCodes={[
                                'remove',
                                EQUIPMENT_KEYS.EQUIPMENT_CODE,
                                EQUIPMENT_KEYS.EQUIPMENT_DESC,
                                'sampleList',
                            ]}
                        />
                        {this.canAddLine() && (
                            <PlusBox
                                onClick={(_) => {
                                    this.addEmptyLine();
                                }}
                                style={{ color: '#2196F3', marginTop: '14', marginLeft: '2' }}
                            />
                        )}
                    </div>
                </EISPanel>
            </div>
        );
    }
}

export default RPSMEquipmentSelection;
