import { Search } from '@material-ui/icons';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Cancel from '@material-ui/icons/Cancel';
import Save from '@material-ui/icons/Save';
import { Refresh } from 'mdi-material-ui';
import { TREC_GENERAL_KEYS } from '../../../enums/Constants';
import TRECButton from '../../components/buttons/TRECButton';
import { STEPS, VACUUM_RENTAL_STATUS } from './VacCleanerConstants';

const VacuumWizardControls = (props) => {
    const {
        handleCancel,
        handleCreate,
        handlePrevious,
        handleNext,
        isFirstStep,
        isLastStep,
        getTranslation,
        canUpdate,
        validForm,
        reload,
        vaccleanerNumber,
        cancelVacRequest,
        statusCode,
        currentStep,
    } = props;

    return (
        <>
            <TRECButton onClick={handleCancel} color="default">
                <Search />
                {getTranslation(TREC_GENERAL_KEYS.BACK)}
            </TRECButton>

            <TRECButton disabled={isFirstStep} onClick={handlePrevious}>
                <ArrowBack />
                {getTranslation('PREVIOUS')}
            </TRECButton>

            {!isLastStep && (
                <TRECButton onClick={handleNext}>
                    <ArrowForward />
                    {getTranslation(TREC_GENERAL_KEYS.NEXT)}
                </TRECButton>
            )}
            {canUpdate && (
                <>
                    {vaccleanerNumber && (
                        <TRECButton color="default" onClick={cancelVacRequest}>
                            <Cancel />
                            {getTranslation(TREC_GENERAL_KEYS.CANCEL)}
                        </TRECButton>
                    )}

                    {vaccleanerNumber && (
                        <TRECButton color="secondary" onClick={reload}>
                            <Refresh />
                            {getTranslation(TREC_GENERAL_KEYS.RELOAD)}
                        </TRECButton>
                    )}

                    {(vaccleanerNumber || [STEPS.SUMMARY].includes(currentStep)) && (
                        <TRECButton color="secondary" disabled={!validForm} onClick={() => handleCreate()}>
                            <Save />
                            {getTranslation(TREC_GENERAL_KEYS.SAVE)}
                        </TRECButton>
                    )}
                    {[STEPS.SUMMARY].includes(currentStep) &&
                        [undefined, '', null, VACUUM_RENTAL_STATUS.CREATED].includes(statusCode) && (
                            <TRECButton
                                color="primary"
                                disabled={!validForm}
                                onClick={() => handleCreate(VACUUM_RENTAL_STATUS.PENDING)}
                            >
                                <Save />
                                {getTranslation(TREC_GENERAL_KEYS.SUBMIT)}
                            </TRECButton>
                        )}
                    {[VACUUM_RENTAL_STATUS.PENDING].includes(statusCode) && (
                        <TRECButton
                            color="primary"
                            disabled={!validForm}
                            onClick={() => handleCreate(VACUUM_RENTAL_STATUS.IN_PROGRESS)}
                        >
                            <Save />
                            {getTranslation('ACCEPT')}
                        </TRECButton>
                    )}
                </>
            )}
        </>
    );
};

export default VacuumWizardControls;
