import { Button, Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import { CloseCircle, PlusBox } from 'mdi-material-ui';
import Plus from 'mdi-material-ui/Plus';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import Tools from 'tools/Tools';
import { showError } from 'tools/TrecNotifications';
import WSRWElimination from 'tools/rest/WSRWElimination';
import EquipmentOption from 'ui/components/autocomplete/EquipmentOption';
import { generateTableData, getInputGenerator } from 'ui/pages/rpmrequest2/InputGenerator';
import { TABLE_DATA_TYPES } from '../../../../enums/Constants';
import { ATTRIBUTES } from '../../wrrequest/WRRHelpers';
import RWElimTranslations from '../RWElimTranslations';
import {
    COLISSAGE_OPTIONS,
    RW_ELIMINATION_KEYS,
    RW_ELIMINATION_PACKAGE_KEYS,
    RW_ELIMINATION_STATUS,
    RW_ELIMINATION_TRANSPORT_CONTAINER_KEYS,
    RW_ELIM_STEPS_KEY,
    TRANSLATION_KEYS,
} from '../RWEliminationConstants';
import ContainerEliminationSelection from '../ContainerEliminationSelection.js';

const WASTE_PACKAGE_STYLES = {
    container: {
        display: 'flex',
        margin: '8px 0',
        width: '100%',
    },
    label: {
        height: '100%',
        minWidth: '125px',
        width: '125px',
        display: 'inline-flex',
        alignItems: 'center',
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: '14px',
        marginRight: '4px',
        justifyContent: 'end',
    },
    elementsContainer: {
        display: 'flex',
        flex: 1,
        border: '1px solid #ced4da',
        width: '100%',
        borderRadius: '4px',
        flexWrap: 'wrap',
        backgroundColor: '#fafafa',
    },
    elementContainer: {
        display: 'flex',
        minHeight: '32px',
        width: 'fit-content',
        textAlign: 'left',
        backgroundColor: 'white',
        border: '1px solid #ced4da',
        color: 'black',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        padding: '0 8px 0 4px',
        fontSize: '14px',
    },
    elementLabel: {
        display: 'flex',
        flexDirection: 'column',
    },
    eliminationPathway: {
        fontSize: '10px',
    },
    elementButtonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    elementButton: {
        padding: '2px 0',
        margin: '2px 0 2px 6px',
        minHeight: 0,
        boxShadow: 'none',
        fontSize: '12px',
    },
};

const customAMMDropdownStyles = {
    control: (provided) => ({
        ...provided,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fafafa',
        minHeight: '33px',
        minWidth: '365px',
        ':hover': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            borderColor: '#80bdff',
        },
    }),
};

const counterStyle = { height: '100%', margin: '0 -4px -4px -4px', display: 'flex', flexDirection: 'column' };

const CustomIndicatorContainer = (props) => {
    const { innerProps, getStyles, children, getValue, selectProps } = props;
    const { showCount, isMulti } = selectProps;
    const selectedCount = isMulti && getValue().length;
    return (
        <div
            {...innerProps}
            style={{
                ...getStyles('clearIndicator', props),
                padding: '8px 0 0 0',
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            {selectedCount > 0 && showCount && (
                <>
                    {children.filter((child) => child?.type.name === 'IndicatorSeparator')}
                    <div style={counterStyle}>
                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '-8px' }}>
                            ({selectedCount})
                        </div>
                        {children.filter((child) => child?.type.name === 'ClearIndicator')}
                    </div>
                </>
            )}
        </div>
    );
};

const updateTransportContainer = (rwElimination, wastePackage, transportContainer, storeActions) => {
    storeActions.updateRWElimination({
        [RW_ELIMINATION_KEYS.PACKAGES]: Tools.applyToFieldsKV(rwElimination[RW_ELIMINATION_KEYS.PACKAGES], (k, v) => ({
            ...v,
            [RW_ELIMINATION_PACKAGE_KEYS.TRANSPORT_CONTAINER]:
                wastePackage === k ? `${transportContainer}` : v[RW_ELIMINATION_PACKAGE_KEYS.TRANSPORT_CONTAINER],
        })),
    });
};

const updateContainerElimination = (rwElimination, storeActions) => (wastePackages, eliminateContainer) => {
    storeActions.updateRWElimination({
        [RW_ELIMINATION_KEYS.PACKAGES]: Tools.applyToFieldsKV(rwElimination[RW_ELIMINATION_KEYS.PACKAGES], (k, v) => ({
            ...v,
            [RW_ELIMINATION_PACKAGE_KEYS.CONTAINER_ELIMINATION]: wastePackages.includes(k)
                ? eliminateContainer
                : v[RW_ELIMINATION_PACKAGE_KEYS.CONTAINER_ELIMINATION],
        })),
    });
};

const AddTCButton = (props) => {
    const { label, onClick } = props;
    return (
        <Button style={WASTE_PACKAGE_STYLES.elementButton} onClick={onClick} variant="contained" color="info">
            <Plus style={{ fontSize: 'inherit' }} /> {label}
        </Button>
    );
};

const WastePackageComponent = ({ eqCode, eliminationPathway, styles, extraRender, data }) => {
    const eliminationPathwayValue = eliminationPathway || data?.eliminationPathway;
    return (
        <div
            onMouseDown={(e) => e.stopPropagation()}
            style={{ ...WASTE_PACKAGE_STYLES.elementContainer, ...styles?.elementContainer }}
        >
            <div style={{ ...WASTE_PACKAGE_STYLES.elementLabel, ...styles?.elementLabel }}>
                {eqCode || data.eqCode || data.equipmentCode}{' '}
                {eliminationPathwayValue && (
                    <i style={{ ...WASTE_PACKAGE_STYLES.eliminationPathway, ...styles?.elementLabel }}>
                        ({eliminationPathwayValue}) {data.grossMass ? `${data.grossMass.toFixed(2)} kg` : ''}
                    </i>
                )}
            </div>
            {extraRender}
        </div>
    );
};

export const getDetailsFields = ({
    rwEquipmentList,
    transportingContainers,
    applicationData,
    storeActions,
    getTranslation,
    eliminationPathways,
    canEdit,
    statusCode,
}) => [
    {
        code: RW_ELIMINATION_KEYS.PACKAGES,
        translationKey: RWElimTranslations.PRESELECTED_PACKAGES,
        type: TABLE_DATA_TYPES.AMMDROPDOWN,
        getAttribute: () => (canEdit ? ATTRIBUTES.REQUIRED : ATTRIBUTES.READONLY),
        loadOptions: WSRWElimination.getAutocompleteWastePackages(eliminationPathways),
        placeholder: getTranslation('PACKAGES'),
        valueKey: 'equipmentCode',
        customInputProps: {
            OptionComponent: EquipmentOption,
            ValueComponent: WastePackageComponent,
            styles: { ...customAMMDropdownStyles },
            multi: true,
        },
        customValue: () => rwEquipmentList,
        customUpdateObject: () => (objectToUpdate) => {
            storeActions.updateRWElimination({
                [RW_ELIMINATION_KEYS.PACKAGES]:
                    objectToUpdate?.[RW_ELIMINATION_KEYS.PACKAGES]?.reduce(
                        (acc, s) => ({
                            ...acc,
                            ...{
                                [s.eqCode || s.equipmentCode]: {
                                    equipmentCode: s.eqCode || s.equipmentCode,
                                    ...s,
                                },
                            },
                        }),
                        {}
                    ) ?? [],
            });
        },
    },
    {
        code: RW_ELIMINATION_KEYS.PICKUP_DATE,
        translationKey: TRANSLATION_KEYS.PICKUP_DATE,
        type: TABLE_DATA_TYPES.DATE,
        getAttribute: () => ATTRIBUTES.OPTIONAL,
        customInputProps: () => ({
            style: { minWidth: '110px' },
            timestamp: true,
        }),
        width: 6,
    },
    {
        code: RW_ELIMINATION_KEYS.END_DATE,
        translationKey: TRANSLATION_KEYS.END_DATE,
        type: TABLE_DATA_TYPES.DATE,
        getAttribute: () => ATTRIBUTES.OPTIONAL,
        customInputProps: () => ({
            style: { minWidth: '110px' },
            timestamp: true,
        }),
        width: 6,
    },
    {
        code: 'readonly_totalmass',
        translationKey: TRANSLATION_KEYS.TOTAL_MASS,
        type: TABLE_DATA_TYPES.INPUT,
        getAttribute: () => ATTRIBUTES.READONLY,
        customValue: () =>
            Math.round(
                transportingContainers
                    ?.map((s) => s?.[RW_ELIMINATION_TRANSPORT_CONTAINER_KEYS.TARE_WEIGHT] ?? 0)
                    .reduce((acc, el) => acc + +el, 0) +
                    rwEquipmentList?.reduce((acc, s) => +s?.[RW_ELIMINATION_PACKAGE_KEYS.GROSS_MASS] + acc, 0)
            ),
        uom: 'kg',
        width: 6,
    },
    {
        code: RW_ELIMINATION_KEYS.COLISSAGE,
        translationKey: RW_ELIMINATION_KEYS.COLISSAGE,
        type: TABLE_DATA_TYPES.SELECT,
        getAttribute: () => (canEdit ? ATTRIBUTES.REQUIRED : ATTRIBUTES.READONLY),
        values: Object.entries(COLISSAGE_OPTIONS).map(([code, desc]) => ({ code, desc })),
        width: 6,
    },
    {
        code: RW_ELIMINATION_KEYS.LENGTH,
        translationKey: RW_ELIMINATION_KEYS.LENGTH,
        type: TABLE_DATA_TYPES.INPUT,
        getAttribute: () => (canEdit ? ATTRIBUTES.REQUIRED : ATTRIBUTES.READONLY),
        dataType: 'number',
        uom: 'cm',
        width: 4,
    },
    {
        code: RW_ELIMINATION_KEYS.WIDTH,
        translationKey: RW_ELIMINATION_KEYS.WIDTH,
        type: TABLE_DATA_TYPES.INPUT,
        getAttribute: () => (canEdit ? ATTRIBUTES.REQUIRED : ATTRIBUTES.READONLY),
        dataType: 'number',
        uom: 'cm',
        width: 4,
    },
    {
        code: RW_ELIMINATION_KEYS.HEIGHT,
        translationKey: RW_ELIMINATION_KEYS.HEIGHT,
        type: TABLE_DATA_TYPES.INPUT,
        getAttribute: () => (canEdit ? ATTRIBUTES.REQUIRED : ATTRIBUTES.READONLY),
        dataType: 'number',
        uom: 'cm',
        width: 4,
    },
    {
        code: RW_ELIMINATION_KEYS.ID_ANDRA,
        translationKey: TRANSLATION_KEYS.ID_ANDRA,
        type: TABLE_DATA_TYPES.INPUT,
        getAttribute: () => (canEdit ? ATTRIBUTES.OPTIONAL : ATTRIBUTES.READONLY),
    },
    {
        code: RW_ELIMINATION_KEYS.EDH_NUMBER,
        translationKey: TRANSLATION_KEYS.EDH_NUMBER,
        type: TABLE_DATA_TYPES.INPUT,
        getAttribute: () => ATTRIBUTES.READONLY,
        linkInput: applicationData.edhurl,
    },
    {
        code: RW_ELIMINATION_KEYS.INVOICE_EDH,
        translationKey: TRANSLATION_KEYS.INVOICE_EDH,
        type: TABLE_DATA_TYPES.INPUT,
        getAttribute: () => (statusCode === RW_ELIMINATION_STATUS.RECU ? ATTRIBUTES.REQUIRED : ATTRIBUTES.OPTIONAL),
        linkInput: applicationData.edhurl,
    },
];

export const getTCFields = ({ rwElimination, rwEquipmentList, removeItem, storeActions, writeAccess }) => [
    {
        code: 'REMOVE_TC',
        translationKey: ' ',
        type: TABLE_DATA_TYPES.STATIC,
        customValue: (obj) =>
            writeAccess && 1 + +obj.index === rwElimination[RW_ELIMINATION_KEYS.TRANSPORTING_CONTAINERS].length ? (
                <CloseCircle
                    onClick={() => {
                        storeActions.updateRWElimination({
                            [RW_ELIMINATION_KEYS.PACKAGES]: Tools.applyToFieldsKV(
                                rwElimination[RW_ELIMINATION_KEYS.PACKAGES],
                                (k, v) => ({
                                    ...v,
                                    [RW_ELIMINATION_PACKAGE_KEYS.TRANSPORT_CONTAINER]:
                                        v[RW_ELIMINATION_PACKAGE_KEYS.TRANSPORT_CONTAINER] !== obj.index
                                            ? v[RW_ELIMINATION_PACKAGE_KEYS.TRANSPORT_CONTAINER]
                                            : '',
                                })
                            ),
                        });
                        removeItem(RW_ELIMINATION_KEYS.TRANSPORTING_CONTAINERS, obj.index);
                    }}
                    style={{ color: 'rgba(230, 63, 43, 0.7)' }}
                />
            ) : null,
    },
    {
        code: 'index',
        translationKey: '',
        type: TABLE_DATA_TYPES.STATIC,
        customValue: (obj) => `TC ${+obj.index + 1}`,
    },
    {
        code: RW_ELIMINATION_TRANSPORT_CONTAINER_KEYS.TC_CODE,
        translationKey: TRANSLATION_KEYS.IDTRANSPORTINGCONTAINER,
        type: TABLE_DATA_TYPES.INPUT,
        getAttribute: () => ATTRIBUTES.REQUIRED,
        customUpdateObject: (obj) => (objToUpdate) => {
            const tcs = rwElimination[RW_ELIMINATION_KEYS.TRANSPORTING_CONTAINERS].map((cont, idx) =>
                idx === +obj.index ? { ...cont, ...objToUpdate } : cont
            );
            storeActions.updateRWElimination({ [RW_ELIMINATION_KEYS.TRANSPORTING_CONTAINERS]: tcs });
        },
    },
    {
        code: RW_ELIMINATION_PACKAGE_KEYS.TRANSPORT_CONTAINER,
        translationKey: TRANSLATION_KEYS.TRANSP_CONT_CONTENT,
        type: TABLE_DATA_TYPES.AMMDROPDOWN,
        customInputProps: {
            multi: true,
            forceSync: true,
            showCount: true,
            IndicatorsContainerComponent: CustomIndicatorContainer,
            ValueComponent: WastePackageComponent,
            styles: { ...customAMMDropdownStyles },
            options: rwEquipmentList
                .filter((s) => !s[RW_ELIMINATION_PACKAGE_KEYS.TRANSPORT_CONTAINER])
                .map((s) => ({
                    eqCode: s[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE],
                })),
        },
        valueKey: 'eqCode',
        customValue: (object) =>
            rwEquipmentList
                .filter(
                    (s) =>
                        s[RW_ELIMINATION_PACKAGE_KEYS.TRANSPORT_CONTAINER] &&
                        s[RW_ELIMINATION_PACKAGE_KEYS.TRANSPORT_CONTAINER] === object.index
                )
                .map((s) => ({
                    eqCode: s[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE],
                    eliminationPathway:
                        s[RW_ELIMINATION_PACKAGE_KEYS.ELIMINATION_PATHWAY] ||
                        s[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT]?.eliminationProcess,
                })),
        getAttribute: () => ATTRIBUTES.REQUIRED,
        customUpdateObject: (obj) => (updateObj) => {
            const eqCodes = updateObj?.[RW_ELIMINATION_PACKAGE_KEYS.TRANSPORT_CONTAINER]?.map((wp) => wp.eqCode) ?? [];
            if (
                rwEquipmentList
                    .filter((s) => s[RW_ELIMINATION_PACKAGE_KEYS.TRANSPORT_CONTAINER] === obj.index)
                    .map((s) => s[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE])?.length === eqCodes?.length
            ) {
                return;
            }

            storeActions.updateRWElimination({
                [RW_ELIMINATION_KEYS.PACKAGES]: Tools.applyToFieldsKV(
                    rwElimination[RW_ELIMINATION_KEYS.PACKAGES],
                    (k, v) => ({
                        ...v,
                        [RW_ELIMINATION_PACKAGE_KEYS.TRANSPORT_CONTAINER]: eqCodes.includes(k)
                            ? obj.index
                            : v[RW_ELIMINATION_PACKAGE_KEYS.TRANSPORT_CONTAINER] !== obj.index
                            ? v[RW_ELIMINATION_PACKAGE_KEYS.TRANSPORT_CONTAINER]
                            : '',
                    })
                ),
            });
        },
    },
    {
        code: RW_ELIMINATION_TRANSPORT_CONTAINER_KEYS.TARE_WEIGHT,
        translationKey: TRANSLATION_KEYS.TARE_WEIGHT_TRANSPORTING_CONTAINER,
        type: TABLE_DATA_TYPES.INPUT,
        getAttribute: () => ATTRIBUTES.REQUIRED,
        dataType: 'number',
        uom: 'kg',
        customUpdateObject: (obj) => (objToUpdate) => {
            const tcs = rwElimination[RW_ELIMINATION_KEYS.TRANSPORTING_CONTAINERS].map((cont, idx) =>
                idx === +obj.index ? { ...cont, ...objToUpdate } : cont
            );
            storeActions.updateRWElimination({ [RW_ELIMINATION_KEYS.TRANSPORTING_CONTAINERS]: tcs });
        },
    },
    {
        code: 'TRANSP_CONT_WEIGHT',
        translationKey: TRANSLATION_KEYS.TRANSP_CONT_WEIGHT,
        type: TABLE_DATA_TYPES.INPUT,
        getAttribute: () => ATTRIBUTES.READONLY,
        customValue: (obj) =>
            Math.round(
                rwEquipmentList
                    .filter((s) => s[RW_ELIMINATION_PACKAGE_KEYS.TRANSPORT_CONTAINER] === obj.index)
                    .map((s) => s[RW_ELIMINATION_PACKAGE_KEYS.GROSS_MASS])
                    .reduce((acc, el) => acc + +el, 0)
            ),
        dataType: 'number',
        uom: 'kg',
    },
    {
        code: 'tcTotalWeight',
        translationKey: TRANSLATION_KEYS.TRANSP_CONT_TOTAL_WEIGHT,
        type: TABLE_DATA_TYPES.INPUT,
        getAttribute: () => ATTRIBUTES.READONLY,
        customValue: (obj) =>
            Math.round(
                rwEquipmentList
                    .filter((s) => s[RW_ELIMINATION_PACKAGE_KEYS.TRANSPORT_CONTAINER] === obj.index)
                    .map((s) => s[RW_ELIMINATION_PACKAGE_KEYS.GROSS_MASS])
                    .reduce((acc, el) => acc + +el, +obj[RW_ELIMINATION_TRANSPORT_CONTAINER_KEYS.TARE_WEIGHT])
            ),
        dataType: 'number',
        uom: 'kg',
    },
];

export const generateTCTableData = ({ fields, rwElimination, storeActions, formFields, labelStyle, writeAccess }) => {
    const tableHeaders = fields.map((e) => e.header);
    const tableCodes = fields.map((e) => e.code);

    const tableData = [
        ...generateTableData(
            fields,
            Object.values(rwElimination[RW_ELIMINATION_KEYS.TRANSPORTING_CONTAINERS]).map((s, i) => ({
                ...s,
                index: String(i),
            })),
            {
                updateObject: (obj) => (objectToUpdate) =>
                    storeActions.updateRWEliminationPackage(
                        obj[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE],
                        objectToUpdate
                    ),
                updateObjectPartial: (obj) => (objectToUpdate, path) =>
                    storeActions.updateRWEliminationPackage(obj[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE], {
                        [path]: {
                            ...obj[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE][path],
                            ...objectToUpdate,
                        },
                    }),
                formFields,
                labelStyle,
                writeAccess,
            },
            'index'
        ),
        ...(writeAccess
            ? [
                  {
                      [tableCodes[0]]: (
                          <PlusBox
                              key={`notrepeated${Math.random()}`}
                              onClick={(_) => {
                                  storeActions.updateRWElimination({
                                      [RW_ELIMINATION_KEYS.TRANSPORTING_CONTAINERS]: [
                                          ...rwElimination[RW_ELIMINATION_KEYS.TRANSPORTING_CONTAINERS],
                                          {},
                                      ],
                                  });
                              }}
                              style={{ color: '#2196F3', marginTop: '14', marginLeft: '2' }}
                          />
                      ),
                  },
              ]
            : []),
    ];

    return {
        tableCodes,
        tableHeaders,
        tableData,
    };
};

const RWEliminationDetails = ({
    labelStyle,
    rwElimRequest,
    writeAccess: writeAccess2,
    getTranslation,
    formFields,
    rwElimGetters,
    storeActions,
    lang,
    applicationData,
    isLastStep,
    stepKey,
}) => {
    const rwElimination = rwElimGetters.getProperties();
    const rwEquipmentList = Object.values(rwElimination[RW_ELIMINATION_KEYS.PACKAGES]).map((equipment) => ({
        ...equipment,
        eliminationPathway:
            equipment[RW_ELIMINATION_PACKAGE_KEYS.ELIMINATION_PATHWAY] ||
            equipment[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT]?.eliminationProcess,
    }));
    const transportingContainers = rwElimination[RW_ELIMINATION_KEYS.TRANSPORTING_CONTAINERS];
    const unassignedWPs = rwEquipmentList.filter((s) => !s[RW_ELIMINATION_PACKAGE_KEYS.TRANSPORT_CONTAINER]);
    const unassignedContainerElimination = rwEquipmentList.filter(
        (s) => s[RW_ELIMINATION_PACKAGE_KEYS.CONTAINER_ELIMINATION] === undefined
    );
    const eliminationPathways = rwElimination[RW_ELIMINATION_KEYS.RW_ELIMINATION_PATHWAY];
    const statusCode = rwElimination[RW_ELIMINATION_KEYS.STATUS];

    const writeAccess =
        (writeAccess2 &&
            [RW_ELIMINATION_STATUS.CREATED, RW_ELIMINATION_STATUS.PENDING].includes(
                rwElimRequest[RW_ELIMINATION_KEYS.STATUS]
            )) ||
        isLastStep;

    const detailsFields = getDetailsFields({
        getTranslation,
        transportingContainers,
        rwEquipmentList,
        applicationData,
        storeActions,
        eliminationPathways,
        canEdit: [RW_ELIMINATION_STATUS.CREATED, RW_ELIMINATION_STATUS.PENDING].includes(
            rwElimRequest[RW_ELIMINATION_KEYS.STATUS]
        ),
        statusCode,
    });
    const inputGenerator = getInputGenerator({
        object: rwElimRequest,
        updateObject: storeActions.updateRWElimination,
        updateObjectPartial: storeActions.updateRWEliminationPartial,
        formFields,
        labelStyle,
        getTranslation,
        writeAccess,
        lang,
    });

    const removeItem = (key, index) => {
        const weightLines = rwElimination[key];
        storeActions.updateRWElimination({
            [key]: [...weightLines.slice(0, index), ...weightLines.slice(1 + +index)],
        });
    };

    const fields = getTCFields({ rwElimination, rwEquipmentList, removeItem, storeActions, writeAccess }).map((e) => ({
        ...e,
        header: getTranslation(e.translationKey ?? e.code),
    }));

    const { tableHeaders, tableCodes, tableData } = generateTCTableData({
        fields,
        rwElimination,
        storeActions,
        formFields,
        labelStyle,
        writeAccess,
    });

    const renderWastePackageSection = (items, labelTranslationKey, extraRender) =>
        items.length > 0 && (
            <div style={WASTE_PACKAGE_STYLES.container}>
                <span style={WASTE_PACKAGE_STYLES.label}>{getTranslation(labelTranslationKey)}</span>
                <div style={WASTE_PACKAGE_STYLES.elementsContainer}>
                    {items.map((s) => (
                        <WastePackageComponent
                            key={s[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE]} // Se recomienda añadir una key única
                            eqCode={s[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE]}
                            eliminationPathway={s[RW_ELIMINATION_PACKAGE_KEYS.ELIMINATION_PATHWAY]}
                            styles={{
                                elementContainer: { margin: '2px' },
                                elementLabel: { width: '255px' },
                            }}
                            extraRender={extraRender(s)}
                        />
                    ))}
                </div>
            </div>
        );

    return (
        <div style={{ margin: 8 }}>
            <Grid container spacing={8}>
                <Grid item md={12} sm={12} xs={12}>
                    <EISPanel heading="RW Elimination Job Details" alwaysExpanded>
                        <Grid container spacing={0}>
                            {detailsFields.map(inputGenerator)}
                            {stepKey === RW_ELIM_STEPS_KEY.RECU && (
                                <>
                                    <div style={WASTE_PACKAGE_STYLES.container}>
                                        <span style={WASTE_PACKAGE_STYLES.label}>
                                            {getTranslation(RWElimTranslations.CONTAINER_ELIMINATION)}
                                        </span>
                                        <ContainerEliminationSelection
                                            wastePackages={rwEquipmentList}
                                            getTranslation={getTranslation}
                                            updateContainerElimination={updateContainerElimination(
                                                rwElimination,
                                                storeActions
                                            )}
                                            writeAccess={writeAccess}
                                        />
                                    </div>
                                    {renderWastePackageSection(
                                        unassignedContainerElimination,
                                        RWElimTranslations.UNASSIGNED_CONTAINER_ELIMINATION,
                                        (s) =>
                                            writeAccess && (
                                                <div style={WASTE_PACKAGE_STYLES.elementButtonsContainer}>
                                                    <AddTCButton
                                                        label={getTranslation(RWElimTranslations.CONTENT_ONLY)}
                                                        onClick={() =>
                                                            updateContainerElimination(rwElimination, storeActions)(
                                                                s[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE],
                                                                false
                                                            )
                                                        }
                                                    />
                                                    <AddTCButton
                                                        label={getTranslation(RWElimTranslations.CONTAINER_AND_CONTENT)}
                                                        onClick={() =>
                                                            updateContainerElimination(rwElimination, storeActions)(
                                                                s[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE],
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )
                                    )}
                                </>
                            )}
                            {renderWastePackageSection(
                                unassignedWPs,
                                RWElimTranslations.UNASSIGNED_PACKAGES,
                                (s) =>
                                    writeAccess && (
                                        <div style={WASTE_PACKAGE_STYLES.elementButtonsContainer}>
                                            {transportingContainers.map((container, index) => (
                                                <AddTCButton
                                                    label={`TC ${index + 1}`}
                                                    onClick={() =>
                                                        updateTransportContainer(
                                                            rwElimination,
                                                            s[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE],
                                                            index,
                                                            storeActions
                                                        )
                                                    }
                                                />
                                            ))}
                                        </div>
                                    )
                            )}
                            <EISTable
                                data={tableData}
                                headers={tableHeaders}
                                propCodes={tableCodes}
                                maxMobileSize={600}
                                style={{ overflowY: 'visible' }}
                            />
                        </Grid>
                    </EISPanel>
                </Grid>
            </Grid>
        </div>
    );
};

class RWEliminationDetailsStep extends WizardStep {
    formFields = {};

    state = {};

    componentDidMount() {
        super.componentDidMount();
    }

    handleSubmit = () => {
        const { stepKey, stepStatus } = this.props;
        this.canContinue() &&
            this.props.handleUpdate({
                translationKey: `${stepKey}_SUBMIT_MSG`,
                updateObject: {
                    [RW_ELIMINATION_KEYS.STATUS]: stepStatus,
                },
            });
    };

    handleSave = () => {
        this.canContinue();
    };

    /**
     * Can continue to the next step on the wizard
     */
    canContinue = () => {
        const isValid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
        if (!isValid) {
            showError('One or more fields are not valid.');
        }

        return isValid;
    };

    /**
     * SaveChanges is used to save the localized state to the redux-store. It can only run synchronous methods.
     */
    saveChanges = () => true;

    /**
     *  CommitChanges is used to trigger any side effects of the step, such as invocations to the back-end to
     * retrieve information about resources. This method can run asynchronous methods/requests but SHALL execute
     * the callback method afterwards to inform the wizard.
     */
    commitChanges = async (callback) => {
        callback();
    };

    /**
     * On true, the wizard automatically jumps this step immediately after it is mounted
     */
    canJumpStep = () => false;

    render() {
        return <RWEliminationDetails formFields={this.formFields} {...this.props} />;
    }
}

export default RWEliminationDetailsStep;
