export const RESET_RPMR = 'RESET_RPMR';
export const UPDATE_RPMR_BLOCK_UI = 'UPDATE_RPMR_BLOCK_UI';
export const UPDATE_RPMR = 'UPDATE_RPMR';
export const UPDATE_RPMR_SELECTED_EQUIPMENT = 'UPDATE_RPMR_SELECTED_EQUIPMENT';
export const UPDATE_RPMR_EQUIPMENT = 'UPDATE_RPMR_EQUIPMENT';
export const UPDATE_RPMR_EQUIPMENT_MAP = 'UPDATE_RPMR_EQUIPMENT_MAP';
export const UPDATE_APPLY_ALL_LINES = 'UPDATE_APPLY_ALL_LINES';
export const UPDATE_CREATED_RPMS = 'UPDATE_CREATED_RPMS';
export const UPDATE_ALLOW_MULTIPLE_RESP = 'UPDATE_ALLOW_MULTIPLE_RESP';
export const ADD_RPMR_SELECTED_EQUIPMENT = 'ADD_RPMR_SELECTED_EQUIPMENT';

export const resetRPMR = () => ({
    type: RESET_RPMR,
});

export const updateRPMRBlockUI = (value) => ({
    type: UPDATE_RPMR_BLOCK_UI,
    value,
});

export const updateRPMRequest = (value) => ({
    type: UPDATE_RPMR,
    value,
});

export const updateRPMRSelectedEquipment = (value) => ({
    type: UPDATE_RPMR_SELECTED_EQUIPMENT,
    value,
});

export const addRPMRSelectedEquipment = (value) => ({
    type: ADD_RPMR_SELECTED_EQUIPMENT,
    value,
});

export const updateRPMREquipment = (eqCodeList, object) => ({
    type: UPDATE_RPMR_EQUIPMENT,
    eqCodeList,
    object,
});

export const updateRPMREquipmentMap = (value, fetched = false) => ({
    type: UPDATE_RPMR_EQUIPMENT_MAP,
    value,
    fetched,
});

export const setApplyAllLines = (value) => ({
    type: UPDATE_APPLY_ALL_LINES,
    value,
});

export const setAllowMultipleResp = (value) => ({
    type: UPDATE_ALLOW_MULTIPLE_RESP,
    value,
});

export const updateCreatedRPMs = (value) => ({
    type: UPDATE_CREATED_RPMS,
    value,
});

export default {
    resetRPMR,
    updateRPMRBlockUI,
    updateRPMRequest,
    updateRPMRSelectedEquipment,
    updateRPMREquipment,
    updateRPMREquipmentMap,
    setApplyAllLines,
    updateCreatedRPMs,
    setAllowMultipleResp,
    addRPMRSelectedEquipment,
};
