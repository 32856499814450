import { EQUIPMENT_KEYS } from '../enums/Constants';
import Tools from './Tools';

// verifies that the hazards are enabled in applicationData and present in at least one
// of the provided arguments (equipment, hazards, hazard)
// note: for equipment, only EQUIPMENT_KEYS.HAZARDS is checked and not HAZARD_CODES nor HAZARD_COMMENT
const hasHazards = ({ applicationData, equipment, hazards, hazard }) =>
    applicationData.isHazardsFeatureActive &&
    (Array.isArray(hazards) ||
        (typeof equipment === 'object' && Array.isArray(equipment[EQUIPMENT_KEYS.HAZARDS])) ||
        typeof hazard === 'object');

const isTRECHazard = ({ hazard, applicationData }) => hasHazards({ applicationData, hazard }) && hazard.trecHazard;

// hazards is an Array of HazardTREC, groups hazards and returns { trecHazards, nonTrecHazards }
const groupHazards = ({ hazards, applicationData }) => {
    const returnObject = { trecHazards: [], nonTrecHazards: [] };
    const { trecHazards, nonTrecHazards } = returnObject;

    if (!hasHazards({ applicationData, hazards })) {
        return returnObject;
    }

    hazards.forEach((hazard) =>
        isTRECHazard({
            hazard,
            applicationData,
        })
            ? trecHazards.push(hazard)
            : nonTrecHazards.push(hazard)
    );

    return returnObject;
};

export const shouldShowComment = ({ equipment, applicationData }) =>
    hasHazards({ equipment, applicationData }) &&
    Array.isArray(equipment[EQUIPMENT_KEYS.HAZARD_CODES]) &&
    equipment[EQUIPMENT_KEYS.HAZARD_CODES].some((hazard) => hazard === applicationData.otherHazardCode);

export const equipmentHazardInputAdapter = ({ equipment, applicationData }) => {
    if (!hasHazards({ equipment, applicationData })) {
        return equipment;
    }

    const { trecHazards } = groupHazards({
        hazards: equipment[EQUIPMENT_KEYS.HAZARDS],
        applicationData,
    });

    return {
        ...equipment,
        [EQUIPMENT_KEYS.HAZARD_CODES]: trecHazards.map((hazard) => hazard.hazardTRECCode),
        [EQUIPMENT_KEYS.HAZARD_COMMENT]: (
            trecHazards.find(
                (hazard) => hazard.comment !== null && hazard.hazardTRECCode === applicationData.otherHazardCode
            ) || {}
        ).comment,
    };
};

export const equipmentsArrayHazardInputAdapter = ({ equipments, applicationData }) =>
    equipments.map((equipment) => equipmentHazardInputAdapter({ equipment, applicationData }));

export const equipmentsObjectHazardInputAdapter = ({ equipments, applicationData }) =>
    Tools.applyToFields(equipments, (equipment) => equipmentHazardInputAdapter({ equipment, applicationData }));

const HAZARD_KEYS = ['hazardTRECCode', 'comment', 'safety'];

const hazardDTOMapper = (hazard) =>
    HAZARD_KEYS.reduce(
        (acc, key) => ({
            ...acc,
            [key]: hazard[key],
        }),
        {}
    );

export const equipmentHazardOutputAdapter = ({ equipment, applicationData }) => {
    if (!hasHazards({ applicationData, equipment }) || !Array.isArray(equipment[EQUIPMENT_KEYS.HAZARD_CODES])) {
        return equipment;
    }

    const outputEquipment = {
        ...equipment,
        [EQUIPMENT_KEYS.HAZARDS]: [
            ...groupHazards({
                hazards: equipment[EQUIPMENT_KEYS.HAZARDS],
                applicationData,
            }).nonTrecHazards,
            ...equipment[EQUIPMENT_KEYS.HAZARD_CODES].map((hazardCode) => ({
                ...(equipment[EQUIPMENT_KEYS.HAZARDS].find(
                    (hazard) => hazard.hazardTRECCode === hazardCode && isTRECHazard({ hazard, applicationData })
                ) || { hazardTRECCode: hazardCode }),
                comment:
                    hazardCode === applicationData.otherHazardCode ? equipment[EQUIPMENT_KEYS.HAZARD_COMMENT] : null,
            })),
        ].map(hazardDTOMapper),
    };

    delete outputEquipment[EQUIPMENT_KEYS.HAZARD_CODES];
    delete outputEquipment[EQUIPMENT_KEYS.HAZARD_COMMENT];

    return outputEquipment;
};

export const equipmentsArrayHazardOutputAdapter = ({ equipments, applicationData }) =>
    equipments.map((equipment) => equipmentHazardOutputAdapter({ equipment, applicationData }));

export const equipmentsObjectHazardOutputAdapter = ({ equipments, applicationData }) =>
    Tools.applyToFields(equipments, (equipment) => equipmentHazardOutputAdapter({ equipment, applicationData }));

export const getHazardsFromEAMGridRow = (row) => (Tools.getGridCellValueFromRow(row, 'hazards_list') || '').split(',');
