import React from 'react';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import { WizardOrientation } from 'amm-tools';

const stepLabelStyle = {
    wordWrap: 'break-word',
    boxSizing: 'border-box',
    margin: 0,
};

const DefaultStepper = ({
    stepSequence,
    steps,
    isFinished,
    currentStepIndex,
    clickStep,
    permissions,
    orientation,
    maxWidth,
}) => (
    <Stepper
        activeStep={currentStepIndex + (isFinished && currentStepIndex === stepSequence.length - 1 ? 1 : 0)}
        alternativeLabel={WizardOrientation.VERTICAL === orientation}
        style={{
            padding: 0,
            margin: 5,
            marginRight: WizardOrientation.HORIZONTAL === orientation && maxWidth ? '30px' : undefined,
            maxWidth: WizardOrientation.HORIZONTAL === orientation && maxWidth,
            boxSizing: 'border-box',
        }}
        orientation={
            WizardOrientation.VERTICAL === orientation ? WizardOrientation.HORIZONTAL : WizardOrientation.VERTICAL
        }
    >
        {stepSequence.map((step) => (
            <Step
                style={stepLabelStyle}
                key={step}
                orientation={
                    WizardOrientation.VERTICAL === orientation
                        ? WizardOrientation.HORIZONTAL
                        : WizardOrientation.VERTICAL
                }
            >
                <StepLabel onClick={() => clickStep(step)} style={stepLabelStyle}>
                    {steps[step]?.label || `Step ${step} undefined`}
                    {permissions ? (
                        <>
                            <br />
                            {permissions[step].join() || '-'}
                        </>
                    ) : null}
                </StepLabel>
            </Step>
        ))}
    </Stepper>
);
export default DefaultStepper;
