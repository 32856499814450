import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Info from '@material-ui/icons/Info';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EAMLookup from 'eam-components/dist/ui/components/lookup/EAMLookup';
import { FilterRemoveOutline } from 'mdi-material-ui';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import BlockUi from 'react-block-ui';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import WSRWManagement from 'tools/rest/WSRWManagement';
import WSRWPProcessing from 'tools/rest/WSRWPProcessing';
import { generateRoute, getInternalLink, ROUTES } from 'tools/Routes';
import Tools from 'tools/Tools';
import TRECButton from 'ui/components/buttons/TRECButton';
import EquipmentHazardContainer from 'ui/components/eqphazard/EquipmentHazardContainer';
import EAMGridNoOverflow from 'ui/components/grid/EAMGridNoOverflow';
import gridNoOverflowClasses from 'ui/components/grid/EAMGridNoOverflow.module.css';
import SearchPanel from 'ui/components/searchpanel/SearchPanel';
import SearchPanelControls from 'ui/components/searchpanel/SearchPanelControls';
import TRECSearchPage from '../TRECSearchPage';
import RWManagementControls from './RWManagementControls';
import RWManagementDetail from './RWManagementDetail';
import RWManagementElimination from './RWManagementElimination';
import RWManagementUpdate from './RWManagementUpdate';
import { CUTG10_FIELDS, CUTG10_TRANSL_MAP, getRowAsAnObject } from './RWManagementUtils';
import { handleError, showSuccess } from 'tools/TrecNotifications';

const labelStyleRight = {
    width: 'auto',
    minWidth: 70,
    textAlign: 'right',
};

const CELL_TYPES = {
    CHECKBOX: '___checkbox',
};

const cellRenderer =
    ({ rwpDropdowns, selectedEquipment, setSelectedEquipment, setWasteEquipmentDetail, structure }) =>
    ({ t, value }, row) => {
        const object = getRowAsAnObject(row);
        if (t === CELL_TYPES.CHECKBOX) {
            const checked = !!selectedEquipment[object[CUTG10_FIELDS.OBJ_CODE]];
            const check = object[CUTG10_FIELDS.OBJ_PARENT] ? null : (
                <Checkbox
                    checked={checked}
                    onChange={() =>
                        setSelectedEquipment((prevState) => ({
                            ...Tools.filterObjectFields(prevState, (key) => key !== object[CUTG10_FIELDS.OBJ_CODE]),
                            ...(prevState?.[object[CUTG10_FIELDS.OBJ_CODE]]
                                ? {}
                                : { [object[CUTG10_FIELDS.OBJ_CODE]]: object }),
                        }))
                    }
                />
            );

            return check;
        }
        if (t === CUTG10_FIELDS.HAZARDS_LIST) {
            const classification = object[CUTG10_FIELDS.EQP_RPCLASSIFICATION];
            return <EquipmentHazardContainer classification={classification} hazards={value} />;
        }
        if (t === CUTG10_FIELDS.EQP_DIRECT_HAZARDS) {
            return (
                <Typography>
                    {value
                        ?.split(',')
                        .map((code) => rwpDropdowns.hazard.find((cd) => cd.code === code)?.desc || code)
                        .join(', ')}
                </Typography>
            );
        }
        if (t === CUTG10_FIELDS.LASTRP_ASSOCIATED_RISK) {
            const classification = object[CUTG10_FIELDS.EQP_RPCLASSIFICATION];
            const contamination = object[CUTG10_FIELDS.LASTRP_CONTAMINATION];
            const risks = value;
            return (
                <EquipmentHazardContainer
                    classification={classification}
                    contamination={contamination}
                    risks={risks}
                    hazards={object[CUTG10_FIELDS.HAZARDS_LIST]}
                />
            );

            // Link to the equipment in EAMLight
        }
        if (t === CUTG10_FIELDS.OBJ_CODE) {
            return getInternalLink({
                route: generateRoute(ROUTES.equipmentShow, { equipmentID: value }),
                style: { padding: '0px 2px', whiteSpace: 'nowrap' },
                text: value,
            });
        }
        if (Object.keys(CUTG10_TRANSL_MAP).includes(t)) {
            // TODO
            return (
                <Typography>
                    {value
                        ?.split(',')
                        .map((code) => rwpDropdowns[CUTG10_TRANSL_MAP[t]].find((cd) => cd.code === code)?.desc || code)
                        .join(', ')}
                </Typography>
            );
        }
        if (t === CUTG10_FIELDS.OBJ_LOCATION) {
            // Waste zone
            const wasteZone = Tools.getGridCellValueFromRow(row, CUTG10_FIELDS.RW_WASTE_ZONE);
            return <Typography>{wasteZone ? wasteZone.replace('W$', '') : value}</Typography>;
        }
        if (t === 'detail') {
            return (
                <Info
                    color="primary"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setWasteEquipmentDetail(object[CUTG10_FIELDS.OBJ_CODE])}
                />
            );
        }
        if (t === 'level') {
            return <Typography>{structure && structure[object[CUTG10_FIELDS.OBJ_CODE]]}</Typography>;
        }

        return false;
    };

const RWManagementFC = (props) => {
    const {
        assignRef,
        applicationData,
        constants,
        filters,
        formFields,
        getTranslation,
        grid,
        history,
        lang,
        location,
        onClearClick,
        onKeyDown,
        onPanelChange,
        onSearchClick,
        openConfirmDialog,
        refreshPageFiltersFromGridFilters,
        rwpDropdowns,
        screenData,
        thisParentRef,
        translations,
        updateProperty,
        writeAccess,
    } = props;

    const { customFieldsWasteDef, assetFields } = screenData[lang];

    const [valuesGrid, setValuesGrid] = useState();
    const [autorun, setAutoRun] = useState();
    const [propertyToUpdate, setPropertyToUpdate] = useState();
    const [wasteEquipmentDetail, setWasteEquipmentDetail] = useState();
    const [searchStructure, setSearchStructure] = useState();
    const [structureNodeCode, setStructureNodeCode] = useState();
    const [structure, setStructure] = useState();
    const [processing, setProcessing] = useState();
    const [selectAll, setSelectAll] = useState(false);
    const [rows, setRows] = useState([]);

    const [selectedEquipment, setSelectedEquipment] = useState({});
    const node = useRef(null);

    assetFields.equipmentno.readonly = searchStructure;
    assetFields.serialnumber.readonly = searchStructure;

    const extraColumns = useMemo(
        () => [
            ...(writeAccess
                ? [
                      {
                          width: '40px',
                          t: CELL_TYPES.CHECKBOX,
                      },
                  ]
                : []),
            {
                width: '70px',
                headerLabel: translations.DETAIL,
                t: 'detail',
            },
            ...(searchStructure
                ? [
                      {
                          width: '70px',
                          headerLabel: translations.LEVEL,
                          t: 'level',
                      },
                  ]
                : []),
        ],
        [writeAccess, searchStructure]
    );

    const performSearchOnEnterKey = (event) => {
        onKeyDown(event);
    };

    useEffect(() => {
        node.current.addEventListener('keydown', performSearchOnEnterKey, false);

        WSRWPProcessing.getValuesGrid()
            .then((response) => setValuesGrid(response.body.data))
            .catch(handleError);

        const queryParams = queryString.parse(location.search, { arrayFormat: 'comma' });
        if (CUTG10_FIELDS.OBJ_CODE in queryParams) {
            updateProperty(
                CUTG10_FIELDS.OBJ_CODE,
                [].concat(queryParams[CUTG10_FIELDS.OBJ_CODE]).map((e) => ({ code: e })),
                'EQUALS',
                'VARCHAR',
                true
            );
            setAutoRun(true);
        }

        return () => {
            node.current.removeEventListener('keydown', performSearchOnEnterKey, false);
        };
    }, []);

    const gridTranslations = useMemo(
        () =>
            [
                CUTG10_FIELDS.CF_RW_FAMILY,
                CUTG10_FIELDS.CF_RW_SUBFAMILY,
                CUTG10_FIELDS.CF_RW_PROCESSING,
                CUTG10_FIELDS.CF_ELIM_PROCESS,
                CUTG10_FIELDS.UDL_RW_MATERIALS,
                CUTG10_FIELDS.UDL_RW_FACILITIES,
            ].reduce(
                (acc, s) => ({
                    ...acc,
                    [s]: rwpDropdowns[CUTG10_TRANSL_MAP[s]].reduce((a, el) => ({ ...a, [el.code]: el.desc }), {}),
                }),
                {}
            ),
        []
    );

    const hiddenColumns = [CUTG10_FIELDS.EQP_RPCLASSIFICATION, CUTG10_FIELDS.CONTAMINATION];

    const gridRequestAdapter = useCallback(
        (gridRequest) => {
            let gridFilters = [...gridRequest.gridFilter];
            gridFilters = gridFilters.map((gridFilter) => {
                switch (gridFilter.fieldName) {
                    case CUTG10_FIELDS.RW_WASTE_ZONE:
                    case CUTG10_FIELDS.RW_WASTE_SUBZONE:
                        return {
                            ...gridFilter,
                            fieldName: CUTG10_FIELDS.RW_WASTE_ZONE,
                            fieldValue: gridFilter.fieldValue,
                        };
                    case CUTG10_FIELDS.OBJ_LOCATION:
                        if (gridFilter.fieldValue.indexOf('W$') !== -1) {
                            return {
                                ...gridFilter,
                                operator: 'BEGINS',
                                fieldName: CUTG10_FIELDS.RW_WASTE_ZONE,
                                fieldValue: gridFilter.fieldValue,
                            };
                        }
                        // Normal location
                        return gridFilter;
                    case CUTG10_FIELDS.OBJ_DESC:
                        return {
                            ...gridFilter,
                            forceCaseInsensitive: true,
                        };
                    default:
                        return gridFilter;
                }
            });

            if (!searchStructure) {
                gridFilters.push({
                    operator: 'IS_EMPTY',
                    fieldName: CUTG10_FIELDS.OBJ_PARENT,
                    joiner: 'AND',
                });
            }
            return {
                ...gridRequest,
                gridFilter: gridFilters,
            };
        },
        [searchStructure]
    );

    const runSearch = () => {
        onSearchClick(!searchStructure);
    };

    // Update the selected data
    const updateWasteEquipment = (records) => {
        openConfirmDialog(
            {
                title: translations.CONFIRM,
                message: translations.AREYOUSURE,
                cancelButtonLabel: translations.CANCEL,
                confirmButtonLabel: translations.CONFIRM,
                waitForCompletion: true,
            },
            () =>
                Promise.all(
                    records.map((elem) =>
                        WSRWManagement.updateWasteEquipment(
                            Tools.filterObjectFieldsFromList(elem, [
                                'property',
                                'propertyCode',
                                'equipmentCode',
                                'propertyList',
                                'irradEndDateMin',
                                'irradEndDateMax',
                            ])
                        )
                    )
                )
                    .then(() => {
                        // Data updated
                        showSuccess(translations.DATAUPDATEDOK);
                        // Refresh grid results
                        runSearch();
                        // Close update panel
                        setPropertyToUpdate(undefined);
                    })
                    .catch(handleError)
        );
    };

    const onSearchSwitchChange = () => {
        setSearchStructure((prevState) => !prevState);
        setStructureNodeCode('');
        if (grid) {
            grid.clearFilters(refreshPageFiltersFromGridFilters.bind(thisParentRef));
        }
    };

    const getStructureContent = (structureNodeCodeValue) => {
        if (structureNodeCodeValue) {
            setStructureNodeCode(structureNodeCodeValue);
        } else {
            setStructureNodeCode('');
        }
        if (grid && searchStructure && structureNodeCodeValue) {
            WSRWManagement.getTopParentChildren(structureNodeCodeValue)
                .then((response) => {
                    const equipments = Object.assign({}, ...response.body.data);
                    setStructure(equipments);
                    grid.setFilter(
                        {
                            fieldName: 'obj_code',
                            fieldValue: Object.keys(equipments).join(','),
                            operator: 'IN',
                            joiner: 'AND',
                        },
                        'VARCHAR'
                    );
                })
                .catch(handleError);
        }
    };

    const returnProps = {
        ...props,
        setLoading: setProcessing,
        propertyToUpdate,
        setPropertyToUpdate,
        selectedEquipment,
        setWasteEquipmentDetail,
        updateWasteEquipment,
    };

    const gridId = useMemo(() => applicationData.radioactiveWasteGridID, []);

    return (
        <div ref={node} style={{ height: '100%', width: '100%', position: 'relative' }}>
            <BlockUi tag="div" blocking={processing} className={gridNoOverflowClasses.outerBlock}>
                <SearchPanel title={translations.SEARCH_CRITERIA} onPanelChange={onPanelChange}>
                    <>
                        {/* Waste Location */}
                        <Grid container spacing={8}>
                            <Grid item xl={4} md={4} sm={6} xs={12}>
                                <EAMLookup
                                    right={1}
                                    top={13}
                                    width={26}
                                    height={26}
                                    gridId={applicationData.wasteLocationGridID}
                                    keys={{
                                        code: 'obj_code',
                                        mapCodeTo: 'obj_location',
                                    }}
                                    updateProperty={(key, value) => updateProperty(key, value, 'BEGINS', 'VARCHAR')}
                                    value={filters.obj_location}
                                >
                                    <EAMSelect
                                        elementInfo={screenData[lang].assetFields.wasteLocation}
                                        valueKey={CUTG10_FIELDS.OBJ_LOCATION}
                                        value={filters.obj_location}
                                        values={applicationData.wasteLocations || []}
                                        updateProperty={(key, value) => updateProperty(key, value, 'EQUALS', 'VARCHAR')}
                                        labelStyle={labelStyleRight}
                                        creatable
                                        arrowRenderer={() => <span />}
                                    />
                                </EAMLookup>
                            </Grid>
                            <Grid item xl={2} md={2} sm={6} xs={6}>
                                <EAMSelect
                                    elementInfo={screenData[lang].assetFields.wasteZone}
                                    valueKey={CUTG10_FIELDS.RW_WASTE_ZONE}
                                    value={filters[CUTG10_FIELDS.RW_WASTE_ZONE]}
                                    values={applicationData.wasteZones[filters.obj_location] || []}
                                    updateProperty={(key, value) => updateProperty(key, value, 'BEGINS', 'VARCHAR')}
                                    labelStyle={labelStyleRight}
                                />
                            </Grid>
                            <Grid item xl={2} md={2} sm={6} xs={6}>
                                <EAMSelect
                                    elementInfo={screenData[lang].assetFields.wasteSubzone}
                                    valueKey={CUTG10_FIELDS.RW_WASTE_SUBZONE}
                                    value={filters[CUTG10_FIELDS.RW_WASTE_SUBZONE]}
                                    values={
                                        applicationData.rwSubzoneMap[filters.obj_location]?.[
                                            filters[CUTG10_FIELDS.RW_WASTE_ZONE]
                                        ] || []
                                    }
                                    updateProperty={(key, value) => updateProperty(key, value, 'EQUALS', 'VARCHAR')}
                                    labelStyle={labelStyleRight}
                                />
                            </Grid>
                            <Grid item xl={4} md={4} sm={6} xs={12}>
                                <EAMSelect
                                    elementInfo={screenData[lang].customFieldsWaste[constants.cfpWmWasteFamily]}
                                    valueKey={CUTG10_FIELDS.CF_RW_FAMILY}
                                    value={filters[CUTG10_FIELDS.CF_RW_FAMILY]}
                                    values={Tools.getCustomFieldsCodeDesc(
                                        customFieldsWasteDef[constants.cfpWmWasteFamily]
                                    )}
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) =>
                                        updateProperty(key, value, 'EQUALS', 'VARCHAR', true)
                                    }
                                />
                            </Grid>
                        </Grid>

                        {/* Equipment & serial number */}
                        <Grid container spacing={8}>
                            <Grid item md={searchStructure ? 4 : 6} sm={12} xs={12}>
                                <EAMAutocomplete
                                    elementInfo={screenData[lang].assetFields.equipmentno}
                                    value={filters.obj_code}
                                    valueKey={CUTG10_FIELDS.OBJ_CODE}
                                    multi
                                    creatable
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) => updateProperty(key, value, 'BEGINS', 'VARCHAR')}
                                    loadOptions={WSAutocomplete.autocompleteWasteEquipment}
                                />
                            </Grid>

                            <Grid item md={searchStructure ? 4 : 6} sm={12} xs={12}>
                                <EAMInput
                                    elementInfo={screenData[lang].assetFields.serialnumber}
                                    value={filters.obj_serialno}
                                    valueKey={CUTG10_FIELDS.OBJ_SERIALNO}
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) => updateProperty(key, value, 'BEGINS', 'VARCHAR')}
                                />
                            </Grid>

                            {searchStructure && (
                                <Grid item md={4} sm={12} xs={12}>
                                    <EAMAutocomplete
                                        label={screenData[lang].translations.TREC_RWMANAGEMENT.STRUCTUREMEMBER}
                                        value={structureNodeCode}
                                        labelStyle={labelStyleRight}
                                        creatable
                                        updateProperty={(key, value) => getStructureContent(value)}
                                        loadOptions={WSAutocomplete.autocompleteWasteEquipment}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </>
                    <SearchPanelControls>
                        <TRECButton
                            color="default"
                            onClick={() => {
                                history.push('/menu');
                            }}
                        >
                            <MenuIcon />
                            {translations.GOTOMENU}
                        </TRECButton>

                        <TRECButton
                            onClick={() => {
                                runSearch();
                                setPropertyToUpdate(undefined);
                            }}
                        >
                            <SearchIcon />
                            {translations.SEARCH}
                        </TRECButton>
                        <TRECButton
                            onClick={() => {
                                selectAll
                                    ? setSelectedEquipment({})
                                    : setSelectedEquipment(
                                          rows
                                              .map(getRowAsAnObject)
                                              .filter((obj) => !obj[CUTG10_FIELDS.OBJ_PARENT])
                                              .map((row) => ({ [row[CUTG10_FIELDS.OBJ_CODE]]: row }))
                                              .reduce((acc, el) => ({ ...acc, ...el }), {})
                                      );
                                setSelectAll(!selectAll);
                            }}
                        >
                            {getTranslation(selectAll ? 'UNSELECTALL' : 'SELECTALL')}
                            <Checkbox style={{ padding: 0 }} checked={selectAll} />
                        </TRECButton>

                        <TRECButton
                            onClick={() => {
                                onClearClick();
                                setPropertyToUpdate(undefined);
                                setStructureNodeCode('');
                            }}
                        >
                            <FilterRemoveOutline />
                            {translations.RESETVALUES}
                        </TRECButton>

                        <FormControlLabel
                            labelPlacement="start"
                            // style={{marginLeft: "0px"}}
                            label={
                                <Typography
                                    style={{ ...labelStyleRight, color: 'rgb(0, 101, 152)', fontWeight: 'bold' }}
                                >
                                    {screenData[lang].translations.TREC_RWMANAGEMENT.SEARCHSTRUCTURE}
                                </Typography>
                            }
                            control={<Switch checked={searchStructure} onChange={onSearchSwitchChange} />}
                        />

                        {Object.keys(selectedEquipment).length > 0 && (
                            <>
                                <RWManagementControls {...returnProps} />
                                <RWManagementElimination
                                    rwpDropdowns={rwpDropdowns}
                                    wasteLocations={applicationData.wasteLocations}
                                    assetFields={assetFields}
                                    applicationData={applicationData}
                                    translations={translations}
                                    getTranslation={getTranslation}
                                    history={history}
                                    constants={constants}
                                    selectedEquipment={Object.keys(selectedEquipment)}
                                />
                            </>
                        )}
                    </SearchPanelControls>
                </SearchPanel>

                {/* For the display detail */}
                {wasteEquipmentDetail && <RWManagementDetail {...returnProps} equipmentCode={wasteEquipmentDetail} />}

                {/* When Updating property */}
                {propertyToUpdate && (
                    <RWManagementUpdate
                        {...returnProps}
                        cancel={() => setPropertyToUpdate(undefined)}
                        formFields={formFields}
                        runSearch={runSearch}
                        valuesGrid={valuesGrid}
                    />
                )}

                {/* When not updating property, render grid */}
                {
                    //! this.state.propertyToUpdate &&
                    <EAMGridNoOverflow
                        gridId={gridId}
                        gridRequestAdapter={gridRequestAdapter}
                        extraColumns={extraColumns}
                        cellRenderer={cellRenderer({
                            rwpDropdowns,
                            selectedEquipment,
                            setSelectedEquipment,
                            setWasteEquipmentDetail,
                            structure,
                        })}
                        onLoad={(gridRows) => {
                            setRows(gridRows);
                        }}
                        onRef={(ref) => assignRef(ref)}
                        autorun={autorun}
                        showDataspySelection
                        hiddenTags={hiddenColumns}
                        translations={gridTranslations}
                        heightFilterVisible="290px"
                        heightFilterNotVisible="240px"
                        bypassAlternateRows
                    />
                }
            </BlockUi>
        </div>
    );
};

class RWManagement extends TRECSearchPage {
    renderPage(writeAccess) {
        const { filters } = this.state;

        return (
            <RWManagementFC
                assignRef={(ref) => {
                    this.grid = ref;
                }}
                checkAccess={() => this.checkAccess()}
                filters={filters}
                formFields={this.formFields}
                grid={this.grid}
                onClearClick={this.onClearClick}
                onKeyDown={this.onKeyDown}
                onPanelChange={this._onPanelChange}
                onSearchClick={this.onSearchClick}
                refreshPageFiltersFromGridFilters={this.refreshPageFiltersFromGridFilters}
                thisParentRef={this}
                updateProperty={this.updateProperty}
                writeAccess={writeAccess}
                {...this.props}
            />
        );
    }
}

export default RWManagement;
