import React from 'react';
import Grid from '@material-ui/core/Grid';

// For the search screens
function SearchPanelControls(props) {
    const { children } = props;
    return (
        <Grid item xs={12}>
            <div style={{ float: 'left', marginTop: '10px' }}>{children}</div>
        </Grid>
    );
}

export default SearchPanelControls;
