import React from 'react';
import JobsReportResultLine from './JobsReportResultLine';

const JobsReportResults = (props) => {
    const headerStyle = {
        padding: 10,
        paddingBottom: 15,
        paddingTop: 15,
    };

    const { results, translations } = props;

    return (
        <>
            <div style={headerStyle}>
                <strong>{translations.EQP_AND_RP_MEASUREMENTS}</strong>
            </div>
            <JobsReportResultLine text={translations.RP_JOBS_CREATED} value={results[0]} />
            <JobsReportResultLine text={translations.RP_JOBS_CREATED_BZ} value={results[1]} />
            <JobsReportResultLine text={translations.RP_JOBS_ALREADY_CLOSED} value={results[2]} />
            <JobsReportResultLine text={translations.EQP_WAITING_FOR_FETCH} value={results[3]} />
            <JobsReportResultLine
                text={`${translations.EDH_TRANSPORT_REQUESTS} ${translations.EDH_GENERATED}`}
                value={results[4]}
            />
            <JobsReportResultLine
                text={`${translations.EDH_STORAGE_REQUESTS} ${translations.EDH_GENERATED}`}
                value={results[5]}
            />
            <JobsReportResultLine
                text={`${translations.EDH_SHIPPING_REQUESTS} ${translations.EDH_GENERATED}`}
                value={results[6]}
            />
            <div style={headerStyle}>
                <strong>{translations.ALL_MATERIAL}</strong>
            </div>
            <JobsReportResultLine text={translations.TOTAL_VOLUME} value={results[7]} />
            <JobsReportResultLine text={translations.TOTAL_WEIGHT} value={results[8]} />
            <div style={headerStyle}>
                <strong>{translations.RADIOACTIVE_MATERIAL}</strong>
            </div>
            <JobsReportResultLine text={translations.TOTAL_VOLUME} value={results[9]} />
            <JobsReportResultLine text={translations.TOTAL_WEIGHT} value={results[10]} />
            <div style={headerStyle}>
                <strong>{translations.RADIOACTIVE_WASTE}</strong>
            </div>
            <JobsReportResultLine text={translations.TOTAL_VOLUME} value={results[11]} />
            <JobsReportResultLine text={translations.TOTAL_WEIGHT} value={results[12]} />
        </>
    );
};

export default JobsReportResults;
