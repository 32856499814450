import React from 'react';
import { Clear } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    closeButtonStyle: {
        textAlign: 'right',
        margin: 1,
        height: 25,
        width: 25,
        float: 'right',
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        right: 0,
        color: 'white',
    },
    headerStyle: {
        height: 25,
        textAlign: 'left',
        overflow: 'hidden',
        backgroundColor: theme.palette.primary.main,
        position: 'relative',
    },
    headerTextStyle: {
        margin: '0px auto',
        lineHeight: '25px',
        textAlign: 'center',
        marginLeft: '5px',
        paddingRight: '20px',
        fontSize: 18,
        color: '#fafafa',
    },
});

function TRECHelpHeader(props) {
    const { classes, closeHelp, masterPageDescription, pageDescription } = props;
    const pageNewDescription =
        pageDescription && pageDescription !== masterPageDescription ? ` (${pageDescription})` : '';

    const description = masterPageDescription + pageNewDescription;

    return (
        <div className={classes.headerStyle}>
            <span className={classes.headerTextStyle}>TREC Help - {description}</span>
            <div className={classes.closeButtonStyle} onClick={closeHelp} onKeyDown={closeHelp}>
                <Clear />
            </div>
        </div>
    );
}

export default withStyles(styles)(TRECHelpHeader);
