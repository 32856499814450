import React, { useState } from 'react';

export const useStageProgress = () => {
    const [stages, setStages] = useState({});
    const [inProgress, setInProgress] = useState(false);

    const updateStages = ({ stageList, stageData }) =>
        setStages((prevStages) =>
            stageList.reduce(
                (acc, stageID) => ({
                    ...acc,
                    [stageID]: {
                        ...prevStages[stageID],
                        ...stageData,
                    },
                }),
                prevStages
            )
        );

    const updateStage = ({ id, stageData }) => updateStages({ stageList: [id], stageData });
    const beginStageProgress = () => setInProgress(true);
    const endStageProgress = () => setInProgress(false);

    return {
        stages,
        setStages,
        updateStage,
        updateStages,
        isInStageProgress: inProgress,
        beginStageProgress,
        endStageProgress,
    };
};

export const withStageProgress = (Component) => (props) =>
    <Component stageProgressProps={useStageProgress()} {...props} />;
