import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { TREC_GENERAL_KEYS } from '../../../../enums/Constants';

const RWPConfirmationDialog = (props) => {
    const { open, onClose, message, onConfirm, getTranslation } = props;
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <DialogContentText>{getTranslation(message)}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {getTranslation(TREC_GENERAL_KEYS.CANCEL)}
                </Button>
                <Button
                    onClick={() => {
                        onClose();
                        onConfirm();
                    }}
                    color="primary"
                    variant="contained"
                >
                    {getTranslation(TREC_GENERAL_KEYS.CONFIRM)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RWPConfirmationDialog;
