import { Grid, withStyles } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import CommentsContainer from 'eam-components/dist/ui/components/comments/CommentsContainer';
import { NonRadioactiveWarningIcon, RadioactiveWarningIcon } from 'eam-components/dist/ui/components/icons';
import EAMDateTimePicker from 'eam-components/dist/ui/components/inputs/EAMDateTimePicker';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import { RP_MEASUREMENT_KEYS } from 'enums/Constants';
import React from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import WSComments from 'tools/rest/WSComments';
import Tools from 'tools/Tools';
import { EQUIPMENT_KEYS, EQUIPMENT_RPM_KEYS, RPMR_TRANSLATION_KEYS } from '../RPMRConstants';
import { showError, showWarning } from 'tools/TrecNotifications';

const labelStyle = {
    width: 170,
    minWidth: 170,
};

const styles = () => ({
    iconStyle: {
        '& svg': {
            fontSize: '60px',
            position: 'relative',
            left: 'calc(50% - 30px)',
        },
    },
});

const RPM_STATUS = {
    PERFORMED: 'PERFORMED',
    MISSING: 'MISSING',
    PENDING: 'PENDING',
};

class RPMRResultsStep extends WizardStep {
    UNSAFE_componentWillMount() {
        const { storeActions } = this.props;
        storeActions.setPage('TREC_REQRPMEAS', 'STEP_RESULTS');
        this.checkMissingRPMs();
        this.checkPendingRPMs();
    }

    canContinue = () => {
        this.checkMissingRPMs();
        const rpmStatusMap = this.getRPMStatusMap();
        return !rpmStatusMap[RPM_STATUS.MISSING].length;
    };

    saveChanges = () => true;

    commitChanges = (callback) => callback();

    checkMissingRPMs = () => {
        const { translations } = this.props;
        const rpmStatusMap = this.getRPMStatusMap();
        const missingRPMEquipment = rpmStatusMap[RPM_STATUS.MISSING];
        missingRPMEquipment.length &&
            showError(
                missingRPMEquipment.map((eq) => eq[EQUIPMENT_KEYS.CODE]).join(', '),
                translations.NOMEASPERFORM,
                null
            );
    };

    checkPendingRPMs = () => {
        const { translations } = this.props;
        const rpmStatusMap = this.getRPMStatusMap();
        const pendingRPMEquipment = rpmStatusMap[RPM_STATUS.PENDING];
        pendingRPMEquipment.length &&
            showWarning(
                pendingRPMEquipment.map((eq) => eq[EQUIPMENT_KEYS.CODE]).join(', '),
                translations.LASTRPMNOTDONE,
                null
            );
    };

    getRPMStatusMap = () => {
        const { rpmrGetters } = this.props;
        const equipmentMap = rpmrGetters.getEquipmentMap();
        const hasLastRPMPending = (equipment) =>
            equipment[EQUIPMENT_RPM_KEYS.LAST_CLOSED_RPM_CODE] !== equipment[EQUIPMENT_RPM_KEYS.LAST_RPM_CODE];
        const hasRPMMissing = (equipment) => !equipment[EQUIPMENT_RPM_KEYS.LAST_CLOSED_RPM_CODE];
        return Object.values(equipmentMap).reduce(
            (acc, equipment) => {
                const rpmStatus = hasRPMMissing(equipment)
                    ? RPM_STATUS.MISSING
                    : hasLastRPMPending(equipment)
                    ? RPM_STATUS.PENDING
                    : RPM_STATUS.PERFORMED;
                return {
                    ...acc,
                    [rpmStatus]: [...acc[rpmStatus], equipment],
                };
            },
            Object.keys(RPM_STATUS).reduce((acc, status) => ({ ...acc, [status]: [] }), {})
        );
    };

    renderRPClassificationIcon = (rpClassification) => {
        const { constants } = this.props;
        return rpClassification === constants.radioactive ? <RadioactiveWarningIcon /> : <NonRadioactiveWarningIcon />;
    };

    renderResultsTable = () => {
        const { rpmrGetters, screenData, constants, getTranslation, lang } = this.props;
        const equipmentMap = rpmrGetters.getEquipmentMap();
        const { assetFields, woFields, customFields, customFieldsDef } = screenData;
        const { RESULTS_TABLE_HEADER } = RPMR_TRANSLATION_KEYS;

        const tableProps = {
            headers: [
                assetFields.equipmentno.text,
                `Eq. ${customFields[constants.cfpRadiationClassification].text}`,
                woFields.workordernum.text,
                customFields[constants.cfpFutureAction].text,
                woFields.datecompleted.text,
                `RPM ${customFields[constants.cfpRadiationClassification].text}`,
            ],
            propCodes: [
                EQUIPMENT_KEYS.CODE,
                EQUIPMENT_RPM_KEYS.RP_CLASSIFICATION,
                EQUIPMENT_RPM_KEYS.LAST_CLOSED_RPM_CODE,
                EQUIPMENT_RPM_KEYS.FUTURE_ACTION,
                EQUIPMENT_RPM_KEYS.DATE_COMPLETED,
                RP_MEASUREMENT_KEYS.RP_CLASSIFICATION_CPY,
            ],
            data: Object.values(equipmentMap).map((equipment) => ({
                ...equipment,
                [EQUIPMENT_RPM_KEYS.DATE_COMPLETED]: (
                    <EAMDateTimePicker
                        elementInfo={{ ...woFields.datecompleted, readonly: true, text: '' }}
                        value={equipment[EQUIPMENT_RPM_KEYS.DATE_COMPLETED]}
                        labelStyle={labelStyle}
                        localeString={lang}
                    />
                ),
                [EQUIPMENT_RPM_KEYS.FUTURE_ACTION]: Tools.getCustomFieldValueDesc(
                    customFieldsDef[constants.cfpFutureAction],
                    equipment[EQUIPMENT_RPM_KEYS.FUTURE_ACTION]
                ),
                [EQUIPMENT_RPM_KEYS.RP_CLASSIFICATION]: this.renderRPClassificationIcon(
                    equipment[RP_MEASUREMENT_KEYS.RP_CLASSIFICATION]
                ),
                [RP_MEASUREMENT_KEYS.RP_CLASSIFICATION_CPY]: this.renderRPClassificationIcon(
                    equipment[RP_MEASUREMENT_KEYS.RP_CLASSIFICATION_CPY]
                ),
            })),
        };
        return (
            <EISPanel heading={getTranslation(RESULTS_TABLE_HEADER)}>
                <div style={{ width: '100%', marginTop: 0 }}>
                    <EISTable {...tableProps} maxMobileSize={600} />
                </div>
            </EISPanel>
        );
    };

    renderSingleResult = () => {
        const {
            rpmrGetters,
            getTranslation,
            screenData,
            constants,
            customFields,
            customFieldsDef,
            userData,
            classes,
            lang,
        } = this.props;
        const { woFields, assetFields } = screenData;
        const equipmentMap = rpmrGetters.getEquipmentMap();
        const equipment = Object.values(equipmentMap)[0];
        const { RESULTS_GENERAL_DATA_HEADER, RESULTS_COMMENTS_HEADER } = RPMR_TRANSLATION_KEYS;
        return (
            <>
                <Grid item md={6} sm={12} xs={12}>
                    <EISPanel heading={getTranslation(RESULTS_GENERAL_DATA_HEADER)}>
                        <div style={{ width: '100%', marginTop: 0 }}>
                            <div className={classes.iconStyle}>
                                {this.renderRPClassificationIcon(equipment[RP_MEASUREMENT_KEYS.RP_CLASSIFICATION])}
                            </div>
                            <EAMInput
                                elementInfo={{ ...woFields.workordernum, readonly: true }}
                                value={equipment[EQUIPMENT_RPM_KEYS.LAST_CLOSED_RPM_CODE]}
                                labelStyle={labelStyle}
                            />
                            <EAMInput
                                elementInfo={{ ...assetFields.equipmentno, readonly: true }}
                                value={[equipment[EQUIPMENT_KEYS.CODE], equipment[EQUIPMENT_KEYS.DESCRIPTION]]
                                    .filter(Boolean)
                                    .join(' - ')}
                                labelStyle={labelStyle}
                            />
                            <EAMDateTimePicker
                                elementInfo={{ ...woFields.datecompleted, readonly: true }}
                                value={equipment[EQUIPMENT_RPM_KEYS.DATE_COMPLETED]}
                                labelStyle={labelStyle}
                                localeString={lang}
                            />
                            <EAMSelect
                                elementInfo={{ ...customFields[constants.cfpFutureAction], readonly: true }}
                                value={equipment[EQUIPMENT_RPM_KEYS.FUTURE_ACTION]}
                                values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpFutureAction], true)}
                                labelStyle={labelStyle}
                            />
                            <EAMSelect
                                elementInfo={{ ...customFields[constants.cfpAssociatedRisks], readonly: true }}
                                value={equipment[EQUIPMENT_RPM_KEYS.ASSOC_RISK]}
                                values={Tools.getCustomFieldsCodeDesc(
                                    customFieldsDef[constants.cfpAssociatedRisks],
                                    true
                                )}
                                labelStyle={labelStyle}
                            />
                            <EAMSelect
                                elementInfo={{ ...customFields[constants.cfpEdhDocument], readonly: true }}
                                value={equipment[EQUIPMENT_RPM_KEYS.EDH]}
                                values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpEdhDocument])}
                                labelStyle={labelStyle}
                            />
                        </div>
                    </EISPanel>
                    <CommentsContainer
                        title={getTranslation(RESULTS_COMMENTS_HEADER)}
                        ref={(comments) => {
                            this.comments = comments;
                        }}
                        entityCode="EVNT"
                        entityKeyCode={equipment[EQUIPMENT_RPM_KEYS.LAST_CLOSED_RPM_CODE]}
                        userDesc={userData.eamAccount.userDesc}
                        readComments={WSComments.readComments}
                    />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    {this.renderResultsTable()}
                </Grid>
            </>
        );
    };

    renderMultipleResults = () => (
        <Grid item md={8} sm={12} xs={12}>
            {this.renderResultsTable()}
        </Grid>
    );

    render() {
        const { rpmrGetters } = this.props;
        return (
            <div style={{ margin: 8 }}>
                <Grid container spacing={8}>
                    {rpmrGetters.isMultipleEquipment() ? this.renderMultipleResults() : this.renderSingleResult()}
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(RPMRResultsStep);
