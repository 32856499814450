import { toast } from 'react-toastify';
import { ERROR_TYPES } from '../enums/Defaults';

export const showError = (message, title, autoClose) => {
    toast.dismiss();
    toast(
        <span style={{ display: 'flex', flexDirection: 'column' }}>
            <b>{title}</b>
            <span>{message}</span>
        </span>,
        { type: 'error', autoClose: autoClose ?? 4000, delay: 0 }
    );
    toast.clearWaitingQueue();
};

export const showWarning = (message, title, autoClose) => {
    toast.dismiss();
    toast(
        <span style={{ display: 'flex', flexDirection: 'column' }}>
            <b>{title}</b>
            <span>{message}</span>
        </span>,
        { type: 'warning', autoClose: autoClose ?? 4000 }
    );
};

export const showSuccess = (message, title, autoClose) => {
    toast.dismiss();
    toast(
        <span style={{ display: 'flex', flexDirection: 'column' }}>
            <b>{title}</b>
            <span>{message}</span>
        </span>,
        { type: 'success', autoClose: autoClose ?? 4000 }
    );
};

export const handleError = (error) => {
    toast.dismiss();
    switch (error?.type) {
        case ERROR_TYPES.CONNECTION_ABORDED:
            toast(
                'The server did not respond in a timely fashion. Please try again or check your internet connection.',
                { type: 'error' }
            );
            break;
        case ERROR_TYPES.NETWORK_ERROR:
            toast('The server could not be reached.', { type: 'error' });
            break;
        case ERROR_TYPES.SERVER_ERROR:
            if (error.response?.body) {
                const { errors } = error.response.body;
                if (errors?.length > 0) {
                    toast(
                        <span style={{ display: 'flex', flexDirection: 'column' }}>
                            <b>{errors[0].detail}</b>
                            <span>{errors[0].title}</span>
                        </span>,
                        { type: 'error', autoClose: false }
                    );
                } else {
                    toast(error.response.body, {
                        type: 'error',
                        autoClose: false,
                    });
                }
            }
            break;
        default:
            break;
    }
};
