import { Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import { TABLE_DATA_TYPES } from 'enums/Constants';
import React from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import Tools from 'tools/Tools';
import { isContainer } from 'ui/pages/rwprocessing/RWProcessingTools';
import { WarningText } from 'ui/pages/wrrequest/WRRHelpers';
import InputGenerator from '../InputGenerator';
import { EQUIPMENT_KEYS, REQUEST_KEYS } from '../RPMRConstants';
import { showError } from 'tools/TrecNotifications';

const { ATTRIBUTES } = InputGenerator;

const requestFields = ({
    applicationData,
    screenData,
    constants,
    properties,
    translations,
    equipmentMap,
    getTranslation,
}) => {
    const { customFields, customFieldsDef, lists } = screenData;
    const action = properties[REQUEST_KEYS.ACTION];

    const getEDHDocumentValues = (cf) => {
        switch (action) {
            case constants.futureActionWasteDeclare:
            case constants.futureActionWasteReceive:
                return [constants.cfvEdhDocumentTransport, constants.cfvEdhDocumentStockage];
            case constants.futureActionMoveatcern:
            case constants.futureActionVacuumMove:
                return [constants.cfvEdhDocumentTransport];
            case constants.futureActionStock:
                return [constants.cfvEdhDocumentStockage];
            case constants.futureActionDispatch:
                return [constants.cfvEdhDocumentShipping];
            default:
                return cf.pcode;
        }
    };

    const getStorageOperationValues = (cf) => {
        switch (action) {
            case constants.futureActionDecwaste: // checkr: remove futureActionDecwaste
            case constants.futureActionWasteDeclare: // checkr: can we remove either waste declare or waste receive?
            case constants.futureActionWasteReceive:
                return [constants.cfvNatureOutbound];
            default:
                return cf.pcode;
        }
    };

    const getDestinationFields = () => {
        if (
            [
                constants.futureActionDecwaste, // checkr: remove futureActionDecwaste. Can we remove either waste declare or waste receive?
                constants.futureActionWasteDeclare,
                constants.futureActionWasteReceive,
            ].includes(action)
        ) {
            return [];
        }

        if (properties[REQUEST_KEYS.EDH_DOCUMENT] === constants.cfvEdhDocumentTransport) {
            return [
                {
                    code: REQUEST_KEYS.EQUIPMENT_DESTINATION,
                    type: TABLE_DATA_TYPES.LOOKUP_AUTOCOMPLETE,
                    keysMap: (field) => ({
                        code: 'obj_code',
                        mapCodeTo: field.code,
                    }),
                    elementInfo: {
                        ...customFields[constants.cfpEquipmentDestination],
                        xpath: `${customFields[constants.cfpEquipmentDestination].xpath}_Transport`,
                    },
                    gridId: applicationData.locationRaisinGridID,
                    getAttribute: () => ATTRIBUTES.REQUIRED,
                    autocompleteType: WSAutocomplete.autocompleteTRECLocation,
                },
                {
                    code: REQUEST_KEYS.EQUIPMENT_DESTINATION_RAD,
                    type: TABLE_DATA_TYPES.LOOKUP_AUTOCOMPLETE,
                    keysMap: (field) => ({
                        code: 'obj_code',
                        mapCodeTo: field.code,
                    }),
                    elementInfo: {
                        ...customFields[constants.cfpEquipmentDestinationRad],
                        xpath: `${customFields[constants.cfpEquipmentDestinationRad].xpath}_Transport`,
                    },
                    gridId: applicationData.locationRaisinGridID,
                    getAttribute: () => ATTRIBUTES.REQUIRED,
                    autocompleteType: WSAutocomplete.autocompleteTRECLocation,
                },
            ];
        }
        if (properties[REQUEST_KEYS.EDH_DOCUMENT] === constants.cfvEdhDocumentStockage) {
            if (properties[REQUEST_KEYS.STORAGE_OPERATION] === constants.cfvNatureInbound) {
                return [
                    {
                        code: REQUEST_KEYS.EQUIPMENT_DESTINATION,
                        type: TABLE_DATA_TYPES.SELECT,
                        elementInfo: {
                            ...customFields[constants.cfpEquipmentDestination],
                            xpath: `${customFields[constants.cfpEquipmentDestination].xpath}_Inbound`,
                        },
                        getAttribute: () => ATTRIBUTES.REQUIRED,
                        values: Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpStoreDestination]),
                    },
                    {
                        code: REQUEST_KEYS.EQUIPMENT_DESTINATION_RAD,
                        type: TABLE_DATA_TYPES.SELECT,
                        elementInfo: {
                            ...customFields[constants.cfpEquipmentDestinationRad],
                            xpath: `${customFields[constants.cfpEquipmentDestinationRad].xpath}_Inbound`,
                        },
                        getAttribute: () => ATTRIBUTES.REQUIRED,
                        values: Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpStoreDestination]),
                    },
                ];
            }
            return [
                {
                    code: REQUEST_KEYS.EQUIPMENT_DESTINATION,
                    type: TABLE_DATA_TYPES.LOOKUP_AUTOCOMPLETE,
                    keysMap: (field) => ({
                        code: 'obj_code',
                        mapCodeTo: field.code,
                    }),
                    elementInfo: {
                        ...customFields[constants.cfpEquipmentDestination],
                        xpath: `${customFields[constants.cfpEquipmentDestination].xpath}_Outbound`,
                    },
                    gridId: applicationData.locationRaisinGridID,
                    getAttribute: () => ATTRIBUTES.REQUIRED,
                    autocompleteType: WSAutocomplete.autocompleteTRECLocation,
                },
                {
                    code: REQUEST_KEYS.EQUIPMENT_DESTINATION_RAD,
                    type: TABLE_DATA_TYPES.LOOKUP_AUTOCOMPLETE,
                    keysMap: (field) => ({
                        code: 'obj_code',
                        mapCodeTo: field.code,
                    }),
                    elementInfo: {
                        ...customFields[constants.cfpEquipmentDestinationRad],
                        xpath: `${customFields[constants.cfpEquipmentDestinationRad].xpath}_Outbound`,
                    },
                    gridId: applicationData.locationRaisinGridID,
                    getAttribute: () => ATTRIBUTES.REQUIRED,
                    autocompleteType: WSAutocomplete.autocompleteTRECLocation,
                },
            ];
        }
        return [];
    };

    const isEquipmentInStore = Object.values(equipmentMap).some(
        (equipment) => equipment[EQUIPMENT_KEYS.SYSTEM_STATUS] === 'C'
    );

    // TODO make it a variable
    const edhOptions = isEquipmentInStore
        ? []
        : Tools.getCustomFieldsCodeDesc(
              Tools.filterCustomFieldValues(
                  customFieldsDef[constants.cfpEdhDocument],
                  getEDHDocumentValues(customFieldsDef[constants.cfpEdhDocument])
              ),
              true
          );

    return [
        {
            code: REQUEST_KEYS.ACTION,
            type: TABLE_DATA_TYPES.INPUT,
            elementInfo: customFields[constants.cfpFutureAction],
            getAttribute: () => ATTRIBUTES.READONLY,
        },
        {
            code: REQUEST_KEYS.DEPARTMENT,
            type: TABLE_DATA_TYPES.SELECT,
            elementInfo: screenData.woFields.department,
            getAttribute: () => ATTRIBUTES.HIDDEN, // EAM-741
            // getAttribute: () => (userData.eamAccount.departmentalSecurity[applicationData.rwsection]
            //         && !userData.eamAccount.departmentalSecurity[applicationData.rwsection].readOnly
            //         && [constants.futureActionDecwaste, constants.futureActionOther].includes(action)) ? ATTRIBUTES.REQUIRED : ATTRIBUTES.HIDDEN,
            values: lists.departments,
        },
        {
            code: REQUEST_KEYS.REMOVE_ASSET_CLASS,
            type: TABLE_DATA_TYPES.CHECKBOX,
            elementInfo: {
                ...customFields[constants.cfpRemoveAssetClass],
                text: getTranslation(REQUEST_KEYS.REMOVE_ASSET_CLASS),
            },
            getAttribute: () => {
                const equipment = Object.values(equipmentMap);
                return equipment.every(isContainer) && action === constants.futureActionWasteReceive
                    ? ATTRIBUTES.OPTIONAL
                    : ATTRIBUTES.HIDDEN;
            },
            customInputProps: () => ({
                style: { maxWidth: 80 },
                trueValue: true,
                falseValue: false,
            }),
        },
        ...(!applicationData.isHazardsFeatureActive
            ? [
                  {
                      code: REQUEST_KEYS.ASSOCIATED_RISK,
                      type: TABLE_DATA_TYPES.SELECT,
                      elementInfo: customFields[constants.cfpAssociatedRisks],
                      getAttribute: () => ATTRIBUTES.REQUIRED,
                      values: Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpAssociatedRisks], true),
                  },
              ]
            : []),
        {
            code: REQUEST_KEYS.EDH_DOCUMENT,
            type: TABLE_DATA_TYPES.SELECT,
            elementInfo: customFields[constants.cfpEdhDocument],
            getAttribute: () =>
                action === constants.futureActionDispatch
                    ? ATTRIBUTES.REQUIRED
                    : [
                          constants.futureActionVacuumDeclare,
                          constants.futureActionVacuumEmpty,
                          constants.futureActionVacuumEnd,
                          constants.futureActionVacuumEndFull,
                      ].includes(action)
                    ? ATTRIBUTES.HIDDEN
                    : ATTRIBUTES.OPTIONAL,
            values: edhOptions,
            customInputProps: () => ({
                placeholder: translations.NOEDHDOC,
            }),
        },
        {
            code: REQUEST_KEYS.TYPE_OF_TRANSPORT,
            type: TABLE_DATA_TYPES.SELECT,
            elementInfo: customFields[constants.cfpTypeOfTransport],
            getAttribute: () =>
                properties[REQUEST_KEYS.EDH_DOCUMENT] === constants.cfvEdhDocumentTransport
                    ? ATTRIBUTES.REQUIRED
                    : ATTRIBUTES.HIDDEN,
            values: Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpTypeOfTransport], true),
        },
        {
            code: REQUEST_KEYS.STORAGE_OPERATION,
            type: TABLE_DATA_TYPES.SELECT,
            elementInfo: customFields[constants.cfpStorageOperation],
            getAttribute: () =>
                properties[REQUEST_KEYS.EDH_DOCUMENT] === constants.cfvEdhDocumentStockage
                    ? ATTRIBUTES.REQUIRED
                    : ATTRIBUTES.HIDDEN,
            values: Tools.getCustomFieldsCodeDesc(
                Tools.filterCustomFieldValues(
                    customFieldsDef[constants.cfpStorageOperation],
                    getStorageOperationValues(customFieldsDef[constants.cfpStorageOperation])
                ),
                true
            ),
        },
        {
            code: REQUEST_KEYS.TRANSPORT_REQUIRED,
            type: TABLE_DATA_TYPES.SELECT,
            elementInfo: customFields[constants.cfpTransportRequired],
            getAttribute: () =>
                properties[REQUEST_KEYS.EDH_DOCUMENT] === constants.cfvEdhDocumentStockage
                    ? ATTRIBUTES.REQUIRED
                    : ATTRIBUTES.HIDDEN,
            values: Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpTransportRequired]),
        },
        ...getDestinationFields(),
        {
            code: REQUEST_KEYS.MEASUREMENT_DEADLINE,
            type: TABLE_DATA_TYPES.DATE,
            elementInfo: screenData.woFields.schedstartdate,
            customInputProps: () => ({
                disablePast: true,
            }),
        },
        {
            code: REQUEST_KEYS.MEASUREMENT_COMMENTS,
            type: TABLE_DATA_TYPES.INPUT,
            elementInfo: screenData.woFields.measurementComments,
        },
    ];
};

export const RequestDetails = (props) => {
    const {
        userData,
        applicationData,
        screenData,
        constants,
        rpmrGetters,
        translations,
        storeActions,
        formFields,
        readOnly,
        fieldsProcessor,
        dropdowns,
        getTranslation,
    } = props;
    const properties = rpmrGetters.getProperties();
    const equipmentMap = rpmrGetters.getEquipmentMap();
    const fields = requestFields({
        userData,
        applicationData,
        screenData,
        constants,
        properties,
        translations,
        equipmentMap,
        dropdowns,
        getTranslation,
    });
    const computedFields = (fieldsProcessor && fieldsProcessor(fields)) || fields;
    return computedFields.reduce(
        (acc, field) => [
            ...acc,
            <div key={field.code}>
                {InputGenerator.generate({
                    field: {
                        ...field,
                        getAttribute: (object) => {
                            const attr = field.getAttribute && field.getAttribute(object);
                            return attr === ATTRIBUTES.HIDDEN
                                ? ATTRIBUTES.HIDDEN
                                : readOnly
                                ? ATTRIBUTES.READONLY
                                : attr;
                        },
                    },
                    object: properties,
                    updateObject: (key, value) => storeActions.updateRPMRequest({ [key]: value }),
                    formFields,
                })}
            </div>,
        ],
        []
    );
};

class RPMRRequestStep extends WizardStep {
    formFields = [];

    UNSAFE_componentWillMount() {
        const { storeActions } = this.props;
        storeActions.setPage('TREC_REQRPMEAS', 'STEP_REQUEST');
    }

    applyRequestDefault = (key, value) => {
        const { storeActions, rpmrGetters } = this.props;
        const properties = rpmrGetters.getProperties();
        properties[key] === undefined && storeActions.updateRPMRequest({ [key]: value });
    };

    canContinue = () => {
        const { rpmrGetters, constants, customFieldsDef, translations } = this.props;
        const properties = rpmrGetters.getProperties();
        let isValid = true;

        // EAMTREC-223: Check the location (BAAN building) in case of Stock/Destock request, option Outbound
        if (
            properties[REQUEST_KEYS.EDH_DOCUMENT] === constants.cfvEdhDocumentStockage &&
            properties[REQUEST_KEYS.STORAGE_OPERATION] === constants.cfvNatureOutbound &&
            !Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpStoreDestination])
                .map((el) => el.code)
                .includes(properties[REQUEST_KEYS.CURRENT_LOCATION_CODE])
        ) {
            showError(translations.LOCATION_NOT_BAAN);
            return false;
        }

        // TODO BUG FOUND: Changing Storage Operation to nOutboud allows for Next without filling equipment destination
        // Iterate over the required fields and validate them
        Object.keys(this.formFields).forEach((key) => {
            if (this.formFields[key] && this.formFields[key].validate && !this.formFields[key].validate()) {
                isValid = false;
            }
        });

        if (!isValid) {
            showError(translations.REQFIELINV);
        }
        return isValid;
    };

    saveChanges = () => true;

    commitChanges = (callback) => callback();

    render() {
        const { translations, getTranslation, rpmrGetters } = this.props;
        const properties = rpmrGetters.getProperties();

        const removeAssetClass = properties[REQUEST_KEYS.REMOVE_ASSET_CLASS];
        return (
            <div style={{ margin: 8 }}>
                <Grid container spacing={8}>
                    <Grid item md={6} sm={12} xs={12}>
                        <EISPanel
                            heading={translations.DETAILSRPMEAS}
                            detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                            alwaysExpanded
                        >
                            <RequestDetails
                                {...this.props}
                                formFields={this.formFields}
                                fieldsProcessor={(fields) =>
                                    fields.filter((field) => ![REQUEST_KEYS.ACTION].includes(field.code))
                                }
                            />
                            {removeAssetClass && <WarningText text={getTranslation('REMOVE_ASSET_CLASS_WARNING')} />}
                        </EISPanel>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default RPMRRequestStep;
