import React from 'react';
import { Grid, Card, CardMedia, Typography, Divider } from '@material-ui/core';
import { VACUUM_TYPE_KEYS, getVacuumColour } from '../VacCleanerConstants';

const styles = {
    root: {
        marginTop: 35,
    },
    vacuumTile: {
        cursor: 'pointer',
        display: 'flex',
        height: '120px',
        padding: 7,
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
        backgroundColor: '#f6f6f6',
    },
    accessoryTile: {
        height: '200px',
        padding: '10px',
        cursor: 'pointer',
        display: 'flex',
        backgroundColor: '#f6f6f6',
    },
};

const VacuumTile = ({ vacuum, onClick }) => (
    <Card style={{ ...styles.vacuumTile, borderLeftColor: getVacuumColour(vacuum.conditionOfUse) }} onClick={onClick}>
        <Card style={{ flex: 1 }}>
            <img
                src={vacuum.imageUrl}
                alt={vacuum.partCode}
                style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            />
        </Card>
        <div
            style={{
                flex: 2,
                paddingLeft: 10,
                border: '1px solid #dfdfdf',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
                backgroundImage: '',
            }}
        >
            <Typography variant="subtitle1">{vacuum.description}</Typography>
            {/* <Typography color="textSecondary">{vacuum.partCode}</Typography> Removed due to RP Service Request */}
            <Typography color="textSecondary">{vacuum[VACUUM_TYPE_KEYS.CONDITION_OF_USE]}</Typography>
            <Typography color="textSecondary">{vacuum[VACUUM_TYPE_KEYS.CAPACITY]}</Typography>
        </div>
    </Card>
);

const AccessoryTile = ({ accessory, onClick }) => (
    <Card style={{ ...styles.accessoryTile, flexDirection: 'column', minWidth: 0 }} onClick={onClick}>
        <CardMedia image={accessory.imageUrl} style={{ height: '100%', backgroundSize: 'contain' }} />
        <div style={{ flex: 1, marginTop: 3 }}>
            <Divider />
            <Typography variant="subtitle1">{accessory.description}</Typography>
            {/* <Typography color="textSecondary">{accessory[VACUUM_RENTAL_ACCESSORY_KEYS.PART_CODE]}</Typography> Removed due to RP Service Request */}
        </div>
    </Card>
);

const VacuumTiles = ({ options = [], useAccessoryTile, isVacuumSelected, selectOption }) => (
    <div style={styles.root}>
        <Grid container spacing={8}>
            {options.map((opt) =>
                useAccessoryTile ? (
                    <Grid item md={isVacuumSelected ? 3 : 2} xs={4} key={opt.partCode}>
                        <AccessoryTile accessory={opt} onClick={(e) => selectOption(opt, e)} />
                    </Grid>
                ) : (
                    <Grid item md={isVacuumSelected ? 4 : 3} xs={6} key={opt.partCode}>
                        <VacuumTile vacuum={opt} onClick={(e) => selectOption(opt, e)} />
                    </Grid>
                )
            )}
        </Grid>
    </div>
);

export default VacuumTiles;
