import { format } from 'date-fns';
import { AssetIcon } from 'eam-components/dist/ui/components/icons/index';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMDatePicker from 'eam-components/dist/ui/components/inputs/EAMDatePicker';
import EAMDateTimePicker from 'eam-components/dist/ui/components/inputs/EAMDateTimePicker';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMLinkInput from 'eam-components/dist/ui/components/inputs/EAMLinkInput';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EAMLookup from 'eam-components/dist/ui/components/lookup/EAMLookup';
import { Component } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import { ROUTES, withQueryParams } from 'tools/Routes';
import TRECButton from 'ui/components/buttons/TRECButton';
import { WarningText } from 'ui/pages/wrrequest/WRRHelpers';
import { DATE_TIME_FORMAT, FIRSTTIMECLOSED_PROD } from '../../../../enums/Constants';
import WSAutocomplete from '../../../../tools/rest/WSAutocomplete';

class Status extends Component {
    statusChangeHandler = (status) => {
        const { constants, eamAccount, updateProperty, measurement } = this.props;
        const { jobNumber } = measurement;
        // When changing status to 'Done' (T) the executed by and date completed should be filled
        if (measurement.statusCode !== status && status === constants.statusWoT) {
            if (!measurement.executedByCode) {
                updateProperty(`${jobNumber}.executedByCode`, eamAccount.employeeCode);
                updateProperty(`${jobNumber}.executedByDesc`, eamAccount.userDesc);
            }
            if (!measurement.dateCompleted) {
                const newDate = format(new Date(), DATE_TIME_FORMAT.DATE_FNS);
                updateProperty(`${jobNumber}.dateCompleted`, newDate);
            }
            if (measurement.futureAction === constants.futureActionWasteReceive) {
                const path = `${measurement.jobNumber}.equipment[0].weight`;
                updateProperty(path, '');
            }
        }
    };

    getEDHNumberAttribute = () => {
        const { constants, currentMeasurementStatus, measurement } = this.props;
        if (currentMeasurementStatus === constants.statusWoT && +measurement.edhNumber > 0) {
            return 'O';
        }
        return 'H';
    };

    getBasicStatusAttribute = () => {
        const { measurement, constants } = this.props;
        if (measurement.statusCode === constants.statusWoT) {
            return 'R';
        }
        return 'O';
    };

    componentDidUpdate() {
        try {
            const { measurement, readonly, recordRWDate, updateProperty } = this.props;
            const { jobNumber } = measurement;
            const rwDate = measurement.equipment[0].inforEquipment.userDefinedFields.udfdate06;

            if (
                recordRWDate &&
                !measurement.rwReceptionDate &&
                // RQF1721164, RQF1797957
                !readonly
            ) {
                updateProperty(`${jobNumber}.rwReceptionDate`, rwDate || new Date().getTime());
            }
        } catch (err) {
            console.error(err);
        }
    }

    render() {
        const {
            applicationData,
            translations,
            readonly,
            woFields,
            measurement,
            updateProperty,
            lists,
            formFields,
            detailsStyle,
            recordRWDate,
            recordRWDateMessage,
            firstTimeCompleted,
            lang,
            getTranslation,
            showReceptionEmptyContainer,
            history,
            attachVirtualCode,
        } = this.props;
        const { jobNumber } = measurement;
        const rwReceptionDateObject = measurement.equipment[0].inforEquipment.userDefinedFields.udfdate06;

        return (
            <EISPanel detailsStyle={detailsStyle} heading={translations.HEAD_STATUS}>
                <div style={{ width: '100%', marginTop: 0 }}>
                    <EAMSelect
                        elementInfo={{ ...woFields.workorderstatus, readonly, attribute: 'R' }}
                        value={measurement.statusCode}
                        updateProperty={updateProperty}
                        valueKey={`${jobNumber}.statusCode`}
                        values={lists.jobStatuses.filter(
                            (s) => !['TI', 'TX'].includes(s.code) || s.code === measurement.statusCode
                        )}
                        formFields={formFields}
                        onChangeValue={this.statusChangeHandler}
                    />

                    <EAMAutocomplete
                        elementInfo={{
                            ...woFields.reportedby,
                            readonly: true,
                        }}
                        value={measurement.requestedByCode}
                        valueDesc={measurement.requestedByDesc}
                        updateProperty={updateProperty}
                        valueKey={`${jobNumber}.requestedByCode`}
                        descKey={`${jobNumber}.requestedByDesc`}
                        loadOptions={WSAutocomplete.autocompleteEmployee}
                        formFields={formFields}
                    />

                    <EAMInput
                        elementInfo={{
                            ...woFields.updatedOn,
                            readonly: true,
                        }}
                        value={measurement.updatedOn}
                        updateProperty={updateProperty}
                        valueKey={`${jobNumber}.updatedOn`}
                        formFields={formFields}
                    />

                    <EAMAutocomplete
                        elementInfo={{
                            ...woFields.udfchar21,
                            readonly,
                            attribute: this.getBasicStatusAttribute(),
                        }}
                        value={measurement.executedByCode}
                        valueDesc={measurement.executedByDesc}
                        updateProperty={updateProperty}
                        valueKey={`${jobNumber}.executedByCode`}
                        descKey={`${jobNumber}.executedByDesc`}
                        loadOptions={WSAutocomplete.autocompleteEmployee}
                        formFields={formFields}
                    />

                    <EAMLookup
                        right={1}
                        top={13}
                        width={26}
                        height={26}
                        gridId={applicationData.locationRaisinGridID}
                        keys={{
                            code: 'obj_code',
                            mapCodeTo: `${jobNumber}.jobLocationCode`,
                            desc: 'obj_desc',
                            mapDescTo: `${jobNumber}.jobLocationDesc`,
                        }}
                        updateProperty={updateProperty}
                        value={measurement.jobLocationCode}
                        disabled={readonly}
                    >
                        <EAMAutocomplete
                            elementInfo={{ ...woFields.location, readonly, attribute: 'R' }}
                            value={measurement.jobLocationCode}
                            valueDesc={measurement.jobLocationDesc}
                            updateProperty={updateProperty}
                            valueKey={`${jobNumber}.jobLocationCode`}
                            descKey={`${jobNumber}.jobLocationDesc`}
                            loadOptions={WSAutocomplete.autocompleteTRECLocation}
                            formFields={formFields}
                            columnsCodes={['code', 'desc', 'extraAttr1']}
                            columnsWidth={['30%', '30%', '40%']}
                        />
                    </EAMLookup>

                    <EAMDateTimePicker
                        elementInfo={{
                            ...woFields.datecompleted,
                            text: translations.MEASUREMENT_DATE,
                            readonly,
                            attribute: this.getBasicStatusAttribute(),
                        }}
                        value={measurement.dateCompleted}
                        updateProperty={updateProperty}
                        valueKey={`${jobNumber}.dateCompleted`}
                        formFields={formFields}
                        ampm={false}
                        localeString={lang}
                    />

                    {(FIRSTTIMECLOSED_PROD || applicationData.environment !== 'PROD') && (
                        <EAMDateTimePicker
                            elementInfo={{
                                text: 'First Time Closed',
                                readonly: true,
                                attribute: firstTimeCompleted ? 'O' : 'H',
                            }}
                            value={firstTimeCompleted}
                            valueKey={`${jobNumber}.firstTimeCompleted`}
                            ampm={false}
                            localeString={lang}
                        />
                    )}

                    {this.getEDHNumberAttribute() !== 'H' && (
                        <EAMLinkInput
                            value={measurement.edhNumber}
                            link="https://edh.cern.ch/Document/"
                            right={6}
                            top={8}
                            isExternalLink
                        >
                            <EAMInput
                                elementInfo={{
                                    ...woFields.edhnumber,
                                    readonly: true,
                                    attribute: this.getEDHNumberAttribute(),
                                }}
                                value={measurement.edhNumber}
                                updateProperty={updateProperty}
                                valueKey={`${jobNumber}.edhNumber`}
                                formFields={formFields}
                            />
                        </EAMLinkInput>
                    )}
                    {recordRWDate && (
                        <EAMDatePicker
                            elementInfo={{
                                text: 'RW Reception Date',
                                readonly,
                                attribute: this.getBasicStatusAttribute(),
                            }}
                            value={measurement.rwReceptionDate}
                            updateProperty={updateProperty}
                            valueKey={`${jobNumber}.rwReceptionDate`}
                            formFields={formFields}
                            localeString={lang}
                            // ampm={false}
                            keyboard={false}
                            timestamp
                            maxDate={new Date()}
                            clearable={!rwReceptionDateObject}
                        />
                    )}
                    <div style={{ color: 'Red', fontSize: '10px' }}>{recordRWDateMessage}</div>
                    {!readonly && showReceptionEmptyContainer && (
                        <>
                            <WarningText text={getTranslation('RECEIVE_WASTE_NO_CONTENT')} />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                    flex: 1,
                                    maxWidth: '350px',
                                }}
                            >
                                <TRECButton
                                    size="small"
                                    onClick={() =>
                                        history.push(
                                            withQueryParams({
                                                path: ROUTES.container,
                                                queryParams: { code: measurement.rwEquipment?.[0]?.code },
                                            })
                                        )
                                    }
                                >
                                    <i className="fa fa-archive" style={{ alignSelf: 'center', fontSize: '30px' }} />
                                    {getTranslation('GO_TO_CONTAINER')}
                                </TRECButton>
                                <TRECButton size="small" onClick={attachVirtualCode}>
                                    <AssetIcon />
                                    {getTranslation('ATTACH_VIRTUAL_CODE')}
                                </TRECButton>
                            </div>
                        </>
                    )}
                </div>
            </EISPanel>
        );
    }
}

export default Status;
