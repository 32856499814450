import React from 'react';
import DEFAULTS from 'enums/Defaults';
import RPARAnalysisDetails from './RPARAnalysisDetails';

const ANALYSIS_TYPE = 'ABCOUNT';
export default function RPARABCount(props) {
    const { getTranslation, valuesGrid } = props;
    const tableLayout = DEFAULTS.getTableLayout({ cannotRequestLL: true, valuesGrid, ANALYSIS_TYPE }).map((e) => ({
        ...e,
        header: getTranslation(e.code),
    }));
    return <RPARAnalysisDetails {...props} analysisType="ABCOUNT" tableLayout={tableLayout} />;
}
