import { Grid } from '@material-ui/core';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import EAMDatePicker from 'eam-components/dist/ui/components/inputs/EAMDatePicker';
import EAMDateTimePicker from 'eam-components/dist/ui/components/inputs/EAMDateTimePicker';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMLinkInput from 'eam-components/dist/ui/components/inputs/EAMLinkInput';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EAMLookup from 'eam-components/dist/ui/components/lookup/EAMLookup';
import { TABLE_DATA_TYPES } from '../../../enums/Constants';
import TRECPrefixInput from '../rpmperform/components/radiationMeasurements/TRECPrefixInput';
import Tools from 'tools/Tools';
import { AMMDropdown } from 'amm-tools';

const ATTRIBUTES = {
    REQUIRED: 'REQUIRED',
    OPTIONAL: 'OPTIONAL',
    READONLY: 'READONLY',
    READONLY_TEXT: 'READONLY_TEXT',
    HIDDEN: 'HIDDEN',
};

const ATTRIBUTES_MAP = {
    [ATTRIBUTES.REQUIRED]: 'R',
    [ATTRIBUTES.OPTIONAL]: 'O',
    [ATTRIBUTES.READONLY]: 'O',
    [ATTRIBUTES.HIDDEN]: 'H',
};

const generate = ({
    field,
    object,
    updateObject,
    formFields,
    hideLabel,
    key,
    labelStyle,
    getTranslation,
    lang,
    linkInput,
    setFormFields,
}) => {
    const attribute = field.getAttribute && field.getAttribute(object, field);
    let comp = null;
    const value =
        (typeof field.customValue === 'function' ? field.customValue(object, field) : field.customValue) ??
        object[field.code] ??
        '';
    const values =
        Object.prototype.toString.call(field.values) === '[object Function]' ? field.values(object) : field.values;
    const customInputProps =
        (typeof field.customInputProps === 'function' && field.customInputProps({ field, object })) ||
        (field.customInputProps ?? {});

    const baseProps = {
        key,
        elementInfo: {
            xpath: field.code,
            udfUom: field.uom,
            ...(field.elementInfo || {}),
            ...(attribute
                ? {
                      readonly: attribute === ATTRIBUTES.READONLY,
                      attribute: ATTRIBUTES_MAP[attribute],
                      ...(field.translationKey && getTranslation ? { text: getTranslation(field.translationKey) } : {}),
                  }
                : {}),
            ...(hideLabel ? { text: '' } : {}),
        },
        formFields,
        setFormFields,
        labelStyle,
        valueKey: field.code,
        value,
        values,
        updateProperty:
            field.dataType === 'number'
                ? (k, val, onMount) => (!val || val.match(/^[+]?([.]\d+|\d+[.]?\d*)$/)) && updateObject(k, val, onMount)
                : (k, val, onMount) => updateObject(k, val, onMount),
        validate: field.validate,
    };

    if (attribute === ATTRIBUTES.HIDDEN) {
        return null;
    }
    if (attribute === ATTRIBUTES.READONLY_TEXT) {
        if (field.renderValue) return field.renderValue(object, field);
        switch (field.type) {
            case TABLE_DATA_TYPES.SELECT: {
                const code = value;
                const valueFound = (values || []).find((val) => val.code === code);
                return (valueFound && valueFound.desc) || code;
            }
            default:
                return value;
        }
    }
    switch (field.type) {
        case TABLE_DATA_TYPES.STATIC:
            return value;
        case TABLE_DATA_TYPES.INPUT:
            comp = <EAMInput labelStyle={{ wordWrap: 'break-word' }} {...baseProps} {...customInputProps} />;
            break;
        case TABLE_DATA_TYPES.AUTOCOMPLETE:
            comp = (
                <EAMAutocomplete
                    {...baseProps}
                    loadOptions={field.autocompleteType}
                    descKey={field.descKey}
                    valueDesc={object[field.descKey]}
                    {...customInputProps}
                />
            );
            break;
        case TABLE_DATA_TYPES.SELECT:
            comp = <EAMSelect {...baseProps} {...customInputProps} />;
            break;
        case TABLE_DATA_TYPES.CHECKBOX:
            comp = <EAMCheckbox {...baseProps} trueValue falseValue={false} {...customInputProps} />;
            break;
        case TABLE_DATA_TYPES.LOOKUP_AUTOCOMPLETE:
            comp = (
                <EAMLookup
                    key={key}
                    right={1}
                    top={13}
                    width={26}
                    height={26}
                    gridId={field.gridId}
                    keys={field.keysMap(field)}
                    updateProperty={(k, val) => updateObject(k, val)}
                    value={value}
                    // TODO, review disabled property
                    disabled={[ATTRIBUTES.READONLY, ATTRIBUTES.HIDDEN].includes(attribute)}
                    {...customInputProps.lookup}
                >
                    <EAMAutocomplete
                        {...baseProps}
                        loadOptions={field.autocompleteType}
                        descKey={field.descKey}
                        valueDesc={object[field.descKey]}
                        {...customInputProps.autoComplete}
                    />
                </EAMLookup>
            );
            break;
        case TABLE_DATA_TYPES.LOOKUP_SELECT:
            comp = (
                <EAMLookup
                    labelStyle={{ wordWrap: 'break-word' }}
                    key={key}
                    right={1}
                    top={13}
                    width={26}
                    height={26}
                    gridId={field.gridId}
                    keys={field.keysMap(field)}
                    updateProperty={(k, val) => updateObject(k, val)}
                    value={value}
                    // TODO, review disabled property
                    disabled={[ATTRIBUTES.READONLY, ATTRIBUTES.HIDDEN].includes(attribute)}
                    {...customInputProps.lookup}
                >
                    <EAMSelect
                        {...baseProps}
                        descKey={field.descKey}
                        valueDesc={object[field.descKey]}
                        arrowRenderer={() => <span />}
                        creatable
                        {...customInputProps.select}
                    />
                </EAMLookup>
            );
            break;
        case TABLE_DATA_TYPES.DATE:
            comp = (
                <EAMDatePicker
                    labelStyle={{ wordWrap: 'break-word' }}
                    keyboard={false}
                    localeString={lang}
                    {...baseProps}
                    {...customInputProps}
                />
            );
            break;
        case TABLE_DATA_TYPES.DATE_TIME:
            comp = (
                <EAMDateTimePicker
                    labelStyle={{ wordWrap: 'break-word' }}
                    keyboard={false}
                    ampm={false}
                    localeString={lang}
                    {...baseProps}
                    {...customInputProps}
                />
            );
            break;
        case TABLE_DATA_TYPES.QUALIFIER_INPUT:
            comp = <TRECPrefixInput {...baseProps} {...customInputProps} />;
            break;
        case TABLE_DATA_TYPES.AMMDROPDOWN:
            // eslint-disable-next-line no-case-declarations
            const { ValueComponent, OptionComponent, IndicatorsContainerComponent } = customInputProps;
            comp = (
                <div style={{ margin: '4px 0 4px 0' }}>
                    <AMMDropdown
                        getOptionValue={(option) => option[field.valueKey || field.code]}
                        getOptionLabel={(option) => option[field.descKey || field.valueKey || field.code]}
                        getNewOptionData={(val) => ({
                            code: val,
                            [field.code]: val,
                        })}
                        loadOptions={
                            field.loadOptions ??
                            (field.autocompleteType &&
                                (async ({ hint }, ...args) =>
                                    (await field.autocompleteType(hint, ...args))?.body.data.map((data) => ({
                                        [field.valueKey]: data.code,
                                        [field.descKey]: data.desc,
                                    }))))
                        }
                        components={{
                            ...(OptionComponent
                                ? {
                                      Option: OptionComponent,
                                  }
                                : {}),
                            ...(IndicatorsContainerComponent
                                ? {
                                      IndicatorsContainer: IndicatorsContainerComponent,
                                  }
                                : {}),
                            ...(ValueComponent
                                ? {
                                      SingleValue: ValueComponent,
                                      MultiValueLabel: ValueComponent,
                                  }
                                : {}),
                        }}
                        onChange={(val) => updateObject(field.code, val)}
                        {...baseProps}
                        {...customInputProps}
                        valueKey={field.valueKey}
                        descKey={field.descKey}
                    />
                </div>
            );
            break;
        default:
            break;
    }
    return (
        <Grid key={`grid#${key}`} item sm={field.width ?? 12} xs={12}>
            {linkInput ? (
                <EAMLinkInput value={value} isExternalLink link={linkInput} right={1} top={8}>
                    {comp}
                </EAMLinkInput>
            ) : (
                comp
            )}
        </Grid>
    );
};

const getInputGenerator =
    ({
        object,
        updateObject,
        updateObjectPartial,
        formFields,
        labelStyle,
        getTranslation,
        writeAccess,
        lang,
        setFormFields,
    }) =>
    ({ linkInput, ...field }) =>
        generate({
            key: field.key ?? field.code,
            field: {
                ...field,
                code:
                    field.complex ?? [TABLE_DATA_TYPES.AUTOCOMPLETE].includes(field.type)
                        ? field.valueKey || field.code
                        : field.code,
                getAttribute: !writeAccess ? () => ATTRIBUTES.READONLY : field.getAttribute,
                validate: field.validate && field.validate(object),
            },
            object:
                field.complex ?? [TABLE_DATA_TYPES.AUTOCOMPLETE].includes(field.type)
                    ? object[field.code] || {}
                    : object,
            updateObject: (key, value) => {
                const isPartial = field.complex ?? [TABLE_DATA_TYPES.AUTOCOMPLETE].includes(field.type);
                const funcToUpdate =
                    field.customUpdateObject?.(object) ?? ((isPartial && updateObjectPartial) || updateObject);
                let val = field.getUpdateValue?.(value) ?? value;
                val = field.dataType !== 'number' || !val || val.match(/^[-]?([.]\d+|\d+[.]?\d*)$/) ? val : '';
                funcToUpdate({ [key]: val }, isPartial ? field.code : undefined);
            },
            formFields,
            labelStyle,
            getTranslation,
            lang,
            linkInput,
            setFormFields,
        });

const generateTableData = (tableColumns, objectList, tableGeneratorProps, idKey) =>
    objectList.map((object, index) =>
        tableColumns.reduce((acc, field) => {
            acc[field.code] = getInputGenerator({
                object,
                ...tableGeneratorProps,
                updateObject: tableGeneratorProps.updateObject?.(object),
                updateObjectPartial: tableGeneratorProps.updateObjectPartial?.(object),
            })({
                ...field,
                keyCode: `${field.code}#${object[idKey]}#${index}`,
                autocompleteType: field.autocompleteType?.(object),
                translationKey: null,
                elementInfo: {
                    ...(field?.elementInfo ?? {}),
                    xpath: `${field.code}#${object[idKey]}#${index}`,
                },
            });
            return acc;
        }, {})
    );

const computePermissions = (steps, authorizedMenus, necessaryInfo) =>
    Tools.applyToFields(steps, (currentStep) => [
        ...new Set([
            ...authorizedMenus
                .filter(({ type, code }) => type === 'TB' && code === currentStep.permissionKey)
                .map(({ readOnly }) => (readOnly ? 'R' : 'W')),
            ...(currentStep.computeSpecialPermissions?.(necessaryInfo) ?? []),
        ]),
    ]);

export { ATTRIBUTES, generate, computePermissions, generateTableData, getInputGenerator };

export default {
    ATTRIBUTES,
    generate,
    computePermissions,
    generateTableData,
    getInputGenerator,
};
