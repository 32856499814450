import React from 'react';
import {
    List,
    ListItem,
    ListItemIcon,
    CircularProgress,
    ListItemText,
    Typography,
    withStyles,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

export const STAGES_PROGRESS_SOURCE = {
    RW_MANAGEMENT: 'RW_MANAGEMENT',
};

export const STAGE_STATUS_TYPES = {
    HIDDEN: 'HIDDEN',
    WAITING: 'WAITING',
    PROCESSING: 'PROCESSING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

export const STAGE_ERROR_TYPES = {
    STAGE_ERROR: 'STAGE_ERROR',
};

export const applyParallelStageProcedure = ({ stage, executeParams = {}, previousResultData = {}, updateStage }) => {
    const { id, execute } = stage;
    const params = { ...executeParams, ...previousResultData };
    updateStage({ id, stageData: { status: STAGE_STATUS_TYPES.PROCESSING } });
    return execute(params)
        .then((resultData) => {
            const { successMessage, ...other } = resultData;
            updateStage({
                id,
                stageData: { status: STAGE_STATUS_TYPES.SUCCESS, successMessage },
            });
            stage.afterSuccess({ stage });
            return other;
        })
        .catch((error) => {
            updateStage({
                id,
                stageData: {
                    status: STAGE_STATUS_TYPES.ERROR,
                    error: error && error.message === STAGE_ERROR_TYPES.STAGE_ERROR ? undefined : error,
                },
            });
            // throw new Error(STAGE_ERROR_TYPES.STAGE_ERROR);
        });
};

export const executeParallel = ({ stages, updateStage }) =>
    Promise.all(
        Object.values(stages)
            .sort((a, b) => a.order > b.order)
            .map((stage) =>
                applyParallelStageProcedure({
                    stage,
                    executeParams: stage.executeParams,
                    updateStage,
                })
            )
    );

const SuccessIcon = withStyles(() => ({
    root: {
        color: '#4caf50',
    },
}))(CheckIcon);

const SuccessTypograpy = withStyles(() => ({
    root: {
        color: '#4caf50',
        display: 'inline-flex',
        background: 'honeydew',
        padding: '4px',
        borderRadius: '4px',
        border: '1px solid #fbfbfb',
    },
}))(Typography);

const ErrorTypography = withStyles((theme) => ({
    root: {
        color: theme.palette.error.main,
        background: '#ffeaea',
        display: 'inline-flex',
        padding: '4px',
        borderRadius: '4px',
    },
}))(Typography);

const defaultRenderStageStatusIcon = (status) => {
    switch (status) {
        case STAGE_STATUS_TYPES.WAITING:
            return <HourglassEmptyIcon />;
        case STAGE_STATUS_TYPES.PROCESSING:
            return <CircularProgress size={24} />;
        case STAGE_STATUS_TYPES.SUCCESS:
            return <SuccessIcon color="primary" />;
        case STAGE_STATUS_TYPES.ERROR:
            return <ErrorIcon color="error" />;
        default:
            return null;
    }
};

// REVIEW, this should not be part of this component, but for now it is used for compliance with error formats being returned from backend
const getErrorsList = (error) => (error && error.response && error.response.body && error.response.body.errors) || [];

const defaultRenderStages = (renderIcon) => (stages) =>
    Object.values(stages)
        .sort((a, b) => a.order > b.order)
        .map((stage) =>
            stage.status === STAGE_STATUS_TYPES.HIDDEN ? null : (
                <ListItem key={stage.id}>
                    <ListItemIcon>{renderIcon(stage.status)}</ListItemIcon>
                    <ListItemText
                        primary={stage.title}
                        secondary={
                            <>
                                {stage.description ? (
                                    <Typography color="textSecondary" component="p">
                                        {stage.description}
                                    </Typography>
                                ) : null}
                                {stage.errorMessage && (
                                    <ErrorTypography variant="body1" component="span">
                                        {stage.errorMessage}
                                    </ErrorTypography>
                                )}
                                {stage.error &&
                                    /* eslint-disable react/no-array-index-key */
                                    getErrorsList(stage.error).map((error, index) => (
                                        <ErrorTypography variant="body1" component="span" key={index}>
                                            {[error.code, error.detail || error.title].filter(Boolean).join(': ')}
                                        </ErrorTypography>
                                    ))}
                                {stage.successMessage && (
                                    <SuccessTypograpy variant="body1" component="span">
                                        {stage.successMessage}
                                    </SuccessTypograpy>
                                )}
                            </>
                        }
                    />
                </ListItem>
            )
        );

const StageProgress = (props) => {
    const { stages = {}, renderStageStatusIcon = defaultRenderStageStatusIcon } = props;
    const { renderStages = defaultRenderStages(renderStageStatusIcon) } = props;
    const { applicationData, constants } = props;
    return (
        <div>
            <List>{renderStages(stages, applicationData, constants)}</List>
        </div>
    );
};

export default StageProgress;
