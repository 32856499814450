import React, { Component } from 'react';
import { TRECLogo } from 'eam-components/dist/ui/components/icons';
import Languages from '../../../enums/Languages';
import GB from '../../../GB.svg';
import FR from '../../../FR.svg';

const LANGUAGE_SELECTION = 'TREC_LANGUAGE';
class LanguageSelection extends Component {
    containerStyle = {
        width: '15%',
        minWidth: '300px',
        margin: 'auto',
    };

    englishStyle = {
        float: 'left',
        cursor: 'pointer',
    };

    frenchStyle = {
        float: 'right',
        cursor: 'pointer',
    };

    flagStyle = {
        width: 120,
        height: 120,
    };

    greetingStyle = {
        minWidth: '300px',
        margin: '0 auto',
        textAlign: 'center',
    };

    flexContainerStyle = {
        height: 'calc(100% - 250px)',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    };

    trecLogoStyle = {};

    welcomeTextStyle = {
        display: 'inline-flex',
        verticalAlign: 'middle',
    };

    UNSAFE_componentWillMount() {
        const { setPage } = this.props;
        setPage(LANGUAGE_SELECTION);
    }

    chooseLanguage(lang) {
        const { bufferZoneAccount, userData, history, loginToken, bufferZone, updateLayout } = this.props;
        updateLayout({ lang });
        const loginRequired = bufferZoneAccount === userData.eamAccount.userCode && bufferZone;
        const shouldUserLogin = loginRequired && !loginToken;
        history.push(shouldUserLogin ? '/login' : '/menu');
    }

    render() {
        return (
            <div style={this.flexContainerStyle}>
                <div id="languageSelection">
                    <div style={this.greetingStyle}>
                        <div style={this.trecLogoStyle}>
                            <TRECLogo size="60" />
                        </div>
                        <div style={this.welcomeTextStyle}>
                            <h1>
                                <span>Welcome to EAM TREC</span>
                            </h1>
                        </div>
                    </div>
                    <div style={this.containerStyle}>
                        <div
                            style={this.englishStyle}
                            onClick={() => this.chooseLanguage(Languages.ENGLISH)}
                            onKeyDown={() => this.chooseLanguage(Languages.ENGLISH)}
                        >
                            <img src={GB} style={this.flagStyle} alt="English" />
                        </div>
                        <div
                            style={this.frenchStyle}
                            onClick={() => this.chooseLanguage(Languages.FRENCH)}
                            onKeyDown={() => this.chooseLanguage(Languages.ENGLISH)}
                        >
                            <img src={FR} style={this.flagStyle} alt="Français" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LanguageSelection;
