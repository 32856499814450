import { openConfirmDialog } from 'react-eis-components/dist/actions/uiActions';
import { connect } from 'react-redux';
import { setPage, updateLayout } from '../../../actions/layoutActions';
import rparActions from '../../../actions/rparActions';
import { rparGettersWithState } from '../../../reducers/rparReducer';
import Tools from '../../../tools/Tools';
import RPAnalysis from './RPAnalysis';

const RPAM_REQUEST_MASTERPAGE = 'RPAM_ANALYSIS';

const RPAM_TRANSLATION_KEY = 'TREC_RPAM';

function mapStateToProps(state) {
    const { applicationData, screenData, menuType, rwpDropdowns } = state.application;
    const { layout } = state;
    const { lang } = layout;
    const translations = screenData[lang].translations[RPAM_TRANSLATION_KEY];
    const lang2 = Tools.getURLParameterByName('lang');
    const rwpDropdownsTranslated = Tools.applyToFields(rwpDropdowns, (list) =>
        list.map((codeDesc) => ({
            ...codeDesc,
            desc: (codeDesc.descTranslated && codeDesc.descTranslated[lang]) || codeDesc.desc,
        }))
    );

    const gridTranslations = Object.entries({
        ana_facility: 'facility',
    }).reduce(
        (acc, [key, val]) => ({
            ...acc,
            [key]: rwpDropdownsTranslated[val].reduce((a, el) => ({ ...a, [el.code]: el.desc }), {}),
        }),
        {}
    );

    return {
        page: RPAM_REQUEST_MASTERPAGE,
        masterPage: RPAM_REQUEST_MASTERPAGE,
        getTranslation: (code) =>
            code &&
            ((lang2 === 'DEV' && code) ||
                (translations && translations[code.toUpperCase()]) ||
                (state.application.screenData.EN.translations[RPAM_TRANSLATION_KEY] &&
                    state.application.screenData.EN.translations[RPAM_TRANSLATION_KEY][code.toUpperCase()]) ||
                code),
        menu: screenData[lang].menu[menuType],
        lang,
        translatedScreenData: screenData[lang],
        applicationData,
        rpar: state.rpar,
        rparGetters: rparGettersWithState(state.rpar),
        dropdowns: rwpDropdownsTranslated,
        gridTranslations,
    };
}

const RPAnalysisContainer = connect(mapStateToProps, {
    updateLayout,
    setPage,
    openConfirmDialog,
    ...rparActions,
})(RPAnalysis);

export default RPAnalysisContainer;
