import Grid from '@material-ui/core/Grid';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import React, { Component } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import WSAutocomplete from '../../../../tools/rest/WSAutocomplete';
import { showError } from 'tools/TrecNotifications';

class RPMRSearch extends Component {
    formFields = [];

    UNSAFE_componentWillMount() {
        // Set the page for the help
        const { setPage } = this.props;
        setPage('TREC_REQRPMEAS', 'STEP_SEARCH');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const uniqueEquipments = this.getUniqueEquipmentSet(nextProps.equipments);
        const { updateRequestProperty } = this.props;
        if (uniqueEquipments !== nextProps.equipments) {
            updateRequestProperty('equipments', uniqueEquipments);
        }
    }

    componentDidMount() {
        const { equipments, onRef, updateRequestProperty } = this.props;
        if (onRef) onRef(this);
        if (equipments) {
            updateRequestProperty('equipments', this.getUniqueEquipmentSet(equipments));
        }
    }

    getUniqueEquipmentSet = (equipments) => {
        if (equipments) {
            const equipmentList = equipments.split(',');
            return [...new Set(equipmentList)].join(',');
        }
        return undefined;
    };

    validate = () => {
        let isValid = true;
        const { applicationData, translations, woFields } = this.props;
        const equipmentField = this.formFields[woFields.equipment.xpath];
        if (!equipmentField.validate() || !this.checkForEquipmentLimit(null, equipmentField.state.value)) {
            isValid = false;
            !this.checkForEquipmentLimit(null, equipmentField.state.value)
                ? showError(`You cannot select more than ${applicationData.maxUpdateRecords} equipments`)
                : showError(translations.EQPINVALID);
        }
        return isValid;
    };

    checkForEquipmentLimit = (key, value) => {
        const { applicationData, updateRequestProperty } = this.props;
        if (value && value.length <= applicationData.maxUpdateRecords) {
            updateRequestProperty('equipments', value);
            return true;
        }
        showError(`You cannot select more than ${applicationData.maxUpdateRecords} equipments`);
        return false;
    };

    render() {
        const { translations, woFields, equipments, applicationData } = this.props;
        return (
            <Grid container spacing={0}>
                <Grid item md={6} sm={12} xs={12}>
                    <EISPanel heading={translations.EQUIPMENT} alwaysExpanded>
                        <div style={{ width: '100%', marginTop: 0 }}>
                            <EAMAutocomplete
                                elementInfo={{
                                    ...woFields.equipment,
                                    text: translations.EQUIPMENTID,
                                    attribute: 'R',
                                    maxUpdateRecords: applicationData.maxUpdateRecords,
                                }}
                                value={equipments}
                                valueKey="equipments"
                                updateProperty={this.checkForEquipmentLimit}
                                loadOptions={WSAutocomplete.autocompleteAllEquipment}
                                multi
                                creatable
                                formFields={this.formFields}
                                columnsWidth={['0%', '100%']}
                                autoSelectSingle
                            />
                        </div>
                    </EISPanel>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    {/* No content */}
                </Grid>
            </Grid>
        );
    }
}

export default RPMRSearch;
