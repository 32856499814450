import React from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import Grid from '@material-ui/core/Grid';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EAMDatePicker from 'eam-components/dist/ui/components/inputs/EAMDatePicker';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import Tools from '../../../tools/Tools';
import WSAutocomplete from '../../../tools/rest/WSAutocomplete';

const labelStyle = {
    width: 150,
    minWidth: 150,
};

const labelStyleAuto = {
    width: 'auto',
    minWidth: 130,
};

class RWCheckInformation extends React.Component {
    _isRequired = () => {
        const { constants, data, processed, writeAccess } = this.props;
        if (!writeAccess || processed) {
            return { readonly: true };
        }
        if (data.statusCode === constants.statusWoT) {
            return { attribute: 'R' };
        }
        return { readonly: true };
    };

    _isRequiredAndResultOK = () => {
        const { constants, data, processed, writeAccess } = this.props;
        if (!writeAccess || processed) {
            return { readonly: true };
        }
        if (data.statusCode === constants.statusWoT && data.result === constants.cfvRwcStepResultOk) {
            return { attribute: 'R' };
        }
        return { readonly: true };
    };

    render() {
        const {
            lang,
            data,
            screenData,
            updateProperty,
            applicationData,
            constants,
            formFields,
            translations,
            processed,
            writeAccess,
        } = this.props;
        const { customFieldsRW, customFieldsRWDef } = screenData[lang];

        return (
            <EISPanel heading={translations.RWCHECKINFO}>
                <Grid container spacing={8}>
                    <Grid item md={12} sm={12} xs={12}>
                        <EAMInput
                            elementInfo={{ ...screenData[lang].woFields.workordernum, readonly: true }}
                            value={data.jobNumber}
                            labelStyle={labelStyle}
                            formFields={formFields}
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <EAMDatePicker
                            elementInfo={{ ...screenData[lang].woFields.datecreated, readonly: true }}
                            value={data.created}
                            localeString={lang}
                            labelStyle={labelStyle}
                            formFields={formFields}
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <EAMDatePicker
                            elementInfo={{ ...screenData[lang].woFields.datecompleted, ...this._isRequired() }}
                            valueKey="completed"
                            value={data.completed}
                            updateProperty={(key, value) => updateProperty(key, value)}
                            localeString={lang}
                            labelStyle={labelStyleAuto}
                            disablePast
                            formFields={formFields}
                        />
                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                        <EAMAutocomplete
                            elementInfo={{ ...screenData[lang].woFields.reportedby, readonly: true }}
                            value={data.requestedBy}
                            labelStyle={labelStyle}
                            valueDesc={data.requestedByDesc}
                            formFields={formFields}
                        />
                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                        <EAMAutocomplete
                            elementInfo={{ ...screenData[lang].woFields.location, readonly: true }}
                            value={data.location}
                            labelStyle={labelStyle}
                            valueDesc={data.locationDesc}
                            formFields={formFields}
                        />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        {/* Executed by */}
                        <EAMAutocomplete
                            elementInfo={{ ...screenData[lang].woFields.udfchar21, ...this._isRequired() }}
                            value={data.executedBy}
                            valueKey="executedBy"
                            valueDesc={data.executedByDesc}
                            descKey="executedByDesc"
                            updateProperty={(key, value) => updateProperty(key, value)}
                            loadOptions={WSAutocomplete.autocompleteEmployee}
                            labelStyle={labelStyle}
                            formFields={formFields}
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <EAMSelect
                            elementInfo={{
                                ...screenData[lang].woFields.workorderstatus,
                                attribute: 'R',
                                readonly: !writeAccess || processed,
                            }}
                            valueKey="statusCode"
                            value={data.statusCode}
                            values={screenData[lang].lists.jobStatuses.filter(
                                (f) => ![constants.statusWoRc, 'TI'].includes(f.code)
                            )}
                            updateProperty={(key, value) => updateProperty(key, value)}
                            labelStyle={labelStyle}
                            formFields={formFields}
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <EAMSelect
                            elementInfo={{ ...customFieldsRW[constants.cfpRwcStepResult], ...this._isRequired() }}
                            valueKey="result"
                            value={data.result}
                            values={Tools.getCustomFieldsCodeDesc(customFieldsRWDef[constants.cfpRwcStepResult]).filter(
                                (f) => f.code !== constants.cfvRwcStepResultCancelled
                            )}
                            updateProperty={(key, value) => updateProperty(key, value)}
                            labelStyle={labelStyleAuto}
                            formFields={formFields}
                        />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <EAMSelect
                            elementInfo={{
                                ...customFieldsRW[constants.cfpRwcEqpDestination],
                                ...this._isRequiredAndResultOK(),
                            }}
                            valueKey="destination"
                            value={data.destination}
                            values={applicationData.rwdestinations.map((rwd) => ({ code: rwd, desc: rwd }))}
                            updateProperty={(key, value) => updateProperty(key, value)}
                            labelStyle={labelStyle}
                            formFields={formFields}
                        />
                    </Grid>
                </Grid>
            </EISPanel>
        );
    }
}

export default RWCheckInformation;
