import React from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import HistoryIcon from '@material-ui/icons/History';
import { BZ_INVENTORY, STEP, STEP_ACTIONS, STEP_PAST_INVENTORIES, STEP_SCANNING } from './BZInventoryUtils';
import TRECButton from '../../../components/buttons/TRECButton';

const BUFFER_ZONE = 'bufferZone';

class BZInventoryInitialization extends React.Component {
    inputsContainerStyle = {
        width: '90%',
        maxWidth: '800px',
        padding: '10px',
    };

    buttonStyle = {
        marginTop: 5,
        marginBottom: 5,
    };

    iconStyle = {
        padding: 5,
    };

    formFields = [];

    handleBeginInventory = () => {
        const { updateProperty } = this.props;
        const isValid = this.formFields[BUFFER_ZONE].validate();

        if (isValid) {
            updateProperty(STEP, STEP_SCANNING);
        }
    };

    handlePastInventories = () => {
        const { setPage, updateProperty } = this.props;
        updateProperty(STEP, STEP_PAST_INVENTORIES);
        setPage(BZ_INVENTORY, STEP_ACTIONS);
    };

    render() {
        const { bufferZone, bufferZones, history, translations, updateProperty } = this.props;
        return (
            <EISPanel heading={translations.SEARCH_CRITERIA}>
                <div style={this.inputsContainerStyle}>
                    <EAMSelect
                        elementInfo={{
                            text: translations.BUFFER_ZONE,
                            xpath: BUFFER_ZONE,
                            attribute: 'R',
                        }}
                        value={bufferZone}
                        updateProperty={updateProperty}
                        valueKey={BUFFER_ZONE}
                        values={bufferZones.map((bz) => ({ code: bz.locationCode, desc: bz.locationCode }))}
                        formFields={this.formFields}
                    />
                    <TRECButton style={this.buttonStyle} onClick={() => history.push('menu')} color="default">
                        <MenuIcon style={this.iconStyle} />
                        {translations.GOTOMENU}
                    </TRECButton>
                    <TRECButton style={this.buttonStyle} onClick={this.handleBeginInventory}>
                        <SearchIcon />
                        {translations.BEGIN_INVENTORY}
                    </TRECButton>
                    <TRECButton style={this.buttonStyle} onClick={this.handlePastInventories}>
                        <HistoryIcon />
                        {translations.VIEW_PAST_INV}
                    </TRECButton>
                </div>
            </EISPanel>
        );
    }
}

export default BZInventoryInitialization;
