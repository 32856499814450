import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import axios from 'axios';
import { TRECLogo } from 'eam-components/dist/ui/components/icons';
import { REQUEST_MODES } from 'enums/Constants';
import themes from 'enums/DifferentLayoutThemes';
import { Component } from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import 'react-eis-components/dist/ui/layout/CERNToolbar.css';
import EISLayout from 'react-eis-components/dist/ui/layout/EISLayout';
import { generatePath, Link, Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import colorConfig from 'ThemesConfig';
import { ROUTES } from 'tools/Routes';
import 'TREC.css';
import TRECFooter from 'TRECFooter';
import TRECAppBarContainer from 'ui/components/appbar/TRECAppBarContainer';
import TRECHelpContainer from 'ui/components/help/TRECHelpContainer';
import IconPage from 'ui/components/IconPage';
import InfoPage from 'ui/components/infopage/InfoPage';
import BZInventoryContainer from 'ui/pages/bzinventory/BZInventoryContainer';
import ContainerContainer from 'ui/pages/container/ContainerContainer';
import EDHDocmngContainer from 'ui/pages/edhdocmng/EDHDocmngContainer';
import EquipmentTreePage from 'ui/pages/eqtree/EquipmentTreePage';
import EquipmentSearchPageContainer from 'ui/pages/equipment/EquipmentSearchPageContainer';
import { TABS } from 'ui/pages/equipment/show/EquipmentShowPage';
import EquipmentShowPageContainer from 'ui/pages/equipment/show/EquipmentShowPageContainer';
import JobsReportContainer from 'ui/pages/jobsreport/JobsReportContainer';
import LanguageSelectionContainer from 'ui/pages/language/LanguageSelectionContainer';
import LoginContainer from 'ui/pages/login/LoginContainer';
import MenuContainer from 'ui/pages/menu/MenuContainer';
import NotFoundPage from 'ui/pages/NotFoundPage';
import Reports from 'ui/pages/reports';
import RPAnalysisContainer from 'ui/pages/rpanalysis/RPAnalysisContainer';
import RPAnalysisJobContainer from 'ui/pages/rpanalysis/RPAnalysisJobContainer';
import RPARequestLoaderContainer from 'ui/pages/rparequest/RPARequestLoaderContainer';
import RPMeasurementsContainer from 'ui/pages/rpmeasurements/RPMeasurementsContainer';
import RPMPerformContainer from 'ui/pages/rpmperform/RPMPerformContainer';
import RPMRequestContainer from 'ui/pages/rpmrequest/RPMRequestContainer';
import RPMRequest from 'ui/pages/rpmrequest2';
import RPSamplingContainer from 'ui/pages/rpsampling/RPSamplingContainer';
import RWCPerformContainer from 'ui/pages/rwcheckperform/RWCPerformContainer';
import RWChecksContainer from 'ui/pages/rwchecks/RWChecksContainer';
import RWEliminationRequest from 'ui/pages/rwelimination';
import RWInconsistencyChecksContainer from 'ui/pages/rwinconsistencychecks/RWInconsistencyChecksContainer';
import RWManagementContainer from 'ui/pages/rwmanagement/RWManagementContainer';
import RWProcessing from 'ui/pages/rwprocessing';
import RWProcessingSearchContainer from 'ui/pages/rwprocessing/RWProcessingSearchContainer';
import SearchRequestsContainer from 'ui/pages/searchrequests/SearchRequestsContainer';
import SettingsPage from 'ui/pages/settings';
import VacuumCleanerManage from 'ui/pages/vaccleanermanage';
import VacCleanerRequest from 'ui/pages/vaccleanerrequest';
import VacuumCleanerReservations from 'ui/pages/vaccleanerreservations';
import VacuumCleanerStock from 'ui/pages/vaccleanerstock';
import WorkOrdersRedirectContainer from 'ui/pages/workorders/WorkOrdersRedirectContainer';
import WRRequest from 'ui/pages/wrrequest';
import WaterReleaseSearch from 'ui/pages/wrsearch';
import RPMSearchContainer from './ui/pages/rpmsearch/RPMSearchContainer';
import RWEliminationManage from './ui/pages/rwelimination/manage';
import ReleaseNotes from './ui/pages/releasenotes/ReleaseNotes';
import { ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const getTheme = (themeName) => ({
    ...themes[themeName],
    typography: {
        body1: {
            fontSize: '12px',
            lineHeight: 1,
        },
        subtitle1: {
            lineHeight: 1.2,
        },
        subtitle2: {
            lineHeight: 1.2,
        },
        useNextVariants: true, // https://material-ui.com/style/typography/#migration-to-typography-v2
    },
});

const environmentColor = (environment) => {
    const theme = getTheme(colorConfig[environment] || colorConfig.DEFAULT);
    return createMuiTheme(theme);
};

class TREC extends Component {
    blockUiStyle = {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    blockUiStyleDiv = {
        display: 'flex',
        height: 60,
        alignItems: 'flex-end',
    };

    UNSAFE_componentWillReceiveProps() {
        const { loginToken } = this.props;
        if (loginToken) {
            axios.defaults.headers.common.Authorization = loginToken;
        }
    }

    componentDidMount() {
        const { initializeApplication } = this.props;
        console.info('Initializing');
        initializeApplication();
    }

    render() {
        // User data still not there, display loading page
        const {
            applicationData,
            bufferZone,
            bufferZoneAccount,
            loginToken,
            logout,
            userData,
            guestUsername,
            loading,
            screenData,
            store,
        } = this.props;
        if (loading || !(applicationData && userData)) {
            return (
                <BlockUi tag="div" blocking style={this.blockUiStyle}>
                    <div style={this.blockUiStyleDiv}>Loading TREC ...</div>
                </BlockUi>
            );
        }
        const { environment } = applicationData;
        const { eamAccount, invalidAccount } = userData;
        // Error when initializing application
        if (invalidAccount) {
            return (
                <InfoPage title="Error" message="Application couldn't be initialized. Please contact CMMS Support" />
            );
        }
        const selectedTheme = environmentColor(environment);

        const guestAccount = bufferZoneAccount === eamAccount.userCode;
        const loginSet = loginToken || guestUsername;

        const guestNoBuffer = guestAccount && !bufferZone;
        const shouldUserLogin = guestAccount && bufferZone && !loginSet;
        const accessGranted = !guestAccount || loginSet;

        return (
            <Router basename={process.env.REACT_APP_FRONTEND}>
                <MuiThemeProvider theme={selectedTheme}>
                    <ToastContainer
                        position="top-center"
                        style={{ width: '70%' }}
                        theme="colored"
                        autoClose={6000}
                        // hideProgressBar
                        closeOnClick
                        // pauseOnFocusLoss
                        draggable
                        newestOnTop
                        limit={1}
                        queueLimit={0}
                        transition={(props) => <Bounce {...props} preventExitTransition />}
                    />
                    <iframe title="EDMS" src="https://edms.cern.ch/ui/file/2753872/latest/picture.jpg" hidden />
                    <TRECHelpContainer />
                    <EISLayout
                        userDesc={eamAccount.userDesc}
                        userCode={eamAccount.userCode}
                        appName={
                            <div style={{ marginLeft: 10, marginTop: 9, display: 'flex', flexDirection: 'row' }}>
                                <Link to="/menu" style={{ textDecoration: 'none' }}>
                                    <TRECLogo size="20" color="#FFF" />
                                </Link>
                                <div
                                    style={{
                                        fontWeight: 900,
                                        marginLeft: 5,
                                        fontSize: '20px',
                                        lineHeight: '20px',
                                        verticalAlign: 'middle',
                                    }}
                                >
                                    {environment !== 'PROD' ? `  - ${environment}` : null}
                                </div>
                            </div>
                        }
                        userInstitution="CERN"
                        signOutFromApplication={() => new Promise(() => logout())}
                        signOutFromCERN={() => {}}
                        appBarContent={<TRECAppBarContainer />}
                        footerContent={
                            <TRECFooter
                                applicationData={applicationData}
                                screenData={screenData}
                                layout={store.layout}
                            />
                        }
                        showCERNToolbar={false}
                    >
                        {guestNoBuffer && <InfoPage title="Access Denied" message="GUEST_NO_BUFFER" />}
                        {accessGranted && (
                            <div style={{ height: '100%', boxSizing: 'border-box', padding: '10px' }}>
                                <Switch>
                                    <Route exact path={ROUTES.base} component={LanguageSelectionContainer} />
                                    <Route exact path={ROUTES.menu} component={MenuContainer} />
                                    <Route exact path={ROUTES.login} component={LoginContainer} />
                                    <Route exact path={ROUTES.releaseNotes} component={ReleaseNotes} />
                                    <Route exact path={ROUTES.rpsm} component={RPSamplingContainer} />
                                    <Route exact path={ROUTES.rpmRequest2} component={RPMRequest} />
                                    <Route exact path={ROUTES.waterReleaseSearch} component={WaterReleaseSearch} />
                                    <Route exact path={ROUTES.vacuumCleanerManage} component={VacuumCleanerManage} />
                                    <Route exact path={ROUTES.vacuumCleanerStock} component={VacuumCleanerStock} />
                                    <Route exact path={ROUTES.rwElimination} component={RWEliminationManage} />
                                    <Route
                                        exact
                                        path={ROUTES.rwEliminationAssets}
                                        render={(props) => <RWEliminationManage {...props} mode="ASSET" />}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.rwEliminationCreate}
                                        render={(props) => (
                                            <RWEliminationRequest
                                                {...props}
                                                key={REQUEST_MODES.CREATING}
                                                mode={REQUEST_MODES.CREATING}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.rwEliminationEdit}
                                        render={(props) => (
                                            <RWEliminationRequest
                                                {...props}
                                                key={REQUEST_MODES.EDITING}
                                                mode={REQUEST_MODES.EDITING}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.vacuumCleanerReservations}
                                        component={VacuumCleanerReservations}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.waterReleaseRequest}
                                        render={(routeProps) => (
                                            <WRRequest
                                                {...routeProps}
                                                key={REQUEST_MODES.CREATING}
                                                mode={REQUEST_MODES.CREATING}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.waterRelease}
                                        render={(routeProps) => (
                                            <WRRequest
                                                {...routeProps}
                                                key={REQUEST_MODES.EDITING}
                                                mode={REQUEST_MODES.EDITING}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.rwProcessingCreate}
                                        render={(routeProps) => (
                                            <RWProcessing
                                                {...routeProps}
                                                key={REQUEST_MODES.CREATING}
                                                mode={REQUEST_MODES.CREATING}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.rwProcessing}
                                        render={() => <Redirect to={ROUTES.rwProcessingCreate} />}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.rwProcessingEdit}
                                        render={(routeProps) => (
                                            <RWProcessing
                                                {...routeProps}
                                                key={REQUEST_MODES.EDITING}
                                                mode={REQUEST_MODES.EDITING}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.rwProcessingRead}
                                        render={(routeProps) => (
                                            <RWProcessing
                                                {...routeProps}
                                                key={REQUEST_MODES.READING}
                                                mode={REQUEST_MODES.READING}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.rwProcessingSearch}
                                        render={(routeProps) => <RWProcessingSearchContainer {...routeProps} />}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.rpamRequest}
                                        render={() => <Redirect to={ROUTES.rpamRequestCreate} />}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.rpamRequestCreate}
                                        key={ROUTES.rpamRequestCreate}
                                        render={(routeProps) => (
                                            <RPARequestLoaderContainer
                                                {...routeProps}
                                                clearRpm
                                                mode={REQUEST_MODES.CREATING}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.vaccleanerRequest}
                                        key={ROUTES.vaccleanerRequest}
                                        render={(routeProps) => (
                                            <VacCleanerRequest
                                                {...routeProps}
                                                key={REQUEST_MODES.CREATING}
                                                mode={REQUEST_MODES.CREATING}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.vaccleanerRequestEdit}
                                        key={ROUTES.vaccleanerRequestEdit}
                                        render={(routeProps) => (
                                            <VacCleanerRequest
                                                {...routeProps}
                                                key={REQUEST_MODES.EDITING}
                                                mode={REQUEST_MODES.EDITING}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.rpamRequestClone}
                                        key={ROUTES.rpamRequestClone}
                                        render={(routeProps) => (
                                            <RPARequestLoaderContainer
                                                {...routeProps}
                                                clearRpm
                                                mode={REQUEST_MODES.CLONING}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.rpamRequestEdit}
                                        key={ROUTES.rpamRequestEdit}
                                        render={(routeProps) => (
                                            <RPARequestLoaderContainer {...routeProps} mode={REQUEST_MODES.EDITING} />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.rpamRequestReadOnly}
                                        key={ROUTES.rpamRequestReadOnly}
                                        render={(routeProps) => (
                                            <RPARequestLoaderContainer
                                                {...routeProps}
                                                mode={REQUEST_MODES.READING}
                                                readOnly
                                            />
                                        )}
                                    />
                                    <Route exact path={ROUTES.rpamAnalysisJob} component={RPAnalysisJobContainer} />
                                    <Route exact path={ROUTES.rpamAnalysis} component={RPAnalysisContainer} />
                                    <Route exact path={ROUTES.rpMeasurements} component={RPMeasurementsContainer} />
                                    <Route path={ROUTES.searchRequests} component={SearchRequestsContainer} />
                                    <Route exact path={ROUTES.rpmSearch} component={RPMSearchContainer} />
                                    <Route path={ROUTES.rwChecks} component={RWChecksContainer} />
                                    <Route
                                        path={ROUTES.rwInconsistencyChecks}
                                        component={RWInconsistencyChecksContainer}
                                    />
                                    <Route path={ROUTES.reports} component={Reports} />
                                    <Route path={ROUTES.rwcPerform} component={RWCPerformContainer} />
                                    <Route path={ROUTES.rpmRequest} component={RPMRequestContainer} />
                                    <Route path={ROUTES.inventory} component={BZInventoryContainer} />
                                    <Route path={ROUTES.edhdocmng} component={EDHDocmngContainer} />
                                    <Route path={ROUTES.jobsReport} component={JobsReportContainer} />
                                    <Route exact path={ROUTES.rpmPerform} component={RPMPerformContainer} />
                                    <Route path={ROUTES.rwManagement} component={RWManagementContainer} />
                                    <Route path={ROUTES.container} component={ContainerContainer} />
                                    <Route path={ROUTES.workOrdersRedirect} component={WorkOrdersRedirectContainer} />
                                    <Route path={ROUTES.redirect} component={WorkOrdersRedirectContainer} />
                                    <Route exact path={ROUTES.equipment} component={EquipmentSearchPageContainer} />
                                    <Route
                                        exact
                                        path={ROUTES.equipmentShow}
                                        render={({ match }) => (
                                            <Redirect to={generatePath(ROUTES.equipmentOverview, match.params)} />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.equipmentHierarchy}
                                        render={(props) => (
                                            <EquipmentShowPageContainer {...props} activeTab={TABS.TAB_HIERARCHY} />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.equipmentHistory}
                                        render={(props) => (
                                            <EquipmentShowPageContainer {...props} activeTab={TABS.TAB_HISTORY} />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.equipmentOverview}
                                        render={(props) => (
                                            <EquipmentShowPageContainer {...props} activeTab={TABS.TAB_OVERVIEW} />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.equipmentAudit}
                                        render={(props) => (
                                            <EquipmentShowPageContainer {...props} activeTab={TABS.TAB_AUDIT} />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.equipmentEvents}
                                        render={(props) => (
                                            <EquipmentShowPageContainer {...props} activeTab={TABS.TAB_EVENTS} />
                                        )}
                                    />
                                    <Route exact path={ROUTES.equipmentTree} component={EquipmentTreePage} />
                                    <Route exact path={ROUTES.settings} component={SettingsPage} />
                                    <Route exact path="/iconpage" component={IconPage} />
                                    <Route path={ROUTES.notFound} component={NotFoundPage} />
                                    <Route component={NotFoundPage} />
                                </Switch>
                            </div>
                        )}
                        {shouldUserLogin && (
                            <div style={{ height: '100%', marginLeft: '10px', marginRight: '10px' }}>
                                <Switch>
                                    <Route exact path={ROUTES.login} component={LoginContainer} />
                                    <Route path={ROUTES.base} component={LanguageSelectionContainer} />
                                </Switch>
                            </div>
                        )}
                    </EISLayout>
                </MuiThemeProvider>
            </Router>
        );
    }
}

export default TREC;
