import { connect } from 'react-redux';
import Tools from '../../../tools/Tools';
import EquipmentTreeLoader from './EquipmentTreeLoader';

const TRANSLATION_KEY = 'EQUIPMENT_TREE';
const mapStateToProps = (state) => {
    const lang2 = Tools.getURLParameterByName('lang');
    const translations = state.application.screenData[state.layout.lang].translations[TRANSLATION_KEY];
    return {
        getTranslation: (code) =>
            code &&
            ((lang2 === 'DEV' && code) ||
                (translations && translations[code.toUpperCase()]) ||
                (translations[TRANSLATION_KEY] && translations[TRANSLATION_KEY][code.toUpperCase()]) ||
                code),
    };
};

const EquipmentTreeContainer = connect(mapStateToProps, {})(EquipmentTreeLoader);

export default EquipmentTreeContainer;
