export const RPMR_TRANSLATION_KEYS = {
    EQUIPMENT: 'EQUIPMENT',
    EQUIPMENTID: 'EQUIPMENT',
    EQPINVALID: 'EQPINVALID',
    EPQISNOTVCL: 'EPQISNOTVCL',
    RESULTS_GENERAL_DATA_HEADER: 'GENERALDATA',
    RESULTS_COMMENTS_HEADER: 'RPMEASCOMMENT',
    RESULTS_TABLE_HEADER: 'RESULTS',
    LOCATION_HEADER: 'LOCATION',
    EQUIPMENT_ALREADY_WASTE: 'EQALRWASTE',
    EQ_NEEDS_STRUCTURE_CHANGE: 'EQ_NEEDS_STRUCTURE_CHANGE',
    PARENT: 'PARENT',
    NON_DEPENDENT_CHILDREN: 'NON_DEPENDENT_CHILDREN',
    HAZARD_CODES: 'RISKS',
    HAZARD_COMMENT: 'HAZARDS_COMMENTS',
};

export const REQUEST_KEYS = {
    ACTION: 'futureAction',
    FUTURE_LOCATION_CODE: 'futureLocationCode',
    FUTURE_LOCATION_DESC: 'futureLocationDesc',
    CURRENT_LOCATION_CODE: 'currentLocationCode',
    CURRENT_LOCATION_DESC: 'currentLocationDesc',
    RESP_CODE: 'equipmentRespCode',
    RESP_DESC: 'equipmentRespDesc',
    EDH_DOCUMENT: 'edhDocument',
    DETACH_EQUIPMENT: 'detachEquipment',
    DEPARTMENT: 'department',
    ASSOCIATED_RISK: 'associatedRisk',
    TYPE_OF_TRANSPORT: 'typeOfTransport',
    STORAGE_OPERATION: 'storageOperation',
    TRANSPORT_REQUIRED: 'transportRequired',
    EQUIPMENT_DESTINATION: 'equipmentDestination',
    EQUIPMENT_DESTINATION_RAD: 'destinationIfRadioactive',
    MEASUREMENT_DEADLINE: 'measurementDeadline',
    MEASUREMENT_COMMENTS: 'measurementComments',
    REMOVE_ASSET_CLASS: 'removeAssetClass',
};

export const EQUIPMENT_KEYS = {
    CODE: 'equipmentCode',
    SERIALNUMBER: 'serialno',
    DESCRIPTION: 'equipmentDesc',
    RESPONSIBLE_CID: 'respTechnique',
    RESPONSIBLE_DESC: 'respTechniqueDesc',
    CLASS_CODE: 'classCode',
    LOCATION: 'objLocation',
    LAST_LOCATION_CODE: 'lastLocationCode',
    LAST_LOCATION_DESC: 'lastLocationDesc',
    PARENT_OBJ_CODE: 'objParentCode',
    PARENT_POSITION_CODE: 'objParentPositionCode',
    OBJ_PARENT_SYSTEM_CODE: 'objParentSystemCode',
    ATTACHED_TO: 'attachedTo',
    DETACH: 'detach',
    LENGTH: 'length',
    WIDTH: 'width',
    HEIGHT: 'height',
    WEIGHT: 'weight',
    VALUE: 'value',
    FAMILY: 'family',
    MATERIAL: 'material',
    IRRADIATION_END_DATE_MIN: 'irradiationEndDateMin',
    IRRADIATION_END_DATE_MAX: 'irradiationEndDateMax',
    TYPE: 'type',
    DB_SOURCE: 'dbSource',
    LOCK_CODE: 'lockCodes',
    FACILITY: 'facility',
    FACILITIES: 'facilities',
    HAZARDS: 'hazards',
    HAZARD_CODES: 'hazardCodes',
    HAZARD_COMMENT: 'hazardComment',
    DEPARTMENT: 'mrc',
    STORE: 'storeCode',
    RW_RECEPTION_DATE: 'rwReceptionDate',
    SYSTEM_STATUS: 'systemStatus',
};

export const EQUIPMENT_KEYS_DTO = [
    EQUIPMENT_KEYS.CODE,
    EQUIPMENT_KEYS.SERIALNUMBER,
    EQUIPMENT_KEYS.DESCRIPTION,
    EQUIPMENT_KEYS.RESPONSIBLE_CID,
    EQUIPMENT_KEYS.RESPONSIBLE_DESC,
    EQUIPMENT_KEYS.CLASS_CODE,
    EQUIPMENT_KEYS.LOCATION,
    EQUIPMENT_KEYS.LAST_LOCATION_CODE,
    // EQUIPMENT_KEYS.LAST_LOCATION_DESC,
    EQUIPMENT_KEYS.PARENT_OBJ_CODE,
    EQUIPMENT_KEYS.PARENT_POSITION_CODE,
    EQUIPMENT_KEYS.OBJ_PARENT_SYSTEM_CODE,
    EQUIPMENT_KEYS.ATTACHED_TO,
    EQUIPMENT_KEYS.DETACH,
    EQUIPMENT_KEYS.LENGTH,
    EQUIPMENT_KEYS.WIDTH,
    EQUIPMENT_KEYS.HEIGHT,
    EQUIPMENT_KEYS.WEIGHT,
    EQUIPMENT_KEYS.VALUE,
    EQUIPMENT_KEYS.FAMILY,
    EQUIPMENT_KEYS.MATERIAL,
    EQUIPMENT_KEYS.CLASS_CODE,
    EQUIPMENT_KEYS.TYPE,
    EQUIPMENT_KEYS.DB_SOURCE,
    EQUIPMENT_KEYS.IRRADIATION_END_DATE_MIN,
    EQUIPMENT_KEYS.IRRADIATION_END_DATE_MAX,
    EQUIPMENT_KEYS.FACILITY,
    EQUIPMENT_KEYS.FACILITIES,
    EQUIPMENT_KEYS.HAZARDS,
    EQUIPMENT_KEYS.DEPARTMENT,
];

export const EQUIPMENT_RPM_KEYS = {
    RP_CLASSIFICATION: 'rpClassification',
    FUTURE_ACTION: 'futureAction',
    ASSOC_RISK: 'associatedRisk',
    EDH: 'edhNumber',
    DATE_COMPLETED: 'completedDate',
    LAST_CLOSED_RPM_CODE: 'rpMeasurement',
    LAST_RPM_CODE: 'lastRequestedRPMeasurement',
};

export const REQUEST_SOURCES = {
    EMPTY_CONTAINER: 'EMPTY_CONTAINER',
};
