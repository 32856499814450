/* eslint-disable @typescript-eslint/no-unused-vars */
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, useState } from 'react';
import WSEquipment from '../../../tools/rest/WSEquipment';
import WSRPMeasurements from '../../../tools/rest/WSRPMeasurement';
import { INFORMATION_SOURCE, NODE_TYPES } from './Constants';
import EquipmentTree from './EquipmentTree';

const EquipmentTreeLoader = (props) => {
    // eslint-disable-next-line no-underscore-dangle
    window.__isReactDndBackendSetUp = false; // avoids 'Error: Cannot have two HTML5 backends at the same time' from react-dnd

    const { equipmentCode, jobCode, width, height, src, structure, nodeRenderer, extraProps, onLoad } = props;

    const [data, setData] = useState();
    const [error, setError] = useState();

    const getAssetTree = (assetData) => {
        const edges = [];
        const nodes = assetData.map((node) => {
            if (node.parentEqCode) {
                edges.push({
                    pk: `${node.parentEqCode}#${node.eqCode}`,
                    source: node.parentEqCode,
                    target: node.eqCode,
                });
            }

            return {
                id: node.eqCode,
                desc: node.equipmentInfo.description,
                type: NODE_TYPES.ASSET,
                noDragging: true,
                ...node,
            };
        });
        return { edges, nodes };
    };

    const expandPathToQueriedNode = (tree) => {
        const targetNode = tree.nodes.find((node) => node.id === equipmentCode);
        const path = targetNode.path.split('>');
        targetNode.focus = true;
        tree.nodes.forEach((node) => {
            node.expanded = path.includes(node.id);
        });
    };

    const loadData = async (dataSrc) => {
        let tree;

        try {
            switch (dataSrc) {
                case INFORMATION_SOURCE.EQUIPMENT_STRUCTURE: {
                    const response = await WSEquipment.getStructure({ equipmentID: equipmentCode });
                    tree = getAssetTree(response.body.data);
                    expandPathToQueriedNode(tree);
                    return tree;
                }
                case INFORMATION_SOURCE.RPM_STRUCTURE: {
                    const response2 = await WSRPMeasurements.getRPMJob({ rpmCode: jobCode });
                    tree = getAssetTree(
                        response2.body.data.equipmentStructure && response2.body.data.equipmentStructure.nodeList
                    );
                    expandPathToQueriedNode(tree);
                    return tree;
                }
                case INFORMATION_SOURCE.BREAK_UP_CORRECTIONS: {
                    tree = getAssetTree(structure);
                    expandPathToQueriedNode(tree);
                    return tree;
                }
                default:
                    return null;
            }
        } catch (err) {
            setError(err);
            return null;
        }
    };

    useEffect(() => {
        const getData = async () => {
            const tree = await loadData(src);
            setData(tree);
            onLoad && onLoad(tree);
        };
        getData();
    }, [equipmentCode, src]);

    return (
        <div>
            {error ? (
                <p style={{ color: 'red' }}>{error.title}</p>
            ) : !data ? (
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <p>Loading...</p>
                    <CircularProgress />
                </div>
            ) : (
                <EquipmentTree
                    tree={data}
                    width={width}
                    height={height}
                    nodeRenderer={nodeRenderer}
                    extraProps={extraProps}
                />
            )}
        </div>
    );
};

export default EquipmentTreeLoader;
