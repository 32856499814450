import React, { Component } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import Grid from '@material-ui/core/Grid';
import CommentsContainer from 'eam-components/dist/ui/components/comments/CommentsContainer';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMDatePicker from 'eam-components/dist/ui/components/inputs/EAMDatePicker';
import EISTable from 'react-eis-components/dist/ui/components/table';
import Tools from '../../../../tools/Tools';
import WSComments from '../../../../tools/rest/WSComments';

const labelStyle = {
    width: 170,
    minWidth: 170,
};

class RPMRConfirm extends Component {
    UNSAFE_componentWillMount() {
        // Set the page for the help
        const { setPage } = this.props;
        setPage('TREC_REQRPMEAS', 'STEP_CONFIRM');
    }

    // There is no validation in the confirm step
    validate() {
        // All ok
        return true;
    }

    // To know if the EDH Is displayed or not
    getEDHDocumentAttribute = () => {
        const { request, constants } = this.props;
        const { futureAction } = request;
        if (
            [
                constants.futureActionVacuumDeclare,
                constants.futureActionVacuumEmpty,
                constants.futureActionVacuumEnd,
                constants.futureActionVacuumEndFull,
            ].includes(futureAction)
        ) {
            // No EDH Document for vacuum cleaner
            return 'H';
        }
        // Optional
        return 'O';
    };

    // Fields for transport request
    getTransRequestAttribute = () => {
        // Displayed if the edh document is transport request
        const { request, constants } = this.props;
        const { edhDocument } = request;
        if (edhDocument === constants.cfvEdhDocumentTransport) {
            return 'O';
        }
        // Not displayed otherwise
        return 'H';
    };

    // Storage attribute
    getStorageAttribute = () => {
        // Displayed if the edh document is store request
        const { request, constants } = this.props;
        const { edhDocument } = request;
        if (edhDocument === constants.cfvEdhDocumentStockage) {
            return 'O';
        }
        // Not displayed otherwise
        return 'H';
    };

    // Calculate destination attribute
    getDestinationAttribute = () => {
        // Displayed if the edh document is transport or store and the future action is not declare waste
        const { request, constants } = this.props;
        const { edhDocument } = request;
        const { futureAction } = request;
        if (
            (edhDocument === constants.cfvEdhDocumentTransport || edhDocument === constants.cfvEdhDocumentStockage) &&
            futureAction !== constants.futureActionDecwaste
        ) {
            // rpmrequest v1 is not used anymore for declaring waste
            return 'O';
        }
        // Not displayed otherwise
        return 'H';
    };

    renderEquipmentData = () => {
        const { request } = this.props;
        const multipleEquipment = request.equipment.length > 1;
        if (multipleEquipment) {
            return this.renderEquipmentMulti();
        }
        return this.renderEquipmentSingle();
    };

    renderEquipmentMulti = () => {
        const { assetFields, request, applicationData, translations, lists } = this.props;
        const { equipment } = request;
        // Table with data for all the equipments
        const headers = [
            `${assetFields.equipmentno.text}`,
            `${assetFields.equipmentdesc.text}`,
            `${assetFields.udfnum07.text} (${assetFields.udfnum07.udfUom})`,
            `${assetFields.udfnum08.text} (${assetFields.udfnum08.udfUom})`,
            `${assetFields.udfnum09.text} (${assetFields.udfnum09.udfUom})`,
            `${assetFields.udfnum10.text} (${assetFields.udfnum10.udfUom})`,
            `${assetFields.equipmentvalue.text} (${applicationData.eqpValueCurrency})`,
        ];
        const headersADR = [`${assetFields.equipmentno.text}`, assetFields.udfchar21.text, assetFields.udfchar22.text];

        // Property codes
        const propCodes = ['equipmentCode', 'equipmentDesc', 'length', 'width', 'height', 'weight', 'equipmentValue'];
        const propCodesADR = ['equipmentCode', 'family', 'mainMaterial'];

        // The data to be displayed
        const data = equipment.map((eqp) => ({
            equipmentCode: eqp.equipmentCode + (equipment.serialNumber ? ` (${equipment.serialNumber})` : ''),
            equipmentDesc: eqp.equipmentDesc,
            length: eqp.length,
            width: eqp.width,
            height: eqp.height,
            weight: eqp.weight,
            equipmentValue: eqp.equipmentValue,
        }));

        const dataADR = equipment.map((eqp) => ({
            equipmentCode: eqp.equipmentCode + (equipment.serialNumber ? ` (${equipment.serialNumber})` : ''),
            family: Tools.getDescValueFromList(eqp.family, lists.objFamily),
            mainMaterial: Tools.getDescValueFromList(eqp.mainMaterial, lists.objMainMaterial),
        }));

        // Render
        return (
            <div style={{ width: '100%', height: '100%' }}>
                {/* Data for the equipments */}
                <h5>{translations.EQPCHARACTERISTIC}</h5>
                <EISTable data={data} headers={headers} propCodes={propCodes} maxMobileSize={600} />

                {/* Physical Characteristics */}
                <h5>{translations.ADRDETAILS}</h5>
                <EISTable data={dataADR} headers={headersADR} propCodes={propCodesADR} maxMobileSize={600} />

                <h5>{translations.DETAILS}</h5>
                {/* Equipment Responsible */}
                <EAMAutocomplete
                    elementInfo={{ ...assetFields.eqpResponsible, readonly: true }}
                    value={request.equipmentRespCode}
                    valueDesc={request.equipmentRespDesc}
                    labelStyle={labelStyle}
                />

                {/* Future Location */}
                {request.futureLocationCode && (
                    <EAMInput
                        elementInfo={{ ...assetFields.futureLocation, readonly: true }}
                        value={request.futureLocationCode}
                        labelStyle={labelStyle}
                    />
                )}

                {/* Current Location */}
                {request.currentLocationCode && (
                    <EAMInput
                        elementInfo={{ ...assetFields.location, readonly: true }}
                        value={request.currentLocationCode}
                        labelStyle={labelStyle}
                    />
                )}
            </div>
        );
    };

    renderEquipmentSingle = () => {
        const { assetFields, request, applicationData, translations, lists } = this.props;
        const equipment = request.equipment[0];

        // Table with physical characteristics
        const headers = [
            `${assetFields.udfnum07.text} (${assetFields.udfnum07.udfUom})`,
            `${assetFields.udfnum08.text} (${assetFields.udfnum08.udfUom})`,
            `${assetFields.udfnum09.text} (${assetFields.udfnum09.udfUom})`,
            `${assetFields.udfnum10.text} (${assetFields.udfnum10.udfUom})`,
            `${assetFields.equipmentvalue.text} (${applicationData.eqpValueCurrency})`,
        ];
        const propCodes = ['length', 'width', 'height', 'weight', 'equipmentValue'];
        const data = [
            {
                length: equipment.length,
                width: equipment.width,
                height: equipment.height,
                weight: equipment.weight,
                equipmentValue: equipment.equipmentValue,
            },
        ];

        return (
            <div style={{ width: '100%', height: '100%' }}>
                {/* Equipment Code - and serial */}
                <EAMInput
                    elementInfo={{ ...assetFields.equipmentno, readonly: true }}
                    value={equipment.equipmentCode + (equipment.serialNumber ? ` (${equipment.serialNumber})` : '')}
                    labelStyle={labelStyle}
                />

                {/* Equipment Description */}
                <EAMInput
                    elementInfo={{ ...assetFields.equipmentdesc, readonly: true }}
                    value={equipment.equipmentDesc}
                    labelStyle={labelStyle}
                />

                {/* Equipment Responsible */}
                <EAMAutocomplete
                    elementInfo={{ ...assetFields.eqpResponsible, readonly: true }}
                    value={request.equipmentRespCode}
                    valueDesc={request.equipmentRespDesc}
                    labelStyle={labelStyle}
                />

                {/* Future Location */}
                {request.futureLocationCode && (
                    <EAMInput
                        elementInfo={{ ...assetFields.futureLocation, readonly: true }}
                        value={request.futureLocationCode}
                        labelStyle={labelStyle}
                    />
                )}

                {/* Current Location */}
                {request.currentLocationCode && (
                    <EAMInput
                        elementInfo={{ ...assetFields.location, readonly: true }}
                        value={request.currentLocationCode}
                        labelStyle={labelStyle}
                    />
                )}

                {/* Last Location */}
                {equipment.lastLocationCode && (
                    <EAMInput
                        elementInfo={{ ...assetFields.lastLocation, readonly: true }}
                        value={equipment.lastLocationCode}
                        labelStyle={labelStyle}
                    />
                )}

                {/* Family */}
                <EAMSelect
                    elementInfo={{ ...assetFields.udfchar21, readonly: true }}
                    valueKey="family"
                    value={equipment.family}
                    values={lists.objFamily}
                    labelStyle={labelStyle}
                />

                {/* Main Material */}
                <EAMSelect
                    elementInfo={{ ...assetFields.udfchar22, readonly: true }}
                    valueKey="mainMaterial"
                    value={equipment.mainMaterial}
                    values={lists.objMainMaterial}
                    labelStyle={labelStyle}
                />

                {/* Physical Characteristics */}
                <h5>{translations.EQPCHARACTERISTIC}</h5>
                <EISTable data={data} headers={headers} propCodes={propCodes} maxMobileSize={600} />
            </div>
        );
    };

    renderMeasurementData = () => {
        const { request, woFields, customFields, constants, customFieldsDef, translations, applicationData, lang } =
            this.props;
        return (
            <div style={{ width: '100%', height: '100%' }}>
                {/* Department */}
                <EAMInput
                    elementInfo={{ ...woFields.department, readonly: true }}
                    value={request.department}
                    labelStyle={labelStyle}
                />

                {/* Future Action */}
                <EAMSelect
                    elementInfo={{ ...customFields[constants.cfpFutureAction], readonly: true }}
                    value={request.futureAction}
                    values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpFutureAction], true)}
                    labelStyle={labelStyle}
                />

                {/* Associated risks */}
                {applicationData.isHazardsFeatureActive ? null : (
                    <EAMSelect
                        elementInfo={{ ...customFields[constants.cfpAssociatedRisks], readonly: true }}
                        value={request.associatedRisk}
                        values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpAssociatedRisks], true)}
                        labelStyle={labelStyle}
                    />
                )}

                {/* EDH Document */}
                <EAMSelect
                    elementInfo={{
                        ...customFields[constants.cfpEdhDocument],
                        attribute: this.getEDHDocumentAttribute(),
                        readonly: true,
                    }}
                    value={request.edhDocument}
                    values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpEdhDocument], true)}
                    labelStyle={labelStyle}
                    placeholder={translations.NOEDHDOC}
                />

                {/* Type of transport */}
                <EAMSelect
                    elementInfo={{
                        ...customFields[constants.cfpTypeOfTransport],
                        attribute: this.getTransRequestAttribute(),
                        readonly: true,
                    }}
                    value={request.typeOfTransport}
                    values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpTypeOfTransport], true)}
                    labelStyle={labelStyle}
                />

                {/* Storage operation */}
                <EAMSelect
                    elementInfo={{
                        ...customFields[constants.cfpStorageOperation],
                        attribute: this.getStorageAttribute(),
                        readonly: true,
                    }}
                    value={request.storageOperation}
                    values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpStorageOperation])}
                    labelStyle={labelStyle}
                />

                {/* Transport required */}
                <EAMSelect
                    elementInfo={{
                        ...customFields[constants.cfpTransportRequired],
                        attribute: this.getStorageAttribute(),
                        readonly: true,
                    }}
                    value={request.transportRequired}
                    values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpTransportRequired])}
                    labelStyle={labelStyle}
                />

                {/* Equipment destination */}
                <EAMInput
                    elementInfo={{
                        ...customFields[constants.cfpEquipmentDestination],
                        attribute: this.getDestinationAttribute(),
                        readonly: true,
                    }}
                    value={request.equipmentDestination}
                    labelStyle={labelStyle}
                />

                {/* Equipment destination if radioactive */}
                <EAMInput
                    elementInfo={{
                        ...customFields[constants.cfpEquipmentDestinationRad],
                        attribute: this.getDestinationAttribute(),
                        readonly: true,
                    }}
                    value={request.destinationIfRadioactive}
                    labelStyle={labelStyle}
                />

                {/* Measurement deadline */}
                <EAMDatePicker
                    elementInfo={{ ...woFields.schedstartdate, readonly: true }}
                    value={request.measurementDeadline}
                    labelStyle={labelStyle}
                    localeString={lang}
                    disablePast
                />

                {/* Measurement Comments */}
                <EAMInput
                    elementInfo={{ ...woFields.measurementComments, readonly: true }}
                    value={request.measurementComments}
                    labelStyle={labelStyle}
                />
            </div>
        );
    };

    isUpdateLocation = () => {
        const { request, constants, action } = this.props;
        return action === 'updateloc' || request.futureAction === constants.futureActionVacuumDeclare;
    };

    render() {
        const { translations, request, userData } = this.props;
        const { equipment } = request;
        const displayComments = !!(equipment.length === 1 && equipment[0].inforEquipment);
        return (
            <Grid container spacing={8}>
                <Grid item md={6} sm={12} xs={12}>
                    <EISPanel heading={translations.EQPDETANDPHY}>{this.renderEquipmentData()}</EISPanel>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    {!this.isUpdateLocation() && (
                        <EISPanel heading={translations.DETAILSRPMEAS}>{this.renderMeasurementData()}</EISPanel>
                    )}
                    {displayComments && (
                        <CommentsContainer
                            title={translations.EQPCOMMENTS}
                            ref={(comments) => {
                                this.comments = comments;
                            }}
                            entityCode="OBJ"
                            entityKeyCode={equipment[0].equipmentCode}
                            userDesc={userData.eamAccount.userDesc}
                            readComments={WSComments.readComments}
                        />
                    )}
                </Grid>
            </Grid>
        );
    }
}

export default RPMRConfirm;
