/* eslint-disable react/no-array-index-key */

import React from 'react';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import BlockUi from 'react-block-ui';
import Tools from '../../../tools/Tools';
import WSRPMRequest from '../../../tools/rest/WSRPMRequest';
import RPMRSearch from './steps/RPMRSearch';
import RPMREquipment from './steps/RPMREquipment';
import RPMRResults from './steps/RPMRResults';
import RPMRLocation from './steps/RPMRLocation';
import RPMRConfirm from './steps/RPMRConfirm';
import RPMRActions from './steps/RPMRActions';
import RPMRDetails from './steps/RPMRDetails';
import RPMRRequest from './steps/RPMRRequest';
import RPMRequestControls from './RPMRequestControls';
import TRECPage from '../TRECPage';
import StaticMessage from '../../components/staticmessage/StaticMessage';
import { handleError } from 'tools/TrecNotifications';

const actionsToSearch = ['declarewaste', 'moveatcern', 'storageatcern', 'dispatch']; // rpmrequest v1 does not use declarewaste anymore

class RPMRequest extends TRECPage {
    state = {
        activeStep: 0,
        action: undefined,
        steps: [],
        request: undefined,
        measurementNumber: undefined,
        wizardFinish: false,
        equipments: undefined,
        isSaving: false,
        lastMeasurement: undefined,
        lastMeasurementNumber: undefined,
    };

    stepsRef = [];

    UNSAFE_componentWillMount() {
        // Get action from the url
        const action = Tools.getURLParameterByName('action') || 'requestrp';
        // Save the action
        const equipment = Tools.getURLParameterByName('equipment');

        this.setState(() => ({ action, equipments: equipment }));
        // Init RP Request
        WSRPMRequest.initRPMRequest(action, this.props.lang)
            .then((response) => {
                // Set the request
                this.setState({ request: response.body.data });
                // If action is : Declare Waste, Move at CERN, Store, Dispatch. Step will be the search
                if (actionsToSearch.includes(action)) {
                    // Step will be the second (I.E Search)
                    this.setState({ activeStep: 1 });
                }
            })
            .catch(handleError);
    }

    /**
     * To calculate if the user is an officer that can perform the request
     */
    userIsRPOfficer = () => {
        // The user is an officer if he/she has the access to the RPMEASUREMENTS menu
        let rpMeasurementsMenu;
        const menus = this.props.screenData[this.props.lang].menu[this.props.menuType];
        for (let i = 0; i < menus.length; i++) {
            if (menus[i].menuCode === 'RPMEASUREMENTS') {
                rpMeasurementsMenu = menus[i];
                break;
            }
        }
        // Access if there is the menu and it is not read only
        return rpMeasurementsMenu && !rpMeasurementsMenu.readOnly;
    };

    handleNext = () => {
        const { activeStep } = this.state;

        // Validate step
        const isValid = this.stepsRef[activeStep].validate();
        if (isValid) {
            // Jump to next step
            this.setState(() => ({
                activeStep: activeStep + 1,
            }));
        }
    };

    /**
     * This will call the service to create the RP Measurement Request or to Update the location of
     * an equipment after the measurement has been performed
     */
    handleSave = () => {
        const isUpdateLocation =
            this.state.action === 'updateloc' ||
            (this.state.action === 'vacuum' &&
                this.state.request.futureAction === this.props.constants.futureActionVacuumDeclare);
        // Is saving
        this.setState(() => ({ isSaving: true }));
        if (isUpdateLocation) {
            // Update equipment location
            WSRPMRequest.updateEquipmentLocation(this.state.request)
                .then((response) => {
                    // Update data with the response
                    this.setState(() => ({
                        wizardFinish: true,
                        measurementNumber: response.body.data,
                        isSaving: false,
                    }));
                })
                .catch((error) => {
                    handleError(error);
                    this.setState(() => ({ isSaving: false }));
                });
        } else {
            // Regular RP Measurement request
            WSRPMRequest.createRPMRequest(this.state.request)
                .then((response) => {
                    // Update data with the response
                    const measurementNumber = response.body.data;
                    this.setState(() => ({ wizardFinish: true, measurementNumber, isSaving: false }));
                })
                .catch((error) => {
                    handleError(error);
                    this.setState(() => ({ isSaving: false }));
                });
        }
    };

    handlePerform = () => {
        const { constants } = this.props;
        const measurementNumberList = this.state.measurementNumber
            .split(constants.paramSeparator)
            .map((s) => s.split(':')[1].trim())
            .map((elem) => `wo=${elem}`)
            .join('&');
        this.props.history.push(`/rpmperform/?${measurementNumberList}`);
    };

    handleBack = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1,
        });
    };

    handleCancel = () => {
        this.props.history.push('/menu');
    };

    /**
     * Function to update a property in the request that is going to be generated
     * @param key The key to the prop. Ex: futureAction
     * @param value The new value of the property
     */
    updateRequestProperty = (key, value) => {
        let valueUpdate;
        if (key === 'equipment') {
            valueUpdate = value;
        } else if (value) {
            if (Array.isArray(value)) valueUpdate = value.map((elem) => elem.code).toString();
            else valueUpdate = value;
        }
        if (key !== 'equipments') {
            this.setState((prevState) => ({ request: { ...prevState.request, [key]: valueUpdate } }));
        } else {
            /* Equipment */
            // Set just the codes
            this.setState(() => ({
                equipments: valueUpdate
                    ?.split(/,| |\n/)
                    .filter((code) => code)
                    .join(','),
            }));
        }
    };

    /**
     * To update a property of one of the equipments selected for the measurement
     * @param key The key of the property
     * @param value The value of the property
     * @param indexUpd The index in the array of equipments
     */
    updateEquipmentProperty = (key, value, indexUpd) => {
        let valueUpdate;
        // Value update
        if (value) {
            if (Array.isArray(value)) valueUpdate = value.map((elem) => elem.code).toString();
            else valueUpdate = value;
        }
        // Set the new array updated
        this.setState((prevState) => {
            // Create the new array replacing the property
            const equipment = prevState.request.equipment.map((elem, index) => {
                if (index === indexUpd) {
                    return {
                        ...elem,
                        [key]: valueUpdate,
                    };
                }
                return elem;
            });
            return { request: { ...prevState.request, equipment } };
        });
    };

    setBlockingScreen = (blocking) => {
        // Blocking === Saving
        this.setState(() => ({ isSaving: blocking }));
    };

    setLastMeasurementData = (lastMeasurement, lastMeasurementNumber) => {
        this.setState(() => ({
            lastMeasurement,
            lastMeasurementNumber,
        }));
    };

    /**
     * Calculates the steps to be displayed in the wizard according to the action and the future action selected
     */
    calculateSteps = () => {
        let steps;
        const { action } = this.state;
        const { futureAction } = this.state.request;
        // Props to be passed to the children components
        const props = {
            applicationData: this.props.applicationData,
            userData: this.props.userData,
            lang: this.props.lang,
            bufferZone: this.props.bufferZone,
            action: this.state.action,
            request: this.state.request,
            translations: this.props.translations,
            updateRequestProperty: this.updateRequestProperty,
            setPage: this.props.setPage,
            woFields: this.props.screenData[this.props.lang].woFields,
            assetFields: this.props.screenData[this.props.lang].assetFields,
            customFields: this.props.screenData[this.props.lang].customFields,
            constants: this.props.constants,
            customFieldsDef: this.props.screenData[this.props.lang].customFieldsDef,
            lists: this.props.screenData[this.props.lang].lists,
            equipments: this.state.equipments,
            handleBack: this.handleBack,
            handleNext: this.handleNext.bind(this),
            updateEquipmentProperty: this.updateEquipmentProperty,
            setBlockingScreen: this.setBlockingScreen,
            lastMeasurement: this.state.lastMeasurement,
            lastMeasurementNumber: this.state.lastMeasurementNumber,
            setLastMeasurementData: this.setLastMeasurementData,
        };
        if (action === 'updateloc') {
            // Update Location
            steps = [
                {
                    label: this.props.translations.SEARCH,
                    content: (
                        <RPMRSearch
                            onRef={(step) => {
                                this.stepsRef[0] = step;
                            }}
                            {...props}
                        />
                    ),
                },
                {
                    label: this.props.translations.EQUIPMENT,
                    content: (
                        <RPMREquipment
                            ref={(step) => {
                                this.stepsRef[1] = step;
                            }}
                            {...props}
                        />
                    ),
                },
                {
                    label: this.props.translations.RESULTS,
                    content: (
                        <RPMRResults
                            onRef={(step) => {
                                this.stepsRef[2] = step;
                            }}
                            {...props}
                        />
                    ),
                },
                {
                    label: this.props.translations.LOCATION,
                    content: (
                        <RPMRLocation
                            ref={(step) => {
                                this.stepsRef[3] = step;
                            }}
                            {...props}
                        />
                    ),
                },
                {
                    label: this.props.translations.CONFIRM,
                    content: (
                        <RPMRConfirm
                            ref={(step) => {
                                this.stepsRef[4] = step;
                            }}
                            {...props}
                        />
                    ),
                },
            ];
        } else if (action === 'vacuum' && futureAction === this.props.constants.futureActionVacuumDeclare) {
            // Update location with the actions still
            steps = [
                {
                    label: this.props.translations.ACTIONS,
                    content: (
                        <RPMRActions
                            ref={(step) => {
                                this.stepsRef[0] = step;
                            }}
                            {...props}
                        />
                    ),
                    isActions: true,
                },
                {
                    label: this.props.translations.SEARCH,
                    content: (
                        <RPMRSearch
                            onRef={(step) => {
                                this.stepsRef[1] = step;
                            }}
                            {...props}
                        />
                    ),
                },
                {
                    label: this.props.translations.EQUIPMENT,
                    content: (
                        <RPMREquipment
                            ref={(step) => {
                                this.stepsRef[2] = step;
                            }}
                            {...props}
                        />
                    ),
                },
                {
                    label: this.props.translations.LOCATION,
                    content: (
                        <RPMRLocation
                            ref={(step) => {
                                this.stepsRef[3] = step;
                            }}
                            {...props}
                        />
                    ),
                },
                {
                    label: this.props.translations.CONFIRM,
                    content: (
                        <RPMRConfirm
                            ref={(step) => {
                                this.stepsRef[4] = step;
                            }}
                            {...props}
                        />
                    ),
                },
            ];
        } else {
            // Normal Wizard
            steps = [
                {
                    label: this.props.translations.ACTIONS,
                    content: (
                        <RPMRActions
                            ref={(step) => {
                                this.stepsRef[0] = step;
                            }}
                            {...props}
                        />
                    ),
                    isActions: true,
                },
                {
                    label: this.props.translations.SEARCH,
                    content: (
                        <RPMRSearch
                            onRef={(step) => {
                                this.stepsRef[1] = step;
                            }}
                            {...props}
                        />
                    ),
                },
                {
                    label: this.props.translations.EQUIPMENT,
                    content: (
                        <RPMREquipment
                            ref={(step) => {
                                this.stepsRef[2] = step;
                            }}
                            {...props}
                        />
                    ),
                },
                {
                    label: this.props.translations.LOCATION,
                    content: (
                        <RPMRLocation
                            ref={(step) => {
                                this.stepsRef[3] = step;
                            }}
                            {...props}
                        />
                    ),
                },
                {
                    label: this.props.translations.REQUEST,
                    content: (
                        <RPMRRequest
                            ref={(step) => {
                                this.stepsRef[4] = step;
                            }}
                            {...props}
                        />
                    ),
                },
                {
                    label: this.props.translations.DETAILS,
                    content: (
                        <RPMRDetails
                            ref={(step) => {
                                this.stepsRef[5] = step;
                            }}
                            {...props}
                        />
                    ),
                },
                {
                    label: this.props.translations.CONFIRM,
                    content: (
                        <RPMRConfirm
                            ref={(step) => {
                                this.stepsRef[6] = step;
                            }}
                            {...props}
                        />
                    ),
                },
            ];
        }
        // Return the steps
        return steps;
    };

    /**
     * Render messages when the wizard has finished correctly
     */
    renderFinishMessages = () => {
        const { translations, constants } = this.props;
        const isUpdateLocation =
            this.state.action === 'updateloc' ||
            (this.state.action === 'vacuum' &&
                this.state.request.futureAction === this.props.constants.futureActionVacuumDeclare);
        const message = [];
        if (!isUpdateLocation) {
            message.push(
                translations.RPMEASCREATED.replace(
                    '{0}',
                    this.state.measurementNumber.split(constants.paramSeparator).toString()
                )
            );
        } else {
            message.push(translations.EQPLOCUPDAT);
        }
        const { futureAction } = this.state.request;
        if (futureAction === constants.futureActionVacuumDeclare) {
            // Check for the lock codes
            this.state.request.equipment.forEach((elem) => {
                if (elem.lockCode) {
                    message.push(
                        translations.LOCKCODEFOREQP.replace('{0}', elem.equipmentCode).replace('{1}', elem.lockCode)
                    );
                }
            });
        }
        return <StaticMessage message={message} />;
    };

    renderPage() {
        const { activeStep } = this.state;
        // Only render when there is the request
        if (!this.state.request) {
            return <div />;
        }
        // Calculate the steps
        const steps = this.calculateSteps();
        // Properties to the controls
        const props = {
            translations: this.props.translations,
            handlers: {
                handleCancel: this.handleCancel,
                handleBack: this.handleBack,
                handleNext: this.handleNext,
                handleSave: this.handleSave,
                handlePerform: this.handlePerform,
                logout: this.props.logout,
            },
            wizardFinish: this.state.wizardFinish,
            steps,
            activeStep,
            measurementNumber: this.state.measurementNumber,
            userIsRPOfficer: this.userIsRPOfficer(),
            action: this.state.action,
            request: this.state.request,
            constants: this.props.constants,
            equipments: this.state.equipments,
        };
        return (
            <BlockUi tag="div" blocking={this.state.isSaving} style={{ height: '100%', width: '100%' }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepLabel>{step.label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    <div>
                        {this.state.wizardFinish && this.renderFinishMessages()}
                        {steps[activeStep].content}
                        <RPMRequestControls {...props} />
                    </div>
                </div>
            </BlockUi>
        );
    }
}

export default RPMRequest;
