export const RWELIMINATION_ACTION_TYPES = {
    RESET: 'RESET',
    UPDATE_RW_ELIMINATION: 'UPDATE_RW_ELIMINATION',
    UPDATE_RW_ELIMINATION_PARTIAL: 'UPDATE_RW_ELIMINATION_PARTIAL',
    UPDATE_RW_ELIMINATION_PACKAGE: 'UPDATE_RW_ELIMINATION_PACKAGE',
};

export const resetRwElimination = ({ userData, applyDefaults = false } = {}) => ({
    type: RWELIMINATION_ACTION_TYPES.RESET,
    value: { userData, applyDefaults },
});

export const updateRWEliminationPackage = (eqCode, value) => ({
    type: RWELIMINATION_ACTION_TYPES.UPDATE_RW_ELIMINATION_PACKAGE,
    eqCode,
    value,
});

export const updateRWElimination = (data) => ({
    type: RWELIMINATION_ACTION_TYPES.UPDATE_RW_ELIMINATION,
    data,
});

export const updateRWEliminationPartial = (data, path) => ({
    type: RWELIMINATION_ACTION_TYPES.UPDATE_RW_ELIMINATION_PARTIAL,
    path,
    value: data,
});

export default {
    resetRwElimination,
    updateRWEliminationPackage,
    updateRWElimination,
    updateRWEliminationPartial,
};
