import { openConfirmDialog } from 'react-eis-components/dist/actions/uiActions';
import { connect } from 'react-redux';
import { setPage, updateLayout } from '../../../actions/layoutActions';
import RWChecks from './RWChecks';

function mapStateToProps(state) {
    return {
        applicationData: state.application.applicationData,
        rwpDropdowns: state.application.rwpDropdowns,
        screenData: state.application.screenData,
        lang: state.layout.lang,
        translations: state.application.screenData[state.layout.lang].translations.TREC_MENU,
        masterPage: 'RWCHECKS',
        page: 'RWCHECKS',
        menu: state.application.screenData[state.layout.lang].menu[state.application.menuType],
        bufferZone: state.application.bufferZone,
        constants: state.application.constants,
    };
}

const RWChecksContainer = connect(mapStateToProps, {
    updateLayout,
    setPage,
    openConfirmDialog,
})(RWChecks);

export default RWChecksContainer;
