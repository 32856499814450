import queryString from 'query-string';
import { Link } from 'react-router-dom';

const ROUTES = {
    base: '/',
    menu: '/menu',
    login: '/login',
    rpsm: '/rpsm',
    rpamRequest: '/rparequest',
    rpamRequestCreate: '/rparequest/create',
    rpamRequestEdit: '/rparequest/edit/:requestwonumber',
    rpamRequestClone: '/rparequest/clone/:requestwonumber',
    rpamRequestReadOnly: '/rparequest/readonly/:requestwonumber',
    rpamAnalysisJob: '/rpanalysis/:jobnumber',
    rpamAnalysis: '/rpanalysis',
    rpMeasurements: '/rpmeasurements',
    rpmSearch: '/rpmsearch',
    searchRequests: '/searchRequests',
    reports: '/reports',
    rwChecks: '/rwchecks',
    rwInconsistencyChecks: '/rwinconsistencychecks',
    rwcPerform: '/rwcperform',
    rpmRequest: '/rpmrequest',
    rpmRequest2: '/rpm/request',
    waterReleaseRequest: '/waterrelease/request',
    waterRelease: '/waterrelease/request/:waterReleaseNumber',
    waterReleaseSearch: '/waterrelease/search',
    waterReleaseDepositSample: '/waterrelease/deposit',
    rwProcessing: '/rwp',
    rwProcessingCreate: '/rwp/create',
    rwProcessingEdit: '/rwp/edit/:jobID',
    rwProcessingRead: '/rwp/readonly/:jobID',
    rwProcessingSearch: '/rwp/search',
    inventory: '/inventory',
    edhdocmng: '/edhdocmng',
    jobsReport: '/jobsreport',
    rpmPerform: '/rpmperform',
    rwManagement: '/rwmanagement',
    container: '/container',
    workOrdersRedirect: '/workorders/:workOrderID/redirect',
    redirect: '/redirect/:workOrderID',
    equipment: '/equipment',
    equipmentShow: '/equipment/:equipmentID',
    equipmentOverview: '/equipment/:equipmentID/overview',
    equipmentHistory: '/equipment/:equipmentID/history',
    equipmentHierarchy: '/equipment/:equipmentID/hierarchy',
    equipmentAudit: '/equipment/:equipmentID/audit',
    equipmentEvents: '/equipment/:equipmentID/events',
    equipmentTree: '/equipmenttree/:equipmentID',
    settings: '/settings',
    notFound: '/404',
    vacuumCleanerManage: '/vaccleaner/manage',
    vacuumCleanerStock: '/vaccleaner/stock',
    vacuumCleanerReservations: '/vaccleaner/reservations',
    vaccleanerRequest: '/vaccleaner/request',
    vaccleanerRequestEdit: '/vaccleaner/request/:vaccleanerNumber',
    rwElimination: '/rwelimination',
    rwEliminationAssets: '/rwelimination/assets',
    rwEliminationCreate: '/rwelimination/create',
    rwEliminationEdit: '/rwelimination/edit/:eliminationNumber',
    releaseNotes: '/releasenotes',
};

const generateRoute = (path, params = {}) =>
    Object.keys(params).reduce((acc, key) => {
        const paramRegex = new RegExp(`:${key}`, 'g');
        return acc.replace(paramRegex, params[key]);
    }, path);

const withQueryParams = ({ path, queryParams }) =>
    `${path}?${queryString.stringify(queryParams, { arrayFormat: 'comma' })}`;

const getExternalLink = ({ url, text, newTab = true }) => (
    <a target={newTab ? '_blank' : '_self'} href={url} rel="noreferrer">
        {text}
    </a>
);

const getInternalLink = ({ route, text, newTab = false, style = {}, state = undefined, otherProps }) => {
    const [url, hash] = route.split('#', 2);
    const { url: pathname, query } = queryString.parseUrl(url);
    return (
        <Link
            target={newTab ? '_blank' : ''}
            style={style}
            to={{
                pathname,
                search: `?${queryString.stringify(query)}`,
                hash: hash === undefined ? undefined : `#${hash}`,
                state,
            }}
            {...otherProps}
        >
            {text}
        </Link>
    );
};

export { ROUTES, generateRoute, withQueryParams, getExternalLink, getInternalLink };
