import { connect } from 'react-redux';
import Login from './Login';
import initializeApplication from '../../../actions/actions';
import { setUser } from '../../../actions/applicationActions';

const mapStateToProps = (state) => ({
    translations: state.application.screenData[state.layout.lang].translations.TREC_LOGIN,
    user: state.application.userData.eamAccount.userCode,
    bufferZoneAccount: state.application.applicationData.bufferZoneAccount,
    bufferZone: state.application.bufferZone,
});

const LoginContainer = connect(mapStateToProps, {
    initializeApplication,
    setUser,
})(Login);

export default LoginContainer;
