import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import { TABLE_DATA_TYPES } from 'enums/Constants';
import React from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import WSRWPProcessing from 'tools/rest/WSRWPProcessing';
import EquipmentAutocomplete from '../../../components/autocomplete/EquipmentAutocomplete';
import InputGenerator from '../../rpmrequest2/InputGenerator';
import { RWP_KEYS, TRANSLATION_KEYS } from '../RWPConstants';
import WSEquipment from '../../../../tools/rest/WSEquipment';
import RWPBreakUpDialog from '../dialogs/RWPBreakUpDialog';
import { handleError, showWarning } from 'tools/TrecNotifications';

const { ATTRIBUTES } = InputGenerator;

const getInputWasteFieldsReadOnly = ({ rwpGetters, rwpDropdowns, getValues, rwpExtra, rwpExtra2 }) => {
    const rwpProperties = rwpGetters.getRWPProperties();
    return [
        {
            code: RWP_KEYS.FACILITY,
            type: TABLE_DATA_TYPES.SELECT,
            getAttribute: () => ATTRIBUTES.READONLY,
            translationKey: 'FACILITIES_ALLOWED_LIST',
            values: getValues({
                key: RWP_KEYS.FACILITY,
                object: rwpProperties,
                values: rwpDropdowns[RWP_KEYS.FACILITY],
            }),
            customValue: () =>
                getValues({
                    key: RWP_KEYS.FACILITY,
                    object: rwpProperties,
                    values: rwpDropdowns[RWP_KEYS.FACILITY],
                }).map((s) => s.code),
            customInputProps: () => ({
                multi: true,
            }),
        },
        {
            code: RWP_KEYS.PROCESSING,
            type: TABLE_DATA_TYPES.INPUT,
            getAttribute: () => ATTRIBUTES.READONLY,
            translationKey: TRANSLATION_KEYS.PROCESSING_FIELD,

            values: getValues({
                key: RWP_KEYS.PROCESSING,
                object: rwpProperties,
                values: rwpDropdowns[RWP_KEYS.PROCESSING],
            }),
        },
        {
            code: 'RWP_EXTRA',
            type: TABLE_DATA_TYPES.INPUT,
            getAttribute: () => ATTRIBUTES.READONLY,
            translationKey: TRANSLATION_KEYS.RWP_EXTRA,
            customValue: () => rwpExtra,
        },
        {
            code: 'RWP_EXTRA2',
            type: TABLE_DATA_TYPES.INPUT,
            getAttribute: () => ATTRIBUTES.READONLY,
            translationKey: TRANSLATION_KEYS.RWP_EXTRA2,
            customValue: () => rwpExtra2,
        },
    ];
};

const getInputWasteFields = ({ rwpGetters, rwpDropdowns, getValues, screenData, rwpExtra, rwpExtra2 }) => {
    const rwpProperties = { ...rwpGetters.getRWPProperties(), rwpExtra, rwpExtra2 };
    return [
        {
            code: RWP_KEYS.JOB_NUMBER,
            type: TABLE_DATA_TYPES.INPUT,
            getAttribute: () => (rwpProperties[RWP_KEYS.JOB_NUMBER] ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN),
            translationKey: TRANSLATION_KEYS.OUTPUT_JOB_NUMBER_FIELD,
        },
        {
            code: RWP_KEYS.USER_STATUS,
            type: TABLE_DATA_TYPES.INPUT,
            getAttribute: () => (rwpProperties[RWP_KEYS.JOB_NUMBER] ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN),
            customValue: (obj) =>
                (screenData.lists.jobStatuses.find((codeDesc) => codeDesc.code === obj[RWP_KEYS.USER_STATUS]) || {})
                    .desc,
            translationKey: TRANSLATION_KEYS.STATUS_CODE,
        },
        {
            code: RWP_KEYS.PROCESSING_METHOD,
            type: TABLE_DATA_TYPES.SELECT,
            getAttribute: () =>
                ((valuesList, currentVal) =>
                    !currentVal || valuesList.find((s) => s.code === currentVal)
                        ? ATTRIBUTES.REQUIRED
                        : ATTRIBUTES.READONLY)(
                    getValues({
                        key: RWP_KEYS.PROCESSING_METHOD,
                        object: rwpProperties,
                        values: rwpDropdowns[RWP_KEYS.PROCESSING_METHOD],
                    }),
                    rwpProperties[RWP_KEYS.PROCESSING_METHOD]
                ),
            translationKey: RWP_KEYS.PROCESSING_METHOD,
            values: ((valuesList, currentVal) =>
                !currentVal || valuesList.find((s) => s.code === currentVal)
                    ? valuesList
                    : rwpDropdowns[RWP_KEYS.PROCESSING_METHOD].filter((s) => s.code === currentVal))(
                getValues({
                    key: RWP_KEYS.PROCESSING_METHOD,
                    object: rwpProperties,
                    values: rwpDropdowns[RWP_KEYS.PROCESSING_METHOD],
                }),
                rwpProperties[RWP_KEYS.PROCESSING_METHOD]
            ),
        },
        {
            code: RWP_KEYS.RW_ELIM_PATHWAY,
            type: TABLE_DATA_TYPES.SELECT,
            getAttribute: () =>
                rwpProperties[RWP_KEYS.PROCESSING_METHOD] === 'ELIM' ? ATTRIBUTES.REQUIRED : ATTRIBUTES.OPTIONAL,
            translationKey: RWP_KEYS.RW_ELIM_PATHWAY,
            values:
                rwpProperties[RWP_KEYS.PROCESSING_METHOD] || rwpProperties[RWP_KEYS.PROCESSING_METHOD] !== 'ELIM'
                    ? ((valuesList, currentVal) =>
                          !currentVal || valuesList?.includes(currentVal)
                              ? valuesList
                              : rwpDropdowns[RWP_KEYS.RW_ELIM_PATHWAY].filter((s) => s.code === currentVal))(
                          getValues({
                              key: RWP_KEYS.RW_ELIM_PATHWAY,
                              object: rwpProperties,
                              values: rwpDropdowns[RWP_KEYS.RW_ELIM_PATHWAY],
                          }),
                          rwpProperties[RWP_KEYS.RW_ELIM_PATHWAY]
                      )
                    : [],
        },
        {
            code: RWP_KEYS.FACILITIES_ALLOWED,
            type: TABLE_DATA_TYPES.SELECT,
            getAttribute: () => ATTRIBUTES.REQUIRED,
            translationKey: RWP_KEYS.FACILITIES_ALLOWED,
            values: getValues({
                key: RWP_KEYS.FACILITIES_ALLOWED,
                object: rwpProperties,
                values: rwpDropdowns[RWP_KEYS.FACILITIES_ALLOWED],
            }),
        },
        {
            code: RWP_KEYS.DECAY_PERIOD,
            type: TABLE_DATA_TYPES.SELECT,
            getAttribute: () =>
                ((valuesList, currentVal) =>
                    !currentVal || valuesList.find((s) => s.code === currentVal)
                        ? ATTRIBUTES.REQUIRED
                        : ATTRIBUTES.READONLY)(
                    getValues({
                        key: RWP_KEYS.DECAY_PERIOD,
                        object: rwpProperties,
                        values: rwpDropdowns[RWP_KEYS.DECAY_PERIOD],
                    }),
                    rwpProperties[RWP_KEYS.DECAY_PERIOD]
                ),
            translationKey: RWP_KEYS.DECAY_PERIOD,
            values: ((valuesList, currentVal) =>
                !currentVal || valuesList.find((s) => s.code === currentVal)
                    ? valuesList
                    : rwpDropdowns[RWP_KEYS.DECAY_PERIOD].filter((s) => s.code === currentVal))(
                getValues({
                    key: RWP_KEYS.DECAY_PERIOD,
                    object: rwpProperties,
                    values: rwpDropdowns[RWP_KEYS.DECAY_PERIOD],
                }),
                rwpProperties[RWP_KEYS.DECAY_PERIOD]
            ),
        },
        {
            code: RWP_KEYS.START_DATE,
            type: TABLE_DATA_TYPES.DATE,
            getAttribute: () => ATTRIBUTES.REQUIRED,
            translationKey: TRANSLATION_KEYS.START_DATE_FIELD,
            customInputProps: () => ({
                timestamp: true,
                disableFuture: true,
            }),
        },
        {
            // TODO, show code and desc when rendering
            code: RWP_KEYS.OPERATOR,
            type: TABLE_DATA_TYPES.AUTOCOMPLETE,
            getAttribute: () => ATTRIBUTES.REQUIRED,
            translationKey: TRANSLATION_KEYS.OPERATORS_FIELD,
            autocompleteType: WSAutocomplete.autocompleteEmployee,
            descKey: '_operatorDesc',
            customInputProps: () => ({
                // multi: true,
                autoSelectSingleElement: true,
            }),
            // customValue: (object, field) => (object[field.code] || []).join(','),
            // updateObject: handleOperatorsUpdate,
        },
        {
            code: RWP_KEYS.CREATED_BY,
            type: TABLE_DATA_TYPES.INPUT,
            getAttribute: () => (rwpProperties[RWP_KEYS.CREATED_BY] ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN),
            translationKey: TRANSLATION_KEYS.CREATED_BY,
        },
        {
            code: RWP_KEYS.CREATION_DATE,
            type: TABLE_DATA_TYPES.DATE,
            getAttribute: () => (rwpProperties[RWP_KEYS.CREATION_DATE] ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN),
            translationKey: TRANSLATION_KEYS.CREATION_DATE,
        },
        {
            code: RWP_KEYS.UPDATED_BY,
            type: TABLE_DATA_TYPES.INPUT,
            getAttribute: () => (rwpProperties[RWP_KEYS.UPDATED_BY] ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN),
            translationKey: TRANSLATION_KEYS.UPDATED_BY,
        },
        {
            code: RWP_KEYS.UPDATION_DATE,
            type: TABLE_DATA_TYPES.DATE,
            getAttribute: () => (rwpProperties[RWP_KEYS.UPDATION_DATE] ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN),
            translationKey: TRANSLATION_KEYS.UPDATION_DATE,
        },
    ];
};

export default class InputWasteStep extends WizardStep {
    formFields = {};

    state = {
        isCheckingBreakUpStatus: false,
        needBreakup: [],
        breakUpDialog: false,
        breakUpPermissions: new Set(),
        panelExpanded: false,
    };

    UNSAFE_componentWillMount() {
        // TODO, set page for help
        // TODO, handle defaults
        const { authorizedMenus } = this.props;
        this.setState({
            breakUpPermissions: new Set(
                authorizedMenus
                    .filter(({ type, code }) => type === 'TB' && code === 'RWP_BREAKUP')
                    .map(({ readOnly }) => (readOnly ? 'R' : 'W'))
            ),
            rwpExtra: authorizedMenus
                .filter(({ type, code }) => type === 'TB' && ['RWP_EXTRA', 'RWP_EXTRA2'].includes(code))
                .map(({ readOnly }) => (readOnly ? 'R' : 'W'))?.[0],
            rwpExtra2: authorizedMenus
                .filter(({ type, code }) => type === 'TB' && code === 'RWP_EXTRA2')
                .map(({ readOnly }) => (readOnly ? 'R' : 'W'))?.[0],
        });
    }

    canContinue = () =>
        Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );

    saveChanges = () => true;

    commitChanges = (callback) => callback();

    handleChangeWasteEquipmentsList = (selectedOptions) => {
        const { applicationData, storeActions, rwpGetters } = this.props;
        const { breakUpPermissions } = this.state;
        const { updateRWPProperties } = storeActions;
        const rwpProperties = rwpGetters.getRWPProperties();
        const maxSelectedEquipments = applicationData.rwpmaxInputWasteEquipments;
        const options = selectedOptions || [];

        if (options.length > maxSelectedEquipments) {
            showWarning(`You cannot select more than ${maxSelectedEquipments} equipments`);
            return;
        }
        const inputWaste = options.map((e) => e.code);
        updateRWPProperties({ inputWaste });

        if (breakUpPermissions.size) {
            const newInputWaste = inputWaste.filter(
                (eq) => !(rwpProperties[RWP_KEYS.INPUT_EQUIPMENT] || []).includes(eq)
            );
            this.setState({ isCheckingBreakUpStatus: true });
            Promise.all(newInputWaste.map((eq) => WSEquipment.getCorrections({ equipmentID: eq, maxLevel: 2 })))
                .then((responses) => {
                    const results = responses.map((r) => r?.body?.data);
                    const needBreakup = results.filter((r) => Object.keys(r.levelsMap).length > 2);
                    this.setState((prevState) => ({
                        ...prevState,
                        needBreakup: [...prevState.needBreakup, ...needBreakup].filter((e) =>
                            inputWaste.includes(e.rootEquipmentCode)
                        ),
                    }));
                })
                .catch(handleError)
                .finally(() => this.setState({ isCheckingBreakUpStatus: false }));
        }
    };

    addRetrievedEquipment = (list, code) => {
        const { rwpGetters } = this.props;
        const rwpProperties = rwpGetters.getRWPProperties();
        const newSelectedOptions = [...new Set([...rwpProperties[RWP_KEYS.INPUT_EQUIPMENT], ...(list || [])])]
            .filter((s) => s !== code)
            .map((e) => ({ code: e }));
        this.handleChangeWasteEquipmentsList(newSelectedOptions);
    };

    handleBreakUp = () => {
        this.setState({ breakUpDialog: true });
    };

    render() {
        const {
            getTranslation,
            rwpGetters,
            screenData,
            storeActions,
            isReadOnly,
            applicationData,
            HeaderInfo,
            constants,
        } = this.props;
        const { rwpExtra, rwpExtra2 } = this.state;

        const inputWasteFields = getInputWasteFields({ ...this.props, rwpExtra, rwpExtra2 });
        const equipmentField = screenData.woFields.equipment;
        const rwpProperties = rwpGetters.getRWPProperties();
        const { isCheckingBreakUpStatus, needBreakup, breakUpDialog, breakUpPermissions, panelExpanded } = this.state;

        return (
            <div style={{ padding: 8 }}>
                <Grid container spacing={8}>
                    <Grid item md={10} sm={12}>
                        <EISPanel
                            heading={getTranslation(TRANSLATION_KEYS.INPUT_WASTE)}
                            detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                            alwaysExpanded
                        >
                            {isCheckingBreakUpStatus ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        padding: '0.5rem 0px',
                                        placeContent: 'flex-end',
                                    }}
                                >
                                    <Typography>Checking breakup status</Typography>
                                    <CircularProgress size={18} style={{ marginLeft: '0.5rem' }} />
                                </div>
                            ) : null}
                            {needBreakup.length && !isCheckingBreakUpStatus ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: 'column',
                                        placeItems: 'flex-end',
                                        padding: '0.5rem 0',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            color: 'orange',
                                            borderRadius: '4px',
                                            fontSize: 'smaller',
                                        }}
                                    >
                                        Containers with second level content detected
                                    </Typography>
                                    <Button onClick={this.handleBreakUp} variant="outlined" color="primary">
                                        Break UP
                                    </Button>
                                </div>
                            ) : null}
                            {breakUpDialog ? (
                                <RWPBreakUpDialog
                                    addRetrievedEquipment={this.addRetrievedEquipment}
                                    open={breakUpDialog}
                                    onClose={() => {
                                        this.setState({
                                            breakUpDialog: false,
                                        });
                                    }}
                                    needBreakup={needBreakup}
                                    setNeedBreakup={(list) => this.setState({ needBreakup: list })}
                                    getTranslation={getTranslation}
                                    applicationData={applicationData}
                                    constants={constants}
                                    breakUpPermissions={breakUpPermissions}
                                />
                            ) : null}
                            {rwpProperties[RWP_KEYS.JOB_NUMBER] && (
                                <EquipmentAutocomplete
                                    formFields={this.formFields}
                                    value={
                                        rwpProperties.inputWaste && rwpProperties.inputWaste.map((e) => ({ code: e }))
                                    }
                                    onChange={this.handleChangeWasteEquipmentsList}
                                    elementInfo={{
                                        ...equipmentField,
                                        text: getTranslation(TRANSLATION_KEYS.INPUT_EQUIPMENT_FIELD),
                                        attribute: 'R',
                                        maxUpdateRecords: applicationData.rwpmaxInputWasteEquipments,
                                        xpath: 'inputWaste',
                                    }}
                                    loadOptions={(query) =>
                                        WSRWPProcessing.getInputEquipmentSuggestions({
                                            jobID: rwpProperties[RWP_KEYS.JOB_NUMBER],
                                            query: query.hint,
                                        })
                                    }
                                    labelStyle={{ minWidth: '200px' }}
                                />
                            )}
                            {inputWasteFields.reduce(
                                (acc, field) => [
                                    ...acc,
                                    <div key={field.code}>
                                        {InputGenerator.generate({
                                            field: {
                                                ...field,
                                                elementInfo: {
                                                    xpath: field.code,
                                                    text: getTranslation(field.translationKey),
                                                },
                                                customInputProps: () => ({
                                                    ...(field.customInputProps ? field.customInputProps() : {}),
                                                    labelStyle: {
                                                        width: 200,
                                                        minWidth: 200,
                                                    },
                                                }),
                                                ...(isReadOnly &&
                                                (!field.getAttribute ||
                                                    field.getAttribute() !== ATTRIBUTES.READONLY_TEXT)
                                                    ? {
                                                          getAttribute: () => ATTRIBUTES.READONLY,
                                                      }
                                                    : {}),
                                            },
                                            object: rwpGetters.getRWPProperties(),
                                            updateObject:
                                                field.updateObject ||
                                                ((key, value) => storeActions.updateRWPProperties({ [key]: value })),
                                            formFields: this.formFields,
                                        })}
                                    </div>,
                                ],
                                []
                            )}
                        </EISPanel>
                        <EISPanel
                            heading={getTranslation(TRANSLATION_KEYS.EXTRA_INFO)}
                            detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                            panelExpanded={panelExpanded}
                            onPanelChange={(s) => this.setState({ panelExpanded: s })}
                        >
                            <HeaderInfo />
                            {getInputWasteFieldsReadOnly({ ...this.props, rwpExtra, rwpExtra2 }).reduce(
                                (acc, field) => [
                                    ...acc,
                                    <div key={field.code}>
                                        {InputGenerator.generate({
                                            field: {
                                                ...field,
                                                elementInfo: {
                                                    xpath: field.code,
                                                    text: getTranslation(field.translationKey),
                                                },
                                                customInputProps: () => ({
                                                    ...(field.customInputProps ? field.customInputProps() : {}),
                                                    labelStyle: {
                                                        width: 200,
                                                        minWidth: 200,
                                                    },
                                                }),
                                                ...(isReadOnly &&
                                                (!field.getAttribute ||
                                                    field.getAttribute() !== ATTRIBUTES.READONLY_TEXT)
                                                    ? {
                                                          getAttribute: () => ATTRIBUTES.READONLY,
                                                      }
                                                    : {}),
                                            },
                                            object: rwpGetters.getRWPProperties(),
                                            updateObject:
                                                field.updateObject ||
                                                ((key, value) => storeActions.updateRWPProperties({ [key]: value })),
                                            formFields: this.formFields,
                                        })}
                                    </div>,
                                ],
                                []
                            )}
                        </EISPanel>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
