import FontIcon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Tools from 'tools/Tools';

const mainDivStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fafafa',
};

const fontIconStyle = {
    fontSize: 30,
    color: 'darkblue',
    margin: 10,
};

const paperStyle = {
    padding: 15,
    margin: 10,
};

const InfoPage = ({ message, title, screenData, lang }) => {
    const getTranslation = Tools.getTranslation({
        screenData,
        lang,
        translationKey: 'TREC_MENU',
    });

    return (
        <div style={mainDivStyle}>
            <Paper style={paperStyle}>
                <Typography type="headline" component="h3">
                    <FontIcon style={fontIconStyle} className="fa fa-user-times" />
                    {getTranslation(title)}
                </Typography>
                <Typography component="p">{getTranslation(message)}</Typography>
            </Paper>
        </div>
    );
};

const Containerize = (Component) =>
    connect(
        (state) => {
            const { layout, application } = state;
            const { screenData, applicationData } = application;
            const { lang } = layout;

            const translations = screenData[lang].translations['TREC_MENU*'];

            return {
                screenData,
                lang,
                applicationData,
                translations,
            };
        },
        {},
        (stateProps, dispatchProps, ownProps) => ({
            ...ownProps,
            ...stateProps,
            ...dispatchProps,
        })
    )(Component);

export default Containerize(InfoPage);
