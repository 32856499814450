import { ANALYSIS_KEYS, TABLE_DATA_TYPES } from 'enums/Constants';
import DEFAULTS from 'enums/Defaults';
import React from 'react';
import RPARAnalysisDetails from './RPARAnalysisDetails';

export const ANALYSIS_TYPE = 'GAMMASPEC';

function RPARGammaSpec(props) {
    const { getTranslation, rpamValues, rparGetters, valuesGrid, dropdowns } = props;
    const numberOfEquipments = Object.keys(rparGetters.getAnalysisTypeEquipments(ANALYSIS_TYPE)).length;
    const batchValues = DEFAULTS.getBatchValues(numberOfEquipments);

    const GammaSpecTableLayout = [
        ...DEFAULTS.getTableLayout({ ANALYSIS_TYPE, dropdowns, valuesGrid, batchValues }),
        {
            code: ANALYSIS_KEYS.LAST_IRRADIATION,
            type: TABLE_DATA_TYPES.SELECT,
            values: () => rpamValues[ANALYSIS_KEYS.LAST_IRRADIATION],
            required: true,
        },
        {
            code: ANALYSIS_KEYS.OFFICIAL_REPORT,
            type: TABLE_DATA_TYPES.CHECKBOX,
        },
        {
            code: ANALYSIS_KEYS.REQUESTED_ACTIVITY_TIME,
            type: TABLE_DATA_TYPES.DATE_TIME,
            defaultValue: '',
        },
        {
            code: ANALYSIS_KEYS.ACTIVE_UNIT,
            type: TABLE_DATA_TYPES.SELECT,
            values: () => rpamValues[ANALYSIS_KEYS.ACTIVE_UNIT],
            required: true,
        },
    ].map((e) => ({
        ...e,
        header: getTranslation(e.code),
    }));

    return (
        <RPARAnalysisDetails
            {...props}
            analysisType={ANALYSIS_TYPE}
            tableLayout={GammaSpecTableLayout}
            batchValues={batchValues}
        />
    );
}

export default RPARGammaSpec;
