import { connect } from 'react-redux';
import { openConfirmDialog } from 'react-eis-components/dist/actions/uiActions';
import RPMPerform from './RPMPerform';
import { setPage, updateLayout } from '../../../actions/layoutActions';
import { logout } from '../../../actions/applicationActions';
import Tools from '../../../tools/Tools';
import { TRANSLATION_KEY } from '../vaccleanermanage/Containerize';

const mapStateToProps = (state) => {
    const { screenData } = state.application;
    const { lang } = state.layout;

    const rwpDropdownsTranslated = Tools.applyToFields(state.application.rwpDropdowns, (list) =>
        list.map((codeDesc) => ({
            ...codeDesc,
            desc: (codeDesc.descTranslated && codeDesc.descTranslated[lang]) || codeDesc.desc,
        }))
    );

    return {
        userData: state.application.userData,
        applicationData: state.application.applicationData,
        rwpDropdowns: rwpDropdownsTranslated,
        constants: state.application.constants,
        translations: screenData[lang].translations.TREC_PERFRPMEAS,
        rwmTranslations: screenData[lang].translations.TREC_RWMANAGEMENT,
        woFields: screenData[lang].woFields,
        assetFields: screenData[lang].assetFields,
        customFields: screenData[lang].customFields,
        customFieldsDef: screenData[lang].customFieldsDef,
        customFieldsWaste: screenData[lang].customFieldsWaste,
        customFieldsWasteDef: screenData[lang].customFieldsWasteDef,
        lists: screenData[lang].lists,
        masterPage: 'RPMEASUREMENTS',
        page: 'RPMPERFORM',
        menu: screenData[lang].menu[state.application.menuType],
        bufferZone: state.application.bufferZone,
        lang,
        getTranslation: Tools.getTranslation({ screenData, lang, translationKey: TRANSLATION_KEY }),
    };
};

const RPMPerformContainer = connect(mapStateToProps, {
    updateLayout,
    setPage,
    logout,
    openConfirmDialog,
})(RPMPerform);

export default RPMPerformContainer;
