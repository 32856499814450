import WS from './WS';
import { EQUIPMENT_KEYS_DTO } from '../../ui/pages/rpmrequest2/RPMRConstants';
import { equipmentHazardInputAdapter, equipmentsArrayHazardOutputAdapter } from '../Hazards';
import Tools from '../Tools';

/**
 * Rest services for the Request of RP Measurement
 */
class WSRPMeasurements {
    createRPMAdapter = ({ rpm, applicationData }) => {
        rpm.equipment = this.updateEquipmentsAdapter(
            equipmentsArrayHazardOutputAdapter({
                equipments: rpm.equipment,
                applicationData,
            })
        );
        return rpm;
    };

    createRPM = ({ rpm, applicationData }, config = {}) =>
        WS._post(`/rpm`, this.createRPMAdapter({ rpm, applicationData }), config);

    autocompleteAllEqp = async (query, config = {}) => {
        const { hint } = query;
        const result = await this.autocompleteAllEquipment(hint, config);
        return result.body.data;
    };

    autocompleteAllEquipment = (hint, config = {}) =>
        WS._get(`/rpm/equipment?hint=${encodeURIComponent(hint)}`, config);

    getEquipment = ({ eqCode, applicationData, useStructure = false }, config = {}) =>
        WS._get(`/rpm/equipment/${eqCode}`, { ...config, params: { useStructure } }).then((response) => {
            if (
                applicationData.isHazardsFeatureActive &&
                response &&
                response.body &&
                response.body.data &&
                typeof response.body.data === 'object'
            ) {
                response.body.data = equipmentHazardInputAdapter({
                    equipment: response.body.data,
                    applicationData,
                });
            }

            return response;
        });

    updateEquipmentsAdapter = (equipments) =>
        equipments.map((equipment) => Tools.filterObjectFieldsFromList(equipment, EQUIPMENT_KEYS_DTO));

    createEquipment = ({ equipmentList, applicationData }, config = {}) =>
        WS._post(
            `/rpm/equipment/`,
            this.updateEquipmentsAdapter(
                equipmentsArrayHazardOutputAdapter({
                    equipments: equipmentList,
                    applicationData,
                })
            ),
            config
        );

    updateEquipment = ({ equipmentList, applicationData }, config = {}) =>
        WS._put(
            `/rpm/equipment/`,
            this.updateEquipmentsAdapter(
                equipmentsArrayHazardOutputAdapter({
                    equipments: equipmentList,
                    applicationData,
                })
            ),
            config
        );

    getLastCompletedRPJob = (evtCode) => WS._get(`/rpm/${evtCode}/lastcompleted`);

    getRPM = ({ rpmCode }, config = {}) => WS._get(`/rpm/${rpmCode}`, config);

    getRPMJob = ({ rpmCode }, config = {}) => WS._get(`/rpjobs/${rpmCode}/rpm`, config);
}

export default new WSRPMeasurements();
