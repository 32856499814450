/* eslint-disable react/no-array-index-key */

import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import React from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import WSAutocomplete from '../../../../tools/rest/WSAutocomplete';
import EquipmentHazardContainer from '../../../components/eqphazard/EquipmentHazardContainer';
import {
    ACTION_CHANGE_LOCATION,
    EQP_FOUND,
    EQP_NOT_PRESENT,
    EQP_PRESENT,
    EQP_TO_CHECK,
    getActionDropdownValues,
    isActionPossible,
} from './BZInventoryUtils';

export const PROPERTY_ACTION = 'afterInventoryActionCode';
export const PROPERTY_NEW_LOCATION = 'newLocationCode';

class BZInventoryTable extends React.Component {
    state = {};

    rowStyle = {
        textAlign: 'center',
        padding: '2px 10px 2px 10px',
        maxWidth: 180,
    };

    blockUiStyle = {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    blockUiStyleDiv = {
        display: 'flex',
        height: 60,
        alignItems: 'flex-end',
    };

    render() {
        const { actions, approved, data, translations } = this.props;
        const { selectAll } = this.state;
        const headers = this.getHeaders();
        const dropdownActions = getActionDropdownValues(translations);

        if (data) {
            return (
                <>
                    {null && (
                        <div style={{ width: '300px', marginLeft: '10px' }}>
                            {translations.APPLYTOALL}
                            <Checkbox
                                checked={selectAll}
                                onChange={() => this.setState((prevState) => ({ selectAll: !prevState.selectAll }))}
                            />
                        </div>
                    )}
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headers.map((header, index) => (
                                    <TableCell key={index} style={this.rowStyle}>
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((content, index) => {
                                let backgroundColor = '#ffffff';

                                switch (content.resultCode) {
                                    case EQP_FOUND: {
                                        backgroundColor = '#ffff72';
                                        break;
                                    }

                                    case EQP_PRESENT: {
                                        backgroundColor = '#80e27e';
                                        break;
                                    }
                                    default:
                                        backgroundColor = undefined;
                                }

                                if (content.errorCode) {
                                    backgroundColor = '#ff7961';
                                }

                                const style = {
                                    ...this.rowStyle,
                                    backgroundColor,
                                };

                                return (
                                    <TableRow key={index}>
                                        <TableCell style={style}>{content.equipment}</TableCell>
                                        <TableCell style={style}>{this.getResultDesc(content.resultCode)}</TableCell>
                                        {actions && !approved && (
                                            <>
                                                <TableCell style={{ ...style, minWidth: 150 }}>
                                                    <EAMSelect
                                                        labelStyle={{ display: 'none' }}
                                                        values={this.calculatePossibleActions(content)}
                                                        clearable={false}
                                                        valueKey={{
                                                            equipment: content.equipment,
                                                            property: PROPERTY_ACTION,
                                                        }}
                                                        value={content.afterInventoryActionCode}
                                                        updateProperty={this.updatePropertyInsideTable}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ ...style, minWidth: 120 }}>
                                                    <EAMAutocomplete
                                                        value={content.newLocationCode}
                                                        valueKey={{
                                                            equipment: content.equipment,
                                                            property: PROPERTY_NEW_LOCATION,
                                                        }}
                                                        disabled={
                                                            content.afterInventoryActionCode !== ACTION_CHANGE_LOCATION
                                                        }
                                                        clearable={false}
                                                        updateProperty={this.updatePropertyInsideTable}
                                                        loadOptions={WSAutocomplete.autocompleteTRECLocation}
                                                        columnsCodes={['code', 'desc', 'extraAttr1']}
                                                        columnsWidth={['30%', '30%', '40%']}
                                                        labelStyle={{ display: 'none' }}
                                                    />
                                                </TableCell>
                                            </>
                                        )}
                                        {actions && approved && (
                                            <>
                                                <TableCell style={style}>
                                                    {
                                                        dropdownActions.filter(
                                                            (element) =>
                                                                element.code === content.afterInventoryActionCode
                                                        )[0].desc
                                                    }
                                                </TableCell>
                                                <TableCell style={style}>{content.newLocationCode}</TableCell>
                                                <TableCell style={style}>{content.errorCode}</TableCell>
                                            </>
                                        )}
                                        <TableCell style={style}>{content.locationCode}</TableCell>
                                        <TableCell style={style}>
                                            {this.getFutureActionDesc(content.futureUsageCode)}
                                        </TableCell>
                                        <TableCell style={style}>{content.description}</TableCell>
                                        <TableCell style={style}>{content.responsible}</TableCell>
                                        <TableCell style={{ ...style, maxWidth: 30 }}>
                                            <EquipmentHazardContainer
                                                classification={content.classificationCode}
                                                contamination={content.contamination}
                                                risks={content.risks}
                                                hazards={content.hazardsList}
                                            />
                                        </TableCell>
                                        <TableCell style={style}>{content.dose}</TableCell>
                                        <TableCell style={style}>{content.reportedDate}</TableCell>
                                        <TableCell style={style}>{content.completedDate}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </>
            );
        }
        return (
            <BlockUi tag="div" blocking style={this.blockUiStyle}>
                <div style={this.blockUiStyleDiv}>{translations.LOADING}</div>
            </BlockUi>
        );
    }

    updatePropertyInsideTable = (key, value) => {
        const { updatePropertyInsideTable } = this.props;
        const { selectAll } = this.state;
        updatePropertyInsideTable({ ...key, selectAll }, value);
    };

    calculatePossibleActions = (inventoryItem) => {
        const { translations } = this.props;
        return getActionDropdownValues(translations).filter((element) => isActionPossible(inventoryItem, element.code));
    };

    getResultDesc = (resultCode) => {
        const { translations } = this.props;
        switch (resultCode) {
            case EQP_PRESENT: {
                return translations.PRESENT;
            }

            case EQP_TO_CHECK: {
                return translations.TOCHECK;
            }

            case EQP_FOUND: {
                return translations.ADDED;
            }

            case EQP_NOT_PRESENT: {
                return translations.NOT_PRESENT;
            }
            default:
                return translations.NOT_PRESENT;
        }
    };

    getFutureActionDesc = (futureActionCode) => {
        const { futureActions } = this.props;
        return futureActions.pdesc[futureActions.pcode.findIndex((element) => element === futureActionCode)];
    };

    getHeaders = () => {
        const { actions, approved, assetFields, translations, woFields } = this.props;
        // if table is in the actions mode there are additional columns
        const actionsHeaders = actions ? [translations.ACTION, translations.NEWLOCATION] : [];

        if (approved) {
            actionsHeaders.push(translations.ERROR);
        }

        return [
            assetFields.equipmentno.text,
            translations.RESULT,
            ...actionsHeaders,
            assetFields.location.text,
            translations.FUTURE_ACTION,
            assetFields.equipmentdesc.text,
            translations.EQPRESPONSIBLE,
            translations.RISKS,
            translations.DOSE_RATE,
            woFields.datereported.text,
            woFields.datecompleted.text,
        ];
    };
}

export default BZInventoryTable;
