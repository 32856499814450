import React from 'react';
import MenuIcon from 'mdi-material-ui/Menu';
import BlockUi from 'react-block-ui';
import TRECSearchPage from '../TRECSearchPage';
import TRECButton from '../../components/buttons/TRECButton';
import { ROUTES } from '../../../tools/Routes';

export default class Reports extends TRECSearchPage {
    frameRef = null;

    renderPage() {
        return (
            <BlockUi style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                <TRECButton onClick={() => this.props.history.push(ROUTES.menu)} color="default">
                    <MenuIcon />
                    MENU
                </TRECButton>
                <iframe
                    ref={(el) => {
                        this.frameRef = el;
                    }}
                    title="Reports"
                    src="https://eam-reports.web.cern.ch/TREC.html" // TODO move to app data
                    style={{ height: '100%', width: '100%' }}
                />
            </BlockUi>
        );
    }
}
