import { Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import CommentsContainer from 'eam-components/dist/ui/components/comments/CommentsContainer';
import { TABLE_DATA_TYPES } from 'enums/Constants';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import { shouldShowComment } from 'tools/Hazards';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import WSComments from 'tools/rest/WSWRComments';
import {
    TRANSLATION_KEYS,
    VACUUM_RENTAL_KEYS,
    VACUUM_RENTAL_STATUS,
} from 'ui/pages/vaccleanerrequest/VacCleanerConstants';
import { ENTITY_TRANSL_PREFIX } from 'ui/pages/wrrequest/WRRConstants';
import InputGenerator, { ATTRIBUTES } from '../../rpmrequest2/InputGenerator';
import { showError } from 'tools/TrecNotifications';

export const getUserFields = (assetFields) => [
    {
        code: VACUUM_RENTAL_KEYS.CREATOR,
        translationKey: 'CREATED_BY',
        type: TABLE_DATA_TYPES.AUTOCOMPLETE,
        getAttribute: (creator) => (creator ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN),
        autocompleteType: WSAutocomplete.autocompleteEmployee,
        valueKey: 'cernId',
        descKey: 'description',
    },
    {
        code: VACUUM_RENTAL_KEYS.REQUESTER,
        translationKey: VACUUM_RENTAL_KEYS.REQUESTER,
        type: TABLE_DATA_TYPES.AUTOCOMPLETE,
        getAttribute: () => ATTRIBUTES.REQUIRED,
        autocompleteType: WSAutocomplete.autocompleteEmployee,
        valueKey: 'cernId',
        descKey: 'description',
    },
    {
        code: VACUUM_RENTAL_KEYS.BUDGET_CODE,
        type: TABLE_DATA_TYPES.INPUT,
        elementInfo: {
            ...assetFields.costcode,
            attribute: 'R',
        },
        descKey: 'budgetCode',
    },
    {
        code: VACUUM_RENTAL_KEYS.COMMENT,
        type: TABLE_DATA_TYPES.INPUT,
        translationKey: VACUUM_RENTAL_KEYS.COMMENT,
        getAttribute: (vacRental) => (vacRental[VACUUM_RENTAL_KEYS.CODE] ? ATTRIBUTES.HIDDEN : ATTRIBUTES.OPTIONAL),
    },
];

export const getLocationFields = (applicationData, assetFields, getTranslation) => [
    {
        code: VACUUM_RENTAL_KEYS.FACILITY,
        type: TABLE_DATA_TYPES.SELECT,
        elementInfo: {
            text: getTranslation('FACILITY'),
            attribute: 'R',
        },
        descKey: 'facilities',
        values: applicationData.facilities,
        customInputProps: () => ({
            clearable: false,
        }),
    },
    {
        code: VACUUM_RENTAL_KEYS.USAGE_LOCATION,
        type: TABLE_DATA_TYPES.LOOKUP_SELECT,
        keysMap: (field) => ({
            code: 'obj_code',
            mapCodeTo: field.code,
        }),
        elementInfo: {
            ...assetFields.location,
            text: getTranslation('LOCATION_OF_DELIVERY'),
            attribute: 'R',
        },
        values: (vacRental) =>
            applicationData.bufferZones
                .filter(
                    (bz) =>
                        !vacRental[VACUUM_RENTAL_KEYS.FACILITY] ||
                        bz.facility === vacRental[VACUUM_RENTAL_KEYS.FACILITY]
                )
                .map((bz) => ({
                    code: bz.locationCode,
                    desc: bz.locationCode,
                })) || [],
        gridId: applicationData.locationRaisinGridID,
    },
];

export const getUsageFields = ({ vacCleanerRental, getTranslation, applicationData, dropdowns }) => [
    {
        code: VACUUM_RENTAL_KEYS.CODE,
        type: TABLE_DATA_TYPES.INPUT,
        translationKey: TRANSLATION_KEYS.JOB_NUMBER,
        getAttribute: (vacRental) => (vacRental[VACUUM_RENTAL_KEYS.CODE] ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN),
    },
    {
        code: VACUUM_RENTAL_KEYS.STATUS,
        type: TABLE_DATA_TYPES.SELECT,
        values: Object.entries(VACUUM_RENTAL_STATUS)
            .filter(
                ([_, value]) =>
                    value !== VACUUM_RENTAL_STATUS.CANCELLED ||
                    vacCleanerRental[VACUUM_RENTAL_KEYS.STATUS] === VACUUM_RENTAL_STATUS.CANCELLED
            )
            .map(([_, code]) => ({ code, desc: getTranslation(ENTITY_TRANSL_PREFIX.EVT_STATUS + code) })),
        translationKey: TRANSLATION_KEYS.STATUS,
        getAttribute: (vacRental) => (vacRental[VACUUM_RENTAL_KEYS.CODE] ? ATTRIBUTES.REQUIRED : ATTRIBUTES.HIDDEN),
    },
    {
        code: VACUUM_RENTAL_KEYS.DESCRIPTION,
        type: TABLE_DATA_TYPES.INPUT,
        translationKey: VACUUM_RENTAL_KEYS.DESCRIPTION,
        getAttribute: () => ATTRIBUTES.REQUIRED,
    },
    {
        code: VACUUM_RENTAL_KEYS.HAZARD_CODES,
        type: TABLE_DATA_TYPES.SELECT,
        elementInfo: {
            text: getTranslation(TRANSLATION_KEYS.HAZARD_CODES),
        },
        customInputProps: () => ({
            multi: true,
            searchable: true,
        }),
        values: [{ code: 'NONE', desc: 'None' }, ...dropdowns.hazard],
        getAttribute: (vacRental) => (vacRental[VACUUM_RENTAL_KEYS.CODE] ? ATTRIBUTES.OPTIONAL : ATTRIBUTES.REQUIRED),
        customValue: (vacRental) => {
            const a =
                vacCleanerRental[VACUUM_RENTAL_KEYS.HAZARD_CODES]?.length === 0
                    ? ['NONE']
                    : vacRental[VACUUM_RENTAL_KEYS.HAZARD_CODES] || [];
            return a;
        },
        getUpdateValue: (val) => {
            if (!vacCleanerRental[VACUUM_RENTAL_KEYS.HAZARD_CODES] && !val?.[0]) {
                return null;
            }
            const value = val?.[val.length - 1] === 'NONE' ? ['NONE'] : val.filter((v) => v !== 'NONE');
            return value;
        },
    },
    {
        code: VACUUM_RENTAL_KEYS.HAZARD_COMMENT,
        type: TABLE_DATA_TYPES.INPUT,
        elementInfo: {
            text: getTranslation(TRANSLATION_KEYS.HAZARD_COMMENT),
        },
        getAttribute: (equipment) =>
            shouldShowComment({ equipment, applicationData }) ? ATTRIBUTES.REQUIRED : ATTRIBUTES.HIDDEN,
    },
    {
        code: VACUUM_RENTAL_KEYS.MODIFIED_BY,
        translationKey: VACUUM_RENTAL_KEYS.MODIFIED_BY,
        type: TABLE_DATA_TYPES.AUTOCOMPLETE,
        getAttribute: (vacRental) =>
            vacRental[VACUUM_RENTAL_KEYS.MODIFIED_BY] ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN,
        valueKey: 'cernId',
        descKey: 'description',
    },
    {
        code: VACUUM_RENTAL_KEYS.MODIFIED_ON,
        translationKey: VACUUM_RENTAL_KEYS.MODIFIED_ON,
        type: TABLE_DATA_TYPES.DATE_TIME,
        getAttribute: (vacRental) =>
            vacRental[VACUUM_RENTAL_KEYS.MODIFIED_ON] ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN,
        customInputProps: () => ({
            timestamp: true,
        }),
    },
];

const getInputGenerator =
    ({ vacCleanerGetters, vacuumRequest, storeActions, formFields, labelStyle, getTranslation, writeAccess }) =>
    (field) =>
        InputGenerator.generate({
            key: field.code,
            field: {
                ...field,
                code: field.valueKey ?? field.code,
                getAttribute: !writeAccess ? () => ATTRIBUTES.READONLY : field.getAttribute,
            },
            object:
                field.type === TABLE_DATA_TYPES.AUTOCOMPLETE
                    ? vacCleanerGetters.getProperties()[field.code] || {}
                    : vacuumRequest,
            updateObject: (key, value) => {
                const val = field.getUpdateValue ? field.getUpdateValue(value) : value;
                const updateObject = { [key]: val };
                field.type === TABLE_DATA_TYPES.AUTOCOMPLETE
                    ? storeActions.updateVacRentalPartial(field.code, updateObject)
                    : storeActions.updateVacRental(field.code, val);
            },
            formFields,
            labelStyle,
            getTranslation,
        });

const VacCleanerUser = ({
    formFields,
    labelStyle,
    vacuumRequest,
    vacCleanerGetters,
    storeActions,
    assetFields,
    applicationData,
    getTranslation,
    dropdowns,
    userData,
    writeAccess,
}) => {
    const vacCleanerRental = vacCleanerGetters.getProperties();

    const userFields = getUserFields(assetFields);
    const locationFields = getLocationFields(applicationData, assetFields, getTranslation);
    const usageFields = getUsageFields({ vacCleanerRental, getTranslation, applicationData, dropdowns, writeAccess });

    const inputGenerator = getInputGenerator({
        vacCleanerGetters,
        vacuumRequest,
        storeActions,
        formFields,
        labelStyle,
        getTranslation,
        writeAccess,
    });

    const code = vacCleanerRental[VACUUM_RENTAL_KEYS.CODE];

    return (
        <Grid container>
            <Grid item md={6} sm={12} xs={12} style={{ padding: '4px' }}>
                <EISPanel heading={getTranslation('USAGE')} alwaysExpanded>
                    <div style={{ width: '100%', marginTop: 0 }}>{usageFields.map(inputGenerator)}</div>
                </EISPanel>
                <EISPanel heading={getTranslation('USER')} alwaysExpanded>
                    <div style={{ width: '100%', marginTop: 0 }}>{userFields.map(inputGenerator)}</div>
                </EISPanel>
            </Grid>
            <Grid item md={6} sm={12} xs={12} style={{ padding: '4px' }}>
                <EISPanel heading={getTranslation('LOCATION')} alwaysExpanded>
                    <div style={{ width: '100%', marginTop: 0 }}>{locationFields.map(inputGenerator)}</div>
                </EISPanel>
                {code && (
                    <CommentsContainer
                        title={getTranslation('COMMENTS')}
                        entityCode="EVNT"
                        entityKeyCode={code}
                        userDesc={userData.eamAccount.userDesc}
                        readComments={WSComments.readComments}
                        createComment={WSComments.createComment}
                    />
                )}
            </Grid>
        </Grid>
    );
};

class VacCleanerUserStep extends WizardStep {
    formFields = {};

    prevValues = null;

    state = {
        isNewEquipment: false,
        newEquipment: {},
    };

    handleSubmit = () => {
        this.canContinue();
    };

    handleSave = () => {
        this.canContinue();
    };

    /**
     * Can continue to the next step on the wizard
     */
    canContinue = () => {
        const isValid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
        if (!isValid) {
            showError('One or more fields are not valid.');
        }

        return isValid;
    };

    /**
     * SaveChanges is used to save the localized state to the redux-store. It can only run synchronous methods.
     */
    saveChanges = () => true;

    /**
     *  CommitChanges is used to trigger any side effects of the step, such as invocations to the back-end to
     * retrieve information about resources. This method can run asynchronous methods/requests but SHALL execute
     * the callback method afterwards to inform the wizard.
     */
    commitChanges = async (callback) => {
        callback();
    };

    /**
     * On true, the wizard automatically jumps this step immediately after it is mounted
     */
    canJumpStep = () => false;

    render() {
        return <VacCleanerUser formFields={this.formFields} {...this.props} />;
    }
}

export default VacCleanerUserStep;
