import React from 'react';
import DEFAULTS from 'enums/Defaults';
import RPARAnalysisDetails from './RPARAnalysisDetails';

const ANALYSIS_TYPE = 'ALPHASPEC';
function RPARAlphaSpec(props) {
    const { getTranslation, dropdowns, valuesGrid } = props;
    const tableLayout = DEFAULTS.getTableLayout({ ANALYSIS_TYPE, dropdowns, valuesGrid }).map((e) => ({
        ...e,
        header: getTranslation(e.code),
    }));
    return <RPARAnalysisDetails {...props} analysisType="ALPHASPEC" tableLayout={tableLayout} />;
}

export default RPARAlphaSpec;
