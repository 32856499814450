import { connect } from 'react-redux';
import { openConfirmDialog } from 'react-eis-components/dist/actions/uiActions';
import { setPage } from '../../../actions/layoutActions';
import BZInventory from './BZInventory';
import { BZ_INVENTORY } from './steps/BZInventoryUtils';

const mapStateToProps = (state) => ({
    page: BZ_INVENTORY,
    masterPage: BZ_INVENTORY,
    menu: state.application.screenData[state.layout.lang].menu[state.application.menuType],
    translations: state.application.screenData[state.layout.lang].translations.TREC_BZINVENTORY,
    bufferZones: state.application.applicationData.bufferZones,
    woFields: state.application.screenData[state.layout.lang].woFields,
    lang: state.layout.lang,
    futureActions:
        state.application.screenData[state.layout.lang].customFieldsDef[state.application.constants.cfpFutureAction],
    radiationClassifications:
        state.application.screenData[state.layout.lang].customFieldsDef[
            state.application.constants.cfpRadiationClassification
        ],
    inventoryGridID: state.application.applicationData.gridInventoryGridID,
    assetFields: state.application.screenData[state.layout.lang].assetFields,
    constants: state.application.constants,
    pastInventoriesGridID: state.application.applicationData.gridBZPastInventoriesGridID,
    inventoryResultsGridID: state.application.applicationData.gridBZPastInventoryResultsID,
    jobStatusValues: state.application.screenData[state.layout.lang].lists.jobStatuses,
    inventoryWasteLocation: state.application.applicationData.inventoryWasteLocation,
    bufferZone: state.application.bufferZone,
});

const BZInventoryContainer = connect(mapStateToProps, {
    setPage,
    openConfirmDialog,
})(BZInventory);

export default BZInventoryContainer;
