import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { EQUIPMENT_PAGE_KEYS } from './Constants';
import EQPageTable from './EQPageTable';

const EQPageTableWrapper = (props) => {
    const { tableInstance, getTranslation, getRowProps } = props;
    const [filtersEnabled, setFiltersEnabled] = useState(true);

    return (
        <section
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Grid container spacing={8} direction="row" justify="flex-end" alignItems="center">
                <Grid item>
                    <Button size="small" variant="outlined" onClick={() => setFiltersEnabled(!filtersEnabled)}>
                        {filtersEnabled
                            ? getTranslation(EQUIPMENT_PAGE_KEYS.HIDE_FILTERS)
                            : getTranslation(EQUIPMENT_PAGE_KEYS.SHOW_FILTERS)}
                    </Button>
                </Grid>
                <Grid item>
                    <Button size="small" variant="outlined" onClick={() => tableInstance.setAllFilters([])}>
                        {getTranslation(EQUIPMENT_PAGE_KEYS.RESET_FILTERS)}
                    </Button>
                </Grid>
            </Grid>
            <div
                style={{
                    position: 'relative',
                    height: '100%',
                    overflow: 'auto',
                    marginTop: '1em',
                    flex: '1',
                }}
            >
                <EQPageTable tableInstance={tableInstance} getRowProps={getRowProps} disableFilters={!filtersEnabled} />
            </div>
        </section>
    );
};

export default EQPageTableWrapper;
