import React, { useEffect } from 'react';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import DoneAll from '@material-ui/icons/DoneAll';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import EAMLookup from 'eam-components/dist/ui/components/lookup/EAMLookup';
import moment from 'moment/moment';
import { withStyles } from '@material-ui/core/styles/index';
import { Link } from 'react-router-dom';
import { FilterRemoveOutline } from 'mdi-material-ui';
import { DATE_FORMAT } from 'enums/Constants';
import { getHazardsFromEAMGridRow } from 'tools/Hazards';
import { ROUTES, withQueryParams } from 'tools/Routes';
import Tools from 'tools/Tools';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import SearchPanel from '../../components/searchpanel/SearchPanel';
import SearchPanelControls from '../../components/searchpanel/SearchPanelControls';
import SearchPanelColumn from '../../components/searchpanel/SearchPanelColumn';
import EquipmentHazardContainer from '../../components/eqphazard/EquipmentHazardContainer';
import TRECSearchPage from '../TRECSearchPage';
import TRECButton from '../../components/buttons/TRECButton';
import { trecPageStyles } from '../TRECPage';
import {
    CUTG0104_FIELDS,
    CUTG0104_ASSET_LOCATION_FIELDS,
    CUTG0104_FIELDS_REQUEST_PARAMETERS,
    CUTG0104_TRANSL_MAP,
    CUTG0104_JOB_LOCATION_FIELDS,
    CUTG0104_JOB_SIMILAR_FIELDS,
    CUTG0104_HIDDEN_FIELDS,
} from './RPMSearchUtils';
import EAMGridNoOverflow from '../../components/grid/EAMGridNoOverflow';
import gridNoOverflowClasses from '../../components/grid/EAMGridNoOverflow.module.css';

const labelStyleRight = {
    width: 'auto',
    minWidth: 70,
    textAlign: 'right',
};

const RPMSearchFC = (props) => {
    const {
        history,
        translations,
        screenData,
        lang,
        constants,
        classes,
        applicationData,
        userData,
        updateProperty,
        onPanelChange,
        filters,
        assignRef,
        selectedRows,
        onClearClick,
        onSearchClick,
        getGridFilterLocation,
        writeAccess,
        onSelectRow,
        onKeyDown,
        rwpDropdowns,
    } = props;
    const { customFieldsDef } = screenData[lang];

    const performSearchOnEnterKey = (event) => {
        onKeyDown(event);
    };

    const assignRequestParameters = () => {
        // apply default filters
        if (!writeAccess)
            updateProperty(
                CUTG0104_HIDDEN_FIELDS.JOB_REPORTEDBY,
                userData.eamAccount.employeeCode,
                'EQUALS',
                'VARCHAR'
            );

        const { bufferZone } = props;
        const parametersNames = Object.values(CUTG0104_FIELDS_REQUEST_PARAMETERS);

        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.searchParams);
        // const isParameterProvided = parametersNames.some((parameter) => searchParams.has(parameter));
        // if (isParameterProvided) {
        //     parametersNames.forEach((parameter) => {
        //         if (CUTG0104_FIELDS.OBJ_CLASS) updateProperty(parameter, '', 'IN', 'VARCHAR', true);
        //         else if (CUTG0104_FIELDS.CADRA_CHECK_PRESENT) updateProperty(parameter, '', 'EQUALS', 'VARCHAR');
        //         else updateProperty(parameter, '', 'BEGINS', 'VARCHAR', true);
        //     });
        // }

        // apply the real parameters
        parametersNames.forEach((parameter) => {
            if (searchParams.has(parameter)) {
                if (parameter === CUTG0104_FIELDS.JOB_CREATED)
                    updateProperty(parameter, searchParams.get(parameter), 'GREATER_THAN', 'VARCHAR');
                else if (CUTG0104_FIELDS.OBJ_CLASS)
                    updateProperty(parameter, searchParams.get(parameter), 'IN', 'VARCHAR');
                else if (CUTG0104_FIELDS.EQUIPMENT_ID)
                    updateProperty(parameter, searchParams.get(parameter), 'BEGINS', 'VARCHAR');
                else if (CUTG0104_FIELDS.OBJ_LOCATION || CUTG0104_FIELDS.JOB_LOCATION)
                    updateProperty(parameter, searchParams.get(parameter), 'BEGINS', 'VARCHAR', true);
                else updateProperty(parameter, searchParams.get(parameter), 'EQUALS', 'VARCHAR');
            } else if (parameter === CUTG0104_FIELDS_REQUEST_PARAMETERS.OBJ_LOCATION && bufferZone?.locationCode) {
                updateProperty(parameter, bufferZone?.locationCode, 'BEGINS', 'VARCHAR', true);
            }
        });
    };

    useEffect(() => {
        document.addEventListener('keydown', performSearchOnEnterKey.bind(this), false);
        assignRequestParameters();
        return () => document.removeEventListener('keydown', performSearchOnEnterKey.bind(this), false);
    }, []);

    const hiddenColumns = () => Object.values(CUTG0104_HIDDEN_FIELDS);

    const cellRenderer = (cell, row) => {
        if (cell.t === CUTG0104_FIELDS.RP_STATUS) {
            return <EquipmentHazardContainer classification={cell.value} hazards={getHazardsFromEAMGridRow(row)} />;
        }
        if (cell.t === CUTG0104_FIELDS.ASSET_RPCLASS) {
            return <EquipmentHazardContainer classification={cell.value} hazards={getHazardsFromEAMGridRow(row)} />;
        }
        if (cell.t === CUTG0104_FIELDS.OBJ_CODE) {
            return (
                <Typography>
                    <a
                        target="_blank"
                        href={`${process.env.REACT_APP_FRONTEND}equipment/${cell.value}`}
                        rel="noreferrer"
                    >
                        {cell.value}
                    </a>
                </Typography>
            );
        }
        if (Object.keys(CUTG0104_TRANSL_MAP).includes(cell.t)) {
            return (
                <Typography>
                    {cell.value
                        ?.split(',')
                        .map((code) =>
                            rwpDropdowns[CUTG0104_TRANSL_MAP[cell.t]]
                                ? (
                                      rwpDropdowns[CUTG0104_TRANSL_MAP[cell.t]].find((cd) => cd.code === code) || {
                                          descTranslated: {
                                              [lang]: code,
                                          },
                                      }
                                  ).descTranslated[lang]
                                : (
                                      screenData[lang].lists[CUTG0104_TRANSL_MAP[cell.t]].find(
                                          (cd) => cd.code === code
                                      ) || {
                                          desc: code,
                                      }
                                  ).desc
                        )
                        .join(', ')}
                </Typography>
            );
        }
        if (cell.t === CUTG0104_FIELDS.JOB_EXECUTEDBY) {
            const executedByDesc = Tools.getGridCellValueFromRow(row, CUTG0104_FIELDS.JOB_EXECUTEDBY_DESC);
            if (executedByDesc) return <Typography>{executedByDesc}</Typography>;
        } else if (cell.t === CUTG0104_FIELDS.EQUIPMENT_ID) {
            // Link to the equipment in EAMLight
            return (
                <Typography>
                    <a
                        target="_blank"
                        href={`${process.env.REACT_APP_FRONTEND}equipment/${cell.value}`}
                        rel="noreferrer"
                    >
                        {cell.value}
                    </a>
                </Typography>
            );
        } else if (cell.t === CUTG0104_FIELDS.JOB_NUMBER) {
            // Link to go to the measurement
            return (
                <Typography>
                    <Link to={`/rpmperform/?wo=${cell.value}`}>{cell.value}</Link>
                </Typography>
            );
        } else if (cell.t === CUTG0104_FIELDS.EDHDOCID) {
            // Link to the EDH Document
            return (
                <Typography>
                    <a target="_blank" href={`${applicationData.edhurl}${cell.value}`} rel="noreferrer">
                        {cell.value}
                    </a>
                </Typography>
            );
        }

        //
        return false;
    };

    const gridRequestAdapter = (gridRequest) => {
        let gridFilters = [...gridRequest.gridFilter];
        gridFilters = gridFilters.map((gridFilter) => {
            const value = gridFilter.fieldValue;
            switch (gridFilter.fieldName) {
                case CUTG0104_FIELDS.JOB_STATUS:
                    // All active status
                    if (gridFilter.fieldValue === 'ALLACTIVE') {
                        return {
                            ...gridFilter,
                            fieldName: CUTG0104_FIELDS.JOB_STATUS,
                            fieldValue: 'R',
                            operator: 'BEGINS',
                        };
                    }
                    // Normal status

                    return gridFilter;

                case CUTG0104_FIELDS.OBJ_LOCATION:
                    return getGridFilterLocation(gridFilter, value, ...Object.values(CUTG0104_ASSET_LOCATION_FIELDS));
                case CUTG0104_FIELDS.JOB_LOCATION:
                    return getGridFilterLocation(gridFilter, value, ...Object.values(CUTG0104_JOB_LOCATION_FIELDS));
                case CUTG0104_FIELDS.JOB_CREATED: {
                    if (['LASTDAY', 'LASTWEEK', 'LASTMONTH'].includes(gridFilter.fieldValue)) {
                        let date = moment();
                        date = date.subtract(1, gridFilter.fieldValue.replace('LAST', '').toLowerCase());
                        return {
                            ...gridFilter,
                            fieldName: 'job_reported',
                            fieldValue: date.format(DATE_FORMAT.EAMCOMPONENT),
                            operator: 'GREATER_THAN',
                        };
                    }
                    return gridFilter;
                }
                case 'asset_desc':
                    return {
                        ...gridFilter,
                        forceCaseInsensitive: true,
                    };
                default:
                    return gridFilter;
            }
        });
        return {
            ...gridRequest,
            gridFilter: gridFilters,
            gridSort: [...gridRequest.gridSort, { sortType: 'DESC', sortBy: 'updated_on' }],
        };
    };

    const getWorkOrdersList = (rowKeys) =>
        rowKeys.map((elem) => {
            const row = selectedRows[elem];
            return Tools.getGridCellValueFromRow(row, CUTG0104_FIELDS.JOB_NUMBER);
        });

    // Go to perform page
    const handlePerform = (jobIds) => {
        history.push(
            withQueryParams({
                path: ROUTES.rpmPerform,
                queryParams: {
                    wo: jobIds.map((jobId) => jobId),
                },
            })
        );
    };

    // Click on Edit button, in a row of the grid
    const handleEdit = (row) => {
        handlePerform([row.cell.filter((column) => column.t === CUTG0104_FIELDS.JOB_NUMBER)[0].value]);
    };

    // Click on Batch Update button
    const handleBatchUpdate = () => {
        handlePerform(
            Object.keys(selectedRows).map((elem) => {
                const row = selectedRows[elem];
                return Tools.getGridCellValueFromRow(row, CUTG0104_FIELDS.JOB_NUMBER);
            })
        );
    };

    /**
     * Convert the row data structure to a classical Javascript object
     * @param job
     * @private
     */

    const convertJob = (job) =>
        job.cell.reduce((acc, cur) => {
            acc[cur.t] = cur.value;
            return acc;
        }, {});

    const areJobsSimilar = (row1, row2) => {
        const job1 = convertJob(row1);
        const job2 = convertJob(row2);
        return Object.values(CUTG0104_JOB_SIMILAR_FIELDS).every((field) => job1[field] === job2[field]);
    };

    const searchJobStatuses = Tools.sortCodeDescList([
        ...screenData[lang].lists.jobStatuses,
        {
            code: 'ALLACTIVE',
            desc: translations.ALLACTIVE,
        },
    ]);

    const isRowSelectable = (row, selectedRecords) => {
        if (!selectedRecords) return true;
        const keys = Object.keys(selectedRecords);
        const woList = getWorkOrdersList(Object.keys(selectedRows));
        const woNumber = Tools.getGridCellValueFromRow(row, CUTG0104_FIELDS.JOB_NUMBER);
        if (keys.length === 0) {
            return true;
        }
        if (woList.includes(woNumber)) {
            // Already selected, so its ok
            return true;
        }
        if (keys.length >= applicationData.maxUpdateRecords) {
            // Max 20 jobs updated at the same time
            return false;
        }
        return areJobsSimilar(row, selectedRecords[keys[0]]);
    };

    const updateInputPropertyWithBegins = (key, value, forceCaseInsensitive = false) =>
        updateProperty(key, value, 'BEGINS', 'VARCHAR', forceCaseInsensitive);
    const updateInputPropertyWithEquals = (key, value, forceCaseInsensitive = false) =>
        updateProperty(key, value, 'EQUALS', 'VARCHAR', forceCaseInsensitive);

    return (
        <div className={gridNoOverflowClasses.outerBlock}>
            <SearchPanel title={translations.SEARCH_CRITERIA} onPanelChange={onPanelChange}>
                <SearchPanelColumn md={12}>
                    <Grid container spacing={8} style={{ margin: '-10px' }}>
                        <Grid item md={2} sm={12} xs={12}>
                            <EAMInput
                                label={translations.JOBNUMBER}
                                value={filters.job_number}
                                valueKey="job_number"
                                labelStyle={labelStyleRight}
                                updateProperty={updateInputPropertyWithBegins}
                            />
                        </Grid>
                        <Grid item md={4} sm={12} xs={12}>
                            <EAMLookup
                                right={1}
                                top={13}
                                width={26}
                                height={26}
                                gridId={applicationData.locationRaisinGridID}
                                keys={{
                                    code: 'obj_code',
                                    mapCodeTo: 'asset_loc',
                                }}
                                updateProperty={updateInputPropertyWithBegins}
                                value={filters.location}
                            >
                                <EAMSelect
                                    elementInfo={{
                                        text: translations.EQPLOCATION,
                                    }}
                                    valueKey="asset_loc"
                                    creatable
                                    value={filters.asset_loc}
                                    values={screenData[lang].lists.locationsList}
                                    labelStyle={labelStyleRight}
                                    updateProperty={updateInputPropertyWithBegins}
                                    arrowRenderer={() => <span />}
                                />
                            </EAMLookup>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                            <EAMLookup
                                right={1}
                                top={13}
                                width={26}
                                height={26}
                                gridId={applicationData.locationRaisinGridID}
                                keys={{
                                    code: 'obj_code',
                                    mapCodeTo: 'job_location',
                                }}
                                updateProperty={updateInputPropertyWithBegins}
                                value={filters.job_location}
                            >
                                <EAMSelect
                                    elementInfo={screenData[lang].woFields.location}
                                    valueKey="job_location"
                                    value={filters.job_location}
                                    values={screenData[lang].lists.locationsList}
                                    creatable
                                    labelStyle={labelStyleRight}
                                    updateProperty={updateInputPropertyWithBegins}
                                    arrowRenderer={() => <span />}
                                />
                            </EAMLookup>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                            {/* Equipment */}
                            <EAMAutocomplete
                                elementInfo={screenData[lang].woFields.equipment}
                                value={filters.asset_code}
                                valueKey="asset_code"
                                multi
                                creatable
                                updateProperty={(key, value) => updateInputPropertyWithBegins(key, value, true)}
                                loadOptions={WSAutocomplete.autocompleteEquipment}
                                labelStyle={labelStyleRight}
                                columnsWidth={['0', '100%']}
                                selectStyle={classes.selectStyle}
                                autoSelectSingleElement
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8} style={{ margin: '-10px' }}>
                        <Grid item md={4} sm={12} xs={12}>
                            {/* Equipment Responsible */}
                            <EAMAutocomplete
                                elementInfo={screenData[lang].assetFields.eqpResponsible}
                                value={filters[CUTG0104_FIELDS_REQUEST_PARAMETERS.ASSET_RESPONSIBLE]}
                                valueKey={CUTG0104_FIELDS_REQUEST_PARAMETERS.ASSET_RESPONSIBLE}
                                valueDesc={filters.responsible_desc}
                                descKey="responsible_desc"
                                updateProperty={updateInputPropertyWithBegins}
                                loadOptions={WSAutocomplete.autocompleteEmployee}
                                labelStyle={labelStyleRight}
                            />
                        </Grid>
                        <Grid item md={4} sm={12} xs={12}>
                            {/* Executed By */}
                            <EAMAutocomplete
                                elementInfo={screenData[lang].woFields.udfchar21}
                                value={filters.job_executedby}
                                valueKey="job_executedby"
                                valueDesc={filters.job_executedbyDesc}
                                descKey="job_executedbyDesc"
                                updateProperty={updateInputPropertyWithBegins}
                                loadOptions={WSAutocomplete.autocompleteEmployee}
                                labelStyle={labelStyleRight}
                            />
                        </Grid>
                        {/* Requested By */}
                        <Grid item md={4} sm={12} xs={12}>
                            <EAMAutocomplete
                                elementInfo={screenData[lang].woFields.reportedby}
                                value={filters[CUTG0104_HIDDEN_FIELDS.JOB_REPORTEDBY]}
                                valueKey={CUTG0104_HIDDEN_FIELDS.JOB_REPORTEDBY}
                                valueDesc={filters.reportedbyDesc}
                                descKey="reportedbyDesc"
                                updateProperty={updateInputPropertyWithBegins}
                                loadOptions={WSAutocomplete.autocompleteEmployee}
                                labelStyle={labelStyleRight}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8} style={{ margin: '-10px' }}>
                        <Grid item md={3} sm={12} xs={12}>
                            <EAMSelect
                                label={translations.EQPCLASS}
                                valueKey="asset_class"
                                value={filters.asset_class}
                                values={screenData[lang].lists.equipmentClasses}
                                labelStyle={labelStyleRight}
                                updateProperty={(key, value) => updateProperty(key, value, 'IN', 'VARCHAR')}
                            />
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                            {/* EDH Type */}
                            <EAMSelect
                                label={translations.EDHTYPE}
                                valueKey={CUTG0104_FIELDS_REQUEST_PARAMETERS.EDHDOCTYPE_CODE}
                                value={filters[CUTG0104_FIELDS_REQUEST_PARAMETERS.EDHDOCTYPE_CODE]}
                                values={screenData[lang].lists.edhTypes}
                                updateProperty={(key, value) => updateInputPropertyWithEquals(key, value, true)}
                                labelStyle={labelStyleRight}
                            />
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                            <EAMSelect
                                label={translations.CADRACHECKPRESENT}
                                valueKey="cadra_check_present"
                                value={filters.cadra_check_present}
                                values={screenData[lang].lists.yesnoList}
                                labelStyle={labelStyleRight}
                                updateProperty={updateInputPropertyWithEquals}
                            />
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                            <EAMSelect
                                label={translations.RPSECTION}
                                valueKey="job_department"
                                value={filters.job_department}
                                values={screenData[lang].lists.departments}
                                labelStyle={labelStyleRight}
                                updateProperty={updateInputPropertyWithEquals}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8} style={{ margin: '-10px' }}>
                        <Grid item md={4} sm={12} xs={12}>
                            {/* Future action */}
                            <EAMSelect
                                elementInfo={screenData[lang].customFields[constants.cfpFutureAction]}
                                valueKey="job_future_action"
                                value={filters.job_future_action}
                                values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpFutureAction], true)}
                                labelStyle={labelStyleRight}
                                updateProperty={(key, value) => updateInputPropertyWithEquals(key, value, true)}
                            />
                        </Grid>
                        <Grid item md={4} sm={12} xs={12}>
                            {/* WO Status */}
                            <EAMSelect
                                elementInfo={screenData[lang].woFields.workorderstatus}
                                valueKey="job_status"
                                value={filters.job_status}
                                values={searchJobStatuses}
                                updateProperty={updateInputPropertyWithEquals}
                                labelStyle={labelStyleRight}
                            />
                        </Grid>
                        <Grid item md={4} sm={12} xs={12}>
                            <EAMSelect
                                label={translations.JOBCREATED}
                                valueKey="job_reported"
                                value={filters.job_reported}
                                values={screenData[lang].lists.createdList}
                                labelStyle={labelStyleRight}
                                updateProperty={(key, value) => updateProperty(key, value, 'GREATER_THAN', 'VARCHAR')}
                            />
                        </Grid>
                    </Grid>
                </SearchPanelColumn>
                <SearchPanelControls>
                    <TRECButton
                        variant="contained"
                        color="default"
                        size="medium"
                        style={{ margin: '1px' }}
                        onClick={() => {
                            history.push('/menu');
                        }}
                    >
                        <MenuIcon />
                        {translations.GOTOMENU}
                    </TRECButton>
                    <TRECButton variant="contained" onClick={onSearchClick} size="medium" style={{ margin: '1px' }}>
                        <SearchIcon />
                        {translations.SEARCH}
                    </TRECButton>
                    <TRECButton variant="contained" onClick={onClearClick} size="medium" style={{ margin: '1px' }}>
                        <FilterRemoveOutline />
                        {translations.RESETVALUES}
                    </TRECButton>
                    {writeAccess && Object.keys(selectedRows).length > 0 && (
                        <TRECButton onClick={handleBatchUpdate} size="medium" style={{ margin: '1px' }}>
                            <DoneAll />
                            {translations.BATCH_UPDATE}
                        </TRECButton>
                    )}
                </SearchPanelControls>
            </SearchPanel>
            <EAMGridNoOverflow
                onRef={(ref) => assignRef(ref)}
                gridId={applicationData.gridRPRequestsGridID}
                cellRenderer={cellRenderer}
                showDataspySelection={false}
                hiddenTags={hiddenColumns()}
                onEditRow={writeAccess ? (row) => handleEdit(row) : undefined}
                heightFilterNotVisible="190px"
                headerStyle={{}}
                gridRequestAdapter={gridRequestAdapter}
                language={lang}
                allowRowSelection={writeAccess}
                isRowSelectable={isRowSelectable}
                onSelectRow={onSelectRow}
            />
        </div>
    );
};

class RPMSearch extends TRECSearchPage {
    renderPage(writeAccess) {
        return (
            <RPMSearchFC
                updateProperty={this.updateProperty}
                checkAccess={this.checkAccess}
                writeAccess={writeAccess}
                onPanelChange={this._onPanelChange}
                filters={this.state.filters}
                layout={this.state.layout}
                selectedRows={this.state.selectedRows}
                assignRef={(ref) => {
                    this.grid = ref;
                }}
                onSearchClick={this.onSearchClick}
                onClearClick={this.onClearClick}
                getGridFilterLocation={this._getGridFilterLocation}
                onSelectRow={(row, checked, selectedRows) => {
                    // Set the selected rows by default
                    this.setState(() => ({ selectedRows }));
                }}
                onKeyDown={this.onKeyDown}
                {...this.props}
            />
        );
    }
}

export default withStyles(trecPageStyles)(RPMSearch);
