import Wizard from 'amm-tools/dist/components/Wizard';
import { REQUEST_MODES } from 'enums/Constants';
import { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import DefaultStepper from 'ui/components/wizard/DefaultStepper';
import Tools from '../../../tools/Tools';
import WSWaterRelease from '../../../tools/rest/WSWaterRelease';
import TRECPage from '../TRECPage';
import { computePermissions } from '../rpmrequest2/InputGenerator';
import { STEPS, WATER_RELEASE_KEYS, WATER_RELEASE_KEYS_DTO, WRR_STATUS } from './WRRConstants';
import WRRequestControls from './WRRequestControls';
import WRRActionsStep from './steps/WRRActionsStep';
import WRRAssessmentStep from './steps/WRRAssessmentStep';
import WRRDecisionStep from './steps/WRRDecisionStep';
import WRRNoAccessStep from './steps/WRRNoAccessStep';
import WRRSamplingStep from './steps/WRRSamplingStep';
import WRRValidationStep from './steps/WRRValidationStep';
import { handleError } from 'tools/TrecNotifications';

// TODO translation should come from normal place
const WRR_STATUS_MAXSTEP = {
    [WRR_STATUS.CREATED]: STEPS.ACTIONS_STEP,
    [WRR_STATUS.PENDING]: STEPS.ASSESSMENT_STEP,
    [WRR_STATUS.ASSESSED_SELF_SAMPL]: STEPS.SAMPLING_STEP,
    [WRR_STATUS.ASSESSED_BZ_SAMPL]: STEPS.SAMPLING_STEP,
    [WRR_STATUS.ASSESSED_ASSIS_SAMPL]: STEPS.SAMPLING_STEP,
    [WRR_STATUS.IN_PROGRESS]: STEPS.SAMPLING_STEP,
    [WRR_STATUS.DECISION_PENDING]: STEPS.DECISION_STEP,
    [WRR_STATUS.CANCELLED]: STEPS.VALIDATION_STEP,
    [WRR_STATUS.NOT_APPROVED]: STEPS.DECISION_STEP,
    [WRR_STATUS.APPROVED]: STEPS.VALIDATION_STEP,
    [WRR_STATUS.RELEASED]: STEPS.VALIDATION_STEP,
};

const WRM_STEPS = {
    [STEPS.ACTIONS_STEP]: {
        key: STEPS.ACTIONS_STEP,
        Step: WRRActionsStep,
        translation: 'ACTIONS',
        permissionKey: 'WRP_ACTIONS',
        computeSpecialPermissions: ({ wrRequest, creatorOrRequester, submitted }) => {
            const a = !wrRequest[WATER_RELEASE_KEYS.WR_NUMBER]
                ? ['W']
                : creatorOrRequester
                ? submitted
                    ? ['R']
                    : ['W']
                : [];
            return a;
        },
    },
    [STEPS.ASSESSMENT_STEP]: {
        key: STEPS.ASSESSMENT_STEP,
        Step: WRRAssessmentStep,
        translation: 'ASSESSMENT',
        permissionKey: 'WRP_ASSESSMENT',
    },
    [STEPS.SAMPLING_STEP]: {
        key: STEPS.SAMPLING_STEP,
        Step: WRRSamplingStep,
        translation: 'SAMPLING',
        permissionKey: 'WRP_SAMPLING',
        computeSpecialPermissions: ({ wrRequest, creatorOrRequester }) =>
            creatorOrRequester &&
            [WRR_STATUS.ASSESSED_SELF_SAMPL, WRR_STATUS.ASSESSED_BZ_SAMPL].includes(
                wrRequest[WATER_RELEASE_KEYS.STATUS]
            )
                ? ['W']
                : [],
    },
    [STEPS.DECISION_STEP]: {
        key: STEPS.DECISION_STEP,
        Step: WRRDecisionStep,
        translation: 'DECISION',
        permissionKey: 'WRP_DECISION',
    },
    [STEPS.VALIDATION_STEP]: {
        key: STEPS.VALIDATION_STEP,
        Step: WRRValidationStep,
        translation: 'VALIDATION',
        permissionKey: 'WRP_VALIDATION',
        computeSpecialPermissions: ({ wrRequest, creatorOrRequester }) =>
            creatorOrRequester && wrRequest[WATER_RELEASE_KEYS.STATUS] === WRR_STATUS.APPROVED ? ['W'] : [],
    },
};

const wrapStepProps = (Component, stepProps) => (props) =>
    <Component style={{ overflowX: 'hidden' }} {...stepProps} {...props} />;

const getSteps = (stepProps = {}, perm) =>
    Tools.applyToFields(WRM_STEPS, (step) => ({
        ...step,
        getContent: wrapStepProps(perm[step.key].length > 0 ? step.Step : WRRNoAccessStep, {
            ...stepProps,
            writeAccess: perm[step.key]?.includes('W'),
            key: step.key,
        }),
        label: stepProps.getTranslation(step.translation),
        permissions: perm[step.key],
    }));

const getStepSequence = (properties) => {
    const status = properties[WATER_RELEASE_KEYS.STATUS];
    return Object.keys(WRM_STEPS).slice(
        0,
        Object.keys(WRM_STEPS).indexOf(WRR_STATUS_MAXSTEP[status] || STEPS.ACTIONS_STEP) + 1
    );
};

const isSubmitted = (properties) => {
    const status = properties[WATER_RELEASE_KEYS.STATUS];
    return ![undefined, WRR_STATUS.CREATED].includes(status);
};

const isCreatorOrRequester = ({ userData, wrRequest }) => {
    const req = wrRequest[WATER_RELEASE_KEYS.REQUESTER]?.username;
    const cre = wrRequest[WATER_RELEASE_KEYS.CREATED_BY]?.username;
    const jobNumber = wrRequest[WATER_RELEASE_KEYS.WR_NUMBER];
    const { userCode } = userData.eamAccount;
    return !jobNumber || [req, cre].includes(userCode);
};

const WRRequestFC = (props) => {
    const {
        getTranslation,
        applicationData,
        constants,
        userData,
        screenData,
        translations,
        storeActions,
        customFields,
        customFieldsDef,
        urlParams,
        bufferZone,
        dropdowns,
        firstStepIndex,
        wrrGetters,
        mode,
        match,
        history,
        writeAccess,
        setPage,
        authorizedMenus,
        lang,
        openConfirmDialog,
    } = props;

    const [initialized, setInitialized] = useState(false);
    const [loading, setLoading] = useState(mode !== REQUEST_MODES.CREATING);

    const wrrCode = match.params?.waterReleaseNumber;

    const wrRequest = wrrGetters.getProperties();
    const status = wrRequest[WATER_RELEASE_KEYS.STATUS];
    const creatorOrRequester = isCreatorOrRequester({ userData, wrRequest });
    const submitted = isSubmitted(wrRequest);

    const perm = computePermissions(WRM_STEPS, authorizedMenus, { wrRequest, creatorOrRequester, submitted });
    const canUpdate =
        writeAccess && ![WRR_STATUS.CANCELLED, WRR_STATUS.RELEASED, WRR_STATUS.NOT_APPROVED].includes(status);

    const reload = async () => {
        storeActions.resetWRR();
        setLoading(true);
        const data = await WSWaterRelease.readWRRequest(match.params.waterReleaseNumber);
        setLoading(false);
        storeActions.updateWRRRequest(data);
    };

    const updateWRR = async (updateFields) => {
        setLoading(true);
        try {
            const updateObj = Tools.filterObjectFieldsFromList(
                { ...wrRequest, ...updateFields },
                WATER_RELEASE_KEYS_DTO
            );
            await WSWaterRelease.updateWRRequest(updateObj);
            await reload();
        } catch (err) {
            setLoading(false);
            handleError(err);
        }
    };

    const stepProps = {
        getTranslation,
        applicationData,
        constants,
        userData,
        screenData,
        translations,
        storeActions,
        customFields,
        customFieldsDef,
        urlParams,
        bufferZone,
        dropdowns,
        wrrGetters,
        requestSource: urlParams.requestSource,
        history,
        writeAccess: canUpdate,
        originalWriteAccess: writeAccess,
        setPage,
        setLoading,
        reload,
        updateWRR,
        submitted,
        creatorOrRequester,
        lang,
    };

    const handleCancel = () => history.push('/menu');

    useEffect(() => {
        if (REQUEST_MODES.CREATING === mode) {
            storeActions.resetWRR({ userData });
            setInitialized(true);
        }
        if (REQUEST_MODES.EDITING === mode) {
            const fetch = async () => {
                await reload();
                setInitialized(true);
            };
            fetch();
        }
    }, [wrrCode]);

    return initialized ? (
        <BlockUi blocking={loading} style={{ height: '100%' }}>
            <Wizard
                {...props}
                steps={getSteps(stepProps, perm)}
                stepSequence={getStepSequence(wrRequest)}
                firstStepIndex={firstStepIndex || -1}
                handleCancel={handleCancel}
                controls={(p) => (
                    <WRRequestControls
                        {...p}
                        divStyle={{ margin: 'unset', height: 'unset', padding: 8 }}
                        getTranslation={getTranslation}
                        updateWRR={updateWRR}
                        canUpdate={canUpdate}
                        status={status}
                        permissions={perm}
                        writeAccess={writeAccess}
                        openConfirmDialog={openConfirmDialog}
                    />
                )}
                renderStepper={DefaultStepper}
            />
        </BlockUi>
    ) : (
        <BlockUi message="Loading..." blocking style={{ height: '100%', width: '100%' }} />
    );
};

class WRRequest extends TRECPage {
    renderPage(writeAccess) {
        return <WRRequestFC checkAccess={() => this.checkAccess()} writeAccess={writeAccess} {...this.props} />;
    }
}
export default WRRequest;
