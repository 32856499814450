import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { TRECIcon } from 'eam-components/dist/ui/components/icons/index';

const styles = (theme) => ({
    button: {
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        whiteSpace: 'wrap',
        textAlign: 'center',
        textDecoration: 'none',
    },

    icon: {
        color: theme.palette.primary.main,
    },

    '@media (max-width: 600px)': {
        button: {
            margin: '5px',
            width: '130px',
        },
        icon: {
            fontSize: '40px !important',
        },
    },
    '@media (min-width: 601px)': {
        button: {
            margin: '15px',
            width: '100px',
        },
        icon: {
            // color: "#00aaff",
            fontSize: '70px !important',
        },
    },
});

const linkStyle = {
    color: 'black',
    textDecoration: 'none',
};

const buttonText = {
    marginTop: '5px',
};

function MenuItem(props) {
    const { className, openNewTarget, link, icon, description, key, classes, ...other } = props;
    const parts = link ? link.split('?') : [''];
    const search = parts[1] ? `?${parts[1]}` : '';

    return (
        <div className={classes.button} {...other}>
            {openNewTarget && (
                <a href={link} style={linkStyle} target="_blank" rel="noreferrer">
                    <TRECIcon icon={icon} className={classes.icon} />
                    <div style={buttonText}>{description}</div>
                </a>
            )}
            {!openNewTarget && (
                <Link to={{ pathname: parts[0], search }} style={linkStyle}>
                    <TRECIcon icon={icon} className={classes.icon} />
                    <div style={buttonText}>{description}</div>
                </Link>
            )}
        </div>
    );
}

export default withStyles(styles)(MenuItem);
