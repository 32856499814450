import { WizardStep } from 'amm-tools';
import CommentsContainer from 'eam-components/dist/ui/components/comments/CommentsContainer';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import { REQUEST_MODES, TABLE_DATA_TYPES, TREC_GENERAL_KEYS } from 'enums/Constants';
import PrintIcon from 'mdi-material-ui/Printer';
import React from 'react';
import BlockUi from 'react-block-ui';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import WSComments from 'tools/rest/WSComments';
import WSMeasurements from 'tools/rest/WSMeasurements';
import WSRWPProcessing from 'tools/rest/WSRWPProcessing';
import Tools from 'tools/Tools';
import TRECButton from '../../../components/buttons/TRECButton';
import InputGenerator from '../../rpmrequest2/InputGenerator';
import { EQUIPMENT_KEYS, RWP_KEYS, TRANSLATION_KEYS } from '../RWPConstants';
import { isContainer, isContaminated } from '../RWProcessingTools';
import { handleError, showError } from 'tools/TrecNotifications';

const { ATTRIBUTES } = InputGenerator;

const getPropertiesFields = ({ rwpDropdowns, customFieldsDef, constants, getValues, rwpGetters, applicationData }) => {
    // TODO, review translation keys
    const rwpProperties = rwpGetters.getRWPProperties();
    return [
        {
            code: EQUIPMENT_KEYS.CODE,
            type: TABLE_DATA_TYPES.STATIC,
            translationKey: TRANSLATION_KEYS.OUTPUT_WASTE,
            getAttribute: () => ATTRIBUTES.READONLY_TEXT,
        },
        {
            code: EQUIPMENT_KEYS.SERIALNO,
            type: TABLE_DATA_TYPES.INPUT,
            translationKey: EQUIPMENT_KEYS.SERIALNO,
            getAttribute: () => ATTRIBUTES.OPTIONAL,
            customValue: (eqp) => (!eqp[EQUIPMENT_KEYS.SERIALNO] ? '' : eqp[EQUIPMENT_KEYS.SERIALNO]),
            customInputProps: () => ({
                style: {
                    minWidth: 200,
                    marginRight: '16px',
                },
                multiline: false,
                fullWidth: true,
            }),
        },
        {
            code: EQUIPMENT_KEYS.ELIMINATION_PROCESS,
            type: TABLE_DATA_TYPES.SELECT,
            translationKey: TRANSLATION_KEYS.ELIMINATION_PROCESS,
            getAttribute: (equipment) =>
                !isContainer(equipment) || equipment[EQUIPMENT_KEYS.CONTAINER_FULL]
                    ? ATTRIBUTES.OPTIONAL
                    : ATTRIBUTES.READONLY_TEXT,
            values: (equipment) =>
                getValues({
                    key: EQUIPMENT_KEYS.ELIMINATION_PROCESS,
                    object: equipment,
                    values: rwpDropdowns[EQUIPMENT_KEYS.ELIMINATION_PROCESS],
                }),
            customInputProps: () => ({
                style: { minWidth: 140 },
            }),
        },
        {
            code: EQUIPMENT_KEYS.CONTENT,
            type: TABLE_DATA_TYPES.AUTOCOMPLETE,
            translationKey: EQUIPMENT_KEYS.CONTENT,
            autocompleteType: (object) => (query) =>
                WSRWPProcessing.getContentEquipmentSuggestions({
                    jobID: rwpProperties[RWP_KEYS.JOB_NUMBER],
                    query,
                    containerCode: object[EQUIPMENT_KEYS.CODE],
                }),
            getAttribute: (eqp) => (!isContainer(eqp) ? ATTRIBUTES.HIDDEN : ATTRIBUTES.READONLY),
            // : eqp[EQUIPMENT_KEYS.RW_PROCESSING_DATE] && !inputWaste.includes(eqp[EQUIPMENT_KEYS.CODE])
            // ? ATTRIBUTES.READONLY
            // : ATTRIBUTES.REQUIRED,
            customInputProps: ({ object }) => ({
                creatable: false,
                ...(isContainer(object) ? { style: { minWidth: 150 } } : {}),
                columnsWidth: ['100%', '0%'],
            }),
        },
        {
            code: EQUIPMENT_KEYS.DESCRIPTION,
            type: TABLE_DATA_TYPES.INPUT,
            translationKey: EQUIPMENT_KEYS.DESCRIPTION,
            getAttribute: () => ATTRIBUTES.REQUIRED,
            customValue: (eqp) =>
                eqp[EQUIPMENT_KEYS.DESCRIPTION] &&
                eqp[EQUIPMENT_KEYS.DESCRIPTION].toUpperCase() === applicationData.rpeqpNewDesc.toUpperCase()
                    ? ''
                    : eqp[EQUIPMENT_KEYS.DESCRIPTION],
            customInputProps: () => ({
                style: {
                    minWidth: 200,
                    marginRight: '16px',
                },
                multiline: true,
                rowsMax: 3,
                fullWidth: true,
            }),
            customUpdateObject: (objToUpdate) => ({
                ...objToUpdate,
                [EQUIPMENT_KEYS.CONTENT_DESCRIPTION]: `RWP: ${objToUpdate[EQUIPMENT_KEYS.DESCRIPTION]}`,
            }),
        },
        {
            code: EQUIPMENT_KEYS.CONTENT_DESCRIPTION,
            type: TABLE_DATA_TYPES.INPUT,
            translationKey: EQUIPMENT_KEYS.CONTENT_DESCRIPTION,
            getAttribute: (eqp) => (!isContainer(eqp) ? ATTRIBUTES.HIDDEN : ATTRIBUTES.READONLY),
            customInputProps: () => ({
                style: {
                    minWidth: 200,
                    marginRight: '16px',
                },
                multiline: true,
                rowsMax: 3,
                fullWidth: true,
            }),
        },
        {
            code: EQUIPMENT_KEYS.COMPLETED_DATE,
            type: TABLE_DATA_TYPES.DATE,
            translationKey: EQUIPMENT_KEYS.COMPLETED_DATE,
            getAttribute: () => ATTRIBUTES.REQUIRED,
            customInputProps: () => ({
                style: { minWidth: 160 },
                timestamp: true,
                maxDate: new Date(),
            }),
        },
        {
            code: EQUIPMENT_KEYS.PERFORMED_BY,
            type: TABLE_DATA_TYPES.AUTOCOMPLETE,
            translationKey: EQUIPMENT_KEYS.PERFORMED_BY,
            getAttribute: () => ATTRIBUTES.REQUIRED,
            autocompleteType: () => WSAutocomplete.autocompleteEmployee,
            customInputProps: () => ({
                style: { minWidth: 160 },
            }),
        },
        {
            code: EQUIPMENT_KEYS.AD6_CONTACT,
            type: TABLE_DATA_TYPES.QUALIFIER_INPUT,
            translationKey: EQUIPMENT_KEYS.AD6_CONTACT,
            getAttribute: () => ATTRIBUTES.REQUIRED,
            customInputProps: ({ object }) => ({
                style: { maxWidth: 80 },
                prefixValue: object[EQUIPMENT_KEYS.AD6_CONTACT_QUALIFIER],
                prefixValueKey: EQUIPMENT_KEYS.AD6_CONTACT_QUALIFIER,
                codes: customFieldsDef[constants.cfpMeasValueQualifier1].pcode,
            }),
        },
        {
            code: EQUIPMENT_KEYS.BGO_CONTACT,
            type: TABLE_DATA_TYPES.QUALIFIER_INPUT,
            translationKey: EQUIPMENT_KEYS.BGO_CONTACT,
            getAttribute: (equipment) =>
                equipment[EQUIPMENT_KEYS.AD6_CONTACT] < 0.5 ? ATTRIBUTES.REQUIRED : ATTRIBUTES.HIDDEN,
            customInputProps: ({ object }) => ({
                style: { maxWidth: 80 },
                prefixValue: object[EQUIPMENT_KEYS.BGO_CONTACT_QUALIFIER],
                prefixValueKey: EQUIPMENT_KEYS.BGO_CONTACT_QUALIFIER,
                codes: customFieldsDef[constants.cfpMeasValueQualifier4].pcode,
            }),
        },
        {
            code: EQUIPMENT_KEYS.CONTENT_CONTAMINATED,
            type: TABLE_DATA_TYPES.SELECT,
            translationKey: EQUIPMENT_KEYS.CONTENT_CONTAMINATED,
            getAttribute: () => ATTRIBUTES.REQUIRED,
            values: (equipment) =>
                getValues({
                    key: EQUIPMENT_KEYS.CONTENT_CONTAMINATED,
                    object: equipment,
                    values: Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpContaminationRisk], true),
                }),
            customInputProps: () => ({
                style: { maxWidth: 80 },
            }),
        },
        {
            code: EQUIPMENT_KEYS.BQ_ALPHA,
            type: TABLE_DATA_TYPES.QUALIFIER_INPUT,
            translationKey: EQUIPMENT_KEYS.BQ_ALPHA,
            getAttribute: (equipment) => (isContaminated(equipment) ? ATTRIBUTES.REQUIRED : ATTRIBUTES.HIDDEN),
            customInputProps: ({ object }) => ({
                style: { maxWidth: 80 },
                prefixValue: object[EQUIPMENT_KEYS.BQ_ALPHA_QUALIFIER],
                prefixValueKey: EQUIPMENT_KEYS.BQ_ALPHA_QUALIFIER,
                codes: customFieldsDef[constants.cfpAlphaMeasQualifier].pcode,
            }),
        },
        {
            code: EQUIPMENT_KEYS.BQ_BETA,
            type: TABLE_DATA_TYPES.QUALIFIER_INPUT,
            translationKey: EQUIPMENT_KEYS.BQ_BETA,
            getAttribute: (equipment) => (isContaminated(equipment) ? ATTRIBUTES.REQUIRED : ATTRIBUTES.HIDDEN),
            customInputProps: ({ object }) => ({
                style: { maxWidth: 80 },
                prefixValue: object[EQUIPMENT_KEYS.BQ_BETA_QUALIFIER],
                prefixValueKey: EQUIPMENT_KEYS.BQ_BETA_QUALIFIER,
                codes: customFieldsDef[constants.cfpBetaMeasQualifier].pcode,
            }),
        },
        {
            code: EQUIPMENT_KEYS.CONTAIN_ALPHA_EMITTER,
            type: TABLE_DATA_TYPES.SELECT,
            translationKey: EQUIPMENT_KEYS.CONTAIN_ALPHA_EMITTER,
            getAttribute: () => ATTRIBUTES.REQUIRED,
            values: (equipment) =>
                getValues({
                    key: EQUIPMENT_KEYS.CONTAIN_ALPHA_EMITTER,
                    object: { ...equipment, ...rwpProperties },
                    values: rwpDropdowns[EQUIPMENT_KEYS.CONTAIN_ALPHA_EMITTER],
                }),
            customInputProps: () => ({
                style: { minWidth: 100 },
            }),
        },
        {
            code: EQUIPMENT_KEYS.RW_FAMILY,
            type: TABLE_DATA_TYPES.SELECT,
            translationKey: EQUIPMENT_KEYS.RW_FAMILY,
            getAttribute: () => ATTRIBUTES.REQUIRED,
            values: (equipment) =>
                getValues({
                    key: EQUIPMENT_KEYS.RW_FAMILY,
                    object: equipment,
                    values: rwpDropdowns[EQUIPMENT_KEYS.RW_FAMILY],
                }),
            customInputProps: () => ({
                style: { minWidth: 160 },
                autoSelectSingleResult: true,
            }),
        },
        {
            code: EQUIPMENT_KEYS.SUB_FAMILY,
            type: TABLE_DATA_TYPES.SELECT,
            translationKey: EQUIPMENT_KEYS.SUB_FAMILY,
            getAttribute: () => ATTRIBUTES.OPTIONAL,
            values: (equipment) =>
                getValues({
                    key: EQUIPMENT_KEYS.SUB_FAMILY,
                    object: equipment,
                    values: rwpDropdowns[EQUIPMENT_KEYS.SUB_FAMILY],
                }),
            customInputProps: () => ({
                style: { minWidth: 160 },
                autoSelectSingleResult: true,
            }),
        },
        {
            code: EQUIPMENT_KEYS.MATERIAL,
            type: TABLE_DATA_TYPES.SELECT,
            translationKey: EQUIPMENT_KEYS.MATERIAL,
            getAttribute: () => ATTRIBUTES.OPTIONAL,
            values: (equipment) =>
                getValues({
                    key: EQUIPMENT_KEYS.MATERIAL,
                    object: equipment,
                    values: rwpDropdowns[EQUIPMENT_KEYS.MATERIAL],
                }),
            customInputProps: () => ({
                multi: true,
                style: { minWidth: 160 },
            }),
        },
        {
            translationKey: TRANSLATION_KEYS.PRINT,
            customValue: (object) => (
                <TRECButton
                    style={{ minWidth: '0px', minHeight: '30px' }}
                    onClick={() => window.open(`${applicationData.rwpprintURL}${object[EQUIPMENT_KEYS.CODE]}`)}
                >
                    <PrintIcon />
                </TRECButton>
            ),
            getAttribute: () => ATTRIBUTES.READONLY_TEXT,
            customInputProps: () => ({
                style: { maxWidth: 60 },
            }),
        },
    ];
};

const getDeviceFields = (containsElimPath) => [
    {
        code: EQUIPMENT_KEYS.DEVICE_AD6_SN,
        type: TABLE_DATA_TYPES.INPUT,
        translationKey: EQUIPMENT_KEYS.DEVICE_AD6_SN,
        getAttribute: () => (containsElimPath ? ATTRIBUTES.REQUIRED : ATTRIBUTES.READONLY),
    },
    {
        code: EQUIPMENT_KEYS.DEVICE_BGO_SN,
        type: TABLE_DATA_TYPES.INPUT,
        translationKey: EQUIPMENT_KEYS.DEVICE_BGO_SN,
        getAttribute: () => (containsElimPath ? ATTRIBUTES.REQUIRED : ATTRIBUTES.READONLY),
    },
    {
        code: EQUIPMENT_KEYS.DEVICE_ADK_SN,
        type: TABLE_DATA_TYPES.INPUT,
        translationKey: EQUIPMENT_KEYS.DEVICE_ADK_SN,
        getAttribute: () => (containsElimPath ? ATTRIBUTES.REQUIRED : ATTRIBUTES.READONLY),
    },
];

export default class PropertiesStep extends WizardStep {
    formFields = [];

    state = {
        loadingDevices: false,
        panelExpanded: false,
    };

    componentDidMount() {
        super.componentDidMount();
        const { rwpGetters, mode, storeActions, setLoading } = this.props;
        const outputEquipmentList = rwpGetters.getOutputEquipmentList();
        const loadedDevicesSN = rwpGetters.getLoadedDevicesSN();
        const outputEquipmentMap = rwpGetters.getOutputEquipmentMap();

        if (
            [REQUEST_MODES.CREATING, REQUEST_MODES.EDITING].includes(mode) &&
            !loadedDevicesSN &&
            outputEquipmentList.length
        ) {
            this.fetchDevices();
        }
        this.updateEquipment();
        (async () => {
            const missingContent = Object.values(outputEquipmentMap)
                .filter(isContainer)
                .filter((eqp) => !eqp[EQUIPMENT_KEYS.CONTENT])
                .map((eqp) => eqp[EQUIPMENT_KEYS.CODE]);
            if (missingContent.length) {
                setLoading(true);
                try {
                    const virtualCodes = await WSRWPProcessing.createVirtualCodes(missingContent.length);
                    const eqpMap = missingContent.reduce(
                        (acc, code, i) => ({
                            ...acc,
                            [code]: {
                                ...outputEquipmentMap[code],
                                [EQUIPMENT_KEYS.CONTENT]: virtualCodes[i],
                            },
                        }),
                        {}
                    );
                    storeActions.updatedOutputEquipmentMap(eqpMap);
                    setLoading(false);
                } catch (err) {
                    console.error(err);
                }
            }
        })();
    }

    componentDidUpdate() {
        this.updateEquipment();
    }

    async updateEquipment() {
        const { rwpGetters, storeActions } = this.props;
        const outputEquipmentMap = rwpGetters.getOutputEquipmentMap();
        const rwpProperties = rwpGetters.getRWPProperties();

        const eqpConfs = rwpProperties[RWP_KEYS.EQUIPMENT_CONFIGURATIONS];

        let update = false;

        const eqMap = Tools.applyToFields(outputEquipmentMap, (eqp) => {
            if (eqp[EQUIPMENT_KEYS.DESCRIPTION] === eqpConfs[eqp[EQUIPMENT_KEYS.CODE]]?.[EQUIPMENT_KEYS.DESCRIPTION]) {
                update = true;
                return {
                    ...eqp,
                    [EQUIPMENT_KEYS.DESCRIPTION]: ``,
                    [EQUIPMENT_KEYS.CONTENT_DESCRIPTION]: `RWP: ${eqp[EQUIPMENT_KEYS.DESCRIPTION]}`,
                };
            }
            if (
                eqp[EQUIPMENT_KEYS.DESCRIPTION] &&
                `RWP: ${eqp[EQUIPMENT_KEYS.DESCRIPTION]}` !== eqp[EQUIPMENT_KEYS.CONTENT_DESCRIPTION]
            ) {
                update = true;
                return {
                    ...eqp,
                    [EQUIPMENT_KEYS.CONTENT_DESCRIPTION]: `RWP: ${eqp[EQUIPMENT_KEYS.DESCRIPTION]}`,
                };
            }
            return eqp;
        });

        if (update) {
            storeActions.updatedOutputEquipmentMap(eqMap);
        }
    }

    canContinue = () => {
        const { getTranslation, rwpGetters } = this.props;
        const rwpProperties = rwpGetters.getRWPProperties();
        const outputEquipmentMap = rwpGetters.getOutputEquipmentMap();
        const contentEquipmentList = Object.values(outputEquipmentMap)
            .map((eq) => eq[EQUIPMENT_KEYS.CONTENT])
            .filter(Boolean);
        const rwpRequestEquipmentList = [
            ...new Set([...rwpGetters.getOutputEquipmentList(), ...(rwpProperties[RWP_KEYS.INPUT_EQUIPMENT] || [])]),
        ];
        const hasRepeatedContentEquipment = new Set(contentEquipmentList).size !== contentEquipmentList.length;
        const hasRWPRequestEquipmentAsContent = contentEquipmentList.some((eq) => rwpRequestEquipmentList.includes(eq));
        if (hasRepeatedContentEquipment) {
            showError(getTranslation(TRANSLATION_KEYS.REPEATED_CONTENT_ERROR_MSG));
            return false;
        }

        if (hasRWPRequestEquipmentAsContent) {
            showError(getTranslation(TRANSLATION_KEYS.REQUEST_EQP_AS_CONTENT_ERROR_MSG));
            return false;
        }

        const valid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );

        if (!valid) {
            showError(getTranslation(TREC_GENERAL_KEYS.REQUIRED_ERROR_MESSAGE), null, 4000);
        }

        return valid;
    };

    saveChanges = () => true;

    commitChanges = (callback) => callback();

    fetchDevices = () => {
        const { constants, rwpGetters, storeActions } = this.props;
        const outputEquipmentMap = rwpGetters.getOutputEquipmentMap();
        const firstEquipment = Object.values(outputEquipmentMap)[0] || {};
        const fetchDeviceMap = {
            [EQUIPMENT_KEYS.DEVICE_AD6_SN]: constants.deviceAd6,
            [EQUIPMENT_KEYS.DEVICE_BGO_SN]: constants.deviceBgo,
            [EQUIPMENT_KEYS.DEVICE_ADK_SN]: 'ADK', // TODO, should be a constant
        };
        const devicesToFetch = Object.entries(fetchDeviceMap).filter(([key, _]) => !firstEquipment[key]);
        if (devicesToFetch.length) {
            this.setState({ loadingDevices: true });
            Promise.all(devicesToFetch.map(([key, entry]) => this.fetchDevice(key, entry)))
                .then(() => storeActions.setLoadedDevicesSN(true))
                .finally(() => this.setState({ loadingDevices: false }));
        }
    };

    fetchDevice = async (key, code) => {
        const { storeActions, rwpGetters } = this.props;
        const outputEquipmentList = rwpGetters.getOutputEquipmentList();
        const deviceCode = `PWD${code.replace(' (c/s)', '').toUpperCase()}`; // TODO, find a better solution for this (right now is the same in rpmperform)
        return WSMeasurements.getDeviceSerial(deviceCode)
            .then((response) => {
                const device = response.body.data;
                if (device && device.serialNumber) {
                    storeActions.updatedOutputEquipment(outputEquipmentList, { [key]: device.serialNumber });
                }
            })
            .catch(handleError);
    };

    render() {
        const { HeaderInfo, rwpGetters, storeActions, getTranslation, isReadOnly } = this.props;
        const { loadingDevices, panelExpanded } = this.state;
        const outputEquipmentList = rwpGetters.getOutputEquipmentList();
        const outputEquipmentMap = rwpGetters.getOutputEquipmentMap();

        const containsElimPath = !Object.values(outputEquipmentMap).every(
            (e) => !e[EQUIPMENT_KEYS.ELIMINATION_PROCESS]
        );

        const devicesFields = getDeviceFields(containsElimPath);
        const propertiesFields = getPropertiesFields(this.props);

        const updateCommentField = (key, value) => {
            this.setState({ selectedEquipment: value });
        };

        const updateOutputEquipment = (eqCode, fieldCode, field) => (key, value) => {
            let eqList = [eqCode];
            if (rwpGetters.isApplyAllLines()) {
                if (!field) return;
                eqList = outputEquipmentList.filter(
                    (outputEqCode) =>
                        !field.getAttribute ||
                        [ATTRIBUTES.OPTIONAL, ATTRIBUTES.REQUIRED].includes(
                            field.getAttribute(outputEquipmentMap[outputEqCode])
                        )
                );
            }
            storeActions.updatedOutputEquipment(
                eqList,
                field.customUpdateObject?.({ [key]: value }) ?? { [key]: value }
            );
        };

        const allFields = outputEquipmentList.map((eqCode) =>
            propertiesFields.reduce(
                (acc, field) => ({
                    ...acc,
                    [field.code]: (
                        <div key={field.code}>
                            {InputGenerator.generate({
                                field: {
                                    ...field,
                                    elementInfo: {
                                        ...field.elementInfo,
                                        xpath: `${field.code}${eqCode}`,
                                    },
                                    ...(field.autocompleteType
                                        ? { autocompleteType: field.autocompleteType(outputEquipmentMap[eqCode] || {}) }
                                        : {}),
                                },
                                object: outputEquipmentMap[eqCode] || {},
                                updateObject: updateOutputEquipment(eqCode, field.code, field),
                                formFields: this.formFields,
                                hideLabel: true,
                                ...(isReadOnly &&
                                (!field.getAttribute ||
                                    field.getAttribute(outputEquipmentMap[eqCode]) !== ATTRIBUTES.READONLY_TEXT)
                                    ? {
                                          getAttribute: () => ATTRIBUTES.READONLY,
                                      }
                                    : {}),
                            })}
                        </div>
                    ),
                }),
                {}
            )
        );

        const allDeviceFields = devicesFields.reduce(
            (acc, field) => ({
                ...acc,
                [field.code]: (
                    <div key={field.code}>
                        {InputGenerator.generate({
                            field: {
                                getAttribute: () => ATTRIBUTES.OPTIONAL,
                                ...field,
                                elementInfo: {
                                    ...field.elementInfo,
                                    text: getTranslation(field.translationKey),
                                    xpath: field.code,
                                },
                                customInputProps: () => ({
                                    style: { maxWidth: 160 },
                                    labelStyle: {
                                        width: 'unset',
                                        minWidth: 'unset',
                                        padding: 10,
                                    },
                                }),
                            },
                            object: Object.values(outputEquipmentMap)[0] || {},
                            updateObject: (key, value) =>
                                storeActions.updatedOutputEquipment(outputEquipmentList, { [key]: value }),
                            formFields: this.formFields,
                        })}
                    </div>
                ),
            }),
            {}
        );

        return (
            <div style={{ padding: 8, display: 'inline-block' }}>
                <EISPanel
                    heading={getTranslation(TRANSLATION_KEYS.RPM_AND_RW_PROPERTIES)}
                    detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                    alwaysExpanded
                >
                    <BlockUi
                        style={{ display: 'flex', flexDirection: 'row', marginLeft: -10 }}
                        blocking={loadingDevices}
                    >
                        {Object.values(allDeviceFields)}
                    </BlockUi>
                    <EAMCheckbox
                        label={getTranslation(TRANSLATION_KEYS.APPLY_ALL_LINES)}
                        value={rwpGetters.isApplyAllLines()}
                        updateProperty={(key, value) => storeActions.setApplyAllLines(value)}
                        trueValue
                        falseValue={false}
                    />
                    <EISTable
                        data={allFields}
                        headers={propertiesFields.map((field) => getTranslation(field.translationKey))}
                        propCodes={propertiesFields.map((field) => field.code)}
                        style={{ width: 'auto', height: 'auto' }}
                    />
                </EISPanel>
                <EISPanel
                    heading={getTranslation(TRANSLATION_KEYS.EXTRA_INFO)}
                    detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                    panelExpanded={panelExpanded}
                    onPanelChange={(s) => this.setState({ panelExpanded: s })}
                >
                    <HeaderInfo />
                </EISPanel>
                {false && (
                    <EISPanel heading={getTranslation(TRANSLATION_KEYS.COMMENTS)} alwaysExpanded={false}>
                        <div style={{ display: 'block', width: '100%' }}>
                            <EAMSelect
                                elementInfo={{ text: getTranslation(TRANSLATION_KEYS.OUTPUT_WASTE) }}
                                values={outputEquipmentList.map((outputEquipment) => ({
                                    code: outputEquipment,
                                    desc: outputEquipment,
                                }))}
                                updateProperty={updateCommentField}
                                value={this.state.selectedEquipment}
                            />
                            {this.state.selectedEquipment && (
                                <CommentsContainer
                                    entityCode="EVNT"
                                    entityKeyCode={outputEquipmentMap[this.state.selectedEquipment].workorderCode}
                                    userDesc={this.props.userData.eamAccount.userDesc}
                                    readComments={WSComments.readComments}
                                    createComment={WSComments.createComment}
                                    updateComment={WSComments.updateComment}
                                />
                            )}
                        </div>
                    </EISPanel>
                )}
            </div>
        );
    }
}
