import { connect } from 'react-redux';
import LanguageSelection from './LanguageSelection';
import { updateLayout, setPage } from '../../../actions/layoutActions';

const mapStateToProps = (state) => ({
    userData: state.application.userData,
    applicationData: state.application.applicationData,
    loginToken: state.application.loginToken,
    bufferZone: state.application.bufferZone,
    bufferZoneAccount: state.application.applicationData ? state.application.applicationData.bufferZoneAccount : '',
});

const LanguageSelectionContainer = connect(mapStateToProps, {
    updateLayout,
    setPage,
})(LanguageSelection);

export default LanguageSelectionContainer;
