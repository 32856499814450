/* eslint-disable no-underscore-dangle */

import Grid from '@material-ui/core/Grid';
import { WizardStep } from 'amm-tools';
import { EQUIPMENT_KEYS, RPAR_REQUEST_KEYS } from 'enums/Constants';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import WSRPARequest from 'tools/rest/WSRPARequest';
import Tools from 'tools/Tools';
import { handleError, showWarning } from 'tools/TrecNotifications';
import EquipmentAutocomplete from 'ui/components/autocomplete/EquipmentAutocomplete';

import WSEquipment from 'tools/rest/WSEquipment';

class RPAREquipment extends WizardStep {
    state = {};

    formFields = [];

    static getDerivedStateFromProps(props, state) {
        const equipment = Tools.getURLParameterByName('equipment');
        return {
            selectedEquipments:
                state.selectedEquipments ||
                (equipment && equipment.split(',').map((eqCode) => ({ eqCode, description: eqCode }))) ||
                props.rparGetters.getEquipmentList().map((eqCode) => ({ eqCode, description: eqCode })),
        };
    }

    canContinue = () => {
        const { screenData } = this.props;
        const equipmentFormField = this.formFields[screenData.woFields.equipment.xpath];
        return equipmentFormField.validate();
    };

    saveChanges = () => this.propagateEquipmentListUpdate();

    commitChanges = (callback) => {
        const { selectedEquipments } = this.state;
        const { rparActions, rparGetters, setLoading, applyDefaults, applicationData } = this.props;
        const equipmentMap = rparGetters.getEquipments();

        // If the equipment is an empty object, it hasn't been fetched
        const unfetchedList = selectedEquipments
            .map((s) => s.eqCode)
            .filter((code) => !equipmentMap[code] || !Object.values(equipmentMap[code]).length);

        if (unfetchedList.length > 0) {
            setLoading(true);
            Promise.all(
                unfetchedList.map((code) =>
                    Promise.all([
                        WSRPARequest.getEquipment({ applicationData, code }),
                        WSRPARequest.getLastRPMeasurement(code),
                    ])
                )
            )
                .then((respArray) => {
                    const fetchedEquipmentMap = respArray.reduce((acc, [respEq, respRPM]) => {
                        if (!(respEq && respEq.body && respEq.body.data)) return acc;
                        // eslint-disable-next-line
                        respEq = respEq.body.data;
                        respEq._oldLocation = respEq[EQUIPMENT_KEYS.OBJ_LOCATION];
                        respEq._oldLastLocation = respEq[EQUIPMENT_KEYS.LAST_LOCATION_CODE];
                        if (respEq[EQUIPMENT_KEYS.RW_RECEPTION_DATE]) {
                            respEq[EQUIPMENT_KEYS.DETACH] = false;
                        }
                        const eq = {
                            ...(respRPM?.body?.data || {}),
                            ...respEq,
                        };
                        delete eq[EQUIPMENT_KEYS.RPA_MEASUREMENT];
                        return {
                            ...acc,
                            [respEq[EQUIPMENT_KEYS.EQUIPMENT_CODE]]: eq,
                        };
                    }, {});

                    rparActions.updateRPAREquipments(fetchedEquipmentMap, applyDefaults);
                    callback();
                })
                .catch(handleError)
                .finally((_) => setLoading(false));
        } else {
            callback();
        }
    };

    propagateEquipmentListUpdate = () => {
        const { rparActions } = this.props;
        const { selectedEquipments } = this.state;
        rparActions.setRPAREquipmentList(selectedEquipments.map((s) => s.eqCode));

        return true;
    };

    handleEquipmentListUpdate = (value, updValue) => {
        const { applicationData } = this.props;
        const maxSelectedEquipments = applicationData.rpanalysisMaxEquipments;

        if (value?.length > maxSelectedEquipments) {
            showWarning(`You cannot select more than ${maxSelectedEquipments} equipments`);
            return;
        }

        if (updValue?.action === 'select-option' && updValue.option.rwReceptionDate) {
            const func = async () => {
                const a = (await WSEquipment.getStructure({ equipmentID: updValue.option.eqCode }))?.body?.data;
                const moreValues = a
                    ?.filter((s) => s.level === 1)
                    .map((s) => s.equipmentInfo)
                    .filter((s) => !value?.find((prevValue) => prevValue.eqCode === s.eqCode));

                const newEQList = [...(value ?? []), ...(moreValues ?? [])];
                if (value?.length > maxSelectedEquipments) {
                    showWarning(`The
                    number of immediate children of top parent ${updValue.option.eqCode} exceed the limit.
                    Hence they will not be added to the request.`);
                    return;
                }
                this.setState({
                    selectedEquipments: newEQList,
                });
            };
            func();
            return;
        }

        this.setState({
            selectedEquipments: value || [],
        });
    };

    render() {
        const { selectedEquipments } = this.state;
        const { screenData, getTranslation, applicationData } = this.props;
        const equipmentField = screenData.woFields.equipment;

        return (
            <Grid container>
                <Grid item md={6} sm={12} xs={12}>
                    <EISPanel heading={getTranslation(RPAR_REQUEST_KEYS.ANALYSIS_SAMPLE)} alwaysExpanded>
                        <EquipmentAutocomplete
                            formFields={this.formFields}
                            value={selectedEquipments}
                            onChange={this.handleEquipmentListUpdate}
                            elementInfo={{
                                ...equipmentField,
                                text: undefined,
                                attribute: 'R',
                                maxUpdateRecords: applicationData.rpanalysisMaxEquipments,
                            }}
                        />
                    </EISPanel>
                </Grid>
            </Grid>
        );
    }
}

export default RPAREquipment;
