import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import { GRID_ROW_COLORS } from 'enums/Constants';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { generateRoute, ROUTES, withQueryParams } from 'tools/Routes';
import Tools from 'tools/Tools';
import TRECButton from 'ui/components/buttons/TRECButton';
import EAMGridNoOverflow from 'ui/components/grid/EAMGridNoOverflow';
import gridNoOverflowClasses from 'ui/components/grid/EAMGridNoOverflow.module.css';
import { getRowAsAnObject } from 'ui/pages/rwmanagement/RWManagementUtils';
import TRECPage from 'ui/pages/TRECPage';
import { STEPS } from '../vaccleanerrequest/VacCleanerConstants';
import { ENTITY_TRANSL_PREFIX } from '../wrrequest/WRRConstants';
import { TRECText } from '../wrrequest/WRRHelpers';
import { TranslatedDiv } from '../wrsearch/WaterReleaseSearch';
import VacuumCleanerManageButtons from './VacuumCleanerManageButtons';
import { showError } from 'tools/TrecNotifications';

const CELL_TYPES = {
    VACEQUEST_CODE: 'evt_code',
    EQUIPMENT: 'evt_object',
    STATUS: 'evt_status',
    COMMENTS: 'comments',
};

const TRANSLATION_KEYS = {
    STATUS_DESC: 'STATUS_DESC',
};

const getCellRenderer =
    ({ getTranslation }) =>
    ({ t, value }, row) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const object = getRowAsAnObject(row);
        if (t === CELL_TYPES.VACEQUEST_CODE) {
            return <Link to={generateRoute(ROUTES.vaccleanerRequestEdit, { vaccleanerNumber: value })}>{value}</Link>;
        }
        if (t === CELL_TYPES.RPANALYSIS) {
            return value && <Link to={generateRoute(ROUTES.rpamRequestEdit, { requestwonumber: value })}>{value}</Link>;
        }
        if (t === CELL_TYPES.COMMENTS) {
            const comments = value?.split(/\r?\n/);
            return comments?.map((comment) => <Typography>{comment}</Typography>);
        }
        if (t === CELL_TYPES.RPMEASUREMENT) {
            return (
                value && (
                    <Link to={withQueryParams({ path: ROUTES.rpmPerform, queryParams: { wo: value } })}>{value}</Link>
                )
            );
        }
        if (t === TRANSLATION_KEYS.STATUS_DESC) {
            return (
                <TranslatedDiv
                    code={ENTITY_TRANSL_PREFIX.EVT_STATUS + object[CELL_TYPES.STATUS]}
                    getTranslation={getTranslation}
                />
            );
        }
        return null;
    };

const getGridRequestAdapter =
    ({ writeAccess, userData }) =>
    (gridRequest) => {
        let gridFilters = [...gridRequest.gridFilter];
        // People with no write access can only see their requests
        if (!writeAccess) {
            gridFilters = gridFilters.filter((f) => !['evt_createdby', 'evt_origin'].includes(f.fieldName));
            gridFilters.push({
                fieldName: 'evt_createdby',
                fieldValue: userData.eamAccount.userCode,
                operator: 'EQUALS',
                joiner: 'OR',
                leftParenthesis: true,
            });
            gridFilters.push({
                fieldName: 'evt_origin',
                fieldValue: userData.eamAccount.employeeCode,
                operator: 'EQUALS',
                joiner: 'AND',
                rightParenthesis: true,
            });
        }
        return {
            ...gridRequest,
            gridFilter: gridFilters,
        };
    };

const onClickNavigate = async (history, vacRequest, getTranslation) => {
    let vacNumber;
    if (/^[0-9]+$/.test(vacRequest)) {
        vacNumber = vacRequest;
    } else {
        showError(getTranslation('NO_VAC_REQUEST_FOUND'));
        // vacNumber = await WSVacRental.getVacCleanerRequestNumberFromVacCleaner({ code: vacRequest });
        // if (vacNumber?.length > 1) {
        //     showError(getTranslation('TOO_MANY_VAC_REQUEST'));
        //     return null;
        // }
        // if (!vacNumber?.[0]) {
        //     showError(getTranslation('NO_VAC_REQUEST_FOUND'));
        //     return null;
        // }
        // vacNumber = vacNumber?.[0];
    }
    history.push(
        generateRoute(ROUTES.workOrdersRedirect, {
            workOrderID: vacNumber,
        })
    );
    return null;
};

const rowStyler = (row) => {
    const rowObject = Tools.getRowAsAnObject(row);
    return GRID_ROW_COLORS[rowObject.row_color] || {};
};

const VacuumCleanerManage = ({ userData, writeAccess, applicationData, getTranslation, history, page, lang, menu }) => {
    const vacStockAccess = menu.some((menuEntry) => menuEntry.menuCode === 'VAC_CLEANER_STOCK');
    const extraColumns = [
        {
            width: '150px',
            t: TRANSLATION_KEYS.STATUS_DESC,
            headerLabel: getTranslation(TRANSLATION_KEYS.STATUS_DESC),
        },
    ];

    const [vacRequest, setVacRequest] = useState('');
    return (
        <div className={gridNoOverflowClasses.outerBlock}>
            <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <Grid container spacing={0} xs={8}>
                    {writeAccess ? null : (
                        <TRECText
                            style={{ maxWidth: '410px', marginRight: '12px' }}
                            text={getTranslation('VAC_MANAGE_INFO')}
                        />
                    )}
                    <Grid item>
                        <VacuumCleanerManageButtons
                            history={history}
                            getTranslation={getTranslation}
                            page={page}
                            writeAccess={writeAccess}
                            vacStockAccess={vacStockAccess}
                        />
                    </Grid>
                    <Grid item style={{ alignSelf: 'center' }}>
                        <TRECButton
                            align="center"
                            size="small"
                            onClick={() =>
                                history.push(
                                    withQueryParams({
                                        path: ROUTES.vaccleanerRequest,
                                        queryParams: {
                                            step: STEPS.MODELS,
                                        },
                                    })
                                )
                            }
                        >
                            {getTranslation('CREATE_NEW')}
                        </TRECButton>
                    </Grid>
                    <Grid item style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        <Typography variant="subtitle1" color="textPrimary">
                            {getTranslation('CANT_SEE_YOUR_REQUEST')}
                        </Typography>
                        <EAMInput
                            style={{ maxWidth: '270px', minWidth: '170px' }}
                            value={vacRequest}
                            updateProperty={(key, val) => setVacRequest(val)}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    history.push(
                                        generateRoute(ROUTES.workOrdersRedirect, {
                                            workOrderID: e.target.value,
                                        })
                                    );
                                }
                            }}
                        />
                        <Grid item align="right">
                            <TRECButton
                                style={{ height: '100%' }}
                                size="small"
                                disabled={!vacRequest}
                                onClick={() => onClickNavigate(history, vacRequest, showError, getTranslation)}
                            >
                                {getTranslation('NAVIGATE')}
                            </TRECButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="column" align="center" xs={4}>
                    <Divider />
                    <Typography
                        variant="subtitle1"
                        style={{ color: '#FF0000', fontStyle: 'italic', fontWeight: 'bold' }}
                    >
                        {getTranslation('VAC_REQUEST_INFO')}
                    </Typography>
                    <Divider />
                    <Typography variant="subtitle2" color="textPrimary">
                        {getTranslation('VAC_CLEANER_PROCEDURE')}
                        <Link to={{ pathname: `https://edms.cern.ch/document/1728012` }} target="_blank">
                            EDMS 1728012
                        </Link>
                    </Typography>
                    <Typography variant="subtitle2" color="textPrimary">
                        {getTranslation('SAFETY_INSTRUCTION')}
                        <Link to={{ pathname: `https://edms.cern.ch/document/1556473` }} target="_blank">
                            EDMS 1556473
                        </Link>
                    </Typography>
                    <Typography variant="subtitle2" style={{ background: '#0047AB', color: '#FFFFFF' }}>
                        {getTranslation('CHECK_DELIVERY_TIME')}
                    </Typography>
                </Grid>
            </Grid>
            <EAMGridNoOverflow
                gridId={applicationData.vacCleanersRentalsGridID}
                gridRequestAdapter={getGridRequestAdapter({ writeAccess, userData })}
                extraColumns={extraColumns}
                bypassAlternateRows
                filterVisible
                cellRenderer={getCellRenderer({ getTranslation })}
                heightFilterVisible="245px"
                heightFilterNotVisible="195px"
                rowStyler={rowStyler}
                hiddenTags={['row_color']}
                language={lang}
            />
        </div>
    );
};

class VacuumCleanerManageClass extends TRECPage {
    renderPage(writeAccess) {
        return <VacuumCleanerManage writeAccess={writeAccess} {...this.props} />;
    }
}

export default VacuumCleanerManageClass;
