import { Grid, Typography } from '@material-ui/core';
import { AMMSingleAutocomplete, WizardStep } from 'amm-tools';
import CommentsContainer from 'eam-components/dist/ui/components/comments/CommentsContainer';
import { TABLE_DATA_TYPES } from 'enums/Constants';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import { ROUTES, generateRoute } from 'tools/Routes';
import WSEquipment from 'tools/rest/WSEquipment';
import WSComments from 'tools/rest/WSWRComments';
import WSWaterRelease from 'tools/rest/WSWaterRelease';
import EquipmentOption from '../../../components/autocomplete/EquipmentOption';
import InputGenerator from '../../rpmrequest2/InputGenerator';
import { TRANSLATION_KEYS, WATER_RELEASE_KEYS, WRR_STATUS } from '../WRRConstants';
import { ATTRIBUTES, WarningText, generateWRRFields, getWRRActionFields } from '../WRRHelpers';
import { showError, showSuccess } from 'tools/TrecNotifications';

export const SingleValueContent = ({ data }) => {
    const { code, description, newOption } = data;
    return code ? (
        <div
            style={{
                border: '1px solid #e6e6e6',
                borderLeft: `4px solid ${newOption ? 'red' : 'green'}`,
                background: 'white',
                display: 'flex',
                direction: 'row',
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
            }}
        >
            <Typography style={{ margin: 0, padding: 0 }} variant="subtitle1" component="div">
                <div style={{ margin: 0, padding: 0 }}>{code}</div>
            </Typography>

            <Typography
                style={{ alignSelf: 'center', marginLeft: '0.5rem', fontStyle: 'italic' }}
                align="center"
                variant="body1"
                color="textSecondary"
            >
                {description}
            </Typography>
        </div>
    ) : null;
};

class WRRActionsStep extends WizardStep {
    formFields = [];

    prevValues = null;

    state = {
        isNewEquipment: false,
        newEquipment: {},
    };

    handleSubmit = () => {
        this.canContinue() && this.createOrUpdate(WRR_STATUS.PENDING, true);
    };

    handleSave = () => {
        this.canContinue() && this.createOrUpdate(WRR_STATUS.CREATED);
    };

    /**
     * Can continue to the next step on the wizard
     */
    canContinue = () => {
        const isValid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
        if (!isValid) {
            showError('One or more fields are not valid.');
        }

        return isValid;
    };

    /**
     * SaveChanges is used to save the localized state to the redux-store. It can only run synchronous methods.
     */
    saveChanges = () => true;

    async createOrUpdate(status, after) {
        const { wrrGetters, history, setLoading, reload, getTranslation } = this.props;
        const { isNewEquipment, newEquipment } = this.state;
        const { jobNumber, equipment, forecastedReleaseVolume, forecastedReleaseDate, location, requester } =
            wrrGetters.getProperties();
        setLoading(true);
        try {
            let eqCode = equipment?.code;
            if (isNewEquipment) {
                eqCode = await this.createNewEquipment({ ...newEquipment, hierarchyLocationCode: location?.code });
                this.setState({ isNewEquipment: false, newEquipment: {} });
            }
            const updateObj = {
                jobNumber,
                status,
                location,
                forecastedReleaseDate,
                forecastedReleaseVolume,
                requester,
                equipment: {
                    code: eqCode,
                },
            };
            let waterReleaseNumber = jobNumber;
            if (!jobNumber) {
                waterReleaseNumber = await WSWaterRelease.createWRRequest(updateObj);
                history.push(generateRoute(ROUTES.waterRelease, { waterReleaseNumber }));
            } else {
                await WSWaterRelease.updateWRRequest(updateObj);
                await reload();
            }
            if (after) {
                // console.log(getTranslation('WRR_SUBMITTED') + waterReleaseNumber);
                history.push(generateRoute(ROUTES.menu, { waterReleaseNumber }));
                showSuccess(getTranslation('WRR_SUBMITTED') + waterReleaseNumber);
            }
        } catch (err) {
            console.error(err);
            setLoading(false);
            return;
        }
        setLoading(false);
    }

    /**
     *  CommitChanges is used to trigger any side effects of the step, such as invocations to the back-end to
     * retrieve information about resources. This method can run asynchronous methods/requests but SHALL execute
     * the callback method afterwards to inform the wizard.
     */
    commitChanges = async (callback) => {
        callback();
    };

    /**
     * On true, the wizard automatically jumps this step immediately after it is mounted
     */
    canJumpStep = () => false;

    renderRequestInputs = (writeAccess) => {
        const { storeActions, wrrGetters, screenData, getTranslation } = this.props;
        const properties = wrrGetters.getProperties();

        return generateWRRFields({
            fieldRefs: this.formFields,
            fields: getWRRActionFields(screenData),
            getTranslation,
            storeActions,
            wrrGetters,
            writeAccess,
            properties,
        });
    };

    renderNewEquipmentInputs = (writeAccess) => {
        const { getTranslation, screenData } = this.props;
        const { assetFields } = screenData;

        const equipmentFields = [
            {
                code: 'code',
                elementInfo: assetFields.equipmentno,
                getAttribute: () => (writeAccess ? ATTRIBUTES.REQUIRED : ATTRIBUTES.READONLY),
                type: TABLE_DATA_TYPES.INPUT,
                translationKey: TRANSLATION_KEYS.WATER_ORIGIN_CODE,
            },
            {
                code: 'description',
                elementInfo: assetFields.equipmentdesc,
                getAttribute: () => (writeAccess ? ATTRIBUTES.REQUIRED : ATTRIBUTES.READONLY),
                type: TABLE_DATA_TYPES.INPUT,
                translationKey: TRANSLATION_KEYS.WATER_ORIGIN_DESC,
            },
        ];

        return equipmentFields.map((field) =>
            InputGenerator.generate({
                field: {
                    ...field,
                    elementInfo: {
                        xpath: field.code,
                        ...field.elementInfo,
                        text: getTranslation(field.translationKey) ?? field.elementInfo.text,
                    },
                    getAttribute: (object) => object.getAttribute && object.getAttribute(),
                    code: field.code,
                },
                formFields: this.formFields,
                object: this.state.newEquipment,
                updateObject: (key, value) =>
                    this.setState({
                        newEquipment: {
                            ...this.state.newEquipment,
                            [key]: value,
                        },
                    }),
                hideLabel: false,
            })
        );
    };

    createNewEquipment = async (newEquipment) => await WSWaterRelease.createWaterCircuit(newEquipment);

    render() {
        const { getTranslation, wrrGetters, storeActions, writeAccess, userData, submitted } = this.props;
        const properties = wrrGetters.getProperties();

        return (
            <div style={{ margin: 8 }}>
                <Grid container spacing={8} style={{ overflowX: 'hidden' }}>
                    <Grid item md={6} sm={12} xs={12}>
                        <EISPanel
                            detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                            heading={getTranslation(TRANSLATION_KEYS.ACTIONS)}
                            alwaysExpanded
                        >
                            {!submitted && <WarningText text={getTranslation(TRANSLATION_KEYS.WRR_ACTIONS_NOTICE)} />}
                            <AMMSingleAutocomplete
                                defaultOptions
                                loadOptions={({ hint }, conf) =>
                                    hint
                                        ? WSEquipment.getAutocompleteWaterOrigins({ hint }, conf)
                                        : WSEquipment.getAutocompleteWaterCircuits({ hint }, conf)
                                }
                                getOptionValue={(option) => option.eqCode}
                                getOptionLabel={(option) => option.eqCode}
                                getNewOptionData={(value) => ({
                                    eqCode: value,
                                    description: '+ Create new',
                                    newOption: true,
                                })}
                                components={{
                                    Option: EquipmentOption,
                                    SingleValue: SingleValueContent,
                                }}
                                value={properties[WATER_RELEASE_KEYS.EQUIPMENT]}
                                onChange={(value) => {
                                    this.setState({
                                        isNewEquipment: Boolean(value?.newOption),
                                        newEquipment: {
                                            description: value?.eqCode,
                                        },
                                    });
                                    storeActions.updateWRRRequest({
                                        [WATER_RELEASE_KEYS.EQUIPMENT]: {
                                            code: value?.eqCode,
                                            description: value?.description,
                                        },
                                        ...(!properties[WATER_RELEASE_KEYS.LOCATION]?.code
                                            ? {
                                                  [WATER_RELEASE_KEYS.LOCATION]: {
                                                      code: value?.locationCode,
                                                      desc: '',
                                                  },
                                              }
                                            : {}),
                                    });
                                }}
                                elementInfo={{
                                    attribute: 'R',
                                    readonly: !!properties[WATER_RELEASE_KEYS.STATUS] || !writeAccess,
                                    text: getTranslation(TRANSLATION_KEYS.WATER_ORIGIN_CODE),
                                }}
                                creatable
                                autoSelectSingle={false}
                            />
                            {this.renderRequestInputs(writeAccess)}
                        </EISPanel>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        {false && this.state.isNewEquipment && (
                            <EISPanel
                                detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                                heading={getTranslation(TRANSLATION_KEYS.NEW_EQUIPMENT_DETAILS)}
                                alwaysExpanded
                            >
                                {this.renderNewEquipmentInputs(writeAccess)}
                            </EISPanel>
                        )}
                        {!submitted && (
                            <EISPanel
                                detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                                // heading={getTranslation('INFO')}
                                alwaysExpanded
                            >
                                <WarningText text={getTranslation(TRANSLATION_KEYS.WRR_ORIGIN_NOTICE)} />
                            </EISPanel>
                        )}
                        {properties[WATER_RELEASE_KEYS.WR_NUMBER] && (
                            <CommentsContainer
                                title={getTranslation('COMMENTS')}
                                entityCode="EVNT"
                                entityKeyCode={properties[WATER_RELEASE_KEYS.WR_NUMBER]}
                                userDesc={userData.eamAccount.userDesc}
                                readComments={WSComments.readComments}
                                createComment={WSComments.createComment}
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default WRRActionsStep;
