import { Typography } from '@material-ui/core';
import { reactSelectComponents } from 'amm-tools';

export const SupplierOptionInner = ({ data }) => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '4px',
            // border: '3px double #32a1ce',
            borderRadius: '4px',
        }}
    >
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'space-between',
                width: '100%',
                height: '100%',
            }}
        >
            <Typography variant="subtitle1" style={{ flex: 1 }}>
                {data.code}
            </Typography>
            <Typography variant="subtitle2">{data.desc}</Typography>
        </div>
        <Typography variant="body2" color="textSecondary">
            {data.addressCode}
        </Typography>
        {data.contactName && (
            <Typography variant="body2" color="textSecondary">
                <b>Attention Of: </b>
                {data.contactName}
            </Typography>
        )}
    </div>
);

const SupplierOption = (props) => (
    <reactSelectComponents.Option {...props}>
        <SupplierOptionInner {...props} />
    </reactSelectComponents.Option>
);

export default SupplierOption;
