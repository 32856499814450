import React from 'react';
import Paper from '@material-ui/core/Paper';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import { Icon } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import EAMPlusMinusInput from 'eam-components/dist/ui/components/inputs/EAMPlusMinusInput';
import BZInventoryStats from './BZInventoryStats';
import WSAutocomplete from '../../../../tools/rest/WSAutocomplete';
import BZInventoryFilters from './BZInventoryFilters';
import TRECButton from '../../../components/buttons/TRECButton';
import BZInventoryTable from './BZInventoryTable';
import WS from '../../../../tools/rest/WSBZInventory';
import { handleError, showWarning } from 'tools/TrecNotifications';
import {
    EQP_FOUND,
    EQP_PRESENT,
    EQP_TO_CHECK,
    filterFutureAction,
    filterMeasurementStatus,
    filterRadiationClassification,
    filterResult,
    FILTER_FUTURE_ACTION,
    FILTER_OBJRESP,
    filterObjResp,
    FILTER_RADIATION_CLASSIFICATION,
    FILTER_RESULT,
    FILTER_STATUS,
} from './BZInventoryUtils';

class BZInventoryScanning extends React.Component {
    state = {
        equipments: null,
        equipmentsFound: [],
        filters: {},
    };

    inputsContainerStyle = {
        width: '100%',
    };

    buttonsContainerStyle = {
        display: 'flex',
        float: 'right',
        padding: '5px',
        flexDirection: 'row',
    };

    paperStyle = {
        margin: 5,
        marginBottom: 15,
        overflow: 'auto',
    };

    componentDidMount() {
        const { inventoryData, bufferZone, setData } = this.props;
        // if there is already data, don't fetch it again
        // when coming back from actions to scan more codes
        if (inventoryData) {
            return;
        }

        WS.getInventoryData(bufferZone)
            .then((response) => {
                const { data } = response.body;

                data.forEach((element) => {
                    element.resultCode = EQP_TO_CHECK;
                });

                setData(data);
            })
            .catch(handleError);
    }

    updateProperty = (key, value) => {
        const valueUpdate = key === 'equipments' ? value.map((elem) => elem.code).toString() : value;

        this.setState((prevState) => ({
            ...prevState,
            [key]: valueUpdate,
        }));
    };

    handlePlusClick = () => {
        const { equipments: stateEquipments } = this.state;
        const { inventoryData, translations, setData } = this.props;
        if (!stateEquipments) {
            return;
        }

        const equipments = stateEquipments.split(',');
        const data = inventoryData;

        equipments.forEach((equipment) => {
            const elementToMark = data.filter((element) => element.equipment === equipment);

            // equipment was already scanned before
            if (
                elementToMark.length === 1 &&
                (elementToMark[0].resultCode === EQP_FOUND || elementToMark[0].resultCode === EQP_PRESENT)
            ) {
                showWarning(`${translations.ALREADY_SCANNED}: ${equipment}`);
            }

            // equipment is present
            if (elementToMark.length === 1 && elementToMark[0].resultCode === EQP_TO_CHECK) {
                elementToMark[0].resultCode = EQP_PRESENT;
            }

            // equipment is found
            if (elementToMark.length === 0) {
                WS.getSingleInventoryItem(equipment).then((response) => {
                    if (response.body.data) {
                        const newEquipment = response.body.data;
                        newEquipment.resultCode = EQP_FOUND;

                        setData((prevState) => ({
                            data: [...prevState.data, newEquipment],
                        }));
                    } else {
                        showWarning(`${translations.EQ_INVALID}: ${equipment}`);
                    }
                });
            }
        });

        this.setState(() => ({
            equipments: null,
        }));

        setData(data);
    };

    handleMinusClick = () => {
        const { equipments: stateEquipments } = this.state;
        const { inventoryData, translations, setData } = this.props;
        if (!stateEquipments) {
            return;
        }

        const equipments = stateEquipments.split(',');
        let data = inventoryData;
        const equipmentsToRemove = [];

        equipments.forEach((equipment) => {
            const elementToMark = data.filter((element) => element.equipment === equipment);

            if (elementToMark.length === 1) {
                if (elementToMark[0].resultCode === EQP_PRESENT) {
                    data.filter((element) => element.equipment === equipment).forEach((element) => {
                        element.resultCode = EQP_TO_CHECK;
                    });
                }
                if (elementToMark[0].resultCode === EQP_FOUND) {
                    equipmentsToRemove.push(equipment);
                }

                if (elementToMark[0].resultCode === EQP_TO_CHECK) {
                    showWarning(`${translations.NOT_SCANNED}: ${equipment}`);
                }
            }

            if (elementToMark.length === 0) {
                showWarning(`${translations.NOT_SCANNED}: ${equipment}`);
            }
        });

        data = data.filter((element) => !equipmentsToRemove.includes(element.equipment));

        this.setState(() => ({
            equipments: null,
        }));

        setData(data);
    };

    filterData = (data) => {
        const { futureActions } = this.props;
        if (!data) {
            return data;
        }

        const { filters } = this.state;

        const dataFiltered = data.filter(
            (element) =>
                filterMeasurementStatus(element.measurementStatusCode, filters[FILTER_STATUS]) &&
                filterResult(element.resultCode, filters[FILTER_RESULT]) &&
                filterFutureAction(element.futureUsageCode, filters[FILTER_FUTURE_ACTION], futureActions) &&
                filterRadiationClassification(element.classificationCode, filters[FILTER_RADIATION_CLASSIFICATION]) &&
                filterObjResp(element.responsible, filters[FILTER_OBJRESP])
        );

        return dataFiltered;
    };

    handleActionsClick = () => {
        const { goToActions, inventoryData } = this.props;
        // push the data to the parent component
        // and from there to actions component
        goToActions(inventoryData);
    };

    render() {
        const { translations, inventoryData, openConfirmDialog, woFields, history } = this.props;
        const { equipments, filters } = this.state;
        const data = this.filterData(inventoryData);

        return (
            <>
                <Paper style={{ ...this.paperStyle, overflow: 'visible' }}>
                    <div style={this.buttonsContainerStyle}>
                        <TRECButton disabled={!data} onClick={this.handleActionsClick}>
                            <Icon>navigation</Icon>
                            {translations.ACTIONS}
                        </TRECButton>
                        <TRECButton
                            color="default"
                            onClick={() => {
                                openConfirmDialog(
                                    {
                                        title: translations.CONFIRM_HEADER,
                                        message: translations.CANCELLATION,
                                        cancelButtonLabel: translations.BUTTON_CANCEL,
                                        confirmButtonLabel: translations.YES,
                                        waitForCompletion: false,
                                    },
                                    () => {
                                        history.push('menu');
                                    }
                                );
                            }}
                        >
                            <MenuIcon />
                            {translations.GOTOMENU}
                        </TRECButton>
                    </div>
                    <BZInventoryStats
                        data={inventoryData}
                        getVisibleEquipment={() => (data ? data.length : 0)}
                        {...this.props}
                    />
                    <div style={this.inputsContainerStyle}>
                        <EAMPlusMinusInput
                            handlePlusClick={this.handlePlusClick}
                            handleMinusClick={this.handleMinusClick}
                        >
                            <EAMAutocomplete
                                elementInfo={{ ...woFields.equipment }}
                                value={equipments}
                                valueKey="equipments"
                                updateProperty={this.updateProperty}
                                loadOptions={WSAutocomplete.autocompleteEquipment}
                                multi
                                creatable
                                formFields={this.formFields}
                                labelStyle={{ minWidth: 150 }}
                                columnsWidth={['0', '100%']}
                                autoSelectSingleElement
                            />
                        </EAMPlusMinusInput>
                        <BZInventoryFilters
                            {...this.props}
                            updateProperty={(key, value) =>
                                this.setState((prevState) => ({
                                    ...prevState,
                                    filters: {
                                        ...prevState.filters,
                                        [key]: value,
                                    },
                                }))
                            }
                            filters={filters}
                        />
                    </div>
                </Paper>
                <Paper style={this.paperStyle}>
                    <BZInventoryTable data={data} {...this.props} />
                </Paper>
            </>
        );
    }
}

export default BZInventoryScanning;
