import React from 'react';
import EISTable from 'react-eis-components/dist/ui/components/table';
// import Typography from '@material-ui/core/Typography';
import Minus from 'mdi-material-ui/Minus';
import { Button } from '@material-ui/core';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import OpenInNew from '@material-ui/icons/OpenInNew';
import EquipmentHazardContainer from '../../components/eqphazard/EquipmentHazardContainer';

/**
 * Content section in Container page
 */
const ContainerContent = (props) => {
    const { onRemove, translations, containerContent, showRemove } = props;
    const tableContent = containerContent.map((record) => {
        const risks = !record.hazardsList
            ? {
                  contamination: record.contamination,
                  risks: record.risks,
              }
            : {
                  hazards: record.hazardsList,
              };

        return {
            ...record,
            equipmentLink: (
                <a
                    target="_blank"
                    href={`${process.env.REACT_APP_FRONTEND}equipment/${record.equipmentId}`}
                    rel="noreferrer"
                    aria-label="equipmentId"
                >
                    <OpenInNew />
                </a>
            ),
            rpmeasurement: (
                <a
                    target="_blank"
                    href={`${process.env.REACT_APP_FRONTEND}rpmperform?wo=${record.lastRPMeasurement}`}
                    rel="noreferrer"
                    aria-label="lastRPMeasurement"
                >
                    <OpenInNew />
                </a>
            ),
            equipmentId: record.equipmentId,
            risks: <EquipmentHazardContainer classification={record.classification} {...risks} />,
            remove: (
                <div onClick={() => onRemove(record.equipmentId)} onKeyDown={() => onRemove(record.equipmentId)}>
                    <Button>
                        <Minus />
                    </Button>
                </div>
            ),
        };
    });
    const propCodes = [
        'rpmeasurement',
        'equipmentLink',
        'equipmentId',
        'description',
        'risks',
        'dimensions',
        'grossWeight',
        'doseRate',
    ];
    const headers = [
        translations.RPMEASUREMENT,
        'Equipment Link',
        translations.EQUIPMENTID,
        translations.DESCRIPTION,
        translations.RISKS,
        translations.DIMENSIONS,
        translations.WEIGHT,
        translations.DOSE_RATE,
    ];

    if (showRemove) {
        propCodes.push('remove');
        headers.push(translations.REMOVE);
    }

    return (
        <EISPanel heading={translations.CONTENT}>
            <div style={{ width: '100%', height: '100%' }}>
                <EISTable data={tableContent} headers={headers} propCodes={propCodes} />
            </div>
        </EISPanel>
    );
};

export default ContainerContent;
