import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Clear from '@material-ui/icons/Clear';
import FileCopy from '@material-ui/icons/FileCopy';
import Menu from '@material-ui/icons/Menu';
import Save from '@material-ui/icons/Save';
import { Wizard } from 'amm-tools';
import React from 'react';
import BlockUi from 'react-block-ui';
import DefaultStepper from 'ui/components/wizard/DefaultStepper';
import {
    EQUIPMENT_KEYS,
    REQUEST_ACTIONS,
    REQUEST_MODES,
    RPAR_REQUEST_KEYS,
    RP_MEASUREMENT_DTO_KEYLIST,
    RP_MEASUREMENT_KEYS,
    TREC_GENERAL_KEYS,
} from '../../../enums/Constants';
import Tools from '../../../tools/Tools';
import WSRPARequest from '../../../tools/rest/WSRPARequest';
import TRECButton from '../../components/buttons/TRECButton';
import TRECPage from '../TRECPage';
import ReplaceEquipmentDialog from './dialogs/ReplaceEquipmentDialog';
import RPARABCount from './steps/RPARABCount';
import RPARAlphaSpec from './steps/RPARAlphaSpec';
import RPAREquipment from './steps/RPAREquipment';
import RPARGammaSpec from './steps/RPARGammaSpec';
import RPARLiquidScintillation from './steps/RPARLiquidScintillation';
import RPARLogistics from './steps/RPARLogistics';
import RPARPhysicoChemical from './steps/RPARPhysicoChemical';
import RPARPurpose from './steps/RPARPurpose';
import RPARRados from './steps/RPARRados';
import RPARSummary from './steps/RPARSummary';
import RPARTaskSelector from './steps/RPARTaskSelector';
import RPARUpdateEquipments from './steps/RPARUpdateEquipments';
import { handleError, showSuccess } from 'tools/TrecNotifications';

class RPARequest extends TRECPage {
    state = {
        values: {},
        valuesGrid: {},
        loading: true,
        deleteDialog: false,
        isReplacingEquipment: false,
    };

    shouldComponentUpdate(nextProps, nextState) {
        const curr = JSON.stringify(this.props.rpar);
        const next = JSON.stringify(nextProps.rpar);
        return (
            curr !== next ||
            this.props.lang !== nextProps.lang ||
            this.state.values !== nextState.values ||
            this.state.loading !== nextState.loading ||
            this.state.deleteDialog !== nextState.deleteDialog ||
            this.state.isReplacingEquipment !== nextState.isReplacingEquipment
        );
    }

    componentDidMount() {
        const { setRPARAnalysisTypes, rpamAnalysisTypes, setPage, masterPage, page, mode } = this.props;
        setPage(masterPage, page);
        // TODO reset should happen, but this is messing with the RPARequestLoader
        Promise.all([WSRPARequest.getValuesGrid(), WSRPARequest.getValuesDef()]).then((respArray) => {
            const valuesDefMap = respArray[1].body.data.reduce(
                (acc, value) => ({
                    ...acc,
                    [value.field]: [
                        ...(acc[value.field] || []),
                        value.description ? { code: value.value, desc: value.description } : value.value,
                    ],
                }),
                {}
            );
            this.setState({ values: valuesDefMap, valuesGrid: respArray[0].body.data, loading: false });
        });
        if (mode === REQUEST_MODES.CLONING) this.setState({ isReplacingEquipment: true });
        setRPARAnalysisTypes(rpamAnalysisTypes);
    }

    // RIC: TODO keyList should be replaced by filter entity.

    getSteps = () => {
        const { getTranslation, applicationData } = this.props;

        const rpamValues = Object.entries(this.state.values).reduce(
            (acc, [code, list]) =>
                Object.assign(acc, {
                    [code]: list.map((codeOrObj) =>
                        typeof codeOrObj === 'object'
                            ? codeOrObj
                            : { code: codeOrObj, desc: this.props.getTranslation(codeOrObj) }
                    ),
                }),
            {}
        );

        rpamValues.rpo = applicationData.rpos;

        const stepProps = {
            // rpar: this.props.rpar,
            rparGetters: this.props.rparGetters,
            rparActions: {
                updateRPARequest: this.props.updateRPARequest,
                setRPAREquipmentList: this.props.setRPAREquipmentList,
                resetRPAR: this.props.resetRPAR,
                updateRPAREquipments: this.props.updateRPAREquipments,
                // setRPARAnalysisTypes: this.props.setRPARAnalysisTypes,
                setRPAREquipmentsForAnalysisType: this.props.setRPAREquipmentsForAnalysisType,
                setAnalysisProperties: this.props.setAnalysisProperties,
            },
            screenData: this.props.screenData,
            constants: this.props.constants,
            getTranslation: this.props.getTranslation,
            applicationData: this.props.applicationData,
            translations: this.props.translations,
            rpamAnalysisTypes: this.props.rpamAnalysisTypes,
            valuesGrid: this.state.valuesGrid,
            rpamValues,
            applyDefaults: Tools.getApplyDefaults(
                this.state.valuesGrid.DEF,
                Object.values({ ...EQUIPMENT_KEYS, ...RP_MEASUREMENT_KEYS })
            ),
            getApplyDefaults: this.getApplyDefaults,
            translatedScreenData: this.props.translatedScreenData,
            dropdowns: this.props.dropdowns,
            lang: this.props.lang,
        };
        return {
            PURPOSE: {
                key: 'PURPOSE',
                label: getTranslation('PURPOSE'),
                getContent: (props) => <RPARPurpose {...stepProps} {...props} />,
                extraProps: {},
            },
            SELECT_EQUIPMENT: {
                label: getTranslation('ASSET_SELECTION'),
                getContent: (props) => <RPAREquipment {...stepProps} {...props} />,
                extraProps: {},
            },
            UPDATE_EQUIPMENT: {
                label: getTranslation('UPDATE_EQUIPMENT'),
                getContent: (props) => <RPARUpdateEquipments {...stepProps} {...props} />,
            },
            TASK_SELECTOR: {
                label: getTranslation('TASK_SELECTOR'),
                getContent: (props) => <RPARTaskSelector {...stepProps} {...props} />,
                extraProps: {},
            },
            LOGISTICS: {
                label: getTranslation('LOGISTICS'),
                getContent: (props) => <RPARLogistics {...stepProps} {...props} />,
                extraProps: {},
            },
            ABCOUNT: {
                label: getTranslation('ABCOUNT'),
                getContent: (props) => <RPARABCount key="abcount" {...stepProps} {...props} />,
                extraProps: {},
            },
            LIQUIDSCINT: {
                label: getTranslation('LIQUIDSCINT'),
                getContent: (props) => <RPARLiquidScintillation key="liquidscint" {...stepProps} {...props} />,
                extraProps: {},
            },
            RADOS: {
                label: getTranslation('RADOS'),
                getContent: (props) => <RPARRados key="rados" {...stepProps} {...props} />,
                extraProps: {},
            },
            ALPHASPEC: {
                label: getTranslation('RPAM_AS'),
                getContent: (props) => <RPARAlphaSpec key="alphaspec" {...stepProps} {...props} />,
                extraProps: {},
            },
            GAMMASPEC: {
                label: getTranslation('GAMMASPEC'),
                getContent: (props) => <RPARGammaSpec key="gammaspec" {...stepProps} {...props} />,
                extraProps: {},
            },
            PHYSCHEM: {
                label: getTranslation('PHYSCHEM'),
                getContent: (props) => <RPARPhysicoChemical key="PHYSCHEM" {...stepProps} {...props} />,
                extraProps: {},
            },
            SUMMARY: {
                label: getTranslation('SUMMARY'),
                getContent: (props) => <RPARSummary key="summary" {...stepProps} {...props} />,
                extraProps: {},
            },
        };
    };

    getStepSequence = () => {
        const { rparStepSequence, rparGetters } = this.props;
        const analysisTypesWithEquipment = rparGetters.getAnalysisTypesWithEquipments();
        return rparStepSequence.reduce(
            (acc, step) =>
                step === 'ANALYSIS_TYPES'
                    ? [...acc, ...analysisTypesWithEquipment]
                    : // : step === 'LOGISTICS' && !rparGetters.edhNecessary() ? acc
                      [...acc, step],
            []
        );
    };

    getActions = (mode) => {
        const actionsMap = {
            [REQUEST_MODES.CREATING]: [REQUEST_ACTIONS.CREATE],
            [REQUEST_MODES.CLONING]: [REQUEST_ACTIONS.CREATE],
            [REQUEST_MODES.EDITING]: [REQUEST_ACTIONS.EDIT, REQUEST_ACTIONS.CLONE, REQUEST_ACTIONS.DELETE],
            [REQUEST_MODES.READING]: [REQUEST_ACTIONS.CLONE],
        };
        return actionsMap[mode];
    };

    handleCreateAction = () => {
        const { rparGetters, history, getTranslation } = this.props;
        this.setState({ loading: true });
        const equipment = rparGetters.getEquipments();

        this.updateEquipment(equipment)
            .then((_) => this.createRPMeasurements(equipment))
            .then((eq) => this.createRPARequest(eq))
            .then((rparNumber) => {
                showSuccess(`${getTranslation(RPAR_REQUEST_KEYS.ANALYSIS_SUCCESS_MSG)}: ${rparNumber}`);
                history.push(`/rparequest/edit/${rparNumber}`);
            })
            .catch(handleError)
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    updateEquipment = (equipmentMap) => {
        const { applicationData } = this.props;

        this.setState({ loading: true });
        return Promise.all(
            Object.keys(equipmentMap).map((eqCode) =>
                WSRPARequest.updateEquipment({
                    code: eqCode,
                    equipment: equipmentMap[eqCode],
                    applicationData,
                })
            )
        );
    };

    updateStateEquipment = (equipment) => {
        this.props.updateRPAREquipments(equipment);
    };

    createRPMeasurements = (equipment) => {
        const { updateRPAREquipments } = this.props;
        const rpmeasure = Tools.applyToFields(
            Tools.filterObjectFields(
                equipment,
                (key, value) =>
                    !value[RP_MEASUREMENT_KEYS.RPA_MEASUREMENT] &&
                    (value[RP_MEASUREMENT_KEYS.MEASURE_CONTACT] || value[EQUIPMENT_KEYS.EDH_DOCUMENT] !== 'None')
            ),
            (eq) => Tools.filterObjectFieldsFromList(eq, RP_MEASUREMENT_DTO_KEYLIST)
        );

        if (Tools.isEmpty(rpmeasure)) {
            return Promise.resolve(equipment);
        }
        return WSRPARequest.createRPAMeasurement({ equipment, rpmeasure })
            .then((resp) => {
                const { data } = resp.body;

                const equipment2 = Tools.applyToFields(equipment, (eq) => ({
                    ...eq,
                    ...data[eq[EQUIPMENT_KEYS.EQUIPMENT_CODE]],
                }));

                updateRPAREquipments(equipment);
                return Promise.resolve(equipment2);
            })
            .catch((error) => {
                handleError(error);
                return Promise.reject(error);
            });
    };

    createRPARequest = (equipment) => {
        const { rparGetters } = this.props;
        return WSRPARequest.createRPAnalysisRequest({
            woProperties: rparGetters.getAnalysisRequestProperties(),
            equipment,
            analysisProperties: rparGetters.getAnalysisTypesWithEquipments().reduce(
                (acc, at) => ({
                    ...acc,
                    [at]: rparGetters.getAnalysisTypeEquipments(at),
                }),
                {}
            ),
        })
            .then((resp) => {
                const rparNumber = resp.body.data;
                return Promise.resolve(rparNumber);
            })
            .catch((error) => {
                handleError(error);
                return Promise.reject(error);
            });
    };

    handleEditAction = (callback) => {
        const { rparGetters, getTranslation } = this.props;

        const equipment = rparGetters.getEquipments();
        this.setState({ loading: true });
        // TODO - refactor. This is preventing sending an empty object to BE in case someone leaves scheduled dates empty and tries to save the request again.
        const startDate = Tools.isEmptyObject(
            rparGetters.getAnalysisRequestProperties()[RPAR_REQUEST_KEYS.SCHEDULED_START_DATE]
        )
            ? ''
            : rparGetters.getAnalysisRequestProperties()[RPAR_REQUEST_KEYS.SCHEDULED_START_DATE];

        const endDate = Tools.isEmptyObject(
            rparGetters.getAnalysisRequestProperties()[RPAR_REQUEST_KEYS.SCHEDULED_END_DATE]
        )
            ? ''
            : rparGetters.getAnalysisRequestProperties()[RPAR_REQUEST_KEYS.SCHEDULED_END_DATE];

        Tools.isEmpty(rparGetters.getAnalysisRequestProperties().scheduledEnd);

        this.createRPMeasurements(equipment)
            .then((equipment2) => {
                this.updateEquipment(equipment2);
                this.updateStateEquipment(equipment2);
                return Promise.resolve(equipment2);
            })
            .then((equipment2) =>
                WSRPARequest.updateRPAnalysisRequest({
                    woProperties: {
                        ...rparGetters.getAnalysisRequestProperties(),
                        [RPAR_REQUEST_KEYS.SCHEDULED_START_DATE]: startDate,
                        [RPAR_REQUEST_KEYS.SCHEDULED_END_DATE]: endDate,
                    },
                    equipment: equipment2,
                    analysisProperties: rparGetters.getAnalysisTypesWithEquipments().reduce(
                        (acc, at) => ({
                            ...acc,
                            [at]: rparGetters.getAnalysisTypeEquipments(at),
                        }),
                        {}
                    ),
                })
            )
            .then((resp) => {
                showSuccess(`${getTranslation(RPAR_REQUEST_KEYS.ANALYSIS_EDIT_SUCCESS_MSG)}: ${resp.body.data}`);
                callback();
            })
            .finally((_) => this.setState({ loading: false }))
            .catch(handleError);
    };

    handleCloneAction = () => {
        const { history, match } = this.props;
        return history.push(`/rparequest/clone/${match.params.requestwonumber}`);
    };

    handleCancelAction = () => {
        const { history, match, getTranslation } = this.props;
        const id = match.params.requestwonumber;
        WSRPARequest.deleteRPAnalysisRequest({ id })
            .then(() => {
                showSuccess(`${getTranslation(RPAR_REQUEST_KEYS.CANCEL_REQUEST_SUCCESS_MSG)}: ${id}`);
                history.push('/menu');
            })
            .catch(handleError)
            .finally(() => {
                this.handleCancelRequestDialogClose();
            });
    };

    handleCancel = () => {
        this.props.resetRPAR();
        this.props.history.push('/menu');
    };

    handleCancelRequestDialogClose = () => {
        this.setState({ deleteDialog: false });
    };

    openCancelRequestDialog = () => {
        this.setState({ deleteDialog: true });
    };

    handleReplaceEquipmentCancel = () => this.setState({ isReplacingEquipment: false });

    handleReplaceEquipmentConfirm = (replaceMap) => {
        const { cloneRequestNewEquipment } = this.props;
        cloneRequestNewEquipment(replaceMap);
        this.setState({ isReplacingEquipment: false });
    };

    renderControls = ({ handlePrevious, handleNext, isFirstStep, isLastStep, isFinished, writeAccess }) => {
        const { mode, getTranslation, match } = this.props;
        const { deleteDialog } = this.state;
        return (
            <>
                <TRECButton onClick={this.handleCancel} color="default">
                    <Menu />
                    MENU
                </TRECButton>

                <TRECButton disabled={isFirstStep} onClick={handlePrevious}>
                    <ArrowBack />
                    {getTranslation(TREC_GENERAL_KEYS.BACK)}
                </TRECButton>

                {!isLastStep ? (
                    <TRECButton onClick={handleNext}>
                        <ArrowForward />
                        {getTranslation(TREC_GENERAL_KEYS.NEXT)}
                    </TRECButton>
                ) : (
                    <>
                        {this.getActions(mode).map((action) => {
                            switch (action) {
                                case REQUEST_ACTIONS.CREATE:
                                    return !writeAccess ? null : (
                                        <TRECButton
                                            key={`WizardControl${action}`}
                                            disabled={isFinished}
                                            onClick={this.handleCreateAction}
                                        >
                                            <Save />
                                            {getTranslation(TREC_GENERAL_KEYS.SUBMIT)}
                                        </TRECButton>
                                    );
                                case REQUEST_ACTIONS.CLONE:
                                    return !writeAccess ? null : (
                                        <TRECButton
                                            key={`WizardControl${action}`}
                                            disabled={isFinished}
                                            onClick={this.handleCloneAction}
                                        >
                                            <FileCopy />
                                            {getTranslation(TREC_GENERAL_KEYS.CLONE)}
                                        </TRECButton>
                                    );
                                case REQUEST_ACTIONS.EDIT:
                                    return !writeAccess ? null : (
                                        <TRECButton
                                            key={`WizardControl${action}`}
                                            disabled={isFinished}
                                            onClick={this.handleEditAction}
                                        >
                                            <Save />
                                            {getTranslation(TREC_GENERAL_KEYS.SAVE)}
                                        </TRECButton>
                                    );
                                case REQUEST_ACTIONS.DELETE:
                                    return !writeAccess ? null : (
                                        <React.Fragment key={`WizardControl${action}`}>
                                            <TRECButton
                                                style={{
                                                    // color: 'rgba(230, 63, 43, 0.8)',
                                                    backgroundColor: 'rgba(230, 63, 43, 0.8)',
                                                }}
                                                color="secondary"
                                                onClick={this.openCancelRequestDialog}
                                            >
                                                <Clear />
                                                {getTranslation(RPAR_REQUEST_KEYS.CANCEL_REQUEST)}
                                            </TRECButton>
                                            <Dialog open={deleteDialog} onClose={this.handleCancelRequestDialogClose}>
                                                <DialogContent>
                                                    <DialogContentText>
                                                        {`${getTranslation(
                                                            RPAR_REQUEST_KEYS.CANCEL_REQUEST_CONFIRMATION
                                                        )} `}
                                                        <b>{match.params.requestwonumber}</b>?
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button
                                                        onClick={this.handleCancelRequestDialogClose}
                                                        color="primary"
                                                    >
                                                        {getTranslation(TREC_GENERAL_KEYS.CANCEL)}
                                                    </Button>
                                                    <Button onClick={this.handleCancelAction} color="primary" autoFocus>
                                                        {getTranslation(TREC_GENERAL_KEYS.CONFIRM)}
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </React.Fragment>
                                    );
                                default:
                                    return null;
                            }
                        })}
                    </>
                )}
            </>
        );
    };

    renderPage(writeAccess) {
        const { firstStepIndex, mode, getTranslation, rparGetters } = this.props;
        const { loading, isReplacingEquipment } = this.state;

        return (
            <BlockUi blocking={loading} style={{ height: '100%', width: '100%' }}>
                <Wizard
                    {...this.props}
                    title="RP Analysis Request"
                    steps={this.getSteps()}
                    stepSequence={this.getStepSequence()}
                    firstStepIndex={firstStepIndex || 0}
                    writeAccess={writeAccess}
                    controls={this.renderControls}
                    renderStepper={DefaultStepper}
                />
                {mode === REQUEST_MODES.CLONING && (
                    <ReplaceEquipmentDialog
                        open={isReplacingEquipment}
                        onCancel={this.handleReplaceEquipmentCancel}
                        onConfirm={this.handleReplaceEquipmentConfirm}
                        getTranslation={getTranslation}
                        equipmentList={rparGetters.getEquipmentList()}
                    />
                )}
            </BlockUi>
        );
    }
}

export default RPARequest;
