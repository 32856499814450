import { Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import { RWP_KEYS } from 'ui/pages/rwprocessing/RWPConstants';
import { TABLE_DATA_TYPES } from '../../../../enums/Constants';
import { ENTITY_TRANSL_PREFIX } from '../../wrrequest/WRRConstants';
import { ATTRIBUTES } from '../../wrrequest/WRRHelpers';
import { getInputGenerator } from 'ui/pages/rpmrequest2/InputGenerator';
import { RW_ELIMINATION_KEYS, RW_ELIMINATION_STATUS, TRANSLATION_KEYS } from '../RWEliminationConstants';
import SupplierOption, { SupplierOptionInner } from 'ui/components/autocomplete/SupplierOption';
import EmployeeOption, { EmployeeOptionInner } from 'ui/components/autocomplete/EmployeeOption';
import LocationOption, { LocationOptionInner } from 'ui/components/autocomplete/LocationOption';
import { showError } from 'tools/TrecNotifications';

export const getUsageFields = ({ dropdowns, getTranslation, canEdit }) => [
    {
        code: RW_ELIMINATION_KEYS.ASSIGNED_TO,
        valueKey: 'cernId',
        descKey: 'description',
        translationKey: RW_ELIMINATION_KEYS.ASSIGNED_TO,
        type: TABLE_DATA_TYPES.AMMDROPDOWN,
        getAttribute: () => (canEdit ? ATTRIBUTES.REQUIRED : ATTRIBUTES.READONLY),
        autocompleteType: WSAutocomplete.autocompleteEmployee,
        customInputProps: {
            Option: EmployeeOption,
            ValueComponent: EmployeeOptionInner,
        },
    },
    {
        code: RW_ELIMINATION_KEYS.STATUS,
        translationKey: TRANSLATION_KEYS.STATUS,
        type: TABLE_DATA_TYPES.SELECT,
        getAttribute: (obj) => (obj[RW_ELIMINATION_KEYS.STATUS] ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN),
        values: Object.values(RW_ELIMINATION_STATUS).map((code) => ({
            code,
            desc: getTranslation(ENTITY_TRANSL_PREFIX.EVT_STATUS + code),
        })),
    },
    {
        code: RW_ELIMINATION_KEYS.DESCRIPTION,
        type: TABLE_DATA_TYPES.INPUT,
        translationKey: RW_ELIMINATION_KEYS.DESCRIPTION,
        getAttribute: () => (canEdit ? ATTRIBUTES.REQUIRED : ATTRIBUTES.READONLY),
    },
    {
        code: RW_ELIMINATION_KEYS.RW_ELIMINATION_PATHWAY,
        translationKey: TRANSLATION_KEYS.RW_ELIMINATION_PATHWAY,
        type: TABLE_DATA_TYPES.SELECT,
        getAttribute: () => (canEdit ? ATTRIBUTES.REQUIRED : ATTRIBUTES.READONLY),
        values: dropdowns[RWP_KEYS.RW_ELIM_PATHWAY],
        customInputProps: {
            multi: true,
        },
    },
    {
        code: RW_ELIMINATION_KEYS.SUPPLIER,
        translationKey: RW_ELIMINATION_KEYS.SUPPLIER,
        valueKey: 'code',
        descKey: 'desc',
        type: TABLE_DATA_TYPES.AMMDROPDOWN,
        getAttribute: () => ATTRIBUTES.READONLY,
        // loadOptions: WSEquipment.getAutocompleteOptions,
        customInputProps: {
            Option: SupplierOption,
            ValueComponent: SupplierOptionInner,
        },
    },
    {
        code: RW_ELIMINATION_KEYS.LOCATION,
        valueKey: 'code',
        descKey: 'desc',
        translationKey: RW_ELIMINATION_KEYS.LOCATION,
        type: TABLE_DATA_TYPES.AMMDROPDOWN,
        autocompleteType: WSAutocomplete.autocompleteLocation,
        getAttribute: () => (canEdit ? ATTRIBUTES.REQUIRED : ATTRIBUTES.READONLY),
        customInputProps: {
            Option: LocationOption,
            ValueComponent: LocationOptionInner,
        },
    },
    {
        code: RW_ELIMINATION_KEYS.BUDGET_CODE,
        translationKey: RW_ELIMINATION_KEYS.BUDGET_CODE,
        type: TABLE_DATA_TYPES.INPUT,
        getAttribute: () => (canEdit ? ATTRIBUTES.REQUIRED : ATTRIBUTES.READONLY),
    },
    {
        code: RW_ELIMINATION_KEYS.CREATED_BY,
        type: TABLE_DATA_TYPES.AUTOCOMPLETE,
        valueKey: 'username',
        translationKey: TRANSLATION_KEYS.CREATED_BY,
        getAttribute: (obj) => (obj.username ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN),
        width: 6,
    },
    {
        code: RW_ELIMINATION_KEYS.CREATED_ON,
        type: TABLE_DATA_TYPES.DATE_TIME,
        getAttribute: (obj) => (obj[RW_ELIMINATION_KEYS.CREATED_ON] ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN),
        translationKey: TRANSLATION_KEYS.CREATED_ON,
        customInputProps: (_) => ({ timestamp: true }),
        width: 6,
    },
    {
        code: RW_ELIMINATION_KEYS.MODIFIED_BY,
        type: TABLE_DATA_TYPES.AUTOCOMPLETE,
        valueKey: 'username',
        translationKey: TRANSLATION_KEYS.UPDATED_BY,
        getAttribute: (obj) => (obj.username ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN),
        width: 6,
    },
    {
        code: RW_ELIMINATION_KEYS.MODIFIED_ON,
        type: TABLE_DATA_TYPES.DATE_TIME,
        getAttribute: (obj) => (obj[RW_ELIMINATION_KEYS.MODIFIED_ON] ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN),
        translationKey: TRANSLATION_KEYS.UPDATED_ON,
        customInputProps: (_) => ({ timestamp: true }),
        width: 6,
    },
];

const RWEliminationCreation = ({
    labelStyle,
    rwElimRequest,
    writeAccess,
    getTranslation,
    formFields,
    storeActions,
    lang,
    dropdowns,
}) => {
    const usageFields = getUsageFields({ getTranslation, dropdowns, canEdit: true });

    const inputGenerator = getInputGenerator({
        object: rwElimRequest,
        updateObject: storeActions.updateRWElimination,
        updateObjectPartial: storeActions.updateRWEliminationPartial,
        formFields,
        labelStyle,
        getTranslation,
        writeAccess:
            writeAccess &&
            [undefined, RW_ELIMINATION_STATUS.CREATED, RW_ELIMINATION_STATUS.PENDING].includes(
                rwElimRequest[RW_ELIMINATION_KEYS.STATUS]
            ),
        lang,
    });

    return (
        <div style={{ margin: 8 }}>
            <Grid container spacing={8}>
                <Grid item md={12} sm={12} xs={12}>
                    <EISPanel heading="RW Elimination Job Details" alwaysExpanded>
                        <Grid container spacing={0}>
                            {usageFields.map(inputGenerator)}
                        </Grid>
                    </EISPanel>
                </Grid>
            </Grid>
        </div>
    );
};

class RWEliminationCreationStep extends WizardStep {
    formFields = {};

    state = {};

    componentDidMount() {
        super.componentDidMount();
    }

    handleSubmit = () => {
        this.canContinue();
    };

    handleSave = () => {
        this.canContinue();
    };

    /**
     * Can continue to the next step on the wizard
     */
    canContinue = () => {
        const isValid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
        if (!isValid) {
            showError('One or more fields are not valid.');
        }

        return isValid;
    };

    /**
     * SaveChanges is used to save the localized state to the redux-store. It can only run synchronous methods.
     */
    saveChanges = () => true;

    /**
     *  CommitChanges is used to trigger any side effects of the step, such as invocations to the back-end to
     * retrieve information about resources. This method can run asynchronous methods/requests but SHALL execute
     * the callback method afterwards to inform the wizard.
     */
    commitChanges = async (callback) => {
        callback();
    };

    /**
     * On true, the wizard automatically jumps this step immediately after it is mounted
     */
    canJumpStep = () => false;

    render() {
        return <RWEliminationCreation formFields={this.formFields} {...this.props} />;
    }
}

export default RWEliminationCreationStep;
