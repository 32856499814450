import React from 'react';
import { AMMMultiAutocomplete } from 'amm-tools';
import WSEquipment from '../../../tools/rest/WSEquipment';
import EquipmentOption from './EquipmentOption';

const EquipmentAutocomplete = (props) => (
    <div style={{ width: '100%' }}>
        <AMMMultiAutocomplete
            loadOptions={WSEquipment.getAutocompleteOptions}
            getOptionValue={(option) => option.eqCode || option.code}
            getOptionLabel={(option) => option.eqCode || option.code}
            getNewOptionData={(value) => ({
                code: value,
                eqCode: value,
            })}
            components={{
                Option: ({ data, ...optionProps }) => (
                    <EquipmentOption {...optionProps} data={{ eqCode: data.code, description: data.desc, ...data }} />
                ),
            }}
            {...props}
        />
    </div>
);

export default EquipmentAutocomplete;
