export const STEPS = {
    LOCATION: 'LOCATION',
    USAGE: 'USAGE',
    DETAILS: 'DETAILS',
    DURATION: 'DURATION',
    MODELS: 'MODELS',
    ACCESSORIES: 'ACCESSORIES',
    SUMMARY: 'SUMMARY',
    REQUESTINFO: 'REQUESTINFO',
    ASSIGNMENT: 'ASSIGNMENT',
    DECLAREUSE: 'DECLAREUSESTEP',
    VALIDATION: 'VALIDATION',
    RETURN: 'RETURN',
};

export const TRANSLATION_KEYS = {
    DESC: 'DESC',
    ACCESSORIES: 'ACCESSORIES',
    VACUUMS: 'VACUUMS',
    STATUS: 'STATUS',
    JOB_NUMBER: 'JOBNUMBER',
    MISSING_VACUUM: 'MISSING_VACUUM',
    PENDING_VACUUM_SELECTION: 'PENDING_VACUUM_SELECTION',
    NEED_REPLACEMENT: 'NEED_REPLACEMENT',
    HAZARD_CODES: 'RISKS',
    HAZARD_COMMENT: 'HAZARDS_COMMENTS',
    PER_VACUUM_CLEANER: 'PER_VACUUM_CLEANER',
    RESERVATIONS: 'RESERVATIONS',
};

export const VACUUM_TYPE_KEYS = {
    CODE: 'partCode',
    ENABLED: 'enabled',
    MINIMUM_DELIVERY_TIME_HOURS: 'mininumDeliveryTime',
    CONDITION_OF_USE: 'conditionOfUse',
    CAPACITY: 'capacity',
    TYPICAL_USE: 'typicalUse',
    DESCRIPTION: 'description',
    WEIGHT: 'weight',
    WEIGHT_IN_CHARGE: 'weightInCharge',
    WARNING: 'warning',
    IMAGE_URL: 'imageUrl',
};

export const VACUUM_RENTAL_STATUS = {
    CREATED: 'RDT',
    PENDING: 'R',
    CANCELLED: 'TX',
    IN_PROGRESS: 'RC',
    // READY_FOR_DELIVERY: 'RA',
    // DELIVERED: 'RT',
    POSTPONED: 'RAD',
    COMPLETED: 'T',
};

export const VACUUM_RENTAL_LINE_STATUS = {
    RESERVED: 'U',
    ISSUED: 'I',
    RETURNED: 'R',
    REPLACED: 'RR',
    COMPLETED: 'COM',
};

export const VACUUM_RENTAL_KEYS = {
    CODE: 'code',
    OBJ_LOCATION: 'objLocation',
    FACILITY: 'facility',
    USAGE_LOCATION: 'locationCode',
    DESCRIPTION: 'description',
    COMMENT: 'comment',
    BUDGET_CODE: 'budgetCode',
    START_DATE: 'evtRequestStart',
    END_DATE: 'evtRequestEnd',
    STATUS: 'statusCode',
    VACUUMS: 'vacCleanerRentalLines',
    HAZARDS: 'hazards',
    HAZARD_CODES: 'hazardCodes',
    HAZARD_COMMENT: 'hazardComment',
    CREATOR: 'creator',
    REQUESTER: 'requester',
    CREATED_ON: 'createdOn',
    MODIFIED_BY: 'modifiedBy',
    MODIFIED_ON: 'modifiedOn',
};

export const VACUUM_RENTAL_ACCESSORY_KEYS = {
    CODE: 'id',
    PART_CODE: 'partCode',
    DESCRIPTION: 'description',
    QUANTITY: 'requestedQuantity',
};

export const VACUUM_RENTAL_LINE_KEYS = {
    CODE: 'code',
    VAC_TYPE: 'vacTypeCode',
    VAC_CLEANER_ACCESSORIES: 'vacCleanerRentalAccessories',
    DESCRIPTION: 'description',
    LOCATION: 'usageLocation',
    ASSET_CODE: 'equipmentCode',
    START_DATE: 'estimatedIssueDate', // 'rentalLineStart',
    END_DATE: 'estimatedReturnDate', // 'rentalLineEnd',
    ISSUED_DATE: 'issuedDate',
    RETURNED_DATE: 'returnedDate',
    STATUS: 'statusCode',
    REPLACEMENT_LINE: 'replacementLine',
    REPLACEMENT_REASON: 'replacementReason',
    TOTAL_COST: 'totalCost',
    RETURN_RPM_CODE: 'returnRpmCode',
    RETURN_RPM: 'returnRpm',
    RETURN_RPM_CHECKLIST_ANSWERS: 'returnRpmChecklistAnswers',
    NOTIFICATION_ENABLED: 'notificationEnabled',
};

export const VACUUM_RENTAL_ACCESSORY_DTO_KEYLIST = [
    VACUUM_RENTAL_ACCESSORY_KEYS.CODE,
    VACUUM_RENTAL_ACCESSORY_KEYS.PART_CODE,
    VACUUM_RENTAL_ACCESSORY_KEYS.QUANTITY,
];

export const VACUUM_RENTAL_LINE_DTO_KEYLIST = [
    VACUUM_RENTAL_LINE_KEYS.CODE,
    VACUUM_RENTAL_LINE_KEYS.VAC_TYPE,
    VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES,
    VACUUM_RENTAL_LINE_KEYS.DESCRIPTION,
    VACUUM_RENTAL_LINE_KEYS.LOCATION,
    VACUUM_RENTAL_LINE_KEYS.ASSET_CODE,
    VACUUM_RENTAL_LINE_KEYS.START_DATE,
    VACUUM_RENTAL_LINE_KEYS.END_DATE,
    VACUUM_RENTAL_LINE_KEYS.ISSUED_DATE,
    VACUUM_RENTAL_LINE_KEYS.RETURNED_DATE,
    VACUUM_RENTAL_LINE_KEYS.TOTAL_COST,
    VACUUM_RENTAL_LINE_KEYS.NOTIFICATION_ENABLED,
];

export const VACUUM_RENTAL_DTO_KEYLIST = [
    VACUUM_RENTAL_KEYS.CODE,
    VACUUM_RENTAL_KEYS.USAGE_LOCATION,
    VACUUM_RENTAL_KEYS.DESCRIPTION,
    VACUUM_RENTAL_KEYS.REQUESTER,
    VACUUM_RENTAL_KEYS.BUDGET_CODE,
    VACUUM_RENTAL_KEYS.START_DATE,
    VACUUM_RENTAL_KEYS.END_DATE,
    VACUUM_RENTAL_KEYS.STATUS,
    VACUUM_RENTAL_KEYS.VACUUMS,
    VACUUM_RENTAL_KEYS.HAZARDS,
    VACUUM_RENTAL_KEYS.FACILITY,
    VACUUM_RENTAL_KEYS.COMMENT,
];

export const getVacuumColour = (type) => {
    switch (type) {
        case 'Dust':
            return '#faaa44';
        case 'Water':
            return '#0044fe';
        default:
            return '#dedede';
    }
};

export const VAC_CLEANER_REQUEST_KEYS_DTO = [];
