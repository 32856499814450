import React, { Component } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import { CircularProgress } from '@material-ui/core';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMDatePicker from 'eam-components/dist/ui/components/inputs/EAMDatePicker';
import WSRWPProcessing from '../../../../tools/rest/WSRWPProcessing';
import ValuesManager from '../../../../tools/ValuesManager';
import WSRWManagement from '../../../../tools/rest/WSRWManagement';
import { EQUIPMENT_KEYS as RWP_EQUIPMENT_KEYS } from '../../rwprocessing/RWPConstants';
import { handleError } from 'tools/TrecNotifications';

class RWEquipmentDetails extends Component {
    state = {
        loading: true,
        wasteLocations: [],
        valuesGrid: {},
    };

    constrain = (key, values) => {
        const { measurement } = this.props;
        const object = measurement.rwEquipment[0];

        const { valuesGrid } = this.state;

        return ValuesManager.constrain({ key, object, valuesGrid, values });
    };

    loadWasteZones = (wasteLocation, firstLoad = false) => {
        const { measurement } = this.props;
        const equipment = measurement.equipment[0];

        // only load the waste zones when necessary, to avoid clearing the rwSubzone value
        if (wasteLocation === equipment.locationCode && !firstLoad) {
            return Promise.resolve();
        }

        if (wasteLocation) {
            WSRWManagement.getWasteZones(wasteLocation).then((response) => {
                this.setState({
                    wasteZones: response.body.data,
                    // do not clear the wasteSubzones on the first load, as this will clear the rwSubzone value
                    ...(firstLoad ? {} : { wasteSubzones: [] }),
                });
            });
        } else {
            this.setState({
                wasteZones: [],
                wasteSubzones: [],
            });
        }
        return Promise.resolve();
    };

    loadWasteSubzones = (wasteLocation, wasteZone) => {
        if (wasteLocation && wasteZone) {
            WSRWManagement.getWasteSubzones(wasteLocation, wasteZone).then((response) =>
                this.setState({ wasteSubzones: response.body.data })
            );
        } else {
            this.setState({ wasteSubzones: [] });
        }
    };

    componentDidMount() {
        const { measurement } = this.props;
        const equipment = measurement.equipment[0];
        const rwEquipment = measurement.rwEquipment[0];

        Promise.all([
            WSRWPProcessing.getValuesGrid(),
            WSRWManagement.getWasteLocations(),
            ...(equipment.locationCode
                ? [
                      this.loadWasteZones(equipment.locationCode, true).then(
                          () => rwEquipment.rwZone && this.loadWasteSubzones(rwEquipment.location, rwEquipment.rwZone)
                      ),
                  ]
                : []),
        ])
            .then(([valuesGridResponse, wasteLocationsResponse]) => {
                this.setState({
                    valuesGrid: valuesGridResponse.body.data,
                    wasteLocations: wasteLocationsResponse.body.data,
                    loading: false,
                });
            })
            .catch(handleError);
    }

    validateField = (value) => !Number.isNaN(value) && value > 0 && value < 999999999999;

    _getAttribute = (fieldName) => {
        const { getAttribute, measurement } = this.props;
        return (getAttribute && getAttribute(fieldName, measurement.rwEquipment[0])) || 'O';
    };

    render() {
        const {
            translations,
            rwmTranslations,
            assetFields,
            measurement,
            updateProperty,
            formFields,
            rwpDropdowns,
            lang,
        } = this.props;
        const { loading, wasteLocations, wasteZones, wasteSubzones } = this.state;
        const rwEquipment = measurement.rwEquipment[0];
        const equipment = measurement.equipment[0];
        const path = `${measurement.jobNumber}.rwEquipment[0].`;
        const equipmentPath = `${measurement.jobNumber}.equipment[0].`;

        return (
            <EISPanel heading="RW Equipment Details">
                <div style={{ width: '100%', marginTop: 0 }}>
                    {loading ? (
                        <div style={{ textAlign: 'center', marginTop: '24px' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            <EAMDatePicker
                                elementInfo={{
                                    text: rwmTranslations.RECEPTIONDATE,
                                    attribute: this._getAttribute(RWP_EQUIPMENT_KEYS.RW_RECEPTION_DATE),
                                }}
                                value={rwEquipment[RWP_EQUIPMENT_KEYS.RW_RECEPTION_DATE]}
                                updateProperty={updateProperty}
                                valueKey={path + RWP_EQUIPMENT_KEYS.RW_RECEPTION_DATE}
                                formFields={formFields}
                                keyboard={false}
                                localeString={lang}
                                timestamp
                                maxDate={new Date()}
                                disabled
                            />

                            <EAMSelect
                                elementInfo={{
                                    xpath: RWP_EQUIPMENT_KEYS.RW_FAMILY,
                                    text: rwmTranslations[RWP_EQUIPMENT_KEYS.RW_FAMILY.toUpperCase()],
                                    attribute: this._getAttribute(RWP_EQUIPMENT_KEYS.RW_FAMILY),
                                }}
                                valueKey={path + RWP_EQUIPMENT_KEYS.RW_FAMILY}
                                value={rwEquipment[RWP_EQUIPMENT_KEYS.RW_FAMILY]}
                                values={this.constrain(
                                    RWP_EQUIPMENT_KEYS.RW_FAMILY,
                                    rwpDropdowns[RWP_EQUIPMENT_KEYS.RW_FAMILY]
                                )}
                                formFields={formFields}
                                updateProperty={updateProperty}
                            />

                            <EAMSelect
                                elementInfo={{
                                    xpath: RWP_EQUIPMENT_KEYS.RW_SUB_FAMILY,
                                    text: rwmTranslations[RWP_EQUIPMENT_KEYS.RW_SUB_FAMILY.toUpperCase()],
                                    attribute: this._getAttribute(RWP_EQUIPMENT_KEYS.RW_SUB_FAMILY),
                                }}
                                valueKey={path + RWP_EQUIPMENT_KEYS.RW_SUB_FAMILY}
                                value={rwEquipment[RWP_EQUIPMENT_KEYS.RW_SUB_FAMILY]}
                                values={this.constrain(
                                    RWP_EQUIPMENT_KEYS.RW_SUB_FAMILY,
                                    rwpDropdowns[RWP_EQUIPMENT_KEYS.RW_SUB_FAMILY]
                                )}
                                formFields={formFields}
                                updateProperty={updateProperty}
                            />

                            <EAMSelect
                                elementInfo={{
                                    xpath: RWP_EQUIPMENT_KEYS.MATERIAL,
                                    text: rwmTranslations.MATERIAL,
                                    attribute: this._getAttribute(RWP_EQUIPMENT_KEYS.MATERIAL),
                                }}
                                valueKey={path + RWP_EQUIPMENT_KEYS.MATERIAL}
                                value={rwEquipment[RWP_EQUIPMENT_KEYS.MATERIAL]}
                                values={this.constrain(
                                    RWP_EQUIPMENT_KEYS.MATERIAL,
                                    rwpDropdowns[RWP_EQUIPMENT_KEYS.MATERIAL]
                                )}
                                updateProperty={updateProperty}
                                multi
                            />

                            <EAMSelect
                                elementInfo={{
                                    ...assetFields.wasteLocation,
                                    attribute: this._getAttribute('wasteLocation'),
                                }}
                                valueKey={`${equipmentPath}locationCode`}
                                value={equipment.locationCode}
                                values={wasteLocations}
                                updateProperty={updateProperty}
                                onChangeValue={this.loadWasteZones}
                                creatable
                            />

                            <EAMSelect
                                elementInfo={{ ...assetFields.wasteZone, attribute: this._getAttribute('wasteZone') }}
                                valueKey={path + RWP_EQUIPMENT_KEYS.RW_ZONE}
                                value={rwEquipment[RWP_EQUIPMENT_KEYS.RW_ZONE]}
                                values={wasteZones} // {this.constrain('PW220', Tools.getCustomFieldsCodeDesc(customFieldsWasteDef.PW220))}
                                formFields={formFields}
                                updateProperty={updateProperty}
                                onChangeValue={(value) => this.loadWasteSubzones(equipment.locationCode, value)}
                            />

                            <EAMSelect
                                elementInfo={{
                                    ...assetFields.wasteSubzone,
                                    attribute: this._getAttribute('wasteSubzone'),
                                }}
                                valueKey={path + RWP_EQUIPMENT_KEYS.RW_SUBZONE}
                                value={rwEquipment[RWP_EQUIPMENT_KEYS.RW_SUBZONE]}
                                values={wasteSubzones} // {this.constrain('PW221', Tools.getCustomFieldsCodeDesc(customFieldsWasteDef.PW221))}
                                formFields={formFields}
                                updateProperty={updateProperty}
                            />

                            <EAMInput
                                elementInfo={{
                                    text: translations[RWP_EQUIPMENT_KEYS.CONTAINER_INTERNAL_LENGTH.toUpperCase()],
                                    fieldType: 'number',
                                    udfUom: 'cm',
                                    attribute: this._getAttribute(RWP_EQUIPMENT_KEYS.CONTAINER_INTERNAL_LENGTH),
                                    xpath: path + RWP_EQUIPMENT_KEYS.CONTAINER_INTERNAL_LENGTH,
                                }}
                                value={rwEquipment.containerInternalLength}
                                updateProperty={updateProperty}
                                valueKey={path + RWP_EQUIPMENT_KEYS.CONTAINER_INTERNAL_LENGTH}
                                formFields={formFields}
                                validate={this.validateField}
                            />

                            <EAMInput
                                elementInfo={{
                                    text: translations[RWP_EQUIPMENT_KEYS.CONTAINER_INTERNAL_HEIGHT.toUpperCase()],
                                    fieldType: 'number',
                                    udfUom: 'cm',
                                    attribute: this._getAttribute(RWP_EQUIPMENT_KEYS.CONTAINER_INTERNAL_HEIGHT),
                                    xpath: path + RWP_EQUIPMENT_KEYS.CONTAINER_INTERNAL_HEIGHT,
                                }}
                                value={rwEquipment.containerInternalHeight}
                                updateProperty={updateProperty}
                                valueKey={path + RWP_EQUIPMENT_KEYS.CONTAINER_INTERNAL_HEIGHT}
                                formFields={formFields}
                                validate={this.validateField}
                            />

                            <EAMInput
                                elementInfo={{
                                    text: translations[RWP_EQUIPMENT_KEYS.CONTAINER_INTERNAL_WIDTH.toUpperCase()],
                                    fieldType: 'number',
                                    udfUom: 'cm',
                                    attribute: this._getAttribute(RWP_EQUIPMENT_KEYS.CONTAINER_INTERNAL_WIDTH),
                                    xpath: path + RWP_EQUIPMENT_KEYS.CONTAINER_INTERNAL_WIDTH,
                                }}
                                value={rwEquipment.containerInternalWidth}
                                updateProperty={updateProperty}
                                valueKey={path + RWP_EQUIPMENT_KEYS.CONTAINER_INTERNAL_WIDTH}
                                formFields={formFields}
                                validate={this.validateField}
                            />
                        </>
                    )}
                </div>
            </EISPanel>
        );
    }
}

export default RWEquipmentDetails;
