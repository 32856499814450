import WS from './WS';

/**
 * Handles all calls to REST Api
 */
class WSComments {
    //
    // COMMENTS
    //
    readComments(entityCode, entityKeyCode, config = {}) {
        const encodedEntityKeyCode = encodeURIComponent(entityKeyCode);
        return WS._get(`/comments?entityCode=${entityCode}&entityKeyCode=${encodedEntityKeyCode}`, config);
    }

    createComment(comment, config = {}) {
        return WS._post('/comments/', comment, config);
    }

    updateComment(comment, config = {}) {
        return WS._put('/comments/', comment, config);
    }
}

export default new WSComments();
