import React, { Component } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import Grid from '@material-ui/core/Grid';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMDatePicker from 'eam-components/dist/ui/components/inputs/EAMDatePicker';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMLookup from 'eam-components/dist/ui/components/lookup/EAMLookup';
import Tools from '../../../../tools/Tools';
import WSAutocomplete from '../../../../tools/rest/WSAutocomplete';
import { showError } from 'tools/TrecNotifications';

const labelStyle = {
    width: 170,
    minWidth: 170,
};

class RPMRRequest extends Component {
    formFields = [];

    UNSAFE_componentWillMount() {
        const { setPage } = this.props;
        // Set the page for the help
        setPage('TREC_REQRPMEAS', 'STEP_REQUEST');
    }

    componentDidMount() {
        // Empty EDH in case it is hidden
        const { request, constants, updateRequestProperty } = this.props;

        // EAMTREC-224
        request.transportRequired = 'Yes';

        const { futureAction } = request;
        if (
            [
                constants.futureActionVacuumDeclare,
                constants.futureActionVacuumEmpty,
                constants.futureActionVacuumEnd,
                constants.futureActionVacuumEndFull,
            ].includes(futureAction)
        ) {
            // Remove edh value
            updateRequestProperty('edhDocument', '');
        }
    }

    validate() {
        const { request, constants, customFieldsDef, translations } = this.props;
        // Validations ok from the beginning
        let isValid = true;

        // EAMTREC-223: Check the location (BAAN building) in case of Stock/Destock request, option Outbound
        if (
            request.edhDocument === constants.cfvEdhDocumentStockage &&
            request.storageOperation === constants.cfvNatureOutbound &&
            !Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpStoreDestination])
                .map((el) => el.code)
                .includes(request.currentLocationCode)
        ) {
            showError(translations.LOCATION_NOT_BAAN);
            return false;
        }

        // TODO BUG FOUND: Changing Storage Operation to nOutboud allows for Next without filling equipment destination
        // Iterate over the required fields and validate them
        Object.keys(this.formFields).forEach((key) => {
            if (this.formFields[key] && this.formFields[key].validate && !this.formFields[key].validate()) {
                isValid = false;
            }
        });

        if (!isValid) {
            // Invalid data in request
            showError(translations.REQFIELINV);
        }
        return isValid;
    }

    // Possible values of the EDH Document
    getEDHDocumentValues = (edhDocumentCF) => {
        // Filter values according to the future action
        const { request, constants } = this.props;
        const { futureAction } = request;
        switch (futureAction) {
            case constants.futureActionDecwaste: // rpmrequest v1 is not used anymore for declaring waste
                return [constants.cfvEdhDocumentTransport, constants.cfvEdhDocumentStockage];
            case constants.futureActionMoveatcern:
            case constants.futureActionVacuumMove:
                return [constants.cfvEdhDocumentTransport];
            case constants.futureActionStock:
                return [constants.cfvEdhDocumentStockage];
            case constants.futureActionDispatch:
                return [constants.cfvEdhDocumentShipping];
            default:
                return edhDocumentCF.pcode;
        }
    };

    // Posible values for storage operation
    getStorageOperationValues = (storageOperationCF) => {
        // Filter values according to the future action
        const { request, constants } = this.props;
        const { futureAction } = request;
        switch (futureAction) {
            case constants.futureActionDecwaste: // rpmrequest v1 is not used anymore for declaring waste
                return [constants.cfvNatureOutbound];
            default:
                return storageOperationCF.pcode;
        }
    };

    // To know if the EDH Is required or not
    getEDHDocumentAttribute = () => {
        const { request, constants } = this.props;
        const { futureAction } = request;
        // Required for future action 'dispatch outside' and 'vacuum cleaner move'
        if (futureAction === constants.futureActionDispatch) {
            return 'R';
        }
        if (
            [
                constants.futureActionVacuumDeclare,
                constants.futureActionVacuumEmpty,
                constants.futureActionVacuumEnd,
                constants.futureActionVacuumEndFull,
            ].includes(futureAction)
        ) {
            // No EDH Document for vacuum cleaner
            return 'H';
        }
        // Optional
        return 'O';
    };

    // Fields for transport request
    getTransRequestAttribute = () => {
        // Displayed if the edh document is transport request
        const { request, constants } = this.props;
        const { edhDocument } = request;
        if (edhDocument === constants.cfvEdhDocumentTransport) {
            return 'R';
        }
        // Not displayed otherwise
        return 'H';
    };

    // Storage attribute
    getStorageAttribute = () => {
        // Displayed if the edh document is store request
        const { request, constants } = this.props;
        const { edhDocument } = request;
        if (edhDocument === constants.cfvEdhDocumentStockage) {
            return 'R';
        }
        // Not displayed otherwise
        return 'H';
    };

    // For the store destination
    getStoreDestinationAttribute = () => {
        // Displayed if the edh document is store request
        const { request, constants } = this.props;
        const { edhDocument } = request;
        const { futureAction } = request;
        // rpmrequest v1 is not used anymore for declaring waste
        if (edhDocument === constants.cfvEdhDocumentStockage && futureAction !== constants.futureActionDecwaste) {
            return 'R';
        }
        // Not displayed otherwise
        return 'H';
    };

    // Calculate destination attribute
    getDestinationAttribute = () => {
        // Displayed if the edh document is transport and the future action is not declare waste
        const { request, constants } = this.props;
        const { edhDocument } = request;
        const { futureAction } = request;
        // rpmrequest v1 is not used anymore for declaring waste
        if (edhDocument === constants.cfvEdhDocumentTransport && futureAction !== constants.futureActionDecwaste) {
            return 'R';
        }
        // Not displayed otherwise
        return 'H';
    };

    // Calculate department attribute
    getDepartmentAttribute = () => {
        // Displayed if future action is declare waste and the user is an RW Officer as well
        const { userData, applicationData } = this.props;
        const { department } = userData.eamAccount;
        // List of departments to allow display department
        const departments = [applicationData.rwsection, applicationData.r5Section];
        return departments.includes(department) ? 'R' : 'H';
    };

    renderContent = () => {
        const {
            applicationData,
            request,
            updateRequestProperty,
            woFields,
            customFields,
            constants,
            customFieldsDef,
            lists,
            translations,
            lang,
        } = this.props;

        // EDH Document custom field
        let edhDocumentCF = { ...customFieldsDef[constants.cfpEdhDocument] };
        // Replace Values
        edhDocumentCF = Tools.filterCustomFieldValues(edhDocumentCF, this.getEDHDocumentValues(edhDocumentCF));
        // Storage Operation
        let storageOperationCF = { ...customFieldsDef[constants.cfpStorageOperation] };
        // Replace values
        storageOperationCF = Tools.filterCustomFieldValues(
            storageOperationCF,
            this.getStorageOperationValues(storageOperationCF)
        );

        return (
            <div style={{ width: '100%', height: '100%' }}>
                {/* Department */}
                <EAMSelect
                    elementInfo={{ ...woFields.department, attribute: this.getDepartmentAttribute() }}
                    valueKey="department"
                    value={request.department}
                    values={lists.departments}
                    updateProperty={updateRequestProperty}
                    formFields={this.formFields}
                    labelStyle={labelStyle}
                />

                {/* Associated risk */}
                {applicationData.isHazardsFeatureActive ? null : (
                    <EAMSelect
                        elementInfo={{ ...customFields[constants.cfpAssociatedRisks], attribute: 'R' }}
                        valueKey="associatedRisk"
                        value={request.associatedRisk}
                        values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpAssociatedRisks], true)}
                        updateProperty={updateRequestProperty}
                        formFields={this.formFields}
                        labelStyle={labelStyle}
                    />
                )}

                {/* EDH Document */}
                <EAMSelect
                    elementInfo={{
                        ...customFields[constants.cfpEdhDocument],
                        attribute: this.getEDHDocumentAttribute(),
                    }}
                    valueKey="edhDocument"
                    value={request.edhDocument}
                    values={Tools.getCustomFieldsCodeDesc(edhDocumentCF, true)}
                    updateProperty={updateRequestProperty}
                    formFields={this.formFields}
                    labelStyle={labelStyle}
                    placeholder={translations.NOEDHDOC}
                />

                {/* Type of transport */}
                <EAMSelect
                    elementInfo={{
                        ...customFields[constants.cfpTypeOfTransport],
                        attribute: this.getTransRequestAttribute(),
                    }}
                    valueKey="typeOfTransport"
                    value={request.typeOfTransport}
                    values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpTypeOfTransport], true)}
                    updateProperty={updateRequestProperty}
                    formFields={this.formFields}
                    labelStyle={labelStyle}
                />

                {/* Storage operation */}
                <EAMSelect
                    elementInfo={{
                        ...customFields[constants.cfpStorageOperation],
                        attribute: this.getStorageAttribute(),
                    }}
                    valueKey="storageOperation"
                    value={request.storageOperation}
                    values={Tools.getCustomFieldsCodeDesc(storageOperationCF, true)}
                    updateProperty={updateRequestProperty}
                    formFields={this.formFields}
                    labelStyle={labelStyle}
                />

                {/* Transport required */}
                <EAMSelect
                    elementInfo={{
                        ...customFields[constants.cfpTransportRequired],
                        attribute: this.getStorageAttribute(),
                    }}
                    valueKey="transportRequired"
                    value={request.transportRequired}
                    values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpTransportRequired])}
                    updateProperty={updateRequestProperty}
                    formFields={this.formFields}
                    labelStyle={labelStyle}
                />

                {/* Equipment destination */}
                <EAMLookup
                    right={1}
                    top={13}
                    width={26}
                    height={26}
                    gridId={applicationData.locationRaisinGridID}
                    keys={{
                        code: 'obj_code',
                        mapCodeTo: 'equipmentDestination',
                    }}
                    updateProperty={updateRequestProperty}
                    value={request.equipmentDestination}
                    disabled={this.getDestinationAttribute() === 'H'}
                >
                    <EAMAutocomplete
                        elementInfo={{
                            ...customFields[constants.cfpEquipmentDestination],
                            attribute: this.getDestinationAttribute(),
                            xpath: 'equipmentDestination',
                        }}
                        value={request.equipmentDestination}
                        valueKey="equipmentDestination"
                        updateProperty={updateRequestProperty}
                        loadOptions={WSAutocomplete.autocompleteTRECLocation}
                        labelStyle={labelStyle}
                        formFields={this.formFields}
                        columnsCodes={['code', 'desc', 'extraAttr1']}
                        columnsWidth={['30%', '30%', '40%']}
                    />
                </EAMLookup>

                {/* Equipment destination - Radioactive */}
                <EAMLookup
                    right={1}
                    top={13}
                    width={26}
                    height={26}
                    gridId={applicationData.locationRaisinGridID}
                    keys={{
                        code: 'obj_code',
                        mapCodeTo: 'destinationIfRadioactive',
                    }}
                    updateProperty={updateRequestProperty}
                    value={request.destinationIfRadioactive}
                    disabled={this.getDestinationAttribute() === 'H'}
                >
                    <EAMAutocomplete
                        elementInfo={{
                            ...customFields[constants.cfpEquipmentDestinationRad],
                            attribute: this.getDestinationAttribute(),
                            xpath: 'equipmentDestinationRad',
                        }}
                        value={request.destinationIfRadioactive}
                        valueKey="destinationIfRadioactive"
                        updateProperty={updateRequestProperty}
                        loadOptions={WSAutocomplete.autocompleteTRECLocation}
                        labelStyle={labelStyle}
                        formFields={this.formFields}
                        columnsCodes={['code', 'desc', 'extraAttr1']}
                        columnsWidth={['30%', '30%', '40%']}
                    />
                </EAMLookup>
                {/* Store destination */}
                {/* if Inbound load TREC locations, if Outbound load all locations */}
                {request.storageOperation === constants.cfvNatureInbound ? (
                    <EAMSelect
                        elementInfo={{
                            ...customFields[constants.cfpEquipmentDestination],
                            attribute: this.getStoreDestinationAttribute(),
                        }}
                        valueKey="equipmentDestination"
                        value={request.equipmentDestination}
                        values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpStoreDestination])}
                        updateProperty={updateRequestProperty}
                        formFields={this.formFields}
                        labelStyle={labelStyle}
                    />
                ) : (
                    <EAMLookup
                        right={1}
                        top={13}
                        width={26}
                        height={26}
                        gridId={applicationData.locationRaisinGridID}
                        keys={{
                            code: 'obj_code',
                            mapCodeTo: 'equipmentDestination',
                        }}
                        updateProperty={updateRequestProperty}
                        value={request.equipmentDestination}
                        disabled={this.getStoreDestinationAttribute() === 'H'}
                    >
                        <EAMAutocomplete
                            elementInfo={{
                                ...customFields[constants.cfpEquipmentDestination],
                                attribute: this.getStoreDestinationAttribute(),
                            }}
                            value={request.equipmentDestination}
                            valueKey="equipmentDestination"
                            updateProperty={updateRequestProperty}
                            loadOptions={WSAutocomplete.autocompleteTRECLocation}
                            labelStyle={labelStyle}
                            formFields={this.formFields}
                            columnsCodes={['code', 'desc', 'extraAttr1']}
                            columnsWidth={['30%', '30%', '40%']}
                        />
                    </EAMLookup>
                )}

                {/* Store destination - Radioactive */}
                {/* if Inbound load TREC locations, if Outbound load all locations */}
                {request.storageOperation === constants.cfvNatureInbound ? (
                    <EAMSelect
                        elementInfo={{
                            ...customFields[constants.cfpEquipmentDestinationRad],
                            attribute: this.getStoreDestinationAttribute(),
                        }}
                        valueKey="destinationIfRadioactive"
                        value={request.destinationIfRadioactive}
                        values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpStoreDestination])}
                        updateProperty={updateRequestProperty}
                        formFields={this.formFields}
                        labelStyle={labelStyle}
                    />
                ) : (
                    <EAMLookup
                        right={1}
                        top={13}
                        width={26}
                        height={26}
                        gridId={applicationData.locationRaisinGridID}
                        keys={{
                            code: 'obj_code',
                            mapCodeTo: 'destinationIfRadioactive',
                        }}
                        updateProperty={updateRequestProperty}
                        value={request.destinationIfRadioactive}
                        disabled={this.getStoreDestinationAttribute() === 'H'}
                    >
                        <EAMAutocomplete
                            elementInfo={{
                                ...customFields[constants.cfpEquipmentDestinationRad],
                                attribute: this.getStoreDestinationAttribute(),
                            }}
                            valueKey="destinationIfRadioactive"
                            value={request.destinationIfRadioactive}
                            updateProperty={updateRequestProperty}
                            loadOptions={WSAutocomplete.autocompleteTRECLocation}
                            labelStyle={labelStyle}
                            formFields={this.formFields}
                            columnsCodes={['code', 'desc', 'extraAttr1']}
                            columnsWidth={['30%', '30%', '40%']}
                        />
                    </EAMLookup>
                )}

                {/* Measurement deadline */}
                <EAMDatePicker
                    elementInfo={woFields.schedstartdate}
                    valueKey="measurementDeadline"
                    value={request.measurementDeadline}
                    updateProperty={updateRequestProperty}
                    formFields={this.formFields}
                    labelStyle={labelStyle}
                    localeString={lang}
                    disablePast
                />

                {/* Measurement Comments */}
                <EAMInput
                    elementInfo={woFields.measurementComments}
                    valueKey="measurementComments"
                    value={request.measurementComments}
                    updateProperty={updateRequestProperty}
                    formFields={this.formFields}
                    labelStyle={labelStyle}
                />
            </div>
        );
    };

    render() {
        const { translations } = this.props;
        return (
            <Grid container spacing={0}>
                <Grid item md={6} sm={12} xs={12}>
                    <EISPanel heading={translations.DETAILSRPMEAS} alwaysExpanded>
                        {this.renderContent()}
                    </EISPanel>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    {/* No content */}
                </Grid>
            </Grid>
        );
    }
}

export default RPMRRequest;
