import DEFAULTS from 'enums/Defaults';
import React from 'react';
import RPARAnalysisDetails from './RPARAnalysisDetails';

export const ANALYSIS_TYPE = 'LIQUIDSCINT';

function RPARLiquidScintillation(props) {
    const { getTranslation, rparGetters, dropdowns, valuesGrid } = props;
    const tableLayout = DEFAULTS.getTableLayout({ ANALYSIS_TYPE, rparGetters, dropdowns, valuesGrid }).map((e) => ({
        ...e,
        header: getTranslation(e.code),
    }));
    return <RPARAnalysisDetails {...props} analysisType="LIQUIDSCINT" tableLayout={tableLayout} />;
}

export default RPARLiquidScintillation;
