import React from 'react';
import { withStyles } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const styles = (theme) => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    errorIcon: {
        padding: theme.spacing.unit * 1,
    },
});

const NotFoundPage = (props) => {
    const { classes } = props;
    return (
        <div className={classes.root}>
            <ErrorOutlineIcon className={classes.errorIcon} />
            Ups! Page not Found
        </div>
    );
};

export default withStyles(styles)(NotFoundPage);
