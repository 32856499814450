import React from 'react';
import TRECPage from '../TRECPage';
import BZInventoryInitialization from './steps/BZInventoryInitialization';
import BZInventoryScanning from './steps/BZInventoryScanning';
import BZInventoryActions from './steps/actions/BZInventoryActions';
import 'react-block-ui/style.css';
import BZPastInventories from './BZPastInventories';
import BZPastInventory from './BZPastInventory';
import Tools from '../../../tools/Tools';
import {
    ACTION_CHANGE_LOCATION,
    ACTION_NONE,
    BZ_INVENTORY,
    STEP_INITALIZATION,
    STEP_SCANNING,
    STEP_ACTIONS,
    STEP_APPROVED,
    STEP_PAST_INVENTORIES,
    EQP_PRESENT,
    EQP_NOT_PRESENT,
    EQP_TO_CHECK,
    EQP_FOUND,
} from './steps/BZInventoryUtils';

class BZInventory extends TRECPage {
    state = {
        step: STEP_INITALIZATION,
        loading: false,
    };

    componentDidMount() {
        const pastInventory = Tools.getURLParameterByName('pastinventory');
        if (pastInventory) {
            this.setState(() => ({
                pastInventory,
            }));
        }
    }

    updateProperty = (key, value) => {
        this.setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    goToActions = (data) => {
        // flip statuses from "to check" to "not present"
        data.filter((element) => element.resultCode === EQP_TO_CHECK).forEach((element) => {
            element.resultCode = EQP_NOT_PRESENT;
        });

        data.forEach((element) => this.setDefaults(element));

        this.setState(() => ({
            step: STEP_ACTIONS,
            data,
        }));

        this.props.setPage(BZ_INVENTORY, STEP_ACTIONS);
    };

    scanMoreCodes = () => {
        const { data } = this.state;

        // flip statuses from "not present" to "to check"
        data.filter((element) => element.resultCode === EQP_NOT_PRESENT).forEach((element) => {
            element.resultCode = EQP_TO_CHECK;
        });

        this.setState(() => ({
            data,
            step: STEP_SCANNING,
        }));

        this.props.setPage(BZ_INVENTORY, STEP_SCANNING);
    };

    setDefaults = (inventoryItem) => {
        const { constants } = this.props;
        // set default new location to null
        inventoryItem.newLocationCode = null;

        // if asset was found, change action to Change location, with new location
        // of the buffer zone where asset is found
        if (inventoryItem.resultCode === EQP_FOUND && !inventoryItem.afterInventoryActionCode) {
            inventoryItem.afterInventoryActionCode = ACTION_CHANGE_LOCATION;
            inventoryItem.newLocationCode = this.state.bufferZone;
        }

        // for found or present waste set the default new location to 133
        if (
            (inventoryItem.resultCode === EQP_FOUND || inventoryItem.resultCode === EQP_PRESENT) &&
            !inventoryItem.afterInventoryActionCode
        ) {
            if (
                [
                    constants.futureActionDecwaste,
                    constants.futureActionWasteDeclare,
                    constants.futureActionWasteReceive,
                ].includes(inventoryItem.futureUsageCode)
            ) {
                inventoryItem.afterInventoryActionCode = ACTION_CHANGE_LOCATION;
                inventoryItem.newLocationCode = this.props.inventoryWasteLocation;
            }
        }

        // if asset is present or not present default action is none
        if (
            (inventoryItem.resultCode === EQP_PRESENT || inventoryItem.resultCode === EQP_NOT_PRESENT) &&
            !inventoryItem.afterInventoryActionCode
        ) {
            inventoryItem.afterInventoryActionCode = ACTION_NONE;
        }
    };

    approveInventory = () => {
        this.setState(() => ({ step: STEP_APPROVED }));

        this.props.setPage(BZ_INVENTORY, STEP_ACTIONS);
    };

    renderPage(writeAccess) {
        const { bufferZone, ...other } = this.props;

        if (this.state.pastInventory) {
            return (
                <BZPastInventory
                    inventory={this.state.pastInventory}
                    goBackToPastInventorySelection={() => {
                        this.props.history.push('inventory');
                        this.setState(() => ({ pastInventory: null, STEP: STEP_PAST_INVENTORIES }));
                    }}
                    {...other}
                />
            );
        }

        if (this.state.step === STEP_INITALIZATION && writeAccess) {
            return (
                <BZInventoryInitialization
                    updateProperty={this.updateProperty}
                    bufferZone={this.state.bufferZone}
                    {...other}
                />
            );
        }

        if (this.state.step === STEP_SCANNING) {
            return (
                <BZInventoryScanning
                    bufferZone={this.state.bufferZone}
                    goToActions={this.goToActions}
                    inventoryData={this.state.data}
                    setData={(data) => {
                        typeof data === 'function'
                            ? this.setState((prevState) => data(prevState))
                            : this.setState({ data });
                    }}
                    {...other}
                />
            );
        }

        if (this.state.step === STEP_ACTIONS || this.state.step === STEP_APPROVED) {
            return (
                <BZInventoryActions
                    data={this.state.data}
                    scanMoreCodes={this.scanMoreCodes}
                    updateProperty={this.updateProperty}
                    approveInventory={this.approveInventory}
                    approved={this.state.step === STEP_APPROVED}
                    bufferZone={this.state.bufferZone}
                    {...other}
                />
            );
        }

        if (this.state.step === STEP_PAST_INVENTORIES) {
            return (
                <BZPastInventories
                    {...other}
                    backToMenu={() => {
                        this.setState(() => ({ step: STEP_INITALIZATION }));
                    }}
                    setPastInventory={(number) => this.setState(() => ({ pastInventory: number }))}
                />
            );
        }

        return null;
    }
}

export default BZInventory;
