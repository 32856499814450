import { logout } from 'actions/applicationActions';
import { setPage, updateLayout } from 'actions/layoutActions';
import rwpActions from 'actions/rwpActions';
import { parse as queryParse } from 'query-string';
import { connect } from 'react-redux';
import { rwpGettersWithState } from 'reducers/rwpReducer';
import Tools from 'tools/Tools';

const RPM_TRANSLATION_KEY = 'TREC_RWPROCESSING';

const mapStateToProps = (state, ownProps) => {
    const { action, application, layout } = state;
    const { applicationData, bufferZone, constants, menuType, screenData, userData, rwpDropdowns, authorizedMenus } =
        application;
    const { lang } = layout;
    const translations = screenData[lang].translations[RPM_TRANSLATION_KEY];
    const urlParams = queryParse(ownProps.location.search);

    const { customFields } = screenData[lang];
    const { customFieldsDef } = screenData[lang];

    const rwpDropdownsTranslated = Tools.applyToFields(rwpDropdowns, (list) =>
        list.map((codeDesc) => ({
            ...codeDesc,
            desc: (codeDesc.descTranslated && codeDesc.descTranslated[lang]) || codeDesc.desc,
        }))
    );

    return {
        screenData: screenData[lang],
        userData,
        applicationData,
        bufferZone,
        menuType,
        constants,
        rwpDropdowns: rwpDropdownsTranslated,
        customFields,
        customFieldsDef,
        lang,
        translations,
        masterPage: ['RW_PROCESSING'],
        page: 'RW_PROCESSING',
        menu: screenData[lang].menu[menuType],
        action,
        urlParams,
        getTranslation: (code) =>
            code &&
            ((urlParams.lang === 'DEV' && code) ||
                (translations && translations[code.toUpperCase()]) ||
                (screenData.EN.translations[RPM_TRANSLATION_KEY] &&
                    screenData.EN.translations[RPM_TRANSLATION_KEY][code.toUpperCase()]) ||
                code),
        rwpGetters: rwpGettersWithState(state),
        authorizedMenus,
    };
};

const Containerize = (Component) =>
    connect(
        mapStateToProps,
        {
            updateLayout,
            setPage,
            logout,
            ...rwpActions,
        },
        (stateProps, dispatchProps, ownProps) => ({
            ...ownProps,
            ...stateProps,
            storeActions: dispatchProps,
            setPage: dispatchProps.setPage,
        })
    )(Component);

export default Containerize;
