import React, { Component } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import Grid from '@material-ui/core/Grid';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import Tools from '../../../../tools/Tools';
import { showError } from 'tools/TrecNotifications';

class RPMRActions extends Component {
    formFields = [];

    UNSAFE_componentWillMount() {
        // Set the page for the help
        const { setPage } = this.props;
        setPage('TREC_REQRPMEAS', 'STEP_ACTIONS');
    }

    validate() {
        // Validations ok from the beginning
        let isValid = true;
        const { translations, customFields, constants, request, updateRequestProperty } = this.props;
        const { futureAction } = request;
        // Validate future action only
        const futureActionField = this.formFields[customFields[constants.cfpFutureAction].xpath];
        if (!futureActionField.validate()) {
            isValid = false;
            // Show error
            showError(translations.ACTINVALID);
        } else {
            // Check if future action is 'declare use', in this case don't populate the responsible
            if (futureActionField.state.value.code.indexOf('declare use') !== -1) {
                updateRequestProperty('equipmentRespCode', '');
                updateRequestProperty('equipmentRespDesc', '');
            }
            // RQF1056033 If Future action is "Move inside CERN", then default EDH Document is transport
            if (futureAction === constants.futureActionMoveatcern && !request.edhDocument) {
                // Set transport as suggestion
                updateRequestProperty('edhDocument', constants.cfvEdhDocumentTransport);
            }
        }
        return isValid;
    }

    onChangeFutureAction = (newValue) => {
        // If the value changes, we remove the equipments selected
        const { request, updateRequestProperty } = this.props;
        if (newValue !== request.futureAction) {
            updateRequestProperty('equipment', []);
            updateRequestProperty('equipmentRespCode', '');
            updateRequestProperty('equipmentRespDesc', '');
        }
    };

    render() {
        const { translations, request, updateRequestProperty, customFields, constants, customFieldsDef } = this.props;
        const { customFieldsRP } = request;
        // Future action custom field
        let futureActionCF = { ...customFieldsDef[constants.cfpFutureAction] };
        // Replace future action codes
        futureActionCF = Tools.filterCustomFieldValues(futureActionCF, customFieldsRP[constants.cfpFutureAction].pcode);

        return (
            <Grid container spacing={0}>
                <Grid item md={6} sm={12} xs={12}>
                    <EISPanel heading={translations.ACTIONS} alwaysExpanded>
                        <div style={{ width: '100%', marginTop: 0 }}>
                            {translations.ACT_MESSAGE}
                            <EAMSelect
                                elementInfo={{ ...customFields[constants.cfpFutureAction], attribute: 'R' }}
                                valueKey="futureAction"
                                value={request.futureAction}
                                values={Tools.getCustomFieldsCodeDesc(futureActionCF, true)}
                                updateProperty={updateRequestProperty}
                                formFields={this.formFields}
                                onChangeValue={this.onChangeFutureAction}
                            />

                            {request.futureAction === constants.futureActionVacuumEnd && (
                                <EAMCheckbox
                                    elementInfo={customFields[constants.cfpVacclIsfull]}
                                    valueKey="vacclisfull"
                                    value={request.vacclisfull}
                                    updateProperty={updateRequestProperty}
                                    trueValue
                                    falseValue={false}
                                />
                            )}
                        </div>
                    </EISPanel>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    {/* No content */}
                </Grid>
            </Grid>
        );
    }
}

export default RPMRActions;
