import { setPage, updateLayout } from 'actions/layoutActions';
import { openConfirmDialog } from 'react-eis-components/dist/actions/uiActions';
import { connect } from 'react-redux';
import Tools from 'tools/Tools';
import RWManagement from './RWManagement';

const TRANSLATION_KEY = 'TREC_RWMANAGEMENT';

function mapStateToProps(state) {
    const { application, layout } = state;
    const { lang } = layout;
    const translations = state.application.screenData[state.layout.lang].translations[TRANSLATION_KEY];
    const { screenData } = state.application;

    const getTranslation = (code) =>
        !code || Tools.getURLParameterByName('lang') === 'DEV'
            ? code
            : translations?.[code.toUpperCase()] ||
              screenData.EN.translations[TRANSLATION_KEY]?.[code.toUpperCase()] ||
              code;
    const rwpDropdownsTranslated = Tools.applyToFields(application.rwpDropdowns, (list) =>
        list.map((codeDesc) => ({
            ...codeDesc,
            desc: (codeDesc.descTranslated && codeDesc.descTranslated[lang]) || codeDesc.desc,
        }))
    );

    return {
        screenData,
        translations,
        userData: state.application.userData,
        applicationData: state.application.applicationData,
        bufferZone: state.application.bufferZone,
        lang: state.layout.lang,
        constants: state.application.constants,
        menuType: state.application.menuType,
        masterPage: 'RAD_WASTE_MNG',
        page: 'RAD_WASTE_MNG',
        menu: state.application.screenData[state.layout.lang].menu[state.application.menuType],
        rwpDropdowns: rwpDropdownsTranslated,
        getTranslation,
    };
}

const RWManagementContainer = connect(mapStateToProps, {
    updateLayout,
    setPage,
    openConfirmDialog,
})(RWManagement);

export default RWManagementContainer;
