import {
    CLONE_REQUEST_NEW_EQUIPMENT,
    RESET_RPAR,
    SET_RPAR_ANALYSIS_TYPES,
    SET_RPAR_EQUIPMENTS_ANALYSIS_TYPE,
    SET_RPAR_EQUIPMENT_LIST,
    SET_RPAR_PROPERTIES,
    UPDATE_RPAR,
    UPDATE_RPAR_EQUIPMENTS,
} from '../actions/rparActions';
import { ANALYSIS_KEYS, EQUIPMENT_KEYS, RPAR_PERFORM_KEYS, RPAR_REQUEST_KEYS } from '../enums/Constants';
import Tools from '../tools/Tools';

const FAST_DEBUG = false;

const getNewRPAR = () => ({
    properties: {
        [RPAR_REQUEST_KEYS.DESCRIPTION]: FAST_DEBUG ? 'Fast Debug' : '',
        [RPAR_REQUEST_KEYS.WORKORDER]: '',
        [RPAR_REQUEST_KEYS.STATUS]: '',
        [RPAR_REQUEST_KEYS.SCHEDULED_START_DATE]: '',
        [RPAR_REQUEST_KEYS.SCHEDULED_END_DATE]: '',
        [RPAR_REQUEST_KEYS.PURPOSE]: FAST_DEBUG ? 'CLAS' : '',
    },
    equipments: {},
    analysisTypesEquipmentMap: {},

    getAnalysisTypeMap() {
        return Tools.applyToFields(this.analysisTypesEquipmentMap, (objectMap) =>
            Tools.filterObjectFields(objectMap, (key) => Object.keys(this.equipments).includes(key))
        );
    },
});

const getDefaultEndline = () =>
    FAST_DEBUG
        ? {
              [EQUIPMENT_KEYS.LABORATORY]: 'B179A',
              [EQUIPMENT_KEYS.TYPE_OF_TRANSPORT]: 'None',
              [EQUIPMENT_KEYS.POST_DEST]: '112',
              [EQUIPMENT_KEYS.POST_DEST_RAD]: '112',
          }
        : {};

export default function application(state = getNewRPAR(), action) {
    switch (action.type) {
        case RESET_RPAR:
            return getNewRPAR();
        case UPDATE_RPAR:
            return {
                ...state,
                properties: {
                    ...state.properties,
                    ...action.value,
                },
            };
        case SET_RPAR_EQUIPMENT_LIST:
            return {
                ...state,
                equipments: {
                    ...action.value.reduce((acc, code) => Object.assign(acc, { [code]: getDefaultEndline() }), {}),
                    ...Object.entries(state.equipments)
                        .filter((entry) => action.value.includes(entry[0]))
                        .reduce(
                            (acc, entry) => Object.assign(acc, { [entry[0]]: { ...getDefaultEndline(), ...entry[1] } }),
                            {}
                        ),
                },
            };
        case UPDATE_RPAR_EQUIPMENTS:
            return {
                ...state,
                equipments: {
                    ...state.equipments,
                    ...action.value,
                },
            };
        case SET_RPAR_ANALYSIS_TYPES:
            return {
                ...state,
                analysisTypesEquipmentMap: action.analysisTypes.reduce(
                    (acc, atCode) => Object.assign(acc, { [atCode]: state.analysisTypesEquipmentMap[atCode] || {} }),
                    {}
                ),
            };
        case SET_RPAR_EQUIPMENTS_ANALYSIS_TYPE:
            return {
                ...state,
                analysisTypesEquipmentMap: {
                    ...state.analysisTypesEquipmentMap,
                    [action.analysisType]: {
                        ...action.equipments.reduce(
                            (acc, eqCode) => ({
                                ...acc,
                                [eqCode]: state.analysisTypesEquipmentMap[action.analysisType][eqCode] || {},
                            }),
                            {}
                        ),
                    },
                },
            };
        case SET_RPAR_PROPERTIES:
            return {
                ...state,
                analysisTypesEquipmentMap: {
                    ...state.analysisTypesEquipmentMap,
                    [action.analysisType]: {
                        ...state.analysisTypesEquipmentMap[action.analysisType],
                        ...action.propertiesMap,
                    },
                },
            };
        case CLONE_REQUEST_NEW_EQUIPMENT:
            return {
                ...state,
                equipments: Tools.applyToFieldsKV(
                    Tools.applyToFieldsK(state.equipments, (eqCode) => action.equipmentMap[eqCode]),
                    (key, val) => ({ ...val, [EQUIPMENT_KEYS.EQUIPMENT_CODE]: key })
                ),
                analysisTypesEquipmentMap: Tools.applyToFields(state.analysisTypesEquipmentMap, (anType) =>
                    Tools.applyToFields(
                        Tools.applyToFieldsK(anType, (eqCode) => action.equipmentMap[eqCode]),
                        (obj) => ({ ...obj, [ANALYSIS_KEYS.DEADLINE]: '', [ANALYSIS_KEYS.REQUESTED_ACTIVITY_TIME]: '' })
                    )
                ),
            };
        default:
            return state;
    }
}

const getAnalysisRequestProperties = (state) => () => state.properties;

const getEquipmentList = (state) => () => Object.keys(state.equipments);

const getEquipments = (state) => () => state.equipments;

const getAnalysisTypes = (state) => () => Object.keys(state.analysisTypesEquipmentMap);

const getAnalysisTypesWithEquipments = (state) => () =>
    Object.entries(state.getAnalysisTypeMap())
        .filter((entry) => Object.entries(entry[1]).length > 0)
        .map((entry) => entry[0]);

const getAnalysisTypeEquipments = (state) => (type) => state.getAnalysisTypeMap()[type] || [];

const isAnalysisEditable = (state) => (eqCode, analysisType) =>
    !state.analysisTypesEquipmentMap[analysisType][eqCode] ||
    !state.analysisTypesEquipmentMap[analysisType][eqCode][RPAR_PERFORM_KEYS.REPORTWO];

const rparGetters = {
    getAnalysisRequestProperties,
    getEquipmentList,
    getEquipments,
    getAnalysisTypes,
    getAnalysisTypesWithEquipments,
    getAnalysisTypeEquipments,
    isAnalysisEditable,
};

export const rparGettersWithState = (state) =>
    Object.keys(rparGetters).reduce((acc, key) => Object.assign(acc, { [key]: rparGetters[key](state) }), {});
