import React, { Component } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel/index';
import MeasurementLine from './MeasurementLine';

class RadiationMeasurements extends Component {
    getBKGDeviceAttribute = () => {
        const { measurement, constants } = this.props;
        const codes = [constants.deviceBgo, constants.deviceAdb];
        const devices = [measurement.device1, measurement.device2, measurement.device3, measurement.device4];

        return codes.some((code) => devices.includes(code)) ? 'R' : 'O';
    };

    isRWC = (measurement) => {
        const { applicationData } = this.props;
        return applicationData && applicationData.rwsection === measurement.department;
    };
    /*
     * Update BKG Device with either ADB or BKG if these values are
     * selected for any of the devices (Device1, Device2, ...)
     */

    updateBKGDevice = (value) => {
        const { constants, updateProperty, measurement } = this.props;
        const isRwc = this.isRWC(measurement);
        const codes = [constants.deviceBgo, constants.deviceAdb];
        if (updateProperty && codes.indexOf(value) > -1 && !isRwc) {
            updateProperty(`${measurement.jobNumber}.bkgDevice`, value);
            if (!measurement.bkgQualifier) {
                updateProperty(`${measurement.jobNumber}.bkgQualifier`, '=');
            }
        }
    };

    render() {
        const { translations, constants, detailsStyle } = this.props;

        return (
            <EISPanel detailsStyle={detailsStyle} heading={translations.HEAD_RADMEAS}>
                <div style={{ width: '100%', marginTop: 0 }}>
                    <MeasurementLine
                        deviceProperty="device1"
                        distanceProperty="distance1"
                        qualifierProperty="qualifier1"
                        valueProperty="value1"
                        serialProperty="serial1"
                        deviceCF={constants.cfpRadiationDevice1}
                        distanceCF={constants.cfpRadiationDistance1}
                        qualifierCF={constants.cfpMeasValueQualifier1}
                        valueCF={constants.cfpRadiationNetValue1}
                        serialCF={constants.cfpRadiationSerialValue1}
                        onDeviceChange={this.updateBKGDevice}
                        {...this.props}
                    />

                    <MeasurementLine
                        deviceProperty="device2"
                        distanceProperty="distance2"
                        qualifierProperty="qualifier2"
                        valueProperty="value2"
                        serialProperty="serial2"
                        deviceCF={constants.cfpRadiationDevice2}
                        distanceCF={constants.cfpRadiationDistance2}
                        qualifierCF={constants.cfpMeasValueQualifier2}
                        valueCF={constants.cfpRadiationNetValue2}
                        serialCF={constants.cfpRadiationSerialValue2}
                        onDeviceChange={this.updateBKGDevice}
                        {...this.props}
                    />

                    <MeasurementLine
                        deviceProperty="device3"
                        distanceProperty="distance3"
                        qualifierProperty="qualifier3"
                        valueProperty="value3"
                        serialProperty="serial3"
                        deviceCF={constants.cfpRadiationDevice3}
                        distanceCF={constants.cfpRadiationDistance3}
                        qualifierCF={constants.cfpMeasValueQualifier3}
                        valueCF={constants.cfpRadiationNetValue3}
                        serialCF={constants.cfpRadiationSerialValue3}
                        onDeviceChange={this.updateBKGDevice}
                        {...this.props}
                    />

                    <MeasurementLine
                        deviceProperty="device4"
                        distanceProperty="distance4"
                        qualifierProperty="qualifier4"
                        valueProperty="value4"
                        serialProperty="serial4"
                        deviceCF={constants.cfpRadiationDevice4}
                        distanceCF={constants.cfpRadiationDistance4}
                        qualifierCF={constants.cfpMeasValueQualifier4}
                        valueCF={constants.cfpRadiationNetValue4}
                        serialCF={constants.cfpRadiationSerialValue4}
                        onDeviceChange={this.updateBKGDevice}
                        {...this.props}
                    />

                    <MeasurementLine
                        deviceProperty="bkgDevice"
                        qualifierProperty="bkgQualifier"
                        valueProperty="bkgValue"
                        deviceCF={constants.cfpBkgDevice}
                        qualifierCF={constants.cfpBkgQualifier}
                        valueCF={constants.cfpBkgValue}
                        attribute={this.getBKGDeviceAttribute()}
                        {...this.props}
                    />
                </div>
            </EISPanel>
        );
    }
}

export default RadiationMeasurements;
