import React, { Component } from 'react';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import Tools from '../../../../../tools/Tools';
import TRECPrefixInput from './TRECPrefixInput';

class MeasurementLine extends Component {
    mainDivStyle = {
        width: '100%',
        display: 'flex',
    };

    deviceStyle = {
        width: 140,
        minWidth: 50,
    };

    restStyle = {
        display: 'flex',
        flexGrow: '1',
    };

    distanceStyle = {
        width: 110,
    };

    onDeviceChange = (value) => {
        const {
            measurement,
            updateProperty,
            distanceProperty,
            qualifierProperty,
            valueProperty,
            serialProperty,
            onDeviceChange,
            fetchUsersSerialDevice,
            jobIsClosed,
            jobIsCancelled,
        } = this.props;

        if (!updateProperty) return;

        // Update BKG Device with either ADB or BKG if these values are
        // selected for any of the devices (Device1, Device2, ...)
        if (onDeviceChange) {
            onDeviceChange(value);
        }

        if (value) {
            if (!measurement[qualifierProperty]) updateProperty(`${measurement.jobNumber}.${qualifierProperty}`, '=');
            // If there is serial device and is not set yet, try to fetch the value
            if (
                serialProperty &&
                !measurement[serialProperty] &&
                !(jobIsClosed || jobIsCancelled) &&
                fetchUsersSerialDevice
            ) {
                fetchUsersSerialDevice(value, `${measurement.jobNumber}.${serialProperty}`);
            }
        } else {
            updateProperty(`${measurement.jobNumber}.${distanceProperty}`, '');
            updateProperty(`${measurement.jobNumber}.${qualifierProperty}`, '');
            updateProperty(`${measurement.jobNumber}.${valueProperty}`, '');
            updateProperty(`${measurement.jobNumber}.${serialProperty}`, '');
        }
    };

    render() {
        const {
            attribute,
            customFields,
            deviceCF,
            distanceProperty,
            distanceCF,
            deviceProperty,
            formFields,
            measurement,
            updateProperty,
            customFieldsDef,
            qualifierProperty,
            readonly,
            serialProperty,
            valueCF,
            qualifierCF,
            valueProperty,
            serialCF,
        } = this.props;
        const path = `${measurement.jobNumber}.`;

        return (
            <>
                <div style={this.mainDivStyle}>
                    <div>
                        <EAMSelect
                            formFields={formFields}
                            onChangeValue={this.onDeviceChange}
                            style={this.deviceStyle}
                            elementInfo={{
                                ...customFields[deviceCF],
                                readonly,
                                attribute,
                            }}
                            value={measurement[deviceProperty]}
                            updateProperty={updateProperty}
                            valueKey={path + deviceProperty}
                            values={Tools.getCustomFieldsCodeDesc(customFieldsDef[deviceCF])}
                        />
                    </div>

                    {measurement[deviceProperty] && (
                        <div style={this.restStyle}>
                            {distanceProperty ? (
                                <div style={{ marginLeft: 5, marginRight: 5 }}>
                                    <EAMSelect
                                        formFields={formFields}
                                        style={this.distanceStyle}
                                        elementInfo={{
                                            ...customFields[distanceCF],
                                            text: '',
                                            readonly,
                                            attribute: 'R',
                                        }}
                                        value={measurement[distanceProperty]}
                                        updateProperty={updateProperty}
                                        valueKey={path + distanceProperty}
                                        values={Tools.getCustomFieldsCodeDesc(customFieldsDef[distanceCF])}
                                    />
                                </div>
                            ) : (
                                <div style={{ width: 105 }} />
                            )}

                            <TRECPrefixInput
                                formFields={formFields}
                                codes={customFieldsDef[qualifierCF].pcode}
                                elementInfo={{
                                    ...customFields[valueCF],
                                    text: '',
                                    attribute: 'R',
                                    readonly,
                                }}
                                updateProperty={updateProperty}
                                value={measurement[valueProperty]}
                                valueKey={path + valueProperty}
                                prefixValue={measurement[qualifierProperty]}
                                prefixValueKey={`${measurement.jobNumber}.${qualifierProperty}`}
                            />
                        </div>
                    )}
                </div>
                {/* Serial if provided */}
                {serialProperty && measurement[deviceProperty] && (
                    <EAMInput
                        formFields={formFields}
                        elementInfo={{
                            ...customFields[serialCF],
                            readonly,
                        }}
                        value={measurement[serialProperty]}
                        updateProperty={updateProperty}
                        valueKey={path + serialProperty}
                    />
                )}
            </>
        );
    }
}

MeasurementLine.defaultProps = {
    attribute: 'O',
};

export default MeasurementLine;
