import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { VisualEngine } from 'amm-tools';
import { format } from 'date-fns';
import { AssetIcon, RadioactiveWarningIcon } from 'eam-components/dist/ui/components/icons/index';
import { DATE_FORMAT } from 'enums/Constants';
import React from 'react';
import { generateRoute, getInternalLink, ROUTES, withQueryParams } from '../../../tools/Routes';
import EquipmentHazardContainer from '../../components/eqphazard/EquipmentHazardContainer';
import { isContainer } from '../rwprocessing/RWProcessingTools';
import { NODE_TYPES } from './Constants';

const iconStyle = { width: '45px', height: '35px', textAlign: 'center', marginTop: '2px' };
const smallIconStyle = { width: '24px', height: '24px', textAlign: 'center' };
const getNodesWrapperStyle = (node) => ({
    backgroundColor: node.focus ? '#ffc53d' : undefined,
    color: node.equipmentInfo.outOfService === '+' ? '#808080' : undefined,
    pointerEvents: node.equipmentInfo.outOfService === '+' ? 'none' : undefined,
    display: 'flex',
    height: 'fitContent',
});
const equipmentNodeStyle = { minWidth: '260px', maxWidth: '350px', display: 'flex', alignItems: 'center' };
const rpMeasurementStyle = {
    paddingLeft: '5px',
    width: '200px',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
};
const nodeAttributeStyle = { whiteSpace: 'normal' };

const EquipmentTree = (props) => {
    const { height, tree, width, nodeRenderer, extraProps } = props;
    const areaWidth = width || 1000;
    const areaHeight = height || 1000;

    const methods = {
        nodeRenderer:
            nodeRenderer ||
            (({ node }) => {
                switch (node.type) {
                    case NODE_TYPES.ASSET:
                        return (
                            <div style={getNodesWrapperStyle(node)}>
                                {getInternalLink({
                                    route: generateRoute(ROUTES.equipmentShow, { equipmentID: node.id }),
                                    style: { textDecoration: 'none', color: 'inherit', ...equipmentNodeStyle },
                                    text: (
                                        <div style={equipmentNodeStyle}>
                                            {isContainer(node.equipmentInfo) ? (
                                                <div style={iconStyle}>
                                                    <i
                                                        className="fa fa-archive"
                                                        style={{ ...iconStyle, alignSelf: 'center', fontSize: '30px' }}
                                                    />
                                                </div>
                                            ) : (
                                                <AssetIcon style={iconStyle} />
                                            )}
                                            <div style={{ display: 'inline-grid', padding: '4px' }}>
                                                <span style={{ ...nodeAttributeStyle, marginTop: '4px' }}>
                                                    {node.id}
                                                </span>
                                                <span
                                                    style={{
                                                        ...nodeAttributeStyle,
                                                        lineHeight: '1em',
                                                        minHeight: '1em',
                                                        maxHeight: '3em',
                                                        fontWeight: '400',
                                                    }}
                                                >
                                                    {node.desc}
                                                </span>
                                            </div>
                                            <EquipmentHazardContainer
                                                classification={node.equipmentInfo.rpClassification}
                                                hazards={node.equipmentInfo.objectHazards}
                                            />
                                        </div>
                                    ),
                                })}
                                {node.lastRpJob &&
                                    getInternalLink({
                                        route: withQueryParams({
                                            path: generateRoute(ROUTES.workOrdersRedirect, {
                                                workOrderID: node.lastRpJob.jobNumber,
                                            }),
                                        }),
                                        text:
                                            node.lastRpJob.classCode === 'RP01' &&
                                            Object.keys(node.lastRpJob.measurementLines).length === 0 ? (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        direction: 'column',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            textAlign: 'center',
                                                            maxWidth: '190px',
                                                            fontSize: '10px',
                                                            wordBreak: 'keep-all',
                                                            overflowWrap: 'break-word',
                                                            whiteSpace: 'wrap',
                                                        }}
                                                    >
                                                        {node.lastRpJob.description}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={rpMeasurementStyle}>
                                                    <div style={iconStyle}>
                                                        <i
                                                            className={
                                                                node.lastRpJob.classCode &&
                                                                node.lastRpJob.classCode.startsWith('RPAM_')
                                                                    ? 'fa fa-flask fa-2x'
                                                                    : 'fa fa-tachometer fa-2x'
                                                            }
                                                            aria-hidden="true"
                                                            style={{
                                                                ...smallIconStyle,
                                                                alignSelf: 'center',
                                                                fontSize: '25px',
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ display: 'inline-grid' }}>
                                                        <span style={nodeAttributeStyle}>
                                                            {node.lastRpJob.jobNumber}
                                                        </span>
                                                        <span style={{ fontWeight: '300', ...nodeAttributeStyle }}>
                                                            {format(
                                                                new Date(node.lastRpJob.completedDate),
                                                                DATE_FORMAT.DATE_FNS_SHORT
                                                            )}
                                                        </span>
                                                    </div>
                                                    {node.lastRpJob.rpClassification === 'NONRADIOACTIVE' && (
                                                        <CheckCircleIcon
                                                            style={{ ...smallIconStyle, color: 'green' }}
                                                        />
                                                    )}
                                                    {node.lastRpJob.rpClassification === 'RADIOACTIVE' && (
                                                        <RadioactiveWarningIcon style={{ smallIconStyle }} />
                                                    )}
                                                    {node.lastRpJob.eqCode !== node.eqCode && (
                                                        <div style={{ display: 'inline-grid' }}>
                                                            <span style={{ fontSize: '10px', fontWeight: '400' }}>
                                                                Inherited:
                                                            </span>
                                                            <span style={{ fontSize: '10px' }}>
                                                                {node.lastRpJob.eqCode}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            ),
                                        style: rpMeasurementStyle,
                                    })}
                            </div>
                        );
                    default:
                        return (
                            <div
                                style={{
                                    width: '100px',
                                    height: '50px',
                                    backgroundColor: 'red',
                                }}
                            >
                                {node.id}
                            </div>
                        );
                }
            }),
        onNodeClickHandler: () => undefined,
        canDrop: () => false,
    };

    const { GenerateVisualization } = VisualEngine;

    let data = [];
    try {
        data = JSON.parse(JSON.stringify(tree));
    } catch (err) {
        console.error(err);
    }

    return (
        <div style={{ width: '100%', display: 'flex', flex: '1 0 auto', overflow: 'auto' }}>
            {tree && data?.nodes?.length ? (
                <GenerateVisualization
                    {...{
                        type: VisualEngine.GRAPH_TYPES.VIRTUAL_TREE,
                        data,
                        methods,
                        extraProps,
                        RenderArea: ({ visualization: Visualization }) => (
                            <div style={{ width: `${areaWidth}px`, height: `${areaHeight}px` }}>
                                <Visualization />
                            </div>
                        ),
                    }}
                />
            ) : null}
        </div>
    );
};

export default EquipmentTree;
