import React from 'react';
import Tools from 'tools/Tools';
import { TRANSLATION_KEY } from './ui/pages/vaccleanermanage/Containerize';
/**
 * Footer of the application
 * We can safely use dangerouslySetInnerHTML as the data is only set by admnistrators on Infor
 */
/* eslint-disable react/no-danger */

export default function TRECFooter(props) {
    const { applicationData, screenData, layout } = props;
    const getTranslation = Tools.getTranslation({ screenData, lang: layout.lang, translationKey: TRANSLATION_KEY });
    const vacCleanerContact =
        layout.currentPage === 'VAC_CLEANER_MANAGE'
            ? { contact: getTranslation('VAC_CONTACT'), email: 'safety-rp-vacuum-cleaners@cern.ch' }
            : { contact: '', email: '' };

    return (
        <div
            dangerouslySetInnerHTML={{
                __html: `
      <b>EAM TREC</b> (<a style="color: white;" href="/releasenotes">v${applicationData.version} - ${applicationData.environment}</a>)
      <a style="margin-left: 10px; color: white;" href="mailto:${applicationData.mailSupport}">${applicationData.mailSupport}</a>
      <span>${applicationData.contactData}</span>
      <span>${applicationData.contactDataSupp}</span>
      <span>${vacCleanerContact.contact}</span>
      <a style="margin-left: 10px; color: white;" href="mailto:${vacCleanerContact.email}">
      ${vacCleanerContact.email}</a>
    `,
            }}
        />
    );
}
