import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TRECIcon } from 'eam-components/dist/ui/components/icons/index';
import MenuTypes from '../../../enums/MenuTypes';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        margin: '10px',
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        margin: '3px',
        alignItems: 'center',
        textAlign: 'center',
        width: '120px',
    },
};

const iconStyle = {
    fontSize: '25px',
    color: '#000000',
};

function MenuModeSelection(props) {
    const { classes, getTranslation, type, update } = props;
    return (
        <div className={classes.container}>
            <div
                className={classes.item}
                onClick={() => update(MenuTypes.STANDARD)}
                onKeyDown={() => update(MenuTypes.STANDARD)}
                style={type === MenuTypes.BUFFER_ZONE ? { opacity: 0.5 } : {}}
            >
                <TRECIcon icon="standard-menu" style={iconStyle} />
                {getTranslation('SM_MENU')}
            </div>
            <div
                className={classes.item}
                onClick={() => update(MenuTypes.BUFFER_ZONE)}
                onKeyDown={() => update(MenuTypes.BUFFER_ZONE)}
                style={type === MenuTypes.STANDARD ? { opacity: 0.5 } : {}}
            >
                <TRECIcon icon="fa fa-desktop" style={iconStyle} />
                {getTranslation('BZ_MENU')}
            </div>
        </div>
    );
}

export default withStyles(styles)(MenuModeSelection);
