export const RESET_WRR = 'RESET_WRR';
export const UPDATE_WRR_BLOCK_UI = 'UPDATE_WRR_BLOCK_UI';
export const UPDATE_WRR = 'UPDATE_WRR';
export const UPDATE_WRR_PARTIAL = 'UPDATE_WRR_PARTIAL';
export const UPDATE_CREATED_WRRS = 'UPDATE_CREATED_WRRS';

export const resetWRR = ({ userData } = {}) => ({
    type: RESET_WRR,
    value: { userData },
});

export const updateWRRBlockUI = (value) => ({
    type: UPDATE_WRR_BLOCK_UI,
    value,
});

export const updateWRRRequest = (value) => ({
    type: UPDATE_WRR,
    value,
});

export const updateWRRRequestPartial = (path, value) => ({
    type: UPDATE_WRR_PARTIAL,
    path,
    value,
});

export const updateCreatedWRRs = (value) => ({
    type: UPDATE_CREATED_WRRS,
    value,
});

export default {
    resetWRR,
    updateWRRBlockUI,
    updateWRRRequest,
    updateCreatedWRRs,
    updateWRRRequestPartial,
};
