/* eslint-disable no-underscore-dangle */

import React, { useMemo } from 'react';
import { useFilters, useSortBy, useTable } from 'react-table';
import { AUDIT_KEYS, EVENT_KEYS, HISTORY_ENTRY_TYPES, HISTORY_KEYS, EVENT_TYPES } from './Constants';
import { generateRoute, getInternalLink, getExternalLink, ROUTES } from '../../../../tools/Routes';
import EQPageTableWrapper from './EQPageTableWrapper';
import { CreatableSelectFilter, multiSelectFilter } from './eqPageTableUtils';

const convertEventEntry = (entry) => ({
    [HISTORY_KEYS._TYPE]: HISTORY_ENTRY_TYPES.EVENT,
    [HISTORY_KEYS._RAWDATA]: entry,
    [HISTORY_KEYS.ENTRY_TYPE]: entry[EVENT_KEYS.GROUPID],
    [HISTORY_KEYS.ENTRY_VALUE]: entry[EVENT_KEYS.CODE],
    [HISTORY_KEYS.DESCRIPTION]: entry[EVENT_KEYS.EVT_DESC],
    [HISTORY_KEYS.ENTRY_DATE]: entry[EVENT_KEYS.EVT_COMPLETED],
    [HISTORY_KEYS.ENTRY_USER]: entry[EVENT_KEYS.PERFORMED_BY_LOGIN],
    [HISTORY_KEYS.ORDERED]: entry[EVENT_KEYS.ORDERED],
});

const convertAuditEntry = (entry) => ({
    [HISTORY_KEYS._TYPE]: HISTORY_ENTRY_TYPES.AUDIT,
    [HISTORY_KEYS._RAWDATA]: entry,
    [HISTORY_KEYS.DESCRIPTION]: entry[AUDIT_KEYS.AAT_TEXT],
    [HISTORY_KEYS.ENTRY_TYPE]: HISTORY_ENTRY_TYPES.AUDIT,
    [HISTORY_KEYS.ENTRY_VALUE]: entry[AUDIT_KEYS.AVA_TO],
    [HISTORY_KEYS.ENTRY_DATE]: entry[AUDIT_KEYS.AVA_CHANGED],
    [HISTORY_KEYS.ENTRY_USER]: entry[AUDIT_KEYS.AVA_MODIFIEDBY],
    [HISTORY_KEYS.ORDERED]: entry[AUDIT_KEYS.ORDERED],
});

const convert = ({ eventData, auditData }) => [
    ...eventData.map((entry) => convertEventEntry(entry)),
    ...auditData.map((entry) => convertAuditEntry(entry)),
];

const customCellRenderer =
    ({ getTranslation, applicationData }) =>
    (props) => {
        const { column, value, row } = props;

        const defaultRender = (val) => (val ? String(val) : '');

        switch (column.id) {
            case HISTORY_KEYS.ENTRY_VALUE:
                if (row.original[HISTORY_KEYS._TYPE] === HISTORY_ENTRY_TYPES.EVENT) {
                    if ([EVENT_TYPES.STRUCT, EVENT_TYPES.SAMPLING].includes(row.original[HISTORY_KEYS.ENTRY_TYPE])) {
                        return getInternalLink({
                            route: generateRoute(ROUTES.equipmentShow, { equipmentID: value }),
                            text: value,
                        });
                    }
                    if (EVENT_TYPES.EDH_DOC === row.original[HISTORY_KEYS.ENTRY_TYPE]) {
                        return getExternalLink({
                            url: applicationData.edhurl + value,
                            text: value,
                        });
                    }
                    return getInternalLink({
                        route: generateRoute(ROUTES.workOrdersRedirect, { workOrderID: value }),
                        text: value,
                    });
                }
                return defaultRender(value);
            case HISTORY_KEYS.ENTRY_TYPE:
                return defaultRender(getTranslation(value));
            default:
                return defaultRender(value);
        }
    };

const columnKeys = [
    HISTORY_KEYS.ENTRY_TYPE,
    HISTORY_KEYS.ENTRY_DATE,
    HISTORY_KEYS.ENTRY_VALUE,
    HISTORY_KEYS.DESCRIPTION,
    HISTORY_KEYS.ENTRY_USER,
    HISTORY_KEYS.ORDERED,
];

const EquipmentHistory = (props) => {
    const { eventData, auditData, getTranslation, applicationData } = props;

    const data = useMemo(
        () =>
            convert({ eventData, auditData }).sort(
                (rowA, rowB) => rowA[HISTORY_KEYS.ENTRY_DATE] < rowB[HISTORY_KEYS.ENTRY_DATE]
            ),
        [eventData, auditData]
    );

    const columns = useMemo(
        () =>
            columnKeys.map((key) => ({
                Header: getTranslation(key),
                accessor: key,
                Cell: customCellRenderer({ getTranslation, applicationData }),
                Filter: (filterProps) => CreatableSelectFilter({ ...filterProps, getTranslation }),
                filter: multiSelectFilter,
            })),
        [columnKeys, getTranslation]
    );

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: [
                    {
                        id: HISTORY_KEYS.ENTRY_DATE,
                        desc: true,
                    },
                ],
            },
        },
        useFilters,
        useSortBy
    );

    return (
        <EQPageTableWrapper
            tableInstance={tableInstance}
            getTranslation={getTranslation}
            getRowProps={(row) => ({
                style: {
                    background: row.original[HISTORY_KEYS._TYPE] === HISTORY_ENTRY_TYPES.EVENT ? '#ecf5ff' : 'white',
                },
            })}
        />
    );
};

export default EquipmentHistory;
