import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { generateRoute, ROUTES, withQueryParams } from 'tools/Routes';
import { getRowAsAnObject } from 'ui/pages/rwmanagement/RWManagementUtils';
import TRECPage from 'ui/pages/TRECPage';
import EAMGridNoOverflow from '../../components/grid/EAMGridNoOverflow';
import gridNoOverflowClasses from '../../components/grid/EAMGridNoOverflow.module.css';
import { ENTITY_TRANSL_PREFIX, WRR_STATUS } from '../wrrequest/WRRConstants';

const CELL_TYPES = {
    WRREQUEST_CODE: 'evt_code',
    EQUIPMENT: 'evt_object',
    RPANALYSIS: 'wrr_rpar_code',
    RPMEASUREMENT: 'wrr_rpm_code',
    WRR_SAMPLE: 'wrr_sample',
    DECISION: 'decision',
    STATUS: 'evt_status',
    COMMENTS: 'comments',
};

const TRANSLATION_KEYS = {
    STATUS_DESC: 'STATUS_DESC',
};

export const TranslatedDiv = ({ getTranslation, code }) => (
    <>
        <div
            style={{
                textAlign: 'center',
                wordBreak: 'normal',
            }}
        >
            {getTranslation(code)}
        </div>
    </>
);

const getCellRenderer =
    ({ getTranslation }) =>
    ({ t, value }, row) => {
        const object = getRowAsAnObject(row);
        if (t === CELL_TYPES.WRREQUEST_CODE) {
            return <Link to={generateRoute(ROUTES.waterRelease, { waterReleaseNumber: value })}>{value}</Link>;
        }
        if (t === CELL_TYPES.EQUIPMENT || t === CELL_TYPES.WRR_SAMPLE) {
            return (
                <Typography>
                    <a target="_blank" href={`${process.env.REACT_APP_FRONTEND}equipment/${value}`} rel="noreferrer">
                        {value}
                    </a>
                </Typography>
            );
        }
        if (t === CELL_TYPES.RPANALYSIS) {
            return value && <Link to={generateRoute(ROUTES.rpamRequestEdit, { requestwonumber: value })}>{value}</Link>;
        }
        if (t === CELL_TYPES.DECISION) {
            const wrrStatus = object[CELL_TYPES.STATUS];
            return [WRR_STATUS.APPROVED, WRR_STATUS.RELEASED].includes(wrrStatus)
                ? 'APPROVE'
                : wrrStatus === WRR_STATUS.NOT_APPROVED && 'REJECT';
        }
        if (t === CELL_TYPES.COMMENTS) {
            const comments = value?.split(/\r?\n/);
            return comments?.map((comment) => <Typography>{comment}</Typography>);
        }
        if (t === CELL_TYPES.RPMEASUREMENT) {
            return (
                value && (
                    <Link to={withQueryParams({ path: ROUTES.rpmPerform, queryParams: { wo: value } })}>{value}</Link>
                )
            );
        }
        if (t === TRANSLATION_KEYS.STATUS_DESC) {
            return (
                <TranslatedDiv
                    getTranslation={getTranslation}
                    code={ENTITY_TRANSL_PREFIX.EVT_STATUS + object[CELL_TYPES.STATUS]}
                />
            );
        }
        return null;
    };

const getGridRequestAdapter =
    ({ writeAccess, userData, deposit }) =>
    (gridRequest) => {
        let gridFilters = [...gridRequest.gridFilter];
        // People with no write access can only see their requests
        if (!writeAccess) {
            gridFilters = gridFilters.filter((f) => !['evt_createdby', 'evt_origin'].includes(f.fieldName));
            gridFilters.push({
                fieldName: 'evt_createdby',
                fieldValue: userData.eamAccount.userCode,
                operator: 'EQUALS',
                joiner: 'OR',
                leftParenthesis: true,
            });
            gridFilters.push({
                fieldName: 'evt_origin',
                fieldValue: userData.eamAccount.employeeCode,
                operator: 'EQUALS',
                joiner: 'AND',
                rightParenthesis: true,
            });
        }

        // In deposit mode
        if (deposit) {
            gridFilters = gridFilters.filter((f) => !['evt_status', 'wrr_sample'].includes(f.fieldName));
            gridFilters.push({
                fieldName: 'evt_status',
                fieldValue: 'RS',
                operator: 'BEGINS',
                joiner: 'AND',
            });
            gridFilters.push({
                fieldName: 'wrr_sample',
                fieldValue: '',
                operator: 'IS_EMPTY',
                joiner: 'AND',
            });
        }

        return {
            ...gridRequest,
            gridFilter: gridFilters,
        };
    };

const redirectTo = (history, gridRows) => {
    const linkParameter = 'evt_code';
    if (gridRows.length === 1) {
        const cell = gridRows[0].cell.find((c) => c.t === linkParameter);
        const waterReleaseNumber = cell.value;
        history.push(generateRoute(ROUTES.waterRelease, { waterReleaseNumber }));
    }
};

const WaterReleaseSearch = ({ deposit, userData, writeAccess, applicationData, getTranslation, history }) => {
    const { gridWaterReleaseSearchGridID } = applicationData;

    const extraColumns = [
        {
            width: '150px',
            t: TRANSLATION_KEYS.STATUS_DESC,
            headerLabel: getTranslation(TRANSLATION_KEYS.STATUS_DESC),
        },
    ];

    return (
        <div className={gridNoOverflowClasses.outerBlock}>
            <EAMGridNoOverflow
                gridId={gridWaterReleaseSearchGridID}
                gridRequestAdapter={getGridRequestAdapter({ writeAccess, userData, deposit })}
                extraColumns={extraColumns}
                bypassAlternateRows
                filterVisible
                cellRenderer={getCellRenderer({ applicationData, getTranslation })}
                onLoad={(gridRows) => deposit && redirectTo(history, gridRows)}
                heightFilterVisible="245px"
                heightFilterNotVisible="195px"
            />
        </div>
    );
};

class WaterReleaseSearchClass extends TRECPage {
    renderPage(writeAccess) {
        return <WaterReleaseSearch writeAccess={writeAccess} {...this.props} />;
    }
}

export default WaterReleaseSearchClass;
