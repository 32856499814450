import Cancel from '@material-ui/icons/Cancel';
import Save from '@material-ui/icons/Save';
import { EQUIPMENT_KEYS, TABLE_DATA_TYPES } from 'enums/Constants';
import { useState } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import WSRWManagement from 'tools/rest/WSRWManagement';
import TRECButton from 'ui/components/buttons/TRECButton';
import { ATTRIBUTES, generate } from 'ui/pages/rpmrequest2/InputGenerator';
import { CUTG10_FIELDS } from './RWManagementUtils';
import { TRANSLATION_KEYS } from '../vaccleanerrequest/VacCleanerConstants';
import { handleError, showSuccess } from 'tools/TrecNotifications';

const AFFECTED_EQUIPMENT = 'AFFECTED_EQUIPMENT';
const OPERATION = 'operation';

const getHazardFields = ({ hazardsObj, applicationData, getTranslation, rwpDropdowns, objects }) => [
    {
        code: AFFECTED_EQUIPMENT,
        type: TABLE_DATA_TYPES.INPUT,
        elementInfo: {
            text: getTranslation(AFFECTED_EQUIPMENT),
        },
        customValue: () => objects.map((c) => c[CUTG10_FIELDS.OBJ_CODE]).join(', '),
        getAttribute: () => ATTRIBUTES.READONLY,
    },
    {
        code: OPERATION,
        type: TABLE_DATA_TYPES.SELECT,
        elementInfo: {
            text: getTranslation('OPERATION'),
        },
        values: ['ADD', 'REMOVE'].map((code) => ({ code, desc: getTranslation(code) })),
        getAttribute: () => ATTRIBUTES.REQUIRED,
    },
    {
        code: EQUIPMENT_KEYS.HAZARD_CODES,
        type: TABLE_DATA_TYPES.SELECT,
        elementInfo: {
            text: getTranslation(TRANSLATION_KEYS.HAZARD_CODES),
        },
        customInputProps: () => ({
            multi: true,
            searchable: true,
        }),
        getAttribute: () => ATTRIBUTES.REQUIRED,
        values: rwpDropdowns.hazard,
    },
    {
        code: EQUIPMENT_KEYS.HAZARD_COMMENT,
        type: TABLE_DATA_TYPES.INPUT,
        elementInfo: {
            text: getTranslation(TRANSLATION_KEYS.HAZARD_COMMENT),
        },
        getAttribute: (equipment) =>
            !equipment[EQUIPMENT_KEYS.HAZARD_CODES]?.some((haz) => haz === applicationData.otherHazardCode)
                ? ATTRIBUTES.HIDDEN
                : hazardsObj[OPERATION] === 'ADD'
                ? ATTRIBUTES.REQUIRED
                : ATTRIBUTES.OPTIONAL,
    },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onSave = ({ refs, hazardsObj, objects, cancel, getTranslation, setLoading, runSearch }) => {
    const canContinue = Object.values(refs).reduce(
        (acc, field) => (!field || !field.validate || field.validate()) && acc,
        true
    );
    if (!canContinue) return;

    const wsCall = hazardsObj[OPERATION] === 'ADD' ? WSRWManagement.addHazards : WSRWManagement.removeHazards;
    const dto = {
        [EQUIPMENT_KEYS.HAZARD_CODES]: hazardsObj[EQUIPMENT_KEYS.HAZARD_CODES],
        [EQUIPMENT_KEYS.HAZARD_COMMENT]: hazardsObj[EQUIPMENT_KEYS.HAZARD_COMMENT],
        equipmentCodes: objects.map((c) => c[CUTG10_FIELDS.OBJ_CODE]),
    };

    const asyncCall = async () => {
        try {
            setLoading(true);
            const resp = await wsCall(dto);
            showSuccess(
                `${getTranslation('UPDATED_HAZARDS')}${Object.values(resp).reduce((acc, val) => acc + val, 0)}`
            );
            // Not closing to make it easier to TEST. Also, it is idempotent
            cancel();
            // Refresh grid results
            runSearch();
        } catch (err) {
            handleError(err);
        }
        setLoading(false);
    };
    asyncCall();
};

const RWManagementHazards = ({
    translations,
    cancel,
    selectedEquipment,
    runSearch,
    rwpDropdowns,
    applicationData,
    getTranslation,
    setLoading,
}) => {
    const [hazardsObj, setHazardsObj] = useState({});
    const [refs] = useState({});

    const objects = Object.values(selectedEquipment);

    const fields = getHazardFields({ applicationData, getTranslation, rwpDropdowns, objects, hazardsObj });

    const renderFields = fields.reduce(
        (acc, field) => ({
            ...acc,
            [field.code]: (
                <div key={field.code}>
                    {generate({
                        field: {
                            ...field,
                            elementInfo: {
                                ...field.elementInfo,
                                xpath: `${field.elementInfo?.xpath || field.code}`,
                            },
                            getAttribute: (object) => {
                                const attr = field.getAttribute && field.getAttribute(object);
                                return attr;
                            },
                        },
                        object: hazardsObj,
                        updateObject: (key, val) => setHazardsObj({ ...hazardsObj, [key]: val }),
                        formFields: refs,
                    })}
                </div>
            ),
        }),
        {}
    );

    return (
        <EISPanel heading={translations.HAZARDS} alwaysExpanded>
            <div style={{ width: '100%' }}>
                {Object.values(renderFields)}
                {hazardsObj[OPERATION] === 'REMOVE' && getTranslation(translations.HAZARD_EDIT_NOTE)}
                <div style={{ textAlign: 'right' }}>
                    <TRECButton color="secondary" style={{ marginRight: 10 }} onClick={cancel} size="small">
                        <Cancel />
                        {translations.CANCEL}
                    </TRECButton>
                    <TRECButton
                        onClick={() =>
                            onSave({
                                setLoading,
                                refs,
                                hazardsObj,
                                objects,
                                cancel,
                                getTranslation,
                                runSearch,
                            })
                        }
                        size="small"
                    >
                        <Save />
                        {translations.SAVE}
                    </TRECButton>
                </div>
            </div>
        </EISPanel>
    );
};

export default RWManagementHazards;
