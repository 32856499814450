import WS from './WS';
import { EQUIPMENT_DTO_KEYLIST, SAMPLE_DTO_KEYLIST } from '../../enums/Constants';

const NS = '/rpsm';

const filterUpdateEquipment = (equipment) =>
    [...EQUIPMENT_DTO_KEYLIST, ...SAMPLE_DTO_KEYLIST].reduce(
        (acc, key) => ({
            ...acc,
            [key]: equipment[key],
        }),
        {}
    );

class WSRPSampling {
    getSample(id, config = {}) {
        return WS._get(`${NS}/sample/${id}`, config);
    }

    updateEquipment(equipmentMap, config = {}) {
        const filteredEquipmentMap = Object.entries(equipmentMap).reduce(
            (acc, [code, eq]) => ({
                ...acc,
                [code]: filterUpdateEquipment(eq),
            }),
            {}
        );
        return WS._put(`${NS}/sample/`, filteredEquipmentMap, config);
    }

    getSamples(code, config = {}) {
        return WS._get(`${NS}/sample/?code=${code}`, config);
    }

    getNewSamples = async ({ hint }, config = {}) => {
        const result = await WS._get(`${NS}/sample/?code=${hint}&newOnly=true`, config);
        return result.body.data;
    };
}

export default new WSRPSampling();
