import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Edit from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import Call from '@material-ui/icons/Call';
import CommentsContainer from 'eam-components/dist/ui/components/comments/CommentsContainer';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import EAMDatePicker from 'eam-components/dist/ui/components/inputs/EAMDatePicker';
import EAMDateTimePicker from 'eam-components/dist/ui/components/inputs/EAMDateTimePicker';
import EAMFormLabel from 'eam-components/dist/ui/components/inputs/EAMFormLabel';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import {
    ANALYSIS_KEYS,
    ANALYSIS_TYPES,
    CLASSIFICATION_REQUESTED_TYPES,
    EQUIPMENT_DTO_KEYLIST,
    EQUIPMENT_KEYS,
    RP_MEASUREMENT_KEYS,
    RPA_NEW_FIELDS_PROD,
    RPAR_PERFORM_KEYS,
    RPAR_REQUEST_KEYS,
    TREC_GENERAL_KEYS,
} from 'enums/Constants';
import BlockUi from 'react-block-ui';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import { Link } from 'react-router-dom';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import WSComments from 'tools/rest/WSComments';
import WSRPARequest from 'tools/rest/WSRPARequest';
import { generateRoute, getInternalLink, ROUTES } from 'tools/Routes';
import Tools from 'tools/Tools';
import ValuesManager from 'tools/ValuesManager';
import TRECButton from '../../components/buttons/TRECButton';
import EDMSDoclightIframeContainer from '../../components/iframes/EDMSDoclightIframeContainer';
import TRECPage from '../TRECPage';
import { ENTITY_TRANSL_PREFIX } from '../wrrequest/WRRConstants';
import { handleError, showError, showSuccess } from 'tools/TrecNotifications';
import { WarningText } from '../wrrequest/WRRHelpers';

const NUMBER_REGEX = /^([.]\\d+|\\d+[.]?\\d*)$/;

const styles = {
    analysisJobSection: {
        height: '100%',
        display: 'flex',
        marginLeft: '-10px',
        marginRight: '-10px',
    },
    toolbarSection: {
        backgroundColor: '#fafafa',
        borderBottom: '1px solid #eeeeee',
        padding: '1em',
        zIndex: '2',
    },
    contentSection: {
        padding: '14px',
        height: 'calc(100% - 120px)',
        overflow: 'hidden',
        overflowY: 'auto',
    },
    analysisJobMain: {
        flex: '1',
        width: '100%',
    },
    rpClassificationField: {
        minWidth: '200px',
    },
};

class RPAnalysisJob extends TRECPage {
    rpMeasurementTableColumns = [
        RP_MEASUREMENT_KEYS.RPA_MEASUREMENT,
        EQUIPMENT_KEYS.EQUIPMENT_CODE,
        RP_MEASUREMENT_KEYS.RP_CLASSIFICATION,
        RP_MEASUREMENT_KEYS.CONTAMINATION,
        RP_MEASUREMENT_KEYS.MEASURE_CONTACT,
        RP_MEASUREMENT_KEYS.MEASURE10CM,
        RP_MEASUREMENT_KEYS.MEASURE_BGO,
        RP_MEASUREMENT_KEYS.MEASURE_ALPHA,
        RP_MEASUREMENT_KEYS.MEASURE_BETA,
    ];

    analysisTableColumns() {
        return [
            RPAR_PERFORM_KEYS.REPORTWO,
            ANALYSIS_KEYS.EQUIPMENT_CODE,
            ANALYSIS_KEYS.RP_CLASSIFICATION,
            ANALYSIS_KEYS.LAST_IRRADIATION,
            ANALYSIS_KEYS.ACTIVE_UNIT,
            ANALYSIS_KEYS.MEASUREMENT_COMMENTS,
            ANALYSIS_KEYS.OFFICIAL_REPORT,
            // ANALYSIS_KEYS.REQUEST_LL,
            ANALYSIS_KEYS.CLASSIFICATION_REQUESTED,
            ...((RPA_NEW_FIELDS_PROD || this.props.applicationData.environment !== 'PROD') && this.isParallelJob()
                ? [ANALYSIS_KEYS.LL_VALUE, ANALYSIS_KEYS.LI_VALUE]
                : []),

            ANALYSIS_KEYS.EDH_GENERATION,
        ];
    }

    equipmentTableColumns(_, measuredActivityDateAttribute) {
        return [
            EQUIPMENT_KEYS.EQUIPMENT_CODE,
            EQUIPMENT_KEYS.EQUIPMENT_DESC,
            EQUIPMENT_KEYS.FACILITY,
            EQUIPMENT_KEYS.OBJ_FAMILY,
            EQUIPMENT_KEYS.RESP_TECHNIQUE_DESC,
            EQUIPMENT_KEYS.RPO,
            EQUIPMENT_KEYS.MATERIAL,
            EQUIPMENT_KEYS.RW_ELIMINATION_PATHWAY,
            EQUIPMENT_KEYS.IRRADIATION_END_DATE_MIN,
            EQUIPMENT_KEYS.IRRADIATION_END_DATE_MAX,
            ...((RPA_NEW_FIELDS_PROD || this.props.applicationData.environment !== 'PROD') &&
            measuredActivityDateAttribute !== 'H'
                ? [EQUIPMENT_KEYS.DECAY_PERIOD]
                : []),
        ];
    }

    formFields = [];

    state = {
        rpamValues: {},
        readOnly: false,
    };

    validate = () => {
        let isValid = true;
        const { getTranslation } = this.props;
        Object.keys(this.formFields).forEach((key) => {
            if (this.formFields[key] && this.formFields[key].validate && !this.formFields[key].validate()) {
                isValid = false;
                showError(getTranslation(TREC_GENERAL_KEYS.REQUIRED_ERROR_MESSAGE), null, 4000);
            }
        });
        return isValid && this.checkDecayPeriodRespected();
    };

    getValues = ({ key, object, values }) => {
        const { valuesGrid } = this.state;
        return ValuesManager.constrain({ key, object, valuesGrid, values });
    };

    componentDidMount() {
        Promise.all([WSRPARequest.getValuesGrid(), WSRPARequest.getValuesDef()]).then((respArr) => {
            const valuesDefMap = respArr[1].body.data.reduce(
                (acc, value) => ({
                    ...acc,
                    [value.field]: [
                        ...(acc[value.field] || []),
                        value.description
                            ? { code: value.value, desc: value.description }
                            : { code: value.value, desc: this.props.getTranslation(value.value) },
                    ],
                }),
                {}
            );
            const rpamValues = valuesDefMap;
            rpamValues.edmsContext = valuesDefMap.edmsContext.map((context) => ({ ...context, shouldLoad: false }));
            rpamValues.edmsContext[0].shouldLoad = true;
            this.setState({ rpamValues, valuesGrid: respArr[0].body.data });
        });

        const wo = this.props.match.params.jobnumber;
        if (wo !== this.state.jobNumber) {
            this.reload(wo);
        } else {
            this.setState({ loading: false });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { userData } = this.props;
        if (!this.state[RPAR_PERFORM_KEYS.DECAY_PERIOD] && this.isRWEliminationRP()) {
            const decayPeriod = Object.values(this.state.equipmentMap)
                ?.map((s) => s[RPAR_PERFORM_KEYS.DECAY_PERIOD])
                .find((s) => s);

            if (decayPeriod) {
                this.setState({ [RPAR_PERFORM_KEYS.DECAY_PERIOD]: decayPeriod });
            }
        }
        if (
            prevState[RPAR_PERFORM_KEYS.STATUS] !== this.state[RPAR_PERFORM_KEYS.STATUS] &&
            this.state[RPAR_PERFORM_KEYS.STATUS] === 'T'
        ) {
            this.setState((preState) => ({
                [RPAR_PERFORM_KEYS.PERFORMEDBY]:
                    preState[RPAR_PERFORM_KEYS.PERFORMEDBY] || userData.eamAccount.employeeCode,
                [RPAR_PERFORM_KEYS.PERFORMEDBYDESC]:
                    preState[RPAR_PERFORM_KEYS.PERFORMEDBYDESC] || userData.eamAccount.userCode,
            }));
        }
    }

    isJobDoneOrCancelled = () =>
        this.state[RPAR_PERFORM_KEYS.STATUS] === 'TX' || this.state[RPAR_PERFORM_KEYS.STATUS] === 'T';

    isReadOnly = () => this.isJobDoneOrCancelled();

    isRWEliminationRP = () => {
        const { equipmentMap } = this.state;
        const anaType = this.state[RPAR_PERFORM_KEYS.ANALYSIS_TYPE];

        const wasteWithElimination = Object.values(equipmentMap || {}).some(
            (eqp) => eqp[EQUIPMENT_KEYS.RW_ELIMINATION_PATHWAY]
        );
        const analysisType = [ANALYSIS_TYPES.GAMMASPEC, ANALYSIS_TYPES.RADOS].includes(anaType);
        return wasteWithElimination && analysisType;
    };

    isParallelJob = () =>
        this.props.applicationData.rpamanalysisTypesParallel.includes(this.state[RPAR_PERFORM_KEYS.ANALYSIS_TYPE]);

    reload = (wo) => {
        this.setState({ jobNumber: wo, loading: true });
        WSRPARequest.getRPAnalysisPerform(wo)
            .then((resp) => {
                const analysis = WSRPARequest.treatDateFromBE(resp.body.data);
                const equipmentMap = Tools.applyToFieldsKV(analysis.equipmentMap, (k, v) => ({
                    ...v,
                    ...analysis.equipmentPropertiesMap[k],
                }));
                const analysisProps = analysis[RPAR_PERFORM_KEYS.ANALYSIS];
                const updatedAnalysisProps = analysisProps.map((ap) => ({
                    ...ap,
                    [ANALYSIS_KEYS.RP_CLASSIFICATION]:
                        ap[ANALYSIS_KEYS.RP_CLASSIFICATION] ||
                        equipmentMap[ap[ANALYSIS_KEYS.EQUIPMENT_CODE]][ANALYSIS_KEYS.RP_CLASSIFICATION],
                }));
                this.setState({
                    ...analysis,
                    [RPAR_PERFORM_KEYS.ANALYSIS]: updatedAnalysisProps,
                    // [RPAR_PERFORM_KEYS.PERFORMEDBY]:
                    //     analysis[RPAR_PERFORM_KEYS.PERFORMEDBY] || userData.eamAccount.employeeCode,
                    // [RPAR_PERFORM_KEYS.PERFORMEDBYDESC]:
                    //     analysis[RPAR_PERFORM_KEYS.PERFORMEDBYDESC] || userData.eamAccount.userCode,
                    equipmentMap,
                });
                WSRPARequest.getAnalysisTypeCFMapping(analysis[RPAR_PERFORM_KEYS.ANALYSIS_TYPE]).then((response) => {
                    const analysisTypeCFMap = response.body.data;
                    this.setState({ analysisTypeCFMap, loading: false });
                });
                this.setState({
                    readOnly: this.isJobDoneOrCancelled(),
                });
            })
            .catch((err) => {
                handleError(err);
                this.setState({ loading: false });
            });
    };

    handleCreateComment = (comment) =>
        new Promise((resolve, reject) => {
            WSComments.createComment(comment)
                .then((resp) => {
                    resolve(resp);
                })
                .catch((error) => {
                    reject(error);
                });
        });

    handleUpdateComment = (comment) =>
        new Promise((resolve, reject) => {
            WSComments.updateComment(comment)
                .then((resp) => {
                    resolve(resp);
                })
                .catch((error) => {
                    reject(error);
                });
        });

    handleUpdateProperty = (key, value) => {
        this.setState({ [key]: value });
    };

    handleUpdatePropertyInsideAnArray = (stateCode, index, key, value, applyToAll) => {
        let stateArrayCopy;
        if (applyToAll) {
            stateArrayCopy = this.state[stateCode].map((line) => ({ ...line, [key]: value }));
        } else {
            stateArrayCopy = [...this.state[stateCode]];
            stateArrayCopy[index] = { ...stateArrayCopy[index], [key]: value };
        }
        this.setState({ [stateCode]: stateArrayCopy });
    };

    handleUpdatePropertyForAll = (stateCode, key, value) => {
        const stateArrayCopy = [...this.state[stateCode]];
        this.setState({ [stateCode]: stateArrayCopy.map((e) => ({ ...e, [key]: value })) });
    };

    handleUpdatePropertyTree = (stateCode, entryCode, key, value) => {
        Tools.updateState(this, stateCode, entryCode, key, value);
    };

    handleSave = () => {
        if (this.validate()) {
            const { jobNumber, rpamValues, loading, analysisTypeCFMap, valuesGrid, readOnly, propsApplyAll, ...other } =
                this.state;
            this.setState({ loading: true });
            WSRPARequest.updateRPAnalysisPerform(other.workorder, {
                ...other,
                equipmentMap: Tools.filterObjectFieldsFromList(other.equipmentMap, EQUIPMENT_DTO_KEYLIST),
            })
                .then((resp) => {
                    showSuccess('Analysis job successfully updated.');
                    this.reload(resp.body.data);
                    this.setState({ loading: false });
                })
                .catch((error) => {
                    this.setState({ loading: false });
                    handleError(error);
                });
        }
    };

    handleBack = () => {
        this.props.history.push(`/rpanalysis`);
    };

    getEamLightLink = (item, type) => (
        <a target="_blank" href={`${this.props.applicationData.eamlightLink}${type}/${item}`} rel="noreferrer">
            {item}
        </a>
    );

    getRPAMeasurementLink = (workorder) => (
        <Link to={`/rpmperform?wo=${workorder}`} target="_blank">
            {workorder}
        </Link>
    );

    getRequestLink = (workorder) => (
        <Link to={`/rparequest/edit/${workorder}`} target="_self">
            {workorder}
        </Link>
    );

    generateEquipmentTableData = (tableColumns, equipmentMap, readOnly, isJobDone) => {
        const { screenData, getTranslation, applicationData } = this.props;
        const { rpamValues } = this.state;
        return Object.keys(equipmentMap).map((eqCode, index) => {
            const equipment = equipmentMap[eqCode];
            const classificationRequestedList = this.state.analysisProps[index][ANALYSIS_KEYS.CLASSIFICATION_REQUESTED];
            return tableColumns.reduce((acc, column) => {
                switch (column.code) {
                    case RPAR_PERFORM_KEYS.REPORTWO:
                        acc[column.code] = this.getEamLightLink(equipment[column.code], 'workorder');
                        break;
                    case EQUIPMENT_KEYS.EQUIPMENT_CODE:
                        acc[column.code] = (
                            <Link to={`/equipment/${equipment[column.code]}`} target="_self">
                                {equipment[column.code]}
                            </Link>
                        );
                        break;
                    case ANALYSIS_KEYS.RP_CLASSIFICATION:
                        acc[column.code] = this.getRPClassificationEAMSelect(equipment[column.code], index, readOnly);
                        break;
                    case EQUIPMENT_KEYS.FACILITY: {
                        const facilityValue =
                            (rpamValues[EQUIPMENT_KEYS.FACILITY] &&
                                rpamValues[EQUIPMENT_KEYS.FACILITY].find(
                                    (e) => e && e.code === equipment[column.code]
                                )) ||
                            {};
                        acc[column.code] = facilityValue.desc || equipment[column.code];
                        break;
                    }
                    case EQUIPMENT_KEYS.OBJ_FAMILY:
                        acc[column.code] = (
                            screenData.lists.objFamily.find((element) => element.code === equipment[column.code]) || {}
                        ).desc;
                        break;
                    case EQUIPMENT_KEYS.MATERIAL:
                        acc[column.code] = (
                            screenData.lists.objMainMaterial.find(
                                (element) => element.code === equipment[column.code]
                            ) || {}
                        ).desc;
                        break;
                    case ANALYSIS_KEYS.LAST_IRRADIATION: {
                        const lastIrradiationTranslated = getTranslation(equipment[column.code] || '');
                        acc[column.code] = lastIrradiationTranslated || equipment[column.code];
                        break;
                    }
                    case EQUIPMENT_KEYS.RPO: {
                        const rpoCode = equipment[column.code];
                        const rpo = rpoCode && applicationData.rpos && applicationData.rpos[rpoCode];
                        acc[column.code] =
                            (rpo && [rpo.lastName, rpo.firstName, rpo.phoneNumber, rpo.cernID].join(' ')) || rpoCode;
                        break;
                    }
                    case EQUIPMENT_KEYS.RW_ELIMINATION_PATHWAY: {
                        const rwEliminationPathwayTranslated = getTranslation(equipment[column.code] || '');
                        acc[column.code] = rwEliminationPathwayTranslated || equipment[column.code];
                        break;
                    }
                    case EQUIPMENT_KEYS.DECAY_PERIOD: {
                        const decayPeriodTranslated = getTranslation(equipment[column.code] || '');
                        acc[column.code] = decayPeriodTranslated || equipment[column.code];
                        break;
                    }
                    case EQUIPMENT_KEYS.IRRADIATION_END_DATE_MIN:
                    case EQUIPMENT_KEYS.IRRADIATION_END_DATE_MAX: {
                        acc[column.code] = (
                            <EAMDatePicker
                                elementInfo={{ readonly: true }}
                                keyboard={false}
                                value={equipment[column.code]}
                            />
                        );
                        break;
                    }
                    case ANALYSIS_KEYS.LL_VALUE: {
                        acc[column.code] = (
                            <EAMInput
                                style={this.valueStyle}
                                elementInfo={{
                                    xpath: column.code,
                                    attribute: !classificationRequestedList.some((s) =>
                                        [
                                            CLASSIFICATION_REQUESTED_TYPES.CLR,
                                            CLASSIFICATION_REQUESTED_TYPES.H2O,
                                            CLASSIFICATION_REQUESTED_TYPES.CL,
                                        ].includes(s)
                                    )
                                        ? 'H'
                                        : isJobDone()
                                        ? 'R'
                                        : 'O',
                                }}
                                keyboard={false}
                                value={equipment[column.code] ?? ''}
                                updateProperty={(key, value) => {
                                    this.handleUpdatePropertyInsideAnArray(
                                        RPAR_PERFORM_KEYS.ANALYSIS,
                                        index,
                                        key,
                                        value,
                                        this.state.propsApplyAll
                                    );
                                }}
                                valueKey={column.code}
                                inputProps={this.inputProps}
                                formFields={this.formFields}
                            />
                        );
                        break;
                    }
                    case ANALYSIS_KEYS.LI_VALUE: {
                        acc[column.code] = (
                            <EAMInput
                                style={this.valueStyle}
                                elementInfo={{
                                    xpath: column.code,
                                    attribute: !classificationRequestedList.some((s) =>
                                        [
                                            CLASSIFICATION_REQUESTED_TYPES.CLR,
                                            CLASSIFICATION_REQUESTED_TYPES.H2O,
                                            CLASSIFICATION_REQUESTED_TYPES.CL,
                                        ].includes(s)
                                    )
                                        ? 'H'
                                        : isJobDone()
                                        ? 'R'
                                        : 'O',
                                }}
                                keyboard={false}
                                value={equipment[column.code] ?? ''}
                                updateProperty={(key, value) => {
                                    this.handleUpdatePropertyInsideAnArray(
                                        RPAR_PERFORM_KEYS.ANALYSIS,
                                        index,
                                        key,
                                        value,
                                        this.state.propsApplyAll
                                    );
                                }}
                                valueKey={column.code}
                                inputProps={this.inputProps}
                                formFields={this.formFields}
                            />
                        );
                        break;
                    }
                    case ANALYSIS_KEYS.OFFICIAL_REPORT: {
                        const comp = (
                            <EAMCheckbox
                                elementInfo={{
                                    xpath: `${eqCode}.${EQUIPMENT_KEYS.OFFICIAL_REPORT}`,
                                    readonly: true,
                                }}
                                value={equipment[column.code]}
                                trueValue="true"
                                falseValue="false"
                            />
                        );
                        acc[column.code] = comp;
                        break;
                    }
                    case ANALYSIS_KEYS.REQUEST_LL: {
                        const req = (
                            <EAMCheckbox
                                elementInfo={{
                                    xpath: `${eqCode}.${ANALYSIS_KEYS.REQUEST_LL}`,
                                    readonly: readOnly,
                                }}
                                value={equipment[column.code]}
                                valueKey={ANALYSIS_KEYS.REQUEST_LL}
                                trueValue
                                falseValue={false}
                                updateProperty={(key, value) => {
                                    this.handleUpdatePropertyInsideAnArray(
                                        RPAR_PERFORM_KEYS.ANALYSIS,
                                        index,
                                        key,
                                        value,
                                        this.state.propsApplyAll
                                    );
                                }}
                            />
                        );
                        acc[column.code] = req;
                        break;
                    }
                    case ANALYSIS_KEYS.CLASSIFICATION_REQUESTED:
                        if (
                            this.state[RPAR_PERFORM_KEYS.ANALYSIS].some(
                                (s) =>
                                    !s[ANALYSIS_KEYS.CLASSIFICATION_REQUESTED] ||
                                    s[ANALYSIS_KEYS.CLASSIFICATION_REQUESTED].length === 0
                            )
                        ) {
                            break;
                        }
                        acc[column.code] = (
                            <EAMSelect
                                elementInfo={{
                                    xpath: `${eqCode}.${column.code}`,
                                    readonly: readOnly,
                                }}
                                valueKey={column.code}
                                value={equipment[column.code]}
                                updateProperty={(key, val) => {
                                    const value = val[val.length - 1] === 'NO' ? ['NO'] : val.filter((v) => v !== 'NO');
                                    value &&
                                        value.length &&
                                        this.handleUpdatePropertyInsideAnArray(
                                            RPAR_PERFORM_KEYS.ANALYSIS,
                                            index,
                                            key,
                                            value,
                                            this.state.propsApplyAll
                                        );
                                }}
                                values={ValuesManager.constrain({
                                    valuesGrid: this.state.valuesGrid,
                                    key: ANALYSIS_KEYS.CLASSIFICATION_REQUESTED,
                                    object: {
                                        [RPAR_PERFORM_KEYS.ANALYSIS_TYPE]: this.state[RPAR_PERFORM_KEYS.ANALYSIS_TYPE],
                                    },
                                    values: this.props.dropdowns[ANALYSIS_KEYS.CLASSIFICATION_REQUESTED],
                                })}
                                multi
                            />
                        );
                        break;
                    case ANALYSIS_KEYS.WATER_ELIMINATION_PATHWAY:
                        if (
                            this.state[RPAR_PERFORM_KEYS.ANALYSIS].some(
                                (s) => s[ANALYSIS_KEYS.CLASSIFICATION_REQUESTED] !== CLASSIFICATION_REQUESTED_TYPES.H2O
                            )
                        ) {
                            break;
                        }
                        acc[column.code] = (
                            <EAMSelect
                                elementInfo={{
                                    xpath: `${eqCode}.${column.code}`,
                                    readonly: readOnly,
                                    attribute:
                                        !classificationRequestedList.includes(CLASSIFICATION_REQUESTED_TYPES.H2O) ||
                                        this.state[RPAR_PERFORM_KEYS.ANALYSIS_TYPE] !== ANALYSIS_TYPES.PHYSCHEM
                                            ? 'H'
                                            : isJobDone()
                                            ? 'R'
                                            : 'O',
                                }}
                                valueKey={column.code}
                                value={this.state[ANALYSIS_KEYS.WATER_ELIMINATION_PATHWAY]}
                                updateProperty={(key, val) => {
                                    val &&
                                        val.length &&
                                        this.handleUpdatePropertyInsideAnArray(
                                            RPAR_PERFORM_KEYS.ANALYSIS,
                                            index,
                                            key,
                                            val,
                                            this.state.propsApplyAll
                                        );
                                }}
                                values={ValuesManager.constrain({
                                    valuesGrid: this.state.valuesGrid,
                                    key: 'PHYSCHEM',
                                    object: {
                                        [RPAR_PERFORM_KEYS.ANALYSIS_TYPE]: this.state[RPAR_PERFORM_KEYS.ANALYSIS_TYPE],
                                    },
                                    values: this.props.dropdowns[ANALYSIS_KEYS.WATER_ELIMINATION_PATHWAY],
                                })}
                            />
                        );
                        break;
                    case ANALYSIS_KEYS.EDH_GENERATION:
                        acc[column.code] = (
                            <EAMCheckbox
                                elementInfo={{
                                    xpath: `${eqCode}.${ANALYSIS_KEYS.EDH_GENERATION}`,
                                    readonly: readOnly,
                                }}
                                valueKey={ANALYSIS_KEYS.EDH_GENERATION}
                                value={equipment[column.code]}
                                trueValue
                                falseValue={false}
                                updateProperty={(key, value) => {
                                    this.handleUpdatePropertyInsideAnArray(
                                        RPAR_PERFORM_KEYS.ANALYSIS,
                                        index,
                                        key,
                                        value,
                                        this.state.propsApplyAll
                                    );
                                }}
                            />
                        );
                        break;
                    default:
                        acc[column.code] = equipment[column.code];
                        break;
                }
                return acc;
            }, {});
        });
    };

    generateRPMeasurementTableData = (tableColumns, equipmentMap) =>
        Object.keys(equipmentMap).map((eqCode) => {
            const equipment = equipmentMap[eqCode];
            return tableColumns.reduce((acc, column) => {
                switch (column.code) {
                    case RP_MEASUREMENT_KEYS.RPA_MEASUREMENT:
                        acc[column.code] = this.getRPAMeasurementLink(equipment[column.code]);
                        break;
                    case RP_MEASUREMENT_KEYS.MEASURE_CONTACT:
                    case RP_MEASUREMENT_KEYS.MEASURE10CM:
                    case RP_MEASUREMENT_KEYS.MEASURE_BGO:
                    case RP_MEASUREMENT_KEYS.MEASURE_ALPHA:
                    case RP_MEASUREMENT_KEYS.MEASURE_BETA: {
                        const qualifierMap = {
                            [RP_MEASUREMENT_KEYS.MEASURE_CONTACT]: RP_MEASUREMENT_KEYS.MEASURE_CONTACT_QUALIFIER,
                            [RP_MEASUREMENT_KEYS.MEASURE10CM]: RP_MEASUREMENT_KEYS.MEASURE10CM_QUALIFIER,
                            [RP_MEASUREMENT_KEYS.MEASURE_BGO]: RP_MEASUREMENT_KEYS.MEASURE_BGO_QUALIFIER,
                            [RP_MEASUREMENT_KEYS.MEASURE_ALPHA]: RP_MEASUREMENT_KEYS.MEASURE_ALPHA_QUALIFIER,
                            [RP_MEASUREMENT_KEYS.MEASURE_BETA]: RP_MEASUREMENT_KEYS.MEASURE_BETA_QUALIFIER,
                        };
                        acc[column.code] = equipment[column.code]
                            ? `${equipment[qualifierMap[column.code]] || ''} ${equipment[column.code]}`
                            : '';
                        break;
                    }
                    default:
                        acc[column.code] = equipment[column.code];
                        break;
                }
                return acc;
            }, {});
        });

    getRPClassificationEAMSelect = (equipment, index, readOnly) => (
        <EAMSelect
            elementInfo={{
                xpath: equipment + ANALYSIS_KEYS.RP_CLASSIFICATION,
                readonly: readOnly,
            }}
            formFields={this.formFields}
            value={equipment}
            valueKey={ANALYSIS_KEYS.RP_CLASSIFICATION}
            style={styles.rpClassificationField}
            values={Tools.getCustomFieldsCodeDesc(this.props.screenData.customFieldsDef.PR210, false)}
            updateProperty={(key, value) => {
                if (
                    this.state.propsApplyAll ||
                    this.props.applicationData.rpamapplyAllRPClassification.includes(
                        this.state[RPAR_PERFORM_KEYS.ANALYSIS_TYPE]
                    )
                ) {
                    this.handleUpdatePropertyForAll(RPAR_PERFORM_KEYS.ANALYSIS, key, value);
                } else {
                    this.handleUpdatePropertyInsideAnArray(RPAR_PERFORM_KEYS.ANALYSIS, index, key, value);
                }
            }}
        />
    );

    renderListRPMeasurementProperties = () => {
        const { equipmentMeasurementMap } = this.state;

        const tableLayout = this.rpMeasurementTableColumns.map((e) => ({
            code: e,
            header: this.props.getTranslation(e),
        }));

        const tableHeaders = tableLayout.map((e) => e.header);
        const tableCodes = tableLayout.map((e) => e.code);
        const tableData = this.generateRPMeasurementTableData(tableLayout, equipmentMeasurementMap);

        return (
            <div style={{ width: '100%', height: '100%' }}>
                <Grid item lg={12} md={12}>
                    <EISTable data={tableData} headers={tableHeaders} propCodes={tableCodes} maxMobileSize={600} />
                </Grid>
            </div>
        );
    };

    renderPropertiesTable = (dataSource, layout, readOnly, isJobDone) => {
        const tableLayout = layout.map((e) => ({
            code: e,
            header: this.props.getTranslation(e),
        }));

        const tableHeaders = tableLayout.map((e) => e.header);
        const tableCodes = tableLayout.map((e) => e.code);
        const tableData = this.generateEquipmentTableData(tableLayout, dataSource, readOnly, isJobDone);

        return (
            <div style={{ width: '100%', height: '100%' }}>
                <EISTable data={tableData} headers={tableHeaders} propCodes={tableCodes} maxMobileSize={600} />
            </div>
        );
    };

    getEDMSDocLight = (id, objectId, objectType, profile, context, title) => (
        <EDMSDoclightIframeContainer
            id={id}
            key={`DocLight${context}`}
            profile={profile}
            objectType={objectType}
            objectID={objectId}
            onLoad={this.handleLoadIframe}
            context={context}
            title={title}
        />
    );

    getRPAMDocLight = (context, index) => {
        const workorderId = this.props.match.params.jobnumber;
        const { valuesGrid } = this.state;
        const edmsProfile = this.getValuesGridMapping(valuesGrid, null, 'edmsProfile', 'edmsContext', context);
        const iframeId = index;
        return this.getEDMSDocLight(
            iframeId,
            workorderId,
            'J',
            edmsProfile,
            context,
            this.props.getTranslation(context)
        );
    };

    handleLoadIframe = (iframeId) => {
        const rpamValues = { ...this.state.rpamValues };
        const { edmsContext } = rpamValues;
        const nextcontext = edmsContext[iframeId + 1];
        if (nextcontext) {
            nextcontext.shouldLoad = true;
        }
        this.setState({ rpamValues });
    };

    getValuesGridMapping = (valuesGrid, entity, field, parentField, parentValue) => {
        try {
            const arr = valuesGrid.MAP[field];
            const { value } = (arr
                .filter((v) => entity === v.entity)
                .filter((v) => parentField === v.parentField)
                .filter((v) => parentValue === v.parentValue) || [])[0];
            return value;
        } catch (err) {
            return null;
        }
    };

    checkDecayPeriodRespected = () => {
        const { equipmentMap } = this.state;
        const activityDate = this.state[RPAR_PERFORM_KEYS.MEASURED_ACTIVITY_DATE];
        const decayPeriod = this.state[RPAR_PERFORM_KEYS.DECAY_PERIOD];
        if (!activityDate || !decayPeriod) {
            return true;
        }
        const equipmentArray = Object.values(equipmentMap);

        const equipmentDates = equipmentArray.map((eq) => [eq.irradiationEndDateMin, eq.irradiationEndDateMax]);
        // const decayPeriod = dropdowns[EQUIPMENT_KEYS.DECAY_PERIOD].filter((val) => val.code === '6M3Y')[0].code;
        const range = Tools.computeMinimumAndMaximumDates(decayPeriod, activityDate, -1);

        let valid = true;
        equipmentDates.forEach((datesList, i) => {
            const notRespectedDates = Tools.getDatesNotInRange(range, datesList);
            if (notRespectedDates?.length) {
                showError(
                    `Decay period not respected! ${
                        equipmentArray[i][EQUIPMENT_KEYS.EQUIPMENT_CODE]
                    } : ${notRespectedDates.map((a) => new Date(a))}`
                );
                valid = false;
            }
        });
        return valid;
    };

    handleUpdateActivityDate = () => {
        const minimumDate = this.getMinumimMeasuredActivityDate();
        if (!minimumDate) {
            showError('No irradation end dates present on the equipment');
        }
        this.setState({ [RPAR_PERFORM_KEYS.MEASURED_ACTIVITY_DATE]: minimumDate?.getTime() });
    };

    handleUpdateMaxActivityDate = () => {
        const maximumDate = this.getMaximumMeasuredActivityDate();
        if (!maximumDate) {
            showError('No irradation end dates present on the equipment');
        }
        this.setState({ [RPAR_PERFORM_KEYS.MEASURED_ACTIVITY_DATE]: maximumDate?.getTime() });
    };

    getMinumimMeasuredActivityDate = () => {
        const equipmentDates = Object.values(this.state.equipmentMap || {})
            .map((eq) => [eq.irradiationEndDateMin, eq.irradiationEndDateMax])
            .flat()
            .filter((s) => s);
        const { maxDate } = Tools.returnDateExtremes(equipmentDates);
        if (!maxDate) {
            return null;
        }
        const { earliestDate } = Tools.computeMinimumAndMaximumDates(
            this.state[RPAR_PERFORM_KEYS.DECAY_PERIOD],
            maxDate
        );
        return earliestDate;
    };

    getMaximumMeasuredActivityDate = () => {
        const { valuesGrid } = this.state;
        const decayPeriod = this.state[RPAR_PERFORM_KEYS.DECAY_PERIOD];
        const decayPeriodValidRange = valuesGrid.MAP.decayPeriodValidRange.find(
            (val) => val.parentValue === decayPeriod
        )?.value;
        if (!decayPeriodValidRange) return null;
        const equipmentDates = Object.values(this.state.equipmentMap || {})
            .map((eq) => [eq.irradiationEndDateMin, eq.irradiationEndDateMax])
            .flat()
            .filter((s) => s);
        const { minDate } = Tools.returnDateExtremes(equipmentDates);
        if (!minDate) {
            return null;
        }
        const { latestDate } = Tools.computeMinimumAndMaximumDates(decayPeriodValidRange, minDate);
        return latestDate;
    };

    renderPage(writeAccess) {
        const { rpamValues, loading } = this.state;
        const { userData, match, translations, getTranslation, dropdowns, rpaDropdowns, lang, authorizedMenus } =
            this.props;
        const workorderId = match.params.jobnumber;
        if (!this.state[RPAR_REQUEST_KEYS.WORKORDER]) {
            return <BlockUi message="Loading..." blocking={loading} style={{ height: '100%', width: '100%' }} />;
        }
        const isJobDone = () => this.state[RPAR_PERFORM_KEYS.STATUS] === 'T';
        const readOnly = this.state.readOnly || !writeAccess;
        const classificationRequestedList = this.state[RPAR_PERFORM_KEYS.ANALYSIS]
            .map((analysisProps) => analysisProps[ANALYSIS_KEYS.CLASSIFICATION_REQUESTED])
            .flat();

        const admin = authorizedMenus
            .filter(({ type, code }) => type === 'SM' && ['SETTINGS'].includes(code))
            .map(({ readOnly: readon }) => (readon ? 'R' : 'W'))
            .includes('W');

        const topMessages = [];

        const measuredActivityDateAttribute = this.isRWEliminationRP()
            ? 'R'
            : // EAM-3442
              // : [
              //       ANALYSIS_TYPES.GAMMASPEC,
              //       ANALYSIS_TYPES.RADOS,
              //       ANALYSIS_TYPES.LSC,
              //       ANALYSIS_TYPES.ABCOUNT,
              //       ANALYSIS_TYPES.ALPHASPEC,
              //   ].includes(this.state[RPAR_PERFORM_KEYS.ANALYSIS_TYPE])
              // ? 'O'
              'H';

        const isCurRW = (eqp) => eqp[EQUIPMENT_KEYS.USER_STATUS] === 'IW' && eqp[EQUIPMENT_KEYS.RW_RECEPTION_DATE];
        if (
            this.state.equipmentMap &&
            Object.values(this.state.equipmentMap).some(isCurRW) &&
            Object.values(this.state.equipmentMap).some((eqp) => !isCurRW(eqp))
        ) {
            topMessages.push(() => <WarningText text={getTranslation('BOTH_CURRW_AND_NOT_CURRW')} />);
        }

        return (
            <BlockUi blocking={loading} style={{ height: '100%', width: '100%' }}>
                <section style={styles.analysisJobSection}>
                    <section style={styles.analysisJobMain}>
                        <section style={styles.toolbarSection}>
                            {writeAccess && (
                                <TRECButton onClick={this.handleSave}>
                                    <SaveIcon />
                                    {translations.SAVE}
                                </TRECButton>
                            )}
                            {admin && (
                                <TRECButton
                                    onClick={async () => {
                                        try {
                                            const body = await WSRPARequest.callHseLimsIntegAPI(workorderId);
                                            showSuccess(body);
                                            this.setState({ hseLimpsApi: body });
                                        } catch (error) {
                                            handleError(error);
                                        }
                                    }}
                                >
                                    <Call />
                                    {getTranslation(RPAR_PERFORM_KEYS.HSE_LIMS_API)}
                                </TRECButton>
                            )}
                            <TRECButton onClick={this.handleBack}>{translations.BACK}</TRECButton>
                        </section>
                        {this.state.hseLimpsApi && (
                            <div style={{ padding: '20px', boxSizing: 'border-box' }}>
                                <pre>{JSON.stringify(JSON.parse(this.state.hseLimpsApi), null, 2)}</pre>
                            </div>
                        )}
                        {topMessages.map((s) => s())}
                        <section style={styles.contentSection}>
                            <Grid container spacing={8}>
                                <Grid item lg={7} md={7} sm={12}>
                                    <EISPanel heading={getTranslation(RPAR_PERFORM_KEYS.WORKORDERDETAILS)}>
                                        <div style={{ width: '100%' }}>
                                            <EAMInput
                                                elementInfo={{
                                                    readonly: true,
                                                    text: getTranslation(RPAR_PERFORM_KEYS.ANALYSIS_JOB_ID),
                                                    xpath: RPAR_PERFORM_KEYS.ANALYSIS_JOB_ID,
                                                }}
                                                formFields={this.formFields}
                                                value={this.state[RPAR_PERFORM_KEYS.WORKORDER]}
                                                valueKey={RPAR_PERFORM_KEYS.WORKORDER}
                                                updateProperty={(key, value) => {
                                                    this.handleUpdateProperty(key, value);
                                                }}
                                            />
                                            <EAMInput
                                                elementInfo={{
                                                    readonly: readOnly,
                                                    text: getTranslation(RPAR_REQUEST_KEYS.DESCRIPTION),
                                                    xpath: RPAR_PERFORM_KEYS.DESCRIPTION,
                                                }}
                                                formFields={this.formFields}
                                                value={this.state[RPAR_PERFORM_KEYS.DESCRIPTION]}
                                                valueKey={RPAR_PERFORM_KEYS.DESCRIPTION}
                                                updateProperty={(key, value) => {
                                                    this.handleUpdateProperty(key, value);
                                                }}
                                            />

                                            <EAMAutocomplete
                                                elementInfo={{
                                                    text: getTranslation(RPAR_PERFORM_KEYS.REQUESTEDBY),
                                                    xpath: RPAR_PERFORM_KEYS.REQUESTEDBY,
                                                    readonly: true,
                                                }}
                                                formFields={this.formFields}
                                                value={this.state[RPAR_PERFORM_KEYS.REQUESTEDBY]}
                                                valueDesc={this.state[RPAR_PERFORM_KEYS.REQUESTEDBYDESC]}
                                                updateProperty={(key, value) => {
                                                    this.handleUpdateProperty(key, value);
                                                }}
                                                valueKey={RPAR_PERFORM_KEYS.REQUESTEDBY}
                                                descKey=""
                                            />
                                            <EAMAutocomplete
                                                elementInfo={{
                                                    text: getTranslation(RPAR_PERFORM_KEYS.PERFORMEDBY),
                                                    xpath: RPAR_PERFORM_KEYS.PERFORMEDBY,
                                                    readonly: readOnly,
                                                }}
                                                value={this.state[RPAR_PERFORM_KEYS.PERFORMEDBY] || ''}
                                                valueDesc={this.state[RPAR_PERFORM_KEYS.PERFORMEDBYDESC]}
                                                formFields={this.formFields}
                                                updateProperty={(key, value) => {
                                                    this.handleUpdateProperty(key, value);
                                                }}
                                                valueKey={RPAR_PERFORM_KEYS.PERFORMEDBY}
                                                loadOptions={WSAutocomplete.autocompleteEmployee}
                                                descKey={RPAR_PERFORM_KEYS.PERFORMEDBYDESC}
                                            />
                                            <EAMAutocomplete
                                                elementInfo={{
                                                    text: getTranslation(RPAR_PERFORM_KEYS.UPDATEDBY),
                                                    xpath: RPAR_PERFORM_KEYS.UPDATEDBY,
                                                    readonly: true,
                                                }}
                                                value={this.state[RPAR_PERFORM_KEYS.UPDATEDBY] || ''}
                                                valueDesc={this.state[RPAR_PERFORM_KEYS.UPDATEDBYDESC]}
                                                formFields={this.formFields}
                                                updateProperty={(key, value) => {
                                                    this.handleUpdateProperty(key, value);
                                                }}
                                                valueKey={RPAR_PERFORM_KEYS.UPDATEDBY}
                                                loadOptions={WSAutocomplete.autocompleteEmployee}
                                                descKey=""
                                            />
                                            <EAMDateTimePicker
                                                keyboard={false}
                                                elementInfo={{
                                                    text: getTranslation(RPAR_PERFORM_KEYS.UPDATEDDATE),
                                                    xpath: RPAR_PERFORM_KEYS.UPDATEDDATE,
                                                    readonly: true,
                                                }}
                                                formFields={this.formFields}
                                                localeString={lang}
                                                valueKey={RPAR_PERFORM_KEYS.UPDATEDDATE}
                                                value={this.state[RPAR_PERFORM_KEYS.UPDATEDDATE]}
                                                updateProperty={(key, value) => {
                                                    this.handleUpdateProperty(key, value);
                                                }}
                                            />
                                            <EAMSelect
                                                elementInfo={{
                                                    text: getTranslation(RPAR_PERFORM_KEYS.STATUS),
                                                    xpath: RPAR_PERFORM_KEYS.STATUS,
                                                    readonly: !writeAccess,
                                                }}
                                                formFields={this.formFields}
                                                value={this.state[RPAR_PERFORM_KEYS.STATUS]}
                                                valueKey={RPAR_PERFORM_KEYS.STATUS}
                                                values={this.getValues({
                                                    key: 'rpaStatus',
                                                    object: this.state,
                                                    values: rpaDropdowns.rpaStatus,
                                                }).map(({ code }) => ({
                                                    code,
                                                    desc: getTranslation(ENTITY_TRANSL_PREFIX.EVT_STATUS + code),
                                                }))}
                                                updateProperty={(key, value) => {
                                                    this.handleUpdateProperty(key, value);
                                                }}
                                            />
                                            <EAMSelect
                                                elementInfo={{
                                                    readonly: readOnly,
                                                    text: getTranslation(RPAR_PERFORM_KEYS.PRIORITY),
                                                    attribute: isJobDone() ? 'R' : 'O',
                                                    xpath: RPAR_PERFORM_KEYS.PRIORITY,
                                                }}
                                                formFields={this.formFields}
                                                value={this.state.priority}
                                                valueKey={RPAR_PERFORM_KEYS.PRIORITY}
                                                values={rpamValues[RPAR_PERFORM_KEYS.PRIORITY]}
                                                updateProperty={(key, value) => {
                                                    this.handleUpdateProperty(key, value);
                                                }}
                                            />
                                            {this.state[RPAR_PERFORM_KEYS.REQUESTWOPARENT] ? (
                                                <div
                                                    key="parent"
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'baseline',
                                                    }}
                                                >
                                                    <EAMFormLabel label={getTranslation(RPAR_REQUEST_KEYS.PARENT_WO)} />
                                                    {getInternalLink({
                                                        route: generateRoute(ROUTES.waterRelease, {
                                                            waterReleaseNumber:
                                                                this.state[RPAR_PERFORM_KEYS.REQUESTWOPARENT],
                                                        }),
                                                        text: this.state[RPAR_PERFORM_KEYS.REQUESTWOPARENT],
                                                    })}
                                                </div>
                                            ) : null}
                                        </div>
                                    </EISPanel>

                                    <EISPanel heading={getTranslation(RPAR_REQUEST_KEYS.RPANALYSISDETAILS)}>
                                        <div style={{ width: '100%' }}>
                                            <EAMInput
                                                elementInfo={{
                                                    readonly: readOnly,
                                                    text: getTranslation(RPAR_PERFORM_KEYS.ANALYSIS_ID),
                                                    xpath: RPAR_PERFORM_KEYS.ANALYSIS_ID,
                                                    attribute: isJobDone() ? 'R' : 'O',
                                                }}
                                                formFields={this.formFields}
                                                value={this.state[RPAR_PERFORM_KEYS.ANALYSIS_ID] || ''}
                                                valueKey={RPAR_PERFORM_KEYS.ANALYSIS_ID}
                                                updateProperty={(key, value) => {
                                                    this.handleUpdateProperty(key, value);
                                                }}
                                            />
                                            <Grid item xs={12}>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={10}>
                                                        <EAMInput
                                                            elementInfo={{
                                                                readonly: true,
                                                                text: getTranslation(RPAR_PERFORM_KEYS.REQUESTWO),
                                                                xpath: RPAR_PERFORM_KEYS.REQUESTWO,
                                                            }}
                                                            value={this.state[RPAR_PERFORM_KEYS.REQUESTWO]}
                                                            formFields={this.formFields}
                                                            valueKey={RPAR_PERFORM_KEYS.REQUESTWO}
                                                            updateProperty={(key, value) => {
                                                                this.handleUpdateProperty(key, value);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} style={{ textAlign: 'center' }}>
                                                        <TRECButton
                                                            size="small"
                                                            onClick={(_) =>
                                                                this.props.history.push(
                                                                    `/rparequest/edit/${
                                                                        this.state[RPAR_PERFORM_KEYS.REQUESTWO]
                                                                    }`
                                                                )
                                                            }
                                                        >
                                                            <Edit />
                                                        </TRECButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <EAMInput
                                                elementInfo={{
                                                    text: getTranslation(RPAR_PERFORM_KEYS.ANALYSIS_TYPE),
                                                    readonly: true,
                                                    xpath: RPAR_PERFORM_KEYS.ANALYSIS_TYPE,
                                                }}
                                                formFields={this.formFields}
                                                value={getTranslation(this.state[RPAR_PERFORM_KEYS.ANALYSIS_TYPE])}
                                                valueKey={RPAR_PERFORM_KEYS.ANALYSIS_TYPE}
                                                updateProperty={(key, value) => {
                                                    this.handleUpdateProperty(key, value);
                                                }}
                                            />
                                            {this.state.analysisTypeCFMap?.map((cf) => {
                                                if (cf === RPAR_PERFORM_KEYS.LL_VALUE) {
                                                    return (
                                                        <EAMInput
                                                            key={`analysisCFs${cf}`}
                                                            style={this.valueStyle}
                                                            elementInfo={{
                                                                readonly: readOnly,
                                                                text: getTranslation(RPAR_PERFORM_KEYS.LL_VALUE),
                                                                xpath: RPAR_PERFORM_KEYS.LL_VALUE,
                                                                attribute: this.isParallelJob()
                                                                    ? 'H'
                                                                    : isJobDone() &&
                                                                      this.state[RPAR_PERFORM_KEYS.ANALYSIS].some((s) =>
                                                                          s[
                                                                              ANALYSIS_KEYS.CLASSIFICATION_REQUESTED
                                                                          ]?.includes('CLR')
                                                                      )
                                                                    ? // https://cern.service-now.com/service-portal?id=ticket&table=u_request_fulfillment&n=RQF1639203
                                                                      //  && !(this.state[RPAR_PERFORM_KEYS.ANALYSIS_TYPE] == GAMMASPEC_ATYPE && this.state[RPAR_PERFORM_KEYS.REQUEST_PURPOSE] == 'ELIM')
                                                                      'R'
                                                                    : 'O',
                                                            }}
                                                            value={this.state[RPAR_PERFORM_KEYS.LL_VALUE] ?? ''}
                                                            updateProperty={(key, val) => {
                                                                const value = val || '';
                                                                this.handleUpdateProperty(
                                                                    key,
                                                                    NUMBER_REGEX.test(value)
                                                                        ? value
                                                                        : value.replace(/[^0-9[.]]+/g, '')
                                                                );
                                                            }}
                                                            valueKey={RPAR_PERFORM_KEYS.LL_VALUE}
                                                            inputProps={this.inputProps}
                                                            formFields={this.formFields}
                                                        />
                                                    );
                                                }
                                                if (
                                                    ![
                                                        'acquisitionTime',
                                                        'analysisTime',
                                                        'difficulty',
                                                        'waterEliminationPathway',
                                                    ].includes(cf)
                                                )
                                                    return null;

                                                return (
                                                    <EAMSelect
                                                        labelStyle={{ wordWrap: 'break-word' }}
                                                        key={`analysisCFs${cf}`}
                                                        elementInfo={{
                                                            readonly: readOnly,
                                                            text: getTranslation(cf),
                                                            attribute:
                                                                (cf !== 'waterEliminationPathway' ||
                                                                    this.state[RPAR_PERFORM_KEYS.ANALYSIS].some((s) =>
                                                                        s[
                                                                            ANALYSIS_KEYS.CLASSIFICATION_REQUESTED
                                                                        ]?.includes(CLASSIFICATION_REQUESTED_TYPES.H2O)
                                                                    )) &&
                                                                isJobDone()
                                                                    ? 'R'
                                                                    : 'O',
                                                            xpath: cf,
                                                        }}
                                                        value={this.state[cf]}
                                                        formFields={this.formFields}
                                                        valueKey={cf}
                                                        values={dropdowns[cf] || rpamValues[cf]}
                                                        updateProperty={(key, value) => {
                                                            this.handleUpdateProperty(key, value);
                                                        }}
                                                    />
                                                );
                                            })}
                                            {(RPA_NEW_FIELDS_PROD ||
                                                this.props.applicationData.environment !== 'PROD') && (
                                                <>
                                                    <EAMInput
                                                        elementInfo={{
                                                            xpath: RPAR_PERFORM_KEYS.NUMBER_OF_COUNTS,
                                                            text: getTranslation(RPAR_PERFORM_KEYS.NUMBER_OF_COUNTS),
                                                            attribute:
                                                                this.state[RPAR_PERFORM_KEYS.ANALYSIS_TYPE] !==
                                                                ANALYSIS_TYPES.GAMMASPEC
                                                                    ? 'H'
                                                                    : isJobDone()
                                                                    ? 'R'
                                                                    : 'O',
                                                        }}
                                                        value={this.state[RPAR_PERFORM_KEYS.NUMBER_OF_COUNTS]}
                                                        updateProperty={(key, value) => {
                                                            this.handleUpdateProperty(key, value);
                                                        }}
                                                        valueKey={RPAR_PERFORM_KEYS.NUMBER_OF_COUNTS}
                                                        formFields={this.formFields}
                                                    />
                                                    <EAMSelect
                                                        elementInfo={{
                                                            xpath: `${RPAR_PERFORM_KEYS.DECAY_PERIOD}`,
                                                            readonly: readOnly,
                                                            text: getTranslation(RPAR_PERFORM_KEYS.DECAY_PERIOD),
                                                            attribute: measuredActivityDateAttribute,
                                                        }}
                                                        valueKey={RPAR_PERFORM_KEYS.DECAY_PERIOD}
                                                        value={this.state[RPAR_PERFORM_KEYS.DECAY_PERIOD]}
                                                        updateProperty={(key, value) => {
                                                            this.handleUpdateProperty(key, value);
                                                        }}
                                                        values={rpaDropdowns[RPAR_PERFORM_KEYS.DECAY_PERIOD]}
                                                    />
                                                    <EAMDatePicker
                                                        keyboard={false}
                                                        timestamp
                                                        elementInfo={{
                                                            text: getTranslation(
                                                                RPAR_PERFORM_KEYS.MEASURED_ACTIVITY_DATE
                                                            ),
                                                            xpath: RPAR_PERFORM_KEYS.MEASURED_ACTIVITY_DATE,
                                                            attribute: measuredActivityDateAttribute,
                                                        }}
                                                        localeString={lang}
                                                        formFields={this.formFields}
                                                        valueKey={RPAR_PERFORM_KEYS.MEASURED_ACTIVITY_DATE}
                                                        value={this.state[RPAR_PERFORM_KEYS.MEASURED_ACTIVITY_DATE]}
                                                        updateProperty={(key, value) => {
                                                            this.handleUpdateProperty(key, value);
                                                        }}
                                                    />

                                                    {measuredActivityDateAttribute !== 'H' && (
                                                        <div style={{ display: 'flex' }}>
                                                            <Button
                                                                variant="caption"
                                                                color="secondary"
                                                                onClick={() => this.handleUpdateActivityDate()}
                                                            >
                                                                {getTranslation('MIN_MEAS_ACT_DATE')}
                                                            </Button>
                                                            <EAMDatePicker
                                                                elementInfo={{ readonly: true }}
                                                                keyboard={false}
                                                                value={this.getMinumimMeasuredActivityDate()}
                                                            />
                                                        </div>
                                                    )}

                                                    {measuredActivityDateAttribute !== 'H' && (
                                                        <div style={{ display: 'flex' }}>
                                                            <Button
                                                                variant="caption"
                                                                color="secondary"
                                                                onClick={() => this.handleUpdateMaxActivityDate()}
                                                            >
                                                                {getTranslation('MAX_MEAS_ACT_DATE')}
                                                            </Button>
                                                            <EAMDatePicker
                                                                elementInfo={{ readonly: true }}
                                                                keyboard={false}
                                                                value={this.getMaximumMeasuredActivityDate()}
                                                            />
                                                        </div>
                                                    )}

                                                    <EAMInput
                                                        style={this.valueStyle}
                                                        elementInfo={{
                                                            readonly: readOnly,
                                                            text: getTranslation(RPAR_PERFORM_KEYS.IRAS),
                                                            xpath: RPAR_PERFORM_KEYS.IRAS,
                                                            attribute:
                                                                this.state[RPAR_PERFORM_KEYS.ANALYSIS_TYPE] !==
                                                                    ANALYSIS_TYPES.GAMMASPEC ||
                                                                !classificationRequestedList.includes(
                                                                    CLASSIFICATION_REQUESTED_TYPES.TFA
                                                                )
                                                                    ? 'H'
                                                                    : isJobDone()
                                                                    ? 'R'
                                                                    : 'O',
                                                        }}
                                                        value={this.state[RPAR_PERFORM_KEYS.IRAS] ?? ''}
                                                        updateProperty={(key, val) => {
                                                            const value = val || '';
                                                            this.handleUpdateProperty(
                                                                key,
                                                                NUMBER_REGEX.test(value)
                                                                    ? value
                                                                    : value.replace(/[^0-9[.]]+/g, '')
                                                            );
                                                        }}
                                                        valueKey={RPAR_PERFORM_KEYS.IRAS}
                                                        inputProps={this.inputProps}
                                                        formFields={this.formFields}
                                                    />
                                                    <EAMInput
                                                        style={this.valueStyle}
                                                        elementInfo={{
                                                            readonly: readOnly,
                                                            text: getTranslation(RPAR_PERFORM_KEYS.TOTAL_BETA_GAMA),
                                                            xpath: RPAR_PERFORM_KEYS.TOTAL_BETA_GAMA,
                                                            attribute: !classificationRequestedList.includes(
                                                                CLASSIFICATION_REQUESTED_TYPES.FMA
                                                            )
                                                                ? 'H'
                                                                : isJobDone()
                                                                ? 'R'
                                                                : 'O',
                                                        }}
                                                        value={this.state[RPAR_PERFORM_KEYS.TOTAL_BETA_GAMA] ?? ''}
                                                        updateProperty={(key, val) => {
                                                            const value = val || '';
                                                            this.handleUpdateProperty(
                                                                key,
                                                                NUMBER_REGEX.test(value)
                                                                    ? value
                                                                    : value.replace(/[^0-9[.]]+/g, '')
                                                            );
                                                        }}
                                                        valueKey={RPAR_PERFORM_KEYS.TOTAL_BETA_GAMA}
                                                        inputProps={this.inputProps}
                                                        formFields={this.formFields}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </EISPanel>
                                    <EISPanel heading={getTranslation(RPAR_PERFORM_KEYS.SCHEDULING)}>
                                        <div style={{ width: '100%' }}>
                                            <Grid item xs={10} md={12} xl={12}>
                                                <Grid container spacing={8}>
                                                    <EAMDatePicker
                                                        elementInfo={{
                                                            text: getTranslation(RPAR_PERFORM_KEYS.DEADLINE),
                                                            xpath: RPAR_PERFORM_KEYS.DEADLINE,
                                                            readonly: readOnly,
                                                        }}
                                                        keyboard={false}
                                                        localeString={lang}
                                                        formFields={this.formFields}
                                                        valueKey={RPAR_PERFORM_KEYS.DEADLINE}
                                                        value={this.state[RPAR_PERFORM_KEYS.DEADLINE]}
                                                        updateProperty={(key, value) => {
                                                            this.handleUpdateProperty(key, value);
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={10} md={12} xl={12}>
                                                <Grid container spacing={8}>
                                                    <Grid item xs={5}>
                                                        <EAMFormLabel
                                                            label={getTranslation(RPAR_PERFORM_KEYS.START_DATE)}
                                                        />
                                                        <EAMDateTimePicker
                                                            elementInfo={{
                                                                readonly: readOnly,
                                                                xpath: RPAR_PERFORM_KEYS.START_DATE,
                                                                attribute: isJobDone() ? 'R' : 'O',
                                                            }}
                                                            {...(this.state[RPAR_PERFORM_KEYS.END_DATE]
                                                                ? { maxDate: this.state[RPAR_PERFORM_KEYS.END_DATE] }
                                                                : {})}
                                                            ampm={false}
                                                            keyboard={false}
                                                            formFields={this.formFields}
                                                            valueKey={RPAR_PERFORM_KEYS.START_DATE}
                                                            localeString={lang}
                                                            value={this.state[RPAR_PERFORM_KEYS.START_DATE]}
                                                            updateProperty={(key, value) => {
                                                                this.handleUpdateProperty(key, value);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        {/* No content */}
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <EAMFormLabel
                                                            label={getTranslation(RPAR_PERFORM_KEYS.END_DATE)}
                                                        />
                                                        <EAMDateTimePicker
                                                            elementInfo={{
                                                                readonly: readOnly,
                                                                xpath: RPAR_PERFORM_KEYS.END_DATE,
                                                            }}
                                                            {...(this.state[RPAR_PERFORM_KEYS.START_DATE]
                                                                ? { minDate: this.state[RPAR_PERFORM_KEYS.START_DATE] }
                                                                : {})}
                                                            ampm={false}
                                                            keyboard={false}
                                                            formFields={this.formFields}
                                                            valueKey={RPAR_PERFORM_KEYS.END_DATE}
                                                            localeString={lang}
                                                            value={this.state[RPAR_PERFORM_KEYS.END_DATE]}
                                                            updateProperty={(key, value) => {
                                                                this.handleUpdateProperty(key, value);
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </EISPanel>
                                </Grid>
                                <Grid item lg={5} sm={12} md={5}>
                                    {rpamValues &&
                                        rpamValues.edmsContext &&
                                        rpamValues.edmsContext
                                            .filter((context) => context.shouldLoad)
                                            .map((context, i) => this.getRPAMDocLight(context.code, i))}

                                    <CommentsContainer
                                        title={getTranslation(RPAR_PERFORM_KEYS.COMMENTS)}
                                        entityCode="EVNT"
                                        entityKeyCode={workorderId}
                                        userDesc={userData.eamAccount.userDesc}
                                        readComments={WSComments.readComments}
                                        createComment={this.handleCreateComment}
                                        updateComment={this.handleUpdateComment}
                                    />
                                </Grid>
                                <Grid item lg={12} md={12} sm={12}>
                                    <EISPanel heading={getTranslation(RPAR_REQUEST_KEYS.EQUIPMENTFORANALYSIS)}>
                                        {this.state.equipmentMap &&
                                            this.renderPropertiesTable(
                                                this.state.equipmentMap,
                                                this.equipmentTableColumns(
                                                    this.state.equipmentMap,
                                                    measuredActivityDateAttribute
                                                ),
                                                readOnly,
                                                isJobDone
                                            )}
                                    </EISPanel>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12}>
                                    <EISPanel heading={getTranslation(RPAR_REQUEST_KEYS.ANALYSIS_PROPS)}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {!this.props.applicationData.rpamapplyAllRPClassification.includes(
                                                this.state[RPAR_PERFORM_KEYS.ANALYSIS_TYPE]
                                            ) ? (
                                                <EAMCheckbox
                                                    elementInfo={{
                                                        text: getTranslation(RPAR_REQUEST_KEYS.COPYCHECKBOX),
                                                    }}
                                                    value={this.state.propsApplyAll}
                                                    updateProperty={(_, value) =>
                                                        this.setState({ propsApplyAll: value })
                                                    }
                                                    trueValue
                                                    falseValue={false}
                                                />
                                            ) : null}
                                            {this.state.equipmentMap &&
                                                this.renderPropertiesTable(
                                                    this.state.analysisProps,
                                                    this.analysisTableColumns(),
                                                    readOnly,
                                                    isJobDone
                                                )}
                                        </div>
                                    </EISPanel>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12}>
                                    <EISPanel heading={getTranslation(RPAR_REQUEST_KEYS.RPMEASUREMENT)}>
                                        <div style={{ width: '100%', height: '100%' }}>
                                            {this.state.analysisProps && this.renderListRPMeasurementProperties()}
                                        </div>
                                    </EISPanel>
                                </Grid>
                            </Grid>
                        </section>
                    </section>
                </section>
            </BlockUi>
        );
    }
}

export default RPAnalysisJob;
