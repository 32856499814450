import client from '../tools/rest/client';
import { SET_LOGIN_TOKEN, SET_USERNAME, UPDATE_APPLICATION } from '../actions/applicationActions';

function buildApplicationObject() {
    return {};
}

export default function application(state = buildApplicationObject(), action) {
    switch (action.type) {
        case UPDATE_APPLICATION:
            /**
             * Necessary for our login: if there is already a buffer zone
             * in the state, do not delete it when changing the user.
             */
            if (state.bufferZone) {
                delete action.value.bufferZone;
            }

            return {
                ...state,
                ...action.value,
            };
        case SET_LOGIN_TOKEN: {
            if (action.value) {
                return {
                    ...state,
                    loginToken: action.value,
                };
            }

            return {
                ...state,
                loginToken: null,
                userData: {
                    eamAccount: {
                        userCode: state.applicationData.bufferZoneAccount,
                    },
                },
            };
        }
        case SET_USERNAME: {
            client.defaults.headers.common.INFOR_USER = action.value;
            return {
                ...state,
                guestUsername: action.value,
            };
        }
        default:
            return state;
    }
}
