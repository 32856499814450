import { connect } from 'react-redux';
import EquipmentHazard from './EquipmentHazard';

function mapStateToProps(state) {
    return {
        constants: state.application.constants,
        translations: state.application.screenData[state.layout.lang].translations.TREC_MENU,
    };
}

const EquipmentHazardContainer = connect(mapStateToProps, {})(EquipmentHazard);

export default EquipmentHazardContainer;
