import Grid from '@material-ui/core/Grid';
import { WizardStep } from 'amm-tools';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import { EQUIPMENT_KEYS, RPAR_REQUEST_KEYS, RPSM_KEYS, TABLE_DATA_TYPES, TREC_GENERAL_KEYS } from 'enums/Constants';
import React from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import EamComponentsTools from 'tools/EamComponentsTools';
import Tools from 'tools/Tools';
import { showError } from 'tools/TrecNotifications';

const styles = {
    tableContainerSection: {
        width: '100%',
    },
};

class RPSMAssetSelection extends WizardStep {
    equipmentTableColumns = [
        {
            code: RPSM_KEYS.ORIGIN,
            header: RPSM_KEYS.ORIGIN,
            type: TABLE_DATA_TYPES.STATIC,
        },
        {
            code: EQUIPMENT_KEYS.EQUIPMENT_CODE,
            header: RPSM_KEYS.SAMPLE_CODE,
            type: TABLE_DATA_TYPES.STATIC,
        },
        {
            code: RPSM_KEYS.REQUEST_ANALYSIS,
            header: RPSM_KEYS.REQUEST_ANALYSIS,
            type: TABLE_DATA_TYPES.CHECKBOX,
        },
    ];

    state = {
        copyOnFirstLine: false,
        loading: false,
        samplesForAnalysis: [],
    };

    formFields = {};

    validate = () => Object.values(this.formFields).reduce((acc, ff) => (!ff.validate || ff.validate()) && acc, true);

    canContinue = () => {
        const { getTranslation } = this.props;
        if (!this.validate) {
            showError(getTranslation(TREC_GENERAL_KEYS.REQUIRED_ERROR_MESSAGE), null, 4000);
        }
        return this.validate();
    };

    saveChanges = () => true;

    commitChanges = (callback) => {
        callback();
    };

    handleBackPressed = () => this.handleNextPressed();

    static getDerivedStateFromProps(props, state) {
        return {
            samples: props.rpsmGetters.getSamples(),
            ...state,
        };
    }

    handleStateChange(key, value) {
        this.setState({
            [key]: value,
        });
    }

    setSamplesListForAnalysis = () => {
        const { samples } = this.state;
        const { rpsmActions } = this.props;
        const sampleCodesList = [];
        Object.values(samples).map((sample) => {
            if (sample.requestAnalysis) {
                sampleCodesList.push(sample[EQUIPMENT_KEYS.EQUIPMENT_CODE]);
            }
            return true;
        });
        rpsmActions.updateSamplesForAnalysis(sampleCodesList);
    };

    getTableData = (tableColumns, equipmentMap, stateCode) =>
        Object.keys(equipmentMap).map((eqCode) => {
            const equipment = equipmentMap[eqCode];
            return tableColumns.reduce((acc, column) => {
                switch (column.type) {
                    case TABLE_DATA_TYPES.STATIC:
                        acc[column.code] = equipment[column.code];
                        break;
                    case TABLE_DATA_TYPES.CHECKBOX:
                        acc[column.code] = EamComponentsTools.getEAMCheckbox(
                            eqCode,
                            equipment,
                            column.code,
                            (key, value) => {
                                this.updateThisState(
                                    [stateCode, eqCode, key],
                                    value,
                                    true,
                                    this.state.copyOnFirstLine,
                                    this.setSamplesListForAnalysis
                                );
                            },
                            this.formFields,
                            column.fieldType,
                            column.required
                        );
                        break;
                    default:
                        break;
                }
                return acc;
            }, {});
        });

    updateThisState = Tools.updateThisState;

    render() {
        const { getTranslation } = this.props;
        const { copyOnFirstLine, samples } = this.state;
        const equipmentTableLayout = this.equipmentTableColumns.map((e) => ({
            ...e,
            header: getTranslation(e.header || e.code),
        }));

        return (
            <Grid container spacing={0}>
                <Grid item md={12} sm={12} xs={12}>
                    <EISPanel heading={getTranslation(RPSM_KEYS.SAMPLE_SELECTION)} alwaysExpanded>
                        <section style={styles.tableContainerSection}>
                            <EAMCheckbox
                                elementInfo={{
                                    label: 'OLabel',
                                    attribute: 'O',
                                    text: getTranslation(RPAR_REQUEST_KEYS.COPYCHECKBOX),
                                }}
                                valueKey="copyOnFirstLine"
                                value={copyOnFirstLine}
                                updateProperty={(key, value) => this.handleStateChange(key, value)}
                                trueValue
                                falseValue={false}
                            />
                            <EISTable
                                headers={equipmentTableLayout.map((e) => e.header)}
                                propCodes={equipmentTableLayout.map((e) => e.code)}
                                data={this.getTableData(equipmentTableLayout, samples, 'samples')}
                                maxMobileSize={600}
                            />
                        </section>
                    </EISPanel>
                </Grid>
            </Grid>
        );
    }
}

export default RPSMAssetSelection;
