import { connect } from 'react-redux';
import { setPage, updateLayout } from '../../../actions/layoutActions';
import Tools from '../../../tools/Tools';
import RPAnalysisJob from './RPAnalysisJob';

const RPAM_REQUEST_MASTERPAGE = 'RPAM_ANALYSIS';
const RPAM_TRANSLATION_KEY = 'TREC_RPAM';

function mapStateToProps(state) {
    const { application, layout } = state;
    const { applicationData, screenData, menuType, userData, rwpDropdowns, rpaDropdowns, authorizedMenus } =
        application;
    const { lang } = layout;
    const translations = screenData[lang].translations[RPAM_TRANSLATION_KEY];
    const lang2 = Tools.getURLParameterByName('lang');
    const menu = screenData[lang].menu[menuType];

    return {
        authorizedMenus,
        page: RPAM_REQUEST_MASTERPAGE,
        masterPage: RPAM_REQUEST_MASTERPAGE,
        screenData: screenData[lang],
        userData,
        applicationData,
        lang,
        menu,
        translations,
        dropdowns: rwpDropdowns,
        rpaDropdowns,
        getTranslation: (code) =>
            code &&
            ((lang2 === 'DEV' && code) ||
                (translations && translations[code.toUpperCase()]) ||
                (screenData.EN.translations[RPAM_TRANSLATION_KEY] &&
                    screenData.EN.translations[RPAM_TRANSLATION_KEY][code.toUpperCase()]) ||
                code),
    };
}

const RPAnalysisJobContainer = connect(mapStateToProps, {
    updateLayout,
    setPage,
})(RPAnalysisJob);

export default RPAnalysisJobContainer;
