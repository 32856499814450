export const CUTG0104_FIELDS_REQUEST_PARAMETERS = {
    OBJ_LOCATION: 'asset_loc',
    EQUIPMENT: 'equipment',
    JOB_LOCATION: 'job_location',
    JOB_NUMBER: 'job_number',
    JOB_STATUS: 'job_status',
    JOB_FUTURE_ACTION: 'job_future_action',
    JOB_CREATED: 'job_reported',
    JOB_CREATED_BY: 'job_createdby',
    ASSET_RESPONSIBLE: 'asset_responsible',
    JOB_EXECUTEDBY: 'job_executedby',
    REPORTEDBY: 'job_reportedby',
    EDHDOCTYPE_CODE: 'edh_document',
    EDHDOCSTATUS_CODE: 'edhdocstatus_code',
    OBJ_CLASS: 'asset_class',
    CADRA_CHECK_PRESENT: 'cadra_check_present',
    RP_STATUS: 'rp_status',
    DEPARTMENT: 'job_department',
};

export const CUTG0104_HIDDEN_FIELDS = {
    OBJ_CLASS: 'asset_class',
    ASSET_RESPONSIBLE: 'asset_responsible',
    JOB_DEPARTMENT: 'job_department',
    EDHDOCSTATUS_CODE: 'edhdocstatus_code',
    EXECUTEDBY_CODE: 'executedby_code',
    JOB_STATUS_CODE: 'job_status_code',
    // JOB_EXECUTEDBY_DESC: 'job_executedby_desc',
    JOB_EXECUTEDBY: 'job_executedby',
    RESPONSIBLE_CODE: 'responsible_code',
    EDH_DOCUMENT: 'edh_document',
    EQUIPMENT_DESTINATION: 'equipment_destination',
    EQUIPMENT_DESTINATION_RAD: 'equipment_destination_rad',
    REQUESTED_BY: 'requested_by',
    ASSET_LOC_ISBZ: 'asset_locisbz',
    ASSET_TOP_LOCATION: 'asset_toplocation',
    ASSET_SITE_LOCATION: 'asset_sitelocation',
    JOB_LOC_ISBZ: 'job_locisbz',
    JOB_TOP_LOCATION: 'job_toplocation',
    JOB_LOCATION: 'job_location',
    JOB_SITE_LOCATION: 'job_sitelocation',
    TYPE_OF_TRANSPORT: 'type_of_transport',
    STORAGE_OPERATION: 'storage_operation',
    TRANSPORT_REQUIRED: 'transport_required',
    JOB_REPORTEDBY: 'job_reportedby',
    CADRA_CHECK_PRESENT: 'cadra_check_present',
    ASSOCIATED_RISKS: 'associated_risks',
    JOB_NOT_IN_GROUP: 'job_notigroup',
};

export const CUTG0104_ASSET_LOCATION_FIELDS = {
    ASSET_LOC_ISBZ: 'asset_locisbz',
    ASSET_TOP_LOCATION: 'asset_toplocation',
    ASSET_SITE_LOCATION: 'asset_sitelocation',
    OBJ_LOCATION: 'asset_loc',
};

export const CUTG0104_JOB_LOCATION_FIELDS = {
    JOB_LOC_ISBZ: 'job_locisbz',
    JOB_TOP_LOCATION: 'job_toplocation',
    JOB_SITE_LOCATION: 'job_sitelocation',
    JOB_LOCATION: 'job_location',
};

export const CUTG0104_JOB_SIMILAR_FIELDS = {
    JOB_STATUS: 'job_status',
    JOB_DEPARTMENT: 'job_department',
    JOB_FUTURE_ACTION: 'job_future_action',
    JOB_LOCATION: 'job_location',
    EDH_DOCUMENT: 'edh_document',
    TYPE_OF_TRANSPORT: 'type_of_transport',
    STORAGE_OPERATION: 'storage_operation',
    TRANSPORT_REQUIRED: 'transport_required',
    EQUIPMENT_DESTINATION: 'equipment_destination',
    EQUIPMENT_DESTINATION_RAD: 'equipment_destination_rad',
    ASSOCIATED_RISKS: 'associated_risks',
    JOB_CREATED_BY: 'job_createdby',
};

export const CUTG0104_FIELDS = {
    OBJ_CODE: 'asset_code',
    OBJ_DESC: 'obj_desc',
    EQUIPMENT_ID: 'equipment_id',
    OBJ_SERIALNO: 'obj_serialno',
    JOB_EXECUTEDBY_DESC: 'job_executedby_desc',
    EDHDOCTYPE_CODE: 'edhdoctype_code',
    JOB_REPORTEDBY: 'job_reportedby,',
    ASSET_RPCLASS: 'asset_rpclass',
    EDHDOCID: 'edhdocid',
    ...CUTG0104_FIELDS_REQUEST_PARAMETERS,
    ...CUTG0104_ASSET_LOCATION_FIELDS,
    ...CUTG0104_JOB_LOCATION_FIELDS,
    ...CUTG0104_JOB_SIMILAR_FIELDS,
    ...CUTG0104_HIDDEN_FIELDS,
};

export const CUTG0104_TRANSL_MAP = {
    [CUTG0104_FIELDS.JOB_FUTURE_ACTION]: 'futureAction',
    [CUTG0104_FIELDS.EDHDOCTYPE_CODE]: 'edhTypes',
    [CUTG0104_FIELDS.EDHDOCSTATUS_CODE]: 'edhStatuses',
    [CUTG0104_FIELDS.JOB_STATUS]: 'jobStatuses',
};
