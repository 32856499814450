export const EQUIPMENT_KEYS = {
    BIN: 'bin',
    EQUIPMENT_CODE: 'equipmentCode',
    EQUIPMENT_DESC: 'equipmentDesc',
    HEIGHT: 'height',
    LENGTH: 'length',
    LOT: 'lot',
    MATERIAL: 'material',
    MRC: 'mrc',
    OBJ_LOCATION: 'objLocation',
    OBJ_PARENT_CODE: 'objParentCode',
    PART_CODE: 'partCode',
    RESP_TECHNIQUE: 'respTechnique',
    SERIALNO: 'serialno',
    STORE_CODE: 'storeCode',
    SYSTEM_STATUS: 'systemStatus',
    USER_STATUS: 'userStatus',
    WEIGHT: 'weight',
    WIDTH: 'width',
    LAST_LOCATION_CODE: 'lastLocationCode',
    HIERARCHY_LOCATION_CODE: 'hierarchyLocationCode',
    LABORATORY: 'laboratory',
    EDH_DOCUMENT: 'edhDocument',
    RP_ANALYSIS: 'rpAnalysis',
    FRAGILE: 'fragile',
    FACILITY: 'facility',
    FACILITIES: 'facilities',
    EQUIPMENT_VALUE: 'value',
    OBJ_FAMILY: 'family',
    TYPE_OF_TRANSPORT: 'typeOfTransport',
    RPO: 'rpo',
    RPA_MEASUREMENT: 'rpMeasurement',
    RESP_TECHNIQUE_DESC: 'respTechniqueDesc',
    ASSOCIATED_RISK: 'associatedRisk',
    DETACH: 'detach',
    POST_DEST: 'postDestination',
    POST_DEST_RAD: 'postDestinationIfRad',
    ATTACHED_TO: 'attachedTo',
    OBJ_PARENT_POSITION_CODE: 'objParentPositionCode',
    OBJ_PARENT_SYSTEM_CODE: 'objParentSystemCode',
    IRRADIATION_END_DATE_MIN: 'irradiationEndDateMin',
    IRRADIATION_END_DATE_MAX: 'irradiationEndDateMax',
    HAZARDS: 'hazards',
    HAZARD_CODES: 'hazardCodes',
    HAZARD_COMMENT: 'hazardComment',
    RW_ELIMINATION_PATHWAY: 'rwEliminationPathway',
    RW_RECEPTION_DATE: 'rwReceptionDate',
    DECAY_PERIOD: 'decayPeriod',
};

export const ANALYSIS_KEYS = {
    EQUIPMENT_CODE: 'equipmentCode',
    DEADLINE: 'deadline',
    MEASUREMENT_COMMENTS: 'comment',
    BATCH: 'batch',
    OFFICIAL_REPORT: 'officialReport',
    LAST_IRRADIATION: 'lastIrradiation',
    REQUESTED_ACTIVITY_TIME: 'requestedActivityDate',
    ACTIVE_UNIT: 'activityUnit',
    RP_CLASSIFICATION: 'rpClassification',
    RPA_MEASUREMENT: 'rpaMeasurement',
    EDH_DOCUMENT: 'edhDocument',
    EDH_GENERATION: 'edhGeneration',
    REQUEST_LL: 'requestLL',
    CLASSIFICATION_REQUESTED: 'classificationRequested',
    LL_VALUE: 'llValue',
    LI_VALUE: 'liValue',
    WATER_ELIMINATION_PATHWAY: 'waterEliminationPathway',
};

export const TABLE_DATA_TYPES = {
    AUTOCOMPLETE: 'AUTOCOMPLETE',
    CHECKBOX: 'CHECKBOX',
    SELECT: 'SELECT',
    STATIC: 'STATIC',
    DATE: 'DATE',
    DATE_TIME: 'DATE_TIME',
    INPUT: 'INPUT',
    COUNTER: 'COUNTER',
    QUALIFIER_INPUT: 'QUALIFIER_INPUT',
    LOOKUP_AUTOCOMPLETE: 'LOOKUP_AUTOCOMPLETE',
    LOOKUP_SELECT: 'LOOKUP_SELECT',
    AMMDROPDOWN: 'AMMDROPDOWN',
};

export const RPAR_REQUEST_KEYS = {
    DESCRIPTION: 'description',
    PURPOSE: 'purpose',
    STATUS: 'status',
    SCHEDULED_END_DATE: 'scheduledEnd',
    SCHEDULED_START_DATE: 'scheduledStart',
    WORKORDER: 'workorder',
    COPYCHECKBOX: 'copyCheckbox',
    SAMPLEREVIEW: 'sampleReview',
    SUMMARY: 'summary',
    SELECT_EQUIPMENT: 'select_equipment',
    REQUESTDETAILS: 'requestDetails',
    ANALYSIS_SAMPLE: 'analysissample',
    REQUESTEDBY: 'reportedBy',
    JOBSTATUS: 'jobstatus',
    SELECTANALYSISMESSAGE: 'selectAnalysisMessage',
    RPANALYSISDETAILS: 'rpanalysisdetails',
    SUBMIT_BTN: 'submit',
    EQUIPMENTFORANALYSIS: 'equipmentforanalysis',
    RPMEASUREMENT: 'rpmeasurement',
    RP_CLASSIFICATION: 'rpClassification',
    CLEAR: 'clear',
    ANALYSIS_SUCCESS_MSG: 'createAnRequestSuccess',
    ANALYSIS_EDIT_SUCCESS_MSG: 'editAnRequestSuccess',
    ANALYSIS_PROPS: 'analysisProps',
    REQUESTJOBSLINK: 'REQUESTJOBSLINK',
    CANCEL_REQUEST_CONFIRMATION: 'cancelRequestConfirmation',
    CANCEL_REQUEST_SUCCESS_MSG: 'cancelRequestSuccessMsg',
    CANCEL_REQUEST: 'cancelRequest',
    REPLACE_EQUIPMENT_TITLE: 'replaceEquipmentTitle',
    REPLACE_EQUIPMENT_SOURCE: 'replaceEquipmentSource',
    REPLACE_EQUIPMENT_TARGET: 'replaceEquipmentTarget',
    REPEATED_EQUIPMENT_MESSAGE: 'repeatedEquipmentMessage',
    APPLY_ALL_LINES: 'applyAllLines',
    PARENT_WO: 'parentCode',
};

export const RPAR_PERFORM_KEYS = {
    DESCRIPTION: 'desc',
    WORKORDER: 'workorder',
    WORKORDERDETAILS: 'workorderDetails',
    REQUESTWO: 'requestWo',
    REQUESTWOPARENT: 'requestWoParent',
    ANALYSIS_TYPE: 'analysisType',
    ANALYSIS: 'analysisProps',
    STATUS: 'status',
    LOCATION: 'location',
    PRIORITY: 'priority',
    PERFORMEDBY: 'performedBy',
    PERFORMEDBYDESC: 'performedByDesc',
    REQUESTEDBY: 'reportedBy',
    REQUESTEDBYDESC: 'reportedByDesc',
    DEADLINE: 'deadline',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    SCHEDULING: 'scheduling',
    COMMENTS: 'comments',
    ACQUISITION_TIME: 'acquisitionTime',
    ANALYSIS_JOB_ID: 'analysisJobId',
    ANALYSIS_ID: 'analysisId',
    DIFFICULTY: 'difficulty',
    ANALYSIS_TIME: 'analysisTime',
    RP_CLASSIFICATION: 'rpClassification',
    REPORTWO: 'reportWo',
    ANALYSIS_OFFICER: 'analysisOfficer',
    SCHEDULED_START_DATE: 'scheduledStart',
    SCHEDULED_END_DATE: 'scheduledEnd',
    UPDATEDBY: 'updatedBy',
    UPDATEDBYDESC: 'updatedByDesc',
    UPDATEDDATE: 'updatedDate',
    ANALYSIS_STATUS: 'analysisStatus',
    REQUEST_STATUS: 'requestStatus',
    BATCH: 'batch',
    EQUIPMENT_CODE: 'equipmentCode',
    REQUEST_CODE: 'requestCode',
    LL_VALUE: 'llValue',
    REQUEST_PURPOSE: 'requestPurpose',
    IRAS: 'irasValue',
    TOTAL_BETA_GAMA: 'totalBetaGammaActivity',
    MEASURED_ACTIVITY_DATE: 'measuredActivityDate',
    DECAY_PERIOD: 'decayPeriod',
    NUMBER_OF_COUNTS: 'numberOfCounts',
    HSE_LIMS_API: 'HSE_LIMS_API',
};

export const TRANSLATION_KEYS = {
    ORIGIN: 'origin',
    RP_CLASSIFICATION: 'CLASSIFICATION',
};

export const RPSM_KEYS = {
    PURPOSE: 'purpose',
    PURPOSE_STEP: 'purposeStep',
    CONTAINER_CODE: 'containerCode',
    PARENT_DESC: 'parentDesc',
    OTHER_ID: 'otherID',
    PARENT_RESP_TECHNIQUE: 'parentRespTechnique',
    PARENT_RESP_TECHNIQUE_DESC: 'parentRespTechniqueDesc',
    PARENT_LOCATION: 'parentLocation',
    ORIGIN: 'sampleOriginCode',
    ORIGIN_DESCRIPTION: 'sampleOriginDesc',
    SAMPLE_NO: 'sampleNo',
    SAMPLE_CODE: 'sampleCode',
    SAMPLE_DESC: 'sampleDesc',
    SAMPLING_DATE: 'sampleDate',
    SAMPLING_RESP: 'sampleResp',
    SAMPLING_RESP_DESC: 'sampleRespDesc',
    PARENT_DETAILS: 'parentDetails',
    SAMPLE_DETAILS: 'sampleDetails',
    REQUEST_ANALYSIS: 'requestAnalysis',
    SAMPLE_SELECTION: 'sampleSelection',
    JUMP_TO_REQUEST: 'jumpToRequest',
    SAMPLE_LOCATION: 'sampleLocation',
    SAMPLE_PURPOSE: 'samplePurpose',
    SAVE: 'save',
    SAMPLING_SUCCESS_MSG: 'samplingSuccessMsg',
};

export const TREC_GENERAL_KEYS = {
    NEXT: 'next',
    BACK: 'back',
    SAVE: 'save',
    RELOAD: 'reload',
    EDIT: 'edit',
    CLONE: 'clone',
    SUBMIT: 'submit',
    CREATE: 'create',
    CANCEL: 'cancel',
    CONFIRM: 'confirm',
    CLOSE: 'close',
    REQUIRED_ERROR_MESSAGE: 'requiredErrorMessage',
};

export const RP_MEASUREMENT_KEYS = {
    RP_CLASSIFICATION: 'rpClassification',
    MEASURE_CONTACT: 'measureContactValue',
    MEASURE_CONTACT_QUALIFIER: 'measureContactQualifier',
    MEASURE10CM_QUALIFIER: 'measure10cmQualifier',
    MEASURE10CM: 'measure10cmValue',
    MEASURE40CM_QUALIFIER: 'measure40cmQualifier',
    MEASURE40CM: 'measure40cmValue',
    MEASURE_BGO_QUALIFIER: 'measureBGOQualifier',
    MEASURE_BGO: 'measureBGOValue',
    MEASURE_ALPHA: 'measureAlpha',
    MEASURE_ALPHA_QUALIFIER: 'measureAlphaQualifier',
    MEASURE_BETA: 'measureBeta',
    MEASURE_BETA_QUALIFIER: 'measureBetaQualifier',
    DESTINATION_IF_RADIOACTIVE: 'destinationIfRadioactive',
    CONTAMINATION: 'contamination',
    RPA_MEASUREMENT: 'rpMeasurement',
    ASSOCIATED_RISK: 'associatedRisk',
    RP_CLASSIFICATION_CPY: '_rpClassification',
};

export const RPAR_REQUEST_DTO_KEYLIST = [
    RPAR_REQUEST_KEYS.DESCRIPTION,
    RPAR_REQUEST_KEYS.PURPOSE,
    RPAR_REQUEST_KEYS.WORKORDER,
    RPAR_REQUEST_KEYS.STATUS,
    RPAR_REQUEST_KEYS.SCHEDULED_END_DATE,
    RPAR_REQUEST_KEYS.SCHEDULED_START_DATE,
    RPAR_REQUEST_KEYS.PARENT_WO,
];

export const EQUIPMENT_DTO_KEYLIST = [
    EQUIPMENT_KEYS.BIN,
    EQUIPMENT_KEYS.EQUIPMENT_CODE,
    EQUIPMENT_KEYS.EQUIPMENT_DESC,
    EQUIPMENT_KEYS.HEIGHT,
    EQUIPMENT_KEYS.LENGTH,
    EQUIPMENT_KEYS.LOT,
    EQUIPMENT_KEYS.MATERIAL,
    EQUIPMENT_KEYS.MRC,
    EQUIPMENT_KEYS.OBJ_LOCATION,
    EQUIPMENT_KEYS.OBJ_PARENT_CODE,
    EQUIPMENT_KEYS.PART_CODE,
    EQUIPMENT_KEYS.RESP_TECHNIQUE,
    EQUIPMENT_KEYS.SERIALNO,
    EQUIPMENT_KEYS.STORE_CODE,
    EQUIPMENT_KEYS.SYSTEM_STATUS,
    EQUIPMENT_KEYS.USER_STATUS,
    EQUIPMENT_KEYS.WEIGHT,
    EQUIPMENT_KEYS.WIDTH,
    EQUIPMENT_KEYS.LAST_LOCATION_CODE,
    EQUIPMENT_KEYS.HIERARCHY_LOCATION_CODE,
    EQUIPMENT_KEYS.OBJ_FAMILY,
    EQUIPMENT_KEYS.EQUIPMENT_VALUE,
    EQUIPMENT_KEYS.DETACH,
    EQUIPMENT_KEYS.ATTACHED_TO,
    EQUIPMENT_KEYS.OBJ_PARENT_POSITION_CODE,
    EQUIPMENT_KEYS.OBJ_PARENT_SYSTEM_CODE,
    EQUIPMENT_KEYS.IRRADIATION_END_DATE_MIN,
    EQUIPMENT_KEYS.IRRADIATION_END_DATE_MAX,
    EQUIPMENT_KEYS.FACILITIES,
    EQUIPMENT_KEYS.HAZARDS,
];

export const SAMPLE_DTO_KEYLIST = [
    RPSM_KEYS.SAMPLING_DATE,
    RPSM_KEYS.SAMPLING_LOCATION,
    RPSM_KEYS.ORIGIN,
    RPSM_KEYS.ORIGIN_DESCRIPTION,
    RPSM_KEYS.SAMPLE_PURPOSE,
    RPSM_KEYS.SAMPLING_RESP,
    RPSM_KEYS.SAMPLING_RESP_DESC,
    RPSM_KEYS.SAMPLE_LOCATION,
];

export const EQUIPMENT_PROPERTIES_DTO_KEYLIST = [
    EQUIPMENT_KEYS.FRAGILE,
    EQUIPMENT_KEYS.FACILITY,
    EQUIPMENT_KEYS.EDH_DOCUMENT,
    EQUIPMENT_KEYS.TYPE_OF_TRANSPORT,
    EQUIPMENT_KEYS.LABORATORY,
    EQUIPMENT_KEYS.RPA_MEASUREMENT,
    EQUIPMENT_KEYS.RPO,
    EQUIPMENT_KEYS.ASSOCIATED_RISK,
    EQUIPMENT_KEYS.POST_DEST,
    EQUIPMENT_KEYS.POST_DEST_RAD,
];

export const RP_MEASUREMENT_DTO_KEYLIST = [
    EQUIPMENT_KEYS.EQUIPMENT_CODE,
    RP_MEASUREMENT_KEYS.RP_CLASSIFICATION,
    RP_MEASUREMENT_KEYS.MEASURE_CONTACT,
    RP_MEASUREMENT_KEYS.MEASURE_CONTACT_QUALIFIER,
    RP_MEASUREMENT_KEYS.MEASURE10CM,
    RP_MEASUREMENT_KEYS.MEASURE10CM_QUALIFIER,
    RP_MEASUREMENT_KEYS.MEASURE_BGO,
    RP_MEASUREMENT_KEYS.MEASURE_BGO_QUALIFIER,
    RP_MEASUREMENT_KEYS.MEASURE_ALPHA,
    RP_MEASUREMENT_KEYS.MEASURE_ALPHA_QUALIFIER,
    RP_MEASUREMENT_KEYS.MEASURE_BETA,
    RP_MEASUREMENT_KEYS.MEASURE_BETA_QUALIFIER,
    // RP_MEASUREMENT_KEYS.ASSOCIATED_RISK, // right now, the associated risks come from EquipmentProperties
    RP_MEASUREMENT_KEYS.CONTAMINATION,
    RP_MEASUREMENT_KEYS.RPA_MEASUREMENT,
    EQUIPMENT_KEYS.ASSOCIATED_RISK,
];

export const ANALYSIS_PROPERTIES_DTO_KEYLIST = [
    ANALYSIS_KEYS.DEADLINE,
    ANALYSIS_KEYS.OFFICIAL_REPORT,
    ANALYSIS_KEYS.LAST_IRRADIATION,
    ANALYSIS_KEYS.REQUESTED_ACTIVITY_TIME,
    ANALYSIS_KEYS.ACTIVE_UNIT,
    ANALYSIS_KEYS.MEASUREMENT_COMMENTS,
    ANALYSIS_KEYS.BATCH,
    ANALYSIS_KEYS.RPA_MEASUREMENT,
    ANALYSIS_KEYS.EDH_DOCUMENT,
    ANALYSIS_KEYS.EDH_GENERATION,
    ANALYSIS_KEYS.REQUEST_LL,
    ANALYSIS_KEYS.CLASSIFICATION_REQUESTED,
];

export const DATE_VALUE_KEYLIST = [
    ANALYSIS_KEYS.DEADLINE,
    RPAR_REQUEST_KEYS.SCHEDULED_START_DATE,
    RPAR_REQUEST_KEYS.SCHEDULED_END_DATE,
    // RPAR_PERFORM_KEYS.MEASURED_ACTIVITY_DATE, // This field is now a normal date
];

export const DATE_TIME_VALUE_KEYLIST = [
    ANALYSIS_KEYS.REQUESTED_ACTIVITY_TIME,
    RPAR_PERFORM_KEYS.START_DATE,
    RPAR_PERFORM_KEYS.END_DATE,
    RPAR_PERFORM_KEYS.UPDATEDDATE,
    RPAR_PERFORM_KEYS.SCHEDULED_START_DATE,
    RPAR_PERFORM_KEYS.SCHEDULED_END_DATE,
];

export const DATE_FORMAT = {
    EAMCOMPONENT: 'DD-MMM-YYYY',
    DATE_FNS: 'dd-MMM-yyyy',
    JAVA: 'dd-MMM-yyyy',
    DATE_FNS_SHORT: 'dd/MM/yyyy',
    HUMAN_DATE_FNS: 'EEE, dd MMM yyyy',
};

export const DATE_TIME_FORMAT = {
    EAMCOMPONENT: 'DD-MMM-YYYY hh:mm',
    DATE_FNS: 'dd-MMM-yyyy HH:mm',
    JAVA: 'dd-MMM-yyyy HH:mm',
    HUMAN_DATE_FNS: 'EEE, dd MMM yyyy HH:mm',
};

export const REQUEST_ACTIONS = {
    CLONE: 'CLONE',
    CREATE: 'CREATE',
    EDIT: 'EDIT',
    DELETE: 'DELETE',
};

export const REQUEST_MODES = {
    CLONING: 'CLONING',
    CREATING: 'CREATING',
    EDITING: 'EDITING',
    READING: 'READING',
};

export const GRID_ROW_COLORS = {
    red: {
        color: '#bf360C',
        backgroundColor: 'rgba(251,233,231,0.7)',
    },
    green: {
        color: '#00701a',
        backgroundColor: 'rgba(118,210,117,0.1)',
    },
    orange: {
        color: '#FF7F2B',
        backgroundColor: 'rgba(255,183,42,0.13)',
    },
    blue: {
        color: '#1976d2',
        backgroundColor: 'rgba(227,242,253,0.5)',
    },
    blueOnWhite: {
        color: '#1976d2',
    },
    yellow: {
        color: '#330a00',
        backgroundColor: 'rgba(255, 255, 204, 0.85)',
    },
    pink: {
        color: '#8f3319',
        backgroundColor: 'rgba(252, 204, 239, 0.5)',
    },
    grey: {
        color: '#A0A0A0',
        backgroundColor: 'rgba(220, 220, 220, 0.7)',
    },
    blackOnGrey: {
        color: '#404040',
        backgroundColor: 'rgba(220, 220, 220, 0.7)',
    },
};

export const RWP_PROCESSING_FIELDS_PROD = true;

export const RPA_NEW_FIELDS_PROD = true;

export const FIRSTTIMECLOSED_PROD = false;

export const RADIOACTIVE_VERIF_PROD = false;

export const ANALYSIS_TYPES = {
    GAMMASPEC: 'GAMMASPEC',
    RADOS: 'RADOS',
    LIQUIDSCINT: 'LIQUIDSCINT',
    PHYSCHEM: 'PHYSCHEM',
    ABCOUNT: 'ABCOUNT',
    ALPHASPEC: 'ALPHASPEC',
};

export const CLASSIFICATION_REQUESTED_TYPES = {
    H2O: 'H2O',
    TFA: 'TFA',
    FMA: 'FMA',
    CLR: 'CLR',
    CL: 'CL',
};
