import * as ACTIONS from '../actions/rpmrActions';
import Tools from '../tools/Tools';

export const RPMR = 'RPMR';

const getInitialState = () => ({
    blockUI: false,
    createdRPMs: false,
    properties: {},
    equipmentMap: {},
    allowMultipleResp: true,
});

export default (state = getInitialState(), action) => {
    switch (action.type) {
        case ACTIONS.RESET_RPMR:
            return getInitialState();
        case ACTIONS.UPDATE_RPMR_BLOCK_UI:
            return {
                ...state,
                blockUI: action.value,
            };
        case ACTIONS.UPDATE_RPMR:
            return {
                ...state,
                properties: {
                    ...state.properties,
                    ...action.value,
                },
            };
        case ACTIONS.UPDATE_RPMR_SELECTED_EQUIPMENT:
            return {
                ...state,
                equipmentMap: {
                    ...action.value.reduce(
                        (acc, code) => ({
                            ...acc,
                            [code]: state.equipmentMap[code] || {},
                        }),
                        {}
                    ),
                },
            };
        case ACTIONS.ADD_RPMR_SELECTED_EQUIPMENT:
            return {
                ...state,
                equipmentMap: {
                    ...state.equipmentMap,
                    [action.value]: state.equipmentMap[action.value] || {},
                },
            };
        case ACTIONS.UPDATE_RPMR_EQUIPMENT:
            return {
                ...state,
                equipmentMap: {
                    ...state.equipmentMap,
                    ...action.eqCodeList
                        .map((eqCode) => ({
                            [eqCode]: {
                                ...state.equipmentMap[eqCode],
                                ...action.object,
                            },
                        }))
                        .reduce((acc, el) => ({ ...acc, ...el }), {}),
                },
            };
        case ACTIONS.UPDATE_RPMR_EQUIPMENT_MAP:
            return {
                ...state,
                equipmentMap: {
                    ...state.equipmentMap,
                    ...action.value,
                },
                ...(action.fetched ? { fetchedEquipmentMap: action.value } : {}),
            };
        case ACTIONS.UPDATE_APPLY_ALL_LINES:
            return {
                ...state,
                applyAllLines: action.value,
            };
        case ACTIONS.UPDATE_CREATED_RPMS:
            return {
                ...state,
                createdRPMs: action.value,
            };
        case ACTIONS.UPDATE_ALLOW_MULTIPLE_RESP:
            return {
                ...state,
                allowMultipleResp: action.value,
            };
        default:
            return state;
    }
};

const getProperties = (state) => () => state.properties;

const getEquipmentList = (state) => () => Object.keys(state.equipmentMap);

const getEquipmentMap = (state) => () => state.equipmentMap;

const isBlockUI = (state) => () => state.blockUI;

const isApplyAllLines = (state) => () => state.applyAllLines;

const isMultipleEquipment = (state) => () => getEquipmentList(state)().length > 1;

const getCreatedRPMs = (state) => () => state.createdRPMs;

const getFetchedEquipmentMap = (state) => () => state.fetchedEquipmentMap;

const isAllowMultipleResp = (state) => () => state.allowMultipleResp;

const rpmrGetters = {
    getProperties,
    getEquipmentList,
    getEquipmentMap,
    isBlockUI,
    isApplyAllLines,
    isMultipleEquipment,
    getCreatedRPMs,
    getFetchedEquipmentMap,
    isAllowMultipleResp,
};

export const rpmrGettersWithState = (state) => state && Tools.applyToFields(rpmrGetters, (func) => func(state[RPMR]));
