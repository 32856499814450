import { EQUIPMENT_KEYS_DTO, RPSM_KEYS_DTO, RWP_KEYS, RWP_KEYS_DTO } from '../../ui/pages/rwprocessing/RWPConstants';
import Tools from '../Tools';
import WS from './WS';
import {
    equipmentsObjectHazardInputAdapter,
    equipmentHazardInputAdapter,
    equipmentsObjectHazardOutputAdapter,
} from '../Hazards';

const rpmAdapter = (outputWaste) =>
    Tools.applyToFields(outputWaste, (ow) => Tools.filterObjectFieldsFromList(ow, RPSM_KEYS_DTO));

const rwpAdapter = (rwpProperties, outputwaste) => {
    const rwp = Tools.filterObjectFieldsFromList(rwpProperties, RWP_KEYS_DTO);
    rwp[RWP_KEYS.OUTPUT_EQUIPMENT] =
        outputwaste === undefined
            ? undefined
            : Tools.applyToFields(outputwaste, (equipment) =>
                  Tools.filterObjectFieldsFromList(equipment, EQUIPMENT_KEYS_DTO)
              );
    return rwp;
};

class WSRWPProcessing {
    getProcessingJob = ({ jobID, applicationData }, config = {}) =>
        WS._get(`/rwp/processing/jobs/${jobID}`, config).then((response) => {
            if (
                applicationData.isHazardsFeatureActive &&
                response &&
                response.body &&
                response.body.data &&
                typeof response.body.data === 'object'
            ) {
                response.body.data[RWP_KEYS.OUTPUT_EQUIPMENT] = equipmentsObjectHazardInputAdapter({
                    equipments: response.body.data[RWP_KEYS.OUTPUT_EQUIPMENT],
                    applicationData,
                });
            }

            return response;
        });

    getInputEquipmentSuggestions = async ({ jobID, query }, config = {}) => {
        const result = await WS._get(`/rwp/processing/equipment/input?query=${query}&jobID=${jobID}`, config);
        return result.body.data;
    };

    getInputEquipmentProperties = ({ jobID }, config = {}) =>
        WS._get(`/rwp/processing/jobs/${jobID}/input/properties`, config);

    getOutputEquipmentSuggestions = async ({ jobID, query }, config = {}) => {
        const result = await WS._get(`/rwp/processing/equipment/output?query=${query}&jobID=${jobID}`, config);
        return result.body.data;
    };

    getContentEquipmentSuggestions = ({ jobID, query, containerCode }, config = {}) =>
        WS._get(`/rwp/processing/equipment/content?query=${query}&jobID=${jobID}&container=${containerCode}`, config);

    getEquipment = ({ equipmentCode, applicationData }, config) =>
        WS._get(`/rwp/processing/equipment/${equipmentCode}`, config).then((response) => {
            if (
                applicationData.isHazardsFeatureActive &&
                response &&
                response.body &&
                response.body.data &&
                typeof response.body.data === 'object'
            ) {
                response.body.data = equipmentHazardInputAdapter({
                    equipment: response.body.data,
                    applicationData,
                });
            }

            return response;
        });

    getEquipmentConfiguration = async ({ equipmentCode }, config) =>
        (await WS._get(`/rwp/processing/equipment/${equipmentCode}/eqpconfiguration`, config)).body.data;

    updateEquipment = ({ equipmentMap }, config) => {
        const filtered = Tools.applyToFields(equipmentMap, (eq) =>
            Tools.filterObjectFieldsFromList(eq, EQUIPMENT_KEYS_DTO)
        );
        return WS._put(`/rwp/processing/equipment/`, filtered, config);
    };

    createProcessingJob = ({ rwpProperties }, config) =>
        WS._post(`/rwp/processing/jobs`, rwpAdapter(rwpProperties), config);

    updateProcessingJob = ({ jobID, rwpProperties, outputEquipmentMap, applicationData }, config) => {
        const rwp = rwpAdapter(
            rwpProperties,
            equipmentsObjectHazardOutputAdapter({ equipments: outputEquipmentMap, applicationData })
        );
        return WS._put(`/rwp/processing/jobs/${jobID}`, rwp, config);
    };

    getDropdownValues = (module, config = {}) => WS._get(`/rwp/processing/dropdowns/values?module=${module}`, config);

    getValuesGrid = (config = {}) => WS._get(`/rwp/processing/valuesgrid`, config);

    createRPMs = ({ outputWaste }, config) => WS._post(`/rwp/processing/rpm/multiple`, rpmAdapter(outputWaste), config);

    updateRPMs = ({ outputWaste, rpmMap, isFinish }, config) =>
        WS._put(
            `/rwp/processing/rpm/multiple?isFinish=${isFinish}`,
            { equipmentMeasurementMap: rpmAdapter(outputWaste), rpmMap },
            config
        );

    associateRPM = ({ jobID, rpmMap }, config) => WS._put(`/rwp/processing/jobs/${jobID}/rpm`, rpmMap, config);

    prepareFinish = ({ jobID }, config) => WS._put(`/rwp/processing/jobs/${jobID}/preparefinish`, null, config);

    finishRWP = ({ jobID }, config) => WS._put(`/rwp/processing/jobs/${jobID}/finish`, null, config);

    validateRWP = ({ jobID }, config) => WS._get(`/rwp/processing/jobs/${jobID}/validate`, config);

    cancelRWP = ({ jobID }, config) => WS._put(`/rwp/processing/jobs/${jobID}/cancel`, null, config);

    checkAsync = ({ jobID }, config) => WS._get(`/rwp/processing/jobs/${jobID}/async/check`, config);

    markAsync = ({ jobID }, config) => WS._put(`/rwp/processing/jobs/${jobID}/async/mark`, null, config);

    createVirtualCodes = async (quantity, config) =>
        (await WS._post(`/rwp/processing/virtualcodes`, [quantity], config))?.body.data;

    attachVirtualCodeInside = async (containerCode, config) =>
        (await WS._put(`/rwp/processing/container/attachvirtual`, [containerCode], config))?.body.data;
}

export default new WSRWPProcessing();
