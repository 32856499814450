import { Grid } from '@material-ui/core';
import * as AllIcons from '@material-ui/icons';

const IconPage = () => (
    <>
        <Grid container style={{ width: '100%', height: '100%', overflowY: 'scroll' }} spacing={1}>
            {Object.keys(AllIcons)
                .filter((iconname) => !/.*(Rounded|Outlined|Sharp|TwoTone)$/.test(iconname))
                .map((iconname) => {
                    const Icon = AllIcons[iconname];
                    return (
                        <div
                            style={{
                                border: '2px solid #669966',
                                height: '115px',
                                width: '90px',
                                fontSize: 12,
                                textAlign: 'center',
                                wordBreak: 'break-all',
                                margin: '2px',
                            }}
                        >
                            <span>{iconname}</span>
                            <Icon style={{ scale: 3, height: '80px', width: '80px' }} />
                        </div>
                    );
                })}
        </Grid>
    </>
);

export default IconPage;
