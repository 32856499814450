import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import React from 'react';
import { handleError } from '../../../tools/TrecNotifications';
import WS from '../../../tools/rest/WSBZInventory';
import TRECButton from '../../components/buttons/TRECButton';
import EAMGridNoOverflow from '../../components/grid/EAMGridNoOverflow';
import gridNoOverflowClasses from '../../components/grid/EAMGridNoOverflow.module.css';
import { getActionDropdownValues, getResultDropdownValues } from './steps/BZInventoryUtils';

class BZPastInventory extends React.Component {
    state = {};

    componentDidMount() {
        const { inventory } = this.props;
        this.grid.setFilter({
            fieldName: 'bzi_sequence',
            fieldValue: inventory,
            operator: 'EQUALS',
        });

        WS.getPastInventoryMetadata(inventory)
            .then((response) => {
                this.setState(() => ({
                    inventoryData: response.body.data,
                }));
            })
            .catch(handleError);
    }

    _cellRenderer = (cell) => {
        const { translations } = this.props;
        if (cell.t === 'bzr_action') {
            return (
                <Typography>
                    {getActionDropdownValues(translations).filter((element) => element.code === cell.value)[0].desc}
                </Typography>
            );
        }

        if (cell.t === 'bzr_result') {
            return (
                <Typography>
                    {getResultDropdownValues(translations).filter((element) => element.code === cell.value)[0].desc}
                </Typography>
            );
        }

        return false;
    };

    render() {
        const { goBackToPastInventorySelection, inventoryResultsGridID, translations } = this.props;
        const { inventoryData } = this.state;
        return (
            <div className={gridNoOverflowClasses.outerBlock}>
                {inventoryData && (
                    <Paper style={{ margin: 10 }}>
                        <div>
                            <div style={{ padding: 5 }}>
                                <strong>{translations.CREATED_BY}</strong>: {inventoryData.createdBy}
                            </div>
                            <div style={{ padding: 5 }}>
                                <strong>{translations.COMMENT}</strong>: {inventoryData.comment}
                            </div>
                            <div style={{ padding: 5 }}>
                                <strong>{translations.BUFFER_ZONE}</strong>: {inventoryData.bufferZoneCode}
                            </div>
                            <div style={{ padding: 5 }}>
                                <strong>{translations.COMPLETED}</strong>:{' '}
                                {new Date(inventoryData.created).toDateString()}
                            </div>
                        </div>
                        <div style={{ padding: 5 }}>
                            <TRECButton onClick={goBackToPastInventorySelection}>
                                <ArrowBack />
                                {translations.BACK}
                            </TRECButton>
                        </div>
                    </Paper>
                )}
                <EAMGridNoOverflow
                    onRef={(ref) => {
                        this.grid = ref;
                    }}
                    gridId={inventoryResultsGridID}
                    showDataspySelection={false}
                    cellRenderer={this._cellRenderer}
                    heightFilterNotVisible="140px"
                />
            </div>
        );
    }
}

export default BZPastInventory;
