/* eslint-disable react/no-this-in-sfc */ // false positive in cellRenderer only
import React from 'react';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import MenuIcon from '@material-ui/icons/Menu';
import { FilterRemoveOutline } from 'mdi-material-ui';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import moment from 'moment/moment';
import Checkbox from '@material-ui/core/Checkbox';
import BlockUi from 'react-block-ui';
import Typography from '@material-ui/core/Typography';
import Attachment from '@material-ui/icons/Attachment';
import EAMLookup from 'eam-components/dist/ui/components/lookup/EAMLookup';
import { withStyles } from '@material-ui/core/styles/index';
import TRECSearchPage from '../TRECSearchPage';
import WSAutocomplete from '../../../tools/rest/WSAutocomplete';
import SearchPanel from '../../components/searchpanel/SearchPanel';
import SearchPanelColumn from '../../components/searchpanel/SearchPanelColumn';
import SearchPanelControls from '../../components/searchpanel/SearchPanelControls';
import WSRWChecks from '../../../tools/rest/WSRWChecks';
import EquipmentHazardContainer from '../../components/eqphazard/EquipmentHazardContainer';
import Tools from '../../../tools/Tools';
import TRECButton from '../../components/buttons/TRECButton';
import { trecPageStyles } from '../TRECPage';
import { DATE_FORMAT } from '../../../enums/Constants';
import EAMGridNoOverflow from '../../components/grid/EAMGridNoOverflow';
import gridNoOverflowClasses from '../../components/grid/EAMGridNoOverflow.module.css';
import { handleError, showError, showSuccess } from 'tools/TrecNotifications';

const labelStyleRight = {
    width: 'auto',
    minWidth: 70,
    textAlign: 'right',
};

class RWChecks extends TRECSearchPage {
    formFields = [];

    componentWillUnmount() {
        document.removeEventListener('keydown', this.performSearchOnEnterKey.bind(this), false);
    }

    performSearchOnEnterKey(event) {
        this.onKeyDown(event);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.performSearchOnEnterKey.bind(this), false);
        // Parent mount
        super.componentDidMount();
        // Check parameters in the URL
        this._assignRequestParameters();
    }

    _assignRequestParameters = () => {
        // Job Status (by default pending)
        const status = Tools.getURLParameterByName('status');
        if (status) {
            this.updateProperty('job_status', status, 'EQUALS', 'VARCHAR', true);
        }

        // Equipment location
        const location = Tools.getURLParameterByName('location');
        if (location) {
            this.updateProperty('eqpLocation', location, 'BEGINS', 'VARCHAR');
        }

        // Equipment
        const equipment = Tools.getURLParameterByName('equipment');
        if (equipment) {
            this.updateProperty('obj_code', equipment, 'BEGINS', 'VARCHAR');
        }

        // Job Created
        const created = Tools.getURLParameterByName('created');
        if (created) {
            this.updateProperty('created', created, 'GREATER_THAN', 'VARCHAR');
        }

        // Work Worder Created
        const job = Tools.getURLParameterByName('job_number');
        if (job) {
            this.updateProperty('evt_code', job, 'EQUALS', 'VARCHAR');
        }

        // Work Worder Created
        const rwResult = Tools.getURLParameterByName('job_result');
        if (rwResult) {
            this.updateProperty('rw_result', rwResult, 'EQUALS', 'VARCHAR', true);
        }
    };

    _hiddenColumns = () => {
        let hiddenCols = [];

        switch (this.props.lang) {
            case 'EN':
                hiddenCols = [
                    'job_status',
                    'job_status_desc_fr',
                    'classification',
                    'contamination',
                    'evt_code',
                    'evt_created',
                    'eqp_locisbz',
                    'eqp_toplocation',
                    'dose_val',
                    'evt_parent',
                    'obj_class',
                    'rp_meas_job',
                    'eqp_sitelocation',
                    'transp_date',
                    'child_classification',
                    'child_contamination',
                    'child_risks',
                ];
                break;
            case 'FR':
                hiddenCols = [
                    'job_status',
                    'job_status_desc_en',
                    'classification',
                    'contamination',
                    'evt_code',
                    'evt_created',
                    'eqp_locisbz',
                    'eqp_toplocation',
                    'dose_val',
                    'evt_parent',
                    'obj_class',
                    'rp_meas_job',
                    'eqp_sitelocation',
                    'transp_date',
                    'child_classification',
                    'child_contamination',
                    'child_risks',
                ];
                break;

            default:
                break;
        }

        return hiddenCols;
    };

    _updateValueInRow = (row, key, value) => {
        Tools.setGridCellValueFromRow(row, key, value);
        this.setState({ [key]: value });
    };

    _cellRenderer(writeAccess) {
        return (cell, row) => {
            const { applicationData } = this.props;
            const { t, value } = cell;
            if (t === 'hazards_list') {
                return (
                    <EquipmentHazardContainer
                        classification={Tools.getGridCellValueFromRow(row, 'classification')}
                        hazards={Tools.getGridCellValueFromRow(row, 'hazards_list')}
                    />
                );
            }
            if (t === 'risks') {
                // Get cell value for risks
                const risks = `${value},${Tools.getGridCellValueFromRow(row, 'child_risks')}`;
                // Get cell value for classification
                const classification =
                    Tools.getGridCellValueFromRow(row, 'child_classification') === 'Radioactive'
                        ? 'Radioactive'
                        : Tools.getGridCellValueFromRow(row, 'classification');
                // Cell value for contamination
                const contamination =
                    Tools.getGridCellValueFromRow(row, 'child_contamination') === 'Yes'
                        ? 'Yes'
                        : Tools.getGridCellValueFromRow(row, 'contamination');
                return (
                    <EquipmentHazardContainer
                        classification={classification}
                        contamination={contamination}
                        risks={risks}
                    />
                );
            }
            if (t === 'rw_result') {
                // Get cell value for Status
                const jobStatusFilter = Tools.getGridCellValueFromRow(row, 'job_status');
                if (writeAccess && jobStatusFilter && jobStatusFilter === 'R') {
                    return <Checkbox onChange={(event) => this._toggleRowCheckbox(row, event.target.checked)} />;
                }
            } else if (t === 'eqp_destination') {
                // Get cell value for Status
                const jobStatusFilter = Tools.getGridCellValueFromRow(row, 'job_status');
                if (writeAccess && jobStatusFilter && jobStatusFilter === 'R') {
                    const rwdestinations = applicationData.rwdestinations.map((rwd) => ({
                        code: rwd,
                        desc: rwd,
                    }));
                    return (
                        <EAMSelect
                            formFields={this.formFields}
                            valueKey="eqp_destination"
                            value={Tools.getGridCellValueFromRow(row, 'eqp_destination')}
                            values={rwdestinations}
                            updateProperty={(key, val) => this._updateValueInRow(row, key, val)}
                        />
                    );
                }
            } else if (t === 'obj_code') {
                // Link to the equipment in EAMLight
                return (
                    <Typography>
                        <a
                            target="_blank"
                            href={`${process.env.REACT_APP_FRONTEND}equipment/${value}`}
                            rel="noreferrer"
                        >
                            {value}
                        </a>
                    </Typography>
                );
            }
            return false;
        };
    }

    // Go to perform page
    _handlePerform = (jobIds) => {
        const queryParams = jobIds.map((jobId) => `wo=${jobId}`).join('&');
        this.props.history.push(`/rwcperform?${queryParams}`);
    };

    // Click on Edit button, in a row of the grid
    _handleEdit = ({ cell }) => {
        // Normal perform
        this._handlePerform([cell.filter((column) => column.t === 'evt_code')[0].value]);
    };

    _toggleRowCheckbox = (row, checked) => {
        const selectedRows = {
            ...this.state.selectedRows,
        };

        if (checked) {
            selectedRows[row.id] = row.cell;
        } else {
            delete selectedRows[row.id];
        }

        this.setState({ selectedRows });
    };

    _convertRwChecksRowIntoData = (rowcell) =>
        rowcell.reduce((acc, cur) => {
            switch (cur.t) {
                case 'obj_code':
                    acc.equipmentId = cur.value;
                    break;
                case 'obj_class':
                    acc.equipmentClass = cur.value;
                    break;
                case 'eqp_destination':
                    acc.destination = cur.value;
                    break;
                case 'evt_code':
                    acc.jobNumber = cur.value;
                    break;
                default:
                    break;
            }
            return acc;
        }, {});

    _updateRWChecksData = () => {
        const { translations } = this.props;
        if (this._validate()) {
            this.props.openConfirmDialog(
                {
                    title: translations.CONFIRM,
                    message: translations.AREYOUSURE,
                    cancelButtonLabel: translations.CANCEL,
                    confirmButtonLabel: translations.CONFIRM,
                    waitForCompletion: true,
                },
                () => {
                    const rwChecksRequests = [];

                    Object.values(this.state.selectedRows).forEach((element) => {
                        // convert the row data structure to a classical Javascript object
                        const rwChecksData = this._convertRwChecksRowIntoData(element);
                        rwChecksRequests.push(WSRWChecks.processRWCheckOk(rwChecksData));
                    });

                    return Promise.all(rwChecksRequests)
                        .then(() => {
                            this.setState(
                                () => ({ processing: false, selectedRows: {} }),
                                () => {
                                    const message = this.props.translations.JOBDATAUPD;
                                    showSuccess(message, '', null);
                                    this.onSearchClick();
                                }
                            );
                        })
                        .catch((error) => {
                            this.setState(
                                () => ({ processing: false }),
                                () => {
                                    handleError(error);
                                }
                            );
                        });
                }
            );
        }
    };

    _validate = () => {
        // Validations ok from the beginning
        let isValid = true;
        // Iterate over the required fields and validate them
        Object.keys(this.formFields).forEach((key) => {
            if (this.formFields[key] && this.formFields[key].validate && !this.formFields[key].validate()) {
                isValid = false;
            }
        });
        if (!isValid) {
            // Invalid data in location
            const { translations } = this.props;
            showError(translations.LOCDETINV);
        }
        return isValid;
    };

    gridRequestAdapter = (gridRequest) => {
        let gridFilters = [...gridRequest.gridFilter];
        gridFilters = gridFilters.map((gridFilter) => {
            const value = gridFilter.fieldValue;

            switch (gridFilter.fieldName) {
                case 'eqpLocation':
                    return this._getGridFilterLocation(
                        gridFilter,
                        value,
                        'eqp_locisbz',
                        'eqp_toplocation',
                        'eqp_sitelocation',
                        'obj_location'
                    );
                case 'created': {
                    let date = moment();
                    if (gridFilter.fieldValue === 'LASTDAY') {
                        date = date.subtract(1, 'day');
                    } else if (gridFilter.fieldValue === 'LASTWEEK') {
                        date = date.subtract(1, 'week');
                    } else if (gridFilter.fieldValue === 'LASTMONTH') {
                        date = date.subtract(1, 'month');
                    }
                    return {
                        ...gridFilter,
                        fieldName: 'evt_created',
                        fieldValue: date.format(DATE_FORMAT.EAMCOMPONENT),
                    };
                }
                default:
                    return gridFilter;
            }
        });

        return {
            ...gridRequest,
            gridFilter: gridFilters,
            gridSort: [...gridRequest.gridSort, { sortType: 'DESC', sortBy: 'evt_created' }],
        };
    };

    edhDocumentHandler = () => {
        const { constants } = this.props;
        this.props.history.push(
            `/edhdocmng/?futureaction=${constants.futureActionWasteDeclare}&datecompleted=LASTWEEK`
        );
    };

    renderPage(writeAccess) {
        const { screenData, lang, classes } = this.props;

        return (
            <BlockUi tag="div" blocking={this.state.processing} className={gridNoOverflowClasses.outerBlock}>
                <SearchPanel title={this.props.translations.SEARCH_CRITERIA} onPanelChange={this._onPanelChange}>
                    <SearchPanelColumn>
                        {/* Equipment Location */}
                        <EAMLookup
                            right={1}
                            top={13}
                            width={26}
                            height={26}
                            gridId={this.props.applicationData.locationRaisinGridID}
                            keys={{
                                code: 'obj_code',
                                mapCodeTo: 'eqpLocation',
                            }}
                            updateProperty={(key, value) => this.updateProperty(key, value, 'BEGINS', 'VARCHAR')}
                            value={this.state.filters.eqpLocation}
                        >
                            <EAMSelect
                                elementInfo={screenData[lang].assetFields.eqpLocation}
                                valueKey="eqpLocation"
                                creatable
                                value={this.state.filters.eqpLocation}
                                values={screenData[lang].lists.locationsList}
                                labelStyle={labelStyleRight}
                                updateProperty={(key, value) => this.updateProperty(key, value, 'BEGINS', 'VARCHAR')}
                                arrowRenderer={() => <span />}
                            />
                        </EAMLookup>
                        {/* Equipment */}
                        <EAMAutocomplete
                            elementInfo={screenData[lang].woFields.equipment}
                            value={this.state.filters.obj_code}
                            valueKey="obj_code"
                            multi
                            creatable
                            labelStyle={labelStyleRight}
                            updateProperty={(key, value) => this.updateProperty(key, value, 'BEGINS', 'VARCHAR')}
                            loadOptions={WSAutocomplete.autocompleteEquipment}
                            columnsWidth={['0', '100%']}
                            selectStyle={classes.selectStyle}
                            autoSelectSingleElement
                        />
                        {/* Workorder */}
                        <EAMAutocomplete
                            elementInfo={screenData[lang].woFields.workordernum}
                            valueKey="job_number"
                            value={this.state.filters.evt_code}
                            labelStyle={labelStyleRight}
                            updateProperty={(key, value) => this.updateProperty('evt_code', value, 'EQUALS', 'VARCHAR')}
                        />
                    </SearchPanelColumn>
                    <SearchPanelColumn>
                        {/* Status */}
                        <EAMSelect
                            elementInfo={screenData[lang].woFields.workorderstatus}
                            valueKey="job_status"
                            value={this.state.filters.job_status}
                            values={screenData[lang].lists.jobStatuses.filter(
                                (f) => f.code !== 'RC' && f.code !== 'TX'
                            )}
                            labelStyle={labelStyleRight}
                            updateProperty={(key, value) => this.updateProperty(key, value, 'EQUALS', 'VARCHAR')}
                        />
                        {/* Job created */}
                        <EAMSelect
                            label={this.props.translations.JOBCREATED}
                            valueKey="created"
                            value={this.state.filters.created}
                            values={screenData[lang].lists.createdList}
                            labelStyle={labelStyleRight}
                            updateProperty={(key, value) => this.updateProperty(key, value, 'GREATER_THAN', 'VARCHAR')}
                        />
                        {/* RW Result */}
                        <EAMAutocomplete
                            label={this.props.translations.RESULT}
                            valueKey="job_result"
                            value={this.state.filters.rw_result}
                            labelStyle={labelStyleRight}
                            updateProperty={(key, value) =>
                                this.updateProperty('rw_result', value, 'EQUALS', 'VARCHAR')
                            }
                        />
                    </SearchPanelColumn>
                    <SearchPanelControls>
                        <TRECButton
                            variant="contained"
                            color="default"
                            onClick={() => {
                                this.props.history.push('/menu');
                            }}
                        >
                            <MenuIcon />
                            {this.props.translations.GOTOMENU}
                        </TRECButton>
                        <TRECButton variant="contained" onClick={this.onSearchClick}>
                            <SearchIcon />
                            {this.props.translations.SEARCH}
                        </TRECButton>
                        <TRECButton variant="contained" onClick={this.onClearClick}>
                            <FilterRemoveOutline />
                            {this.props.translations.RESETVALUES}
                        </TRECButton>
                        {writeAccess && (
                            <TRECButton
                                onClick={this._updateRWChecksData}
                                disabled={Object.keys(this.state.selectedRows).length === 0}
                            >
                                <SaveIcon />
                                {this.props.translations.SAVE}
                            </TRECButton>
                        )}

                        {writeAccess && (
                            <TRECButton onClick={this.edhDocumentHandler}>
                                <Attachment />
                                {this.props.translations.TREC_EDHDOCMNG}
                            </TRECButton>
                        )}
                    </SearchPanelControls>
                </SearchPanel>
                <EAMGridNoOverflow
                    onRef={(ref) => {
                        this.grid = ref;
                    }}
                    gridId={this.props.applicationData.gridRWChecksGridID}
                    cellRenderer={this._cellRenderer(writeAccess)}
                    showDataspySelection={false}
                    hiddenTags={this._hiddenColumns()}
                    heightFilterNotVisible="140px"
                    gridRequestAdapter={this.gridRequestAdapter}
                    onEditRow={writeAccess ? (row) => this._handleEdit(row) : undefined}
                    language={lang}
                    headerStyle={{}}
                />
            </BlockUi>
        );
    }
}

export default withStyles(trecPageStyles)(RWChecks);
