import React, { Component } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import Grid from '@material-ui/core/Grid';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import EISTable from 'react-eis-components/dist/ui/components/table';
import EAMLookup from 'eam-components/dist/ui/components/lookup/EAMLookup';
import WSAutocomplete from '../../../../tools/rest/WSAutocomplete';
import { showError, showWarning } from 'tools/TrecNotifications';

const labelStyle = {
    width: 170,
    minWidth: 170,
};

class RPMRLocation extends Component {
    formFields = [];

    state = {
        areaClassificationCode: undefined,
        areaClassification: undefined,
        editAll: false,
    };

    UNSAFE_componentWillMount() {
        const { setPage, lastMeasurement, request, updateRequestProperty, updateEquipmentProperty } = this.props;
        // Set the page for the help
        setPage('TREC_REQRPMEAS', 'STEP_LOCATION');
        // If there is last measurement with equipment destination, we set it
        if (lastMeasurement && lastMeasurement.equipmentDestination && !request.futureLocationCode) {
            updateRequestProperty('futureLocationCode', lastMeasurement.equipmentDestination);
        }
        const equipmentList = request.equipment;
        if (equipmentList) {
            equipmentList.forEach((eq, index) =>
                eq.lastLocationCode ? 1 : updateEquipmentProperty('lastLocationCode', eq.locationCode, index)
            );
        }
    }

    validate() {
        // Validations ok from the beginning
        let isValid = true;
        // If fething location, then don't let pass
        if (this.fetchingLocation) return false;
        // Iterate over the required fields and validate them
        Object.keys(this.formFields).forEach((key) => {
            if (this.formFields[key] && this.formFields[key].validate && !this.formFields[key].validate()) {
                isValid = false;
            }
        });
        if (!isValid) {
            // Invalid data in location
            const { translations } = this.props;
            showError(translations.LOCDETINV);
        } else {
            // Show warning if equipment is radioactive and the area is not classified
            const { request, constants, translations } = this.props;
            const { equipment } = request;
            const { areaClassificationCode: classificationCode } = this.state;
            if (
                classificationCode <= 0 &&
                equipment[0].inforEquipment.userDefinedFields.udfchar04 === constants.radioactive
            ) {
                showWarning(translations.NONRADAREAERR.replace('{0}', request.futureLocationCode), '', null);
            }
        }
        return isValid;
    }

    isUpdateLocation = () => {
        const { request, constants, action } = this.props;
        return action === 'updateloc' || request.futureAction === constants.futureActionVacuumDeclare;
    };

    isReadOnlyCurrentLocation = () => {
        // Current location is read only if we are in a buffer zone or if is update location wizard
        // Updated: We let also the modification even if we are in a buffer zone
        const { bufferZone, request } = this.props;
        return this.isUpdateLocation() || (!bufferZone && !request.detachEquipment);
    };

    isReadOnlyLastLocation = (equipment) =>
        // Last location is readonly if it is update location only now
        this.isUpdateLocation() && equipment.lastLocationCode;

    renderLastLocation = () => {
        const { request } = this.props;
        const multipleEquipment = request.equipment.length > 1;
        if (multipleEquipment) {
            return this.renderLastLocationMulti();
        }
        return this.renderLastLocationSingle();
    };

    renderLastLocationSingle = () => {
        const { applicationData, assetFields, request, updateEquipmentProperty } = this.props;
        const equipment = request.equipment[0];
        return (
            <EAMLookup
                right={1}
                top={13}
                width={26}
                height={26}
                gridId={applicationData.locationRaisinGridID}
                keys={{
                    code: 'obj_code',
                    mapCodeTo: 'lastLocationCode',
                    desc: 'obj_desc',
                    mapDescTo: 'lastLocationDesc',
                }}
                updateProperty={(key, value) => {
                    updateEquipmentProperty(key, value, 0);
                }}
                value={equipment.lastLocationCode}
                disabled={this.isReadOnlyLastLocation(equipment)}
            >
                <EAMAutocomplete
                    elementInfo={{
                        ...assetFields.lastLocation,
                        // attribute: 'R',
                        readonly: this.isReadOnlyLastLocation(equipment),
                    }}
                    value={equipment.lastLocationCode}
                    valueDesc={equipment.lastLocationDesc}
                    valueKey="lastLocationCode"
                    descKey="lastLocationDesc"
                    updateProperty={(key, value) => {
                        updateEquipmentProperty(key, value, 0);
                    }}
                    loadOptions={WSAutocomplete.autocompleteTRECLocation}
                    labelStyle={labelStyle}
                    formFields={this.formFields}
                    columnsCodes={['code', 'desc', 'extraAttr1']}
                    columnsWidth={['30%', '30%', '40%']}
                />
            </EAMLookup>
        );
    };

    updatePropertyMultiple = (key, value, index, isReadOnlyFunction) => {
        const { request, updateEquipmentProperty } = this.props;
        // Size of list
        const { equipment } = request;
        const { editAll } = this.state;
        // If edit all and index 0, set to all of the equipments
        if (editAll && index === 0) {
            // Update all (if no read onlt)
            equipment.forEach((elem, innerIndex) => {
                if (!isReadOnlyFunction(elem)) updateEquipmentProperty(key, value, innerIndex);
            });
        } else {
            // Normal update
            updateEquipmentProperty(key, value, index);
        }
    };

    renderLastLocationMulti = () => {
        const { applicationData, assetFields, request, translations } = this.props;
        const { equipment } = request;
        const equipmentList = equipment.map((elem, index) => {
            let eqp = { ...elem };
            // Last Location
            eqp = {
                ...eqp,
                lastLocation: (
                    <EAMLookup
                        right={1}
                        top={13}
                        width={26}
                        height={26}
                        gridId={applicationData.locationRaisinGridID}
                        keys={{
                            code: 'obj_code',
                            mapCodeTo: 'lastLocationCode',
                            desc: 'obj_desc',
                            mapDescTo: 'lastLocationDesc',
                        }}
                        updateProperty={(key, value) => {
                            this.updatePropertyMultiple(key, value, index, this.isReadOnlyLastLocation);
                        }}
                        value={eqp.lastLocationCode}
                        disabled={this.isReadOnlyLastLocation(equipment)}
                    >
                        <EAMAutocomplete
                            elementInfo={{
                                ...assetFields.lastLocation,
                                text: '',
                                // attribute: 'R',
                                xpath: `${assetFields.lastLocation.xpath}_${index}`,
                                readonly: this.isReadOnlyLastLocation(elem),
                            }}
                            value={eqp.lastLocationCode}
                            valueDesc={eqp.lastLocationDesc}
                            valueKey="lastLocationCode"
                            descKey="lastLocationDesc"
                            updateProperty={(key, value) => {
                                this.updatePropertyMultiple(key, value, index, this.isReadOnlyLastLocation);
                            }}
                            loadOptions={WSAutocomplete.autocompleteTRECLocation}
                            labelStyle={labelStyle}
                            formFields={this.formFields}
                            columnsCodes={['code', 'desc', 'extraAttr1']}
                            columnsWidth={['30%', '30%', '40%']}
                        />
                    </EAMLookup>
                ),
            };
            return eqp;
        });
        const headers = [assetFields.equipmentno.text, assetFields.location.text, assetFields.lastLocation.text];
        const propCodes = ['equipmentCode', 'locationCode', 'lastLocation'];
        const { editAll } = this.state;

        return (
            <>
                <EAMCheckbox
                    label={translations.EDITALL}
                    valueKey="editAll"
                    value={editAll}
                    updateProperty={(key, value) => {
                        this.setState({ [key]: value });
                    }}
                    trueValue
                    falseValue={false}
                />
                <EISTable data={equipmentList} headers={headers} propCodes={propCodes} maxMobileSize={600} />
            </>
        );
    };

    renderDetachMessage = () => {
        const { bufferZone, request, translations } = this.props;
        const { equipment } = request;
        const message = bufferZone ? translations.EQPWILLDETAC : translations.PLEASESELDET;
        // Iterate over the equipments
        let isAttached = false;
        equipment.forEach((eqp) => {
            const { inforEquipment } = eqp;
            if (inforEquipment && (inforEquipment.hierarchyAssetCode || inforEquipment.hierarchyPositionCode)) {
                isAttached = true;
            }
        });
        if (isAttached) {
            return <p>{message}</p>;
        }
        return null;
    };

    showDetachIndicator = () => {
        // The indicator will be shown if at least one of the equipment it's attached to a parent
        // And there is not buffer zone
        const { bufferZone, request } = this.props;
        const { equipment } = request;
        return (
            !bufferZone &&
            equipment
                .map((eqp) => eqp.inforEquipment)
                .some((inforEq) => inforEq && (inforEq.hierarchyAssetCode || inforEq.hierarchyPositionCode))
        );
    };

    // Onchange future location to get the classification area
    onChangeFutureLocation = (code, selectedObject) => {
        if (!selectedObject) {
            // Empty area
            this.setState(() => ({
                areaClassificationCode: undefined,
                areaClassification: undefined,
            }));
            return;
        }
        const classificationCode = selectedObject.extraAttr2;
        if (selectedObject.extraAttr1) {
            // Set area
            this.setState(() => ({
                areaClassificationCode: classificationCode,
                areaClassification: selectedObject.extraAttr1,
            }));
        }
    };

    renderAreaClassification = () => {
        const { areaClassification } = this.state;
        if (!areaClassification) return null;
        return <p>{areaClassification}</p>;
    };

    render() {
        const { applicationData, translations, request, updateRequestProperty, assetFields, constants, customFields } =
            this.props;
        return (
            <Grid container spacing={0}>
                <Grid item md={12} sm={12} xs={12}>
                    <EISPanel heading={translations.LOCATION} alwaysExpanded>
                        <div style={{ width: '100%', marginTop: 0 }}>
                            {/* Future Location */}
                            {this.isUpdateLocation() && (
                                <>
                                    <EAMLookup
                                        right={1}
                                        top={13}
                                        width={26}
                                        height={26}
                                        gridId={applicationData.locationRaisinGridID}
                                        keys={{
                                            code: 'obj_code',
                                            mapCodeTo: 'futureLocationCode',
                                            desc: 'obj_desc',
                                            mapDescTo: 'futureLocationDesc',
                                        }}
                                        updateProperty={updateRequestProperty}
                                        value={request.futureLocationCode}
                                    >
                                        <EAMAutocomplete
                                            elementInfo={{
                                                ...assetFields.futureLocation,
                                                attribute: 'R',
                                            }}
                                            value={request.futureLocationCode}
                                            valueDesc={request.futureLocationDesc}
                                            valueKey="futureLocationCode"
                                            descKey="futureLocationDesc"
                                            updateProperty={updateRequestProperty}
                                            loadOptions={WSAutocomplete.autocompleteTRECLocation}
                                            labelStyle={labelStyle}
                                            formFields={this.formFields}
                                            columnsCodes={['code', 'desc', 'extraAttr1']}
                                            columnsWidth={['30%', '30%', '40%']}
                                            onChangeValue={this.onChangeFutureLocation}
                                        />
                                    </EAMLookup>
                                    {this.renderAreaClassification()}
                                </>
                            )}

                            {/* Indicator of detach Equipment */}
                            {!this.isUpdateLocation() && this.renderDetachMessage()}
                            {!this.isUpdateLocation() && this.showDetachIndicator() && (
                                <EAMCheckbox
                                    elementInfo={{ ...customFields[constants.cfpEqpDetached], attribute: 'R' }}
                                    value={request.detachEquipment}
                                    valueKey="detachEquipment"
                                    updateProperty={updateRequestProperty}
                                    labelStyle={labelStyle}
                                    trueValue
                                    falseValue={false}
                                />
                            )}

                            {/* Current Location */}
                            <EAMLookup
                                right={1}
                                top={13}
                                width={26}
                                height={26}
                                gridId={applicationData.locationRaisinGridID}
                                keys={{
                                    code: 'obj_code',
                                    mapCodeTo: 'currentLocationCode',
                                    desc: 'obj_desc',
                                    mapDescTo: 'currentLocationDesc',
                                }}
                                updateProperty={updateRequestProperty}
                                value={request.currentLocationCode}
                                disabled={this.isReadOnlyCurrentLocation()}
                            >
                                <EAMAutocomplete
                                    elementInfo={{
                                        ...assetFields.location,
                                        ...(this.isReadOnlyCurrentLocation() ? {} : { attribute: 'R' }),
                                        readonly: this.isReadOnlyCurrentLocation(),
                                    }}
                                    value={request.currentLocationCode}
                                    valueDesc={request.currentLocationDesc}
                                    valueKey="currentLocationCode"
                                    descKey="currentLocationDesc"
                                    updateProperty={updateRequestProperty}
                                    loadOptions={WSAutocomplete.autocompleteTRECLocation}
                                    labelStyle={labelStyle}
                                    formFields={this.formFields}
                                    columnsCodes={['code', 'desc', 'extraAttr1']}
                                    columnsWidth={['30%', '30%', '40%']}
                                />
                            </EAMLookup>

                            {/* Last location */}
                            {this.renderLastLocation()}
                        </div>
                    </EISPanel>
                </Grid>
            </Grid>
        );
    }
}

export default RPMRLocation;
