import React from 'react';
import {
    NonRadioactiveWarningIcon,
    QuestionMarkCircleIcon,
    RadioactiveWarningIcon,
    FluidWarningIcon,
    ChemicalWarningIcon,
    ContaminationWarningIcon,
    FluidHazardIcon,
    OtherHazardIcon,
} from 'eam-components/dist/ui/components/icons';

/**
 * To display Equipment Hazard Icons. Must receive also the constants for the application and the translations
 * @param props The props of the equipment {classification, contamination, risks}
 * @returns {*} Icons to be displayed for the equipment hazard
 */
const iconStyle = { paddingRight: 2, color: 'black' };

const HAZARDS_MAP = {
    OTHER: ['RP-ASB', 'RP-AUX', 'RP-BE', 'RP-FLA', 'RP-HG', 'RP-PB'],
    FLUID: ['RP-FLU'],
    CONTAMINATED: ['Contaminated'],
};

const EquipmentHazard = (props) => {
    const { classification, constants, contamination, hazards, risks } = props;
    const equipmentRisks = !risks ? [] : typeof risks === 'string' ? risks.split(',') : risks;
    const contaminated = contamination === constants.trueYes || contamination === true;
    const equipmentHazards = !hazards
        ? []
        : (typeof hazards === 'string' ? hazards.split(',') : hazards).filter((h) => h);

    return (
        <span>
            {([constants.radioactive, 'RADIOACTIVE'].includes(classification) ||
                equipmentHazards.includes(constants.radioactive)) && <RadioactiveWarningIcon style={iconStyle} />}

            {!['RADIOACTIVE', 'NONRADIOACTIVE', constants.radioactive, constants.nonRadioactive].includes(
                classification
            ) && <QuestionMarkCircleIcon style={{ ...iconStyle, color: 'black' }} />}

            {(contaminated || HAZARDS_MAP.CONTAMINATED.some((elem) => equipmentHazards.includes(elem))) && (
                <ContaminationWarningIcon style={iconStyle} />
            )}

            {equipmentRisks.includes(constants.cfvAssocrisksChemMaterial) && <ChemicalWarningIcon style={iconStyle} />}

            {equipmentRisks.includes(constants.cfvAssocrisksFluids) && <FluidWarningIcon style={{ paddingRight: 2 }} />}

            {[constants.nonRadioactive, 'NONRADIOACTIVE'].includes(classification) &&
                !equipmentRisks.includes(constants.cfvAssocrisksChemMaterial) &&
                !equipmentRisks.includes(constants.cfvAssocrisksFluids) &&
                !equipmentHazards.includes(constants.radioactive) &&
                !Object.values(HAZARDS_MAP).some((hazardList) =>
                    hazardList.some((elem) => equipmentHazards.includes(elem))
                ) &&
                !contaminated && <NonRadioactiveWarningIcon style={iconStyle} />}

            {HAZARDS_MAP.FLUID.some((elem) => equipmentHazards.includes(elem)) && <FluidHazardIcon style={iconStyle} />}

            {HAZARDS_MAP.OTHER.some((elem) => equipmentHazards.includes(elem)) && <OtherHazardIcon style={iconStyle} />}
        </span>
    );
};

export default EquipmentHazard;
