import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Cancel from '@material-ui/icons/Cancel';
import List from '@material-ui/icons/List';
import Menu from '@material-ui/icons/Menu';
import Save from '@material-ui/icons/Save';
import { REQUEST_MODES, TREC_GENERAL_KEYS } from '../../../enums/Constants';
import TRECButton from '../../components/buttons/TRECButton';
import { RWP_STATUS, STEPS, TRANSLATION_KEYS } from './RWPConstants';

export default function RWProcessingControls(props) {
    const {
        handleCancel,
        handlePrevious,
        handleGoBack,
        handleNext,
        isFirstStep,
        isLastStep,
        currentStep,
        currentStepRef,
        getTranslation,
        mode,
        onStartTreatementClick,
        onSaveClick,
        onFinishClick,
        onCancelClick,
        onOpenRPMClick,
        onReadyForMeasurementClick,
        rwpStatus,
        markedAsync,
    } = props;

    return (
        <>
            <TRECButton onClick={handleCancel} color="default">
                <Menu />
                MENU
            </TRECButton>

            <TRECButton onClick={handleGoBack} color="default">
                <List />
                RWP LIST PAGE
            </TRECButton>

            <TRECButton disabled={isFirstStep} onClick={handlePrevious}>
                <ArrowBack />
                {getTranslation(TREC_GENERAL_KEYS.BACK)}
            </TRECButton>

            {!isLastStep && !(mode === REQUEST_MODES.CREATING && isFirstStep) && (
                <TRECButton onClick={handleNext}>
                    <ArrowForward />
                    {getTranslation(TREC_GENERAL_KEYS.NEXT)}
                </TRECButton>
            )}

            {mode === REQUEST_MODES.CREATING && isFirstStep && (
                <TRECButton onClick={() => currentStepRef.canContinue() && onStartTreatementClick()}>
                    {getTranslation(TRANSLATION_KEYS.START_TREATMENT)}
                </TRECButton>
            )}

            {mode === REQUEST_MODES.EDITING && (
                // RQF1654368
                // && !(currentStep === STEPS.PHYSICAL_DATA && rwpStatus === RWP_STATUS.PENDING)
                <TRECButton onClick={() => onSaveClick(currentStep)}>
                    <Save />
                    {getTranslation(TREC_GENERAL_KEYS.SAVE)}
                </TRECButton>
            )}

            {mode === REQUEST_MODES.EDITING && (
                <TRECButton onClick={onCancelClick}>
                    <Cancel />
                    {getTranslation(TREC_GENERAL_KEYS.CANCEL)}
                </TRECButton>
            )}

            {mode === REQUEST_MODES.EDITING &&
                currentStep === STEPS.PHYSICAL_DATA &&
                rwpStatus === RWP_STATUS.PENDING && (
                    <TRECButton onClick={() => currentStepRef.canContinue() && onReadyForMeasurementClick()}>
                        <Save />
                        {getTranslation(TRANSLATION_KEYS.READY_FOR_MEASURMENT)}
                    </TRECButton>
                )}

            {(mode === REQUEST_MODES.EDITING ||
                (mode === REQUEST_MODES.READING && rwpStatus === RWP_STATUS.INCOMPLETE)) &&
                currentStep === STEPS.PROPERTIES &&
                !markedAsync && (
                    <TRECButton onClick={() => currentStepRef.canContinue() && onFinishClick()}>
                        <Save />
                        {getTranslation(TRANSLATION_KEYS.FINISH)}
                    </TRECButton>
                )}
            {[REQUEST_MODES.EDITING, REQUEST_MODES.READING].includes(mode) && rwpStatus === RWP_STATUS.DONE && (
                <TRECButton onClick={onOpenRPMClick}>{getTranslation(TRANSLATION_KEYS.OPEN_RPM)}</TRECButton>
            )}
        </>
    );
}
