import React, { useState } from 'react';
import Update from '@material-ui/icons/Update';
import { Menu, MenuItem } from '@material-ui/core';
import TRECButton from 'ui/components/buttons/TRECButton';
import { CUTG10_FIELDS, CUTG10_TRANSL_MAP } from './RWManagementUtils';

export const IRRAD_END_DATE = 'irradEndDate';

/**
 * Controls to update properties in selected data
 */
const RWManagementControls = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { constants, getTranslation, lang, screenData, setPropertyToUpdate, translations } = props;
    const { assetFields } = screenData[lang];

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openMenuSelection = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePropertySelection = (propertyCode) => {
        // Set the property to update
        setPropertyToUpdate(propertyCode);
        // Close menu
        handleClose();
    };

    return (
        <>
            <TRECButton onClick={openMenuSelection}>
                <Update />
                {translations.UPDATEPROPERTY}
            </TRECButton>

            {/* Menu options */}
            <Menu id="simple-menu" open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
                <MenuItem onClick={() => handlePropertySelection(constants.cfpWmLocation)}>
                    {assetFields.location.text}
                </MenuItem>

                <MenuItem onClick={() => handlePropertySelection(CUTG10_TRANSL_MAP[CUTG10_FIELDS.UDL_RW_FACILITIES])}>
                    {getTranslation(CUTG10_TRANSL_MAP[CUTG10_FIELDS.UDL_RW_FACILITIES])}
                </MenuItem>

                <MenuItem onClick={() => handlePropertySelection(CUTG10_TRANSL_MAP[CUTG10_FIELDS.CF_RW_FAMILY])}>
                    {getTranslation(CUTG10_TRANSL_MAP[CUTG10_FIELDS.CF_RW_FAMILY])}
                </MenuItem>

                <MenuItem onClick={() => handlePropertySelection(CUTG10_TRANSL_MAP[CUTG10_FIELDS.CF_RW_SUBFAMILY])}>
                    {getTranslation(CUTG10_TRANSL_MAP[CUTG10_FIELDS.CF_RW_SUBFAMILY])}
                </MenuItem>

                <MenuItem onClick={() => handlePropertySelection(CUTG10_TRANSL_MAP[CUTG10_FIELDS.UDL_RW_MATERIALS])}>
                    {getTranslation(CUTG10_TRANSL_MAP[CUTG10_FIELDS.UDL_RW_MATERIALS])}
                </MenuItem>

                <MenuItem
                    onClick={() =>
                        handlePropertySelection(CUTG10_TRANSL_MAP[CUTG10_FIELDS.CF_RW_CONTAINS_ALPHA_EMITTER])
                    }
                >
                    {getTranslation(CUTG10_TRANSL_MAP[CUTG10_FIELDS.CF_RW_CONTAINS_ALPHA_EMITTER])}
                </MenuItem>

                <MenuItem onClick={() => handlePropertySelection(CUTG10_TRANSL_MAP[CUTG10_FIELDS.RW_PRODUCER_ORGUNIT])}>
                    {getTranslation(CUTG10_TRANSL_MAP[CUTG10_FIELDS.RW_PRODUCER_ORGUNIT])}
                </MenuItem>

                <MenuItem onClick={() => handlePropertySelection(IRRAD_END_DATE)}>
                    {getTranslation('IRRAD_END_DATE')}
                </MenuItem>

                <MenuItem onClick={() => handlePropertySelection(constants.cfpWmTreatment)}>
                    {getTranslation(constants.cfpWmTreatment)}
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        handlePropertySelection(CUTG10_TRANSL_MAP[CUTG10_FIELDS.HAZARDS_LIST]);
                    }}
                >
                    {getTranslation('RISKS')}
                </MenuItem>

                {/* <MenuItem
                        onClick={() => this.handlePropertySelection(constants.cfpWmMaterial)}>
                        {customFieldsWaste[constants.cfpWmMaterial].text}
                    </MenuItem> */}

                <MenuItem onClick={() => handlePropertySelection(CUTG10_TRANSL_MAP[CUTG10_FIELDS.CF_ELIM_PROCESS])}>
                    {getTranslation(CUTG10_TRANSL_MAP[CUTG10_FIELDS.CF_ELIM_PROCESS])}
                </MenuItem>
            </Menu>
        </>
    );
};

export default RWManagementControls;
