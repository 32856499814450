import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useState } from 'react';
import { VACUUM_RENTAL_LINE_KEYS } from 'ui/pages/vaccleanerrequest/VacCleanerConstants';
import BlockUi from 'react-block-ui';

const VacDeclareUseDialog = ({
    rentalLine,
    closeDialog,
    getTranslation,
    declareUse,
    readLockCode,
    reload,
    loading,
}) => {
    const [lockCode, setLockCode] = useState();

    return (
        <Dialog open={!!rentalLine} onClose={closeDialog} fullWidth maxWidth="md">
            <DialogTitle>{getTranslation('DECLAREUSE')}</DialogTitle>,
            {rentalLine && (
                <DialogContent style={{ fontSize: 14 }}>
                    <DialogContentText>
                        {!lockCode ? (
                            <div
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: getTranslation('DECLAREUSE_VACUUM_MSG').replace(
                                        '{0}',
                                        rentalLine[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE]
                                    ),
                                }}
                            />
                        ) : (
                            <>
                                <div style={{ width: '100%', textAlign: 'center' }}>Lock code:</div>
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <span style={{ fontSize: '30px', color: 'green' }}>{lockCode}</span>
                                </div>
                            </>
                        )}
                    </DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                <BlockUi blocking={loading}>
                    <Button onClick={closeDialog} color="textSecondary">
                        {getTranslation('CANCEL')}
                    </Button>
                    <Button
                        onClick={
                            lockCode
                                ? closeDialog
                                : declareUse(rentalLine?.[VACUUM_RENTAL_LINE_KEYS.CODE], (data, success) => {
                                      success(data);
                                      readLockCode(
                                          rentalLine?.[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE],
                                          (data2, success2) => {
                                              setLockCode(data2);
                                              success2();
                                              reload();
                                          }
                                      )();
                                  })
                        }
                        variant="contained"
                        color="primary"
                        autoFocus
                    >
                        {getTranslation('CONFIRM')}
                    </Button>
                </BlockUi>
            </DialogActions>
        </Dialog>
    );
};

export default VacDeclareUseDialog;
