import { Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMBarcodeInput from 'eam-components/dist/ui/components/inputs/EAMBarcodeInput';
import { RP_MEASUREMENT_KEYS } from 'enums/Constants';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import WSEquipment from 'tools/rest/WSEquipment';
import WSRPARequest from 'tools/rest/WSRPARequest';
import WSRPMeasurement from 'tools/rest/WSRPMeasurement';
import Tools from 'tools/Tools';
import { Link } from 'react-router-dom';
import { ERROR_TYPES } from 'enums/Defaults';
import { isEqual } from 'lodash';
import EquipmentAutocomplete from '../../../components/autocomplete/EquipmentAutocomplete';
import { EQUIPMENT_KEYS, REQUEST_KEYS, REQUEST_SOURCES, RPMR_TRANSLATION_KEYS } from '../RPMRConstants';
import { handleError, showError, showWarning } from 'tools/TrecNotifications';

// The expected errorTitle format is: "Assets 'P' have non dependent children: 'C1', 'C2', 'C3'."
const extractNonDependentChildren = (errorTitle) => {
    const nonDependentChildren = errorTitle
        .slice(0, -1) // remove period
        .split(':')[1] // get the part after the colon (contains children)
        .split(',') // split by comma (separator between children)
        .map((eqp) => eqp.trim().replace(/'/g, '')); // trim whitespace and remove single quotes

    return nonDependentChildren;
};

class RPMRSearchStep extends WizardStep {
    formFields = [];

    state = {
        eqpsNeedingChange: {},
    };

    UNSAFE_componentWillMount() {
        const { urlParams, storeActions, rpmrGetters } = this.props;
        const { setPage, updateRPMRSelectedEquipment } = storeActions;
        setPage('TREC_REQRPMEAS', 'STEP_SEARCH');
        const equipmentList = rpmrGetters.getEquipmentList();
        if (urlParams.equipment && (!equipmentList || !equipmentList.length)) {
            updateRPMRSelectedEquipment([].concat(urlParams.equipment));
        }
    }

    canContinue = () => {
        const { screenData } = this.props;

        const equipmentFormField = this.formFields[screenData.woFields.equipment.xpath];
        const retVal = equipmentFormField.validate();

        return retVal;
    };

    saveChanges = () => true;

    commitChanges = (callback) => {
        const { rpmrGetters, storeActions, applicationData, requestSource } = this.props;
        const { updateRPMRBlockUI, updateRPMREquipmentMap, updateRPMRequest } = storeActions;
        const equipmentMap = rpmrGetters.getEquipmentMap();
        const equipmentList = rpmrGetters.getEquipmentList();
        const unfetchedEquipmentList = equipmentList.filter(
            (code) => !equipmentMap[code] || !Object.values(equipmentMap[code]).length
        );
        if (unfetchedEquipmentList.length < 1) {
            return this.validateVacuumCleaners(equipmentMap) && callback();
        }
        updateRPMRBlockUI(true);
        return Promise.all(
            unfetchedEquipmentList.map((code) =>
                Promise.all([
                    WSRPMeasurement.getEquipment({ eqCode: code, applicationData, useStructure: true }),
                    WSRPARequest.getLastRPMeasurement(code),
                ])
            )
        )
            .then((responses) => {
                const updatedEquipmentMap = Tools.applyToFields(
                    responses.reduce((acc, [rEquip, rLastRPM]) => {
                        const equipment = rEquip && rEquip.body && rEquip.body.data;
                        const lastRPM = rLastRPM && rLastRPM.body && rLastRPM.body.data;
                        const fullEquipmentData = {
                            ...lastRPM,
                            ...equipment,
                            [RP_MEASUREMENT_KEYS.RP_CLASSIFICATION_CPY]: lastRPM[RP_MEASUREMENT_KEYS.RP_CLASSIFICATION],
                        };
                        return {
                            ...acc,
                            ...(equipment ? { [equipment[EQUIPMENT_KEYS.CODE]]: fullEquipmentData } : {}),
                        };
                    }, equipmentMap),
                    (eqp) => ({
                        ...eqp,
                        [EQUIPMENT_KEYS.DETACH]: true,
                    })
                );
                // Default for Request Responsible
                const resp = Object.values(updatedEquipmentMap)[0][EQUIPMENT_KEYS.RESPONSIBLE_CID];
                if (
                    Object.values(updatedEquipmentMap).every(
                        (eq) => !eq[EQUIPMENT_KEYS.RESPONSIBLE_CID] || eq[EQUIPMENT_KEYS.RESPONSIBLE_CID] === resp
                    )
                ) {
                    updateRPMRequest({ [REQUEST_KEYS.RESP_CODE]: resp });
                }
                return updatedEquipmentMap;
            })
            .then((updatedEquipmentMap) => {
                const locArray = Object.values(updatedEquipmentMap)
                    .map((eq) => eq[EQUIPMENT_KEYS.LAST_LOCATION_CODE])
                    .filter((loc) => loc);

                return Promise.all(locArray.map((loc) => WSRPARequest.getLocationFacility(loc)))
                    .then((res) => {
                        const locMap = locArray.reduce((acc, loc, i) => ({ ...acc, [loc]: res[i].body.data }), {});

                        // Apply equipment defaults here
                        const eqMap = Tools.applyToFields(updatedEquipmentMap, (eqp) => ({
                            ...eqp,
                            [EQUIPMENT_KEYS.FACILITY]:
                                eqp[EQUIPMENT_KEYS.FACILITY] || locMap[eqp[EQUIPMENT_KEYS.LAST_LOCATION_CODE]],
                            [EQUIPMENT_KEYS.FACILITIES]:
                                eqp[EQUIPMENT_KEYS.FACILITIES] && eqp[EQUIPMENT_KEYS.FACILITIES].length
                                    ? eqp[EQUIPMENT_KEYS.FACILITIES]
                                    : requestSource === REQUEST_SOURCES.EMPTY_CONTAINER
                                    ? []
                                    : [eqp[EQUIPMENT_KEYS.FACILITY] || locMap[eqp[EQUIPMENT_KEYS.LAST_LOCATION_CODE]]],
                        }));
                        updateRPMREquipmentMap(eqMap, true);
                        return this.validateVacuumCleaners(updatedEquipmentMap) && callback();
                    })
                    .catch(() => this.validateVacuumCleaners(updatedEquipmentMap) && callback());
            })
            .catch(handleError)
            .finally(() => updateRPMRBlockUI(false));
    };

    validateVacuumCleaners = (equipmentMap) => {
        const { applicationData, getTranslation, isVacuumAction, constants, rpmrGetters } = this.props;

        const hasOtherEquipment = Object.values(equipmentMap).some((equipment) => {
            const isVacuumCleanerEquipment = applicationData.vacuumCleanerClassesList.includes(
                equipment[EQUIPMENT_KEYS.CLASS_CODE]
            );
            return isVacuumAction && !isVacuumCleanerEquipment;
        });
        if (hasOtherEquipment) {
            showError(getTranslation(RPMR_TRANSLATION_KEYS.EPQISNOTVCL));
        }

        const futureAction = rpmrGetters.getProperties()[REQUEST_KEYS.ACTION];
        const isDeclareWaste = [
            constants.futureActionDecwaste,
            constants.futureActionWasteDeclare,
            constants.futureActionWasteReceive,
        ].includes(futureAction); // checkr: remove futureActionDecwaste. Can we remove either declare or receive?
        const alreadyWaste = Object.values(equipmentMap).filter(
            (equipment) => equipment[EQUIPMENT_KEYS.RW_RECEPTION_DATE]
        );
        if (isDeclareWaste && alreadyWaste.length > 0) {
            showError(
                `${getTranslation(RPMR_TRANSLATION_KEYS.EQUIPMENT_ALREADY_WASTE)}: ${alreadyWaste
                    .map((eq) => eq[EQUIPMENT_KEYS.CODE])
                    .join(', ')}`
            );
        }

        return !hasOtherEquipment && (!isDeclareWaste || alreadyWaste.length === 0);
    };

    validateNoNonDependentChildren = async (action) => {
        const { eqpsNeedingChange } = this.state;
        const { updateRPMRBlockUI } = this.props.storeActions;
        const { option, action: actionType } = action;

        if (actionType === 'select-option') {
            const selectedEquipment = option.eqCode;
            const currentNonDependentChildren = eqpsNeedingChange[selectedEquipment];

            try {
                updateRPMRBlockUI(true);
                await WSEquipment.checkChildren(selectedEquipment);

                // Remove the equipment from the list of equipment needing change
                // if there was no error in a subsequent WS call to the same equipment.
                if (currentNonDependentChildren) {
                    this.setState((prevState) => {
                        const updatedEqpsNeedingChange = { ...prevState.eqpsNeedingChange };

                        delete updatedEqpsNeedingChange[selectedEquipment];

                        return { eqpsNeedingChange: updatedEqpsNeedingChange };
                    });
                }
            } catch (error) {
                // Show error to user
                handleError(error);

                if (error.type === ERROR_TYPES.SERVER_ERROR) {
                    const errorTitle = error.response.body?.errors?.[0]?.title;

                    if (errorTitle?.includes('have non dependent children')) {
                        const nonDependentChildren = extractNonDependentChildren(errorTitle);

                        // Add parent equipment and non-dependent children to list of equipment needing change
                        if (!isEqual(currentNonDependentChildren, nonDependentChildren)) {
                            this.setState((prevState) => ({
                                eqpsNeedingChange: {
                                    ...prevState.eqpsNeedingChange,
                                    [selectedEquipment]: nonDependentChildren,
                                },
                            }));
                        }
                    }
                }

                return false;
            } finally {
                updateRPMRBlockUI(false);
            }
        }

        return true;
    };

    handleEquipmentListUpdate = async (value) => {
        const { applicationData, storeActions, rpmrGetters } = this.props;
        const { updateRPMRSelectedEquipment } = storeActions;
        const maxSelectedEquipments = applicationData.rpanalysisMaxEquipments;
        const equipmentList = rpmrGetters.getEquipmentList();

        if (equipmentList.length >= maxSelectedEquipments) {
            showWarning(`You cannot select more than ${maxSelectedEquipments} equipments`);
            return;
        }

        if (!(await this.validateNoNonDependentChildren({ action: 'select-option', option: { eqCode: value } }))) {
            return;
        }

        updateRPMRSelectedEquipment([...(equipmentList || []), value]);
    };

    handleChangeEquipmentList = async (selectedOptions, action) => {
        const { applicationData, storeActions } = this.props;
        const { addRPMRSelectedEquipment, updateRPMRSelectedEquipment } = storeActions;
        const maxSelectedEquipments = applicationData.rpanalysisMaxEquipments;
        const options = selectedOptions || [];
        if (options.length > maxSelectedEquipments) {
            showWarning(`You cannot select more than ${maxSelectedEquipments} equipments`);
            return;
        }

        if (!(await this.validateNoNonDependentChildren(action))) return;

        if (action.action === 'select-option') {
            addRPMRSelectedEquipment(...[action.option].map((e) => e.eqCode || e.code));
        } else {
            updateRPMRSelectedEquipment(options.map((e) => e.eqCode || e.code));
        }
    };

    render() {
        const { eqpsNeedingChange } = this.state;
        const { applicationData, screenData, getTranslation, rpmrGetters } = this.props;
        const equipmentField = screenData.woFields.equipment;
        const equipmentList = rpmrGetters.getEquipmentList();

        const linkifyEquipment = (equipment) => (
            <Link
                to={{
                    pathname: `${applicationData.eamlightLink}equipment/${equipment}`,
                }}
                target="_blank"
                rel="noreferrer"
            >
                {equipment}
            </Link>
        );

        const neededChangesTableData = Object.entries(eqpsNeedingChange).map(([parent, children]) => {
            const parentLink = linkifyEquipment(parent);

            const childrenLinks = (
                <Grid container spacing={8}>
                    {children.map((child) => (
                        <Grid item key={child}>
                            {linkifyEquipment(child)}
                        </Grid>
                    ))}
                </Grid>
            );

            return {
                parent: parentLink,
                children: childrenLinks,
            };
        });

        return (
            <div style={{ margin: 8 }}>
                <Grid container spacing={8}>
                    <Grid item md={6} sm={12} xs={12}>
                        <EISPanel
                            heading={getTranslation(RPMR_TRANSLATION_KEYS.EQUIPMENT)}
                            alwaysExpanded
                            detailsStyle={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '22px',
                                marginBottom: '10px',
                            }}
                        >
                            <EAMBarcodeInput updateProperty={this.handleEquipmentListUpdate} right={5} top={4}>
                                <EquipmentAutocomplete
                                    formFields={this.formFields}
                                    value={equipmentList.map((e) => ({ eqCode: e }))}
                                    onChange={this.handleChangeEquipmentList}
                                    elementInfo={{
                                        ...equipmentField,
                                        text: getTranslation(RPMR_TRANSLATION_KEYS.EQUIPMENTID),
                                        attribute: 'R',
                                        maxUpdateRecords: applicationData.rpanalysisMaxEquipments,
                                    }}
                                    loadOptions={(hint, config) =>
                                        WSEquipment.getAutocompleteOptions(
                                            { ...hint, includeExperiments: true },
                                            config
                                        )
                                    }
                                    showCount
                                />
                            </EAMBarcodeInput>
                            <div hidden>
                                <EAMBarcodeInput updateProperty={this.handleEquipmentListUpdate} right={5} top={10}>
                                    <EAMAutocomplete
                                        elementInfo={{
                                            ...equipmentField,
                                            text: getTranslation(RPMR_TRANSLATION_KEYS.EQUIPMENTID),
                                            attribute: 'R',
                                            maxUpdateRecords: applicationData.rpanalysisMaxEquipments,
                                        }}
                                        value={equipmentList.join(',')}
                                        valueKey="equipments"
                                        updateProperty={this.handleEquipmentListUpdate}
                                        loadOptions={WSRPMeasurement.autocompleteAllEquipment}
                                        formFields={this.formFields}
                                        columnsWidth={['0%', '100%']}
                                        multi
                                        creatable
                                    />
                                </EAMBarcodeInput>
                            </div>
                        </EISPanel>
                    </Grid>
                    {Object.keys(eqpsNeedingChange).length > 0 ? (
                        <Grid item md={6} sm={12} xs={12}>
                            <EISPanel
                                heading={getTranslation(RPMR_TRANSLATION_KEYS.EQ_NEEDS_STRUCTURE_CHANGE)}
                                alwaysExpanded
                            >
                                <EISTable
                                    data={neededChangesTableData}
                                    headers={[
                                        getTranslation(RPMR_TRANSLATION_KEYS.PARENT),
                                        getTranslation(RPMR_TRANSLATION_KEYS.NON_DEPENDENT_CHILDREN),
                                    ]}
                                    propCodes={['parent', 'children']}
                                />
                            </EISPanel>
                        </Grid>
                    ) : null}
                </Grid>
            </div>
        );
    }
}

export default RPMRSearchStep;
