import { UPDATE_LAYOUT, SET_PAGE, TOGGLE_HELP } from '../actions/layoutActions';

function buildLayoutObject() {
    return {
        lang: localStorage.getItem(`TREC_LANGUAGE`) || 'EN',
        currentMasterPage: '',
        currentPage: '',
        helpOpen: false,
    };
}

export default function application(state = buildLayoutObject(), action) {
    switch (action.type) {
        case UPDATE_LAYOUT:
            return {
                ...state,
                ...action.value,
            };
        case SET_PAGE: {
            return {
                ...state,
                currentPage: action.page,
                currentMasterPage: action.masterPage,
            };
        }
        case TOGGLE_HELP: {
            return {
                ...state,
                helpOpen: !state.helpOpen,
            };
        }
        default:
            return state;
    }
}
