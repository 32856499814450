import React from 'react';
import Paper from '@material-ui/core/Paper';
import EAMGrid from 'eam-components/dist/ui/components/eamgrid';
import classes from './EAMGridNoOverflow.module.css';
import { handleError, showSuccess } from 'tools/TrecNotifications';

const EAMGridNoOverflow = (props) => (
    <Paper elevation={4} className={classes.gridWrapper}>
        <EAMGrid {...props} handleError={handleError} showSuccess={showSuccess} />
    </Paper>
);

export default EAMGridNoOverflow;
