import { connect } from 'react-redux';
import { logout } from '../../../actions/applicationActions';
import { setPage, updateLayout } from '../../../actions/layoutActions';
import RPMRequest from './RPMRequest';

function mapStateToProps(state) {
    return {
        screenData: state.application.screenData,
        userData: state.application.userData,
        applicationData: state.application.applicationData,
        bufferZone: state.application.bufferZone,
        lang: state.layout.lang,
        constants: state.application.constants,
        menuType: state.application.menuType,
        translations: state.application.screenData[state.layout.lang].translations.TREC_REQRPMEAS,
        masterPage: [
            'TREC_REQRPMEAS',
            'DEC_WASTE',
            'DEP_WASTE',
            'MOVE_AT_CERN',
            'STO_DSTO_CERN',
            'DISPATCH_OUTSIDE',
            'VACUUM_CLEANER',
            'REQUEST_RPM',
            'REQUEST_RPM',
            'UPDATE_LOC',
            'FETCH',
        ],
        page: 'REQUEST_RPM',
        menu: state.application.screenData[state.layout.lang].menu[state.application.menuType],
    };
}

const RPMRequestContainer = connect(mapStateToProps, {
    updateLayout,
    setPage,
    logout,
})(RPMRequest);

export default RPMRequestContainer;
