import React, { useState, useEffect } from 'react';
import EquipmentPropertiesInfo from '../../../components/equipmentpropertiesinfo/EquipmentPropertiesInfo';
import { TRANSLATION_KEYS, EQUIPMENT_KEYS, RWP_KEYS } from '../RWPConstants';

export const DATA_EXTRACTION_TYPE = {
    MAX_VALUE: 'MAX_VALUE',
    OR_YES_NO_VALUE: 'OR_YES_NO_VALUE',
    OR_TRUE_FALSE_VALUE: 'OR_TRUE_FALSE_VALUE',
    CONCATENATE: 'CONCATENATE',
    MAX_VALUE_MULTIPLE_KEYS: 'MAX_VALUE_MULTIPLE_KEYS',
};

export const RP_MEASUREMENT_KEYS_VALUES = {
    CONTAMINATION_RISK: 'contaminationRisk',
    EQUIPMENT_KEY: 'eqCode',
    BKG: 'measurementLines.BKG.netValue',
    BKG_ALPHA: 'measurementLines.BKG_ALPHA.netValue',
    BKG_BETA: 'measurementLines.BKG_BETA.netValue',
    CONT_ALPHA: 'measurementLines.CONT_ALPHA.netValue',
    CONT_BETA: 'measurementLines.CONT_BETA.netValue',
    NET_ALPHA: 'measurementLines.NET_ALPHA.netValue',
    NET_BETA: 'measurementLines.NET_BETA.netValue',
    CONTAINS_ALPHA_EMITTERS: 'containsAlphaEmitters',
    DOSE_RATE: [
        'measurementLines.1.netValue',
        'measurementLines.2.netValue',
        'measurementLines.3.netValue',
        'measurementLines.4.netValue',
    ],
};

export const RP_MEASUREMENT_KEYS_QUALIFIERS = {
    BKG: 'measurementLines.BKG.qualifier',
    BKG_ALPHA: 'measurementLines.BKG_ALPHA.qualifier',
    BKG_BETA: 'measurementLines.BKG_BETA.qualifier',
    CONT_ALPHA: 'measurementLines.CONT_ALPHA.qualifier',
    CONT_BETA: 'measurementLines.CONT_BETA.qualifier',
    NET_ALPHA: 'measurementLines.NET_ALPHA.qualifier',
    NET_BETA: 'measurementLines.NET_BETA.qualifier',
    DOSE_RATE: [
        'measurementLines.1.qualifier',
        'measurementLines.2.qualifier',
        'measurementLines.3.qualifier',
        'measurementLines.4.qualifier',
    ],
};

export const getDeepValue = (object, keyz) => {
    let obj = object;
    try {
        const keys = keyz.split('.');
        keys.forEach((key) => {
            obj = obj[key];
        });
        return obj;
    } catch (exception) {
        return null;
    }
};

export const extractValueFromStructure = ({ equipment, keyValue, keyEquipmentID, dataExtractionType, qualifier }) => {
    switch (dataExtractionType) {
        case DATA_EXTRACTION_TYPE.MAX_VALUE: {
            const a = equipment.reduce(
                (acc, eq) => {
                    if (!getDeepValue(eq, keyValue)) return acc;
                    const val =
                        (Number.isNaN(getDeepValue(eq, keyValue)) && parseFloat(getDeepValue(eq, keyValue))) ||
                        getDeepValue(eq, keyValue);
                    const eqCode = getDeepValue(eq, keyEquipmentID);
                    const qual = getDeepValue(eq, qualifier);
                    if (!acc.value) {
                        return {
                            value: val,
                            equipment: eqCode ? [eqCode] : [],
                            qualifier: qual,
                        };
                    }
                    return (
                        (val === acc.value &&
                            qual === acc.qualifier && {
                                ...acc,
                                equipment: [...acc.equipment, eqCode],
                                qualifier: qual,
                            }) ||
                        ((val > acc.value || (val === acc.value && qual === '=')) && {
                            value: val,
                            equipment: [eqCode],
                            qualifier: qual,
                        }) ||
                        acc
                    );
                },
                { value: undefined, equipment: [] }
            ); // Assume value is non-negative
            return a;
        }
        case DATA_EXTRACTION_TYPE.OR_YES_NO_VALUE:
            return equipment.reduce(
                (acc, eq) =>
                    (getDeepValue(eq, keyValue) === 'Yes' && {
                        value: 'Yes',
                        equipment: [...acc.equipment, getDeepValue(eq, keyEquipmentID)],
                    }) ||
                    acc,
                { value: 'No', equipment: [] }
            );
        case DATA_EXTRACTION_TYPE.OR_TRUE_FALSE_VALUE: {
            const trueEquipment = equipment
                .filter((eq) => getDeepValue(eq, keyValue) === true)
                .map((eq) => getDeepValue(eq, keyEquipmentID));
            const falseEquipment = equipment
                .filter((eq) => getDeepValue(eq, keyValue) === false)
                .map((eq) => getDeepValue(eq, keyEquipmentID));
            return (
                (trueEquipment.length && { value: 'Yes', equipment: trueEquipment }) ||
                (falseEquipment.length && { value: 'No', equipment: [] }) || { value: undefined, equipment: [] }
            );
        }
        case DATA_EXTRACTION_TYPE.MAX_VALUE_MULTIPLE_KEYS: {
            const measurements = keyValue.map((key, index) =>
                extractValueFromStructure({
                    keyValue: key,
                    keyEquipmentID,
                    dataExtractionType: DATA_EXTRACTION_TYPE.MAX_VALUE,
                    qualifier: qualifier ? qualifier[index] : null,
                    equipment,
                })
            );
            return measurements.reduce(
                (acc, measurement) => ((!acc.value || acc.value < measurement.value) && measurement) || acc,
                {}
            );
        }
        default:
            return {};
    }
};

export const EquipmentPropertiesInfoLoader = (props) => {
    const { applicationData, getTranslation, rwpGetters } = props;

    const [data, setData] = useState([]);

    const extractValuesFromStructure = (equipmentData) => {
        const measurements = [
            {
                property: RP_MEASUREMENT_KEYS_VALUES.CONTAMINATION_RISK,
                label: TRANSLATION_KEYS.CONTAMINATION_RISK,
                keyValue: RP_MEASUREMENT_KEYS_VALUES.CONTAMINATION_RISK,
                dataExtractionType: DATA_EXTRACTION_TYPE.OR_TRUE_FALSE_VALUE,
                group: 'Contamination',
            },
            {
                property: RP_MEASUREMENT_KEYS_VALUES.CONT_ALPHA,
                label: TRANSLATION_KEYS.CONTAMINATION_ALPHA,
                keyValue: RP_MEASUREMENT_KEYS_VALUES.CONT_ALPHA,
                dataExtractionType: DATA_EXTRACTION_TYPE.MAX_VALUE,
                group: 'Contamination',
                qualifier: RP_MEASUREMENT_KEYS_QUALIFIERS.CONT_ALPHA,
            },
            {
                property: RP_MEASUREMENT_KEYS_VALUES.CONT_BETA,
                label: TRANSLATION_KEYS.CONTAMINATION_BETA,
                keyValue: RP_MEASUREMENT_KEYS_VALUES.CONT_BETA,
                dataExtractionType: DATA_EXTRACTION_TYPE.MAX_VALUE,
                group: 'Contamination',
                qualifier: RP_MEASUREMENT_KEYS_QUALIFIERS.CONT_BETA,
            },
            {
                property: 'DOSE_RATE',
                label: TRANSLATION_KEYS.DOSE_RATE,
                keyValue: RP_MEASUREMENT_KEYS_VALUES.DOSE_RATE,
                dataExtractionType: DATA_EXTRACTION_TYPE.MAX_VALUE_MULTIPLE_KEYS,
                qualifier: RP_MEASUREMENT_KEYS_QUALIFIERS.DOSE_RATE,
            },
        ];

        return measurements.map((measurement) => ({
            ...measurement,
            ...extractValueFromStructure({
                ...measurement,
                keyEquipmentID: RP_MEASUREMENT_KEYS_VALUES.EQUIPMENT_KEY,
                equipment: equipmentData,
            }),
        }));
    };

    const getTimeDifference = (time1, time2) => {
        const differenceInDays = (time1 - time2) / (1000 * 3600 * 24);
        const differenceInMonths = (differenceInDays * 1.0) / (365 / 12);
        const differenceInYears = (differenceInDays * 1.0) / 365;
        return Math.abs(differenceInMonths) < 1
            ? `${Math.round(differenceInDays * 100) / 100} days`
            : Math.abs(differenceInYears) < 1
            ? `${Math.round(differenceInMonths * 100) / 100} months`
            : `${Math.round(differenceInYears * 100) / 100} years`;
    };

    const extractInputEquipmentProperties = (equipmentData) => {
        let minIrradDateDifference = '';
        const irradDateDiff = [EQUIPMENT_KEYS.IRRADIATION_END_DATE_MIN, EQUIPMENT_KEYS.IRRADIATION_END_DATE_MAX]
            .map((e) => equipmentData[e])
            .filter((e) => e)
            .map((e) => new Date(e));
        const irradEquipment = [
            EQUIPMENT_KEYS.IRRADIATION_END_DATE_MAX_EQUIPMENT,
            EQUIPMENT_KEYS.IRRADIATION_END_DATE_MIN_EQUIPMENT,
        ]
            .map((e) => equipmentData[e])
            .filter((e) => e);
        const today = Date.now();

        if (irradDateDiff.length > 1) {
            minIrradDateDifference = `Between ${getTimeDifference(today, irradDateDiff[1])} and `;
        }
        if (irradDateDiff.length > 0) {
            minIrradDateDifference += `${getTimeDifference(today, irradDateDiff[0])} ago.`;
        }

        const facilities = equipmentData[EQUIPMENT_KEYS.FACILITIES]
            ? equipmentData[EQUIPMENT_KEYS.FACILITIES]
                  .map((facil) => applicationData.facilities.find((f) => f.code === facil).desc)
                  .join(', ')
            : [];

        return [
            {
                property: EQUIPMENT_KEYS.IRRADIATION_END_DATE_MIN,
                label: getTranslation(TRANSLATION_KEYS.IRRAD_END_DATE_CATEGORY),
                value: minIrradDateDifference || undefined,
                group: 'IRRADIATION_END_DATE',
                equipment: irradEquipment,
            },
            // {
            //     property: EQUIPMENT_KEYS.IRRADIATION_END_DATE_MAX,
            //     label: getTranslation(TRANSLATION_KEYS.IRRADIATION_END_DATE_MAX),
            //     value: maxIrradDateDifference,
            //     group: "IRRADIATION_END_DATE",
            //     equipment: data[EQUIPMENT_KEYS.IRRADIATION_END_DATE_MAX_EQUIPMENT] && [data[EQUIPMENT_KEYS.IRRADIATION_END_DATE_MAX_EQUIPMENT]]
            //                 || [],
            // },
            {
                label: getTranslation(EQUIPMENT_KEYS.CONTAIN_ALPHA_EMITTER),
                property: EQUIPMENT_KEYS.CONTAIN_ALPHA_EMITTER,
                group: 'Contamination',
                value: equipmentData[EQUIPMENT_KEYS.CONTAIN_ALPHA_EMITTER],
                dataExtractionType: DATA_EXTRACTION_TYPE.OR_TRUE_FALSE_VALUE,
            },
            {
                property: EQUIPMENT_KEYS.FACILITIES,
                label: getTranslation(TRANSLATION_KEYS.FACILITIES),
                value: facilities.length ? facilities : undefined,
            },
        ];
    };

    const processData = () => {
        const structureData = extractValuesFromStructure(
            rwpGetters.getRWPProperties()[RWP_KEYS.INPUT_EQUIPMENT_STRUCTURE_LAST_RP_MEASUREMENTS] || []
        );
        const inputEquipmentData = extractInputEquipmentProperties(
            ['T', 'TX'].includes(rwpGetters.getRWPProperties()[RWP_KEYS.USER_STATUS])
                ? rwpGetters.getRWPProperties()
                : rwpGetters.getRWPProperties()[RWP_KEYS.INPUT_EQUIPMENT_PROPERTIES] || {}
        );
        return [...structureData, ...inputEquipmentData];
    };

    useEffect(() => {
        setData(processData());
    }, [rwpGetters]);

    return (
        <div style={{ marginBottom: 20 }}>
            <span style={{ fontWeight: '600', marginTop: 8 }}>
                {getTranslation(TRANSLATION_KEYS.INPUT_WASTE_INFORMATION)}
            </span>
            <EquipmentPropertiesInfo data={data} />
        </div>
    );
};
