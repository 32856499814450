import WS from './WS';

class WSEquipment {
    getEvents = ({ equipmentID }, config = {}) => WS._get(`/equipment/${equipmentID}/events`, config);

    getAudit = ({ equipmentID }, config = {}) => WS._get(`/equipment/${equipmentID}/audit`, config);

    getStructure = ({ equipmentID }, config = {}) => WS._get(`/equipment/structure/${equipmentID}`, config);

    getAutocompleteOptions = async ({ hint, includeExperiments }, config = {}) => {
        const result = await WS._get('/equipment/autocomplete', { ...config, params: { hint, includeExperiments } });
        return result.body.data;
    };

    getAutocompleteWaterOrigins = async ({ hint }, config = {}) => {
        const result = await WS._get('/equipment/autocomplete/waterorigins', { ...config, params: { hint } });
        return result.body.data;
    };

    getAutocompleteWaterCircuits = async ({ hint }, config = {}) => {
        const result = await WS._get('/equipment/autocomplete/watercircuits', { ...config, params: { hint } });
        return result.body.data;
    };

    getStatuses = ({ previousStatus }, config = {}) =>
        WS._get('/equipment/statuses', { ...config, params: { previousStatus } });

    getCorrections = ({ equipmentID, maxLevel }, config = {}) =>
        WS._get(`/equipment/${equipmentID}/corrections`, { ...config, params: { maxLevel } });

    breakUp = ({ equipmentID, maxLevel, retrieveSecondLevel }, config = {}) =>
        WS._post(`/equipment/breakup`, { equipmentCode: equipmentID, maxLevel, retrieveSecondLevel }, config);

    queryFacility = async ({ equipmentID, facility }, config = {}) => {
        const resp = await WS._get(`/equipment/${equipmentID}/facilities/${facility}/origin`, config);
        return resp.body.data;
    };

    queryMinIrradEndDate = async ({ equipmentID }, config = {}) => {
        const resp = await WS._get(`/equipment/${equipmentID}/irradenddates/min/origin`, config);
        return resp.body.data;
    };

    queryMaxIrradEndDate = async ({ equipmentID }, config = {}) => {
        const resp = await WS._get(`/equipment/${equipmentID}/irradenddates/max/origin`, config);
        return resp.body.data;
    };

    checkChildren = async (equipmentID, config = {}) => {
        const resp = await WS._get(`/rpm/equipment/${equipmentID}/checkChildren`, config);
        return resp.body.data;
    };
}

export default new WSEquipment();
