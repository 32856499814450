import WS from './WS';

class WSWaterRelease {
    async readWRRequest(request, config = {}) {
        const resp = await WS._get(`/waterrelease/requests/${request}`, request, config);
        return resp.body.data;
    }

    async createWRRequest(request, config = {}) {
        const resp = await WS._post('/waterrelease/requests', request, config);
        return resp.body.data;
    }

    async createWaterCircuit(request, config = {}) {
        const resp = await WS._post('/waterrelease/circuits', request, config);
        return resp.body.data;
    }

    async updateWRRequest({ jobNumber, ...request }, config = {}) {
        const resp = await WS._put(`/waterrelease/requests/${jobNumber}`, { jobNumber, ...request }, config);
        return resp.body.data;
    }

    async attachSample({ jobNumber, sampleCode }, config = {}) {
        const resp = await WS._put(`/waterrelease/requests/${jobNumber}/deposit/${sampleCode}`, config);
        return resp.body.data;
    }
}

export default new WSWaterRelease();
