export const getRowAsAnObject = (row) =>
    row.cell.reduce(
        (acc, cell) => ({
            ...acc,
            [cell.t]: cell.value || '',
        }),
        {}
    );

export const CUTG10_FIELDS = {
    OBJ_CODE: 'obj_code',
    OBJ_DESC: 'obj_desc',
    OBJ_CLASS: 'obj_class',
    OBJ_CATEGORY: 'obj_category',
    OBJ_STATUS: 'obj_status',
    OBJ_STATE: 'obj_state',
    OBJ_SERIALNO: 'obj_serialno',
    OBJ_PARENT: 'obj_parent',
    OBJ_LOCATION: 'obj_location',
    OBJ_POSITION: 'obj_position',
    OBJ_CREATED: 'obj_created',
    EQP_LENGTH: 'eqp_length',
    EQP_WIDTH: 'eqp_width',
    EQP_HEIGHT: 'eqp_height',
    EQP_WEIGHT: 'eqp_weight',
    EQP_ORGUNIT: 'eqp_org_unit',
    obj_facility: 'obj_facility',
    RW_RECEPTION_DATE: 'rw_reception_date',
    RW_ELIMINATION_DATE: 'rw_elimination_date',
    EQP_RPCLASSIFICATION: 'eqp_rpclassification',
    RW_WASTE_ZONE: 'rw_waste_zone',
    RW_WASTE_SUBZONE: 'rw_waste_subzone',
    DIMENSIONS: 'dimensions',
    TARE_MASS: 'tare_mass',
    PER_DESC: 'per_desc',
    R5PERSONNEL: 'r5personnel',
    EQP_RESPONSIBLE: 'eqp_responsible',
    OBJ_RESP_CERN_ID: 'obj_resp_cern_id',
    LASTRP_EVT_CODE: 'lastrp_evt_code',
    LASTRP_EVT_COMPLETED: 'lastrp_evt_completed',
    LASTRP_ASSOCIATED_RISK: 'lastrp_associated_risk',
    LASTRP_CONTAMINATION: 'lastrp_contamination',
    LASTRP_MEAS_CONTACT: 'lastrp_meas_contact',
    LASTRP_MEAS_10: 'lastrp_meas_10',
    LASTRP_MEAS_40: 'lastrp_meas_40',
    LASTRP_BGO_CONTACT: 'lastrp_bgo_contact',
    CF_RW_FAMILY: 'cf_rw_family',
    CF_RW_SUBFAMILY: 'cf_rw_subfamily',
    CF_RW_PROCESSING: 'cf_rw_processing',
    CF_MATERIAL: 'cf_material',
    CF_ELIM_PROCESS: 'cf_elim_process',
    CF_IRRADIATION_END_DATE_MIN: 'cf_irradiation_end_date_min',
    CF_IRRADIATION_END_DATE_MAX: 'cf_irradiation_end_date_max',
    CF_RW_CONTAINS_ALPHA_EMITTER: 'cf_rw_contains_alpha_emitter',
    CF_RW_CONTAINER_FULL: 'cf_rw_container_full',
    UDL_RW_MATERIALS: 'udl_rw_materials',
    UDL_RW_FACILITIES: 'udl_rw_facilities',
    RW_PRODUCER_ORGUNIT: 'rw_producer_org_unit',
    HAZARDS_LIST: 'eqp_hazards',
    EQP_DIRECT_HAZARDS: 'eqp_direct_hazards',
    EQP_DIRECT_HAZARDS_COMMENT: 'eqp_direct_hazards_comments',
};

export const CUTG10_TRANSL_MAP = {
    [CUTG10_FIELDS.CF_RW_FAMILY]: 'rwFamily',
    [CUTG10_FIELDS.CF_RW_SUBFAMILY]: 'rwSubFamily',
    [CUTG10_FIELDS.CF_RW_PROCESSING]: 'processing',
    [CUTG10_FIELDS.CF_ELIM_PROCESS]: 'eliminationProcess',
    [CUTG10_FIELDS.UDL_RW_MATERIALS]: 'materialList',
    [CUTG10_FIELDS.UDL_RW_FACILITIES]: 'facility',
    [CUTG10_FIELDS.CF_RW_CONTAINS_ALPHA_EMITTER]: 'containAlphaEmitter',
    [CUTG10_FIELDS.RW_PRODUCER_ORGUNIT]: 'orgUnit',
    [CUTG10_FIELDS.HAZARDS_LIST]: 'hazards_list',
};
