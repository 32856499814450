import { connect } from 'react-redux';
import Tools from 'tools/Tools';
import { logout, updateApplication } from '../../../actions/applicationActions';
import { setPage } from '../../../actions/layoutActions';
import Menu from './Menu';

const mapStateToProps = (state) => ({
    items: state.application.screenData[state.layout.lang].menu[state.application.menuType],
    translations: state.application.screenData[state.layout.lang].translations.TREC_MENU,
    menuType: state.application.menuType,
    bufferZone: state.application.bufferZone,
    loading: state.application.loading,
    menuCategoryOrder: state.application.applicationData.menuCategoryOrder,
    getTranslation: Tools.getTranslation({
        screenData: state.application.screenData,
        lang: state.layout.lang,
        translationKey: 'TREC_MENU',
    }),
});

const MenuContainer = connect(mapStateToProps, {
    updateApplication,
    setPage,
    logout,
})(Menu);

export default MenuContainer;
