import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { AMMSingleAutocomplete, WizardStep } from 'amm-tools';
import { useMemo, useState } from 'react';
import WSVacRental from 'tools/rest/WSVacRental';
import InputGenerator from 'ui/pages/rpmrequest2/InputGenerator';
import {
    VACUUM_RENTAL_KEYS,
    VACUUM_RENTAL_LINE_KEYS,
    VACUUM_RENTAL_STATUS,
} from 'ui/pages/vaccleanerrequest/VacCleanerConstants';
import VacuumOverviewPanel from '../components/VacuumOverviewPanel';
import { getVacCleanerLinesFields, VAC_TABLE_KEYS } from '../VacCleanerUtils';
import VacDeclareUseDialog from '../VacDeclareUseDialog';
import VacReturnDialog from '../VacReturnDialog';
import { showError } from 'tools/TrecNotifications';

const cellStyle = {
    textAlign: 'center',
    padding: '2px 10px 2px 10px',
    maxWidth: 180,
};

const tableFields = [
    VACUUM_RENTAL_LINE_KEYS.STATUS,
    VACUUM_RENTAL_LINE_KEYS.ASSET_CODE,
    VACUUM_RENTAL_LINE_KEYS.VAC_TYPE,
    VACUUM_RENTAL_LINE_KEYS.LOCATION,
    VACUUM_RENTAL_LINE_KEYS.START_DATE,
    VACUUM_RENTAL_LINE_KEYS.END_DATE,
    VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES,
    VAC_TABLE_KEYS.ACTION,
    VAC_TABLE_KEYS.FETCH_LOCK_CODE,
    VACUUM_RENTAL_LINE_KEYS.REPLACEMENT_REASON,
];

const VacCleanerDeclareUse = ({
    labelStyle,
    match,
    vacuumRequest,
    storeActions,
    getTranslation,
    callWs,
    loadingAround,
    blockAndCallWs,
    reload,
    loading,
    adminMode,
    applicationData,
}) => {
    const vacCleanerRequestNumber = match.params.vaccleanerNumber;
    const { vacCleanerRentalLines } = vacuumRequest;
    const status = vacuumRequest[VACUUM_RENTAL_KEYS.STATUS];

    const [declareUseLine, setDeclareUseLine] = useState();
    const [returnLine, setReturnLine] = useState();
    const [lockCodes, setLockCodes] = useState({});

    const declareUse = (reservationCode, callback) =>
        blockAndCallWs(
            WSVacRental.declareVacuumUseReservation,
            [
                {
                    vacRentalRequest: vacuumRequest,
                    reservationCode,
                },
            ],
            callback
        );

    const readLockCode = (assetCode, callback) =>
        blockAndCallWs(
            WSVacRental.readLockCode,
            [
                {
                    vacRentalRequest: vacuumRequest,
                    assetCode,
                },
            ],
            callback
        );

    const returnVacuum = ({ reservationCode, returnBean }, callback) =>
        blockAndCallWs(
            WSVacRental.declareVacuumReturnReservation,
            [
                {
                    vacRentalRequest: vacuumRequest,
                    reservationCode,
                    returnBean,
                },
            ],
            callback
        );

    const fields = useMemo(
        () =>
            getVacCleanerLinesFields(
                {
                    storeActions,
                    vacCleanerRequestNumber,
                    vacCleanerRentalLines,
                    labelStyle,
                    vacuumRequest,
                    getTranslation,
                    callWs,
                    loadingAround,
                    blockAndCallWs,
                    reload,
                    setDeclareUseLine,
                    readLockCode,
                    lockCodes,
                    setLockCodes,
                    returnVacuum,
                    declareUse,
                    setReturnLine,
                    adminMode,
                },
                tableFields
            ),
        [vacCleanerRentalLines, lockCodes]
    );

    return (
        <>
            {declareUseLine && (
                <VacDeclareUseDialog
                    rentalLine={declareUseLine}
                    closeDialog={() => setDeclareUseLine(null)}
                    getTranslation={getTranslation}
                    declareUse={declareUse}
                    readLockCode={readLockCode}
                    reload={reload}
                    loading={loading}
                />
            )}
            {returnLine && (
                <VacReturnDialog
                    rentalLine={returnLine}
                    closeDialog={() => setReturnLine(null)}
                    getTranslation={getTranslation}
                    returnVacuum={returnVacuum}
                    reload={reload}
                    loading={loading}
                    applicationData={applicationData}
                    vacuumRequest={vacuumRequest}
                />
            )}
            <Grid container spacing={0} style={{ marginBottom: '10px' }}>
                <Grid item xs={12} lg={9} xl={10} style={{ marginBottom: 20 }}>
                    {status === VACUUM_RENTAL_STATUS.IN_PROGRESS && (
                        <AMMSingleAutocomplete
                            loadOptions={async ({ hint }) => {
                                const alreadyUsed = vacCleanerRentalLines
                                    .filter((vacRentalLine) =>
                                        ['U'].includes(vacRentalLine[VACUUM_RENTAL_LINE_KEYS.STATUS])
                                    )
                                    .filter((line) => line[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE] === hint?.trim())
                                    .map((line) => ({
                                        code: line[VACUUM_RENTAL_LINE_KEYS.CODE],
                                        description: line[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE],
                                    }));
                                return alreadyUsed;
                            }}
                            value={null}
                            getOptionLabel={(opt) => (opt.code ? `${opt.description}` : '')}
                            getOptionValue={(opt) => opt.code}
                            placeholder={getTranslation('SCAN_VACUUM_CLEANER')}
                            onChange={(opt) => {
                                setDeclareUseLine(
                                    vacCleanerRentalLines
                                        .filter((vacRentalLine) =>
                                            ['U'].includes(vacRentalLine[VACUUM_RENTAL_LINE_KEYS.STATUS])
                                        )
                                        .filter((line) => line[VACUUM_RENTAL_LINE_KEYS.CODE] === opt.code)?.[0]
                                );
                            }}
                            style={labelStyle}
                            autoSelectSingle
                            autoFocus
                        />
                    )}
                    <Table>
                        <TableHead>
                            <TableRow>
                                {fields.map((obj) => (
                                    <TableCell key={`header${obj.code}`} style={cellStyle}>
                                        {getTranslation(obj.translationKey || obj.code)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {vacCleanerRentalLines.map((vacRentalLine) => (
                                <TableRow key={vacRentalLine.code}>
                                    {fields.map((field) => (
                                        <TableCell key={`content#${field.code}`} style={cellStyle}>
                                            <div key={field.code}>
                                                {InputGenerator.generate({
                                                    key: `${field.code}#${vacRentalLine[VACUUM_RENTAL_LINE_KEYS.CODE]}`,
                                                    field: {
                                                        ...field,
                                                        elementInfo: {
                                                            xpath: field.code,
                                                            text: getTranslation(field.translationKey),
                                                        },
                                                    },
                                                    object: vacRentalLine,
                                                    ...field,
                                                })}
                                            </div>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12} lg={3} xl={2} style={{ marginBottom: 20 }}>
                    <VacuumOverviewPanel getTranslation={getTranslation} vacuumRequest={vacuumRequest} />
                </Grid>
            </Grid>
        </>
    );
};

class VacCleanerDeclareUseStep extends WizardStep {
    formFields = [];

    prevValues = null;

    state = {
        isNewEquipment: false,
        newEquipment: {},
    };

    handleSubmit = () => {
        this.canContinue();
    };

    handleSave = () => {
        this.canContinue();
    };

    /**
     * Can continue to the next step on the wizard
     */
    canContinue = () => {
        const isValid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
        if (!isValid) {
            showError('One or more fields are not valid.');
        }

        return isValid;
    };

    /**
     * SaveChanges is used to save the localized state to the redux-store. It can only run synchronous methods.
     */
    saveChanges = () => true;

    /**
     *  CommitChanges is used to trigger any side effects of the step, such as invocations to the back-end to
     * retrieve information about resources. This method can run asynchronous methods/requests but SHALL execute
     * the callback method afterwards to inform the wizard.
     */
    commitChanges = async (callback) => {
        callback();
    };

    /**
     * On true, the wizard automatically jumps this step immediately after it is mounted
     */
    canJumpStep = () => false;

    render() {
        return <VacCleanerDeclareUse formFields={this.formFields} {...this.props} />;
    }
}

export default VacCleanerDeclareUseStep;
