import React from 'react';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import BlockUi from 'react-block-ui';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import AttachFile from '@material-ui/icons/AttachFile';
import Cancel from '@material-ui/icons/Cancel';
import { FilterRemoveOutline } from 'mdi-material-ui';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import EAMLookup from 'eam-components/dist/ui/components/lookup/EAMLookup';
import TRECSearchPage from '../TRECSearchPage';
import WSAutocomplete from '../../../tools/rest/WSAutocomplete';
import SearchPanel from '../../components/searchpanel/SearchPanel';
import SearchPanelColumn from '../../components/searchpanel/SearchPanelColumn';
import SearchPanelControls from '../../components/searchpanel/SearchPanelControls';
import Tools from '../../../tools/Tools';
import EquipmentHazardContainer from '../../components/eqphazard/EquipmentHazardContainer';
import WSEDHDoc from '../../../tools/rest/WSEDHDoc';
import TRECButton from '../../components/buttons/TRECButton';
import { DATE_FORMAT, GRID_ROW_COLORS } from '../../../enums/Constants';
import EAMGridNoOverflow from '../../components/grid/EAMGridNoOverflow';
import gridNoOverflowClasses from '../../components/grid/EAMGridNoOverflow.module.css';
import { handleError, showSuccess } from 'tools/TrecNotifications';

const labelStyleRight = {
    width: 120,
    minWidth: 70,
    textAlign: 'right',
};

class EDHDocmng extends TRECSearchPage {
    componentWillUnmount() {
        document.removeEventListener('keydown', this.performSearchOnEnterKey.bind(this), false);
    }

    performSearchOnEnterKey(event) {
        this.onKeyDown(event);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.performSearchOnEnterKey.bind(this), false);
        // Parent mount
        super.componentDidMount();
        // Check parameters in the URL (From when it is called from the RPM Perform)
        this._assignRequestParameters();
        // Check if there is buffer zone to set the filter
        const { bufferZone } = this.props;
        if (bufferZone) {
            this.updateProperty('evt_location', bufferZone.locationCode, 'BEGINS', 'VARCHAR');
        }
    }

    _assignRequestParameters = () => {
        const { constants } = this.props;

        // EDH Document
        const edhDocument = Tools.getURLParameterByName('edhdocument');
        // location
        const location = Tools.getURLParameterByName('location');
        // Job location
        const jobLocation = Tools.getURLParameterByName('job_location');
        // Responsible
        const responsible = Tools.getURLParameterByName('responsible');
        // Job Number - evt_code
        const jobNumber = Tools.getURLParameterByName('job_number');
        // Equipment
        const equipment = Tools.getURLParameterByName('equipment');
        // Date Completed
        const datecompleted = Tools.getURLParameterByName('datecompleted');
        // Future Action
        const futureaction = Tools.getURLParameterByName('futureaction');

        // Clean all field if request parameters exist
        const isParameterProvided =
            edhDocument ||
            location ||
            jobLocation ||
            responsible ||
            jobNumber ||
            equipment ||
            datecompleted ||
            futureaction;
        if (isParameterProvided) {
            this.updateProperty('edh_document', '', 'EQUALS', 'VARCHAR', true);
            this.updateProperty('obj_location', '', 'BEGINS', 'VARCHAR');
            this.updateProperty('evt_location', '', 'BEGINS', 'VARCHAR');
            this.updateProperty('eqp_responsible', '', 'BEGINS', 'VARCHAR');
            this.updateProperty('evt_code', '', 'BEGINS', 'VARCHAR');
            this.updateProperty('obj_code', '', 'BEGINS', 'VARCHAR');
            this.updateProperty('evt_completed', '', 'GREATER_THAN', 'VARCHAR');
            this.updateProperty('future_action', '', 'EQUALS', 'VARCHAR', true);
        }

        // apply the real parameters
        if (edhDocument) {
            this.updateProperty('edh_document', edhDocument, 'EQUALS', 'VARCHAR', true);
        }

        if (location) {
            this.updateProperty('obj_location', location, 'BEGINS', 'VARCHAR');
        }

        if (jobLocation) {
            this.updateProperty('evt_location', jobLocation, 'BEGINS', 'VARCHAR');
        }

        if (responsible) {
            this.updateProperty('eqp_responsible', responsible, 'BEGINS', 'VARCHAR');
            this.setState((prevState) => ({ filters: { ...prevState.filters, eqp_responsibleDesc: '' } }));
        }

        if (jobNumber) {
            this.updateProperty('evt_code', jobNumber, this.isMultipleValues(jobNumber) ? 'IN' : 'BEGINS', 'VARCHAR');
        }

        if (equipment) {
            this.updateProperty('obj_code', equipment, 'BEGINS', 'VARCHAR');
        }

        if (datecompleted) {
            this.updateProperty('evt_completed', datecompleted, 'GREATER_THAN', 'VARCHAR');
        }

        if (futureaction) {
            if (futureaction === constants.futureActionDecwaste) {
                // kept for backwards compatibility
                const values = `${constants.futureActionWasteDeclare},${constants.futureActionWasteReceive}`;
                this.updateProperty('future_action', values, 'IN', 'VARCHAR', true);
            } else {
                this.updateProperty('future_action', futureaction, 'EQUALS', 'VARCHAR', true);
            }
        }
    };

    isMultipleValues = (value) => value && value.includes(',');

    _hiddenColumns = () => [
        'associated_risk',
        'contamination',
        'dimensions',
        'eqp_destination_rad',
        'eqp_responsible',
        'evt_status',
        'obj_serialno',
        'obj_value',
        'storage_operation',
        'transp_required',
        'type_transport',
        'asset_locisbz',
        'asset_toplocation',
        'job_locisbz',
        'job_toplocation',
        'asset_sitelocation',
        'job_sitelocation',
        'hazards_list',
    ];

    _cellRenderer = (cell, row) => {
        if (cell.t === 'classification') {
            // Get cell value for classification
            const classification = cell.value;
            // Cell value for contamination
            const contamination = Tools.getGridCellValueFromRow(row, 'contamination');
            // Cell value for associated risks
            const risks = Tools.getGridCellValueFromRow(row, 'associated_risk');
            return (
                <EquipmentHazardContainer
                    classification={classification}
                    contamination={contamination}
                    risks={risks}
                    hazards={Tools.getGridCellValueFromRow(row, 'hazards_list')}
                />
            );
        }
        if (cell.t === 'evt_code') {
            // Link to go to the measurement
            return (
                <Typography>
                    <Link to={`/rpmperform/?wo=${cell.value}`}>{cell.value}</Link>
                </Typography>
            );
        }
        if (cell.t === 'obj_code') {
            // Link to the equipment in EAMLight
            return (
                <Typography>
                    <a
                        target="_blank"
                        href={`${this.props.applicationData.eamlightLink}equipment/${cell.value}`}
                        rel="noreferrer"
                    >
                        {cell.value}
                    </a>
                </Typography>
            );
        }

        // Normal cell
        return false;
    };

    rowStyler = (row) => (Tools.getGridCellValueFromRow(row, 'tdg_error') ? GRID_ROW_COLORS.red : {});

    gridRequestAdapter = (gridRequest) => {
        let gridFilters = [...gridRequest.gridFilter];
        gridFilters = gridFilters.map((gridFilter) => {
            const value = gridFilter.fieldValue;
            switch (gridFilter.fieldName) {
                case 'evt_completed': {
                    let date = moment();
                    if (gridFilter.fieldValue === 'LASTDAY') {
                        date = date.subtract(1, 'day');
                    } else if (gridFilter.fieldValue === 'LASTWEEK') {
                        date = date.subtract(1, 'week');
                    } else if (gridFilter.fieldValue === 'LASTMONTH') {
                        date = date.subtract(1, 'month');
                    }
                    return {
                        ...gridFilter,
                        fieldName: 'evt_completed',
                        fieldValue: date.format(DATE_FORMAT.EAMCOMPONENT),
                    };
                }
                case 'obj_location':
                    return this._getGridFilterLocation(
                        gridFilter,
                        value,
                        'asset_locisbz',
                        'asset_toplocation',
                        'asset_sitelocation',
                        'obj_location'
                    );
                case 'evt_location':
                    return this._getGridFilterLocation(
                        gridFilter,
                        value,
                        'job_locisbz',
                        'job_toplocation',
                        'job_sitelocation',
                        'evt_location'
                    );
                default:
                    return gridFilter;
            }
        });

        return {
            ...gridRequest,
            gridFilter: gridFilters,
        };
    };

    _handleGenerateDocument = () => {
        const { translations, constants } = this.props;
        this.props.openConfirmDialog(
            {
                title: translations.CONFIRM,
                message: translations.AREYOUSURE.replace('{0}', `${Object.keys(this.state.selectedRows).length}`),
                cancelButtonLabel: translations.CANCEL,
                confirmButtonLabel: translations.CONFIRM,
                waitForCompletion: true,
            },
            () =>
                WSEDHDoc.generateEDHDocument(this.getWorkOrdersList(Object.keys(this.state.selectedRows)))
                    .then((response) => {
                        const edhNumbers = response.body.data;
                        const message = translations.EDHDOCCREATED.replace(
                            '{1}',
                            edhNumbers.split(constants.paramSeparator).toString()
                        );
                        showSuccess(message, '', false);
                        // Search
                        this.onSearchClick();
                    })
                    .catch(handleError)
        );
    };

    _handleCancelDocument = () => {
        const { translations } = this.props;
        this.props.openConfirmDialog(
            {
                title: translations.CONFIRM,
                message: translations.CANCELCONFIRM.replace('{0}', `${Object.keys(this.state.selectedRows).length}`),
                cancelButtonLabel: translations.CANCEL,
                confirmButtonLabel: translations.CONFIRM,
                waitForCompletion: true,
            },
            () =>
                WSEDHDoc.cancelEDHDocument(this.getWorkOrdersList(Object.keys(this.state.selectedRows)))
                    .then(() => {
                        showSuccess(translations.CANCELDONE, '', null);
                        // Search
                        this.onSearchClick();
                    })
                    .catch(handleError)
        );
    };

    getWorkOrdersList = (rowKeys) =>
        rowKeys.map((elem) => {
            const row = this.state.selectedRows[elem];
            return Tools.getGridCellValueFromRow(row, 'evt_code');
        });

    _isRowSelectable = (row, selectedRecords) => {
        const keys = Object.keys(selectedRecords);
        const woList = this.getWorkOrdersList(Object.keys(this.state.selectedRows));
        const woNumber = Tools.getGridCellValueFromRow(row, 'evt_code');
        if (woList.includes(woNumber)) {
            // Already selected, so its ok
            return true;
        }
        // Max 20 jobs updated at the same time
        return keys.length < this.props.applicationData.maxUpdateRecords;
    };

    renderPage(writeAccess) {
        const { screenData, lang } = this.props;
        const { customFieldsDef } = this.props.screenData[this.props.lang];
        const { constants } = this.props;

        return (
            <BlockUi tag="div" blocking={this.state.processing} className={gridNoOverflowClasses.outerBlock}>
                <SearchPanel title={this.props.translations.SEARCH_CRITERIA} onPanelChange={this._onPanelChange}>
                    <SearchPanelColumn>
                        <Grid container spacing={8}>
                            <Grid item md={6} sm={12} xs={12}>
                                {/* Equipment */}
                                <EAMAutocomplete
                                    elementInfo={screenData[lang].assetFields.equipmentno}
                                    value={this.state.filters.obj_code}
                                    valueKey="obj_code"
                                    multi
                                    creatable
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'BEGINS', 'VARCHAR', true)
                                    }
                                    loadOptions={WSAutocomplete.autocompleteEquipment}
                                    columnsWidth={['0', '100%']}
                                    autoSelectSingleElement
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <EAMSelect
                                    elementInfo={screenData[lang].customFields[constants.cfpFutureAction]}
                                    valueKey="future_action"
                                    value={this.state.filters.future_action}
                                    values={Tools.getCustomFieldsCodeDesc(
                                        customFieldsDef[constants.cfpFutureAction],
                                        true
                                    )}
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'EQUALS', 'VARCHAR', true)
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item md={6} sm={12} xs={12}>
                                {/* Equipment Responsible */}
                                <EAMAutocomplete
                                    elementInfo={screenData[lang].assetFields.eqpResponsible}
                                    value={this.state.filters.eqp_responsible}
                                    valueKey="eqp_responsible"
                                    valueDesc={this.state.filters.eqp_responsibleDesc}
                                    descKey="eqp_responsibleDesc"
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'BEGINS', 'VARCHAR')
                                    }
                                    loadOptions={WSAutocomplete.autocompleteEmployee}
                                    labelStyle={labelStyleRight}
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                {/* EDH Document */}
                                <EAMSelect
                                    elementInfo={screenData[lang].customFields[constants.cfpEdhDocument]}
                                    valueKey="edh_document"
                                    value={this.state.filters.edh_document}
                                    values={Tools.getCustomFieldsCodeDesc(
                                        customFieldsDef[constants.cfpEdhDocument],
                                        true
                                    )}
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'EQUALS', 'VARCHAR', true)
                                    }
                                />
                            </Grid>
                        </Grid>
                    </SearchPanelColumn>
                    <SearchPanelColumn>
                        <Grid container spacing={8}>
                            <Grid item md={6} sm={12} xs={12}>
                                <EAMLookup
                                    right={1}
                                    top={13}
                                    width={26}
                                    height={26}
                                    gridId={this.props.applicationData.locationRaisinGridID}
                                    keys={{
                                        code: 'obj_code',
                                        mapCodeTo: 'obj_location',
                                    }}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'BEGINS', 'VARCHAR')
                                    }
                                    value={this.state.filters.obj_location}
                                >
                                    <EAMSelect
                                        elementInfo={screenData[lang].assetFields.eqpLocation}
                                        valueKey="obj_location"
                                        creatable
                                        value={this.state.filters.obj_location}
                                        values={screenData[lang].lists.locationsList}
                                        labelStyle={labelStyleRight}
                                        updateProperty={(key, value) =>
                                            this.updateProperty(key, value, 'BEGINS', 'VARCHAR')
                                        }
                                        arrowRenderer={() => <span />}
                                    />
                                </EAMLookup>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <EAMLookup
                                    right={1}
                                    top={13}
                                    width={26}
                                    height={26}
                                    gridId={this.props.applicationData.locationRaisinGridID}
                                    keys={{
                                        code: 'obj_code',
                                        mapCodeTo: 'evt_location',
                                    }}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'BEGINS', 'VARCHAR')
                                    }
                                    value={this.state.filters.evt_location}
                                >
                                    <EAMSelect
                                        elementInfo={screenData[lang].woFields.location}
                                        valueKey="evt_location"
                                        value={this.state.filters.evt_location}
                                        values={screenData[lang].lists.locationsList}
                                        creatable
                                        labelStyle={labelStyleRight}
                                        updateProperty={(key, value) =>
                                            this.updateProperty(key, value, 'BEGINS', 'VARCHAR')
                                        }
                                        arrowRenderer={() => <span />}
                                    />
                                </EAMLookup>
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid item md={6} sm={12} xs={12}>
                                {/* Job Number */}
                                <EAMInput
                                    elementInfo={screenData[lang].woFields.workordernum}
                                    value={this.state.filters.evt_code}
                                    valueKey="evt_code"
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(
                                            key,
                                            value,
                                            this.isMultipleValues(value) ? 'IN' : 'BEGINS',
                                            'VARCHAR'
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                {/* Date Completed */}
                                <EAMSelect
                                    elementInfo={screenData[lang].woFields.datecompleted}
                                    valueKey="evt_completed"
                                    value={this.state.filters.evt_completed}
                                    values={screenData[lang].lists.createdList}
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'GREATER_THAN', 'VARCHAR')
                                    }
                                />
                            </Grid>
                        </Grid>
                    </SearchPanelColumn>
                    <SearchPanelControls>
                        <TRECButton
                            color="default"
                            onClick={() => {
                                this.props.history.push('/menu');
                            }}
                        >
                            <MenuIcon />
                            {this.props.translations.GOTOMENU}
                        </TRECButton>

                        <TRECButton onClick={this.onSearchClick}>
                            <SearchIcon />
                            {this.props.translations.SEARCH}
                        </TRECButton>

                        <TRECButton onClick={this.onClearClick}>
                            <FilterRemoveOutline />
                            {this.props.translations.RESETVALUES}
                        </TRECButton>

                        {writeAccess && (
                            <TRECButton
                                onClick={this._handleGenerateDocument}
                                disabled={Object.keys(this.state.selectedRows).length === 0}
                            >
                                <AttachFile />
                                {this.props.translations.EDHGENERATION}
                            </TRECButton>
                        )}

                        {writeAccess && (
                            <TRECButton
                                onClick={this._handleCancelDocument}
                                color="default"
                                disabled={Object.keys(this.state.selectedRows).length === 0}
                            >
                                <Cancel />
                                {this.props.translations.CANCELDOCS}
                            </TRECButton>
                        )}
                    </SearchPanelControls>
                </SearchPanel>
                <EAMGridNoOverflow
                    onRef={(ref) => {
                        this.grid = ref;
                    }}
                    gridId={this.props.applicationData.rpmedhdocGridID}
                    cellRenderer={this._cellRenderer}
                    showDataspySelection={false}
                    hiddenTags={this._hiddenColumns()}
                    heightFilterNotVisible="189px"
                    gridRequestAdapter={this.gridRequestAdapter}
                    allowRowSelection={writeAccess}
                    isRowSelectable={this._isRowSelectable}
                    onSelectRow={this.onSelectRow}
                    headerStyle={{}}
                    rowStyler={this.rowStyler}
                />
            </BlockUi>
        );
    }
}

export default EDHDocmng;
