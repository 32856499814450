import { setPage, updateLayout } from 'actions/layoutActions';
import { connect } from 'react-redux';
import RPMeasurementsSearch from './RPMeasurements';

function mapStateToProps(state) {
    return {
        translations: state.application.screenData[state.layout.lang].translations.TREC_RPMEASURMENTS,
        screenData: state.application.screenData,
        applicationData: state.application.applicationData,
        lang: state.layout.lang,
        constants: state.application.constants,
        masterPage: 'RPMEASUREMENTS',
        page: 'RPMEASUREMENTS',
        menu: state.application.screenData[state.layout.lang].menu[state.application.menuType],
        bufferZone: state.application.bufferZone,
    };
}

const RPMeasurementsContainer = connect(mapStateToProps, {
    updateLayout,
    setPage,
})(RPMeasurementsSearch);

export default RPMeasurementsContainer;
