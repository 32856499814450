// @flow
import queryString from 'query-string';
import client from './client';

/**
 * Handles all calls to REST Api
 */
class WS {
    //
    // GENERAL
    //
    getUserData(config = {}) {
        return this._get('/application/userdata', config);
    }

    getApplicationVersion(config = {}) {
        return this._get('/application/version', config);
    }

    getApplicationData(egroupList, config = {}) {
        return this._get('/application/applicationdata', config);
    }

    getScreenData(egroupList, config = {}) {
        return this._get(
            `/application/screenelements?${egroupList ? queryString.stringify({ egroupList }) : ''}`,
            config
        );
    }

    getBufferZoneData(bufferzone, config = {}) {
        return this._get(`/application/bufferzone?bufferzone=${bufferzone}`, config);
    }

    getConstants(config = {}) {
        return this._get('/application/constants', config);
    }

    postLoginData(data, loginType, config = {}) {
        return this._post(`/login/${loginType}`, data, config);
    }

    getMenu(egroupList, config = {}) {
        return this._get(`/application/menu?${egroupList ? queryString.stringify({ egroupList }) : ''}`, config);
    }

    clearServerCache() {
        return this._delete(`/application/cache/`);
    }

    //
    //
    //
    _get(url, config = {}) {
        return client.get(process.env.REACT_APP_BACKEND + url, config);
    }

    _post(url, data, config = {}) {
        return client.post(process.env.REACT_APP_BACKEND + url, data, config);
    }

    _put(url, data, config = {}) {
        return client.put(process.env.REACT_APP_BACKEND + url, data, config);
    }

    _delete(url, config = {}) {
        return client.delete(process.env.REACT_APP_BACKEND + url, config);
    }

    _postEDMS(url, config = {}) {
        return client.post(process.env.REACT_APP_BACKEND.replace('/rest', '/edms') + url, config);
    }
}

export default new WS();
