import { openConfirmDialog } from 'react-eis-components/dist/actions/uiActions';
import { connect } from 'react-redux';
import { logout } from '../../../actions/applicationActions';
import { setPage, updateLayout } from '../../../actions/layoutActions';
import rparActions from '../../../actions/rparActions';
import { rparGettersWithState } from '../../../reducers/rparReducer';
import RPARequest from './RPARequest';
import Tools from '../../../tools/Tools';

const RPAM_REQUEST_MASTERPAGE = 'RPAM_REQUEST';
const RPAM_TRANSLATION_KEY = 'TREC_RPAM';

function mapStateToProps(state) {
    const { applicationData, screenData, bufferZone, menuType, constants, userData, rwpDropdowns } = state.application;
    const { lang } = state.layout;
    const translations = screenData[lang].translations[RPAM_TRANSLATION_KEY];
    const queryParamLang = Tools.getURLParameterByName('lang');

    const rwpDropdownsTranslated = Tools.applyToFields(rwpDropdowns, (list) =>
        list.map((codeDesc) => ({
            ...codeDesc,
            desc: (codeDesc.descTranslated && codeDesc.descTranslated[lang]) || codeDesc.desc,
        }))
    );

    return {
        page: RPAM_REQUEST_MASTERPAGE,
        masterPage: RPAM_REQUEST_MASTERPAGE,
        screenData: state.application.screenData[lang],
        constants,
        userData,
        applicationData,
        dropdowns: rwpDropdownsTranslated,
        translations,
        lang,
        bufferZone,
        getTranslation: (code) =>
            code &&
            ((queryParamLang === 'DEV' && code) ||
                (translations && translations[code.toUpperCase()]) ||
                (screenData.EN.translations[RPAM_TRANSLATION_KEY] &&
                    screenData.EN.translations[RPAM_TRANSLATION_KEY][code.toUpperCase()]) ||
                code),
        rpar: state.rpar,
        menu: screenData[lang].menu[menuType],
        rparGetters: rparGettersWithState(state.rpar),
        rpamAnalysisTypes: applicationData.rpamanalysisTypes,
        rparStepSequence: applicationData.rpamrequestStepSequence,
        translatedScreenData: screenData[lang],
    };
}

const RPARequestContainer = connect(mapStateToProps, {
    updateLayout,
    setPage,
    openConfirmDialog,
    logout,
    ...rparActions,
})(RPARequest);

export default RPARequestContainer;
