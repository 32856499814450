export const STEP_SUFFIX = '_STEP';
export const CHECKLIST_PREFIX = 'PRWE_';

export const RW_ELIMINATION_STATUS = {
    CREATED: 'RDT',
    COLIS_PRESELECTIONNES: 'R',
    VERIFIE: 'RA',
    VALIDE: 'RA1',
    DECLAREE: 'RS',
    PLANIFIE: 'RASH',
    ENVOYEE: 'RT',
    RECU: 'RTF',
    FINISHED: 'T',

    PLANIFIE2: 'RATS',
    CANCELLED: 'TX',
    POSTPONED: 'RAD',
};

export const getChecklistKey = (status) => `${CHECKLIST_PREFIX}${status}_CHKL`;

export const getChecklistStepKey = (status) => `${getChecklistKey(status)}${STEP_SUFFIX}`;

export const getStepKey = (status) =>
    `${Object.keys(RW_ELIMINATION_STATUS).find((key) => status === RW_ELIMINATION_STATUS[key])}${STEP_SUFFIX}`;

export const RW_ELIM_STEPS_KEY = {
    CREATED: 'CREATED_STEP',
    COLIS_PRESELECTIONNES: 'COLIS_PRESELECTIONNES_STEP',
    VERIFIE: 'VERIFIE_STEP',
    VALIDE: 'VALIDE_STEP',
    DECLAREE: 'DECLAREE_STEP',
    PLANIFIE: 'PLANIFIE_STEP',
    ENVOYEE: 'ENVOYEE_STEP',
    RECU: 'RECU_STEP',
    FINISHED: 'FINISHED_STEP',
    // PLANIFIE2: 'PLANIFIE2_STEP',
};

export const RWELIM_STATUS_MAXSTEP = {
    [null]: RW_ELIM_STEPS_KEY.CREATED,
    [RW_ELIMINATION_STATUS.CREATED]: RW_ELIM_STEPS_KEY.COLIS_PRESELECTIONNES,
    [RW_ELIMINATION_STATUS.COLIS_PRESELECTIONNES]: RW_ELIM_STEPS_KEY.VERIFIE,
    [RW_ELIMINATION_STATUS.VERIFIE]: RW_ELIM_STEPS_KEY.VALIDE,
    [RW_ELIMINATION_STATUS.VALIDE]: RW_ELIM_STEPS_KEY.DECLAREE,
    [RW_ELIMINATION_STATUS.DECLAREE]: RW_ELIM_STEPS_KEY.PLANIFIE,
    [RW_ELIMINATION_STATUS.PLANIFIE]: RW_ELIM_STEPS_KEY.ENVOYEE,
    [RW_ELIMINATION_STATUS.ENVOYEE]: RW_ELIM_STEPS_KEY.RECU,
    [RW_ELIMINATION_STATUS.RECU]: RW_ELIM_STEPS_KEY.FINISHED,

    [RW_ELIMINATION_STATUS.POSTPONED]: RW_ELIM_STEPS_KEY.FINISHED,
    [RW_ELIMINATION_STATUS.CANCELLED]: RW_ELIM_STEPS_KEY.FINISHED,
    [RW_ELIMINATION_STATUS.FINISHED]: RW_ELIM_STEPS_KEY.FINISHED,
};

export const COLISSAGE_OPTIONS = {
    BT: 'Bottle',
    BD: 'Can',
    CN: 'Carton',
    CA: 'Case',
    CT: 'Container',
    C1: 'Container 20 Feet',
    C2: 'Container 40 Feet',
    HA: 'Crate',
    CL: 'Cylinder',
    FT: 'Drum',
    EN: 'Envelope',
    FD: 'Load',
    VR: 'Loose',
    LO: 'Lot',
    CO: 'Package',
    PQ: 'Packet',
    P1: 'Pal + 1 Cadre + 1 Couvercle',
    P2: 'Pal + 2 Cadres + 1 Couvercle',
    P3: 'Pal + 2 Cadres + 2 Couvercles',
    PL: 'Pallet',
    TR: 'Reel',
    SC: 'Sack',
    CI: 'Tank',
    CH: 'Trolley',
    CM: 'Truck',
    TU: 'Tube',
};

export const TRANSLATION_KEYS = {
    TITLE: 'RW_ELIM_TITLE',
    DESC: 'DESC',
    STATUS: 'STATUS',
    JOB_NUMBER: 'JOBNUMBER',
    EDH_NUMBER: 'EDHNUMBER',
    INVOICE_EDH: 'INVOICE_EDH',
    PACKAGES: 'PACKAGES',
    END_DATE: 'ENDDATE',
    RW_ELIMINATION_PATHWAY: 'RWELIMINATIONPATHWAY',
    TARE_WEIGHT_TRANSPORTING_CONTAINER: 'TAREWEIGHTTRANSPORTINGCONTAINER',
    IDTRANSPORTINGCONTAINER: 'IDTRANSPORTINGCONTAINER',
    TRANSP_CONT_CONTENT: 'TRANSP_CONT_CONTENT',
    TRANSP_CONT_WEIGHT: 'TRANSP_CONT_WEIGHT',
    TRANSP_CONT_TOTAL_WEIGHT: 'TRANSP_CONT_TOTAL_WEIGHT',
    TOTAL_MASS: 'TOTALMASS',
    DOCUMENTS: 'DOCUMENTS',
    ID_ANDRA: 'IDANDRA',
    TYPE: 'TYPE',
    WEIGHT: 'WEIGHT',
    PACKAGE_WEIGHT: 'PACKAGEWEIGHT',
    RPA: 'RPA',
    PICKUP_DATE: 'PICKUP_DATE',

    CREATED_BY: 'CREATED_BY',
    UPDATED_BY: 'UPDATED_BY',
    CREATED_ON: 'JOBCREATED',
    UPDATED_ON: 'UPDATED',

    SELECT_EQUIPMENT_FOR_DOC: 'SELECT_EQUIPMENT_FOR_DOC',
    EDMS_CONTEXT_UNKNOWN: 'EDMS_CONTEXT_UNKNOWN',
    ERROR_RETRIEVING_DOCUMENTS: 'ERROR_RETRIEVING_DOCUMENTS',
    EDMS_CONTEXT_INVALID: 'EDMS_CONTEXT_INVALID',
};

export const RW_ELIMINATION_KEYS = {
    CODE: 'jobNumber',
    DESCRIPTION: 'description',
    STATUS: 'statusCode',
    EDH_NUMBER: 'edhDocumentNumber',
    INVOICE_EDH: 'invoiceEdh',
    PACKAGES: 'packages',
    PICKUP_DATE: 'requestedStartDate',
    END_DATE: 'requestedEndDate',
    RW_ELIMINATION_PATHWAY: 'rwEliminationPathway',
    // TARE_WEIGHT_TRANSPORTING_CONTAINER: 'tareWeightOfTransportingContainer',
    TRANSPORTING_CONTAINERS: 'transportingContainers',
    ID_ANDRA: 'idAndra',
    SUPPLIER: 'supplier',
    ASSIGNED_TO: 'assignedTo',
    COLISSAGE: 'colissage',
    LENGTH: 'length',
    WIDTH: 'width',
    HEIGHT: 'height',
    BUDGET_CODE: 'budgetCode',
    LOCATION: 'location',
    CREATED_BY: 'creator',
    CREATED_ON: 'createdOn',
    MODIFIED_BY: 'modifiedBy',
    MODIFIED_ON: 'modifiedOn',
};

export const RW_ELIMINATION_PACKAGE_KEYS = {
    CODE: 'mecCode',
    EQUIPMENT_CODE: 'equipmentCode',
    ELIMINATION_PATHWAY: 'eliminationPathway',
    EQUIPMENT: 'equipment',
    GROSS_MASS: 'grossMass',
    RPA: 'rpaNumber',
    TRANSPORT_CONTAINER: 'transportingContainer',
    ID_TRANSPORT_CONTAINER: 'idTransportingContainer',
    IRAS_VALUE: 'irasValue',
    CONTAINER_ELIMINATION: 'containerElimination',
};

export const RW_ELIMINATION_TRANSPORT_CONTAINER_KEYS = {
    TC_CODE: 'tcCode',
    TARE_WEIGHT: 'tareWeight',
};

export const RW_ELIMINATION_DTO_KEYLIST = [
    RW_ELIMINATION_KEYS.CODE,
    RW_ELIMINATION_KEYS.DESCRIPTION,
    RW_ELIMINATION_KEYS.STATUS,
    RW_ELIMINATION_KEYS.EDH_NUMBER,
    RW_ELIMINATION_KEYS.INVOICE_EDH,
    RW_ELIMINATION_KEYS.PACKAGES,
    RW_ELIMINATION_KEYS.END_DATE,
    RW_ELIMINATION_KEYS.RW_ELIMINATION_PATHWAY,
    // RW_ELIMINATION_KEYS.TARE_WEIGHT_TRANSPORTING_CONTAINER,
    // RW_ELIMINATION_KEYS.ID_TRANSPORTING_CONTAINER,
    RW_ELIMINATION_KEYS.TRANSPORTING_CONTAINERS,
    RW_ELIMINATION_KEYS.ID_ANDRA,
    RW_ELIMINATION_KEYS.SUPPLIER,
    RW_ELIMINATION_KEYS.PICKUP_DATE,
    RW_ELIMINATION_KEYS.ASSIGNED_TO,
    RW_ELIMINATION_KEYS.COLISSAGE,
    RW_ELIMINATION_KEYS.LENGTH,
    RW_ELIMINATION_KEYS.WIDTH,
    RW_ELIMINATION_KEYS.HEIGHT,
    RW_ELIMINATION_KEYS.LOCATION,
    RW_ELIMINATION_KEYS.BUDGET_CODE,
];

export const RW_ELIMINATION_PACKAGE_DTO_KEYLIST = [
    RW_ELIMINATION_PACKAGE_KEYS.CODE,
    RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE,
    RW_ELIMINATION_PACKAGE_KEYS.RPA,
    RW_ELIMINATION_PACKAGE_KEYS.TRANSPORT_CONTAINER,
    RW_ELIMINATION_PACKAGE_KEYS.IRAS_VALUE,
    RW_ELIMINATION_PACKAGE_KEYS.CONTAINER_ELIMINATION,
];
