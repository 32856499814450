import React, { Component } from 'react';
import InfoPage from '../components/infopage/InfoPage';

/**
 *
 * Styles for the pages
 */
export const trecPageStyles = () => ({
    selectLocation: {
        '& .Select-menu-outer': {
            width: 'auto',
            maxWidth: '600px',
        },
    },
    selectStyle: {
        '& .Select-menu-outer': {
            zIndex: 2,
            width: '500px',
        },
    },
});

/**
 * General TREC Page
 * The page needs to receive the setPage function and also the menu to be able to know if there is access or nor
 */
class TRECPage extends Component {
    blockUiStyle = {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    blockUiStyleDiv = {
        display: 'flex',
        height: 60,
        alignItems: 'flex-end',
    };

    constructor(props) {
        super(props);
        //
        this.formFields = {};
        // Set the page with the settings
        const masterPage = Array.isArray(props.masterPage) ? props.masterPage[0] : props.masterPage;
        if (props.setPage) {
            props.setPage(masterPage, props.page);
        } else {
            console.warn('WARNING: Page is not receiving the setPage function as prop!');
        }
    }

    checkAccess = () => {
        // Check the access with menu
        let access = false;
        let writeAccess = false;
        const { masterPage, menu } = this.props;
        // Check access'
        menu.forEach((menuOption) => {
            if (
                (Array.isArray(masterPage) && masterPage.includes(menuOption.menuCode)) ||
                menuOption.menuCode === masterPage
            ) {
                // There is access
                access = true;
                writeAccess = !menuOption.readOnly;
            }
        });
        return { access, writeAccess };
    };

    //
    // VALIDATION
    //
    validateFields() {
        return Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
    }

    render() {
        const { redirectUrl } = this.props;
        const { access, writeAccess } = this.checkAccess();
        // Check the access first
        if (!access) {
            if (redirectUrl) {
                window.open(redirectUrl, '_self');
            }
            // Access denied to the page
            return <InfoPage title="Access Denied" message="You seem to have no access to this screen." />;
        }

        // Render real page
        return <div className="trecPageContainer">{this.renderPage(writeAccess)}</div>;
    }
}

export default TRECPage;
