import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import React from 'react';
import Tools from '../../../../tools/Tools';
import {
    FILTER_ACTION,
    FILTER_FUTURE_ACTION,
    FILTER_OBJRESP,
    FILTER_RADIATION_CLASSIFICATION,
    FILTER_RESULT,
    FILTER_STATUS,
    getActionDropdownValues,
    getResultDropdownValues,
    NOT_WASTE,
} from './BZInventoryUtils';

class BZInventoryFilters extends React.Component {
    inputDivStyle = {
        width: '100%',
    };

    filterColumn = {
        maxWidth: 750,
        width: '100%',
        flexDirection: 'column',
        padding: '20px',
    };

    filterContainer = {
        display: 'flex',
        flexWrap: 'wrap',
    };

    labelStyle = {
        minWidth: 150,
    };

    render() {
        const {
            actions,
            jobStatusValues,
            futureActions,
            radiationClassifications,
            filters,
            translations,
            updateProperty,
            inventoryData,
        } = this.props;

        const responsibleList = [...new Set(inventoryData?.map((s) => s.responsible).filter((s) => s) || [])];

        return (
            <div style={this.filterContainer}>
                <div style={this.filterColumn}>
                    <div style={this.inputDivStyle}>
                        <EAMSelect
                            elementInfo={{
                                text: translations.RESULT,
                            }}
                            values={getResultDropdownValues(translations)}
                            valueKey={FILTER_RESULT}
                            value={filters[FILTER_RESULT]}
                            updateProperty={updateProperty}
                            labelStyle={this.labelStyle}
                        />
                    </div>
                    <div style={this.inputDivStyle}>
                        <EAMSelect
                            elementInfo={{
                                text: translations.MEASSTATUS,
                            }}
                            values={jobStatusValues}
                            valueKey={FILTER_STATUS}
                            value={filters[FILTER_STATUS]}
                            updateProperty={updateProperty}
                            labelStyle={this.labelStyle}
                        />
                    </div>
                    {actions && (
                        <div style={this.inputDivStyle}>
                            <EAMSelect
                                elementInfo={{
                                    text: translations.ACTION,
                                }}
                                valueKey={FILTER_ACTION}
                                value={filters[FILTER_ACTION]}
                                values={getActionDropdownValues(translations)}
                                updateProperty={updateProperty}
                                labelStyle={this.labelStyle}
                            />
                        </div>
                    )}
                </div>
                <div style={this.filterColumn}>
                    <div style={this.inputDivStyle}>
                        <EAMSelect
                            elementInfo={{
                                text: translations.FUTURE_ACTION,
                            }}
                            values={Tools.getCustomFieldsCodeDesc(futureActions, true).concat({
                                code: NOT_WASTE,
                                desc: translations.NOT_WASTE,
                            })}
                            valueKey={FILTER_FUTURE_ACTION}
                            value={filters[FILTER_FUTURE_ACTION]}
                            updateProperty={updateProperty}
                            labelStyle={this.labelStyle}
                        />
                    </div>
                    <div style={this.inputDivStyle}>
                        <EAMSelect
                            elementInfo={{
                                text: translations.CLASSIFICATION,
                            }}
                            values={Tools.getCustomFieldsCodeDesc(radiationClassifications)}
                            valueKey={FILTER_RADIATION_CLASSIFICATION}
                            value={filters[FILTER_RADIATION_CLASSIFICATION]}
                            updateProperty={updateProperty}
                            labelStyle={this.labelStyle}
                        />
                    </div>
                    <div style={this.inputDivStyle}>
                        <EAMSelect
                            elementInfo={{
                                text: translations.EQPRESPONSIBLE,
                                attribute: 'O',
                            }}
                            value={filters[FILTER_OBJRESP]}
                            values={responsibleList.map((s) => ({ code: s, desc: s }))}
                            valueKey={FILTER_OBJRESP}
                            updateProperty={updateProperty}
                            labelStyle={this.labelStyle}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default BZInventoryFilters;
