import { Typography } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import React from 'react';

class WRRNoAccessStep extends WizardStep {
    /**
     * Can continue to the next step on the wizard
     */
    canContinue = () => true;

    /**
     * SaveChanges is used to save the localized state to the redux-store. It can only run synchronous methods.
     */
    saveChanges = () => true;

    /**
     *  CommitChanges is used to trigger any side effects of the step, such as invocations to the back-end to
     * retrieve information about resources. This method can run asynchronous methods/requests but SHALL execute
     * the callback method afterwards to let the inform the wizard.
     */
    commitChanges = async (callback) => {
        callback();
    };

    /**
     * On true, the wizard automatically jumps this step immediately after it is mounted
     */
    canJumpStep = () => false;

    render() {
        const { getTranslation } = this.props;
        return (
            <Typography
                style={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}
                component="span"
            >
                <div style={{ padding: '30px', border: '2px solid', borderRadius: '10px', margin: '30px' }}>
                    <i className="fas fa-info-circle fa-2x" style={{ paddingRight: '6px' }} />
                    {getTranslation('NOACCESS')}
                </div>
            </Typography>
        );
    }
}

export default WRRNoAccessStep;
