import { CONTAINER_CLASSES, EQUIPMENT_KEYS, RWP_KEYS } from './RWPConstants';

export const isContainer = (equipment) => CONTAINER_CLASSES.includes(equipment[EQUIPMENT_KEYS.CLASS]);

export const isContaminated = (equipment) => equipment[EQUIPMENT_KEYS.CONTENT_CONTAMINATED] === 'Yes';

export const isCompleted = ({ rwpProperties, systemStatus, userStatus }) => {
    const completedSystemStatus = 'C';
    return rwpProperties
        ? rwpProperties[RWP_KEYS.SYSTEM_STATUS] === completedSystemStatus
        : systemStatus
        ? systemStatus === completedSystemStatus
        : userStatus && ['T', 'TX'].includes(userStatus);
};

export const applyDefaults = (object = {}, keyValueMap) =>
    Object.entries(keyValueMap).reduce(
        (acc, [key, defaultValue]) => ({
            ...acc,
            [key]: defaultValue,
        }),
        object
    );

export default {
    isContainer,
    isContaminated,
    isCompleted,
    applyDefaults,
};
