export default {
    GET_RW_ELIM_CREATED_SUCCESS_MSG: 'GET_RW_ELIM_CREATED_SUCCESS_MSG',
    EDHDOCCREATED: 'EDHDOCCREATED',
    CREATE_RW_TRANSPORT_REQUEST_TITLE: 'CREATE_RW_TRANSPORT_REQUEST_TITLE',
    CREATE_RW_TRANSPORT_REQUEST_MESSAGE: 'CREATE_RW_TRANSPORT_REQUEST_MESSAGE',
    BUTTON_CONFIRM: 'YES',
    BUTTON_CANCEL: 'BUTTON_CANCEL',
    EDHNUMBER: 'EDHNUMBER',
    PRESELECTED_PACKAGES: 'PRESELECTED_PACKAGES',
    UNASSIGNED_PACKAGES: 'UNASSIGNED_PACKAGES',
    CONTAINER_ELIMINATION: 'CONTAINER_ELIMINATION',
    CONTENT_ONLY: 'CONTENT_ONLY',
    CONTAINER_AND_CONTENT: 'CONTAINER_AND_CONTENT',
    UNASSIGNED_CONTAINER_ELIMINATION: 'UNASSIGNED_CONTAINER_ELIMINATION',
};
