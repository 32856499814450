import React, { useState } from 'react';
import { RW_ELIMINATION_PACKAGE_KEYS } from './RWEliminationConstants';
import RWElimTranslations from './RWElimTranslations';
import Checkbox from '@material-ui/core/Checkbox';
import groupBy from 'lodash/groupBy';
import { Button } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
};

const columnStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
};

const labelStyle = {
    height: '100%',
    fontWeight: 'bold',
    fontSize: '14px',
    marginRight: '4px',
};

const boxStyle = {
    border: '1px solid #ced4da',
    width: '100%',
    borderRadius: '4px',
    flexWrap: 'wrap',
    backgroundColor: '#fafafa',
    marginRight: '10px',
    height: '312px',
    overflow: 'scroll',
};

const buttonGroupStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 20px',
};

const itemGroupStyle = {
    fontSize: '14px',
};

const groupHeaderStyle = (index) => ({
    background: '#f0f0f0',
    borderBottom: '1px solid #bbb',
    minHeight: '30px',
    alignItems: 'center',
    display: 'flex',
    paddingLeft: '3px',
    borderTop: index === 0 ? 'none' : '1px solid #bbb',
});

const groupLabelStyle = {
    marginLeft: '10px',
};

const ContainerEliminationSelection = ({ wastePackages, updateContainerElimination, getTranslation, writeAccess }) => {
    const [contentSelected, setContentSelected] = useState([]);
    const [containerSelected, setContainerSelected] = useState([]);

    const contentOnly = wastePackages.filter((s) => s[RW_ELIMINATION_PACKAGE_KEYS.CONTAINER_ELIMINATION] === false);
    const containerAndContent = wastePackages.filter(
        (s) => s[RW_ELIMINATION_PACKAGE_KEYS.CONTAINER_ELIMINATION] === true
    );

    const groupedContentOnly = groupBy(contentOnly, 'transportingContainer');
    const groupedContainerAndContent = groupBy(containerAndContent, 'transportingContainer');

    const handleSelectItem = (item, selectedItems, setSelectedItems) => {
        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter((i) => i !== item));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    };

    const handleSelectGroup = (group, selectedItems, setSelectedItems) => {
        const allSelected = group.every((item) => selectedItems.includes(item));
        if (allSelected) {
            setSelectedItems(selectedItems.filter((item) => !group.includes(item)));
        } else {
            setSelectedItems([...selectedItems, ...group.filter((item) => !selectedItems.includes(item))]);
        }
    };

    const updateWastePackagesContainerElimination = (selection, value) => {
        updateContainerElimination(
            selection.map((item) => item.equipmentCode),
            value
        );
    };

    const moveItemsToRight = () => {
        updateWastePackagesContainerElimination(contentSelected, true);
        setContentSelected([]);
    };

    const moveAllItemsToRight = () => {
        updateWastePackagesContainerElimination(contentOnly, true);
        setContentSelected([]);
    };

    const moveItemsToLeft = () => {
        updateWastePackagesContainerElimination(containerSelected, false);
        setContainerSelected([]);
    };

    const moveAllItemsToLeft = () => {
        updateWastePackagesContainerElimination(containerAndContent, false);
        setContainerSelected([]);
    };

    const renderGroupedItems = (groupedItems, selectedItems, setSelectedItems) =>
        Object.entries(groupedItems).map(([transportingContainer, entries], index) => (
            <div key={transportingContainer} style={itemGroupStyle}>
                <div style={groupHeaderStyle(index)}>
                    {writeAccess && (
                        <Checkbox
                            type="checkbox"
                            checked={entries.every((item) => selectedItems.includes(item))}
                            indeterminate={
                                entries.some((item) => selectedItems.includes(item)) &&
                                entries.some((item) => !selectedItems.includes(item))
                            }
                            onChange={() => handleSelectGroup(entries, selectedItems, setSelectedItems)}
                        />
                    )}
                    {transportingContainer !== 'undefined' ? `TC ${Number(transportingContainer) + 1}` : 'TC undefined'}
                </div>
                <div style={groupLabelStyle}>
                    {entries.map((item) => (
                        <div
                            style={{ minHeight: '30px', alignItems: 'center', display: 'flex' }}
                            key={item.equipmentCode}
                        >
                            {writeAccess && (
                                <Checkbox
                                    type="checkbox"
                                    checked={selectedItems.includes(item)}
                                    onChange={() => handleSelectItem(item, selectedItems, setSelectedItems)}
                                />
                            )}
                            {item.equipmentCode}{' '}
                            {item.eliminationPathway && <i style={{ fontSize: '10px' }}>({item.eliminationPathway})</i>}
                        </div>
                    ))}
                </div>
            </div>
        ));

    return (
        <div style={containerStyle}>
            <div style={columnStyle}>
                <span style={labelStyle}>{getTranslation(RWElimTranslations.CONTENT_ONLY)}</span>
                <div style={boxStyle}>
                    {renderGroupedItems(groupedContentOnly, contentSelected, setContentSelected)}
                </div>
            </div>

            {writeAccess ? (
                <div style={buttonGroupStyle}>
                    <Button
                        style={{ padding: '0px' }}
                        onClick={moveAllItemsToRight}
                        disabled={contentOnly.length === 0}
                    >
                        <LastPageIcon />
                    </Button>
                    <Button
                        style={{ padding: '0px' }}
                        onClick={moveItemsToRight}
                        disabled={contentSelected.length === 0}
                    >
                        <KeyboardArrowRightIcon />
                    </Button>
                    <Button
                        style={{ padding: '0px' }}
                        onClick={moveItemsToLeft}
                        disabled={containerSelected.length === 0}
                    >
                        <KeyboardArrowLeftIcon />
                    </Button>
                    <Button
                        style={{ padding: '0px' }}
                        onClick={moveAllItemsToLeft}
                        disabled={containerAndContent.length === 0}
                    >
                        <FirstPageIcon />
                    </Button>
                </div>
            ) : (
                <div style={{ minWidth: '30px' }} />
            )}

            <div style={columnStyle}>
                <span style={labelStyle}>{getTranslation(RWElimTranslations.CONTAINER_AND_CONTENT)}</span>
                <div style={boxStyle}>
                    {renderGroupedItems(groupedContainerAndContent, containerSelected, setContainerSelected)}
                </div>
            </div>
        </div>
    );
};

export default ContainerEliminationSelection;
