import { Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import { showError } from 'tools/TrecNotifications';
import EDMSDoclightIframeContainer from 'ui/components/iframes/EDMSDoclightIframeContainer';
import { getInputGenerator } from 'ui/pages/rpmrequest2/InputGenerator';
import { RW_ELIMINATION_KEYS, TRANSLATION_KEYS } from '../RWEliminationConstants';
import { getUsageFields } from './RWEliminationCreationStep';
import { generateTCTableData, getDetailsFields, getTCFields } from './RWEliminationPreselectionStep';
import { validateEDMSContext } from '../RWElimUtils';
import ErrorText from 'ui/components/text/ErrorText';

const RWEliminationControl = ({
    labelStyle,
    rwElimRequest,
    writeAccess,
    getTranslation,
    formFields,
    rwElimGetters,
    storeActions,
    lang,
    applicationData,
    dropdowns,
    stepStatus,
}) => {
    const rwElimination = rwElimGetters.getProperties();
    const rwEquipmentList = Object.values(rwElimination[RW_ELIMINATION_KEYS.PACKAGES]);
    const transportingContainers = rwElimination[RW_ELIMINATION_KEYS.TRANSPORTING_CONTAINERS];

    const usageFields = getUsageFields({
        getTranslation,
        rwEquipmentList,
        applicationData,
        storeActions,
        dropdowns,
        canEdit: false,
    });

    const detailsFields = getDetailsFields({
        getTranslation,
        transportingContainers,
        rwEquipmentList,
        applicationData,
        storeActions,
        dropdowns,
    });
    const inputGenerator = getInputGenerator({
        object: rwElimRequest,
        updateObject: storeActions.updateRWElimination,
        updateObjectPartial: storeActions.updateRWEliminationPartial,
        formFields,
        labelStyle,
        getTranslation,
        writeAccess,
        lang,
    });

    const removeItem = (key, index) => {
        const weightLines = rwElimination[key];
        storeActions.updateRWElimination({
            [key]: [...weightLines.slice(0, index), ...weightLines.slice(index + 1)],
        });
    };

    const fields = getTCFields({ rwElimination, rwEquipmentList, removeItem, storeActions }).map((e) => ({
        ...e,
        key: e.code,
        header: getTranslation(e.translationKey ?? e.code),
    }));

    const { tableHeaders, tableCodes, tableData } = generateTCTableData({
        fields,
        rwElimination,
        storeActions,
        formFields,
        labelStyle,
        writeAccess,
    });

    const activity = rwElimRequest.stepActivityMap?.[stepStatus];
    const edmsProfile = activity?.edmsProfile;
    const edmsContext = activity?.edmsContext;

    return (
        <div style={{ margin: 8 }}>
            <Grid container spacing={8}>
                <Grid item md={12} sm={12} xs={12}>
                    {edmsProfile && !edmsContext && (
                        <ErrorText text={`${getTranslation(TRANSLATION_KEYS.EDMS_CONTEXT_UNKNOWN)} (${edmsProfile})`} />
                    )}
                    {edmsProfile && edmsContext && (
                        <EDMSDoclightIframeContainer
                            title={`Documents ${edmsContext}`}
                            objectID={rwElimRequest[RW_ELIMINATION_KEYS.CODE]}
                            objectType="J"
                            profile={activity.edmsProfile}
                        />
                    )}

                    <EISPanel heading="RW Elimination Job Details" alwaysExpanded>
                        <Grid container spacing={0}>
                            {usageFields.map(inputGenerator)}
                            {detailsFields.map(inputGenerator)}
                            <EISTable
                                data={tableData}
                                headers={tableHeaders}
                                propCodes={tableCodes}
                                maxMobileSize={600}
                                style={{ overflowY: 'visible' }}
                            />
                        </Grid>
                    </EISPanel>
                </Grid>
            </Grid>
        </div>
    );
};

class RWEliminationControlStep extends WizardStep {
    formFields = {};

    state = {};

    componentDidMount() {
        super.componentDidMount();
    }

    handleSubmit = async () => {
        const { stepKey, stepStatus, rwElimRequest, getTranslation } = this.props;
        const activity = rwElimRequest.stepActivityMap?.[stepStatus];
        if (activity?.edmsProfile) {
            try {
                await validateEDMSContext(
                    activity?.edmsContext,
                    activity?.edmsNumberDocuments,
                    rwElimRequest[RW_ELIMINATION_KEYS.CODE],
                    getTranslation
                );
            } catch (error) {
                showError(error.message);
                return;
            }
        }
        this.canContinue() &&
            this.props.handleUpdate({
                translationKey: `${stepKey}_SUBMIT_MSG`,
                updateObject: {
                    [RW_ELIMINATION_KEYS.STATUS]: stepStatus,
                },
            });
    };

    handleSave = () => {
        this.canContinue();
    };

    /**
     * Can continue to the next step on the wizard
     */
    canContinue = () => {
        const { writeAccess } = this.props;
        if (!writeAccess) {
            return true;
        }
        const isValid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
        if (!isValid) {
            showError('One or more fields are not valid.');
        }

        return isValid;
    };

    /**
     * SaveChanges is used to save the localized state to the redux-store. It can only run synchronous methods.
     */
    saveChanges = () => true;

    /**
     *  CommitChanges is used to trigger any side effects of the step, such as invocations to the back-end to
     * retrieve information about resources. This method can run asynchronous methods/requests but SHALL execute
     * the callback method afterwards to inform the wizard.
     */
    commitChanges = async (callback) => {
        callback();
    };

    /**
     * On true, the wizard automatically jumps this step immediately after it is mounted
     */
    canJumpStep = () => false;

    render() {
        return <RWEliminationControl formFields={this.formFields} {...this.props} />;
    }
}

export default RWEliminationControlStep;
