import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import EISTable from 'react-eis-components/dist/ui/components/table';
import DialogActions from '@material-ui/core/DialogActions';
import { TREC_GENERAL_KEYS, RPAR_REQUEST_KEYS, TABLE_DATA_TYPES } from '../../../../enums/Constants';
import WSRPSampling from '../../../../tools/rest/WSRPSampling';
import InputGenerator from '../../rpmrequest2/InputGenerator';
import { showWarning } from 'tools/TrecNotifications';

const { ATTRIBUTES } = InputGenerator;

const getFields = () => [
    {
        code: RPAR_REQUEST_KEYS.REPLACE_EQUIPMENT_SOURCE,
        type: TABLE_DATA_TYPES.STATIC,
        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
        renderValue: (object, field) => (
            <div style={{ minWidth: '150px', maxWidth: '200px' }}>{object[field.code]}</div>
        ),
    },
    {
        code: RPAR_REQUEST_KEYS.REPLACE_EQUIPMENT_TARGET,
        type: TABLE_DATA_TYPES.AUTOCOMPLETE,
        autocompleteType: async (value) => await WSRPSampling.getSamples(value),
        getAttribute: () => ATTRIBUTES.REQUIRED,
        customInputProps: () => ({
            style: {
                width: '300px',
            },
        }),
    },
];

class ReplaceEquipmentDialog extends React.Component {
    state = {};

    formFields = [];

    static getDerivedStateFromProps(props, state) {
        const { equipmentList } = props;
        return {
            replaceMap: equipmentList.reduce((acc, code) => ({ ...acc, [code]: code }), {}),
            ...state,
        };
    }

    validate = () => {
        const { replaceMap } = this.state;
        const allValuesAreValid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
        const allValues = Object.values(replaceMap);
        const allDifferentValues = [...new Set(allValues)];
        const hasRepeatedEquipment = allValues.length !== allDifferentValues.length;
        if (hasRepeatedEquipment) {
            showWarning(RPAR_REQUEST_KEYS.REPEATED_EQUIPMENT_MESSAGE);
        }
        return allValuesAreValid && !hasRepeatedEquipment;
    };

    getObjects = () => {
        const { replaceMap } = this.state;
        return Object.entries(replaceMap).map(([source, target]) => ({
            [RPAR_REQUEST_KEYS.REPLACE_EQUIPMENT_SOURCE]: source,
            [RPAR_REQUEST_KEYS.REPLACE_EQUIPMENT_TARGET]: target,
        }));
    };

    getTableData = ({ fields, objects }) =>
        objects.map((object) =>
            fields.reduce(
                (acc, field) => ({
                    ...acc,
                    [field.code]: (
                        <div key={field.code}>
                            {InputGenerator.generate({
                                field: {
                                    ...field,
                                    // elementInfo.xpath needed for formfields validation
                                    elementInfo: {
                                        xpath: `${field.code}${object[RPAR_REQUEST_KEYS.REPLACE_EQUIPMENT_SOURCE]}`,
                                    },
                                },
                                object,
                                updateObject: (key, value) =>
                                    this.updateReplaceMap({
                                        source: object[RPAR_REQUEST_KEYS.REPLACE_EQUIPMENT_SOURCE],
                                        target: value,
                                    }),
                                formFields: this.formFields,
                            })}
                        </div>
                    ),
                }),
                {}
            )
        );

    updateReplaceMap = ({ source, target }) => {
        this.setState((prevState) => ({ replaceMap: { ...prevState.replaceMap, [source]: target } }));
    };

    render() {
        const { open, onCancel, onConfirm, getTranslation } = this.props;
        const { replaceMap } = this.state;
        const fields = getFields();
        const objects = this.getObjects();

        return (
            <Dialog open={open} scroll="body">
                <DialogTitle>{getTranslation(RPAR_REQUEST_KEYS.REPLACE_EQUIPMENT_TITLE)}</DialogTitle>
                <DialogContent>
                    <EISTable
                        data={this.getTableData({ fields, objects })}
                        headers={fields.map((field) => getTranslation(field.code))}
                        propCodes={fields.map((field) => field.code)}
                        maxMobileSize={600}
                    />
                    <div style={{ height: '150px' }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>{getTranslation(TREC_GENERAL_KEYS.CANCEL)}</Button>
                    <Button onClick={() => this.validate() && onConfirm(replaceMap)} color="primary" autoFocus>
                        {getTranslation(TREC_GENERAL_KEYS.CONFIRM)}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ReplaceEquipmentDialog;
