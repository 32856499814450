import React from 'react';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import Textarea from 'react-autosize-textarea';
import EAMFormLabel from 'eam-components/dist/ui/components/inputs/EAMFormLabel';
import 'eam-components/dist/ui/components/comments/Comments.css';
import WSAutocomplete from '../../../../../tools/rest/WSAutocomplete';
import { getActionDropdownValues } from '../BZInventoryUtils';

export const GLOBAL_ACTION = 'globalAction';
export const GLOBAL_LOCATION = 'globalLocation';

const BZInventoryGlobalActions = (props) => {
    const actionsContainer = {
        display: 'flex',
        flexWrap: 'wrap',
    };

    const columnStyle = {
        maxWidth: '750px',
        width: '100%',
        flexDirection: 'column',
        padding: '20px',
    };

    const commentStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
    };

    const { approved, globalAction, comment, globalLocation, translations, updateProperty } = props;

    return (
        <div style={actionsContainer}>
            <div style={columnStyle}>
                <EAMSelect
                    elementInfo={{
                        text: translations.MODIFY_ALL_ACTIONS,
                        readonly: approved,
                    }}
                    values={getActionDropdownValues(translations)}
                    value={globalAction}
                    updateProperty={updateProperty}
                    valueKey={GLOBAL_ACTION}
                />
                <div style={commentStyle}>
                    <EAMFormLabel label={translations.COMMENT} />
                    <Textarea
                        placeholder={translations.COMMENT_HERE}
                        className="commentText"
                        onInput={(event) => updateProperty('comment', event.target.value)}
                        value={comment}
                        disabled={approved}
                    />
                </div>
            </div>
            <div style={columnStyle}>
                <EAMAutocomplete
                    elementInfo={{
                        text: translations.MODIFY_ALL_LOCATIONS,
                        readonly: approved,
                    }}
                    value={globalLocation}
                    valueKey={GLOBAL_LOCATION}
                    updateProperty={updateProperty}
                    loadOptions={WSAutocomplete.autocompleteTRECLocation}
                    labelStyle={{ minWidth: 150 }}
                    columnsCodes={['code', 'desc', 'extraAttr1']}
                    columnsWidth={['30%', '30%', '40%']}
                />
            </div>
        </div>
    );
};

export default BZInventoryGlobalActions;
