import React, { Component } from 'react';
import { iframeResizer } from 'iframe-resizer';

class ResizableIFrame extends Component {
    componentWillUnmount() {
        const mountedIframe = this.frameRef.iFrameResizer;
        if (!mountedIframe) return;
        mountedIframe.removeListeners();
    }

    resize = () => {
        const { id, iframeResizerOptions, onLoad } = this.props;
        if (!this.frameRef) return;
        iframeResizer(iframeResizerOptions, this.frameRef);
        onLoad && setTimeout(() => onLoad(id), 1000);
    };

    render() {
        const { src, id, className, style, title } = this.props;
        return (
            <iframe
                ref={(el) => {
                    this.frameRef = el;
                }}
                title={title}
                src={src}
                id={id}
                className={className}
                style={style}
                onLoad={this.resize}
            />
        );
    }
}

ResizableIFrame.defaultProps = {
    iframeResizerOptions: {},
};

export default ResizableIFrame;
