import WS from './WS';

/**
 * Rest services for the Request of RP Measurement
 */
class WSRPMRequest {
    initRPMRequest = (action, language, config = {}) =>
        WS._get(`/rpmrequest/initrpmrequest?action=${action}&lang=${language}`, config);

    readLastMeasurement = (equipment, language, config = {}) => {
        const equipmentEncoded = encodeURIComponent(equipment);
        return WS._get(`/rpmrequest/readlastrpmperformed?equipment=${equipmentEncoded}&lang=${language}`, config);
    };

    createRPMRequest = (request, config = {}) => WS._post(`/rpmrequest/createrpm`, request, config);

    updateEquipmentLocation = (request, language, config = {}) =>
        WS._post(`/rpmrequest/updatelocation`, request, config);

    readSelectedEquipmentData = (code, config = {}) => {
        const codeEncoded = encodeURIComponent(code);
        return WS._get(`/autocomplete/equipmentall/selected?equipment=${codeEncoded}`, config);
    };

    readLastMeasurementEquipments = (equipments, language, config = {}) => {
        const queryParams = equipments.map((eqp) => `eqp=${eqp}`).join('&');
        return WS._get(`/rpmrequest/readlastrpmperformedeqp/?${queryParams}&lang=${language}`, config);
    };
}

export default new WSRPMRequest();
