import { IconButton, Typography } from '@material-ui/core';
import { RemoveRedEye } from '@material-ui/icons';
import { AMMSingleAutocomplete } from 'amm-tools';
import EAMLookup from 'eam-components/dist/ui/components/lookup/EAMLookup';
import { TABLE_DATA_TYPES } from 'enums/Constants';
import { TrashCanOutline } from 'mdi-material-ui';
import { Link } from 'react-router-dom';
import { ROUTES, withQueryParams } from 'tools/Routes';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import WSVacRental from 'tools/rest/WSVacRental';
import TRECButton from 'ui/components/buttons/TRECButton';
import {
    VACUUM_RENTAL_ACCESSORY_KEYS,
    VACUUM_RENTAL_KEYS,
    VACUUM_RENTAL_LINE_KEYS,
    VACUUM_RENTAL_LINE_STATUS,
    VACUUM_TYPE_KEYS,
} from 'ui/pages/vaccleanerrequest/VacCleanerConstants';
import { ATTRIBUTES } from '../rpmrequest2/InputGenerator';
import { TRANSLATION_KEYS } from '../rwprocessing/RWPConstants';
import { getCellRenderer } from '../vaccleanerstock/VacuumCleanerStock';
import { ENTITY_TRANSL_PREFIX } from '../wrrequest/WRRConstants';
import { TranslatedDiv } from '../wrsearch/WaterReleaseSearch';
import { handleError } from 'tools/TrecNotifications';

const VAC_TABLE_KEYS = {
    ACTION: 'ACTION',
    FETCH_LOCK_CODE: 'fetchLockCode',
    DURATION: 'rentalDuration',
    RETURNED_RPM_STATUS: 'returnRpmStatus',
    GENERATE_EDH: 'GENERATE_EDH',
    LOOKUP_ASSET_CODE: ' ',
    COMPLETE_LINE: 'COMPLETE_LINE',
    VAC_DESC: 'VAC_DESC',
    DELETE_LINE: 'DELETE_LINE',
    RPM_MAX_DOSE_RATE: 'RPM_MAX_DOSE_RATE',
};

const SECONDS_IN_DAY = 86400000;

const getVacCleanerLinesFieldsObj = ({
    storeActions,
    vacCleanerRequestNumber,
    vacCleanerRentalLines,
    labelStyle,
    getTranslation,
    editAsset,
    returnVacuum,
    setDeclareUseLine,
    readLockCode,
    setLockCodes,
    lockCodes,
    setReturnLine,
    editDates,
    vacuumRequest,
    applicationData,
    completeLine,
    vacuumsData,
    adminMode,
    valuesGrid,
}) => ({
    [VACUUM_RENTAL_LINE_KEYS.STATUS]: {
        type: TABLE_DATA_TYPES.STATIC,
        translationKey: TRANSLATION_KEYS.STATUS_CODE,
        customValue: (obj, field) => (
            <TranslatedDiv
                getTranslation={getTranslation}
                code={ENTITY_TRANSL_PREFIX.RESERVATION_STATUS + obj[field.code]}
            />
        ),
        getAttribute: (obj, field) => (obj[field.code] ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN),
    },
    [VACUUM_RENTAL_LINE_KEYS.ASSET_CODE]: {
        type: TABLE_DATA_TYPES.STATIC,

        customValue: (vacRentalLine) =>
            vacRentalLine[VACUUM_RENTAL_LINE_KEYS.CODE] && editAsset ? (
                // <EAMBarcodeInput
                //     updateProperty={(val) =>
                //         storeActions.assignVacuumReservation(val, vacRentalLine[VACUUM_RENTAL_LINE_KEYS.CODE])
                //     }
                //     right={4}
                //     top={3}
                // >
                <div style={{ minWidth: '234px' }}>
                    <AMMSingleAutocomplete
                        elementInfo={{
                            readonly:
                                vacRentalLine[VACUUM_RENTAL_LINE_KEYS.STATUS] !== VACUUM_RENTAL_LINE_STATUS.RESERVED,
                        }}
                        loadOptions={async ({ hint }, conf) => {
                            const opts = await WSAutocomplete.autocompleteVacuums(
                                {
                                    code: vacCleanerRequestNumber,
                                    reservationCode: vacRentalLine[VACUUM_RENTAL_LINE_KEYS.CODE],
                                    hint: hint?.toUpperCase(),
                                },
                                conf
                            );
                            const alreadyUsed = vacCleanerRentalLines
                                .filter((vacRentalLin) =>
                                    ['I', 'U'].includes(vacRentalLin[VACUUM_RENTAL_LINE_KEYS.STATUS])
                                )
                                .map((vacRentalLin) => vacRentalLin[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE]);

                            return opts.filter((opt) => !alreadyUsed.includes(opt.code));
                        }}
                        value={{
                            code: vacRentalLine[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE],
                            description: '',
                        }}
                        getOptionLabel={(opt) => (opt.code ? `${opt.code} ${opt.description}` : '')}
                        getOptionValue={(opt) => opt.code}
                        placeholder={getTranslation('CHOOSE_ELIGIBLE_VACUUUMS')}
                        onChange={(opt) =>
                            storeActions.assignVacuumReservation(opt?.code, vacRentalLine[VACUUM_RENTAL_LINE_KEYS.CODE])
                        }
                        style={labelStyle}
                        // isClearable={false}
                    />
                </div>
            ) : (
                vacRentalLine[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE]
            ),
    },
    [VAC_TABLE_KEYS.LOOKUP_ASSET_CODE]: {
        type: TABLE_DATA_TYPES.STATIC,
        customValue: (vacRentalLine) => (
            <div style={{ maxWidth: '40px' }}>
                <EAMLookup
                    right={3}
                    top={-14}
                    width={32}
                    height={32}
                    gridId={applicationData.vacCleanersStockGridID}
                    keys={{
                        code: 'obj_code',
                        mapCodeTo: vacRentalLine[VACUUM_RENTAL_LINE_KEYS.CODE],
                    }}
                    updateProperty={(k, val) =>
                        storeActions.assignVacuumReservation(val, vacRentalLine[VACUUM_RENTAL_LINE_KEYS.CODE])
                    }
                    value={vacRentalLine[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE]}
                    // TODO, review disabled property
                    disabled={vacRentalLine[VACUUM_RENTAL_LINE_KEYS.STATUS] !== VACUUM_RENTAL_LINE_STATUS.RESERVED}
                    gridRequestAdapter={(gridRequest) => {
                        const gridFilters = [...gridRequest.gridFilter];
                        gridFilters.push({
                            fieldName: 'par_tool',
                            fieldValue: vacRentalLine[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE],
                            operator: 'EQUALS',
                            joiner: 'AND',
                            rightParenthesis: false,
                        });
                        return {
                            ...gridRequest,
                            gridFilter: gridFilters,
                        };
                    }}
                    cellRenderer={getCellRenderer({ getTranslation, valuesGrid })}
                >
                    {null}
                </EAMLookup>
            </div>
        ),
    },
    [VACUUM_RENTAL_LINE_KEYS.VAC_TYPE]: {
        type: TABLE_DATA_TYPES.STATIC,
    },
    [VACUUM_RENTAL_LINE_KEYS.LOCATION]: {
        type: TABLE_DATA_TYPES.STATIC,
        customValue: (obj, field) => obj[field.code]?.code,
    },
    [VACUUM_RENTAL_LINE_KEYS.START_DATE]: {
        type: TABLE_DATA_TYPES.DATE_TIME,
        customInputProps: ({ object }) => {
            const vc = vacuumsData?.find((s) => s[VACUUM_TYPE_KEYS.CODE] === object[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE]);

            // Compute minimum delivery time
            const a = vacuumRequest?.[VACUUM_RENTAL_KEYS.CREATED_ON]
                ? new Date(vacuumRequest?.[VACUUM_RENTAL_KEYS.CREATED_ON])
                : new Date();
            a.setHours(a.getHours() + (vc?.[VACUUM_TYPE_KEYS.MINIMUM_DELIVERY_TIME_HOURS] ?? 0));

            return {
                style: { maxWidth: '150px', margin: '0 auto' },
                timestamp: true,
                minDate: adminMode ? undefined : a,
                minTime: adminMode ? undefined : a,
                maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + (adminMode ? 6 : 1))),
            };
        },
        validate:
            editDates && vacuumsData && !adminMode
                ? (vacRentalLine) => (value) => {
                      // Compute minimum delivery time
                      const createdOn = vacuumRequest?.[VACUUM_RENTAL_KEYS.CREATED_ON]
                          ? new Date(vacuumRequest?.[VACUUM_RENTAL_KEYS.CREATED_ON])
                          : new Date();

                      const vc = vacuumsData.find(
                          (s) => s[VACUUM_TYPE_KEYS.CODE] === vacRentalLine[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE]
                      );
                      createdOn.setHours(
                          createdOn.getHours() + (vc?.[VACUUM_TYPE_KEYS.MINIMUM_DELIVERY_TIME_HOURS] ?? 0)
                      );
                      return value >= createdOn.getTime();
                  }
                : undefined,
        getAttribute: () => (editDates ? ATTRIBUTES.REQUIRED : ATTRIBUTES.READONLY),
    },
    [VACUUM_RENTAL_LINE_KEYS.END_DATE]: {
        type: TABLE_DATA_TYPES.DATE_TIME,
        customInputProps: ({ object }) => ({
            style: { maxWidth: '150px', margin: '0 auto' },
            timestamp: true,
            minDate: new Date(
                object?.[VACUUM_RENTAL_LINE_KEYS.START_DATE] || vacuumRequest?.[VACUUM_RENTAL_KEYS.CREATED_ON]
            ),
            maxDate: adminMode
                ? new Date(new Date().setFullYear(new Date().getFullYear() + 6))
                : new Date(object[VACUUM_RENTAL_LINE_KEYS.START_DATE]).setHours(
                      new Date(object[VACUUM_RENTAL_LINE_KEYS.START_DATE]).getHours() + 24 * 365
                  ),
        }),
        getAttribute: () => (editDates ? ATTRIBUTES.REQUIRED : ATTRIBUTES.READONLY),
        validate:
            editDates && vacuumsData
                ? (vacRentalLine) => (value) =>
                      vacRentalLine[VACUUM_RENTAL_LINE_KEYS.START_DATE] &&
                      value >= vacRentalLine[VACUUM_RENTAL_LINE_KEYS.START_DATE] &&
                      (adminMode ||
                          value <=
                              new Date(vacRentalLine[VACUUM_RENTAL_LINE_KEYS.START_DATE]).setHours(
                                  new Date(vacRentalLine[VACUUM_RENTAL_LINE_KEYS.START_DATE]).getHours() + 24 * 365
                              ))
                : undefined,
    },
    [VAC_TABLE_KEYS.DURATION]: {
        type: TABLE_DATA_TYPES.INPUT,
        customInputProps: ({ object }) => ({
            type: 'number',
            updateProperty: (key, value) => {
                if (value > 0) {
                    storeActions.updateVacRentalLine(object[VACUUM_RENTAL_LINE_KEYS.CODE], {
                        [VACUUM_RENTAL_LINE_KEYS.END_DATE]:
                            object[VACUUM_RENTAL_LINE_KEYS.START_DATE] + value * 86400000,
                    });
                } else {
                    storeActions.updateVacRentalLine(object[VACUUM_RENTAL_LINE_KEYS.CODE], {
                        [VACUUM_RENTAL_LINE_KEYS.END_DATE]: null,
                    });
                }
            },
            rightAddon: getTranslation('DAYS'),
            style: { maxWidth: '50px', minWidth: '50px', marginLeft: '25%' },
        }),
        getAttribute: () => (editDates ? ATTRIBUTES.OPTIONAL : ATTRIBUTES.READONLY),
        customValue: (vacRentalLine) =>
            vacRentalLine[VACUUM_RENTAL_LINE_KEYS.END_DATE]
                ? Math.round(
                      (vacRentalLine[VACUUM_RENTAL_LINE_KEYS.END_DATE] -
                          vacRentalLine[VACUUM_RENTAL_LINE_KEYS.START_DATE]) /
                          86400000
                  )
                : '',
    },
    [VACUUM_RENTAL_LINE_KEYS.ISSUED_DATE]: {
        type: TABLE_DATA_TYPES.DATE_TIME,
        customInputProps: () => ({
            style: { maxWidth: '150px' },
            timestamp: true,
        }),
        getAttribute: () => (adminMode ? ATTRIBUTES.OPTIONAL : ATTRIBUTES.READONLY),
    },
    [VACUUM_RENTAL_LINE_KEYS.RETURNED_DATE]: {
        type: TABLE_DATA_TYPES.DATE_TIME,
        customInputProps: () => ({
            style: { maxWidth: '150px' },
            timestamp: true,
        }),
        getAttribute: () => (adminMode ? ATTRIBUTES.OPTIONAL : ATTRIBUTES.READONLY),
    },
    [VAC_TABLE_KEYS.ACTION]: {
        type: TABLE_DATA_TYPES.STATIC,
        customValue: (vacRentalLine) => (
            <div style={{ minWidth: '200px', textAlign: 'center' }}>
                {!returnVacuum || !vacRentalLine[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE] ? null : vacRentalLine[ // status === VACUUM_RENTAL_STATUS.READY_FOR_DELIVERY &&
                      VACUUM_RENTAL_LINE_KEYS.STATUS
                  ] === VACUUM_RENTAL_LINE_STATUS.RESERVED ? (
                    <TRECButton
                        style={{ height: '100%' }}
                        onClick={() => setDeclareUseLine(vacRentalLine)}
                        size="small"
                    >
                        {getTranslation('DECLAREUSE')}
                    </TRECButton>
                ) : vacRentalLine[VACUUM_RENTAL_LINE_KEYS.STATUS] === VACUUM_RENTAL_LINE_STATUS.ISSUED ? (
                    <TRECButton style={{ height: '100%' }} onClick={() => setReturnLine(vacRentalLine)} size="small">
                        {getTranslation('RETURNVACCLEANER')}
                    </TRECButton>
                ) : null}
            </div>
        ),
    },
    [VAC_TABLE_KEYS.FETCH_LOCK_CODE]: {
        type: TABLE_DATA_TYPES.STATIC,
        customValue: (vacRentalLine) =>
            !readLockCode || vacRentalLine?.[VACUUM_RENTAL_LINE_KEYS.STATUS] !== 'I'
                ? null
                : lockCodes[vacRentalLine?.[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE]] ?? (
                      <TRECButton
                          size="small"
                          onClick={readLockCode(vacRentalLine?.[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE], (data2) => {
                              setLockCodes((prevState) => ({
                                  ...prevState,
                                  [vacRentalLine?.[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE]]: data2,
                              }));
                          })}
                      >
                          <RemoveRedEye />
                      </TRECButton>
                  ),
    },
    [VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES]: {
        type: TABLE_DATA_TYPES.STATIC,
        customValue: (vacRentalLine, _) =>
            vacRentalLine.vacCleanerRentalAccessories.map((acc) => (
                <div key={`${acc.reservation}${acc[VACUUM_RENTAL_ACCESSORY_KEYS.PART_CODE]}`}>
                    <Typography color="textSecondary" style={{ fontSize: '9pt' }}>
                        {`${acc[VACUUM_RENTAL_ACCESSORY_KEYS.PART_CODE]} (${
                            acc[VACUUM_RENTAL_ACCESSORY_KEYS.QUANTITY]
                        })`}
                    </Typography>
                </div>
            )),
    },
    [VACUUM_RENTAL_LINE_KEYS.REPLACEMENT_REASON]: {
        type: TABLE_DATA_TYPES.STATIC,
        customValue: (obj, field) => (
            <TranslatedDiv
                getTranslation={getTranslation}
                code={`${VACUUM_RENTAL_LINE_KEYS.REPLACEMENT_REASON}.${obj[field.code]}`}
            />
        ),
        getAttribute: (obj) =>
            obj[VACUUM_RENTAL_LINE_KEYS.REPLACEMENT_REASON] ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN,
    },
    [VACUUM_RENTAL_LINE_KEYS.RETURN_RPM_CODE]: {
        type: TABLE_DATA_TYPES.STATIC,
        customValue: (vacRentalLine, _) => (
            <Link
                target="_blank"
                to={withQueryParams({
                    path: ROUTES.rpmPerform,
                    queryParams: { wo: vacRentalLine[VACUUM_RENTAL_LINE_KEYS.RETURN_RPM_CODE] },
                })}
            >
                {vacRentalLine[VACUUM_RENTAL_LINE_KEYS.RETURN_RPM_CODE]}
            </Link>
        ),
    },
    [VACUUM_RENTAL_LINE_KEYS.RETURN_RPM_CHECKLIST_ANSWERS]: {
        type: TABLE_DATA_TYPES.STATIC,
        translationKey: TRANSLATION_KEYS.RETURN_RPM_CHECKLIST_ANSWERS,
        customValue: (vacRentalLine) => {
            const answers = vacRentalLine[VACUUM_RENTAL_LINE_KEYS.RETURN_RPM_CHECKLIST_ANSWERS]?.split(',');
            return answers?.some((ans) => !['YES', 'NO'].includes(ans)) ? (
                <div style={{ color: 'orange' }}>{getTranslation('INCOMPLETE')}</div>
            ) : answers?.every((ans) => ans === 'YES') ? (
                <div style={{ color: 'green' }}>{getTranslation('YES')}</div>
            ) : (
                <div style={{ color: 'orange' }}>
                    {vacRentalLine[VACUUM_RENTAL_LINE_KEYS.RETURN_RPM_CHECKLIST_ANSWERS]}
                </div>
            );
        },
    },
    [VAC_TABLE_KEYS.RETURNED_RPM_STATUS]: {
        type: TABLE_DATA_TYPES.STATIC,
        customValue: (vacRentalLine, _) => <span>{vacRentalLine[VACUUM_RENTAL_LINE_KEYS.RETURN_RPM]?.statusCode}</span>,
    },
    [VAC_TABLE_KEYS.GENERATE_EDH]: {
        type: TABLE_DATA_TYPES.STATIC,
        customValue: (vacRentalLine) =>
            vacRentalLine[VACUUM_RENTAL_LINE_KEYS.RETURN_RPM]?.statusCode !== 'T'
                ? null
                : vacRentalLine[VACUUM_RENTAL_LINE_KEYS.RETURN_RPM_CODE] &&
                  (vacRentalLine[VACUUM_RENTAL_LINE_KEYS.RETURN_RPM]?.edhDocId ? (
                      vacRentalLine[VACUUM_RENTAL_LINE_KEYS.RETURN_RPM]?.edhDocId
                  ) : (
                      <TRECButton
                          onClick={async () => {
                              try {
                                  await WSVacRental.updateRpmEDHTransport({
                                      rpmCode: vacRentalLine[VACUUM_RENTAL_LINE_KEYS.RETURN_RPM_CODE],
                                  });
                                  window.open(
                                      withQueryParams({
                                          path: ROUTES.edhdocmng,
                                          queryParams: {
                                              job_number: vacRentalLine[VACUUM_RENTAL_LINE_KEYS.RETURN_RPM_CODE],
                                          },
                                      }),
                                      '_blank'
                                  );
                              } catch (err) {
                                  handleError(err);
                              }
                          }}
                          size="small"
                      >
                          SIT EDH
                      </TRECButton>
                  )),
    },
    [VACUUM_RENTAL_LINE_KEYS.TOTAL_COST]: {
        type: TABLE_DATA_TYPES.INPUT,
        getAttribute: (obj) =>
            [VACUUM_RENTAL_LINE_STATUS.RETURNED].includes(obj[VACUUM_RENTAL_LINE_KEYS.STATUS])
                ? ATTRIBUTES.OPTIONAL
                : ATTRIBUTES.HIDDEN,
    },
    [VACUUM_RENTAL_LINE_KEYS.NOTIFICATION_ENABLED]: {
        type: TABLE_DATA_TYPES.CHECKBOX,
        getAttribute: (obj) =>
            !obj[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE]
                ? ATTRIBUTES.HIDDEN
                : VACUUM_RENTAL_LINE_STATUS.RESERVED !== obj[VACUUM_RENTAL_LINE_KEYS.STATUS]
                ? ATTRIBUTES.READONLY
                : ATTRIBUTES.OPTIONAL,
    },
    [VAC_TABLE_KEYS.COMPLETE_LINE]: {
        type: TABLE_DATA_TYPES.STATIC,
        customValue: (vacRentalLine) =>
            !['T', 'TX'].includes(vacRentalLine[VACUUM_RENTAL_LINE_KEYS.RETURN_RPM]?.statusCode) ? null : (
                <TRECButton
                    style={{ height: '100%' }}
                    onClick={completeLine({ reservationCode: vacRentalLine[VACUUM_RENTAL_LINE_KEYS.CODE] })}
                    size="small"
                >
                    {getTranslation(VAC_TABLE_KEYS.COMPLETE_LINE)}
                </TRECButton>
            ),
        getAttribute: (vacRentalLine) =>
            vacRentalLine[VACUUM_RENTAL_LINE_KEYS.STATUS] === 'R' ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN,
    },
    [VAC_TABLE_KEYS.VAC_DESC]: {
        type: TABLE_DATA_TYPES.STATIC,
        customValue: (vacRentalLine) =>
            vacuumsData?.find((s) => s[VACUUM_TYPE_KEYS.CODE] === vacRentalLine[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE])?.[
                VACUUM_TYPE_KEYS.DESCRIPTION
            ],
    },
    [VAC_TABLE_KEYS.DELETE_LINE]: {
        type: TABLE_DATA_TYPES.STATIC,
        translationKey: 'DELETE',
        customValue: (object) => (
            <IconButton onClick={() => storeActions.removeVacuum(null, null, object[VACUUM_RENTAL_LINE_KEYS.CODE])}>
                <TrashCanOutline />
            </IconButton>
        ),
    },
    [VAC_TABLE_KEYS.RPM_MAX_DOSE_RATE]: {
        type: TABLE_DATA_TYPES.STATIC,
        translationKey: 'RPM_MAX_DOSE_RATE',
        customValue: (vacRentalLine, _) => (
            <span>
                {Object.values(vacRentalLine[VACUUM_RENTAL_LINE_KEYS.RETURN_RPM]?.measurementLines || {})
                    ?.filter((s) => ['1', '2', '3', '4'].includes(s.measId))
                    ?.filter((s) => !['BGO'].includes(s.device))
                    .reduce((acc, el) => (+el.netValue > acc ? +el.netValue : acc), null)}
            </span>
        ),
    },
});

const getVacCleanerLinesFields = (args, list) => {
    const obj = getVacCleanerLinesFieldsObj(args);
    return (list || Object.keys(obj)).map((field) => ({ ...obj[field], code: field }));
};

// eslint-disable-next-line import/prefer-default-export
export { getVacCleanerLinesFields, VAC_TABLE_KEYS, SECONDS_IN_DAY };
