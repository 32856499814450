export const UPDATE_LAYOUT = 'UPDATE_LAYOUT';
export const SET_PAGE = 'SET_PAGE';
export const TOGGLE_HELP = 'TOGGLE_HELP';

export function updateLayout(value) {
    // If language, set it in the local storage
    if (value && value.lang) {
        localStorage.setItem(`TREC_LANGUAGE`, value.lang);
    }
    return {
        type: UPDATE_LAYOUT,
        value,
    };
}

export function setPage(masterPage, page = null) {
    if (page) {
        return {
            type: SET_PAGE,
            masterPage,
            page,
        };
    }

    return {
        type: SET_PAGE,
        masterPage,
        page: masterPage,
    };
}

export function toggleHelp() {
    return {
        type: TOGGLE_HELP,
    };
}
