import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, withStyles } from '@material-ui/core';

const defaultPropGetter = () => ({});

const StickyHeaderTableCell = withStyles({
    root: {
        position: 'sticky',
        top: 0,
        background: 'white',
        boxShadow: '0px 1px 0px 0px #e0e0e0',
    },
})(TableCell);

const CustomTable = withStyles({
    root: {
        textAlign: 'left',
        position: 'relative',
    },
})(Table);

const EQPageTable = (props) => {
    const {
        tableInstance,
        getHeaderProps = defaultPropGetter,
        getRowProps = defaultPropGetter,
        getCellProps = defaultPropGetter,
        disableFilters,
    } = props;

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    return (
        <CustomTable {...getTableProps()} component="div">
            <TableHead component="div">
                {headerGroups.map((headerGroup) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()} component="div">
                        {headerGroup.headers.map((column) => {
                            const customHeaderProps = [getHeaderProps(column)].filter(Boolean);
                            return (
                                <StickyHeaderTableCell
                                    {...column.getHeaderProps(customHeaderProps)}
                                    variant="head"
                                    component="div"
                                >
                                    <div
                                        {...(column.getSortByToggleProps
                                            ? column.getSortByToggleProps({ style: { display: 'inline-flex' } })
                                            : {})}
                                    >
                                        <span>{column.render('Header')}</span>
                                        {column.getSortByToggleProps && (
                                            <TableSortLabel
                                                component="span"
                                                active={column.isSorted}
                                                direction={column.isSortedDesc ? 'desc' : 'asc'}
                                            />
                                        )}
                                    </div>
                                    <div>{!disableFilters && column.canFilter ? column.render('Filter') : null}</div>
                                </StickyHeaderTableCell>
                            );
                        })}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody {...getTableBodyProps()} component="div">
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <TableRow {...row.getRowProps(getRowProps(row))} component="div">
                            {row.cells.map((cell) => (
                                <TableCell {...cell.getCellProps([getCellProps(cell)])} variant="body" component="div">
                                    {cell.render('Cell')}
                                </TableCell>
                            ))}
                        </TableRow>
                    );
                })}
            </TableBody>
        </CustomTable>
    );
};

export default EQPageTable;
