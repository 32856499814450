import { connect } from 'react-redux';

import RWInconsistencyChecks from './RWInconsistencyChecks';

const mapStateToProps = (state) => {
    const { applicationData, screenData, bufferZone, constants, menuType, rwpDropdowns } = state.application;

    const { lang } = state.layout;

    return {
        applicationData,
        screenData,
        lang,
        translations: {
            ...screenData[lang].translations.TREC_MENU,
            ...screenData[lang].translations.TREC_RWINCONSCHECKS,
        },
        masterPage: 'RWCHECKS',
        page: 'RWINCONSCHECKS',
        menu: screenData[lang].menu[menuType],
        bufferZone,
        constants,
        rwpDropdowns,
    };
};

export default connect(mapStateToProps, {})(RWInconsistencyChecks);
