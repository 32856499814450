import { Button, Card, CardActions, Typography } from '@material-ui/core';
import { AMMSingleAutocomplete } from 'amm-tools';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import {
    VACUUM_RENTAL_KEYS,
    VACUUM_RENTAL_LINE_KEYS,
    VACUUM_TYPE_KEYS,
} from 'ui/pages/vaccleanerrequest/VacCleanerConstants';

const styles = {
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
    actions: {
        flexDirection: 'column',
        position: 'absolute',
        bottom: 10,
        left: 0,
        right: 0,
    },
    panelStyle: {
        padding: 5,
        overflowY: 'auto',
        height: '60vh',
        scrollbarWidth: 'thin',
    },
    detailsStyle: {
        display: 'flex',
        flexDirection: 'column',
        padding: 6,
    },
};

const VacuumDetails = ({
    onClose,
    onAdd,
    focusedObject,
    menuPortalTarget,
    vacuumRequest,
    getTranslation,
    vacuumLocation,
    setVacuumLocation,
}) => {
    // const defaultOptions = vacuumRequest[VACUUM_RENTAL_KEYS.VACUUMS]
    //     .filter((vac) => focusedObject[VACUUM_TYPE_KEYS.CODE] !== vac[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE])
    //     .filter((vac) => vac[VACUUM_RENTAL_LINE_KEYS.LOCATION])
    //     .flatMap((vac) => vac[VACUUM_RENTAL_LINE_KEYS.LOCATION])
    //     .filter((vac, index, arr) => arr.findIndex((vac2) => vac2.code === vac.code) === index);

    const defaultOptions = Object.values(
        vacuumRequest[VACUUM_RENTAL_KEYS.VACUUMS].reduce((acc, el) => {
            acc[el[VACUUM_RENTAL_LINE_KEYS.LOCATION]?.code] =
                acc[el[VACUUM_RENTAL_LINE_KEYS.LOCATION]?.code] === null ||
                focusedObject[VACUUM_TYPE_KEYS.CODE] === el[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE]
                    ? null
                    : el[VACUUM_RENTAL_LINE_KEYS.LOCATION];
            return acc;
        }, {})
    ).filter((i) => i);

    return (
        <EISPanel
            detailsStyle={styles.detailsStyle}
            panelStyle={{ position: 'relative' }}
            heading={focusedObject[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE]}
            headingIcon={focusedObject.warning && 'fa-warning'}
            headingIconStyle={{ color: 'red' }}
            alwaysExpanded
        >
            <div style={styles.panelStyle}>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', height: '25vh' }}>
                    <img
                        src={focusedObject.imageUrl}
                        alt={focusedObject.description}
                        style={{ objectFit: 'contain', height: '100%', width: '100%' }}
                    />
                </div>

                <Card
                    style={{
                        padding: '10px 15px',
                        backgroundColor: '#f6f6f6',
                        overflowY: 'scroll',
                        maxHeight: '20vh',
                    }}
                >
                    <div style={styles.row}>
                        <Typography variant="subtitle1">{focusedObject.description}</Typography>
                    </div>
                    <div style={styles.row}>
                        <Typography variant="subtitle1">{getTranslation('VACTYPECODE')}</Typography>
                        <Typography>{focusedObject.partCode}</Typography>
                    </div>
                    <div style={styles.row}>
                        <Typography variant="subtitle1">{getTranslation('CAPACITY')}</Typography>
                        <Typography>{focusedObject.capacity}</Typography>
                    </div>
                    {/* <div style={styles.row}>
                        <Typography variant="subtitle1">TREC ID</Typography>
                        <Typography>{focusedObject.trecCode}</Typography>
                    </div> */}
                    <div style={styles.row}>
                        <Typography variant="subtitle1">{getTranslation('TYPICAL_USES')}</Typography>
                        <Typography style={{ maxWidth: '60%' }}>
                            {focusedObject[VACUUM_TYPE_KEYS.TYPICAL_USE]}
                        </Typography>
                    </div>
                    <div style={styles.row}>
                        <Typography variant="subtitle1">{getTranslation('CONDITION_OF_USE')}</Typography>
                        <Typography style={{ maxWidth: '60%' }}>
                            {focusedObject[VACUUM_TYPE_KEYS.CONDITION_OF_USE]}
                        </Typography>
                    </div>
                    <div style={styles.row}>
                        <Typography variant="subtitle1">{getTranslation('MINIMUM_DELIVERY_TIME')}</Typography>
                        <Typography style={{ maxWidth: '60%' }}>
                            {focusedObject[VACUUM_TYPE_KEYS.MINIMUM_DELIVERY_TIME_HOURS]} h
                        </Typography>
                    </div>
                    <div style={styles.row}>
                        <Typography variant="subtitle1">{getTranslation('TOTAL_WEIGHT')}</Typography>
                        <Typography style={{ maxWidth: '60%' }}>{focusedObject[VACUUM_TYPE_KEYS.WEIGHT]}</Typography>
                    </div>
                    <div style={styles.row}>
                        <Typography variant="subtitle1">{getTranslation('WEIGHT_IN_CHARGE')}</Typography>
                        <Typography style={{ maxWidth: '60%' }}>
                            {focusedObject[VACUUM_TYPE_KEYS.WEIGHT_IN_CHARGE]}
                        </Typography>
                    </div>
                    {focusedObject.warning && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                backgroundColor: '#f003',
                                border: '1px dashed #f00',
                                padding: 5,
                                margin: '5px 0',
                            }}
                        >
                            <Typography variant="subtitle2" style={{ color: '#b80000' }}>
                                {focusedObject.warning}
                            </Typography>
                        </div>
                    )}
                </Card>
            </div>
            <CardActions style={{ ...styles.actions, marginTop: '6px' }}>
                <div style={{ width: '100%', marginBottom: 5 }}>
                    <AMMSingleAutocomplete
                        defaultOptions={defaultOptions}
                        loadOptions={WSAutocomplete.newAutocompleteTRECLocation}
                        getOptionLabel={(opt) => `${opt.code} - ${opt.desc}`}
                        getOptionValue={(opt) => opt.code}
                        menuPortalTarget={menuPortalTarget?.current}
                        placeholder={getTranslation('USAGELOCATION')}
                        onChange={setVacuumLocation}
                        value={vacuumLocation}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Button onClick={onClose}>{getTranslation('CLOSE')}</Button>
                    <Button
                        disabled={!vacuumLocation}
                        onClick={() => {
                            onAdd(focusedObject, [vacuumLocation]);
                            setVacuumLocation(null);
                        }}
                    >
                        {getTranslation('ADD')}
                    </Button>
                </div>
            </CardActions>
        </EISPanel>
    );
};

export default VacuumDetails;
