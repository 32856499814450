import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Cancel from '@material-ui/icons/Cancel';
import Menu from '@material-ui/icons/Menu';
import Save from '@material-ui/icons/Save';
import { TREC_GENERAL_KEYS } from '../../../enums/Constants';
import TRECButton from '../../components/buttons/TRECButton';
import { STEPS, WATER_RELEASE_KEYS, WRR_STATUS } from './WRRConstants';

const WRRequestControls = (props) => {
    const {
        handleCancel,
        handlePrevious,
        handleNext,
        isFirstStep,
        isLastStep,
        currentStep,
        currentStepRef,
        getTranslation,
        isFinished,
        updateWRR,
        canUpdate,
        status,
        permissions,
        openConfirmDialog,
    } = props;

    return (
        <>
            <TRECButton onClick={handleCancel} color="default">
                <Menu />
                MENU
            </TRECButton>

            <TRECButton disabled={isFirstStep} onClick={handlePrevious}>
                <ArrowBack />
                {getTranslation(TREC_GENERAL_KEYS.BACK)}
            </TRECButton>

            {!isLastStep ? (
                <TRECButton onClick={handleNext}>
                    <ArrowForward />
                    {getTranslation(TREC_GENERAL_KEYS.NEXT)}
                </TRECButton>
            ) : (
                (canUpdate || permissions[currentStep]?.some((s) => s === 'W')) && (
                    <>
                        <TRECButton
                            color="default"
                            onClick={() =>
                                openConfirmDialog(
                                    {
                                        title: getTranslation('WRR_CANCEL_TITLE'),
                                        message: getTranslation('WRR_CANCEL_MESSAGE'),
                                        cancelButtonLabel: getTranslation('CANCEL'),
                                        confirmButtonLabel: getTranslation(getTranslation('CONFIRM')),
                                        waitForCompletion: true,
                                    },
                                    () => updateWRR({ [WATER_RELEASE_KEYS.STATUS]: WRR_STATUS.CANCELLED })
                                )
                            }
                        >
                            <Cancel />
                            {getTranslation(TREC_GENERAL_KEYS.CANCEL)}
                        </TRECButton>
                        {![STEPS.SAMPLING_STEP, STEPS.DECISION_STEP].includes(currentStep) && (
                            <TRECButton color="secondary" onClick={() => (currentStepRef?.handleSave || updateWRR)()}>
                                <Save />
                                {getTranslation(TREC_GENERAL_KEYS.SAVE)}
                            </TRECButton>
                        )}
                        {status !== WRR_STATUS.IN_PROGRESS && (
                            <TRECButton disabled={isFinished} onClick={currentStepRef?.handleSubmit}>
                                <Save />
                                {getTranslation(TREC_GENERAL_KEYS.SUBMIT)}
                            </TRECButton>
                        )}
                    </>
                )
            )}
        </>
    );
};

export default WRRequestControls;
