import WSEDMS from 'tools/rest/WSEDMS';
import { TRANSLATION_KEYS } from './RWEliminationConstants';

// eslint-disable-next-line import/prefer-default-export
export const validateEDMSContext = async (context, validNumberDocuments, jobId, getTranslation) => {
    try {
        if (validNumberDocuments === null || validNumberDocuments === 0) {
            return;
        }
        if (!context) {
            throw new Error(getTranslation(TRANSLATION_KEYS.EDMS_CONTEXT_UNKNOWN));
        }
        const { body } = await WSEDMS.getEDMSWorkorderRegistries(jobId);
        const documents = body.data;
        const numberDocumentsContext = documents?.filter((document) => document.context === context).length || 0;
        if (validNumberDocuments > numberDocumentsContext) {
            throw new Error(
                getTranslation(TRANSLATION_KEYS.EDMS_CONTEXT_INVALID)
                    .replace('{0}', context)
                    .replace('{1}', numberDocumentsContext)
                    .replace('{2}', validNumberDocuments)
            );
        }
    } catch (error) {
        throw new Error(`${getTranslation(TRANSLATION_KEYS.ERROR_RETRIEVING_DOCUMENTS)}: ${error.message}`);
    }
};
