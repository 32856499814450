import React, { Component } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import Grid from '@material-ui/core/Grid';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EISTable from 'react-eis-components/dist/ui/components/table';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import { showError } from 'tools/TrecNotifications';

class RPMRDetails extends Component {
    formFields = [];

    state = {
        editAll: false,
    };

    UNSAFE_componentWillMount() {
        // Set the page for the help
        const { setPage } = this.props;
        setPage('TREC_REQRPMEAS', 'STEP_DETAILS');
    }

    validate() {
        // Validations ok from the beginning
        let isValid = true;
        // Iterate over the required fields and validate them
        Object.keys(this.formFields).forEach((key) => {
            if (this.formFields[key] && this.formFields[key].validate && !this.formFields[key].validate()) {
                isValid = false;
            }
        });
        if (!isValid) {
            // Invalid data in details
            const { translations } = this.props;
            showError(translations.PHYCHARINV);
        }
        return isValid;
    }

    validateField = (value) => !Number.isNaN(value) && value > 0 && value < 999999999999;

    isValidForEDH = (value) => this.validateField(value) && !this.isSmallValue(value);

    renderDetails = () => {
        const { request } = this.props;
        const multipleEquipment = request.equipment.length > 1;
        return multipleEquipment ? this.renderDetailsMulti() : this.renderDetailsSingle();
    };

    isSmallValue = (val) => val < 0.001;

    _getADRFieldAttribute = () => {
        /**
         * The fields are required if there is transport request, or it the field transport required is yes
         * */
        const { request, constants } = this.props;
        if (
            request.edhDocument === constants.cfvEdhDocumentTransport ||
            request.edhDocument === constants.cfvEdhDocumentShipping ||
            request.edhDocument === constants.cfvEdhDocumentStockage ||
            request.transportRequired === constants.trueYes
        ) {
            return 'R';
        }
        // Is not required here
        return 'O';
    };

    updatePropertyMultiple = (key, value, index) => {
        const { request, updateEquipmentProperty } = this.props;
        // Size of list
        const { equipment } = request;
        // If edit all and index 0, set to all of the equipments
        const { editAll } = this.state;
        if (editAll && index === 0) {
            // Update all (if no read onlt)
            equipment.forEach((elem, innerIndex) => {
                updateEquipmentProperty(key, value, innerIndex);
            });
        } else {
            // Normal update
            updateEquipmentProperty(key, value, index);
        }
    };

    renderDetailsMulti = () => {
        const { assetFields, applicationData, lists, request, translations } = this.props;
        const { equipment } = request;
        const equipmentList = equipment.map((elem, index) => ({
            equipmentCode: elem.equipmentCode,
            length: (
                <EAMInput
                    elementInfo={{
                        ...assetFields.udfnum07,
                        text: '',
                        attribute: 'R',
                        xpath: `${assetFields.udfnum07.xpath}_${index}`,
                    }}
                    valueKey="length"
                    value={elem.length}
                    updateProperty={(key, value) => {
                        this.updatePropertyMultiple(key, value, index);
                    }}
                    formFields={this.formFields}
                    validate={this.validateField}
                />
            ),
            width: (
                <EAMInput
                    elementInfo={{
                        ...assetFields.udfnum08,
                        text: '',
                        attribute: 'R',
                        xpath: `${assetFields.udfnum08.xpath}_${index}`,
                    }}
                    valueKey="width"
                    value={elem.width}
                    updateProperty={(key, value) => {
                        this.updatePropertyMultiple(key, value, index);
                    }}
                    formFields={this.formFields}
                    validate={this.validateField}
                />
            ),
            height: (
                <EAMInput
                    elementInfo={{
                        ...assetFields.udfnum09,
                        text: '',
                        attribute: 'R',
                        xpath: `${assetFields.udfnum09.xpath}_${index}`,
                    }}
                    valueKey="height"
                    value={elem.height}
                    updateProperty={(key, value) => {
                        this.updatePropertyMultiple(key, value, index);
                    }}
                    formFields={this.formFields}
                    validate={this.validateField}
                />
            ),
            weight: (
                <EAMInput
                    elementInfo={{
                        ...assetFields.udfnum10,
                        text: '',
                        attribute: 'R',
                        xpath: `${assetFields.udfnum10.xpath}_${index}`,
                    }}
                    valueKey="weight"
                    value={elem.weight}
                    updateProperty={(key, value) => {
                        this.updatePropertyMultiple(key, value, index);
                    }}
                    formFields={this.formFields}
                    validate={(value) => this.isValidForEDH(value)}
                />
            ),
            equipmentValue: (
                <EAMInput
                    elementInfo={{
                        ...assetFields.equipmentvalue,
                        text: '',
                        attribute: 'R',
                        xpath: `${assetFields.equipmentvalue.xpath}_${index}`,
                    }}
                    valueKey="equipmentValue"
                    value={elem.equipmentValue}
                    updateProperty={(key, value) => {
                        this.updatePropertyMultiple(key, value, index);
                    }}
                    formFields={this.formFields}
                    rightAddon={applicationData.eqpValueCurrency}
                    validate={this.validateField}
                />
            ),
        }));

        const equipmentListADR = equipment.map((elem, index) => ({
            equipmentCode: elem.equipmentCode,
            family: (
                <EAMSelect
                    elementInfo={{
                        ...assetFields.udfchar21,
                        text: '',
                        attribute: this._getADRFieldAttribute(),
                        xpath: `${assetFields.udfchar21.xpath}_${index}`,
                    }}
                    valueKey="family"
                    value={elem.family}
                    values={lists.objFamily}
                    updateProperty={(key, value) => {
                        this.updatePropertyMultiple(key, value, index);
                    }}
                    formFields={this.formFields}
                />
            ),
            mainMaterial: (
                <EAMSelect
                    elementInfo={{
                        ...assetFields.udfchar22,
                        text: '',
                        attribute: this._getADRFieldAttribute(),
                        xpath: `${assetFields.udfchar22.xpath}_${index}`,
                    }}
                    valueKey="mainMaterial"
                    value={elem.mainMaterial}
                    values={lists.objMainMaterial}
                    updateProperty={(key, value) => {
                        this.updatePropertyMultiple(key, value, index);
                    }}
                    formFields={this.formFields}
                />
            ),
        }));

        const headers = [
            `${assetFields.equipmentno.text}`,
            `${assetFields.udfnum07.text} (${assetFields.udfnum07.udfUom})`,
            `${assetFields.udfnum08.text} (${assetFields.udfnum08.udfUom})`,
            `${assetFields.udfnum09.text} (${assetFields.udfnum09.udfUom})`,
            `${assetFields.udfnum10.text} (${assetFields.udfnum10.udfUom})`,
            `${assetFields.equipmentvalue.text} (${applicationData.eqpValueCurrency})`,
        ];
        const headersADR = [`${assetFields.equipmentno.text}`, assetFields.udfchar21.text, assetFields.udfchar22.text];

        const propCodes = ['equipmentCode', 'length', 'width', 'height', 'weight', 'equipmentValue'];
        const propCodesADR = ['equipmentCode', 'family', 'mainMaterial'];
        const { editAll } = this.state;

        return (
            <div style={{ width: '100%', height: '100%' }}>
                <EAMCheckbox
                    label={translations.EDITALL}
                    valueKey="editAll"
                    value={editAll}
                    updateProperty={(key, value) => {
                        this.setState({ [key]: value });
                    }}
                    trueValue
                    falseValue={false}
                />
                <h5>{translations.EQPCHARACTERISTIC}</h5>
                <EISTable data={equipmentList} headers={headers} propCodes={propCodes} maxMobileSize={600} />

                <h5>{translations.ADRDETAILS}</h5>
                <EISTable data={equipmentListADR} headers={headersADR} propCodes={propCodesADR} maxMobileSize={600} />
            </div>
        );
    };

    renderDetailsSingle = () => {
        const { translations, assetFields, request, updateEquipmentProperty, applicationData, lists } = this.props;
        const equipment = request.equipment[0];
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <EAMInput
                    elementInfo={{ ...assetFields.udfnum07, attribute: 'R' }}
                    valueKey="length"
                    value={equipment.length}
                    updateProperty={(key, value) => {
                        updateEquipmentProperty(key, value, 0);
                    }}
                    formFields={this.formFields}
                    validate={this.validateField}
                />

                <EAMInput
                    elementInfo={{ ...assetFields.udfnum08, attribute: 'R' }}
                    valueKey="width"
                    value={equipment.width}
                    updateProperty={(key, value) => {
                        updateEquipmentProperty(key, value, 0);
                    }}
                    formFields={this.formFields}
                    validate={this.validateField}
                />

                <EAMInput
                    elementInfo={{ ...assetFields.udfnum09, attribute: 'R' }}
                    valueKey="height"
                    value={equipment.height}
                    updateProperty={(key, value) => {
                        updateEquipmentProperty(key, value, 0);
                    }}
                    formFields={this.formFields}
                    validate={this.validateField}
                />

                <EAMInput
                    elementInfo={{ ...assetFields.udfnum10, attribute: 'R' }}
                    valueKey="weight"
                    value={equipment.weight}
                    updateProperty={(key, value) => {
                        updateEquipmentProperty(key, value, 0);
                    }}
                    formFields={this.formFields}
                    validate={this.isValidForEDH}
                    helperText={this.isSmallValue(equipment.weight) ? translations.WARN_SMALLVALUE : ''}
                />

                <EAMInput
                    elementInfo={{ ...assetFields.equipmentvalue, attribute: 'R' }}
                    valueKey="equipmentValue"
                    value={equipment.equipmentValue}
                    updateProperty={(key, value) => {
                        updateEquipmentProperty(key, value, 0);
                    }}
                    formFields={this.formFields}
                    rightAddon={applicationData.eqpValueCurrency}
                    validate={this.validateField}
                />

                <EAMSelect
                    elementInfo={{ ...assetFields.udfchar21, attribute: this._getADRFieldAttribute() }}
                    valueKey="family"
                    value={equipment.family}
                    values={lists.objFamily}
                    updateProperty={(key, value) => {
                        updateEquipmentProperty(key, value, 0);
                    }}
                    formFields={this.formFields}
                />

                <EAMSelect
                    elementInfo={{ ...assetFields.udfchar22, attribute: this._getADRFieldAttribute() }}
                    valueKey="mainMaterial"
                    value={equipment.mainMaterial}
                    values={lists.objMainMaterial}
                    updateProperty={(key, value) => {
                        updateEquipmentProperty(key, value, 0);
                    }}
                    formFields={this.formFields}
                />
            </div>
        );
    };

    render() {
        const { translations } = this.props;
        return (
            <Grid container spacing={0}>
                <Grid item md={6} sm={12} xs={12}>
                    <EISPanel heading={translations.EQPCHARACTERISTIC} alwaysExpanded>
                        <div style={{ width: '100%', marginTop: 0 }}>{this.renderDetails()}</div>
                    </EISPanel>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    {/* No content */}
                </Grid>
            </Grid>
        );
    }
}

export default RPMRDetails;
