import { Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import { TABLE_DATA_TYPES } from 'enums/Constants';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import { shouldShowComment } from 'tools/Hazards';
import { showError } from 'tools/TrecNotifications';
import InputGenerator from '../InputGenerator';
import { EQUIPMENT_KEYS, REQUEST_SOURCES, RPMR_TRANSLATION_KEYS } from '../RPMRConstants';

const { ATTRIBUTES } = InputGenerator;

const getEquipmentFields = ({
    isMultipleEquipment,
    screenData,
    applicationData,
    constants,
    translations,
    getTranslation,
    isVacuumAction,
    isVacuumCleanerEquipment,
    dropdowns,
    requestSource,
    isOtherRwc,
}) => {
    const { assetFields, lists } = screenData;

    const validateField = (value) => !Number.isNaN(value) && value > 0 && value < 999999999999;

    const isSmallValue = (val) => val < 0.001;

    const isValidForEDH = (value) => validateField(value) && !isSmallValue(value);

    const inputStyle = isMultipleEquipment
        ? {
              maxWidth: '100px',
          }
        : {};

    const selectStyle = isMultipleEquipment
        ? {
              minWidth: '200px',
          }
        : {};

    return [
        {
            code: EQUIPMENT_KEYS.CODE,
            type: TABLE_DATA_TYPES.INPUT,
            elementInfo: assetFields.equipmentno,
            getAttribute: () => (isMultipleEquipment ? ATTRIBUTES.READONLY_TEXT : ATTRIBUTES.HIDDEN),
        },
        {
            code: EQUIPMENT_KEYS.LENGTH,
            type: TABLE_DATA_TYPES.INPUT,
            elementInfo: assetFields.udfnum07,
            getAttribute: () => ATTRIBUTES.REQUIRED,
            validate: validateField,
            customInputProps: () => ({
                style: inputStyle,
            }),
        },
        {
            code: EQUIPMENT_KEYS.WIDTH,
            type: TABLE_DATA_TYPES.INPUT,
            elementInfo: assetFields.udfnum08,
            getAttribute: () => ATTRIBUTES.REQUIRED,
            validate: validateField,
            customInputProps: () => ({
                style: inputStyle,
            }),
        },
        {
            code: EQUIPMENT_KEYS.HEIGHT,
            type: TABLE_DATA_TYPES.INPUT,
            elementInfo: assetFields.udfnum09,
            getAttribute: () => ATTRIBUTES.REQUIRED,
            validate: validateField,
            customInputProps: () => ({
                style: inputStyle,
            }),
        },
        {
            code: EQUIPMENT_KEYS.WEIGHT,
            type: TABLE_DATA_TYPES.INPUT,
            elementInfo: assetFields.udfnum10,
            getAttribute: () => ATTRIBUTES.REQUIRED,
            validate: isValidForEDH,
            customInputProps: ({ field, object }) => ({
                style: inputStyle,
                helperText: isSmallValue(object[field.code]) ? translations.WARN_SMALLVALUE : '',
            }),
        },
        {
            code: EQUIPMENT_KEYS.VALUE,
            type: TABLE_DATA_TYPES.INPUT,
            elementInfo: assetFields.equipmentvalue,
            getAttribute: () => ATTRIBUTES.REQUIRED,
            validate: validateField,
            customInputProps: () => ({
                style: inputStyle,
                rightAddon: applicationData.eqpValueCurrency,
            }),
        },
        {
            code: EQUIPMENT_KEYS.FAMILY,
            type: TABLE_DATA_TYPES.SELECT,
            elementInfo: assetFields.udfchar21,
            getAttribute: () => (isOtherRwc ? ATTRIBUTES.OPTIONAL : ATTRIBUTES.REQUIRED),
            values: lists.objFamily,
            customInputProps: () => ({
                style: selectStyle,
            }),
        },
        {
            code: EQUIPMENT_KEYS.MATERIAL,
            type: TABLE_DATA_TYPES.SELECT,
            elementInfo: assetFields.udfchar22,
            getAttribute: () => (isOtherRwc ? ATTRIBUTES.OPTIONAL : ATTRIBUTES.REQUIRED),
            values: lists.objMainMaterial,
            customInputProps: () => ({
                style: selectStyle,
            }),
        },
        {
            code: EQUIPMENT_KEYS.IRRADIATION_END_DATE_MIN,
            type: TABLE_DATA_TYPES.DATE,
            elementInfo: {
                ...screenData.customFieldsWaste[constants.cfpWmIrradiationEndDateMin],
                attribute: 'R',
            },
            customInputProps: ({ object }) => ({
                style: { minWidth: '110px' },
                timestamp: true,
                maxDate: object[EQUIPMENT_KEYS.IRRADIATION_END_DATE_MAX] || new Date(),
            }),
            getAttribute: (eqp) =>
                !eqp[EQUIPMENT_KEYS.IRRADIATION_END_DATE_MIN] || isVacuumAction || isVacuumCleanerEquipment(eqp)
                    ? ATTRIBUTES.HIDDEN
                    : ATTRIBUTES.OPTIONAL,
        },
        {
            code: EQUIPMENT_KEYS.IRRADIATION_END_DATE_MAX,
            type: TABLE_DATA_TYPES.DATE,
            elementInfo: {
                ...screenData.customFieldsWaste[constants.cfpWmIrradiationEndDateMax],
                attribute: 'R',
            },
            customInputProps: ({ object }) => ({
                style: { minWidth: '110px' },
                timestamp: true,
                minDate: object[EQUIPMENT_KEYS.IRRADIATION_END_DATE_MIN] || undefined,
                maxDate: new Date(),
            }),
            getAttribute: (eqp) =>
                isVacuumAction || isVacuumCleanerEquipment(eqp)
                    ? ATTRIBUTES.HIDDEN
                    : requestSource === REQUEST_SOURCES.EMPTY_CONTAINER
                    ? ATTRIBUTES.HIDDEN
                    : ATTRIBUTES.OPTIONAL,
        },
        ...(applicationData.isHazardsFeatureActive
            ? [
                  {
                      code: EQUIPMENT_KEYS.HAZARD_CODES,
                      type: TABLE_DATA_TYPES.SELECT,
                      elementInfo: {
                          text: getTranslation(RPMR_TRANSLATION_KEYS.HAZARD_CODES),
                      },
                      customInputProps: () => ({
                          multi: true,
                          searchable: true,
                      }),
                      getAttribute: (equipment) =>
                          Array.isArray(equipment[EQUIPMENT_KEYS.HAZARD_CODES])
                              ? ATTRIBUTES.OPTIONAL
                              : ATTRIBUTES.HIDDEN,
                      values: dropdowns.hazard,
                  },
                  {
                      code: EQUIPMENT_KEYS.HAZARD_COMMENT,
                      type: TABLE_DATA_TYPES.INPUT,
                      elementInfo: {
                          text: getTranslation(RPMR_TRANSLATION_KEYS.HAZARD_COMMENT),
                      },
                      getAttribute: (equipment) =>
                          shouldShowComment({ equipment, applicationData }) ? ATTRIBUTES.REQUIRED : ATTRIBUTES.HIDDEN,
                  },
              ]
            : []),
        // RQF1619305 waiting for decision if here or RPMRLocation
        // {
        //     code: EQUIPMENT_KEYS.FACILITY,
        //     type: TABLE_DATA_TYPES.SELECT,
        //     elementInfo: {
        //         text: 'Facility',
        //     },
        //     getAttribute: () => ATTRIBUTES.REQUIRED,
        //     values: applicationData.facilities,
        // },
    ];
};

export const EquipmentDetails = (props) => {
    const {
        rpmrGetters,
        translations,
        getTranslation,
        screenData,
        applicationData,
        constants,
        storeActions,
        formFields,
        readOnly,
        fieldsProcessor,
        isVacuumAction,
        isOtherRwc,
        isVacuumCleanerEquipment,
        dropdowns,
        requestSource,
    } = props;
    const isMultipleEquipment = rpmrGetters.isMultipleEquipment();
    const equipmentList = rpmrGetters.getEquipmentList();
    const equipmentMap = rpmrGetters.getEquipmentMap();
    const properties = rpmrGetters.getProperties();

    const getFieldHeader = (field) => {
        if (
            [EQUIPMENT_KEYS.HEIGHT, EQUIPMENT_KEYS.WIDTH, EQUIPMENT_KEYS.HEIGHT, EQUIPMENT_KEYS.WEIGHT].includes(
                field.code
            )
        ) {
            return [field.elementInfo.text, `(${field.elementInfo.udfUom})`].join(' ');
        }
        if (EQUIPMENT_KEYS.VALUE === field.code) {
            return [field.elementInfo.text, `(${applicationData.eqpValueCurrency})`].join(' ');
        }
        return field.elementInfo.text;
    };

    const equipmentFields = getEquipmentFields({
        isMultipleEquipment,
        properties,
        screenData,
        applicationData,
        constants,
        translations,
        getTranslation,
        isVacuumAction,
        isOtherRwc,
        isVacuumCleanerEquipment,
        dropdowns,
        requestSource,
    });
    const computedFields = (fieldsProcessor && fieldsProcessor(equipmentFields)) || equipmentFields;

    const updateEquipment = (eqCode) => (key, value, onMount) => {
        const eqList = [eqCode];
        if (rpmrGetters.isApplyAllLines() && !onMount) {
            equipmentList.forEach((code) => {
                const eq = equipmentMap[code];
                const field = computedFields.filter((computedField) => computedField.code === key);
                if (
                    eq[EQUIPMENT_KEYS.CODE] !== eqCode &&
                    field.length &&
                    (!field[0].getAttribute ||
                        [ATTRIBUTES.OPTIONAL, ATTRIBUTES.REQUIRED].includes(field[0].getAttribute(eq)))
                ) {
                    eqList.push(eq[EQUIPMENT_KEYS.CODE]);
                }
            });
        }

        storeActions.updateRPMREquipment(eqList, { [key]: value });
    };

    const allFields = equipmentList.map((eqCode) =>
        computedFields.reduce(
            (acc, field) => ({
                ...acc,
                [field.code]: (
                    <div key={field.code}>
                        {InputGenerator.generate({
                            field: {
                                ...field,
                                elementInfo: {
                                    ...field.elementInfo,
                                    xpath: `${field.elementInfo.xpath}${isMultipleEquipment ? eqCode : ''}`,
                                },
                                getAttribute: (object) => {
                                    const attr = field.getAttribute && field.getAttribute(object);
                                    if (attr === ATTRIBUTES.HIDDEN) return ATTRIBUTES.HIDDEN;
                                    return readOnly
                                        ? isMultipleEquipment &&
                                          ![
                                              TABLE_DATA_TYPES.DATE,
                                              TABLE_DATA_TYPES.DATE_TIME,
                                              TABLE_DATA_TYPES.SELECT,
                                          ].includes(field.type)
                                            ? ATTRIBUTES.READONLY_TEXT
                                            : ATTRIBUTES.READONLY
                                        : attr;
                                },
                            },
                            object: equipmentMap[eqCode],
                            updateObject: updateEquipment(eqCode),
                            formFields,
                            hideLabel: isMultipleEquipment,
                        })}
                    </div>
                ),
            }),
            {}
        )
    );

    return !isMultipleEquipment ? (
        allFields.length ? (
            Object.values(allFields[0])
        ) : null
    ) : (
        <>
            {!readOnly && (
                <EAMCheckbox
                    label={translations.EDITALL}
                    value={rpmrGetters.isApplyAllLines()}
                    updateProperty={(key, value) => storeActions.setApplyAllLines(value)}
                    trueValue
                    falseValue={false}
                />
            )}
            <EISTable
                data={allFields}
                headers={computedFields.map((field) => getFieldHeader(field))}
                propCodes={computedFields.map((field) => field.code)}
                maxMobileSize={1150}
                style={{ width: 'auto', height: 'auto' }}
            />
        </>
    );
};

class RPMRDetailsStep extends WizardStep {
    formFields = [];

    UNSAFE_componentWillMount() {
        const { storeActions } = this.props;
        storeActions.setPage('TREC_REQRPMEAS', 'STEP_DETAILS');
    }

    canContinue = () => {
        const { translations } = this.props;
        const isValid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
        if (!isValid) {
            showError(translations.EQUFIELINV);
        }
        return isValid;
    };

    saveChanges = () => true;

    commitChanges = (callback) => callback();

    render() {
        const { translations, rpmrGetters } = this.props;

        return (
            <div style={{ margin: 8 }}>
                <Grid container spacing={8}>
                    <Grid item md={rpmrGetters.isMultipleEquipment() ? 12 : 6} sm={12} xs={12}>
                        <EISPanel
                            heading={translations.EQPCHARACTERISTIC}
                            detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                            alwaysExpanded
                        >
                            <EquipmentDetails {...this.props} formFields={this.formFields} />
                        </EISPanel>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default RPMRDetailsStep;
