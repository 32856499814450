import { Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';

const ErrorText = ({ text }) => (
    <div
        style={{
            border: '1px solid red',
            backgroundColor: '#ffcccc',
            display: 'flex',
            alignItems: 'center',
            padding: 5,
            margin: 15,
            borderRadius: '4px',
        }}
    >
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Error style={{ fontSize: 35, color: 'red' }} />
        </div>

        <Typography dangerouslySetInnerHTML={{ __html: text }} style={{ flex: 5, fontWeight: 'bold', color: 'red' }} />
    </div>
);

export default ErrorText;
