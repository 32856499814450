import React, { Component } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import Grid from '@material-ui/core/Grid';
import BlockUi from 'react-block-ui';
import CommentsContainer from 'eam-components/dist/ui/components/comments/CommentsContainer';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EAMDatePicker from 'eam-components/dist/ui/components/inputs/EAMDatePicker';
import { NonRadioactiveWarningIcon, RadioactiveWarningIcon } from 'eam-components/dist/ui/components/icons';
import EISTable from 'react-eis-components/dist/ui/components/table';
import { withStyles } from '@material-ui/core/styles';
import Tools from '../../../../tools/Tools';
import WSRPMRequest from '../../../../tools/rest/WSRPMRequest';
import WSComments from '../../../../tools/rest/WSComments';
import { handleError, showWarning } from 'tools/TrecNotifications';

const blockUiStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const blockUiStyleDiv = {
    display: 'flex',
    height: 60,
    alignItems: 'flex-end',
};

const labelStyle = {
    width: 170,
    minWidth: 170,
};

const styles = () => ({
    iconStyle: {
        '& svg': {
            fontSize: '60px',
            position: 'relative',
            left: 'calc(50% - 30px)',
        },
    },
});

class RPMRResults extends Component {
    UNSAFE_componentWillMount() {
        const { handleBack, setPage, request, lang } = this.props;
        // Set the page for the help
        setPage('TREC_REQRPMEAS', 'STEP_RESULTS');
        const multipleEquipment = request.equipment.length > 1;
        if (!multipleEquipment) {
            const { equipmentCode } = request.equipment[0];
            // Fetch the information from the last measurement
            WSRPMRequest.readLastMeasurement(equipmentCode, lang)
                .then((response) => {
                    // Process measurement
                    this.processMeasurement(response.body.data);
                })
                .catch((error) => {
                    handleError(error);
                    // Go back
                    handleBack();
                });
        } else {
            // Multiple equipment
            const equipmentList = request.equipment.map((elem) => elem.equipmentCode);
            WSRPMRequest.readLastMeasurementEquipments(equipmentList, lang)
                .then((response) => {
                    // Process measurements
                    this.processMeasurements(response.body.data);
                })
                .catch((error) => {
                    handleError(error);
                    // Go back
                    handleBack();
                });
        }
    }

    componentDidMount() {
        const { onRef } = this.props;
        if (onRef) {
            onRef(this);
        }
    }

    validate() {
        const { lastMeasurement } = this.props;
        const { lastMeasurementNumber } = this.props;
        if (!lastMeasurement || !lastMeasurementNumber) {
            return false;
        }
        // There is the last measurement (It is possible to continue)
        return true;
    }

    processMeasurement = (measurementData) => {
        const { setLastMeasurementData, translations } = this.props;
        const { measurement } = measurementData;
        const { lastMeasurementNumber } = measurementData;
        if (!measurement) {
            // No measurement registered for the equipment
            throw Tools.createServerError('', translations.NOMEASPERFORM);
        }
        /* There is the measurement */
        // Check  if the measurement is not completed
        if (measurement.jobNumber !== lastMeasurementNumber) {
            // Measurement not done. Show warning
            showWarning(translations.LASTRPMNOTDONE, '', null);
        }
        // Set the last measurement data
        setLastMeasurementData(measurement, lastMeasurementNumber);
    };

    processMeasurements = (measurementData) => {
        const { request, setLastMeasurementData, translations } = this.props;
        // 1. Length of the data must be the same as equipments
        if (measurementData.length !== request.equipment.length) {
            throw Tools.createServerError('', translations.NOMEASPERFORM);
        }
        // All data with job numbers
        measurementData.forEach((measurement) => {
            // Check that there is measurement
            if (!measurement.lastMeasurementNumber) {
                throw Tools.createServerError('', translations.NOMEASPERFORM);
            }
            // Check that the measurement is the last one
            if (measurement.lastMeasurementNumber !== measurement.jobNumber) {
                showWarning(translations.LASTRPMNOTDONE, '', null);
            }
        });
        // Set the last measurement data
        setLastMeasurementData(measurementData, measurementData[0].lastMeasurementNumber);
    };

    // When Loading
    renderLoading = () => {
        const { translations } = this.props;
        return (
            <BlockUi tag="div" blocking style={blockUiStyle}>
                <div style={blockUiStyleDiv}>{translations.LOADING}</div>
            </BlockUi>
        );
    };

    renderGeneralData = () => {
        const {
            request,
            assetFields,
            woFields,
            customFields,
            customFieldsDef,
            constants,
            classes,
            applicationData,
            lang,
        } = this.props;
        const { lastMeasurement: measurement } = this.props;
        const equipment = request.equipment[0].inforEquipment;
        return (
            <div style={{ width: '100%', marginTop: 0 }}>
                {/* Radioactive Icon */}
                {
                    // RQF1421663
                    equipment.userDefinedFields.udfchar04 === constants.radioactive ? (
                        <div className={classes.iconStyle}>
                            <RadioactiveWarningIcon />
                        </div>
                    ) : (
                        <div className={classes.iconStyle}>
                            <NonRadioactiveWarningIcon />
                        </div>
                    )
                }

                {/* Job Number */}
                <EAMInput
                    elementInfo={{ ...woFields.workordernum, readonly: true }}
                    value={measurement.jobNumber}
                    labelStyle={labelStyle}
                />

                {/* Equipment */}
                <EAMInput
                    elementInfo={{ ...assetFields.equipmentno, readonly: true }}
                    value={`${equipment.code} - ${equipment.description}`}
                    labelStyle={labelStyle}
                />

                {/* Date completed */}
                <EAMDatePicker
                    elementInfo={{ ...woFields.datecompleted, readonly: true }}
                    value={measurement.dateCompleted}
                    labelStyle={labelStyle}
                    localeString={lang}
                />

                {/* Future Action */}
                <EAMSelect
                    elementInfo={{ ...customFields[constants.cfpFutureAction], readonly: true }}
                    value={measurement.futureAction}
                    values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpFutureAction], true)}
                    labelStyle={labelStyle}
                />

                {/* Associated risks */}
                {applicationData.isHazardsFeatureActive ? null : (
                    <EAMSelect
                        elementInfo={{ ...customFields[constants.cfpAssociatedRisks], readonly: true }}
                        value={measurement.associatedRisk}
                        values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpAssociatedRisks], true)}
                        labelStyle={labelStyle}
                    />
                )}

                {/* EDH Document */}
                <EAMSelect
                    elementInfo={{ ...customFields[constants.cfpEdhDocument], readonly: true }}
                    value={measurement.edhDocument}
                    values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpEdhDocument])}
                    labelStyle={labelStyle}
                />
            </div>
        );
    };

    renderRadiationMeasurements = () => {
        const { constants, translations, customFields, customFieldsDef } = this.props;
        const { lastMeasurement: measurement } = this.props;

        const headers = [translations.DEVICE, translations.DISTANCE, translations.VALUE];
        const propCodes = ['device', 'distance', 'value'];

        // Data from measurements
        const data = [];
        if (measurement.device1) {
            data.push({
                device: `${customFields[constants.cfpRadiationDevice1].text} [${Tools.getCustomFieldValueDesc(
                    customFieldsDef[constants.cfpRadiationDevice1],
                    measurement.device1
                )}]`,
                distance: Tools.getCustomFieldValueDesc(
                    customFieldsDef[constants.cfpRadiationDistance1],
                    measurement.distance1
                ),
                value: `${measurement.qualifier1} ${measurement.value1}`,
            });
        }
        if (measurement.device2) {
            data.push({
                device: `${customFields[constants.cfpRadiationDevice2].text} [${Tools.getCustomFieldValueDesc(
                    customFieldsDef[constants.cfpRadiationDevice2],
                    measurement.device2
                )}]`,
                distance: Tools.getCustomFieldValueDesc(
                    customFieldsDef[constants.cfpRadiationDistance2],
                    measurement.distance2
                ),
                value: `${measurement.qualifier2} ${measurement.value2}`,
            });
        }
        if (measurement.device3) {
            data.push({
                device: `${customFields[constants.cfpRadiationDevice3].text} [${Tools.getCustomFieldValueDesc(
                    customFieldsDef[constants.cfpRadiationDevice3],
                    measurement.device3
                )}]`,
                distance: Tools.getCustomFieldValueDesc(
                    customFieldsDef[constants.cfpRadiationDistance3],
                    measurement.distance3
                ),
                value: `${measurement.qualifier3} ${measurement.value3}`,
            });
        }
        if (measurement.device4) {
            data.push({
                device: `${customFields[constants.cfpRadiationDevice4].text} [${Tools.getCustomFieldValueDesc(
                    customFieldsDef[constants.cfpRadiationDevice4],
                    measurement.device4
                )}]`,
                distance: Tools.getCustomFieldValueDesc(
                    customFieldsDef[constants.cfpRadiationDistance4],
                    measurement.distance4
                ),
                value: `${measurement.qualifier4} ${measurement.value4}`,
            });
        }
        // BKG Device
        if (measurement.bkgDevice) {
            data.push({
                device: `${customFields[constants.cfpBkgDevice].text} [${Tools.getCustomFieldValueDesc(
                    customFieldsDef[constants.cfpBkgDevice],
                    measurement.bkgDevice
                )}]`,
                distance: ' - ',
                value: `${measurement.bkgQualifier} ${measurement.bkgValue}`,
            });
        }

        return (
            <div style={{ width: '100%', marginTop: 0 }}>
                <EISTable data={data} headers={headers} propCodes={propCodes} maxMobileSize={600} />
            </div>
        );
    };

    renderContaminationMeasurements = () => {
        const { constants, translations, customFields, customFieldsDef } = this.props;
        const { lastMeasurement: measurement } = this.props;
        const headers = [
            translations.MEASUREMENT,
            translations.VALUE,
            translations.NETVALUE,
            translations.SURFACICCONT,
        ];
        const propCodes = ['measurement', 'value', 'netValue', 'surfacicCont'];
        const data = [
            {
                measurement: translations.BKGALPHA,
                value: `${measurement.bkgAlphaQualifier} ${measurement.bkgAlphaValue}`,
                netValue: `${measurement.bkgAlphaNetQualifier} ${measurement.bkgAlphaNetValue}`,
                surfacicCont: measurement.alphaSurfacicCont,
            },
            {
                measurement: translations.BKGBETA,
                value: `${measurement.bkgBetaQualifier} ${measurement.bkgBetaValue}`,
                netValue: `${measurement.bkgBetaNetQualifier} ${measurement.bkgBetaNetValue}`,
                surfacicCont: measurement.betaSurfacicCont,
            },
        ];
        return (
            <div style={{ width: '100%', marginTop: 0 }}>
                {/* Contamination Risks */}
                <EAMSelect
                    elementInfo={{ ...customFields[constants.cfpContaminationRisk], readonly: true }}
                    value={measurement.contaminationRisk}
                    values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpContaminationRisk], true)}
                    labelStyle={labelStyle}
                />

                {/* Contamination Tool */}
                <EAMSelect
                    elementInfo={{ ...customFields[constants.cfpContaminationMeasTool], readonly: true }}
                    value={measurement.contMeasurementTool}
                    values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpContaminationMeasTool])}
                    labelStyle={labelStyle}
                />

                {/* Contamination Measurement Type */}
                <EAMSelect
                    elementInfo={{ ...customFields[constants.cfpContaminationType], readonly: true }}
                    value={measurement.contMeasurementType}
                    values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpContaminationType])}
                    labelStyle={labelStyle}
                />

                {/* Contamination BKG Alpha/Beta */}
                <EISTable data={data} headers={headers} propCodes={propCodes} maxMobileSize={600} />
            </div>
        );
    };

    renderResultsSingle = () => {
        const { lastMeasurement, translations, userData } = this.props;
        return (
            <Grid container spacing={8}>
                <Grid item md={6} sm={12} xs={12}>
                    {/* General Data */}
                    <EISPanel heading={translations.GENERALDATA}>
                        {!lastMeasurement ? this.renderLoading() : this.renderGeneralData()}
                    </EISPanel>

                    {/* Measurement comments */}
                    {lastMeasurement && (
                        <CommentsContainer
                            title={translations.RPMEASCOMMENT}
                            ref={(comments) => {
                                this.comments = comments;
                            }}
                            entityCode="EVNT"
                            entityKeyCode={lastMeasurement.jobNumber}
                            userDesc={userData.eamAccount.userDesc}
                            readComments={WSComments.readComments}
                        />
                    )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    <EISPanel heading={translations.RESULTS}>
                        {!lastMeasurement ? this.renderLoading() : this.renderSingleResultTable(lastMeasurement)}
                    </EISPanel>
                    {
                        // RQF1421663
                    }
                    {/* Radiation Measurements
                    <EISPanel heading={translations.RADIATIONMEAS}>
                        {!this.props.lastMeasurement ? this.renderLoading() : this.renderRadiationMeasurements()}
                    </EISPanel>

                    {/*Contamination Measurements
                    <EISPanel heading={translations.CONTAMINMEAS}>
                        {!this.props.lastMeasurement ? this.renderLoading() : this.renderContaminationMeasurements()}
                    </EISPanel>
                    */}
                </Grid>
            </Grid>
        );
    };

    renderResultsMulti = () => {
        const { lastMeasurement, translations } = this.props;
        return (
            <Grid container spacing={8}>
                <Grid item md={8} sm={12} xs={12}>
                    <EISPanel heading={translations.RESULTS}>
                        {!lastMeasurement ? this.renderLoading() : this.renderResultsTable()}
                    </EISPanel>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    {/* No content */}
                </Grid>
            </Grid>
        );
    };

    renderSingleResultTable = (measurement) => {
        const { assetFields, woFields, customFields, customFieldsDef, request, constants, equipments } = this.props;
        const headers = [
            assetFields.equipmentno.text,
            woFields.workordernum.text,
            customFields[constants.cfpFutureAction].text,
            woFields.datecompleted.text,
            `Eq. ${customFields[constants.cfpRadiationClassification].text}`,
        ];
        const propCodes = ['equipment', 'jobNumber', 'futureAction', 'completed', 'eqclassification'];
        const eqRPClassMap =
            request.equipment &&
            request.equipment.length &&
            request.equipment.reduce(
                (acc, eq) => ({
                    ...acc,
                    [eq.equipmentCode]:
                        eq.inforEquipment &&
                        eq.inforEquipment.userDefinedFields &&
                        eq.inforEquipment.userDefinedFields.udfchar04,
                }),
                {}
            );

        const data = [
            {
                ...measurement,
                completed: measurement.dateCompleted,
                equipment: equipments,
                futureAction: Tools.getCustomFieldValueDesc(
                    customFieldsDef[constants.cfpFutureAction],
                    measurement.futureAction
                ),
                eqclassification:
                    eqRPClassMap[equipments] === constants.radioactive ? (
                        <RadioactiveWarningIcon />
                    ) : (
                        <NonRadioactiveWarningIcon />
                    ),
            },
        ];

        return (
            <div style={{ width: '100%', marginTop: 0 }}>
                <EISTable data={data} headers={headers} propCodes={propCodes} maxMobileSize={600} />
            </div>
        );
    };

    renderResultsTable = () => {
        const { assetFields, woFields, customFields, customFieldsDef, lastMeasurement, constants, request } =
            this.props;
        const headers = [
            assetFields.equipmentno.text,
            woFields.workordernum.text,
            customFields[constants.cfpFutureAction].text,
            woFields.datecompleted.text,
            `Eq. ${customFields[constants.cfpRadiationClassification].text}`,
        ];
        const propCodes = ['equipment', 'jobNumber', 'futureAction', 'completed', 'eqclassification'];
        const eqRPClassMap =
            request.equipment &&
            request.equipment.length &&
            request.equipment.reduce(
                (acc, eq) => ({
                    ...acc,
                    [eq.equipmentCode]:
                        eq.inforEquipment &&
                        eq.inforEquipment.userDefinedFields &&
                        eq.inforEquipment.userDefinedFields.udfchar04,
                }),
                {}
            );

        const data = (!lastMeasurement.length ? [lastMeasurement] : lastMeasurement).map((measurement) => ({
            ...measurement,
            futureAction: Tools.getCustomFieldValueDesc(
                customFieldsDef[constants.cfpFutureAction],
                measurement.customFieldsRP && measurement.customFieldsRP[constants.cfpFutureAction]
            ),
            // classification: (measurement.classification === constants.radioactive ?
            //         <RadioactiveWarningIcon/>
            //         :
            //         <NonRadioactiveWarningIcon/>

            // ),
            eqclassification:
                eqRPClassMap[measurement.equipment] === constants.radioactive ? (
                    <RadioactiveWarningIcon />
                ) : (
                    <NonRadioactiveWarningIcon />
                ),
        }));

        return (
            <div style={{ width: '100%', marginTop: 0 }}>
                <EISTable data={data} headers={headers} propCodes={propCodes} maxMobileSize={600} />
            </div>
        );
    };

    render() {
        const { request } = this.props;
        const multipleEquipment = request.equipment.length > 1;
        return multipleEquipment ? this.renderResultsMulti() : this.renderResultsSingle();
    }
}

export default withStyles(styles)(RPMRResults);
