import Grid from '@material-ui/core/Grid';
import { WizardStep } from 'amm-tools';
import { addBusinessDays } from 'date-fns';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import React from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import { REQUEST_KEYS } from '../RPMRConstants';
import { showError } from 'tools/TrecNotifications';

class RPMRActionsStep extends WizardStep {
    formFields = [];

    validate = () => {
        const { translations } = this.props;

        if (this.hasInvalidPermissions()) {
            return false;
        }

        const valid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );

        if (!valid) showError(translations.ACTINVALID);
        return valid;
    };

    changeAction(action) {
        const { storeActions, constants, rpmrGetters, applicationData } = this.props;
        const properties = rpmrGetters.getProperties();

        // EAMTREC-224
        storeActions.updateRPMRequest({
            [REQUEST_KEYS.ACTION]: action,
            [REQUEST_KEYS.RESP_CODE]: '',
            [REQUEST_KEYS.RESP_DESC]: '',
            // RQF1056033 If Future action is "Move inside CERN", then default EDH Document is transport
            [REQUEST_KEYS.EDH_DOCUMENT]:
                action === constants.futureActionMoveatcern
                    ? constants.cfvEdhDocumentTransport
                    : action === constants.futureActionDispatch
                    ? constants.cfvEdhDocumentShipping
                    : action === constants.futureActionStock
                    ? constants.cfvEdhDocumentStockage
                    : '',
            [REQUEST_KEYS.DEPARTMENT]:
                action === constants.futureActionOther || !action
                    ? ''
                    : action === constants.futureActionWasteReceive
                    ? applicationData.rwsection
                    : applicationData.rpsection,
            [REQUEST_KEYS.TRANSPORT_REQUIRED]:
                action === constants.futureActionStock ? constants.trueYes : constants.falseNo,
            [REQUEST_KEYS.ASSOCIATED_RISK]: properties[REQUEST_KEYS.ASSOCIATED_RISK] || constants.cfvAssocrisksNone,
            [REQUEST_KEYS.MEASUREMENT_DEADLINE]:
                properties[REQUEST_KEYS.MEASUREMENT_DEADLINE] || addBusinessDays(new Date(), 3),
            [REQUEST_KEYS.STORAGE_OPERATION]: action === constants.futureActionStock ? constants.cfvNatureInbound : '',
        });
        // storeActions.updateRPMRSelectedEquipment([]);
    }

    actionFilter = (action, departments) => {
        const { constants, applicationData } = this.props;
        if (action === constants.futureActionWasteDeclare) {
            return departments.map(({ code }) => code).includes(applicationData.rpsection);
        }

        if (action === constants.futureActionWasteReceive) {
            return departments.map(({ code }) => code).includes(applicationData.rwsection);
        }

        if (!action || action === constants.futureActionOther) {
            return true;
        }

        return departments.map(({ code }) => code).includes(applicationData.rpsection);
    };

    computedData = () => {
        const { constants, rpmrGetters, urlParams, dropdowns, screenData, userData } = this.props;
        const requestProperties = rpmrGetters.getProperties();
        const action = requestProperties[REQUEST_KEYS.ACTION];
        const department = requestProperties[REQUEST_KEYS.DEPARTMENT];

        const {
            futureActionWasteDeclare,
            futureActionWasteReceive,
            futureActionDispatch,
            futureActionMoveatcern,
            futureActionOther,
            futureActionStock,
            futureActionUpdateloc,
            futureActionVacuumDeclare,
            futureActionVacuumEmpty,
            futureActionVacuumEnd,
            futureActionVacuumMove,
            futureActionVacuumEndFull,
        } = constants;

        const accessibleDepartments = screenData.lists.departments
            .filter((dep) => !urlParams.dep || urlParams.dep === dep.code)
            .filter(
                (dep) =>
                    userData.eamAccount.departmentalSecurity[dep.code] &&
                    !userData.eamAccount.departmentalSecurity[dep.code].readOnly
            );

        const values = (
            urlParams.action === 'updateloc'
                ? [futureActionUpdateloc]
                : urlParams.action === 'declarewaste'
                ? [futureActionWasteReceive, futureActionWasteDeclare]
                : urlParams.action === 'declareaswaste'
                ? [futureActionWasteDeclare]
                : urlParams.action === 'receiveaswaste'
                ? [futureActionWasteReceive]
                : urlParams.action === 'moveatcern'
                ? [futureActionMoveatcern]
                : urlParams.action === 'storageatcern'
                ? [futureActionStock]
                : urlParams.action === 'dispatch'
                ? [futureActionDispatch]
                : urlParams.action === 'vacuum'
                ? [futureActionVacuumDeclare, futureActionVacuumEnd, futureActionVacuumEmpty, futureActionVacuumMove]
                : ['other', futureActionOther].includes(urlParams.action)
                ? [futureActionOther]
                : [
                      futureActionMoveatcern,
                      futureActionWasteDeclare,
                      futureActionWasteReceive,
                      futureActionDispatch,
                      futureActionOther,
                      futureActionStock,
                  ]
        ).filter((actionElement) => this.actionFilter(actionElement, accessibleDepartments));

        const filteredActionValues = values.map(
            (code) => dropdowns.futureAction.find((vl) => vl.code === code) || { code, desc: code }
        );

        const departments = accessibleDepartments.filter((dep) => this.actionFilter(action, [dep]));

        // Needed because the checkbox is just for another action
        const shownAction = action === futureActionVacuumEndFull ? futureActionVacuumEnd : action;
        return { actionValues: filteredActionValues, action, shownAction, department, departments };
    };

    /** WizardStep required methods */
    canContinue = () => this.validate();

    saveChanges = () => true;

    commitChanges = (callback) => {
        // RIC: the code is commented since the changeAction deals with this
        // Check if future action is 'declare use', in this case don't populate the responsible

        // const { rpmrGetters, storeActions, constants } = this.props;
        // const requestProperties = rpmrGetters.getProperties();
        // const action = requestProperties[REQUEST_KEYS.ACTION];
        // const edhDocument = requestProperties[REQUEST_KEYS.EDH_DOCUMENT];

        // if (action === constants.futureActionVacuumDeclare) {
        //     storeActions.updateRPMRequest({equipmentRespCode: '', equipmentRespDesc: ''});
        // }
        callback();
    };

    /** Component life-cycle methods */
    UNSAFE_componentWillMount() {
        // Set the page for the help
        const { setPage } = this.props.storeActions;
        this.checkActions();
        setPage('TREC_REQRPMEAS', 'STEP_ACTIONS');
    }

    componentDidUpdate() {
        this.checkActions();
    }

    checkActions = () => {
        const { constants, storeActions } = this.props;
        const { action, actionValues, department, departments } = this.computedData();

        if (this.hasInvalidPermissions()) {
            return;
        }

        // determine what should be the new action
        let newAction;
        if (!action && actionValues.length === 1) {
            newAction = actionValues[0].code;
        }

        const newActionDepartments = departments.filter((dep) => this.actionFilter(newAction, [dep]));
        if (!department && newActionDepartments.length === 1) {
            storeActions.updateRPMRequest({ [REQUEST_KEYS.DEPARTMENT]: departments[0].code });
        }

        if (newAction) {
            this.changeAction(actionValues[0].code);

            if (actionValues.length === 1 && newActionDepartments.length === 1) {
                // automatically select if there is only one option
                actionValues[0].code !== constants.futureActionUpdateloc &&
                    setTimeout((_) => this.props.handleNext(), 0);
            }
        }
    };

    hasInvalidPermissions = () => {
        const { actionValues, departments } = this.computedData();
        return actionValues.length === 0 || departments.length === 0;
    };

    render() {
        const { constants, storeActions, customFields, translations, screenData } = this.props;

        const { actionValues, action, shownAction, department, departments } = this.computedData();
        // const futureActionCF = Tools.filterCustomFieldValues(customFieldsDef[constants.cfpFutureAction], values);

        const invalidPermissions = this.hasInvalidPermissions();

        return (
            <div style={{ margin: 8 }}>
                <Grid container spacing={8}>
                    <Grid item md={6} sm={12} xs={12}>
                        <EISPanel heading={translations.ACTIONS} alwaysExpanded>
                            <div style={{ width: '100%', marginTop: 0 }}>
                                {invalidPermissions ? (
                                    <p>
                                        {translations.ACT_PERMISSION_MESSAGE ||
                                            'You do not have permission to execute this action'}
                                    </p>
                                ) : (
                                    <>
                                        {translations.ACT_MESSAGE}
                                        <EAMSelect
                                            elementInfo={{ ...customFields[constants.cfpFutureAction], attribute: 'R' }}
                                            value={shownAction}
                                            values={actionValues}
                                            updateProperty={(_, value) =>
                                                value !== shownAction && this.changeAction(value)
                                            }
                                            formFields={this.formFields}
                                        />

                                        {shownAction === constants.futureActionVacuumEnd && (
                                            <EAMCheckbox
                                                elementInfo={{ text: translations.VACCLISFULL }}
                                                value={action}
                                                updateProperty={(_, val) =>
                                                    storeActions.updateRPMRequest({ [REQUEST_KEYS.ACTION]: val })
                                                }
                                                trueValue={constants.futureActionVacuumEndFull}
                                                falseValue={constants.futureActionVacuumEnd}
                                            />
                                        )}
                                        <EAMSelect
                                            elementInfo={{
                                                ...screenData.woFields.department,
                                                attribute: action === constants.futureActionOther ? 'R' : 'O',
                                                readonly: !action || departments.length < 2,
                                            }}
                                            value={department}
                                            values={departments}
                                            updateProperty={(_, val) =>
                                                storeActions.updateRPMRequest({ [REQUEST_KEYS.DEPARTMENT]: val })
                                            }
                                            formFields={this.formFields}
                                        />
                                    </>
                                )}
                            </div>
                        </EISPanel>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default RPMRActionsStep;
