import React, { Component } from 'react';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import WSInconsistencyChecks from '../../../tools/rest/WSInconsistencyChecks';

const flexContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
};

const circularProgressContainerStyle = {
    width: '32px',
    height: '33px',
    padding: '8px 16px',
    flex: '0 1 auto',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export default class RWInconsistencyChecksComment extends Component {
    constructor(props) {
        super(props);

        const { initialComment } = props;

        this.state = {
            comment: initialComment === null ? '' : initialComment,
            loading: false,
        };
    }

    onCommentHandler = () => {
        const { comment } = this.state;
        const { objCode } = this.props;

        this.setState({ loading: true });
        WSInconsistencyChecks.acknowledge({ objCode, value: null, comment })
            .catch(() => {
                // do nothing
            })
            .finally(() => this.setState({ loading: false }));
    };

    render() {
        const { comment, loading } = this.state;

        return (
            <div style={flexContainerStyle}>
                <div style={{ flex: '1 1 auto' }}>
                    <EAMInput
                        value={comment}
                        updateProperty={(_, value) => this.setState({ comment: value })}
                        disabled={loading}
                        onBlur={this.onCommentHandler}
                    />
                </div>
                {loading ? (
                    <div style={circularProgressContainerStyle}>
                        <CircularProgress size={24} />
                    </div>
                ) : (
                    <Button onClick={this.onCommentHandler} color="primary" style={{ flex: '0 1 auto' }}>
                        <SaveIcon />
                    </Button>
                )}
            </div>
        );
    }
}
