import { connect } from 'react-redux';
import { updateLayout, toggleHelp } from '../../../actions/layoutActions';
import TRECAppBar from './TRECAppBar';
import { logout } from '../../../actions/applicationActions';

function mapStateToProps(state) {
    return {
        lang: state.layout.lang,
        userData: state.application.userData,
        bufferZoneAccount: state.application.applicationData ? state.application.applicationData.bufferZoneAccount : '',
    };
}

const TrecAppBarContainer = connect(mapStateToProps, {
    updateLayout,
    toggleHelp,
    logout,
})(TRECAppBar);

export default TrecAppBarContainer;
