import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Menu from '@material-ui/icons/Menu';
import Save from '@material-ui/icons/Save';
import { TREC_GENERAL_KEYS } from '../../../enums/Constants';
import TRECButton from '../../components/buttons/TRECButton';

function WizardControls(props) {
    const {
        handleCancel,
        handlePrevious,
        handleNext,
        handleFinish,
        isFirstStep,
        isLastStep,
        isFinished,
        getTranslation,
        children,
    } = props;

    return (
        <>
            <TRECButton onClick={handleCancel} color="default">
                <Menu />
                MENU
            </TRECButton>

            <TRECButton disabled={isFirstStep} onClick={handlePrevious}>
                <ArrowBack />
                {getTranslation(TREC_GENERAL_KEYS.BACK)}
            </TRECButton>

            {!isLastStep ? (
                <TRECButton onClick={handleNext}>
                    <ArrowForward />
                    {getTranslation(TREC_GENERAL_KEYS.NEXT)}
                </TRECButton>
            ) : (
                <TRECButton disabled={isFinished} onClick={handleFinish}>
                    <Save />
                    {getTranslation(TREC_GENERAL_KEYS.SUBMIT)}
                </TRECButton>
            )}
            {children && children(props)}
        </>
    );
}

export default WizardControls;
