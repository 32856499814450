import { Typography } from '@material-ui/core';
import { reactSelectComponents } from 'amm-tools';

export const LocationOptionInner = ({ data }) => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '4px',
            // border: '3px double #32a1ce',
            borderRadius: '4px',
        }}
    >
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'space-between',
                width: '100%',
                height: '100%',
            }}
        >
            <Typography variant="subtitle1" style={{ flex: 1 }}>
                {data.code}
            </Typography>
            <Typography variant="subtitle2">{data.building}</Typography>
        </div>
        <Typography variant="body2" color="textSecondary">
            {data.desc}
        </Typography>
        <Typography variant="body1" color="textSecondary">
            {data.areaClassificationDesc}
        </Typography>
    </div>
);

const LocationOption = (props) => (
    <reactSelectComponents.Option {...props}>
        <LocationOptionInner {...props} />
    </reactSelectComponents.Option>
);

export default LocationOption;
