export const ACTION_ASK_FETCH = 'ACTION_ASK_FETCH';
export const ACTION_ASK_WHERE = 'ACTION_ASK_WHERE';
export const ACTION_NONE = 'ACTION_NONE';
export const ACTION_CHANGE_LOCATION = 'ACTION_CHANGE_LOCATION';
export const ACTION_DECLARE_NOT_PRESENT = 'ACTION_DECLARE_NOT_PRESENT';

export const BZ_INVENTORY = 'BZ_INVENTORY';

export const STEP = 'step';
export const STEP_INITALIZATION = 'STEP_INITIALIZATION';
export const STEP_SCANNING = 'STEP_SCANNING';
export const STEP_ACTIONS = 'STEP_ACTIONS';
export const STEP_APPROVED = 'STEP_APPROVED';
export const STEP_PAST_INVENTORIES = 'STEP_PAST_INVENTORIES';

export const EQP_TOTAL = 'EQP_TOTAL';
export const EQP_PRESENT = 'EQP_PRESENT';
export const EQP_NOT_PRESENT = 'EQP_NOT_PRESENT';
export const EQP_TO_CHECK = 'EQP_TO_CHECK';
export const EQP_FOUND = 'EQP_FOUND';
export const EQP_VISIBLE = 'EQP_VISIBLE';

export const FILTER_RESULT = 'resultFilter';
export const FILTER_STATUS = 'statusFilter';
export const FILTER_FUTURE_ACTION = 'futureActionFilter';
export const FILTER_RADIATION_CLASSIFICATION = 'radiationClassificationFilter';
export const FILTER_ACTION = 'actionFilter';
export const FILTER_OBJRESP = 'objRespFilter';

export const NOT_WASTE = 'NOT_WASTE';
export const OTHER = 'Other';

export function filterResult(code, filter) {
    return !filter || code === filter;
}

export function filterMeasurementStatus(code, filter) {
    return !filter || code === filter;
}

export function filterFutureAction(code, filter, futureActions) {
    if (filter === NOT_WASTE) {
        return !['Waste', 'Waste::Declare', 'Waste::Receive'].includes(code);
    }

    if (filter === OTHER) {
        return !futureActions.pcode.includes(code);
    }

    return !filter || code === filter;
}

export function filterRadiationClassification(code, filter) {
    return !filter || code === filter;
}

export function filterObjResp(code, filter) {
    return !filter || code === filter;
}

export function getActionDropdownValues(translations) {
    return [
        { code: ACTION_ASK_FETCH, desc: translations.ASK_FETCH },
        { code: ACTION_ASK_WHERE, desc: translations.ASK_WHERE },
        { code: ACTION_CHANGE_LOCATION, desc: translations.CHANGE_LOC },
        { code: ACTION_DECLARE_NOT_PRESENT, desc: translations.DECL_NOT_PRES },
        { code: ACTION_NONE, desc: translations.NONE },
    ];
}

export function getResultDropdownValues(translations) {
    return [
        { code: EQP_PRESENT, desc: translations.PRESENT },
        { code: EQP_FOUND, desc: translations.FOUND },
        { code: EQP_NOT_PRESENT, desc: translations.NOT_PRESENT },
        { code: EQP_TO_CHECK, desc: translations.VERIFY },
    ];
}

export const isActionPossible = (inventoryItem, action) => {
    switch (action) {
        case ACTION_CHANGE_LOCATION: {
            return true;
        }

        case ACTION_NONE: {
            return true;
        }

        case ACTION_ASK_FETCH: {
            return inventoryItem.resultCode !== EQP_NOT_PRESENT && inventoryItem.responsible;
        }

        case ACTION_ASK_WHERE: {
            return inventoryItem.resultCode === EQP_NOT_PRESENT && inventoryItem.responsible;
        }

        case ACTION_DECLARE_NOT_PRESENT: {
            return inventoryItem.resultCode === EQP_NOT_PRESENT;
        }
        default:
            return true;
    }
};

export const getNumberOfEquipment = (data, status) => {
    if (!data) {
        return 0;
    }

    if (status === EQP_TOTAL) {
        return data.length;
    }

    return data.filter((element) => element.resultCode === status).length;
};
