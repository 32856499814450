/* eslint-disable no-underscore-dangle */

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { WizardStep } from 'amm-tools';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import {
    EQUIPMENT_DTO_KEYLIST,
    EQUIPMENT_KEYS,
    RPAR_REQUEST_KEYS,
    RPSM_KEYS,
    TABLE_DATA_TYPES,
    TREC_GENERAL_KEYS,
} from 'enums/Constants';
import React from 'react';
import BlockUi from 'react-block-ui';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import WSRPARequest from 'tools/rest/WSRPARequest';
import Tools from 'tools/Tools';
import { showError } from 'tools/TrecNotifications';

const styles = {
    inputFields: {
        fontSize: '14px',
        maxWidth: '80px',
    },
    descriptionInputField: {
        minWidth: '290px',
        fontSize: '14px',
    },
    selectFields: {
        minWidth: '140px',
        fontSize: '14px',
    },
    autocompleteFields: {
        minWidth: '210px',
        fontSize: '14px',
    },
    locationFields: {
        minWidth: '150px',
        fontSize: '14px',
    },
};

const isEquipmentDetached = (equipment) => !equipment[EQUIPMENT_KEYS.ATTACHED_TO] || equipment[EQUIPMENT_KEYS.DETACH];

class RPARUpdateEquipments extends WizardStep {
    tableColumns = [
        {
            code: RPSM_KEYS.ORIGIN,
            type: TABLE_DATA_TYPES.STATIC,
        },
        {
            code: EQUIPMENT_KEYS.EQUIPMENT_CODE,
            type: TABLE_DATA_TYPES.STATIC,
        },
        {
            code: EQUIPMENT_KEYS.SERIALNO,
            type: TABLE_DATA_TYPES.INPUT,
            fieldType: 'text',
        },
        {
            code: EQUIPMENT_KEYS.EQUIPMENT_DESC,
            type: TABLE_DATA_TYPES.INPUT,
            required: true,
            fieldType: 'text',
            validate: (value) => value && this.props.applicationData.rpeqpNewDesc.toUpperCase() !== value.toUpperCase(),
        },
        {
            code: EQUIPMENT_KEYS.RESP_TECHNIQUE,
            type: TABLE_DATA_TYPES.AUTOCOMPLETE,
            autocompleteType: WSAutocomplete.autocompleteEmployee,
            columnCodes: ['desc'],
            required: true,
            descKey: EQUIPMENT_KEYS.RESP_TECHNIQUE_DESC,
        },
        {
            code: EQUIPMENT_KEYS.LENGTH,
            type: TABLE_DATA_TYPES.INPUT,
            required: true,
            fieldType: 'number',
        },
        {
            code: EQUIPMENT_KEYS.WIDTH,
            type: TABLE_DATA_TYPES.INPUT,
            required: true,
            fieldType: 'number',
        },
        {
            code: EQUIPMENT_KEYS.HEIGHT,
            type: TABLE_DATA_TYPES.INPUT,
            required: true,
            fieldType: 'number',
        },
        {
            code: EQUIPMENT_KEYS.WEIGHT,
            type: TABLE_DATA_TYPES.INPUT,
            required: true,
            fieldType: 'number',
        },
        {
            code: EQUIPMENT_KEYS.EQUIPMENT_VALUE,
            type: TABLE_DATA_TYPES.INPUT,
            required: true,
            fieldType: 'number',
        },
        {
            code: EQUIPMENT_KEYS.OBJ_FAMILY,
            type: TABLE_DATA_TYPES.SELECT,
            values: this.props.screenData.lists.objFamily,
            required: true,
        },
        {
            code: EQUIPMENT_KEYS.MATERIAL,
            type: TABLE_DATA_TYPES.SELECT,
            values: this.props.screenData.lists.objMainMaterial,
            required: true,
        },
        {
            code: EQUIPMENT_KEYS.OBJ_LOCATION,
            type: TABLE_DATA_TYPES.AUTOCOMPLETE,
            autocompleteType: WSAutocomplete.autocompleteLocation,
            required: true,
            columnCodes: ['code'],
            isEditable: isEquipmentDetached,
        },
        {
            code: EQUIPMENT_KEYS.LAST_LOCATION_CODE,
            type: TABLE_DATA_TYPES.AUTOCOMPLETE,
            autocompleteType: WSAutocomplete.autocompleteLocation,
            columnCodes: ['code'],
            isEditable: (eq) => isEquipmentDetached(eq) && !eq._oldLastLocation && !eq._oldLocation,
        },
        {
            code: EQUIPMENT_KEYS.DETACH,
            type: TABLE_DATA_TYPES.CHECKBOX,
            isVisible: (equipment) =>
                !!equipment[EQUIPMENT_KEYS.ATTACHED_TO] && !equipment[EQUIPMENT_KEYS.RW_RECEPTION_DATE],
        },
    ];

    state = {
        copyOnFirstLine: false,
        equipmentMap: {},
        tableColumns: [],
        loading: true,
        showDetachWarningDialog: false,
        detachWarningAccepted: false,
    };

    equipmentToUpdate = new Set();

    formFields = [];

    validate = () => {
        let isValid = true;
        const { getTranslation } = this.props;
        Object.keys(this.formFields).forEach((key) => {
            if (this.formFields[key] && this.formFields[key].validate && !this.formFields[key].validate()) {
                isValid = false;
                showError(getTranslation(TREC_GENERAL_KEYS.REQUIRED_ERROR_MESSAGE), null, 4000);
            }
        });
        return isValid;
    };

    canContinue = () => {
        const { detachWarningAccepted, equipmentMap } = this.state;
        const validated = this.validate();
        if (!validated) return validated;
        const equipmentNotDetachedAndNotRwWasteExists = Object.values(equipmentMap).some(
            (e) =>
                e[EQUIPMENT_KEYS.OBJ_PARENT_CODE] && !e[EQUIPMENT_KEYS.DETACH] && !e[EQUIPMENT_KEYS.RW_RECEPTION_DATE]
        );
        if (equipmentNotDetachedAndNotRwWasteExists && !detachWarningAccepted) {
            this.handleDetachWarningDialogOpen();
            return detachWarningAccepted;
        }
        return validated;
    };

    UNSAFE_componentWillMount() {
        this.init(this.props);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.init(props);
    }

    init = (props) => {
        this.setState({
            equipmentMap: props.rparGetters.getEquipments(),
            copyOnFirstLine: false,
            loading: false,
        });
    };

    saveChanges = () => {
        this.updateEquipmentDefaults().then((equipmentMap) =>
            this.props.rparActions.updateRPAREquipments(equipmentMap)
        );
        return true;
    };

    commitChanges = (callback) => {
        callback();
    };

    updateEquipmentDefaults = () => {
        const { equipmentMap } = this.state;
        return Promise.all(
            Object.keys(equipmentMap)
                .filter(
                    (eqCode) => equipmentMap[eqCode][EQUIPMENT_KEYS.OBJ_LOCATION]
                    // !equipmentMap[eqCode][EQUIPMENT_KEYS.FACILITY]
                    // || ! equipmentMap[eqCode][EQUIPMENT_KEYS.RPO]
                )
                .map((eqCode) => WSRPARequest.getLocationDefaults(equipmentMap[eqCode][EQUIPMENT_KEYS.OBJ_LOCATION]))
        ).then((response) => {
            const defMap = response
                .map((resp) => resp.body.data)
                .reduce((acc, data) => ({ ...acc, [data.location]: data }), {});

            return Promise.resolve(
                Tools.applyToFieldsKV(equipmentMap, (code, eq) => {
                    const def = defMap[eq[EQUIPMENT_KEYS.OBJ_LOCATION]] || {};
                    return {
                        ...eq,
                        [EQUIPMENT_KEYS.RPO]: eq[EQUIPMENT_KEYS.RPO] || def[EQUIPMENT_KEYS.RPO],
                        // [EQUIPMENT_KEYS.FACILITY]: eq[EQUIPMENT_KEYS.FACILITY] || def[EQUIPMENT_KEYS.FACILITY],
                        [EQUIPMENT_KEYS.FACILITIES]:
                            eq[EQUIPMENT_KEYS.FACILITIES] && eq[EQUIPMENT_KEYS.FACILITIES].length
                                ? eq[EQUIPMENT_KEYS.FACILITIES]
                                : !def[EQUIPMENT_KEYS.FACILITY]
                                ? []
                                : [def[EQUIPMENT_KEYS.FACILITY]],
                    };
                })
            );
        });
    };

    changeState = (key, value) => this.setState({ [key]: value });

    getTableData = (tableColumns, equipmentMap) =>
        // getRPEqpNewDesc
        Object.keys(equipmentMap).map((eqCode) => {
            const equipment = equipmentMap[eqCode];
            return tableColumns.reduce((acc, column) => {
                if (column.isVisible && !column.isVisible(equipment)) {
                    acc[column.code] = null;
                    return acc;
                }
                if (
                    column.code === EQUIPMENT_KEYS.EQUIPMENT_DESC &&
                    equipment[column.code] &&
                    equipment[column.code].toUpperCase() === this.props.applicationData.rpeqpNewDesc.toUpperCase()
                ) {
                    equipment[column.code] = '';
                }
                const readonly = column.isEditable && !column.isEditable(equipment);
                switch (column.type) {
                    case TABLE_DATA_TYPES.STATIC:
                        acc[column.code] = equipment[column.code];
                        break;
                    case TABLE_DATA_TYPES.INPUT:
                        acc[column.code] = this.getEAMInput(
                            eqCode,
                            equipment,
                            column.code,
                            column.required,
                            column.fieldType,
                            column.validate
                        );
                        break;
                    case TABLE_DATA_TYPES.AUTOCOMPLETE:
                        acc[column.code] = this.getEAMAutocomplete(
                            eqCode,
                            equipment,
                            column.code,
                            column.autocompleteType,
                            column.required,
                            '',
                            column.descKey,
                            readonly,
                            column.columnCodes
                        );
                        break;
                    case TABLE_DATA_TYPES.SELECT:
                        acc[column.code] = this.getEAMSelect(
                            eqCode,
                            equipment,
                            column.code,
                            column.values,
                            column.required
                        );
                        break;
                    case TABLE_DATA_TYPES.CHECKBOX:
                        acc[column.code] = this.getEAMCheckbox(eqCode, equipment, column.code, column.required);
                        break;
                    default:
                        break;
                }
                return acc;
            }, {});
        });

    getEAMCheckbox = (eqCode, equipment, valueKey, required) => (
        <EAMCheckbox
            elementInfo={{ attribute: required ? 'R' : 'O', xpath: `${eqCode}.${valueKey}` }}
            formFields={this.formFields}
            valueKey={valueKey}
            value={equipment[valueKey]}
            updateProperty={(key, value) => this.updateEquipment('equipmentMap', eqCode, key, value)}
            trueValue="true"
            falseValue={false}
        />
    );

    getEAMInput = (eqCode, equipment, valueKey, required, fieldType, validate = undefined) => (
        <EAMInput
            elementInfo={{ attribute: required ? 'R' : 'O', xpath: `${eqCode}.${valueKey}`, fieldType }}
            style={valueKey === EQUIPMENT_KEYS.EQUIPMENT_DESC ? styles.descriptionInputField : styles.inputFields}
            formFields={this.formFields}
            valueKey={valueKey}
            value={equipment[valueKey]}
            updateProperty={(key, value) => this.updateEquipment('equipmentMap', eqCode, key, value)}
            validate={validate}
        />
    );

    getEAMSelect = (eqCode, equipment, valueKey, values, required) => (
        <EAMSelect
            elementInfo={{ attribute: required ? 'R' : 'O', xpath: `${eqCode}.${valueKey}` }}
            values={values}
            style={styles.selectFields}
            formFields={this.formFields}
            valueKey={valueKey}
            value={equipment[valueKey]}
            updateProperty={(key, value) => this.updateEquipment('equipmentMap', eqCode, key, value)}
        />
    );

    getEAMAutocomplete = (
        eqCode,
        equipment,
        valueKey,
        autocompleteType,
        required,
        defaultValue = '',
        descKey,
        readonly,
        columnCodes
    ) => (
        <EAMAutocomplete
            elementInfo={{ attribute: required ? 'R' : 'O', xpath: `${eqCode}.${valueKey}`, readonly }}
            value={(descKey ? equipment[descKey] : equipment[valueKey]) || defaultValue}
            style={valueKey === EQUIPMENT_KEYS.RESP_TECHNIQUE ? styles.autocompleteFields : styles.locationFields}
            valueKey={valueKey}
            valueDesc=""
            descKey={descKey}
            formFields={this.formFields}
            updateProperty={(key, value) => {
                this.updateEquipment('equipmentMap', eqCode, key, value);

                if (key === EQUIPMENT_KEYS.LAST_LOCATION_CODE || key === EQUIPMENT_KEYS.OBJ_LOCATION) {
                    const objLoc =
                        key === EQUIPMENT_KEYS.OBJ_LOCATION
                            ? value
                            : this.state.equipmentMap[eqCode][EQUIPMENT_KEYS.OBJ_LOCATION];
                    const objLastLoc =
                        key === EQUIPMENT_KEYS.LAST_LOCATION_CODE
                            ? value
                            : this.state.equipmentMap[eqCode][EQUIPMENT_KEYS.LAST_LOCATION_CODE];

                    const oldObjLoc = this.state.equipmentMap[eqCode]._oldLocation;
                    const oldObjLastLoc = this.state.equipmentMap[eqCode]._oldLastLocation;
                    const newLastLoc =
                        !oldObjLoc && !oldObjLastLoc
                            ? objLastLoc
                            : objLoc !== oldObjLoc && objLastLoc !== oldObjLoc
                            ? oldObjLoc
                            : objLoc === oldObjLoc && objLastLoc !== oldObjLastLoc
                            ? oldObjLastLoc
                            : objLastLoc;

                    if (
                        !newLastLoc ||
                        newLastLoc === this.state.equipmentMap[eqCode][EQUIPMENT_KEYS.LAST_LOCATION_CODE]
                    )
                        return;

                    // Promise.all(
                    //     [WSRPARequest.getLocationFacility(newLastLoc),
                    //     WSRPARequest.getLocationRPO(newLastLoc)]
                    // )
                    //     .then(resp => {
                    //         let def = {
                    //             [EQUIPMENT_KEYS.FACILITY]: resp[0].body.data,
                    //             [EQUIPMENT_KEYS.RPO]: resp[1].body.data,

                    //         }
                    //         Tools.updateThisState.bind(this)(['equipmentMap', eqCode], def, true, false)
                    //     })
                    Tools.updateThisState.bind(this)(
                        ['equipmentMap', eqCode, EQUIPMENT_KEYS.LAST_LOCATION_CODE],
                        newLastLoc,
                        true,
                        false
                    );
                }
            }}
            columnsCodes={columnCodes}
            columnsWidth={['100%']}
            loadOptions={autocompleteType}
        />
    );

    updateEquipment = (stateCode, eqCode, key, value) => {
        if (EQUIPMENT_DTO_KEYLIST.includes(key) && this.state[stateCode][eqCode][key] !== value)
            this.equipmentToUpdate.add(eqCode);
        Tools.updateState(
            this,
            stateCode,
            eqCode,
            key,
            value,
            this.props.rparGetters.getAnalysisRequestProperties()[RPAR_REQUEST_KEYS.APPLY_ALL_LINES]
        );
    };

    handleDetachWarningDialogOpen = () => {
        this.setState({ showDetachWarningDialog: true });
    };

    handleDetachWarningDialogClose = () => {
        this.setState({
            showDetachWarningDialog: false,
            detachWarningAccepted: true,
        });
    };

    render() {
        const { equipmentMap, loading, showDetachWarningDialog } = this.state;
        const { getTranslation } = this.props;

        const properties = this.props.rparGetters.getAnalysisRequestProperties();
        const updateProperties = (key, value) => {
            this.props.rparActions.updateRPAREquipments(equipmentMap);
            this.props.rparActions.updateRPARequest({
                [key]: value,
            });
        };

        const tableLayout = this.tableColumns.map((e) => ({
            ...e,
            header: getTranslation(e.code),
        }));
        const tableHeaders = tableLayout.map((e) => e.header);
        const tableCodes = tableLayout.map((e) => e.code);
        const tableData = this.getTableData(tableLayout, equipmentMap);

        return (
            <EISPanel
                heading={getTranslation(RPAR_REQUEST_KEYS.SAMPLEREVIEW)}
                alwaysExpanded
                style={{ width: '100%', height: 'auto' }}
            >
                {loading ? (
                    <BlockUi tag="div" blocking>
                        <div>Please wait...</div>
                    </BlockUi>
                ) : (
                    <div
                        style={{
                            height: '60vh',
                            display: 'flex',
                            flexWrap: 'nowrap',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                        <div style={{ flexGrow: '1', width: '100%' }}>
                            <EAMCheckbox
                                elementInfo={{
                                    label: 'OLabel',
                                    attribute: 'O',
                                    text: getTranslation(RPAR_REQUEST_KEYS.COPYCHECKBOX),
                                }}
                                valueKey={RPAR_REQUEST_KEYS.APPLY_ALL_LINES}
                                value={properties[RPAR_REQUEST_KEYS.APPLY_ALL_LINES]}
                                updateProperty={updateProperties}
                                trueValue
                                falseValue={false}
                            />
                        </div>
                        <div style={{ flexGrow: '20', width: '100%', overflowX: 'auto', height: 'auto' }}>
                            <EISTable
                                data={tableData}
                                headers={tableHeaders}
                                propCodes={tableCodes}
                                maxMobileSize={600}
                                style={{ width: 'auto', height: 'auto' }}
                            />
                        </div>
                    </div>
                )}
                <Dialog open={showDetachWarningDialog}>
                    <DialogTitle>Equipment not detached from parent</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            There is equipment not being detached from their parents. Have in mind that if an equipment
                            is not detached from its parent, its location will remain the same.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDetachWarningDialogClose} color="primary" autoFocus>
                            I understand
                        </Button>
                    </DialogActions>
                </Dialog>
            </EISPanel>
        );
    }
}

export default RPARUpdateEquipments;
