import { EQUIPMENT_KEYS } from '../rwprocessing/RWPConstants';

export const ELIMINATION_REQUEST_KEYS = {
    PERFORM_TYPE: 'performType',
    EQUIPMENT: 'equipment',
    CONTENT: 'content',
};

export const EQUIPMENT_KEYS_DTO = [
    EQUIPMENT_KEYS.CODE,
    EQUIPMENT_KEYS.ELIMINATION_PROCESS,
    EQUIPMENT_KEYS.ELIMINATION_PERFORMED_BY,
    EQUIPMENT_KEYS.RW_ELIMINATION_DATE,
    EQUIPMENT_KEYS.LOCATION,
    EQUIPMENT_KEYS.SERIALNO,
    EQUIPMENT_KEYS.ASSIGNED_TO,
    EQUIPMENT_KEYS.RESPONSIBLE,
    EQUIPMENT_KEYS.USER_STATUS,
    EQUIPMENT_KEYS.RW_ZONE,
    EQUIPMENT_KEYS.RW_SUBZONE,
    EQUIPMENT_KEYS.DEPARTMENT,
];

export const ELIMINATION_REQUEST_KEYS_DTO = [
    ELIMINATION_REQUEST_KEYS.PERFORM_TYPE,
    ELIMINATION_REQUEST_KEYS.EQUIPMENT,
    ELIMINATION_REQUEST_KEYS.CONTENT,
];

export const ELIMINATION_TRANSLATION_KEYS = {
    ELIMINATE: 'ELIMINATE',
    ELIMINATION_TITLE: 'ELIMINATION',
    SINGLE_EQUIPMENT: 'SINGLE_EQUIPMENT',
    CONTAINER_EQUIPMENT: 'CONTAINER_EQUIPMENT',
    BACK_TO_EDIT: 'BACK_TO_EDIT',
    ELIMINATION_SUCCESS_MSG: 'ELIMINATION_SUCCESS_MSG',
    OPEN_RPM: 'OPEN_RPM',
    ADD_REPORT: 'ADD_REPORT',
    CREATE_RPM_REQUEST: 'CREATE_RPM_REQUEST',
};

export const PERFORM_TYPES = {
    CONTENT_ONLY: 'CONTENT_ONLY',
    CONTAINER_AND_CONTENT: 'CONTAINER_AND_CONTENT',
};
