import { Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import CommentsContainer from 'eam-components/dist/ui/components/comments/CommentsContainer';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import { TABLE_DATA_TYPES } from 'enums/Constants';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import WSComments from 'tools/rest/WSWRComments';
import { ATTRIBUTES } from 'ui/pages/rpmrequest2/InputGenerator';
import { TRANSLATION_KEYS, WATER_RELEASE_KEYS, WRR_STATUS } from '../WRRConstants';
import { WRRAssociatedWOsInfoPanel, fieldGenerator, generateWRRFields, getWRRAllFields } from '../WRRHelpers';
import WRRDetailsPanel from './components/WRRDetailsPanel';
import { showError } from 'tools/TrecNotifications';

const APPROVE = 'APPROVE';
const REJECT = 'REJECT';

class WRRDecisionStep extends WizardStep {
    formFields = [];

    state = {};

    componentDidMount() {
        super.componentDidMount();
        this.props.setPage('WRP_DECISION');
    }

    /**
     * Can continue to the next step on the wizard
     */
    canContinue = () => {
        const isValid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
        if (!isValid) {
            showError('One or more fields are not valid.');
        }
        return isValid;
    };

    /**
     * SaveChanges is used to save the localized state to the redux-store. It can only run synchronous methods.
     */
    saveChanges = () => true;

    handleSubmit = () => {
        const { updateWRR } = this.props;
        return (
            this.canContinue() &&
            updateWRR({
                [WATER_RELEASE_KEYS.STATUS]:
                    this.state.DECISION === APPROVE ? WRR_STATUS.APPROVED : WRR_STATUS.NOT_APPROVED,
            })
        );
    };

    /**
     *  CommitChanges is used to trigger any side effects of the step, such as invocations to the back-end to
     * retrieve information about resources. This method can run asynchronous methods/requests but SHALL execute
     * the callback method afterwards to let the inform the wizard.
     */
    commitChanges = async (callback) => {
        callback();
    };

    /**
     * On true, the wizard automatically jumps this step immediately after it is mounted
     */
    canJumpStep = () => false;

    renderInputs = (writeAccess) => {
        const { storeActions, wrrGetters, getTranslation, dropdowns, screenData } = this.props;
        const properties = wrrGetters.getProperties();

        return generateWRRFields({
            fieldRefs: this.formFields,
            fields: [
                {
                    code: WATER_RELEASE_KEYS.SAMPLE_TO_BE_RETRIEVED,
                    type: TABLE_DATA_TYPES.SELECT,
                    elementInfo: screenData.customFieldsWRM.P296,
                    values: dropdowns[WATER_RELEASE_KEYS.SAMPLE_TO_BE_RETRIEVED],
                    getAttribute: () => ATTRIBUTES.REQUIRED,
                },
                {
                    code: 'DECISION',
                    type: TABLE_DATA_TYPES.SELECT,
                    translationKey: 'DECISION',
                    getAttribute: () => ATTRIBUTES.REQUIRED,
                    values: [APPROVE, REJECT].map((code) => ({ code, desc: getTranslation(code) })),
                },
            ],
            properties,
            storeActions,
            wrrGetters,
            writeAccess,
            getTranslation,
        });
    };

    renderActionFields = () => {
        const { storeActions, wrrGetters, screenData, getTranslation } = this.props;
        const properties = wrrGetters.getProperties();

        return generateWRRFields({
            fieldRefs: this.formFields,
            fields: getWRRAllFields({ ...screenData, getTranslation }),
            properties,
            storeActions,
            wrrGetters,
            writeAccess: false,
            getTranslation,
        });
    };

    render() {
        const { getTranslation, writeAccess, wrrGetters, userData, screenData, dropdowns, lang, storeActions } =
            this.props;

        const wrrRequest = wrrGetters.getProperties();
        const status = wrrRequest[WATER_RELEASE_KEYS.STATUS];

        return (
            <div style={{ margin: 8 }}>
                <Grid container spacing={8}>
                    <Grid item md={6} sm={12} xs={12}>
                        <EISPanel
                            detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                            heading={getTranslation(TRANSLATION_KEYS.ASSESSMENT)}
                            alwaysExpanded
                        >
                            <EAMSelect
                                elementInfo={{
                                    attribute: 'R',
                                    xpath: `DECISION`,
                                    readonly: !writeAccess,
                                    text: getTranslation('DECISION'),
                                }}
                                formFields={this.formFields}
                                values={[APPROVE, REJECT].map((code) => ({ code, desc: getTranslation(code) }))}
                                valueKey="DECISION"
                                value={
                                    [WRR_STATUS.APPROVED, WRR_STATUS.RELEASED].includes(status)
                                        ? APPROVE
                                        : status === WRR_STATUS.NOT_APPROVED
                                        ? REJECT
                                        : this.state.DECISION || ''
                                }
                                updateProperty={(key, val) => {
                                    this.setState({ [key]: val });
                                }}
                            />
                            {fieldGenerator({
                                fieldRefs: this.formFields,
                                field: {
                                    code: WATER_RELEASE_KEYS.SAMPLE_TO_BE_RETRIEVED,
                                    type: TABLE_DATA_TYPES.SELECT,
                                    elementInfo: screenData.customFieldsWRM.P296,
                                    values: dropdowns[WATER_RELEASE_KEYS.SAMPLE_TO_BE_RETRIEVED],
                                    getAttribute: () => ATTRIBUTES.REQUIRED,
                                },
                                writeAccess,
                                properties: wrrRequest,
                                storeActions,
                                wrrGetters,
                                getTranslation,
                            })}
                            <WRRAssociatedWOsInfoPanel
                                jobStatuses={screenData.lists.jobStatuses}
                                properties={wrrRequest}
                                getTranslation={getTranslation}
                                dropdowns={dropdowns}
                                lang={lang}
                            />
                        </EISPanel>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <WRRDetailsPanel
                            jobStatuses={screenData.lists.jobStatuses}
                            getTranslation={getTranslation}
                            values={wrrRequest}
                            lang={lang}
                        />
                        <CommentsContainer
                            title={getTranslation('COMMENTS')}
                            entityCode="EVNT"
                            entityKeyCode={wrrRequest[WATER_RELEASE_KEYS.WR_NUMBER]}
                            userDesc={userData.eamAccount.userDesc}
                            createComment={(comm) =>
                                WSComments.createComment({ ...comm, text: `Decision: ${comm.text}` })
                            }
                            readComments={WSComments.readComments}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default WRRDecisionStep;
