import WS from './WS';

/**
 * Calls to rest services for the Radioactive Waste Management Module
 */
class WSRWManagement {
    //
    // DROP DOWN VALUES FOR WOS
    //

    getWasteLocations(config = {}) {
        return WS._get(`/rwmanagement/wastelocation`, config);
    }

    getWasteZones(wasteLocation, config = {}) {
        return WS._get(`/rwmanagement/wastezones?wasteLocation=${wasteLocation}`, config);
    }

    getWasteSubzones(wasteLocation, wasteZone, config = {}) {
        return WS._get(`/rwmanagement/wastesubzones?wasteLocation=${wasteLocation}&wasteZone=${wasteZone}`, config);
    }

    updateWasteEquipment(wasteEquipment, config = {}) {
        return WS._put(`/rwmanagement/`, wasteEquipment, config);
    }

    getWasteEquipment(equipmentCode, config = {}) {
        return WS._get(`/rwmanagement/wasteEquipment/?equipmentCode=${equipmentCode}`, config);
    }

    getTopParentChildren(equipmentCode, config = {}) {
        return WS._get(`/rwmanagement/${equipmentCode}/structure`, config);
    }

    async addHazards(hazardOp, config = {}) {
        const resp = await WS._post(`/rwmanagement/hazards`, hazardOp, config);
        return resp.body.data;
    }

    async removeHazards(hazardOp, config = {}) {
        const resp = await WS._post(`/rwmanagement/hazards/delete`, hazardOp, config);
        return resp.body.data;
    }
}

export default new WSRWManagement();
