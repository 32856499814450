import React, { useReducer, useState } from 'react';
import { Grid, Checkbox } from '@material-ui/core';
import EISTable from 'react-eis-components/dist/ui/components/table';
import EDMSUploadDropZone from 'ui/components/documents/EDMSUploadDropZone';
import EDMSLinkDocument from 'ui/components/documents/EDMSLinkDocument';
import EDMSDoclightContainer from '../iframes/EDMSDoclightIframeContainer';
import { handleError, showSuccess } from 'tools/TrecNotifications';

const arrayReducer = (state, action) => {
    switch (action.type) {
        case 'ADD':
            return [...state, action.data];
        case 'REMOVE':
            return state.filter((val) => val !== action.data);
        case 'CLEAR':
            return [];
        case 'SET':
            return action.data;
        default:
            return state;
    }
};

/**
 *
 * @param columnHeadings string[] for the column headings
 * @param objects
 * @param {*} edmsDocListLink
 * @returns
 */
const EDMSMultilinkUpload = ({ edmsdocListLink, columnHeadings, context, profile, objects, getTranslation }) => {
    const [selectedObjects, dispatch] = useReducer(arrayReducer, []);

    const [iframeKey, setIframeKey] = useState(0);

    const columnHeadingsPlusSelect = ['select', ...columnHeadings];

    const getComponentFields = (objectsList = []) =>
        objectsList.filter(Boolean).map((object) => ({
            ...columnHeadingsPlusSelect.reduce(
                (acc, field) => ({
                    ...acc,
                    [field]: <div key={field}>{object[field]}</div>,
                }),
                {}
            ),
            select: (
                <Checkbox
                    checked={selectedObjects.includes(object.code)}
                    onChange={(_, checked) => dispatch({ type: checked ? 'ADD' : 'REMOVE', data: object.code })}
                />
            ),
        }));

    return (
        <Grid container spacing={16} style={{ height: '100%' }}>
            <Grid item sm={5} style={{ position: 'relative' }}>
                <Grid container style={{ overflowY: 'auto', position: 'absolute', top: 0, bottom: 0, right: 5 }}>
                    {objects.length ? (
                        <Grid item xs={12} style={{ marginBottom: 16 }}>
                            <EISTable
                                data={getComponentFields(objects)}
                                headers={columnHeadingsPlusSelect.map((field) =>
                                    field === 'select' ? (
                                        <Checkbox
                                            onChange={(_, checked) =>
                                                dispatch({
                                                    type: checked ? 'SET' : 'CLEAR',
                                                    data: objects.map((e) => e.code),
                                                })
                                            }
                                        />
                                    ) : (
                                        getTranslation(field)
                                    )
                                )}
                                propCodes={columnHeadingsPlusSelect}
                            />
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
            <Grid item sm={7} style={{ position: 'relative' }}>
                <Grid
                    container
                    style={{
                        overflowY: 'auto',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 15,
                        overflowX: 'hidden',
                        scrollbarWidth: 'thin',
                    }}
                >
                    <Grid item xs={12} style={{ padding: 20 }}>
                        <EDMSUploadDropZone
                            objectIds={selectedObjects}
                            onSuccess={() => {
                                showSuccess('Upload successful!');
                                setIframeKey(iframeKey + 1);
                            }}
                            onFailure={handleError}
                            context={context}
                        />
                        {selectedObjects && selectedObjects.length > 0 && (
                            <div key={iframeKey} style={{ marginTop: 20 }}>
                                <EDMSDoclightContainer
                                    objectID={selectedObjects.join(',')}
                                    objectType="A"
                                    mode="read"
                                    edmsDocListLink={edmsdocListLink}
                                    profile={profile}
                                    title={`Documents ${context}`}
                                />
                            </div>
                        )}
                        {selectedObjects && selectedObjects.length > 0 && (
                            <EDMSLinkDocument
                                objectIds={selectedObjects}
                                onSuccess={() => {
                                    showSuccess('Link successful!');
                                    setIframeKey(iframeKey + 1);
                                }}
                                onFailure={handleError}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EDMSMultilinkUpload;
