import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import WSEDMS from 'tools/rest/WSEDMS';
import { Button, Paper, Dialog, Typography } from '@material-ui/core';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';

const baseStyle = {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#2196f3',
    borderStyle: 'dashed',
    backgroundColor: '#fff',
    color: '#bdbdbd',
    outline: 'none',
    cursor: 'pointer',
    transition: 'ease 0.2s',
    height: 100,
};

const acceptStyle = {
    borderColor: '#00e676',
};

const disabledStyle = {
    backgroundColor: '#fafafa',
    borderColor: '#ffcfcf',
    cursor: 'not-allowed',
};

const EDMSUploadDropZone = ({ objectIds = [], onSuccess, onFailure, context }) => {
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [files, setFiles] = useState(null);
    const [title, setTitle] = useState(null);
    const [isValid, setValid] = useState(false);
    const onDrop = async (dropedFiles) => {
        setDialogOpen(true);
        setFiles(dropedFiles);
    };

    const clearState = () => {
        setFiles(null);
        setTitle(null);
        setDialogOpen(false);
    };

    const onUpload = async () => {
        try {
            await WSEDMS.handleFileUpload(files, objectIds.join(','), 'A', 'EDMS_AGGREGATED', context, title);
            clearState();
            onSuccess?.();
        } catch (err) {
            onFailure?.(err);
        }
    };

    const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({
        onDrop,
        disabled: objectIds.length === 0,
    });

    const onDocumentTitleChange = (event) => {
        const { value } = event.target;
        setTitle(value);
        setValid(value !== null && value.trim() !== '');
    };

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragAccept ? acceptStyle : {}),
            ...(objectIds.length === 0 ? disabledStyle : {}),
        }),
        [isDragAccept, objectIds]
    );

    const objectIdsString =
        objectIds?.join(', ').length > 20
            ? `${objectIds?.slice(0, 2).join(', ')} and ${objectIds.length - 2} others`
            : objectIds?.join(', ');

    return (
        <>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {objectIds.length > 0 ? (
                    isDragActive ? (
                        <div>Accepting files for {objectIdsString}.</div>
                    ) : (
                        <div>Click or drag and drop to upload documents for {objectIdsString}.</div>
                    )
                ) : (
                    <div>Please select one or more assets on the right to upload to.</div>
                )}
            </div>
            <Dialog open={isDialogOpen} fullWidth maxWidth="xs">
                <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
                    <Typography variant="h5" style={{ marginBottom: '20px' }}>
                        Files
                    </Typography>
                    {files?.map((file) => (
                        <div key={file.id} style={{ marginBottom: '20px' }}>
                            <Typography variant="body1" style={{ marginBottom: '10px' }}>
                                {file.name}
                            </Typography>
                        </div>
                    ))}
                    <EAMInput required label={"Document's Title"} value={title} onChange={onDocumentTitleChange} />
                    <div style={{ marginTop: '20px' }}>
                        <Button
                            disabled={!isValid}
                            onClick={onUpload}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: '10px' }}
                        >
                            Upload
                        </Button>
                        <Button onClick={clearState} color="info" variant="contained">
                            Exit
                        </Button>
                    </div>
                </Paper>
            </Dialog>
        </>
    );
};

export default EDMSUploadDropZone;
