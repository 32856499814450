import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { AMMSingleAutocomplete, WizardStep } from 'amm-tools';
import { useMemo } from 'react';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import InputGenerator from 'ui/pages/rpmrequest2/InputGenerator';
import {
    VACUUM_RENTAL_KEYS,
    VACUUM_RENTAL_LINE_KEYS,
    VACUUM_RENTAL_STATUS,
} from 'ui/pages/vaccleanerrequest/VacCleanerConstants';
import VacuumOverviewPanel from '../components/VacuumOverviewPanel';
import { getVacCleanerLinesFields, VAC_TABLE_KEYS } from '../VacCleanerUtils';
import { showError } from 'tools/TrecNotifications';

const cellStyle = {
    textAlign: 'center',
    padding: '2px 10px 2px 10px',
    maxWidth: 180,
};

const tableFields = [
    VAC_TABLE_KEYS.DELETE_LINE,
    VACUUM_RENTAL_LINE_KEYS.STATUS,
    VACUUM_RENTAL_LINE_KEYS.ASSET_CODE,
    VAC_TABLE_KEYS.LOOKUP_ASSET_CODE,
    // VACUUM_RENTAL_LINE_KEYS.VAC_TYPE,
    VAC_TABLE_KEYS.VAC_DESC,
    VACUUM_RENTAL_LINE_KEYS.LOCATION,
    VACUUM_RENTAL_LINE_KEYS.START_DATE,
    VAC_TABLE_KEYS.DURATION,
    VACUUM_RENTAL_LINE_KEYS.END_DATE,
    VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES,
    VACUUM_RENTAL_LINE_KEYS.NOTIFICATION_ENABLED,
];

const VacCleanerAssignment = ({
    vacuumsData,
    labelStyle,
    match,
    vacuumRequest,
    storeActions,
    getTranslation,
    applicationData,
    valuesGrid,
}) => {
    const vacCleanerRequestNumber = match.params.vaccleanerNumber;
    const { vacCleanerRentalLines } = vacuumRequest;
    const status = vacuumRequest[VACUUM_RENTAL_KEYS.STATUS];

    const fields = useMemo(
        () =>
            getVacCleanerLinesFields(
                {
                    applicationData,
                    storeActions,
                    vacCleanerRequestNumber,
                    vacCleanerRentalLines,
                    labelStyle,
                    getTranslation,
                    editAsset: true,
                    vacuumsData,
                    valuesGrid,
                },
                tableFields
            ),
        [vacCleanerRentalLines, tableFields]
    );

    return (
        <Grid container spacing={0} style={{ marginBottom: '10px' }}>
            <Grid item xs={12} lg={9} xl={10} style={{ marginBottom: 20 }}>
                {status === VACUUM_RENTAL_STATUS.IN_PROGRESS && (
                    <AMMSingleAutocomplete
                        loadOptions={async ({ hint }, conf) => {
                            const opts = await WSAutocomplete.autocompleteVacuums(
                                {
                                    code: vacCleanerRequestNumber,
                                    hint: hint?.toUpperCase(),
                                },
                                conf
                            );
                            const alreadyUsed = vacCleanerRentalLines.map(
                                (vacRentalLine) => vacRentalLine[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE]
                            );
                            return opts.filter((opt) => !alreadyUsed.includes(opt.code));
                        }}
                        value={null}
                        getOptionLabel={(opt) => (opt.code ? `${opt.code} ${opt.description}` : '')}
                        getOptionValue={(opt) => opt.code}
                        placeholder={getTranslation('CHOOSE_ELIGIBLE_VACUUUMS')}
                        onChange={(opt) => {
                            opt && storeActions.assignVacuum(opt.code, opt.toolCode);
                        }}
                        style={labelStyle}
                    />
                )}
                <Table>
                    <TableHead>
                        <TableRow>
                            {fields.map((obj) => (
                                <TableCell key={`header${obj.code}`} style={cellStyle}>
                                    {getTranslation(obj.translationKey || obj.code)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {vacCleanerRentalLines.map((vacRentalLine) => (
                            <TableRow key={vacRentalLine.code}>
                                {fields.map((field) => (
                                    <TableCell style={cellStyle}>
                                        <div key={field.code}>
                                            {InputGenerator.generate({
                                                key: `${field.code}#${vacRentalLine[VACUUM_RENTAL_LINE_KEYS.CODE]}`,
                                                field: {
                                                    ...field,
                                                    elementInfo: {
                                                        xpath: field.code,
                                                        text: getTranslation(field.translationKey),
                                                    },
                                                },
                                                updateObject: (_key, value) =>
                                                    storeActions.updateVacRentalLine(
                                                        vacRentalLine[VACUUM_RENTAL_LINE_KEYS.CODE],
                                                        { [field.code]: value }
                                                    ),
                                                object: vacRentalLine,
                                                ...field,
                                            })}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12} lg={3} xl={2} style={{ marginBottom: 20 }}>
                <VacuumOverviewPanel getTranslation={getTranslation} vacuumRequest={vacuumRequest} />
            </Grid>
        </Grid>
    );
};

class VacCleanerAssignmentStep extends WizardStep {
    formFields = [];

    prevValues = null;

    state = {
        isNewEquipment: false,
        newEquipment: {},
    };

    handleSubmit = () => {
        this.canContinue();
    };

    handleSave = () => {
        this.canContinue();
    };

    /**
     * Can continue to the next step on the wizard
     */
    canContinue = () => {
        const isValid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
        if (!isValid) {
            showError('One or more fields are not valid.');
        }

        return isValid;
    };

    /**
     * SaveChanges is used to save the localized state to the redux-store. It can only run synchronous methods.
     */
    saveChanges = () => true;

    /**
     *  CommitChanges is used to trigger any side effects of the step, such as invocations to the back-end to
     * retrieve information about resources. This method can run asynchronous methods/requests but SHALL execute
     * the callback method afterwards to inform the wizard.
     */
    commitChanges = async (callback) => {
        callback();
    };

    /**
     * On true, the wizard automatically jumps this step immediately after it is mounted
     */
    canJumpStep = () => false;

    render() {
        return <VacCleanerAssignment formFields={this.formFields} {...this.props} />;
    }
}

export default VacCleanerAssignmentStep;
