import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import { useMemo } from 'react';
import WSVacRental from 'tools/rest/WSVacRental';
import InputGenerator from 'ui/pages/rpmrequest2/InputGenerator';
import { VACUUM_RENTAL_LINE_KEYS } from 'ui/pages/vaccleanerrequest/VacCleanerConstants';
import VacuumOverviewPanel from '../components/VacuumOverviewPanel';
import { getVacCleanerLinesFields, VAC_TABLE_KEYS } from '../VacCleanerUtils';
import { showError } from 'tools/TrecNotifications';

const cellStyle = {
    textAlign: 'center',
    padding: '2px 10px 2px 10px',
    maxWidth: 180,
};

const tableFields = [
    VACUUM_RENTAL_LINE_KEYS.STATUS,
    VACUUM_RENTAL_LINE_KEYS.ASSET_CODE,
    VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES,
    VACUUM_RENTAL_LINE_KEYS.ISSUED_DATE,
    VACUUM_RENTAL_LINE_KEYS.RETURNED_DATE,
    VACUUM_RENTAL_LINE_KEYS.REPLACEMENT_REASON,
    VACUUM_RENTAL_LINE_KEYS.RETURN_RPM_CODE,
    VAC_TABLE_KEYS.RETURNED_RPM_STATUS,
    VACUUM_RENTAL_LINE_KEYS.RETURN_RPM_CHECKLIST_ANSWERS,
    VAC_TABLE_KEYS.RPM_MAX_DOSE_RATE,
    VAC_TABLE_KEYS.GENERATE_EDH,
    VACUUM_RENTAL_LINE_KEYS.TOTAL_COST,
    VAC_TABLE_KEYS.COMPLETE_LINE,
];

const VacCleanerReturned = ({
    labelStyle,
    match,
    vacuumRequest,
    storeActions,
    getTranslation,
    callWs,
    loadingAround,
    blockAndCallWs,
    reload,
    adminMode,
}) => {
    const vacCleanerRequestNumber = match.params.vaccleanerNumber;
    const { vacCleanerRentalLines } = vacuumRequest;
    // const status = vacuumRequest[VACUUM_RENTAL_KEYS.STATUS];

    const completeLine = ({ reservationCode }) =>
        blockAndCallWs(
            WSVacRental.declareVacuumCompleteReservation,
            [
                {
                    vacRentalRequest: vacuumRequest,
                    reservationCode,
                },
            ],
            (data, success) => {
                reload();
                success(data);
            }
        );

    const fields = useMemo(
        () =>
            getVacCleanerLinesFields(
                {
                    storeActions,
                    vacCleanerRequestNumber,
                    vacCleanerRentalLines,
                    labelStyle,
                    vacuumRequest,
                    getTranslation,
                    callWs,
                    loadingAround,
                    blockAndCallWs,
                    reload,
                    completeLine,
                    adminMode,
                },
                tableFields
            ),
        [vacCleanerRentalLines, tableFields]
    );

    return (
        <>
            <Grid container spacing={0} style={{ marginBottom: '10px' }}>
                <Grid item xs={12} lg={9} xl={10} style={{ marginBottom: 20 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {fields.map((obj) => (
                                    <TableCell key={`header${obj.code}`} style={cellStyle}>
                                        {getTranslation(obj.translationKey || obj.code)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {vacCleanerRentalLines.map((vacRentalLine) => (
                                <TableRow key={vacRentalLine.code}>
                                    {fields.map((field) => (
                                        <TableCell key={`content#${field.code}`} style={cellStyle}>
                                            <div key={field.code}>
                                                {InputGenerator.generate({
                                                    key: `${field.code}#${vacRentalLine[VACUUM_RENTAL_LINE_KEYS.CODE]}`,
                                                    field: {
                                                        ...field,
                                                        elementInfo: {
                                                            xpath: field.code,
                                                            text: getTranslation(field.translationKey),
                                                        },
                                                    },
                                                    object: vacRentalLine,
                                                    updateObject: (_key, value) =>
                                                        storeActions.updateVacRentalLine(
                                                            vacRentalLine[VACUUM_RENTAL_LINE_KEYS.CODE],
                                                            { [field.code]: value }
                                                        ),
                                                    ...field,
                                                })}
                                            </div>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12} lg={3} xl={2} style={{ marginBottom: 20 }}>
                    <VacuumOverviewPanel getTranslation={getTranslation} vacuumRequest={vacuumRequest} />
                </Grid>
            </Grid>
        </>
    );
};

class VacCleanerReturnedStep extends WizardStep {
    formFields = [];

    prevValues = null;

    state = {
        isNewEquipment: false,
        newEquipment: {},
    };

    handleSubmit = () => {
        this.canContinue();
    };

    handleSave = () => {
        this.canContinue();
    };

    /**
     * Can continue to the next step on the wizard
     */
    canContinue = () => {
        const isValid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
        if (!isValid) {
            showError('One or more fields are not valid.');
        }

        return isValid;
    };

    /**
     * SaveChanges is used to save the localized state to the redux-store. It can only run synchronous methods.
     */
    saveChanges = () => true;

    /**
     *  CommitChanges is used to trigger any side effects of the step, such as invocations to the back-end to
     * retrieve information about resources. This method can run asynchronous methods/requests but SHALL execute
     * the callback method afterwards to inform the wizard.
     */
    commitChanges = async (callback) => {
        callback();
    };

    /**
     * On true, the wizard automatically jumps this step immediately after it is mounted
     */
    canJumpStep = () => false;

    render() {
        return <VacCleanerReturned formFields={this.formFields} {...this.props} />;
    }
}

export default VacCleanerReturnedStep;
