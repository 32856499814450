import * as ACTIONS from 'actions/wrrActions';
import Tools from 'tools/Tools';
import { WATER_RELEASE_KEYS } from 'ui/pages/wrrequest/WRRConstants';

export const WRR = 'WRR';

const getInitialState = ({ userData }) => ({
    blockUI: false,
    createdWRRs: false,
    properties: {
        [WATER_RELEASE_KEYS.REQUESTER]: {
            cernId: userData?.eamAccount?.employeeCode,
        },
        [WATER_RELEASE_KEYS.REQUESTED_RELEASE_DATE]: (() => {
            const date = new Date();
            date.setDate(date.getDate() + 7);
            return date;
        })(),
    },
});

export default (state = getInitialState({}), action) => {
    switch (action.type) {
        case ACTIONS.RESET_WRR:
            return getInitialState(action.value);
        case ACTIONS.UPDATE_WRR_BLOCK_UI:
            return {
                ...state,
                blockUI: action.value,
            };
        case ACTIONS.UPDATE_WRR:
            return {
                ...state,
                properties: {
                    ...state.properties,
                    ...action.value,
                },
            };
        case ACTIONS.UPDATE_WRR_PARTIAL:
            return {
                ...state,
                properties: {
                    ...state.properties,
                    [action.path]: {
                        ...state.properties[action.path],
                        ...action.value,
                    },
                },
            };
        case ACTIONS.UPDATE_CREATED_WRRS:
            return {
                ...state,
                createdWRRs: action.value,
            };
        default:
            return state;
    }
};

const getProperties = (state) => () => state.properties;

const isBlockUI = (state) => () => state.blockUI;

const getCreatedWRRs = (state) => () => state.createdWRRs;

const wrrGetters = {
    getProperties,
    isBlockUI,
    getCreatedWRRs,
};

export const wrrGettersWithState = (state) => state && Tools.applyToFields(wrrGetters, (func) => func(state[WRR]));
