import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DoneAll from '@material-ui/icons/DoneAll';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EAMLookup from 'eam-components/dist/ui/components/lookup/EAMLookup';
import { FilterRemoveOutline } from 'mdi-material-ui';
import moment from 'moment';
import { DATE_FORMAT } from '../../../enums/Constants';
import { getHazardsFromEAMGridRow } from '../../../tools/Hazards';
import Tools from '../../../tools/Tools';
import WSAutocomplete from '../../../tools/rest/WSAutocomplete';
import TRECButton from '../../components/buttons/TRECButton';
import EquipmentHazardContainer from '../../components/eqphazard/EquipmentHazardContainer';
import EAMGridNoOverflow from '../../components/grid/EAMGridNoOverflow';
import gridNoOverflowClasses from '../../components/grid/EAMGridNoOverflow.module.css';
import SearchPanel from '../../components/searchpanel/SearchPanel';
import SearchPanelColumn from '../../components/searchpanel/SearchPanelColumn';
import SearchPanelControls from '../../components/searchpanel/SearchPanelControls';
import TRECSearchPage from '../TRECSearchPage';

const labelStyleRight = {
    width: 'auto',
    minWidth: 120,
    textAlign: 'right',
};

/**
 * Search page for RP Measurements
 */
class RPMeasurements extends TRECSearchPage {
    componentWillUnmount() {
        document.removeEventListener('keydown', this.performSearchOnEnterKey.bind(this), false);
    }

    performSearchOnEnterKey(event) {
        this.onKeyDown(event);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.performSearchOnEnterKey.bind(this), false);
        // Parent mount
        super.componentDidMount();
        // Check parameters in the URL
        this._assignRequestParameters();
    }

    _assignRequestParameters = () => {
        const { bufferZone } = this.props;
        const eqpLocation = Tools.getURLParameterByName('eqpLocation');
        let jobLocation = Tools.getURLParameterByName('jobLocation');
        const equipment = Tools.getURLParameterByName('equipment');
        const assetClass = Tools.getURLParameterByName('asset_class');
        const cadraCheckPresent = Tools.getURLParameterByName('cadra_check_present');
        const jobNumber = Tools.getURLParameterByName('job_number');
        const created = Tools.getURLParameterByName('created');
        const jobStatus = Tools.getURLParameterByName('status');
        const jobFutureAction = Tools.getURLParameterByName('job_future_action');
        const jobDepartment = Tools.getURLParameterByName('department');

        // Clean all field if request parameters exist
        const isParameterProvided =
            eqpLocation ||
            jobLocation ||
            equipment ||
            assetClass ||
            cadraCheckPresent ||
            jobNumber ||
            created ||
            jobStatus ||
            jobFutureAction ||
            jobDepartment;
        if (isParameterProvided) {
            this.updateProperty('eqpLocation', '', 'BEGINS', 'VARCHAR', true);
            this.updateProperty('jobLocation', '', 'BEGINS', 'VARCHAR', true);
            this.updateProperty('asset_code', '', 'BEGINS', 'VARCHAR');
            this.updateProperty('asset_class', '', 'IN', 'VARCHAR');
            this.updateProperty('cadra_check_present', '', 'EQUALS', 'VARCHAR');
            this.updateProperty('job_number', '', 'EQUALS', 'VARCHAR');
            this.updateProperty('created', '', 'GREATER_THAN', 'VARCHAR');
            this.updateProperty('job_status', '', 'EQUALS', 'VARCHAR');
            this.updateProperty('job_future_action', '', 'EQUALS', 'VARCHAR');
            this.updateProperty('job_department', '', 'EQUALS', 'VARCHAR');
        }

        // Apply defaults
        jobLocation = jobLocation || (bufferZone && bufferZone.locationCode);
        // apply the real parameters
        if (eqpLocation) {
            this.updateProperty('eqpLocation', eqpLocation, 'BEGINS', 'VARCHAR', true);
        }

        if (jobLocation) {
            this.updateProperty('jobLocation', jobLocation, 'BEGINS', 'VARCHAR', true);
        }

        if (equipment) {
            this.updateProperty('asset_code', equipment, 'BEGINS', 'VARCHAR');
        }

        if (assetClass) {
            this.updateProperty('asset_class', assetClass, 'IN', 'VARCHAR');
        }

        if (cadraCheckPresent) {
            this.updateProperty('cadra_check_present', cadraCheckPresent, 'EQUALS', 'VARCHAR');
        }

        if (jobNumber) {
            this.updateProperty('job_number', jobNumber, 'EQUALS', 'VARCHAR');
        }

        if (created) {
            this.updateProperty('created', created, 'GREATER_THAN', 'VARCHAR');
        }

        if (jobStatus) {
            this.updateProperty('job_status', jobStatus, 'EQUALS', 'VARCHAR');
        }

        if (jobFutureAction) {
            this.updateProperty('job_future_action', jobFutureAction, 'EQUALS', 'VARCHAR');
        }

        if (jobDepartment) {
            this.updateProperty('job_department', jobDepartment, 'EQUALS', 'VARCHAR');
        }
    };

    _hiddenColumns = () => {
        let hiddenCols = [];

        switch (this.props.lang) {
            case 'EN':
                hiddenCols = [
                    'asset_class',
                    'asset_locisbz',
                    'asset_responsible',
                    'asset_toplocation',
                    'job_department',
                    'job_executedby_desc',
                    'job_future_action',
                    'job_future_action_desc_fr',
                    'job_locisbz',
                    'job_notigroup',
                    'job_number',
                    'job_reportedby',
                    'job_status',
                    'job_status_desc_fr',
                    'job_toplocation',
                    'associated_risks',
                    'edh_document',
                    'equipment_destination',
                    'equipment_destination_rad',
                    'storage_operation',
                    'transport_required',
                    'type_of_transport',
                    'cadra_check_present',
                    'asset_sitelocation',
                    'job_sitelocation',
                ];
                break;
            case 'FR':
                hiddenCols = [
                    'asset_class',
                    'asset_locisbz',
                    'asset_responsible',
                    'asset_toplocation',
                    'job_department',
                    'job_executedby_desc',
                    'job_future_action',
                    'job_future_action_desc_en',
                    'job_locisbz',
                    'job_notigroup',
                    'job_number',
                    'job_reportedby',
                    'job_status',
                    'job_status_desc_en',
                    'job_toplocation',
                    'associated_risks',
                    'edh_document',
                    'equipment_destination',
                    'equipment_destination_rad',
                    'storage_operation',
                    'transport_required',
                    'type_of_transport',
                    'cadra_check_present',
                    'asset_sitelocation',
                    'job_sitelocation',
                ];
                break;

            default:
                break;
        }

        return hiddenCols;
    };

    _cellRenderer = (cell, row) => {
        if (cell.t === 'asset_rpclass') {
            return <EquipmentHazardContainer classification={cell.value} hazards={getHazardsFromEAMGridRow(row)} />;
        }
        if (cell.t === 'asset_code') {
            return (
                <Typography>
                    <a target="_blank" href={`${process.env.REACT_APP_FRONTEND}equment/${cell.value}`} rel="noreferrer">
                        {cell.value}
                    </a>
                </Typography>
            );
        }
        if (cell.t === 'job_executedby') {
            const executedByDesc = Tools.getGridCellValueFromRow(row, 'job_executedby_desc');
            if (executedByDesc) return <Typography>{executedByDesc}</Typography>;
        }
        return false;
    };

    // Go to perform page
    _handlePerform = (jobIds) => {
        const queryParams = jobIds.map((jobId) => `wo=${jobId}`).join('&');
        this.props.history.push(`/rpmperform?${queryParams}`);
    };

    // Click on Edit button, in a row of the grid
    _handleEdit = (row) => {
        this._handlePerform([row.cell.filter((column) => column.t === 'job_number')[0].value]);
    };

    // Click on Batch Update button
    _handleBatchUpdate = () => {
        this._handlePerform(
            Object.keys(this.state.selectedRows).map((elem) => {
                const row = this.state.selectedRows[elem];
                return Tools.getGridCellValueFromRow(row, 'job_number');
            })
        );
    };

    /**
     * Convert the row data structure to a classical Javascript object
     * @param job
     * @private
     */
    _convertJob(job) {
        return job.cell.reduce((acc, cur) => {
            acc[cur.t] = cur.value;
            return acc;
        }, {});
    }

    gridRequestAdapter = (gridRequest) => {
        let gridFilters = [...gridRequest.gridFilter];
        gridFilters = gridFilters.map((gridFilter) => {
            const value = gridFilter.fieldValue;
            switch (gridFilter.fieldName) {
                case 'eqpLocation':
                    return this._getGridFilterLocation(
                        gridFilter,
                        value,
                        'asset_locisbz',
                        'asset_toplocation',
                        'asset_sitelocation',
                        'asset_loc'
                    );
                case 'jobLocation':
                    return this._getGridFilterLocation(
                        gridFilter,
                        value,
                        'job_locisbz',
                        'job_toplocation',
                        'job_sitelocation',
                        'job_location'
                    );
                case 'created': {
                    let date = moment();
                    if (gridFilter.fieldValue === 'LASTDAY') {
                        date = date.subtract(1, 'day');
                    } else if (gridFilter.fieldValue === 'LASTWEEK') {
                        date = date.subtract(1, 'week');
                    } else if (gridFilter.fieldValue === 'LASTMONTH') {
                        date = date.subtract(1, 'month');
                    }
                    return {
                        ...gridFilter,
                        fieldName: 'job_reported',
                        fieldValue: date.format(DATE_FORMAT.EAMCOMPONENT),
                    };
                }
                case 'job_status':
                    // All active status
                    if (gridFilter.fieldValue === 'ALLACTIVE') {
                        return { ...gridFilter, fieldName: 'job_status', fieldValue: 'R', operator: 'BEGINS' };
                    }
                    // Normal status

                    return gridFilter;

                default:
                    return gridFilter;
            }
        });

        return {
            ...gridRequest,
            gridFilter: gridFilters,
            // gridSort: [...gridRequest.gridSort, {sortType: "DESC", sortBy: "job_reported"}]
        };
    };

    /**
     * Jobs are similar if they have the same important properties
     * Jobs need to be similar so they can be updated in batch
     * @param row1
     * @param row2
     * @returns {boolean}
     * @private
     */
    _areJobsSimilar(row1, row2) {
        const job1 = this._convertJob(row1);
        const job2 = this._convertJob(row2);
        return [
            'job_status',
            'job_department',
            'job_future_action',
            'job_location',
            'edh_document',
            'type_of_transport',
            'storage_operation',
            'transport_required',
            'equipment_destination',
            'equipment_destination_rad',
            'associated_risks',
            'job_createdby',
        ].every((field) => job1[field] === job2[field]);
    }

    getWorkOrdersList = (rowKeys) =>
        rowKeys.map((elem) => {
            const row = this.state.selectedRows[elem];
            return Tools.getGridCellValueFromRow(row, 'job_number');
        });

    _isRowSelectable = (row, selectedRecords) => {
        if (!selectedRecords) return true;
        const keys = Object.keys(selectedRecords);
        const woList = this.getWorkOrdersList(Object.keys(this.state.selectedRows));
        const woNumber = Tools.getGridCellValueFromRow(row, 'job_number');
        if (keys.length === 0) {
            return true;
        }
        if (woList.includes(woNumber)) {
            // Already selected, so its ok
            return true;
        }
        if (keys.length >= this.props.applicationData.maxUpdateRecords) {
            // Max 20 jobs updated at the same time
            return false;
        }
        return this._areJobsSimilar(row, selectedRecords[keys[0]]);
    };

    renderPage(writeAccess) {
        const { screenData, lang, translations } = this.props;
        const { customFieldsDef } = this.props.screenData[this.props.lang];
        const { constants } = this.props;

        const searchJobStatuses = Tools.sortCodeDescList([
            ...screenData[lang].lists.jobStatuses,
            {
                code: 'ALLACTIVE',
                desc: translations.ALLACTIVE,
            },
        ]);

        return (
            <div className={gridNoOverflowClasses.outerBlock}>
                <SearchPanel title={this.props.translations.SEARCH_CRITERIA} onPanelChange={this._onPanelChange}>
                    <SearchPanelColumn>
                        <Grid container spacing={8}>
                            <Grid item md={6} sm={12} xs={12}>
                                <EAMLookup
                                    right={1}
                                    top={13}
                                    width={26}
                                    height={26}
                                    gridId={this.props.applicationData.locationRaisinGridID}
                                    keys={{
                                        code: 'obj_code',
                                        mapCodeTo: 'eqpLocation',
                                    }}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'BEGINS', 'VARCHAR')
                                    }
                                    value={this.state.filters.eqpLocation}
                                >
                                    <EAMSelect
                                        elementInfo={screenData[lang].assetFields.eqpLocation}
                                        valueKey="eqpLocation"
                                        creatable
                                        value={this.state.filters.eqpLocation}
                                        values={screenData[lang].lists.locationsList}
                                        labelStyle={labelStyleRight}
                                        updateProperty={(key, value) =>
                                            this.updateProperty(key, value, 'BEGINS', 'VARCHAR')
                                        }
                                        arrowRenderer={() => <span />}
                                    />
                                </EAMLookup>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <EAMLookup
                                    right={1}
                                    top={13}
                                    width={26}
                                    height={26}
                                    gridId={this.props.applicationData.locationRaisinGridID}
                                    keys={{
                                        code: 'obj_code',
                                        mapCodeTo: 'jobLocation',
                                    }}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'BEGINS', 'VARCHAR')
                                    }
                                    value={this.state.filters.jobLocation}
                                >
                                    <EAMSelect
                                        elementInfo={screenData[lang].woFields.location}
                                        valueKey="jobLocation"
                                        value={this.state.filters.jobLocation}
                                        values={screenData[lang].lists.locationsList}
                                        creatable
                                        labelStyle={labelStyleRight}
                                        updateProperty={(key, value) =>
                                            this.updateProperty(key, value, 'BEGINS', 'VARCHAR')
                                        }
                                        arrowRenderer={() => <span />}
                                    />
                                </EAMLookup>
                            </Grid>
                        </Grid>

                        <EAMAutocomplete
                            elementInfo={screenData[lang].woFields.equipment}
                            value={this.state.filters.asset_code}
                            valueKey="asset_code"
                            multi
                            creatable
                            labelStyle={labelStyleRight}
                            updateProperty={(key, value) => this.updateProperty(key, value, 'BEGINS', 'VARCHAR', true)}
                            loadOptions={WSAutocomplete.autocompleteEquipment}
                            columnsWidth={['0', '100%']}
                            autoSelectSingleElement
                        />
                        <Grid container spacing={8}>
                            <Grid item md={6} sm={12} xs={12}>
                                <EAMSelect
                                    label={this.props.translations.EQPCLASS}
                                    valueKey="asset_class"
                                    value={this.state.filters.asset_class}
                                    values={screenData[lang].lists.equipmentClasses}
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) => this.updateProperty(key, value, 'IN', 'VARCHAR')}
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <EAMSelect
                                    label={this.props.translations.CADRACHECKPRESENT}
                                    valueKey="cadra_check_present"
                                    value={this.state.filters.cadra_check_present}
                                    values={screenData[lang].lists.yesnoList}
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'EQUALS', 'VARCHAR')
                                    }
                                />
                            </Grid>
                        </Grid>
                    </SearchPanelColumn>

                    <SearchPanelColumn>
                        <Grid container spacing={8}>
                            <Grid item md={6} sm={12} xs={12}>
                                <EAMInput
                                    label={this.props.translations.JOBNUMBER}
                                    value={this.state.filters.job_number}
                                    valueKey="job_number"
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'BEGINS', 'VARCHAR')
                                    }
                                />
                            </Grid>

                            <Grid item md={6} sm={12} xs={12}>
                                <EAMSelect
                                    label={this.props.translations.JOBCREATED}
                                    valueKey="created"
                                    value={this.state.filters.created}
                                    values={screenData[lang].lists.createdList}
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'GREATER_THAN', 'VARCHAR')
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid item md={6} sm={12} xs={12}>
                                <EAMSelect
                                    elementInfo={screenData[lang].woFields.workorderstatus}
                                    valueKey="job_status"
                                    value={this.state.filters.job_status}
                                    values={searchJobStatuses}
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'EQUALS', 'VARCHAR')
                                    }
                                />
                            </Grid>

                            <Grid item md={6} sm={12} xs={12}>
                                <EAMSelect
                                    elementInfo={screenData[lang].customFields[constants.cfpFutureAction]}
                                    valueKey="job_future_action"
                                    value={this.state.filters.job_future_action}
                                    values={Tools.getCustomFieldsCodeDesc(
                                        customFieldsDef[constants.cfpFutureAction],
                                        true
                                    )}
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'EQUALS', 'VARCHAR', true)
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid item md={6} sm={12} xs={12}>
                                <EAMSelect
                                    label={this.props.translations.RPSECTION}
                                    valueKey="job_department"
                                    value={this.state.filters.job_department}
                                    values={screenData[lang].lists.departments}
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'EQUALS', 'VARCHAR')
                                    }
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12} />
                        </Grid>
                    </SearchPanelColumn>

                    <SearchPanelControls>
                        <TRECButton
                            color="default"
                            onClick={() => {
                                this.props.history.push('/menu');
                            }}
                        >
                            <MenuIcon />
                            {this.props.translations.GOTOMENU}
                        </TRECButton>

                        <TRECButton onClick={this.onSearchClick}>
                            <SearchIcon />
                            {this.props.translations.SEARCH}
                        </TRECButton>

                        <TRECButton onClick={this.onClearClick}>
                            <FilterRemoveOutline />
                            {this.props.translations.RESETVALUES}
                        </TRECButton>
                        {writeAccess && Object.keys(this.state.selectedRows).length > 0 && (
                            <TRECButton onClick={this._handleBatchUpdate}>
                                <DoneAll />
                                {this.props.translations.BATCH_UPDATE}
                            </TRECButton>
                        )}
                    </SearchPanelControls>
                </SearchPanel>

                <EAMGridNoOverflow
                    onRef={(ref) => {
                        this.grid = ref;
                    }}
                    gridId={this.props.applicationData.gridRPMeasurementsGridID}
                    cellRenderer={this._cellRenderer}
                    showDataspySelection={false}
                    hiddenTags={this._hiddenColumns()}
                    onEditRow={writeAccess ? (row) => this._handleEdit(row) : undefined}
                    gridRequestAdapter={this.gridRequestAdapter}
                    heightFilterNotVisible="189px"
                    allowRowSelection={writeAccess}
                    isRowSelectable={this._isRowSelectable}
                    onSelectRow={this.onSelectRow}
                    headerStyle={{}}
                />
            </div>
        );
    }
}

export default RPMeasurements;
