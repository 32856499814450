import React from 'react';
import { withStyles } from '@material-ui/core/styles';

/* eslint-disable react/no-danger */

const styles = () => ({
    contentStyle: {
        width: '100%',
        height: 'calc(100% - 25px)',
        cursor: 'auto',
        textAlign: 'left',
        overflowY: 'auto',
        backgroundColor: '#ffffff',
    },
    textStyle: {
        display: 'inline-block',
        padding: '3px',
    },
});

function TRECHelpContent(props) {
    const { classes, text } = props;
    return (
        <div className={classes.contentStyle}>
            <span className={classes.textStyle} dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    );
}

export default withStyles(styles)(TRECHelpContent);
