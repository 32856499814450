import { Typography } from '@material-ui/core';
import { reactSelectComponents } from 'amm-tools';
import { isContainer } from 'ui/pages/rwprocessing/RWProcessingTools';
import EquipmentHazardContainer from '../eqphazard/EquipmentHazardContainer';

const smallIconStyle = { margin: '0px', marginLeft: '4px', width: '24px', height: '24px', textAlign: 'center' };

export const EquipmentOptionInner = ({ data = {} }) => (
    <>
        <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'space-between' }}>
            <Typography variant="subtitle1" style={{ flex: 1 }}>
                {data.eqCode ?? data.code}
                {isContainer(data) ? (
                    <i className="fa fa-archive" style={{ ...smallIconStyle, alignSelf: 'center', fontSize: '20px' }} />
                ) : null}
            </Typography>

            <EquipmentHazardContainer classification={data.rpClassification} hazards={data.objectHazards} />
            <></>
        </div>
        <Typography variant="body1" color="textSecondary">
            {data.description ?? data.desc}
        </Typography>
        {data.serialno ? (
            <Typography variant="body1" color="textSecondary">
                Serial Number: {data.serialno}
            </Typography>
        ) : null}
        {data.alias ? (
            <Typography variant="body1" color="textSecondary">
                Alias: {data.alias}
            </Typography>
        ) : null}
        {data.otherId ? (
            <Typography variant="body1" color="textSecondary">
                Other ID: {data.otherId}
            </Typography>
        ) : null}
    </>
);

const EquipmentOption = (props) => (
    <reactSelectComponents.Option {...props}>
        <EquipmentOptionInner {...props} />
    </reactSelectComponents.Option>
);

export default EquipmentOption;
