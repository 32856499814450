import queryString from 'query-string';
import { equipmentsArrayHazardInputAdapter, equipmentsArrayHazardOutputAdapter } from '../Hazards';
import WS from './WS';

/**
 * Handles all calls to REST Api
 */
class WSMeasurements {
    getMeasurements(workorders, config = {}) {
        return WS._get(`/rpmperform/readmeasurement?${queryString.stringify({ wo: workorders })}`, config);
    }

    getMeasurementDetails({ workorder, applicationData }, config = {}) {
        return WS._get(`/rpmperform/readmeasurement/${workorder}`, config).then((response) => {
            if (
                applicationData.isHazardsFeatureActive &&
                response &&
                response.body &&
                response.body.data &&
                typeof response.body.data === 'object'
            ) {
                response.body.data.equipment = equipmentsArrayHazardInputAdapter({
                    equipments: response.body.data.equipment,
                    applicationData,
                });
            }

            return response;
        });
    }

    updateMeasurements({ measurements, applicationData }, config = {}) {
        const equipments = equipmentsArrayHazardOutputAdapter({
            equipments: measurements.equipments,
            applicationData,
        });

        return WS._put(
            '/rpmperform/updatemeasurement',
            {
                ...measurements,
                equipments,
                measurement: {
                    ...measurements.measurement,
                    equipment: equipments,
                },
            },
            config
        );
    }

    openMeasurements(workorders, config = {}) {
        return WS._get(`/rpmperform/openjob?${queryString.stringify({ wo: workorders })}`, config);
    }

    cancelMeasurements(workorders, config = {}) {
        return WS._get(`/rpmperform/canceljob?${queryString.stringify({ wo: workorders })}`, config);
    }

    getPreviousResults(equipment, config = {}) {
        return WS._get(`/rpmperform/previousresults?equipment=${equipment}`, config);
    }

    updateChecklistItem(checklistItem, config = {}) {
        return WS._put('/rpmperform/updatechecklist/', checklistItem, config);
    }

    getDeviceSerial(deviceCode, config = {}) {
        return WS._get(`/rpmperform/readuserdevice?deviceCode=${deviceCode}`, config);
    }
}

export default new WSMeasurements();
