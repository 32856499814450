import { IconButton, Typography, withStyles } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';
import { AMMSingleAutocomplete } from 'amm-tools';
import Axios from 'axios';
import { EquipmentOptionInner } from 'ui/components/autocomplete/EquipmentOption';
import React, { useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import WSEquipment from 'tools/rest/WSEquipment';
import { generateRoute, ROUTES } from 'tools/Routes';

const styles = {
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 15,
        boxSizing: 'border-box',
    },
    headerSection: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: '#f5f5f5',
        padding: '25px',
        marginTop: '-25px',
        position: 'relative',
    },
    title: {
        paddingTop: '2rem',
        paddingBottom: '2rem',
    },
    inputSection: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: '0.25rem',
        // boxShadow: "0 0.1875rem 0.4375rem 0 rgba(0,0,0,.1)",
        border: '1px solid #e2e2e2',
        backgroundColor: '#ffffff',
        padding: '0.5rem',
        boxSizing: 'border-box',
        marginBottom: '0.5rem',
    },
    searchIcon: {
        margin: '5px',
    },
    inputContainer: {
        width: '100%',
    },
    inputField: {
        container: (base) => ({
            ...base,
            flex: 1,
            border: 'none',
            fontSize: '1.125rem',
            outline: 'none',
            ':focus': {
                outline: 'none',
                border: 'none',
            },
        }),
    },
    progress: {
        margin: 10,
    },
    resultsSection: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        position: 'relative',
        paddingLeft: '25px',
        paddingRight: '25px',
        marginBottom: '-25px',
    },
    resultList: {},
    resultListItem: {},
    messageSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'top',
        margin: '2rem',
    },
};

const listItemStyles = () => ({
    root: {
        border: '1px solid #e2e2e2',
        borderRadius: '0.25rem',
        margin: '1rem 5%',
        padding: '1rem',
        '&:hover': {
            background: '#ebf6ff',
            border: '1px solid #4c96f2',
            cursor: 'pointer',
        },
    },
    link: {
        textDecoration: 'none',
    },
});

const TRANSLATION_KEYS = {
    SEARCH_TITLE: 'SEARCH_TITLE',
    SEARCH_PLACEHOLDER: 'SEARCH_PLACEHOLDER',
    SEARCH_NO_RESULTS_MSG: 'SEARCH_NO_RESULTS_MSG',
    SEARCH_ERROR_MSG_PRIMARY: 'SEARCH_ERROR_MSG_PRIMARY',
    SEARCH_ERROR_MSG_SECONDARY: 'SEARCH_ERROR_MSG_SECONDARY',
    SEARCH_INIT_MSG_PRIMARY: 'SEARCH_INIT_MSG_PRIMARY',
    SEARCH_INIT_MSG_SECONDARY: 'SEARCH_INIT_MSG_SECONDARY',
};

const ListItem = withStyles(listItemStyles)((props) => {
    const { equipment, linkTo, classes } = props;
    return (
        <Link to={linkTo} className={classes.link}>
            <div className={classes.root}>
                <EquipmentOptionInner
                    {...props}
                    data={{ eqCode: equipment.code, description: equipment.desc, ...equipment }}
                />
            </div>
        </Link>
    );
});

const STATUS_TYPES = {
    HAS_RESULTS: 'HAS_RESULTS',
    NO_RESULTS: 'NO_RESULTS',
    ERROR: 'ERROR',
    INIT: 'INIT',
    LOADING: 'LOADING',
};

let source = null;

const EquipmentSearchPage = (props) => {
    const { getTranslation } = props;
    const [equipmentResults, setEquipmentResults] = useState([]);
    const [value, setValue] = useState('');
    const [statusType, setStatusType] = useState(STATUS_TYPES.INIT);
    const resultsAreaRef = useRef(null);

    const handleSearch = (hint) => {
        if (statusType === STATUS_TYPES.LOADING || !hint.trim().length) return;
        setStatusType(STATUS_TYPES.LOADING);
        source && source.cancel();
        source = Axios.CancelToken.source();
        WSEquipment.getAutocompleteOptions({ hint: hint.trim() }, { cancelToken: source.token })
            .then((newEquipmentResults) => {
                setEquipmentResults(newEquipmentResults);
                newEquipmentResults.length
                    ? setStatusType(STATUS_TYPES.HAS_RESULTS)
                    : setStatusType(STATUS_TYPES.NO_RESULTS);
            })
            .catch((error) => {
                if (error && error.type === 'REQUEST_CANCELLED') return;
                setStatusType(STATUS_TYPES.ERROR);
            });
    };

    const handleClear = () => {
        source && source.cancel();
        setValue('');
        setEquipmentResults([]);
        setStatusType(STATUS_TYPES.INIT);
    };

    const handleKeyDown = (e) => {
        switch (e.key) {
            case 'Enter':
                value && handleSearch();
                break;
            case 'Escape':
                handleClear();
                break;
            default:
                break;
        }
    };

    const handleValueChange = (val, e) => {
        if (e?.action === 'input-change') {
            const newValue = val;
            if (!newValue) handleClear();
            else {
                if (statusType !== STATUS_TYPES.INIT) setStatusType(STATUS_TYPES.INIT);
                setValue(val);
            }
        }
    };

    const renderResultsArea = () => {
        switch (statusType) {
            case STATUS_TYPES.HAS_RESULTS:
                return equipmentResults.map((equipment) => (
                    <>
                        <ListItem
                            key={equipment.eqCode}
                            equipment={equipment}
                            linkTo={generateRoute(ROUTES.equipmentShow, { equipmentID: equipment.eqCode })}
                        />
                    </>
                ));
            case STATUS_TYPES.NO_RESULTS:
                return (
                    <div style={styles.messageSection}>
                        <Typography variant="subtitle1" color="textSecondary">
                            {getTranslation(TRANSLATION_KEYS.SEARCH_NO_RESULTS_MSG).replace('{{QUERY}}', value)}
                        </Typography>
                    </div>
                );
            case STATUS_TYPES.ERROR:
                return (
                    <div style={styles.messageSection}>
                        <Typography variant="subtitle1" color="textSecondary">
                            {getTranslation(TRANSLATION_KEYS.SEARCH_ERROR_MSG_PRIMARY)}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {getTranslation(TRANSLATION_KEYS.SEARCH_ERROR_MSG_SECONDARY)}
                        </Typography>
                    </div>
                );
            case STATUS_TYPES.INIT:
                return (
                    <div style={styles.messageSection}>
                        <Typography variant="subtitle1" color="textSecondary">
                            {getTranslation(TRANSLATION_KEYS.SEARCH_INIT_MSG_PRIMARY)}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {getTranslation(TRANSLATION_KEYS.SEARCH_INIT_MSG_SECONDARY)}
                        </Typography>
                    </div>
                );
            case STATUS_TYPES.LOADING:
                return (
                    <Skeleton
                        height={72}
                        count={
                            (resultsAreaRef &&
                                resultsAreaRef.current &&
                                Math.floor(resultsAreaRef.current.offsetHeight / 96)) ||
                            6
                        }
                        wrapper={({ children }) => <div style={{ margin: '1rem 5%' }}>{children}</div>}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div style={styles.root}>
            <section style={styles.headerSection}>
                <Typography variant="h4" style={styles.title} color="textPrimary">
                    {getTranslation(TRANSLATION_KEYS.SEARCH_TITLE)}
                </Typography>
                <section style={styles.inputSection}>
                    <Search style={styles.searchIcon} />
                    <div style={styles.inputContainer}>
                        <AMMSingleAutocomplete
                            loadOptions={({ hint }) => handleSearch(hint)}
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                            }}
                            menuIsOpen={false}
                            placeholder={getTranslation(TRANSLATION_KEYS.SEARCH_PLACEHOLDER)}
                            onInputChange={handleValueChange}
                            onKeyDown={handleKeyDown}
                            value={value === '' ? null : { value, label: value }}
                            styles={styles.inputField}
                            isClearable={false}
                        />
                    </div>
                    {value.trim() && (
                        <IconButton aria-label="Clear" onClick={handleClear}>
                            <Clear />
                        </IconButton>
                    )}
                </section>
            </section>
            <section ref={resultsAreaRef} style={styles.resultsSection}>
                {renderResultsArea()}
            </section>
        </div>
    );
};

export default EquipmentSearchPage;
