import { WizardStep } from 'amm-tools';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import { EQUIPMENT_KEYS, RPAR_REQUEST_KEYS, TABLE_DATA_TYPES, TREC_GENERAL_KEYS } from 'enums/Constants';
import React from 'react';
import BlockUi from 'react-block-ui';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import Tools from 'tools/Tools';
import { showError } from 'tools/TrecNotifications';

const styles = {
    tableContainerSection: {
        width: '100%',
    },
    inputStyle: {
        fontSize: '14px',
    },
    locationFields: {
        minWidth: '150px',
        fontSize: '14px',
    },
};

class RPARTaskSelector extends WizardStep {
    formFields = [];

    state = {
        copyOnFirstLine: false,
        equipmentMap: {},
        tableColumns: [],
        loading: true,
    };

    UNSAFE_componentWillMount() {
        this.loadInitialState(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.loadInitialState(nextProps);
    }

    componentDidUpdate(prevProps, prevState) {
        const { equipmentMap } = this.state;
        const { rpamAnalysisTypes, rparActions } = this.props;
        const equipmentCodeList = Object.keys(equipmentMap);
        if (
            !equipmentCodeList.every((code) =>
                rpamAnalysisTypes.every((type) => equipmentMap[code][type] === prevState.equipmentMap[code][type])
            )
        ) {
            const analysisTypeEquipmentMap = rpamAnalysisTypes.reduce((acc, at) => {
                acc[at] = [];
                equipmentCodeList.forEach((eqCode) => {
                    equipmentMap[eqCode][at] && acc[at].push(eqCode);
                });
                return acc;
            }, {});
            rpamAnalysisTypes.forEach((analysisType) => {
                const equipments = analysisTypeEquipmentMap[analysisType];
                rparActions.setRPAREquipmentsForAnalysisType(equipments, analysisType);
            });
        }
    }

    loadInitialState = (props) => {
        this.setState({
            equipmentMap: this.generateEquipmentsMap(props),
            tableColumns: this.generateTableColumns(props),
            loading: false,
        });
    };

    getFixedTableColumns = () => [
        {
            header: this.props.getTranslation(EQUIPMENT_KEYS.EQUIPMENT_CODE),
            code: EQUIPMENT_KEYS.EQUIPMENT_CODE,
            type: () => TABLE_DATA_TYPES.STATIC,
            editable: () => false,
        },
        {
            header: this.props.getTranslation(EQUIPMENT_KEYS.EQUIPMENT_DESC),
            code: EQUIPMENT_KEYS.EQUIPMENT_DESC,
            type: () => TABLE_DATA_TYPES.STATIC,
            editable: () => false,
        },
        {
            header: this.props.getTranslation(EQUIPMENT_KEYS.LABORATORY),
            code: EQUIPMENT_KEYS.LABORATORY,
            type: ({ equipment }) =>
                equipment[EQUIPMENT_KEYS.RPA_MEASUREMENT] && equipment[EQUIPMENT_KEYS.TYPE_OF_TRANSPORT] !== 'None'
                    ? TABLE_DATA_TYPES.STATIC
                    : TABLE_DATA_TYPES.SELECT,
            values: this.props.rpamValues[EQUIPMENT_KEYS.LABORATORY],
            editable: () => true,
            required: true,
        },
        {
            header: this.props.getTranslation(EQUIPMENT_KEYS.TYPE_OF_TRANSPORT),
            code: EQUIPMENT_KEYS.TYPE_OF_TRANSPORT,
            type: ({ equipment }) =>
                equipment[EQUIPMENT_KEYS.RPA_MEASUREMENT] ? TABLE_DATA_TYPES.STATIC : TABLE_DATA_TYPES.SELECT,
            values: this.props.rpamValues[EQUIPMENT_KEYS.TYPE_OF_TRANSPORT],
            editable: () => true,
            required: true,
        },
        {
            header: this.props.getTranslation(EQUIPMENT_KEYS.POST_DEST),
            code: EQUIPMENT_KEYS.POST_DEST,
            type: () => TABLE_DATA_TYPES.AUTOCOMPLETE,
            autocompleteType: WSAutocomplete.autocompleteLocation,
            columnCodes: ['code'],
            editable: () => true,
            required: true,
        },
        {
            header: this.props.getTranslation(EQUIPMENT_KEYS.POST_DEST_RAD),
            code: EQUIPMENT_KEYS.POST_DEST_RAD,
            type: () => TABLE_DATA_TYPES.AUTOCOMPLETE,
            autocompleteType: WSAutocomplete.autocompleteLocation,
            columnCodes: ['code'],
            editable: () => true,
            required: true,
        },
    ];

    generateTableColumns = (props) => {
        const { rpamAnalysisTypes, getTranslation, rparGetters } = props;
        const temp = this.mergeColumns(
            this.getFixedTableColumns(),
            rpamAnalysisTypes.map((e) => ({
                header: getTranslation(e),
                code: e,
                type: () => TABLE_DATA_TYPES.CHECKBOX,
                editable: ({ eqCode, analysisType }) => rparGetters.isAnalysisEditable(eqCode, analysisType),
            })),
            this.getIndexAfterColumn(this.getFixedTableColumns(), EQUIPMENT_KEYS.EQUIPMENT_DESC)
        );

        return temp;
    };

    generateEquipmentsMap = (props) => {
        const { rparGetters, rpamAnalysisTypes } = props;
        const equipmentList = rparGetters.getEquipmentList();
        const equipments = rparGetters.getEquipments();

        return equipmentList
            .map((eqCode) => ({
                [eqCode]: {
                    ...equipments[eqCode],
                    ...rpamAnalysisTypes.reduce((acc, at) => {
                        const currentAnalysisForEquipments = Object.keys(rparGetters.getAnalysisTypeEquipments(at));
                        return {
                            ...acc,
                            [at]: currentAnalysisForEquipments.includes(eqCode),
                        };
                    }, {}),
                },
            }))
            .reduce((acc, eq) => ({ ...acc, ...eq }), {});
    };

    validate = () => {
        let isValid = true;
        const { getTranslation } = this.props;
        Object.keys(this.formFields).forEach((key) => {
            if (this.formFields[key] && this.formFields[key].validate && !this.formFields[key].validate()) {
                isValid = false;
                showError(getTranslation(TREC_GENERAL_KEYS.REQUIRED_ERROR_MESSAGE), null, 4000);
            }
        });
        return isValid;
    };

    canContinue = () => {
        const { equipmentMap } = this.state;
        const { rpamAnalysisTypes, getTranslation } = this.props;
        const selectedCheckboxes = [];
        let areAnalysisTypesSelected = false;

        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const eqCode in equipmentMap) {
            // eslint-disable-next-line no-restricted-syntax
            for (const analysisType of rpamAnalysisTypes) {
                if (equipmentMap[eqCode][analysisType]) {
                    selectedCheckboxes.push(equipmentMap[eqCode][analysisType]);
                    break;
                }
            }
            if (Object.entries(equipmentMap).length === selectedCheckboxes.length) {
                areAnalysisTypesSelected = true;
            }
        }

        if (!areAnalysisTypesSelected) {
            showError(getTranslation(RPAR_REQUEST_KEYS.SELECTANALYSISMESSAGE));
        }

        return this.validate() && areAnalysisTypesSelected;
    };

    saveChanges = () => {
        const { equipmentMap } = this.state;
        const { rparActions, rparGetters } = this.props;

        const originalEquipmentMap = rparGetters.getEquipments();
        const updatedEquipmentMap = Object.keys(equipmentMap).reduce(
            (acc, eqCode) => ({
                ...acc,
                [eqCode]: {
                    ...originalEquipmentMap[eqCode],
                    ...this.getFixedTableColumns()
                        .filter((ftc) => ftc.editable())
                        .reduce(
                            (properties, property) => ({
                                ...properties,
                                [property.code]: equipmentMap[eqCode][property.code],
                            }),
                            {}
                        ),
                },
            }),
            {}
        );
        rparActions.updateRPAREquipments(updatedEquipmentMap);
        return true;
    };

    commitChanges = (callback) => this.setState({}, callback);

    getEAMSelect = (eqCode, equipment, valueKey, values, required) => (
        <EAMSelect
            elementInfo={{ attribute: required ? 'R' : 'O', xpath: `${eqCode}.${valueKey}` }}
            values={values}
            formFields={this.formFields}
            valueKey={valueKey}
            checkValue
            style={styles.inputStyle}
            value={equipment[valueKey]}
            updateProperty={(key, value) => this.updateEquipment('equipmentMap', eqCode, key, value)}
        />
    );

    getEAMCheckbox = (eqCode, equipment, valueKey, required, readonly) => (
        <EAMCheckbox
            elementInfo={{ attribute: required ? 'R' : 'O', xpath: `${eqCode}.${valueKey}`, readonly }}
            valueKey={valueKey}
            value={equipment[valueKey]}
            updateProperty={(key, value) => this.updateEquipment('equipmentMap', eqCode, key, value)}
            trueValue
            falseValue={false}
        />
    );

    constrain(key, obj, valuesGrid, values) {
        // TODO the comparator here should be IN and the object should arrive here with analysisType, currently not on the object, as a list
        const overwrites = (valuesGrid.CON || {})[key];
        const constraints = (valuesGrid.CO2 || {})[key];
        const checkCon = (con) => this.checkContraint(obj, con);

        // Only allows SOME logic
        if (overwrites) {
            const vals = overwrites
                .reduce((acc, list) => [...acc, ...list], [])
                // TODO operator (equals, IN, <, >)
                .filter(checkCon);
            return [...new Set(vals.map((e) => e.value))].map((code) => ({ code, desc: code }));
        }
        if (constraints) {
            // TODO
            const contraintsMap = Tools.groupListBy(constraints, 'action');
            if (contraintsMap.SET && contraintsMap.SET.filter(checkCon).length) {
                return [...new Set(contraintsMap.SET.filter(checkCon))].map((v) => ({
                    code: v.value,
                    desc: v.desc || v.value,
                }));
            }
            // TODO add and remove
        }
        return values;
        // return [...new Set(values.map(e => e.value))].map(code => ({code, desc: code}))
    }

    checkContraint = (obj, con) => `${obj[con.parentField]}` === con.parentValue;

    constrainLabs = (key, obj, valuesGrid) =>
        [...new Set(valuesGrid.CON[key].filter((con) => obj[con.parentValue]).map((e) => e.value))].map((code) => ({
            code,
            desc: code,
        }));

    getTableData = (tableColumns, equipmentMap) =>
        Object.keys(equipmentMap).map((eqCode) => {
            const equipment = equipmentMap[eqCode];
            return tableColumns.reduce((acc, column) => {
                const readonly = column.isEditable && !column.isEditable(equipment);
                switch (column.type({ equipment })) {
                    case TABLE_DATA_TYPES.STATIC:
                        acc[column.code] = equipment[column.code];
                        break;
                    case TABLE_DATA_TYPES.CHECKBOX:
                        acc[column.code] = this.getEAMCheckbox(
                            eqCode,
                            equipment,
                            column.code,
                            column.required,
                            !column.editable({ eqCode, analysisType: column.code })
                        );
                        break;
                    case TABLE_DATA_TYPES.SELECT: {
                        let temp = column.values;
                        if (column.code === EQUIPMENT_KEYS.LABORATORY) {
                            temp = this.constrainLabs(column.code, equipment, this.props.valuesGrid);
                        } else {
                            temp = this.constrain(column.code, equipment, this.props.valuesGrid, column.values);
                        }
                        acc[column.code] = this.getEAMSelect(eqCode, equipment, column.code, temp, column.required);
                        break;
                    }
                    case TABLE_DATA_TYPES.AUTOCOMPLETE:
                        acc[column.code] = this.getEAMAutocomplete(
                            eqCode,
                            equipment,
                            column.code,
                            column.autocompleteType,
                            column.required,
                            '',
                            column.descKey,
                            readonly,
                            column.columnCodes
                        );
                        break;
                    default:
                        break;
                }
                return acc;
            }, {});
        });

    getEAMAutocomplete = (
        eqCode,
        equipment,
        valueKey,
        autocompleteType,
        required,
        defaultValue = '',
        descKey,
        readonly,
        columnCodes
    ) => (
        <EAMAutocomplete
            elementInfo={{ attribute: required ? 'R' : 'O', xpath: `${eqCode}.${valueKey}`, readonly }}
            value={(descKey ? equipment[descKey] : equipment[valueKey]) || defaultValue}
            style={valueKey === EQUIPMENT_KEYS.RESP_TECHNIQUE ? styles.autocompleteFields : styles.locationFields}
            valueKey={valueKey}
            valueDesc=""
            descKey={descKey}
            formFields={this.formFields}
            updateProperty={(key, value) => this.updateEquipment('equipmentMap', eqCode, key, value)}
            columnsCodes={columnCodes}
            columnsWidth={['100%']}
            loadOptions={autocompleteType}
        />
    );

    updateEquipment = (stateCode, entryCode, key, value) => {
        Tools.updateState(
            this,
            stateCode,
            entryCode,
            key,
            value,
            this.props.rparGetters.getAnalysisRequestProperties()[RPAR_REQUEST_KEYS.APPLY_ALL_LINES]
        );
    };

    changeState = (key, value) => this.setState({ [key]: value });

    mergeColumns = (originalColumns, columns, index) => [
        ...originalColumns.slice(0, index),
        ...columns,
        ...originalColumns.slice(index),
    ];

    getIndexAfterColumn = (columns, columnCode) => columns.map((c) => c.code).indexOf(columnCode) + 1;

    render() {
        const { equipmentMap, loading, tableColumns } = this.state;

        const properties = this.props.rparGetters.getAnalysisRequestProperties();
        const updateProperties = (key, value) => {
            this.saveChanges();
            this.props.rparActions.updateRPARequest({
                [key]: value,
            });
        };

        const { getTranslation } = this.props;
        const tableHeaders = tableColumns.map((e) => e.header);
        const tableCodes = tableColumns.map((e) => e.code);
        const tableData = this.getTableData(tableColumns, equipmentMap);
        return (
            <EISPanel heading={getTranslation(RPAR_REQUEST_KEYS.REQUESTDETAILS)} alwaysExpanded>
                <section style={styles.tableContainerSection}>
                    {loading ? (
                        <BlockUi tag="div" blocking>
                            <div>Please wait...</div>
                        </BlockUi>
                    ) : (
                        <>
                            <EAMCheckbox
                                elementInfo={{
                                    label: 'OLabel',
                                    attribute: 'O',
                                    text: getTranslation(RPAR_REQUEST_KEYS.COPYCHECKBOX),
                                }}
                                valueKey={RPAR_REQUEST_KEYS.APPLY_ALL_LINES}
                                value={properties[RPAR_REQUEST_KEYS.APPLY_ALL_LINES]}
                                updateProperty={updateProperties}
                                trueValue
                                falseValue={false}
                            />
                            <EISTable
                                data={tableData}
                                headers={tableHeaders}
                                propCodes={tableCodes}
                                maxMobileSize={600}
                            />
                        </>
                    )}
                </section>
            </EISPanel>
        );
    }
}

export default RPARTaskSelector;
