import React from 'react';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Save from '@material-ui/icons/Save';
import Menu from '@material-ui/icons/Menu';
import Logout from '@material-ui/icons/PowerSettingsNew';
import SpeedometerIcon from 'mdi-material-ui/Speedometer';
import TRECButton from '../../components/buttons/TRECButton';

/**
 * Controls (Buttons) for the RP Measurement Request wizard.
 * Buttons to be here:
 * 1. Cancel: To go to the menu
 * 2. Back: Go step back
 * 3. Next: Go step forward
 * 4. Save: Create the measurement or update the location (According to the wizard)
 * 5. Perform Measurement: When save is successful and the user has the rights to perform the request
 */
export function RPMRequestControls(props) {
    const spacingDiv = {
        height: '15px',
    };
    // Properties
    const {
        translations,
        steps,
        activeStep,
        measurementNumber,
        wizardFinish,
        userIsRPOfficer,
        action,
        request,
        constants,
        handlers,
    } = props;
    const { handleCancel, handleBack, handleNext, handleSave, handlePerform, logout } = handlers;
    const isLastStep = activeStep === steps.length - 1;
    const isUpdateLoc = action === 'updateloc' || request.futureAction === constants.futureActionVacuumDeclare;
    return (
        <div style={{ marginBottom: 15 }}>
            <div style={spacingDiv} />
            {!wizardFinish && (
                <div>
                    <TRECButton onClick={handleCancel} color="default">
                        <Menu />
                        {translations.GOTOMENU}
                    </TRECButton>

                    <TRECButton onClick={handleBack} disabled={activeStep === 0}>
                        <ArrowBack />
                        {translations.BACK}
                    </TRECButton>

                    {isLastStep ? (
                        <TRECButton onClick={handleSave}>
                            <Save />
                            {translations.SAVE}
                        </TRECButton>
                    ) : (
                        <TRECButton onClick={handleNext}>
                            <ArrowForward />
                            {translations.NEXT}
                        </TRECButton>
                    )}
                </div>
            )}
            {wizardFinish && (
                <div>
                    <TRECButton onClick={logout} color="default">
                        <Logout />
                        {translations.LOGOUT}
                    </TRECButton>

                    <TRECButton onClick={handleCancel} color="default">
                        <Menu />
                        {translations.GOTOMENU}
                    </TRECButton>

                    {!isUpdateLoc && measurementNumber && userIsRPOfficer && (
                        <TRECButton onClick={handlePerform}>
                            <SpeedometerIcon />
                            {translations.PERFORMREQU}
                        </TRECButton>
                    )}
                </div>
            )}
        </div>
    );
}

export default RPMRequestControls;
