import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import lightBlue from '@material-ui/core/colors/lightBlue';
import lightGreen from '@material-ui/core/colors/lightGreen';
import red from '@material-ui/core/colors/red';

const themes = {
    BLUE: {
        palette: {
            primary: { main: blue[500], contrastText: '#fff' }, // EAMLight blue '#2196F3'
            secondary: { main: lightBlue[900], contrastText: '#fff' }, // Darker blue '#01579b'
        },
    },

    GREEN: {
        palette: {
            primary: { main: green[600], contrastText: '#fff' }, // green '#4caf50'
            secondary: { main: lightGreen[900], contrastText: '#fff' }, // Darker green '#33691e'
        },
    },

    RED: {
        palette: {
            primary: { main: red[600], contrastText: '#fff' },
            secondary: { main: red[300], contrastText: '#fff' },
        },
    },
};

export default themes;
