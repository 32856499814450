import { Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import CommentsContainer from 'eam-components/dist/ui/components/comments/CommentsContainer';
import { TABLE_DATA_TYPES } from 'enums/Constants';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import WSComments from 'tools/rest/WSWRComments';
import { ATTRIBUTES } from 'ui/pages/rpmrequest2/InputGenerator';
import { TRANSLATION_KEYS, WATER_RELEASE_KEYS, WRR_STATUS } from '../WRRConstants';
import { WRRAssociatedWOsInfoPanel, generateWRRFields } from '../WRRHelpers';
import WRRDetailsPanel from './components/WRRDetailsPanel';
import { showError } from 'tools/TrecNotifications';

const getFields = ({ screenData, dropdowns }) => [
    {
        code: WATER_RELEASE_KEYS.ACTUAL_RELEASE_DATE,
        translationKey: TRANSLATION_KEYS.ACTUAL_RELEASE_DATE,
        type: TABLE_DATA_TYPES.DATE,
        elementInfo: screenData.customFieldsWRM.P298,
        getAttribute: () => ATTRIBUTES.REQUIRED,
        customInputProps: (_) => ({ timestamp: true }),
    },
    {
        code: WATER_RELEASE_KEYS.ACTUAL_RELEASE_VOLUME,
        translationKey: TRANSLATION_KEYS.ACTUAL_RELEASE_VOLUME,
        type: TABLE_DATA_TYPES.INPUT,
        elementInfo: screenData.customFieldsWRM.P299,
        values: dropdowns[WATER_RELEASE_KEYS.ACTUAL_RELEASE_VOLUME],
        getAttribute: () => ATTRIBUTES.REQUIRED,
    },
];

class WRRValidationStep extends WizardStep {
    formFields = {};

    componentDidMount() {
        super.componentDidMount();
        this.props.setPage('WRP_VALIDATION');
    }

    /**
     * Can continue to the next step on the wizard
     */
    canContinue = () => {
        const isValid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
        if (!isValid) {
            showError('One or more fields are not valid.');
        }

        return isValid;
    };

    /**
     * SaveChanges is used to save the localized state to the redux-store. It can only run synchronous methods.
     */
    saveChanges = () => true;

    handleSubmit = () => {
        const { updateWRR } = this.props;
        this.canContinue() &&
            updateWRR({
                [WATER_RELEASE_KEYS.STATUS]: WRR_STATUS.RELEASED,
            });
    };

    /**
     *  CommitChanges is used to trigger any side effects of the step, such as invocations to the back-end to
     * retrieve information about resources. This method can run asynchronous methods/requests but SHALL execute
     * the callback method afterwards to let the inform the wizard.
     */
    commitChanges = (callback) => {
        callback();
        return true;
    };

    /**
     * On true, the wizard automatically jumps this step immediately after it is mounted
     */
    canJumpStep = () => false;

    render() {
        const {
            writeAccess,
            translations,
            storeActions,
            wrrGetters,
            screenData,
            getTranslation,
            dropdowns,
            userData,
            lang,
        } = this.props;
        const properties = wrrGetters.getProperties();
        return (
            <div style={{ margin: 8 }}>
                <Grid container spacing={8}>
                    <Grid item md={6} sm={12} xs={12}>
                        <EISPanel
                            detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                            heading={translations?.ACTIONS || ''}
                            alwaysExpanded
                        >
                            {generateWRRFields({
                                fieldRefs: this.formFields,
                                translations,
                                storeActions,
                                wrrGetters,
                                screenData,
                                getTranslation,
                                properties,
                                writeAccess,
                                fields: getFields({ screenData, dropdowns }),
                            })}
                            <WRRAssociatedWOsInfoPanel
                                jobStatuses={screenData.lists.jobStatuses}
                                properties={properties}
                                getTranslation={getTranslation}
                                dropdowns={dropdowns}
                            />
                        </EISPanel>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <WRRDetailsPanel
                            jobStatuses={screenData.lists.jobStatuses}
                            getTranslation={getTranslation}
                            values={properties}
                            lang={lang}
                        />
                        <CommentsContainer
                            title={getTranslation('COMMENTS')}
                            entityCode="EVNT"
                            entityKeyCode={properties[WATER_RELEASE_KEYS.WR_NUMBER]}
                            userDesc={userData.eamAccount.userDesc}
                            readComments={WSComments.readComments}
                            createComment={WSComments.createComment}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default WRRValidationStep;
