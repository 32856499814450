import WS from './WS';

/**
 * Rest services for EDH Document generation
 */

class WSEDHDoc {
    //
    // Generate EDH Documents
    //
    generateEDHDocument(workorders, config = {}) {
        const queryParams = workorders.map((jobId) => `wo=${jobId}`).join('&');
        return WS._get(`/edhdocmng/generate?${queryParams}`, { ...config, timeout: 200000 });
    }

    cancelEDHDocument(workorders, config = {}) {
        const queryParams = workorders.map((jobId) => `wo=${jobId}`).join('&');
        return WS._get(`/edhdocmng/cancel?${queryParams}`, { ...config, timeout: 200000 });
    }

    generateRWEDHTransportDocument(jobNumber, config = {}) {
        return WS._post(`/rwelim/jobs/${jobNumber}/edh`, config);
    }
}

export default new WSEDHDoc();
