import { Typography } from '@material-ui/core';
import { Error, Info } from '@material-ui/icons';
import { format } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { DATE_TIME_FORMAT, TABLE_DATA_TYPES } from 'enums/Constants';
import { Link } from 'react-router-dom';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import InputGenerator from 'ui/pages/rpmrequest2/InputGenerator';
import { ANALYSIS_TYPES, ENTITY_TRANSL_PREFIX, TRANSLATION_KEYS, WATER_RELEASE_KEYS, WRR_STATUS } from './WRRConstants';

export const { ATTRIBUTES } = InputGenerator;

export const samplingFields = ({ woFields }) => [
    {
        code: WATER_RELEASE_KEYS.RPAR_NUMBER,
        type: TABLE_DATA_TYPES.INPUT,
        elementInfo: woFields.location,
        getAttribute: () => ATTRIBUTES.READONLY,
        complex: true,
    },
    {
        code: WATER_RELEASE_KEYS.RPM_NUMBER,
        type: TABLE_DATA_TYPES.INPUT,
        getAttribute: () => ATTRIBUTES.READONLY,
        complex: true,
    },
];

export const getWRRActionFields = ({ woFields, customFieldsWRM }) => [
    {
        code: WATER_RELEASE_KEYS.LOCATION,
        type: TABLE_DATA_TYPES.AUTOCOMPLETE,
        valueKey: 'code',
        descKey: 'desc',
        elementInfo: woFields.location,
        translationKey: TRANSLATION_KEYS.LOCATION,
        getAttribute: () => ATTRIBUTES.REQUIRED,
        autocompleteType: WSAutocomplete.autocompleteLocation,
    },
    {
        code: WATER_RELEASE_KEYS.RPO,
        type: TABLE_DATA_TYPES.INPUT,
        translationKey: TRANSLATION_KEYS.RPO,
        autocompleteType: WSAutocomplete.autocompleteEmployee,
        getAttribute: () => ATTRIBUTES.HIDDEN,
    },
    {
        code: WATER_RELEASE_KEYS.REQUESTER,
        valueKey: 'cernId',
        descKey: 'description',
        translationKey: TRANSLATION_KEYS.REQUESTER,
        type: TABLE_DATA_TYPES.AUTOCOMPLETE,
        elementInfo: woFields.createdby,
        autocompleteType: WSAutocomplete.autocompleteEmployee,
        getAttribute: () => ATTRIBUTES.REQUIRED,
    },
    {
        code: WATER_RELEASE_KEYS.REQUESTED_RELEASE_DATE,
        type: TABLE_DATA_TYPES.DATE,
        translationKey: TRANSLATION_KEYS.FORECASTED_RELEASE_DATE,
        elementInfo: customFieldsWRM.P291,
        getAttribute: () => ATTRIBUTES.REQUIRED,
        customInputProps: ({ _ }) => ({
            timestamp: true,
            minDate: new Date(),
        }),
    },
    {
        code: WATER_RELEASE_KEYS.FORECASTED_RELEASE_VOLUME,
        type: TABLE_DATA_TYPES.INPUT,
        elementInfo: customFieldsWRM.P290,
        translationKey: TRANSLATION_KEYS.FORECASTED_RELEASE_VOLUME,
        dataType: 'number',
        getAttribute: () => ATTRIBUTES.REQUIRED,
    },
];

export const getWRRAllFields = ({ woFields, customFieldsWRM, getTranslation }) =>
    [
        {
            code: WATER_RELEASE_KEYS.WR_NUMBER,
            type: TABLE_DATA_TYPES.INPUT,
            elementInfo: woFields.workordernum,
            getAttribute: () => ATTRIBUTES.READONLY,
            translationKey: TRANSLATION_KEYS.JOB_NUMBER,
        },
        {
            code: WATER_RELEASE_KEYS.STATUS,
            type: TABLE_DATA_TYPES.SELECT,
            elementInfo: {
                attribute: 'O',
                text: 'Status',
                defaultValue: 1,
                readonly: false,
            },
            translationKey: TRANSLATION_KEYS.STATUS,
            getAttribute: (obj) => (obj[WATER_RELEASE_KEYS.STATUS] ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN),
            values: Object.values(WRR_STATUS).map((code) => ({
                code,
                desc: getTranslation(ENTITY_TRANSL_PREFIX.EVT_STATUS + code),
            })),
        },
        {
            code: WATER_RELEASE_KEYS.EQUIPMENT,
            type: TABLE_DATA_TYPES.AUTOCOMPLETE,
            valueKey: 'code',
            descKey: 'description',
            elementInfo: woFields.equipment,
            translationKey: TRANSLATION_KEYS.EQUIPMENT,
            getAttribute: () => ATTRIBUTES.READONLY,
        },
    ]
        .concat(getWRRActionFields({ woFields, customFieldsWRM }))
        .concat([
            {
                code: WATER_RELEASE_KEYS.COUNTRY,
                type: TABLE_DATA_TYPES.INPUT,
                elementInfo: {
                    text: 'Country',
                },
                translationKey: TRANSLATION_KEYS.COUNTRY,
                getAttribute: () => ATTRIBUTES.READONLY,
            },
            {
                code: WATER_RELEASE_KEYS.CREATED_ON,
                type: TABLE_DATA_TYPES.DATE_TIME,
                elementInfo: woFields.datecreated,
                getAttribute: () => ATTRIBUTES.READONLY,
                translationKey: TRANSLATION_KEYS.CREATED_ON,
                customInputProps: (_) => ({ timestamp: true }),
            },
            {
                code: WATER_RELEASE_KEYS.CREATED_BY,
                type: TABLE_DATA_TYPES.AUTOCOMPLETE,
                valueKey: 'username',
                elementInfo: woFields.createdby,
                complex: true,
                translationKey: TRANSLATION_KEYS.CREATED_BY,
                getAttribute: () => ATTRIBUTES.READONLY,
            },
            {
                code: WATER_RELEASE_KEYS.MODIFIED_ON,
                type: TABLE_DATA_TYPES.DATE_TIME,
                elementInfo: woFields.updatedOn,
                getAttribute: () => ATTRIBUTES.READONLY,
                translationKey: TRANSLATION_KEYS.UPDATED_ON,
                customInputProps: (_) => ({ timestamp: true }),
            },
            {
                code: WATER_RELEASE_KEYS.MODIFIED_BY,
                type: TABLE_DATA_TYPES.AUTOCOMPLETE,
                valueKey: 'username',
                elementInfo: {
                    text: getTranslation('UPDATEDBY'),
                },
                translationKey: TRANSLATION_KEYS.UPDATED_BY,
                complex: true,
                getAttribute: () => ATTRIBUTES.READONLY,
            },
        ]);

export const fieldGenerator = ({
    fieldRefs,
    field,
    writeAccess,
    properties,
    storeActions,
    wrrGetters,
    getTranslation,
}) =>
    InputGenerator.generate({
        key: field.code,
        field: {
            ...field,
            elementInfo: {
                xpath: field.code,
                ...field.elementInfo,
                ...(field.translationKey ? { text: getTranslation(field.translationKey) } : {}),
            },
            getAttribute: (object) => {
                const attr = field.getAttribute?.(object);
                return writeAccess ? attr : attr !== ATTRIBUTES.HIDDEN ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN;
            },
            code: field.valueKey ?? field.code,
        },
        formFields: fieldRefs,
        object:
            field.type === TABLE_DATA_TYPES.AUTOCOMPLETE || field.complex
                ? wrrGetters.getProperties()[field.code] || {}
                : properties,
        updateObject: (key, value) => {
            const updateObject = { [key]: value };
            field.type === TABLE_DATA_TYPES.AUTOCOMPLETE || field.complex
                ? storeActions.updateWRRRequestPartial(field.code, updateObject)
                : storeActions.updateWRRRequest(updateObject);
        },
        hideLabel: false,
    });

export const WRRAssociatedWOsInfoPanel = ({ properties, getTranslation, dropdowns, lang = 'EN' }) => {
    const rpm = properties[WATER_RELEASE_KEYS.RPM_NUMBER];
    const rpar = properties[WATER_RELEASE_KEYS.RPAR_NUMBER];
    const labelStyle = {
        color: 'rgb(0, 101, 152)',
        fontWeight: 'bold',
    };

    return (
        <div style={{ marginTop: 10 }}>
            {rpm && (
                <div style={{ border: '2px solid #4c96f2', borderRadius: '6px', padding: '8px', marginBottom: '4px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography style={labelStyle}>{getTranslation(TRANSLATION_KEYS.RP_MEASUREMENT)}</Typography>
                        <Link to={`/rpmperform?wo=${rpm?.jobNumber}`} target="_blank">
                            {rpm?.jobNumber}
                        </Link>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography style={labelStyle}>{getTranslation(TRANSLATION_KEYS.STATUS)}</Typography>
                        <Typography>{`${getTranslation(ENTITY_TRANSL_PREFIX.EVT_STATUS + rpm.statusCode)} (${
                            rpm.statusCode
                        })`}</Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography style={labelStyle}>{getTranslation(TRANSLATION_KEYS.CLASSIFICATION)}</Typography>
                        <Typography>{rpm?.rwpEquipmentBase?.rpClassification}</Typography>
                    </div>
                    {rpm?.createdDate && (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography style={labelStyle}>{getTranslation(TRANSLATION_KEYS.CREATED_BY)}</Typography>
                            <div>
                                <Typography>
                                    {rpm?.enteredBy?.description} ({rpm?.enteredBy?.cernId})
                                </Typography>
                                <Typography style={{ textAlign: 'right' }}>
                                    on{' '}
                                    {format(rpm?.createdDate, DATE_TIME_FORMAT.HUMAN_DATE_FNS, {
                                        locale: lang === 'FR' ? fr : enUS,
                                    })}
                                </Typography>
                            </div>
                        </div>
                    )}
                    {rpm?.updatedDate && (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography style={labelStyle}>{getTranslation(TRANSLATION_KEYS.UPDATED_BY)}</Typography>
                            <div>
                                <Typography>
                                    {rpm?.performedBy?.description} ({rpm?.performedBy?.cernId})
                                </Typography>
                                <Typography style={{ textAlign: 'right' }}>
                                    on{' '}
                                    {format(rpm?.updatedDate, DATE_TIME_FORMAT.HUMAN_DATE_FNS, {
                                        locale: lang === 'FR' ? fr : enUS,
                                    })}
                                </Typography>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {rpar && (
                <div style={{ border: '2px solid #4c96f2', borderRadius: '6px', padding: '8px' }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography style={labelStyle}>
                            {getTranslation(TRANSLATION_KEYS.RP_ANALYSIS_REQUEST)}
                        </Typography>

                        <Link to={`/rparequest/edit/${rpar?.workorder}`} target="_blank">
                            {rpar?.workorder}
                        </Link>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: 7,
                        }}
                    >
                        <Typography style={labelStyle}>{getTranslation(TRANSLATION_KEYS.STATUS)}</Typography>
                        <Typography>{`${getTranslation(ENTITY_TRANSL_PREFIX.EVT_STATUS + rpar.status)} (${
                            rpar.status
                        })`}</Typography>
                    </div>

                    <Typography style={labelStyle}>
                        <Link to={`/rpanalysis?request=${rpar?.workorder}`} target="_blank">
                            {getTranslation(TRANSLATION_KEYS.RP_ANALYSIS_JOBS)}
                        </Link>
                    </Typography>

                    {Object.entries(rpar.analysisPropertiesMap || {}).map(([key, val], i) => (
                        <div
                            key={key}
                            style={{
                                paddingLeft: 20,
                                borderBottom: '1px solid #e4e7ea',
                                borderTop: i === 0 ? '1px solid #e4e7ea' : 'none',
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography style={labelStyle}>
                                    {getTranslation(TRANSLATION_KEYS.ANALYSIS_TYPE)}
                                </Typography>
                                <Typography>{ANALYSIS_TYPES[key]}</Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                <Typography style={labelStyle}>{getTranslation(TRANSLATION_KEYS.JOBS)}</Typography>
                                <div>
                                    {val.map((reportJob) =>
                                        reportJob?.analysisWo ? (
                                            <Link to={`/rpanalysis/${reportJob?.analysisWo}`} target="_blank">
                                                {reportJob?.analysisWo}
                                            </Link>
                                        ) : (
                                            <Typography style={{ color: 'grey' }}>
                                                {getTranslation(TRANSLATION_KEYS.NOT_CREATED)}
                                            </Typography>
                                        )
                                    )}
                                </div>
                            </div>
                            {rpar.waterEliminationPathway && key === 'PHYSCHEM' && (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography style={labelStyle}>
                                        {getTranslation(TRANSLATION_KEYS.WATER_ELIMINATION_PATHWAY)}
                                    </Typography>
                                    {rpar.waterEliminationPathway && (
                                        <Typography>
                                            {`${((foundVal) => foundVal?.desc)(
                                                dropdowns.waterEliminationPathway.find(
                                                    (s) => s.code === rpar.waterEliminationPathway
                                                )
                                            )} (${rpar.waterEliminationPathway})`}
                                        </Typography>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export const generateWRRFields = ({
    fieldRefs,
    fields,
    writeAccess,
    properties,
    storeActions,
    wrrGetters,
    getTranslation,
}) =>
    fields.map((field) =>
        fieldGenerator({ fieldRefs, field, writeAccess, properties, storeActions, wrrGetters, getTranslation })
    );

export const WarningText = ({ text }) => (
    <div
        style={{
            border: '1px solid coral',
            backgroundColor: '#fd32',
            display: 'flex',
            alignItems: 'center',
            padding: 5,
            margin: 15,
            borderRadius: '4px',
        }}
    >
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Error style={{ fontSize: 35, color: 'coral' }} />
        </div>

        <Typography
            dangerouslySetInnerHTML={{ __html: text }}
            style={{ flex: 5, fontWeight: 'bold', color: 'coral' }}
        />
    </div>
);

export const TRECText = ({ text, style = {}, fontSize }) => (
    <div
        style={{
            border: '1px solid #00007F ',
            backgroundColor: '#EEEEFF',
            display: 'flex',
            alignItems: 'center',
            padding: 5,
            margin: 1,
            borderRadius: '4px',
            ...style,
        }}
    >
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Info style={{ fontSize: 35, color: '#00007F' }} />
        </div>

        <Typography
            dangerouslySetInnerHTML={{ __html: text }}
            style={{ flex: 5, fontWeight: 'bold', color: '#00007F ', fontSize }}
        />
    </div>
);
