import { PanTool, Search, VerifiedUser } from '@material-ui/icons';

import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Save from '@material-ui/icons/Save';
import { Reload } from 'mdi-material-ui';
import { TREC_GENERAL_KEYS } from '../../../enums/Constants';
import TRECButton from '../../components/buttons/TRECButton';
import { RW_ELIM_STEPS_KEY, RW_ELIMINATION_KEYS, RW_ELIMINATION_STATUS } from './RWEliminationConstants';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { useState } from 'react';

const RWEliminationWizardControls = (props) => {
    const {
        handleCancel,
        handleCreate,
        handlePrevious,
        handleNext,
        reload,
        isFirstStep,
        isLastStep,
        getTranslation,
        canUpdate,
        // currentStep,
        handleUpdate,
        eliminationNumber,
        showCreateEDHButton,
        handleCreateEDH,
        currentStepRef,
        statusCode,
        currentStep,
        rwElimRequest,
    } = props;

    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <>
            <TRECButton size="medium" onClick={handleCancel} color="default">
                <Search />
                {getTranslation(TREC_GENERAL_KEYS.BACK)}
            </TRECButton>

            <TRECButton size="medium" disabled={isFirstStep} onClick={handlePrevious}>
                <ArrowBack />
                {getTranslation('PREVIOUS')}
            </TRECButton>

            <TRECButton size="medium" disabled={isLastStep} onClick={handleNext}>
                <ArrowForward />
                {getTranslation(TREC_GENERAL_KEYS.NEXT)}
            </TRECButton>

            {eliminationNumber && (
                <TRECButton size="medium" color="text" onClick={reload}>
                    <Reload />
                    {getTranslation(TREC_GENERAL_KEYS.RELOAD)}
                </TRECButton>
            )}

            {canUpdate && eliminationNumber ? (
                <TRECButton size="medium" onClick={() => handleUpdate({ translationKey: 'JOB_SAVED' })}>
                    <Save />
                    {getTranslation(TREC_GENERAL_KEYS.SAVE)}
                </TRECButton>
            ) : (
                <TRECButton
                    size="medium"
                    color="secondary"
                    onClick={() => currentStepRef.canContinue() && handleCreate()}
                >
                    <VerifiedUser />
                    {getTranslation(TREC_GENERAL_KEYS.CREATE)}
                </TRECButton>
            )}

            {canUpdate && isLastStep && eliminationNumber ? (
                <>
                    {![RW_ELIMINATION_STATUS.CREATED, null, undefined].includes(statusCode) && (
                        <TRECButton
                            size="medium"
                            color="secondary"
                            style={{ backgroundColor: '#EE4B2B' }}
                            onClick={() =>
                                handleUpdate({
                                    translationKey: 'MODIFY_ACTION_TEXT',
                                    updateObject: { [RW_ELIMINATION_KEYS.STATUS]: RW_ELIMINATION_STATUS.CREATED },
                                })
                            }
                        >
                            <PanTool />
                            {getTranslation('MODIFY')}
                        </TRECButton>
                    )}
                    {!currentStep.includes('_CHKL_STEP') && (
                        <TRECButton
                            size="medium"
                            color="secondary"
                            onClick={() =>
                                currentStep === RW_ELIM_STEPS_KEY.FINISHED
                                    ? setDialogOpen(true)
                                    : currentStepRef.handleSubmit()
                            }
                        >
                            <VerifiedUser />
                            {getTranslation(TREC_GENERAL_KEYS.SUBMIT)}
                        </TRECButton>
                    )}
                </>
            ) : null}

            {showCreateEDHButton && isLastStep && (
                <TRECButton size="medium" onClick={handleCreateEDH}>
                    {getTranslation('CREATESHIP')}
                </TRECButton>
            )}

            {!showCreateEDHButton && rwElimRequest[RW_ELIMINATION_KEYS.EDH_NUMBER] && isLastStep && (
                <TRECButton
                    size="medium"
                    color="secondary"
                    onClick={() =>
                        handleUpdate({
                            translationKey: 'UNLINKSHIP_TEXT',
                            updateObject: { [RW_ELIMINATION_KEYS.EDH_NUMBER]: '' },
                        })
                    }
                >
                    {getTranslation('UNLINKSHIP')}
                </TRECButton>
            )}
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogContent>
                    <DialogContentText>{getTranslation('COMPLETE_RW_ELIM_JOB')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="primary">
                        {getTranslation(TREC_GENERAL_KEYS.CANCEL)}
                    </Button>
                    <Button onClick={() => currentStepRef.handleSubmit()} color="primary" autoFocus>
                        {getTranslation(TREC_GENERAL_KEYS.CONFIRM)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RWEliminationWizardControls;
