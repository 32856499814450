import { Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { handleError } from 'tools/TrecNotifications';
import WSEquipment from 'tools/rest/WSEquipment';
import { RPAR_PERFORM_KEYS, RPSM_KEYS, RP_MEASUREMENT_KEYS, TABLE_DATA_TYPES } from '../../../../enums/Constants';
import { ROUTES, generateRoute, getExternalLink, getInternalLink, withQueryParams } from '../../../../tools/Routes';
import InputGenerator from '../../rpmrequest2/InputGenerator';
import { CUTG10_FIELDS } from '../../rwmanagement/RWManagementUtils';
import { EQUIPMENT_KEYS } from '../../rwprocessing/RWPConstants';
import { BLOCK_KEYS, BLOCK_STATUS, EDH_PROPERTIES_KEYS, EVENT_KEYS, EVENT_TYPES } from './Constants';

const { ATTRIBUTES } = InputGenerator;

const generateInternalWorkOrderLink = (object, field) =>
    getInternalLink({
        route: generateRoute(ROUTES.workOrdersRedirect, { workOrderID: object[field.code] }),
        text: object[field.code],
        newTab: true,
    });

const generateInternalEquipmentLink = (object, field) =>
    getInternalLink({
        route: generateRoute(ROUTES.equipmentShow, { equipmentID: object[field.code] }),
        text: object[field.code],
    });

const getDropdownDescription = (object, field, dropdowns) => {
    const value = dropdowns.find((el) => el.code === object[field.code]) || {};
    return value.desc;
};

const DynLink = (code, index) => (
    <div
        key={code}
        style={{
            display: 'flex',
            alignItems: 'right',
            justifyContent: 'flex-start',
            flexShrink: 2,
            direction: 'column',
        }}
    >
        {/^[0-9]+$/.test(code) ? (
            <>
                <span style={{ marginLeft: '14px' }}>RWP&nbsp;</span>
                {getInternalLink({
                    route: generateRoute(ROUTES.workOrdersRedirect, { workOrderID: code }),
                    text: code,
                })}
            </>
        ) : (
            <>
                <span>{index / 2 + 1}.&nbsp;</span>
                <Link to={`/equipment/${code}`} target="_self">
                    {code}
                </Link>
            </>
        )}
    </div>
);

export default function getBlocks({
    equipment,
    eventEntries,
    getLazyDataEntry,
    dropdowns,
    applicationData,
    analysisEventKeys,
    blockStatus,
    screenData,
    tempState,
    setTempState,
}) {
    const { lists } = screenData;

    const lastEDHEvent = [eventEntries[EVENT_TYPES.EDHEDH], eventEntries[EVENT_TYPES.EDHTREC]]
        .filter(Boolean)
        .sort((a, b) => b[EVENT_KEYS.EVT_COMPLETED].localeCompare(a[EVENT_KEYS.EVT_COMPLETED]))[0];

    return {
        [BLOCK_KEYS.QUERY_DATA]: {
            translationKey: BLOCK_KEYS.QUERY_DATA,
            isVisible: () => !!equipment[EQUIPMENT_KEYS.RW_RECEPTION_DATE],
            rows: [
                [
                    {
                        code: 'fac',
                        type: TABLE_DATA_TYPES.SELECT,
                        getAttribute: () => ATTRIBUTES.OPTIONAL,
                        // renderValue: (object, field) => object[field.code].join(', '),
                        values: applicationData.facilities.filter((fac) =>
                            equipment[EQUIPMENT_KEYS.FACILITIES].includes(fac.code)
                        ),
                        customInputProps: () => ({
                            multi: false,
                        }),
                        updateObject: (key, val) => setTempState((prevState) => ({ ...prevState, [key]: val })),
                        object: tempState,
                    },
                    {
                        code: 'facOrigin',
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.STATIC,
                        customValue: () =>
                            tempState.facilityOrigin ? (
                                tempState.facilityOrigin.map(DynLink)
                            ) : (
                                <Button
                                    onClick={async () => {
                                        try {
                                            const path = await WSEquipment.queryFacility({
                                                equipmentID: equipment[EQUIPMENT_KEYS.CODE],
                                                facility: tempState.fac,
                                            });
                                            setTempState((prevState) => ({ ...prevState, facilityOrigin: path }));
                                        } catch (err) {
                                            handleError(err);
                                        }
                                    }}
                                    style={{ boxShadow: 'unset' }}
                                    variant="contained"
                                    color="primary"
                                    disabled={!tempState.fac}
                                >
                                    Fetch
                                </Button>
                            ),
                    },
                    {
                        code: 'minIrradEndDate',
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.STATIS,
                        customValue: () =>
                            tempState.minIrradEndDate ? (
                                tempState.minIrradEndDate[0]?.map(DynLink)
                            ) : (
                                <Button
                                    key="minIrradEndDate"
                                    onClick={async () => {
                                        try {
                                            const path = await WSEquipment.queryMinIrradEndDate({
                                                equipmentID: equipment[EQUIPMENT_KEYS.CODE],
                                            });
                                            setTempState((prevState) => ({ ...prevState, minIrradEndDate: path }));
                                        } catch (err) {
                                            handleError(err);
                                        }
                                    }}
                                    style={{ boxShadow: 'unset' }}
                                    variant="contained"
                                    color="primary"
                                >
                                    Fetch
                                </Button>
                            ),
                    },
                    {
                        code: 'maxIrradEndDate',
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.STATIS,
                        customValue: () =>
                            tempState.maxIrradEndDate ? (
                                tempState.maxIrradEndDate[0]?.map(DynLink)
                            ) : (
                                <Button
                                    key="maxIrradEndDate"
                                    onClick={async () => {
                                        try {
                                            const path = await WSEquipment.queryMaxIrradEndDate({
                                                equipmentID: equipment[EQUIPMENT_KEYS.CODE],
                                            });
                                            setTempState((prevState) => ({ ...prevState, maxIrradEndDate: path }));
                                        } catch (err) {
                                            handleError(err);
                                        }
                                    }}
                                    style={{ boxShadow: 'unset' }}
                                    variant="contained"
                                    color="primary"
                                >
                                    Fetch
                                </Button>
                            ),
                    },
                ],
            ],
        },
        [BLOCK_KEYS.EQUIPMENT_DETAILS]: {
            translationKey: BLOCK_KEYS.EQUIPMENT_DETAILS,
            isVisible: () => true,
            isLoading: () => blockStatus[BLOCK_KEYS.EQUIPMENT_DETAILS] === BLOCK_STATUS.LOADING,
            rows: [
                [
                    {
                        code: EQUIPMENT_KEYS.USER_STATUS,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.SERIALNO,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.CONTAINER_INTERNAL_WIDTH,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.CONTAINER_INTERNAL_LENGTH,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.CONTAINER_INTERNAL_HEIGHT,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.CONTAINER_USEFUL_VOLUME,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.CONTAINER_TARE_WEIGHT,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.RESPONSIBLE,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: (object, field) => {
                            const responsible = getLazyDataEntry(object[EQUIPMENT_KEYS.CODE])[
                                EQUIPMENT_KEYS.RESPONSIBLE
                            ];
                            return responsible ? (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="body1">
                                        <strong>{object[field.code]}</strong>
                                    </Typography>
                                    <Typography variant="body1">{responsible.desc}</Typography>
                                </div>
                            ) : (
                                <Typography variant="body1">
                                    <strong>{object[field.code]}</strong>
                                </Typography>
                            );
                        },
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.WIDTH,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.LENGTH,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.HEIGHT,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.VALUE,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.GROSS_WEIGHT,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.FAMILY,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: (object, field) =>
                            (lists.objFamily.find((e) => e.code === object[field.code]) || {}).desc,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.MAIN_MATERIAL,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: (object, field) =>
                            (lists.objMainMaterial.find((e) => e.code === object[field.code]) || {}).desc,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.FACILITIES,
                        type: TABLE_DATA_TYPES.SELECT,
                        getAttribute: () => ATTRIBUTES.READONLY,
                        // renderValue: (object, field) => object[field.code].join(', '),
                        values: applicationData.facilities,
                        customInputProps: () => ({
                            multi: true,
                        }),
                        object: equipment,
                    },
                    ...(applicationData.isHazardsFeatureActive
                        ? [
                              {
                                  code: EQUIPMENT_KEYS.HAZARDS,
                                  type: TABLE_DATA_TYPES.SELECT,
                                  customInputProps: () => ({
                                      multi: true,
                                      searchable: true,
                                  }),
                                  getAttribute: () => ATTRIBUTES.READONLY,
                                  customValue: (object, field) =>
                                      (object[field.code] || []).map((hazard) => hazard.hazardTRECCode),
                                  values: Object.values(applicationData.hazards)
                                      .filter((hazard) => hazard.hazardClass === applicationData.hazardClass)
                                      .map(({ code, description: desc }) => ({ code, desc })),
                                  object: equipment,
                              },
                          ]
                        : []),
                ],
            ],
            doclightPanelInfo: {
                translationKey: 'Attached images',
                objectType: 'A',
                objectID: equipment[EQUIPMENT_KEYS.CODE],
                documentType: 'Illustration / Picture',
            },
        },
        [BLOCK_KEYS.LOCATION]: {
            translationKey: BLOCK_KEYS.LOCATION,
            isVisible: () => true,
            rows: [
                [
                    {
                        code: EQUIPMENT_KEYS.LOCATION,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: equipment.objLocation,
                        renderValue: (object) => (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="body1">
                                    <strong>{object.code}</strong>
                                </Typography>
                                <Typography variant="body1">{object.desc}</Typography>
                            </div>
                        ),
                    },
                    {
                        code: 'areaClassificationDesc',
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: equipment.objLocation,
                        renderValue: (object) => (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="body1">
                                    <strong>{object.areaClassificationId}</strong>
                                </Typography>
                                <Typography variant="body1">{object.areaClassificationDesc}</Typography>
                            </div>
                        ),
                    },
                    ...(equipment[EQUIPMENT_KEYS.CLASS] === 'PW20'
                        ? [
                              {
                                  code: RPSM_KEYS.ORIGIN,
                                  type: TABLE_DATA_TYPES.STATIC,
                                  getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                                  object: equipment,
                                  renderValue: (object) => (
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                          <Typography variant="body1">
                                              <strong>{object[RPSM_KEYS.ORIGIN]}</strong>
                                          </Typography>
                                          <Typography variant="body1">
                                              {object[RPSM_KEYS.ORIGIN_DESCRIPTION]}
                                          </Typography>
                                      </div>
                                  ),
                              },
                          ]
                        : []),
                ],
            ],
        },
        [BLOCK_KEYS.LAST_RPM_JOB]: {
            translationKey: BLOCK_KEYS.LAST_RPM_JOB,
            isVisible: () => eventEntries[EVENT_TYPES.RPM],
            isLoading: () => blockStatus[BLOCK_KEYS.LAST_RPM_JOB] === BLOCK_STATUS.LOADING,
            rows: [
                [
                    {
                        code: EVENT_KEYS.CODE,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: generateInternalWorkOrderLink,
                        object: eventEntries[EVENT_TYPES.RPM],
                    },
                    {
                        code: EVENT_KEYS.EVT_DESC,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY,
                        object: eventEntries[EVENT_TYPES.RPM],
                    },
                    {
                        code: EVENT_KEYS.EVT_COMPLETED,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: eventEntries[EVENT_TYPES.RPM],
                    },
                    {
                        code: EVENT_KEYS.PERFORMED_BY_LOGIN,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: eventEntries[EVENT_TYPES.RPM],
                    },
                    {
                        code: RP_MEASUREMENT_KEYS.MEASURE_CONTACT,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: (object) =>
                            object[RP_MEASUREMENT_KEYS.MEASURE_CONTACT]
                                ? [
                                      object[RP_MEASUREMENT_KEYS.MEASURE_CONTACT_QUALIFIER],
                                      object[RP_MEASUREMENT_KEYS.MEASURE_CONTACT],
                                  ]
                                      .filter(Boolean)
                                      .join(' ')
                                : null,
                        object: getLazyDataEntry(EVENT_TYPES.RPM),
                    },
                    {
                        code: RP_MEASUREMENT_KEYS.MEASURE10CM,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: (object) =>
                            object[RP_MEASUREMENT_KEYS.MEASURE10CM]
                                ? [
                                      object[RP_MEASUREMENT_KEYS.MEASURE10CM_QUALIFIER],
                                      object[RP_MEASUREMENT_KEYS.MEASURE10CM],
                                  ]
                                      .filter(Boolean)
                                      .join(' ')
                                : null,
                        object: getLazyDataEntry(EVENT_TYPES.RPM),
                    },
                    {
                        code: RP_MEASUREMENT_KEYS.MEASURE40CM,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: (object) =>
                            object[RP_MEASUREMENT_KEYS.MEASURE40CM]
                                ? [
                                      object[RP_MEASUREMENT_KEYS.MEASURE40CM_QUALIFIER],
                                      object[RP_MEASUREMENT_KEYS.MEASURE40CM],
                                  ]
                                      .filter(Boolean)
                                      .join(' ')
                                : null,
                        object: getLazyDataEntry(EVENT_TYPES.RPM),
                    },
                    {
                        code: RP_MEASUREMENT_KEYS.MEASURE_BGO,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: (object) =>
                            object[RP_MEASUREMENT_KEYS.MEASURE_BGO]
                                ? [
                                      object[RP_MEASUREMENT_KEYS.MEASURE_BGO_QUALIFIER],
                                      object[RP_MEASUREMENT_KEYS.MEASURE_BGO],
                                  ]
                                      .filter(Boolean)
                                      .join(' ')
                                : null,
                        object: getLazyDataEntry(EVENT_TYPES.RPM),
                    },
                ],
            ],
            doclightPanelInfo: {
                translationKey: 'Attached images',
                objectType: 'J',
                objectID: eventEntries[EVENT_TYPES.RPM]?.CODE,
                documentType: 'Illustration / Picture',
            },
        },
        [BLOCK_KEYS.LAST_RPA_REQUEST]: {
            translationKey: BLOCK_KEYS.LAST_RPA_REQUEST,
            isVisible: () => eventEntries[EVENT_TYPES.RPA_REQUEST],
            rows: [
                [
                    {
                        code: EVENT_KEYS.CODE,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: generateInternalWorkOrderLink,
                        object: eventEntries[EVENT_TYPES.RPA_REQUEST],
                    },
                    {
                        code: EVENT_KEYS.EVT_DESC,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY,
                        object: eventEntries[EVENT_TYPES.RPA_REQUEST],
                    },
                    {
                        code: EVENT_KEYS.EVT_COMPLETED,
                        type: TABLE_DATA_TYPES.DATE,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: eventEntries[EVENT_TYPES.RPA_REQUEST],
                    },
                    {
                        code: EVENT_KEYS.PERFORMED_BY_LOGIN,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: eventEntries[EVENT_TYPES.RPA_REQUEST],
                    },
                ],
            ],
        },
        [BLOCK_KEYS.LAST_RPA_JOBS]: {
            translationKey: BLOCK_KEYS.LAST_RPA_JOBS,
            isVisible: () => analysisEventKeys.length,
            isLoading: () => blockStatus[BLOCK_KEYS.LAST_RPA_JOBS] === BLOCK_STATUS.LOADING,
            rows: analysisEventKeys.map((eventKey) => [
                {
                    code: EVENT_KEYS.CODE,
                    type: TABLE_DATA_TYPES.STATIC,
                    getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                    renderValue: generateInternalWorkOrderLink,
                    object: eventEntries[eventKey],
                },
                {
                    code: EVENT_KEYS.EVT_DESC,
                    type: TABLE_DATA_TYPES.STATIC,
                    getAttribute: () => ATTRIBUTES.READONLY,
                    object: eventEntries[eventKey],
                },
                {
                    code: EVENT_KEYS.EVT_CLASS,
                    type: TABLE_DATA_TYPES.STATIC,
                    getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                    object: eventEntries[eventKey],
                },
                {
                    code: EVENT_KEYS.EVT_COMPLETED,
                    type: TABLE_DATA_TYPES.DATE,
                    getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                    object: eventEntries[eventKey],
                },
                {
                    code: EVENT_KEYS.PERFORMED_BY_LOGIN,
                    type: TABLE_DATA_TYPES.STATIC,
                    getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                    object: eventEntries[eventKey],
                },
                {
                    code: RPAR_PERFORM_KEYS.REPORTWO,
                    type: TABLE_DATA_TYPES.STATIC,
                    getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                    object: getLazyDataEntry(eventKey),
                },
                {
                    code: RPAR_PERFORM_KEYS.LL_VALUE,
                    type: TABLE_DATA_TYPES.STATIC,
                    getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                    object: getLazyDataEntry(eventKey)[RPAR_PERFORM_KEYS.LL_VALUE],
                },
                // TODO, both IRAS and Measure beta will be added to RPA process, uncomment and rework renderValue for measureBeta
                // {
                //     code: RPAR_PERFORM_KEYS.IRAS,
                //     type: TABLE_DATA_TYPES.STATIC,
                //     getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                //     object: getLazyDataEntry(eventKey),
                // },
                // {
                //     code: RP_MEASUREMENT_KEYS.MEASURE_BETA,
                //     type: TABLE_DATA_TYPES.STATIC,
                //     getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                //     renderValue: (object) => {
                //         const measurement = (object.equipmentMeasurementMap || {})[equipment[EQUIPMENT_KEYS.CODE]] || {};
                //         return measurement[RP_MEASUREMENT_KEYS.MEASURE_BETA] ? (
                //             [measurement[RP_MEASUREMENT_KEYS.MEASURE_BETA_QUALIFIER], measurement[RP_MEASUREMENT_KEYS.MEASURE_BETA]].filter(Boolean).join(" ")
                //         ) : null;
                //     },
                //     object: getLazyDataEntry(eventKey),
                // },
            ]),
        },
        [BLOCK_KEYS.LAST_CADRA]: {
            translationKey: BLOCK_KEYS.LAST_CADRA,
            isVisible: () => eventEntries[EVENT_TYPES.CADRA],
            rows: [
                [
                    {
                        code: EVENT_KEYS.CODE,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: generateInternalWorkOrderLink,
                        object: eventEntries[EVENT_TYPES.CADRA],
                    },
                    {
                        code: EVENT_KEYS.EVT_COMPLETED,
                        type: TABLE_DATA_TYPES.DATE,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: eventEntries[EVENT_TYPES.CADRA],
                    },
                ],
            ],
        },
        [BLOCK_KEYS.LAST_RW_CHECK]: {
            translationKey: BLOCK_KEYS.LAST_RW_CHECK,
            isVisible: () => eventEntries[EVENT_TYPES.RW_CHECK],
            rows: [
                [
                    {
                        code: EVENT_KEYS.CODE,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: generateInternalWorkOrderLink,
                        object: eventEntries[EVENT_TYPES.RW_CHECK],
                    },
                    {
                        code: EVENT_KEYS.EVT_COMPLETED,
                        type: TABLE_DATA_TYPES.DATE,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: eventEntries[EVENT_TYPES.RW_CHECK],
                    },
                ],
            ],
            doclightPanelInfo: {
                translationKey: 'Attached images',
                objectType: 'J',
                objectID: eventEntries[EVENT_TYPES.RW_CHECK]?.CODE,
                documentType: 'Illustration / Picture',
            },
        },
        [BLOCK_KEYS.LAST_RWP_CHECK]: {
            translationKey: BLOCK_KEYS.LAST_RWP_CHECK,
            isVisible: () => eventEntries[EVENT_TYPES.RWP],
            rows: [
                [
                    {
                        code: EVENT_KEYS.CODE,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: generateInternalWorkOrderLink,
                        object: eventEntries[EVENT_TYPES.RWP],
                    },
                    {
                        code: EVENT_KEYS.EVT_COMPLETED,
                        type: TABLE_DATA_TYPES.DATE,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: eventEntries[EVENT_TYPES.RWP],
                    },
                ],
            ],
        },
        [BLOCK_KEYS.LAST_RP_SAMPLING]: {
            translationKey: BLOCK_KEYS.LAST_RP_SAMPLING,
            isVisible: () => eventEntries[EVENT_TYPES.SAMPLING],
            rows: [
                [
                    {
                        code: EVENT_KEYS.CODE,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: generateInternalEquipmentLink,
                        object: eventEntries[EVENT_TYPES.SAMPLING],
                    },
                    {
                        code: EVENT_KEYS.EVT_COMPLETED,
                        type: TABLE_DATA_TYPES.DATE,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: eventEntries[EVENT_TYPES.SAMPLING],
                    },
                ],
            ],
        },
        [BLOCK_KEYS.EDH_PROPERTIES]: {
            translationKey: BLOCK_KEYS.EDH_PROPERTIES,
            isVisible: () => lastEDHEvent,
            rows: [
                [
                    {
                        code: EVENT_KEYS.CODE,
                        translationKey: 'EDHNUMBER',
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: (object, field) =>
                            getExternalLink({
                                url: `${applicationData.edhurl}${object[field.code]}`,
                                text: object[field.code],
                            }),
                        object: lastEDHEvent,
                    },
                    {
                        code: EDH_PROPERTIES_KEYS.LAST_EDH_CREATED_BY_TREC,
                        type: TABLE_DATA_TYPES.CHECKBOX,
                        getAttribute: () => ATTRIBUTES.READONLY,
                        customValue: (object) => object[EVENT_KEYS.GROUPID] === EVENT_TYPES.EDHTREC,
                        object: lastEDHEvent,
                    },
                ],
            ],
        },
        [BLOCK_KEYS.CONTAMINATION_PROPERTIES]: {
            translationKey: BLOCK_KEYS.CONTAMINATION_PROPERTIES,
            isVisible: () => true,
            rows: [
                [
                    {
                        code: EQUIPMENT_KEYS.CONTENT_CONTAMINATED,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: (object) => object[RP_MEASUREMENT_KEYS.CONTAMINATION],
                        object: getLazyDataEntry(EVENT_TYPES.RPM),
                    },
                    {
                        code: RP_MEASUREMENT_KEYS.MEASURE_ALPHA,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: (object) =>
                            object[RP_MEASUREMENT_KEYS.MEASURE_ALPHA]
                                ? [
                                      object[RP_MEASUREMENT_KEYS.MEASURE_ALPHA_QUALIFIER],
                                      object[RP_MEASUREMENT_KEYS.MEASURE_ALPHA],
                                  ]
                                      .filter(Boolean)
                                      .join(' ')
                                : null,
                        object: getLazyDataEntry(EVENT_TYPES.RPM),
                    },
                    {
                        code: RP_MEASUREMENT_KEYS.MEASURE_BETA,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: (object) =>
                            object[RP_MEASUREMENT_KEYS.MEASURE_BETA]
                                ? [
                                      object[RP_MEASUREMENT_KEYS.MEASURE_BETA_QUALIFIER],
                                      object[RP_MEASUREMENT_KEYS.MEASURE_BETA],
                                  ]
                                      .filter(Boolean)
                                      .join(' ')
                                : null,
                        object: getLazyDataEntry(EVENT_TYPES.RPM),
                    },
                ],
            ],
        },
        [BLOCK_KEYS.RADIATION_PROPERTIES]: {
            translationKey: BLOCK_KEYS.RADIATION_PROPERTIES,
            isVisible: () => true,
            rows: [
                [
                    {
                        code: EQUIPMENT_KEYS.RP_CLASSIFICATION,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.CONTAIN_ALPHA_EMITTER,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.IRRADIATION_END_DATE_MIN,
                        type: TABLE_DATA_TYPES.DATE,
                        getAttribute: () => ATTRIBUTES.READONLY,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.IRRADIATION_END_DATE_MAX,
                        type: TABLE_DATA_TYPES.DATE,
                        getAttribute: () => ATTRIBUTES.READONLY,
                        object: equipment,
                    },
                ],
            ],
        },
        [BLOCK_KEYS.RW_PROPERTIES]: {
            translationKey: BLOCK_KEYS.RW_PROPERTIES,
            isVisible: () => true,
            rows: [
                [
                    {
                        code: EQUIPMENT_KEYS.IS_RADIOACTIVE_WASTE,
                        type: TABLE_DATA_TYPES.CHECKBOX,
                        getAttribute: () => ATTRIBUTES.READONLY,
                        customValue: (object) =>
                            object[EQUIPMENT_KEYS.RW_RECEPTION_DATE] && object[EQUIPMENT_KEYS.USER_STATUS] === 'IW',
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.RW_RECEPTION_DATE,
                        type: TABLE_DATA_TYPES.DATE,
                        getAttribute: () => ATTRIBUTES.READONLY,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.RW_FAMILY,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: (object, field) => getDropdownDescription(object, field, dropdowns[field.code]),
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.RW_SUB_FAMILY,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: (object, field) => getDropdownDescription(object, field, dropdowns[field.code]),
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.MATERIAL,
                        type: TABLE_DATA_TYPES.SELECT,
                        getAttribute: () => ATTRIBUTES.READONLY,
                        object: equipment,
                        customInputProps: () => ({
                            multi: true,
                        }),
                        values: dropdowns[EQUIPMENT_KEYS.MATERIAL],
                    },
                    {
                        code: EQUIPMENT_KEYS.RW_PROCESSING_DATE,
                        type: TABLE_DATA_TYPES.DATE,
                        getAttribute: () => ATTRIBUTES.READONLY,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.RW_ELIMINATION_DATE,
                        type: TABLE_DATA_TYPES.DATE,
                        getAttribute: () => ATTRIBUTES.READONLY,
                        object: equipment,
                    },
                    {
                        code: EQUIPMENT_KEYS.ELIMINATION_PROCESS,
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: (object, field) => getDropdownDescription(object, field, dropdowns[field.code]),
                        object: equipment,
                    },
                    {
                        code: 'RWMLINK',
                        type: TABLE_DATA_TYPES.STATIC,
                        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
                        renderValue: (object) =>
                            getInternalLink({
                                route: withQueryParams({
                                    path: generateRoute(ROUTES.rwManagement),
                                    queryParams: {
                                        [CUTG10_FIELDS.OBJ_CODE]: object[EQUIPMENT_KEYS.CODE],
                                    },
                                }),
                                text: <i style={{ fontSize: 24 }} className="fa fa-cubes Fs100 Blue" />,
                            }),
                        object: equipment,
                    },
                ],
            ],
        },
    };
}
