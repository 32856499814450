import * as ACTIONS from '../actions/rpsmActions';
import Tools from '../tools/Tools';
import { EQUIPMENT_KEYS } from '../enums/Constants';

export const RPSM = 'RPSM';

// origins: {
//      [code + "#%#" + desc]: {
//          code:
//          desc:
//          sampleList:
//          samplingResponsible:
//          samplingDate:
//          location:
//          equipmentResponsible:
//          otherId:
//      }
// }

// samples: {
//      [code]: {
//          code:
//          desc:
//          containerCode:
//          currentSampleLocation:
//          samplingLocation:
//      }
// }

// analysisSelection: ["SMP-..", ...]

// properties: {},
// origins: {
//         0: {code : '', desc: '123'},
//         1: {code: '', desc: 'River - invented'},
//         2: {code: 'CR-TEST02', desc: 'This is a true desc of CR-TEST02'},
// }

const getNewRPSM = () => ({
    properties: {
        // purpose: 'CLAS'
    },
    // origins: Tools.aggregateObjects(Array(20).fill().map(i => ({[Math.floor(Math.random() * 10000) + '']: {code: '', desc: ''}}))),
    origins: {},
    samples: {},
    samplesForAnalysis: [],
});

export default function application(state = getNewRPSM(), action) {
    switch (action.type) {
        case ACTIONS.RESET:
            return getNewRPSM();
        case ACTIONS.UPDATE_PROPERTIES:
            return {
                ...state,
                properties: action.value,
            };
        case ACTIONS.UPDATE_SAMPLES:
            return {
                ...state,
                samples: action.value,
            };
        case ACTIONS.UPDATE_ORIGINS:
            return {
                ...state,
                origins: action.value,
            };
        case ACTIONS.UPDATE_SAMPLES_FOR_ANALYSIS:
            return {
                ...state,
                samplesForAnalysis: action.value,
            };
        default:
            return state;
    }
}

const getProperties = (state) => () => state.properties;
const getOrigins = (state) => () => state.origins;
const getSamples = (state) => () => state.samples;
const getAnalysisSelection = (state) => () => state.analysisSelection;
const getSamplesForAnalysis = (state) => () => state.samplesForAnalysis;
const getAllEquipmentCodes = (state) => () =>
    [...Object.values(state.origins).map((s) => s[EQUIPMENT_KEYS.EQUIPMENT_CODE]), ...Object.keys(state.samples)];
// This one should be memoized
const getOriginsWithCode = (state) => () =>
    Object.keys(state.origins).filter((key) => state.origins[key][EQUIPMENT_KEYS.EQUIPMENT_CODE]);

const rpsmGetters = {
    getProperties,
    getOrigins,
    getSamples,
    getAnalysisSelection,
    getSamplesForAnalysis,
    getOriginsWithCode,
    getAllEquipmentCodes,
};

// Method do retrieve the getters keeping closure over the state
export const rpsmGettersWithState = (state) => state && Tools.applyToFields(rpsmGetters, (func) => func(state[RPSM]));
