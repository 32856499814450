import WS from './WS';

/**
 * Handles all calls to WRM Comments REST Api
 */
class WSComments {
    readComments(entityCode, entityKeyCode, config = {}) {
        const encodedEntityKeyCode = encodeURIComponent(entityKeyCode);
        return WS._get(`/wrm/comments?entityCode=${entityCode}&entityKeyCode=${encodedEntityKeyCode}`, config);
    }

    createComment(comment, config = {}) {
        return WS._post('/wrm/comments/', comment, config);
    }

    updateComment(comment, config = {}) {
        return WS._put('/wrm/comments/', comment, config);
    }
}

export default new WSComments();
