import React from 'react';
import { connect } from 'react-redux';
import { parse as queryParse } from 'query-string';
import { setPage } from '../../../actions/layoutActions';
import EquipmentSearchPage from './EquipmentSearchPage';
import TRECPage from '../TRECPage';

const TRANSLATION_KEY = 'TREC_EQUIPMENT_SEARCH_PAGE';

class EquipmentSearchPageContainer extends TRECPage {
    renderPage(writeAccess) {
        const customProps = {
            ...this.props,
            writeAccess,
        };
        return <EquipmentSearchPage {...customProps} />;
    }
}

const mapStateToProps = (state, ownProps) => {
    const { application, layout } = state;
    const { screenData, menuType } = application;
    const { lang } = layout;
    const translations = screenData[lang].translations[TRANSLATION_KEY];
    const urlParams = queryParse(ownProps.location.search);

    return {
        masterPage: ['EQUIPMENT_PAGE'],
        page: 'EQUIPMENT_SEARCH_PAGE',
        menu: screenData[lang].menu[menuType],
        getTranslation: (code) =>
            code &&
            ((urlParams.lang === 'DEV' && code) ||
                (translations && translations[code.toUpperCase()]) ||
                (screenData.EN.translations[TRANSLATION_KEY] &&
                    screenData.EN.translations[TRANSLATION_KEY][code.toUpperCase()]) ||
                code),
    };
};

const containerize = (Component) =>
    connect(
        mapStateToProps,
        {
            setPage,
        },
        (stateProps, dispatchProps, ownProps) => ({
            ...ownProps,
            ...stateProps,
            storeActions: dispatchProps,
            setPage: dispatchProps.setPage,
        })
    )(Component);

export default containerize(EquipmentSearchPageContainer);
