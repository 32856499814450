import { openConfirmDialog } from 'react-eis-components/dist/actions/uiActions';
import { connect } from 'react-redux';
import { setPage } from '../../../actions/layoutActions';
import Tools from '../../../tools/Tools';
import RWCPerform from './RWCPerform';

function mapStateToProps(state) {
    const rwpDropdownsTranslated = Tools.applyToFields(state.application.rwpDropdowns, (list) =>
        list.map((codeDesc) => ({
            ...codeDesc,
            desc: (codeDesc.descTranslated && codeDesc.descTranslated[state.layout.lang]) || codeDesc.desc,
        }))
    );

    return {
        applicationData: state.application.applicationData,
        screenData: state.application.screenData,
        rwpDropdowns: rwpDropdownsTranslated,
        userData: state.application.userData,
        lang: state.layout.lang,
        translations: state.application.screenData[state.layout.lang].translations.TREC_PERFRWCHECK,
        customFields: state.application.screenData[state.layout.lang].customFields,
        customFieldsDef: state.application.screenData[state.layout.lang].customFieldsDef,
        customFieldsWaste: state.application.screenData[state.layout.lang].customFieldsWaste,
        woFields: state.application.screenData[state.layout.lang].woFields,
        assetFields: state.application.screenData[state.layout.lang].assetFields,
        lists: state.application.screenData[state.layout.lang].lists,
        masterPage: 'RWCHECKS',
        page: 'RWCPERFORM',
        menu: state.application.screenData[state.layout.lang].menu[state.application.menuType],
        constants: state.application.constants,
    };
}

const RWCPerformContainer = connect(mapStateToProps, {
    openConfirmDialog,
    setPage,
})(RWCPerform);

export default RWCPerformContainer;
