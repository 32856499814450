import { Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import CommentsContainer from 'eam-components/dist/ui/components/comments/CommentsContainer';
import React from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import { TABLE_DATA_TYPES } from 'enums/Constants';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import WSComments from 'tools/rest/WSComments';
import InputGenerator from '../InputGenerator';
import { EQUIPMENT_KEYS, REQUEST_KEYS } from '../RPMRConstants';
import { EquipmentDetails } from './RPMRDetailsStep';
import { RequestDetails } from './RPMRRequestStep';

const { ATTRIBUTES } = InputGenerator;

const getEquipmentFields = ({ assetFields, applicationData }) => [
    {
        code: EQUIPMENT_KEYS.CODE,
        type: TABLE_DATA_TYPES.STATIC,
        elementInfo: assetFields.equipmentno,
        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
    },
    {
        code: EQUIPMENT_KEYS.RESPONSIBLE_CID,
        renderValue: (eqp) => `${eqp[EQUIPMENT_KEYS.RESPONSIBLE_CID]} - ${eqp[EQUIPMENT_KEYS.RESPONSIBLE_DESC]}`,
        type: TABLE_DATA_TYPES.AUTOCOMPLETE,
        key: EQUIPMENT_KEYS.RESPONSIBLE_CID,
        descKey: EQUIPMENT_KEYS.RESPONSIBLE_DESC,
        elementInfo: assetFields.eqpResponsible,
        autocompleteType: WSAutocomplete.autocompleteEmployee,
        getAttribute: () => ATTRIBUTES.READONLY,
    },
    {
        code: EQUIPMENT_KEYS.LOCATION,
        type: TABLE_DATA_TYPES.LOOKUP_AUTOCOMPLETE,
        keysMap: (field) => ({
            code: 'obj_code',
            mapCodeTo: field.code,
            desc: 'obj_desc',
            mapDescTo: field.descKey,
        }),
        elementInfo: assetFields.location,
        gridId: applicationData.locationRaisinGridID,
        getAttribute: () => ATTRIBUTES.READONLY,
        autocompleteType: WSAutocomplete.autocompleteTRECLocation,
    },
    {
        code: EQUIPMENT_KEYS.LAST_LOCATION_CODE,
        type: TABLE_DATA_TYPES.LOOKUP_AUTOCOMPLETE,
        keysMap: (field) => ({
            code: 'obj_code',
            mapCodeTo: field.code,
            desc: 'obj_desc',
            mapDescTo: field.descKey,
        }),
        descKey: EQUIPMENT_KEYS.LAST_LOCATION_DESC,
        elementInfo: assetFields.lastLocation,
        getAttribute: () => ATTRIBUTES.READONLY,
        autocompleteType: WSAutocomplete.autocompleteTRECLocation,
    },
];
class RPMRConfirmStep extends WizardStep {
    formFields = [];

    canContinue = () => true;

    saveChanges = () => true;

    commitChanges = (callback) => callback();

    render() {
        const { translations, rpmrGetters, userData, screenData, constants, applicationData } = this.props;
        const properties = rpmrGetters.getProperties();
        const equipmentList = rpmrGetters.getEquipmentList();
        const equipmentMap = rpmrGetters.getEquipmentMap();
        const isMultipleEquipment = rpmrGetters.isMultipleEquipment();
        const isUpdateLocation = [constants.futureActionUpdateloc, constants.futureActionVacuumDeclare].includes(
            properties[REQUEST_KEYS.ACTION]
        );

        const equipmentFields = getEquipmentFields({
            assetFields: screenData.assetFields,
            applicationData,
        });

        const equipmentRenderFields = equipmentList.map((eqCode) =>
            equipmentFields.reduce(
                (acc, field) => ({
                    ...acc,
                    [field.code]: (
                        <div key={field.code}>
                            {InputGenerator.generate({
                                field: {
                                    ...field,
                                    elementInfo: {
                                        ...(field.elementInfo || {}),
                                        xpath: field.elementInfo ? `${field.elementInfo.xpath}_${eqCode}` : null,
                                    },
                                },
                                object: equipmentMap[eqCode],
                                hideLabel: true,
                                updateObject: () => 1,
                            })}
                        </div>
                    ),
                }),
                {}
            )
        );

        return (
            <div style={{ margin: 8 }}>
                <Grid container spacing={8}>
                    <Grid item xs={12} {...(!isMultipleEquipment ? { md: 6 } : {})}>
                        <EISPanel
                            heading={translations.EQPDETANDPHY}
                            detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                            alwaysExpanded
                        >
                            <h5>{translations.EQPCHARACTERISTIC}</h5>
                            <EquipmentDetails {...this.props} formFields={this.formFields} readOnly />
                            <h5>{translations.DETAILS}</h5>

                            <EISTable
                                data={equipmentRenderFields}
                                headers={equipmentFields.map((field) => field.elementInfo.text)}
                                propCodes={equipmentFields.map((field) => field.code)}
                                maxMobileSize={600}
                            />
                        </EISPanel>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        {!isUpdateLocation && (
                            <EISPanel
                                heading={translations.DETAILSRPMEAS}
                                detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                                alwaysExpanded
                            >
                                <RequestDetails {...this.props} formFields={this.formFields} readOnly />
                            </EISPanel>
                        )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                        {!rpmrGetters.isMultipleEquipment() && (
                            <CommentsContainer
                                title={translations.EQPCOMMENTS}
                                ref={(comments) => {
                                    this.comments = comments;
                                }}
                                entityCode="OBJ"
                                entityKeyCode={rpmrGetters.getEquipmentList()[0]}
                                userDesc={userData.eamAccount.userDesc}
                                readComments={WSComments.readComments}
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default RPMRConfirmStep;
