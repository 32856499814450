/* eslint-disable react/no-array-index-key */

import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = (theme) => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    success: {
        border: '1px #669966 solid',
        background: '#ccffcc',
    },
    warning: {
        border: '1px #c8b900 solid',
        background: '#fff59d',
    },
    error: {
        border: '1px #ba000d solid',
        background: '#f44336',
    },
    title: {
        fontSize: 16,
        fontWeight: 500,
    },
    message: {
        fontSize: 14,
        fontWeight: 500,
    },
});

function StaticMessage(props) {
    const { classes, message, title, type } = props;
    let rootClasses;
    if (type === 'warning') {
        rootClasses = classNames(classes.root, classes.warning);
    } else if (type === 'error') {
        rootClasses = classNames(classes.root, classes.error);
    } else {
        rootClasses = classNames(classes.root, classes.success);
    }
    return (
        <Paper className={rootClasses} elevation={1}>
            {title && (
                <Typography variant="headline" component="h3" className={classes.title}>
                    {title}
                </Typography>
            )}

            {/* Only one message */}
            {message && !Array.isArray(message) && (
                <Typography component="p" className={classes.message}>
                    {message}
                </Typography>
            )}

            {/* Array of messages */}
            {message &&
                Array.isArray(message) &&
                message.map((elem, index) => (
                    <Typography key={index} component="p" className={classes.message}>
                        {elem}
                    </Typography>
                ))}
        </Paper>
    );
}

StaticMessage.defaultProps = {
    type: 'success',
};

export default withStyles(styles)(StaticMessage);
