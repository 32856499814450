import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
// import { parse, sub } from 'date-fns';
import { TABLE_DATA_TYPES } from 'enums/Constants';
import { useMemo } from 'react';
import {
    VACUUM_RENTAL_KEYS,
    VACUUM_RENTAL_LINE_KEYS,
    VACUUM_TYPE_KEYS,
} from 'ui/pages/vaccleanerrequest/VacCleanerConstants';
import InputGenerator from '../../rpmrequest2/InputGenerator';
import { VAC_TABLE_KEYS, getVacCleanerLinesFields } from '../VacCleanerUtils';
import VacuumOverviewPanel from '../components/VacuumOverviewPanel';
import { showError } from 'tools/TrecNotifications';

const cellStyle = {
    textAlign: 'center',
    padding: '2px 10px 2px 10px',
    maxWidth: 180,
};

const tableFieldsDuration = [
    VAC_TABLE_KEYS.DELETE_LINE,
    VACUUM_RENTAL_LINE_KEYS.STATUS,
    // VACUUM_RENTAL_LINE_KEYS.VAC_TYPE,
    VAC_TABLE_KEYS.VAC_DESC,
    VACUUM_RENTAL_LINE_KEYS.LOCATION,
    VACUUM_RENTAL_LINE_KEYS.START_DATE,
    VAC_TABLE_KEYS.DURATION,
    VACUUM_RENTAL_LINE_KEYS.END_DATE,
    VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES,
];

export const getDurationFields = (getTranslation, adminMode, minDate, vacuumRequest) => [
    {
        code: VACUUM_RENTAL_KEYS.START_DATE,
        type: TABLE_DATA_TYPES.DATE_TIME,
        elementInfo: {
            text: getTranslation(VACUUM_RENTAL_KEYS.START_DATE),
        },
        relatedField: VACUUM_RENTAL_LINE_KEYS.START_DATE,
        customInputProps: () => ({
            timestamp: true,
            minDate: adminMode
                ? new Date(vacuumRequest[VACUUM_RENTAL_KEYS.CREATED_ON]).setFullYear(new Date().getFullYear() - 1)
                : minDate,
            minTime: adminMode ? 1 : minDate,
            maxDate: new Date(
                new Date(vacuumRequest[VACUUM_RENTAL_KEYS.CREATED_ON]).setFullYear(
                    new Date().getFullYear() + (adminMode ? 6 : 1)
                )
            ),
        }),
    },
    {
        code: VACUUM_RENTAL_KEYS.END_DATE,
        type: TABLE_DATA_TYPES.DATE_TIME,
        elementInfo: {
            text: getTranslation(VACUUM_RENTAL_KEYS.END_DATE),
        },
        relatedField: VACUUM_RENTAL_LINE_KEYS.END_DATE,
        customInputProps: ({ object }) => ({
            timestamp: true,
            minDate: object[VACUUM_RENTAL_KEYS.START_DATE],
            minTime: adminMode ? 1 : minDate,
            maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + (adminMode ? 6 : 1))),
        }),
    },
];

const getMinDate = ({ vacuumRequest, vacCleanerRentalLines, vacuumsData }) => {
    const minDate = vacuumRequest?.[VACUUM_RENTAL_KEYS.CREATED_ON]
        ? new Date(vacuumRequest?.[VACUUM_RENTAL_KEYS.CREATED_ON])
        : new Date();

    const mininumHours = Math.max(
        ...vacCleanerRentalLines
            .map((line) => line[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE])
            .map((type) => vacuumsData?.find((s) => s[VACUUM_TYPE_KEYS.CODE] === type))
            .map((vac) => vac?.[VACUUM_TYPE_KEYS.MINIMUM_DELIVERY_TIME_HOURS])
            .filter((t) => t)
    );

    minDate.setHours(minDate.getHours() + (mininumHours ?? 0));

    return minDate;
};

const VacCleanerDuration = ({
    formFields,
    labelStyle,
    vacuumRequest,
    storeActions,
    match,
    getTranslation,
    vacuumsData,
    adminMode,
}) => {
    const vacCleanerRequestNumber = match.params.vaccleanerNumber;
    const { vacCleanerRentalLines } = vacuumRequest;
    const minDate = getMinDate({ vacCleanerRentalLines, vacuumRequest, vacuumsData });

    const durationFields = getDurationFields(getTranslation, adminMode, minDate, vacuumRequest);

    const fields = useMemo(
        () =>
            getVacCleanerLinesFields(
                {
                    storeActions,
                    vacCleanerRequestNumber,
                    vacCleanerRentalLines,
                    labelStyle,
                    getTranslation,
                    editAsset: false,
                    editDates: true,
                    vacuumRequest,
                    vacuumsData,
                    adminMode,
                },
                tableFieldsDuration
            ),
        [vacCleanerRentalLines, vacuumsData]
    );

    return (
        <Grid
            container
            spacing={8}
            style={{ marginBottom: 20, width: '100%' }}
            direction="row"
            flexwrap="wrap"
            justify="space-between"
        >
            <Grid item xs={12} lg={9} xl={10} style={{ marginBottom: 20 }}>
                <EISPanel heading="DURATION" alwaysExpanded item>
                    <Grid item md={12} sm={12} xs={12} container>
                        <div style={{ width: '100%', marginTop: 0 }}>
                            {durationFields.map((field) =>
                                InputGenerator.generate({
                                    ...field,
                                    key: field.code,
                                    field,
                                    object: vacuumRequest,
                                    updateObject: (key, value) => {
                                        storeActions.updateVacRental(field.code, value);
                                        storeActions.updateVacRentalLine(null, {
                                            [field.relatedField]: value,
                                        });
                                    },
                                    formFields,
                                    labelStyle,
                                })
                            )}
                        </div>
                        <div style={{ width: '100%', marginTop: 0 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {fields.map((obj) => (
                                            <TableCell key={`header${obj.code}`} style={cellStyle}>
                                                {getTranslation(obj.translationKey || obj.code)}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {vacCleanerRentalLines.map((vacRentalLine) => (
                                        <TableRow key={vacRentalLine.code}>
                                            {fields.map((field) => (
                                                <TableCell style={cellStyle} key={field.code}>
                                                    {InputGenerator.generate({
                                                        field: {
                                                            ...field,
                                                            validate: field.validate?.(vacRentalLine),
                                                            elementInfo: {
                                                                xpath: `${field.code}#${vacRentalLine.code}`,
                                                                text: getTranslation(field.translationKey),
                                                            },
                                                        },
                                                        object: vacRentalLine,
                                                        updateObject: (_key, value) =>
                                                            storeActions.updateVacRentalLine(
                                                                vacRentalLine[VACUUM_RENTAL_LINE_KEYS.CODE],
                                                                { [field.code]: value }
                                                            ),
                                                        formFields,
                                                        labelStyle,
                                                    })}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </Grid>
                </EISPanel>
            </Grid>
            <Grid item xs={12} lg={3} xl={2} style={{ marginBottom: 20 }}>
                <VacuumOverviewPanel getTranslation={getTranslation} vacuumRequest={vacuumRequest} />
            </Grid>
        </Grid>
    );
};
class VacCleanerDurationStep extends WizardStep {
    formFields = [];

    prevValues = null;

    state = {
        isNewEquipment: false,
        newEquipment: {},
    };

    handleSubmit = () => {
        this.canContinue();
    };

    handleSave = () => {
        this.canContinue();
    };

    /**
     * Can continue to the next step on the wizard
     */
    canContinue = () => {
        const isValid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
        if (!isValid) {
            showError('One or more fields are not valid.');
        }

        return isValid;
    };

    /**
     * SaveChanges is used to save the localized state to the redux-store. It can only run synchronous methods.
     */
    saveChanges = () => true;

    /**
     *  CommitChanges is used to trigger any side effects of the step, such as invocations to the back-end to
     * retrieve information about resources. This method can run asynchronous methods/requests but SHALL execute
     * the callback method afterwards to inform the wizard.
     */
    commitChanges = async (callback) => {
        this.canContinue() && callback();
    };

    /**
     * On true, the wizard automatically jumps this step immediately after it is mounted
     */
    canJumpStep = () => false;

    render() {
        return <VacCleanerDuration formFields={this.formFields} {...this.props} />;
    }
}

export default VacCleanerDurationStep;
