import React, { Component } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import remove from 'lodash/remove';
import TRECPrefixInput from './radiationMeasurements/TRECPrefixInput';
import Tools from '../../../../tools/Tools';

class ContaminationMeasurements extends Component {
    getContaminationRiskValues = () => {
        const { constants, customFieldsDef, measurement } = this.props;
        const values = Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpContaminationRisk], true);
        // Contamination Risk can not be YES if equipment is not radioactive
        if (measurement.radiationClassification === constants.nonRadioactive) {
            remove(values, (value) => value.code === constants.trueYes);
        }
        return values;
    };

    _getContaminationAttribute = () => {
        // Contamination should be mandatory if radioactive
        // 2018-06-01: Mandatory now for all of the cases (Not only if Radioactive)
        const { constants, measurement } = this.props;
        if (measurement.statusCode === constants.statusWoT) return 'R';
        return 'O';
    };

    _getContaminationPropsAttribute = ({ field }) => {
        // All of the other properties are required if the contamination is Yes
        const { constants, measurement } = this.props;
        if (measurement.contaminationRisk === constants.trueYes) return 'R';
        switch (field) {
            case constants.cfpContaminationMeasTool:
            case constants.cfpBetaSurfacicCont:
                if (
                    measurement.statusCode === constants.statusWoT &&
                    measurement.contaminationRisk === constants.falseNo &&
                    measurement.measurementType === constants.cfvMeasurementTypeClearance
                ) {
                    return 'R';
                }
            // no-fallthrough
            default:
                return 'O';
        }
    };

    _onChangeContamination = (value) => {
        const { measurement, updateProperty } = this.props;
        const { jobNumber } = measurement;
        // Initialize always qualifiers if they don't have a value
        if (value && updateProperty) {
            if (!measurement.bkgAlphaQualifier) updateProperty(`${jobNumber}.bkgAlphaQualifier`, '=');
            if (!measurement.bkgBetaQualifier) updateProperty(`${jobNumber}.bkgBetaQualifier`, '=');
            if (!measurement.bkgAlphaNetQualifier) updateProperty(`${jobNumber}.bkgAlphaNetQualifier`, '=');
            if (!measurement.bkgBetaNetQualifier) updateProperty(`${jobNumber}.bkgBetaNetQualifier`, '=');
            if (!measurement.alphaSurfacicQualifier) updateProperty(`${jobNumber}.alphaSurfacicQualifier`, '=');
            if (!measurement.betaSurfacicQualifier) updateProperty(`${jobNumber}.betaSurfacicQualifier`, '=');
        }
    };

    // For when a contamination tool is selected
    _onChangeContaminationTool = (value) => {
        const { measurement, fetchUsersSerialDevice } = this.props;
        const path = `${measurement.jobNumber}.`;
        if (value && fetchUsersSerialDevice) {
            fetchUsersSerialDevice(value, `${path}serialContMeasTool`);
        }
    };

    validateFieldN = (value) => !Number.isNaN(value) && value >= 0 && value < 999999999999;

    render() {
        const {
            translations,
            constants,
            customFields,
            customFieldsDef,
            measurement,
            updateProperty,
            formFields,
            detailsStyle,
            readonly,
        } = this.props;
        const path = `${measurement.jobNumber}.`;

        return (
            <EISPanel detailsStyle={detailsStyle} heading={translations.CONTMEASUREMENTS}>
                <div style={{ width: '100%', marginTop: 0 }}>
                    <EAMSelect
                        elementInfo={{
                            ...customFields[constants.cfpContaminationRisk],
                            readonly,
                            attribute: this._getContaminationAttribute(),
                        }}
                        value={measurement.contaminationRisk}
                        updateProperty={updateProperty}
                        valueKey={`${path}contaminationRisk`}
                        values={this.getContaminationRiskValues()}
                        formFields={formFields}
                        onChangeValue={this._onChangeContamination}
                    />

                    <EAMSelect
                        elementInfo={{
                            ...customFields[constants.cfpContaminationMeasTool],
                            readonly,
                            attribute: this._getContaminationPropsAttribute({
                                field: constants.cfpContaminationMeasTool,
                            }),
                        }}
                        value={measurement.contMeasurementTool}
                        updateProperty={updateProperty}
                        valueKey={`${path}contMeasurementTool`}
                        values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpContaminationMeasTool])}
                        formFields={formFields}
                        onChangeValue={this._onChangeContaminationTool}
                    />

                    {/* Serial for contamination measurement tool */}
                    {measurement.contMeasurementTool && (
                        <EAMInput
                            formFields={formFields}
                            elementInfo={{
                                ...customFields[constants.cfpContaminationMeasToolSerial],
                                readonly,
                            }}
                            value={measurement.serialContMeasTool}
                            updateProperty={updateProperty}
                            valueKey={`${path}serialContMeasTool`}
                        />
                    )}

                    <EAMSelect
                        elementInfo={{
                            ...customFields[constants.cfpContaminationType],
                            readonly,
                            attribute: this._getContaminationPropsAttribute({ field: constants.cfpContaminationType }),
                        }}
                        value={measurement.contMeasurementType}
                        updateProperty={updateProperty}
                        valueKey={`${path}contMeasurementType`}
                        values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpContaminationType])}
                        formFields={formFields}
                    />

                    <TRECPrefixInput
                        codes={customFieldsDef[constants.cfpBkgQualifierAlpha].pcode}
                        elementInfo={{
                            ...customFields[constants.cfpBkgValueAlpha],
                            readonly,
                        }}
                        updateProperty={updateProperty}
                        value={measurement.bkgAlphaValue}
                        valueKey={`${path}bkgAlphaValue`}
                        prefixValue={measurement.bkgAlphaQualifier}
                        prefixValueKey={`${path}bkgAlphaQualifier`}
                        formFields={formFields}
                    />

                    <TRECPrefixInput
                        codes={customFieldsDef[constants.cfpBkgQualifierBeta].pcode}
                        elementInfo={{
                            ...customFields[constants.cfpBkgValueBeta],
                            readonly,
                        }}
                        updateProperty={updateProperty}
                        value={measurement.bkgBetaValue}
                        valueKey={`${path}bkgBetaValue`}
                        prefixValue={measurement.bkgBetaQualifier}
                        prefixValueKey={`${path}bkgBetaQualifier`}
                        formFields={formFields}
                    />

                    <TRECPrefixInput
                        codes={customFieldsDef[constants.cfpAlphaMeasQualifier].pcode}
                        elementInfo={{
                            ...customFields[constants.cfpAlphaNetValue],
                            readonly,
                        }}
                        updateProperty={updateProperty}
                        value={measurement.bkgAlphaNetValue}
                        valueKey={`${path}bkgAlphaNetValue`}
                        prefixValue={measurement.bkgAlphaNetQualifier}
                        prefixValueKey={`${path}bkgAlphaNetQualifier`}
                        formFields={formFields}
                    />

                    <TRECPrefixInput
                        codes={customFieldsDef[constants.cfpBetaMeasQualifier].pcode}
                        elementInfo={{
                            ...customFields[constants.cfpBetaNetValue],
                            readonly,
                        }}
                        updateProperty={updateProperty}
                        value={measurement.bkgBetaNetValue}
                        valueKey={`${path}bkgBetaNetValue`}
                        prefixValue={measurement.bkgBetaNetQualifier}
                        prefixValueKey={`${path}bkgBetaNetQualifier`}
                        formFields={formFields}
                    />

                    <TRECPrefixInput
                        codes={customFieldsDef[constants.cfpAlphaSurfacicQualifier].pcode}
                        elementInfo={{
                            ...customFields[constants.cfpAlphaSurfacicCont],
                            readonly,
                            attribute: this._getContaminationPropsAttribute({ field: constants.cfpAlphaSurfacicCont }),
                        }}
                        updateProperty={updateProperty}
                        value={measurement.alphaSurfacicCont}
                        valueKey={`${path}alphaSurfacicCont`}
                        prefixValue={measurement.alphaSurfacicQualifier}
                        prefixValueKey={`${path}alphaSurfacicQualifier`}
                        formFields={formFields}
                    />

                    <TRECPrefixInput
                        codes={customFieldsDef[constants.cfpBetaSurfacicQualifier].pcode}
                        elementInfo={{
                            ...customFields[constants.cfpBetaSurfacicCont],
                            readonly,
                            attribute: this._getContaminationPropsAttribute({ field: constants.cfpBetaSurfacicCont }),
                        }}
                        updateProperty={updateProperty}
                        value={measurement.betaSurfacicCont}
                        valueKey={`${path}betaSurfacicCont`}
                        prefixValue={measurement.betaSurfacicQualifier}
                        prefixValueKey={`${path}betaSurfacicQualifier`}
                        formFields={formFields}
                    />
                </div>
            </EISPanel>
        );
    }
}

export default ContaminationMeasurements;
