import { Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import { RPSM_KEYS } from 'enums/Constants';
import React from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import Tools from 'tools/Tools';

class RPSMPurpose extends WizardStep {
    state = {};

    formFields = {};

    updateThisState = Tools.updateThisState;

    validate = () => Object.values(this.formFields).reduce((acc, ff) => (!ff.validate || ff.validate()) && acc, true);

    canContinue = () => this.validate();

    saveChanges = (_) => {
        const { rpsmActions } = this.props;
        rpsmActions.updateProperties(this.state.properties);
        return true;
    };

    commitChanges = (callback) => {
        callback();
    };

    static getDerivedStateFromProps(props, state) {
        return {
            properties: props.rpsmGetters.getProperties(),
            ...state,
        };
    }

    render() {
        const { rpsmValues, getTranslation } = this.props;
        const { properties } = this.state;
        if (!properties) return null;
        return (
            <Grid container spacing={0}>
                <Grid item md={6} sm={12} xs={12}>
                    <EISPanel heading={getTranslation(RPSM_KEYS.PURPOSE_STEP)} alwaysExpanded>
                        <EAMSelect
                            elementInfo={{
                                attribute: 'R',
                                text: getTranslation(RPSM_KEYS.PURPOSE),
                            }}
                            formFields={this.formFields}
                            values={rpsmValues[RPSM_KEYS.PURPOSE]}
                            valueKey={[RPSM_KEYS.PURPOSE]}
                            value={properties[RPSM_KEYS.PURPOSE]}
                            updateProperty={(key, value) => this.updateThisState(['properties', key], value)}
                        />
                    </EISPanel>
                </Grid>
            </Grid>
        );
    }
}

export default RPSMPurpose;
