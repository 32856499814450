import { AMMSelect } from 'amm-tools';
import { TABLE_DATA_TYPES } from 'enums/Constants';
import React, { useState } from 'react';
import WS from 'tools/rest/WS';
import TRECButton from 'ui/components/buttons/TRECButton';
import InputGenerator, { ATTRIBUTES } from 'ui/pages/rpmrequest2/InputGenerator';
import TRECPage from '../TRECPage';
import { handleError, showSuccess } from 'tools/TrecNotifications';

const SettingsPage = ({ translations, getTranslation, screenData, authorizedMenus, ...props }) => {
    const [egroupList, setEgroupList] = useState(
        [...new Set(authorizedMenus.map((s) => s.egroup))].map((code) => ({ code, desc: code }))
    );
    const bufferZones = props.applicationData.bufferZones.map(({ code, locationCode }) => ({
        code,
        desc: locationCode,
    }));

    const egroups = [
        ...new Set(
            Object.values(props.applicationData.egroupList)
                .map((s) => Object.values(s).map((t) => Object.keys(t)))
                .flat(2)
        ),
    ].sort();

    return (
        <div style={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
            <div style={{ width: '300px' }}>
                {InputGenerator.generate({
                    field: {
                        type: TABLE_DATA_TYPES.SELECT,
                        getAttribute: () => ATTRIBUTES.OPTIONAL,
                        code: 'code',
                        elementInfo: {
                            text: getTranslation('bufferZone'),
                        },
                        values: bufferZones,
                    },
                    descKey: 'locationCode',
                    updateObject: (s, val) => {
                        if (props.bufferZone?.code !== val) {
                            localStorage.setItem('TREC_BUFFERZONE', val);
                            window.location.reload();
                        }
                    },
                    object: props.bufferZone || {},
                })}
            </div>
            <div style={{ width: '300px' }}>
                {InputGenerator.generate({
                    field: {
                        type: TABLE_DATA_TYPES.CHECKBOX,
                        getAttribute: () => ATTRIBUTES.READONLY,
                        code: 'inheritanceOn',
                        elementInfo: {
                            text: getTranslation('inheritanceOn'),
                        },
                    },
                    object: props.applicationData,
                })}
            </div>
            <div style={{ width: '300px' }}>
                {InputGenerator.generate({
                    field: {
                        type: TABLE_DATA_TYPES.INPUT,
                        getAttribute: () => ATTRIBUTES.READONLY,
                        code: 'rwpmaxEquipmentSync',
                        elementInfo: {
                            text: getTranslation('RWP_MAX_EQP_NUMBER_SYNC'),
                        },
                    },
                    object: props.applicationData,
                })}
            </div>

            <div style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>
                <AMMSelect
                    elementInfo={{
                        text: getTranslation('EGROUP_LIST'),
                    }}
                    columnDirection
                    multi
                    options={egroups.map((code) => ({ code }))}
                    getOptionValue={(option) => option.code}
                    getOptionLabel={(option) => option.code}
                    value={egroupList}
                    onChange={(val) => {
                        setEgroupList(val ?? [{ code: 'eam-logins' }, { code: 'trec-admin-settings-mock' }]);
                    }}
                    style={{ width: '100%' }}
                />
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexShrink: 1,
                        flexFlow: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <TRECButton
                        onClick={() => {
                            props.storeActions.updateApplication({ userData: null });
                            props.storeActions.initializeApplication(egroupList.map(({ code }) => code));
                        }}
                    >
                        SET EGROUPS
                    </TRECButton>
                </div>
            </div>
            <TRECButton
                onClick={() => {
                    localStorage.clear();
                    props.storeActions.updateApplication({ userData: null });
                    props.storeActions.initializeApplication();
                }}
            >
                CLEAR LOCAL CACHE
            </TRECButton>

            <TRECButton
                onClick={() => {
                    WS.clearServerCache().then(showSuccess('Server cached cleared')).catch(handleError);
                }}
            >
                CLEAR SERVER CACHE
            </TRECButton>
            <pre>{JSON.stringify(props.applicationData.egroupList, null, 2)}</pre>
        </div>
    );
};

class SettingsPageClass extends TRECPage {
    renderPage() {
        return <SettingsPage {...this.props} />;
    }
}

export default SettingsPageClass;
