export const TRANSLATION_KEYS = {
    WIZARD_TITLE: 'PROCESSING',
    INPUT_WASTE: 'INPUT_WASTE',
    INPUT_EQUIPMENT_FIELD: 'INPUT_EQUIPMENT_FIELD',
    START_DATE_FIELD: 'START_DATE_FIELD',
    PROCESSING_FIELD: 'PROCESSING',
    OPERATORS_FIELD: 'OPERATORS_FIELD',
    OUTPUT_WASTE: 'OUTPUT_WASTE',
    OUTPUT_JOB_NUMBER_FIELD: 'OUTPUT_JOB_NUMBER_FIELD',
    OUTPUT_EQUIPMENT_FIELD: 'OUTPUT_EQUIPMENT_FIELD',
    END_DATE_FIELD: 'END_DATE_FIELD',
    PHYSICAL_DATA: 'PHYSICAL_DATA',
    RPM_AND_RW_PROPERTIES: 'RPM_AND_RW_PROPERTIES',
    APPLY_ALL_LINES: 'APPLY_ALL_LINES',
    START_TREATMENT: 'START_TREATMENT',
    FINISH: 'FINISH',
    FINISH_PROCESSING: 'FINISH_PROCESSING',
    MEASURE: 'MEASURE',
    START_TREATMENT_SUCCESS_MSG: 'START_TREATMENT_SUCCESS_MSG',
    UPDATE_TREATMENT_SUCCESS_MSG: 'UPDATE_TREATMENT_SUCCESS_MSG',
    UPDATE_RPMS_SUCCESS_MESSAGE: 'UPDATE_RPMS_SUCCESS_MESSAGE',
    UPDATE_EQUIPMENT_SUCCESS_MSG: 'UPDATE_EQUIPMENT_SUCCESS_MSG',
    CONTAINER_WITHDRAWN_WARNING_MSG: 'CONTAINER_WITHDRAWN_WARNING_MSG',
    FINISH_SUCCESS_MSG: 'FINISH_SUCCESS_MSG',
    CANCEL_SUCCESS_MSG: 'CANCEL_SUCCESS_MSG',
    CANCEL_CONFIRMATION_MESSAGE: 'CANCEL_CONFIRMATION_MESSAGE',
    FINISH_CONFIRMATION_MESSAGE: 'FINISH_CONFIRMATION_MESSAGE',
    READY_FOR_MEAS_CONFIRMATION_MESSAGE: 'READY_FOR_MEAS_CONFIRMATION_MESSAGE',
    READY_FOR_MEASURMENT: 'READY_FOR_MEASURMENT',
    OPEN_RPMS_FOR_CONTAINERS: 'OPEN_RPMS_FOR_CONTAINERS',
    REPEATED_CONTENT_ERROR_MSG: 'REPEATED_CONTENT_ERROR_MSG',
    REQUEST_EQP_AS_CONTENT_ERROR_MSG: 'REQUEST_EQP_AS_CONTENT_ERROR_MSG',
    STATUS_CODE: 'STATUS',
    STATUS_DESC: 'STATUS_DESC',
    PRINT: 'PRINT',
    COMMENTS: 'COMMENT',
    RESPONSIBLE: 'EQPRESPONSIBLE',
    CREATED_BY: 'CREATED_BY',
    CREATION_DATE: 'CREATION_DATE',
    UPDATED_BY: 'UPDATED_BY',
    UPDATION_DATE: 'UPDATION_DATE',
    IRRADIATION_END_DATE_MAX: 'IRRADIATION_END_DATE_MAX',
    IRRADIATION_END_DATE_MIN: 'IRRADIATION_END_DATE_MIN',
    FACILITIES: 'FACILITIES',
    CONTAMINATION_RISK: 'CONTAMINATION_RISK',
    CONTAMINATION_ALPHA: 'CONTAMINATION_ALPHA',
    CONTAMINATION_BETA: 'CONTAMINATION_BETA',
    CONTAINS_ALPHA_EMITTERS: 'CONTAINS_ALPHA_EMITTERS',
    INPUT_WASTE_INFORMATION: 'INPUT_WASTE_INFORMATION',
    DOSE_RATE: 'DOSE_RATE',
    IRRAD_END_DATE_CATEGORY: 'IRRAD_END_DATE_CATEGORY',
    OPEN_RPM: 'OPEN_RPM',
    MARKED_SUCCESS_MESSAGE: 'MARKED_SUCCESS_MESSAGE',
    RETRIEVE_SECOND_LEVEL: 'RETRIEVE_SECOND_LEVEL',
    FACILITIES_ALLOWED: 'FACILITIES_ALLOWED',
    EXTRA_INFO: 'EXTRA_INFO',
    ELIMINATION_PROCESS: 'CF_ELIM_PROCESS',
    HAZARD_CODES: 'RISKS',
    HAZARD_COMMENT: 'HAZARDS_COMMENTS',
    TREATMENT: 'PROCESSING',
    USER_STATUS: 'STATUS',
    RWP_EXTRA: 'RWP_EXTRA',
    RWP_EXTRA2: 'RWP_EXTRA2',
    SERIALNO: 'SERIAL_NO',
};

export const RWP_KEYS = {
    INPUT_EQUIPMENT: 'inputWaste',
    OUTPUT_EQUIPMENT: 'outputWaste',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    PROCESSING: 'processing',
    OPERATOR: 'operator',
    JOB_NUMBER: 'workorderCode',
    SYSTEM_STATUS: 'systemStatus',
    USER_STATUS: 'userStatus',
    RPM_MAP: 'rpmMap',
    CREATED_BY: 'createdBy',
    CREATION_DATE: 'creationDate',
    UPDATED_BY: 'updatedBy',
    UPDATION_DATE: 'updationDate',
    INPUT_EQUIPMENT_PROPERTIES: 'inputEquipmentProperties',
    INPUT_EQUIPMENT_STRUCTURE_LAST_RP_MEASUREMENTS: 'inputEquipmentStructureLastRpMeasurements',
    IRRADIATION_END_DATE_MAX: 'irradiationEndDateMax',
    IRRADIATION_END_DATE_MIN: 'irradiationEndDateMin',
    FACILITIES: 'facilities',
    MARKED_ASYNC: 'markedAsync',
    EQUIPMENT_CONFIGURATIONS: 'equipmentConfigurationMap',
    SERIALNO: 'serialno',

    PROCESSING_METHOD: 'processingMethod',
    DECAY_PERIOD: 'decayPeriod',
    FACILITIES_ALLOWED: 'facilitiesAllowed',
    RW_ELIM_PATHWAY: 'eliminationProcess',

    FACILITY: 'facility',
};

export const RWP_KEYS_DTO = [
    RWP_KEYS.INPUT_EQUIPMENT,
    // RWP_KEYS.OUTPUT_EQUIPMENT,
    RWP_KEYS.START_DATE,
    RWP_KEYS.END_DATE,
    RWP_KEYS.PROCESSING,
    RWP_KEYS.OPERATOR,
    RWP_KEYS.JOB_NUMBER,
    RWP_KEYS.USER_STATUS,
    RWP_KEYS.PROCESSING_METHOD,
    RWP_KEYS.DECAY_PERIOD,
    RWP_KEYS.FACILITIES_ALLOWED,
    RWP_KEYS.RW_ELIM_PATHWAY,
    RWP_KEYS.FACILITY, // for the validation
];

export const EQUIPMENT_KEYS = {
    CODE: 'code',
    CLASS: 'classCode',
    DESCRIPTION: 'description',
    LOCATION: 'location',
    ZONE: 'zone',
    SUB_ZONE: 'subZone',
    CONTAIN_ALPHA_EMITTER: 'containAlphaEmitter',
    HAZARDOUS_CONTENT: 'hazardousContent',
    GROSS_WEIGHT: 'weight',
    LENGTH: 'length',
    WIDTH: 'width',
    HEIGHT: 'height',
    VALUE: 'value',
    CONTAINER_FULL: 'containerFull',
    HAZARDOUS_COMMENT: 'hazardousComment',
    ELIMINATION_PROCESS: 'eliminationProcess',
    CONTENT: 'content',
    COMPLETED_DATE: 'completedDate',
    PERFORMED_BY: 'performedBy',
    AD6_CONTACT: 'measureContactValue',
    AD6_CONTACT_QUALIFIER: 'measureContactQualifier',
    BGO_CONTACT: 'measureBGOValue',
    BGO_CONTACT_QUALIFIER: 'measureBGOQualifier',
    CONTENT_CONTAMINATED: 'contamination',
    BQ_ALPHA: 'measureAlpha',
    BQ_ALPHA_QUALIFIER: 'measureAlphaQualifier',
    BQ_BETA: 'measureBeta',
    BQ_BETA_QUALIFIER: 'measureBetaQualifier',
    FAMILY: 'family',
    SUB_FAMILY: 'rwSubFamily',
    MATERIAL: 'materialList',
    IRRADIATION_END_DATE: 'irradiationEndDate',
    FACILITY: 'facilities',
    DEVICE_AD6_SN: 'measureContactSerialNumber',
    DEVICE_BGO_SN: 'measureBGOSerialNumber',
    DEVICE_ADK_SN: 'contaminationSerialNumber',
    RPM_CODE: 'workorderCode',
    RW_ZONE: 'rwZone',
    RW_SUBZONE: 'rwSubzone',
    USER_TYPE: 'userTypeCode',
    SYSTEM_TYPE: 'systemTypeCode',
    CONTAINER_TARE_WEIGHT: 'containerTareWeight',
    CONTAINER_USEFUL_VOLUME: 'containerUsefulVolume',
    ASSOCIATED_RISK: 'associatedRisk',
    CONTENT_DESCRIPTION: 'contentDescription',
    RESPONSIBLE: 'respCode',
    FACILITIES: 'facilities',
    IRRADIATION_END_DATE_MAX: 'irradiationEndDateMax',
    IRRADIATION_END_DATE_MIN: 'irradiationEndDateMin',
    IRRADIATION_END_DATE_MAX_EQUIPMENT: 'irradiationEndDateMaxEquipment',
    IRRADIATION_END_DATE_MIN_EQUIPMENT: 'irradiationEndDateMinEquipment',
    RP_CLASSIFICATION: 'rpClassification',
    RW_RECEPTION_DATE: 'rwReceptionDate',
    RW_FAMILY: 'rwFamily',
    RW_SUB_FAMILY: 'rwSubFamily',
    RW_PROCESSING_DATE: 'rwProcessingDate',
    RW_ELIMINATION_DATE: 'rwEliminationDate',
    AREA_CLASSIFICATION: 'areaClassification',
    ORIGIN: 'origin',
    IS_RADIOACTIVE_WASTE: 'isRadioactiveWaste',
    USER_STATUS: 'userStatus',
    HAZARDS: 'hazards',
    HAZARD_CODES: 'hazardCodes',
    HAZARD_COMMENT: 'hazardComment',
    CONTAINER_INTERNAL_LENGTH: 'containerInternalLength',
    CONTAINER_INTERNAL_HEIGHT: 'containerInternalHeight',
    CONTAINER_INTERNAL_WIDTH: 'containerInternalWidth',
    ELIMINATION_PERFORMED_BY: 'eliminationPerformedBy',
    SERIALNO: 'serialno',
    ASSIGNED_TO: 'assignedTo',
    ATTACHED_TO: 'attachedTo',
    MAIN_MATERIAL: 'material',
    DEPARTMENT: 'department',
    FUTURE_ACTION: 'futureAction',
};

export const EQUIPMENT_KEYS_DTO = [
    EQUIPMENT_KEYS.CODE,
    EQUIPMENT_KEYS.CLASS,
    EQUIPMENT_KEYS.DESCRIPTION,
    EQUIPMENT_KEYS.LOCATION,
    EQUIPMENT_KEYS.ZONE,
    EQUIPMENT_KEYS.SUB_ZONE,
    EQUIPMENT_KEYS.CONTAIN_ALPHA_EMITTER,
    EQUIPMENT_KEYS.GROSS_WEIGHT,
    EQUIPMENT_KEYS.LENGTH,
    EQUIPMENT_KEYS.WIDTH,
    EQUIPMENT_KEYS.HEIGHT,
    EQUIPMENT_KEYS.CONTAINER_FULL,
    EQUIPMENT_KEYS.FACILITY,
    EQUIPMENT_KEYS.ELIMINATION_PROCESS,
    EQUIPMENT_KEYS.CONTENT,
    EQUIPMENT_KEYS.RW_FAMILY,
    EQUIPMENT_KEYS.SUB_FAMILY,
    EQUIPMENT_KEYS.MATERIAL,
    EQUIPMENT_KEYS.IRRADIATION_END_DATE,
    EQUIPMENT_KEYS.RW_ZONE,
    EQUIPMENT_KEYS.RW_SUBZONE,
    EQUIPMENT_KEYS.USER_TYPE,
    EQUIPMENT_KEYS.SYSTEM_TYPE,
    EQUIPMENT_KEYS.CONTENT_DESCRIPTION,
    EQUIPMENT_KEYS.CONTAINER_INTERNAL_LENGTH,
    EQUIPMENT_KEYS.CONTAINER_INTERNAL_WIDTH,
    EQUIPMENT_KEYS.CONTAINER_INTERNAL_HEIGHT,
    EQUIPMENT_KEYS.HAZARDS,
    EQUIPMENT_KEYS.SERIALNO,
];

export const RPSM_KEYS_DTO = [
    EQUIPMENT_KEYS.RPM_CODE,
    EQUIPMENT_KEYS.COMPLETED_DATE,
    EQUIPMENT_KEYS.PERFORMED_BY,
    EQUIPMENT_KEYS.AD6_CONTACT,
    EQUIPMENT_KEYS.AD6_CONTACT_QUALIFIER,
    EQUIPMENT_KEYS.BGO_CONTACT,
    EQUIPMENT_KEYS.BGO_CONTACT_QUALIFIER,
    EQUIPMENT_KEYS.CONTENT_CONTAMINATED,
    EQUIPMENT_KEYS.BQ_ALPHA,
    EQUIPMENT_KEYS.BQ_ALPHA_QUALIFIER,
    EQUIPMENT_KEYS.BQ_BETA,
    EQUIPMENT_KEYS.BQ_BETA_QUALIFIER,
    EQUIPMENT_KEYS.DEVICE_AD6_SN,
    EQUIPMENT_KEYS.DEVICE_BGO_SN,
    EQUIPMENT_KEYS.DEVICE_ADK_SN,
    EQUIPMENT_KEYS.ASSOCIATED_RISK,
    EQUIPMENT_KEYS.FUTURE_ACTION,
];

export const STEPS = {
    INPUT_WASTE: 'InputWasteStep',
    OUTPUT_WASTE: 'OutputWasteStep',
    PHYSICAL_DATA: 'PhysicalDataStep',
    PROPERTIES: 'PropertiesStep',
};

export const CONTAINER_CLASSES = ['PW00'];

export const DIALOGS = {
    CANCEL_CONFIRMATION: 'CANCEL_CONFIRMATION',
    FINISH_CONFIRMATION: 'FINISH_CONFIRMATION',
    STAGES_PROGRESS: 'STAGES_PROGRESS',
    READY_FOR_MEAS_CONFIRMATION: 'READY_FOR_MEAS_CONFIRMATION',
    OPEN_RPMS_FOR_CONTAINERS: 'OPEN_RPMS_FOR_CONTAINERS',
};

export const RWP_STATUS = {
    PENDING: 'R',
    INPROGRESS: 'RC',
    INCOMPLETE: 'TI',
    DONE: 'T',
};
