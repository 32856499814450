import React from 'react';
import ArrowBack from '@material-ui/icons/ArrowBack';
import TRECButton from '../../components/buttons/TRECButton';
import EAMGridNoOverflow from '../../components/grid/EAMGridNoOverflow';
import gridNoOverflowClasses from '../../components/grid/EAMGridNoOverflow.module.css';

function BZPastInventories(props) {
    const { backToMenu, history, pastInventoriesGridID, setPastInventory, translations } = props;
    return (
        <div className={gridNoOverflowClasses.outerBlock}>
            <TRECButton style={{ marginLeft: 10, alignSelf: 'flex-start' }} onClick={backToMenu}>
                <ArrowBack />
                {translations.BACK}
            </TRECButton>
            <EAMGridNoOverflow
                gridId={pastInventoriesGridID}
                editColumn
                hiddenTags={['updated', 'updatecount', 'updatetby']}
                onRowClick={(row) => {
                    const inventoryNumber = row.cell.filter((element) => element.t === 'bzi_sequence')[0].value;
                    history.replace(`?pastinventory=${inventoryNumber}`);
                    setPastInventory(inventoryNumber);
                }}
                heightFilterVisible="250px"
                heightFilterNotVisible="200px"
            />
        </div>
    );
}

export default BZPastInventories;
