export const STEPS = {
    ACTIONS_STEP: 'ActionsStep',
    ASSESSMENT_STEP: 'AssessmentStep',
    SAMPLING_STEP: 'SamplingStep',
    DECISION_STEP: 'DecisionStep',
    VALIDATION_STEP: 'ValidationStep',
};

export const TRANSLATION_KEYS = {
    ASSESSMENT: 'ASSESSMENT',
    REQUEST_DATA: 'REQUEST_DATA',
    ACTIONS: 'ACTIONS',
    NEW_EQUIPMENT_DETAILS: 'NEW_EQUIPMENT_DETAILS',
    JOB_NUMBER: 'JOBNUMBER',
    LOCATION: 'LOCATION',
    RPO: 'RPO',
    REQUESTER: 'REQUESTER',
    FORECASTED_RELEASE_VOLUME: 'FORECASTED_RELEASE_VOLUME',
    FORECASTED_RELEASE_DATE: 'FORECASTED_RELEASE_DATE',
    EQUIPMENT: 'EQUIPMENT',
    CREATED_BY: 'CREATED_BY',
    UPDATED_BY: 'UPDATED_BY',
    CREATED_ON: 'CREATED_ON',
    UPDATED_ON: 'UPDATED_ON',
    RP_MEASUREMENT: 'RPMEASUREMENT',
    STATUS: 'STATUS',
    CLASSIFICATION: 'CLASSIFICATION',
    RP_ANALYSIS_REQUEST: 'RP_ANALYSIS_REQUEST',
    RP_ANALYSIS_JOBS: 'RP_ANALYSIS_JOBS',
    ANALYSIS_TYPE: 'ANALYSIS_TYPE',
    JOBS: 'JOBS',
    NOT_CREATED: 'NOT_CREATED',
    ELIMINATION_PATHWAY: 'ELIMINATION_PATHWAY',
    COUNTRY: 'COUNTRY',
    NOT_ASSUMED_RADIOACTIVE_WARNING: 'NOT_ASSUMED_RADIOACTIVE_WARNING',
    NO_ASSISTANCE_WARNING: 'NO_ASSISTANCE_WARNING',
    ENSURE_CORRECT_SAMPLE: 'ENSURE_CORRECT_SAMPLE',
    WRR_ACTIONS_NOTICE: 'WRR_ACTIONS_NOTICE',
    WRR_ORIGIN_NOTICE: 'WRR_ORIGIN_NOTICE',
    WATER_ELIMINATION_PATHWAY: 'WATERELIMINATIONPATHWAY',

    WATER_ORIGIN_CODE: 'WATER_ORIGIN_CODE',
    WATER_ORIGIN_DESC: 'WATER_ORIGIN_DESC',
    WATER_ORIGIN_LOCATION: 'WATER_ORIGIN_LOCATION',
    ACTUAL_RELEASE_DATE: 'ACTUAL_RELEASE_DATE',
    ACTUAL_RELEASE_VOLUME: 'ACTUAL_RELEASE_VOLUME',
};

export const ANALYSIS_TYPES = {
    GAMMASPEC: 'Gamma Spectroscopy',
    LIQUIDSCINT: 'Liquid Scintillation Counting',
    PHYSCHEM: 'Physico-Chemical',
};

export const ENTITY_TRANSL_PREFIX = {
    EVT_STATUS: 'EVST.',
    AVAILABILITY_STATUS: 'AVST.',
    RESERVATION_STATUS: 'RNST.',
    OBJECT_STATE: 'OBSA.',
};

export const WRR_STATUS = {
    CREATED: 'RDT',
    PENDING: 'R',
    ASSESSED_SELF_SAMPL: 'RSS',
    ASSESSED_BZ_SAMPL: 'RSB',
    ASSESSED_ASSIS_SAMPL: 'RSA',
    IN_PROGRESS: 'RC',
    DECISION_PENDING: 'RT',
    CANCELLED: 'TX',
    NOT_APPROVED: 'TNA',
    APPROVED: 'RTA',
    RELEASED: 'TA',
};

export const WATER_RELEASE_KEYS = {
    CREATOR: 'creator',
    REQUESTER: 'requester',
    LOCATION: 'location',
    RPO: 'rpo',
    REQUESTED_RELEASE_DATE: 'forecastedReleaseDate',
    FORECASTED_RELEASE_VOLUME: 'forecastedReleaseVolume',
    CREATED_BY: 'creator',
    CREATED_ON: 'createdOn',
    MODIFIED_BY: 'modifiedBy',
    MODIFIED_ON: 'modifiedOn',
    STATUS: 'status',
    EQUIPMENT: 'equipment',
    COUNTRY: 'countryOfRequest',
    SAMPLING_VOLUME: 'samplingVolume',
    WR_NUMBER: 'jobNumber',
    ASSUMED_RADIOACTIVE: 'assumedRadioactive',
    ASSISTANCE_REQUIRED: 'assistanceRequired',
    SAMPLE: 'sample',
    RPAR_NUMBER: 'rpAnalysisRequest',
    RPM_NUMBER: 'rpMeasurement',
    ACTUAL_RELEASE_DATE: 'actualReleaseDate',
    SAMPLE_TO_BE_RETRIEVED: 'sampleToBeRetrieved',
    ACTUAL_RELEASE_VOLUME: 'actualReleaseVolume',
};

export const WATER_RELEASE_KEYS_DTO = [
    WATER_RELEASE_KEYS.CREATOR,
    WATER_RELEASE_KEYS.REQUESTER,
    WATER_RELEASE_KEYS.LOCATION,
    WATER_RELEASE_KEYS.REQUESTED_RELEASE_DATE,
    WATER_RELEASE_KEYS.FORECASTED_RELEASE_VOLUME,
    WATER_RELEASE_KEYS.STATUS,
    WATER_RELEASE_KEYS.EQUIPMENT,
    WATER_RELEASE_KEYS.SAMPLING_VOLUME,
    WATER_RELEASE_KEYS.WR_NUMBER,
    WATER_RELEASE_KEYS.ASSUMED_RADIOACTIVE,
    WATER_RELEASE_KEYS.ASSISTANCE_REQUIRED,
    WATER_RELEASE_KEYS.SAMPLE,
    WATER_RELEASE_KEYS.ACTUAL_RELEASE_DATE,
    WATER_RELEASE_KEYS.SAMPLE_TO_BE_RETRIEVED,
    WATER_RELEASE_KEYS.ACTUAL_RELEASE_VOLUME,
];
