import React, { useState } from 'react';
import WSEDMS from 'tools/rest/WSEDMS';
import { Button, Paper, Typography } from '@material-ui/core';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';

const EDMSLinkDocument = ({ objectIds = [], onSuccess, onFailure }) => {
    const [documentId, setDocumentId] = useState('');
    const [isValid, setValid] = useState(false);

    const onDocumentIdChange = (event) => {
        const { value } = event.target;
        setDocumentId(value);
        setValid(value && value.trim() !== '');
    };

    const onLink = async () => {
        try {
            await WSEDMS.handleDocumentLink(documentId, objectIds, 'A');
            setDocumentId('');
            setValid(false);
            onSuccess?.();
        } catch (err) {
            onFailure?.(err);
        }
    };

    const onEnter = (ev) => {
        if (ev.key === 'Enter' && isValid) {
            onLink();
            ev.stopPropagation();
        }
    };

    return (
        <Paper elevation={2} style={{ marginTop: '20px', padding: '15px', textAlign: 'center' }}>
            <Typography variant="h6" style={{ marginBottom: '10px' }}>
                Link EDMS Document
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <EAMInput
                    required
                    label={"Document's ID"}
                    style={{ marginRight: '10px' }}
                    value={documentId}
                    onChange={onDocumentIdChange}
                    onKeyDown={onEnter}
                />
                <Button disabled={!isValid} onClick={onLink} color="primary" variant="contained">
                    Link
                </Button>
            </div>
        </Paper>
    );
};

export default EDMSLinkDocument;
