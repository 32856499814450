import { connect } from 'react-redux';
import { setPage } from '../../../actions/layoutActions';
import Reports from './Reports';

const mapStateToProps = (state) => ({
    applicationData: state.applicationData,
    translations: state.application.screenData[state.layout.lang].translations.TREC_JOBSREPORT,
    bufferZones: state.application.applicationData.bufferZones,
    page: 'RP_JOBSREPORT',
    masterPage: 'RP_JOBSREPORT',
    menu: state.application.screenData[state.layout.lang].menu[state.application.menuType],
    bufferZone: state.application.bufferZone,
});

const JobsReportContainer = connect(mapStateToProps, {
    setPage,
})(Reports);

export default JobsReportContainer;
