import { format, parse } from 'date-fns';
import {
    ANALYSIS_KEYS,
    ANALYSIS_PROPERTIES_DTO_KEYLIST,
    DATE_FORMAT,
    DATE_TIME_FORMAT,
    DATE_TIME_VALUE_KEYLIST,
    DATE_VALUE_KEYLIST,
    EQUIPMENT_DTO_KEYLIST,
    EQUIPMENT_KEYS,
    EQUIPMENT_PROPERTIES_DTO_KEYLIST,
    RPAR_REQUEST_DTO_KEYLIST,
    RPAR_REQUEST_KEYS,
} from '../../enums/Constants';
import Tools from '../Tools';
import WS from './WS';
import {
    equipmentHazardInputAdapter,
    equipmentHazardOutputAdapter,
    equipmentsObjectHazardInputAdapter,
} from '../Hazards';

/**
 * Rest services for the RP Analysis Request
 */
class WSRPARequest {
    getAnalysisPurposes(config = {}) {
        return WS._get(`/rpar/analysis/purposes/`, config);
    }

    getDropdownValues(config = {}) {
        return WS._get(`/rpar/dropdowns/values/`, config);
    }

    getValuesDef(config = {}) {
        return WS._get(`/rpar/valuesdef/`, config);
    }

    getValuesGrid(config = {}) {
        return WS._get(`/rpar/valuesgrid/`, config);
    }

    getAnalysisTypeCFMapping(analysisType, config = {}) {
        return WS._get(`/rpar/values/${analysisType}/fields`, config);
    }

    getAnalysisTypes(config = {}) {
        return WS._get(`/rpar/analysis/types/`, config);
    }

    getEquipment({ applicationData, code }, config = {}) {
        return WS._get(`/rpar/equipment/${code}/`, config).then((response) => {
            if (
                applicationData.isHazardsFeatureActive &&
                response &&
                response.body &&
                response.body.data &&
                typeof response.body.data === 'object'
            ) {
                response.body.data = equipmentHazardInputAdapter({
                    equipment: response.body.data,
                    applicationData,
                });
            }

            return response;
        });
    }

    getEquipmentDefaults = (code, config = {}) => WS._get(`/rpar/equipment/${code}/defaults`, config);

    getLocationDefaults = (code, config = {}) =>
        WS._get(`/rpar/equipment/defaults?location=${encodeURIComponent(code)}`, config);

    getLocationFacility = (code, config = {}) =>
        WS._get(`/rpar/facilities/default?location=${encodeURIComponent(code)}`, config);

    getLocationRPO = (code, config = {}) => WS._get(`/rpar/rpos/default?location=${encodeURIComponent(code)}`, config);

    getLastRPMeasurement(code, config = {}) {
        return WS._get(`/rpar/equipment/${code}/rpmeasurement`, config);
    }

    updateEquipment({ code, equipment, applicationData }, config = {}) {
        return WS._put(
            `/rpar/equipment/${code}/`,
            this.updateEquipmentAdapter(
                equipmentHazardOutputAdapter({
                    equipment,
                    applicationData,
                })
            ),
            config
        );
    }

    updateMultipleEquipment({ applicationData, equipmentMap }, config = {}) {
        return WS._put(
            `/rpar/equipment/`,
            Object.entries(equipmentMap).reduce(
                (acc, [code, eq]) => ({
                    ...acc,
                    [code]: this.updateEquipmentAdapter(
                        equipmentHazardOutputAdapter({
                            equipment: eq,
                            applicationData,
                        })
                    ),
                }),
                {}
            ),
            config
        );
    }

    updateEquipmentAdapter = (equipment) =>
        EQUIPMENT_DTO_KEYLIST.reduce((acc, key) => ({ ...acc, [key]: equipment[key] }), {});

    createRPAnalysisPerform = (obj, config = {}) => WS._post(`/rpar/perform/`, obj, config);

    createRPAnalysisReturnEDH = (data, config = {}) =>
        // return WS._post(`/rpar/analysis/edh`, data, config);
        WS._post(`/rpar/analysis/edh`, data, config);

    previewRPAnalysisReturnEDH = (data, config = {}) =>
        // return WS._post(`/rpar/analysis/edh`, data, config);
        WS._post(`/rpar/analysis/edhpreview`, data, config);

    updateRPAnalysisPerform = (code, obj, config = {}) =>
        WS._put(`/rpar/perform/${code}`, this.treatDateToBE(obj), config);

    getRPAnalysisPerform = (code, config = {}) => WS._get(`/rpar/perform/${code}`, config);

    getRPARequestAdapter = ({ woProperties, equipment, analysisProperties, rpmeasure }) => {
        const equipmentPropertiesMap = Object.keys(equipment || {}).reduce(
            (equipmentAcc, eqCode) => ({
                ...equipmentAcc,
                [eqCode]: EQUIPMENT_PROPERTIES_DTO_KEYLIST.reduce(
                    (propertiesAcc, key) => ({
                        ...propertiesAcc,
                        [key]: equipment[eqCode][key],
                    }),
                    {}
                ),
            }),
            {}
        );

        const analysisPropertiesMap = Object.keys(analysisProperties || {}).reduce(
            (analysisAcc, analysisType) => ({
                ...analysisAcc,
                [analysisType]: Object.keys(analysisProperties[analysisType]).map((eqCode) => ({
                    [ANALYSIS_KEYS.EQUIPMENT_CODE]: eqCode,
                    ...ANALYSIS_PROPERTIES_DTO_KEYLIST.reduce(
                        (propertiesAcc, key) => ({
                            ...propertiesAcc,
                            [key]: DATE_VALUE_KEYLIST.includes(key)
                                ? {
                                      value: analysisProperties[analysisType][eqCode][key],
                                      format: DATE_FORMAT.JAVA,
                                      date: parse(
                                          analysisProperties[analysisType][eqCode][key] &&
                                              analysisProperties[analysisType][eqCode][key].substring(
                                                  0,
                                                  DATE_FORMAT.DATE_FNS.length
                                              ),
                                          DATE_FORMAT.DATE_FNS,
                                          new Date()
                                      ), // { value: analysisProperties[analysisType][eqCode][key], format: DATE_FORMAT }
                                  }
                                : DATE_TIME_VALUE_KEYLIST.includes(key)
                                ? {
                                      value: analysisProperties[analysisType][eqCode][key],
                                      format: DATE_TIME_FORMAT.JAVA,
                                      date: parse(
                                          analysisProperties[analysisType][eqCode][key],
                                          DATE_TIME_FORMAT.DATE_FNS,
                                          new Date()
                                      ), // { value: analysisProperties[analysisType][eqCode][key], format: DATE_FORMAT }
                                  }
                                : analysisProperties[analysisType][eqCode][key],
                        }),
                        {}
                    ),
                })),
            }),
            {}
        );

        const payload = {
            ...Tools.filterObjectFieldsFromList(woProperties, RPAR_REQUEST_DTO_KEYLIST),
            equipmentPropertiesMap,
            analysisPropertiesMap,
            rpaMeasurementMap: rpmeasure,
            equipmentMap: Tools.applyToFields(equipment, (v) =>
                Tools.filterObjectFieldsFromList(v, EQUIPMENT_DTO_KEYLIST)
            ),
        };
        return payload;
    };

    createRPAnalysisRequest = ({ woProperties, equipment, analysisProperties }, config = {}) => {
        const cleansedWoProperties = {
            ...woProperties,
            [RPAR_REQUEST_KEYS.SCHEDULED_END_DATE]:
                woProperties[RPAR_REQUEST_KEYS.SCHEDULED_END_DATE] &&
                Object.keys(woProperties[RPAR_REQUEST_KEYS.SCHEDULED_END_DATE]).length
                    ? woProperties[RPAR_REQUEST_KEYS.SCHEDULED_END_DATE]
                    : null,
            [RPAR_REQUEST_KEYS.SCHEDULED_START_DATE]:
                woProperties[RPAR_REQUEST_KEYS.SCHEDULED_START_DATE] &&
                Object.keys(woProperties[RPAR_REQUEST_KEYS.SCHEDULED_START_DATE]).length
                    ? woProperties[RPAR_REQUEST_KEYS.SCHEDULED_START_DATE]
                    : null,
        };
        return WS._post(
            `/rpar/requests/`,
            this.getRPARequestAdapter({ woProperties: cleansedWoProperties, equipment, analysisProperties }),
            config
        );
    };

    getRPAnalysisRequest = ({ code, applicationData }) =>
        WS._get(`/rpar/requests/${code}`, {}).then((response) => {
            if (
                applicationData.isHazardsFeatureActive &&
                response &&
                response.body &&
                response.body.data &&
                typeof response.body.data === 'object'
            ) {
                response.body.data.equipmentMap = equipmentsObjectHazardInputAdapter({
                    equipments: response.body.data.equipmentMap,
                    applicationData,
                });
            }

            return response;
        });

    updateRPAnalysisRequest = ({ woProperties, equipment, analysisProperties }, config = {}) =>
        WS._put(
            `/rpar/requests/${woProperties[RPAR_REQUEST_KEYS.WORKORDER]}`,
            this.getRPARequestAdapter({ woProperties, equipment, analysisProperties }),
            config
        );

    createRPAMeasurement = ({ rpmeasure, equipment }, config = {}) =>
        WS._post(`/rpar/measurements/`, this.getRPARequestAdapter({ equipment, rpmeasure }), config);

    deleteRPAnalysisRequest = ({ id, config = {} }) => WS._delete(`/rpar/requests/${id}`, config);

    getRPAnalysisRequestAdapter = (rpar) => {
        const woProps = RPAR_REQUEST_DTO_KEYLIST.map((key) => ({ [key]: rpar[key] })).reduce(
            (acc, eq) => ({ ...acc, ...eq }),
            {}
        );

        const equipment = Object.keys(rpar.equipmentMap).reduce(
            (acc, code) => ({
                ...acc,
                [code]: {
                    ...rpar.equipmentMap[code],
                    ...rpar.rpaMeasurementMap[code],
                    ...rpar.equipmentPropertiesMap[code],
                },
            }),
            {}
        );

        const analysis = Object.entries(rpar.analysisPropertiesMap)
            .map(([at, anList]) => ({
                [at]: anList
                    .map((an) => ({
                        [an[EQUIPMENT_KEYS.EQUIPMENT_CODE]]: this.treatDateFromBE(an),
                    }))
                    .reduce((acc, el) => ({ ...acc, ...el }), {}),
            }))
            .reduce((acc, el) => ({ ...acc, ...el }), {});

        return { woProps, equipment, analysis };
    };

    treatDateToBE = (obj) => {
        const a = Object.entries(obj)
            .map(([key, value]) => ({
                [key]: !value
                    ? value
                    : DATE_VALUE_KEYLIST.includes(key)
                    ? {
                          format: DATE_FORMAT.JAVA,
                          value: format(parse(value, DATE_FORMAT.DATE_FNS, new Date()), DATE_FORMAT.JAVA),
                      }
                    : DATE_TIME_VALUE_KEYLIST.includes(key)
                    ? {
                          format: DATE_TIME_FORMAT.JAVA,
                          value: format(parse(value, DATE_TIME_FORMAT.DATE_FNS, new Date()), DATE_TIME_FORMAT.JAVA),
                      }
                    : value,
            }))
            .reduce((acc, el) => ({ ...acc, ...el }), {});
        return a;
    };

    treatDateFromBE = (obj) => {
        const a = Object.entries(obj)
            .map(([key, value]) => ({
                [key]: Array.concat(DATE_VALUE_KEYLIST, DATE_TIME_VALUE_KEYLIST).includes(key)
                    ? this.formatDate(key, value)
                    : value,
            }))
            .reduce((acc, el) => ({ ...acc, ...el }), {});
        return a;
    };

    getCompletedRPAs = async (eqCode, hint, config) => ({
        body: {
            data: (
                await WS._get(`/rpar/equipment/${eqCode}/analysis/?hint=${encodeURIComponent(hint)}`, config)
            )?.body?.data
                // .filter((a) => a.analysisJob.startsWith(hint))
                .map((f) => ({ code: f.analysisJob, desc: f.analysisType })),
        },
    });

    callHseLimsIntegAPI = async (wo, config = {}) =>
        (await WS._post(`/rpar/perform/${wo}/integ/lims/`, config))?.body.data;

    formatDate = (key, dateType) =>
        !dateType
            ? dateType
            : dateType.value
            ? format(parse(dateType.value, dateType.format, new Date()), DATE_TIME_FORMAT.DATE_FNS)
            : dateType.date
            ? format(dateType.date, DATE_VALUE_KEYLIST.includes(key) ? DATE_FORMAT.DATE_FNS : DATE_TIME_FORMAT.DATE_FNS)
            : null;

    format(...args) {
        return format.apply(this, args);
    }

    parse(...args) {
        return parse.apply(this, args);
    }
}

export default new WSRPARequest();
