import React, { Component } from 'react';
import axios from 'axios';
import BlockUi from 'react-block-ui';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import { Tabs, Tab, Paper } from '@material-ui/core';
import TRECButton from '../../components/buttons/TRECButton';
import WS from '../../../tools/rest/WS';
import 'react-block-ui/style.css';
import InfoPage from '../../components/infopage/InfoPage';
import { showError } from 'tools/TrecNotifications';

const NICE_LOGIN = 0;

const ENTER = 'Enter';

class Login extends Component {
    state = {
        firstCredential: '',
        secondCredential: '',
        tab: 0,
        loading: false,
    };

    flexContainerStyle = {
        height: 'calc(100% - 250px)',
        display: 'flex',
        alignItems: 'center',
    };

    containerStyle = {
        minWidth: 250,
        maxWidth: 500,
        height: 300,
        margin: '0px auto',
        position: 'relative',
        padding: '10px',
    };

    buttonStyle = {
        position: 'absolute',
        right: 10,
        bottom: 10,
    };

    blockUiStyle = {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    blockUiStyleDiv = {
        display: 'flex',
        height: 60,
        alignItems: 'flex-end',
    };

    UNSAFE_componentWillMount() {
        delete axios.defaults.headers.common.Authorization;
    }

    authenticate = async () => {
        const { history, setUser, initializeApplication, translations } = this.props;
        const { firstCredential, secondCredential, tab } = this.state;
        const auth = btoa(`${(firstCredential || '').toUpperCase()}:${secondCredential}`);
        const loginType = tab === NICE_LOGIN ? 'nice2' : 'user';

        this.setState(() => ({
            loading: true,
        }));

        let user = 'NOK';
        try {
            const response = await WS.postLoginData(auth, loginType);
            user = response.body.data;
        } catch (error) {
            showError(translations.LOGININVALID);
            return;
        } finally {
            this.setState({
                loading: false,
            });
        }

        if (user === 'NOK') {
            showError(translations.LOGININVALID);
            return;
        }

        setUser(user?.userCode);
        initializeApplication();
        history.push('/menu');
    };

    render() {
        const { bufferZone, bufferZoneAccount, translations, user } = this.props;
        const { loading, tab } = this.state;
        if (user !== bufferZoneAccount || !bufferZone) {
            return <InfoPage title={translations.LOGINUNAVAILABLE} message={translations.LOGINUNAVAILABLEMSG} />;
        }

        return (
            <div style={this.flexContainerStyle}>
                <Paper style={this.containerStyle}>
                    {loading && (
                        <BlockUi tag="div" blocking style={this.blockUiStyle}>
                            <div style={this.blockUiStyleDiv}>Please wait...</div>
                        </BlockUi>
                    )}

                    {!loading && (
                        <div>
                            <Tabs
                                value={tab}
                                onChange={(event, value) =>
                                    this.setState({
                                        tab: value,
                                        firstCredential: '',
                                        secondCredential: '',
                                    })
                                }
                            >
                                <Tab label={translations.NICELOGIN} />
                                <Tab label={translations.GUESTLOGIN} />
                            </Tabs>
                            {this.renderInputs()}
                            <div style={this.buttonStyle}>
                                <TRECButton onClick={this.authenticate}>Login</TRECButton>
                            </div>
                        </div>
                    )}
                </Paper>
            </div>
        );
    }

    updateProperty(key, value) {
        this.setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    }

    onKeyDown(e) {
        if (e.key === ENTER) {
            this.authenticate();
        }
    }

    renderInputs = () => {
        const { translations } = this.props;
        const { firstCredential, tab, secondCredential } = this.state;
        return (
            <div>
                <EAMInput
                    label={tab === NICE_LOGIN ? translations.NICELOGIN : translations.CERNID}
                    value={firstCredential}
                    valueKey="firstCredential"
                    updateProperty={(key, value) => this.updateProperty(key, value)}
                    onKeyDown={(e) => this.onKeyDown(e)}
                />
                <EAMInput
                    label={tab === NICE_LOGIN ? translations.NICEPASSWORD : translations.LASTNAME}
                    value={secondCredential}
                    valueKey="secondCredential"
                    updateProperty={(key, value) => this.updateProperty(key, value)}
                    type={tab === NICE_LOGIN ? 'password' : 'text'}
                    onKeyDown={(e) => this.onKeyDown(e)}
                />
            </div>
        );
    };
}

export default Login;
