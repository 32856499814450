import Tools from 'tools/Tools';
import { RWELIMINATION_ACTION_TYPES } from '../actions/rwEliminationActions';
import {
    RW_ELIMINATION_KEYS,
    RW_ELIMINATION_TRANSPORT_CONTAINER_KEYS,
} from '../ui/pages/rwelimination/RWEliminationConstants';

export const RWELIM = 'RWELIM';

const getInitialState = ({ userData, applyDefaults }) => ({
    userData,
    [RW_ELIMINATION_KEYS.PACKAGES]: {},
    [RW_ELIMINATION_KEYS.TRANSPORTING_CONTAINERS]: [
        {
            [RW_ELIMINATION_TRANSPORT_CONTAINER_KEYS.TC_CODE]: '',
            [RW_ELIMINATION_TRANSPORT_CONTAINER_KEYS.TARE_WEIGHT]: '',
        },
    ],
    ...(applyDefaults
        ? {
              [RW_ELIMINATION_KEYS.ASSIGNED_TO]: { cernId: userData?.eamAccount?.employeeCode },
              [RW_ELIMINATION_KEYS.SUPPLIER]: { code: 'ANDR01' },
              [RW_ELIMINATION_KEYS.COLISSAGE]: 'C1',
              [RW_ELIMINATION_KEYS.LENGTH]: '606',
              [RW_ELIMINATION_KEYS.WIDTH]: '244',
              [RW_ELIMINATION_KEYS.HEIGHT]: '130',
              [RW_ELIMINATION_KEYS.BUDGET_CODE]: '57373',
              [RW_ELIMINATION_KEYS.LOCATION]: { code: '184' },
          }
        : {}),
});

export default (state = getInitialState({}), action) => {
    switch (action.type) {
        case RWELIMINATION_ACTION_TYPES.RESET:
            return getInitialState(action.value);
        case RWELIMINATION_ACTION_TYPES.UPDATE_RW_ELIMINATION: {
            const updatedState = {
                ...state,
                ...action.data,
            };
            return updatedState;
        }
        case RWELIMINATION_ACTION_TYPES.UPDATE_RW_ELIMINATION_PARTIAL:
            return {
                ...state,
                [action.path]: {
                    ...state[action.path],
                    ...action.value,
                },
            };
        case RWELIMINATION_ACTION_TYPES.UPDATE_RW_ELIMINATION_PACKAGE:
            return {
                ...state,
                [RW_ELIMINATION_KEYS.PACKAGES]: {
                    ...state[RW_ELIMINATION_KEYS.PACKAGES],
                    [action.eqCode]: {
                        ...state[RW_ELIMINATION_KEYS.PACKAGES][action.eqCode],
                        ...action.value,
                    },
                },
            };
        default:
            return state;
    }
};

const getProperties = (state) => () => state;

const isBlockUI = (state) => () => state.blockUI;

const rwEliminationGetters = {
    getProperties,
    isBlockUI,
};

export const rwElimGettersWithState = (state) =>
    Tools.applyToFields(rwEliminationGetters, (func) => func(state?.[RWELIM]));
