import React, { useMemo } from 'react';
import { useFilters, useSortBy, useTable } from 'react-table';
import { AUDIT_KEYS } from './Constants';
import EQPageTableWrapper from './EQPageTableWrapper';
import { CreatableSelectFilter, multiSelectFilter } from './eqPageTableUtils';

const EquipmentAudit = (props) => {
    const { auditData, getTranslation } = props;

    const data = auditData;

    const columns = useMemo(
        () =>
            Object.keys(AUDIT_KEYS).map((key) => ({
                Header: getTranslation(key),
                accessor: key,
                Filter: (filterProps) => CreatableSelectFilter({ ...filterProps, getTranslation }),
                filter: multiSelectFilter,
            })),
        [getTranslation]
    );

    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useFilters,
        useSortBy
    );

    return <EQPageTableWrapper tableInstance={tableInstance} getTranslation={getTranslation} />;
};

export default EquipmentAudit;
