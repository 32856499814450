import { connect } from 'react-redux';
import { openConfirmDialog } from 'react-eis-components/dist/actions/uiActions';
import SearchRequests from './SearchRequests';
import { setPage, updateLayout } from '../../../actions/layoutActions';
import { showSuccess } from '../../../tools/TrecNotifications';

function mapStateToProps(state) {
    return {
        applicationData: state.application.applicationData,
        screenData: state.application.screenData,
        lang: state.layout.lang,
        translations: state.application.screenData[state.layout.lang].translations.TREC_MENU,
        masterPage: 'MY_REQUESTS',
        page: 'MY_REQUESTS',
        menu: state.application.screenData[state.layout.lang].menu[state.application.menuType],
        bufferZone: state.application.bufferZone,
        constants: state.application.constants,
    };
}

const SearchRequestsContainer = connect(mapStateToProps, {
    updateLayout,
    showSuccess,
    setPage,
    openConfirmDialog,
})(SearchRequests);

export default SearchRequestsContainer;
