import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Close from '@material-ui/icons/Close';
import EISTable from 'react-eis-components/dist/ui/components/table';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import Grid from '@material-ui/core/Grid';
import BlockUi from 'react-block-ui';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import CommentsContainer from 'eam-components/dist/ui/components/comments/CommentsContainer';
import WSComments from 'tools/rest/WSComments';
import TRECButton from 'ui/components/buttons/TRECButton';
import WSRWManagement from 'tools/rest/WSRWManagement';
import Tools from 'tools/Tools';
import { TABLE_DATA_TYPES } from 'enums/Constants';
import { EQUIPMENT_KEYS, RWRESULT_KEYS } from './RWManagementConstants';
import InputGenerator from '../rpmrequest2/InputGenerator';
import { TRANSLATION_KEYS } from '../rwprocessing/RWPConstants';
import { handleError } from 'tools/TrecNotifications';

const { ATTRIBUTES } = InputGenerator;

/**
 * Detail of one equipment
 */
const RWManagementDetail = (props) => {
    const {
        equipmentCode,
        setWasteEquipmentDetail,
        translations,
        screenData,
        lang,
        applicationData,
        rwpDropdowns,
        getTranslation,
        userData,
    } = props;

    const [loading, setLoading] = useState(true);
    const [auditHistory, setAuditHistory] = useState();
    const [equipment, setEquipment] = useState();
    const [rwResultData, setRwResultData] = useState();

    const comments = useRef(null);

    const { assetFields, customFieldsWaste, customFieldsWasteDef } = screenData[lang];

    useEffect(() => {
        // Get waste equipment details
        (async () => {
            try {
                const response = await WSRWManagement.getWasteEquipment(equipmentCode);
                setLoading(false);
                setAuditHistory(response.body.data.auditHistory);
                setEquipment(response.body.data.equipment);
                setRwResultData(response.body.data.rwResultData);
            } catch (error) {
                handleError(error);
            }
        })();
    }, [equipmentCode]);

    const handleClose = () => {
        setWasteEquipmentDetail(undefined);
    };

    const getValue = (codeDescriptions) => (object, field) => {
        try {
            return Tools.groupListBy(codeDescriptions, 'code')[object[field.code]][0].desc;
        } catch (error) {
            return object[field.code];
        }
    };

    // Prop codes for waste history table
    const propCodes = ['propCode', 'fromValue', 'toValue', 'changed', 'modifiedBy'];

    // Headers for waste history table
    const headersTable = [
        translations.PROPERTY,
        translations.FROMVALUE,
        translations.TOVALUE,
        translations.CHANGED,
        translations.MODIFIEDBY,
    ];

    const data =
        auditHistory &&
        auditHistory.map((elem) => ({
            ...elem,
            propCode: customFieldsWaste[elem.propCode].text,
            fromValue: Tools.getCustomFieldValueDesc(customFieldsWasteDef[elem.propCode], elem.fromValue),
            toValue: Tools.getCustomFieldValueDesc(customFieldsWasteDef[elem.propCode], elem.toValue),
            changed: Tools.toDateString(new Date(elem.changed)),
        }));

    const wasteDetailsProperties = [
        {
            code: RWRESULT_KEYS.RW_FAMILY,
            type: TABLE_DATA_TYPES.INPUT,
            translationKey: getTranslation(EQUIPMENT_KEYS.RW_FAMILY),
            getAttribute: () => ATTRIBUTES.READONLY,
            customValue: getValue(rwpDropdowns[EQUIPMENT_KEYS.RW_FAMILY]),
        },
        {
            code: 'wasteSubFamily',
            type: TABLE_DATA_TYPES.INPUT,
            translationKey: getTranslation(EQUIPMENT_KEYS.RW_SUB_FAMILY),
            getAttribute: () => ATTRIBUTES.READONLY,
            customValue: getValue(rwpDropdowns[EQUIPMENT_KEYS.RW_SUB_FAMILY]),
        },
        {
            code: EQUIPMENT_KEYS.TREATMENT,
            type: TABLE_DATA_TYPES.INPUT,
            translationKey: getTranslation(TRANSLATION_KEYS.TREATMENT),
            getAttribute: () => ATTRIBUTES.READONLY,
        },
        {
            code: RWRESULT_KEYS.FACILITIES,
            type: TABLE_DATA_TYPES.INPUT,
            translationKey: getTranslation(EQUIPMENT_KEYS.FACILITIES),
            getAttribute: () => ATTRIBUTES.READONLY,
        },
        {
            code: RWRESULT_KEYS.MATERIAL,
            type: TABLE_DATA_TYPES.INPUT,
            translationKey: getTranslation(EQUIPMENT_KEYS.MATERIAL),
            getAttribute: () => ATTRIBUTES.READONLY,
        },
        {
            code: RWRESULT_KEYS.ELIMINATION_PROCESS,
            type: TABLE_DATA_TYPES.INPUT,
            translationKey: getTranslation(TRANSLATION_KEYS.ELIMINATION_PROCESS),
            getAttribute: () => ATTRIBUTES.READONLY,
            customValue: getValue(rwpDropdowns[EQUIPMENT_KEYS.ELIMINATION_PROCESS]),
        },
        {
            code: RWRESULT_KEYS.CONTAIN_ALPHA_EMITTER,
            type: TABLE_DATA_TYPES.INPUT,
            translationKey: getTranslation(EQUIPMENT_KEYS.CONTAIN_ALPHA_EMITTER),
            getAttribute: () => ATTRIBUTES.READONLY,
            customValue: getValue(rwpDropdowns[EQUIPMENT_KEYS.CONTAIN_ALPHA_EMITTER]),
        },
        {
            code: RWRESULT_KEYS.IRRADIATION_END_DATE_MAX,
            type: TABLE_DATA_TYPES.INPUT,
            translationKey: getTranslation(EQUIPMENT_KEYS.IRRADIATION_END_DATE_MAX),
            getAttribute: () => ATTRIBUTES.READONLY,
        },
        {
            code: RWRESULT_KEYS.IRRADIATION_END_DATE_MIN,
            type: TABLE_DATA_TYPES.INPUT,
            translationKey: getTranslation(EQUIPMENT_KEYS.IRRADIATION_END_DATE_MIN),
            getAttribute: () => ATTRIBUTES.READONLY,
        },
    ];

    // Render of the detail
    const details = equipment && rwResultData && data && (
        <Grid container spacing={8}>
            <Grid item md={6} sm={12} xs={12}>
                {/* Equipment details */}
                <EISPanel heading={assetFields.equipmentno.text}>
                    <div style={{ marginTop: 0, height: '100%' }}>
                        <EAMInput
                            elementInfo={{ ...assetFields.serialnumber, readonly: true }}
                            value={equipment.serialNumber}
                        />

                        <EAMInput
                            elementInfo={{ ...assetFields.equipmentdesc, readonly: true }}
                            value={equipment.description}
                        />

                        <EAMInput
                            elementInfo={{ ...assetFields.location, readonly: true }}
                            value={
                                equipment.hierarchyPrimarySystemCode
                                    ? equipment.hierarchyPrimarySystemCode.replace('W$', '')
                                    : equipment.hierarchyLocationCode
                            }
                        />

                        <EAMInput
                            elementInfo={{ ...assetFields.lastLocation, readonly: true }}
                            value={equipment.lastLocationCode}
                        />

                        <EAMInput
                            elementInfo={{ ...assetFields.udfnum07, readonly: true }}
                            value={equipment.userDefinedFields.udfnum07}
                            rightAddon={assetFields.udfnum07.udfUom}
                        />

                        <EAMInput
                            elementInfo={{ ...assetFields.udfnum09, readonly: true }}
                            value={equipment.userDefinedFields.udfnum09}
                            rightAddon={assetFields.udfnum09.udfUom}
                        />

                        <EAMInput
                            elementInfo={{ ...assetFields.udfnum08, readonly: true }}
                            value={equipment.userDefinedFields.udfnum08}
                            rightAddon={assetFields.udfnum08.udfUom}
                        />

                        <EAMInput
                            elementInfo={{ ...assetFields.udfnum10, readonly: true }}
                            value={equipment.userDefinedFields.udfnum10}
                            rightAddon={assetFields.udfnum10.udfUom}
                        />

                        <EAMInput
                            elementInfo={{ ...assetFields.equipmentvalue, readonly: true }}
                            value={equipment.equipmentValue}
                            rightAddon={applicationData.eqpValueCurrency}
                        />

                        {/* RW Reception Date */}
                        <EAMInput
                            elementInfo={{ text: translations.RECEPTIONDATE, readonly: true }}
                            value={equipment.userDefinedFields.udfdate06}
                        />

                        {/* RW Elimination Date */}
                        <EAMInput
                            elementInfo={{ text: translations.RWELIMINATIONDATE, readonly: true }}
                            value={equipment.userDefinedFields.udfdate07}
                        />
                    </div>
                </EISPanel>

                {/* Comments */}
                <CommentsContainer
                    title={translations.EQPCOMMENTS}
                    ref={(commentsRef) => {
                        comments.current = commentsRef;
                    }}
                    entityCode="OBJ"
                    entityKeyCode={equipmentCode}
                    userDesc={userData.eamAccount.userDesc}
                    readComments={WSComments.readComments}
                />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
                {/* Waste details */}
                <EISPanel heading={translations.WASTEDETAILS}>
                    <div style={{ marginTop: 0, height: '100%' }}>
                        {wasteDetailsProperties.map((wasteDetailsProperty) =>
                            InputGenerator.generate({
                                field: {
                                    ...wasteDetailsProperty,
                                    elementInfo: {
                                        text: getTranslation(wasteDetailsProperty.translationKey),
                                        xpath: wasteDetailsProperty.code,
                                    },
                                    customInputProps: () => ({
                                        key: wasteDetailsProperty.translationKey,
                                    }),
                                },
                                object: { ...rwResultData },
                            })
                        )}
                    </div>
                </EISPanel>

                {/* Waste history */}
                <EISPanel heading={translations.WASTEHISTORY}>
                    <div style={{ marginTop: 0, height: '100%' }}>
                        <EISTable data={data} headers={headersTable} propCodes={propCodes} maxMobileSize={600} />
                    </div>
                </EISPanel>
            </Grid>
        </Grid>
    );

    return (
        <Dialog open onClose={handleClose} aria-labelledby="alert-dialog-title" maxWidth="md" fullWidth>
            <DialogTitle id="alert-dialog-title">{`${assetFields.equipmentno.text}: ${equipmentCode}`}</DialogTitle>
            <DialogContent>
                <BlockUi tag="div" blocking={loading} style={{ width: '100%', height: '100%' }}>
                    {!loading && details}
                </BlockUi>
            </DialogContent>
            <DialogActions>
                <TRECButton onClick={handleClose} size="small">
                    <Close />
                    {translations.CLOSE}
                </TRECButton>
            </DialogActions>
        </Dialog>
    );
};

export default RWManagementDetail;
