import { Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import { showError } from 'tools/TrecNotifications';
import WSRPARequest from 'tools/rest/WSRPARequest';
import { generateTableData } from 'ui/pages/rpmrequest2/InputGenerator';
import { EQUIPMENT_KEYS, TABLE_DATA_TYPES } from '../../../../enums/Constants';
import { ATTRIBUTES } from '../../wrrequest/WRRHelpers';
import { RW_ELIMINATION_KEYS, RW_ELIMINATION_PACKAGE_KEYS, TRANSLATION_KEYS } from '../RWEliminationConstants';
import { useEffect, useMemo, useState } from 'react';
import TRECButton from 'ui/components/buttons/TRECButton';
import EDMSDoclightIframeContainer from 'ui/components/iframes/EDMSDoclightIframeContainer';
import { Eye } from 'mdi-material-ui';
import { validateEDMSContext } from '../RWElimUtils';
import ErrorText from 'ui/components/text/ErrorText';

export const getPackageFields = ({ selectedCode, setSelectedCode, getTranslation }) => [
    {
        code: EQUIPMENT_KEYS.EQUIPMENT_CODE,
        type: TABLE_DATA_TYPES.STATIC,
    },
    {
        code: 'equipment',
        valueKey: 'serialno',
        translationKey: TRANSLATION_KEYS.ID_ANDRA,
        type: TABLE_DATA_TYPES.INPUT,
        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
        complex: true,
    },
    {
        code: 'grossMass',
        valueKey: EQUIPMENT_KEYS.WEIGHT,
        translationKey: EQUIPMENT_KEYS.WEIGHT,
        type: TABLE_DATA_TYPES.INPUT,
        getAttribute: () => ATTRIBUTES.READONLY_TEXT,
    },
    {
        code: RW_ELIMINATION_PACKAGE_KEYS.RPA,
        translationKey: TRANSLATION_KEYS.RPA,
        type: TABLE_DATA_TYPES.AUTOCOMPLETE,
        complex: false,
        getAttribute: () => ATTRIBUTES.OPTIONAL,
        linkInput: '/rpanalysis/',
        autocompleteType:
            (object) =>
            (...args) =>
                WSRPARequest.getCompletedRPAs(object[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE], ...args),
        customInputProps: () => ({
            minWidth: '300px',
            width: '300px',
        }),
    },
    {
        code: RW_ELIMINATION_PACKAGE_KEYS.IRAS_VALUE,
        translationKey: TRANSLATION_KEYS.IRAS_VALUE,
        type: TABLE_DATA_TYPES.INPUT,
        getAttribute: (obj) =>
            obj[RW_ELIMINATION_PACKAGE_KEYS.IRAS_VALUE] ? ATTRIBUTES.REQUIRED : ATTRIBUTES.OPTIONAL,
        validate: () => (value) => value && value < 10,
    },
    {
        code: 'RPA_PRIMARYRESULTS',
        type: TABLE_DATA_TYPES.STATIC,
        translationKey: 'RPA_PRIMARYRESULTS',
        customValue: (obj) =>
            selectedCode === obj[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE] ? (
                <EDMSDoclightIframeContainer
                    id={obj[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE]}
                    key={`DocLight${obj[RW_ELIMINATION_PACKAGE_KEYS.RPA]}`}
                    profile="TREC-RPAM-PR"
                    objectType="J"
                    objectID={obj[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE]}
                    title={getTranslation('RP-TREC2SPECTRO-PR')}
                    context="RP-TREC2SPECTRO-PR"
                />
            ) : (
                <TRECButton
                    size="small"
                    onClick={() => setSelectedCode(obj[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE])}
                >
                    <Eye />
                </TRECButton>
            ),
        getAttribute: () => ATTRIBUTES.OPTIONAL,
    },
];

const RWEliminationPackageList = ({
    stepStatus,
    rwElimRequest,
    getTranslation,
    rwElimGetters,
    storeActions,
    labelStyle,
    writeAccess,
    setFormFields,
}) => {
    const [selectedCode, setSelectedCode] = useState();

    const rwElimination = rwElimGetters.getProperties();

    const rwEquipmentList = rwElimination[RW_ELIMINATION_KEYS.PACKAGES];
    const activity = rwElimRequest.stepActivityMap?.[stepStatus];
    const edmsProfile = activity?.edmsProfile;
    const edmsContext = activity?.edmsContext;

    useEffect(() => {
        (async () => {
            const eqpList = Object.values(rwEquipmentList || [])
                .filter((eqp) => !eqp[RW_ELIMINATION_PACKAGE_KEYS.RPA])
                .map((eqp) => eqp[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE]);

            if (!eqpList?.length) {
                return;
            }

            const resp = await Promise.all(eqpList.map((eqp) => WSRPARequest.getCompletedRPAs(eqp, '%')));
            const repT = resp.map((r) => r?.body?.data);

            repT.map(
                (rList) =>
                    rList.sort(
                        (a, b) => (a.completedDate - b.completedDate) / Math.abs(a.completedDate - b.completedDate)
                    )?.[0]
            )
                .map((s, i) => (s ? [[eqpList[i]], { [RW_ELIMINATION_PACKAGE_KEYS.RPA]: s.code }] : null))
                .filter((s) => s)
                .forEach(([eqpCode, objectToUpdate]) =>
                    storeActions.updateRWEliminationPackage(eqpCode, objectToUpdate)
                );
        })();
    }, []);

    const tableLayout = useMemo(
        () =>
            getPackageFields({ selectedCode, setSelectedCode, getTranslation }).map((e) => ({
                ...e,
                header: getTranslation(e.translationKey ?? e.code),
            })),
        [selectedCode]
    );
    const tableHeaders = tableLayout.map((e) => e.header);
    const tableCodes = tableLayout.map((e) => e.code);
    const tableData = generateTableData(
        tableLayout,
        Object.values(rwEquipmentList),
        {
            updateObject: (obj) => (objectToUpdate) =>
                storeActions.updateRWEliminationPackage(
                    obj[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE],
                    objectToUpdate
                ),
            updateObjectPartial: (obj) => (objectToUpdate, path) =>
                storeActions.updateRWEliminationPackage(obj[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE], {
                    [path]: {
                        ...obj[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE][path],
                        ...objectToUpdate,
                    },
                }),
            // formFields,
            labelStyle,
            getTranslation,
            writeAccess,
            setFormFields,
        },
        'index'
    );

    return (
        <div style={{ margin: 8 }}>
            <Grid container spacing={8}>
                <Grid item md={12} sm={12} xs={12}>
                    <EISPanel heading="Package List Details" alwaysExpanded>
                        <div style={{ width: '100%', height: '100%' }}>
                            <EISTable
                                data={tableData}
                                headers={tableHeaders}
                                propCodes={tableCodes}
                                maxMobileSize={600}
                                style={{ overflowY: 'visible' }}
                            />
                            {edmsProfile && !edmsContext && (
                                <ErrorText
                                    text={`${getTranslation(TRANSLATION_KEYS.EDMS_CONTEXT_UNKNOWN)} (${edmsProfile})`}
                                />
                            )}
                            {edmsProfile && edmsContext && (
                                <EDMSDoclightIframeContainer
                                    key="DocLight$"
                                    profile={edmsProfile}
                                    objectType="J"
                                    objectID={rwElimRequest[RW_ELIMINATION_KEYS.CODE]}
                                    title={`Documents ${edmsContext}`}
                                />
                            )}
                        </div>
                    </EISPanel>
                </Grid>
            </Grid>
        </div>
    );
};

class RWEliminationPackageListStep extends WizardStep {
    formFields = {};

    state = {
        formFields: {},
    };

    componentDidMount() {
        super.componentDidMount();
    }

    handleSubmit = async () => {
        const { stepKey, stepStatus, rwElimRequest, getTranslation } = this.props;
        const activity = rwElimRequest.stepActivityMap[stepStatus];
        if (activity?.edmsProfile) {
            try {
                await validateEDMSContext(
                    activity?.edmsContext,
                    activity?.edmsNumberDocuments,
                    rwElimRequest[RW_ELIMINATION_KEYS.CODE],
                    getTranslation
                );
            } catch (error) {
                showError(error.message);
                return;
            }
        }
        this.canContinue() &&
            this.props.handleUpdate({
                translationKey: `${stepKey}_SUBMIT_MSG`,
                updateObject: {
                    [RW_ELIMINATION_KEYS.STATUS]: stepStatus,
                },
            });
    };

    handleSave = () => {
        this.canContinue();
    };

    /**
     * Can continue to the next step on the wizard
     */
    canContinue = () => {
        const isValid = Object.values(this.state.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
        if (!isValid) {
            showError('One or more fields are not valid.');
        }

        return isValid;
    };

    /**
     * SaveChanges is used to save the localized state to the redux-store. It can only run synchronous methods.
     */
    saveChanges = () => true;

    /**
     *  CommitChanges is used to trigger any side effects of the step, such as invocations to the back-end to
     * retrieve information about resources. This method can run asynchronous methods/requests but SHALL execute
     * the callback method afterwards to inform the wizard.
     */
    commitChanges = async (callback) => {
        callback();
    };

    /**
     * On true, the wizard automatically jumps this step immediately after it is mounted
     */
    canJumpStep = () => false;

    render() {
        return (
            <RWEliminationPackageList
                setFormFields={(obj) => {
                    const differs = Object.entries(obj).every(([key, value]) => !value !== !this.state.formFields[key]);
                    differs && this.setState((prevState) => ({ formFields: { ...prevState.formFields, ...obj } }));
                }}
                // formFields={this.state.formFields}
                {...this.props}
            />
        );
    }
}

export default RWEliminationPackageListStep;
