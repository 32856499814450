import parse from 'date-fns/parse';
import ErrorTypes from '../enums/ErrorTypes';

/**
 * General tools for the app
 */

/**
 * To get the value of a parameter from the URL
 * @param name (Key) of the parameter
 * @returns {string} The value of the parameter,or an empty string
 */
const getURLParameterByName = (name) => {
    const url = window.location.href;
    const nameAdjusted = name.replace(/[[]]/g, '\\$&');
    const regex = new RegExp(`[?&]${nameAdjusted}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results || !results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

/**
 * To get the custom fields list with code desc
 * @param customField The custom field
 * @param sorted To know if we sort the list or not before returning it
 *
 */
const sortCodeDescList = (list) =>
    [...list].sort((a, b) => {
        if (a.desc > b.desc) {
            return 1;
        }
        if (a.desc < b.desc) {
            return -1;
        }
        // a must be equal to b
        return 0;
    });

const getCustomFieldsCodeDesc = (customField, sorted = true) => {
    let values = [];
    for (let i = 0; i < customField.pcode.length; i++) {
        if (customField.pcode[i])
            /* To remove empty value */
            values.push({ code: customField.pcode[i], desc: customField.pdesc[i] });
    }
    if (sorted) {
        values = sortCodeDescList(values);
    }
    return values;
};

/**
 * Get the description of a custom field value code
 * @param customField The definition of the custom field
 * @param valueCode the code to be searched
 * @returns {*} Description of the selected code
 */
const getCustomFieldValueDesc = (customField, valueCode) => {
    for (let i = 0; i < (customField.pcode || []).length; i++) {
        if (customField.pcode[i] === valueCode) {
            return customField.pdesc[i];
        }
    }
    return valueCode;
};

/**
 * Get the description of a valueCode in a list
 * @param valueCode The selected code from the list of values
 * @param list the list containing all the values
 * @returns {*} The description of the selected code
 */
const getDescValueFromList = (valueCode, list) => {
    if (!valueCode || !list) {
        return '';
    }
    return list.find((elem) => elem.code === valueCode).desc;
};

/**
 * Filters the list of custom fields to keep only the list of values received
 * @param customField The custom field to be filtered
 * @param valuesToKeep The list of values to be kept
 */
const filterCustomFieldValues = (customField, valuesToKeep) => {
    const pcode = [];
    const pdesc = [];
    for (let i = 0; i < customField.pcode.length; i++) {
        const code = customField.pcode[i];
        if (valuesToKeep.includes(code)) {
            pcode.push(code);
            pdesc.push(customField.pdesc[i]);
        }
    }
    return { ...customField, pcode, pdesc };
};

/**
 * To create a server error that will be displayed on the screen
 * @param title Title of the error
 * @param detail Detail
 * @returns {{type: string, response: {status: number, body: {errors: *[]}}}}
 */
const createServerError = (title, detail) => ({
    type: ErrorTypes.SERVER_ERROR,
    response: {
        status: 400,
        body: {
            errors: [
                {
                    title,
                    detail,
                },
            ],
        },
    },
});

/**
 * To get the value of a cell  in a grid row
 * @param row The grid row to get the cell value
 * @param cellTag The identifier for the cell
 */
const getGridCellValueFromRow = (row, cellTag) => {
    const cell = row.cell.find((elem) => elem.t === cellTag);
    return cell ? cell.value : '';
};

/**
 * To set the value of a cell  in a grid row
 * @param row The grid row to set the cell value to
 * @param cellTag The identifier for the cell
 */
const setGridCellValueFromRow = (row, cellTag, value) => {
    const cell = row.cell.find((elem) => elem.t === cellTag);
    cell.value = value;
};

const toDateString = (date) =>
    `${date.getFullYear()}-${
        date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }-${date.getDate()}`;

/**
 * Create the default confirm object
 * @param translations Translations
 * @returns {{title: *, message: *, cancelButtonLabel: *, confirmButtonLabel: *, waitForCompletion: boolean}}
 */
const createDefaultConfirmObj = (translations) => ({
    title: translations.CONFIRM,
    message: translations.AREYOUSURE,
    cancelButtonLabel: translations.CANCEL,
    confirmButtonLabel: translations.CONFIRM,
    waitForCompletion: true,
});

const getIsVacuumAction = (constants) => (futureAction) => {
    const vacuumActions = [
        constants.futureActionVacuumDeclare,
        constants.futureActionVacuumEmpty,
        constants.futureActionVacuumEnd,
        constants.futureActionVacuumEndFull,
        constants.futureActionVacuumMove,
    ];
    return vacuumActions.includes(futureAction);
};

const updateState = (context, stateCode, entryCode, key, value, applyAllLines) => {
    context.setState((prevState) =>
        (applyAllLines !== undefined ? applyAllLines : prevState.copyOnFirstLine)
            ? {
                  [stateCode]: Object.entries(prevState[stateCode]).reduce(
                      (acc, entry) => ({
                          ...acc,
                          [entry[0]]: {
                              ...entry[1],
                              [key]: value,
                          },
                      }),
                      {}
                  ),
              }
            : {
                  [stateCode]: {
                      ...prevState[stateCode],
                      [entryCode]: {
                          ...prevState[stateCode][entryCode],
                          [key]: value,
                      },
                  },
              }
    );
};

const isEmptyObject = (obj) => obj == null || (Object.keys(obj).length === 0 && obj.constructor === Object);

const groupListBy = (list, key) =>
    list.reduce((acc, entry) => ({ ...acc, [entry[key]]: [...(acc[entry[key]] || []), entry] }), {});

const groupListByFunction = (list, func) =>
    list.reduce((acc, entry) => ({ ...acc, [func(entry)]: [...(acc[func(entry)] || []), entry] }), {});

const isPresent = (val) => val !== null && val !== undefined;

const aggregateEntries = (list) => list.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

const filterObjectFields = (obj, func) =>
    aggregateEntries(Object.entries(obj).filter(([key, value]) => func(key, value)));

const filterObjectFieldsFromList = (object, fieldList) =>
    object && aggregateEntries(Object.entries(object).filter(([entry]) => fieldList.includes(entry)));

const applyToFieldsKV = (obj, func) =>
    aggregateEntries(Object.entries(obj).map(([key, value]) => [key, func(key, value)]));

const applyToFields = (obj, func) => applyToFieldsKV(obj, (k, v) => func(v));

const applyToFieldsK = (obj, func) =>
    aggregateEntries(Object.entries(obj).map(([key, value]) => [func(key, value), value]));

const aggregateObjects = (list) => list.reduce((acc, obj) => Object.assign(acc, obj), {});

const arrayToObject = (array, keyField) => array.reduce((acc, item) => ({ ...acc, [item[keyField]]: item }), {});

const promiseWaitAllSequentially = (promiseArray) =>
    promiseArray.reduce(
        (promiseChain, currentTask) =>
            promiseChain.then((chainResults) =>
                currentTask().then((currentResult) => [...chainResults, currentResult])
            ),
        Promise.resolve([])
    );

const isEmpty = (obj) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) return false; // eslint-disable-line
    }
    return true;
};

const getNewObject = (object, path, value, merge = true, applyToAllFields = false) =>
    !path || !path.length
        ? merge && typeof value === 'object' && typeof object === 'object'
            ? {
                  ...(object || {}),
                  ...(value || {}),
              }
            : value
        : path.length === 2 && applyToAllFields
        ? applyToFields(object, (obj) => ({ ...((merge && obj) || {}), [path[1]]: value }))
        : {
              ...(object || {}),
              [path[0]]: getNewObject(object[path[0]], path.slice(1), value, merge, applyToAllFields),
          };

function updateThisState(path, value, merge, applyToAllFields, callback = null) {
    this.setState((prevState) => getNewObject(prevState, path, value, merge, applyToAllFields), callback);
}

function updateThisStateLegacy(stateCode, entryCode, key, value, copyOnAllLines = false) {
    this.setState((prevState) =>
        getNewObject(
            prevState,
            [
                // Add code to array only if it is defined
                ...[...((stateCode && [stateCode]) || [])],
                ...[...((entryCode && [entryCode]) || [])],
                key,
            ],
            value,
            true,
            copyOnAllLines
        )
    );
}

const getApplyDefaults = (defaultMap, keyList) => (obj) => {
    const getValueOrDefault = (value, filter) =>
        isPresent(value) || !filter
            ? value // apply default only if there is no value and there is a default
            : obj[filter.parentValue] && filter.parentValue !== obj[filter.parentField]
            ? value // constrained default
            : obj[filter.parentField]
            ? obj[filter.parentField] // fetch from parent
            : filter.value; // take the default

    const keys = [...new Set([...(keyList || []), ...Object.keys(obj)])];

    return aggregateEntries(
        keys.map((key, index, values, value = obj[key], filter = defaultMap[key] && defaultMap[key][0]) => [
            key,
            getValueOrDefault(value, filter),
        ])
    );
};

const autocompleteStringToArrayHelper = (value) =>
    value
        .reduce((acc, val) => [...acc, ...val.code.split(',').map((code) => ({ code: code.trim() }))], [])
        .map((v) => v.code);

const uuidv4 = () =>
    ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        // eslint-disable-next-line no-bitwise
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );

const getTranslation =
    ({ screenData, lang, translationKey }) =>
    (code) =>
        !code || getURLParameterByName('lang') === 'DEV'
            ? code
            : screenData[lang].translations[translationKey]?.[code.toUpperCase()] ||
              screenData.EN.translations[translationKey]?.[code.toUpperCase()] ||
              code;

const getRowAsAnObject = (row) =>
    row.cell.reduce(
        (acc, cell) => ({
            ...acc,
            [cell.t]: cell.value || '',
        }),
        {}
    );

const addPeriodByLetter = (date, quantity, unit) => {
    if (!date) return date;
    let year = date.getFullYear ? date.getFullYear() : new Date(date).getFullYear();
    let month = date.getMonth ? date.getMonth() : new Date(date).getMonth();
    switch (unit) {
        case 'M':
            month += quantity;
            break;
        default:
            year += quantity;
    }
    const day = date.getDate ? date.getDate() : new Date(date).getDate();
    return new Date(year, month, day, 0, 0, 0);
};

export const computeMinimumAndMaximumDates = (period, fromDateIn, direction = 1) => {
    if (!period) return {};
    const regex = new RegExp('^([0-9]+)([MY])([0-9]+)?([MY])?([+]?)$');
    const results = regex.exec(period);
    const fromDate =
        typeof fromDateIn === 'string'
            ? parse(fromDateIn, 'dd-MMM-yyyy HH:mm'.substring(0, fromDateIn.length), new Date())
            : fromDateIn;

    const [, atLeastQty, atLeastUnit, atMostQty, atMostUnit] = results;

    const minimumDate = addPeriodByLetter(fromDate, direction * atLeastQty, atLeastUnit);
    const maximumDate = atMostQty != null ? addPeriodByLetter(fromDate, direction * atMostQty, atMostUnit) : null;

    return {
        earliestDate: direction > 0 ? minimumDate : maximumDate,
        latestDate: direction > 0 ? maximumDate : minimumDate,
    };
};

export const getDatesNotInRange = ({ earliestDate, latestDate }, dateList) =>
    dateList
        ?.filter((s) => s)
        .filter((dat) => (earliestDate && dat < earliestDate) || (latestDate && latestDate < dat));

export const returnDateExtremes = (dateList) => {
    const finalEq = dateList.filter((s) => s).sort();
    return {
        minDate: finalEq?.[0],
        maxDate: finalEq?.[finalEq?.length - 1],
    };
};

export default {
    getURLParameterByName,
    getCustomFieldsCodeDesc,
    sortCodeDescList,
    getCustomFieldValueDesc,
    getDescValueFromList,
    filterCustomFieldValues,
    createServerError,
    getGridCellValueFromRow,
    setGridCellValueFromRow,
    toDateString,
    createDefaultConfirmObj,
    updateState,
    groupListBy,
    groupListByFunction,
    aggregateEntries,
    aggregateObjects,
    filterObjectFieldsFromList,
    applyToFields,
    applyToFieldsKV,
    applyToFieldsK,
    filterObjectFields,
    isEmpty,
    isPresent,
    updateThisState,
    getNewObject,
    updateThisStateLegacy,
    promiseWaitAllSequentially,
    isEmptyObject,
    getApplyDefaults,
    autocompleteStringToArrayHelper,
    getIsVacuumAction,
    arrayToObject,
    uuidv4,
    getTranslation,
    getRowAsAnObject,
    addPeriodByLetter,
    getDatesNotInRange,
    computeMinimumAndMaximumDates,
    returnDateExtremes,
};
