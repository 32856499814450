import Wizard from 'amm-tools/dist/components/Wizard';
import { REQUEST_MODES } from 'enums/Constants';
import { useEffect, useMemo, useState } from 'react';
import BlockUi from 'react-block-ui';
import WSVacRental from 'tools/rest/WSVacRental';
import { generateRoute, ROUTES } from 'tools/Routes';
import Tools from 'tools/Tools';
import DefaultStepper from 'ui/components/wizard/DefaultStepper';
import {
    STEPS,
    VACUUM_RENTAL_KEYS,
    VACUUM_RENTAL_STATUS,
    VACUUM_TYPE_KEYS,
} from 'ui/pages/vaccleanerrequest/VacCleanerConstants';
import TRECPage from '../TRECPage';
import VacCleanerAccessoryStep from './steps/VacCleanerAccessoryStep.js';
import VacCleanerModelsStep from './steps/VacCleanerModelsStep';
import VacuumWizardControls from './VacuumWizardControls';
import VacCleanerAssignmentStep from './steps/VacCleanerAssignmentStep';
import VacCleanerDeclareUseStep from './steps/VacCleanerDeclareUseStep';
import VacCleanerDurationStep from './steps/VacCleanerDurationStep';
import VacCleanerSummaryStep from './steps/VacCleanerSummaryStep';
import VacCleanerUserStep from './steps/VacCleanerUserStep';
import VacCleanerReturnedStep from './steps/VacCleanerReturnedStep';
import WSRWPProcessing from 'tools/rest/WSRWPProcessing';
import { handleError, showSuccess } from 'tools/TrecNotifications';

const labelStyle = {
    width: 200,
    minWidth: 200,
};

const VAC_STEPS = {
    [STEPS.DETAILS]: {
        key: STEPS.DETAILS,
        Step: VacCleanerUserStep,
        translation: 'DETAILS',
    },
    [STEPS.MODELS]: {
        key: STEPS.MODELS,
        Step: VacCleanerModelsStep,
        translation: 'MODELS',
    },
    [STEPS.ACCESSORIES]: {
        key: STEPS.ACCESSORIES,
        Step: VacCleanerAccessoryStep,
        translation: 'ACCESSORIES',
    },
    [STEPS.DURATION]: {
        key: STEPS.DURATION,
        Step: VacCleanerDurationStep,
        translation: 'DURATION',
    },
    [STEPS.ASSIGNMENT]: {
        key: STEPS.ASSIGNMENT,
        Step: VacCleanerAssignmentStep,
        translation: STEPS.ASSIGNMENT,
    },
    [STEPS.DECLAREUSE]: {
        key: STEPS.DECLAREUSE,
        Step: VacCleanerDeclareUseStep,
        translation: STEPS.DECLAREUSE,
    },
    [STEPS.VALIDATION]: {
        key: STEPS.VALIDATION,
        Step: VacCleanerReturnedStep,
        translation: STEPS.VALIDATION,
    },
    [STEPS.SUMMARY]: {
        key: STEPS.SUMMARY,
        Step: VacCleanerSummaryStep,
        translation: 'SUMMARY',
    },
};

// const VACUUM_STATUS_MAXSTEP = {
//     [VACUUM_STATUS.CREATED]: STEPS.SUMMARY,
// };

const wrapStepProps = (Component, stepProps) => (props) =>
    <Component style={{ overflowX: 'hidden' }} {...stepProps} {...props} />;

const getSteps = (stepProps = {}) =>
    Tools.applyToFields(VAC_STEPS, (step) => ({
        ...step,
        getContent: wrapStepProps(step.Step, stepProps),
        label: stepProps.getTranslation(step.translation),
    }));

const getStepSequence = (properties, writeAccess) => [
    STEPS.MODELS,
    STEPS.ACCESSORIES,
    STEPS.DETAILS,
    STEPS.DURATION,
    STEPS.SUMMARY,
    ...([VACUUM_RENTAL_STATUS.IN_PROGRESS, VACUUM_RENTAL_STATUS.COMPLETED].includes(
        properties[VACUUM_RENTAL_KEYS.STATUS]
    )
        ? writeAccess
            ? [STEPS.ASSIGNMENT, STEPS.DECLAREUSE, STEPS.VALIDATION]
            : [STEPS.DECLAREUSE]
        : []),
];

const isFormValid = (vacuumRequest) => vacuumRequest.vacCleanerRentalLines.length > 0;

const VacCleanerRequestFC = (props) => {
    const {
        getTranslation,
        applicationData,
        constants,
        userData,
        screenData,
        translations,
        storeActions,
        assetFields,
        customFields,
        customFieldsDef,
        urlParams,
        bufferZone,
        bufferZones,
        dropdowns,
        history,
        vacCleanerGetters,
        vacuumRequest,
        writeAccess,
        mode,
        match,
        getVacCount,
        openConfirmDialog,
    } = props;

    const [loading, setLoading] = useState(vacCleanerGetters.isBlockUI());
    const [initialized, setInitialized] = useState(false);
    const [vacuumsData, setVacuumsData] = useState([]);
    const [valuesGrid, setValuesGrid] = useState(null);

    const stepSequence = getStepSequence(vacCleanerGetters.getProperties(), writeAccess);
    const firstStepIndex = stepSequence.findIndex(
        (step) => step.localeCompare(urlParams.step, 'en', { sensitivity: 'accent' }) === 0
    );

    const { code, statusCode, creator, requester } = vacCleanerGetters.getProperties();
    const { vaccleanerNumber } = match.params;

    const callWs = useMemo(
        () =>
            (ws, args, sucess, error = handleError) =>
            async () => {
                try {
                    const data = await ws(...args);
                    sucess ? sucess(data, showSuccess) : showSuccess(data);
                    return data;
                } catch (err) {
                    error(err);
                    return null;
                }
            },
        [showSuccess, handleError]
    );

    const loadingAround = useMemo(
        () =>
            (func) =>
            async (...args) => {
                setLoading(true);
                const data = await func(...args);
                setLoading(false);
                return data;
            },
        [setLoading]
    );

    const blockAndCallWs = useMemo(
        () =>
            (...args) =>
                loadingAround(callWs(...args)),
        []
    );

    const reload = blockAndCallWs(
        WSVacRental.readVacRental,
        [vaccleanerNumber, applicationData],
        storeActions.updateWholeVacRental
    );

    useEffect(() => {
        blockAndCallWs(
            async () => WSRWPProcessing.getValuesGrid().then((s) => Promise.resolve(s?.body?.data)),
            [],
            setValuesGrid
        )();
    }, []);

    useEffect(() => {
        storeActions.resetVacRental({ userData, bufferZone });
    }, []);

    const computedWriteAccess =
        writeAccess ||
        !vaccleanerNumber ||
        ([VACUUM_RENTAL_STATUS.CREATED, null].includes(statusCode) &&
            [creator.cernId, requester.cernId].includes(userData.eamAccount.employeeCode));

    const stepProps = {
        getTranslation,
        applicationData,
        constants,
        userData,
        screenData,
        translations,
        storeActions,
        assetFields,
        customFields,
        customFieldsDef,
        urlParams,
        bufferZone,
        bufferZones,
        dropdowns,
        requestSource: urlParams.requestSource,
        history,
        vacuumRequest,
        labelStyle,
        vacCleanerGetters,
        getVacCount,
        match,
        blockAndCallWs,
        callWs,
        reload,
        loading,
        vacuumsData,
        setVacuumsData,
        writeAccess: computedWriteAccess,
        adminMode: writeAccess,
        valuesGrid,
    };

    const handleCancel = () => history.goBack();

    useEffect(() => {
        const load = async () => {
            const vacTypes = await WSVacRental.getVacTypes();
            setVacuumsData(
                vacTypes
                    .filter((vac) => vac[VACUUM_TYPE_KEYS.ENABLED])
                    .sort((a, b) => a[VACUUM_TYPE_KEYS.CODE].localeCompare(b[VACUUM_TYPE_KEYS.CODE]))
            );
        };
        load();
    }, []);

    useEffect(() => {
        if (REQUEST_MODES.CREATING === mode) {
            storeActions.resetVacRental({ userData, bufferZone });
            setInitialized(true);
        }
        if (REQUEST_MODES.EDITING === mode) {
            const fetch = async () => {
                await reload();
                setInitialized(true);
            };
            fetch();
        }
    }, [vaccleanerNumber]);

    const createOrUpdateVacRentalRequest = async (status) => {
        const vacRentalRequest = {
            ...vacuumRequest,
            [VACUUM_RENTAL_KEYS.STATUS]: status || statusCode || VACUUM_RENTAL_STATUS.CREATED,
        };
        const prom = !code
            ? WSVacRental.createVacRental({ vacRentalRequest, applicationData })
            : WSVacRental.updateVacRental({ vacRentalRequest, applicationData });
        const returnCode = await prom;
        history.push(generateRoute(ROUTES.vaccleanerRequestEdit, { vaccleanerNumber: returnCode }));
        return returnCode;
    };

    const handleCreate = (status) =>
        blockAndCallWs(createOrUpdateVacRentalRequest, [status], () => {
            showSuccess('Vacuum Cleaner Lending Request created or updated successfully');
            vaccleanerNumber && reload();
        })();

    const cancelVacRequest = () =>
        openConfirmDialog(
            {
                title: getTranslation('VAC_REQUEST_CANCEL_TITLE'),
                message: getTranslation('VAC_REQUEST_CANCEL_MESSAGE'),
                cancelButtonLabel: getTranslation('CANCEL'),
                confirmButtonLabel: getTranslation(getTranslation('CONFIRM')),
                waitForCompletion: true,
            },
            blockAndCallWs(WSVacRental.deleteVacRental, [vaccleanerNumber], () => {
                showSuccess('Vacuum Cleaner Lending Request created or updated successfully');
                vaccleanerNumber && reload();
            })
        );

    return initialized ? (
        <BlockUi blocking={loading} style={{ height: '100%' }}>
            <Wizard
                {...props}
                firstStepIndex={firstStepIndex}
                steps={getSteps(stepProps)}
                stepSequence={stepSequence}
                handleCancel={handleCancel}
                renderStepper={(p) => <DefaultStepper {...p} />}
                controls={(p) => (
                    <VacuumWizardControls
                        {...p}
                        divStyle={{ margin: 'unset', height: 'unset', padding: 8 }}
                        getTranslation={getTranslation}
                        canUpdate={computedWriteAccess}
                        handleCreate={handleCreate}
                        reload={reload}
                        vaccleanerNumber={vaccleanerNumber}
                        cancelVacRequest={cancelVacRequest}
                        validForm={isFormValid(vacuumRequest)}
                        statusCode={statusCode}
                    />
                )}
            />
        </BlockUi>
    ) : (
        <BlockUi message="Loading..." blocking style={{ height: '100%', width: '100%' }} />
    );
};

class VacCleanerRequest extends TRECPage {
    renderPage(writeAccess) {
        return <VacCleanerRequestFC checkAccess={() => this.checkAccess()} writeAccess={writeAccess} {...this.props} />;
    }
}

export default VacCleanerRequest;
