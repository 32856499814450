import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import { CircularProgress, Grid } from '@material-ui/core';
import EDMSDoclightIframeContainer from 'ui/components/iframes/EDMSDoclightIframeContainer';
import { EQUIPMENT_KEYS } from '../../rwprocessing/RWPConstants';
import { BLOCK_KEYS, EVENT_KEYS, EVENT_TYPES, BLOCK_STATUS } from './Constants';
import InputGenerator from '../../rpmrequest2/InputGenerator';
import WSRPMeasurement from '../../../../tools/rest/WSRPMeasurement';
import WSRPARequest from '../../../../tools/rest/WSRPARequest';
import WSAutocomplete from '../../../../tools/rest/WSAutocomplete';
import getBlocks from './EquipmentOverviewUtils';

const FALLBACK_BLOCKS_ORDER = [
    BLOCK_KEYS.EQUIPMENT_DETAILS,
    BLOCK_KEYS.LOCATION,
    BLOCK_KEYS.QUERY_DATA,
    BLOCK_KEYS.EDH_PROPERTIES,
    BLOCK_KEYS.LAST_RPM_JOB,
    BLOCK_KEYS.RADIATION_PROPERTIES,
    BLOCK_KEYS.CONTAMINATION_PROPERTIES,
    BLOCK_KEYS.LAST_RPA_REQUEST,
    BLOCK_KEYS.LAST_RP_SAMPLING,
    BLOCK_KEYS.LAST_CADRA,
    BLOCK_KEYS.LAST_RW_CHECK,
    BLOCK_KEYS.LAST_RWP_CHECK,
    BLOCK_KEYS.RW_PROPERTIES,
];

const EquipmentOverview = (props) => {
    const { equipment, eventData, dropdowns, applicationData, getTranslation, screenData, storeActions } = props;
    const [eventEntries, setEventEntries] = useState({});
    const [lazyDataEntries, setLazyDataEntries] = useState({});
    const [blockStatus, setBlockStatus] = useState(
        Object.keys(BLOCK_KEYS).reduce((blockKey) => ({ [blockKey]: BLOCK_STATUS.LOADED }))
    );
    const [tempState, setTempState] = useState({});

    const getLazyDataEntry = useCallback((key) => lazyDataEntries[key] || {}, [lazyDataEntries]);

    const updateBlockStatus = ({ blockKey, status }) => {
        setBlockStatus((prevState) => ({
            ...prevState,
            [blockKey]: status,
        }));
    };

    const analysisEventKeys = useMemo(() => {
        const { analysisTypeClassMap } = applicationData;
        const analysisTypes = Object.values(analysisTypeClassMap);
        return Object.keys(eventEntries).filter((key) => analysisTypes.includes(key));
    }, [applicationData, eventEntries]);

    useEffect(() => {
        if (equipment && equipment[EQUIPMENT_KEYS.RESPONSIBLE]) {
            updateBlockStatus({ blockKey: BLOCK_KEYS.EQUIPMENT_DETAILS, status: BLOCK_STATUS.LOADING });
            WSAutocomplete.autocompleteEmployee(equipment[EQUIPMENT_KEYS.RESPONSIBLE])
                .then((response) => {
                    const employee = response.body.data[0] || {};
                    setLazyDataEntries((prevState) => ({
                        ...prevState,
                        [equipment[EQUIPMENT_KEYS.CODE]]: {
                            [EQUIPMENT_KEYS.RESPONSIBLE]: employee,
                        },
                    }));
                    updateBlockStatus({ blockKey: BLOCK_KEYS.EQUIPMENT_DETAILS, status: BLOCK_STATUS.LOADED });
                })
                .catch((error) => {
                    // TODO
                    updateBlockStatus({ blockKey: BLOCK_KEYS.EQUIPMENT_DETAILS, status: BLOCK_STATUS.ERROR });
                    console.error(error);
                });
        }
    }, [equipment]);

    useEffect(() => {
        const newEventEntries = eventData
            .filter((entry) => entry[EVENT_KEYS.ORDERED] === '1')
            .reduce((acc, entry) => ({ ...acc, [entry[EVENT_KEYS.GROUPID]]: entry }), {});

        const lastRpm = [newEventEntries[EVENT_TYPES.CADRA], newEventEntries[EVENT_TYPES.RPM]].sort((a, b) =>
            b?.[EVENT_KEYS.EVT_COMPLETED] > a?.[EVENT_KEYS.EVT_COMPLETED] ? 1 : -1
        )?.[0];

        if (lastRpm?.[EVENT_KEYS.CODE] !== newEventEntries[EVENT_TYPES.RPM]?.[EVENT_KEYS.CODE]) {
            newEventEntries[EVENT_TYPES.RPM] = lastRpm;
        }

        if (lastRpm) {
            updateBlockStatus({ blockKey: BLOCK_KEYS.LAST_RPM_JOB, status: BLOCK_STATUS.LOADING });
            WSRPMeasurement.getRPM({ rpmCode: lastRpm.CODE })
                .then((response) => {
                    setLazyDataEntries((prevState) => ({ ...prevState, [EVENT_TYPES.RPM]: response.body.data }));
                    updateBlockStatus({ blockKey: BLOCK_KEYS.LAST_RPM_JOB, status: BLOCK_STATUS.LOADED });
                })
                .catch((error) => {
                    // TODO
                    updateBlockStatus({ blockKey: BLOCK_KEYS.LAST_RPM_JOB, status: BLOCK_STATUS.ERROR });
                    console.error(error);
                });
        }

        setEventEntries(newEventEntries);
    }, [eventData]);

    useEffect(() => {
        if (analysisEventKeys.length) {
            updateBlockStatus({ blockKey: BLOCK_KEYS.LAST_RPA_JOBS, status: BLOCK_STATUS.LOADING });
            analysisEventKeys.forEach((eventKey) => {
                WSRPARequest.getRPAnalysisPerform(eventEntries[eventKey][EVENT_KEYS.CODE])
                    .then((response) => {
                        setLazyDataEntries((prevState) => ({ ...prevState, [eventKey]: response.body.data }));
                        updateBlockStatus({ blockKey: BLOCK_KEYS.LAST_RPA_JOBS, status: BLOCK_STATUS.LOADED });
                    })
                    .catch((error) => {
                        // TODO
                        updateBlockStatus({ blockKey: BLOCK_KEYS.LAST_RPA_JOBS, status: BLOCK_STATUS.ERROR });
                        console.error(error);
                    });
            });
        }
    }, [analysisEventKeys, eventEntries]);

    const blocks = React.useMemo(
        () =>
            getBlocks({
                equipment,
                eventEntries,
                getLazyDataEntry,
                dropdowns,
                applicationData,
                analysisEventKeys,
                blockStatus,
                screenData,
                tempState,
                setTempState,
                storeActions,
            }),
        [
            equipment,
            eventEntries,
            getLazyDataEntry,
            dropdowns,
            applicationData,
            analysisEventKeys,
            blockStatus,
            tempState,
        ]
    );

    const blocksOrder = applicationData.equipmentPageBlockOrder || FALLBACK_BLOCKS_ORDER;

    return (
        <Grid container spacing={8} style={{ height: '100%', overflow: 'auto' }}>
            <Grid item xs={12}>
                {blocksOrder.map((blockKey) => {
                    const block = blocks[blockKey];
                    if (!block || !block.isVisible()) return null;

                    const allRows = block.rows.reduce(
                        (acc, row) => [
                            ...acc,
                            row.reduce(
                                (acc2, field) => ({
                                    ...acc2,
                                    [field.code]: (
                                        <div key={field.code}>
                                            {InputGenerator.generate({
                                                field: {
                                                    ...field,
                                                    elementInfo: {
                                                        xpath: field.code,
                                                        text: getTranslation(field.translationKey),
                                                    },
                                                },
                                                updateObject: ((fun) => fun || (() => null))(field.updateObject),
                                                object: field.object || {},
                                            })}
                                        </div>
                                    ),
                                }),
                                {}
                            ),
                        ],
                        []
                    );

                    const headers = (block.rows[0] || []).map((field) =>
                        getTranslation(field.translationKey || field.code)
                    );
                    const propCodes = (block.rows[0] || []).map((field) => field.code);

                    return (
                        <EISPanel
                            key={blockKey}
                            detailsStyle={{ overflow: 'visible' }}
                            heading={
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    {getTranslation(block.translationKey)}
                                    {block.isLoading && block.isLoading() ? (
                                        <CircularProgress size={15} style={{ marginLeft: '15px', marginTop: '2px' }} />
                                    ) : null}
                                </div>
                            }
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <EISTable
                                        data={allRows}
                                        headers={headers}
                                        propCodes={propCodes}
                                        maxMobileSize={600}
                                    />
                                </Grid>
                                {block.doclightPanelInfo && (
                                    <Grid item xs={12}>
                                        <EDMSDoclightIframeContainer
                                            title={block.doclightPanelInfo.translationKey}
                                            objectID={block.doclightPanelInfo.objectID}
                                            objectType={block.doclightPanelInfo.objectType}
                                            documentType=""
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </EISPanel>
                    );
                })}
            </Grid>
        </Grid>
    );
};

export default EquipmentOverview;
