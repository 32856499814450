import { Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { TABLE_DATA_TYPES } from 'enums/Constants';
import { useMemo, useState } from 'react';
import BlockUi from 'react-block-ui';
import {
    TRANSLATION_KEYS,
    VACUUM_RENTAL_LINE_KEYS,
    VACUUM_RENTAL_KEYS,
} from 'ui/pages/vaccleanerrequest/VacCleanerConstants';
import InputGenerator, { ATTRIBUTES } from '../rpmrequest2/InputGenerator';

const VacReturnDialog = ({
    rentalLine,
    closeDialog,
    getTranslation,
    returnVacuum,
    reload,
    loading,
    applicationData,
    vacuumRequest,
}) => {
    const [replacement, setReplacement] = useState(false);
    const [replacementReason, setReplacementReason] = useState();
    const [returnLocation, setReturnLocation] = useState(vacuumRequest[VACUUM_RENTAL_KEYS.USAGE_LOCATION]);

    const replacementReasons = useMemo(
        () => [
            { code: 'EMPTY_REQUEST', desc: getTranslation('REPLACE_EMPTY_REQUEST') },
            { code: 'REPLACE_BROKEN', desc: getTranslation('REPLACE_BROKEN') },
        ],
        []
    );

    return (
        <Dialog open={!!rentalLine} onClose={closeDialog} fullWidth maxWidth="md">
            <DialogTitle>{getTranslation('RETURNVACUUM')}</DialogTitle>
            {rentalLine && (
                <DialogContent style={{ minHeight: '220px' }}>
                    <DialogContentText variant="h6" style={{ fontWeight: 'normal', margin: '1rem 0' }}>
                        <div
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: getTranslation('RETURN_VACUUM_MSG').replace(
                                    '{0}',
                                    rentalLine[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE]
                                ),
                            }}
                        />
                    </DialogContentText>
                    <Grid container spacing={8} flexDirection="column">
                        <Grid item xs={12} container justify="space-between">
                            <Grid item xs={9}>
                                {InputGenerator.generate({
                                    field: {
                                        code: VACUUM_RENTAL_KEYS.USAGE_LOCATION,
                                        type: TABLE_DATA_TYPES.LOOKUP_SELECT,
                                        keysMap: (field) => ({
                                            code: 'obj_code',
                                            mapCodeTo: field.code,
                                        }),
                                        elementInfo: {
                                            text: getTranslation('DELIVERY_LOCATION'),
                                            attribute: 'R',
                                        },
                                        values:
                                            applicationData.bufferZones
                                                .filter(
                                                    (bz) =>
                                                        !vacuumRequest[VACUUM_RENTAL_KEYS.FACILITY] ||
                                                        bz.facility === vacuumRequest[VACUUM_RENTAL_KEYS.FACILITY]
                                                )
                                                .map((bz) => ({
                                                    code: bz.locationCode,
                                                    desc: bz.locationCode,
                                                })) || [],
                                        gridId: applicationData.locationRaisinGridID,
                                        customInputProps: () => ({
                                            select: {
                                                labelStyle: { wordWrap: 'break-word' },
                                                style: { width: '100%' },
                                            },
                                        }),
                                    },
                                    object: {
                                        [VACUUM_RENTAL_KEYS.USAGE_LOCATION]: returnLocation,
                                    },
                                    getTranslation,
                                    updateObject: (code, val) => setReturnLocation(val),
                                })}
                            </Grid>
                            <Grid item xs={3} container alignItems="center">
                                {InputGenerator.generate({
                                    field: {
                                        code: 'replacement',
                                        type: TABLE_DATA_TYPES.CHECKBOX,
                                        elementInfo: {
                                            text: getTranslation(TRANSLATION_KEYS.NEED_REPLACEMENT),
                                        },
                                        customInputProps: () => ({
                                            labelStyle: {
                                                width: 'auto',
                                                minWidth: '80%',
                                                wordWrap: 'break-word',
                                                textAlign: 'right',
                                            },
                                            style: { justifyContent: 'flex-end' },
                                        }),
                                    },
                                    object: {
                                        replacement,
                                    },
                                    getTranslation,
                                    updateObject: (code, val) => setReplacement(val),
                                })}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {InputGenerator.generate({
                                field: {
                                    type: TABLE_DATA_TYPES.SELECT,
                                    values: replacementReasons,
                                    required: true,
                                    code: 'replacementReason',
                                    getAttribute: () => (replacement ? ATTRIBUTES.REQUIRED : ATTRIBUTES.HIDDEN),
                                    translationKey: 'replacementReason',
                                },
                                object: {
                                    replacementReason,
                                },
                                getTranslation,
                                updateObject: (code, val) => setReplacementReason(val),
                            })}
                        </Grid>
                    </Grid>
                </DialogContent>
            )}
            <DialogActions>
                <BlockUi blocking={loading}>
                    <Button onClick={closeDialog} color="default">
                        {getTranslation('CANCEL')}
                    </Button>
                    <Button
                        disabled={(replacement && !replacementReason) || !returnLocation}
                        onClick={returnVacuum(
                            {
                                reservationCode: rentalLine?.[VACUUM_RENTAL_LINE_KEYS.CODE],
                                returnBean: {
                                    replacementReason,
                                    returnLocation,
                                },
                            },
                            (data, success) => {
                                reload();
                                success(data);
                                closeDialog();
                            }
                        )}
                        color="primary"
                        variant="contained"
                        autoFocus
                    >
                        {getTranslation('CONFIRM')}
                    </Button>
                </BlockUi>
            </DialogActions>
        </Dialog>
    );
};

export default VacReturnDialog;
