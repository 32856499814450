import { VACUUM_RENTAL_ACCESSORY_KEYS, VACUUM_RENTAL_LINE_KEYS } from 'ui/pages/vaccleanerrequest/VacCleanerConstants';

export const VACUUM_ACTION_TYPES = {
    ADD_VACUUM: 'ADD_VACUUM',
    REMOVE_VACUUM: 'REMOVE_VACUUM',
    SET_VACUUM_QUANTITY: 'SET_VACUUM_QUANTITY',
    RESET_CART: 'RESET_CART',
    ADD_ACCESSORY: 'ADD_ACCESSORY',
    REMOVE_ACCESSORY: 'REMOVE_ACCESSORY',
    SET_ACCESSORY_QUANTITY: 'SET_ACCESSORY_QUANTITY',
    RESET_VAC_RENTAL: 'RESET_VAC_RENTAL',
    UPDATE_WHOLE_VAC_RENTAL: 'UPDATE_WHOLE_VAC_RENTAL',
    UPDATE_VAC_RENTAL: 'UPDATE_VAC_RENTAL',
    UPDATE_VAC_RENTAL_PARTIAL: 'UPDATE_VAC_RENTAL_PARTIAL',
    UPDATE_VAC_RENTAL_LINE: 'UPDATE_VAC_RENTAL_LINE',
    ASSIGN_VACUUM: 'ASSIGN_VACUUM',
    ASSIGN_VACUUM_RESERVATION: 'ASSIGASSIGN_VACUUM_RESERVATIONN_VACUUM',
    UNASSIGN_VACUUM: 'UNASSIGN_VACUUM',
};

export const resetVacRental = ({ userData, bufferZone } = {}) => ({
    type: VACUUM_ACTION_TYPES.RESET_VAC_RENTAL,
    value: { userData, bufferZone },
});

export const updateWholeVacRental = (value) => ({
    type: VACUUM_ACTION_TYPES.UPDATE_WHOLE_VAC_RENTAL,
    value,
});

export const updateVacRental = (key, value) => ({
    type: VACUUM_ACTION_TYPES.UPDATE_VAC_RENTAL,
    key,
    value,
});

export const updateVacRentalPartial = (path, value) => ({
    type: VACUUM_ACTION_TYPES.UPDATE_VAC_RENTAL_PARTIAL,
    path,
    value,
});

export const updateVacRentalLine = (key, value) => ({
    type: VACUUM_ACTION_TYPES.UPDATE_VAC_RENTAL_LINE,
    key,
    value,
});

export const resetVacuumCart = () => ({
    type: VACUUM_ACTION_TYPES.RESET_CART,
});

export const addVacuum = (vacuum, locations) => ({
    type: VACUUM_ACTION_TYPES.ADD_VACUUM,

    data: {
        vacuum: {
            ...vacuum,
            partCode: undefined,
            [VACUUM_RENTAL_LINE_KEYS.VAC_TYPE]: vacuum[VACUUM_RENTAL_ACCESSORY_KEYS.PART_CODE],
        },
        locations,
    },
});

export const removeVacuum = (partCode, location, code) => ({
    type: VACUUM_ACTION_TYPES.REMOVE_VACUUM,
    data: {
        [VACUUM_RENTAL_LINE_KEYS.VAC_TYPE]: partCode,
        [VACUUM_RENTAL_LINE_KEYS.LOCATION]: location,
        [VACUUM_RENTAL_LINE_KEYS.CODE]: code,
    },
});

export const setVacuumQuantity = (vacuum, quantity) => ({
    type: VACUUM_ACTION_TYPES.SET_VACUUM_QUANTITY,
    data: {
        vacuum,
        quantity,
    },
});

export const changeVacuumQuantity = (vacuum, changeQuantity) => ({
    type: VACUUM_ACTION_TYPES.SET_VACUUM_QUANTITY,
    data: {
        vacuum,
        changeQuantity,
    },
});

export const addAccessory = (accessory, vacuumsToAttachTo) => ({
    type: VACUUM_ACTION_TYPES.ADD_ACCESSORY,
    data: {
        accessory,
        vacuumsToAttachTo,
    },
});

export const removeAccessory = (accessoryCode, vacuumToRemoveFrom) => ({
    type: VACUUM_ACTION_TYPES.REMOVE_ACCESSORY,
    data: {
        accessoryCode,
        vacuumToRemoveFrom,
    },
});

export const setAccessoryQuantity = (accessoryCode, vacuum, requestedQuantity) => ({
    type: VACUUM_ACTION_TYPES.SET_ACCESSORY_QUANTITY,
    data: {
        accessoryCode,
        vacuum,
        requestedQuantity,
    },
});

export const assignVacuum = (assetCode, vacuumType) => ({
    type: VACUUM_ACTION_TYPES.ASSIGN_VACUUM,
    data: {
        assetCode,
        vacuumType,
    },
});

export const assignVacuumReservation = (assetCode, reservationCode) => ({
    type: VACUUM_ACTION_TYPES.ASSIGN_VACUUM_RESERVATION,
    data: {
        assetCode,
        reservationCode,
    },
});

export const unassignVacuum = (rentalLineCode) => ({
    type: VACUUM_ACTION_TYPES.UNASSIGN_VACUUM,
    data: {
        rentalLineCode,
    },
});

export default {
    resetVacRental,
    updateWholeVacRental,
    updateVacRental,
    updateVacRentalPartial,
    updateVacRentalLine,
    resetVacuumCart,
    addVacuum,
    removeVacuum,
    setVacuumQuantity,
    changeVacuumQuantity,
    addAccessory,
    removeAccessory,
    setAccessoryQuantity,
    assignVacuum,
    assignVacuumReservation,
    unassignVacuum,
};
