import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { DATE_TIME_FORMAT } from 'enums/Constants';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EquipmentOption from 'ui/components/autocomplete/EquipmentOption';
import { ENTITY_TRANSL_PREFIX, TRANSLATION_KEYS } from '../../WRRConstants';

const WRRDetailsPanel = ({ getTranslation, values, lang }) => {
    const labelStyle = {
        color: 'rgb(0, 101, 152)',
        fontWeight: 'bold',
    };
    let formattedCreatedOn = values?.createdOn;

    try {
        formattedCreatedOn = format(values?.createdOn, DATE_TIME_FORMAT.HUMAN_DATE_FNS, {
            locale: lang === 'FR' ? fr : enUS,
        });
    } catch (err) {
        console.error(err);
    }

    return (
        <EISPanel
            detailsStyle={{ display: 'flex', flexDirection: 'column' }}
            heading={getTranslation(TRANSLATION_KEYS.REQUEST_DATA)}
            alwaysExpanded
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 7,
                }}
            >
                <Typography
                    style={{
                        color: 'rgb(0, 101, 152)',
                        fontSize: 16,
                        fontWeight: 'bold',
                    }}
                >
                    {getTranslation(TRANSLATION_KEYS.JOB_NUMBER)} {values?.jobNumber}
                </Typography>
                <Typography
                    style={{
                        color: 'grey',
                        fontSize: 16,
                        fontStyle: 'italic',
                    }}
                >
                    {getTranslation(ENTITY_TRANSL_PREFIX.EVT_STATUS + values?.status)} ({values?.status})
                </Typography>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 7 }}>
                <Typography style={labelStyle}>{getTranslation(TRANSLATION_KEYS.LOCATION)}</Typography>
                <Typography>
                    {values?.location?.desc} ({values?.location?.building})
                </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 7 }}>
                <Typography style={labelStyle}>{getTranslation(TRANSLATION_KEYS.RPO)}</Typography>
                <Typography>{values?.rpo}</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 7 }}>
                <Typography style={labelStyle}>{getTranslation(TRANSLATION_KEYS.REQUESTER)}</Typography>
                <Typography>
                    {values?.requester?.description} ({values?.requester?.cernId})
                </Typography>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 7 }}>
                <Typography style={labelStyle}>{getTranslation(TRANSLATION_KEYS.FORECASTED_RELEASE_VOLUME)}</Typography>
                <Typography>{values?.forecastedReleaseVolume} m³</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 7 }}>
                <Typography style={labelStyle}>{getTranslation(TRANSLATION_KEYS.FORECASTED_RELEASE_DATE)}</Typography>
                <Typography>
                    {format(values?.forecastedReleaseDate, DATE_TIME_FORMAT.HUMAN_DATE_FNS, {
                        locale: lang === 'FR' ? fr : enUS,
                    })}
                </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 7 }}>
                <Typography style={labelStyle}>{getTranslation(TRANSLATION_KEYS.EQUIPMENT)}</Typography>
                <EquipmentOption
                    data={values.equipment}
                    getStyles={() => ({
                        backgroundColor: '#fafafa',
                        padding: 10,
                        border: '1px solid #d4d9de',
                        borderRadius: 4,
                        marginBottom: 10,
                        flexBasis: '70%',
                        maxWidth: 400,
                    })}
                    cx={() => {}}
                />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 7 }}>
                <Typography style={labelStyle}>{getTranslation(TRANSLATION_KEYS.CREATED_BY)}</Typography>
                <div>
                    <Typography>
                        {values?.creator?.description} ({values?.creator?.cernId})
                    </Typography>
                    <Typography style={{ textAlign: 'right' }}>{formattedCreatedOn}</Typography>
                </div>
            </div>
            {values?.modifiedOn && (
                <div
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}
                >
                    <Typography style={labelStyle}>{getTranslation(TRANSLATION_KEYS.UPDATED_BY)}</Typography>
                    <div>
                        <Typography>
                            {values?.modifiedBy?.description} ({values?.modifiedBy?.cernId})
                        </Typography>
                        <Typography style={{ textAlign: 'right' }}>
                            {format(values?.modifiedOn, DATE_TIME_FORMAT.HUMAN_DATE_FNS, {
                                locale: lang === 'FR' ? fr : enUS,
                            })}
                        </Typography>
                    </div>
                </div>
            )}
        </EISPanel>
    );
};

export default WRRDetailsPanel;
