import moment from 'moment';
import ValuesManager from '../tools/ValuesManager';
import {
    ANALYSIS_KEYS,
    DATE_FORMAT,
    DATE_TIME_FORMAT,
    EQUIPMENT_KEYS,
    RPAR_PERFORM_KEYS,
    RPSM_KEYS,
    TABLE_DATA_TYPES,
} from './Constants';

const getAnalysisDeadline = (days) => moment().add(days, 'days').format(DATE_FORMAT.EAMCOMPONENT);

const getRequestLLDefault = () => true;

const getActivityTime = () =>
    moment().add(10, 'days').set({ hour: 8, minute: 0 }).format(DATE_TIME_FORMAT.EAMCOMPONENT);

const getBatchValues = (quantity) => {
    const initialCode = 'A'.charCodeAt(0);
    const result = [];
    if (quantity <= 1) return result;

    for (let i = 0; i < quantity; i++) {
        const batchValue = String.fromCharCode(initialCode + i);
        result.push({ code: batchValue, desc: batchValue });
    }
    return result;
};

const getTableLayout = ({ cannotRequestLL, valuesGrid, ANALYSIS_TYPE, dropdowns, batchValues = [] } = {}) => [
    {
        code: RPSM_KEYS.ORIGIN,
    },
    {
        code: EQUIPMENT_KEYS.EQUIPMENT_CODE,
    },
    {
        code: ANALYSIS_KEYS.DEADLINE,
        type: TABLE_DATA_TYPES.DATE,
        defaultValue: (obj) =>
            getAnalysisDeadline(
                ValuesManager.getSelectedRow(valuesGrid.DEF[ANALYSIS_KEYS.DEADLINE], {
                    ...obj,
                    [RPAR_PERFORM_KEYS.ANALYSIS_TYPE]: ANALYSIS_TYPE,
                }) || 10
            ),
        required: true,
    },
    {
        code: ANALYSIS_KEYS.MEASUREMENT_COMMENTS,
        type: TABLE_DATA_TYPES.INPUT,
    },
    ...(!cannotRequestLL
        ? [
              {
                  code: ANALYSIS_KEYS.CLASSIFICATION_REQUESTED,
                  type: TABLE_DATA_TYPES.SELECT,
                  defaultValue:
                      ValuesManager.getDefault({
                          valuesGrid,
                          key: ANALYSIS_KEYS.CLASSIFICATION_REQUESTED,
                          object: { [RPAR_PERFORM_KEYS.ANALYSIS_TYPE]: ANALYSIS_TYPE },
                      }) || [],
                  values: (object) =>
                      ValuesManager.constrain({
                          valuesGrid,
                          key: ANALYSIS_KEYS.CLASSIFICATION_REQUESTED,
                          object,
                          values: dropdowns[ANALYSIS_KEYS.CLASSIFICATION_REQUESTED],
                      }),
                  required: true,
              },
          ]
        : []),
    ...(batchValues?.length
        ? [
              {
                  code: ANALYSIS_KEYS.BATCH,
                  type: TABLE_DATA_TYPES.SELECT,
                  required: false,
                  values: () => batchValues,
              },
          ]
        : []),
];

export const ERROR_DEFAULT_VALUES = {
    title: '',
    message: '',
    autoHideDuration: null, // setting autoHideDuration to null disables snackbar auto hide behaviour
};

export const WARNING_DEFAULT_VALUES = {
    title: '',
    message: '',
    autoHideDuration: null,
};

export const SUCCESS_DEFAULT_VALUES = {
    title: '',
    message: '',
    autoHideDuration: null,
};

export const ERROR_TYPES = {
    SERVER_ERROR: 'SERVER_ERROR',
    CONNECTION_ABORDED: 'CONNECTION_ABORDED',
    NETWORK_ERROR: 'NETWORK_ERROR',
    REQUEST_CANCELLED: 'REQUEST_CANCELLED',
};

export default {
    getAnalysisDeadline,
    getBatchValues,
    getTableLayout,
    getActivityTime,
    getRequestLLDefault,
};
