import React from 'react';
import BlockUi from 'react-block-ui';
import WSRPARequest from '../../../tools/rest/WSRPARequest';
import RPARequestContainer from './RPARequestContainer';
import Tools from '../../../tools/Tools';
import { REQUEST_MODES, RPAR_PERFORM_KEYS } from '../../../enums/Constants';
import { handleError } from 'tools/TrecNotifications';

class RPARequestLoader extends React.Component {
    state = {
        awaitingForRequest: true,
    };

    componentDidMount() {
        const { match, resetRPAR } = this.props;
        const requestWONumber = match.params.requestwonumber;
        if (requestWONumber) {
            this.loadRequest(requestWONumber);
        } else {
            this.setState({ awaitingForRequest: false });
            resetRPAR();
        }
    }

    componentDidUpdate() {
        const { awaitingForRequest } = this.state;
        return !awaitingForRequest;
    }

    loadRequest = (code) => {
        const {
            applicationData,
            clearRpm,
            mode,
            setAnalysisProperties,
            updateRPARequest,
            updateRPAREquipments,
            setRPAREquipmentList,
        } = this.props;

        setRPAREquipmentList([]);
        WSRPARequest.getRPAnalysisRequest({ code, applicationData })
            .then((resp) => {
                const obj = WSRPARequest.getRPAnalysisRequestAdapter(resp.body.data);

                updateRPARequest(obj.woProps);

                // Since currently we need an RP Measurement to create an EDH, we need to clear the code of the existing RPM so we generate another
                const equip = clearRpm
                    ? Tools.applyToFields(obj.equipment, ({ rpMeasurement, ...v }) => v)
                    : obj.equipment;

                // TODO
                updateRPAREquipments(equip);
                Object.entries(obj.analysis).forEach(([at, aprop]) => {
                    let computedProperties = aprop;
                    if (mode === REQUEST_MODES.CLONING) {
                        computedProperties = Tools.applyToFields(aprop, (equipment) => {
                            delete equipment[RPAR_PERFORM_KEYS.REPORTWO];
                            return equipment;
                        });
                    }
                    setAnalysisProperties(at, computedProperties);
                });
                this.setState({ awaitingForRequest: false });
            })
            .catch(handleError)
            .finally();
    };

    render() {
        const { awaitingForRequest } = this.state;
        const { match, mode } = this.props;
        const requestWONumber = match.params.requestwonumber;
        if (awaitingForRequest)
            return (
                <BlockUi message="Loading..." blocking={awaitingForRequest} style={{ height: '100%', width: '100%' }} />
            );
        return (
            <RPARequestContainer
                {...this.props}
                firstStepIndex={requestWONumber && mode !== REQUEST_MODES.CLONING ? -1 : 0}
            />
        );
    }
}

export default RPARequestLoader;
