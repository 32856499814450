import React from 'react';
import Rnd from 'react-rnd';
import { Paper } from '@material-ui/core';
import TRECHelpContent from './TRECHelpContent';
import TRECHelpHeader from './TRECHelpHeader';

export default class TRECHelp extends React.Component {
    state = {
        width: window.innerWidth > 700 ? 900 : window.innerWidth - 50,
        height: 150,
    };

    componentDidMount() {
        const { toggleHelp } = this.props;
        document.body.onkeydown = (event) => {
            if (event.key === 'F1') {
                event.preventDefault();
                toggleHelp();
            }
        };
    }

    getDefaultPosition() {
        const isMobile = window.innerWidth < 650;

        if (isMobile) {
            return {
                x: 5,
                y: 5,
            };
        }

        return {
            x: 0.2 * window.innerWidth,
            y: 0.2 * window.innerHeight,
        };
    }

    render() {
        const { environment, helpTexts, isOpen, masterPage, masterPageDescription, page, pageDescription, toggleHelp } =
            this.props;
        const { height, width } = this.state;

        const text = helpTexts[`${masterPage}:${page || ''}`] || helpTexts['TREC_HELP:UNAVAILABLE'];

        if (isOpen) {
            return (
                <Rnd
                    default={this.getDefaultPosition()}
                    // eslint-disable-next-line
                    onResize={(e, direction, ref, delta, position) => {
                        e.preventDefault();

                        this.setState(() => ({
                            width: ref.offsetWidth,
                            height: ref.offsetHeight,
                        }));
                    }}
                    style={{ zIndex: 9999 }}
                    minWidth={150}
                    maxWidth={800}
                    minHeight={100}
                    maxHeight={500}
                    cancel=".helpContent"
                    extendsProps={{
                        onMouseMove: () => {
                            if (document.selection) {
                                document.selection.empty();
                            } else {
                                window.getSelection().removeAllRanges();
                            }
                        },
                    }}
                >
                    <Paper>
                        <div style={{ height, width }}>
                            <TRECHelpHeader
                                closeHelp={toggleHelp}
                                masterPageDescription={masterPageDescription}
                                pageDescription={pageDescription}
                                environmet={environment}
                            />
                            <TRECHelpContent text={text} />
                        </div>
                    </Paper>
                </Rnd>
            );
        }
        return null;
    }
}
