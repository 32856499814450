import React, { useCallback, useMemo, useState } from 'react';
import { useAsyncDebounce, useMountedLayoutEffect } from 'react-table';
import CreatableSelect from 'react-select/creatable';

const CreatableSelectFilter = (props) => {
    // TODO: Further improvements:
    // receive translatable columns
    // receive columns that should have default options pre calculated
    const { column, initialRows } = props;
    const { filterValue, setFilter } = column;
    const [localFilter, setLocalFilter] = useState();
    // eslint-disable-next-line react/destructuring-assignment
    const getTranslation = props.getTranslation || ((v) => v);

    useMountedLayoutEffect(() => setLocalFilter(filterValue), [filterValue]);
    const debouncedSetFilter = useAsyncDebounce(
        (filter) => setFilter(filter),
        process.env.NODE_ENV === 'development' ? 100 : 0
    );

    const updateFilter = useCallback(
        (filter) => {
            setLocalFilter(filter);
            debouncedSetFilter(filter);
        },
        [debouncedSetFilter]
    );

    const options = useMemo(
        () =>
            [...new Set(initialRows.map((row) => row.values[column.id]))].map((value) => ({
                label: getTranslation(value),
                value,
            })),
        [column.id, initialRows]
    );

    const defaultRender = (
        <CreatableSelect
            isMulti
            isClearable={false}
            value={localFilter}
            placeholder={null}
            onChange={updateFilter}
            options={options}
            components={{
                DropdownIndicator: null,
            }}
            createOptionPosition="first"
        />
    );

    return defaultRender;
};

const multiSelectFilter = (rows, columnIds, filterValue) => {
    const [columnId] = columnIds;
    const filteredRows =
        (filterValue || []).length === 0
            ? rows
            : rows.filter((row) =>
                  filterValue.some((filter) =>
                      String(row.original[columnId]).toLowerCase().startsWith(filter.value.toLowerCase())
                  )
              );
    return filteredRows;
};

export { CreatableSelectFilter, multiSelectFilter };
