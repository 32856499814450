import { Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import CommentsContainer from 'eam-components/dist/ui/components/comments/CommentsContainer';
import { TABLE_DATA_TYPES } from 'enums/Constants';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import WSComments from 'tools/rest/WSWRComments';
import { TRANSLATION_KEYS, WATER_RELEASE_KEYS, WRR_STATUS } from '../WRRConstants';
import { ATTRIBUTES, WarningText, generateWRRFields, getWRRAllFields } from '../WRRHelpers';
import WRRDetailsPanel from './components/WRRDetailsPanel';
import { showError } from 'tools/TrecNotifications';

class WRRAssessmentStep extends WizardStep {
    formFields = [];

    /**
     * Can continue to the next step on the wizard
     */
    canContinue = () => {
        const isValid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
        if (!isValid) {
            showError('One or more fields are not valid.');
        }

        return isValid;
    };

    /**
     * SaveChanges is used to save the localized state to the redux-store. It can only run synchronous methods.
     */
    saveChanges = () => true;

    handleSubmit = () => {
        const { wrrGetters, updateWRR } = this.props;
        const properties = wrrGetters.getProperties();
        this.canContinue() &&
            updateWRR({
                [WATER_RELEASE_KEYS.STATUS]:
                    properties[WATER_RELEASE_KEYS.ASSUMED_RADIOACTIVE] === 'N'
                        ? WRR_STATUS.ASSESSED_SELF_SAMPL
                        : properties[WATER_RELEASE_KEYS.ASSISTANCE_REQUIRED] === 'N'
                        ? WRR_STATUS.ASSESSED_BZ_SAMPL
                        : WRR_STATUS.ASSESSED_ASSIS_SAMPL,
            });
    };

    /**
     *  CommitChanges is used to trigger any side effects of the step, such as invocations to the back-end to
     * retrieve information about resources. This method can run asynchronous methods/requests but SHALL execute
     * the callback method afterwards to let the inform the wizard.
     */
    commitChanges = async (callback) => {
        callback();
    };

    /**
     * On true, the wizard automatically jumps this step immediately after it is mounted
     */
    canJumpStep = () => false;

    renderInputs = (writeAccess) => {
        const { storeActions, wrrGetters, screenData, getTranslation, dropdowns } = this.props;
        const properties = wrrGetters.getProperties();

        return generateWRRFields({
            fieldRefs: this.formFields,
            fields: [
                {
                    code: WATER_RELEASE_KEYS.ASSUMED_RADIOACTIVE,
                    type: TABLE_DATA_TYPES.SELECT,
                    elementInfo: screenData.customFieldsWRM.P292,
                    getAttribute: () => ATTRIBUTES.REQUIRED,
                    values: dropdowns.assumedRadioactive,
                },
                {
                    code: WATER_RELEASE_KEYS.ASSISTANCE_REQUIRED,
                    type: TABLE_DATA_TYPES.SELECT,
                    elementInfo: screenData.customFieldsWRM.P293,
                    getAttribute: () => ATTRIBUTES.REQUIRED,
                    values: dropdowns.assistanceRequired.filter(
                        (s) => properties[WATER_RELEASE_KEYS.ASSUMED_RADIOACTIVE] !== 'N' || s.code === 'N'
                    ),
                },
            ],
            properties,
            storeActions,
            wrrGetters,
            writeAccess,
            getTranslation,
        });
    };

    renderActionFields = () => {
        const { storeActions, wrrGetters, screenData, getTranslation } = this.props;
        const properties = wrrGetters.getProperties();

        return generateWRRFields({
            fieldRefs: this.formFields,
            fields: getWRRAllFields({ ...screenData, getTranslation }),
            properties,
            storeActions,
            wrrGetters,
            writeAccess: false,
            getTranslation,
        });
    };

    render() {
        const { getTranslation, writeAccess, userData, wrrGetters, screenData, lang } = this.props;
        const wrrRequest = wrrGetters.getProperties();

        return (
            <div style={{ margin: 8 }}>
                <Grid container spacing={8}>
                    <Grid item md={6} sm={12} xs={12}>
                        <EISPanel
                            detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                            heading={getTranslation(TRANSLATION_KEYS.ASSESSMENT)}
                            alwaysExpanded
                        >
                            {this.renderInputs(writeAccess)}
                            {wrrRequest[WATER_RELEASE_KEYS.ASSUMED_RADIOACTIVE] === 'N' && (
                                <WarningText text={getTranslation(TRANSLATION_KEYS.NOT_ASSUMED_RADIOACTIVE_WARNING)} />
                            )}

                            {wrrRequest[WATER_RELEASE_KEYS.ASSUMED_RADIOACTIVE] === 'Y' &&
                                wrrRequest[WATER_RELEASE_KEYS.ASSISTANCE_REQUIRED] === 'N' && (
                                    <WarningText text={getTranslation(TRANSLATION_KEYS.NO_ASSISTANCE_WARNING)} />
                                )}
                        </EISPanel>
                        <CommentsContainer
                            title={getTranslation('COMMENTS')}
                            entityCode="EVNT"
                            entityKeyCode={wrrRequest[WATER_RELEASE_KEYS.WR_NUMBER]}
                            userDesc={userData.eamAccount.userDesc}
                            readComments={WSComments.readComments}
                            createComment={WSComments.createComment}
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <WRRDetailsPanel
                            jobStatuses={screenData.lists.jobStatuses}
                            getTranslation={getTranslation}
                            values={wrrRequest}
                            lang={lang}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default WRRAssessmentStep;
