import { Grid } from '@material-ui/core';
import { generateRoute, ROUTES } from 'tools/Routes';
import TRECButton from 'ui/components/buttons/TRECButton';

const buttonStyle = { height: '100%', marginRight: '6px' };

const VacuumCleanerManageButtons = ({ history, getTranslation, page, writeAccess, vacStockAccess }) => (
    <>
        <Grid container spacing={0}>
            {writeAccess && (
                <>
                    <TRECButton
                        style={buttonStyle}
                        size="small"
                        onClick={() => history.push(generateRoute(ROUTES.vacuumCleanerManage))}
                        disabled={page === 'VAC_CLEANER_MANAGE'}
                    >
                        {getTranslation('VAC_CLEANER_MANAGE')}
                    </TRECButton>
                    <TRECButton
                        style={buttonStyle}
                        size="small"
                        onClick={() => history.push(generateRoute(ROUTES.vacuumCleanerReservations))}
                        disabled={page === 'VAC_CLEANER_RESERVATIONS'}
                    >
                        {getTranslation('VAC_CLEANER_RESERVATIONS')}
                    </TRECButton>
                </>
            )}
            {(writeAccess || vacStockAccess) && (
                <TRECButton
                    style={buttonStyle}
                    size="small"
                    onClick={() => history.push(generateRoute(ROUTES.vacuumCleanerStock))}
                    disabled={page === 'VAC_CLEANER_STOCK'}
                >
                    {getTranslation('VAC_CLEANER_STOCK')}
                </TRECButton>
            )}
        </Grid>
    </>
);

export default VacuumCleanerManageButtons;
