import { ANALYSIS_KEYS, TABLE_DATA_TYPES } from 'enums/Constants';
import DEFAULTS from 'enums/Defaults';
import React from 'react';
import RPARAnalysisDetails from './RPARAnalysisDetails';

const ANALYSIS_TYPE = 'RADOS';
function RPARRados(props) {
    const { getTranslation, rparGetters, rpamValues, dropdowns, valuesGrid } = props;
    const numberOfEquipments = Object.keys(rparGetters.getAnalysisTypeEquipments(ANALYSIS_TYPE)).length;
    const batchValues = DEFAULTS.getBatchValues(numberOfEquipments);
    const tableLayout = [
        ...DEFAULTS.getTableLayout({ ANALYSIS_TYPE, dropdowns, valuesGrid, batchValues }),
        {
            code: ANALYSIS_KEYS.LAST_IRRADIATION,
            type: TABLE_DATA_TYPES.SELECT,
            values: () => rpamValues[ANALYSIS_KEYS.LAST_IRRADIATION],
            required: true,
        },
    ].map((e) => ({
        ...e,
        header: getTranslation(e.code),
    }));
    return (
        <RPARAnalysisDetails
            {...props}
            analysisType={ANALYSIS_TYPE}
            tableLayout={tableLayout}
            batchValues={batchValues}
        />
    );
}

export default RPARRados;
