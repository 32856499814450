import { Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import { TABLE_DATA_TYPES } from 'enums/Constants';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import WSRWPProcessing from 'tools/rest/WSRWPProcessing';
import Tools from 'tools/Tools';
import EquipmentAutocomplete from '../../../components/autocomplete/EquipmentAutocomplete';
import InputGenerator from '../../rpmrequest2/InputGenerator';
import { EQUIPMENT_KEYS, RWP_KEYS, TRANSLATION_KEYS } from '../RWPConstants';
import { applyDefaults, isContainer } from '../RWProcessingTools';
import { handleError, showWarning } from 'tools/TrecNotifications';

const { ATTRIBUTES } = InputGenerator;

const getOutputWasteFields = ({ rwpGetters }) => [
    {
        code: RWP_KEYS.END_DATE,
        type: TABLE_DATA_TYPES.DATE,
        getAttribute: () => ATTRIBUTES.REQUIRED,
        translationKey: TRANSLATION_KEYS.END_DATE_FIELD,
        customInputProps: () => ({
            timestamp: true,
            minDate: rwpGetters.getRWPProperties()[RWP_KEYS.START_DATE],
            maxDate: new Date(),
        }),
    },
];

export default class OutputWasteStep extends WizardStep {
    formFields = [];

    state = {
        panelExpanded: false,
    };

    UNSAFE_componentWillMount() {
        // TODO, set page for help
    }

    canContinue = () =>
        Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );

    saveChanges = () => true;

    commitChanges = (callback) => {
        const { rwpGetters, storeActions, isReadOnly, userData, applicationData } = this.props;
        if (isReadOnly) return callback();
        const rwpProps = rwpGetters.getRWPProperties();
        const inputEquipmentList = rwpProps[RWP_KEYS.INPUT_EQUIPMENT];
        const equipmentList = rwpGetters.getOutputEquipmentList();
        const equipmentMap = rwpGetters.getOutputEquipmentMap();
        const fetchedEquipmentList = Object.keys(equipmentMap);
        const unfetchedEquipmentList = equipmentList.filter((code) => !fetchedEquipmentList.includes(code));
        if (!unfetchedEquipmentList.length) return callback();
        storeActions.setRWPBlockUI(true);
        return Promise.all(
            unfetchedEquipmentList.map((equipmentCode) =>
                WSRWPProcessing.getEquipment({ equipmentCode, applicationData })
            )
        )
            .then((responses) => {
                const updatedEquipmentMap = responses.reduce((acc, rEquip) => {
                    const equipment = rEquip?.body?.data;

                    return {
                        ...acc,
                        ...(equipment
                            ? {
                                  [equipment[EQUIPMENT_KEYS.CODE]]: applyDefaults(equipment, {
                                      [EQUIPMENT_KEYS.GROSS_WEIGHT]: undefined,
                                      [EQUIPMENT_KEYS.CONTENT]: inputEquipmentList.includes(
                                          equipment[EQUIPMENT_KEYS.CODE]
                                      )
                                          ? undefined
                                          : equipment[EQUIPMENT_KEYS.CONTENT],
                                      [EQUIPMENT_KEYS.CONTENT_DESCRIPTION]: inputEquipmentList.includes(
                                          equipment[EQUIPMENT_KEYS.CODE]
                                      )
                                          ? undefined
                                          : equipment[EQUIPMENT_KEYS.CONTENT_DESCRIPTION],
                                      // TODO, check what happens to the following defaults once we are creating/reading RPMs
                                      [EQUIPMENT_KEYS.COMPLETED_DATE]: new Date(),
                                      [EQUIPMENT_KEYS.PERFORMED_BY]: userData.eamAccount.employeeCode,
                                      // TODO, Container Alpha Emitter shall be prefilled = Yes if input waste contained alpha emitter

                                      [EQUIPMENT_KEYS.CONTAINER_INTERNAL_HEIGHT]: undefined,
                                      [EQUIPMENT_KEYS.CONTAINER_INTERNAL_LENGTH]: undefined,
                                      [EQUIPMENT_KEYS.CONTAINER_INTERNAL_WIDTH]: undefined,
                                  }),
                              }
                            : {}),
                    };
                }, equipmentMap);

                storeActions.updatedOutputEquipmentMap(updatedEquipmentMap);
                const unfetchedContainers = unfetchedEquipmentList.filter((code) =>
                    isContainer(updatedEquipmentMap[code])
                );
                return Promise.all(
                    unfetchedContainers.map((equipmentCode) =>
                        WSRWPProcessing.getEquipmentConfiguration({ equipmentCode })
                    )
                ).then((resp) => {
                    const newEqpConfs = Tools.aggregateObjects(
                        unfetchedContainers.map((code, i) => ({ [code]: resp[i] }))
                    );
                    return Promise.resolve(newEqpConfs);
                });
            })
            .then((response) => {
                storeActions.updateRWPProperties({
                    [RWP_KEYS.EQUIPMENT_CONFIGURATIONS]: {
                        ...rwpProps[RWP_KEYS.EQUIPMENT_CONFIGURATIONS],
                        ...response,
                    },
                });
                return callback();
            })
            .catch(handleError)
            .finally(() => storeActions.setRWPBlockUI(false));
    };

    handleChangeWasteEquipmentsList = (selectedOptions) => {
        const { applicationData, storeActions } = this.props;
        const { updateRWPProperties } = storeActions;
        const maxSelectedEquipments = applicationData.rwpmaxOutputWasteEquipments;
        const options = selectedOptions || [];

        if (options.length > maxSelectedEquipments) {
            showWarning(`You cannot select more than ${maxSelectedEquipments} equipments`);
            return;
        }
        updateRWPProperties({ outputWaste: options.map((e) => e.code) });
    };

    render() {
        const { applicationData, getTranslation, rwpGetters, screenData, storeActions, isReadOnly, HeaderInfo } =
            this.props;
        const { panelExpanded } = this.state;
        const outputWasteFields = getOutputWasteFields(this.props);
        const equipmentField = screenData.woFields.equipment;
        const rwpProperties = rwpGetters.getRWPProperties();

        return (
            <div style={{ padding: 8 }}>
                <Grid container spacing={8}>
                    <Grid item md={8} sm={12}>
                        <EISPanel
                            heading={getTranslation(TRANSLATION_KEYS.OUTPUT_WASTE)}
                            detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                            alwaysExpanded
                        >
                            <EquipmentAutocomplete
                                formFields={this.formFields}
                                value={rwpProperties.outputWaste && rwpProperties.outputWaste.map((e) => ({ code: e }))}
                                onChange={this.handleChangeWasteEquipmentsList}
                                elementInfo={{
                                    ...equipmentField,
                                    text: getTranslation(TRANSLATION_KEYS.OUTPUT_EQUIPMENT_FIELD),
                                    attribute: 'R',
                                    maxUpdateRecords: applicationData.rwpmaxOutputWasteEquipments,
                                }}
                                loadOptions={(query) =>
                                    WSRWPProcessing.getOutputEquipmentSuggestions({
                                        jobID: rwpProperties[RWP_KEYS.JOB_NUMBER],
                                        query: query.hint,
                                    })
                                }
                                labelStyle={{ minWidth: '200px' }}
                            />
                            {outputWasteFields.reduce(
                                (acc, field) => [
                                    ...acc,
                                    <div key={field.code}>
                                        {InputGenerator.generate({
                                            field: {
                                                ...field,
                                                elementInfo: {
                                                    xpath: field.code,
                                                    text: getTranslation(field.translationKey),
                                                },
                                                customInputProps: () => ({
                                                    ...(field.customInputProps ? field.customInputProps() : {}),
                                                    labelStyle: {
                                                        width: 200,
                                                        minWidth: 200,
                                                    },
                                                }),
                                                ...(isReadOnly &&
                                                (!field.getAttribute ||
                                                    field.getAttribute() !== ATTRIBUTES.READONLY_TEXT)
                                                    ? {
                                                          getAttribute: () => ATTRIBUTES.READONLY,
                                                      }
                                                    : {}),
                                            },
                                            object: rwpGetters.getRWPProperties(),
                                            updateObject:
                                                field.updateObject ||
                                                ((key, value) => storeActions.updateRWPProperties({ [key]: value })),
                                            formFields: this.formFields,
                                        })}
                                    </div>,
                                ],
                                []
                            )}
                        </EISPanel>
                        <EISPanel
                            heading={getTranslation(TRANSLATION_KEYS.EXTRA_INFO)}
                            detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                            panelExpanded={panelExpanded}
                            onPanelChange={(s) => this.setState({ panelExpanded: s })}
                        >
                            <HeaderInfo />
                        </EISPanel>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
