import WS from './WS';

/**
 * Rest services for the Inconsistency Checks
 */
class WSInconsistencyChecks {
    acknowledge = (data, config = {}) => WS._post(`/inconsistencychecks/acknowledge`, data, config);

    getInconsistencyMetadata = (data = {}, config = {}) => WS._get(`/inconsistencychecks/metadata`, data, config);

    refresh = (data = {}, config = {}) => WS._get(`/inconsistencychecks/refresh`, data, config);
}

export default new WSInconsistencyChecks();
