import React, { Component } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import FolderOpen from '@material-ui/icons/FolderOpen';
import Attachment from '@material-ui/icons/Attachment';
import Cancel from '@material-ui/icons/Cancel';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CloudUpload from '@material-ui/icons/CloudUpload';
import ArrowLeftIcon from 'mdi-material-ui/ArrowLeft';
import RefreshIcon from 'mdi-material-ui/Refresh';
import PrintIcon from 'mdi-material-ui/Printer';
import OpenInNew from 'mdi-material-ui/OpenInNew';
import { withStyles } from '@material-ui/core/styles/index';
import TRECButton from '../../components/buttons/TRECButton';
import autoFilledRPARButtonProps from '../rparequest/RPARequestUtils';

const styles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        flex: '0 0 auto',
        backgroundColor: '#fafafa',
        paddingLeft: 10,
        borderBottom: '1px solid rgb(238, 238, 238)',
        paddingBottom: 5,
    },
};

const actionTypes = {
    BACK: 'BACK',
    SAVE: 'SAVE',
    PREVIOUS_RESULTS: 'PREVIOUS_RESULTS',
    RESET_VALUES: 'RESET_VALUES',
    CREATE_CADRA: 'CREATE_CADRA',
    OPEN_JOB: 'OPEN_JOB',
    CANCEL_JOB: 'CANCEL_JOB',
    OPEN_EDH: 'OPEN_EDH',
    PRINT: 'PRINT',
    JUMP_TO_RPAR: 'JUMP_TO_RPAR',
};

class RPMPerformToolbar extends Component {
    getActions = () => {
        const {
            applicationData,
            constants,
            translations,
            measurement,
            writeAccess,
            jobIsClosed,
            jobIsCancelled,
            cadraCheckPresent,
            canJumpToRPAR,
            backButtonHandler,
            saveHandler,
            previousResultsHandler,
            resetValuesHandler,
            createCadraCheckHandler,
            openJobHandler,
            cancelJobHandler,
            edhDocumentHandler,
            singleResponsible,
            equipmentList,
            history,
            printHandler,
        } = this.props;

        return {
            [actionTypes.BACK]: {
                content: () => (
                    <TRECButton onClick={backButtonHandler}>
                        <ArrowLeftIcon />
                        {translations.BACK}
                    </TRECButton>
                ),
                isVisible: () => true,
            },
            [actionTypes.SAVE]: {
                content: () => (
                    <TRECButton onClick={saveHandler}>
                        <SaveIcon />
                        {translations.SAVE}
                    </TRECButton>
                ),
                isVisible: () => writeAccess && !jobIsClosed,
            },
            [actionTypes.PREVIOUS_RESULTS]: {
                content: () => (
                    <TRECButton onClick={previousResultsHandler}>
                        <CloudUpload />
                        {translations.PREVRESULTS}
                    </TRECButton>
                ),
                isVisible: () => writeAccess && !jobIsClosed,
            },
            [actionTypes.RESET_VALUES]: {
                content: () => (
                    <TRECButton onClick={resetValuesHandler}>
                        <RefreshIcon />
                        {translations.RESETVALUES}
                    </TRECButton>
                ),
                isVisible: () => writeAccess && !jobIsClosed,
            },
            [actionTypes.CREATE_CADRA]: {
                content: () => (
                    <TRECButton onClick={createCadraCheckHandler}>
                        <CheckCircle />
                        {translations.CREATECADRA}
                    </TRECButton>
                ),
                isVisible: () =>
                    writeAccess &&
                    !jobIsClosed &&
                    !cadraCheckPresent &&
                    [
                        constants.futureActionDecwaste,
                        constants.futureActionWasteDeclare,
                        constants.futureActionWasteReceive,
                    ].includes(measurement.futureAction), // checkr: remove futureActionDecwaste. Should we remove futureActionWasteReceive?
            },
            [actionTypes.OPEN_JOB]: {
                content: () => (
                    <TRECButton onClick={openJobHandler}>
                        <FolderOpen />
                        {translations.OPENJOB}
                    </TRECButton>
                ),
                isVisible: () => writeAccess && jobIsClosed,
            },
            [actionTypes.CANCEL_JOB]: {
                content: () => (
                    <TRECButton onClick={cancelJobHandler} style={{ backgroundColor: 'gray' }}>
                        <Cancel />
                        {translations.CANCELJOB}
                    </TRECButton>
                ),
                isVisible: () => writeAccess && !jobIsClosed && !jobIsCancelled,
            },
            [actionTypes.OPEN_EDH]: {
                content: () => (
                    <TRECButton onClick={edhDocumentHandler}>
                        <Attachment />
                        {translations.TREC_EDHDOCMNG}
                    </TRECButton>
                ),
                isVisible: () =>
                    writeAccess &&
                    jobIsClosed &&
                    measurement.edhDocument &&
                    !measurement.edhNumber &&
                    !jobIsCancelled &&
                    [applicationData.rpsection, applicationData.rwsection].includes(measurement.department) &&
                    singleResponsible,
            },
            [actionTypes.PRINT]: {
                content: () => (
                    <TRECButton onClick={printHandler}>
                        <PrintIcon />
                        {translations.PRINT}
                    </TRECButton>
                ),
                isVisible: () =>
                    writeAccess &&
                    jobIsClosed &&
                    !jobIsCancelled &&
                    measurement.department === applicationData.rwsection,
            },
            [actionTypes.JUMP_TO_RPAR]: {
                content: () => (
                    <TRECButton {...autoFilledRPARButtonProps({ equipmentList, history })}>
                        <OpenInNew />
                        {translations.JUMPTORPAR}
                    </TRECButton>
                ),
                isVisible: () => writeAccess && canJumpToRPAR,
            },
        };
    };

    render() {
        const { classes } = this.props;
        const orderedActionList = [
            actionTypes.BACK,
            actionTypes.SAVE,
            actionTypes.PREVIOUS_RESULTS,
            actionTypes.RESET_VALUES,
            actionTypes.CREATE_CADRA,
            actionTypes.OPEN_JOB,
            actionTypes.CANCEL_JOB,
            actionTypes.OPEN_EDH,
            actionTypes.PRINT,
            actionTypes.JUMP_TO_RPAR,
        ];
        const actions = this.getActions();

        return (
            <div className={classes.root}>
                {orderedActionList.map(
                    (actionType) =>
                        actions[actionType].isVisible() && (
                            <React.Fragment key={actionType}>{actions[actionType].content()}</React.Fragment>
                        )
                )}
            </div>
        );
    }
}

export default withStyles(styles)(RPMPerformToolbar);
