/* eslint-disable react/no-danger */

import React from 'react';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import Grid from '@material-ui/core/Grid';
import BlockUi from 'react-block-ui';
import CommentsContainer from 'eam-components/dist/ui/components/comments/CommentsContainer';
import Plus from 'mdi-material-ui/Plus';
import Minus from 'mdi-material-ui/Minus';
import { Button } from '@material-ui/core';
import SearchPanel from '../../components/searchpanel/SearchPanel';
import SearchPanelColumn from '../../components/searchpanel/SearchPanelColumn';
import WSAutocomplete from '../../../tools/rest/WSAutocomplete';
import TRECPage from '../TRECPage';
import WSContainers from '../../../tools/rest/WSContainers';
import ContainerDetails from './ContainerDetails';
import ContainerContent from './ContainerContent';
import WSComments from '../../../tools/rest/WSComments';
import Tools from '../../../tools/Tools';
import RequestRPMRDialog from '../../components/dialogs/RequestRPMRDialog';
import { ROUTES, withQueryParams } from '../../../tools/Routes';
import { REQUEST_SOURCES } from '../rpmrequest2/RPMRConstants';
import { showError, showSuccess } from 'tools/TrecNotifications';

const labelStyle = {
    width: 150,
    minWidth: 150,
};

const defaultContainerObject = {
    code: '',
    description: '',
    manufacturerCode: '',
    manufacturerDesc: '',
    userDefinedFields: { udfnum07: '', udfnum08: '', udfnum09: '', udfnum10: '' },
    tareWeight: '',
};

/**
 * Page detailing the content of a container
 */
class Container extends TRECPage {
    state = {
        loading: false,
        container: defaultContainerObject,
        modifyContentMode: false,
        modifyLocationMode: false,
        modifyWeightMode: false,
        form: {
            locationCode: '',
            locationDesc: '',
            weight: '',
            equipments: '',
        },
        computedWeight: 0,
        containerRPMRDialogOpen: false,
    };

    componentDidMount() {
        // Check if there is a core
        const code = Tools.getURLParameterByName('code');
        if (code) this.loadContainer(code);
    }

    onContainerChange = (code) => {
        if (code) {
            this.loadContainer(code);
            this.props.history.replace(`/container?code=${code}`);
        } else {
            // Clear previous data
            this.setState(
                {
                    container: defaultContainerObject,
                },
                () => this.resetForm(this.state.container)
            );
            this.props.history.replace(`/container`);
        }
    };

    // Load container data
    loadContainer = (code) => {
        this.setState({ loading: true });
        return Promise.all([WSContainers.getContainer(code), WSContainers.getContainerContent(code)]).then(
            (response) => {
                const container = response[0].body.data;
                const containerContent = response[1].body.data;
                // Find tareWeight field inside custom fields
                const tareWeightField = container.customField.find(
                    (customField) => customField.code === this.props.constants.containerTare
                );
                if (tareWeightField) {
                    container.tareWeight = tareWeightField.value;
                }

                this.setState(
                    {
                        loading: false,
                        container,
                        containerContent,
                        computedWeight:
                            Number(container.tareWeight || 0) +
                            containerContent.reduce((acc, item) => acc + Number(item.grossWeight || 0), 0),
                    },
                    () => this.resetForm(container)
                );
            }
        );
    };

    /**
     * Form modifications are canceled
     */
    resetForm = (container) => {
        this.setState({
            form: {
                weight: container.userDefinedFields.udfnum10 ? container.userDefinedFields.udfnum10 : '',
                locationCode: container.hierarchyLocationCode,
                locationDesc: container.hierarchyLocationDesc,
            },
        });
    };

    /**
     * Dispay a confirmation message before executing an action
     * @param action
     */
    confirm = (action) => {
        const { openConfirmDialog } = this.props;
        openConfirmDialog(
            {
                title: this.props.translations.CONFIRM,
                message: this.props.translations.AREYOUSURE,
                cancelButtonLabel: this.props.translations.CANCEL,
                confirmButtonLabel: this.props.translations.CONFIRM,
                waitForCompletion: true,
            },
            () => {
                action();
            }
        );
    };

    confirmEmptyAll = (action) => {
        const { openConfirmDialog, translations } = this.props;
        openConfirmDialog(
            {
                title: this.props.translations.DIALOG_EMPTY_ALL_TITLE,
                // CAREFUL, translations.DIALOG_EMPTY_ALL_MESSAGE usage should be monitored by the team to validate which html is being added
                message: () => <div dangerouslySetInnerHTML={{ __html: translations.DIALOG_EMPTY_ALL_MESSAGE }} />,
                cancelButtonLabel: this.props.translations.CANCEL,
                confirmButtonLabel: this.props.translations.CONFIRM,
                waitForCompletion: true,
            },
            () => {
                action();
            }
        );
    };

    /**
     * Remove equipments from the container content
     */
    removeSelectedEquipmentFromContent = () => {
        const { form, container, containerContent } = this.state;
        if (!form.equipments) return;
        const { lang, translations } = this.props;
        // Array of equipments
        const equipments = form.equipments.split(',');
        this.confirm(() =>
            this.reloadContainerContentAfterPromise(
                Promise.all(
                    equipments.map((equipmentToUpdate) =>
                        WSContainers.deleteEquipmentFromContainer(container.code, equipmentToUpdate, lang)
                    )
                ),
                translations.EQPREMOVEDOK,
                translations.EQPREMOVEDERR,
                containerContent && equipments && equipments.length === containerContent.length && this.emptyContainer
            )
        );
    };

    /**
     * Add equipments to the container content
     */
    addSelectedEquipmentToContent = () => {
        if (this.state.form.equipments) {
            // Array of equipments
            const equipments = this.state.form.equipments.split(',');
            this.confirm(() =>
                this.reloadContainerContentAfterPromise(
                    Promise.all(
                        equipments.map((equipmentToUpdate) =>
                            WSContainers.addEquipmentInContainer(
                                this.state.container.code,
                                equipmentToUpdate,
                                this.props.lang
                            )
                        )
                    ),
                    this.props.translations.EQPADDEDOK,
                    this.props.translations.EQPADDEDERR
                )
            );
        }
    };

    /**
     * Remove an equipment from the container content
     */
    removeFromContent = (equipmentCode) => {
        const { container, containerContent } = this.state;
        const { lang, translations } = this.props;
        this.confirm(() =>
            this.reloadContainerContentAfterPromise(
                WSContainers.deleteEquipmentFromContainer(container.code, equipmentCode, lang),
                translations.EQPREMOVEDOK,
                translations.EQPREMOVEDERR,
                containerContent && containerContent.length === 1 && this.emptyContainer
            )
        );
    };

    /**
     * Delete all content
     */
    emptyContainer = (equipmentCode) => {
        this.confirmEmptyAll(() =>
            this.reloadContainerContentAfterPromise(
                WSContainers.emptyContainer(this.state.container.code, equipmentCode),
                this.props.translations.CONTEMPTYOK,
                this.props.translations.CONTEMPTYERR,
                () => {
                    this.setState({ containerRPMRDialogOpen: true });
                }
            )
        );
    };

    /**
     * Toggle Modify Content panel
     */
    onModifyContentClick = () => {
        this.setState({
            modifyContentMode: true,
            modifyLocationMode: false,
            modifyWeightMode: false,
        });
    };

    /**
     * Click on Change location
     */
    onChangeLocationClick = () => {
        this.setState({
            modifyContentMode: false,
            modifyLocationMode: true,
            modifyWeightMode: false,
        });
    };

    /**
     * Click on Modify weight
     */
    onModifyWeightClick = () => {
        this.setState({
            modifyContentMode: false,
            modifyLocationMode: false,
            modifyWeightMode: true,
        });
    };

    /**
     * Click on Cancel
     */
    onCancel = () => {
        this.setState(
            {
                modifyContentMode: false,
                modifyLocationMode: false,
                modifyWeightMode: false,
                loading: false,
            },
            () => this.resetForm(this.state.container)
        );
    };

    onPropertyChange = (key, value) => {
        // Check for string
        let propertyValue = value;
        if (Array.isArray(value)) {
            propertyValue = value.map((elem) => elem.code).toString();
        }
        this.setState((prevState) => ({ form: { ...prevState.form, [key]: propertyValue } }));
    };

    onContainerPropertyChange = (key, value) => {
        this.setState((prevState) => ({ container: { ...prevState.container, [key]: value } }));
    };

    /**
     * Click on Save
     */
    onSave = () => {
        this.setState({
            loading: true,
        });
        // Call WS
        WSContainers.updateContainer(this.state.container.code, {
            userDefinedFields: {
                udfnum10: this.state.form.weight,
            },
            hierarchyLocationCode: this.state.form.locationCode,
        })
            .then(() => showSuccess(this.props.translations.CONTSAVEDOK))
            .catch((error) => {
                showError(`${this.props.translations.CONTSAVEDERR}: ${error.response.body.message}`);
                this.onCancel();
            })
            .finally(() =>
                this.setState({
                    modifyContentMode: false,
                    modifyLocationMode: false,
                    modifyWeightMode: false,
                    loading: false,
                })
            );
    };

    /**
     * Utility function to show a loading page and reload the container content once the promise is done
     */
    reloadContainerContentAfterPromise = (promise, successMessage, errorMessage, callback) => {
        this.setState({
            loading: true,
        });
        return promise
            .then(() => {
                showSuccess(successMessage);
                callback && callback();
            })
            .catch((error) =>
                showError(
                    `${errorMessage}: ${
                        error.response.body.message ||
                        (error.response.body.errors && error.response.body.errors[0].title)
                    }`
                )
            )
            .finally(() =>
                // Reload content
                this.loadContainer(this.state.container.code)
            );
    };

    renderPage(writeAccess) {
        const { userData, screenData, lang, translations, history, applicationData, constants } = this.props;
        const { container, containerContent, containerRPMRDialogOpen } = this.state;

        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                <Grid container spacing={16}>
                    <Grid item xs={12} md={6}>
                        <ContainerDetails
                            screenData={screenData}
                            lang={lang}
                            history={history}
                            translations={translations}
                            onContainerChange={this.onContainerChange}
                            container={container}
                            containerContent={containerContent}
                            onModifyContentClick={this.onModifyContentClick}
                            onChangeLocationClick={this.onChangeLocationClick}
                            onModifyWeightClick={this.onModifyWeightClick}
                            onCancel={this.onCancel}
                            onSave={this.onSave}
                            onEmptyAll={this.emptyContainer}
                            modifyContentMode={this.state.modifyContentMode}
                            modifyLocationMode={this.state.modifyLocationMode}
                            modifyWeightMode={this.state.modifyWeightMode}
                            form={this.state.form}
                            onPropertyChange={this.onPropertyChange}
                            onContainerPropertyChange={this.onContainerPropertyChange}
                            writeAccess={writeAccess}
                            applicationData={this.props.applicationData}
                            computedWeight={this.state.computedWeight}
                        />
                    </Grid>

                    {container.code && (
                        <>
                            <Grid item xs={12} md={6}>
                                <CommentsContainer
                                    title={translations.CONTCOMMENTS}
                                    ref={(comments) => {
                                        this.comments = comments;
                                    }}
                                    entityCode="OBJ"
                                    entityKeyCode={container.code}
                                    userDesc={userData.eamAccount.userDesc}
                                    readComments={WSComments.readComments}
                                />
                            </Grid>

                            {this.state.modifyContentMode && (
                                <Grid item xs={12} md={6}>
                                    <SearchPanel title={translations.ADDREMOVEEQP}>
                                        <SearchPanelColumn md={12}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {/* Container */}
                                                <EAMAutocomplete
                                                    elementInfo={screenData[lang].assetFields.equipmentno}
                                                    style={{ flex: '1' }}
                                                    multi
                                                    labelStyle={labelStyle}
                                                    loadOptions={WSAutocomplete.autocompleteAllEquipment}
                                                    columnsWidth={['0', '100%']}
                                                    autoSelectSingleElement
                                                    valueKey="equipments"
                                                    value={this.state.form.equipments}
                                                    updateProperty={this.onPropertyChange}
                                                />
                                                <div
                                                    onClick={this.addSelectedEquipmentToContent}
                                                    onKeyDown={this.addSelectedEquipmentToContent}
                                                >
                                                    <Button size="small">
                                                        <Plus />
                                                    </Button>
                                                </div>
                                                &nbsp;
                                                <div
                                                    onClick={this.removeSelectedEquipmentFromContent}
                                                    onKeyDown={this.removeSelectedEquipmentFromContent}
                                                >
                                                    <Button size="small">
                                                        <Minus />
                                                    </Button>
                                                </div>
                                            </div>
                                        </SearchPanelColumn>
                                    </SearchPanel>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <ContainerContent
                                    onRemove={this.removeFromContent}
                                    applicationData={applicationData}
                                    translations={translations}
                                    containerContent={containerContent}
                                    showRemove={writeAccess && this.state.modifyContentMode}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
                <RequestRPMRDialog
                    translations={translations}
                    open={containerRPMRDialogOpen}
                    codes={[container.code]}
                    onCancel={() => this.setState({ containerRPMRDialogOpen: false })}
                    onConfirm={() =>
                        history.push(
                            withQueryParams({
                                path: ROUTES.rpmRequest2,
                                queryParams: {
                                    action: constants.futureActionOther,
                                    equipment: container.code,
                                    dep: applicationData.rpsection,
                                    requestSource: REQUEST_SOURCES.EMPTY_CONTAINER,
                                },
                            })
                        )
                    }
                />
            </BlockUi>
        );
    }
}

export default Container;
