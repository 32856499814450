import Tools from '../tools/Tools';

export const RESET_RPAR = 'RESET_RPAR';
export const UPDATE_RPAR = 'UPDATE_RPAR';
export const SET_RPAR_EQUIPMENT_LIST = 'SET_RPAR_EQUIPMENT_LIST';
export const UPDATE_RPAR_EQUIPMENTS = 'UPDATE_RPAR_EQUIPMENTS';
export const SET_RPAR_ANALYSIS_TYPES = 'SET_RPAR_ANALYSIS_TYPES';
export const SET_RPAR_EQUIPMENTS_ANALYSIS_TYPE = 'SET_RPAR_EQUIPMENTS_ANALYSIS_TYPE';
export const SET_RPAR_PROPERTIES = 'SET_RPAR_PROPERTIES';
export const CLONE_REQUEST_NEW_EQUIPMENT = 'CLONE_REQUEST_NEW_EQUIPMENT';

export function resetRPAR() {
    return {
        type: RESET_RPAR,
    };
}

export function updateRPARequest(value) {
    return {
        type: UPDATE_RPAR,
        value,
    };
}

export function setRPAREquipmentList(value) {
    return {
        type: SET_RPAR_EQUIPMENT_LIST,
        value,
    };
}

export function updateRPAREquipments(equipmentMap, applyDefaults = false) {
    // TODO apply defauls HERE ? only if fields are required... there should be a distinction between update and force create
    return {
        type: UPDATE_RPAR_EQUIPMENTS,
        value: applyDefaults ? Tools.applyToFields(equipmentMap, applyDefaults) : equipmentMap,
    };
}

export function setRPARAnalysisTypes(analysisTypes) {
    return {
        type: SET_RPAR_ANALYSIS_TYPES,
        analysisTypes,
    };
}

export function setRPAREquipmentsForAnalysisType(equipments, analysisType) {
    return {
        type: SET_RPAR_EQUIPMENTS_ANALYSIS_TYPE,
        equipments,
        analysisType,
    };
}

export function setAnalysisProperties(analysisType, propertiesMap) {
    return {
        type: SET_RPAR_PROPERTIES,
        analysisType,
        propertiesMap,
    };
}

export function cloneRequestNewEquipment(equipmentMap) {
    return {
        type: CLONE_REQUEST_NEW_EQUIPMENT,
        equipmentMap,
    };
}

export default {
    updateRPARequest,
    setRPAREquipmentList,
    resetRPAR,
    updateRPAREquipments,
    setRPARAnalysisTypes,
    setRPAREquipmentsForAnalysisType,
    setAnalysisProperties,
    cloneRequestNewEquipment,
};
