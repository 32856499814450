import React from 'react';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import { FilterRemoveOutline } from 'mdi-material-ui';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles/index';
import moment from 'moment/moment';
import EAMLookup from 'eam-components/dist/ui/components/lookup/EAMLookup';
import TRECSearchPage from '../TRECSearchPage';
import WSAutocomplete from '../../../tools/rest/WSAutocomplete';
import SearchPanel from '../../components/searchpanel/SearchPanel';
import SearchPanelColumn from '../../components/searchpanel/SearchPanelColumn';
import SearchPanelControls from '../../components/searchpanel/SearchPanelControls';
import EquipmentHazardContainer from '../../components/eqphazard/EquipmentHazardContainer';
import Tools from '../../../tools/Tools';
import { trecPageStyles } from '../TRECPage';
import TRECButton from '../../components/buttons/TRECButton';
import { DATE_FORMAT } from '../../../enums/Constants';
import { getHazardsFromEAMGridRow } from '../../../tools/Hazards';
import EAMGridNoOverflow from '../../components/grid/EAMGridNoOverflow';
import gridNoOverflowClasses from '../../components/grid/EAMGridNoOverflow.module.css';

const labelStyleRight = {
    width: 'auto',
    minWidth: 70,
    textAlign: 'right',
};

class SearchRequests extends TRECSearchPage {
    componentWillUnmount() {
        document.removeEventListener('keydown', this.performSearchOnEnterKey.bind(this), false);
    }

    performSearchOnEnterKey(event) {
        this.onKeyDown(event);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.performSearchOnEnterKey.bind(this), false);
        // Parent mount
        super.componentDidMount();
        // Check parameters in the URL
        this._assignRequestParameters();
    }

    _assignRequestParameters = () => {
        const { bufferZone, constants } = this.props;

        const jobNumber = Tools.getURLParameterByName('job_number');
        const location = Tools.getURLParameterByName('location');
        const jobLocation = Tools.getURLParameterByName('job_location') || (bufferZone && bufferZone.locationCode);
        const status = Tools.getURLParameterByName('status');
        const equipment = Tools.getURLParameterByName('equipment');
        const eqpResponsible = Tools.getURLParameterByName('responsible');
        const executedBy = Tools.getURLParameterByName('executedby');
        const reportedby = Tools.getURLParameterByName('reportedby');
        const edhdoctypecode = Tools.getURLParameterByName('edhdoctypecode');
        const edhdocstatuscode = Tools.getURLParameterByName('edhdocstatuscode');
        const futureaction = Tools.getURLParameterByName('futureaction');
        const department = Tools.getURLParameterByName('rp_sector');
        const created = Tools.getURLParameterByName('created');

        // Clean all field if request parameters exist
        const isParameterProvided =
            jobNumber ||
            location ||
            jobLocation ||
            status ||
            equipment ||
            eqpResponsible ||
            executedBy ||
            reportedby ||
            edhdoctypecode ||
            edhdocstatuscode ||
            futureaction ||
            department ||
            created;
        if (isParameterProvided) {
            this.updateProperty('job_number', '', 'BEGINS', 'VARCHAR', true);
            this.updateProperty('location', '', 'BEGINS', 'VARCHAR', true);
            this.updateProperty('job_location', '', 'BEGINS', 'VARCHAR', true);
            this.updateProperty('job_status_code', '', 'BEGINS', 'VARCHAR', true);
            this.updateProperty('equipment', '', 'BEGINS', 'VARCHAR', true);
            this.updateProperty('eqpResponsible', '', 'BEGINS', 'VARCHAR', true);
            this.updateProperty('udfchar21', '', 'BEGINS', 'VARCHAR', true);
            this.updateProperty('reportedby', '', 'BEGINS', 'VARCHAR', true);
            this.updateProperty('edhdoctypecode', '', 'BEGINS', 'VARCHAR', true);
            this.updateProperty('edhdocstatuscode', '', 'BEGINS', 'VARCHAR', true);
            this.updateProperty('future_action', '', 'BEGINS', 'VARCHAR', true);
            this.updateProperty('department', '', 'BEGINS', 'VARCHAR', true);
            this.updateProperty('created', '', 'BEGINS', 'VARCHAR', true);
        }

        // apply the real parameters
        if (jobNumber) {
            this.updateProperty('job_number', jobNumber, 'EQUALS', 'VARCHAR');
        }

        if (location) {
            this.updateProperty('location', location, 'BEGINS', 'VARCHAR', true);
        }

        if (jobLocation) {
            this.updateProperty('job_location', jobLocation, 'BEGINS', 'VARCHAR', true);
        }

        if (status) {
            this.updateProperty('job_status_code', status, 'EQUALS', 'VARCHAR');
        }

        if (equipment) {
            this.updateProperty('equipment', equipment, 'BEGINS', 'VARCHAR');
        }

        if (eqpResponsible) {
            this.updateProperty('eqpResponsible', eqpResponsible, 'EQUALS', 'VARCHAR');
        }

        if (executedBy) {
            this.updateProperty('udfchar21', executedBy, 'EQUALS', 'VARCHAR');
        }

        if (reportedby) {
            this.updateProperty('reportedby', reportedby, 'EQUALS', 'VARCHAR');
        }

        if (edhdoctypecode) {
            this.updateProperty('edhdoctypecode', edhdoctypecode, 'EQUALS', 'VARCHAR');
        }

        if (edhdocstatuscode) {
            this.updateProperty('edhdocstatuscode', edhdocstatuscode, 'EQUALS', 'VARCHAR');
        }

        if (futureaction) {
            if (futureaction === constants.futureActionDecwaste) {
                // kept for backwards compatibility
                const values = `${constants.futureActionWasteDeclare},${constants.futureActionWasteReceive}`;
                this.updateProperty('future_action', values, 'IN', 'VARCHAR', true);
            } else {
                this.updateProperty('future_action', futureaction, 'EQUALS', 'VARCHAR', true);
            }
        }

        if (department) {
            this.updateProperty('department', department, 'EQUALS', 'VARCHAR');
        }

        if (created) {
            this.updateProperty('created', created, 'GREATER_THAN', 'VARCHAR');
        }
    };

    adaptColumnNameWithElementInfo(columnName) {
        const mapper = {
            [columnName]: columnName,
            edhdoctypecode: 'edhdoctype_code',
            udfchar21: 'executedby_code',
            reportedby: 'requested_by',
            eqpResponsible: 'responsible_code',
            edhdocstatuscode: 'edhdocstatus_code',
            equipment: 'equipment_id',
        };
        return mapper;
    }

    _hiddenColumns = () => {
        let hiddenCols = [];
        const { lang } = this.props;

        switch (lang) {
            case 'EN':
                hiddenCols = [
                    'job_status_desc_fr',
                    'edhdoctype_desc_fr',
                    'edhdocstatus_desc_fr',
                    'edhdoctype_desc_fr',
                    'edhdoctype_code',
                    'edhdocstatus_code',
                    'executedby_code',
                    'job_status_code',
                    'responsible_code',
                    'requested_by',
                    'future_action',
                    'future_action_desc_fr',
                    'asset_locisbz',
                    'asset_toplocation',
                    'job_location',
                    'job_locisbz',
                    'job_toplocation',
                    'asset_sitelocation',
                    'job_sitelocation',
                ];
                break;
            case 'FR':
                hiddenCols = [
                    'job_status_desc_en',
                    'edhdoctype_desc_en',
                    'edhdocstatus_desc_en',
                    'edhdoctype_desc_en',
                    'edhdoctype_code',
                    'edhdocstatus_code',
                    'executedby_code',
                    'job_status_code',
                    'responsible_code',
                    'requested_by',
                    'future_action',
                    'future_action_desc_en',
                    'asset_locisbz',
                    'asset_toplocation',
                    'job_location',
                    'job_locisbz',
                    'job_toplocation',
                    'asset_sitelocation',
                    'job_sitelocation',
                ];
                break;

            default:
                break;
        }

        return hiddenCols;
    };

    _cellRenderer = (cell, row) => {
        const { applicationData } = this.props;
        if (cell.t === 'rp_status') {
            return <EquipmentHazardContainer classification={cell.value} hazards={getHazardsFromEAMGridRow(row)} />;
        }
        if (cell.t === 'equipment_id') {
            // Link to the equipment in EAMLight
            return (
                <Typography>
                    <a
                        target="_blank"
                        href={`${process.env.REACT_APP_FRONTEND}equipment/${cell.value}`}
                        rel="noreferrer"
                    >
                        {cell.value}
                    </a>
                </Typography>
            );
        }
        if (cell.t === 'job_number') {
            // Link to go to the measurement
            return (
                <Typography>
                    <Link to={`/rpmperform/?wo=${cell.value}`}>{cell.value}</Link>
                </Typography>
            );
        }
        if (cell.t === 'edhdocid') {
            // Link to the EDH Document
            return (
                <Typography>
                    <a target="_blank" href={`${applicationData.edhurl}${cell.value}`} rel="noreferrer">
                        {cell.value}
                    </a>
                </Typography>
            );
        }

        //
        return false;
    };

    gridRequestAdapter = (gridRequest) => {
        let gridFilters = [...gridRequest.gridFilter];
        gridFilters = gridFilters.map((gridFilter) => {
            const value = gridFilter.fieldValue;
            switch (gridFilter.fieldName) {
                case 'job_status_code':
                    // All active status
                    if (gridFilter.fieldValue === 'ALLACTIVE') {
                        return { ...gridFilter, fieldName: 'job_status_code', fieldValue: 'R', operator: 'BEGINS' };
                    }
                    // Normal status

                    return gridFilter;

                case 'location':
                    return this._getGridFilterLocation(
                        gridFilter,
                        value,
                        'asset_locisbz',
                        'asset_toplocation',
                        'asset_sitelocation',
                        'location'
                    );
                case 'job_location':
                    return this._getGridFilterLocation(
                        gridFilter,
                        value,
                        'job_locisbz',
                        'job_toplocation',
                        'job_sitelocation',
                        'job_location'
                    );
                case 'created': {
                    let date = moment();
                    if (gridFilter.fieldValue === 'LASTDAY') {
                        date = date.subtract(1, 'day');
                    } else if (gridFilter.fieldValue === 'LASTWEEK') {
                        date = date.subtract(1, 'week');
                    } else if (gridFilter.fieldValue === 'LASTMONTH') {
                        date = date.subtract(1, 'month');
                    }
                    return {
                        ...gridFilter,
                        fieldName: 'evt_created',
                        fieldValue: date.format(DATE_FORMAT.EAMCOMPONENT),
                    };
                }
                default:
                    return gridFilter;
            }
        });
        return {
            ...gridRequest,
            gridFilter: gridFilters,
            gridSort: [...gridRequest.gridSort, { sortType: 'DESC', sortBy: 'updated_on' }],
        };
    };

    renderPage() {
        const { applicationData, screenData, history, lang, constants, translations, classes } = this.props;
        const { customFieldsDef } = screenData[lang];
        const { filters } = this.state;

        const searchJobStatuses = Tools.sortCodeDescList([
            ...screenData[lang].lists.jobStatuses,
            {
                code: 'ALLACTIVE',
                desc: translations.ALLACTIVE,
            },
        ]);

        return (
            <div className={gridNoOverflowClasses.outerBlock}>
                <SearchPanel title={translations.SEARCH_CRITERIA} onPanelChange={this._onPanelChange}>
                    <SearchPanelColumn>
                        <Grid container spacing={8}>
                            <Grid item md={3} sm={12} xs={12}>
                                <EAMInput
                                    label={translations.JOBNUMBER}
                                    value={filters.job_number}
                                    valueKey="job_number"
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'BEGINS', 'VARCHAR')
                                    }
                                />
                            </Grid>

                            <Grid item md={4} sm={12} xs={12}>
                                <EAMLookup
                                    right={1}
                                    top={13}
                                    width={26}
                                    height={26}
                                    gridId={applicationData.locationRaisinGridID}
                                    keys={{
                                        code: 'obj_code',
                                        mapCodeTo: 'location',
                                    }}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'BEGINS', 'VARCHAR')
                                    }
                                    value={filters.location}
                                >
                                    <EAMSelect
                                        elementInfo={screenData[lang].assetFields.eqpLocation}
                                        valueKey="location"
                                        creatable
                                        value={filters.location}
                                        values={screenData[lang].lists.locationsList}
                                        labelStyle={labelStyleRight}
                                        updateProperty={(key, value) =>
                                            this.updateProperty(key, value, 'BEGINS', 'VARCHAR')
                                        }
                                        arrowRenderer={() => <span />}
                                    />
                                </EAMLookup>
                            </Grid>
                            <Grid item md={5} sm={12} xs={12}>
                                <EAMLookup
                                    right={1}
                                    top={13}
                                    width={26}
                                    height={26}
                                    gridId={applicationData.locationRaisinGridID}
                                    keys={{
                                        code: 'obj_code',
                                        mapCodeTo: 'job_location',
                                    }}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'BEGINS', 'VARCHAR')
                                    }
                                    value={filters.job_location}
                                >
                                    <EAMSelect
                                        elementInfo={screenData[lang].woFields.location}
                                        valueKey="job_location"
                                        value={filters.job_location}
                                        values={screenData[lang].lists.locationsList}
                                        creatable
                                        labelStyle={labelStyleRight}
                                        updateProperty={(key, value) =>
                                            this.updateProperty(key, value, 'BEGINS', 'VARCHAR')
                                        }
                                        arrowRenderer={() => <span />}
                                    />
                                </EAMLookup>
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid item md={6} sm={12} xs={12}>
                                {/* Equipment */}
                                <EAMAutocomplete
                                    elementInfo={screenData[lang].woFields.equipment}
                                    value={filters.equipment}
                                    valueKey="equipment"
                                    multi
                                    creatable
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'BEGINS', 'VARCHAR', true)
                                    }
                                    loadOptions={WSAutocomplete.autocompleteEquipment}
                                    labelStyle={labelStyleRight}
                                    columnsWidth={['0', '100%']}
                                    selectStyle={classes.selectStyle}
                                    autoSelectSingleElement
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                {/* Equipment Responsible */}
                                <EAMAutocomplete
                                    elementInfo={screenData[lang].assetFields.eqpResponsible}
                                    value={filters.eqpResponsible}
                                    valueKey="eqpResponsible"
                                    valueDesc={filters.eqpResponsibleDesc}
                                    descKey="eqpResponsibleDesc"
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'BEGINS', 'VARCHAR')
                                    }
                                    loadOptions={WSAutocomplete.autocompleteEmployee}
                                    labelStyle={labelStyleRight}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid item md={6} sm={12} xs={12}>
                                {/* Future action */}
                                <EAMSelect
                                    elementInfo={screenData[lang].customFields[constants.cfpFutureAction]}
                                    valueKey="future_action"
                                    value={filters.future_action}
                                    values={Tools.getCustomFieldsCodeDesc(
                                        customFieldsDef[constants.cfpFutureAction],
                                        true
                                    )}
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'EQUALS', 'VARCHAR', true)
                                    }
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                {/* WO Status */}
                                <EAMSelect
                                    elementInfo={screenData[lang].woFields.workorderstatus}
                                    valueKey="job_status_code"
                                    value={filters.job_status_code}
                                    values={searchJobStatuses}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'EQUALS', 'VARCHAR')
                                    }
                                    labelStyle={labelStyleRight}
                                />
                            </Grid>
                        </Grid>
                    </SearchPanelColumn>
                    <SearchPanelColumn>
                        <Grid container spacing={8}>
                            <Grid item md={6} sm={12} xs={12}>
                                {/* Executed By */}
                                <EAMAutocomplete
                                    elementInfo={screenData[lang].woFields.udfchar21}
                                    value={filters.udfchar21}
                                    valueKey="udfchar21"
                                    valueDesc={filters.udfchar21Desc}
                                    descKey="udfchar21Desc"
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'BEGINS', 'VARCHAR')
                                    }
                                    loadOptions={WSAutocomplete.autocompleteEmployee}
                                    labelStyle={labelStyleRight}
                                />
                                {/* Requested By */}
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <EAMAutocomplete
                                    elementInfo={screenData[lang].woFields.reportedby}
                                    value={filters.reportedby}
                                    valueKey="reportedby"
                                    valueDesc={filters.reportedbyDesc}
                                    descKey="reportedbyDesc"
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'BEGINS', 'VARCHAR')
                                    }
                                    loadOptions={WSAutocomplete.autocompleteEmployee}
                                    labelStyle={labelStyleRight}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid item md={6} sm={12} xs={12}>
                                {/* EDH Type */}
                                <EAMSelect
                                    label={translations.EDHTYPE}
                                    valueKey="edhdoctypecode"
                                    value={filters.edhdoctypecode}
                                    values={screenData[lang].lists.edhTypes}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'EQUALS', 'VARCHAR', true)
                                    }
                                    labelStyle={labelStyleRight}
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                {/* EDH Status */}
                                <EAMSelect
                                    label={translations.EDHSTATUS}
                                    valueKey="edhdocstatuscode"
                                    value={filters.edhdocstatuscode}
                                    values={screenData[lang].lists.edhStatuses}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'EQUALS', 'VARCHAR')
                                    }
                                    labelStyle={labelStyleRight}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid item md={6} sm={12} xs={12}>
                                <EAMSelect
                                    label={translations.RPSECTION}
                                    valueKey="department"
                                    value={filters.department}
                                    values={screenData[lang].lists.departments}
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'EQUALS', 'VARCHAR')
                                    }
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <EAMSelect
                                    label={translations.JOBCREATED}
                                    valueKey="created"
                                    value={filters.created}
                                    values={screenData[lang].lists.createdList}
                                    labelStyle={labelStyleRight}
                                    updateProperty={(key, value) =>
                                        this.updateProperty(key, value, 'GREATER_THAN', 'VARCHAR')
                                    }
                                />
                            </Grid>
                        </Grid>
                    </SearchPanelColumn>
                    <SearchPanelControls>
                        <TRECButton
                            variant="contained"
                            color="default"
                            onClick={() => {
                                history.push('/menu');
                            }}
                        >
                            <MenuIcon />
                            {translations.GOTOMENU}
                        </TRECButton>
                        <TRECButton variant="contained" onClick={this.onSearchClick}>
                            <SearchIcon />
                            {translations.SEARCH}
                        </TRECButton>
                        <TRECButton variant="contained" onClick={this.onClearClick}>
                            <FilterRemoveOutline />
                            {translations.RESETVALUES}
                        </TRECButton>
                    </SearchPanelControls>
                </SearchPanel>
                <EAMGridNoOverflow
                    onRef={(ref) => {
                        this.grid = ref;
                    }}
                    gridId={applicationData.gridMyRequestsGridID}
                    cellRenderer={this._cellRenderer}
                    showDataspySelection={false}
                    hiddenTags={this._hiddenColumns()}
                    heightFilterNotVisible="140px"
                    headerStyle={{}}
                    gridRequestAdapter={this.gridRequestAdapter}
                    language={lang}
                />
            </div>
        );
    }
}

export default withStyles(trecPageStyles)(SearchRequests);
