import { Card, Divider, IconButton, TextField, Typography } from '@material-ui/core';
import Warning from '@material-ui/icons/Warning';
import { TrashCan } from 'mdi-material-ui';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import Tools from 'tools/Tools';
import {
    getVacuumColour,
    VACUUM_RENTAL_ACCESSORY_KEYS,
    VACUUM_RENTAL_LINE_KEYS,
    VACUUM_TYPE_KEYS,
} from 'ui/pages/vaccleanerrequest/VacCleanerConstants';

const styles = {
    panelStyle: {
        padding: 5,
        overflowY: 'auto',
        height: '60vh',
        scrollbarWidth: 'thin',
    },
    detailsStyle: {
        display: 'flex',
        flexDirection: 'column',
        padding: 6,
    },
};

const VacuumCartItem = ({
    count,
    vacuum,
    handleDelete,
    handleAccessoryDelete,
    handleQuantityChange,
    handleQuantitySet,
    handleAccessoryQuantityChange,
    accessoryData,
    index,
    vacuumType,
    highlighted,
}) => (
    <Card
        style={{
            margin: '5px 0',
            padding: '10px',
            backgroundColor: highlighted ? 'skyblue' : '#f6f6f6',
            borderLeft: `5px solid ${getVacuumColour(vacuumType.conditionOfUse)}`,
        }}
    >
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Typography variant="subtitle1">
                {`${vacuumType[VACUUM_TYPE_KEYS.DESCRIPTION]} ${handleQuantityChange ? '' : ` #${index}`}`}
            </Typography>
            {vacuumType[VACUUM_TYPE_KEYS.WARNING] && <Warning style={{ marginTop: -6, marginRight: 12 }} />}
            {handleDelete && (
                <IconButton
                    onClick={() =>
                        handleDelete(
                            vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE],
                            vacuum[VACUUM_RENTAL_LINE_KEYS.LOCATION],
                            handleQuantityChange ? null : vacuum[VACUUM_RENTAL_LINE_KEYS.CODE]
                        )
                    }
                >
                    <TrashCan />
                </IconButton>
            )}
        </div>
        {/* <Typography variant="body2" color="textSecondary">
            {vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE]}
        </Typography> // Removed due to RP Service Request */}
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
            <img
                src={vacuumType[VACUUM_TYPE_KEYS.IMAGE_URL]}
                alt={vacuumType[VACUUM_TYPE_KEYS.CODE]}
                style={{ objectFit: 'contain', height: '50%', width: '50%' }}
            />
            {handleQuantityChange && (
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <TextField
                        type="number"
                        value={count}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: '3rem', padding: '0 10px' }}
                        inputProps={{
                            type: 'number',
                            inputMode: 'numeric',
                            min: 1,
                            // className: 'showSpinButtons',
                        }}
                        onChange={(e) => {
                            const val = e.target.valueAsNumber;
                            if (!Number.isNaN(val)) {
                                handleQuantitySet(vacuum, val || 0);
                            }
                        }}
                    />
                </div>
            )}
        </div>
        {!handleQuantityChange && vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES].length > 0 && (
            <div style={{ border: '1px solid #bdbdbd', margin: '5px 0', padding: 5, borderRadius: 5 }}>
                {vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES].map((acc, i) => {
                    const accessory = accessoryData?.find(
                        (ac) =>
                            acc[VACUUM_RENTAL_ACCESSORY_KEYS.PART_CODE] === ac[VACUUM_RENTAL_ACCESSORY_KEYS.PART_CODE]
                    );
                    return (
                        <div key={`${acc.partCode}${vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE]}`}>
                            <Typography style={{ fontSize: '10pt' }}>
                                {accessory?.[VACUUM_RENTAL_ACCESSORY_KEYS.DESCRIPTION]}
                            </Typography>
                            {/* <Typography color="textSecondary" style={{ fontSize: '9pt' }}>
                                {accessory?.[VACUUM_RENTAL_ACCESSORY_KEYS.CODE]}
                            </Typography> Removed due to RP Service Request */}
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <TextField
                                    type="number"
                                    value={acc.requestedQuantity}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: '3rem', padding: '0 10px' }}
                                    disabled={!handleAccessoryQuantityChange}
                                    inputProps={{
                                        type: 'number',
                                        inputMode: 'numeric',
                                        min: 1,
                                        // className: handleAccessoryQuantityChange && 'showSpinButtons',
                                    }}
                                    onChange={(e) => {
                                        const val = e.target.valueAsNumber;
                                        // TODO acc[VACUUM_RENTAL_ACCESSORY_KEYS.QUANTITY] from parts associated
                                        if (!Number.isNaN(val) && val <= 1) {
                                            handleAccessoryQuantityChange(acc.partCode, vacuum, val);
                                        }
                                    }}
                                />
                                {handleAccessoryDelete && (
                                    <IconButton onClick={() => handleAccessoryDelete(acc.partCode, vacuum)}>
                                        <TrashCan />
                                    </IconButton>
                                )}
                            </div>
                            {i !== vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES].length - 1 && <Divider />}
                        </div>
                    );
                })}
            </div>
        )}
        <Divider />
    </Card>
);

const VacuumCart = ({
    cartContents,
    onRemove,
    onAccessoryRemove,
    onQuantityChange,
    onQuantitySet,
    onAccessoryQuantityChange,
    getVacCount,
    getTranslation,
    accessoryData,
    vacuumsData,
    onClick = () => 1,
    clickedVacuum,
}) => {
    const groupedContents =
        cartContents && Tools.groupListByFunction(cartContents, (vac) => vac[VACUUM_RENTAL_LINE_KEYS.LOCATION]?.code);

    const VacuumLine = ({ vacuum, array }) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div onClick={() => onClick(vacuum)} key={vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE] + vacuum.code}>
            <VacuumCartItem
                key={vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE] + vacuum.code}
                count={
                    array.filter(
                        (v) => v[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE] === vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE]
                    ).length
                }
                index={getVacCount(array, vacuum)}
                handleQuantityChange={onQuantityChange}
                handleQuantitySet={onQuantitySet}
                vacuum={vacuum}
                handleDelete={onRemove}
                handleAccessoryDelete={onAccessoryRemove}
                handleAccessoryQuantityChange={onAccessoryQuantityChange}
                accessoryData={accessoryData}
                vacuumType={
                    vacuumsData?.find(
                        (vac) => vac[VACUUM_TYPE_KEYS.CODE] === vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE]
                    ) || {}
                }
                highlighted={clickedVacuum?.code === vacuum.code}
            />
        </div>
    );
    return (
        <EISPanel detailsStyle={styles.detailsStyle} heading={getTranslation('CART')} alwaysExpanded>
            <div style={styles.panelStyle}>
                {Object.entries(groupedContents)?.map(([location, array]) => (
                    <Card key={`location#${location}`} style={{ padding: 10, margin: '5px 0' }}>
                        <Typography color="textSecondary">Location: {location}</Typography>
                        <Divider style={{ marginBottom: 10 }} />
                        {!onQuantitySet
                            ? array.map((vacuum) => VacuumLine({ vacuum, array }))
                            : Object.values(Tools.groupListBy(array, [VACUUM_RENTAL_LINE_KEYS.VAC_TYPE])).map(
                                  (vacuum) => VacuumLine({ vacuum: vacuum[0], array, accessoryData })
                              )}
                    </Card>
                ))}
            </div>
        </EISPanel>
    );
};

export default VacuumCart;
