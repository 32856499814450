import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { generateRoute, withQueryParams, ROUTES } from 'tools/Routes';
import WSWorkorders from 'tools/rest/WSWorkorders';
import { parse as queryParse } from 'query-string';
import { handleError } from 'tools/TrecNotifications';

const styles = () => ({
    blocker: {
        height: '100%',
        width: '100%',
    },
    invalidWorkOder: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

const getWorkOrderRoute = ({ workOrderID, workOrderClass, applicationData, parentCode, queryParams }) => {
    const { analysisTypeClassMap } = applicationData;
    const analysisTypes = Object.values(analysisTypeClassMap);

    const mapping = {
        RP01: withQueryParams({ path: ROUTES.rpmPerform, queryParams: { wo: workOrderID } }),
        ...analysisTypes.reduce(
            (acc, analysisType) => ({
                ...acc,
                [analysisType]: generateRoute(ROUTES.rpamAnalysisJob, { jobnumber: parentCode || workOrderID }),
            }),
            {}
        ),
        RPAR: generateRoute(ROUTES.rpamRequestEdit, { requestwonumber: workOrderID }),
        RPWP01: generateRoute(ROUTES.rwProcessingEdit, { jobID: workOrderID }),
        RW01: withQueryParams({ path: ROUTES.rwcPerform, queryParams: { wo: workOrderID } }),
        RPRR: withQueryParams({
            path: generateRoute(ROUTES.waterRelease, { waterReleaseNumber: workOrderID }),
            queryParams,
        }),
        RPVR: withQueryParams({
            path: generateRoute(ROUTES.vaccleanerRequestEdit, { vaccleanerNumber: workOrderID }),
            queryParams,
        }),
        RPELIM: generateRoute(ROUTES.rwEliminationEdit, { eliminationNumber: workOrderID }),
    };
    return mapping[workOrderClass];
};

class WorkOrdersRedirect extends Component {
    state = {
        loading: true,
        workOrderClass: undefined,
    };

    componentDidMount() {
        const { match } = this.props;
        const { workOrderID } = match.params;
        WSWorkorders.getClass(workOrderID)
            .then((response) => this.setState({ ...response.body.data }))
            .catch(handleError)
            .finally(() => this.setState({ loading: false }));
    }

    render() {
        const { workOrderClass, loading, parentCode } = this.state;
        const { classes, match, applicationData, location } = this.props;
        const { workOrderID } = match.params;
        const route = getWorkOrderRoute({
            workOrderID,
            workOrderClass,
            parentCode,
            applicationData,
            queryParams: queryParse(location.search, { arrayFormat: 'comma' }),
        });
        if (loading) {
            return <BlockUi blocking className={classes.blocker} />;
        }
        return route ? (
            <Redirect to={route} />
        ) : (
            <div className={classes.invalidWorkOder}>
                {`Work Order '${workOrderID}' is not a valid TREC Work Order`}
            </div>
        );
    }
}

export default withStyles(styles)(WorkOrdersRedirect);
