import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const RequestRPMRDialog = (props) => {
    const { codes, open, onCancel, onConfirm, translations } = props;
    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>{translations.DIALOG_RPM_REQUEST}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {codes.map((code) => (
                        <p>{code}</p>
                    ))}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    {translations.CANCEL}
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    {translations.CONFIRM}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RequestRPMRDialog;
