import WS from './WS';

/**
 * Rest services for EDMS endpoints
 */
class EDMS {
    //
    // Get EDMS documents for WO
    //
    getEDMSWorkorderDocuments(workorder, config = {}) {
        return this.getEDMSDocuments(workorder, 'J', config);
    }

    //
    // Get EDMS documents for WO
    //
    getEDMSDocuments(workorder, entity, config = {}) {
        return WS._get(`/edms2/files?objectid=${workorder}&objectType=${entity}&mode=EDMS`, config);
    }

    //
    // Get EDMS registries for WO
    //
    getEDMSWorkorderRegistries(workorder, config = {}) {
        return this.getEDMSRegistries(workorder, 'J', config);
    }

    //
    // Get EDMS registries for WO
    //
    getEDMSRegistries(workorder, entity, config = {}) {
        return WS._get(`/edms2/documents?objectid=${workorder}&objectType=${entity}`, config);
    }

    //
    // Get EDMS documents batch
    //
    getEDMSWorkorderDocumentsBatch(workorders, config = {}) {
        return Promise.all(workorders.map((workorder) => this.getEDMSWorkorderDocuments(workorder, config))).then(
            (responses) => ({ body: { data: responses.map((response) => response.body.data) } })
        );
    }

    handleFileUpload(files = [], objectID, objectType, mode, context = null, title, config = {}) {
        return Promise.all(
            files.map((file) => {
                const formData = new FormData();
                formData.append('file', file, file.name);
                formData.append('objectid', objectID);
                formData.append('objecttype', objectType);
                formData.append('mode', mode);
                if (context) {
                    formData.append('context', context);
                }
                if (title) {
                    formData.append('title', title);
                }
                // eslint-disable-next-line no-underscore-dangle
                return WS._postEDMS('/upload', formData, config);
            })
        );
    }

    handleDocumentLink(documentId, objectIds, objectType, documentVersion = null, config = {}) {
        const link = {
            documentId,
            objectIds,
            objectType,
            documentVersion,
        };
        // eslint-disable-next-line no-underscore-dangle
        return WS._post('/edms2/link', link, config);
    }
}

export default new EDMS();
