import React, { useEffect, useState } from 'react';
import { Button, Grid, LinearProgress, Tab, Tabs, Typography } from '@material-ui/core';
import { generatePath } from 'react-router';
import EquipmentHistory from './EquipmentHistory';
import EquipmentHierarchy from './EquipmentHierarchy';
import WSEquipment from '../../../../tools/rest/WSEquipment';
import WSRWPProcessing from '../../../../tools/rest/WSRWPProcessing';
import { EQUIPMENT_KEYS } from '../../rwprocessing/RWPConstants';
import EquipmentAudit from './EquipmentAudit';
import EquipmentEvents from './EquipmentEvents';
import EquipmentOverview from './EquipmentOverview';
import { ROUTES } from '../../../../tools/Routes';
import { EQUIPMENT_PAGE_KEYS } from './Constants';
import InfoPage from '../../../components/infopage/InfoPage';

const styles = {
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
    },
    headerSection: {
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid #f5f5f5',
        position: 'relative',
        padding: '1rem',
        justifyContent: 'space-between',
    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'row',
    },
    bodySection: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    bodyContentSection: {
        height: '100%',
        position: 'relative',
    },
    tabContentSection: {
        height: '100%',
        position: 'absolute',
        width: '100%',
        overflow: 'hidden',
    },
    hierarchySection: {
        height: 'calc(100% - 40px)',
        width: 'calc(100% - 40px)',
        padding: '20px',
    },
    serialno: {
        fontSize: '22px',
        color: 'grey',
    },
};

const TABS = {
    TAB_OVERVIEW: {
        key: 'TAB_OVERVIEW',
        menuKey: 'EQPAGE_OVERVIEW',
        route: ROUTES.equipmentOverview,
    },
    TAB_HISTORY: {
        key: 'TAB_HISTORY',
        menuKey: 'EQPAGE_HISTORY',
        route: ROUTES.equipmentHistory,
    },
    TAB_HIERARCHY: {
        key: 'TAB_HIERARCHY',
        menuKey: 'EQPAGE_HIERARCHY',
        route: ROUTES.equipmentHierarchy,
    },
    TAB_AUDIT: {
        key: 'TAB_AUDIT',
        menuKey: 'EQPAGE_AUDIT',
        route: ROUTES.equipmentAudit,
    },
    TAB_EVENTS: {
        key: 'TAB_EVENTS',
        menuKey: 'EQPAGE_EVENTS',
        route: ROUTES.equipmentEvents,
    },
};

const TABS_ORDER = [
    TABS.TAB_OVERVIEW.key,
    TABS.TAB_HISTORY.key,
    TABS.TAB_HIERARCHY.key,
    TABS.TAB_AUDIT.key,
    TABS.TAB_EVENTS.key,
];

const EquipmentShowPage = (props) => {
    const {
        match,
        dropdowns,
        applicationData,
        getTranslation,
        history,
        screenData,
        activeTab,
        authorizedMenus,
        storeActions,
    } = props;
    const { equipmentID } = match.params;
    const [tab, setTab] = useState();
    const [equipment, setEquipment] = useState({});
    const [eventData, setEventData] = useState();
    const [auditData, setAuditData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const allowedTabs = React.useMemo(() => {
        const authorizedMenuCodes = authorizedMenus.map((authorizedMenu) => authorizedMenu.code);
        return TABS_ORDER.filter((tabKey) => authorizedMenuCodes.includes(TABS[tabKey].menuKey));
    }, [authorizedMenus]);

    useEffect(() => {
        const initialTab = TABS[allowedTabs?.find((t) => t === activeTab.key)] || TABS.TAB_OVERVIEW;
        const initialIndex = allowedTabs?.findIndex((t) => t === initialTab.key);
        setTab({
            ...initialTab,
            index: initialIndex,
        });
    }, [activeTab, allowedTabs]);

    useEffect(() => {
        if (tab && tab.route !== match.path) {
            history.push(generatePath(tab.route, match.params));
        }
    }, [tab]);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            WSRWPProcessing.getEquipment({ equipmentCode: equipmentID, applicationData }),
            WSEquipment.getEvents({ equipmentID }),
            WSEquipment.getAudit({ equipmentID }),
        ])
            .then(([equipmentResult, eventsResult, auditResult]) => {
                setEquipment(equipmentResult.body.data);
                setEventData(eventsResult.body.data);
                setAuditData(auditResult.body.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => setLoading(false));
    }, [equipmentID]);

    if (!allowedTabs) {
        return <InfoPage title="Access Denied" message="You seem to have no access to this screen." />;
    }
    if (error) {
        return <InfoPage title="Server Error" message="Something went wrong when trying to fetch the data." />;
    }
    if (loading) return <LinearProgress />;

    const defaultTabStyle = {
        padding: '20px',
        position: 'relative',
        height: 'calc(100% - 20px)',
        overflow: 'hidden',
    };

    return (
        <section style={styles.root}>
            <section style={styles.headerSection}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5" component="h1" gutterBottom>
                        {equipment[EQUIPMENT_KEYS.CODE]}
                        {equipment[EQUIPMENT_KEYS.SERIALNO] && (
                            <span style={styles.serialno}> - {equipment[EQUIPMENT_KEYS.SERIALNO]}</span>
                        )}
                    </Typography>
                    <Typography variant="caption" component="h2" gutterBottom>
                        {equipment[EQUIPMENT_KEYS.DESCRIPTION]}
                    </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid container spacing={8} direction="row" justify="flex-end" alignItems="center">
                        <Grid item>
                            <Button
                                onClick={() => history.push(ROUTES.menu)}
                                style={{ boxShadow: 'unset' }}
                                variant="contained"
                            >
                                {getTranslation(EQUIPMENT_PAGE_KEYS.MENU)}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => history.push(ROUTES.equipment)}
                                style={{ boxShadow: 'unset' }}
                                variant="contained"
                            >
                                {getTranslation(EQUIPMENT_PAGE_KEYS.SEARCH_OTHER)}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() =>
                                    window.open(`${applicationData.eamlightLink}equipment/${equipmentID}`, '_blank')
                                }
                                style={{ boxShadow: 'unset' }}
                                variant="contained"
                                color="primary"
                            >
                                {getTranslation(EQUIPMENT_PAGE_KEYS.OPEN_IN_EAMLIGHT)}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section style={styles.bodySection}>
                <Tabs
                    value={tab.index}
                    onChange={(e, index) => {
                        const newTab = { ...TABS[allowedTabs[index]], index };
                        setTab(newTab);
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    style={{
                        boxShadow: '#e6e6e6 0px 0px 6px 1px',
                        zIndex: 100,
                    }}
                >
                    {allowedTabs.map((key) => (
                        <Tab key={key} label={getTranslation(key)} />
                    ))}
                </Tabs>
                <section style={styles.bodyContentSection}>
                    <section style={styles.tabContentSection}>
                        {allowedTabs.map((key) => {
                            switch (key) {
                                case TABS.TAB_OVERVIEW.key:
                                    return (
                                        <div style={tab.key !== key ? { display: 'none' } : defaultTabStyle}>
                                            <EquipmentOverview
                                                equipment={equipment}
                                                eventData={eventData}
                                                dropdowns={dropdowns}
                                                applicationData={applicationData}
                                                getTranslation={getTranslation}
                                                screenData={screenData}
                                                storeActions={storeActions}
                                            />
                                        </div>
                                    );
                                case TABS.TAB_HISTORY.key:
                                    return (
                                        <div style={tab.key !== key ? { display: 'none' } : defaultTabStyle}>
                                            <EquipmentHistory
                                                eventData={eventData}
                                                auditData={auditData}
                                                getTranslation={getTranslation}
                                                applicationData={applicationData}
                                            />
                                        </div>
                                    );
                                case TABS.TAB_HIERARCHY.key:
                                    return (
                                        <div style={tab.key !== key ? { display: 'none' } : styles.hierarchySection}>
                                            <EquipmentHierarchy
                                                equipmentID={equipment[EQUIPMENT_KEYS.CODE]}
                                                applicationData={applicationData}
                                            />
                                        </div>
                                    );
                                case TABS.TAB_AUDIT.key:
                                    return (
                                        tab.key === key && (
                                            <div style={defaultTabStyle}>
                                                <EquipmentAudit auditData={auditData} getTranslation={getTranslation} />
                                            </div>
                                        )
                                    );
                                case TABS.TAB_EVENTS.key:
                                    return (
                                        tab.key === key && (
                                            <div style={defaultTabStyle}>
                                                <EquipmentEvents
                                                    eventData={eventData}
                                                    getTranslation={getTranslation}
                                                />
                                            </div>
                                        )
                                    );
                                default:
                                    return null;
                            }
                        })}

                        {/* <div style={tab.key !== TABS.TAB_OVERVIEW.key  ? { display: 'none' } : defaultTabStyle}>
                            <EquipmentOverview
                                equipment={equipment}
                                eventData={eventData}
                                dropdowns={dropdowns}
                                applicationData={applicationData}
                                getTranslation={getTranslation}
                                screenData={screenData}
                            />
                        </div>
                        <div style={tab.key !== TABS.TAB_HISTORY.key  ? { display: 'none' } : defaultTabStyle}>
                            <EquipmentHistory
                                eventData={eventData}
                                auditData={auditData}
                                getTranslation={getTranslation}
                                applicationData={applicationData}
                            />
                        </div>
                        <div style={tab.key !== TABS.TAB_HIERARCHY.key  ? { display: 'none' } : styles.hierarchySection }>
                            <EquipmentHierarchy equipmentID={equipment[EQUIPMENT_KEYS.CODE]} applicationData={applicationData}/>
                        </div>
                        {tab.key === TABS.TAB_AUDIT.key &&
                            <div style={defaultTabStyle}>
                                <EquipmentAudit
                                    auditData={auditData}
                                    getTranslation={getTranslation}
                                />
                            </div>
                        }
                        {tab.key === TABS.TAB_EVENTS.key &&
                            <div style={defaultTabStyle}>
                                <EquipmentEvents
                                    eventData={eventData}
                                    getTranslation={getTranslation}
                                />
                            </div>
                        } */}
                    </section>
                </section>
            </section>
        </section>
    );
};

export default EquipmentShowPage;

export { TABS };
