import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import 'react-block-ui/style.css';
import BlockUi from 'react-block-ui';
import Grid from '@material-ui/core/Grid';
import Tools from '../../../tools/Tools';
import MenuItem from './MenuItem';
import MenuModeSelection from './MenuModeSelection';

const TREC_MENU = 'TREC_MENU';

const OTHER_CATEGORY = 'OTHER';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: 'auto',
    },

    '@media ( min-width : 1360px)': {
        container: {
            width: '50%',
        },
    },

    '@media ( min-width : 981px) and (max-width: 1359px)': {
        container: {
            width: '90%',
        },
    },
};

class Menu extends Component {
    blockUiStyle = {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    blockUiStyleDiv = {
        display: 'flex',
        height: 60,
        alignItems: 'flex-end',
    };

    UNSAFE_componentWillMount() {
        const { setPage } = this.props;
        setPage(TREC_MENU);
    }

    handleLogout = () => {
        const { logout } = this.props;
        logout();
    };

    renderCategory = (items) => {
        const { getTranslation } = this.props;
        return (
            items && (
                <Grid container item xs={12}>
                    {items.map((item) => (
                        <MenuItem
                            key={item.menuCode}
                            description={getTranslation(item.menuCode)}
                            code={item.menuCode}
                            link={item.link}
                            icon={item.icon}
                            openNewTarget={item.openNewTarget}
                            {...(item.onClick ? { onClick: item.onClick } : {})}
                        />
                    ))}
                </Grid>
            )
        );
    };

    render() {
        const { menuCategoryOrder, loading, items, menuType, getTranslation, updateApplication } = this.props;

        if (loading) {
            return (
                <BlockUi tag="div" blocking style={this.blockUiStyle}>
                    <div style={this.blockUiStyleDiv}>Loading menu items...</div>
                </BlockUi>
            );
        }

        const logoutItem = {
            menuCode: 'LOGOUT',
            icon: 'fa fa-sign-out',
            openNewTarget: false,
            onClick: this.handleLogout.bind(this),
            category: OTHER_CATEGORY,
        };

        const categories = Tools.groupListByFunction([...items, logoutItem], (it) => it.category || OTHER_CATEGORY);

        return (
            <div className="trecPageContainer">
                <MenuModeSelection
                    update={(type) => updateApplication({ menuType: type })}
                    type={menuType}
                    getTranslation={getTranslation}
                />

                <Grid container alignItems="center" justify="center" style={{ background: '#f6f6f6' }}>
                    {menuCategoryOrder
                        .filter((category) => categories[category])
                        .map((category) => (
                            <Grid
                                key={category}
                                item
                                xs={12}
                                md={11}
                                xl={9}
                                style={{
                                    background: '#ffffff',
                                    margin: '10px 0px 0px 0px',
                                    padding: '2px 0px 0px 0px',
                                }}
                            >
                                {this.renderCategory(categories[category])}
                            </Grid>
                        ))}
                    <Grid
                        item
                        xs={12}
                        md={11}
                        xl={9}
                        style={{ background: '#ffffff', margin: '9px 0px 0px 0px', padding: '0' }}
                    />
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Menu);
