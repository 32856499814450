import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

// For the search screens
function SearchPanelColumn(props) {
    const { children, md, sm, xs } = props;
    return (
        <Grid item sm={sm} xs={xs} md={md}>
            {children}
        </Grid>
    );
}

SearchPanelColumn.propTypes = {
    md: PropTypes.number,
    sm: PropTypes.number,
    xs: PropTypes.number,
};

SearchPanelColumn.defaultProps = {
    md: 6,
    sm: 12,
    xs: 12,
};

export default SearchPanelColumn;
