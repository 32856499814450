import React from 'react';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import BlockUi from 'react-block-ui';
import { Icon } from '@material-ui/core';
import TRECButton from '../../../../components/buttons/TRECButton';
import BZInventoryFilters from '../BZInventoryFilters';
import BZInventoryGlobalActions, { GLOBAL_ACTION, GLOBAL_LOCATION } from './BZInventoryGlobalActions';
import BZInventoryTable from '../BZInventoryTable';
import { handleError, showSuccess, showWarning } from 'tools/TrecNotifications';
import {
    ACTION_CHANGE_LOCATION,
    filterFutureAction,
    filterMeasurementStatus,
    filterRadiationClassification,
    filterResult,
    filterObjResp,
    FILTER_OBJRESP,
    FILTER_ACTION,
    FILTER_FUTURE_ACTION,
    FILTER_RADIATION_CLASSIFICATION,
    FILTER_RESULT,
    FILTER_STATUS,
    isActionPossible,
} from '../BZInventoryUtils';
import WS from '../../../../../tools/rest/WSBZInventory';
import 'react-block-ui/style.css';
import BZInventoryStats from '../BZInventoryStats';

class BZInventoryActions extends React.Component {
    state = {
        filters: {},
    };

    paperStyle = {
        margin: 5,
        marginBottom: 15,
        overflow: 'auto',
    };

    buttonStyle = {
        width: 180,
        marginBottom: 5,
    };

    buttonsContainerStyle = {
        display: 'flex',
        float: 'right',
        padding: '5px',
        flexDirection: 'row',
        flexWrap: 'wrap',
    };

    blockUiStyle = {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    blockUiStyleDiv = {
        display: 'flex',
        height: 60,
        alignItems: 'flex-end',
    };

    handleScanMoreCodes = () => {
        const { scanMoreCodes } = this.props;
        scanMoreCodes();
    };

    handleApproveInventory = () => {
        const { approveInventory, bufferZone, comment, data, translations, updateProperty } = this.props;

        this.setState(() => ({
            approving: true,
            filters: {},
        }));

        WS.postInventoryData(bufferZone, comment, data)
            .then((response) => {
                const errors = response.body.data
                    .filter((element) => element.errorCode)
                    .map((element) => element.equipment);

                if (errors.length === 0) {
                    showSuccess(translations.INV_APPROVED);
                } else {
                    showWarning(`${translations.APPROVED_WITH_WARNINGS} ${errors.join(', ')}`);
                }

                approveInventory();

                this.setState(() => ({
                    approving: false,
                }));

                // set the data again because there might be error codes coming from the actions
                updateProperty('data', response.body.data);
            })
            .catch((error) => {
                handleError(error);
                approveInventory();

                this.setState(() => ({
                    approving: false,
                }));
            });
    };

    updateProperty = (key, value) => {
        if (key === GLOBAL_ACTION) {
            this.setGlobalAction(value);
        } else if (key === GLOBAL_LOCATION) {
            this.setGlobalLocation(value);
        } else {
            this.setState((prevState) => ({
                ...prevState,
                [key]: value,
            }));
        }
    };

    setGlobalAction = (value) => {
        const { data, updateProperty } = this.props;
        // only set the global action for data that is visible
        const dataFiltered = this.filterData(data);

        data.filter((element) => dataFiltered.includes(element)).forEach((element) => {
            if (isActionPossible(element, value)) {
                element.afterInventoryActionCode = value;

                if (value === ACTION_CHANGE_LOCATION) {
                    element.newLocationCode = element.defaultNewLocation;
                }
            }
        });

        updateProperty('data', data);
    };

    setGlobalLocation = (value) => {
        const { data, updateProperty } = this.props;
        const dataFiltered = this.filterData(data);

        data.filter((element) => dataFiltered.includes(element))
            .filter((element) => element.afterInventoryActionCode === ACTION_CHANGE_LOCATION)
            .forEach((element) => {
                element.newLocationCode = value;
            });

        updateProperty('data', data);
    };

    filterData = (data) => {
        if (!data) {
            return data;
        }

        const { futureActions } = this.props;
        const { filters } = this.state;

        const dataFiltered = data.filter(
            (element) =>
                filterMeasurementStatus(element.measurementStatusCode, filters[FILTER_STATUS]) &&
                filterResult(element.resultCode, filters[FILTER_RESULT]) &&
                filterFutureAction(element.futureUsageCode, filters[FILTER_FUTURE_ACTION], futureActions) &&
                filterRadiationClassification(element.classificationCode, filters[FILTER_RADIATION_CLASSIFICATION]) &&
                (!filters[FILTER_ACTION] || element.afterInventoryActionCode === filters[FILTER_ACTION]) &&
                filterObjResp(element.responsible, filters[FILTER_OBJRESP])
        );

        return dataFiltered;
    };

    updatePropertyInsideTable = (key, value) => {
        const { data } = this.props;

        data.filter((element) => element.equipment === key.equipment || key.selectAll).forEach((element) => {
            element[key.property] = value;

            if (key.property === 'afterInventoryActionCode' && value === ACTION_CHANGE_LOCATION) {
                element.newLocationCode = element.defaultNewLocation;
            }
        });

        this.updateProperty('data', data);
    };

    render() {
        const { approved, data, history, openConfirmDialog, translations } = this.props;
        const { approving, comment, globalAction, globalLocation, filters } = this.state;
        const filteredData = this.filterData(data);

        if (!approving) {
            return (
                <>
                    <Paper style={{ ...this.paperStyle, overflow: 'visible' }}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <div>
                                <BZInventoryStats
                                    data={data}
                                    getVisibleEquipment={() => (filteredData ? filteredData.length : 0)}
                                    actions
                                    {...this.props}
                                />
                            </div>
                            <div style={this.buttonsContainerStyle}>
                                {!approved && (
                                    <>
                                        <TRECButton onClick={this.handleScanMoreCodes} style={this.buttonStyle}>
                                            <Icon style={{ paddingRight: 10 }}>loupe</Icon>
                                            {translations.SCAN_MORE_CODES}
                                        </TRECButton>
                                        <TRECButton
                                            onClick={() =>
                                                openConfirmDialog(
                                                    {
                                                        title: translations.CONFIRM_HEADER,
                                                        message: translations.CONFIRMATION,
                                                        cancelButtonLabel: translations.BUTTON_CANCEL,
                                                        confirmButtonLabel: translations.YES,
                                                        waitForCompletion: false,
                                                    },
                                                    this.handleApproveInventory
                                                )
                                            }
                                            style={this.buttonStyle}
                                        >
                                            <Icon style={{ paddingRight: 10 }}>check</Icon>
                                            {translations.APPROVE_INV}
                                        </TRECButton>
                                    </>
                                )}
                                <TRECButton
                                    style={{ ...this.buttonStyle }}
                                    color="default"
                                    onClick={() => {
                                        if (approved) {
                                            history.push('menu');
                                        } else {
                                            openConfirmDialog(
                                                {
                                                    title: translations.CONFIRM_HEADER,
                                                    message: translations.CANCELLATION,
                                                    cancelButtonLabel: translations.BUTTON_CANCEL,
                                                    confirmButtonLabel: translations.YES,
                                                    waitForCompletion: false,
                                                },
                                                () => {
                                                    history.push('menu');
                                                }
                                            );
                                        }
                                    }}
                                >
                                    <MenuIcon />
                                    {translations.GOTOMENU}
                                </TRECButton>
                            </div>
                        </div>
                        <BZInventoryFilters
                            actions
                            {...this.props}
                            updateProperty={(key, value) =>
                                this.setState((prevState) => ({
                                    ...prevState,
                                    filters: {
                                        ...prevState.filters,
                                        [key]: value,
                                    },
                                }))
                            }
                            filters={filters}
                            inventoryData={data}
                        />
                        <Divider />
                        <BZInventoryGlobalActions
                            globalAction={globalAction}
                            globalLocation={globalLocation}
                            comment={comment}
                            updateProperty={this.updateProperty}
                            translations={translations}
                            approved={approved}
                        />
                    </Paper>
                    <Paper style={this.paperStyle}>
                        <BZInventoryTable
                            {...this.props}
                            actions
                            approved={approved}
                            data={filteredData}
                            updatePropertyInsideTable={this.updatePropertyInsideTable}
                        />
                    </Paper>
                </>
            );
        }
        return (
            <BlockUi tag="div" blocking style={this.blockUiStyle}>
                <div style={this.blockUiStyleDiv}>{translations.LOADING}</div>
            </BlockUi>
        );
    }
}

export default BZInventoryActions;
