import { connect } from 'react-redux';
import { openConfirmDialog } from 'react-eis-components/dist/actions/uiActions';
import { setPage, updateLayout } from 'actions/layoutActions';
import RPMSearch from './RPMSearch';

function mapStateToProps(state) {
    return {
        translations: state.application.screenData[state.layout.lang].translations.TREC_RPMEASURMENTS,
        screenData: state.application.screenData,
        applicationData: state.application.applicationData,
        userData: state.application.userData,
        lang: state.layout.lang,
        constants: state.application.constants,
        masterPage: 'RP_REQUESTS',
        page: 'RP_REQUESTS',
        rwpDropdowns: state.application.rwpDropdowns,
        menu: state.application.screenData[state.layout.lang].menu[state.application.menuType],
        bufferZone: state.application.bufferZone,
    };
}

const RPMSearchContainer = connect(mapStateToProps, {
    updateLayout,
    setPage,
    openConfirmDialog,
})(RPMSearch);

export default RPMSearchContainer;
