import { WizardStep } from 'amm-tools';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import {
    EQUIPMENT_KEYS,
    RPAR_REQUEST_KEYS,
    RP_MEASUREMENT_KEYS,
    TABLE_DATA_TYPES,
    TREC_GENERAL_KEYS,
    TRANSLATION_KEYS,
} from 'enums/Constants';
import React from 'react';
import BlockUi from 'react-block-ui';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import { shouldShowComment } from 'tools/Hazards';
import Tools from 'tools/Tools';
import TRECButton from '../../../components/buttons/TRECButton';
import TRECPrefixInput from '../../rpmperform/components/radiationMeasurements/TRECPrefixInput';
import InputGenerator from '../../rpmrequest2/InputGenerator';
import { RPMR_TRANSLATION_KEYS } from '../../rpmrequest2/RPMRConstants';
import { showError } from 'tools/TrecNotifications';

const { ATTRIBUTES } = InputGenerator;

const styles = {
    longSelectField: {
        minWidth: '150px',
        fontSize: '14px',
    },
    multiSelectField: {
        minWidth: '202px',
        fontSize: '12px',
    },
    shortSelectField: {
        minWidth: '80px',
        fontSize: '14px',
    },
};
class RPARLogistics extends WizardStep {
    formFields = [];

    getRPMeasurementTableColumns = () => [
        {
            header: '',
            code: '',
            type: 'CLEARRPM',
        },
        {
            header: this.props.getTranslation(TRANSLATION_KEYS.RP_CLASSIFICATION),
            code: RP_MEASUREMENT_KEYS.RP_CLASSIFICATION,
            type: TABLE_DATA_TYPES.SELECT,
            values: Tools.getCustomFieldsCodeDesc(this.props.screenData.customFieldsDef.PR210, false),
            editable: true,
            required: true,
        },
        {
            header: this.props.getTranslation(RP_MEASUREMENT_KEYS.CONTAMINATION),
            code: RP_MEASUREMENT_KEYS.CONTAMINATION,
            type: TABLE_DATA_TYPES.SELECT,
            values: Tools.getCustomFieldsCodeDesc(this.props.screenData.customFieldsDef.PR212, false),
            editable: true,
            required: true,
        },
        {
            header: this.props.getTranslation(RP_MEASUREMENT_KEYS.MEASURE_CONTACT),
            code: RP_MEASUREMENT_KEYS.MEASURE_CONTACT,
            qualifierCode: RP_MEASUREMENT_KEYS.MEASURE_CONTACT_QUALIFIER,
            type: TABLE_DATA_TYPES.QUALIFIER_INPUT,
            editable: true,
            required: true,
        },
        {
            header: this.props.getTranslation(RP_MEASUREMENT_KEYS.MEASURE10CM),
            code: RP_MEASUREMENT_KEYS.MEASURE10CM,
            qualifierCode: RP_MEASUREMENT_KEYS.MEASURE10CM_QUALIFIER,
            type: TABLE_DATA_TYPES.QUALIFIER_INPUT,
            editable: true,
            required: false,
        },
        {
            header: this.props.getTranslation(RP_MEASUREMENT_KEYS.MEASURE_BGO),
            code: RP_MEASUREMENT_KEYS.MEASURE_BGO,
            qualifierCode: RP_MEASUREMENT_KEYS.MEASURE_BGO_QUALIFIER,
            type: TABLE_DATA_TYPES.QUALIFIER_INPUT,
            editable: true,
            required: true,
        },
        {
            header: this.props.getTranslation(RP_MEASUREMENT_KEYS.MEASURE_ALPHA),
            code: RP_MEASUREMENT_KEYS.MEASURE_ALPHA,
            qualifierCode: RP_MEASUREMENT_KEYS.MEASURE_ALPHA_QUALIFIER,
            type: TABLE_DATA_TYPES.QUALIFIER_INPUT,
            editable: true,
            required: true,
        },
        {
            header: this.props.getTranslation(RP_MEASUREMENT_KEYS.MEASURE_BETA),
            code: RP_MEASUREMENT_KEYS.MEASURE_BETA,
            qualifierCode: RP_MEASUREMENT_KEYS.MEASURE_BETA_QUALIFIER,
            type: TABLE_DATA_TYPES.QUALIFIER_INPUT,
            editable: false,
            required: true,
        },
    ];

    getEquipmentTableColumns = ({ showIrradEndDateMin, applicationData, dropdowns }) => [
        {
            header: this.props.getTranslation(EQUIPMENT_KEYS.EQUIPMENT_CODE),
            code: EQUIPMENT_KEYS.EQUIPMENT_CODE,
            type: TABLE_DATA_TYPES.STATIC,
            editable: false,
        },
        {
            header: this.props.getTranslation(EQUIPMENT_KEYS.EQUIPMENT_DESC),
            code: EQUIPMENT_KEYS.EQUIPMENT_DESC,
            type: TABLE_DATA_TYPES.STATIC,
            editable: false,
        },
        {
            header: this.props.getTranslation(EQUIPMENT_KEYS.FRAGILE),
            code: EQUIPMENT_KEYS.FRAGILE,
            type: TABLE_DATA_TYPES.SELECT,
            values: this.props.screenData.lists.yesnoList,
            editable: true,
            required: true,
        },
        {
            code: EQUIPMENT_KEYS.FACILITIES,
            type: TABLE_DATA_TYPES.SELECT,
            values: this.props.rpamValues[EQUIPMENT_KEYS.FACILITY],
            required: true,
        },
        ...(showIrradEndDateMin
            ? [
                  {
                      header: this.props.screenData.customFieldsWaste[this.props.constants.cfpWmIrradiationEndDateMin]
                          .text,
                      code: EQUIPMENT_KEYS.IRRADIATION_END_DATE_MIN,
                      type: TABLE_DATA_TYPES.DATE,
                      elementInfo: {
                          ...this.props.screenData.customFieldsWaste[this.props.constants.cfpWmIrradiationEndDateMin],
                          attribute: 'R',
                      },
                      customInputProps: ({ object }) => ({
                          style: { minWidth: '110px' },
                          timestamp: true,
                          maxDate: object[EQUIPMENT_KEYS.IRRADIATION_END_DATE_MAX] || new Date(),
                      }),
                      getAttribute: (eqp) =>
                          !eqp[EQUIPMENT_KEYS.IRRADIATION_END_DATE_MIN] ? ATTRIBUTES.HIDDEN : ATTRIBUTES.READONLY,
                  },
              ]
            : []),
        {
            header: this.props.screenData.customFieldsWaste[this.props.constants.cfpWmIrradiationEndDateMax].text,
            code: EQUIPMENT_KEYS.IRRADIATION_END_DATE_MAX,
            type: TABLE_DATA_TYPES.DATE,
            elementInfo: {
                ...this.props.screenData.customFieldsWaste[this.props.constants.cfpWmIrradiationEndDateMax],
                attribute: 'R',
            },
            customInputProps: ({ object }) => ({
                style: { minWidth: '110px' },
                timestamp: true,
                minDate: object[EQUIPMENT_KEYS.IRRADIATION_END_DATE_MIN] || undefined,
                maxDate: new Date(),
            }),
            getAttribute: (eqp) =>
                eqp[EQUIPMENT_KEYS.IRRADIATION_END_DATE_MIN] ? ATTRIBUTES.REQUIRED : ATTRIBUTES.OPTIONAL,
        },
        {
            code: EQUIPMENT_KEYS.RPO,
            type: TABLE_DATA_TYPES.SELECT,
            values: Object.values(this.props.rpamValues[EQUIPMENT_KEYS.RPO]).map((e) => ({
                code: e.cernID,
                desc: [e.lastName, e.firstName, e.phoneNumber, e.cernID].join(' '),
            })),
            required: true,
        },
        ...(applicationData.isHazardsFeatureActive
            ? [
                  {
                      header: this.props.getTranslation(RPMR_TRANSLATION_KEYS.HAZARD_CODES),
                      code: EQUIPMENT_KEYS.HAZARD_CODES,
                      type: TABLE_DATA_TYPES.SELECT,
                      values: dropdowns.hazard,
                      hidden: (equipment) => !Array.isArray(equipment[EQUIPMENT_KEYS.HAZARD_CODES]),
                  },
                  {
                      header: this.props.getTranslation(RPMR_TRANSLATION_KEYS.HAZARD_COMMENT),
                      code: EQUIPMENT_KEYS.HAZARD_COMMENT,
                      type: TABLE_DATA_TYPES.INPUT,
                      hidden: (equipment) => !shouldShowComment({ equipment, applicationData }),
                      required: (equipment) => shouldShowComment({ equipment, applicationData }),
                  },
              ]
            : [
                  {
                      header: this.props.getTranslation(EQUIPMENT_KEYS.ASSOCIATED_RISK),
                      code: EQUIPMENT_KEYS.ASSOCIATED_RISK,
                      type: TABLE_DATA_TYPES.SELECT,
                      lockIfRPM: true,
                      values: Tools.getCustomFieldsCodeDesc(this.props.screenData.customFieldsDef.P205, false),
                      editable: true,
                      required: true,
                  },
              ]),
    ];

    state = {
        equipmentMap: {},
        tableColumns: [],
    };

    formFields = [];

    validate() {
        let isValid = true;
        const { getTranslation } = this.props;
        Object.keys(this.formFields).forEach((key) => {
            if (this.formFields[key] && this.formFields[key].validate && !this.formFields[key].validate()) {
                isValid = false;
                showError(getTranslation(TREC_GENERAL_KEYS.REQUIRED_ERROR_MESSAGE), null, 4000);
            }
        });
        return isValid;
    }

    canContinue = () => this.validate();

    UNSAFE_componentWillMount() {
        this.init(this.props);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.init(props);
    }

    init = (props) => {
        const { applicationData, dropdowns } = props;

        const equipmentMap = Tools.applyToFields(props.rparGetters.getEquipments(), props.applyDefaults);
        const showIrradEndDateMin = Object.values(equipmentMap).some(
            (eqp) => eqp[EQUIPMENT_KEYS.IRRADIATION_END_DATE_MIN]
        );

        this.setState({
            equipmentMap,
            tableColumns: this.generateTableColumns({ showIrradEndDateMin, applicationData, dropdowns }),
            loading: false,
            showIrradEndDateMin,
            applicationData,
        });
    };

    changeState = (key, value) => this.setState({ [key]: value });

    updateState = (stateCode, entryCode, key, value, onMount) => {
        Tools.updateState(
            this,
            stateCode,
            entryCode,
            key,
            value,
            !onMount && this.props.rparGetters.getAnalysisRequestProperties()[RPAR_REQUEST_KEYS.APPLY_ALL_LINES]
        );
    };

    saveChanges = () => {
        this.props.rparActions.updateRPAREquipments(this.state.equipmentMap);
        return true;
    };

    commitChanges = (callback) => {
        callback();
    };

    generateTableColumns = (props) =>
        this.mergeColumns(this.getEquipmentTableColumns(props), this.getRPMeasurementTableColumns());

    mergeColumns = (originalColumns, columns) => [...originalColumns, ...columns];

    getTableData = (tableColumns, equipmentMap) =>
        Object.keys(equipmentMap).map((eqCode) => {
            const equipment = equipmentMap[eqCode];

            return tableColumns.reduce((acc, column) => {
                const lock = !(column.lockIfRPM && equipment[EQUIPMENT_KEYS.RPA_MEASUREMENT]);
                switch (column.type) {
                    case TABLE_DATA_TYPES.STATIC:
                        acc[column.code] = equipment[column.code];
                        break;
                    case TABLE_DATA_TYPES.INPUT:
                        acc[column.code] = this.getEAMInput(
                            eqCode,
                            equipment,
                            column.code,
                            column.required,
                            column.hidden
                        );
                        break;
                    case TABLE_DATA_TYPES.QUALIFIER_INPUT:
                        if (
                            equipment[RP_MEASUREMENT_KEYS.CONTAMINATION] !== 'Yes' &&
                            (column.code === RP_MEASUREMENT_KEYS.MEASURE_ALPHA ||
                                column.code === RP_MEASUREMENT_KEYS.MEASURE_BETA)
                        ) {
                            break;
                        }
                        if (
                            equipment[RP_MEASUREMENT_KEYS.MEASURE_CONTACT] > 0.5 &&
                            column.code === RP_MEASUREMENT_KEYS.MEASURE_BGO
                        ) {
                            equipment[RP_MEASUREMENT_KEYS.MEASURE_BGO] = '';
                            break;
                        }
                        if (
                            column.code === RP_MEASUREMENT_KEYS.MEASURE_CONTACT ||
                            column.code === RP_MEASUREMENT_KEYS.MEASURE_BGO
                        ) {
                            column.required = equipment[RP_MEASUREMENT_KEYS.RP_CLASSIFICATION] === 'Radioactive';
                        }
                        if (column.code === RP_MEASUREMENT_KEYS.MEASURE10CM) {
                            column.required =
                                equipment[RP_MEASUREMENT_KEYS.RP_CLASSIFICATION] === 'Radioactive' &&
                                equipment[EQUIPMENT_KEYS.TYPE_OF_TRANSPORT] === 'Transport';
                        }
                        acc[column.code] = lock
                            ? this.getQualifierInput(
                                  eqCode,
                                  equipment,
                                  column.code,
                                  column.qualifierCode,
                                  column.required
                              )
                            : equipment[column.code]
                            ? `${equipment[column.qualifierCode] || ''} ${equipment[column.code]}`
                            : '';
                        break;
                    case TABLE_DATA_TYPES.SELECT: {
                        const required =
                            column.code === RP_MEASUREMENT_KEYS.CONTAMINATION
                                ? column.required && Boolean(equipment[RP_MEASUREMENT_KEYS.MEASURE_CONTACT])
                                : column.required;
                        acc[column.code] = lock
                            ? this.getEAMSelect(eqCode, equipment, column.code, column.values, required, column.hidden)
                            : equipment[column.code];
                        break;
                    }
                    case TABLE_DATA_TYPES.AUTOCOMPLETE:
                        acc[column.code] = lock
                            ? this.getEAMAutocomplete(
                                  eqCode,
                                  equipment,
                                  column.code,
                                  column.autocompleteType,
                                  column.required
                              )
                            : equipment[column.code];
                        break;
                    case TABLE_DATA_TYPES.CHECKBOX:
                        acc[column.code] = lock
                            ? this.getEAMCheckbox(eqCode, equipment, column.code, column.required)
                            : equipment[column.code];
                        break;
                    case TABLE_DATA_TYPES.DATE:
                        acc[column.code] = InputGenerator.generate({
                            field: {
                                ...column,
                                // elementInfo.xpath needed for formfields validation
                                elementInfo: {
                                    xpath: `${column.code}${equipment[EQUIPMENT_KEYS.EQUIPMENT_CODE]}`,
                                },
                            },
                            object: equipment,
                            updateObject: (key, value) => this.updateState('equipmentMap', eqCode, column.code, value),
                            formFields: this.formFields,
                        });
                        break;
                    case 'CLEARRPM':
                        acc[column.code] = equipment[EQUIPMENT_KEYS.RPA_MEASUREMENT] && (
                            <TRECButton
                                onClick={(_) => this.updateState('equipmentMap', eqCode, 'rpMeasurement', '')}
                                color="red"
                                size="small"
                            >
                                {this.props.getTranslation(RPAR_REQUEST_KEYS.CLEAR)}
                            </TRECButton>
                        );
                        break;
                    default:
                        break;
                }
                return acc;
            }, {});
        });

    getEAMInput = (eqCode, equipment, valueKey, required, hidden) => {
        const attribute =
            hidden && hidden(equipment)
                ? 'H'
                : (typeof required === 'function' ? required(equipment) : required)
                ? 'R'
                : 'O';

        return (
            <EAMInput
                elementInfo={{
                    attribute,
                    xpath: `${eqCode}.${valueKey}`,
                    fieldType: valueKey === EQUIPMENT_KEYS.HAZARD_COMMENT ? 'text' : 'number',
                }}
                formFields={this.formFields}
                valueKey={valueKey}
                style={styles.inputStyle}
                value={equipment[valueKey]}
                updateProperty={(key, value) => {
                    if (valueKey === EQUIPMENT_KEYS.HAZARD_COMMENT) {
                        this.updateState('equipmentMap', eqCode, key, value);
                    } else {
                        this.updateState(
                            'equipmentMap',
                            eqCode,
                            key,
                            value.match(/^[+]?([.]\d+|\d+[.]?\d*)$/) ? value : ''
                        );
                    }
                }}
            />
        );
    };

    getQualifierInput = (eqCode, equipment, valueKey, qualifierKey, required) => (
        <TRECPrefixInput
            codes={this.props.rpamValues.measureQualifier && this.props.rpamValues.measureQualifier.map((s) => s.code)}
            elementInfo={{
                attribute: required ? 'R' : 'O',
                xpath: `${eqCode}.${valueKey}`,
                text: '',
            }}
            updateProperty={(key, value) => {
                if (key === valueKey) {
                    this.updateState(
                        'equipmentMap',
                        eqCode,
                        key,
                        value.match(/^[+]?([.]\d+|\d+[.]?\d*)$/) ? value : ''
                    );
                } else if (key === qualifierKey) {
                    this.updateState('equipmentMap', eqCode, key, value);
                }
            }}
            value={equipment[valueKey]}
            valueKey={valueKey}
            prefixValue={equipment[qualifierKey]}
            prefixValueKey={qualifierKey}
            formFields={this.formFields}
        />
    );

    getEAMSelect = (eqCode, equipment, valueKey, values, required, hidden) => {
        const attribute = (typeof hidden === 'function' ? hidden(equipment) : hidden)
            ? 'H'
            : (typeof required === 'function' ? required(equipment) : required)
            ? 'R'
            : 'O';

        // note: it seems that even when hidden, EAMSelect crashes if its value is undefined/null
        // solve this here specifically for the HAZARD_CODES case
        if (valueKey === EQUIPMENT_KEYS.HAZARD_CODES && attribute === 'H') return null;

        return (
            <EAMSelect
                elementInfo={{ attribute, xpath: `${eqCode}.${valueKey}` }}
                values={values}
                style={
                    [EQUIPMENT_KEYS.FRAGILE, RP_MEASUREMENT_KEYS.CONTAMINATION].includes(valueKey)
                        ? styles.shortSelectField
                        : valueKey === EQUIPMENT_KEYS.FACILITIES
                        ? styles.multiSelectField
                        : styles.longSelectField
                }
                formFields={this.formFields}
                valueKey={valueKey}
                value={equipment[valueKey]}
                updateProperty={(key, value, onMount) => {
                    this.updateState('equipmentMap', eqCode, key, value, onMount);
                }}
                multi={[EQUIPMENT_KEYS.FACILITIES, EQUIPMENT_KEYS.HAZARD_CODES].includes(valueKey)}
            />
        );
    };

    getEAMAutocomplete = (eqCode, equipment, valueKey, autocompleteType, required, defaultValue = '') => (
        <EAMAutocomplete
            elementInfo={{ attribute: required ? 'R' : 'O', xpath: `${eqCode}.${valueKey}` }}
            value={equipment[valueKey] || defaultValue}
            valueKey={valueKey}
            formFields={this.formFields}
            updateProperty={(key, value) => {
                this.updateState('equipmentMap', eqCode, key, value);
            }}
            loadOptions={autocompleteType}
        />
    );

    render() {
        const { equipmentMap, loading, showIrradEndDateMin, applicationData } = this.state;
        const { getTranslation, dropdowns } = this.props;

        const properties = this.props.rparGetters.getAnalysisRequestProperties();
        const updateProperties = (key, value) => {
            this.saveChanges();
            this.props.rparActions.updateRPARequest({
                [key]: value,
            });
        };

        const tableLayout = [
            ...this.getEquipmentTableColumns({ showIrradEndDateMin, applicationData, dropdowns }),
            ...this.getRPMeasurementTableColumns().map((el) => ({ ...el, lockIfRPM: true })),
        ].map((e) => ({
            ...e,
            header: e.header || getTranslation(e.code),
        }));

        const tableHeaders = tableLayout.map((e) => e.header);
        const tableCodes = tableLayout.map((e) => e.code);
        const tableData = this.getTableData(tableLayout, equipmentMap);

        return (
            <div>
                <EISPanel
                    heading={getTranslation(RPAR_REQUEST_KEYS.SAMPLEREVIEW)}
                    alwaysExpanded
                    style={{ width: '100%', height: 'auto' }}
                >
                    {loading ? (
                        <BlockUi tag="div" blocking>
                            <div>Please wait...</div>
                        </BlockUi>
                    ) : (
                        <div
                            style={{
                                height: '60vh',
                                display: 'flex',
                                flexWrap: 'nowrap',
                                flex: '1',
                                flexDirection: 'column',
                                width: '100%',
                            }}
                        >
                            <div style={{ flexGrow: '1', width: '100%' }}>
                                <EAMCheckbox
                                    elementInfo={{
                                        label: 'OLabel',
                                        attribute: 'O',
                                        text: getTranslation(RPAR_REQUEST_KEYS.COPYCHECKBOX),
                                    }}
                                    valueKey={RPAR_REQUEST_KEYS.APPLY_ALL_LINES}
                                    value={properties[RPAR_REQUEST_KEYS.APPLY_ALL_LINES]}
                                    updateProperty={updateProperties}
                                    trueValue
                                    falseValue={false}
                                />
                            </div>

                            <div style={{ flexGrow: '20', width: '100%', overflowX: 'auto', height: 'auto' }}>
                                <EISTable
                                    data={tableData}
                                    headers={tableHeaders}
                                    propCodes={tableCodes}
                                    maxMobileSize={600}
                                    style={{ width: 'auto' }}
                                />
                            </div>
                        </div>
                    )}
                </EISPanel>
            </div>
        );
    }
}

export default RPARLogistics;
