import { WizardStep } from 'amm-tools';
import { showError } from 'tools/TrecNotifications';
import { RW_ELIMINATION_KEYS } from '../RWEliminationConstants';

const RWEliminationChecklist = ({ rwElimRequest, applicationData, stepStatus }) => {
    const activity = rwElimRequest.stepActivityMap?.[stepStatus];
    return (
        <>
            {/* <span>
                {activity?.act}: {activity?.description}
            </span> */}
            <iframe
                title="Checklist 1"
                src={`${applicationData.eamlightLink}workorder/${
                    rwElimRequest[RW_ELIMINATION_KEYS.CODE]
                }?maximize=CHECKLISTS&activityCode=${activity?.act}`}
                id="Validate1"
                style={{
                    width: '100%',
                    height: '100%',
                }}
            />
        </>
    );
};

class RWEliminationChecklistStep extends WizardStep {
    formFields = {};

    state = {};

    componentDidMount() {
        super.componentDidMount();
    }

    handleSubmit = () => {};

    handleSave = () => {
        this.canContinue();
    };

    /**
     * Can continue to the next step on the wizard
     */
    canContinue = () => {
        const isValid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
        if (!isValid) {
            showError('One or more fields are not valid.');
        }

        return isValid;
    };

    /**
     * SaveChanges is used to save the localized state to the redux-store. It can only run synchronous methods.
     */
    saveChanges = () => true;

    /**
     *  CommitChanges is used to trigger any side effects of the step, such as invocations to the back-end to
     * retrieve information about resources. This method can run asynchronous methods/requests but SHALL execute
     * the callback method afterwards to inform the wizard.
     */
    commitChanges = async (callback) => {
        callback();
    };

    /**
     * On true, the wizard automatically jumps this step immediately after it is mounted
     */
    canJumpStep = () => false;

    render() {
        return <RWEliminationChecklist formFields={this.formFields} {...this.props} />;
    }
}

export default RWEliminationChecklistStep;
