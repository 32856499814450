import React from 'react';
import Grid from '@material-ui/core/Grid';
import BlockUi from 'react-block-ui';
import CommentsContainer from 'eam-components/dist/ui/components/comments/CommentsContainer';
import ChecklistsContainer from 'eam-components/dist/ui/components/checklists/ChecklistsContainer';
import WSChecklists from 'eam-components/dist/tools/WSChecklists';
import EDMSWidgetContainer from 'eam-components/dist/ui/components/edms/EDMSWidgetContainer';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import RWChecksToolbar from './RWCPerformToolbar';
import RWCheckInformation from './RWCheckInformation';
import WSRWChecks from '../../../tools/rest/WSRWChecks';
import Tools from '../../../tools/Tools';
import TRECPage from '../TRECPage';
import WSComments from '../../../tools/rest/WSComments';
import WSMeasurements from '../../../tools/rest/WSMeasurements';
import WSContainers from '../../../tools/rest/WSContainers';
import ContainerContent from '../container/ContainerContent';
import RadiationMeasurements from '../rpmperform/components/radiationMeasurements/RadiationMeasurements';
import ContaminationMeasurements from '../rpmperform/components/ContaminationMeasurements';
import EquipmentDetails from '../rpmperform/components/EquipmentDetails';
import EquipmentTreeContainer from '../eqtree/EquipmentTreeContainer';
import { INFORMATION_SOURCE } from '../eqtree/Constants';
import { EQUIPMENT_KEYS } from '../../../enums/Constants';
import { handleError, showError, showSuccess } from 'tools/TrecNotifications';

class RWCPerform extends TRECPage {
    formFields = [];

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            processed: false,
            measurement: undefined,
            activities: undefined,
            containerContent: undefined,
        };

        const wo = Tools.getURLParameterByName('wo');
        const { constants, applicationData } = this.props;
        WSRWChecks.getRWCheck(wo)
            .then((response) => {
                const { data } = response.body;
                this.setState(() => ({
                    data,
                    processed: data.statusCode === constants.statusWoT,
                }));
                return WSMeasurements.getMeasurementDetails({ applicationData, workorder: data.measurementJobNumber });
            })
            .then((response) => {
                const measurement = response.body.data;
                // Set the measurement
                this.setState(() => ({ measurement }));
                // Load activities and container content if eqp is container
                const eqpClass = measurement.equipment[0].classCode;
                const eqpCode = measurement.equipment[0].equipmentCode;
                // Promises
                const promises = [WSChecklists.getWorkOrderActivities(measurement.jobNumber)];
                if (applicationData.containerClassesList.includes(eqpClass)) {
                    // Promise to get container content
                    promises.push(WSContainers.getContainerContent(eqpCode));
                }
                return Promise.all(promises);
            })
            .then((responses) => {
                const activities = responses[0].body.data;
                // If container content
                const containerContent = responses[1] ? responses[1].body.data : undefined;
                this.setState(() => ({ activities, containerContent }));
            })
            .catch(handleError);
    }

    updateValue = (key, value) => {
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                [key]: value,
            },
        }));
    };

    updateMeasurement = (path, value) => {
        this.setState((prevState) => ({
            measurement: cloneDeep(
                set(prevState.measurement, path.split(`${this.state.measurement.jobNumber}.`)[1], value)
            ),
        }));
    };

    saveData = () => {
        const { translations, constants, applicationData } = this.props;
        // Validations ok from the beginning
        let isValid = true;
        // Iterate over the required fields and validate them
        Object.keys(this.formFields).forEach((key) => {
            if (this.formFields[key] && this.formFields[key].validate && !this.formFields[key].validate()) {
                isValid = false;
            }
        });
        // Return if not valid
        if (!isValid) {
            return;
        }

        // If status cancelled and no comment is added, then throw an error
        if (
            this.state.data.statusCode === constants.statusWoTx &&
            (!this.comments || (this.comments && !this.state.commentAdded))
        ) {
            const message = translations.EXPLAINCANCELLED;
            showError(message);
            return;
        }

        // if 'Not ok' and without comments, then throw an error
        if (
            this.state.data.result === constants.cfvRwcStepResultNotok &&
            (!this.comments || this.comments.wrappedInstance.state.comments.length === 0)
        ) {
            const message = translations.EXPLAINNOTOK;
            showError(message);
            return;
        }

        // otherwise continue with the invocation to the server side processed

        this.props.openConfirmDialog(Tools.createDefaultConfirmObj(translations), () =>
            Promise.all([
                WSRWChecks.processRWCheck(this.state.data),
                WSRWChecks.updateRWCheckEquipment({
                    equipment: this.state.measurement.equipment[0],
                    applicationData,
                }),
            ])
                .then(() => {
                    const message = this.props.translations.JOBDATAUPD;
                    // Processed
                    this.setState(() => ({ processed: this.state.data.statusCode === constants.statusWoT }));
                    showSuccess(message, '', null);
                })
                .catch(handleError)
        );
    };

    onCommentAdded = () => {
        this.setState({ commentAdded: true });
    };

    createCADRAHandler = () => {
        const { translations } = this.props;
        this.props.openConfirmDialog(Tools.createDefaultConfirmObj(translations), () =>
            WSRWChecks.createCADRACheck(this.state.data.measurementJobNumber)
                .then(() => {
                    // Reload activities
                    WSChecklists.getWorkOrderActivities(this.state.data.measurementJobNumber)
                        .then((response) => {
                            this.setState(() => ({ activities: response.body.data }));
                        })
                        .catch(handleError);
                    // Also in checklists
                    this.checklists.wrappedInstance.readActivities(this.state.data.measurementJobNumber);
                })
                .catch(handleError)
        );
    };

    edhDocumentHandler = () => {
        const { constants } = this.props;
        const equipment = this.state.measurement.equipment[0].inforEquipment;
        this.props.history.push(
            `/edhdocmng/?futureaction=${constants.futureActionWasteDeclare}&equipment=${equipment.code}`
        );
    };

    renderPage(writeAccess) {
        const { translations, applicationData, constants, lists } = this.props;

        if (!this.state.data.jobNumber || !this.state.measurement) {
            return (
                <BlockUi tag="div" blocking style={this.blockUiStyle}>
                    <div style={this.blockUiStyleDiv}>{translations.LOADING}</div>
                </BlockUi>
            );
        }

        const equipmentDetailsReadOnly = (inputName) =>
            ![
                'irradiationEndDateMin',
                'irradiationEndDateMax',
                'facilities',
                'containsAlphaEmitters',
                EQUIPMENT_KEYS.HAZARD_CODES,
                EQUIPMENT_KEYS.HAZARD_COMMENT,
            ].includes(inputName);

        return (
            <>
                <RWChecksToolbar
                    translations={translations}
                    history={this.props.history}
                    saveData={this.saveData}
                    createCADRAHandler={this.createCADRAHandler}
                    edhDocumentHandler={this.edhDocumentHandler}
                    writeAccess={writeAccess}
                    processed={this.state.processed}
                    activities={this.state.activities}
                    equipment={this.state.measurement.equipment[0].inforEquipment}
                    applicationData={applicationData}
                />

                <Grid container spacing={8}>
                    {/* Left column */}
                    <Grid item md={6} sm={12} xs={12}>
                        <RWCheckInformation
                            data={this.state.data}
                            updateProperty={this.updateValue}
                            screenData={this.props.screenData}
                            applicationData={this.props.applicationData}
                            lang={this.props.lang}
                            constants={this.props.constants}
                            formFields={this.formFields}
                            writeAccess={writeAccess}
                            processed={this.state.processed}
                            translations={translations}
                        />

                        <CommentsContainer
                            title={translations.RWCHECKCOMMENTS}
                            ref={(comments) => {
                                this.comments = comments;
                            }}
                            entityCode="EVNT"
                            entityKeyCode={this.state.data.jobNumber}
                            userDesc={this.props.userData.eamAccount.userDesc}
                            readComments={WSComments.readComments}
                            createComment={WSComments.createComment}
                            updateComment={WSComments.updateComment}
                            onCommentAdded={this.onCommentAdded}
                        />

                        <ChecklistsContainer
                            title={translations.CADRACHECKS}
                            workorder={this.state.data.measurementJobNumber}
                            ref={(checklists) => {
                                this.checklists = checklists;
                            }}
                            minFindingsDropdown={4}
                        />

                        <RadiationMeasurements
                            measurement={this.state.measurement}
                            readonly
                            translations={translations}
                            constants={constants}
                            customFields={this.props.customFields}
                            customFieldsDef={this.props.customFieldsDef}
                            // updateProperty={this.updateValue}
                        />

                        <ContaminationMeasurements
                            measurement={this.state.measurement}
                            readonly
                            translations={translations}
                            constants={constants}
                            customFields={this.props.customFields}
                            customFieldsDef={this.props.customFieldsDef}
                        />
                    </Grid>

                    {/* Right Column */}
                    <Grid item md={6} sm={12} xs={12}>
                        <EquipmentDetails
                            measurement={this.state.measurement}
                            readonly={equipmentDetailsReadOnly}
                            translations={translations}
                            constants={constants}
                            customFields={this.props.customFields}
                            customFieldsDef={this.props.customFieldsDef}
                            assetFields={this.props.assetFields}
                            lists={lists}
                            applicationData={this.props.applicationData}
                            customFieldsWaste={this.props.customFieldsWaste}
                            rwpDropdowns={this.props.rwpDropdowns}
                            formFields={this.formFields}
                            updateProperty={this.updateMeasurement}
                            showContainsAlphaEmitters
                        />

                        <CommentsContainer
                            title={translations.EQPCOMMENTS}
                            entityCode="OBJ"
                            entityKeyCode={this.state.data.equipmentId}
                            userDesc={this.props.userData.eamAccount.userDesc}
                            readComments={WSComments.readComments}
                        />

                        <CommentsContainer
                            title={translations.RPMEASCOMMENT}
                            entityCode="EVNT"
                            entityKeyCode={this.state.data.measurementJobNumber}
                            userDesc={this.props.userData.eamAccount.userDesc}
                            readComments={WSComments.readComments}
                        />

                        <EDMSWidgetContainer
                            objectID={this.state.data.equipmentId}
                            objectType="A"
                            title={translations.RWCHECKDOCS}
                            edmsDocListLink={applicationData.edmsdocListLink}
                        />

                        <EDMSWidgetContainer
                            objectID={this.state.data.measurementJobNumber}
                            objectType="J"
                            title={this.props.translations.MEASDOCUMENTS}
                            edmsDocListLink={this.props.applicationData.edmsdocListLink}
                        />

                        {/* Container content */}
                        {this.state.containerContent && (
                            <ContainerContent
                                onRemove={() => {}}
                                applicationData={applicationData}
                                translations={translations}
                                containerContent={this.state.containerContent}
                                showRemove={false}
                            />
                        )}

                        <EISPanel heading={translations.EQUIPMENT_TREE || 'EQUIPMENT_TREE'}>
                            <EquipmentTreeContainer
                                equipmentCode={this.state.measurement.equipment[0].equipmentCode}
                                src={INFORMATION_SOURCE.EQUIPMENT_STRUCTURE}
                                height={400}
                            />
                        </EISPanel>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default RWCPerform;
