import Tools from './Tools';

const SOME = 'SOME';
const UNCON = 'UNCON';
const EXC = 'EXC';
const LIST = 'LIST';
const LIST_SEPARATOR = ',';

const checkConstraint = (obj, con) =>
    [SOME, UNCON].includes(con.logic?.toUpperCase()) &&
    (Array.isArray(obj[con.parentField])
        ? obj[con.parentField].length === 0 || obj[con.parentField].includes(con.parentValue)
        : !obj[con.parentField] || obj[con.parentField] === con.parentValue);

const valueStrOrList = (value, filter) => (filter.logic === LIST ? value && value.split(LIST_SEPARATOR) : value);

export const getDefault = ({ key, object, valuesGrid }) => {
    const defaults = (valuesGrid.DEF || {})[key];
    let defaultValue = null;
    const prevValue = object[key];

    if (defaults) {
        const defaultsApplied = defaults
            .map(
                (filter) =>
                    prevValue !== undefined && prevValue !== null && (!Array.isArray(prevValue) || prevValue.length)
                        ? prevValue // no value present
                        : filter.parentValue
                        ? filter.parentValue === object[filter.parentField]
                            ? valueStrOrList(filter.value, filter)
                            : null // constrained default
                        : filter.parentField
                        ? object[filter.parentField] // fetch from parent
                        : valueStrOrList(filter.value, filter) // take the default
            )
            .filter((val) => val);
        [defaultValue] = defaultsApplied || [];
    }
    return defaultValue;
};

export const constrain = ({ key, object, valuesGrid, values }) => {
    const constraints = (valuesGrid.CON || {})[key];
    const checkCon = (con) => checkConstraint(object, con);

    if (!constraints) return values;

    const constraintsSOME = constraints.filter((con) => [SOME, UNCON].includes(con.logic?.toUpperCase()));
    const constraintsEXC = constraints.filter((con) => con.logic?.toUpperCase() === EXC);
    let finalValues = values;

    if (constraintsSOME.length) {
        const constraintsByParent = Tools.groupListBy(constraintsSOME, 'parentField');

        // Filter the values that don't have a parent value to constrain. This can be because the parent field has no value
        // (in case of the SOME logic) or there is no constraint defined for the value present.
        const parentsUnconstrained = Object.keys(constraintsByParent).filter((parent) =>
            // The next line is a simplification, since having UNCON and SOME contraints for the same parent field is inconsistent
            // (it's a binary configuration, either you allow for non-defined parent values to constrain or you don't)
            constraintsByParent[parent]?.[0]?.logic === UNCON
                ? Array.isArray(object[parent])
                    ? object[parent].every((val) => !constraintsByParent[parent].some((con) => con.parentValue === val))
                    : !constraintsByParent[parent].some((con) => con.parentValue === object[parent])
                : Array.isArray(object[parent])
                ? object[parent].length === 0
                : !object[parent]
        );

        // Filter values that passed the validation
        const allowedValuesByParent = Tools.applyToFields(constraintsByParent, (con) =>
            con.filter(checkCon).map((e) => e.value)
        );

        finalValues = values.reduce(
            (acc, value) => [
                ...acc,
                ...(Object.entries(allowedValuesByParent).every(
                    ([k, list]) => parentsUnconstrained.includes(k) || list.includes(value.code)
                )
                    ? [value]
                    : []),
            ],
            []
        );
    }

    if (constraintsEXC.length) {
        finalValues = finalValues.filter((val) =>
            constraintsEXC
                .filter((con) => val.code === con.value)
                .filter((con) => !con.comparator || ['EQUALS', 'NOT_EQUALS'].includes(con.comparator))
                .every((con) =>
                    con.comparator === 'NOT_EQUALS'
                        ? object[con.parentField] === con.parentValue
                        : object[con.parentField] !== con.parentValue
                )
        );
        finalValues = finalValues.filter(
            (val) =>
                !constraintsEXC
                    .filter((con) => val.code === con.value)
                    .filter((con) => ['NOT_EMPTY', 'IS_EMPTY'].includes(con.comparator))
                    .some((con) =>
                        con.comparator === 'NOT_EMPTY' ? !!object[con.parentField] : !object[con.parentField]
                    )
        );
    }
    return finalValues;
};

const compare = (operator, thisValue, otherValue) =>
    operator === 'EQUALS'
        ? thisValue && thisValue === otherValue
        : operator === 'NOT_EQUALS'
        ? thisValue && thisValue !== otherValue
        : operator === 'BEGINS'
        ? thisValue?.startsWith(otherValue)
        : operator === 'IS_EMPTY'
        ? !thisValue
        : operator === 'NOT_EMPTY'
        ? !!thisValue
        : ['>', 'GREATER_THAN'].includes(operator)
        ? thisValue > otherValue
        : ['<', 'LESS_THAN'].includes(operator)
        ? thisValue < otherValue
        : false;

const getSelectedRow = (confRows, values) => {
    // const stylesByAttr =
    //     confRows?.reduce((acc, el) => ({ ...acc, [el.parentField]: [...(acc[el.parentField] || []), el] }), {}) || {};

    try {
        const orderedStyles = Object.values(
            confRows?.reduce((acc, el) => ({ ...acc, [el.order]: [...(acc[el.order] || []), el] }), {}) || {}
        ).sort((a, b) => a[0].order - b[0].order);

        const styleApply = orderedStyles.find((conditionList) =>
            conditionList.every((cond) =>
                compare(cond?.comparator ?? 'EQUALS', values[cond.parentField], cond.parentValue)
            )
        )?.[0];

        return styleApply?.value;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export default {
    constrain,
    getDefault,
    compare,
    getSelectedRow,
};
