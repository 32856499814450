import Grid from '@material-ui/core/Grid';
import { WizardStep } from 'amm-tools';
import CommentsContainer from 'eam-components/dist/ui/components/comments/CommentsContainer';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import { TABLE_DATA_TYPES } from 'enums/Constants';
import React from 'react';
import BlockUi from 'react-block-ui';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import WSComments from 'tools/rest/WSComments';
import InputGenerator from '../InputGenerator';
import { EQUIPMENT_KEYS, REQUEST_KEYS, REQUEST_SOURCES } from '../RPMRConstants';
import { showError, showWarning } from 'tools/TrecNotifications';

const { ATTRIBUTES } = InputGenerator;

const getEquipmentFields = ({
    multipleEquipment,
    applicationData,
    constants,
    futureAction,
    assetFields,
    fetchedEquipmentMap,
    allowMultipleResp,
    isOtherRwc,
    requestSource,
}) => {
    const vacuumHide = [
        constants.futureActionVacuumEmpty,
        constants.futureActionVacuumEnd,
        constants.futureActionVacuumEndFull,
    ].includes(futureAction);

    return [
        {
            code: EQUIPMENT_KEYS.CODE,
            type: TABLE_DATA_TYPES.INPUT,
            elementInfo: assetFields.equipmentno,
            getAttribute: () => (multipleEquipment ? ATTRIBUTES.READONLY_TEXT : ATTRIBUTES.READONLY),
        },
        {
            code: EQUIPMENT_KEYS.SERIALNUMBER,
            type: TABLE_DATA_TYPES.INPUT,
            elementInfo: assetFields.serialnumber,
            getAttribute: (eqp) =>
                !fetchedEquipmentMap[eqp[EQUIPMENT_KEYS.CODE]][EQUIPMENT_KEYS.SERIALNUMBER]
                    ? ATTRIBUTES.OPTIONAL
                    : multipleEquipment
                    ? ATTRIBUTES.READONLY_TEXT
                    : ATTRIBUTES.READONLY,
        },
        {
            code: EQUIPMENT_KEYS.DESCRIPTION,
            type: TABLE_DATA_TYPES.INPUT,
            customValue: (eqp) =>
                eqp[EQUIPMENT_KEYS.DESCRIPTION] &&
                eqp[EQUIPMENT_KEYS.DESCRIPTION].toUpperCase() === applicationData.rpeqpNewDesc.toUpperCase()
                    ? ''
                    : eqp[EQUIPMENT_KEYS.DESCRIPTION],
            elementInfo: assetFields.equipmentdesc,
            getAttribute: (eqp) => {
                const oldEq = fetchedEquipmentMap[eqp[EQUIPMENT_KEYS.CODE]];
                return oldEq[EQUIPMENT_KEYS.DESCRIPTION] &&
                    oldEq[EQUIPMENT_KEYS.DESCRIPTION].toUpperCase() === applicationData.rpeqpNewDesc.toUpperCase()
                    ? ATTRIBUTES.REQUIRED
                    : multipleEquipment
                    ? ATTRIBUTES.READONLY_TEXT
                    : ATTRIBUTES.READONLY;
            },
            validate: (value) => value && value.toUpperCase() !== applicationData.rpeqpNewDesc.toUpperCase(),
        },
        {
            code: EQUIPMENT_KEYS.RESPONSIBLE_CID,
            renderValue: (eqp) =>
                !multipleEquipment || !eqp[EQUIPMENT_KEYS.RESPONSIBLE_CID]
                    ? eqp[EQUIPMENT_KEYS.RESPONSIBLE_CID]
                    : `${eqp[EQUIPMENT_KEYS.RESPONSIBLE_CID]} - ${eqp[EQUIPMENT_KEYS.RESPONSIBLE_DESC]}`,
            type: TABLE_DATA_TYPES.AUTOCOMPLETE,
            key: EQUIPMENT_KEYS.RESPONSIBLE_CID,
            descKey: EQUIPMENT_KEYS.RESPONSIBLE_DESC,
            elementInfo: assetFields.eqpResponsible,
            autocompleteType: WSAutocomplete.autocompleteEmployee,
            getAttribute: () =>
                multipleEquipment && !allowMultipleResp
                    ? ATTRIBUTES.READONLY_TEXT
                    : vacuumHide
                    ? ATTRIBUTES.HIDDEN
                    : isOtherRwc
                    ? ATTRIBUTES.OPTIONAL
                    : requestSource === REQUEST_SOURCES.EMPTY_CONTAINER
                    ? ATTRIBUTES.HIDDEN
                    : ATTRIBUTES.REQUIRED,
        },
        {
            code: EQUIPMENT_KEYS.LOCATION,
            type: TABLE_DATA_TYPES.STATIC,
            elementInfo: assetFields.location,
            getAttribute: () => (multipleEquipment ? ATTRIBUTES.READONLY_TEXT : ATTRIBUTES.HIDDEN),
        },
    ];
};

class RPMREquipmentStep extends WizardStep {
    formFields = {};

    state = { loading: true };

    UNSAFE_componentWillMount() {
        const { translations, storeActions, rpmrGetters } = this.props;
        storeActions.setPage('TREC_REQRPMEAS', 'STEP_EQUIPMENT');

        const equipmentMap = rpmrGetters.getEquipmentMap();
        const equipmentList = rpmrGetters.getEquipmentList();
        const resp = equipmentMap[equipmentList[0]][EQUIPMENT_KEYS.RESPONSIBLE_CID];

        WSAutocomplete.autocompleteEmployee(resp)
            .then((response) => {
                if (response.body.data.length === 0) {
                    equipmentMap[equipmentList[0]][EQUIPMENT_KEYS.RESPONSIBLE_CID] = '';
                    equipmentMap[equipmentList[0]][EQUIPMENT_KEYS.RESPONSIBLE_DESC] = '';
                }
            })
            .finally(() => {
                if (
                    !rpmrGetters.isAllowMultipleResp() &&
                    Object.values(equipmentMap).some(
                        (eq) => eq[EQUIPMENT_KEYS.RESPONSIBLE_CID] && eq[EQUIPMENT_KEYS.RESPONSIBLE_CID] !== resp
                    )
                ) {
                    showWarning(`${translations.EQPINVALID} ${translations.EQPSAMERESP}`);
                }
                this.setState({ loading: false });
            });
    }

    /** Logic */
    renderEquipment() {
        const {
            storeActions,
            applicationData,
            constants,
            rpmrGetters,
            screenData,
            translations,
            isOtherRwc,
            isVacuumAction,
            requestSource,
        } = this.props;

        const { futureAction } = rpmrGetters.getProperties();
        const equipmentList = rpmrGetters.getEquipmentList();
        const equipmentMap = rpmrGetters.getEquipmentMap();
        const multipleEquipment = rpmrGetters.isMultipleEquipment();
        const fetchedEquipmentMap = rpmrGetters.getFetchedEquipmentMap();
        const allowMultipleResp = rpmrGetters.isAllowMultipleResp();

        const equipmentFields = getEquipmentFields({
            assetFields: screenData.assetFields,
            multipleEquipment,
            applicationData,
            constants,
            futureAction,
            fetchedEquipmentMap,
            allowMultipleResp,
            isOtherRwc,
            isVacuumAction,
            requestSource,
        });

        //
        const updateEquipment = (eqCode) => (key, value) => {
            const eqList = [eqCode];
            if (rpmrGetters.isApplyAllLines()) {
                equipmentList.forEach((code) => {
                    const eq = equipmentMap[code];
                    const field = equipmentFields.filter((equipmentField) => equipmentField.code === key);
                    if (
                        eq[EQUIPMENT_KEYS.CODE] !== eqCode &&
                        (!field.length ||
                            !field[0].getAttribute ||
                            [ATTRIBUTES.OPTIONAL, ATTRIBUTES.REQUIRED].includes(field[0].getAttribute(eq)))
                    ) {
                        eqList.push(eq[EQUIPMENT_KEYS.CODE]);
                    }
                });
            }
            if (key === EQUIPMENT_KEYS.RESPONSIBLE_CID && !multipleEquipment) {
                storeActions.updateRPMRequest({ [REQUEST_KEYS.RESP_CODE]: value });
            }

            storeActions.updateRPMREquipment(eqList, { [key]: value });
        };

        const allFields = equipmentList.map((eqCode) =>
            equipmentFields.reduce(
                (acc, field) => ({
                    ...acc,
                    [field.code]: (
                        <div key={`${field.code}#${eqCode}`}>
                            {InputGenerator.generate({
                                field: {
                                    ...field,
                                    elementInfo: {
                                        ...(field.elementInfo || {}),
                                        xpath: field.elementInfo ? `${field.elementInfo.xpath}_${eqCode}` : null,
                                    },
                                },
                                object: equipmentMap[eqCode],
                                updateObject: updateEquipment(eqCode),
                                formFields: this.formFields,
                                hideLabel: multipleEquipment,
                            })}
                        </div>
                    ),
                }),
                {}
            )
        );

        return (
            <div style={{ width: '100%', height: '100%' }}>
                {!multipleEquipment ? (
                    Object.values(allFields[0])
                ) : (
                    <>
                        <EAMCheckbox
                            label={translations.EDITALL}
                            value={rpmrGetters.isApplyAllLines()}
                            updateProperty={(key, value) => storeActions.setApplyAllLines(value)}
                            trueValue
                            falseValue={false}
                        />
                        <EISTable
                            data={allFields}
                            headers={equipmentFields.map((field) => field.elementInfo.text)}
                            propCodes={equipmentFields.map((field) => field.code)}
                            maxMobileSize={600}
                        />
                        {!allowMultipleResp &&
                            InputGenerator.generate({
                                field: {
                                    code: REQUEST_KEYS.RESP_CODE,
                                    type: TABLE_DATA_TYPES.AUTOCOMPLETE,
                                    key: REQUEST_KEYS.RESP_CODE,
                                    descKey: REQUEST_KEYS.RESP_DESC,
                                    elementInfo: screenData.assetFields.eqpResponsible,
                                    autocompleteType: WSAutocomplete.autocompleteEmployee,
                                    getAttribute: () => ATTRIBUTES.REQUIRED,
                                },
                                updateObject: (key, val) => storeActions.updateRPMRequest({ [key]: val }),
                                object: rpmrGetters.getProperties(),
                                formFields: this.formFields,
                            })}
                    </>
                )}
            </div>
        );
    }

    /** WizardStep required methods */
    canContinue = () => {
        const { translations } = this.props;

        const isValid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );

        if (!isValid) {
            showError(translations.EQUFIELINV);
        }

        return isValid;
    };

    saveChanges = () => true;

    commitChanges = (callback) => {
        callback();
    };

    onStepChange = () => {
        this.props.storeActions.setApplyAllLines(false);
    };

    /** Component lifecycle */
    render() {
        const { rpmrGetters, getTranslation, userData } = this.props;
        const { loading } = this.state;
        const equipmentList = rpmrGetters.getEquipmentList();
        const multipleEquipment = rpmrGetters.isMultipleEquipment();
        const displayComments = !multipleEquipment;

        return (
            <BlockUi style={{ height: '100%' }} blocking={loading}>
                <div style={{ margin: 8 }}>
                    <Grid container spacing={8}>
                        <Grid item md={displayComments ? 6 : 12} sm={12} xs={12}>
                            <EISPanel heading={getTranslation('EQPDETAILS')} alwaysExpanded>
                                {this.renderEquipment()}
                            </EISPanel>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            {displayComments && (
                                <CommentsContainer
                                    detailsStyle={{ margin: 0 }}
                                    title={getTranslation('EQPCOMMENTS')}
                                    ref={(comments) => {
                                        this.comments = comments;
                                    }}
                                    entityCode="OBJ"
                                    entityKeyCode={equipmentList[0]}
                                    userDesc={userData.eamAccount.userDesc}
                                    readComments={WSComments.readComments}
                                />
                            )}
                        </Grid>
                    </Grid>
                </div>
            </BlockUi>
        );
    }
}

export default RPMREquipmentStep;
