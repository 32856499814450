import DEFAULTS from 'enums/Defaults';
import React from 'react';
import RPARAnalysisDetails from './RPARAnalysisDetails';

export const ANALYSIS_TYPE = 'PHYSCHEM';
function RPARPhysicoChemical(props) {
    const { getTranslation, rparGetters, dropdowns, valuesGrid } = props;
    const numberOfEquipments = Object.keys(rparGetters.getAnalysisTypeEquipments(ANALYSIS_TYPE)).length;
    const batchValues = DEFAULTS.getBatchValues(numberOfEquipments);
    const RPARPhysicoChemicalLayout = DEFAULTS.getTableLayout({
        rparGetters,
        ANALYSIS_TYPE,
        dropdowns,
        valuesGrid,
        batchValues,
    }).map((e) => ({
        ...e,
        header: getTranslation(e.code),
    }));

    return (
        <RPARAnalysisDetails
            {...props}
            analysisType={ANALYSIS_TYPE}
            tableLayout={RPARPhysicoChemicalLayout}
            batchValues={batchValues}
        />
    );
}

export default RPARPhysicoChemical;
