import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    LinearProgress,
    Typography,
} from '@material-ui/core';
import { isFuture } from 'date-fns';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import { TABLE_DATA_TYPES } from 'enums/Constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import EISTable from 'react-eis-components/dist/ui/components/table';
import { ROUTES, withQueryParams } from 'tools/Routes';
import Tools from 'tools/Tools';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import WSElimination from 'tools/rest/WSElimination';
import WSEquipment from 'tools/rest/WSEquipment';
import WSRWPProcessing from 'tools/rest/WSRWPProcessing';
import TRECButton from 'ui/components/buttons/TRECButton';
import EDMSMultilinkUpload from 'ui/components/documents/EDMSMultilinkUpload';
import InputGenerator from '../rpmrequest2/InputGenerator';
import { REQUEST_SOURCES } from '../rpmrequest2/RPMRConstants';
import { EQUIPMENT_KEYS, TRANSLATION_KEYS } from '../rwprocessing/RWPConstants';
import RWProcessingTools, { isContainer } from '../rwprocessing/RWProcessingTools';
import StageProgress, {
    STAGES_PROGRESS_SOURCE,
    STAGE_ERROR_TYPES,
    STAGE_STATUS_TYPES,
} from '../rwprocessing/StageProgress/StageProgress';
import { useStageProgress } from '../rwprocessing/StageProgress/useStageProgress';
import { ELIMINATION_REQUEST_KEYS, ELIMINATION_TRANSLATION_KEYS, PERFORM_TYPES } from './EliminationConstants';

const RWManagementEliminationDialog = ({ open, children, getTranslation, onClose }) => (
    <Dialog
        fullWidth
        maxWidth="xl"
        PaperProps={{
            style: { height: '100%' },
        }}
        open={open}
    >
        <DialogTitle>{getTranslation(ELIMINATION_TRANSLATION_KEYS.ELIMINATION_TITLE)}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
            <Button onClick={onClose}>CLOSE</Button>
        </DialogActions>
    </Dialog>
);

const localFormFields = [];

const { ATTRIBUTES } = InputGenerator;

const applyDefaults = (equipment) => ({
    ...equipment,
    [EQUIPMENT_KEYS.LOCATION]: 'H',
    [EQUIPMENT_KEYS.RW_ELIMINATION_DATE]: new Date(),
    [EQUIPMENT_KEYS.RW_ZONE]: '',
    [EQUIPMENT_KEYS.RW_SUBZONE]: '',
});

const eliminatePromise =
    ({ eliminationRequest, successMessage }) =>
    async () =>
        WSElimination.eliminate({ eliminationRequest }).then((response) => ({
            response,
            successMessage,
        }));

const applyStageProcedure = ({ stage, executeParams = {}, previousResultData = {}, updateStage }) => {
    const { id, execute } = stage;
    const params = { ...executeParams, ...previousResultData };
    updateStage({ id, stageData: { status: STAGE_STATUS_TYPES.PROCESSING } });
    return execute(params)
        .then((resultData) => {
            const { successMessage, ...other } = resultData;
            updateStage({
                id,
                stageData: { status: STAGE_STATUS_TYPES.SUCCESS, successMessage },
            });
            stage.afterSuccess({ stage, data: other.response.body.data });
            return other;
        })
        .catch((error) => {
            updateStage({
                id,
                stageData: {
                    status: STAGE_STATUS_TYPES.ERROR,
                    error: error && error.message === STAGE_ERROR_TYPES.STAGE_ERROR ? undefined : error,
                },
            });
            // throw new Error(STAGE_ERROR_TYPES.STAGE_ERROR);
        });
};

const executeParallel = ({ stages, updateStage }) =>
    Promise.all(
        Object.values(stages)
            .sort((a, b) => a.order > b.order)
            .map((stage) =>
                applyStageProcedure({
                    stage,
                    executeParams: stage.executeParams,
                    updateStage,
                })
            )
    );

const getParentEquipment = (equipment, equipmentMap) => equipmentMap[equipment[EQUIPMENT_KEYS.ATTACHED_TO]];

const executeStages = async ({
    stages,
    executeAfter,
    updateStage,
    setStages,
    beginStageProgress,
    endStageProgress,
    processStages,
}) => {
    const computedStages = stages.reduce(
        (acc, stage, index) => ({
            ...acc,
            [stage.id]: {
                mapPreviousResult: (previousResultData) => previousResultData,
                ...stage,
                order: index,
            },
        }),
        {}
    );

    setStages(computedStages);
    beginStageProgress();
    processStages({ stages: computedStages, updateStage })
        .catch((error) => {
            // TODO, handle this
            console.error(error);
        })
        .finally(() => {
            executeAfter && executeAfter({ stages });
            endStageProgress();
        });
};

const EliminationRPMRequest = ({ getTranslation, codes, history, constants, rpSection }) => (
    <div>
        <Typography>{getTranslation('DIALOG_RPM_REQUEST')}</Typography>
        <Typography style={{ marginTop: 5 }}>{codes.join(', ')}</Typography>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
                variant="outlined"
                color="primary"
                target="_blank"
                onClick={() =>
                    history.push(
                        withQueryParams({
                            path: ROUTES.rpmRequest2,
                            queryParams: {
                                action: constants.futureActionOther,
                                equipment: codes.join(','),
                                dep: rpSection,
                                requestSource: REQUEST_SOURCES.EMPTY_CONTAINER,
                            },
                        })
                    )
                }
            >
                Create
            </Button>
        </div>
    </div>
);

const EliminationProgress = (props) => {
    const { stages, setStages, updateStage, beginStageProgress, endStageProgress } = useStageProgress();
    const {
        applicationData,
        constants,
        equipmentToProcess,
        equipmentMap,
        equipmentContent,
        executeAfter,
        afterSuccess,
        getTranslation,
        history,
    } = props;

    const eliminationRequests = equipmentToProcess
        .map((eqCode) => equipmentMap[eqCode])
        .map((equipment) => ({
            [ELIMINATION_REQUEST_KEYS.PERFORM_TYPE]: equipment[ELIMINATION_REQUEST_KEYS.PERFORM_TYPE],
            [ELIMINATION_REQUEST_KEYS.EQUIPMENT]: equipment,
            [ELIMINATION_REQUEST_KEYS.CONTENT]: (equipmentContent[equipment[EQUIPMENT_KEYS.CODE]] || []).reduce(
                (acc, eqCode) => ({
                    ...acc,
                    [eqCode]: equipmentMap[eqCode],
                }),
                {}
            ),
        }));

    const eliminationStages = eliminationRequests.map((eliminationRequest, index) => ({
        id: eliminationRequest[ELIMINATION_REQUEST_KEYS.EQUIPMENT][EQUIPMENT_KEYS.CODE],
        execute: eliminatePromise({
            eliminationRequest,
            successMessage: getTranslation(ELIMINATION_TRANSLATION_KEYS.ELIMINATION_SUCCESS_MSG),
        }),
        status: STAGE_STATUS_TYPES.WAITING,
        title: `Elimination ${eliminationRequest[ELIMINATION_REQUEST_KEYS.EQUIPMENT][EQUIPMENT_KEYS.CODE]}`,
        description: eliminationRequest[ELIMINATION_REQUEST_KEYS.PERFORM_TYPE],
        eliminationType: eliminationRequest[ELIMINATION_REQUEST_KEYS.PERFORM_TYPE],
        order: index,
        afterSuccess,
    }));

    useEffect(() => {
        executeStages({
            stages: eliminationStages,
            executeAfter,
            updateStage,
            setStages,
            beginStageProgress,
            endStageProgress,
            processStages: executeParallel,
        });
    }, []);

    return (
        <StageProgress
            stages={stages}
            applicationData={applicationData}
            constants={constants}
            source={STAGES_PROGRESS_SOURCE.RW_MANAGEMENT}
            getTranslation={getTranslation}
            history={history}
        />
    );
};

const ELIMINATION_STATUS = {
    LOADING: 'LOADING',
    LOADING_ERROR: 'LOADING_ERROR',
    EDIT: 'EDIT',
    PROCESSING: 'PROCESSING',
    FINISHED: 'FINISHED',
};

const isContainerContentOnly = (equipment) =>
    equipment &&
    RWProcessingTools.isContainer(equipment) &&
    equipment[ELIMINATION_REQUEST_KEYS.PERFORM_TYPE] === PERFORM_TYPES.CONTENT_ONLY;

const isDefinedInParent = (equipment, equipmentMap) => {
    const parentEquipment = getParentEquipment(equipment, equipmentMap);
    return (
        !!parentEquipment &&
        RWProcessingTools.isContainer(parentEquipment) &&
        parentEquipment[ELIMINATION_REQUEST_KEYS.PERFORM_TYPE] === PERFORM_TYPES.CONTAINER_AND_CONTENT
    );
};

const generateFields = ({
    assetFields,
    applicationData,
    wasteLocations,
    rwpDropdowns,
    equipmentMap,
    equipmentStatusesMap,
}) => [
    {
        code: EQUIPMENT_KEYS.CODE,
        type: TABLE_DATA_TYPES.INPUT,
        translationKey: EQUIPMENT_KEYS.CODE,
        getAttribute: () => ATTRIBUTES.READONLY,
        customInputProps: () => ({
            style: { width: 220 },
        }),
    },
    {
        code: ELIMINATION_REQUEST_KEYS.PERFORM_TYPE,
        type: TABLE_DATA_TYPES.SELECT,
        translationKey: ELIMINATION_REQUEST_KEYS.PERFORM_TYPE,
        getAttribute: (equipment) =>
            RWProcessingTools.isContainer(equipment) && !getParentEquipment(equipment, equipmentMap)
                ? ATTRIBUTES.REQUIRED
                : ATTRIBUTES.READONLY,
        values: Object.values(PERFORM_TYPES).map((e) => ({ code: e, desc: e })),
        customInputProps: () => ({
            style: { width: 220 },
        }),
    },
    {
        code: EQUIPMENT_KEYS.ELIMINATION_PERFORMED_BY,
        type: TABLE_DATA_TYPES.AUTOCOMPLETE,
        translationKey: EQUIPMENT_KEYS.ELIMINATION_PERFORMED_BY,
        descKey: '_eliminationPerformedByDesc',
        autocompleteType: WSAutocomplete.autocompleteEmployee,
        customValue: (equipment, field) =>
            isDefinedInParent(equipment, equipmentMap)
                ? getParentEquipment(equipment, equipmentMap)[field.code]
                : isContainerContentOnly(equipment)
                ? ''
                : equipment[field.code],
        getAttribute: (equipment) =>
            isDefinedInParent(equipment, equipmentMap) || isContainerContentOnly(equipment)
                ? ATTRIBUTES.READONLY
                : ATTRIBUTES.REQUIRED,
        customInputProps: () => ({
            style: { width: 250 },
            autoSelectSingleElement: true,
        }),
    },
    {
        code: EQUIPMENT_KEYS.RW_ELIMINATION_DATE,
        type: TABLE_DATA_TYPES.DATE,
        translationKey: EQUIPMENT_KEYS.RW_ELIMINATION_DATE,
        customValue: (equipment, field) =>
            isDefinedInParent(equipment, equipmentMap)
                ? getParentEquipment(equipment, equipmentMap)[field.code]
                : isContainerContentOnly(equipment)
                ? ''
                : equipment[field.code],
        getAttribute: (equipment) =>
            isDefinedInParent(equipment, equipmentMap) || isContainerContentOnly(equipment)
                ? ATTRIBUTES.READONLY
                : ATTRIBUTES.REQUIRED,
        customInputProps: () => ({
            style: { width: 100 },
            timestamp: true,
        }),
        validate: (date) => !isFuture(date),
    },
    {
        code: EQUIPMENT_KEYS.ELIMINATION_PROCESS,
        type: TABLE_DATA_TYPES.SELECT,
        translationKey: TRANSLATION_KEYS.ELIMINATION_PROCESS,
        customValue: (equipment, field) =>
            isDefinedInParent(equipment, equipmentMap)
                ? getParentEquipment(equipment, equipmentMap)[field.code]
                : isContainerContentOnly(equipment)
                ? ''
                : equipment[field.code],
        getAttribute: (equipment) =>
            isDefinedInParent(equipment, equipmentMap) || isContainerContentOnly(equipment)
                ? ATTRIBUTES.READONLY
                : ATTRIBUTES.REQUIRED,
        values: rwpDropdowns[EQUIPMENT_KEYS.ELIMINATION_PROCESS],
        customInputProps: () => ({
            style: { minWidth: 110 },
        }),
    },
    {
        code: EQUIPMENT_KEYS.LOCATION,
        type: TABLE_DATA_TYPES.LOOKUP_SELECT,
        translationKey: EQUIPMENT_KEYS.LOCATION,
        keysMap: (field) => ({
            code: 'equipmentno',
            mapCodeTo: field.code,
        }),
        elementInfo: assetFields.wasteLocation,
        gridId: applicationData.allLocationsGridID,
        customValue: (equipment, field) =>
            isDefinedInParent(equipment, equipmentMap)
                ? getParentEquipment(equipment, equipmentMap)[field.code]
                : isContainerContentOnly(equipment)
                ? ''
                : equipment[field.code],
        getAttribute: (equipment) =>
            isDefinedInParent(equipment, equipmentMap) || isContainerContentOnly(equipment)
                ? ATTRIBUTES.READONLY
                : ATTRIBUTES.REQUIRED,
        values: (object) => {
            const indexedLocations = Tools.arrayToObject(wasteLocations, 'code');
            const objectLocationCode = object[EQUIPMENT_KEYS.LOCATION];
            indexedLocations[objectLocationCode] = indexedLocations[objectLocationCode] || {
                code: objectLocationCode,
                desc: objectLocationCode, // We should actually have a description for it, but right now this is the working behavior
            };
            return Object.values(indexedLocations);
        },
        customInputProps: () => ({
            select: {
                creatable: true,
                arrowRenderer: () => <span />,
                style: { width: 110 },
            },
        }),
    },
    {
        code: EQUIPMENT_KEYS.SERIALNO,
        type: TABLE_DATA_TYPES.INPUT,
        translationKey: EQUIPMENT_KEYS.SERIALNO,
        getAttribute: (equipment) =>
            isDefinedInParent(equipment, equipmentMap) || isContainerContentOnly(equipment)
                ? ATTRIBUTES.READONLY
                : ATTRIBUTES.OPTIONAL,
        customInputProps: () => ({
            style: { width: 140 },
        }),
    },
    {
        code: EQUIPMENT_KEYS.RESPONSIBLE,
        type: TABLE_DATA_TYPES.AUTOCOMPLETE,
        translationKey: TRANSLATION_KEYS.RESPONSIBLE,
        descKey: '_respDesc',
        autocompleteType: WSAutocomplete.autocompleteEmployee,
        getAttribute: (equipment) =>
            isDefinedInParent(equipment, equipmentMap) || isContainerContentOnly(equipment)
                ? ATTRIBUTES.READONLY
                : ATTRIBUTES.OPTIONAL,
        customInputProps: () => ({
            style: { width: 250 },
            autoSelectSingleElement: true,
        }),
    },
    {
        code: EQUIPMENT_KEYS.DEPARTMENT,
        type: TABLE_DATA_TYPES.AUTOCOMPLETE,
        translationKey: EQUIPMENT_KEYS.DEPARTMENT,
        autocompleteType: WSAutocomplete.autocompleteDepartment,
        getAttribute: () => ATTRIBUTES.OPTIONAL,
        customInputProps: () => ({
            style: { width: 250 },
            autoSelectSingleElement: true,
        }),
    },
    {
        code: EQUIPMENT_KEYS.USER_STATUS,
        type: TABLE_DATA_TYPES.SELECT,
        translationKey: TRANSLATION_KEYS.USER_STATUS,
        values: (object) => equipmentStatusesMap[object[EQUIPMENT_KEYS.CODE]],
        getAttribute: (object) =>
            equipmentStatusesMap[object[EQUIPMENT_KEYS.CODE]] ? ATTRIBUTES.REQUIRED : ATTRIBUTES.HIDDEN,
        customInputProps: () => ({
            style: { width: 250 },
            autoSelectSingleElement: true,
        }),
    },
];

const EliminationPage = ({
    eliminationStatus,
    setEliminationStatus,
    equipmentToProcess,
    setEquipmentToProcess,
    equipmentMap,
    setEquipmentMap,
    equipmentContent,
    applicationData,
    constants,
    history,
    contentLoading,
    getTranslation,
    eliminationFields,
    goToNextStep,
    setEliminated,
    eliminated,
    setEmptiedContainers,
    translations,
}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isApplyToAllLines, setApplyToAllLines] = useState(false);

    const validateFormFields = useCallback(() => {
        const valid = Object.values(localFormFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );
        return valid;
    }, [localFormFields]);

    const updateEquipment = (equipmentCode, fieldCode, fields) => (key, value) => {
        let eqList = [equipmentCode];
        const field = fields.find((f) => f.code === fieldCode);
        if (!field) return;
        if (isApplyToAllLines) {
            eqList = Object.keys(equipmentMap).filter(
                (eqCode) =>
                    !field.getAttribute ||
                    [ATTRIBUTES.OPTIONAL, ATTRIBUTES.REQUIRED].includes(field.getAttribute(equipmentMap[eqCode]))
            );
        } else {
            const equipment = equipmentMap[equipmentCode];
            const parentEquipment = getParentEquipment(equipment, equipmentMap);
            if (parentEquipment && isContainerContentOnly(parentEquipment)) {
                eqList = equipmentContent[parentEquipment[EQUIPMENT_KEYS.CODE]];
            }
        }
        setEquipmentMap((prevEquipmentMap) => ({
            ...prevEquipmentMap,
            ...eqList.reduce(
                (acc, eqCode) => ({
                    ...acc,
                    [eqCode]: {
                        ...prevEquipmentMap[eqCode],
                        [key]: value,
                    },
                }),
                {}
            ),
        }));
    };

    const containers = equipmentToProcess
        .map((eqCode) => equipmentMap[eqCode])
        .filter(Boolean)
        .filter((equipment) => isContainer(equipment));
    const singleEquipment = equipmentToProcess
        .map((eqCode) => equipmentMap[eqCode])
        .filter(Boolean)
        .filter((equipment) => !isContainer(equipment));

    const getComponentFields = (equipmentList = []) =>
        equipmentList.filter(Boolean).map((equipment) => {
            const eqCode = equipment[EQUIPMENT_KEYS.CODE];
            return eliminationFields.reduce(
                (acc, field) => ({
                    ...acc,
                    [field.code]: (
                        <div key={field.code}>
                            {InputGenerator.generate({
                                field: {
                                    ...field,
                                    elementInfo: {
                                        ...field.elementInfo,
                                        xpath: `${field.code}${eqCode}`,
                                    },
                                },
                                object: equipment || {},
                                updateObject: updateEquipment(eqCode, field.code, eliminationFields),
                                formFields: localFormFields,
                                hideLabel: true,
                            })}
                        </div>
                    ),
                }),
                {}
            );
        });

    const catchKeyDown = (e) => {
        e.stopPropagation();
    };

    const handleEliminate = () => {
        if (validateFormFields()) {
            setEliminationStatus(ELIMINATION_STATUS.PROCESSING);
        }
    };

    const handleBackToEdit = () => {
        setEliminationStatus(ELIMINATION_STATUS.EDIT);
    };

    return (
        <div onKeyDown={catchKeyDown}>
            {eliminationStatus === ELIMINATION_STATUS.LOADING ? (
                <LinearProgress />
            ) : [ELIMINATION_STATUS.PROCESSING, ELIMINATION_STATUS.FINISHED].includes(eliminationStatus) ? (
                <>
                    {eliminationStatus === ELIMINATION_STATUS.FINISHED && !equipmentToProcess.length ? (
                        <Typography>All equipment have been eliminated.</Typography>
                    ) : null}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                        }}
                    >
                        {eliminationStatus === ELIMINATION_STATUS.FINISHED && equipmentToProcess.length ? (
                            <Button variant="outlined" color="primary" onClick={handleBackToEdit}>
                                {getTranslation(ELIMINATION_TRANSLATION_KEYS.BACK_TO_EDIT)}
                            </Button>
                        ) : null}
                        {eliminationStatus === ELIMINATION_STATUS.FINISHED && eliminated.length ? (
                            <Button variant="outlined" color="primary" onClick={goToNextStep}>
                                {getTranslation(ELIMINATION_TRANSLATION_KEYS.ADD_REPORT)}
                            </Button>
                        ) : null}
                    </div>
                    <EliminationProgress
                        equipmentToProcess={equipmentToProcess}
                        equipmentMap={equipmentMap}
                        equipmentContent={equipmentContent}
                        afterSuccess={({ stage, data }) => {
                            setEquipmentToProcess((prevState) => prevState.filter((e) => e !== stage.id));
                            setEliminated((prevState) => [...prevState, ...Object.keys(data)]);
                            if (stage.eliminationType === PERFORM_TYPES.CONTENT_ONLY) {
                                setEmptiedContainers((prevState) => [...prevState, stage.id]);
                            }
                        }}
                        executeAfter={() => {
                            setEliminationStatus(ELIMINATION_STATUS.FINISHED);
                        }}
                        getTranslation={getTranslation}
                        applicationData={applicationData}
                        constants={constants}
                        history={history}
                    />
                </>
            ) : eliminationStatus === ELIMINATION_STATUS.EDIT ? (
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <EAMCheckbox
                                label={translations.APPLYTOALL}
                                value={isApplyToAllLines}
                                updateProperty={(key, value) => setApplyToAllLines(value)}
                                trueValue
                                falseValue={false}
                            />

                            <Button color="primary" variant="contained" onClick={handleEliminate}>
                                {getTranslation(ELIMINATION_TRANSLATION_KEYS.ELIMINATE)}
                            </Button>
                            {eliminated.length ? (
                                <Button variant="outlined" color="primary" onClick={goToNextStep}>
                                    {getTranslation(ELIMINATION_TRANSLATION_KEYS.ADD_REPORT)}
                                </Button>
                            ) : null}
                        </div>
                    </Grid>
                    <Grid item container xs={12}>
                        {singleEquipment.length ? (
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    {getTranslation(ELIMINATION_TRANSLATION_KEYS.SINGLE_EQUIPMENT)}
                                </Typography>
                                <EISTable
                                    data={getComponentFields(singleEquipment)}
                                    headers={eliminationFields.map((field) => getTranslation(field.translationKey))}
                                    propCodes={eliminationFields.map((field) => field.code)}
                                    style={{ width: 'auto', height: 'auto' }}
                                />
                            </Grid>
                        ) : null}
                        {containers.length
                            ? containers.map((container) => {
                                  const containerCode = container[EQUIPMENT_KEYS.CODE];
                                  const containerContent = equipmentContent[containerCode] || [];
                                  return (
                                      <Grid key={containerCode} item xs={12}>
                                          <Typography variant="h6">{`${getTranslation(
                                              ELIMINATION_TRANSLATION_KEYS.CONTAINER_EQUIPMENT
                                          )}: ${containerCode}`}</Typography>
                                          <EISTable
                                              data={getComponentFields([container])}
                                              headers={eliminationFields.map((field) =>
                                                  getTranslation(field.translationKey)
                                              )}
                                              propCodes={eliminationFields.map((field) => field.code)}
                                              style={{ width: 'auto', height: 'auto' }}
                                          />
                                          {contentLoading.includes(containerCode) ? (
                                              <LinearProgress />
                                          ) : containerContent.length ? (
                                              <div
                                                  style={{
                                                      background: '#f2f2f2',
                                                      padding: '1em',
                                                      display: 'table',
                                                      marginLeft: '2em',
                                                  }}
                                              >
                                                  <EISTable
                                                      data={getComponentFields(
                                                          containerContent.map((eqCode) => equipmentMap[eqCode])
                                                      )}
                                                      headers={eliminationFields.map((field) =>
                                                          getTranslation(field.translationKey)
                                                      )}
                                                      propCodes={eliminationFields.map((field) => field.code)}
                                                      style={{ width: 'auto', height: 'auto' }}
                                                  />
                                              </div>
                                          ) : null}
                                      </Grid>
                                  );
                              })
                            : null}
                    </Grid>
                </Grid>
            ) : null}
        </div>
    );
};

const DocumentUploadPage = ({
    applicationData,
    getTranslation,
    eliminated,
    equipmentMap,
    eliminationFields,
    equipmentContent,
    canGoNext,
    goToNextStep,
}) => {
    const containers = eliminated
        .map((eqCode) => equipmentMap[eqCode])
        .filter(Boolean)
        .filter((equipment) => isContainer(equipment));

    const allEquipment = eliminated
        .map((eqCode) => equipmentMap[eqCode])
        .concat(
            containers.flatMap((container) =>
                (equipmentContent[container[EQUIPMENT_KEYS.CODE]] || []).map((eqCode) => equipmentMap[eqCode])
            )
        );

    const headers = eliminationFields
        .filter((val) => ['code', 'serialno', 'location'].includes(val.code))
        .map((val) => val.code);

    return (
        <div style={{ height: '95%' }}>
            <EDMSMultilinkUpload
                objects={allEquipment}
                columnHeadings={headers}
                context={applicationData.edmsContexts?.RWMANAGEMENT_EDMS_CONTEXT}
                profile={applicationData.rwEliminationEdmsProfile}
                getTranslation={getTranslation}
                edmsdocListLink={applicationData.edmsdocListLink}
            />
            {canGoNext && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="outlined" color="primary" onClick={goToNextStep}>
                        {getTranslation(ELIMINATION_TRANSLATION_KEYS.CREATE_RPM_REQUEST)}
                    </Button>
                </div>
            )}
        </div>
    );
};

const RWManagementElimination = (props) => {
    const {
        applicationData,
        selectedEquipment,
        translations,
        getTranslation,
        assetFields,
        wasteLocations,
        rwpDropdowns,
        history,
        constants,
    } = props;
    const [equipmentMap, setEquipmentMap] = useState({});
    const [permEquipmentMap, setPermEquipmentMap] = useState({});
    const [equipmentContent, setEquipmentContent] = useState({});
    const [contentLoading, setContentLoading] = useState([]);
    const [eliminated, setEliminated] = useState([]);
    const [emptiedContainers, setEmptiedContainers] = useState([]);
    const [eliminationStatus, setEliminationStatus] = useState(ELIMINATION_STATUS.LOADING);
    const [equipmentToProcess, setEquipmentToProcess] = useState(selectedEquipment);
    const [equipmentStatusesMap, setEquipmentStatusesMap] = useState({});
    const [stepIndex, setStepIndex] = useState(0);
    const [eliminationDialogOpen, setEliminationDialogOpen] = useState(false);
    const [documentDialogOpen, setDocumentDialogOpen] = useState(false);

    const handleOpenEliminationDialog = useCallback(() => {
        setEliminationDialogOpen(true);
    }, []);

    const handleCloseEliminationDialog = useCallback(() => {
        setEliminationDialogOpen(false);
    }, []);

    const handleOpenDocumentDialog = useCallback(() => {
        setDocumentDialogOpen(true);
    }, []);

    const handleCloseDocumentDialog = useCallback(() => {
        setDocumentDialogOpen(false);
    }, []);

    const fetchStatuses = async (equipment) => {
        const equipmentCode = equipment[EQUIPMENT_KEYS.CODE];
        if ((equipmentCode && equipmentStatusesMap[equipmentCode]) || !(EQUIPMENT_KEYS.USER_STATUS in equipment)) {
            return;
        }
        try {
            const response = await WSEquipment.getStatuses({ previousStatus: equipment[EQUIPMENT_KEYS.USER_STATUS] });
            const statuses = response.body.data;
            setEquipmentStatusesMap((prevState) => ({
                ...prevState,
                [equipmentCode]: statuses,
            }));
        } catch (e) {
            // TODO, handle error
        }
    };

    const goToNextStep = () => setStepIndex((prevState) => prevState + 1);

    useEffect(() => setStepIndex(0), [eliminationDialogOpen]);

    useEffect(() => {
        setEquipmentToProcess(selectedEquipment);
        setEliminated([]);
        setPermEquipmentMap({});
        setEliminationStatus(ELIMINATION_STATUS.LOADING);

        Promise.all(
            selectedEquipment.map((code) =>
                WSRWPProcessing.getEquipment({
                    equipmentCode: code,
                    applicationData,
                })
            )
        )
            .then((responses) => {
                const equipmentResponseMap = responses
                    .map((response) => response.body && response.body.data)
                    .filter(Boolean)
                    .map(applyDefaults)
                    .reduce((acc, response) => ({ ...acc, [response[EQUIPMENT_KEYS.CODE]]: response }), []);
                Promise.all(Object.values(equipmentResponseMap).map(fetchStatuses));

                const contentPromises = Object.values(equipmentResponseMap)
                    .filter((equipment) => isContainer(equipment))
                    .map(async (equipment) => {
                        const equipmentCode = equipment[EQUIPMENT_KEYS.CODE];
                        setContentLoading((prevState) => [...prevState, equipmentCode]);
                        try {
                            const response = await WSElimination.getContent({
                                containerCode: equipment[EQUIPMENT_KEYS.CODE],
                            });
                            const content = response.body.data;
                            setEquipmentContent((prevState) => ({
                                ...prevState,
                                [equipmentCode]: content.map((e) => e[EQUIPMENT_KEYS.CODE]),
                            }));
                            const contentWithDefaults = content.map(applyDefaults);

                            setEquipmentMap((prevState) => ({
                                ...prevState,
                                ...contentWithDefaults.reduce(
                                    (acc, contentEqp) => ({
                                        ...acc,
                                        [contentEqp[EQUIPMENT_KEYS.CODE]]: contentEqp,
                                    }),
                                    {}
                                ),
                            }));
                            Promise.all(contentWithDefaults.map(fetchStatuses));
                        } catch (e) {
                            // TODO, handle error
                        } finally {
                            setContentLoading((prevState) => prevState.filter((e) => e !== equipmentCode));
                        }
                    });
                setEquipmentMap(equipmentResponseMap);
                Promise.all(contentPromises);
            })
            .catch((error) => {
                // TODO handle error
                setEliminationStatus(ELIMINATION_STATUS.LOADING_ERROR);
                console.error(error);
            })
            .finally(() => setEliminationStatus(ELIMINATION_STATUS.EDIT));
    }, [selectedEquipment]);

    useEffect(() => {
        Object.entries(equipmentMap).forEach(([key, value]) => {
            if (!(key in permEquipmentMap)) {
                setPermEquipmentMap({ ...permEquipmentMap, [key]: value });
            }
        });
    }, [equipmentMap]);

    const containers = selectedEquipment
        .map((eqCode) => equipmentMap[eqCode])
        .filter(Boolean)
        .filter((equipment) => isContainer(equipment));

    const eliminationFields = useMemo(
        () =>
            generateFields({
                assetFields,
                applicationData,
                wasteLocations,
                rwpDropdowns,
                equipmentMap,
                equipmentStatusesMap,
            }),
        [assetFields, applicationData, wasteLocations, rwpDropdowns, equipmentMap, equipmentStatusesMap]
    );

    const steps = [
        <EliminationPage
            {...{
                eliminationStatus,
                setEliminationStatus,
                equipmentToProcess,
                setEquipmentToProcess,
                equipmentMap,
                setEquipmentMap,
                equipmentContent,
                contentLoading,
                equipmentStatusesMap,
                eliminationFields,
                goToNextStep,
                history,
                setEliminated,
                eliminated,
                setEmptiedContainers,
                emptiedContainers,
            }}
            {...props}
        />,
        <DocumentUploadPage
            {...props}
            {...{
                eliminated,
                equipmentMap: permEquipmentMap,
                equipmentContent,
                eliminationFields,
                canGoNext: !!emptiedContainers.length,
                goToNextStep,
            }}
        />,
        ...[
            emptiedContainers.length ? (
                <EliminationRPMRequest
                    getTranslation={getTranslation}
                    codes={emptiedContainers}
                    history={history}
                    constants={constants}
                    rpSection={applicationData.rpsection}
                />
            ) : (
                []
            ),
        ],
    ];

    return (
        <>
            <TRECButton onClick={handleOpenEliminationDialog}>{translations.ELIMINATION}</TRECButton>
            <TRECButton onClick={handleOpenDocumentDialog}>{translations.ADD_DOCUMENTS}</TRECButton>
            <RWManagementEliminationDialog
                open={eliminationDialogOpen}
                getTranslation={getTranslation}
                onClose={handleCloseEliminationDialog}
            >
                {steps[stepIndex]}
            </RWManagementEliminationDialog>

            <Dialog fullWidth maxWidth="xl" PaperProps={{ style: { height: '100%' } }} open={documentDialogOpen}>
                <DialogContent>
                    <EDMSMultilinkUpload
                        objects={selectedEquipment
                            .map((eqCode) => equipmentMap[eqCode])
                            .concat(
                                containers.flatMap((container) =>
                                    (equipmentContent[container[EQUIPMENT_KEYS.CODE]] || []).map(
                                        (eqCode) => equipmentMap[eqCode]
                                    )
                                )
                            )}
                        context={applicationData.edmsContexts?.RWMANAGEMENT_EDMS_CONTEXT}
                        profile={applicationData.rwEliminationEdmsProfile}
                        columnHeadings={eliminationFields
                            .filter((val) => ['code', 'serialno', 'location'].includes(val.code))
                            .map((val) => val.code)}
                        getTranslation={getTranslation}
                        edmsdocListLink={applicationData.edmsdocListLink}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDocumentDialog}>CLOSE</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RWManagementElimination;
