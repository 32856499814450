import {
    ELIMINATION_REQUEST_KEYS,
    EQUIPMENT_KEYS_DTO,
    ELIMINATION_REQUEST_KEYS_DTO,
} from '../../ui/pages/rwmanagement/EliminationConstants';
import Tools from '../Tools';
import WS from './WS';

const eliminationsAdapter = (eliminationRequest) => ({
    ...Tools.filterObjectFieldsFromList(eliminationRequest, ELIMINATION_REQUEST_KEYS_DTO),
    [ELIMINATION_REQUEST_KEYS.EQUIPMENT]: Tools.filterObjectFieldsFromList(
        eliminationRequest[ELIMINATION_REQUEST_KEYS.EQUIPMENT],
        EQUIPMENT_KEYS_DTO
    ),
    [ELIMINATION_REQUEST_KEYS.CONTENT]: Tools.applyToFields(
        eliminationRequest[ELIMINATION_REQUEST_KEYS.CONTENT],
        (equipment) => Tools.filterObjectFieldsFromList(equipment, EQUIPMENT_KEYS_DTO)
    ),
});

class WSEliminations {
    eliminate = ({ eliminationRequest }, config) =>
        WS._post(`/eliminations`, eliminationsAdapter(eliminationRequest), config);

    getContent = ({ containerCode }, config) => WS._get(`/eliminations/containers/${containerCode}/content`, config);
}

export default new WSEliminations();
