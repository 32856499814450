import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const styles = () => ({
    '@media ( min-width : 865px)': {
        sizeLarge: {
            minWidth: '130px',
            minHeight: '55px',
            margin: '1px 3px 1px 3px',
        },

        root: {
            boxShadow: '0 3px 5px 2px rgba(128, 128, 128, .20)',
            fontSize: 18,
        },
    },
    '@media ( max-width : 864px)': {
        sizeLarge: {
            margin: '1px 2px 1px 2px',
        },

        root: {
            boxShadow: '0 3px 5px 2px rgba(128, 128, 128, .20)',
            fontSize: 15,
        },
    },
});

function TRECButton(props) {
    const { classes, color, size, variant, onClick, ...other } = props;

    return (
        <Button
            size={size}
            color={color}
            variant={variant}
            onClick={onClick}
            classes={{
                root: classes.root,
                sizeLarge: classes.sizeLarge,
            }}
            {...other}
        />
    );
}

TRECButton.defaultProps = {
    color: 'primary',
    size: 'large',
    variant: 'contained',
};

export default withStyles(styles)(TRECButton);
