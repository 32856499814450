import WS from './WS';

/**
 * Rest services for the autocomplete component
 */
class WSAutocomplete {
    autocompleteEmployee = (code, config = {}) => {
        const encodedCode = encodeURIComponent(code);
        return WS._get(`/autocomplete/employee/${encodedCode}`, config);
    };

    autocompleteEquipment = (code, config = {}) => WS._get(`/autocomplete/equipment/?code=${code}`, config);

    autocompleteTRECLocation = (code, config = {}) => WS._get(`/autocomplete/treclocation/?code=${code}`, config);

    newAutocompleteTRECLocation = async ({ hint }, config = {}) => {
        const result = await this.autocompleteTRECLocation(hint, config);
        return result.body.data;
    };

    autocompleteLocation = (code, config = {}) => WS._get(`/autocomplete/location/?code=${code}`, config);

    autocompleteWasteEquipment = (code, config = {}) => WS._get(`/autocomplete/wasteequipment/?code=${code}`, config);

    autocompleteWasteLocation = (code, config = {}) => WS._get(`/autocomplete/wastelocation/?code=${code}`, config);

    autocompleteContainer = (code, config = {}) => WS._get(`/autocomplete/container/?code=${code}`, config);

    autocompleteAllEquipment = (code, config = {}) => WS._get(`/autocomplete/equipmentall/?code=${code}`, config);

    autocompleteDepartment = (code, config = {}) => WS._get(`/autocomplete/department/${code}`, config);

    autocompleteVacuums = async ({ code, hint, reservationCode }, config = {}) => {
        const result = !reservationCode
            ? await WS._get(`/vaccleaners/requests/${code}/vacuums/?hint=${hint}`, config)
            : await WS._get(
                  `/vaccleaners/requests/${code}/reservations/${reservationCode}/vacuums/?hint=${hint}`,
                  config
              );
        return result.body.data;
    };
}

export default new WSAutocomplete();
