import React, { useEffect } from 'react';
import { generateRoute, ROUTES } from '../../../../tools/Routes';
import EquipmentTreeContainer from '../../eqtree/EquipmentTreeContainer';
import { INFORMATION_SOURCE } from '../../eqtree/Constants';

const EquipmentHierarchy = (props) => {
    const { equipmentID } = props;

    useEffect(() => {
        const handler = (event) => {
            if (event.data && event.data.nodes?.length) {
                try {
                    const data = JSON.parse(event.data);
                    if (data.type === 'EQUIPMENT_TREE_NODE_CLICK') {
                        const nodeID = data.node.id;
                        const url = generateRoute(ROUTES.equipmentShow, { equipmentID: nodeID });
                        window.open(url, '_blank');
                    }
                } catch (e) {
                    console.error('Error parsing message event data', e);
                }
            }
        };
        window.addEventListener('message', handler);
        return () => window.removeEventListener('message', handler);
    }, []);

    return (
        // <iframe style={{ width: "100%", height: "100%", border: 0 }} src={`${applicationData.eamlightLink}eqptree?eqpCode=${equipmentID}`} frameBorder="0"/>
        // TODO, change src to eam-toolbox equipment tree (pass equipment code)
        <EquipmentTreeContainer equipmentCode={equipmentID} src={INFORMATION_SOURCE.EQUIPMENT_STRUCTURE} height={700} />
    );
};

export default EquipmentHierarchy;
