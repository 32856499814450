import { Grid, InputAdornment, TextField } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { WizardStep } from 'amm-tools';
import { useEffect, useRef, useState } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import {
    VACUUM_RENTAL_KEYS,
    TRANSLATION_KEYS,
    VACUUM_RENTAL_LINE_KEYS,
} from 'ui/pages/vaccleanerrequest/VacCleanerConstants';
import WSVacRental from 'tools/rest/WSVacRental';
import AccessoryDetails from '../components/AccessoryDetails';
import VacuumCart from '../components/VacuumCart';
import VacuumTiles from '../components/VacuumTiles';
import { showError } from 'tools/TrecNotifications';

const styles = {
    gridContainer: {
        padding: '0.1em',
        width: '95%',
        margin: 'auto',
    },
    flexSection: {
        flexGrow: '1',
        display: 'flex',
        minHeight: '0',
    },
    flexColScroll: {
        flexGrow: 1,
        overflow: 'auto',
        minHeight: '100%',
    },
    section: {
        height: '100%',
    },
    searchField: {
        left: 20,
        right: 20,
        position: 'absolute',
        backgroundColor: 'white',
        TRANSLATION_KEYS,
    },
    panelStyle: {
        padding: 5,
        overflowY: 'auto',
        height: '60vh',
    },
    detailsStyle: {
        display: 'flex',
        flexDirection: 'column',
        padding: 6,
    },
};

const AccessorySelection = ({
    storeActions,
    vacuumRequest,
    getTranslation,
    getVacCount,
    selectedVacuum,
    setSelectedVacuum,
    vacuumsData,
    writeAccess,
}) => {
    const handleSelectVacuum = (v) => (writeAccess ? setSelectedVacuum(v) : () => 1);

    const [searchHint, setSearchHint] = useState('');
    const [accessoryData, setAccessoryData] = useState([]);
    const [filteredAccessoryData, setFilteredAccessoryData] = useState([]);
    const [clickedVacuum, setClickedVacuum] = useState();

    useEffect(() => {
        WSVacRental.getVacAccessories().then((response) => setAccessoryData(response.body.data));
    }, []);
    useEffect(() => {
        setFilteredAccessoryData(
            accessoryData
                .filter((acc) => acc.partCode.toUpperCase().startsWith(searchHint.toUpperCase()))
                .filter(
                    (acc) =>
                        !clickedVacuum ||
                        !clickedVacuum[VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES]
                            .map((s) => s.partCode)
                            .includes(acc.partCode)
                )
                .filter((acce) =>
                    vacuumsData
                        ?.filter((vd) => !clickedVacuum || vd.partCode === clickedVacuum.vacTypeCode)
                        .some((vac) => vac?.vacCleanerAccessoryList.some((acc) => acc.partCode === acce.partCode))
                )
        );
    }, [accessoryData, searchHint, clickedVacuum]);

    const portalRef = useRef();

    return (
        <div style={{ margin: 8 }} ref={portalRef}>
            <Grid container spacing={8} style={styles.flexSection}>
                <Grid item md={2} xs={12} style={styles.flexColScroll}>
                    <VacuumCart
                        cartContents={vacuumRequest[VACUUM_RENTAL_KEYS.VACUUMS]}
                        onRemove={writeAccess ? storeActions.removeVacuum : () => 1}
                        onAccessoryRemove={writeAccess ? storeActions.removeAccessory : () => 1}
                        onAccessoryQuantityChange={writeAccess ? storeActions.setAccessoryQuantity : () => 1}
                        styles={styles}
                        vacuumRequest={vacuumRequest}
                        getVacCount={getVacCount}
                        getTranslation={getTranslation}
                        accessoryData={accessoryData}
                        vacuumsData={vacuumsData}
                        onClick={(vacc) => setClickedVacuum(vacc)}
                        clickedVacuum={clickedVacuum}
                    />
                </Grid>
                <Grid item md={selectedVacuum ? 7 : 10} xs={12} style={styles.flexColScroll}>
                    <EISPanel
                        detailsStyle={styles.detailsStyle}
                        heading={getTranslation(TRANSLATION_KEYS.ACCESSORIES)}
                        panelStyle={{ position: 'relative' }}
                        alwaysExpanded
                    >
                        <TextField
                            placeholder="Search for accessories..."
                            color="primary"
                            onChange={(e) => setSearchHint(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <FilterListIcon color="secondary" />
                                    </InputAdornment>
                                ),
                            }}
                            style={styles.searchField}
                        />
                        <div style={styles.panelStyle}>
                            {clickedVacuum && (
                                <VacuumTiles
                                    useAccessoryTile
                                    options={filteredAccessoryData}
                                    isVacuumSelected={!!selectedVacuum}
                                    selectOption={handleSelectVacuum}
                                />
                            )}
                        </div>
                    </EISPanel>
                </Grid>
                {selectedVacuum && (
                    <Grid item md={3} xs={12} style={styles.flexColScroll}>
                        <AccessoryDetails
                            styles={styles}
                            onClose={() => handleSelectVacuum(undefined)}
                            onAdd={writeAccess ? storeActions.addAccessory : () => 1}
                            focusedObject={selectedVacuum}
                            vacuums={vacuumRequest[VACUUM_RENTAL_KEYS.VACUUMS]}
                            menuPortalTarget={portalRef}
                            getVacCount={getVacCount}
                            vacuumsData={vacuumsData}
                            getTranslation={getTranslation}
                            defaultVacuum={clickedVacuum}
                        />
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

class VacCleanerAccessoryStep extends WizardStep {
    formFields = {};

    state = {
        selectedVacuum: null,
    };

    componentDidMount() {
        super.componentDidMount();
    }

    /**
     *  CommitChanges is used to trigger any side effects of the step, such as invocations to the back-end to
     * retrieve information about resources. This method can run asynchronous methods/requests but SHALL execute
     * the callback method afterwards to inform the wizard.
     */
    commitChanges = async (callback) => {
        callback();
    };

    /**
     * On true, the wizard automatically jumps this step immediately after it is mounted
     */
    canJumpStep = () => false;

    /**
     * SaveChanges is used to save the localized state to the redux-store. It can only run synchronous methods.
     */
    saveChanges = () => true;

    handleSubmit = () => {
        this.canContinue();
    };

    handleSave = () => {
        this.canContinue();
    };

    /**
     * Can continue to the next step on the wizard
     */
    canContinue = () => {
        const { getTranslation } = this.props;
        const { selectedVacuum } = this.state;
        if (selectedVacuum) {
            showError(getTranslation(TRANSLATION_KEYS.PENDING_VACUUM_SELECTION));
            return false;
        }
        return true;
    };

    render() {
        const { selectedVacuum } = this.state;
        return (
            <AccessorySelection
                {...this.props}
                selectedVacuum={selectedVacuum}
                setSelectedVacuum={(vacuum) => this.setState({ selectedVacuum: vacuum })}
            />
        );
    }
}

export default VacCleanerAccessoryStep;
