import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AssetIcon } from 'eam-components/dist/ui/components/icons/index';
import { TREC_GENERAL_KEYS } from 'enums/Constants';
import { useEffect, useMemo, useState } from 'react';
import WSEquipment from 'tools/rest/WSEquipment';
import { ROUTES, withQueryParams } from 'tools/Routes';
import { INFORMATION_SOURCE } from '../../eqtree/Constants';
import EquipmentTreeContainer from '../../eqtree/EquipmentTreeContainer';
import { REQUEST_SOURCES } from '../../rpmrequest2/RPMRConstants';
import { isContainer } from '../RWProcessingTools';
import StageProgress, { executeParallel, STAGE_STATUS_TYPES } from '../StageProgress/StageProgress';
import { useStageProgress } from '../StageProgress/useStageProgress';

const iconStyle = { width: 45, height: 45, padding: '0.4rem', alignSelf: 'center' };

const dimensionsRenderer = ({ key, e, node, getTranslation, applyCorrections }) => {
    const { corrections } = e;
    const beforeCorrections = corrections.before;
    const afterCorrections = corrections.after;
    return afterCorrections[node.id][key] === null ? null : (
        <span>
            <span style={{ fontWeight: '400' }}>{getTranslation(key)}: </span>
            {applyCorrections && beforeCorrections[node.id][key] !== afterCorrections[node.id][key] ? (
                <>
                    <span
                        style={{
                            textDecoration: 'line-through',
                            color: 'red',
                        }}
                    >
                        {beforeCorrections[node.id][key]}
                    </span>
                    <span>{' -> '}</span>
                    <span style={{ color: 'green' }}>
                        {afterCorrections[node.id][key]
                            ? Math.round(+afterCorrections[node.id][key] * 100) / 100
                            : 'null'}
                    </span>
                    <span> </span>
                </>
            ) : (
                <span style={{ color: 'black' }}>{beforeCorrections[node.id][key]}</span>
            )}
        </span>
    );
};

const executeStages = ({
    stages,
    executeAfter,
    updateStage,
    setStages,
    beginStageProgress,
    endStageProgress,
    processStages,
}) => {
    const computedStages = stages.reduce(
        (acc, stage, index) => ({
            ...acc,
            [stage.id]: {
                mapPreviousResult: (previousResultData) => previousResultData,
                ...stage,
                order: index,
            },
        }),
        {}
    );

    setStages(computedStages);
    beginStageProgress();
    processStages({ stages: computedStages, updateStage })
        .catch((error) => {
            // TODO, handle this
            console.error(error);
        })
        .finally(() => {
            executeAfter && executeAfter({ stages });
            endStageProgress();
        });
};

const breakUpPromise =
    ({ code, retrieveSecondLevelMap, successMessage, addRetrievedEquipment }) =>
    async () =>
        retrieveSecondLevelMap[code]
            ? WSEquipment.breakUp({
                  equipmentID: code,
                  maxLevel: 2,
                  retrieveSecondLevel: retrieveSecondLevelMap[code],
              }).then((result) => {
                  addRetrievedEquipment(result.body.data.retrievedEquipment, code);
                  return {
                      successMessage,
                  };
              })
            : Promise.resolve({ successMessage: 'Done.' });

const BreakUpProgress = (props) => {
    const {
        equipmentToProcess,
        retrieveSecondLevelMap,
        applicationData,
        constants,
        getTranslation,
        afterSuccess,
        executeAfter,
        addRetrievedEquipment,
    } = props;
    const { stages, setStages, updateStage, beginStageProgress, endStageProgress } = useStageProgress();

    const breakUpStages = equipmentToProcess
        .filter((code) => retrieveSecondLevelMap[code])
        .map((code, index) => ({
            id: code,
            execute: breakUpPromise({
                code,
                retrieveSecondLevelMap,
                successMessage: 'Successfully broken up',
                addRetrievedEquipment,
            }),
            status: STAGE_STATUS_TYPES.WAITING,
            title: `Break Up for ${code}`,
            description: 'Performing weight and dimensions correction and applying retrieve 2nd level if requested',
            order: index,
            afterSuccess,
        }));

    useEffect(() => {
        executeStages({
            stages: breakUpStages,
            executeAfter,
            updateStage,
            setStages,
            beginStageProgress,
            endStageProgress,
            processStages: executeParallel,
        });
    }, []);

    return (
        <StageProgress
            stages={stages}
            applicationData={applicationData}
            constants={constants}
            source="RWP_BREAK_UP"
            getTranslation={getTranslation}
        />
    );
};

const RWPBreakUpDialog = (props) => {
    const {
        open,
        onClose,
        needBreakup,
        getTranslation,
        applicationData,
        constants,
        addRetrievedEquipment,
        breakUpPermissions,
        setNeedBreakup,
    } = props;
    const [treated, setTreated] = useState([]);
    const [retrieveSecondLevelMap, setRetrieveSecondLevelMap] = useState({});
    const [expandedPanel, setExpandedPanel] = useState();
    const [breakupInProgress, setBreakUpInProgress] = useState(false);
    const [breakupFinished, setBreakUpFinish] = useState(false);

    useEffect(() => {
        setRetrieveSecondLevelMap(
            Object.keys(needBreakup?.equipmentMap || {}).reduce(
                (acc, e) => ({
                    ...acc,
                    [e]: false,
                }),
                {}
            )
        );
    }, [needBreakup.equipmentMap]);

    useEffect(() => {
        if (!expandedPanel && Object.keys(needBreakup).length === 1) {
            setExpandedPanel(needBreakup?.[0]?.rootEquipmentCode);
        }
    }, [expandedPanel, setExpandedPanel]);

    const equipmentToProcess = useMemo(
        () => needBreakup.map((e) => e.rootEquipmentCode).filter((e) => !treated.includes(e)),
        [needBreakup, treated]
    );

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
            <DialogTitle>Update dimensions and break up</DialogTitle>
            <DialogContent>
                {breakupInProgress ? (
                    <BreakUpProgress
                        addRetrievedEquipment={addRetrievedEquipment}
                        applicationData={applicationData}
                        constants={constants}
                        equipmentToProcess={equipmentToProcess}
                        retrieveSecondLevelMap={retrieveSecondLevelMap}
                        getTranslation={getTranslation}
                        afterSuccess={({ stage }) => {
                            setTreated((prevState) => [...prevState, stage.id]);
                        }}
                        executeAfter={() => {
                            setBreakUpFinish(true);
                        }}
                    />
                ) : (
                    needBreakup.map((e) => (
                        <ExpansionPanel key={e} expanded={expandedPanel === e.rootEquipmentCode}>
                            <ExpansionPanelSummary
                                style={{ backgroundColor: '#f7f7f7' }}
                                expandIcon={<ExpandMoreIcon />}
                                onClick={() => setExpandedPanel(e.rootEquipmentCode)}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography variant="subtitle2">{e.rootEquipmentCode}</Typography>
                                    <FormControlLabel
                                        onClick={(event) => event.stopPropagation()}
                                        control={
                                            <Checkbox
                                                checked={retrieveSecondLevelMap[e.rootEquipmentCode]}
                                                onChange={() => {
                                                    setRetrieveSecondLevelMap({
                                                        ...retrieveSecondLevelMap,
                                                        [e.rootEquipmentCode]:
                                                            !retrieveSecondLevelMap[e.rootEquipmentCode],
                                                    });
                                                }}
                                                color="primary"
                                            />
                                        }
                                        label="Retrieve 2nd Level"
                                    />
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <EquipmentTreeContainer
                                    structure={e.structure}
                                    equipmentCode={e.rootEquipmentCode}
                                    src={INFORMATION_SOURCE.BREAK_UP_CORRECTIONS}
                                    height={700}
                                    extraProps={{ canDrag: false, rowHeight: 105 }}
                                    nodeRenderer={({ node }) => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                height: 'fitContent',
                                                backgroundColor: '#EEEEEE',
                                            }}
                                        >
                                            {isContainer(node.equipmentInfo) ? (
                                                <div style={iconStyle}>
                                                    <i className="fa fa-archive" style={{ fontSize: '30px' }} />
                                                </div>
                                            ) : (
                                                <AssetIcon style={iconStyle} />
                                            )}
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontWeight: '500' }}>{node.id}</span>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div
                                                        style={{
                                                            fontSize: 12,
                                                            fontWeight: 100,
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            marginRight: '6px',
                                                        }}
                                                    >
                                                        {['length', 'width', 'height', 'weight'].map((key) =>
                                                            dimensionsRenderer({
                                                                key,
                                                                e,
                                                                node,
                                                                getTranslation,
                                                                applyCorrections:
                                                                    retrieveSecondLevelMap[e.rootEquipmentCode],
                                                            })
                                                        )}
                                                    </div>

                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <div
                                                            style={{
                                                                fontSize: 12,
                                                                fontWeight: 100,
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                marginRight: '6px',
                                                            }}
                                                        >
                                                            {[
                                                                'internalLength',
                                                                'internalWidth',
                                                                'internalHeight',
                                                                'tareWeight',
                                                                'usefulVolume',
                                                            ].map((key) =>
                                                                dimensionsRenderer({
                                                                    key,
                                                                    e,
                                                                    node,
                                                                    getTranslation,
                                                                    applyCorrections:
                                                                        retrieveSecondLevelMap[e.rootEquipmentCode],
                                                                })
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))
                )}
            </DialogContent>
            <DialogActions>
                {breakupFinished ? (
                    <Button
                        onClick={() => {
                            window.open(
                                withQueryParams({
                                    path: ROUTES.rpmRequest2,
                                    queryParams: {
                                        action: constants.futureActionOther,
                                        equipment: Object.entries(retrieveSecondLevelMap)
                                            .filter(([_, val]) => val)
                                            .map(([key, _]) => key)
                                            .join([',']),
                                        dep: applicationData.rpsection,
                                        requestSource: REQUEST_SOURCES.EMPTY_CONTAINERS,
                                    },
                                }),
                                '_blank'
                            );
                            onClose();
                        }}
                        color="primary"
                    >
                        {getTranslation('EMPTY_CONTAINERS')}
                    </Button>
                ) : breakupInProgress ? null : (
                    <>
                        <Button onClick={onClose} color="primary">
                            {getTranslation(TREC_GENERAL_KEYS.CANCEL)}
                        </Button>
                        {breakUpPermissions?.has('W') && (
                            <Button
                                onClick={() => {
                                    setNeedBreakup(
                                        needBreakup.filter((n) => retrieveSecondLevelMap[n.rootEquipmentCode])
                                    );
                                    if (Object.values(retrieveSecondLevelMap).some((a) => a)) {
                                        setBreakUpInProgress(true);
                                    } else {
                                        onClose();
                                    }
                                }}
                                color="primary"
                                variant="contained"
                            >
                                {getTranslation(TREC_GENERAL_KEYS.CONFIRM)}
                            </Button>
                        )}
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default RWPBreakUpDialog;
