import { Grid } from '@material-ui/core';
import { AMMSingleAutocomplete, WizardStep } from 'amm-tools';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import { handleError, showError, showSuccess } from 'tools/TrecNotifications';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import WSRPSampling from 'tools/rest/WSRPSampling';
import WSWaterRelease from 'tools/rest/WSWaterRelease';
import EquipmentOption from 'ui/components/autocomplete/EquipmentOption';
import { TRANSLATION_KEYS, WATER_RELEASE_KEYS, WRR_STATUS } from '../WRRConstants';
import { WRRAssociatedWOsInfoPanel, WarningText, generateWRRFields, getWRRAllFields } from '../WRRHelpers';
import { SingleValueContent } from './WRRActionsStep';
import WRRDetailsPanel from './components/WRRDetailsPanel';

class WRRSamplingStep extends WizardStep {
    formFields = {};

    state = {
        depositLocation: this.props.bufferZone?.locationCode,
    };

    componentDidMount() {
        super.componentDidMount();
        this.props.setPage('WRP_SAMPLING');
    }

    /**
     * Can continue to the next step on the wizard
     */
    canContinue = () => {
        const { wrrGetters } = this.props;
        let isValid = true;
        const properties = wrrGetters.getProperties();

        Object.keys(this.formFields).forEach((key) => {
            if (this.formFields[key] && this.formFields[key].validate && !this.formFields[key].validate()) {
                isValid = false;
                showError('Please fill in all the required fields.');
            }
        });

        if (!properties[WATER_RELEASE_KEYS.SAMPLE]?.code) {
            showError('Please select a sample.');
            return false;
        }
        return isValid;
    };

    /**
     * SaveChanges is used to save the localized state to the redux-store. It can only run synchronous methods.
     */
    saveChanges = () => true;

    /**
     *  CommitChanges is used to trigger any side effects of the step, such as invocations to the back-end to
     * retrieve information about resources. This method can run asynchronous methods/requests but SHALL execute
     * the callback method afterwards to let the inform the wizard.
     */
    commitChanges = (callback) => {
        callback();
    };

    handleSubmit = () => this.canContinue() && this.attachSample();

    attachSample = async () => {
        const { wrrGetters, setLoading, reload } = this.props;
        const { jobNumber, sample } = wrrGetters.getProperties();
        setLoading(true);

        try {
            const resp = await WSWaterRelease.attachSample({
                jobNumber,
                sampleCode: sample.code,
                locationCode: this.state.depositLocation,
            });
            showSuccess(resp);
            reload();
        } catch (err) {
            console.error(err);
            handleError(err);
        }

        setLoading(false);
    };

    /**
     * On true, the wizard automatically jumps this step immediately after it is mounted
     */
    canJumpStep = () => false;

    renderActionFields = () => {
        const { storeActions, wrrGetters, screenData, getTranslation, writeAccess } = this.props;
        const properties = wrrGetters.getProperties();

        return generateWRRFields({
            fieldRefs: this.formFields,
            fields: getWRRAllFields({ ...screenData, getTranslation }),
            properties,
            storeActions,
            wrrGetters,
            writeAccess,
            getTranslation,
        });
    };

    render() {
        const { getTranslation, wrrGetters, storeActions, writeAccess, screenData, dropdowns, lang } = this.props;
        const properties = wrrGetters.getProperties();

        // console.log(properties[WATER_RELEASE_KEYS.SAMPLE]);
        // console.log(this.formFields);

        return (
            <div style={{ margin: 8 }}>
                <Grid container spacing={8}>
                    <Grid item md={6} sm={12} xs={12}>
                        <EISPanel
                            detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                            heading={getTranslation(TRANSLATION_KEYS.ACTIONS)}
                            alwaysExpanded
                        >
                            <AMMSingleAutocomplete
                                // formFields={this.formFields}
                                loadOptions={WSRPSampling.getNewSamples}
                                getOptionValue={(option) => option.code}
                                getOptionLabel={(option) => option.code}
                                components={{
                                    Option: EquipmentOption,
                                    SingleValue: SingleValueContent,
                                }}
                                value={properties[WATER_RELEASE_KEYS.SAMPLE]}
                                onChange={(value) => {
                                    storeActions.updateWRRRequest({
                                        [WATER_RELEASE_KEYS.SAMPLE]: {
                                            code: value?.code,
                                            description: value?.desc,
                                        },
                                    });
                                }}
                                elementInfo={{
                                    xpath: WATER_RELEASE_KEYS.SAMPLE,
                                    text: getTranslation('SAMPLE'),
                                    attribute: 'R',
                                    readonly:
                                        !writeAccess ||
                                        ![
                                            WRR_STATUS.ASSESSED_ASSIS_SAMPL,
                                            WRR_STATUS.ASSESSED_BZ_SAMPL,
                                            WRR_STATUS.ASSESSED_SELF_SAMPL,
                                        ].includes(properties[WATER_RELEASE_KEYS.STATUS]),
                                }}
                            />
                            {!properties[WATER_RELEASE_KEYS.RPAR_NUMBER] && (
                                <WarningText text={getTranslation(TRANSLATION_KEYS.ENSURE_CORRECT_SAMPLE)} />
                            )}
                            {[
                                WRR_STATUS.ASSESSED_ASSIS_SAMPL,
                                WRR_STATUS.ASSESSED_BZ_SAMPL,
                                WRR_STATUS.ASSESSED_SELF_SAMPL,
                            ].includes(properties[WATER_RELEASE_KEYS.STATUS]) && (
                                <EAMAutocomplete
                                    formFields={this.formFields}
                                    value={this.state.depositLocation}
                                    elementInfo={{
                                        text: getTranslation('DEPOSIT_LOCATION'),
                                        attribute: 'R',
                                        xpath: 'depositLocation',
                                    }}
                                    updateProperty={(key, value) => this.setState({ depositLocation: value })}
                                    loadOptions={WSAutocomplete.autocompleteLocation}
                                />
                            )}
                            <WRRAssociatedWOsInfoPanel
                                jobStatuses={screenData.lists.jobStatuses}
                                properties={properties}
                                getTranslation={getTranslation}
                                dropdowns={dropdowns}
                            />
                        </EISPanel>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <WRRDetailsPanel
                            jobStatuses={screenData.lists.jobStatuses}
                            getTranslation={getTranslation}
                            values={properties}
                            lang={lang}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default WRRSamplingStep;
