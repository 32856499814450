import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Info from '@material-ui/icons/Info';
import Save from '@material-ui/icons/Save';
import { parse } from 'date-fns';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import EAMDatePicker from 'eam-components/dist/ui/components/inputs/EAMDatePicker';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import { Cancel } from 'mdi-material-ui';
import { useEffect, useState } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import Tools from 'tools/Tools';
import ValuesManager from 'tools/ValuesManager';
import TRECButton from 'ui/components/buttons/TRECButton';
import EquipmentHazardContainer from 'ui/components/eqphazard/EquipmentHazardContainer';
import { IRRAD_END_DATE } from './RWManagementControls';
import RWManagementHazards from './RWManagementHazards';
import { CUTG10_FIELDS, CUTG10_TRANSL_MAP } from './RWManagementUtils';

const labelStyle = {
    width: 140,
    minWidth: 140,
};

const labelStyleAuto = {
    width: 'auto',
    minWidth: 60,
};

const RW_LOCATION_HIERARCHY = {
    LOCATION: 'wasteLocation',
    ZONE: 'wasteZone',
    SUBZONE: 'wasteSubzone',
};

const RW_IRRAD_END_DATE = {
    MIN: 'irradEndDateMin',
    MAX: 'irradEndDateMax',
};

/**
 * Component to display data to be updated and to handle the update
 */
const RWManagementUpdate = (props) => {
    const {
        applicationData,
        cancel,
        constants,
        formFields,
        getTranslation,
        lang,
        propertyToUpdate,
        rwpDropdowns,
        screenData,
        selectedEquipment,
        setWasteEquipmentDetail,
        translations,
        updateWasteEquipment,
        valuesGrid,
        wasteZones,
    } = props;

    const { assetFields, customFieldsWaste, customFieldsWasteDef } = screenData[lang];

    const getCellTagFromCustomField = (cfCode) => {
        switch (cfCode) {
            case constants.cfpWmLocation:
                return 'obj_location';
            case constants.cfpWmWasteFamily:
                return 'cf_waste_family';
            case constants.cfpWmStorageArea:
                return 'cf_storage_area';
            case constants.cfpWmTreatment:
                return 'cf_treatment';
            case constants.cfpWmElimPathway:
                return 'cf_elim_pathway';
            case constants.cfpWmOriginZone:
                return 'cf_origin_zone';
            case constants.cfpWmOriginSubzone:
                return 'cf_origin_subzone';
            case constants.cfpWmMaterial:
                return 'cf_material';
            case constants.cfpWmDsfi:
                return 'cf_dsfi';
            case constants.cfpWmCampaign:
                return 'cf_campaign';
            case constants.cfpWmProcess:
                return 'cf_process';
            default:
                return 'obj_location';
        }
    };

    // Create the selected records objects
    const createSelectedRecordsObj = (selectedRecordsValue, propertyToUpdateValue) => {
        const valueKey = getCellTagFromCustomField(propertyToUpdateValue);

        return Object.values(selectedRecordsValue).map((object) => {
            const location = object[CUTG10_FIELDS.OBJ_LOCATION];
            const wasteZone = object[CUTG10_FIELDS.RW_WASTE_ZONE];

            const gridPropertyToUpdate =
                Object.keys(CUTG10_TRANSL_MAP).find((k) => CUTG10_TRANSL_MAP[k] === propertyToUpdateValue) ||
                propertyToUpdateValue;

            const propertyList = object.propertyList
                ? object.propertyList
                : object[gridPropertyToUpdate]
                ? object[gridPropertyToUpdate].split(',')
                : [];

            const property = object.property
                ? object.property
                : propertyToUpdateValue === constants.cfpWmLocation
                ? wasteZone || location
                : Object.values(CUTG10_TRANSL_MAP).includes(propertyToUpdateValue)
                ? object[gridPropertyToUpdate]
                : object[valueKey];

            const treatDate = (str) => {
                const d = new Date();
                d.setHours(0, 0, 0, 0);
                return str && parse(str, 'yyyy-MM-dd HH:mm:SS'.substr(0, str.length), d);
            };

            return {
                equipmentCode: object[CUTG10_FIELDS.OBJ_CODE],
                equipmentDesc: object[CUTG10_FIELDS.OBJ_DESC],
                location: wasteZone ? wasteZone.replace('W$', '') : location,
                dimensions: object[CUTG10_FIELDS.DIMENSIONS],
                weight: object[CUTG10_FIELDS.EQP_WEIGHT],
                dose: object[CUTG10_FIELDS.LASTRP_MEAS_10],
                rwcheck: null,
                rpmeasurement: object[CUTG10_FIELDS.LASTRP_EVT_COMPLETED],
                risks: object[CUTG10_FIELDS.LASTRP_ASSOCIATED_RISK],
                classification: object[CUTG10_FIELDS.EQP_RPCLASSIFICATION],
                contamination: object[CUTG10_FIELDS.LASTRP_CONTAMINATION],
                property,
                propertyCode: propertyToUpdateValue,
                ...Tools.applyToFieldsK(
                    Tools.applyToFieldsKV(CUTG10_TRANSL_MAP, (k) => object[k]),
                    (k) => (CUTG10_TRANSL_MAP[k] ? CUTG10_TRANSL_MAP[k] : null)
                ),
                propertyList,
                // The helper above does not work with lists since it does not split
                [CUTG10_TRANSL_MAP[CUTG10_FIELDS.UDL_RW_MATERIALS]]: object[CUTG10_FIELDS.UDL_RW_MATERIALS]
                    ? object[CUTG10_FIELDS.UDL_RW_MATERIALS].split(',')
                    : [],
                [CUTG10_TRANSL_MAP[CUTG10_FIELDS.UDL_RW_FACILITIES]]: object[CUTG10_FIELDS.UDL_RW_FACILITIES]
                    ? object[CUTG10_FIELDS.UDL_RW_FACILITIES].split(',')
                    : [],
                irradEndDateMin: treatDate(object[CUTG10_FIELDS.CF_IRRADIATION_END_DATE_MIN]),
                irradEndDateMax: treatDate(object[CUTG10_FIELDS.CF_IRRADIATION_END_DATE_MAX]),
            };
        });
    };

    const selectedRecordsObj = createSelectedRecordsObj(selectedEquipment, propertyToUpdate);

    const [propertyForAll, setPropertyForAll] = useState(undefined);
    const [selectedRecords, setSelectedRecords] = useState(selectedRecordsObj);
    const [selectedLines, setSelectedLines] = useState(selectedRecordsObj.map((obj) => obj.equipmentCode));
    const [wasteLocation, setWasteLocation] = useState(undefined);
    const [wasteZone, setWasteZone] = useState(undefined);
    const [wasteSubzone, setWasteSubzone] = useState(undefined);
    const [irradEndDateMin, setIrradEndDateMin] = useState(undefined);
    const [irradEndDateMax, setIrradEndDateMax] = useState(undefined);

    useEffect(() => {
        // Update selected records
        const selectedRecordsObjHook = createSelectedRecordsObj(selectedEquipment, propertyToUpdate);
        setSelectedRecords(selectedRecordsObjHook);
        setSelectedLines(selectedRecordsObjHook.map((obj) => obj.equipmentCode));
    }, [propertyToUpdate, selectedEquipment]);

    const getValues = ({ key, object, values }) => ValuesManager.constrain({ key, object, valuesGrid, values });

    const onSaveHandler = () => {
        updateWasteEquipment(selectedRecords);
    };

    // Updates a property's value in the specified equipment (selected line)
    const updateProperty = (key, value, index) => {
        setSelectedRecords((prevState) =>
            prevState.map((elem, innerIndex) => {
                if (index === innerIndex) {
                    return {
                        ...elem,
                        [key]: value,
                    };
                }
                return elem;
            })
        );
    };

    // Called when the property is being changed for all records at the same time (dropdown located above)
    const updateGeneralProperty = (key, value) => {
        // Set property to all records
        selectedRecords.forEach((elem, index) => {
            if (selectedLines.includes(elem.equipmentCode)) {
                updateProperty(key, value, index);
            }
        });
        setPropertyForAll(value);
    };

    // Updates waste location state according to the dropdown used (given by 'key')
    // Waste location uses 'property' in the selected record objects to set the value
    const updateWasteLocation = (key, value) => {
        switch (key) {
            case RW_LOCATION_HIERARCHY.LOCATION:
                setWasteLocation(value);
                break;
            case RW_LOCATION_HIERARCHY.ZONE:
                setWasteZone(value);
                break;
            case RW_LOCATION_HIERARCHY.SUBZONE:
                setWasteSubzone(value);
                break;
            default:
                console.warn('Key for waste location not recognized (should not happen):', key);
                return;
        }
        updateGeneralProperty('property', value);
    };

    // Updates irrad end dates (min/max) according to the dropdown used (given by 'key')
    const updateIrradEndDates = (key, value) => {
        switch (key) {
            case RW_IRRAD_END_DATE.MIN:
                setIrradEndDateMin(value);
                break;
            case RW_IRRAD_END_DATE.MAX:
                setIrradEndDateMax(value);
                break;
            default:
                console.warn('Key for IrradEndDate not recognized (should not happen):', key);
        }
        updateGeneralProperty(key, value);
    };

    const renderGeneralSelection = () => {
        const specialCodes = [
            CUTG10_TRANSL_MAP[CUTG10_FIELDS.UDL_RW_MATERIALS],
            CUTG10_TRANSL_MAP[CUTG10_FIELDS.CF_RW_SUBFAMILY],
            CUTG10_TRANSL_MAP[CUTG10_FIELDS.CF_RW_FAMILY],
            CUTG10_TRANSL_MAP[CUTG10_FIELDS.UDL_RW_FACILITIES],
        ];

        if (propertyToUpdate !== constants.cfpWmLocation) {
            const values = Object.values(CUTG10_TRANSL_MAP).includes(propertyToUpdate)
                ? rwpDropdowns[propertyToUpdate]
                : propertyToUpdate === IRRAD_END_DATE
                ? []
                : Tools.getCustomFieldsCodeDesc(customFieldsWasteDef[propertyToUpdate]);

            return (
                <Grid container spacing={8}>
                    {propertyToUpdate === IRRAD_END_DATE ? (
                        <>
                            <EAMDatePicker
                                elementInfo={customFieldsWaste[constants.cfpWmIrradiationEndDateMin]}
                                formFields={formFields}
                                keyboard={false}
                                valueKey={RW_IRRAD_END_DATE.MIN}
                                timestamp
                                value={irradEndDateMin}
                                updateProperty={updateIrradEndDates}
                                maxDate={irradEndDateMax}
                                localeString={lang}
                            />
                            <EAMDatePicker
                                elementInfo={customFieldsWaste[constants.cfpWmIrradiationEndDateMax]}
                                formFields={formFields}
                                keyboard={false}
                                valueKey={RW_IRRAD_END_DATE.MAX}
                                timestamp
                                value={irradEndDateMax}
                                updateProperty={updateIrradEndDates}
                                minDate={irradEndDateMin || undefined}
                                maxDate={new Date()}
                                localeString={lang}
                            />
                        </>
                    ) : (
                        !specialCodes.includes(propertyToUpdate) && (
                            <Grid item md={6} sm={12} xs={12}>
                                <EAMSelect
                                    elementInfo={{ ...customFieldsWaste[propertyToUpdate] }}
                                    valueKey="property"
                                    value={propertyForAll}
                                    values={values}
                                    updateProperty={updateGeneralProperty}
                                />
                            </Grid>
                        )
                    )}
                    <Grid item md={6} sm={12} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <TRECButton color="secondary" style={{ marginRight: 10 }} onClick={cancel} size="small">
                            <Cancel />
                            {translations.CANCEL}
                        </TRECButton>
                        <TRECButton onClick={onSaveHandler} size="small">
                            <Save />
                            {translations.SAVE}
                        </TRECButton>
                    </Grid>
                </Grid>
            );
        } /* Updating location */
        return (
            <Grid container spacing={8}>
                <Grid item md={6} sm={12} xs={12}>
                    {/* Location dropdowns */}
                    <Grid container spacing={8}>
                        <Grid item md={6} sm={12} xs={12}>
                            <EAMSelect
                                elementInfo={screenData[lang].assetFields.wasteLocation}
                                valueKey={RW_LOCATION_HIERARCHY.LOCATION}
                                value={wasteLocation}
                                values={applicationData.wasteLocations}
                                updateProperty={updateWasteLocation}
                                labelStyle={labelStyle}
                            />
                        </Grid>
                        <Grid item md={3} sm={6} xs={6}>
                            <EAMSelect
                                elementInfo={screenData[lang].assetFields.wasteZone}
                                valueKey={RW_LOCATION_HIERARCHY.ZONE}
                                value={wasteZone}
                                values={applicationData.wasteZones[wasteLocation] || []}
                                updateProperty={updateWasteLocation}
                                labelStyle={labelStyleAuto}
                            />
                        </Grid>
                        <Grid item md={3} sm={6} xs={6}>
                            <EAMSelect
                                elementInfo={screenData[lang].assetFields.wasteSubzone}
                                valueKey={RW_LOCATION_HIERARCHY.SUBZONE}
                                value={wasteSubzone}
                                values={applicationData.rwSubzoneMap[wasteLocation]?.[wasteZone] || []}
                                updateProperty={updateWasteLocation}
                                labelStyle={labelStyleAuto}
                            />
                        </Grid>
                    </Grid>

                    {/* Location autocomplete */}
                    <EAMAutocomplete
                        elementInfo={{ ...assetFields.location }}
                        value={propertyForAll || ''}
                        valueKey="property"
                        updateProperty={updateGeneralProperty}
                        loadOptions={WSAutocomplete.autocompleteWasteLocation}
                        labelStyle={labelStyle}
                        creatable
                    />
                </Grid>
                <Grid item md={6} sm={12} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <TRECButton color="secondary" style={{ marginRight: 10 }} onClick={cancel} size="small">
                        <Cancel />
                        {translations.CANCEL}
                    </TRECButton>
                    <TRECButton
                        onClick={onSaveHandler}
                        size="small"
                        disabled={
                            propertyToUpdate === constants.cfpWmLocation &&
                            !wasteZone &&
                            wasteZones &&
                            wasteZones.length > 0
                        }
                    >
                        <Save />
                        {translations.SAVE}
                    </TRECButton>
                </Grid>
            </Grid>
        );
    };

    const renderPropertyToUpdate = (index, value) => {
        const object = selectedRecords[index];

        if (propertyToUpdate === constants.cfpWmLocation) {
            return (
                <EAMAutocomplete
                    // elementInfo={{...assetFields['location']}}
                    value={object.property || ''}
                    valueKey="property"
                    updateProperty={(k, val) => updateProperty(k, val, index)}
                    loadOptions={WSAutocomplete.autocompleteWasteLocation}
                    labelStyle={labelStyle}
                    creatable
                />
            );
        }

        if (propertyToUpdate === IRRAD_END_DATE) {
            return (
                <>
                    <EAMDatePicker
                        elementInfo={{ text: 'Min' }}
                        formFields={formFields}
                        keyboard={false}
                        valueKey={RW_IRRAD_END_DATE.MIN}
                        value={object.irradEndDateMin}
                        timestamp
                        updateProperty={(k, val) => updateProperty(k, val, index)}
                        maxDate={object.irradEndDateMax || new Date()}
                        localeString={lang}
                    />
                    <EAMDatePicker
                        elementInfo={{ text: 'Max' }}
                        formFields={formFields}
                        keyboard={false}
                        valueKey={RW_IRRAD_END_DATE.MAX}
                        value={object.irradEndDateMax}
                        timestamp
                        updateProperty={(k, val) => updateProperty(k, val, index)}
                        minDate={object.irradEndDateMin || undefined}
                        maxDate={new Date()}
                        localeString={lang}
                    />
                </>
            );
        }

        const values = Object.values(CUTG10_TRANSL_MAP).includes(propertyToUpdate)
            ? rwpDropdowns[propertyToUpdate]
            : Tools.getCustomFieldsCodeDesc(customFieldsWasteDef[propertyToUpdate]);

        const constrainedValues = getValues({ key: propertyToUpdate, object, values });

        // 'RW Material List' or 'RW Facilities' property update
        if (
            [
                CUTG10_TRANSL_MAP[CUTG10_FIELDS.UDL_RW_MATERIALS],
                CUTG10_TRANSL_MAP[CUTG10_FIELDS.UDL_RW_FACILITIES],
            ].includes(propertyToUpdate)
        ) {
            return (
                <EAMSelect
                    elementInfo={{ xpath: `${propertyToUpdate}${index}`, text: '' }}
                    valueKey="propertyList"
                    value={object.propertyList}
                    values={constrainedValues}
                    updateProperty={(k, val) => updateProperty(k, val, index)}
                    multi
                    style={{ minWidth: 160 }}
                />
            );
        }

        // 'Processing' property update
        if (propertyToUpdate === constants.cfpWmTreatment) {
            return (
                <EAMSelect
                    elementInfo={{ xpath: `${propertyToUpdate}${index}`, text: '' }}
                    valueKey="property"
                    value={object.property ? object.property : object.processing}
                    values={constrainedValues}
                    updateProperty={(k, val) => updateProperty(k, val, index)}
                />
            );
        }

        // All other properties to update
        return (
            <EAMSelect
                elementInfo={{ xpath: `${propertyToUpdate}${index}`, text: '' }}
                valueKey="property"
                value={value}
                values={constrainedValues}
                updateProperty={(k, val) => updateProperty(k, val, index)}
            />
        );
    };

    const renderDatatable = (headers, propCodes) => {
        // Data list
        const equipmentList = selectedRecords.map((record, index) => {
            const { equipmentCode } = record;

            return {
                ...record,
                detail: (
                    <Info
                        color="primary"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setWasteEquipmentDetail(equipmentCode)}
                    />
                ),
                equipmentCode: (
                    <Typography>
                        <a
                            target="_blank"
                            href={`${process.env.REACT_APP_FRONTEND}equipment/${equipmentCode}`}
                            rel="noreferrer"
                        >
                            {equipmentCode}
                        </a>
                    </Typography>
                ),
                risks: (
                    <EquipmentHazardContainer
                        classification={record.classification}
                        contamination={record.contamination}
                        risks={record.risks}
                        hazards={record.hazards_list}
                    />
                ),
                property: renderPropertyToUpdate(index, record.property),
                checkbox:
                    propertyToUpdate === constants.cfpWmLocation ? (
                        <EAMCheckbox
                            valueKey={equipmentCode}
                            value={selectedLines.includes(equipmentCode)}
                            updateProperty={(key, value) =>
                                setSelectedLines((prevState) =>
                                    value ? [...prevState, key] : prevState.filter((code) => code !== key)
                                )
                            }
                            trueValue
                            falseValue={false}
                        />
                    ) : null,
            };
        });
        // Render of table
        return <EISTable data={equipmentList} headers={headers} propCodes={propCodes} maxMobileSize={600} />;
    };

    // Prop Codes
    const propCodes = [
        'checkbox',
        'detail',
        'equipmentCode',
        'equipmentDesc',
        'location',
        'dimensions',
        'weight',
        'dose',
        'rpmeasurement',
        'risks',
        'property',
    ];

    const propToUpdate =
        Object.values(CUTG10_TRANSL_MAP).includes(propertyToUpdate) || propertyToUpdate === IRRAD_END_DATE
            ? translations[propertyToUpdate] || propertyToUpdate
            : propertyToUpdate !== constants.cfpWmLocation
            ? customFieldsWaste[propertyToUpdate].text
            : assetFields.wasteLocation.text;

    // Headers
    const headersTable = [
        '',
        translations.DETAIL,
        assetFields.equipmentno.text,
        assetFields.equipmentdesc.text,
        assetFields.location.text,
        translations.EQPDIMENSIONS,
        assetFields.udfnum10.text,
        translations.DOSE_RATE,
        // translations['RWCHECK'],
        translations.RPMEASUREMENT,
        translations.RISKS,
        getTranslation(propToUpdate),
    ];

    return propertyToUpdate === CUTG10_TRANSL_MAP[CUTG10_FIELDS.HAZARDS_LIST] ? (
        <RWManagementHazards {...props} />
    ) : (
        <EISPanel heading={translations.UPDATEPROPERTY} alwaysExpanded>
            <div style={{ width: '100%', marginTop: 0 }}>
                {renderGeneralSelection()}
                {renderDatatable(headersTable, propCodes)}
            </div>
        </EISPanel>
    );
};

export default RWManagementUpdate;
