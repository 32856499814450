import WS from './WS';
import { equipmentHazardOutputAdapter } from '../Hazards';

/**
 * Rest services for the Request of RW Checks
 */
class WSRWChecks {
    processRWCheckOk = (data, config = {}) => WS._put(`/rwchecks/processrwok`, data, config);

    processRWCheck = (data, config = {}) => WS._put(`/rwchecks/processrwcheck`, data, config);

    updateRWCheckEquipment = ({ equipment, applicationData }, config = {}) =>
        WS._put(`/rwchecks/updateequipment`, equipmentHazardOutputAdapter({ equipment, applicationData }), config);

    createCADRACheck = (jobnumber, config = {}) => WS._get(`/rwchecks/createcadra/${jobnumber}`, config);

    getRWCheck(jobnumber, config = {}) {
        return WS._get(`/rwchecks/readdata/${jobnumber}`, config);
    }
}

export default new WSRWChecks();
