import React, { Component } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMLinkInput from 'eam-components/dist/ui/components/inputs/EAMLinkInput';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EAMDatePicker from 'eam-components/dist/ui/components/inputs/EAMDatePicker';
import WSAutocomplete from '../../../../tools/rest/WSAutocomplete';
import Tools from '../../../../tools/Tools';
import { EQUIPMENT_KEYS } from '../../../../enums/Constants';
import { shouldShowComment } from '../../../../tools/Hazards';

const labelStyleRight = {
    width: 'auto',
    minWidth: 70,
    textAlign: 'right',
};

class EquipmentDetails extends Component {
    validateField = (value) => !Number.isNaN(value) && value > 0 && value < 999999999999;

    _getADRAttribute = () => {
        /**
         * The fields are required if there is transport request, or it the field transport required is yes
         * */
        const { measurement, constants } = this.props;
        if (
            measurement.edhDocument === constants.cfvEdhDocumentTransport ||
            measurement.edhDocument === constants.cfvEdhDocumentShipping ||
            measurement.edhDocument === constants.cfvEdhDocumentStockage ||
            measurement.transportRequired === constants.trueYes
        ) {
            return 'R';
        }
        // Is not required here
        return 'O';
    };

    render() {
        const {
            translations,
            assetFields,
            measurement,
            updateProperty,
            lists,
            formFields,
            applicationData,
            requireResponsible,
            customFieldsWaste,
            constants,
            customFields,
            customFieldsDef,
            rwpDropdowns,
            showLocation,
            showContainsAlphaEmitters,
            lang,
        } = this.props;
        const equipment = measurement.equipment[0];
        const path = `${measurement.jobNumber}.equipment[0].`;
        //
        const isVacuumAction = Tools.getIsVacuumAction(constants)(measurement.futureAction);
        const isOtherRwc =
            measurement.futureAction === constants.futureActionOther &&
            measurement.department === applicationData.rwsection;
        const isDecWasteRadioactive =
            [
                constants.futureActionDecwaste,
                constants.futureActionWasteDeclare,
                constants.futureActionWasteReceive,
            ].includes(measurement.futureAction) && measurement.radiationClassification === 'Radioactive'; // checkr: remove futureActionDecwaste
        const isVacuumCleanerEquipment = applicationData.vacuumCleanerClassesList.includes(equipment.classCode);

        const { readonly: readOnly } = this.props;
        const readonly = typeof readOnly === 'function' ? readOnly : () => readOnly;

        return (
            <EISPanel heading={translations.EQPDETAILS}>
                <div style={{ width: '100%', marginTop: 0 }}>
                    <div style={{ display: 'flex' }}>
                        <EAMLinkInput
                            value={equipment.equipmentCode}
                            isExternalLink
                            link={`${process.env.REACT_APP_FRONTEND}equipment/`}
                            right={1}
                            top={8}
                        >
                            <EAMInput
                                style={{ width: '50%' }}
                                elementInfo={{ ...assetFields.equipmentno, readonly: true }}
                                value={equipment.equipmentCode}
                            />
                        </EAMLinkInput>

                        <EAMInput
                            style={{ width: '50%' }}
                            elementInfo={{ ...assetFields.serialnumber, readonly: true }}
                            value={equipment.serialNumber}
                            valueKey={`${path}serialNumber`}
                            labelStyle={labelStyleRight}
                        />
                    </div>

                    <EAMInput
                        elementInfo={{
                            ...assetFields.equipmentdesc,
                            attribute: 'R',
                            readonly: readonly('equipmentDesc'),
                        }}
                        value={equipment.equipmentDesc}
                        valueKey={`${path}equipmentDesc`}
                        updateProperty={updateProperty}
                    />

                    <EAMAutocomplete
                        elementInfo={{
                            ...assetFields.eqpResponsible,
                            attribute: requireResponsible ? 'R' : 'O',
                            readonly: readonly('job.equipmentRespCode'),
                        }}
                        value={measurement.equipmentRespCode}
                        updateProperty={updateProperty}
                        valueKey={`${measurement.jobNumber}.equipmentRespCode`}
                        valueDesc={measurement.equipmentRespDesc}
                        descKey={`${measurement.jobNumber}.equipmentRespDesc`}
                        loadOptions={WSAutocomplete.autocompleteEmployee}
                        formFields={formFields}
                    />

                    <EAMAutocomplete
                        elementInfo={{
                            ...assetFields.eqpLocation,
                            attribute: showLocation ? 'R' : 'H',
                            readonly: readonly('locationCode'),
                        }}
                        value={equipment.locationCode}
                        valueDesc={equipment.locationDesc}
                        valueKey={`${path}locationCode`}
                        descKey={`${path}locationDesc`}
                        updateProperty={updateProperty}
                        loadOptions={WSAutocomplete.autocompleteTRECLocation}
                        columnsCodes={['code', 'desc', 'extraAttr1']}
                        columnsWidth={['30%', '30%', '40%']}
                    />

                    <EAMAutocomplete
                        elementInfo={{ ...assetFields.lastLocation, readonly: true }}
                        value={equipment.lastLocationCode}
                        valueDesc={equipment.lastLocationDesc}
                        valueKey={`${path}lastLocationCode`}
                        descKey={`${path}lastLocationDesc`}
                        updateProperty={updateProperty}
                        loadOptions={WSAutocomplete.autocompleteTRECLocation}
                        columnsCodes={['code', 'desc', 'extraAttr1']}
                        columnsWidth={['30%', '30%', '40%']}
                    />

                    <EAMSelect
                        elementInfo={{
                            ...assetFields.udfchar21,
                            readonly: readonly('family'),
                            attribute: this._getADRAttribute(),
                        }}
                        valueKey={`${path}family`}
                        value={equipment.family}
                        values={lists.objFamily}
                        updateProperty={updateProperty}
                        formFields={formFields}
                    />

                    <EAMSelect
                        elementInfo={{
                            ...assetFields.udfchar22,
                            readonly: readonly('mainMaterial'),
                            attribute: this._getADRAttribute(),
                        }}
                        valueKey={`${path}mainMaterial`}
                        value={equipment.mainMaterial}
                        values={lists.objMainMaterial}
                        updateProperty={updateProperty}
                        formFields={formFields}
                    />

                    <div style={{ display: 'flex' }}>
                        <EAMInput
                            style={{ width: '50%' }}
                            elementInfo={{ ...assetFields.udfnum07, readonly: readonly('length'), attribute: 'R' }}
                            value={equipment.length}
                            updateProperty={updateProperty}
                            valueKey={`${path}length`}
                            formFields={formFields}
                            validate={this.validateField}
                        />

                        <EAMInput
                            style={{ width: '50%' }}
                            elementInfo={{ ...assetFields.udfnum09, readonly: readonly('height'), attribute: 'R' }}
                            value={equipment.height}
                            updateProperty={updateProperty}
                            valueKey={`${path}height`}
                            labelStyle={labelStyleRight}
                            formFields={formFields}
                            validate={this.validateField}
                        />
                    </div>

                    <div style={{ display: 'flex' }}>
                        <EAMInput
                            style={{ width: '50%' }}
                            elementInfo={{ ...assetFields.udfnum08, readonly: readonly('width'), attribute: 'R' }}
                            value={equipment.width}
                            updateProperty={updateProperty}
                            valueKey={`${path}width`}
                            formFields={formFields}
                            validate={this.validateField}
                        />

                        <EAMInput
                            style={{ width: '50%' }}
                            elementInfo={{ ...assetFields.udfnum10, readonly: readonly('weight'), attribute: 'R' }}
                            value={equipment.weight}
                            updateProperty={updateProperty}
                            valueKey={`${path}weight`}
                            labelStyle={labelStyleRight}
                            formFields={formFields}
                            validate={this.validateField}
                        />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <EAMInput
                            style={{ width: '50%' }}
                            elementInfo={{
                                ...assetFields.equipmentvalue,
                                readonly: readonly('equipmentValue'),
                                attribute: 'R',
                            }}
                            value={equipment.equipmentValue}
                            updateProperty={updateProperty}
                            valueKey={`${path}equipmentValue`}
                            formFields={formFields}
                            validate={this.validateField}
                        />
                        {applicationData.vacuumCleanerClassesList.includes(equipment.classCode) && (
                            <EAMInput
                                style={{ width: '50%' }}
                                elementInfo={{
                                    ...assetFields.lockCode,
                                    readonly: readonly('lockCode'),
                                }}
                                value={equipment.lockCode}
                                updateProperty={updateProperty}
                                valueKey={`${path}lockCode`}
                                formFields={formFields}
                                validate={this.validateField}
                                labelStyle={labelStyleRight}
                            />
                        )}
                    </div>

                    {/* TODO reenable after requested changes are implemented (RQF1619305) */}
                    <EAMDatePicker
                        elementInfo={{
                            ...customFieldsWaste.PW232,
                            // Keep until V2 is released
                            attribute:
                                !equipment.irradiationEndDateMin || isVacuumAction || isVacuumCleanerEquipment
                                    ? 'H'
                                    : readonly('irradiationEndDateMin') || !isDecWasteRadioactive
                                    ? 'O'
                                    : 'R',
                            readonly: readonly('irradiationEndDateMin'),
                        }}
                        value={equipment.irradiationEndDateMin}
                        updateProperty={updateProperty}
                        valueKey={`${path}irradiationEndDateMin`}
                        localeString={lang}
                        formFields={formFields}
                        timestamp
                        maxDate={equipment.irradiationEndDateMax || new Date()}
                    />
                    <EAMDatePicker
                        elementInfo={{
                            ...customFieldsWaste.PW233,
                            attribute:
                                isVacuumAction || isVacuumCleanerEquipment
                                    ? 'H'
                                    : readonly('irradiationEndDateMax') ||
                                      (!equipment.irradiationEndDateMin && !isDecWasteRadioactive)
                                    ? 'O'
                                    : 'R',
                            readonly: readonly('irradiationEndDateMax'),
                        }}
                        value={equipment.irradiationEndDateMax}
                        updateProperty={updateProperty}
                        valueKey={`${path}irradiationEndDateMax`}
                        formFields={formFields}
                        localeString={lang}
                        timestamp
                        minDate={equipment.irradiationEndDateMin || undefined}
                        maxDate={new Date()}
                    />
                    <EAMSelect
                        elementInfo={{
                            text: 'Facility',
                            attribute:
                                isVacuumAction || isVacuumCleanerEquipment ? 'H' : readonly || isOtherRwc ? 'O' : 'R',
                            readonly: readonly('facilities'),
                        }}
                        multi
                        value={equipment.facilities || []}
                        values={applicationData.facilities}
                        updateProperty={updateProperty}
                        valueKey={`${path}facilities`}
                        formFields={formFields}
                    />

                    {applicationData.isHazardsFeatureActive ? (
                        <>
                            <EAMSelect
                                elementInfo={{
                                    readonly: readonly(EQUIPMENT_KEYS.HAZARD_CODES),
                                    text: translations.HAZARDS || 'HAZARDS',
                                    attribute: Array.isArray(equipment[EQUIPMENT_KEYS.HAZARD_CODES]) ? 'O' : 'H',
                                }}
                                value={equipment[EQUIPMENT_KEYS.HAZARD_CODES]}
                                updateProperty={updateProperty}
                                valueKey={path + EQUIPMENT_KEYS.HAZARD_CODES}
                                multi
                                searchable
                                values={rwpDropdowns.hazard}
                                formFields={formFields}
                            />
                            <EAMInput
                                elementInfo={{
                                    readonly: readonly(EQUIPMENT_KEYS.HAZARD_COMMENT),
                                    text: translations.HAZARDS_COMMENTS || 'HAZARDCOMMENT',
                                    attribute: shouldShowComment({ equipment, applicationData }) ? 'R' : 'H',
                                }}
                                value={equipment[EQUIPMENT_KEYS.HAZARD_COMMENT]}
                                updateProperty={updateProperty}
                                valueKey={path + EQUIPMENT_KEYS.HAZARD_COMMENT}
                                formFields={formFields}
                            />
                        </>
                    ) : (
                        <EAMSelect
                            elementInfo={{
                                ...customFields[constants.cfpAssociatedRisks],
                                readonly: readonly('job.associatedRisk'),
                                attribute: 'R',
                            }}
                            value={measurement.associatedRisk}
                            updateProperty={updateProperty}
                            valueKey={`${measurement.jobNumber}.associatedRisk`}
                            values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpAssociatedRisks], true)}
                            formFields={formFields}
                        />
                    )}
                    {showContainsAlphaEmitters && (
                        <EAMSelect
                            elementInfo={{
                                ...customFields.PW225,
                                attribute: readonly ? 'O' : 'R',
                                readonly: readonly('containsAlphaEmitters'),
                            }}
                            valueKey={`${path}containsAlphaEmitters`}
                            value={equipment.containsAlphaEmitters}
                            values={lists.yesnoList}
                            updateProperty={updateProperty}
                            formFields={formFields}
                        />
                    )}
                </div>
            </EISPanel>
        );
    }
}

export default EquipmentDetails;
