import React from 'react';
import { Account, Logout } from 'mdi-material-ui';

export default function UserInfo(props) {
    const userInfoStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    };

    const accountIcon = {
        fontSize: 20,
        margin: 5,
    };

    const logoutIcon = {
        color: 'rgba(255, 255, 255, 0.8)',
        height: 22,
        paddingLeft: 9,
        paddingRight: 9,
        borderLeft: '1px solid rgba(255, 255, 255, 0.8)',
        marginLeft: 14,
        fontSize: 18,
        lineHeight: '22px',
        cursor: 'pointer',
    };

    const { bufferZoneAccount, logout, userData } = props;

    return (
        <div style={userInfoStyle}>
            <Account style={accountIcon} />
            {userData.eamAccount.userCode}
            {bufferZoneAccount !== userData.eamAccount.userCode && (
                <i style={logoutIcon} onClick={logout} onKeyDown={logout}>
                    <Logout style={{ fontSize: 20 }} />
                </i>
            )}
        </div>
    );
}
