import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EAMLookup from 'eam-components/dist/ui/components/lookup/EAMLookup';
import React, { Component } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import WSAutocomplete from '../../../../tools/rest/WSAutocomplete';
import Tools from '../../../../tools/Tools';
import { RADIOACTIVE_VERIF_PROD } from 'enums/Constants';

class MeasurementDetails extends Component {
    /**
     * Temporal field for equipment destination
     */
    equipmentDestination = undefined;

    state = {
        areaClassification: undefined,
    };

    getBasicDetailsAttribute = () => {
        const { measurement, constants } = this.props;
        if (measurement.statusCode === constants.statusWoT) {
            return 'R';
        }
        return 'O';
    };

    getStorageOperationAttribute = () => {
        const { measurement, constants } = this.props;
        switch (measurement.edhDocument) {
            case constants.cfvEdhDocumentStockage:
                return 'R';
            default:
                return 'H';
        }
    };

    getTransportRequiredAttribute = () => {
        const { measurement, constants } = this.props;
        switch (measurement.edhDocument) {
            case constants.cfvEdhDocumentStockage:
                return 'R';
            default:
                return 'H';
        }
    };

    getTypeOfTransportAttribute = () => {
        const { measurement, constants } = this.props;
        switch (measurement.edhDocument) {
            case constants.cfvEdhDocumentTransport:
                return 'R';
            default:
                return 'H';
        }
    };

    /** *********   EQUIPMENT DESTINATION ATTRIBUTES   ********** */

    getEquipmentDestinationAttribute = () => {
        const { measurement, constants } = this.props;
        if (
            measurement.edhDocument === constants.cfvEdhDocumentTransport ||
            (measurement.edhDocument === constants.cfvEdhDocumentStockage &&
                measurement.storageOperation !== constants.cfvNatureInbound)
        ) {
            return 'R';
        }
        return 'H';
    };

    getEquipmentDestinationRadAttribute = () => {
        const { measurement, constants } = this.props;
        if (
            measurement.edhDocument === constants.cfvEdhDocumentTransport ||
            (measurement.edhDocument === constants.cfvEdhDocumentStockage &&
                measurement.storageOperation !== constants.cfvNatureInbound)
        ) {
            return 'O';
        }
        return 'H';
    };

    /** *********   STORE DESTINATION ATTRIBUTES   ********** */

    getStoreDestinationAttribute = () => {
        const { measurement, constants } = this.props;
        if (
            measurement.edhDocument === constants.cfvEdhDocumentStockage &&
            measurement.storageOperation === constants.cfvNatureInbound
        ) {
            return 'R';
        }
        return 'H';
    };

    getStoreDestinationRadAttribute = () => {
        const { measurement, constants } = this.props;
        if (
            measurement.edhDocument === constants.cfvEdhDocumentStockage &&
            measurement.storageOperation === constants.cfvNatureInbound
        ) {
            return 'O';
        }
        return 'H';
    };

    /** *********   RW CHECK GENERATION ATTRIBUTE   ********** */

    getRwCheckGenerationAttribute = () => {
        const { measurement, constants } = this.props;
        if (
            measurement.futureAction === constants.futureActionWasteDeclare &&
            measurement.radiationClassification === 'Radioactive'
        ) {
            return measurement.statusCode === constants.statusWoT ? 'R' : 'O';
        }

        return 'H';
    };

    radiationClassificationChangeHandler = (radiationClassification) => {
        const { applicationData, measurement, constants, updateProperty, fetchUsersSerialDevice } = this.props;
        const { jobNumber } = measurement;
        const isRwc = measurement.department === applicationData.rwsection;
        if (constants.radioactive === radiationClassification) {
            if (!measurement.value1) {
                updateProperty(`${jobNumber}.device1`, constants.deviceAd6);
                updateProperty(`${jobNumber}.distance1`, constants.distanceContact);
                updateProperty(`${jobNumber}.qualifier1`, '=');
                // Serial
                fetchUsersSerialDevice(constants.deviceAd6, `${jobNumber}.serial1`);
            }
            if (!isRwc && !measurement.value2) {
                updateProperty(`${jobNumber}.device2`, constants.deviceAd6);
                updateProperty(`${jobNumber}.distance2`, constants.distance10cm);
                updateProperty(`${jobNumber}.qualifier2`, '=');
                // Serial
                fetchUsersSerialDevice(constants.deviceAd6, `${jobNumber}.serial2`);
            }
            if (!isRwc && !measurement.value3) {
                updateProperty(`${jobNumber}.device3`, constants.deviceAd6);
                updateProperty(`${jobNumber}.distance3`, constants.distance40cm);
                updateProperty(`${jobNumber}.qualifier3`, '=');
                // Serial
                fetchUsersSerialDevice(constants.deviceAd6, `${jobNumber}.serial3`);
            }
            // Save the current destination (if not saved)
            if (!this.equipmentDestination) this.equipmentDestination = measurement.equipmentDestination;
            // Take the destination if radioactive if there is a value
            if (measurement.destinationIfRadioactive)
                updateProperty(`${jobNumber}.equipmentDestinationDesc`, measurement.destinationIfRadioactiveDesc);
            updateProperty(`${jobNumber}.equipmentDestination`, measurement.destinationIfRadioactive);
        } else if (constants.nonRadioactive === radiationClassification) {
            if (!measurement.value1) {
                // set BGO in Device 1
                updateProperty(`${jobNumber}.device1`, constants.deviceBgo);
                updateProperty(`${jobNumber}.distance1`, constants.distanceContact);
                updateProperty(`${jobNumber}.qualifier1`, '=');
                // Serial
                fetchUsersSerialDevice(constants.deviceBgo, `${jobNumber}.serial1`);

                // set BGO in BKG Device
                updateProperty(`${jobNumber}.bkgDevice`, constants.deviceBgo);
                updateProperty(`${jobNumber}.bkgQualifier`, '=');
            }
            if (!measurement.value2) {
                updateProperty(`${jobNumber}.device2`, '');
                updateProperty(`${jobNumber}.distance2`, '');
                updateProperty(`${jobNumber}.qualifier2`, '');
            }
            if (!measurement.value3) {
                updateProperty(`${jobNumber}.device3`, '');
                updateProperty(`${jobNumber}.distance3`, '');
                updateProperty(`${jobNumber}.qualifier3`, '');
            }
            // Restore the original destination if there was a value
            if (this.equipmentDestination) {
                updateProperty(`${jobNumber}.equipmentDestination`, this.equipmentDestination);
            }
        }

        // If contamination was yes, then empty the field (When no radioactive or empty the field)
        if (
            (!radiationClassification || constants.nonRadioactive === radiationClassification) &&
            measurement.contaminationRisk === constants.trueYes
        ) {
            updateProperty(`${jobNumber}.contaminationRisk`, '');
        }
    };

    onChangeEDHDocument = (value) => {
        const { measurement, updateProperty } = this.props;
        const { jobNumber } = measurement;
        if (value) {
            // No update location
            updateProperty(`${jobNumber}.updateLocation`, false);
        } else {
            // Remove area classification
            this.removeAreaClassification();
        }
    };

    onChangeNewLocation = (value, selectedObject) => {
        const { measurement, updateProperty } = this.props;
        const { jobNumber } = measurement;
        if (value) {
            // No Container
            updateProperty(`${jobNumber}.newContainerCode`, '');
        }
        // Area classification
        this.onChangeDestination(value, selectedObject);
    };

    onChangeNewContainer = (value) => {
        const { measurement, updateProperty } = this.props;
        const { jobNumber } = measurement;
        if (value) {
            // No Location Code
            updateProperty(`${jobNumber}.newLocationCode`, '');
        }
    };

    // Onchange destination to get the classification area
    onChangeDestination = (code, selectedObject) => {
        if (!selectedObject) {
            this.removeAreaClassification();
            return;
        }
        if (selectedObject.extraAttr1) {
            // Set area
            this.setState(() => ({
                areaClassification: selectedObject.extraAttr1,
            }));
        }
    };

    renderAreaClassification = () => {
        const { areaClassification } = this.state;
        if (!areaClassification) return null;
        return <p>{areaClassification}</p>;
    };

    removeAreaClassification = () => {
        // Empty area
        this.setState(() => ({
            areaClassification: undefined,
        }));
    };

    render() {
        const {
            translations,
            constants,
            assetFields,
            customFields,
            customFieldsDef,
            measurement,
            updateProperty,
            formFields,
            detailsStyle,
            woFields,
            rwpDropdowns,
            lists,
            userData,
            applicationData,
            readonly,
            isEquipmentInStore,
        } = this.props;
        const path = `${measurement.jobNumber}.`;

        const futureActionDepartmentFilter = (departments, futureAction) => {
            if (futureAction === constants.futureActionWasteReceive) {
                return departments.includes(applicationData.rwsection);
            }

            if (futureAction === constants.futureActionWasteDeclare) {
                return departments.includes(applicationData.rpsection);
            }

            if (!futureAction || futureAction === constants.futureActionOther) {
                return true;
            }

            return departments.includes(applicationData.rpsection);
        };

        // this represents the departments that the user has access to
        const departments = lists.departments.filter(
            (dep) =>
                dep.code === measurement.department ||
                (userData.eamAccount.departmentalSecurity[dep.code] &&
                    !userData.eamAccount.departmentalSecurity[dep.code].readOnly)
        );

        // this represents the departments that can be selected depending on the future action
        const futureActionDepartments = departments.filter(({ code }) =>
            futureActionDepartmentFilter([code], measurement.futureAction)
        );

        const futureActions = Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpFutureAction], true).filter(
            ({ code }) =>
                futureActionDepartmentFilter(
                    departments.map(({ code: departmentCode }) => departmentCode),
                    code
                )
        );

        // if there is no department selected and only one can be selected, select it
        if (!measurement.department && futureActionDepartments.length === 1) {
            updateProperty(`${path}department`, futureActionDepartments[0].code);
        }

        // Note: when changing future actions we could clear the department in certain cases (like for future action 'Other'),
        // like it is done in RPMRequest. Since this change is performed rarely in this page, we have chosen not to implement it.

        const removeAssetClass = measurement.customFieldsRP[constants.cfpRemoveAssetClass]?.value;

        return (
            <EISPanel detailsStyle={detailsStyle} heading={translations.HEAD_MEASGDAT}>
                <div style={{ width: '100%', marginTop: 0 }}>
                    {removeAssetClass ? (
                        <EAMCheckbox
                            elementInfo={{
                                ...customFields[constants.cfpRemoveAssetClass],
                                text: translations.REMOVEASSETCLASS || 'REMOVEASSETCLASS',
                            }}
                            valueKey={`${path}customFieldsRP.${constants.cfpRemoveAssetClass}.value`}
                            updateProperty={updateProperty}
                            value={removeAssetClass}
                            trueValue="YES"
                            falseValue="NO"
                        />
                    ) : null}
                    <EAMSelect
                        elementInfo={{
                            ...woFields.department,
                            readonly: readonly || futureActionDepartments.length === 1,
                            attribute: 'R',
                        }}
                        value={measurement.department}
                        updateProperty={updateProperty}
                        valueKey={`${path}department`}
                        values={futureActionDepartments}
                        formFields={formFields}
                        clearable={false}
                    />

                    <EAMSelect
                        elementInfo={{
                            ...customFields[constants.cfpRadiationClassification],
                            readonly,
                            attribute: this.getBasicDetailsAttribute(),
                        }}
                        value={measurement.radiationClassification}
                        updateProperty={updateProperty}
                        valueKey={`${path}radiationClassification`}
                        values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpRadiationClassification])}
                        onChangeValue={this.radiationClassificationChangeHandler}
                        formFields={formFields}
                    />

                    {measurement.radiationClassification === constants.nonRadioactive && (
                        <EAMSelect
                            elementInfo={{
                                ...customFields[constants.cfpMeasurementType],
                                readonly,
                                attribute: 'R',
                            }}
                            value={measurement.measurementType}
                            updateProperty={updateProperty}
                            valueKey={`${path}measurementType`}
                            values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpMeasurementType])}
                            // onChangeValue={this.radiationClassificationChangeHandler}
                            formFields={formFields}
                        />
                    )}

                    <EAMSelect
                        elementInfo={{
                            ...customFields[constants.cfpFutureAction],
                            readonly,
                            attribute: 'R',
                        }}
                        value={measurement.futureAction}
                        updateProperty={updateProperty}
                        valueKey={`${path}futureAction`}
                        values={futureActions}
                        formFields={formFields}
                    />

                    {[
                        constants.futureActionDecwaste,
                        constants.futureActionWasteDeclare,
                        constants.futureActionWasteReceive,
                    ].includes(measurement.futureAction) && // checkr: remove futureActionDecwaste
                        measurement.radiationClassification === 'Radioactive' && (
                            <>
                                <EAMSelect
                                    elementInfo={{
                                        ...woFields.udfchar24,
                                        // Since RW Producer OrgUnit points to udfchar24 (which has a generic description to use across apps
                                        text: 'RW Producer OrgUnit',
                                        attribute: 'O',
                                        readonly,
                                    }}
                                    value={measurement.orgUnit}
                                    values={rwpDropdowns.orgUnit}
                                    updateProperty={updateProperty}
                                    valueKey={`${path}orgUnit`}
                                    formFields={formFields}
                                />

                                <EAMSelect
                                    elementInfo={{
                                        ...customFields.PW225,
                                        attribute: readonly ? 'O' : 'R',
                                        readonly,
                                    }}
                                    value={measurement.containsAlphaEmitters}
                                    values={lists.yesnoList}
                                    updateProperty={updateProperty}
                                    valueKey={`${path}containsAlphaEmitters`}
                                    formFields={formFields}
                                />
                            </>
                        )}
                    {(RADIOACTIVE_VERIF_PROD || applicationData.environment !== 'PROD') &&
                        measurement.rwEquipment[0]?.rpClassification !== 'Radioactive' &&
                        measurement.radiationClassification === 'Radioactive' && (
                            <EAMCheckbox
                                elementInfo={{
                                    ...customFields.PW262,
                                    attribute: 'O',
                                    text: translations.RADFOLLOWINGVERIF,
                                }}
                                value={measurement.radFollowingVerification}
                                valueKey={`${path}radFollowingVerification`}
                                updateProperty={updateProperty}
                                trueValue
                                falseValue={false}
                            />
                        )}

                    <EAMSelect
                        elementInfo={{
                            ...customFields[constants.cfpEdhDocument],
                            readonly,
                        }}
                        value={measurement.edhDocument}
                        updateProperty={updateProperty}
                        valueKey={`${path}edhDocument`}
                        values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpEdhDocument], true).filter(
                            (s) => {
                                const getEDHDocumentValues = () => {
                                    // TODO: Same function in RPMRRequest
                                    // Possible values of the EDH Document
                                    const { futureAction } = measurement;
                                    switch (futureAction) {
                                        case constants.futureActionWasteDeclare:
                                        case constants.futureActionWasteReceive:
                                            return [
                                                constants.cfvEdhDocumentTransport,
                                                constants.cfvEdhDocumentStockage,
                                            ];
                                        case constants.futureActionMoveatcern:
                                        case constants.futureActionVacuumMove:
                                            return [constants.cfvEdhDocumentTransport];
                                        case constants.futureActionStock:
                                            return [constants.cfvEdhDocumentStockage];
                                        case constants.futureActionDispatch:
                                            return [constants.cfvEdhDocumentShipping];
                                        default:
                                            return [
                                                constants.cfvEdhDocumentTransport,
                                                constants.cfvEdhDocumentStockage,
                                                constants.cfvEdhDocumentShipping,
                                            ];
                                    }
                                };
                                return getEDHDocumentValues().includes(s.code);
                            }
                        )}
                        formFields={formFields}
                        onChangeValue={this.onChangeEDHDocument}
                        placeholder={translations.NOEDHDOC}
                    />

                    <EAMSelect
                        elementInfo={{
                            ...customFields[constants.cfpStorageOperation],
                            attribute: this.getStorageOperationAttribute(),
                            readonly,
                        }}
                        value={measurement.storageOperation}
                        updateProperty={updateProperty}
                        valueKey={`${path}storageOperation`}
                        values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpStorageOperation])}
                        formFields={formFields}
                    />

                    <EAMSelect
                        elementInfo={{
                            ...customFields[constants.cfpTransportRequired],
                            attribute: this.getTransportRequiredAttribute(),
                            readonly,
                        }}
                        value={measurement.transportRequired}
                        updateProperty={updateProperty}
                        valueKey={`${path}transportRequired`}
                        values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpTransportRequired])}
                        formFields={formFields}
                    />

                    <EAMSelect
                        elementInfo={{
                            ...customFields[constants.cfpTypeOfTransport],
                            attribute: this.getTypeOfTransportAttribute(),
                            readonly,
                        }}
                        value={measurement.typeOfTransport}
                        updateProperty={updateProperty}
                        valueKey={`${path}typeOfTransport`}
                        values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpTypeOfTransport], true)}
                        formFields={formFields}
                    />

                    {/** *********   EQUIPMENT DESTINATION   ********** */}

                    <EAMLookup
                        right={1}
                        top={13}
                        width={26}
                        height={26}
                        gridId={applicationData.locationRaisinGridID}
                        keys={{
                            code: 'obj_code',
                            mapCodeTo: `${path}equipmentDestination`,
                            desc: 'obj_desc',
                            mapDescTo: `${path}equipmentDestinationDesc`,
                        }}
                        updateProperty={updateProperty}
                        value={measurement.equipmentDestination}
                        disabled={readonly || this.getEquipmentDestinationAttribute() === 'H'}
                    >
                        <EAMAutocomplete
                            elementInfo={{
                                ...customFields[constants.cfpEquipmentDestination],
                                attribute: this.getEquipmentDestinationAttribute(),
                                readonly,
                                xpath: 'equipmentDestination',
                            }}
                            value={measurement.equipmentDestination}
                            updateProperty={updateProperty}
                            valueKey={`${path}equipmentDestination`}
                            valueDesc={measurement.equipmentDestinationDesc}
                            descKey={`${path}equipmentDestinationDesc`}
                            loadOptions={WSAutocomplete.autocompleteTRECLocation}
                            formFields={formFields}
                            columnsCodes={['code', 'desc', 'extraAttr1']}
                            columnsWidth={['30%', '30%', '40%']}
                            onChangeValue={this.onChangeDestination}
                        />
                    </EAMLookup>
                    {!measurement.updateLocation && this.renderAreaClassification()}

                    <EAMAutocomplete
                        elementInfo={{
                            ...customFields[constants.cfpEquipmentDestinationRad],
                            attribute: this.getEquipmentDestinationRadAttribute(),
                            readonly,
                            xpath: 'equipmentDestinationRad',
                        }}
                        value={measurement.destinationIfRadioactive || ''}
                        updateProperty={updateProperty}
                        valueKey={`${path}destinationIfRadioactive`}
                        valueDesc={measurement.destinationIfRadioactiveDesc}
                        descKey={`${path}destinationIfRadioactiveDesc`}
                        loadOptions={WSAutocomplete.autocompleteTRECLocation}
                        formFields={formFields}
                        columnsCodes={['code', 'desc', 'extraAttr1']}
                        columnsWidth={['30%', '30%', '40%']}
                    />

                    {/** *********   STORE DESTINATION   ********** */}

                    <EAMSelect
                        elementInfo={{
                            ...customFields[constants.cfpEquipmentDestination],
                            attribute: this.getStoreDestinationAttribute(),
                            readonly,
                        }}
                        value={measurement.equipmentDestination}
                        updateProperty={updateProperty}
                        valueKey={`${path}equipmentDestination`}
                        values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpStoreDestination])}
                        formFields={formFields}
                    />

                    <EAMSelect
                        elementInfo={{
                            ...customFields[constants.cfpEquipmentDestinationRad],
                            attribute: this.getStoreDestinationRadAttribute(),
                            readonly: true,
                        }}
                        value={measurement.destinationIfRadioactive}
                        updateProperty={updateProperty}
                        valueKey={`${path}destinationIfRadioactive`}
                        values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpStoreDestination])}
                        formFields={formFields}
                    />

                    {/** *********   RW CHECK GENERATION   ********** */}

                    <EAMSelect
                        elementInfo={{
                            ...customFields[constants.cfpRwCheckGeneration],
                            attribute: this.getRwCheckGenerationAttribute(),
                            readonly,
                        }}
                        value={measurement.rwCheckGeneration}
                        updateProperty={updateProperty}
                        valueKey={`${path}rwCheckGeneration`}
                        values={Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpRwCheckGeneration])}
                        formFields={formFields}
                    />

                    {/** *********   TO UPDATE LOCATION DIRECTLY   ********** */}
                    {!readonly &&
                        !measurement.edhDocument &&
                        measurement.statusCode === constants.statusWoT &&
                        !isEquipmentInStore && (
                            <>
                                <EAMCheckbox
                                    elementInfo={{
                                        ...customFields[constants.cfpEqpDetached],
                                        attribute: 'O',
                                        text: translations.UPDATELOCATION,
                                    }}
                                    valueKey={`${path}updateLocation`}
                                    value={measurement.updateLocation}
                                    updateProperty={updateProperty}
                                    trueValue
                                    falseValue={false}
                                    onChangeValue={this.removeAreaClassification}
                                />

                                {measurement.updateLocation && translations.NEWLOCORNEWCONT}

                                <EAMAutocomplete
                                    elementInfo={{
                                        ...assetFields.equipmentno,
                                        text: translations.NEWLOCATION,
                                        attribute: measurement.updateLocation ? 'O' : 'H',
                                    }}
                                    value={measurement.newLocationCode}
                                    valueDesc={measurement.newLocationDesc}
                                    updateProperty={updateProperty}
                                    valueKey={`${path}newLocationCode`}
                                    descKey={`${path}newLocationDesc`}
                                    loadOptions={WSAutocomplete.autocompleteTRECLocation}
                                    formFields={formFields}
                                    onChangeValue={this.onChangeNewLocation}
                                    columnsCodes={['code', 'desc', 'extraAttr1']}
                                    columnsWidth={['30%', '30%', '40%']}
                                />

                                {measurement.updateLocation && this.renderAreaClassification()}

                                <EAMAutocomplete
                                    elementInfo={{
                                        ...assetFields.equipmentno,
                                        text: translations.NEWCONTAINER,
                                        attribute: measurement.updateLocation ? 'O' : 'H',
                                    }}
                                    value={measurement.newContainerCode}
                                    valueDesc={measurement.newContainerDesc}
                                    updateProperty={updateProperty}
                                    valueKey={`${path}newContainerCode`}
                                    descKey={`${path}newContainerDesc`}
                                    loadOptions={WSAutocomplete.autocompleteContainer}
                                    formFields={formFields}
                                    onChangeValue={this.onChangeNewContainer}
                                    columnsWidth={['0%', '100%']}
                                />
                            </>
                        )}
                </div>
            </EISPanel>
        );
    }
}

export default MeasurementDetails;
