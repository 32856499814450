import { combineReducers } from 'redux';
import application from './applicationReducers';
import layout from './layoutReducer';
import rpar from './rparReducer';
import rpsm, { RPSM } from './rpsmReducer';
import rpmr, { RPMR } from './rpmrReducer';
import rwp, { RWP } from './rwpReducer';
import wrr, { WRR } from './wrrReducer';
import rwelim, { RWELIM } from './rwEliminationReducer';
import vac, { VAC } from './vacCleanerReducer';
import ui from 'react-eis-components/dist/reducers/uiReducers';

export default combineReducers({
    application,
    layout,
    rpar,
    ui,
    [RPMR]: rpmr,
    [RPSM]: rpsm,
    [RWP]: rwp,
    [WRR]: wrr,
    [VAC]: vac,
    [RWELIM]: rwelim,
});
