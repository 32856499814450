import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tools from '../../../tools/Tools';
import { generateRoute, getInternalLink, ROUTES } from '../../../tools/Routes';

const rowStyle = {
    display: 'block',
    margin: '3px',
    maxWidth: '900px',
};

const itemStyle = {
    fontWeight: '500',
    padding: '8px',
    borderBottom: '1px solid lightgray',
    overflowWrap: 'break-word',
    overflowX: 'auto',
};

const styles = (theme) => ({ themecolor: { backgroundColor: `${theme.palette.primary.main}10` } });

const EquipmentPropertiesInfo = (props) => {
    const { classes, data } = props;

    const renderEquipmentLinks = (equipment) =>
        equipment.map((eq) =>
            getInternalLink({
                route: generateRoute(ROUTES.equipmentShow, { equipmentID: eq }),
                style: { padding: '0px 2px', whiteSpace: 'nowrap' },
                text: eq,
                otherProps: {
                    key: eq,
                },
            })
        );

    const renderItem = (item) =>
        item.value !== undefined && (
            <div key={item.property} style={itemStyle}>
                <div style={{ paddingBottom: 4 }}>
                    {item.label} : {item.qualifier && `(${item.qualifier})`} {item.value}{' '}
                </div>
                <div>{item.equipment && item.equipment.length > 0 && renderEquipmentLinks(item.equipment)}</div>
            </div>
        );

    const renderGroup = (group, i) => {
        const row = group.map(renderItem).filter((item) => item);
        return row.length > 0 ? (
            <div key={i} style={rowStyle}>
                {row}
            </div>
        ) : null;
    };

    const groupData = (d) => Object.values(Tools.groupListByFunction(d, (item) => item.group || '__OTHER'));

    return <div className={classes.themecolor}>{groupData(data || []).map(renderGroup)}</div>;
};

export default withStyles(styles)(EquipmentPropertiesInfo);
