export const EQUIPMENT_PAGE_KEYS = {
    SHOW_FILTERS: 'SHOW_FILTERS',
    HIDE_FILTERS: 'HIDE_FILTERS',
    RESET_FILTERS: 'RESET_FILTERS',
    SEARCH_OTHER: 'SEARCH_OTHER',
    MENU: 'MENU',
    OPEN_IN_EAMLIGHT: 'OPEN_IN_EAMLIGHT',
};

export const HISTORY_KEYS = {
    _TYPE: '_type',
    _RAWDATA: '_rawData',
    ENTRY_DATE: 'ENTRY_DATE',
    ENTRY_TYPE: 'ENTRY_TYPE',
    ENTRY_VALUE: 'ENTRY_VALUE',
    ENTRY_USER: 'ENTRY_USER',
    DESCRIPTION: 'ENTRY_DESCRIPTION',
    ORDERED: 'ENTRY_ORDERED',
};

export const HISTORY_ENTRY_TYPES = {
    EVENT: 'EVENT',
    AUDIT: 'AUDIT',
};

export const EVENT_KEYS = {
    CODE: 'CODE',
    EVT_CODE: 'EVT_CODE',
    EVT_OBJECT: 'EVT_OBJECT',
    EVT_DESC: 'EVT_DESC',
    EVT_CLASS: 'EVT_CLASS',
    EVT_COMPLETED: 'EVT_COMPLETED',
    EVT_UDFCHAR20: 'EVT_UDFCHAR20',
    EVT_PARENT: 'EVT_PARENT',
    PERFORMED_BY_CID: 'PERFORMED_BY_CID',
    PERFORMED_BY_LOGIN: 'PERFORMED_BY_LOGIN',
    ORDERED: 'ORDERED',
    GROUPID: 'GROUPID',
};

export const AUDIT_KEYS = {
    DATASRC: 'DATASRC',
    AVA_ATTRIBUTE: 'AVA_ATTRIBUTE',
    AVA_PRIMARYID: 'AVA_PRIMARYID',
    AAT_COLUMN: 'AAT_COLUMN',
    AAT_TEXT: 'AAT_TEXT',
    AVA_FROM: 'AVA_FROM',
    AVA_TO: 'AVA_TO',
    AVA_MODIFIEDBY: 'AVA_MODIFIEDBY',
    AVA_CHANGED: 'AVA_CHANGED',
    ORDERED: 'ORDERED',
};

export const EVENT_TYPES = {
    RPM: 'RP01',
    EDHEDH: 'EDHEDH',
    EDHTREC: 'EDHTREC',
    RPA_JOB: 'RPAM',
    RWP: 'RPWP01',
    SAMPLING: 'RP_SAMPL',
    RW_CHECK: 'RW01',
    CADRA: 'CADRA',
    RPA_REQUEST: 'RPAR',
    STRUCT: 'STRUCT',
};

export const EDH_PROPERTIES_KEYS = {
    LAST_EDH_CREATED_BY_TREC: 'LAST_EDH_CREATED_BY_TREC',
};

export const BLOCK_KEYS = {
    QUERY_DATA: 'QUERY_DATA',
    EQUIPMENT_DETAILS: 'EQUIPMENT_DETAILS',
    LOCATION: 'LOCATION',
    LAST_RPM_JOB: 'LAST_RPM_JOB',
    LAST_RPA_REQUEST: 'LAST_RPA_REQUEST',
    LAST_RPA_JOBS: 'LAST_RPA_JOBS',
    LAST_CADRA: 'LAST_CADRA',
    LAST_RW_CHECK: 'LAST_RW_CHECK',
    LAST_RWP_CHECK: 'LAST_RWP_CHECK',
    LAST_RP_SAMPLING: 'LAST_RP_SAMPLING',
    EDH_PROPERTIES: 'EDH_PROPERTIES',
    CONTAMINATION_PROPERTIES: 'CONTAMINATION_PROPERTIES',
    RADIATION_PROPERTIES: 'RADIATION_PROPERTIES',
    RW_PROPERTIES: 'RW_PROPERTIES',
};

export const BLOCK_STATUS = {
    ERROR: 'ERROR',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
};
