import Typography from '@material-ui/core/Typography';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { generateRoute, ROUTES } from 'tools/Routes';
import { getRowAsAnObject } from 'ui/pages/rwmanagement/RWManagementUtils';
import WSRPARequest from '../../../tools/rest/WSRPARequest';
import WSVacRental from '../../../tools/rest/WSVacRental';
import ValuesManager from '../../../tools/ValuesManager';
import EAMGridNoOverflow from '../../components/grid/EAMGridNoOverflow';
import gridNoOverflowClasses from '../../components/grid/EAMGridNoOverflow.module.css';
import TRECSearchPage from '../TRECSearchPage';
import VacuumCleanerManageButtons from '../vaccleanermanage/VacuumCleanerManageButtons';
import { ENTITY_TRANSL_PREFIX } from '../wrrequest/WRRConstants';
import { handleError, showSuccess } from 'tools/TrecNotifications';

const CELL_TYPES = {
    VACREQUEST_CODE: 'evt_code',
    EQUIPMENT: 'evt_object',
    STATUS: 'evt_status',
    COMMENTS: 'comments',
    PENDING_RESERVATIONS: 'pending_reservations',
    AVAILABILITY_STATUS: 'obj_availabilitystatus',
    STATUS_CODE: 'obj_status',
    STATE_CODE: 'obj_state',
    EQUIPMENT_CODE: 'obj_code',
};

const getGridRequestAdapter =
    ({ writeAccess, userData, deposit }) =>
    (gridRequest) => {
        let gridFilters = [...gridRequest.gridFilter];
        // People with no write access can only see their requests
        if (!writeAccess) {
            gridFilters = gridFilters.filter((f) => !['evt_createdby', 'evt_origin'].includes(f.fieldName));
            gridFilters.push({
                fieldName: 'evt_createdby',
                fieldValue: userData.eamAccount.userCode,
                operator: 'EQUALS',
                joiner: 'OR',
                leftParenthesis: true,
            });
            gridFilters.push({
                fieldName: 'evt_origin',
                fieldValue: userData.eamAccount.employeeCode,
                operator: 'EQUALS',
                joiner: 'AND',
                rightParenthesis: true,
            });
        }

        // In deposit mode
        if (deposit) {
            gridFilters = gridFilters.filter((f) => !['evt_status', 'wrr_sample'].includes(f.fieldName));
            gridFilters.push({
                fieldName: 'evt_status',
                fieldValue: 'RS',
                operator: 'BEGINS',
                joiner: 'AND',
            });
            gridFilters.push({
                fieldName: 'wrr_sample',
                fieldValue: '',
                operator: 'IS_EMPTY',
                joiner: 'AND',
            });
        }

        return {
            ...gridRequest,
            gridFilter: gridFilters,
        };
    };

const mapObjectToEquipment = (object) => ({
    code: object[CELL_TYPES.EQUIPMENT_CODE],
    availabilityStatus: object[CELL_TYPES.AVAILABILITY_STATUS],
    stateCode: object[CELL_TYPES.STATE_CODE],
});

const getValues = ({ valuesGrid, key, object, values }) => ValuesManager.constrain({ key, object, valuesGrid, values });

export const getCellRenderer =
    ({ dropdowns, getTranslation, valuesGrid, gridVals = {}, setGridVals }) =>
    ({ t, value }, row) => {
        const object = getRowAsAnObject(row);
        if (t === CELL_TYPES.VACREQUEST_CODE) {
            return <Link to={generateRoute(ROUTES.vaccleanerRequestEdit, { vaccleanerNumber: value })}>{value}</Link>;
        }
        if (t === CELL_TYPES.COMMENTS) {
            const comments = value?.split(/\r?\n/);
            return comments?.map((comment) => <Typography>{comment}</Typography>);
        }
        if (t === CELL_TYPES.PENDING_RESERVATIONS) {
            return value?.split(',').map((val) => (
                <Link key={`rpm#${value}`} to={generateRoute(ROUTES.workOrdersRedirect, { workOrderID: val })}>
                    {val}
                </Link>
            ));
        }
        if (!valuesGrid) return <>Loading...</>;
        if (t === CELL_TYPES.AVAILABILITY_STATUS) {
            const updatedValue =
                gridVals[`${object[CELL_TYPES.EQUIPMENT_CODE]}#${CELL_TYPES.AVAILABILITY_STATUS}`] ?? value;

            return !dropdowns?.eqpAvailabilityStatus ? (
                getTranslation(ENTITY_TRANSL_PREFIX.AVAILABILITY_STATUS + updatedValue)
            ) : (
                <div style={{ width: '100%' }}>
                    <Select
                        isDisabled={!['A', 'NA'].includes(updatedValue)}
                        value={{
                            value: updatedValue,
                            label: getTranslation(ENTITY_TRANSL_PREFIX.AVAILABILITY_STATUS + updatedValue),
                        }}
                        valueKey="code"
                        labelKey="desc"
                        options={getValues({
                            valuesGrid,
                            key: 'eqpAvailabilityStatus',
                            object,
                            values: dropdowns.eqpAvailabilityStatus,
                        })
                            .filter(
                                ({ code }) =>
                                    ['A', 'NA'].includes(updatedValue) && ['A', 'NA', updatedValue].includes(code)
                            )
                            .map(({ code }) => ({
                                value: code,
                                label: getTranslation(ENTITY_TRANSL_PREFIX.AVAILABILITY_STATUS + code),
                            }))}
                        onChange={(sel) => {
                            object[CELL_TYPES.AVAILABILITY_STATUS] = sel.value;
                            const obje = mapObjectToEquipment(object);
                            setGridVals((prevState) => ({
                                ...prevState,
                                [`${object[CELL_TYPES.EQUIPMENT_CODE]}#${CELL_TYPES.AVAILABILITY_STATUS}`]: sel.value,
                            }));
                            WSVacRental.updateEquipment(obje)
                                .then(() => {
                                    showSuccess('Availability Status successfully updated.');
                                })
                                .catch((error) => {
                                    setGridVals((prevState) => ({
                                        ...prevState,
                                        [`${object[CELL_TYPES.EQUIPMENT_CODE]}#${CELL_TYPES.AVAILABILITY_STATUS}`]:
                                            value,
                                    }));
                                    handleError(error);
                                });
                        }}
                    />
                </div>
            );
        }
        if (t === CELL_TYPES.STATE_CODE) {
            const updatedValue = gridVals[`${object[CELL_TYPES.EQUIPMENT_CODE]}#${CELL_TYPES.STATE_CODE}`] ?? value;
            return !dropdowns?.eqpState ? (
                getTranslation(ENTITY_TRANSL_PREFIX.OBJECT_STATE + updatedValue)
            ) : (
                <div style={{ width: '100%' }}>
                    <Select
                        value={{
                            value: updatedValue,
                            label: getTranslation(ENTITY_TRANSL_PREFIX.OBJECT_STATE + updatedValue),
                        }}
                        options={getValues({
                            valuesGrid,
                            key: 'eqpState',
                            object,
                            values: dropdowns.eqpState,
                        }).map(({ code }) => ({
                            value: code,
                            label: getTranslation(ENTITY_TRANSL_PREFIX.OBJECT_STATE + code),
                        }))}
                        onChange={(sel) => {
                            object[CELL_TYPES.STATE_CODE] = sel.value;
                            const obje = mapObjectToEquipment(object);
                            setGridVals((prevState) => ({
                                ...prevState,
                                [`${object[CELL_TYPES.EQUIPMENT_CODE]}#${CELL_TYPES.STATE_CODE}`]: sel.value,
                            }));
                            WSVacRental.updateEquipment(obje)
                                .then(() => {
                                    showSuccess('State Code successfully updated.');
                                })
                                .catch((error) => {
                                    setGridVals((prevState) => ({
                                        ...prevState,
                                        [`${object[CELL_TYPES.EQUIPMENT_CODE]}#${CELL_TYPES.STATE_CODE}`]: value,
                                    }));
                                    handleError(error);
                                });
                        }}
                    />
                </div>
            );
        }
        return null;
    };

const VacuumCleanerStock = ({
    deposit,
    userData,
    writeAccess,
    applicationData,
    page,
    history,
    getTranslation,
    assignRef,
    grid,
    dropdowns,
    valuesGrid,
    lang,
}) => {
    // const { gridWaterReleaseSearchGridID } = applicationData;
    const [gridVals, setGridVals] = useState({});
    const gridRequestAdapterMemoed = useCallback(
        getCellRenderer({
            dropdowns,
            getTranslation,
            grid,
            valuesGrid,
            gridVals,
            setGridVals,
        }),
        [valuesGrid, gridVals, setGridVals]
    );

    if (!valuesGrid) {
        return <div>Loading...</div>;
    }

    return (
        <div className={gridNoOverflowClasses.outerBlock}>
            <VacuumCleanerManageButtons history={history} getTranslation={getTranslation} page={page} />
            <EAMGridNoOverflow
                onRef={(ref) => assignRef(ref)}
                gridId={applicationData.vacCleanersStockGridID}
                gridRequestAdapter={getGridRequestAdapter({ writeAccess, userData, deposit })}
                bypassAlternateRows
                filterVisible
                cellRenderer={gridRequestAdapterMemoed}
                heightFilterVisible="245px"
                heightFilterNotVisible="195px"
                language={lang}
            />
        </div>
    );
};

class VacuumCleanerStockClass extends TRECSearchPage {
    componentDidMount() {
        Promise.all([WSRPARequest.getValuesGrid(), WSRPARequest.getValuesDef()]).then((respArr) => {
            const valuesDefMap = respArr[1].body.data.reduce(
                (acc, value) => ({
                    ...acc,
                    [value.field]: [
                        ...(acc[value.field] || []),
                        value.description
                            ? { code: value.value, desc: value.description }
                            : { code: value.value, desc: this.props.getTranslation(value.value) },
                    ],
                }),
                {}
            );
            const rpamValues = valuesDefMap;
            rpamValues.edmsContext = valuesDefMap.edmsContext.map((context) => ({ ...context, shouldLoad: false }));
            rpamValues.edmsContext[0].shouldLoad = true;
            this.setState({ rpamValues, valuesGrid: respArr[0].body.data });
        });
    }

    renderPage(writeAccess) {
        return (
            <VacuumCleanerStock
                writeAccess={writeAccess}
                assignRef={(ref) => {
                    this.grid = ref;
                }}
                grid={this.grid}
                valuesGrid={this.state.valuesGrid}
                {...this.props}
            />
        );
    }
}

export default VacuumCleanerStockClass;
