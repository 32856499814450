import { parse as queryParse } from 'query-string';
import { connect } from 'react-redux';
import Tools from 'tools/Tools';
import { logout } from '../../../actions/applicationActions';
import { setPage, updateLayout } from '../../../actions/layoutActions';
import rpmrActions from '../../../actions/rpmrActions';
import { rpmrGettersWithState } from '../../../reducers/rpmrReducer';

const RPM_TRANSLATION_KEY = 'TREC_REQRPMEAS';

const mapStateToProps = (state, ownProps) => {
    const { action, application, layout } = state;
    const { applicationData, bufferZone, constants, menuType, screenData, userData, rwpDropdowns } = application;
    const { lang } = layout;
    const translations = screenData[lang].translations[RPM_TRANSLATION_KEY];
    // const { action, mode } = queryParse(ownProps.location.search);
    const urlParams = queryParse(ownProps.location.search, { arrayFormat: 'comma' });

    const { customFields } = screenData[lang];
    const { customFieldsDef } = screenData[lang];

    const rwpDropdownsTranslated = Tools.applyToFields(rwpDropdowns, (list) =>
        list.map((codeDesc) => ({
            ...codeDesc,
            desc: (codeDesc.descTranslated && codeDesc.descTranslated[lang]) || codeDesc.desc,
        }))
    );

    return {
        screenData: screenData[lang],
        userData,
        applicationData,
        dropdowns: rwpDropdownsTranslated,
        bufferZone,
        menuType,
        constants,
        customFields,
        customFieldsDef,
        lang,
        translations,
        masterPage: [
            'TREC_REQRPMEAS',
            'DEC_WASTE',
            'DEP_WASTE',
            'MOVE_AT_CERN',
            'STO_DSTO_CERN',
            'DISPATCH_OUTSIDE',
            'VACUUM_CLEANER',
            'REQUEST_RPM',
            'REQUEST_RPM',
            'UPDATE_LOC',
            'FETCH',
        ],
        page: 'REQUEST_RPM',
        menu: screenData[lang].menu[menuType],
        action,
        urlParams,
        getTranslation: (code) =>
            code &&
            ((urlParams.lang === 'DEV' && code) ||
                (translations && translations[code.toUpperCase()]) ||
                (screenData.EN.translations[RPM_TRANSLATION_KEY] &&
                    screenData.EN.translations[RPM_TRANSLATION_KEY][code.toUpperCase()]) ||
                code),
        rpmrGetters: rpmrGettersWithState(state),
    };
};

const Containerize = (Component) =>
    connect(
        mapStateToProps,
        {
            updateLayout,
            setPage,
            logout,
            ...rpmrActions,
        },
        (stateProps, dispatchProps, ownProps) => ({
            ...ownProps,
            ...stateProps,
            storeActions: dispatchProps,
            setPage: dispatchProps.setPage,
        })
    )(Component);

export default Containerize;
