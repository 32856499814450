import React, { Component } from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import Grid from '@material-ui/core/Grid';
import BlockUi from 'react-block-ui';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import EISTable from 'react-eis-components/dist/ui/components/table';
import CommentsContainer from 'eam-components/dist/ui/components/comments/CommentsContainer';
import WSAutocomplete from '../../../../tools/rest/WSAutocomplete';
import WSRPMRequest from '../../../../tools/rest/WSRPMRequest';
import WSComments from '../../../../tools/rest/WSComments';
import Tools from '../../../../tools/Tools';
import { handleError, showError, showWarning } from 'tools/TrecNotifications';

const blockUiStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const blockUiStyleDiv = {
    display: 'flex',
    height: 60,
    alignItems: 'flex-end',
};

const labelStyle = {
    width: 170,
    minWidth: 170,
};

class RPMREquipment extends Component {
    state = {
        loading: true,
        multipleEquipment: false,
        editAll: false,
    };

    formFields = [];

    validate() {
        const { loading } = this.state;
        if (loading) {
            // Not valid if loading
            return false;
        }
        // Validations ok from the beginning
        let isValid = true;
        // Iterate over the required fields and validate them
        Object.keys(this.formFields).forEach((key) => {
            if (this.formFields[key] && this.formFields[key].validate && !this.formFields[key].validate()) {
                isValid = false;
            }
        });
        if (!isValid) {
            // Invalid data in Equipment
            const { translations } = this.props;
            showError(translations.EQUFIELINV);
        }
        return isValid;
    }

    UNSAFE_componentWillMount() {
        const { equipments, handleBack, request, setPage, updateRequestProperty } = this.props;
        // Set the page for the help
        setPage('TREC_REQRPMEAS', 'STEP_EQUIPMENT');
        // Compare equipments with the list in the request to see if it was loaded already
        if (
            request.equipment &&
            equipments &&
            equipments.toUpperCase() ===
                request.equipment
                    .map((elem) => elem.equipmentCode)
                    .toString()
                    .toUpperCase()
        ) {
            // Not loading
            this.setState(() => ({
                loading: false,
                multipleEquipment: request.equipment.length > 1,
            }));
            return;
        }
        // We need to load the equipments information
        if (equipments) {
            Promise.all(this.createPromiseArray(equipments))
                .then((values) =>
                    // Process response
                    this.processEquipments(values.map((elem) => elem.body.data))
                )
                .then((equipmentList) => {
                    // Assign properties to the request
                    updateRequestProperty('equipment', equipmentList);
                    // Validate equipment
                    this.validateEquipmentList(equipmentList);
                    // Not loading anymore
                    this.setState(() => ({
                        loading: false,
                        multipleEquipment: equipmentList.length > 1,
                    }));
                })
                .catch((error) => {
                    // Most probably equipment invalid
                    handleError(error);
                    // Remove equipments
                    updateRequestProperty('equipment', []);
                    // Go back
                    handleBack();
                });
        } else {
            // No Equipments
            handleBack();
        }
    }

    /**
     * Validate that if we have more than one equipment, then the responsible is the same and also the location.
     * Also validates that for vacuum cleaners, the class of the equipment is of a vacuum cleaner
     * @param equipmentList List of equipment to be validated
     */
    validateEquipmentList = (equipmentList) => {
        const { request, constants, applicationData, translations, bufferZone, updateRequestProperty } = this.props;
        const { futureAction } = request;
        // Vacuum cleaner actions
        const vcActions = [
            constants.futureActionVacuumDeclare,
            constants.futureActionVacuumEmpty,
            constants.futureActionVacuumEnd,
            constants.futureActionVacuumEndFull,
            constants.futureActionVacuumMove,
        ];

        // Variables for responsible and location
        let responsible;
        let responsibleDesc;
        let location;

        let validationResponsibleWarnings;
        let validationLocationWarnings;

        // Multiple equipment
        equipmentList.forEach((equipment) => {
            // InforEquipment
            const { inforEquipment } = equipment;
            let eqpResponsible;
            let eqpResponsibleDesc;
            const { locationCode } = equipment;

            // Get responsible
            if (inforEquipment) {
                eqpResponsible = inforEquipment.customField.find(
                    (elem) => elem.code === constants.cfpEqpResponsible
                ).value;
                eqpResponsibleDesc = inforEquipment.customField.find(
                    (elem) => elem.code === constants.cfpEqpResponsible
                ).valueDesc;
            }

            // Validate responsible (Only if we show it)
            if (this.getResponsibleAttribute() !== 'H') {
                if (responsible && eqpResponsible && responsible !== eqpResponsible) {
                    validationResponsibleWarnings = translations.EQPSAMERESP;
                } else if (eqpResponsible) {
                    // Assign it
                    responsible = eqpResponsible;
                    responsibleDesc = eqpResponsibleDesc;
                }
            }

            // Validate location when !bufferZone
            if (!bufferZone && location && locationCode && location !== locationCode) {
                validationLocationWarnings = translations.EQPSAMELOC;
            } else if (!bufferZone && locationCode) {
                location = locationCode;
            }

            if (validationLocationWarnings || validationResponsibleWarnings) {
                showWarning(
                    `${translations.EQPINVALID} ${validationResponsibleWarnings || ''} ${
                        validationLocationWarnings || ''
                    }`
                );
            }

            // Validate Vacuum cleaner
            if (
                vcActions.includes(futureAction) &&
                (!inforEquipment || !applicationData.vacuumCleanerClassesList.includes(inforEquipment.classCode))
            ) {
                // Equipment is not vacuum cleaner
                throw Tools.createServerError(translations.EQPINVALID, translations.EPQISNOTVCL);
            }
        });

        // if different locations then we let it empty
        if (validationLocationWarnings) {
            location = undefined;
        }

        // if different responsibles then we let it empty
        if (validationResponsibleWarnings) {
            responsible = undefined;
            responsibleDesc = undefined;
        }

        // Set the data before finishing
        // If buffer zone, location will be the buffer zone
        const currentLocationCode = bufferZone ? bufferZone.locationCode : location;
        // Assign properties to the request
        updateRequestProperty('currentLocationCode', currentLocationCode);
        // Check if future action is 'declare use', in this case don't populate the responsible
        if (futureAction.indexOf('declare use') === -1) {
            updateRequestProperty('equipmentRespCode', responsible);
            updateRequestProperty('equipmentRespDesc', responsibleDesc);
        }

        // All ok
        return equipmentList;
    };

    /**
     * Process list of equipment and set it in the properties
     */
    processEquipments = (equipments) => {
        // Iterate over the equipments
        const mappedEquipments = equipments.map((equipment) => {
            // Change the description if needed
            if (!this.isReadOnlyDescription(equipment)) {
                equipment.equipmentDesc = '';
            }
            // Equipment
            return equipment;
        });
        // Return equipment list
        return mappedEquipments;
    };

    createPromiseArray = (equipments) =>
        equipments.split(',').map((equipment) => WSRPMRequest.readSelectedEquipmentData(equipment));

    // When Loading
    renderLoading = () => {
        const { translations } = this.props;
        return (
            <BlockUi tag="div" blocking style={blockUiStyle}>
                <div style={blockUiStyleDiv}>{translations.LOADING}</div>
            </BlockUi>
        );
    };

    // To determine if the property would be read only or not
    isReadOnlySerial = (equipment) => !!(equipment.inforEquipment && equipment.inforEquipment.serialNumber);

    isReadOnlyDescription = (equipment) => {
        const { applicationData } = this.props;
        return !!(
            equipment.inforEquipment &&
            equipment.inforEquipment.description &&
            equipment.inforEquipment.description.toUpperCase() !== applicationData.rpeqpNewDesc.toUpperCase()
        );
    };

    isReadOnlyResponsible = () =>
        /**
         * The responsible should be read only for the update location wizard.
         * The field should be hidden for the vacuum cleaner empty and end of use
         *
         * 2018-06-01: The field will be updateable all the time now
         */
        false;

    getResponsibleAttribute = () => {
        /**
         * The field should be hidden for the vacuum cleaner empty and end of use
         */
        const { request, constants } = this.props;
        const { futureAction } = request;
        return futureAction === constants.futureActionVacuumEmpty ||
            futureAction === constants.futureActionVacuumEnd ||
            futureAction === constants.futureActionVacuumEndFull
            ? 'H'
            : 'R';
    };

    // Render the content
    renderContent = () => {
        const { multipleEquipment } = this.state;
        return multipleEquipment ? this.renderMultiEquipment() : this.renderSingleEquipment();
    };

    updatePropertyMultiple = (key, value, index, isReadOnlyFunction) => {
        const { request, updateEquipmentProperty } = this.props;
        // Size of list
        const { equipment } = request;
        // If edit all and index 0, set to all of the equipments
        const { editAll } = this.state;
        if (editAll && index === 0) {
            // Update all (if no read onlt)
            equipment.forEach((elem, innerIndex) => {
                if (!isReadOnlyFunction(elem)) updateEquipmentProperty(key, value, innerIndex);
            });
        } else {
            // Normal update
            updateEquipmentProperty(key, value, index);
        }
    };

    // Render when multi equipment is selected
    renderMultiEquipment = () => {
        const { assetFields, request, translations, updateRequestProperty } = this.props;
        const { equipment } = request;
        const { editAll } = this.state;

        const equipmentList = equipment.map((elem, index) => {
            let eqp = { ...elem };
            // If description is editable
            if (!this.isReadOnlyDescription(elem)) {
                eqp = {
                    ...eqp,
                    equipmentDesc: (
                        <EAMInput
                            elementInfo={{
                                ...assetFields.equipmentdesc,
                                text: '',
                                attribute: 'R',
                                xpath: `${assetFields.equipmentdesc.xpath}_${index}`,
                            }}
                            valueKey="equipmentDesc"
                            value={eqp.equipmentDesc}
                            updateProperty={(key, value) => {
                                this.updatePropertyMultiple(key, value, index, this.isReadOnlyDescription);
                            }}
                            formFields={this.formFields}
                        />
                    ),
                };
            }
            // If serial is editable
            if (!this.isReadOnlySerial(elem)) {
                eqp = {
                    ...eqp,
                    serialNumber: (
                        <EAMInput
                            elementInfo={{
                                ...assetFields.serialnumber,
                                text: '',
                                xpath: `${assetFields.serialnumber.xpath}_${index}`,
                            }}
                            valueKey="serialNumber"
                            value={eqp.serialNumber}
                            updateProperty={(key, value) => {
                                this.updatePropertyMultiple(key, value, index, this.isReadOnlySerial);
                            }}
                            formFields={this.formFields}
                        />
                    ),
                };
            }
            // add information on responsible
            const customFields0003 = eqp.inforEquipment.customField.filter((c) => c.code === '0003');
            if (customFields0003 && customFields0003[0]) {
                const respInfoValue = customFields0003[0].value ? customFields0003[0].value : '';
                const respInfoValueDesc = customFields0003[0].valueDesc ? customFields0003[0].valueDesc : '';
                const respInfo = customFields0003[0].value ? `${respInfoValue} - ${respInfoValueDesc}` : '';
                eqp = {
                    ...eqp,
                    respInfo,
                };
            }
            return eqp;
        });

        const headers = [
            assetFields.equipmentno.text,
            assetFields.serialnumber.text,
            assetFields.equipmentdesc.text,
            assetFields.eqpResponsible.text,
            assetFields.location.text,
        ];
        const propCodes = ['equipmentCode', 'serialNumber', 'equipmentDesc', 'respInfo', 'locationCode'];

        return (
            <div style={{ width: '100%', height: '100%' }}>
                <EAMCheckbox
                    label={translations.EDITALL}
                    valueKey="editAll"
                    value={editAll}
                    updateProperty={(key, value) => {
                        this.setState({ [key]: value });
                    }}
                    trueValue
                    falseValue={false}
                />
                <EISTable data={equipmentList} headers={headers} propCodes={propCodes} maxMobileSize={600} />

                <EAMAutocomplete
                    elementInfo={{
                        ...assetFields.eqpResponsible,
                        readonly: this.isReadOnlyResponsible(),
                        attribute: this.getResponsibleAttribute(),
                    }}
                    value={request.equipmentRespCode}
                    valueDesc={request.equipmentRespDesc}
                    valueKey="equipmentRespCode"
                    descKey="equipmentRespDesc"
                    updateProperty={updateRequestProperty}
                    loadOptions={WSAutocomplete.autocompleteEmployee}
                    labelStyle={labelStyle}
                    formFields={this.formFields}
                />
            </div>
        );
    };

    // Render for a single equipment selection
    renderSingleEquipment = () => {
        const { assetFields, request, updateEquipmentProperty, updateRequestProperty } = this.props;
        const equipment = request.equipment[0];

        return (
            <div style={{ width: '100%', height: '100%' }}>
                <EAMInput
                    elementInfo={{ ...assetFields.equipmentno, readonly: true }}
                    valueKey="equipmentCode"
                    value={equipment.equipmentCode}
                    updateProperty={(key, value) => {
                        updateEquipmentProperty(key, value, 0);
                    }}
                    labelStyle={labelStyle}
                    formFields={this.formFields}
                />

                <EAMInput
                    elementInfo={{
                        ...assetFields.serialnumber,
                        readonly: this.isReadOnlySerial(equipment),
                    }}
                    valueKey="serialNumber"
                    value={equipment.serialNumber}
                    updateProperty={(key, value) => {
                        updateEquipmentProperty(key, value, 0);
                    }}
                    labelStyle={labelStyle}
                    formFields={this.formFields}
                />

                <EAMInput
                    elementInfo={{
                        ...assetFields.equipmentdesc,
                        readonly: this.isReadOnlyDescription(equipment),
                        attribute: 'R',
                    }}
                    valueKey="equipmentDesc"
                    value={equipment.equipmentDesc}
                    updateProperty={(key, value) => {
                        updateEquipmentProperty(key, value, 0);
                    }}
                    labelStyle={labelStyle}
                    formFields={this.formFields}
                />

                <EAMAutocomplete
                    elementInfo={{
                        ...assetFields.eqpResponsible,
                        readonly: this.isReadOnlyResponsible(),
                        attribute: this.getResponsibleAttribute(),
                    }}
                    value={request.equipmentRespCode}
                    valueDesc={request.equipmentRespDesc}
                    valueKey="equipmentRespCode"
                    descKey="equipmentRespDesc"
                    updateProperty={updateRequestProperty}
                    loadOptions={WSAutocomplete.autocompleteEmployee}
                    labelStyle={labelStyle}
                    formFields={this.formFields}
                />
            </div>
        );
    };

    render() {
        const { translations, request, userData } = this.props;
        const { multipleEquipment, loading } = this.state;
        const displayComments = !!(
            !loading &&
            !multipleEquipment &&
            request.equipment.length > 0 &&
            request.equipment[0].inforEquipment
        );
        return (
            <Grid container spacing={8}>
                <Grid item md={displayComments ? 6 : 12} sm={12} xs={12}>
                    <EISPanel heading={translations.EQPDETAILS} alwaysExpanded>
                        {loading ? this.renderLoading() : this.renderContent()}
                    </EISPanel>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    {displayComments && (
                        <CommentsContainer
                            title={translations.EQPCOMMENTS}
                            ref={(comments) => {
                                this.comments = comments;
                            }}
                            entityCode="OBJ"
                            entityKeyCode={request.equipment[0].equipmentCode}
                            userDesc={userData.eamAccount.userDesc}
                            readComments={WSComments.readComments}
                        />
                    )}
                </Grid>
            </Grid>
        );
    }
}

export default RPMREquipment;
