import EISPanel from 'react-eis-components/dist/ui/components/panel';
// import EquipmentOption from 'ui/components/autocomplete/EquipmentOption';
import { Grid, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { DATE_TIME_FORMAT } from 'enums/Constants';
import {
    TRANSLATION_KEYS,
    VACUUM_RENTAL_KEYS,
    VACUUM_RENTAL_LINE_KEYS,
} from 'ui/pages/vaccleanerrequest/VacCleanerConstants';
import { ENTITY_TRANSL_PREFIX } from 'ui/pages/wrrequest/WRRConstants';

const getMinStartDate = (vacuumRequest, initialValue) => {
    const minStartDate = vacuumRequest?.[VACUUM_RENTAL_KEYS.VACUUMS]?.reduce((min, line) => {
        const startDate = line[VACUUM_RENTAL_LINE_KEYS.START_DATE];
        return startDate < min ? startDate : min;
    }, initialValue);
    return minStartDate;
};

const getMaxEndDate = (vacuumRequest) => {
    const maxEndDate = vacuumRequest?.[VACUUM_RENTAL_KEYS.VACUUMS]?.reduce((max, line) => {
        const endDate = line[VACUUM_RENTAL_LINE_KEYS.END_DATE];
        return endDate > max ? endDate : max;
    }, 0);
    return maxEndDate;
};

const VacuumOverviewPanel = (props) => {
    const { getTranslation, vacuumRequest } = props;
    const panelRowStyle = {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 7,
        borderBottom: '1px solid #e0e0e0',
    };
    const panelColumnStyle = {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 7,
        borderBottom: 'none',
    };
    const labelStyle = {
        color: 'rgb(0, 101, 152)',
        fontWeight: 'bold',
    };

    return (
        <EISPanel
            heading={`${getTranslation('OVERVIEW')} ${
                vacuumRequest?.[VACUUM_RENTAL_KEYS.CODE] ? `(${vacuumRequest?.[VACUUM_RENTAL_KEYS.CODE]})` : ''
            }`}
            alwaysExpanded
        >
            <Grid item md={12} sm={12} xs={12} container>
                <div style={panelRowStyle}>
                    <Typography style={labelStyle}>{getTranslation(TRANSLATION_KEYS.STATUS)}</Typography>
                    <Typography
                        style={{
                            color: 'grey',
                            fontSize: 14,
                            fontStyle: 'italic',
                        }}
                    >
                        {vacuumRequest?.[VACUUM_RENTAL_KEYS.STATUS]
                            ? `${getTranslation(
                                  ENTITY_TRANSL_PREFIX.EVT_STATUS + vacuumRequest[VACUUM_RENTAL_KEYS.STATUS]
                              )} (${vacuumRequest[VACUUM_RENTAL_KEYS.STATUS]})`
                            : getTranslation('NOT_SAVED')}
                    </Typography>
                </div>
                {vacuumRequest?.[VACUUM_RENTAL_KEYS.FACILITY] && (
                    <div style={panelRowStyle}>
                        <Typography style={labelStyle}>{getTranslation(VACUUM_RENTAL_KEYS.FACILITY)}</Typography>
                        <Typography>{vacuumRequest?.[VACUUM_RENTAL_KEYS.FACILITY]}</Typography>
                    </div>
                )}
                {vacuumRequest?.[VACUUM_RENTAL_KEYS.USAGE_LOCATION] && (
                    <div style={panelRowStyle}>
                        <Typography style={labelStyle}>{getTranslation(VACUUM_RENTAL_KEYS.USAGE_LOCATION)}</Typography>
                        <Typography>{vacuumRequest?.[VACUUM_RENTAL_KEYS.USAGE_LOCATION]}</Typography>
                    </div>
                )}
                {vacuumRequest?.[VACUUM_RENTAL_KEYS.CREATOR] && (
                    <div style={panelRowStyle}>
                        <Typography style={labelStyle}>{getTranslation(VACUUM_RENTAL_KEYS.CREATOR)}</Typography>
                        <Typography
                            style={{
                                textAlign: 'right',
                                width: '65%',
                            }}
                        >
                            {vacuumRequest?.[VACUUM_RENTAL_KEYS.CREATOR]?.[VACUUM_RENTAL_KEYS.DESCRIPTION]}
                        </Typography>
                    </div>
                )}
                {vacuumRequest?.[VACUUM_RENTAL_KEYS.REQUESTER] && (
                    <div style={panelRowStyle}>
                        <Typography style={labelStyle}>{getTranslation(VACUUM_RENTAL_KEYS.REQUESTER)}</Typography>
                        <Typography
                            style={{
                                textAlign: 'right',
                                width: '65%',
                            }}
                        >
                            {vacuumRequest?.[VACUUM_RENTAL_KEYS.REQUESTER]?.[VACUUM_RENTAL_KEYS.DESCRIPTION]}
                        </Typography>
                    </div>
                )}
                {vacuumRequest?.[VACUUM_RENTAL_KEYS.MODIFIED_ON] && (
                    <div style={panelRowStyle}>
                        <Typography style={labelStyle}>{getTranslation(VACUUM_RENTAL_KEYS.MODIFIED_BY)}</Typography>
                        <Typography
                            style={{
                                textAlign: 'right',
                                width: '65%',
                            }}
                        >
                            {vacuumRequest?.[VACUUM_RENTAL_KEYS.MODIFIED_BY]?.[VACUUM_RENTAL_KEYS.DESCRIPTION]} <br />
                            <span style={{ color: 'grey', fontSize: 14, fontStyle: 'italic' }}>
                                {format(
                                    vacuumRequest?.[VACUUM_RENTAL_KEYS.MODIFIED_ON],
                                    DATE_TIME_FORMAT.HUMAN_DATE_FNS
                                )}
                            </span>
                        </Typography>
                    </div>
                )}
                {vacuumRequest?.[VACUUM_RENTAL_KEYS.VACUUMS] && (
                    <div style={{ ...panelColumnStyle, borderBottom: panelRowStyle.borderBottom }}>
                        <div style={{ ...panelRowStyle, borderBottom: panelColumnStyle.borderBottom }}>
                            <Typography style={labelStyle}>{getTranslation(TRANSLATION_KEYS.RESERVATIONS)}</Typography>
                            <Typography style={labelStyle}>{getTranslation(TRANSLATION_KEYS.STATUS)}</Typography>
                        </div>
                        <div style={panelColumnStyle}>
                            {vacuumRequest?.[VACUUM_RENTAL_KEYS.VACUUMS]?.map((line) => (
                                <div
                                    style={{ ...panelRowStyle, borderBottom: panelColumnStyle.borderBottom }}
                                    key={line[VACUUM_RENTAL_LINE_KEYS.CODE]}
                                >
                                    <Typography>{line[VACUUM_RENTAL_LINE_KEYS.DESCRIPTION]}</Typography>
                                    <Typography
                                        style={{
                                            color: 'grey',
                                            fontSize: 14,
                                            fontStyle: 'italic',
                                            minWidth: '35%',
                                            textAlign: 'right',
                                        }}
                                    >
                                        {line[VACUUM_RENTAL_LINE_KEYS.STATUS]
                                            ? `${getTranslation(
                                                  ENTITY_TRANSL_PREFIX.RESERVATION_STATUS +
                                                      line[VACUUM_RENTAL_LINE_KEYS.STATUS]
                                              )} (${line[VACUUM_RENTAL_LINE_KEYS.STATUS]})`
                                            : 'NOT SAVED'}
                                    </Typography>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {vacuumRequest?.[VACUUM_RENTAL_KEYS.VACUUMS] && (
                    <div style={{ ...panelColumnStyle, borderBottom: panelRowStyle.borderBottom }}>
                        <div style={{ ...panelRowStyle, borderBottom: panelColumnStyle.borderBottom }}>
                            <Typography style={labelStyle}>
                                {getTranslation(TRANSLATION_KEYS.PER_VACUUM_CLEANER)}
                            </Typography>
                        </div>
                        <div
                            style={{
                                ...panelRowStyle,
                                alignItems: 'flex-end',
                                justifyContent: 'flex-start',
                                flexWrap: 'wrap',
                                borderBottom: panelColumnStyle.borderBottom,
                            }}
                        >
                            {vacuumRequest?.[VACUUM_RENTAL_KEYS.VACUUMS]?.map((line, index) => {
                                const length = vacuumRequest?.[VACUUM_RENTAL_KEYS.VACUUMS]?.length;
                                return (
                                    <div
                                        key={line[VACUUM_RENTAL_LINE_KEYS.CODE]}
                                        style={{
                                            ...panelColumnStyle,
                                            width: '49%',
                                            textAlign: 'center',
                                            marginBottom: 0,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            borderLeft:
                                                length > 1 && (index + 1) % 2 === 0
                                                    ? panelRowStyle.borderBottom
                                                    : 'none',
                                            borderBottom:
                                                (length - index > 2 && length % 2 === 0) ||
                                                (length - index > 1 && length % 2 === 1)
                                                    ? panelRowStyle.borderBottom
                                                    : 'none',
                                        }}
                                    >
                                        {line[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE] ? (
                                            <a
                                                href={`${process.env.REACT_APP_FRONTEND}equipment/${
                                                    line[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE]
                                                }`}
                                                style={{ textDecoration: 'none' }}
                                            >
                                                <Typography>{line[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE]}</Typography>
                                            </a>
                                        ) : (
                                            <Typography style={{ color: 'grey', fontSize: 24 }}>-</Typography>
                                        )}
                                        <Typography style={{ color: 'grey', fontSize: 10 }}>
                                            ({line[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE]})
                                        </Typography>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                {vacuumRequest?.[VACUUM_RENTAL_KEYS.VACUUMS] && (
                    <>
                        <div style={panelRowStyle}>
                            <Typography style={labelStyle}>
                                {getTranslation(VACUUM_RENTAL_LINE_KEYS.START_DATE)}
                            </Typography>
                            <Typography>
                                {format(
                                    getMinStartDate(vacuumRequest, getMaxEndDate(vacuumRequest)),
                                    DATE_TIME_FORMAT.DATE_FNS
                                )}
                            </Typography>
                        </div>
                        <div style={panelRowStyle}>
                            <Typography style={labelStyle}>
                                {getTranslation(VACUUM_RENTAL_LINE_KEYS.END_DATE)}
                            </Typography>
                            <Typography>{format(getMaxEndDate(vacuumRequest), DATE_TIME_FORMAT.DATE_FNS)}</Typography>
                        </div>
                    </>
                )}
            </Grid>
        </EISPanel>
    );
};

export default VacuumOverviewPanel;
