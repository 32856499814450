import { generatePath, Link } from 'react-router-dom';
import { generateRoute, getInternalLink, ROUTES } from 'tools/Routes';
import TRECButton from 'ui/components/buttons/TRECButton';
import { GRID_ROW_COLORS } from '../../../../enums/Constants';
import Tools from '../../../../tools/Tools';
import EAMGridNoOverflow from '../../../components/grid/EAMGridNoOverflow';
import gridNoOverflowClasses from '../../../components/grid/EAMGridNoOverflow.module.css';
import { getRowAsAnObject } from '../../rwmanagement/RWManagementUtils';
import TRECPage from '../../TRECPage';
import { TranslatedDiv } from '../../wrsearch/WaterReleaseSearch';

const CELL_TYPES = {
    JOB_NUMBER: 'evt_code',
    DESCRIPTION: 'evt_desc',
    EDH_DOCUMENT_NUMBER: 'edh_document_nr',
    STATUS: 'evt_status',
    PACKAGES: 'packages',
};

const getCellRenderer =
    ({ getTranslation }) =>
    ({ t, value }, row) => {
        const object = getRowAsAnObject(row);
        if (t === CELL_TYPES.JOB_NUMBER) {
            return <Link to={generatePath(ROUTES.rwEliminationEdit, { eliminationNumber: value })}>{value}</Link>;
        }
        if (t === CELL_TYPES.DESCRIPTION) {
            return <TranslatedDiv code={object[CELL_TYPES.DESCRIPTION]} getTranslation={getTranslation} />;
        }
        if (t === CELL_TYPES.EDH_DOCUMENT_NUMBER) {
            return <TranslatedDiv code={object[CELL_TYPES.EDH_DOCUMENT_NUMBER]} getTranslation={getTranslation} />;
        }
        if (t === CELL_TYPES.STATUS) {
            return <TranslatedDiv code={object[CELL_TYPES.STATUS]} getTranslation={getTranslation} />;
        }
        if (t === CELL_TYPES.PACKAGES) {
            const packages = value?.split(',');
            return packages?.map((pck) =>
                getInternalLink({
                    route: generateRoute(ROUTES.equipmentOverview, { equipmentID: pck }),
                    text: pck,
                })
            );
        }
        return null;
    };

const getGridRequestAdapter =
    ({ writeAccess, userData }) =>
    (gridRequest) => {
        let gridFilters = [...gridRequest.gridFilter];
        // People with no write access can only see their requests
        if (!writeAccess) {
            gridFilters = gridFilters.filter((f) => !['evt_createdby', 'evt_origin'].includes(f.fieldName));
            gridFilters.push({
                fieldName: 'evt_createdby',
                fieldValue: userData.eamAccount.userCode,
                operator: 'EQUALS',
                joiner: 'OR',
                leftParenthesis: true,
            });
            gridFilters.push({
                fieldName: 'evt_origin',
                fieldValue: userData.eamAccount.employeeCode,
                operator: 'EQUALS',
                joiner: 'AND',
                rightParenthesis: true,
            });
        }
        return {
            ...gridRequest,
            gridFilter: gridFilters,
        };
    };

const rowStyler = (row) => {
    const rowObject = Tools.getRowAsAnObject(row);
    return GRID_ROW_COLORS[rowObject.row_color] || {};
};

const RWEliminationGrid = ({ userData, writeAccess, applicationData, getTranslation, history, mode, lang }) => (
    <div className={gridNoOverflowClasses.outerBlock}>
        <div style={{ display: 'flex', direction: 'row' }}>
            <TRECButton
                style={{ marginLeft: 10, alignSelf: 'flex-start' }}
                onClick={() => {
                    history.push(ROUTES.rwEliminationCreate);
                }}
            >
                {getTranslation('CREATE_NEW')}
            </TRECButton>
            <TRECButton
                style={{ marginLeft: 10, alignSelf: 'flex-start' }}
                disabled={!mode}
                onClick={() => {
                    history.push(ROUTES.rwElimination);
                }}
            >
                {getTranslation('ELIM_JOBS_GRID')}
            </TRECButton>
            <TRECButton
                style={{ marginLeft: 10, alignSelf: 'flex-start' }}
                disabled={mode === 'ASSET'}
                onClick={() => {
                    history.push(ROUTES.rwEliminationAssets);
                }}
            >
                {getTranslation('ELIM_ASSETS_GRID')}
            </TRECButton>
        </div>
        {mode === 'ASSET' ? (
            <EAMGridNoOverflow
                gridId={applicationData.rweliminationAssetsGridID}
                gridRequestAdapter={getGridRequestAdapter({ writeAccess, userData })}
                bypassAlternateRows
                filterVisible
                cellRenderer={getCellRenderer({ getTranslation })}
                heightFilterVisible="245px"
                heightFilterNotVisible="195px"
                rowStyler={rowStyler}
                hiddenTags={['row_color']}
                language={lang}
            />
        ) : null}
        {mode !== 'ASSET' ? (
            <EAMGridNoOverflow
                gridId={applicationData.rweliminationJobsGridID}
                gridRequestAdapter={getGridRequestAdapter({ writeAccess, userData })}
                bypassAlternateRows
                filterVisible
                cellRenderer={getCellRenderer({ getTranslation })}
                heightFilterVisible="245px"
                heightFilterNotVisible="195px"
                rowStyler={rowStyler}
                hiddenTags={['row_color']}
                language={lang}
            />
        ) : null}
    </div>
);

class RWEliminationManage extends TRECPage {
    renderPage(writeAccess) {
        return <RWEliminationGrid writeAccess={writeAccess} {...this.props} />;
    }
}

export default RWEliminationManage;
