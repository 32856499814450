import WS from './WS';

class WSBZInventory {
    getInventoryData(bufferZone, config = {}) {
        const bufferZoneEncoded = encodeURIComponent(bufferZone);
        return WS._get(`/inventory/items/?bufferZone=${bufferZoneEncoded}`, config);
    }

    getPastInventoryMetadata(inventoryNumber, config = {}) {
        const inventoryNumberEncoded = encodeURIComponent(inventoryNumber);
        return WS._get(`/inventory/pastinventory/?number=${inventoryNumberEncoded}`, config);
    }

    getSingleInventoryItem(code, config = {}) {
        const encodedCode = encodeURIComponent(code);
        return WS._get(`/inventory/item/?code=${encodedCode}`, config);
    }

    postInventoryData(bufferZone, comment, data, config = {}) {
        const bufferZoneEncoded = encodeURIComponent(bufferZone);
        const dataToPost = {
            items: data,
            comment,
        };

        return WS._post(`/inventory/items/?bufferZone=${bufferZoneEncoded}`, dataToPost, config);
    }
}

export default new WSBZInventory();
