export const RESET_RWP = 'RESET_RWP';
export const SET_RWP_BLOCK_UI = 'SET_RWP_BLOCK_UI';
export const SET_RWP_APPLY_ALL_LINES = 'SET_RWP_APPLY_ALL_LINES';
export const UPDATE_RWP_PROPERTIES = 'UPDATE_RWP_PROPERTIES';
export const UPDATE_RWP_OUTPUT_EQUIPMENT = 'UPDATE_RWP_OUTPUT_EQUIPMENT';
export const UPDATE_RWP_OUTPUT_EQUIPMENT_MAP = 'UPDATE_RWP_OUTPUT_EQUIPMENT_MAP';
export const UPDATE_RWP_RPM_MAP = 'UPDATE_RWP_RPM_MAP';
export const SET_RWP_LOADED_DEVICES_SN = 'SET_RWP_LOADED_DEVICES_SN';

export const resetRWP = () => ({
    type: RESET_RWP,
});

export const setRWPBlockUI = (value) => ({
    type: SET_RWP_BLOCK_UI,
    value,
});

export const setApplyAllLines = (value) => ({
    type: SET_RWP_APPLY_ALL_LINES,
    value,
});

export const updateRWPProperties = (value) => ({
    type: UPDATE_RWP_PROPERTIES,
    value,
});

export const updatedOutputEquipment = (eqCodeList, object) => ({
    type: UPDATE_RWP_OUTPUT_EQUIPMENT,
    eqCodeList,
    object,
});

export const updatedOutputEquipmentMap = (value) => ({
    type: UPDATE_RWP_OUTPUT_EQUIPMENT_MAP,
    value,
});

export const updateRPMMap = (value) => ({
    type: UPDATE_RWP_RPM_MAP,
    value,
});

export const setLoadedDevicesSN = (value) => ({
    type: SET_RWP_LOADED_DEVICES_SN,
    value,
});

export default {
    resetRWP,
    setRWPBlockUI,
    setApplyAllLines,
    updateRWPProperties,
    updatedOutputEquipment,
    updatedOutputEquipmentMap,
    updateRPMMap,
    setLoadedDevicesSN,
};
