import TRECPage from './TRECPage';

export default class TRECSearchPage extends TRECPage {
    state = {
        filters: {},
        layout: { panelExpanded: true },
        processing: false,
        selectedRows: {},
        autorun: false,
    };

    adaptColumnNameWithElementInfo(columnName) {
        if (columnName) {
            return {
                [columnName]: columnName,
            };
        }
        return {};
    }

    pushGridFiltersToPageFilters(excludedFilters = []) {
        if (!this || !this.grid) return;
        const columnsMap = this.adaptColumnNameWithElementInfo();
        const filters = Object.values(this.grid.filterMap)
            .filter((value) => !excludedFilters.includes(value.fieldName))
            .reduce((acc, f) => {
                const filterName = [].concat(
                    Object.keys(columnsMap).filter((key) => columnsMap[key] === f.fieldName),
                    f.fieldName
                )[0];
                return {
                    ...acc,
                    [filterName]: f.fieldValue,
                };
            }, {});

        this.setState({ filters });
    }

    refreshPageFiltersFromGridFilters() {
        Object.keys(this.state.filters).forEach((key) => {
            const columnsMap = this.adaptColumnNameWithElementInfo(key);
            const filterVal = this.grid.state.gridRequest.gridFilter.filter((f) => f.fieldName === columnsMap[key])[0];
            this.setState((prevState) => ({
                filters: {
                    ...prevState.filters,
                    [key]: filterVal ? filterVal.fieldValue : '',
                },
            }));
        });
    }

    componentDidMount() {
        this.pushGridFiltersToPageFilters();
    }

    _onPanelChange = (expanded) => {
        this.setState((prevState) => ({
            layout: {
                ...prevState.layout,
                panelExpanded: expanded,
            },
        }));
    };

    updateProperty = (key, selectedObject, operator, dataType, forceCaseInsensitive = false) => {
        const isArray = Array.isArray(selectedObject);
        let value = '';
        let fieldOperator = operator;
        if (isArray) {
            if (selectedObject.length > 1) {
                // convert to CSV
                value = selectedObject.map((e) => e.code).join(',');
                fieldOperator = 'IN';
            } else if (selectedObject.length === 1) {
                // Only one element
                [value] = selectedObject;
                if (value && value.code) value = value.code;
                if (value.split(',').length > 1) fieldOperator = 'IN';
            }
        } else if (selectedObject) {
            if (selectedObject.code) {
                value = selectedObject.code;
            } else {
                value = selectedObject;
            }
        }
        // For codes
        const columnsMap = this.adaptColumnNameWithElementInfo(key);

        if (this.grid) {
            this.grid.setFilter(
                {
                    fieldName: columnsMap[key],
                    fieldValue: value,
                    operator: fieldOperator,
                    forceCaseInsensitive,
                },
                dataType,
                // Update filters
                this.pushGridFiltersToPageFilters.bind(this, !this.state.searchStructure ? [] : ['obj_code'])
            );
        }
    };

    onSearchClick = (pushFilters = true) => {
        if (this.grid) {
            this.setState({ selectedRows: {} }, () => {
                this.grid.runSearch();
                pushFilters && this.pushGridFiltersToPageFilters();
            });
        }
    };

    onClearClick = (callback) => {
        if (this.grid) {
            this.setState(
                () => ({ selectedRows: {} }),
                () => {
                    this.grid.clearFilters(() => {
                        this.refreshPageFiltersFromGridFilters();
                        if (typeof callback === 'function') callback();
                    });
                }
            );
        }
    };

    onKeyDown = (event, keyName = 'Enter', method = this.onSearchClick) => {
        if (event.key === keyName) {
            method(false);
        }
    };

    onSelectRow = (row, checked, selectedRows) => {
        // Set the selected rows by default
        this.setState(() => ({ selectedRows }));
    };

    //
    // STATE MANIPULATORS
    //
    setLayout(layout) {
        this.setState((prevState) => ({ layout: { ...prevState.layout, ...layout } }));
    }

    //
    // For location filters
    //
    _isTopLocation = (value) => ['M', 'P', 'EL', 'ES', 'SPS-NORTH-AREA'].includes(value);

    _isSiteLocation = (value) => ['L1', 'L18', 'L2', 'L3', 'L4', 'L5', 'L6', 'L7', 'L8'].includes(value);

    _getGridFilterLocation = (gridFilter, value, locisbzField, topLocationField, siteLocationField, field) => {
        // All buffer zones
        if (gridFilter.fieldValue === 'ALLBUFFERZONES') {
            return { ...gridFilter, fieldName: locisbzField, fieldValue: '1', operator: 'EQUALS' };
        }
        // Top locations
        if (this._isTopLocation(value)) {
            return { ...gridFilter, fieldName: topLocationField, operator: 'EQUALS' };
        }
        // Else if
        if (this._isSiteLocation(value)) {
            return { ...gridFilter, fieldName: siteLocationField, operator: 'EQUALS' };
        }
        // Normal locations

        return { ...gridFilter, fieldName: field };
    };
}
