import WS from './WS';

class WSJobsReport {
    getReportData(from, to, location = null) {
        return WS._get(`/report/jobs/?from=${from}&to=${to}${location ? `&location=${location}` : ''}`, {
            timeout: 200000,
        });
    }
}

export default new WSJobsReport();
