import { connect } from 'react-redux';
import WorkOrdersRedirect from './WorkOrdersRedirect';

const mapStateToProps = (state) => ({
    applicationData: state.application.applicationData,
});

const WorkOrdersRedirectContainer = connect(mapStateToProps, {})(WorkOrdersRedirect);

export default WorkOrdersRedirectContainer;
