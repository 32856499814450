import { connect } from 'react-redux';
import TREC from './TREC';
import { logout, login } from './actions/applicationActions';
import initializeApplication from './actions/actions';

const mapStateToProps = (state) => ({
    userData: state.application.userData,
    loading: state.application.loading,
    applicationData: state.application.applicationData,
    loginToken: state.application.loginToken,
    guestUsername: state.application.guestUsername,
    bufferZone: state.application.bufferZone,
    bufferZoneAccount: state.application.applicationData ? state.application.applicationData.bufferZoneAccount : '',
    screenData: state.application.screenData,
    store: state,
});

const TRECContainer = connect(mapStateToProps, {
    login,
    logout,
    initializeApplication,
})(TREC);

export default TRECContainer;
