import { setPage, updateLayout } from 'actions/layoutActions';
import { openConfirmDialog } from 'react-eis-components/dist/actions/uiActions';
import { connect } from 'react-redux';
import EDHDocmng from './EDHDocmng';

function mapStateToProps(state) {
    return {
        screenData: state.application.screenData,
        userData: state.application.userData,
        applicationData: state.application.applicationData,
        bufferZone: state.application.bufferZone,
        lang: state.layout.lang,
        constants: state.application.constants,
        menuType: state.application.menuType,
        translations: state.application.screenData[state.layout.lang].translations.TREC_EDHDOCMNG,
        masterPage: 'EDHDOC_MNG',
        page: 'EDHDOC_MNG',
        menu: state.application.screenData[state.layout.lang].menu[state.application.menuType],
    };
}

const EDHDocmngContainer = connect(mapStateToProps, {
    updateLayout,
    setPage,
    openConfirmDialog,
})(EDHDocmng);

export default EDHDocmngContainer;
