import { connect } from 'react-redux';
import rparActions from '../../../actions/rparActions';
import RPARequestLoader from './RPARequestLoader';

function mapStateToProps(state) {
    const { applicationData } = state.application;

    return {
        applicationData,
    };
}

const RPARequestLoaderContainer = connect(mapStateToProps, {
    ...rparActions,
})(RPARequestLoader);

export default RPARequestLoaderContainer;
