import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Menu from '@material-ui/icons/Menu';
import { Wizard } from 'amm-tools';
import BlockUi from 'react-block-ui';
import DefaultStepper from 'ui/components/wizard/DefaultStepper';
import rpsmActions from '../../../actions/rpsmActions';
import { EQUIPMENT_KEYS, RPSM_KEYS, TREC_GENERAL_KEYS } from '../../../enums/Constants';
import Tools from '../../../tools/Tools';
import WSRPARequest from '../../../tools/rest/WSRPARequest';
import WSRPSampling from '../../../tools/rest/WSRPSampling';
import TRECButton from '../../components/buttons/TRECButton';
import TRECPage from '../TRECPage';
import autoFilledRPARButtonProps from '../rparequest/RPARequestUtils';
import RPSMAssetSelection from './steps/RPSMAssetSelection';
import RPSMEquipmentSelection from './steps/RPSMEquipmentSelection';
import RPSMPurpose from './steps/RPSMPurpose';
import RPSMSampleDetails from './steps/RPSMSampleDetails';
import { handleError, showSuccess } from 'tools/TrecNotifications';

class RPSampling extends TRECPage {
    state = {
        values: {},
        valuesGrid: {},
        loading: true,
        successfulSave: false,
    };

    componentDidMount() {
        const { setPage, masterPage, page } = this.props;
        setPage(masterPage, page);

        Promise.all([WSRPARequest.getValuesGrid(), WSRPARequest.getValuesDef()]).then((respArray) => {
            const valuesDefMap = respArray[1].body.data.reduce(
                (acc, value) => ({
                    ...acc,
                    [value.field]: [
                        ...(acc[value.field] || []),
                        value.description ? { code: value.value, desc: value.description } : value.value,
                    ],
                }),
                {}
            );
            this.setState({ values: valuesDefMap, valuesGrid: respArray[0].body.data, loading: false });
        });
    }

    componentWillUnmount() {
        const { resetRPSM } = this.props;
        resetRPSM();
    }

    getSteps = () => {
        const { getTranslation, rpsmGetters, lang } = this.props;

        const rpsmValues = Object.entries(this.state.values).reduce(
            (acc, [code, list]) =>
                Object.assign(acc, {
                    [code]: list.map((codeOrObj) =>
                        typeof codeOrObj === 'object' ? codeOrObj : { code: codeOrObj, desc: getTranslation(codeOrObj) }
                    ),
                }),
            {}
        );

        const propList = ['screenData', 'getTranslation', 'applicationData', 'translations', 'userData'];

        const stepProps = {
            ...Tools.aggregateEntries(propList.map((key) => [key, this.props[key]])),
            rpsmValues,
            rpsmGetters,
            // Need to get the actions from props otherwise the dispatch function is not connected
            // This way, any method added to the rpsmActions is injected into rpsm actions automatically
            rpsmActions: Tools.applyToFieldsKV(rpsmActions, (key, _) => this.props[key]),
            lang,
        };

        return {
            PURPOSE: {
                key: 'PURPOSE',
                label: getTranslation('PURPOSE'),
                getContent: (props) => <RPSMPurpose {...stepProps} {...props} />,
                extraProps: {},
            },
            SELECT_EQUIPMENT: {
                label: getTranslation('ASSET_SELECTION'),
                getContent: (props) => <RPSMEquipmentSelection {...stepProps} {...props} />,
                extraProps: {},
            },
            SAMPLE_DETAILS: {
                label: getTranslation('SAMPLE_DETAILS'),
                getContent: (props) => <RPSMSampleDetails {...stepProps} {...props} />,
            },
            ASSET_SELECTION: {
                label: getTranslation('ASSET_SELECTION'),
                getContent: (props) => <RPSMAssetSelection {...stepProps} {...props} />,
            },
        };
    };

    getStepSequence = () => {
        const { rpasamplingStepSequence } = this.props;
        return rpasamplingStepSequence;
    };

    handleCancel = () => {
        this.props.resetRPSM();
        this.props.history.push('/menu');
    };

    handleSave = () => {
        const { rpsmGetters, getTranslation, applicationData } = this.props;
        const origins = rpsmGetters.getOrigins();
        const originsWithCode = rpsmGetters.getOriginsWithCode();
        const originsMap = originsWithCode.reduce(
            (acc, e) => ({
                ...acc,
                [origins[e][EQUIPMENT_KEYS.EQUIPMENT_CODE]]: origins[e],
            }),
            {}
        );

        this.setState({ loading: true });
        Promise.all([
            WSRPSampling.updateEquipment(rpsmGetters.getSamples()),
            ...(Object.keys(originsMap).length
                ? [WSRPARequest.updateMultipleEquipment({ equipmentMap: originsMap, applicationData })]
                : []),
        ])
            .then(() => {
                showSuccess(getTranslation(RPSM_KEYS.SAMPLING_SUCCESS_MSG));
                this.setState({ successfulSave: true });
            })
            .catch(handleError)
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    renderControls = ({ handlePrevious, handleNext, isFirstStep, isLastStep }) => {
        const { getTranslation, rpsmGetters } = this.props;
        const { successfulSave } = this.state;
        const samplesForAnalysis = rpsmGetters.getSamplesForAnalysis();
        return (
            <>
                <TRECButton onClick={this.handleCancel} color="default">
                    <Menu />
                    MENU
                </TRECButton>

                <TRECButton disabled={isFirstStep} onClick={handlePrevious}>
                    <ArrowBack />
                    {getTranslation(TREC_GENERAL_KEYS.BACK)}
                </TRECButton>

                {!isLastStep ? (
                    <TRECButton onClick={handleNext}>
                        <ArrowForward />
                        {getTranslation(TREC_GENERAL_KEYS.NEXT)}
                    </TRECButton>
                ) : (
                    <>
                        <TRECButton onClick={this.handleSave}>{getTranslation(RPSM_KEYS.SAVE)}</TRECButton>
                        <TRECButton
                            {...autoFilledRPARButtonProps({ equipmentList: samplesForAnalysis })}
                            disabled={!successfulSave}
                        >
                            {getTranslation(RPSM_KEYS.JUMP_TO_REQUEST)}
                        </TRECButton>
                    </>
                )}
            </>
        );
    };

    renderPage() {
        return (
            <BlockUi blocking={this.state.loading} style={{ height: '100%', width: '100%' }}>
                <Wizard
                    {...this.props}
                    title=""
                    steps={this.getSteps()}
                    stepSequence={this.getStepSequence()}
                    firstStepIndex={this.props.firstStepIndex || 0}
                    controls={(args) => this.renderControls(args)}
                    renderStepper={DefaultStepper}
                />
            </BlockUi>
        );
    }
}

export default RPSampling;
