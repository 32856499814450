import React from 'react';

const JobsReportResultLine = (props) => {
    const lineStyle = {
        padding: '5px',
        paddingLeft: '15px',
    };

    const { text, value } = props;

    return (
        <div style={lineStyle}>
            {text}: {value}
        </div>
    );
};

export default JobsReportResultLine;
