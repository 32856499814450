import Grid from '@material-ui/core/Grid';
import parse from 'date-fns/parse';
import ChecklistsContainer from 'eam-components/dist/ui/components/checklists/ChecklistsContainer';
import CommentsContainer from 'eam-components/dist/ui/components/comments/CommentsContainer';
import EDMSWidgetContainer from 'eam-components/dist/ui/components/edms/EDMSWidgetContainer';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import queryString from 'query-string';
import React from 'react';
import BlockUi from 'react-block-ui';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import { DATE_TIME_FORMAT } from '../../../enums/Constants';
import WSAutocomplete from '../../../tools/rest/WSAutocomplete';
import WSComments from '../../../tools/rest/WSComments';
import WSEDMS from '../../../tools/rest/WSEDMS';
import WSMeasurements from '../../../tools/rest/WSMeasurements';
import WSRPMeasurements from '../../../tools/rest/WSRPMeasurement';
import WSRWChecks from '../../../tools/rest/WSRWChecks';
import { INFORMATION_SOURCE } from '../eqtree/Constants';
import EquipmentTreeContainer from '../eqtree/EquipmentTreeContainer';
import { EQUIPMENT_KEYS as RWP_EQUIPMENT_KEYS } from '../rwprocessing/RWPConstants';
import RWProcessingTools from '../rwprocessing/RWProcessingTools';
import TRECPage from '../TRECPage';
import ContaminationMeasurements from './components/ContaminationMeasurements';
import EquipmentDetails from './components/EquipmentDetails';
import MeasurementDetails from './components/MeasurementDetails';
import RadiationMeasurements from './components/radiationMeasurements/RadiationMeasurements';
import RWEquipmentDetails from './components/RWEquipmentDetails';
import Status from './components/Status';
import Workorders from './components/Workorders';
import RPMPerformToolbar from './RPMPerformToolbar';
import { handleError, showError, showSuccess } from 'tools/TrecNotifications';
import WSRWPProcessing from 'tools/rest/WSRWPProcessing';

const isBelowInStructure = (structure, source, target) =>
    source
        ? source === target ||
          isBelowInStructure(structure, structure.edges.find((s) => s.target === source)?.source, target)
        : false;

class RPMPerform extends TRECPage {
    state = {
        measurements: null,
        measurementsDetails: {},
        currentMeasurement: undefined,
        currentMeasurementStatus: undefined,
        structure: undefined,
    };

    //
    // STYLES
    //
    mainDivStyle = {
        display: 'flex',
        height: '100%',
    };

    contentDivStyle = {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    };

    containerStyle = {
        padding: 10,
        flex: '1 1 auto',
        position: 'relative',
        overflowY: 'auto',
        overflowX: 'hidden',
    };

    //
    //
    //
    updateProperty(path, value) {
        if (!this.isJobClosed() || value) {
            this.setState((state) => ({
                measurementsDetails: cloneDeep(set(state.measurementsDetails, path, value)),
            }));
        }
    }

    UNSAFE_componentWillMount() {
        this.getMeasurements(this.getWorkOrderNumbers());
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props;
        if (prevProps.location.search !== location.search) {
            this.getMeasurements(this.getWorkOrderNumbers());
        }
    }

    getMeasurements = (jobNumber) => {
        this.setState({
            measurements: null,
            measurementsDetails: {},
            currentMeasurement: undefined,
            currentMeasurementStatus: undefined,
        });

        WSMeasurements.getMeasurements(jobNumber)
            .then((response) => {
                this.setCurrentMeasurement(response.body.data[0].jobNumber);
                this.setState({ measurements: response.body.data });
            })
            .catch(handleError);
    };

    setCurrentMeasurement = (currentMeasurement) => {
        // Has the measurement already been fetched?
        if (this.state.measurementsDetails[currentMeasurement]) {
            this.setState({
                currentMeasurement,
            });
            return;
        }

        // Measurement must be fetched
        this.readCurrentMeasurementAndAllDetails(currentMeasurement);
    };

    attachVirtualCode = async () => {
        try {
            this.setState({ blocking: true });
            const { measurementsDetails, currentMeasurement } = this.state;
            const measurement = measurementsDetails[currentMeasurement];
            const resp = await WSRWPProcessing.attachVirtualCodeInside(measurement.rwEquipment?.[0]?.code);
            showSuccess(resp);
            this.resetValuesHandler();
            this.setState({ blocking: false, attachedVirtual: true });
        } catch (err) {
            console.error(err);
            showError(err);
            this.setState({ blocking: false });
        }
    };

    shouldConsiderRWReception = (measurementDetail = this.state.measurementsDetails[this.state.currentMeasurement]) =>
        measurementDetail.futureAction === this.props.constants.futureActionWasteReceive ||
        (measurementDetail.department === this.props.applicationData.rwsection &&
            measurementDetail.futureAction === this.props.constants.futureActionDecwaste); // checkr: remove futureActionDecwaste

    readCurrentMeasurementAndAllDetails = (currentMeasurement) => {
        const { applicationData } = this.props;

        this.setState({ blocking: true });

        const measurementDetailsPromises = this.getWorkOrderNumbers().map((wo) =>
            WSMeasurements.getMeasurementDetails({ applicationData, workorder: wo })
        );

        Promise.all([...measurementDetailsPromises, WSRPMeasurements.getLastCompletedRPJob(currentMeasurement)])
            .then((responses) => {
                const lastRpjob = responses.pop().body.data;

                const measurementDetails = responses.reduce((acc, response) => {
                    const measurementDetail = response.body.data;
                    return {
                        ...acc,
                        [measurementDetail.jobNumber]: measurementDetail,
                    };
                }, {});

                this.setState({
                    currentMeasurement,
                    measurementsDetails: measurementDetails,
                    blocking: false,
                    lastRpjob,
                    currentMeasurementStatus: measurementDetails[currentMeasurement]?.statusCode,
                });
            })
            .catch(handleError);
    };

    isDoseRateContactPresent = (distances, devices) =>
        distances.reduce((acc, s, i) => (s === '0' ? [...acc, devices[i]] : acc), []).filter((e) => e !== 'BGO')
            .length > 0;

    //
    // VALIDATION
    //
    validate = () => {
        const { constants, translations, applicationData } = this.props;
        const { measurementsDetails, currentMeasurement } = this.state;

        const measurement = measurementsDetails[currentMeasurement];
        const devices = [measurement.device1, measurement.device2, measurement.device3, measurement.device4];
        const distances = [measurement.distance1, measurement.distance2, measurement.distance3, measurement.distance4];
        let errorMessage = '';
        const { futureAction, rwCheckGeneration, department } = measurement;
        const isRWCDepartment = department === applicationData.rwsection;

        // EAM-1680 - Only radioactive assets can be received as waste
        if (
            measurement.statusCode === constants.statusWoT &&
            measurement.radiationClassification !== constants.radioactive &&
            futureAction === constants.futureActionWasteReceive
        ) {
            errorMessage += (errorMessage ? ', ' : '') + translations.VALMESSAGE6;
        }

        // Validations when job is done and equipment is radioactive
        if (
            measurement.statusCode === constants.statusWoT &&
            measurement.radiationClassification === constants.radioactive
        ) {
            // Dose rate at contact mandatory RQF1522402
            if (!this.isDoseRateContactPresent(distances, devices)) {
                errorMessage += (errorMessage ? ', ' : '') + translations.VALMESSAGE2;
            }

            if (department !== applicationData.rwsection && distances.indexOf(constants.distance10cm) < 0) {
                errorMessage += (errorMessage ? ', ' : '') + translations.VALMESSAGE3;
            }

            if (department !== applicationData.rwsection && distances.indexOf(constants.distance40cm) < 0) {
                errorMessage += (errorMessage ? ', ' : '') + translations.VALMESSAGE4;
            }
        }

        // Validations when job is done and equipment is not radioactive
        // At least one distance must be contact and done with ADB or BGO
        if (
            !isRWCDepartment &&
            measurement.statusCode === constants.statusWoT &&
            measurement.radiationClassification === constants.nonRadioactive
        ) {
            let abdbgoContact = false;
            for (let i = 0; i < devices.length; i++) {
                if (
                    (devices[i] === constants.deviceBgo || devices[i] === constants.deviceAdb) &&
                    distances[i] === constants.distanceContact
                ) {
                    abdbgoContact = true;
                }
            }
            if (!abdbgoContact) {
                errorMessage += (errorMessage ? ', ' : '') + translations.VALMESSAGE1;
            }
        }

        /*
         If future action is declare as waste, the department is RPM, The Generate RW Check is Yes and the
         classification is radioactive ==> CADRA Check is required
          */
        const { activities } = this.checklists.wrappedInstance.state;
        if (
            measurement.statusCode === constants.statusWoT &&
            rwCheckGeneration === constants.trueYes &&
            ((futureAction === constants.futureActionDecwaste && department === applicationData.rpjobDepartment) || // checkr: remove futureActionDecwaste
                futureAction === constants.futureActionWasteDeclare)
        ) {
            if (activities.length === 0) {
                errorMessage += (errorMessage ? ', ' : '') + translations.CADRAREQUIRED;
            } else {
                let incompleteChecklist = false;
                activities.forEach((activity) => {
                    activity.checklists.forEach((item) => {
                        if (!item.finding) {
                            incompleteChecklist = true;
                        }
                    });
                });
                if (incompleteChecklist) {
                    errorMessage += (errorMessage ? ', ' : '') + translations.CADRANOTCOMPL;
                }
                // Special conditions for CADRA Check.
                const [aValue, bValue, cValue, dValue, eValue, fValue, gValue, hValue, iValue, jValue] =
                    activities[0].checklists
                        .sort((a, b) => parseInt(a.sequence, 10) - parseInt(b.sequence, 10))
                        .map((e) => e.finding);
                const yesValue = 'FYES';
                const noValue = 'FNO';
                const naValue = 'FNNA';
                // 1.  A. must be = Yes if (B or C or F or G or H or I or J) = No
                if (
                    aValue !== yesValue &&
                    (bValue === noValue ||
                        cValue === noValue ||
                        fValue === noValue ||
                        gValue === noValue ||
                        hValue === noValue ||
                        iValue === noValue ||
                        jValue === noValue)
                ) {
                    errorMessage += (errorMessage ? ', ' : '') + translations.AMUSTBEYES1;
                }
                // 2. A. must be = Yes if D = No and E. = N/A
                if (aValue !== yesValue && dValue === noValue && eValue === naValue) {
                    errorMessage += (errorMessage ? ', ' : '') + translations.AMUSTBEYES2;
                }
            }
        }

        /**
         * The BGO measurement shall be required before closing the RP
         measurement request if the dose rate at contact is ≤ 0.5 µSv/h
         */
        const contactValue = this._getMeasurementAtDistance(constants.distanceContact);
        if (
            measurement.statusCode === constants.statusWoT &&
            !Number.isNaN(contactValue) &&
            contactValue <= 0.5 &&
            devices.indexOf(constants.deviceBgo) < 0
        ) {
            // A BGO Measurement is required when dose rate at contact is less than  0.5 µSv/h
            errorMessage += (errorMessage ? ', ' : '') + translations.VALMESSAGE5;
        }

        /**
         * If the update location flag is on, then the new location or the new container must be filled in
         */
        if (
            measurement.statusCode === constants.statusWoT &&
            measurement.updateLocation &&
            !(measurement.newLocationCode || measurement.newContainerCode)
        ) {
            // Please supply the new location or container code
            errorMessage += (errorMessage ? ', ' : '') + translations.NEWLOCORNEWCONT;
        }

        // Show the error and let the client know that validation has failed when errorMessage not empty
        if (errorMessage) {
            showError(errorMessage, '', null);
            return false;
        }
        // All ok
        return true;
    };

    // To get the value of a measurement in a distance
    _getMeasurementAtDistance = (distance) => {
        const measurement = this.state.measurementsDetails[this.state.currentMeasurement];
        // Check one by one of the devices
        if (measurement.distance1 === distance) {
            return measurement.value1;
        }
        if (measurement.distance2 === distance) {
            return measurement.value2;
        }
        if (measurement.distance3 === distance) {
            return measurement.value3;
        }
        if (measurement.distance4 === distance) {
            return measurement.value4;
        }
        return undefined;
    };

    //
    // HANDLERS
    //
    /**
     * Dispay a confirmation message before executing an action
     * @param action
     */
    confirm = (action) => {
        const { translations } = this.props;
        this.confirmMessage(action, translations.AREYOUSURE);
    };

    confirmMessage = (action, message) => {
        const { translations, openConfirmDialog } = this.props;

        openConfirmDialog(
            {
                title: translations.CONFIRM,
                message,
                cancelButtonLabel: translations.CANCEL,
                confirmButtonLabel: translations.CONFIRM,
                waitForCompletion: true,
            },
            () => {
                action();
            }
        );
    };

    saveHandler = (includeRwpEqp) => {
        const { constants, translations, applicationData } = this.props;
        const { currentMeasurement, measurementsDetails, measurements, structure } = this.state;
        // Confirm the save
        const measurement = measurementsDetails[currentMeasurement];

        const realSave = () => this.sendDataToServer(includeRwpEqp);

        // EAM-3081
        if (measurement.statusCode === constants.statusWoRad) {
            realSave();
            return true;
        }

        // Trigger the validate method checking various conditions.
        if (!this.validate()) {
            return false;
        }
        // Iterate over all fields to trigger their validate method. For now used for required fields.
        if (!this.validateFields()) {
            showError(translations.INVMEASUREMENTS, '', 4000);
            return false;
        }

        // eslint-disable-next-line
        for (const fieldName in measurement) {
            if (fieldName === 'undefined' || measurement[fieldName] === null || measurement[fieldName] === undefined) {
                delete measurement[fieldName];
            }
        }

        if (measurement.statusCode !== constants.statusWoT && measurement.statusCode !== constants.statusWoTx) {
            // Save without confirm
            realSave();
            return false;
        }

        const action = measurement.statusCode === constants.statusWoT ? translations.PERFORM : translations.CANCEL;
        // If radioactive, and transport, check if the location is non designated area
        const equipmentDestination =
            measurement.updateLocation && measurement.newLocationCode
                ? measurement.newLocationCode
                : measurement.edhDocument && measurement.equipmentDestination
                ? measurement.equipmentDestination
                : undefined;

        const currentCompletedDate =
            measurement.dateCompleted && parse(measurement.dateCompleted, DATE_TIME_FORMAT.JAVA, new Date());

        this.setState({ blocking: true });

        const workorders = this.getWorkOrderNumbers();

        const rwCheckGeneration =
            measurement.statusCode === constants.statusWoT && measurement.rwCheckGeneration === constants.trueYes;

        const documentsPromise = rwCheckGeneration
            ? () => WSEDMS.getEDMSWorkorderDocumentsBatch(workorders)
            : () => Promise.resolve({ body: { data: [] } });

        // query both endpoints at the same time, even though we might not need both,
        // to not have to wait for the first request when it is needed
        Promise.all([WSAutocomplete.autocompleteTRECLocation(equipmentDestination), documentsPromise()])
            .then((responses) => {
                this.setState({ blocking: false });
                const [location, documents] = responses.map((response) => response.body.data);

                /*
                 *If RW Check generation is Yes, then a picture must be attached
                 */
                if (rwCheckGeneration && Math.min(...documents.map((document) => document.length)) === 0) {
                    showError(translations.PICTUREREQUIRED, '', 4000);
                    return;
                }

                const { radiationClassification } = measurement;

                const rpClassMap = {
                    [constants.radioactive]: 'RADIOACTIVE',
                    [constants.nonRadioactive]: 'NONRADIOACTIVE',
                };

                const willChangeRpClass =
                    structure?.nodes
                        ?.filter(() => applicationData.inheritanceOn)
                        .filter((s) => rpClassMap[radiationClassification] !== s.equipmentInfo.rpClassification)
                        .filter((s) => !(s.lastRpJob?.completedDate >= currentCompletedDate))
                        .filter(
                            (s) =>
                                radiationClassification === constants.radioactive ||
                                isBelowInStructure(structure, s.eqCode, measurement.equipment[0].equipmentCode)
                        )
                        .map((s) => s.eqCode) || [];

                const messagePrefix =
                    applicationData.inheritanceOn && structure?.nodes?.length > 1 ? (
                        <>
                            <span style={{ color: 'red' }}>
                                {measurements.length > 1 || willChangeRpClass?.length ? (
                                    <>
                                        {translations.CONFIRMSAVE_STRUCT || 'CONFIRMSAVE_STRUCT'} <br />
                                    </>
                                ) : null}
                                {measurements.length === 1 && willChangeRpClass?.length ? (
                                    <>
                                        {`${radiationClassification}: ${willChangeRpClass.join(', ')}`} <br />
                                    </>
                                ) : null}
                            </span>
                        </>
                    ) : null;

                /* Not radioactive or not transport */
                if (measurement.radiationClassification !== constants.radioactive || !equipmentDestination) {
                    // Regular confirm
                    this.confirmMessage(
                        realSave,
                        <span>
                            {messagePrefix}
                            {translations.CONFIRMSAVE.replace('{0}', measurements.length).replace('{1}', action)}
                        </span>
                    );
                    return;
                }

                const areaClassification = location.length > 0 ? location[0].extraAttr2 : undefined;

                if (areaClassification <= 0) {
                    this.confirmMessage(
                        realSave,
                        `${translations.NONRADAREAERR.replace(
                            '{0}',
                            equipmentDestination
                        )}. ${translations.CONFIRMSAVE.replace('{0}', measurements.length).replace('{1}', action)}`
                    );
                } else {
                    /* It's ok the classification */
                    this.confirmMessage(
                        realSave,
                        translations.CONFIRMSAVE.replace('{0}', measurements.length).replace('{1}', action)
                    );
                }
            })
            .catch((error) => {
                this.setState({ blocking: false });
                handleError(error);
            });
        return true;
    };

    // Real save
    sendDataToServer = (includeRwpEqp) => {
        const { applicationData } = this.props;
        const { measurements, measurementsDetails, currentMeasurement } = this.state;
        const wrapper = {};

        const { rwEquipment, ...meas } = measurementsDetails[currentMeasurement];

        wrapper.measurement = includeRwpEqp ? { ...meas, rwEquipment } : meas;

        wrapper.measurementNumbers = measurements.map((measurement) => measurement.jobNumber);
        wrapper.equipments = Object.keys(measurementsDetails).map((key) => measurementsDetails[key].equipment[0]);
        this.setState({ blocking: true });

        WSMeasurements.updateMeasurements({ measurements: wrapper, applicationData })
            .then(() => {
                showSuccess(this.props.translations.JOBDATAUPD);
                // Erase measurementsDetails
                this.setState({ measurementsDetails: {} });
                // Read the current measurement again
                this.readCurrentMeasurementAndAllDetails(currentMeasurement);
            })
            .catch((error) => {
                this.setState({ blocking: false });
                handleError(error);
            });
    };

    previousResultsHandler = () => {
        const { measurementsDetails, currentMeasurement } = this.state;
        const measurement = measurementsDetails[currentMeasurement];
        const equipment = measurement.equipment[0];
        this.setState({ blocking: true });
        WSMeasurements.getPreviousResults(equipment.equipmentCode)
            .then((response) => {
                const prevMeasurement = response.body.data;
                const measurementDetail = {
                    ...measurement,
                    radiationClassification: prevMeasurement.radiationClassification,
                    device1: prevMeasurement.device1,
                    distance1: prevMeasurement.distance1,
                    qualifier1: prevMeasurement.qualifier1,
                    value1: prevMeasurement.value1,
                    device2: prevMeasurement.device2,
                    distance2: prevMeasurement.distance2,
                    qualifier2: prevMeasurement.qualifier2,
                    value2: prevMeasurement.value2,
                    device3: prevMeasurement.device3,
                    distance3: prevMeasurement.distance3,
                    qualifier3: prevMeasurement.qualifier3,
                    value3: prevMeasurement.value3,
                    device4: prevMeasurement.device4,
                    distance4: prevMeasurement.distance4,
                    qualifier4: prevMeasurement.qualifier4,
                    value4: prevMeasurement.value4,
                    bkgDevice: prevMeasurement.bkgDevice,
                    bkgQualifier: prevMeasurement.bkgQualifier,
                    bkgValue: prevMeasurement.bkgValue,
                    contaminationRisk: prevMeasurement.contaminationRisk,
                    contMeasurementTool: prevMeasurement.contMeasurementTool,
                    contMeasurementType: prevMeasurement.contMeasurementType,
                    bkgAlphaQualifier: prevMeasurement.bkgAlphaQualifier,
                    bkgAlphaValue: prevMeasurement.bkgAlphaValue,
                    bkgAlphaNetQualifier: prevMeasurement.bkgAlphaNetQualifier,
                    bkgAlphaNetValue: prevMeasurement.bkgAlphaNetValue,
                    alphaSurfacicCont: prevMeasurement.alphaSurfacicCont,
                    bkgBetaQualifier: prevMeasurement.bkgBetaQualifier,
                    bkgBetaValue: prevMeasurement.bkgBetaValue,
                    bkgBetaNetQualifier: prevMeasurement.bkgBetaNetQualifier,
                    bkgBetaNetValue: prevMeasurement.bkgBetaNetValue,
                    betaSurfacicCont: prevMeasurement.betaSurfacicCont,
                    containsAlphaEmitters: prevMeasurement.containsAlphaEmitters,
                };
                // Update measurement details
                this.setState((prevState) => ({
                    measurementsDetails: {
                        ...prevState.measurementsDetails,
                        [measurement.jobNumber]: measurementDetail,
                    },
                    blocking: false,
                }));
            })
            .catch((error) => {
                this.setState({ blocking: false });
                handleError(error);
            });
    };

    resetValuesHandler = () => {
        // Remove measurement details and load again
        this.setState({ measurementsDetails: {} });
        // Read again
        this.readCurrentMeasurementAndAllDetails(this.state.currentMeasurement);
    };

    createCadraCheckHandler = () => {
        const promises = this.state.measurements.map((measurement) =>
            WSRWChecks.createCADRACheck(measurement.jobNumber)
        );
        this.setState({ blocking: true });
        Promise.all(promises)
            .then(() =>
                // Read again the list of measurements to get the indicator of CADRA Checks updated
                WSMeasurements.getMeasurements(this.getWorkOrderNumbers())
            )
            .then((response) => {
                this.setState({
                    measurements: response.body.data,
                    blocking: false,
                });
                // CADRA Check created
                showSuccess(this.props.translations.CADRACHECKPRESENT);
                // Read activities
                this.checklists.wrappedInstance.readActivities(this.state.currentMeasurement);
            })
            .catch((error) => {
                this.setState({ blocking: false });
                handleError(error);
            });
    };

    openJobHandler = () => {
        this.setState({ blocking: true });
        this.jobHandler(WSMeasurements.openMeasurements(this.getWorkOrderNumbers()));
    };

    cancelJobHandler = () => {
        this.setState({ blocking: true });
        this.jobHandler(WSMeasurements.cancelMeasurements(this.getWorkOrderNumbers()));
    };

    jobHandler = (WSPromise) => {
        WSPromise.then((response) => {
            showSuccess(this.props.translations.JOBDATAUPD);
            // Set the measurement list
            this.setState(() => ({
                measurements: response.body.data,
                measurementsDetails: {},
            }));
            // Read the current measurement again
            this.readCurrentMeasurementAndAllDetails(this.state.currentMeasurement);
        }).catch((error) => {
            this.setState({ blocking: true });
            handleError(error);
        });
    };

    edhDocumentHandler = () => {
        const { constants } = this.props;
        const measurement = this.state.measurementsDetails[this.state.currentMeasurement];
        const equipment = measurement.equipment[0].inforEquipment;
        const responsible = equipment.customField.find((elem) => elem.code === constants.cfpEqpResponsible).value;
        this.props.history.push(`/edhdocmng/?edhdocument=${measurement.edhDocument}&responsible=${responsible}`);
    };

    backButtonHandler = () => {
        const { menu } = this.props;
        const link = menu.find((m) => m.menuCode === 'RP_REQUESTS')?.link || '/menu';
        this.props.history.push(`/${link}`.replace('//', '/'));
    };

    printHandler = () => {
        const { applicationData } = this.props;
        const measurement = this.state.measurementsDetails[this.state.currentMeasurement];
        const equipment = measurement.equipment[0].inforEquipment;
        const equipmentCode = equipment.code;
        window.open(`${applicationData.rwcheckPrintURL}${equipmentCode}`, '_blanks');
    };

    createCommentHandler = (comment) =>
        new Promise((resolve, reject) => {
            const promises = this.state.measurements.map((measurement) =>
                WSComments.createComment({ ...comment, entityKeyCode: measurement.jobNumber })
            );
            Promise.all(promises)
                .then((responses) => {
                    resolve(
                        responses.find(
                            (response) => response.body.data[0].entityKeyCode === this.state.currentMeasurement
                        )
                    );
                })
                .catch((error) => {
                    reject(error);
                });
        });

    updateChecklistItem = (checklistItem) => {
        const promises = this.state.measurements.map((measurement) =>
            WSMeasurements.updateChecklistItem({ ...checklistItem, workOrderCode: measurement.jobNumber })
        );
        return Promise.all(promises);
    };

    isJobClosed = () => {
        const { constants } = this.props;
        return (
            constants.statusWoT === this.state.currentMeasurementStatus ||
            constants.statusWoTx === this.state.currentMeasurementStatus
        );
    };

    isJobCancelled = () => {
        const { constants } = this.props;
        return constants.statusWoTx === this.state.currentMeasurementStatus;
    };

    isJobEditable = () => !this.isJobClosed() && !this.isJobCancelled();

    fetchUsersSerialDevice = (deviceCode, serialProperty) => {
        // Only do it  when there are the two values
        if (!deviceCode || !serialProperty) return;
        // Adjust the device code by adding PWD and removing ' (c/s)'
        const deviceCodeAdjusted = `PWD${deviceCode.replace(' (c/s)', '').toUpperCase()}`;
        WSMeasurements.getDeviceSerial(deviceCodeAdjusted)
            .then((response) => {
                const device = response.body.data;
                // Set the serial number if there is device
                if (device && device.serialNumber) {
                    this.updateProperty(serialProperty, device.serialNumber);
                }
            })
            .catch(handleError);
    };

    getWorkOrderNumbers = () =>
        [queryString.parse(this.props.location.search, { arrayFormat: 'none' }).wo || []]
            .flat()
            .map((s) => s.split(','))
            .flat();

    //
    // RENDER
    //
    renderPage(writeAccess) {
        const {
            translations,
            applicationData,
            constants,
            history,
            rwmTranslations,
            userData,
            woFields,
            assetFields,
            customFields,
            customFieldsDef,
            customFieldsWaste,
            customFieldsWasteDef,
            rwpDropdowns,
            lists,
            lang,
            getTranslation,
        } = this.props;

        const { measurements, lastRpjob, currentMeasurement, measurementsDetails, currentMeasurementStatus, blocking } =
            this.state;

        const detailsStyle =
            measurements && measurements.length > 1
                ? { border: '2px solid orange', backgroundColor: 'rgba(255,183,42,0.13)' }
                : null;
        // Block as long the list of measurements and details about the first measurement are read
        if (!currentMeasurement || !measurements || !measurementsDetails[currentMeasurement]) {
            return (
                <BlockUi tag="div" blocking style={this.blockUiStyle}>
                    <div style={this.blockUiStyleDiv}>{translations.LOADING}</div>
                </BlockUi>
            );
        }

        const measurement = measurementsDetails[currentMeasurement];
        const hasWithdrawnAsset =
            measurement && measurement.equipment && measurement.equipment.some((m) => m.statusCode === 'D');

        const access =
            writeAccess &&
            !hasWithdrawnAsset &&
            (userData.eamAccount.departmentalSecurity[measurement.department] || {}).readOnly === false;

        // writeAccess = writeAccess && measyrement.

        const { cadraCheckPresent, firstTimeCompleted } = measurements.find(
            (elem) => elem.jobNumber === currentMeasurement
        );

        const equipmentList = measurements.reduce((acc, m) => [...acc, ...m.equipment], []).map((e) => e.equipmentCode);

        const currentCompletedDate =
            measurement.dateCompleted && parse(measurement.dateCompleted, DATE_TIME_FORMAT.JAVA, new Date());
        const lastRpjobCompletedDate =
            lastRpjob && lastRpjob.completedDate && parse(lastRpjob.completedDate, DATE_TIME_FORMAT.JAVA, new Date());
        const rpclassification = measurement.radiationClassification === 'Radioactive';
        const isNotAttachedToParent =
            !measurement.equipment?.[0]?.inforEquipment?.hierarchyAssetCode &&
            !measurement.equipment?.[0]?.inforEquipment?.hierarchyPositionCode;
        const recordRWDate =
            this.shouldConsiderRWReception() &&
            currentCompletedDate &&
            (!lastRpjobCompletedDate || currentCompletedDate >= lastRpjobCompletedDate) &&
            rpclassification &&
            isNotAttachedToParent;
        const showReceptionEmptyContainer =
            !this.state.attachedVirtual &&
            this.shouldConsiderRWReception() &&
            isNotAttachedToParent &&
            this.state.structure?.nodes?.length === 1;

        const recordRWDateMessage = !this.shouldConsiderRWReception()
            ? ''
            : lastRpjobCompletedDate && currentCompletedDate < lastRpjobCompletedDate
            ? `This measurement cannot be used to record the RW Reception Date: Last RPM is ${lastRpjob.number}`
            : !rpclassification
            ? 'This measurement cannot be used to record the RW Reception Date: RP Classification non Radioactive '
            : !isNotAttachedToParent
            ? 'This measurement cannot be used to record the RW Reception Date: Object attached to parent'
            : '';
        const singleResponsible = new Set(measurements.map((m) => m.equipmentRespCode)).size === 1;

        const { futureAction, department } = measurement;
        const isRWCDepartment = department === applicationData.rwsection;

        const isRadioactiveWasteReception = rpclassification && futureAction === constants.futureActionWasteReceive;

        const showRadioactiveWasteReceptionDate = lastRpjob !== undefined && isNotAttachedToParent;

        const equipment = measurement.equipment[0];

        const isRWDeclarationSpecificCase = equipment.statusCode === 'IW' && isRWCDepartment;

        const showRWEquipmentDetails = isRadioactiveWasteReception || isRWDeclarationSpecificCase;

        const saveHandler2 = () => this.saveHandler(showRWEquipmentDetails);

        const isEquipmentInStore =
            this.state.structure?.nodes?.find((node) => node.id === equipment.equipmentCode)?.equipmentInfo
                .statusCode === 'C';

        const props = {
            translations,
            rwmTranslations,
            woFields,
            assetFields,
            customFields,
            customFieldsDef,
            customFieldsWasteDef,
            rwpDropdowns,
            constants,
            measurement,
            updateProperty: this.updateProperty.bind(this),
            lists,
            formFields: this.formFields,
            applicationData,
            jobIsClosed: this.isJobClosed(),
            readonly: !access || !this.isJobEditable(),
            jobIsCancelled: this.isJobCancelled(),
            currentMeasurementStatus,
            fetchUsersSerialDevice: this.fetchUsersSerialDevice,
            userData,
            ...(detailsStyle ? { detailsStyle } : {}),
            lang,
            isEquipmentInStore,
            getTranslation,
            showNoContentWarning: this.shouldConsiderRWReception() && measurement.equipment,
        };

        return (
            <BlockUi tag="div" blocking={blocking} style={this.mainDivStyle}>
                <Workorders
                    measurements={measurements}
                    currentMeasurement={currentMeasurement}
                    setCurrentMeasurement={this.setCurrentMeasurement}
                    translations={translations}
                />

                <div style={this.contentDivStyle}>
                    <RPMPerformToolbar
                        measurement={measurementsDetails[currentMeasurement]}
                        translations={translations}
                        saveHandler={saveHandler2}
                        previousResultsHandler={() => this.confirm(this.previousResultsHandler)}
                        resetValuesHandler={() => this.confirm(this.resetValuesHandler)}
                        openJobHandler={() => this.confirm(this.openJobHandler)}
                        cancelJobHandler={() => this.confirm(this.cancelJobHandler)}
                        edhDocumentHandler={this.edhDocumentHandler}
                        createCadraCheckHandler={() => this.confirm(this.createCadraCheckHandler)}
                        backButtonHandler={this.backButtonHandler}
                        printHandler={this.printHandler}
                        writeAccess={access}
                        cadraCheckPresent={cadraCheckPresent}
                        jobIsClosed={props.jobIsClosed}
                        jobIsEditable={props.jobIsEditable}
                        jobIsCancelled={props.jobIsCancelled}
                        constants={constants}
                        applicationData={applicationData}
                        canJumpToRPAR={equipmentList.length <= applicationData.rpanalysisMaxEquipments}
                        equipmentList={equipmentList}
                        singleResponsible={singleResponsible}
                        history={history}
                    />

                    <div style={this.containerStyle}>
                        <Grid container spacing={8}>
                            <Grid item lg={6} md={12} sm={12} xs={12}>
                                <Status
                                    {...props}
                                    applicationdata={applicationData}
                                    eamAccount={userData.eamAccount}
                                    recordRWDate={
                                        recordRWDate || (this.isJobClosed() && this.shouldConsiderRWReception())
                                    }
                                    recordRWDateMessage={recordRWDateMessage}
                                    firstTimeCompleted={firstTimeCompleted}
                                    showReceptionEmptyContainer={showReceptionEmptyContainer}
                                    history={history}
                                    attachVirtualCode={() => this.attachVirtualCode()}
                                />

                                <MeasurementDetails {...props} />

                                <RadiationMeasurements {...props} />

                                <ContaminationMeasurements {...props} />

                                <ChecklistsContainer
                                    title={
                                        cadraCheckPresent
                                            ? translations.CADRACHECKS
                                            : translations.VAC_RETURN_CHECKLIST || 'VAC_RETURN_CHECKLIST'
                                    }
                                    workorder={currentMeasurement}
                                    ref={(checklists) => {
                                        this.checklists = checklists;
                                    }}
                                    updateChecklistItem={this.updateChecklistItem}
                                    readonly={props.readonly}
                                    minFindingsDropdown={4}
                                    {...(detailsStyle ? { detailsStyle } : {})}
                                />
                            </Grid>
                            <Grid item lg={6} md={12} sm={12} xs={12}>
                                <CommentsContainer
                                    title={translations.RPMEASCOMMENT}
                                    entityCode="EVNT"
                                    entityKeyCode={currentMeasurement}
                                    userDesc={userData.eamAccount.userDesc}
                                    readComments={WSComments.readComments}
                                    createComment={this.createCommentHandler}
                                    updateComment={WSComments.updateComment}
                                    {...(detailsStyle
                                        ? {
                                              detailsStyle: {
                                                  ...detailsStyle,
                                                  marginLeft: 0,
                                                  marginRight: 0,
                                                  marginBottom: 0,
                                              },
                                          }
                                        : {})}
                                />

                                <EquipmentDetails
                                    {...props}
                                    requireResponsible={measurement && !!measurement.edhDocument}
                                    customFieldsWaste={customFieldsWaste}
                                    showLocation={!showRWEquipmentDetails}
                                />

                                {showRWEquipmentDetails && (
                                    <RWEquipmentDetails
                                        getAttribute={(fieldName, _object) => {
                                            switch (fieldName) {
                                                case RWP_EQUIPMENT_KEYS.RW_RECEPTION_DATE:
                                                    return isRadioactiveWasteReception &&
                                                        showRadioactiveWasteReceptionDate
                                                        ? 'O'
                                                        : 'H';
                                                case RWP_EQUIPMENT_KEYS.CONTAINER_INTERNAL_LENGTH:
                                                case RWP_EQUIPMENT_KEYS.CONTAINER_INTERNAL_WIDTH:
                                                case RWP_EQUIPMENT_KEYS.CONTAINER_INTERNAL_HEIGHT:
                                                    return RWProcessingTools.isContainer(equipment) ? 'R' : 'H';
                                                case 'wasteLocation':
                                                    return showRWEquipmentDetails ? 'R' : 'H';
                                                default:
                                                    return 'O';
                                            }
                                        }}
                                        {...props}
                                    />
                                )}

                                <EISPanel heading={translations.EQUIPMENT_TREE || 'EQUIPMENT_TREE'}>
                                    <EquipmentTreeContainer
                                        equipmentCode={
                                            measurementsDetails[currentMeasurement].equipment[0].equipmentCode
                                        }
                                        src={INFORMATION_SOURCE.EQUIPMENT_STRUCTURE}
                                        height={500}
                                        onLoad={(struct) =>
                                            this.setState((prevState) => ({
                                                structure: prevState.structure || struct,
                                            }))
                                        }
                                    />
                                </EISPanel>

                                <EISPanel heading={translations.RPM_STRUCTURE || 'RPM_STRUCTURE'}>
                                    <EquipmentTreeContainer
                                        equipmentCode={
                                            measurementsDetails[currentMeasurement].equipment[0].equipmentCode
                                        }
                                        src={INFORMATION_SOURCE.RPM_STRUCTURE}
                                        height={500}
                                        jobCode={measurement.jobNumber}
                                        onLoad={(struct) => {
                                            this.setState((prevState) => ({
                                                structure: struct || prevState.structure,
                                            }));
                                        }}
                                    />
                                </EISPanel>

                                <CommentsContainer
                                    title={translations.EQPCOMMENTS}
                                    entityCode="OBJ"
                                    entityKeyCode={measurementsDetails[currentMeasurement].equipment[0].equipmentCode}
                                    userDesc={userData.eamAccount.userDesc}
                                    readComments={WSComments.readComments}
                                />

                                <EDMSWidgetContainer
                                    objectID={currentMeasurement}
                                    objectType="J"
                                    title={translations.MEASDOCUMENTS}
                                    edmsDocListLink={applicationData.edmsdocListLink}
                                    ref={(edmsDocuments) => {
                                        this.edmsDocuments = edmsDocuments;
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </BlockUi>
        );
    }
}

export default RPMPerform;
