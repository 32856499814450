import Tools from 'tools/Tools';
import {
    RW_ELIMINATION_DTO_KEYLIST,
    RW_ELIMINATION_KEYS,
    RW_ELIMINATION_PACKAGE_DTO_KEYLIST,
} from '../../ui/pages/rwelimination/RWEliminationConstants';
import WS from './WS';
import qs from 'qs';

const rwEliminationAdapter = (rwElimRequest) => ({
    ...Tools.filterObjectFieldsFromList(rwElimRequest, RW_ELIMINATION_DTO_KEYLIST),
    [RW_ELIMINATION_KEYS.PACKAGES]: Object.values(rwElimRequest[RW_ELIMINATION_KEYS.PACKAGES])?.map((p) =>
        Tools.filterObjectFieldsFromList(p, RW_ELIMINATION_PACKAGE_DTO_KEYLIST)
    ),
});

class WSRWElimination {
    async createRWEliminationJob(request, config = {}) {
        const resp = await WS._post(`/rwelim/jobs/`, rwEliminationAdapter(request), config);
        return resp.body.data;
    }

    async readRWEliminationJob(jobNumber, config = {}) {
        const resp = await WS._get(`/rwelim/jobs/${jobNumber}`, config);
        return resp.body.data;
    }

    async updateRWEliminationJob({ jobNumber, ...request }, config = {}) {
        const resp = await WS._put(
            `/rwelim/jobs/${jobNumber}`,
            { jobNumber, ...rwEliminationAdapter(request) },
            config
        );
        return resp.body.data;
    }

    async deleteRWEliminationJob(jobNumber, config = {}) {
        const resp = await WS._delete(`/rwelim/jobs/${jobNumber}`, config);
        return resp.body.data;
    }

    getAutocompleteWastePackages =
        (eliminationPathways) =>
        async ({ hint }, config = {}) => {
            const resp = await WS._get(`/rwelim/wastepackages`, {
                ...config,
                params: { hint, eliminationPathways },
                paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            });
            return resp.body.data;
        };
}

export default new WSRWElimination();
