import React from 'react';
import { Paper } from '@material-ui/core';
import EAMDatePicker from 'eam-components/dist/ui/components/inputs/EAMDatePicker';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import MenuIcon from '@material-ui/icons/Menu';
import { TRECIcon } from 'eam-components/dist/ui/components/icons/index';
import moment from 'moment';
import parse from 'date-fns/parse';
import TRECPage from '../TRECPage';
import JobsReportResults from './JobsReportResults';
import WS from '../../../tools/rest/WSJobsReport';
import TRECButton from '../../components/buttons/TRECButton';
import { DATE_FORMAT } from '../../../enums/Constants';
import { handleError } from 'tools/TrecNotifications';

export default class JobsReport extends TRECPage {
    state = {
        location: null,
        from: moment().startOf('month').toDate(),
        to: new Date(),
        isFetching: false,
    };

    inputsContainerStyle = {
        width: '90%',
        maxWidth: '500px',
        padding: '10px',
    };

    paperStyle = {
        margin: 10,
    };

    blockUiStyle = {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    blockUiStyleDiv = {
        display: 'flex',
        height: 60,
        alignItems: 'flex-end',
    };

    buttonStyle = {
        margin: 3,
    };

    updateProperty = (key, value) => {
        this.setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    fetchReportResults = () => {
        // First validate the fields
        if (!this.validateFields()) {
            return;
        }

        // Parse dates
        const from =
            this.state.from instanceof Date
                ? this.state.from
                : parse(this.state.from, DATE_FORMAT.DATE_FNS, new Date());
        const to =
            this.state.to instanceof Date ? this.state.to : parse(this.state.to, DATE_FORMAT.DATE_FNS, new Date());

        // Fetch data
        this.setState(() => ({ isFetching: true }));
        WS.getReportData(from.getTime(), to.getTime(), this.state.location)
            .then((response) => {
                this.setState(() => ({
                    results: response.body.data,
                    isFetching: false,
                }));
            })
            .catch((error) => {
                handleError(error);

                this.setState(() => ({
                    isFetching: false,
                }));
            });
    };

    renderPage() {
        return (
            <div style={this.paperStyle}>
                <EISPanel heading={this.props.translations.REPORT_DETAILS}>
                    <div style={this.inputsContainerStyle}>
                        <EAMDatePicker
                            elementInfo={{
                                attribute: 'R',
                                text: this.props.translations.DATEFROM,
                                xpath: 'from',
                            }}
                            valueKey="from"
                            value={this.state.from}
                            localeString={this.props.lang}
                            updateProperty={this.updateProperty}
                            formFields={this.formFields}
                        />
                        <EAMDatePicker
                            elementInfo={{
                                attribute: 'R',
                                text: this.props.translations.DATETO,
                                xpath: 'to',
                            }}
                            valueKey="to"
                            value={this.state.to}
                            localeString={this.props.lang}
                            updateProperty={this.updateProperty}
                            formFields={this.formFields}
                        />
                        <EAMSelect
                            elementInfo={{
                                text: this.props.translations.LOCATION,
                            }}
                            valueKey="location"
                            value={this.state.location}
                            values={this.props.bufferZones.map((bz) => ({
                                code: bz.locationCode,
                                desc: bz.locationCode,
                            }))}
                            updateProperty={this.updateProperty}
                        />
                        <TRECButton color="default" onClick={() => this.props.history.push('/menu')}>
                            <MenuIcon />
                            {this.props.translations.GOTOMENU}
                        </TRECButton>
                        <TRECButton onClick={this.fetchReportResults} style={this.buttonStyle}>
                            <TRECIcon icon="fa fa-check" style={{ marginRight: 5 }} />
                            {this.props.translations.GENERATE}
                        </TRECButton>
                    </div>
                </EISPanel>
                {(this.state.results || this.state.isFetching) &&
                    (this.state.isFetching ? (
                        <BlockUi tag="div" blocking style={this.blockUiStyle}>
                            <div style={this.blockUiStyleDiv}>{this.props.translations.GENERATING_MESSAGE}</div>
                        </BlockUi>
                    ) : (
                        <Paper>
                            <JobsReportResults {...this.props} results={this.state.results} />
                        </Paper>
                    ))}
            </div>
        );
    }
}
