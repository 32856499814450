import React, { Component } from 'react';
import { Help } from '@material-ui/icons';
import GB from '../../../GB.svg';
import FR from '../../../FR.svg';
import Languages from '../../../enums/Languages';
import UserInfo from './UserInfo';

export default class TRECAppBar extends Component {
    onLangChangeHandler() {
        const { lang, updateLayout } = this.props;
        if (lang === Languages.FRENCH) {
            updateLayout({ lang: Languages.ENGLISH });
        } else {
            updateLayout({ lang: Languages.FRENCH });
        }
    }

    flagStyle = {
        width: 30,
        height: 30,
        margin: 5,
        borderBottom: '2px solid',
        borderBottomColor: 'transparent',
        cursor: 'pointer',
    };

    appBarStyle = {
        display: 'flex',
        alignItems: 'center',
    };

    render() {
        const englishFlagStyle = { ...this.flagStyle };
        const frenchFlagStyle = { ...this.flagStyle };
        const { lang, toggleHelp } = this.props;

        if (lang === Languages.ENGLISH) {
            englishFlagStyle.borderBottomColor = 'white';
        } else {
            frenchFlagStyle.borderBottomColor = 'white';
        }

        return (
            <div style={this.appBarStyle}>
                <Help style={{ margin: 10, cursor: 'pointer' }} onClick={() => toggleHelp()} />
                <input
                    src={GB}
                    style={englishFlagStyle}
                    onClick={() => this.onLangChangeHandler()}
                    onKeyDown={() => this.onLangChangeHandler()}
                    alt="English"
                    type="image"
                />
                <input
                    src={FR}
                    style={frenchFlagStyle}
                    onClick={() => this.onLangChangeHandler()}
                    onKeyDown={() => this.onLangChangeHandler()}
                    alt="French"
                    type="image"
                />
                <UserInfo {...this.props} />
            </div>
        );
    }
}
