import { Link } from 'react-router-dom';

export default function autoFilledRPARButtonProps({ equipmentList = {}, history }) {
    const queryParams = `equipment=${[...new Set(Object.values(equipmentList))].join(',')}`;

    return history
        ? { onClick: () => history.push(`/rparequest/create?${queryParams}`) }
        : {
              component: Link,
              target: '_blank',
              to: `/rparequest/create?${queryParams}`,
          };
}
