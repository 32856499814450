import { WizardOrientation } from 'amm-tools';
import Wizard from 'amm-tools/dist/components/Wizard';
import { useEffect, useMemo, useState } from 'react';
import BlockUi from 'react-block-ui';
import { generatePath } from 'react-router';
import { ROUTES } from 'tools/Routes';
import Tools from '../../../tools/Tools';
import WSEDHDoc from '../../../tools/rest/WSEDHDoc';
import WSRWElimination from '../../../tools/rest/WSRWElimination';
import DefaultStepper from '../../components/wizard/DefaultStepper';
import TRECPage from '../TRECPage';
import { computePermissions } from '../rpmrequest2/InputGenerator';
import WRRNoAccessStep from '../wrrequest/steps/WRRNoAccessStep';
import RWElimTranslations from './RWElimTranslations';
import {
    RWELIM_STATUS_MAXSTEP,
    RW_ELIMINATION_KEYS,
    RW_ELIMINATION_PACKAGE_KEYS,
    RW_ELIMINATION_STATUS,
    RW_ELIM_STEPS_KEY,
    STEP_SUFFIX,
    TRANSLATION_KEYS,
    getChecklistStepKey,
    getStepKey,
} from './RWEliminationConstants';
import RWEliminationWizardControls from './RWEliminationWizardControls';
import RWEliminationCreationStep from './steps/RWEliminationCreationStep';
import RWEliminationDetailsStep from './steps/RWEliminationPreselectionStep';
import RWEliminationPackageListStep from './steps/RWEliminationPackageListStep';
import RWEliminationControlStep from './steps/RWEliminationControlStep';
import { handleError, showSuccess } from 'tools/TrecNotifications';
import RWEliminationChecklistStep from './steps/RWEliminationChecklistStep';

const RW_ELIMINATION_STEPS = {
    [RW_ELIM_STEPS_KEY.CREATED]: {
        key: RW_ELIM_STEPS_KEY.CREATED,
        status: RW_ELIMINATION_STATUS.CREATED,
        Step: RWEliminationCreationStep,
        permissionKey: 'RW_DETAILS',
        computeSpecialPermissions: () => ['W'],
    },
    [RW_ELIM_STEPS_KEY.COLIS_PRESELECTIONNES]: {
        key: RW_ELIM_STEPS_KEY.COLIS_PRESELECTIONNES,
        status: RW_ELIMINATION_STATUS.COLIS_PRESELECTIONNES,
        Step: RWEliminationDetailsStep,
        permissionKey: 'RW_DETAILS',
        computeSpecialPermissions: () => ['W'],
    },
    [RW_ELIM_STEPS_KEY.VERIFIE]: {
        key: RW_ELIM_STEPS_KEY.VERIFIE,
        status: RW_ELIMINATION_STATUS.VERIFIE,
        Step: (props) => <RWEliminationPackageListStep {...props} />,
        permissionKey: 'ANDRA',
        computeSpecialPermissions: () => ['W'],
    },
    [RW_ELIM_STEPS_KEY.VALIDE]: {
        key: RW_ELIM_STEPS_KEY.VALIDE,
        status: RW_ELIMINATION_STATUS.VALIDE,
        Step: (props) => <RWEliminationControlStep {...props} />,
        computeSpecialPermissions: () => ['W'],
    },
    [RW_ELIM_STEPS_KEY.DECLAREE]: {
        key: RW_ELIM_STEPS_KEY.DECLAREE,
        status: RW_ELIMINATION_STATUS.DECLAREE,
        Step: (props) => <RWEliminationControlStep {...props} />,
        computeSpecialPermissions: () => ['W'],
    },
    [RW_ELIM_STEPS_KEY.PLANIFIE]: {
        key: RW_ELIM_STEPS_KEY.PLANIFIE,
        status: RW_ELIMINATION_STATUS.PLANIFIE,
        Step: (props) => <RWEliminationControlStep {...props} />,
        computeSpecialPermissions: () => ['W'],
    },
    // [RW_ELIM_STEPS_KEY.PLANIFIE2]: {
    //     key: RW_ELIM_STEPS_KEY.PLANIFIE2,
    //     status: RW_ELIMINATION_STATUS.PLANIFIE2,
    //     Step: (props) => <RWEliminationControlStep {...props} />,
    //     computeSpecialPermissions: () => ['W'],
    // },
    [RW_ELIM_STEPS_KEY.ENVOYEE]: {
        key: RW_ELIM_STEPS_KEY.ENVOYEE,
        status: RW_ELIMINATION_STATUS.ENVOYEE,
        Step: (props) => <RWEliminationControlStep {...props} />,
        computeSpecialPermissions: () => ['W'],
    },
    [RW_ELIM_STEPS_KEY.RECU]: {
        key: RW_ELIM_STEPS_KEY.RECU,
        status: RW_ELIMINATION_STATUS.RECU,
        Step: (props) => <RWEliminationControlStep {...props} />,
        computeSpecialPermissions: () => ['W'],
    },
    [RW_ELIM_STEPS_KEY.FINISHED]: {
        key: RW_ELIM_STEPS_KEY.FINISHED,
        status: RW_ELIMINATION_STATUS.FINISHED,
        Step: (props) => <RWEliminationControlStep {...props} />,
        computeSpecialPermissions: () => ['W'],
    },
    ...Object.values(RW_ELIMINATION_STATUS)
        .map((status) => ({
            key: getChecklistStepKey(status),
            status,
            Step: (props) => <RWEliminationChecklistStep {...props} task={`${status}_CHKL`} />,
            computeSpecialPermissions: () => ['W'],
        }))
        .reduce((acc, step) => Object.assign(acc, { [`${step.key}`]: step }), {}),
};

const wrapStepProps = (Component, stepProps, step) => (props) =>
    (
        <Component
            key={step.key}
            translation={step.key}
            step={step.key}
            style={{ overflowX: 'hidden' }}
            stepStatus={step.status}
            {...stepProps}
            {...props}
        />
    );

const getSteps = (stepProps = {}, perm) =>
    Tools.applyToFields(RW_ELIMINATION_STEPS, (step) => ({
        ...step,
        getContent: wrapStepProps(perm?.[step.key].length > 0 ? step.Step : WRRNoAccessStep, stepProps, step),
        label: step.key.includes('CHKL')
            ? stepProps.getTranslation(step.key)
            : stepProps.getTranslation(`${step.status}${STEP_SUFFIX}`),
        permissions: perm?.[step.key] ?? [],
    }));

const getStepSequence = (properties) => {
    if (!properties[RW_ELIMINATION_KEYS.STATUS]) {
        return [RW_ELIM_STEPS_KEY.CREATED];
    }
    const currentStatus = properties[RW_ELIMINATION_KEYS.STATUS];

    const a = Object.values(RW_ELIMINATION_STATUS)
        .slice(
            0,
            Object.values(RW_ELIM_STEPS_KEY).indexOf(
                RWELIM_STATUS_MAXSTEP[currentStatus] || RW_ELIM_STEPS_KEY.CREATED
            ) + 1
        )
        .map((status) => {
            const activity = properties.stepActivityMap[status];
            const pendingSignature = activity && !activity?.signedChecklist;
            const steps = [];
            if (activity && activity?.task) {
                steps.push(getChecklistStepKey(status));
            }
            if (!activity?.task || !pendingSignature) {
                steps.push(getStepKey(status));
            }
            // console.log('---------------');
            // console.log(status);
            // console.log(checklist);
            // console.log(pendingSignature);
            return steps;
        })
        .flat();
    // console.log(a);
    // console.log(RW_ELIMINATION_STEPS);
    return a;
};

const labelStyle = {
    width: 125,
    minWidth: 125,
    marginRight: 4,
    textAlign: 'right',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'unset',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'center',
    wordBreak: 'break-word',
};

const RWEliminationWizard = (props) => {
    const {
        history,
        getTranslation,
        storeActions,
        userData,
        rwElimGetters,
        rwElimRequest,
        urlParams,
        match,
        writeAccess,
        dropdowns,
        lang,
        applicationData,
        authorizedMenus,
        // firstStepIndex,
    } = props;
    const [loading, setLoading] = useState(false);

    const stepSequence = getStepSequence(rwElimGetters.getProperties());

    const { eliminationNumber } = match.params;

    const [initialized, setInitialized] = useState(false);

    const { statusCode } = rwElimGetters.getProperties();

    const callWs = useMemo(
        () =>
            (ws, args, sucess, error = handleError) =>
            async () => {
                try {
                    const data = await ws(...args);
                    sucess ? sucess(data, showSuccess) : showSuccess(data);
                    return data;
                } catch (err) {
                    error(err);
                    return null;
                }
            },
        [showSuccess, handleError]
    );

    const loadingAround = useMemo(
        () =>
            (func) =>
            async (...args) => {
                setLoading(true);
                const data = await func(...args);
                setLoading(false);
                return data;
            },
        [setLoading]
    );

    const blockAndCallWs = useMemo(
        () =>
            (...args) =>
                loadingAround(callWs(...args)),
        []
    );

    const reload = blockAndCallWs(WSRWElimination.readRWEliminationJob, [eliminationNumber], (resp) => {
        resp[RW_ELIMINATION_KEYS.PACKAGES] = resp[RW_ELIMINATION_KEYS.PACKAGES]?.reduce(
            (acc, obj) => ({ ...acc, [obj[RW_ELIMINATION_PACKAGE_KEYS.EQUIPMENT_CODE]]: obj }),
            {}
        );
        if (resp[RW_ELIMINATION_KEYS.TRANSPORTING_CONTAINERS].length === 0) {
            resp[RW_ELIMINATION_KEYS.TRANSPORTING_CONTAINERS] = [{}];
        }
        storeActions.resetRwElimination();
        setInitialized(true);
        storeActions.updateRWElimination(resp);
    });

    useEffect(() => {
        setInitialized(false);
        if (eliminationNumber) {
            reload();
        } else {
            storeActions.resetRwElimination({ userData, applyDefaults: true });
            setInitialized(true);
        }
    }, [eliminationNumber]);

    const handleCancel = () => history.push(ROUTES.rwElimination);

    const perm = computePermissions(RW_ELIMINATION_STEPS, authorizedMenus, {});

    const computedWriteAccess =
        writeAccess && ![RW_ELIMINATION_STATUS.CANCELLED].includes(rwElimRequest[RW_ELIMINATION_KEYS.STATUS]);

    const createRWEliminationJobRequest = async () => {
        const jobNumber = await WSRWElimination.createRWEliminationJob(rwElimRequest);
        return jobNumber;
    };

    const updateRWEliminationJobRequest = async (updateObject = {}) => {
        const jobNumber = await WSRWElimination.updateRWEliminationJob({ ...rwElimRequest, ...updateObject });
        return jobNumber;
    };

    const handleCreate = (status) =>
        blockAndCallWs(createRWEliminationJobRequest, [status], (resp) => {
            showSuccess(`${getTranslation(RWElimTranslations.GET_RW_ELIM_CREATED_SUCCESS_MSG)}${resp}`);
            history.push(generatePath(ROUTES.rwEliminationEdit, { eliminationNumber: resp }));
        })();

    const handleUpdate = ({ translationKey, updateObject }) =>
        blockAndCallWs(updateRWEliminationJobRequest, [updateObject], (resp) => {
            reload();
            showSuccess(`${getTranslation(translationKey)} ${resp}`);
        })();

    const stepProps = {
        getTranslation,
        userData,
        storeActions,
        urlParams,
        history,
        requestSource: urlParams.requestSource,
        match,
        blockAndCallWs,
        rwElimGetters,
        rwElimRequest,
        callWs,
        loading,
        writeAccess: computedWriteAccess,
        adminMode: writeAccess,
        dropdowns,
        lang,
        applicationData,
        labelStyle,
        handleUpdate,
    };

    const handleGenerateDocument = () => {
        const { openConfirmDialog } = props;
        openConfirmDialog(
            {
                title: getTranslation(RWElimTranslations.CREATE_RW_TRANSPORT_REQUEST_TITLE),
                message: getTranslation(RWElimTranslations.CREATE_RW_TRANSPORT_REQUEST_MESSAGE),
                cancelButtonLabel: getTranslation(RWElimTranslations.BUTTON_CANCEL),
                confirmButtonLabel: getTranslation(RWElimTranslations.BUTTON_CONFIRM),
                waitForCompletion: true,
            },
            () =>
                WSEDHDoc.generateRWEDHTransportDocument(rwElimRequest[RW_ELIMINATION_KEYS.CODE])
                    .then((response) => {
                        const edhNumber = response.body.data;
                        storeActions.updateRWElimination({
                            [RW_ELIMINATION_KEYS.EDH_NUMBER]: edhNumber,
                        });
                        const message = getTranslation(RWElimTranslations.EDHDOCCREATED).replace('{1}', edhNumber);
                        showSuccess(message);
                        handleUpdate({
                            translationKey: 'EDH_CREATED_AND_LINKED',
                            updateObject: {
                                ...rwElimRequest,
                                [RW_ELIMINATION_KEYS.EDH_NUMBER]: edhNumber,
                            },
                        });
                    })
                    .catch((error) => {
                        handleError(error);
                    })
        );
    };

    if (!initialized) {
        <BlockUi message="Loading..." blocking style={{ height: '100%', width: '100%' }} />;
    }

    return (
        <BlockUi blocking={loading} style={{ height: '100%' }}>
            <Wizard
                {...props}
                title={getTranslation(TRANSLATION_KEYS.TITLE)?.replaceAll('{0}', eliminationNumber ?? '')}
                // firstStepIndex={firstStepIndex || -1}
                steps={getSteps(stepProps, perm)}
                stepSequence={stepSequence}
                handleCancel={handleCancel}
                renderStepper={(p) => <DefaultStepper {...p} maxWidth="130px" />}
                orientation={WizardOrientation.HORIZONTAL}
                controls={(p) => (
                    <RWEliminationWizardControls
                        {...p}
                        getTranslation={getTranslation}
                        canUpdate={computedWriteAccess}
                        handleCreate={handleCreate}
                        reload={reload}
                        statusCode={statusCode}
                        handleUpdate={handleUpdate}
                        eliminationNumber={eliminationNumber}
                        showCreateEDHButton={eliminationNumber && !rwElimRequest[RW_ELIMINATION_KEYS.EDH_NUMBER]}
                        handleCreateEDH={handleGenerateDocument}
                        rwElimRequest={rwElimRequest}
                    />
                )}
            />
        </BlockUi>
    );
};

class RWEliminationRequest extends TRECPage {
    renderPage(writeAccess) {
        return <RWEliminationWizard checkAccess={() => this.checkAccess()} writeAccess={writeAccess} {...this.props} />;
    }
}

export default RWEliminationRequest;
