import React, { Component } from 'react';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import WSInconsistencyChecks from '../../../tools/rest/WSInconsistencyChecks';

export default class RWInconsistencyChecksCheckbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.initialValue,
            loading: false,
        };
    }

    onToggleHandler = () => {
        const { value: oldValue } = this.state;
        const { objCode } = this.props;

        const value = !oldValue;

        this.setState({ loading: true });
        WSInconsistencyChecks.acknowledge({ objCode, value, comment: null })
            .then(() => {
                this.setState({ value });
            })
            .catch(() => {
                // do nothing
            })
            .finally(() => this.setState({ loading: false }));
    };

    render() {
        const { loading, value } = this.state;
        const { show } = this.props;

        return (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <EAMCheckbox
                    elementInfo={{ readonly: loading || !show }}
                    value={value}
                    updateProperty={this.onToggleHandler}
                    trueValue
                    falseValue={false}
                    disabled={loading || !show}
                />
            </div>
        );
    }
}
