import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import 'babel-polyfill';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import rootReducer from './reducers';
import { unregister } from './registerServiceWorker';
import TRECContainer from './TRECContainer';
import AuthWrapper from './AuthWrapper';

const generateClassName = createGenerateClassName();
const jss = create(jssPreset());

unregister();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
    <AuthWrapper>
        <Provider store={store}>
            <JssProvider Jss={jss} generateClassName={generateClassName}>
                <TRECContainer color="#FFF" />
            </JssProvider>
        </Provider>
    </AuthWrapper>,
    document.getElementById('root')
);
