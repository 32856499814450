import { setPage, updateLayout } from 'actions/layoutActions';
import { parse as queryParse } from 'query-string';
import { connect } from 'react-redux';
import Tools from 'tools/Tools';
import TRECPage from '../../TRECPage';
import EquipmentShowPage from './EquipmentShowPage';

const TRANSLATION_KEY = 'TREC_EQUIPMENT_PAGE';

class EquipmentShowPageContainer extends TRECPage {
    renderPage(writeAccess) {
        const customProps = {
            ...this.props,
            writeAccess,
        };
        return <EquipmentShowPage {...customProps} />;
    }
}

const mapStateToProps = (state, ownProps) => {
    const { action, application, layout } = state;
    const { applicationData, authorizedMenus, bufferZone, constants, menuType, screenData, userData, rwpDropdowns } =
        application;
    const { lang } = layout;
    const translations = screenData[lang].translations[TRANSLATION_KEY];
    const urlParams = queryParse(ownProps.location.search);
    const { equipmentID } = ownProps.match.params;

    const { customFields } = screenData[lang];
    const { customFieldsDef } = screenData[lang];

    const rwpDropdownsTranslated = Tools.applyToFields(rwpDropdowns, (list) =>
        list.map((codeDesc) => ({
            ...codeDesc,
            desc: (codeDesc.descTranslated && codeDesc.descTranslated[lang]) || codeDesc.desc,
        }))
    );

    return {
        screenData: screenData[lang],
        userData,
        applicationData,
        bufferZone,
        menuType,
        constants,
        customFields,
        customFieldsDef,
        lang,
        translations,
        masterPage: ['EQUIPMENT_PAGE'],
        page: 'EQUIPMENT_PAGE',
        menu: screenData[lang].menu[menuType],
        action,
        urlParams,
        authorizedMenus,
        getTranslation: (code) =>
            code &&
            ((urlParams.lang === 'DEV' && code) ||
                (translations && translations[code.toUpperCase()]) ||
                (screenData.EN.translations[TRANSLATION_KEY] &&
                    screenData.EN.translations[TRANSLATION_KEY][code.toUpperCase()]) ||
                code),
        dropdowns: rwpDropdownsTranslated,
        redirectUrl: `${applicationData.eamlightLink}equipment/${equipmentID}`,
    };
};

const containerize = (Component) =>
    connect(
        mapStateToProps,
        {
            updateLayout,
            setPage,
        },
        (stateProps, dispatchProps, ownProps) => ({
            ...ownProps,
            ...stateProps,
            storeActions: dispatchProps,
            setPage: dispatchProps.setPage,
        })
    )(Component);

export default containerize(EquipmentShowPageContainer);
