import { WizardStep } from 'amm-tools';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import { TABLE_DATA_TYPES, TREC_GENERAL_KEYS } from 'enums/Constants';
import React from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import { shouldShowComment } from 'tools/Hazards';
import Tools from 'tools/Tools';
import InputGenerator from '../../rpmrequest2/InputGenerator';
import { EQUIPMENT_KEYS, RWP_KEYS, TRANSLATION_KEYS } from '../RWPConstants';
import { isContainer } from '../RWProcessingTools';
import { showError } from 'tools/TrecNotifications';

const { ATTRIBUTES } = InputGenerator;

const CONTAINER_DIMENSIONS_KEY_MAP = {
    [EQUIPMENT_KEYS.HEIGHT]: EQUIPMENT_KEYS.CONTAINER_INTERNAL_HEIGHT,
    [EQUIPMENT_KEYS.WIDTH]: EQUIPMENT_KEYS.CONTAINER_INTERNAL_WIDTH,
    [EQUIPMENT_KEYS.LENGTH]: EQUIPMENT_KEYS.CONTAINER_INTERNAL_LENGTH,
};

const getPhysicalDataFields = ({
    rwpDropdowns,
    applicationData,
    screenData,
    constants,
    customFieldsDef,
    getTranslation,
    rwp,
}) =>
    // TODO, review translation keys
    [
        {
            code: EQUIPMENT_KEYS.CODE,
            type: TABLE_DATA_TYPES.STATIC,
            translationKey: TRANSLATION_KEYS.OUTPUT_WASTE,
            getAttribute: () => ATTRIBUTES.READONLY_TEXT,
        },
        {
            code: EQUIPMENT_KEYS.DESCRIPTION,
            type: TABLE_DATA_TYPES.INPUT,
            translationKey: EQUIPMENT_KEYS.DESCRIPTION,
            getAttribute: () => ATTRIBUTES.REQUIRED,
            customValue: (eqp) =>
                eqp[EQUIPMENT_KEYS.DESCRIPTION] &&
                eqp[EQUIPMENT_KEYS.DESCRIPTION].toUpperCase() === applicationData.rpeqpNewDesc.toUpperCase()
                    ? ''
                    : eqp[EQUIPMENT_KEYS.DESCRIPTION],
            customInputProps: () => ({
                style: {
                    minWidth: 220,
                    marginRight: '16px',
                },
                multiline: true,
                rowsMax: 3,
                fullWidth: true,
            }),
        },
        {
            code: EQUIPMENT_KEYS.LOCATION,
            type: TABLE_DATA_TYPES.SELECT,
            translationKey: EQUIPMENT_KEYS.LOCATION,
            elementInfo: screenData.assetFields.wasteLocation,
            getAttribute: () => ATTRIBUTES.REQUIRED,
            values: [...(applicationData.wasteLocations || [])],
            customInputProps: () => ({
                creatable: true,
                style: { minWidth: 120 },
            }),
        },
        {
            code: EQUIPMENT_KEYS.RW_ZONE,
            type: TABLE_DATA_TYPES.SELECT,
            translationKey: EQUIPMENT_KEYS.RW_ZONE,
            getAttribute: (eqp) =>
                applicationData.wasteZones[eqp[EQUIPMENT_KEYS.LOCATION]]?.length > 0 || eqp[EQUIPMENT_KEYS.RW_ZONE]
                    ? ATTRIBUTES.OPTIONAL
                    : applicationData.wasteZones[eqp[EQUIPMENT_KEYS.LOCATION]]?.length === 0
                    ? ATTRIBUTES.READONLY
                    : ATTRIBUTES.READONLY_TEXT,
            values: (eqp) => applicationData.wasteZones[eqp[EQUIPMENT_KEYS.LOCATION]],
            customInputProps: ({ object }) => ({
                placeholder:
                    applicationData.wasteZones[object[EQUIPMENT_KEYS.LOCATION]]?.length === 0 ? 'No options' : '',
                style: { minWidth: 120 },
            }),
        },
        {
            code: EQUIPMENT_KEYS.RW_SUBZONE,
            type: TABLE_DATA_TYPES.SELECT,
            translationKey: EQUIPMENT_KEYS.RW_SUBZONE,
            getAttribute: (eqp) =>
                applicationData.rwSubzoneMap[eqp[EQUIPMENT_KEYS.LOCATION]]?.[eqp[EQUIPMENT_KEYS.RW_ZONE]]?.length > 0 ||
                eqp[EQUIPMENT_KEYS.RW_SUBZONE]
                    ? ATTRIBUTES.OPTIONAL
                    : applicationData.rwSubzoneMap[eqp[EQUIPMENT_KEYS.LOCATION]]?.[eqp[EQUIPMENT_KEYS.RW_ZONE]]
                          ?.length === 0
                    ? ATTRIBUTES.READONLY
                    : ATTRIBUTES.READONLY_TEXT,
            values: (eqp) => applicationData.rwSubzoneMap[eqp[EQUIPMENT_KEYS.LOCATION]]?.[eqp[EQUIPMENT_KEYS.RW_ZONE]],
            customInputProps: ({ object }) => ({
                placeholder:
                    applicationData.rwSubzoneMap[object[EQUIPMENT_KEYS.LOCATION]]?.[object[EQUIPMENT_KEYS.RW_ZONE]]
                        ?.length === 0
                        ? 'No options'
                        : '',
                style: { minWidth: 120 },
            }),
        },
        ...(!applicationData.isHazardsFeatureActive
            ? [
                  {
                      code: EQUIPMENT_KEYS.ASSOCIATED_RISK,
                      type: TABLE_DATA_TYPES.SELECT,
                      translationKey: EQUIPMENT_KEYS.ASSOCIATED_RISK,
                      getAttribute: () => ATTRIBUTES.HIDDEN,
                      values: Tools.getCustomFieldsCodeDesc(customFieldsDef[constants.cfpAssociatedRisks], true),
                      customInputProps: () => ({
                          style: { minWidth: 290 },
                      }),
                  },
              ]
            : []),
        {
            code: EQUIPMENT_KEYS.GROSS_WEIGHT,
            type: TABLE_DATA_TYPES.INPUT,
            translationKey: EQUIPMENT_KEYS.GROSS_WEIGHT,
            getAttribute: () => ATTRIBUTES.REQUIRED,
            dataType: 'number',
            customInputProps: () => ({
                style: { maxWidth: 120 },
            }),
            validate: (eqp) => (value) =>
                value &&
                (!eqp[EQUIPMENT_KEYS.CONTAINER_TARE_WEIGHT] || +eqp[EQUIPMENT_KEYS.CONTAINER_TARE_WEIGHT] <= value),
        },
        {
            code: EQUIPMENT_KEYS.LENGTH,
            customValue: (object, field) =>
                isContainer(object) ? object[CONTAINER_DIMENSIONS_KEY_MAP[field.code]] : object[field.code],
            type: TABLE_DATA_TYPES.INPUT,
            translationKey: EQUIPMENT_KEYS.LENGTH,
            dataType: 'number',
            getAttribute: (object, field) =>
                isContainer(object) &&
                rwp[RWP_KEYS.EQUIPMENT_CONFIGURATIONS]?.[object[EQUIPMENT_KEYS.CODE]]?.[
                    CONTAINER_DIMENSIONS_KEY_MAP[field.code]
                ]
                    ? ATTRIBUTES.READONLY_TEXT
                    : ATTRIBUTES.REQUIRED,
            customInputProps: ({ object }) => ({
                style: { maxWidth: 120 },
                valueKey: isContainer(object) ? EQUIPMENT_KEYS.CONTAINER_INTERNAL_LENGTH : EQUIPMENT_KEYS.LENGTH,
            }),
        },
        {
            code: EQUIPMENT_KEYS.WIDTH,
            customValue: (object, field) =>
                isContainer(object) ? object[CONTAINER_DIMENSIONS_KEY_MAP[field.code]] : object[field.code],
            type: TABLE_DATA_TYPES.INPUT,
            translationKey: EQUIPMENT_KEYS.WIDTH,
            dataType: 'number',
            getAttribute: (object, field) =>
                isContainer(object) &&
                rwp[RWP_KEYS.EQUIPMENT_CONFIGURATIONS]?.[object[EQUIPMENT_KEYS.CODE]]?.[
                    CONTAINER_DIMENSIONS_KEY_MAP[field.code]
                ]
                    ? ATTRIBUTES.READONLY_TEXT
                    : ATTRIBUTES.REQUIRED,
            customInputProps: ({ object }) => ({
                style: { maxWidth: 120 },
                valueKey: isContainer(object) ? EQUIPMENT_KEYS.CONTAINER_INTERNAL_WIDTH : EQUIPMENT_KEYS.WIDTH,
            }),
        },
        {
            code: EQUIPMENT_KEYS.HEIGHT,
            customValue: (object, field) =>
                isContainer(object) ? object[CONTAINER_DIMENSIONS_KEY_MAP[field.code]] : object[field.code],
            type: TABLE_DATA_TYPES.INPUT,
            translationKey: EQUIPMENT_KEYS.HEIGHT,
            dataType: 'number',
            getAttribute: (object, field) =>
                isContainer(object) &&
                rwp[RWP_KEYS.EQUIPMENT_CONFIGURATIONS]?.[object[EQUIPMENT_KEYS.CODE]]?.[
                    CONTAINER_DIMENSIONS_KEY_MAP[field.code]
                ]
                    ? ATTRIBUTES.READONLY_TEXT
                    : ATTRIBUTES.REQUIRED,
            customInputProps: ({ object }) => ({
                style: { maxWidth: 120 },
                valueKey: isContainer(object) ? EQUIPMENT_KEYS.CONTAINER_INTERNAL_HEIGHT : EQUIPMENT_KEYS.HEIGHT,
            }),
        },
        {
            code: 'EQUIPMENT_CONFIGURATION',
            customValue: (object) =>
                isContainer(object)
                    ? rwp[RWP_KEYS.EQUIPMENT_CONFIGURATIONS]?.[object[EQUIPMENT_KEYS.CODE]]?.code || (
                          <span style={{ color: 'red' }}>{getTranslation('NONE')}</span>
                      )
                    : null,
            type: TABLE_DATA_TYPES.STATIC,
            translationKey: 'EQUIPMENT_CONFIGURATION',
            getAttribute: () => ATTRIBUTES.READONLY,
        },
        {
            code: EQUIPMENT_KEYS.CONTAINER_TARE_WEIGHT,
            customValue: (object, field) =>
                rwp[RWP_KEYS.EQUIPMENT_CONFIGURATIONS]?.[object[EQUIPMENT_KEYS.CODE]]?.[field.code] ||
                object[field.code],
            type: TABLE_DATA_TYPES.STATIC,
            translationKey: EQUIPMENT_KEYS.CONTAINER_TARE_WEIGHT,
            getAttribute: () => ATTRIBUTES.READONLY,
        },
        {
            code: EQUIPMENT_KEYS.CONTAINER_USEFUL_VOLUME,
            customValue: (object, field) =>
                rwp[RWP_KEYS.EQUIPMENT_CONFIGURATIONS]?.[object[EQUIPMENT_KEYS.CODE]]
                    ? rwp[RWP_KEYS.EQUIPMENT_CONFIGURATIONS][object[EQUIPMENT_KEYS.CODE]][field.code]
                    : object[field.code],
            type: TABLE_DATA_TYPES.STATIC,
            translationKey: EQUIPMENT_KEYS.CONTAINER_USEFUL_VOLUME,
            getAttribute: () => ATTRIBUTES.READONLY,
        },
        {
            code: EQUIPMENT_KEYS.CONTAINER_FULL,
            type: TABLE_DATA_TYPES.CHECKBOX,
            translationKey: EQUIPMENT_KEYS.CONTAINER_FULL,
            getAttribute: (equipment) => (isContainer(equipment) ? ATTRIBUTES.OPTIONAL : ATTRIBUTES.READONLY_TEXT),
            customInputProps: () => ({
                style: { maxWidth: '40px !important' },
                trueValue: 'true',
                falseValue: 'false',
            }),
        },
        ...(applicationData.isHazardsFeatureActive
            ? [
                  {
                      code: EQUIPMENT_KEYS.HAZARD_CODES,
                      type: TABLE_DATA_TYPES.SELECT,
                      translationKey: TRANSLATION_KEYS.HAZARD_CODES,
                      customInputProps: () => ({
                          multi: true,
                          searchable: true,
                          style: { minWidth: 160 },
                      }),
                      getAttribute: (equipment) =>
                          Array.isArray(equipment[EQUIPMENT_KEYS.HAZARD_CODES])
                              ? ATTRIBUTES.OPTIONAL
                              : ATTRIBUTES.HIDDEN,
                      values: rwpDropdowns.hazard,
                  },
                  {
                      code: EQUIPMENT_KEYS.HAZARD_COMMENT,
                      type: TABLE_DATA_TYPES.INPUT,
                      translationKey: TRANSLATION_KEYS.HAZARD_COMMENT,
                      getAttribute: (equipment) =>
                          shouldShowComment({ equipment, applicationData }) ? ATTRIBUTES.REQUIRED : ATTRIBUTES.HIDDEN,
                      customInputProps: () => ({
                          style: { minWidth: 120 },
                      }),
                  },
              ]
            : []),
    ];

const Table = ({
    rwpGetters,
    storeActions,
    physicalDataFields,
    outputEquipmentList,
    outputEquipmentMap,
    formFields,
    isReadOnly,
    stylesMap,
    getTranslation,
}) => {
    const updateOutputEquipment = (eqCode) => (key, value, onMount) => {
        let eqList = [eqCode];
        if (rwpGetters.isApplyAllLines() && !onMount) {
            const field = physicalDataFields.find((dataField) => dataField.code === key);
            if (!field) return;
            eqList = outputEquipmentList.filter(
                (code) =>
                    !field.getAttribute ||
                    [ATTRIBUTES.OPTIONAL, ATTRIBUTES.REQUIRED].includes(field.getAttribute(outputEquipmentMap[code]))
            );
        }

        storeActions.updatedOutputEquipment(eqList, { [key]: value });
    };

    const allFields = outputEquipmentList.map((eqCode) =>
        physicalDataFields.reduce(
            (acc, field) => ({
                ...acc,
                [field.code]: (
                    <div key={field.code}>
                        {InputGenerator.generate({
                            key: `${eqCode}#${field.code}`,
                            field: {
                                ...field,
                                validate: field.validate && field.validate(outputEquipmentMap[eqCode]),
                                elementInfo: {
                                    ...field.elementInfo,
                                    xpath: `${field.code}${eqCode}`,
                                },
                            },
                            object: outputEquipmentMap[eqCode],
                            updateObject: updateOutputEquipment(eqCode),
                            formFields,
                            hideLabel: true,
                            ...(isReadOnly &&
                            (!field.getAttribute ||
                                field.getAttribute(outputEquipmentMap[eqCode], field) !== ATTRIBUTES.READONLY_TEXT)
                                ? {
                                      getAttribute: () => ATTRIBUTES.READONLY,
                                  }
                                : {}),
                        })}
                    </div>
                ),
            }),
            {
                isContainer: isContainer(outputEquipmentMap[eqCode]),
            }
        )
    );

    return (
        <EISTable
            data={allFields}
            headers={physicalDataFields.map((field) => getTranslation(field.translationKey))}
            propCodes={physicalDataFields.map((field) => field.code)}
            stylesMap={stylesMap}
            style={{ width: 'auto', height: 'auto' }}
        />
    );
};

const stylesMap = {
    isContainer: {
        backgroundColor: '#deebf7',
    },
};

export default class PhysicalDataStep extends WizardStep {
    formFields = [];

    state = { physicalDataFields: [], panelExpanded: false };

    componentDidMount() {
        super.componentDidMount();
        const { rwpGetters, storeActions, isReadOnly } = this.props;
        const outputEquipmentMap = rwpGetters.getOutputEquipmentMap();
        const rwp = rwpGetters.getRWPProperties();
        const eqpConfs = rwp[RWP_KEYS.EQUIPMENT_CONFIGURATIONS];

        const properties = [
            EQUIPMENT_KEYS.CONTAINER_INTERNAL_LENGTH,
            EQUIPMENT_KEYS.CONTAINER_INTERNAL_HEIGHT,
            EQUIPMENT_KEYS.CONTAINER_INTERNAL_WIDTH,
        ];

        const incons = Object.values(outputEquipmentMap)
            .filter(isContainer)
            .filter((eqp) => eqpConfs[eqp[EQUIPMENT_KEYS.CODE]])
            .filter((eqp) => properties.some((prop) => eqpConfs[eqp[EQUIPMENT_KEYS.CODE]][prop] !== eqp[prop]));

        if (incons.length && !isReadOnly) {
            const a = Tools.applyToFields(
                Tools.filterObjectFieldsFromList(
                    outputEquipmentMap,
                    incons.map((s) => s[EQUIPMENT_KEYS.CODE])
                ),
                (eqp) => ({
                    ...eqp,
                    ...Tools.aggregateObjects(
                        properties.map((prop) => ({ [prop]: eqpConfs[eqp[EQUIPMENT_KEYS.CODE]][prop] || eqp[prop] }))
                    ),
                })
            );
            storeActions.updatedOutputEquipmentMap(a);
        }

        this.setState({ physicalDataFields: getPhysicalDataFields({ ...this.props, rwp }) });
    }

    canContinue = () => {
        const { getTranslation } = this.props;

        const valid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );

        if (!valid) {
            showError(getTranslation(TREC_GENERAL_KEYS.REQUIRED_ERROR_MESSAGE), null, 4000);
        }

        return valid;
    };

    saveChanges = () => true;

    commitChanges = (callback) => callback();

    render() {
        const { rwpGetters, storeActions, getTranslation, isReadOnly, HeaderInfo } = this.props;
        const outputEquipmentList = rwpGetters.getOutputEquipmentList();
        const outputEquipmentMap = rwpGetters.getOutputEquipmentMap();
        const { physicalDataFields, panelExpanded } = this.state;

        return (
            <div style={{ padding: 6, overflowX: 'hidden', display: 'inline-block', width: '100%' }}>
                <EISPanel
                    heading={getTranslation(TRANSLATION_KEYS.PHYSICAL_DATA)}
                    detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                    alwaysExpanded
                >
                    <EAMCheckbox
                        label={getTranslation(TRANSLATION_KEYS.APPLY_ALL_LINES)}
                        value={rwpGetters.isApplyAllLines()}
                        updateProperty={(key, value) => storeActions.setApplyAllLines(value)}
                        trueValue
                        falseValue={false}
                    />
                    {/* <EISTable
                        data={allFields}
                        headers={physicalDataFields.map((field) => getTranslation(field.translationKey))}
                        propCodes={physicalDataFields.map((field) => field.code)}
                        stylesMap={stylesMap}
                        style={{ width: 'auto', height: 'auto' }}
                    /> */}
                    <Table
                        rwpGetters={rwpGetters}
                        storeActions={storeActions}
                        physicalDataFields={physicalDataFields}
                        outputEquipmentList={outputEquipmentList}
                        outputEquipmentMap={outputEquipmentMap}
                        formFields={this.formFields}
                        isReadOnly={isReadOnly}
                        stylesMap={stylesMap}
                        getTranslation={getTranslation}
                    />
                </EISPanel>
                <EISPanel
                    heading={getTranslation(TRANSLATION_KEYS.EXTRA_INFO)}
                    detailsStyle={{ display: 'flex', flexDirection: 'column' }}
                    panelExpanded={panelExpanded}
                    onPanelChange={(s) => this.setState({ panelExpanded: s })}
                >
                    <HeaderInfo />
                </EISPanel>
            </div>
        );
    }
}
