import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { format } from 'date-fns';
import parse from 'date-fns/parse';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import MenuIcon from 'mdi-material-ui/Menu';
import RefreshIcon from 'mdi-material-ui/Refresh';
import BlockUi from 'react-block-ui';
import EAMDatePicker from 'eam-components/dist/ui/components/inputs/EAMDatePicker';
import {
    DATE_TIME_FORMAT,
    EQUIPMENT_DTO_KEYLIST,
    EQUIPMENT_KEYS,
    GRID_ROW_COLORS,
    RPAR_PERFORM_KEYS,
} from '../../../enums/Constants';
import WSAutocomplete from '../../../tools/rest/WSAutocomplete';
import WSRPARequest from '../../../tools/rest/WSRPARequest';
import { generateRoute, getExternalLink, getInternalLink, ROUTES } from '../../../tools/Routes';
import Tools from '../../../tools/Tools';
import TRECButton from '../../components/buttons/TRECButton';
import EquipmentHazardContainer from '../../components/eqphazard/EquipmentHazardContainer';
import EAMGridNoOverflow from '../../components/grid/EAMGridNoOverflow';
import gridNoOverflowClasses from '../../components/grid/EAMGridNoOverflow.module.css';
import SearchPanel from '../../components/searchpanel/SearchPanel';
import TRECSearchPage from '../TRECSearchPage';
import { handleError, showSuccess } from 'tools/TrecNotifications';

const styles = {
    rpAnalysisSection: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 1em)',
    },
    filterSection: {},
    gridSection: {
        flexGrow: '1',
        height: '100%',
    },
    actionButtonSection: {},
    labelRight: {
        width: 'auto',
        minWidth: 120,
        textAlign: 'right',
    },
    datePicker: {
        width: 'auto',
    },
};

const CELL_TYPES = {
    PURPOSE: 'evt_cf_purpose',
    REQUEST: 'ana_request',
    WORKORDER: 'evt_code',
    EQUIPMENT: 'ana_object',
    ANALYSIS_TYPE: 'ana_type',
    CHECKBOX: 'SELECTED',
    ANALYSIS_OFFICER: 'evt_group_performedby',
    ANALYSIS_JOB_STATUS: 'evt_job_status',
    ANALYSIS_JOB: 'ana_analysis',
    BATCH: 'ana_batch',
    REPORT: 'ana_reportwo',
    LABORATORY: 'ana_lab',
    REQUEST_STATUS: 'evt_status',
    ACTIVITY_UNIT: 'ana_actunit',
    FAMILY: 'obj_family', // 'ana_udfchar03',
    MATERIAL: 'obj_material', // 'ana_udfchar04',
    CREATED: 'evt_created',
    GROUP_JOB_STATUS: 'evt_group_status',
    RPO: 'ana_rpo',
    REQUESTOR: 'evt_createdby',
    POST_DEST: 'post_dest', // 'evt_udfchar28',
    POST_DEST_RAD: 'post_dest_rad', // 'evt_udfchar27',
    FRAGILE: 'ana_edh_fragile',
    EDH_GENERATION: 'ana_edh_generation',
    EDH_RPM_DOCUMENT: 'ana_edh_rpm', // 'ana_udfchar02',
    EDH_ANALYSIS_DOCUMENT: 'ana_edh_document',
    DEADLINE: 'ana_deadline',
    OPEN_REPORT: 'OPENREPORT',
    HAZARDS_LIST: 'HAZARDS_LIST',
    RISKS: 'obj_hazards', // 'evt_udfchar29',
    RP_CLASSIFICATION: 'obj_rpclassification',
    PARENT_EVENT: 'evt_parent',
};

const extraColumns = [
    {
        width: '40px',
        t: CELL_TYPES.CHECKBOX,
    },
    {
        width: '50px',
        t: CELL_TYPES.OPEN_REPORT,
    },
    {
        width: '80px',
        t: CELL_TYPES.HAZARDS_LIST,
        headerLabel: CELL_TYPES.HAZARDS_LIST,
    },
];

const RPANALYSIS_MODES = {
    RPA_GENERATION: 'RPA_GENERATION',
    EDH_GENERATION: 'RPA_EDH_MODE',
};

class RPAnalysis extends TRECSearchPage {
    state = {
        selectedForJob: {},
        filters: {},
        rpamValues: {},
        mode: RPANALYSIS_MODES.RPA_GENERATION,
        dataspyId: null,
        selectedRows: [],
    };

    componentWillUnmount() {
        document.removeEventListener('keydown', this.performSearchOnEnterKey.bind(this), false);
    }

    performSearchOnEnterKey(event) {
        this.onKeyDown(event);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.performSearchOnEnterKey.bind(this), false);
        const { getTranslation } = this.props;

        // if (this.grid) {
        //     this.grid.toggleFilter();
        // }

        Promise.all([WSRPARequest.getDropdownValues(), WSRPARequest.getValuesDef(), WSRPARequest.getValuesGrid()]).then(
            (respArray) => {
                const valuesDefMap = respArray[1].body.data.reduce(
                    (acc, value) => ({ ...acc, [value.field]: [...(acc[value.field] || []), value.value] }),
                    {}
                );
                const rpamValues = { ...respArray[0].body.data, ...valuesDefMap };
                this.setState({
                    valuesGrid: respArray[2].body.data,
                    rpamValues: Object.entries(rpamValues).reduce(
                        (acc, [code, list]) =>
                            Object.assign(acc, {
                                [code]: list.map((code2) => ({ code: code2, desc: getTranslation(code2) })),
                            }),
                        {}
                    ),
                });

                Object.keys(CELL_TYPES).some((cellKey) => {
                    const param = Tools.getURLParameterByName(cellKey.toLowerCase());
                    if (param) {
                        return true;
                    }
                    return false;
                }) &&
                    this.grid &&
                    this.grid.clearFilters();

                Object.keys(CELL_TYPES).forEach((cellKey) => {
                    const param = Tools.getURLParameterByName(cellKey.toLowerCase());
                    if (param) {
                        this.updateFilter({ filterKey: CELL_TYPES[cellKey], filterValue: param });
                    }
                });
            }
        );
    }

    hiddenColumns = [CELL_TYPES.RISKS, CELL_TYPES.RP_CLASSIFICATION, CELL_TYPES.REPORT];

    getExtraColumns = (getTranslation) => extraColumns.map((s) => ({ ...s, headerLabel: getTranslation(s.t) }));

    getCheckbox = (row) => {
        const { applicationData } = this.props;
        const { selectedRows, mode, selectedForJob } = this.state;
        const rowObject = this.getRowAsAnObject(row);
        const checked =
            mode === RPANALYSIS_MODES.EDH_GENERATION
                ? selectedRows?.map((s) => s[CELL_TYPES.REPORT]).includes(rowObject[CELL_TYPES.REPORT])
                : this.isFromSameJob({ rowObject, selectedForJob });

        const handleChange =
            applicationData.rpamanalysisTypesParallel.includes(rowObject[CELL_TYPES.ANALYSIS_TYPE]) &&
            !checked &&
            Tools.isEmptyObject(selectedForJob) &&
            mode === RPANALYSIS_MODES.RPA_GENERATION
                ? this.selectAllEligibleRows
                : this.selectRow;

        return (
            <Checkbox
                style={{ padding: 0 }}
                checked={checked}
                onChange={(event) => handleChange(rowObject, event.target.checked)}
            />
        );
    };

    isFromSameJob = ({ rowObject, selectedForJob }) =>
        selectedForJob[CELL_TYPES.REQUEST] === rowObject[CELL_TYPES.REQUEST] &&
        selectedForJob[CELL_TYPES.ANALYSIS_TYPE] === rowObject[CELL_TYPES.ANALYSIS_TYPE] &&
        (selectedForJob[CELL_TYPES.BATCH]
            ? selectedForJob[CELL_TYPES.BATCH] === rowObject[CELL_TYPES.BATCH]
            : selectedForJob[CELL_TYPES.EQUIPMENT].includes(rowObject[CELL_TYPES.EQUIPMENT]));

    isParallelJob = ({ rowObject, selectedForJob }) => {
        const { applicationData } = this.props;
        return (
            selectedForJob[CELL_TYPES.REQUEST] === rowObject[CELL_TYPES.REQUEST] &&
            selectedForJob[CELL_TYPES.ANALYSIS_TYPE] === rowObject[CELL_TYPES.ANALYSIS_TYPE] &&
            !selectedForJob[CELL_TYPES.BATCH] &&
            applicationData.rpamanalysisTypesParallel.includes(selectedForJob[CELL_TYPES.ANALYSIS_TYPE])
        );
    };

    removeKey = (key, { [key]: _, ...rest }) => rest;

    selectAllEligibleRows = (selectedRow) => {
        const selectedForJob = {
            [CELL_TYPES.REQUEST]: selectedRow[CELL_TYPES.REQUEST],
            [CELL_TYPES.ANALYSIS_TYPE]: selectedRow[CELL_TYPES.ANALYSIS_TYPE],
            [CELL_TYPES.EQUIPMENT]: [],
            [CELL_TYPES.BATCH]: selectedRow[CELL_TYPES.BATCH],
        };

        const rowsToBeSelected = this.grid.state.rows
            .map((row) => this.getRowAsAnObject(row))
            .filter((rowObject) => this.canRenderRpaCheckbox({ rowObject, selectedForJob }));

        this.setState({
            selectedForJob: {
                ...selectedForJob,
                [CELL_TYPES.EQUIPMENT]: rowsToBeSelected.map((s) => s[CELL_TYPES.EQUIPMENT]),
            },
        });
    };

    selectRow = (rowObject, checked) => {
        const { selectedRows, mode, selectedForJob } = this.state;

        if (mode === RPANALYSIS_MODES.EDH_GENERATION) {
            const afterCheck = [
                ...new Set(
                    (selectedRows || [])
                        .concat(rowObject)
                        .filter((s) => checked || s[CELL_TYPES.REPORT] !== rowObject[CELL_TYPES.REPORT])
                ),
            ];
            this.setState({ selectedRows: afterCheck });
            return;
        }

        if (checked) {
            this.setState({
                selectedForJob: {
                    [CELL_TYPES.REQUEST]: rowObject[CELL_TYPES.REQUEST],
                    [CELL_TYPES.ANALYSIS_TYPE]: rowObject[CELL_TYPES.ANALYSIS_TYPE],
                    [CELL_TYPES.EQUIPMENT]: this.isParallelJob({ rowObject, selectedForJob })
                        ? [...this.state.selectedForJob[CELL_TYPES.EQUIPMENT], rowObject[CELL_TYPES.EQUIPMENT]]
                        : [rowObject[CELL_TYPES.EQUIPMENT]],
                    [CELL_TYPES.BATCH]: rowObject[CELL_TYPES.BATCH],
                },
            });
        } else if (
            this.state.selectedForJob[CELL_TYPES.EQUIPMENT].length > 1 &&
            this.isParallelJob({ rowObject, selectedForJob })
        ) {
            this.setState((prevState) => ({
                selectedForJob: {
                    ...prevState.selectedForJob,
                    [CELL_TYPES.EQUIPMENT]: prevState.selectedForJob[CELL_TYPES.EQUIPMENT].filter(
                        (e) => e !== rowObject[CELL_TYPES.EQUIPMENT]
                    ),
                },
            }));
        } else {
            this.setState({
                selectedForJob: {},
            });
        }
    };

    getRowAsAnObject = (row) =>
        row.cell.reduce(
            (acc, cell) => ({
                ...acc,
                [cell.t]: cell.value || '',
            }),
            {}
        );

    gridRequestAdapter = (gridRequest) => {
        const { filters } = this.state;
        return {
            ...gridRequest,
            gridFilter: [...gridRequest.gridFilter, ...Object.values(filters)],
        };
    };

    handleUpdateDeadline = (workorder, value, rowId) => {
        this.setState({ loading: true });
        WSRPARequest.getRPAnalysisPerform(workorder).then((resp) => {
            const analysis = WSRPARequest.treatDateFromBE(resp.body.data);
            if (value !== null) {
                analysis.deadline = value;
            }
            WSRPARequest.updateRPAnalysisPerform(analysis.workorder, {
                ...analysis,
                equipmentMap: Tools.filterObjectFieldsFromList(analysis.equipmentMap, EQUIPMENT_DTO_KEYLIST),
            })
                .then(() => {
                    showSuccess('Deadline successfully updated.');
                    this.grid.state.rows[rowId].cell.filter((row) => row.t === CELL_TYPES.DEADLINE)[0].value =
                        analysis.deadline;
                    this.setState({ loading: false });
                })
                .catch((error) => {
                    handleError(error);
                    this.setState({ loading: false });
                });
        });
    };

    cellRenderer = (cell, row) => {
        const { applicationData, translatedScreenData, gridTranslations, lang } = this.props;
        const cellType = cell.t;
        const rowObject = this.getRowAsAnObject(row);

        if (cellType === CELL_TYPES.PARENT_EVENT) {
            const workOrderID = cell.value;
            return getInternalLink({
                route: generateRoute(ROUTES.workOrdersRedirect, { workOrderID }),
                text: workOrderID,
            });
        }

        if (cellType === CELL_TYPES.REQUEST) {
            const requestId = cell.value;
            return requestId
                ? getInternalLink({
                      route: generateRoute(ROUTES.rpamRequestEdit, { requestwonumber: requestId }),
                      text: requestId,
                  })
                : null;
        }
        if (cellType === CELL_TYPES.DEADLINE) {
            const workorder = rowObject[CELL_TYPES.ANALYSIS_JOB];
            const datePickerDisabled = workorder.length === 0;
            return (
                <EAMDatePicker
                    value={rowObject[CELL_TYPES.DEADLINE]}
                    updateProperty={(_, value) => this.handleUpdateDeadline(workorder, value, row.id - 1)}
                    labelStyle={styles.datePicker}
                    localeString={lang}
                    keyboard={false}
                    disabled={datePickerDisabled}
                />
            );
        }
        if (cellType === CELL_TYPES.ANALYSIS_JOB) {
            const jobId = cell.value;
            return jobId
                ? getInternalLink({ route: generateRoute(ROUTES.rpamAnalysisJob, { jobnumber: jobId }), text: jobId })
                : null;
        }
        if (cellType === CELL_TYPES.EQUIPMENT) {
            const equipmentId = cell.value;
            return equipmentId
                ? getInternalLink({
                      route: generateRoute(ROUTES.equipmentOverview, { equipmentID: equipmentId }),
                      text: equipmentId,
                  })
                : null;
        }
        if (cellType === CELL_TYPES.CHECKBOX) {
            return this.canRenderCheckbox(row) ? this.getCheckbox(row) : null;
        }
        if ([CELL_TYPES.EDH_RPM_DOCUMENT, CELL_TYPES.EDH_ANALYSIS_DOCUMENT].includes(cellType)) {
            const edhDoc = cell.value;
            if (edhDoc && edhDoc.length > 0) {
                return getExternalLink({ url: `${applicationData.edhurl}${edhDoc}`, text: edhDoc });
            }
        } else if (cellType === CELL_TYPES.FAMILY) {
            const desc = translatedScreenData.lists.objFamily.find((element) => element.code === cell.value) || {};
            return desc.desc;
        } else if (cellType === CELL_TYPES.MATERIAL) {
            const desc =
                translatedScreenData.lists.objMainMaterial.find((element) => element.code === cell.value) || {};
            return desc.desc;
        } else if (cellType === CELL_TYPES.HAZARDS_LIST) {
            return (
                <EquipmentHazardContainer
                    classification={rowObject[CELL_TYPES.RP_CLASSIFICATION]}
                    hazards={rowObject[CELL_TYPES.RISKS]}
                />
            );
        } else if (cellType === CELL_TYPES.OPEN_REPORT) {
            return (
                <Button
                    style={{ minWidth: 0, padding: '8px 12px', color: 'grey' }}
                    onClick={() =>
                        window.open(`${applicationData.rparreportUrl}${rowObject[CELL_TYPES.REQUEST]}`, '_blank')
                    }
                >
                    <AssignmentIcon />
                </Button>
            );
        } else if (gridTranslations[cellType]) {
            return (
                <Typography>
                    {cell.value
                        ?.split(',')
                        .map((code) => gridTranslations[cellType][code] || code)
                        .join(', ')}
                </Typography>
            );
        }
        return null;
    };

    canRenderCheckbox = (row) => {
        const { selectedForJob, mode, selectedRows } = this.state;
        const rowObject = this.getRowAsAnObject(row);

        switch (mode) {
            case RPANALYSIS_MODES.RPA_GENERATION: {
                return this.canRenderRpaCheckbox({ selectedForJob, rowObject });
            }
            case RPANALYSIS_MODES.EDH_GENERATION:
                return (
                    rowObject[CELL_TYPES.EDH_GENERATION] === '+' &&
                    !rowObject[CELL_TYPES.EDH_ANALYSIS_DOCUMENT] &&
                    rowObject[CELL_TYPES.POST_DEST] &&
                    rowObject[CELL_TYPES.POST_DEST_RAD] &&
                    (!selectedRows.length || this.canShareEDHDocument({ rowA: selectedRows[0], rowB: rowObject }))
                );

            default:
                return false;
        }
    };

    canRenderRpaCheckbox = ({ rowObject, selectedForJob }) => {
        const hasAnalysis = rowObject[CELL_TYPES.ANALYSIS_JOB];
        if (this.isRequestCanceled(rowObject)) return false;
        if (!Object.keys(selectedForJob).length) {
            return !hasAnalysis;
        }
        return (
            !hasAnalysis &&
            (this.isFromSameJob({ rowObject, selectedForJob }) || this.isParallelJob({ rowObject, selectedForJob }))
        );
    };

    canShareEDHDocument = ({ rowA, rowB }) =>
        [
            CELL_TYPES.POST_DEST,
            CELL_TYPES.POST_DEST_RAD,
            CELL_TYPES.RP_CLASSIFICATION,
            CELL_TYPES.LABORATORY,
            CELL_TYPES.RPO,
            CELL_TYPES.REQUESTOR,
            CELL_TYPES.ANALYSIS_OFFICER,
        ].every((cellType) => rowA[cellType] === rowB[cellType]);

    isRequestCanceled = (rowObject) => ['TX'].includes(rowObject[CELL_TYPES.REQUEST_STATUS]);

    rowStyler = (row) => {
        const rowObject = this.getRowAsAnObject(row);
        const analysisStatus = rowObject[CELL_TYPES.GROUP_JOB_STATUS];

        const deadline = parse(rowObject[CELL_TYPES.DEADLINE], 'dd-MMM-yyyy', new Date());
        const requestCreated = parse(rowObject[CELL_TYPES.CREATED], 'dd-MMM-yyyy', new Date());
        const deadlineTreated = new Date(deadline.getFullYear(), deadline.getMonth(), deadline.getDate(), 0, 0, 0);
        const diff = deadlineTreated - requestCreated;
        const dayms = 1000 * 60 * 60 * 24;
        // rowObject[CELL_TYPES.CREATED]

        const colorToApply =
            analysisStatus === 'TX'
                ? GRID_ROW_COLORS.grey
                : analysisStatus === 'T'
                ? GRID_ROW_COLORS.green
                : analysisStatus === 'RAD'
                ? {}
                : diff <= 1 * dayms
                ? GRID_ROW_COLORS.red
                : diff <= 4 * dayms
                ? GRID_ROW_COLORS.orange
                : !analysisStatus
                ? {}
                : GRID_ROW_COLORS.blue;
        return {
            ...colorToApply,
        };
    };

    updateFilter = ({ filterKey, filterValue, operator = 'EQUALS', joiner = 'AND' }, reset = false) => {
        this.setState((prevState) => {
            if (filterValue) {
                return {
                    filters: {
                        ...(reset ? {} : prevState.filters),
                        [filterKey]: {
                            operator,
                            fieldValue: filterValue,
                            joiner,
                            fieldName: filterKey,
                        },
                    },
                };
            }
            return {
                filters: reset ? {} : this.removeKey(filterKey, prevState.filters),
            };
        });
    };

    getValueForFilter = (filter) => {
        const { filters } = this.state;
        return (filters[filter] && filters[filter].fieldValue) || '';
    };

    handleProcessButton = () => {
        const { history } = this.props;
        const { selectedForJob } = this.state;

        const perform = {
            [RPAR_PERFORM_KEYS.REQUEST_CODE]: selectedForJob[CELL_TYPES.REQUEST],
            [RPAR_PERFORM_KEYS.ANALYSIS_TYPE]: selectedForJob[CELL_TYPES.ANALYSIS_TYPE],
            [RPAR_PERFORM_KEYS.EQUIPMENT_CODE]: selectedForJob[CELL_TYPES.EQUIPMENT],
            [RPAR_PERFORM_KEYS.BATCH]: selectedForJob[CELL_TYPES.BATCH],
        };

        this.setState({ loading: true });
        WSRPARequest.createRPAnalysisPerform(perform)
            .then((resp) => {
                showSuccess(`Analysis job created: ${resp.body.data}`);
                history.push(`/rpanalysis/${resp.body.data}`);
            })
            .catch((error) => {
                handleError(error);
                this.setState({ loading: false });
            });
    };

    handleMenuButton = () => {
        this.props.history.push('/menu');
    };

    handleResetButton = () => {
        this.setState({ filters: {} });
    };

    handleCreateEdh = () => {
        this.setState({ loading: true });
        const { selectedRows } = this.state;

        const location =
            selectedRows[0][CELL_TYPES.RP_CLASSIFICATION] === 'Radioactive'
                ? selectedRows[0][CELL_TYPES.POST_DEST_RAD]
                : selectedRows[0][CELL_TYPES.POST_DEST];
        const data = {
            edhDocument: {
                recipientID: selectedRows[0][CELL_TYPES.REQUESTOR],
                creatorID: selectedRows[0][CELL_TYPES.RPO],
                pickUpContactID: selectedRows[0][CELL_TYPES.ANALYSIS_OFFICER],
                destinationLoc: location,
                // desiredDate: new Date().getTime(),
                presignByList: [selectedRows[0][CELL_TYPES.RPO]],
                description: `RP Analysis return transport: ${selectedRows
                    .map((cell) => cell[CELL_TYPES.EQUIPMENT])
                    .join(', ')}`,
            },
            rpaPerfomList: selectedRows?.map((cell) => cell[CELL_TYPES.REPORT]),
        };

        WSRPARequest.previewRPAnalysisReturnEDH(data).then((resp) => {
            this.props.openConfirmDialog(
                {
                    title: 'Create EDH Transport Request (PREVIEW)',
                    message: <div>{this.getEDHFormatted(resp.body.data)}</div>,
                    cancelButtonLabel: 'Cancel',
                    confirmButtonLabel: 'Confirm',
                    waitForCompletion: true,
                },
                () => {
                    WSRPARequest.createRPAnalysisReturnEDH(data)
                        .then((resp2) => {
                            showSuccess(`EDH created: ${resp2.body.data}`);
                            this.setState({ selectedRows: [] });
                            this.grid.runSearch();
                            // history.push(`/rpanalysis/${resp.body.data}`)
                        })
                        .catch(handleError)
                        .finally(() => this.setState({ loading: false }));
                }
            );
        });
    };

    getEDHFormatted = (data) => (
        <>
            <div>
                <b>{'Description: '}</b>
                {data.description}
            </div>
            <div>
                <b>{'Creator: '}</b>
                {data.creatorID}
            </div>
            <div>
                <b>{'Recipient: '}</b>
                {data.recipientID}
            </div>
            <div>
                <b>{'Pick Up Contact: '}</b>
                {data.pickUpContactID}
            </div>
            <div style={{ display: 'inline-block', zoom: 1, verticalAlign: 'center' }}>
                <b>{'Destination: '}</b>
                {data.destinationLoc || ''}
            </div>
            <div>
                <b>{'Desired date: '}</b>
                {format(new Date(data.desiredDate), DATE_TIME_FORMAT.DATE_FNS)}
            </div>
            <div>
                <b>{'Presign By: '}</b>
                {data.presignByList.join(', ')}
            </div>
            <div>
                <b>{'Articles: '}</b>
                {data.articleList.map((s) => s.identifier).join(', ')}
            </div>
        </>
    );

    renderPage(writeAccess) {
        const { applicationData, getTranslation, translatedScreenData, gridTranslations } = this.props;
        const { rpamValues, selectedForJob, mode, dataspyId, selectedRows } = this.state;
        const { rpamanalysisTypes } = applicationData;
        const gridId = applicationData.rpanalysisGridID;

        const rpaGridId = applicationData.rpanalysisGridID2;
        const gridDataspy = rpaGridId
            ? mode === RPANALYSIS_MODES.EDH_GENERATION
                ? applicationData.rpanalysisEDHDataspyID2
                : !dataspyId || dataspyId === applicationData.rpanalysisEDHDataspyID2
                ? applicationData.rpanalysisDefaultDataspyID2
                : dataspyId
            : mode === RPANALYSIS_MODES.EDH_GENERATION
            ? applicationData.rpanalysisEDHDataspyID
            : !dataspyId || dataspyId === applicationData.rpanalysisEDHDataspyID
            ? applicationData.rpanalysisDefaultDataspyID
            : dataspyId;

        return (
            <BlockUi blocking={this.state.loading} className={gridNoOverflowClasses.outerBlock}>
                <SearchPanel alwaysExpanded>
                    <Grid container spacing={8}>
                        <Grid item xl={2} md={4} sm={12} xs={12}>
                            <EAMSelect
                                elementInfo={{
                                    text: getTranslation(RPAR_PERFORM_KEYS.ANALYSIS_TYPE),
                                }}
                                valueKey={CELL_TYPES.ANALYSIS_TYPE}
                                value={this.getValueForFilter(CELL_TYPES.ANALYSIS_TYPE)}
                                values={rpamanalysisTypes.map((at) => ({ code: at, desc: getTranslation(at) }))}
                                updateProperty={(filterKey, filterValue) =>
                                    this.updateFilter({ filterKey, filterValue })
                                }
                                labelStyle={styles.labelRight}
                            />
                        </Grid>

                        <Grid item xl={2} md={4} sm={12} xs={12}>
                            <EAMSelect
                                elementInfo={{
                                    text: getTranslation(RPAR_PERFORM_KEYS.ANALYSIS_STATUS),
                                }}
                                valueKey={CELL_TYPES.ANALYSIS_JOB_STATUS}
                                value={this.getValueForFilter(CELL_TYPES.ANALYSIS_JOB_STATUS)}
                                values={translatedScreenData.lists.jobStatuses}
                                updateProperty={(filterKey, filterValue) =>
                                    this.updateFilter({ filterKey, filterValue })
                                }
                                labelStyle={styles.labelRight}
                                clearable={false}
                            />
                        </Grid>

                        <Grid item xl={2} md={4} sm={12} xs={12}>
                            <EAMSelect
                                elementInfo={{
                                    text: getTranslation(RPAR_PERFORM_KEYS.REQUEST_STATUS),
                                }}
                                valueKey={CELL_TYPES.REQUEST_STATUS}
                                value={this.getValueForFilter(CELL_TYPES.REQUEST_STATUS)}
                                values={translatedScreenData.lists.jobStatuses}
                                updateProperty={(filterKey, filterValue) =>
                                    this.updateFilter({ filterKey, filterValue })
                                }
                                labelStyle={styles.labelRight}
                            />
                        </Grid>

                        <Grid item xl={2} md={4} sm={12} xs={12}>
                            <EAMSelect
                                elementInfo={{
                                    text: getTranslation(EQUIPMENT_KEYS.LABORATORY),
                                }}
                                valueKey={CELL_TYPES.LABORATORY}
                                value={this.getValueForFilter(CELL_TYPES.LABORATORY)}
                                values={rpamValues[EQUIPMENT_KEYS.LABORATORY]}
                                updateProperty={(filterKey, filterValue) =>
                                    this.updateFilter({ filterKey, filterValue })
                                }
                                labelStyle={styles.labelRight}
                            />
                        </Grid>

                        <Grid item xl={2} md={4} sm={12} xs={12}>
                            <EAMAutocomplete
                                elementInfo={{
                                    text: getTranslation(RPAR_PERFORM_KEYS.ANALYSIS_OFFICER),
                                }}
                                valueKey={CELL_TYPES.ANALYSIS_OFFICER}
                                value={this.getValueForFilter(CELL_TYPES.ANALYSIS_OFFICER)}
                                updateProperty={(filterKey, filterValue) =>
                                    this.updateFilter({ filterKey, filterValue })
                                }
                                loadOptions={WSAutocomplete.autocompleteEmployee}
                                autoSelectSingleElement
                                labelStyle={styles.labelRight}
                                columnsWidth={['30%', '70%']}
                            />
                        </Grid>
                        <Grid item xl={2} md={4} sm={12} xs={12}>
                            <EAMSelect
                                elementInfo={{
                                    text: getTranslation('RPA_SELECT_MODE'),
                                }}
                                clearable={false}
                                value={mode}
                                values={Object.values(RPANALYSIS_MODES).map((m) => ({
                                    code: m,
                                    desc: getTranslation(m),
                                }))}
                                updateProperty={(filterKey, filterValue) =>
                                    this.setState({ selectedRows: [], selectedForJob: {}, mode: filterValue })
                                }
                                labelStyle={styles.labelRight}
                            />
                        </Grid>
                    </Grid>
                    <section style={styles.actionButtonSection}>
                        <TRECButton onClick={this.handleMenuButton} color="default">
                            <MenuIcon />
                            MENU
                        </TRECButton>
                        <TRECButton onClick={this.handleResetButton}>
                            <RefreshIcon />
                            {getTranslation('RESETVALUES')}
                        </TRECButton>
                        {writeAccess && mode === RPANALYSIS_MODES.RPA_GENERATION && (
                            <TRECButton
                                disabled={!Object.keys(selectedForJob).length}
                                onClick={this.handleProcessButton}
                            >
                                {getTranslation('CREATEJOB')}
                            </TRECButton>
                        )}

                        {writeAccess && mode === RPANALYSIS_MODES.EDH_GENERATION && (
                            <TRECButton disabled={!selectedRows.length} onClick={this.handleCreateEdh}>
                                {getTranslation('CREATEEDH')}
                            </TRECButton>
                        )}
                    </section>
                </SearchPanel>

                <EAMGridNoOverflow
                    onRef={(grid) => {
                        this.grid = grid;
                    }}
                    gridId={rpaGridId || gridId}
                    cellRenderer={this.cellRenderer}
                    hiddenTags={this.hiddenColumns}
                    {...(gridDataspy ? { dataspyId: gridDataspy } : {})}
                    heightFilterNotVisible="200px"
                    heightFilterVisible="240px"
                    extraColumns={this.getExtraColumns(getTranslation)}
                    gridRequestAdapter={this.gridRequestAdapter}
                    rowStyler={this.rowStyler}
                    bypassAlternateRows
                    handleChangeDataSpy={(val) => this.setState({ dataspyId: val })}
                    filterVisible
                    translations={gridTranslations}
                />
            </BlockUi>
        );
    }
}

export default RPAnalysis;
