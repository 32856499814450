import WS from './WS';

/**
 * Handles all calls to REST Api
 */
class WSContainers {
    getContainer(containerCode, config = {}) {
        return WS._get(`/containers?code=${containerCode}`, config);
    }

    updateContainer(containerCode, container, config = {}) {
        return WS._post(`/containers?code=${containerCode}`, container, config);
    }

    getContainerContent(containerCode, config = {}) {
        return WS._get(`/containers/content?code=${containerCode}`, config);
    }

    addEquipmentInContainer(containerCode, equipmentCode, language, config = {}) {
        return WS._post(
            `/containers/content?containerCode=${containerCode}&equipmentCode=${equipmentCode}&lang=${language}`,
            config
        );
    }

    deleteEquipmentFromContainer(containerCode, equipmentCode, language, config = {}) {
        return WS._delete(
            `/containers/content?containerCode=${containerCode}&equipmentCode=${equipmentCode}&lang=${language}`,
            config
        );
    }

    emptyContainer(containerCode, equipmentCode, config = {}) {
        return WS._delete(`/containers/content/all?containerCode=${containerCode}`, config);
    }
}

export default new WSContainers();
