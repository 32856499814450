import React, { Component } from 'react';
import queryString from 'query-string';
import ResizableIframe from './ResizableIframe';
import { Typography } from '@material-ui/core';

class EDMSDoclightIframe extends Component {
    docLightStyle = {
        width: '100%',
        border: 'none',
        boxShadow:
            '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
    };

    render() {
        if (process.env.NODE_ENV === 'development') {
            return (
                <Typography color="textSecondary">
                    The Doclight iframe is not loaded in the development environment due to infinite network requests
                    being done in some cases (for example, while using some chromium-based browsers).
                </Typography>
            );
        }

        const {
            edmsdoclightURL,
            id,
            objectType,
            objectID,
            mode,
            profile,
            collapsible,
            context,
            title,
            onLoad,
            documentType,
        } = this.props;
        const queryParams = queryString.stringify({
            objectType,
            objectID,
            mode,
            profile,
            collapsible,
            ...(title ? { title } : {}),
            ...(context ? { context } : {}),
            ...(documentType ? { documentType } : {}),
        });
        const src = `${edmsdoclightURL}?${queryParams}`;

        return (
            <ResizableIframe
                id={id}
                iframeResizerOptions={{
                    scrolling: false,
                    checkOrigin: false, // CHECK: disable this option or list allowed origins
                    heightCalculationMethod: 'bodyOffset',
                }}
                src={src}
                style={this.docLightStyle}
                onLoad={onLoad}
            />
        );
    }
}

EDMSDoclightIframe.defaultProps = {
    mode: 'write',
    profile: 'TREC',
    collapsible: true,
    title: 'EDMS Documents',
    view: 'gallery',
};

export default EDMSDoclightIframe;
