const NS = 'RPSM';

export const RESET = `${NS}/RESET`;
export const UPDATE_SAMPLES = `${NS}/UPDATE_SAMPLES`;
export const UPDATE_ORIGINS = `${NS}/UPDATE_ORIGINS`;
export const UPDATE_PROPERTIES = `${NS}/UPDATE_PROPERTIES`;
export const UPDATE_SAMPLES_FOR_ANALYSIS = `${NS}/UPDATE_SAMPLES_FOR_ANALYSIS`;
export const UPDATE_SAMPLE_MAP = `${NS}/UPDATE_SAMPLE_MAP`;

export function resetRPSM() {
    return {
        type: RESET,
    };
}

export function updateSamples(value) {
    return {
        type: UPDATE_SAMPLES,
        value,
    };
}

export function updateOrigins(value) {
    return {
        type: UPDATE_ORIGINS,
        value,
    };
}

export function updateProperties(value) {
    return {
        type: UPDATE_PROPERTIES,
        value,
    };
}

export function updateSamplesForAnalysis(value) {
    return {
        type: UPDATE_SAMPLES_FOR_ANALYSIS,
        value,
    };
}

export default {
    resetRPSM,
    updateSamples,
    updateOrigins,
    updateProperties,
    updateSamplesForAnalysis,
};
