import { connect } from 'react-redux';
import TRECHelp from './TRECHelp';
import { toggleHelp } from '../../../actions/layoutActions';

const mapStateToProps = (state) => ({
    helpTexts: state.application.screenData[state.layout.lang].help,
    page: state.layout.currentPage,
    masterPage: state.layout.currentMasterPage,
    isOpen: state.layout.helpOpen,
    pageDescription: state.application.screenData[state.layout.lang].translations.TREC_MENU[state.layout.currentPage],
    masterPageDescription:
        state.application.screenData[state.layout.lang].translations.TREC_MENU[state.layout.currentMasterPage],
    environment: state.application.applicationData.environment,
});

const TRECHelpContainer = connect(mapStateToProps, {
    toggleHelp,
})(TRECHelp);

export default TRECHelpContainer;
