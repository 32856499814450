import React from 'react';

import BlockUi from 'react-block-ui';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import { FilterRemoveOutline } from 'mdi-material-ui';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import { Link } from 'react-router-dom';
import SearchPanel from '../../components/searchpanel/SearchPanel';
import SearchPanelColumn from '../../components/searchpanel/SearchPanelColumn';
import SearchPanelControls from '../../components/searchpanel/SearchPanelControls';
import TRECButton from '../../components/buttons/TRECButton';
import EAMGridNoOverflow from '../../components/grid/EAMGridNoOverflow';
import gridNoOverflowClasses from '../../components/grid/EAMGridNoOverflow.module.css';

import RWInconsistencyChecksCheckbox from './RWInconsistencyChecksCheckbox';
import RWInconsistencyChecksComment from './RWInconsistencyChecksComment';

import WSInconsistencyChecks from '../../../tools/rest/WSInconsistencyChecks';

import TRECSearchPage from '../TRECSearchPage';
import { showSuccess } from 'tools/TrecNotifications';

const getRowValue = (row, key) => {
    const objCodeCell = row.cell.find((cell) => cell.t === key);
    return objCodeCell === undefined ? undefined : objCodeCell.value;
};

const getRowInconsistencies = (row) =>
    row.cell
        .filter((cell) => cell.t.startsWith('inconsistence_type') && cell.value === '+')
        .map((cell) => parseInt(cell.t.replace('inconsistence_type', ''), 10));

const CELL_TYPES = {
    BUTTON: 'button',
    TEXT: 'text',
    FAMILY: 'cfw_waste_family',
    URL: 'urlFilteredOnInconsistent',
    CONTACTPERSON: 'contactPerson',
};

export default class RWInconsistencyChecks extends TRECSearchPage {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            metadata: null,
            metadataId: null,
            refreshDisabled: false,
        };
    }

    componentDidMount() {
        WSInconsistencyChecks.getInconsistencyMetadata()
            .then((response) => {
                this.setState({
                    metadata: response.body.data.reduce(
                        (acc, inconsistency) => ({
                            ...acc,
                            [inconsistency.inconsistencyNumber]: inconsistency,
                        }),
                        {}
                    ),
                });
            })
            .catch(() => {
                throw new Error('There was an error retrieving the inconsistency metadata');
            });
    }

    metadataSelectedHandler = (newId) => {
        const metadataId = newId === '' ? null : newId;

        this.onClearClick(
            metadataId !== null
                ? () =>
                      this.updateProperty(
                          `inconsistence_type${metadataId < 10 ? '0' : ''}${metadataId}`,
                          '+',
                          'EQUALS',
                          'VARCHAR'
                      )
                : undefined
        );
        this.setState({ metadataId });
    };

    clearSelection = () => {
        this.onClearClick();
        this.setState({ metadataId: null });
    };

    cellRenderer = (cell, row) => {
        const { rwpDropdowns, lang } = this.props;
        const { metadata } = this.state;
        const inconsistencies = getRowInconsistencies(row);

        const cellType = cell.t;

        if (cellType === 'obj_code') {
            return (
                <Link to={`${process.env.REACT_APP_FRONTEND}equipment/${cell.value}`} target="_blank">
                    {cell.value}
                </Link>
            );
        }

        if (cellType === CELL_TYPES.FAMILY) {
            const rwFamilyEntry = rwpDropdowns.rwFamily.find((elem) => elem.code === cell.value);
            if (rwFamilyEntry === undefined) return cell.value;
            return rwFamilyEntry.descTranslated[lang] || rwFamilyEntry.desc || cell.value;
        }

        const objCode = getRowValue(row, 'obj_code');

        if (cellType === CELL_TYPES.TEXT) {
            return <RWInconsistencyChecksComment initialComment={getRowValue(row, 'comments')} objCode={objCode} />;
        }

        if (cellType !== CELL_TYPES.BUTTON) return null;

        const acked = getRowValue(row, 'ack') === '+';

        const show = inconsistencies.every((number) => (metadata[number] || {}).ackAuthorized);

        return <RWInconsistencyChecksCheckbox initialValue={acked} objCode={objCode} show={show} />;
    };

    refresh = () => {
        this.setState({ refreshDisabled: true });
        WSInconsistencyChecks.refresh()
            .then(() => {
                showSuccess('Inconsistency check computation has started. Please refresh the page in 5 minutes.');
            })
            .catch(() => {
                throw new Error('There was an error refreshing the inconsistencies');
            });
    };

    renderPage() {
        const { metadata, processing, metadataId, refreshDisabled } = this.state;
        const { lang, applicationData, translations } = this.props;

        const keyOrdering = [
            'inconsistencyNumber',
            'description',
            'ackAuthorized',
            'title',
            'priority',
            'ticket',
            'descriptionFrench',
            'lookupList',
            'objectToBeChecked',
            'inconsistencyConditions',
            'resetAcknowledgementCondition',
        ];

        const metadataInfo =
            metadata === null || metadata[metadataId] === undefined
                ? null
                : keyOrdering.map((k) => (
                      <p key={k} style={{ whiteSpace: 'pre-wrap' }}>
                          {k}: {metadata[metadataId][k]}
                      </p>
                  ));

        const search =
            metadata === null ? null : (
                <SearchPanel title={this.props.translations.SEARCH_CRITERIA} onPanelChange={this._onPanelChange}>
                    <SearchPanelColumn>
                        <EAMSelect
                            elementInfo={{
                                text: 'Inconsistency',
                            }}
                            values={Object.values(metadata).map((metadataValues) => ({
                                code: metadataValues.inconsistencyNumber,
                                desc: `${metadataValues.title} - ${
                                    lang === 'EN' ? metadataValues.description : metadataValues.descriptionFrench
                                }`,
                            }))}
                            value={metadataId}
                            updateProperty={(k, v) => this.metadataSelectedHandler(v)}
                            key="select"
                        />
                        <div
                            style={{
                                padding: '7px',
                                backgroundColor: 'rgb(255, 244, 229)',
                                borderRadius: '4px',
                            }}
                        >
                            {translations.OUT_OF_DATE_DISCLAIMER}
                        </div>
                        <SearchPanelControls>
                            <TRECButton
                                variant="contained"
                                color="default"
                                onClick={() => this.props.history.push('/menu')}
                            >
                                <MenuIcon />
                                {this.props.translations.GOTOMENU}
                            </TRECButton>
                            <TRECButton variant="contained" onClick={this.onSearchClick}>
                                <SearchIcon />
                                {this.props.translations.SEARCH}
                            </TRECButton>
                            <TRECButton variant="contained" onClick={this.clearSelection}>
                                <FilterRemoveOutline />
                                {this.props.translations.RESETVALUES}
                            </TRECButton>
                            <TRECButton variant="contained" onClick={this.refresh} disabled={refreshDisabled}>
                                <RefreshIcon />
                                Refresh
                            </TRECButton>
                        </SearchPanelControls>
                    </SearchPanelColumn>
                    <SearchPanelColumn>
                        {metadataId !== null && (
                            <div
                                style={{
                                    height: '120px',
                                    overflowY: 'scroll',
                                    border: '1px solid rgb(238, 238, 238)',
                                    padding: '15px',
                                    borderRadius: '4px',
                                }}
                            >
                                {metadataInfo}
                            </div>
                        )}
                    </SearchPanelColumn>
                </SearchPanel>
            );

        const eamGrid =
            metadata === null ? null : (
                <EAMGridNoOverflow
                    onRef={(ref) => {
                        this.grid = ref;
                    }}
                    gridId={applicationData.gridInconsistencyChecksGridID}
                    showDataspySelection
                    language={lang}
                    headerStyle={{}}
                    hiddenTags={[
                        'ack',
                        'comments',
                        'cp_elim',
                        'cp_empty_container',
                        'cp_rpm',
                        'cp_rpm_rpa',
                        'cp_rpm_rwp',
                        'url_rpm',
                        'url_rpm_rpa',
                        'url_rwm',
                    ]}
                    cellRenderer={this.cellRenderer}
                    extraColumns={[
                        {
                            width: '125px',
                            t: CELL_TYPES.BUTTON,
                            headerLabel: 'Acknowledgement',
                        },
                        {
                            width: '300px',
                            t: CELL_TYPES.TEXT,
                            headerLabel: 'Comment',
                        },
                    ]}
                    heightFilterVisible="250px"
                    heightFilterNotVisible="205px"
                />
            );

        return (
            <BlockUi tag="div" blocking={processing} className={gridNoOverflowClasses.outerBlock}>
                {search}
                {eamGrid}
            </BlockUi>
        );
    }
}
