import { Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import { RPAR_REQUEST_KEYS } from 'enums/Constants';
import React from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';

const labelStyleLeft = {
    width: 'auto',
    minWidth: 170,
    textAlign: 'left',
};
class RPARPurpose extends WizardStep {
    state = {};

    formFields = {};

    validate = () => Object.values(this.formFields).reduce((acc, ff) => (!ff.validate || ff.validate()) && acc, true);

    canContinue = () => this.validate();

    saveChanges = (_) => {
        this.props.rparActions.updateRPARequest({
            [RPAR_REQUEST_KEYS.DESCRIPTION]: this.state[RPAR_REQUEST_KEYS.DESCRIPTION],
            [RPAR_REQUEST_KEYS.PURPOSE]: this.state[RPAR_REQUEST_KEYS.PURPOSE],
        });
        return true;
    };

    commitChanges = (callback) => {
        callback();
    };

    static getDerivedStateFromProps(props, state) {
        return {
            ...props.rparGetters.getAnalysisRequestProperties(),
            ...state,
        };
    }

    handleStateChange(key, value) {
        this.setState({
            [key]: value,
        });
    }

    handleSelectStateChange(key, value) {
        this.setState({
            [RPAR_REQUEST_KEYS.PURPOSE]: value,
        });
    }

    render() {
        const { screenData, rpamValues, getTranslation } = this.props;
        const description = this.state[RPAR_REQUEST_KEYS.DESCRIPTION];
        const analysisPurpose = this.state[RPAR_REQUEST_KEYS.PURPOSE];
        return (
            <Grid container spacing={0}>
                <Grid item md={6} sm={12} xs={12}>
                    <EISPanel heading={getTranslation(RPAR_REQUEST_KEYS.REQUESTDETAILS)} alwaysExpanded>
                        <div style={{ width: '100%', height: '100%' }}>
                            <EAMInput
                                elementInfo={{
                                    ...screenData.woFields.description,
                                    text: getTranslation(RPAR_REQUEST_KEYS.DESCRIPTION),
                                    attribute: 'R',
                                }}
                                value={description}
                                formFields={this.formFields}
                                valueKey={[RPAR_REQUEST_KEYS.DESCRIPTION]}
                                labelStyle={labelStyleLeft}
                                updateProperty={(key, value) => this.handleStateChange(key, value)}
                            />
                            <EAMSelect
                                elementInfo={{
                                    ...screenData.customFieldsRPAM.P251,
                                    text: getTranslation(RPAR_REQUEST_KEYS.PURPOSE),
                                    attribute: 'R',
                                }}
                                labelStyle={labelStyleLeft}
                                formFields={this.formFields}
                                values={rpamValues.purpose}
                                valueKey={[RPAR_REQUEST_KEYS.PURPOSE]}
                                value={analysisPurpose}
                                updateProperty={(key, value) => this.handleSelectStateChange(key, value)}
                            />
                        </div>
                    </EISPanel>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    {/* No content */}
                </Grid>
            </Grid>
        );
    }
}

export default RPARPurpose;
