import React from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Attachment from '@material-ui/icons/Attachment';
import OpenInNew from '@material-ui/icons/OpenInNew';
import TRECButton from '../../components/buttons/TRECButton';

class RWChecksToolbar extends React.Component {
    onSaveClick = () => {
        const { saveData } = this.props;
        saveData();
    };

    openReport = () => {
        const { applicationData, equipment } = this.props;
        window.open(`${applicationData.rwcheckPrintURL}${equipment.code}`, '_blanks');
    };

    render() {
        // undefined if it is not known, true or false otherwise
        const { activities, createCADRAHandler, edhDocumentHandler, history, processed, translations, writeAccess } =
            this.props;
        const containsChecklists = !activities ? undefined : !!(activities.length > 0 && activities[0].checklists);

        return (
            <div style={{ paddingBottom: '10px' }}>
                <TRECButton
                    onClick={() => {
                        history.push('/rwchecks/?status=R');
                    }}
                >
                    <ArrowBackIcon />
                    {translations.BACK}
                </TRECButton>
                {writeAccess && !processed && (
                    <TRECButton onClick={this.onSaveClick}>
                        <SaveIcon />
                        {translations.SAVE}
                    </TRECButton>
                )}
                {writeAccess && containsChecklists === false && (
                    <TRECButton onClick={createCADRAHandler}>
                        <CheckIcon />
                        {translations.CREATECADRA}
                    </TRECButton>
                )}

                {writeAccess && processed && (
                    <TRECButton onClick={edhDocumentHandler}>
                        <Attachment />
                        {translations.TREC_EDHDOCMNG}
                    </TRECButton>
                )}

                <TRECButton onClick={this.openReport}>
                    <OpenInNew />
                    {translations.RWCHECK}
                </TRECButton>
            </div>
        );
    }
}

export default RWChecksToolbar;
