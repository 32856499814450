import React, { Component } from 'react';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';

const prefixStyle = {
    position: 'absolute',
    width: 30,
    backgroundColor: '#e0e0e0',
    marginRight: -3,
    zIndex: 1,
    top: 9,
    height: 34,
    borderRadius: '4px 0 0 4px',
    fontSize: 25,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    cursor: 'pointer',
    userSelect: 'none',
};

class TRECPrefixInput extends Component {
    prefixInputStyle = {
        position: 'relative',
        flexGrow: 1,
    };

    valueStyle = {
        width: '100%',
    };

    inputProps = {
        style: {
            width: '100%',
            height: 22,
            paddingLeft: 35,
        },
    };

    validateFieldN = (value) => !Number.isNaN(value) && value >= 0 && value < 999999999999;

    prefixOnClickHandler = () => {
        const { codes, elementInfo, updateProperty, prefixValue, prefixValueKey } = this.props;
        // do nothing when readonly
        if (elementInfo.readonly) {
            return;
        }
        // remove empty code from this.props.codes
        const filteredCodes = codes.filter((code) => code);
        // set the new prefix (rotate over the codes array)
        updateProperty(prefixValueKey, filteredCodes[(filteredCodes.indexOf(prefixValue) + 1) % filteredCodes.length]);
    };

    render() {
        const { updateProperty, value, valueKey, elementInfo, formFields, prefixValue } = this.props;

        return (
            <div style={this.prefixInputStyle}>
                <div
                    style={{ ...prefixStyle, left: elementInfo.text ? 141 : 1 }}
                    onClick={this.prefixOnClickHandler}
                    onKeyDown={this.prefixOnClickHandler}
                >
                    {prefixValue}
                </div>

                <EAMInput
                    formFields={formFields}
                    style={this.valueStyle}
                    elementInfo={elementInfo}
                    value={value}
                    updateProperty={updateProperty}
                    valueKey={valueKey}
                    inputProps={this.inputProps}
                    //   validate={this.validateFieldN}
                />
            </div>
        );
    }
}

export default TRECPrefixInput;
