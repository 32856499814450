import React, { Component } from 'react';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import Grid from '@material-ui/core/Grid';
import Done from '@material-ui/icons/Done';
import Edit from '@material-ui/icons/Edit';
import MenuIcon from '@material-ui/icons/Menu';
import EditLocation from '@material-ui/icons/EditLocation';
import FitnessCenter from '@material-ui/icons/FitnessCenter';
import DeleteForever from 'mdi-material-ui/DeleteForever';
import Cancel from '@material-ui/icons/Cancel';
import Save from '@material-ui/icons/Save';
import EAMLookup from 'eam-components/dist/ui/components/lookup/EAMLookup';
import SearchPanelControls from '../../components/searchpanel/SearchPanelControls';
import TRECButton from '../../components/buttons/TRECButton';
import WSAutocomplete from '../../../tools/rest/WSAutocomplete';
import SearchPanelColumn from '../../components/searchpanel/SearchPanelColumn';
import SearchPanel from '../../components/searchpanel/SearchPanel';

const labelStyle = {
    width: 150,
    minWidth: 150,
};

/**
 * Container details section in Container page
 */
export default class ContainerDetails extends Component {
    // Net weight is the sum of the gross weight of all elements
    getNetWeight = (containerContent) => {
        if (containerContent) {
            return containerContent.map((e) => Number(e.grossWeight)).reduce((a, b) => a + b, 0);
        }
        return '';
    };

    render() {
        const {
            screenData,
            lang,
            history,
            translations,
            onContainerChange,
            onModifyContentClick,
            onChangeLocationClick,
            onModifyWeightClick,
            onPropertyChange,
            onEmptyAll,
            onCancel,
            onSave,
            container,
            containerContent,
            modifyContentMode,
            modifyLocationMode,
            modifyWeightMode,
            form,
            writeAccess,
            onContainerPropertyChange,
            computedWeight,
            applicationData,
        } = this.props;

        return (
            <SearchPanel title={translations.CONTDETAILS}>
                <SearchPanelColumn md={12}>
                    {/* Container */}
                    <EAMAutocomplete
                        elementInfo={screenData[lang].assetFields.containerEqp}
                        valueKey="container"
                        labelStyle={labelStyle}
                        value={container.code}
                        valueDesc={container.description}
                        updateProperty={onContainerPropertyChange}
                        loadOptions={WSAutocomplete.autocompleteContainer}
                        onChangeValue={onContainerChange}
                        columnsWidth={['0%', '100%']}
                    />

                    {/* Location */}
                    <EAMLookup
                        right={1}
                        top={13}
                        width={26}
                        height={26}
                        gridId={applicationData.locationRaisinGridID}
                        keys={{
                            code: 'obj_code',
                            mapCodeTo: 'locationCode',
                            desc: 'obj_desc',
                            mapDescTo: 'locationDesc',
                        }}
                        updateProperty={onPropertyChange}
                        value={form.locationCode}
                        disabled={!modifyLocationMode}
                    >
                        <EAMAutocomplete
                            elementInfo={screenData[lang].assetFields.location}
                            labelStyle={labelStyle}
                            value={form.locationCode}
                            valueKey="locationCode"
                            valueDesc={form.locationDesc}
                            descKey="locationDesc"
                            loadOptions={WSAutocomplete.autocompleteTRECLocation}
                            updateProperty={onPropertyChange}
                            disabled={!modifyLocationMode}
                            columnsCodes={['code', 'desc', 'extraAttr1']}
                            columnsWidth={['30%', '30%', '40%']}
                        />
                    </EAMLookup>

                    {/* Manufacturer */}
                    <EAMAutocomplete
                        elementInfo={screenData[lang].assetFields.manufacturer}
                        labelStyle={labelStyle}
                        value={container.manufacturerCode}
                        valueDesc={container.manufacturerDesc}
                        disabled
                    />

                    <Grid container spacing={16}>
                        <Grid item xs={12} md={6}>
                            {/* Length */}
                            <EAMInput
                                elementInfo={screenData[lang].assetFields.udfnum07}
                                labelStyle={labelStyle}
                                value={container.userDefinedFields.udfnum07}
                                disabled
                            />

                            {/* Height */}
                            <EAMInput
                                elementInfo={screenData[lang].assetFields.udfnum09}
                                labelStyle={labelStyle}
                                value={container.userDefinedFields.udfnum09}
                                disabled
                            />

                            {/* Net weight */}
                            <EAMInput
                                elementInfo={screenData[lang].assetFields.netWeightCont}
                                labelStyle={labelStyle}
                                value={this.getNetWeight(containerContent)}
                                disabled
                            />

                            {/* Weight */}
                            <EAMInput
                                elementInfo={screenData[lang].assetFields.udfnum10}
                                labelStyle={labelStyle}
                                value={form.weight}
                                updateProperty={(key, value) => onPropertyChange('weight', value)}
                                disabled={!modifyWeightMode}
                            />
                            {modifyWeightMode && computedWeight !== Number(form.weight) && (
                                <span
                                    style={{
                                        color: 'orange',
                                        borderRadius: '4px',
                                        fontSize: 'smaller',
                                    }}
                                >
                                    {translations.WEIGHTWARNING}
                                    <span
                                        onClick={() => onPropertyChange('weight', computedWeight)}
                                        onKeyDown={() => onPropertyChange('weight', computedWeight)}
                                        style={{
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {computedWeight}
                                    </span>
                                    {screenData[lang].assetFields.udfnum10.udfUom}
                                </span>
                            )}
                        </Grid>

                        <Grid item xs={12} md={6}>
                            {/* Width */}
                            <EAMInput
                                elementInfo={screenData[lang].assetFields.udfnum08}
                                labelStyle={labelStyle}
                                value={container.userDefinedFields.udfnum08}
                                disabled
                            />

                            {/* Tare weight */}
                            <EAMInput
                                label={translations.TAREWEIGHT}
                                labelStyle={labelStyle}
                                value={container.tareWeight}
                                rightAddon="kg"
                                disabled
                            />

                            {/* Number of items */}
                            <EAMInput
                                elementInfo={screenData[lang].assetFields.itemsContainer}
                                labelStyle={labelStyle}
                                value={containerContent !== undefined ? containerContent.length : '0'}
                                disabled
                            />
                        </Grid>
                    </Grid>

                    <SearchPanelControls>
                        <TRECButton
                            color="default"
                            onClick={() => {
                                history.push('/menu');
                            }}
                        >
                            <MenuIcon />
                            {translations.GOTOMENU}
                        </TRECButton>

                        {writeAccess && container.code && (
                            <>
                                {modifyContentMode ? (
                                    <>
                                        <TRECButton onClick={onCancel}>
                                            <Done />
                                            {translations.FINISHACTION}
                                        </TRECButton>

                                        <TRECButton onClick={onEmptyAll}>
                                            <DeleteForever />
                                            {translations.EMPTYALL}
                                        </TRECButton>
                                    </>
                                ) : (
                                    <>
                                        {modifyLocationMode || modifyWeightMode ? (
                                            <>
                                                <TRECButton onClick={onCancel}>
                                                    <Cancel />
                                                    {translations.CANCEL}
                                                </TRECButton>

                                                <TRECButton
                                                    onClick={onSave}
                                                    disabled={
                                                        modifyWeightMode &&
                                                        Number(form.weight || 0) < Number(container.tareWeight || 0)
                                                    }
                                                >
                                                    <Save />
                                                    {translations.SAVE}
                                                </TRECButton>
                                            </>
                                        ) : (
                                            <>
                                                <TRECButton onClick={onModifyContentClick}>
                                                    <Edit />
                                                    {translations.MODIFYCONTENT}
                                                </TRECButton>

                                                <TRECButton onClick={onChangeLocationClick}>
                                                    <EditLocation />
                                                    {translations.CHANGELOCATION}
                                                </TRECButton>

                                                <TRECButton onClick={onModifyWeightClick}>
                                                    <FitnessCenter />
                                                    {translations.MODIFYWEIGHT}
                                                </TRECButton>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </SearchPanelControls>
                </SearchPanelColumn>
            </SearchPanel>
        );
    }
}
