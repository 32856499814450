import {
    VACUUM_RENTAL_ACCESSORY_DTO_KEYLIST,
    VACUUM_RENTAL_ACCESSORY_KEYS,
    VACUUM_RENTAL_DTO_KEYLIST,
    VACUUM_RENTAL_KEYS,
    VACUUM_RENTAL_LINE_DTO_KEYLIST,
    VACUUM_RENTAL_LINE_KEYS,
} from 'ui/pages/vaccleanerrequest/VacCleanerConstants';
import { equipmentHazardInputAdapter, equipmentHazardOutputAdapter } from '../Hazards';
import WS from './WS';

class WSVacRental {
    createVacRental = async ({ vacRentalRequest, applicationData }, config = {}) =>
        (await WS._post(`/vaccleaners/requests/`, this.vacRentalAdapter(vacRentalRequest, applicationData), config))
            .body.data;

    readVacRental = async (jobNumber, applicationData, config = {}) => {
        const vaccleanerrental = (await WS._get(`/vaccleaners/requests/${jobNumber}`, jobNumber, config)).body.data;
        const vaccleanerrentalAdapted = equipmentHazardInputAdapter({ equipment: vaccleanerrental, applicationData });
        return vaccleanerrentalAdapted;
    };

    updateVacRental = async ({ vacRentalRequest, applicationData }, config = {}) =>
        (
            await WS._put(
                `/vaccleaners/requests/${vacRentalRequest[VACUUM_RENTAL_KEYS.CODE]}`,
                this.vacRentalAdapter(vacRentalRequest, applicationData),
                config
            )
        ).body.data;

    deleteVacRental = async (jobNumber, config = {}) => {
        const vaccleanerrental = (await WS._delete(`/vaccleaners/requests/${jobNumber}`, config)).body.data;
        return vaccleanerrental;
    };

    getVacTypes = async (config = {}) => (await WS._get(`/vaccleaners/types/`, config)).body.data;

    getVacAccessories = (config = {}) => WS._get(`/vaccleaners/accessories/`, config);

    vacRentalAdapter = (vacRentalRequest, applicationData) => {
        let vacRequestWithAdaptedLines = {
            ...vacRentalRequest,
            [VACUUM_RENTAL_KEYS.HAZARD_CODES]: vacRentalRequest[VACUUM_RENTAL_KEYS.HAZARD_CODES]?.filter(
                (haz) => haz !== 'NONE'
            ),
            [VACUUM_RENTAL_KEYS.HAZARDS]: vacRentalRequest[VACUUM_RENTAL_KEYS.HAZARDS] || [],
            [VACUUM_RENTAL_KEYS.VACUUMS]: this.vacRentalLineAdapter(vacRentalRequest[VACUUM_RENTAL_KEYS.VACUUMS]),
        };
        vacRequestWithAdaptedLines = equipmentHazardOutputAdapter({
            applicationData,
            equipment: vacRequestWithAdaptedLines,
        });
        return VACUUM_RENTAL_DTO_KEYLIST.reduce((acc, key) => ({ ...acc, [key]: vacRequestWithAdaptedLines[key] }), {});
    };

    vacRentalLineAdapter = (vacRentalLine) =>
        vacRentalLine
            .map((vac) => ({
                ...vac,
                [VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES]: this.vacAccessoryAdapter(
                    vac[VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES]
                ),
                [VACUUM_RENTAL_LINE_KEYS.LOCATION]: { code: vac[VACUUM_RENTAL_LINE_KEYS.LOCATION].code },
            }))
            .map((vac) => VACUUM_RENTAL_LINE_DTO_KEYLIST.reduce((acc, key) => ({ ...acc, [key]: vac[key] }), {}))
            // Remove stub codes
            .map((vac) => ({
                ...vac,
                [VACUUM_RENTAL_LINE_KEYS.CODE]:
                    vac[VACUUM_RENTAL_LINE_KEYS.CODE]?.length === 36 ? '' : vac[VACUUM_RENTAL_LINE_KEYS.CODE],
            }));

    vacAccessoryAdapter = (accessories) =>
        accessories
            .map((accessory) =>
                VACUUM_RENTAL_ACCESSORY_DTO_KEYLIST.reduce((acc, key) => ({ ...acc, [key]: accessory[key] }), {})
            )
            .map((accessory) => ({
                ...accessory,
                [VACUUM_RENTAL_ACCESSORY_KEYS.CODE]:
                    accessory[VACUUM_RENTAL_ACCESSORY_KEYS.CODE]?.length === 36
                        ? ''
                        : accessory[VACUUM_RENTAL_ACCESSORY_KEYS.CODE],
            }));

    declareVacuumUse = async ({ vacRentalRequest, assetCode }, config = {}) =>
        (
            await WS._put(
                `/vaccleaners/requests/${vacRentalRequest[VACUUM_RENTAL_KEYS.CODE]}/vacuums/${assetCode}/declareuse`,
                {},
                config
            )
        ).body.data;

    declareVacuumUseReservation = async ({ vacRentalRequest, reservationCode }, config = {}) =>
        (
            await WS._put(
                `/vaccleaners/requests/${
                    vacRentalRequest[VACUUM_RENTAL_KEYS.CODE]
                }/reservations/${reservationCode}/declareuse`,
                {},
                config
            )
        ).body.data;

    declareVacuumReturnReservation = async ({ vacRentalRequest, reservationCode, returnBean }, config = {}) =>
        (
            await WS._put(
                `/vaccleaners/requests/${
                    vacRentalRequest[VACUUM_RENTAL_KEYS.CODE]
                }/reservations/${reservationCode}/return`,
                returnBean,
                config
            )
        ).body.data;

    declareVacuumCompleteReservation = async ({ vacRentalRequest, reservationCode }, config = {}) =>
        (
            await WS._put(
                `/vaccleaners/requests/${
                    vacRentalRequest[VACUUM_RENTAL_KEYS.CODE]
                }/reservations/${reservationCode}/complete`,
                null,
                config
            )
        ).body.data;

    readLockCode = async ({ vacRentalRequest, assetCode }, config = {}) =>
        (
            await WS._get(
                `/vaccleaners/requests/${vacRentalRequest[VACUUM_RENTAL_KEYS.CODE]}/vacuums/${assetCode}/lockcode`,
                {},
                config
            )
        ).body.data;

    getVacCleanerRequestNumberFromVacCleaner = async ({ code }, config = {}) =>
        (await WS._get(`/vaccleaners/assigned/${encodeURIComponent(code)}`, {}, config)).body.data;

    updateRpmEDHTransport = async ({ rpmCode }, config = {}) =>
        (await WS._put(`/vaccleaners/rpm/${encodeURIComponent(rpmCode)}/edh`, {}, config)).body.data;

    updateEquipment = async (equipment, config = {}) =>
        (await WS._put(`/vaccleaners/equipment`, equipment, config)).body.data;
}

export default new WSVacRental();
