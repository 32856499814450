import Logout from '@material-ui/icons/PowerSettingsNew';
import { Wizard } from 'amm-tools';
import SpeedometerIcon from 'mdi-material-ui/Speedometer';
import queryString from 'query-string';
import React from 'react';
import BlockUi from 'react-block-ui';
import WSRPMeasurements from '../../../tools/rest/WSRPMeasurement';
import Tools from '../../../tools/Tools';
import TRECButton from '../../components/buttons/TRECButton';
import StaticMessage from '../../components/staticmessage/StaticMessage';
import DefaultStepper from '../../components/wizard/DefaultStepper';
import TRECPage from '../TRECPage';
import WizardControls from '../wizard/WizardControls';
import { EQUIPMENT_KEYS, REQUEST_KEYS } from './RPMRConstants';
import RPMRActionsStep from './steps/RPMRActionsStep';
import RPMRConfirmStep from './steps/RPMRConfirmStep';
import RPMRDetailsStep from './steps/RPMRDetailsStep';
import RPMREquipmentStep from './steps/RPMREquipmentStep';
import RPMRLocationStep from './steps/RPMRLocationStep';
import RPMRRequestStep from './steps/RPMRRequestStep';
import RPMRResultsStep from './steps/RPMRResultsStep';
import RPMRSearchStep from './steps/RPMRSearchStep';
import { handleError, showSuccess } from 'tools/TrecNotifications';

const RPMR_STEPS = {
    RPMRActions: {
        key: 'RPMRActions',
        Step: RPMRActionsStep,
        translation: 'ACTIONS',
    },
    RPMRSearch: {
        key: 'RPMRSearch',
        Step: RPMRSearchStep,
        translation: 'SEARCH',
    },
    RPMREquipment: {
        key: 'RPMREquipment',
        Step: RPMREquipmentStep,
        translation: 'EQUIPMENT',
    },
    RPMRResults: {
        key: 'RPMRResults',
        Step: RPMRResultsStep,
        translation: 'RESULTS',
    },
    RPMRLocation: {
        key: 'RPMRLocation',
        Step: RPMRLocationStep,
        translation: 'LOCATION',
    },
    RPMRConfirm: {
        key: 'RPMRConfirm',
        Step: RPMRConfirmStep,
        translation: 'CONFIRM',
    },
    RPMRDetails: {
        key: 'RPMRDetails',
        Step: RPMRDetailsStep,
        translation: 'DETAILS',
    },
    RPMRRequest: {
        key: 'RPMRRequest',
        Step: RPMRRequestStep,
        translation: 'REQUEST',
    },
};

const wrapStepProps = (Component, stepProps) => (props) =>
    (
        <div style={{ flex: 1, overflowY: 'visible' }}>
            <Component {...stepProps} {...props} />
        </div>
    );

const getSteps = (stepProps = {}) =>
    Tools.applyToFields(RPMR_STEPS, (step) => ({
        ...step,
        getContent: wrapStepProps(step.Step, stepProps),
        label: stepProps.getTranslation(step.translation),
    }));

const isDefaultMode = (props) => {
    const { rpmrGetters, constants } = props;
    const request = rpmrGetters.getProperties();
    const action = request[REQUEST_KEYS.ACTION];
    return ![constants.futureActionUpdateloc, constants.futureActionVacuumDeclare].includes(action);
};

const getStepSequence = (props) => {
    const { rpmrGetters, constants } = props;
    const request = rpmrGetters.getProperties();
    const action = request[REQUEST_KEYS.ACTION];
    const stepSequence = [
        ...(action === constants.futureActionUpdateloc ? [] : [RPMR_STEPS.RPMRActions]),
        RPMR_STEPS.RPMRSearch,
        RPMR_STEPS.RPMREquipment,
        ...(action === constants.futureActionUpdateloc ? [RPMR_STEPS.RPMRResults] : []),
        RPMR_STEPS.RPMRLocation,
        ...(isDefaultMode(props) ? [RPMR_STEPS.RPMRRequest, RPMR_STEPS.RPMRDetails] : []),
        RPMR_STEPS.RPMRConfirm,
    ].map((step) => step.key);

    return stepSequence;
};

const handlePerform = ({ rpmrGetters, history, constants }) => {
    const createdRPMs = rpmrGetters.getCreatedRPMs();
    let query = '';
    if (typeof createdRPMs === 'object') {
        const params = { wo: Object.values(createdRPMs) };
        query = queryString.stringify(params, { arrayFormat: 'none' });
    }
    // TODO, String case will be removed once backend is returning object map instead of string
    else if (typeof createdRPMs === 'string') {
        query = createdRPMs
            .split(constants.paramSeparator)
            .map((s) => s.split(':')[1].trim())
            .map((elem) => `wo=${elem}`)
            .join('&');
    }
    history.push(`/rpmperform/?${query}`);
};

const isUserRPOfficer = (props) => {
    const { menu } = props;
    // TODO, should I check user department instead?
    const rpMeasurementsMenu = menu.find((menuEntry) => menuEntry.menuCode === 'RPMEASUREMENTS');
    return rpMeasurementsMenu && !rpMeasurementsMenu.readOnly;
};

const canPerformBatch = (props) => {
    const { rpmrGetters } = props;
    const equipmentMap = rpmrGetters.getEquipmentMap();
    const location = equipmentMap[Object.keys(equipmentMap)[0]][EQUIPMENT_KEYS.LOCATION];

    return Object.values(equipmentMap).every((eqp) => eqp[EQUIPMENT_KEYS.LOCATION] === location);
};

const renderFinishedMessages = (props) => {
    const { translations, constants, rpmrGetters } = props;
    const messages = [];
    const request = rpmrGetters.getProperties();

    const createdRPMs = rpmrGetters.getCreatedRPMs();

    isDefaultMode(props)
        ? messages.push(
              translations.RPMEASCREATED.replace(
                  '{0}',
                  Object.entries(createdRPMs)
                      .map(([key, value]) => `${key} - ${value}`)
                      .join(', ')
              )
          )
        : messages.push(translations.EQPLOCUPDAT);
    if (request[REQUEST_KEYS.ACTION] === constants.futureActionVacuumDeclare) {
        const equipmentMap = rpmrGetters.getEquipmentMap();
        const eqsWithLockCode = Object.values(equipmentMap).filter((equipment) => equipment[EQUIPMENT_KEYS.LOCK_CODE]);
        eqsWithLockCode.forEach((equipment) =>
            messages.push(
                translations.LOCKCODEFOREQP.replace('{0}', equipment[EQUIPMENT_KEYS.CODE]).replace(
                    '{1}',
                    equipment[EQUIPMENT_KEYS.LOCK_CODE]
                )
            )
        );
    }
    return <StaticMessage message={messages} />;
};

const handleCancel = ({ history }) => history.push('/menu');

// While batch mode is not available
const postTransformations = (eqp, request, multiple) => ({
    ...eqp,
    [EQUIPMENT_KEYS.LOCATION]: request[REQUEST_KEYS.CURRENT_LOCATION_CODE],
    [EQUIPMENT_KEYS.DETACH]: request[REQUEST_KEYS.DETACH_EQUIPMENT],
    ...(multiple ? { [EQUIPMENT_KEYS.RESPONSIBLE_CID]: request[REQUEST_KEYS.RESP_CODE] } : {}),
});

const handleSubmit = ({ rpmrGetters, storeActions, constants, applicationData }, callback) => {
    const request = rpmrGetters.getProperties();
    let equipment = rpmrGetters.getEquipmentMap();

    if (!rpmrGetters.isAllowMultipleResp()) {
        equipment = Tools.applyToFields(equipment, (eqp) =>
            postTransformations(eqp, request, rpmrGetters.getEquipmentList().length > 1)
        );
    }

    const vacuumHide = [
        constants.futureActionVacuumEmpty,
        constants.futureActionVacuumEnd,
        constants.futureActionVacuumEndFull,
    ].includes(request[REQUEST_KEYS.ACTION]);
    if (vacuumHide) {
        equipment = Tools.applyToFields(equipment, (eqp) => {
            eqp[EQUIPMENT_KEYS.RESPONSIBLE_CID] = '';
            return eqp;
        });
    }

    const equipmentToCreate = Object.values(equipment).filter(
        (eq) => !['INFOR', 'MTF'].includes(eq[EQUIPMENT_KEYS.DB_SOURCE])
    );
    const equipmentToUpdate = Object.values(equipment).filter((eq) =>
        ['INFOR', 'MTF'].includes(eq[EQUIPMENT_KEYS.DB_SOURCE])
    );

    const promise1 = () =>
        equipmentToCreate.length
            ? WSRPMeasurements.createEquipment({ equipmentList: equipmentToCreate, applicationData }).then((resp) =>
                  showSuccess(`Assets created: ${Object.keys(resp.body.data).join(', ')}`)
              )
            : Promise.resolve(1);
    const promise2 = () =>
        equipmentToUpdate.length
            ? WSRPMeasurements.updateEquipment({ equipmentList: equipmentToUpdate, applicationData }).then((resp) =>
                  showSuccess(`Assets updated: ${Object.keys(resp.body.data).join(', ')}`)
              )
            : Promise.resolve(1);

    const rpm = {
        ...request,
        equipment: Object.values(equipment),
    };

    const promise3 = () =>
        constants.futureActionUpdateloc !== request[REQUEST_KEYS.ACTION]
            ? WSRPMeasurements.createRPM({ rpm, applicationData }).then((resp) => {
                  storeActions.updateCreatedRPMs(resp.body.data);
                  showSuccess(
                      Object.entries(resp.body.data)
                          .map(([key, val]) => `${key}: ${val}`)
                          .join(', ')
                  );
              })
            : Promise.resolve(1);

    storeActions.updateRPMRBlockUI(true);
    try {
        promise1()
            .then(() => promise2())
            .then(() => promise3())
            .then(() => callback())
            .catch(handleError)
            .finally(() => storeActions.updateRPMRBlockUI(false));
    } catch (err) {
        console.error(err);
    }
};

class RPMRequest extends TRECPage {
    componentWillUnmount = () => {
        const { storeActions } = this.props;
        storeActions.resetRPMR();
    };

    renderPage() {
        const {
            getTranslation,
            applicationData,
            constants,
            userData,
            screenData,
            translations,
            rpmrGetters,
            storeActions,
            customFields,
            customFieldsDef,
            urlParams,
            bufferZone,
            dropdowns,
            lang,
        } = this.props;

        const properties = rpmrGetters.getProperties();
        const futureAction = properties[REQUEST_KEYS.ACTION];
        const isVacuumAction = Tools.getIsVacuumAction(constants)(futureAction);

        const stepProps = {
            getTranslation,
            applicationData,
            constants,
            userData,
            screenData,
            translations,
            rpmrGetters,
            storeActions,
            customFields,
            customFieldsDef,
            urlParams,
            bufferZone,
            dropdowns,
            isVacuumAction,
            lang,
            requestSource: urlParams.requestSource,
            isOtherRwc:
                properties[REQUEST_KEYS.ACTION] === constants.futureActionOther &&
                properties[REQUEST_KEYS.DEPARTMENT] === applicationData.rwsection,
            isVacuumCleanerEquipment: (eqp) =>
                applicationData.vacuumCleanerClassesList.includes(eqp[EQUIPMENT_KEYS.CLASS_CODE]),
        };

        return (
            <BlockUi style={{ height: '100%' }} blocking={rpmrGetters.isBlockUI()}>
                <Wizard
                    {...this.props}
                    title={
                        (dropdowns.futureAction.find((val) => val.code === properties[REQUEST_KEYS.ACTION]) || {}).desc
                    }
                    steps={getSteps(stepProps)}
                    stepSequence={getStepSequence(this.props)}
                    firstStepIndex={this.props.firstStepIndex || 0}
                    handleSubmit={(callback) => {
                        handleSubmit(this.props, callback);
                    }}
                    handleCancel={() => handleCancel(this.props)}
                    renderStepper={(props) => (
                        <>
                            <DefaultStepper {...props} />
                            {props.isFinished && renderFinishedMessages(this.props)}
                        </>
                    )}
                    controls={(p) => (
                        <WizardControls {...p} getTranslation={getTranslation}>
                            {({ isFinished }) =>
                                isFinished && (
                                    <>
                                        <TRECButton onClick={storeActions.logout} color="default">
                                            <Logout />
                                            {translations.LOGOUT}
                                        </TRECButton>
                                        {isDefaultMode(this.props) && isUserRPOfficer(this.props) && (
                                            <TRECButton
                                                disabled={!canPerformBatch(this.props)}
                                                onClick={() => handlePerform(this.props)}
                                            >
                                                <SpeedometerIcon />
                                                {translations.PERFORMREQU}
                                            </TRECButton>
                                        )}
                                    </>
                                )
                            }
                        </WizardControls>
                    )}
                />
            </BlockUi>
        );
    }
}
export default RPMRequest;
