import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Wizard } from 'amm-tools';
import BlockUi from 'react-block-ui';
import DefaultStepper from 'ui/components/wizard/DefaultStepper';
import { WarningText } from 'ui/pages/wrrequest/WRRHelpers';
import { REQUEST_MODES, TREC_GENERAL_KEYS } from '../../../enums/Constants';
import WSEquipment from '../../../tools/rest/WSEquipment';
import WSRPMeasurement from '../../../tools/rest/WSRPMeasurement';
import WSRWManagement from '../../../tools/rest/WSRWManagement';
import WSRWPProcessing from '../../../tools/rest/WSRWPProcessing';
import { generateRoute, ROUTES, withQueryParams } from '../../../tools/Routes';
import Tools from '../../../tools/Tools';
import ValuesManager from '../../../tools/ValuesManager';
import { REQUEST_SOURCES } from '../rpmrequest2/RPMRConstants';
import TRECPage from '../TRECPage';
import RWPConfirmationDialog from './dialogs/RWPConfirmationDialog';
import { EquipmentPropertiesInfoLoader } from './InputEquipmentProperties/InputEquipmentProperties';
import {
    DIALOGS,
    EQUIPMENT_KEYS,
    RPSM_KEYS_DTO,
    RWP_KEYS,
    RWP_KEYS_DTO,
    RWP_STATUS,
    STEPS,
    TRANSLATION_KEYS,
} from './RWPConstants';
import RWProcessingControls from './RWProcessingControls';
import { applyDefaults, isCompleted, isContainer } from './RWProcessingTools';
import StageProgress, { STAGE_ERROR_TYPES, STAGE_STATUS_TYPES } from './StageProgress/StageProgress';
import { withStageProgress } from './StageProgress/useStageProgress';
import InputWasteStep from './steps/InputWasteStep';
import OutputWasteStep from './steps/OutputWasteStep';
import PhysicalDataStep from './steps/PhysicalDataStep';
import PropertiesStep from './steps/PropertiesStep';
import { handleError, showSuccess } from 'tools/TrecNotifications';

const RWP_STEPS = {
    [STEPS.INPUT_WASTE]: {
        key: STEPS.INPUT_WASTE,
        Step: InputWasteStep,
        translation: TRANSLATION_KEYS.INPUT_WASTE,
    },
    [STEPS.OUTPUT_WASTE]: {
        key: STEPS.OUTPUT_WASTE,
        Step: OutputWasteStep,
        translation: TRANSLATION_KEYS.OUTPUT_WASTE,
    },
    [STEPS.PHYSICAL_DATA]: {
        key: STEPS.PHYSICAL_DATA,
        Step: PhysicalDataStep,
        translation: TRANSLATION_KEYS.PHYSICAL_DATA,
    },
    [STEPS.PROPERTIES]: {
        key: STEPS.PROPERTIES,
        Step: PropertiesStep,
        translation: TRANSLATION_KEYS.RPM_AND_RW_PROPERTIES,
    },
};

const STAGE_TYPES = {
    SAVE: 'SAVE',
    VALIDATE: 'VALIDATE',
    FINISH: 'FINISH',
    SAVE_RWP_JOB: 'SAVE_RWP_JOB',
    SAVE_OUTPUT_WASTE: 'SAVE_OUTPUT_WASTE',
    SAVE_RPMS: 'SAVE_RPMS',
    ASSOCIATE_RPMS: 'ASSOCIATE_RPMS',
    PREPARE_FINISH: 'PREPARE_FINISH',
    CHECK_ASYNC: 'CHECK_ASYNC',
    MARK_ASYNC: 'MARK_ASYNC',
};

const STAGES = {
    [STAGE_TYPES.CHECK_ASYNC]: {
        id: [STAGE_TYPES.CHECK_ASYNC],
        title: 'Check sync',
        description: 'Checking if the job can be finished immediately',
        status: STAGE_STATUS_TYPES.WAITING,
    },
    [STAGE_TYPES.MARK_ASYNC]: {
        id: [STAGE_TYPES.MARK_ASYNC],
        title: 'Mark async',
        description: 'Marking the job as pending finish to be completed when opportune',
        status: STAGE_STATUS_TYPES.HIDDEN,
    },
    [STAGE_TYPES.VALIDATE]: {
        id: [STAGE_TYPES.VALIDATE],
        title: 'Validation',
        description: 'Performing checks in order to close RWP',
        status: STAGE_STATUS_TYPES.HIDDEN,
    },
    [STAGE_TYPES.FINISH]: {
        id: [STAGE_TYPES.FINISH],
        title: 'Finish RWP Request',
        description: 'Closing the RWP request',
        status: STAGE_STATUS_TYPES.HIDDEN,
    },
    [STAGE_TYPES.SAVE_RWP_JOB]: {
        id: [STAGE_TYPES.SAVE_RWP_JOB],
        title: 'Saving RWP request',
        description: 'Saves all data related with current request',
        status: STAGE_STATUS_TYPES.WAITING,
    },
    [STAGE_TYPES.SAVE_OUTPUT_WASTE]: {
        id: [STAGE_TYPES.SAVE_OUTPUT_WASTE],
        title: 'Saving Output Waste',
        description: 'Saves all data entered for output waste equipment',
        status: STAGE_STATUS_TYPES.WAITING,
    },
    [STAGE_TYPES.SAVE_RPMS]: {
        id: [STAGE_TYPES.SAVE_RPMS],
        title: 'Saving RP Measurements data',
        description: 'Saves RP measurement details for output waste',
        status: STAGE_STATUS_TYPES.WAITING,
    },
    [STAGE_TYPES.ASSOCIATE_RPMS]: {
        id: [STAGE_TYPES.ASSOCIATE_RPMS],
        title: 'RP Measurements Association',
        description: 'Associated RP Measurements with current RWP Job',
        status: STAGE_STATUS_TYPES.WAITING,
    },
    [STAGE_TYPES.PREPARE_FINISH]: {
        id: [STAGE_TYPES.PREPARE_FINISH],
        title: 'Preparing Finish stage',
        description: 'Prepare the RWP for closing',
        status: STAGE_STATUS_TYPES.HIDDEN,
    },
};

const wrapStepProps = (Component, stepProps) => (props) => <Component {...stepProps} {...props} />;

const getSteps = (stepProps = {}) =>
    Tools.applyToFields(RWP_STEPS, (step) => ({
        ...step,
        getContent: wrapStepProps(step.Step, stepProps),
        label: stepProps.getTranslation(step.translation),
    }));

const userCanMeasure = (props) => {
    const { menu } = props;
    const rpMeasurementsMenu = menu.find((menuEntry) => menuEntry.menuCode === 'RPMEASUREMENTS');
    return rpMeasurementsMenu && !rpMeasurementsMenu.readOnly;
};

const getStepSequence = (props) => {
    const { mode, rwpGetters } = props;
    const rwpProperties = rwpGetters.getRWPProperties();
    return [
        RWP_STEPS.InputWasteStep,
        ...([REQUEST_MODES.EDITING, REQUEST_MODES.READING].includes(mode)
            ? [
                  RWP_STEPS.OutputWasteStep,
                  RWP_STEPS.PhysicalDataStep,
                  ...(userCanMeasure(props) && rwpProperties[RWP_KEYS.USER_STATUS] !== RWP_STATUS.PENDING
                      ? [RWP_STEPS.PropertiesStep]
                      : []),
              ]
            : []),
    ].map((step) => step.key);
};

const handleCancel = ({ history }) => history.push(ROUTES.menu);
const handleGoBack = ({ history }) => history.push(ROUTES.rwProcessingSearch);

class RWProcessing extends TRECPage {
    state = {
        loadingData: true,
        valuesGrid: {},
        isReadOnly: false,
        wasteLocations: [],
        activeDialog: undefined,
        inputContainers: [],
    };

    UNSAFE_componentWillMount = () => {
        const { match, mode, storeActions, rwpGetters } = this.props;
        const { jobID } = match.params;
        storeActions.setRWPBlockUI(true);
        const isLoadingRWPJob = [REQUEST_MODES.EDITING, REQUEST_MODES.READING].includes(mode) && jobID;

        Promise.all([
            ...(isLoadingRWPJob ? [this.loadRWPJob(jobID)] : []),
            this.loadValuesGrid(),
            this.loadWasteLocations(),
        ]).finally(() => {
            storeActions.setRWPBlockUI(false);
            this.setState({ loadingData: false });
            if ([REQUEST_MODES.CREATING].includes(mode)) {
                storeActions.updateRWPProperties(
                    applyDefaults(rwpGetters.getRWPProperties(), {
                        [RWP_KEYS.START_DATE]: new Date(),
                        [RWP_KEYS.USER_STATUS]: 'R',
                    })
                );
            }
        });
    };

    componentDidUpdate(_, prevState) {
        const { rwpGetters, mode, match, history } = this.props;
        const { activeDialog } = this.state;
        const { writeAccess } = this.checkAccess();
        const rwpProperties = rwpGetters.getRWPProperties();
        const isReadOnly = mode === REQUEST_MODES.READING || !writeAccess || isCompleted({ rwpProperties });
        if (isReadOnly && mode !== REQUEST_MODES.READING && !activeDialog) {
            if (match.params.jobID) {
                history.push(generateRoute(ROUTES.rwProcessingRead, { jobID: match.params.jobID }));
            } else {
                history.push(ROUTES.menu);
            }
        }
        if (isReadOnly !== prevState.isReadOnly && !activeDialog) this.setState({ isReadOnly });
    }

    componentWillUnmount = () => {
        const { storeActions } = this.props;
        storeActions.resetRWP();
    };

    loadValuesGrid = async () =>
        WSRWPProcessing.getValuesGrid()
            .then((response) => this.setState({ valuesGrid: response.body.data }))
            .catch(handleError);

    loadWasteLocations = async () =>
        WSRWManagement.getWasteLocations()
            .then((response) => this.setState({ wasteLocations: response.body.data }))
            .catch(handleError);

    loadRWPJob = async (jobID) => {
        const { storeActions, applicationData } = this.props;
        let rwp;
        return WSRWPProcessing.getProcessingJob({ jobID, applicationData })
            .then((response) => {
                rwp = Tools.filterObjectFieldsFromList(response.body.data, [
                    ...RWP_KEYS_DTO,
                    RWP_KEYS.SYSTEM_STATUS,
                    RWP_KEYS.USER_STATUS,
                    RWP_KEYS.CREATED_BY,
                    RWP_KEYS.CREATION_DATE,
                    RWP_KEYS.UPDATED_BY,
                    RWP_KEYS.UPDATION_DATE,
                    RWP_KEYS.IRRADIATION_END_DATE_MAX,
                    RWP_KEYS.IRRADIATION_END_DATE_MIN,
                    RWP_KEYS.FACILITIES,
                    RWP_KEYS.MARKED_ASYNC,
                    RWP_KEYS.EQUIPMENT_CONFIGURATIONS,
                ]);
                rwp[RWP_KEYS.OUTPUT_EQUIPMENT] = Object.keys(response.body.data[RWP_KEYS.OUTPUT_EQUIPMENT]);
                storeActions.updateRWPProperties(rwp);
                storeActions.updateRPMMap(response.body.data[RWP_KEYS.RPM_MAP]);
                return {
                    outputEquipment: response.body.data[RWP_KEYS.OUTPUT_EQUIPMENT],
                    rpmMap: response.body.data[RWP_KEYS.RPM_MAP],
                };
            })
            .then(({ outputEquipment, rpmMap }) => {
                const equipmentWithRPM = Object.keys(rpmMap).filter((e) => Boolean(rpmMap[e]));
                let mergedOutputEquipment = outputEquipment;
                return Promise.all(
                    equipmentWithRPM.map((eqCode) => WSRPMeasurement.getRPM({ rpmCode: rpmMap[eqCode] }))
                )
                    .then((responses) => {
                        mergedOutputEquipment = equipmentWithRPM.reduce(
                            (acc, eqCode, index) => ({
                                ...acc,
                                [eqCode]: {
                                    ...outputEquipment[eqCode],
                                    ...(applicationData.isHazardsFeatureActive
                                        ? {
                                              [EQUIPMENT_KEYS.HAZARDS]: outputEquipment[eqCode][EQUIPMENT_KEYS.HAZARDS],
                                          }
                                        : {}),
                                    ...Tools.filterObjectFieldsFromList(responses[index].body.data, RPSM_KEYS_DTO),
                                },
                            }),
                            outputEquipment
                        );
                    })
                    .catch(handleError)
                    .finally(() => storeActions.updatedOutputEquipmentMap(mergedOutputEquipment));
            })
            .catch(handleError)
            .then(async () => {
                const responses = await Promise.all(
                    rwp[RWP_KEYS.INPUT_EQUIPMENT].map((eq) => WSEquipment.getStructure({ equipmentID: eq }))
                );
                const equipmentStructures = responses.map((res) => res.body.data);
                let rpmeasurements = equipmentStructures
                    .map((structure) => structure.map((node) => (node.lastRpJob && node.lastRpJob) || {}))
                    .flat(2);

                const rpJobCodes = new Set();
                rpmeasurements = rpmeasurements.filter((rpmeasurement) => {
                    const isUnique = !rpJobCodes.has(rpmeasurement.jobNumber);
                    rpJobCodes.add(rpmeasurement.jobNumber);
                    return isUnique;
                });
                const inputWasteProperties = (
                    await WSRWPProcessing.getInputEquipmentProperties({ jobID: rwp[RWP_KEYS.JOB_NUMBER] })
                ).body.data;
                storeActions.updateRWPProperties({
                    [RWP_KEYS.INPUT_EQUIPMENT_PROPERTIES]: inputWasteProperties,
                    [RWP_KEYS.INPUT_EQUIPMENT_STRUCTURE_LAST_RP_MEASUREMENTS]: rpmeasurements,
                });
                this.setState({
                    inputContainers: equipmentStructures
                        .flat(2)
                        .map((e) => e.equipmentInfo)
                        .filter((e) => isContainer(e)),
                });
            });
    };

    updateProcessingJob = ({ customRWPProperties } = {}) => {
        const { rwpGetters, match, getTranslation, applicationData, rwpDropdowns } = this.props;
        const { jobID } = match.params;
        const rwpProperties = customRWPProperties || rwpGetters.getRWPProperties();

        rwpProperties.facility = this.getValues({
            key: RWP_KEYS.FACILITY,
            object: rwpProperties,
            values: rwpDropdowns[RWP_KEYS.FACILITY],
        });

        const equipmentMap = rwpGetters.getOutputEquipmentMap();
        const outputEquipmentMap = rwpProperties[RWP_KEYS.OUTPUT_EQUIPMENT]
            ? Tools.aggregateObjects(
                  rwpProperties[RWP_KEYS.OUTPUT_EQUIPMENT].map((code) => ({
                      [code]: {
                          [EQUIPMENT_KEYS.CODE]: code,
                          ...(!equipmentMap[code]
                              ? {}
                              : // TODO Add all properties here
                                {
                                    [EQUIPMENT_KEYS.CONTENT]: equipmentMap[code][EQUIPMENT_KEYS.CONTENT],
                                    [EQUIPMENT_KEYS.CONTENT_DESCRIPTION]:
                                        equipmentMap[code][EQUIPMENT_KEYS.CONTENT_DESCRIPTION],
                                    [EQUIPMENT_KEYS.GROSS_WEIGHT]: equipmentMap[code][EQUIPMENT_KEYS.GROSS_WEIGHT],
                                    [EQUIPMENT_KEYS.LENGTH]: equipmentMap[code][EQUIPMENT_KEYS.LENGTH],
                                    [EQUIPMENT_KEYS.WIDTH]: equipmentMap[code][EQUIPMENT_KEYS.WIDTH],
                                    [EQUIPMENT_KEYS.HEIGHT]: equipmentMap[code][EQUIPMENT_KEYS.HEIGHT],
                                    [EQUIPMENT_KEYS.LOCATION]: equipmentMap[code][EQUIPMENT_KEYS.LOCATION],
                                    [EQUIPMENT_KEYS.RW_ZONE]: equipmentMap[code][EQUIPMENT_KEYS.RW_ZONE],
                                    [EQUIPMENT_KEYS.RW_SUBZONE]: equipmentMap[code][EQUIPMENT_KEYS.RW_SUBZONE],
                                    [EQUIPMENT_KEYS.RW_FAMILY]: equipmentMap[code][EQUIPMENT_KEYS.RW_FAMILY],
                                    [EQUIPMENT_KEYS.RW_SUB_FAMILY]: equipmentMap[code][EQUIPMENT_KEYS.RW_SUB_FAMILY],
                                    [EQUIPMENT_KEYS.ELIMINATION_PROCESS]:
                                        equipmentMap[code][EQUIPMENT_KEYS.ELIMINATION_PROCESS],
                                    [EQUIPMENT_KEYS.CONTAINER_FULL]: equipmentMap[code][EQUIPMENT_KEYS.CONTAINER_FULL],
                                    [EQUIPMENT_KEYS.MATERIAL]: equipmentMap[code][EQUIPMENT_KEYS.MATERIAL],
                                    [EQUIPMENT_KEYS.DESCRIPTION]: equipmentMap[code][EQUIPMENT_KEYS.DESCRIPTION],
                                    [EQUIPMENT_KEYS.CONTAIN_ALPHA_EMITTER]:
                                        equipmentMap[code][EQUIPMENT_KEYS.CONTAIN_ALPHA_EMITTER],
                                    [EQUIPMENT_KEYS.CONTAINER_INTERNAL_LENGTH]:
                                        equipmentMap[code][EQUIPMENT_KEYS.CONTAINER_INTERNAL_LENGTH],
                                    [EQUIPMENT_KEYS.CONTAINER_INTERNAL_WIDTH]:
                                        equipmentMap[code][EQUIPMENT_KEYS.CONTAINER_INTERNAL_WIDTH],
                                    [EQUIPMENT_KEYS.CONTAINER_INTERNAL_HEIGHT]:
                                        equipmentMap[code][EQUIPMENT_KEYS.CONTAINER_INTERNAL_HEIGHT],
                                    [EQUIPMENT_KEYS.SERIALNO]: equipmentMap[code][EQUIPMENT_KEYS.SERIALNO],
                                    ...(applicationData.isHazardsFeatureActive
                                        ? {
                                              [EQUIPMENT_KEYS.HAZARDS]: equipmentMap[code][EQUIPMENT_KEYS.HAZARDS],
                                              [EQUIPMENT_KEYS.HAZARD_CODES]:
                                                  equipmentMap[code][EQUIPMENT_KEYS.HAZARD_CODES],
                                              [EQUIPMENT_KEYS.HAZARD_COMMENT]:
                                                  equipmentMap[code][EQUIPMENT_KEYS.HAZARD_COMMENT],
                                          }
                                        : {}),
                                }),
                      },
                  }))
              )
            : {};
        return WSRWPProcessing.updateProcessingJob({
            jobID,
            rwpProperties,
            outputEquipmentMap,
            applicationData,
        }).then(() => ({
            successMessage: `${getTranslation(TRANSLATION_KEYS.UPDATE_TREATMENT_SUCCESS_MSG)}: ${jobID}`,
        }));
    };

    updateOutputEquipment = () => {
        const { rwpGetters, getTranslation } = this.props;
        const equipmentMap = rwpGetters.getOutputEquipmentMap();
        return WSRWPProcessing.updateEquipment({
            equipmentMap,
        }).then(() => ({
            successMessage: getTranslation(TRANSLATION_KEYS.UPDATE_EQUIPMENT_SUCCESS_MSG),
        }));
    };

    updateRPMs = ({ isFinish }) => {
        const { rwpGetters, match, getTranslation, constants } = this.props;
        const rpmMap = rwpGetters.getRPMMap();
        const equipmentMap = rwpGetters.getOutputEquipmentMap();
        const equipmentWithoutRPM = Tools.filterObjectFields(equipmentMap, (_, eq) => !rpmMap[eq[EQUIPMENT_KEYS.CODE]]);
        Tools.applyToFields(equipmentWithoutRPM, (obj) => {
            obj[EQUIPMENT_KEYS.FUTURE_ACTION] = obj[EQUIPMENT_KEYS.RW_RECEPTION_DATE]
                ? constants.futureActionOther
                : constants.futureActionWasteReceive;
        });
        const equipmentWithRPM = Tools.filterObjectFields(equipmentMap, (_, eq) => rpmMap[eq[EQUIPMENT_KEYS.CODE]]);
        const hasEquipmentWithoutRPM = Object.keys(equipmentWithoutRPM).length > 0;
        const hasEquipmentWithRPM = Object.keys(equipmentWithRPM).length > 0;
        const { jobID } = match.params;

        return Promise.all([
            // RQF1700826
            hasEquipmentWithoutRPM
                ? WSRWPProcessing.createRPMs({ jobID, outputWaste: equipmentWithoutRPM })
                : Promise.resolve({ body: { data: {} } }),
            hasEquipmentWithRPM
                ? WSRWPProcessing.updateRPMs({ jobID, outputWaste: equipmentWithRPM, rpmMap, isFinish })
                : Promise.resolve({ body: { data: {} } }),
        ]).then(([createResponse, _]) => ({
            rpmMap: {
                ...createResponse.body.data,
            },
            oldRpmMap: rpmMap,
            successMessage: getTranslation(TRANSLATION_KEYS.UPDATE_RPMS_SUCCESS_MESSAGE),
        }));
    };

    associateRPMs = ({ rpmMap, oldRpmMap }) => {
        const { match, storeActions } = this.props;
        const { jobID } = match.params;
        const result = {
            successMessage: 'Success associating RPMs',
        };
        if (Object.keys(rpmMap).length) {
            return WSRWPProcessing.associateRPM({ jobID, rpmMap }).then(() => {
                storeActions.updateRPMMap({ ...rpmMap, ...oldRpmMap });
                return result;
            });
        }
        return Promise.resolve(result);
    };

    handleStartTreament = () => {
        const { history, rwpGetters, storeActions, getTranslation, rwpDropdowns } = this.props;
        const rwpProperties = {
            ...rwpGetters.getRWPProperties(),
            facility: this.getValues({
                key: RWP_KEYS.FACILITY,
                object: rwpGetters.getRWPProperties(),
                values: rwpDropdowns[RWP_KEYS.FACILITY],
            }),
        };

        storeActions.setRWPBlockUI(true);
        WSRWPProcessing.createProcessingJob({ rwpProperties })
            .then((resp) => {
                const jobID = resp.body.data;
                history.push(generateRoute(ROUTES.rwProcessingEdit, { jobID }));
                storeActions.updateRWPProperties({ [RWP_KEYS.JOB_NUMBER]: jobID });
                showSuccess(`${getTranslation(TRANSLATION_KEYS.START_TREATMENT_SUCCESS_MSG)}: ${jobID}`, '', null);
            })
            .catch(handleError)
            .finally(() => storeActions.setRWPBlockUI(false));
    };

    validateRWP = () => {
        const { match } = this.props;
        const { jobID } = match.params;
        return WSRWPProcessing.validateRWP({ jobID }).then(() => ({
            successMessage: 'Validated',
        }));
    };

    prepareFinish = () => {
        const { match } = this.props;
        const { jobID } = match.params;
        return WSRWPProcessing.prepareFinish({ jobID }).then(() => ({
            successMessage: 'Prepared',
        }));
    };

    finishRWP = () => {
        const { match } = this.props;
        const { jobID } = match.params;
        return WSRWPProcessing.finishRWP({ jobID }).then(() => ({
            successMessage: 'Finished',
        }));
    };

    checkAsync = () => {
        const { match } = this.props;
        const { jobID } = match.params;
        return WSRWPProcessing.checkAsync({ jobID }).then(({ body }) => ({
            successMessage: body.data ? 'Cannot finish immediately' : 'Proceeding with the finish process...',
            nextStage: body.data ? STAGE_TYPES.MARK_ASYNC : STAGE_TYPES.VALIDATE,
        }));
    };

    markAsync = () => {
        const { match } = this.props;
        const { jobID } = match.params;
        return WSRWPProcessing.markAsync({ jobID }).then(() => ({
            successMessage: 'This job is marked to finish when the moment is opportune. Check back tomorrow.',
            nextStage: '__NONE__',
            marked: true,
        }));
    };

    executeSequentially = (computedStages) =>
        Object.values(computedStages)
            .sort((a, b) => a.order > b.order)
            .reduce(
                (previousPromise, stage) =>
                    previousPromise.then((previousResultData) =>
                        this.applyStageProcedure({
                            stage,
                            executeParams: stage.executeParams,
                            previousResultData: stage.mapPreviousResult(previousResultData),
                        })
                    ),
                Promise.resolve()
            );

    executeStages = ({ stages, activeDialog, executeAfter }) => {
        const { stageProgressProps } = this.props;
        const { setStages, beginStageProgress, endStageProgress } = stageProgressProps;

        const computedStages = stages.reduce(
            (acc, stage, index) => ({
                ...acc,
                [stage.id]: {
                    mapPreviousResult: (previousResultData) => previousResultData,
                    ...STAGES[stage.id],
                    ...stage,
                    order: index,
                },
            }),
            {}
        );

        setStages(computedStages);
        this.setState({ activeDialog });
        beginStageProgress();
        this.executeSequentially(computedStages)
            .then((result) => {
                executeAfter && executeAfter(result);
            })
            .catch((error) => {
                // Error (STAGE_ERROR) can be used to choose if stage error status should be propagated for upcoming stages for example
                console.error(error);
            })
            .finally(() => {
                endStageProgress();
            });
    };

    applyStageProcedure = ({ stage, executeParams = {}, previousResultData = {} }) => {
        const { id, execute } = stage;
        const { stageProgressProps } = this.props;
        const { updateStage } = stageProgressProps;
        const params = { ...executeParams, ...previousResultData };
        const { nextStage } = previousResultData;
        if (nextStage && nextStage !== id) {
            return Promise.resolve(params);
        }
        updateStage({ id, stageData: { status: STAGE_STATUS_TYPES.PROCESSING } });
        return execute({ ...params, nextStage: null })
            .then((resultData) => {
                const { successMessage, ...other } = resultData;
                updateStage({
                    id,
                    stageData: { status: STAGE_STATUS_TYPES.SUCCESS, successMessage },
                });
                return other;
            })
            .catch((error) => {
                updateStage({
                    id,
                    stageData: {
                        status: STAGE_STATUS_TYPES.ERROR,
                        error: error && error.message === STAGE_ERROR_TYPES.STAGE_ERROR ? undefined : error,
                    },
                });
                throw new Error(STAGE_ERROR_TYPES.STAGE_ERROR);
            });
    };

    handleFinish = () => {
        const { match, rwpGetters, storeActions, getTranslation } = this.props;
        const { inputContainers } = this.state;
        const { jobID } = match.params;
        const equipmentMap = rwpGetters.getOutputEquipmentMap();

        const stages = [
            {
                id: STAGE_TYPES.SAVE_RWP_JOB,
                execute: this.updateProcessingJob,
            },
            {
                id: STAGE_TYPES.SAVE_RPMS,
                executeParams: {
                    isFinish: true,
                },
                execute: this.updateRPMs,
            },
            {
                id: STAGE_TYPES.ASSOCIATE_RPMS,
                execute: this.associateRPMs,
            },
            {
                id: STAGE_TYPES.CHECK_ASYNC,
                execute: this.checkAsync,
            },
            {
                id: STAGE_TYPES.MARK_ASYNC,
                execute: this.markAsync,
            },
            {
                id: STAGE_TYPES.VALIDATE,
                execute: this.validateRWP,
            },
            {
                id: STAGE_TYPES.PREPARE_FINISH,
                execute: this.prepareFinish,
            },
            {
                id: STAGE_TYPES.FINISH,
                execute: this.finishRWP,
            },
        ];

        const stagesIncomplete = stages.filter((stage) =>
            [STAGE_TYPES.VALIDATE, STAGE_TYPES.FINISH].includes(stage.id)
        );

        const rwpProperties = rwpGetters.getRWPProperties();
        this.executeStages({
            stages: rwpProperties[RWP_KEYS.USER_STATUS] === RWP_STATUS.INCOMPLETE ? stagesIncomplete : stages,
            activeDialog: {
                id: DIALOGS.STAGES_PROGRESS,
                title: 'Finish Progress',
            },
            executeAfter: ({ marked }) => {
                const message = marked
                    ? `${getTranslation(TRANSLATION_KEYS.MARKED_SUCCESS_MESSAGE)}: ${jobID}`
                    : `${getTranslation(TRANSLATION_KEYS.FINISH_SUCCESS_MSG)}: ${jobID}`;
                // history.push(
                //     originRoute === undefined ? generateRoute(ROUTES.rwProcessingRead, { jobID }) : originRoute
                // );
                // this.closeDialog();

                const needsToEmptyContainers = inputContainers.filter((s) => !equipmentMap[s.eqCode]).length > 0;
                if (needsToEmptyContainers) {
                    this.setState({ activeDialog: { id: DIALOGS.OPEN_RPMS_FOR_CONTAINERS } });
                }

                showSuccess(message, '', null);
                storeActions.setRWPBlockUI(true);
                this.loadRWPJob(jobID).finally(() => {
                    storeActions.setRWPBlockUI(false);
                });
            },
        });
    };

    handleSave = (currentStep) => {
        const { match, storeActions } = this.props;
        const { jobID } = match.params;

        const stages = [
            {
                id: STAGE_TYPES.SAVE_RWP_JOB,
                execute: this.updateProcessingJob,
            },
            // {
            //     id: STAGE_TYPES.SAVE_OUTPUT_WASTE,
            //     execute: this.updateOutputEquipment,
            // },
            ...(![STEPS.INPUT_WASTE, STEPS.OUTPUT_WASTE].includes(currentStep)
                ? [
                      {
                          id: STAGE_TYPES.SAVE_RPMS,
                          execute: this.updateRPMs,
                      },
                      {
                          id: STAGE_TYPES.ASSOCIATE_RPMS,
                          execute: this.associateRPMs,
                      },
                  ]
                : []),
        ];

        this.executeStages({
            stages,
            activeDialog: {
                id: DIALOGS.STAGES_PROGRESS,
                title: 'Save Progress',
            },
            executeAfter: () => {
                this.loadRWPJob(jobID).finally(() => {
                    storeActions.setRWPBlockUI(false);
                });
            },
        });
    };

    handleCancel = () => {
        const { rwpGetters, storeActions, history, getTranslation } = this.props;
        const rwpProperties = rwpGetters.getRWPProperties();
        const jobID = rwpProperties[RWP_KEYS.JOB_NUMBER];
        storeActions.setRWPBlockUI(true);
        WSRWPProcessing.cancelRWP({ jobID })
            .then(() => {
                showSuccess(`${getTranslation(TRANSLATION_KEYS.CANCEL_SUCCESS_MSG)}: ${jobID}`, '', null);
                history.push(generateRoute(ROUTES.rwProcessingRead, { jobID }));
            })
            .catch(handleError)
            .finally(() => storeActions.setRWPBlockUI(false));
    };

    handleReadyForMeasurement = () => {
        const { storeActions, rwpGetters } = this.props;
        const rwpProperties = rwpGetters.getRWPProperties();
        const customRWPProperties = {
            ...rwpProperties,
            [RWP_KEYS.USER_STATUS]: RWP_STATUS.INPROGRESS,
        };

        const stages = [
            {
                id: STAGE_TYPES.SAVE_RWP_JOB,
                executeParams: {
                    customRWPProperties,
                },
                execute: this.updateProcessingJob,
            },
            // {
            //     id: STAGE_TYPES.SAVE_OUTPUT_WASTE,
            //     execute: this.updateOutputEquipment,
            // },
            {
                id: STAGE_TYPES.SAVE_RPMS,
                execute: this.updateRPMs,
            },
            {
                id: STAGE_TYPES.ASSOCIATE_RPMS,
                execute: this.associateRPMs,
            },
        ];

        this.executeStages({
            stages,
            activeDialog: {
                id: DIALOGS.STAGES_PROGRESS,
                title: 'Ready For Measurement Progress',
            },
            executeAfter: () => {
                storeActions.updateRWPProperties(customRWPProperties);
            },
        });
    };

    closeDialog = () => this.setState({ activeDialog: undefined });

    getValues = ({ key, object, values }) => {
        const { valuesGrid } = this.state;
        return ValuesManager.constrain({ key, object, valuesGrid, values });
    };

    getWasteLocations = () => this.state.wasteLocations;

    onOpenRPMClick = () => {
        const { applicationData, constants, history, rwpGetters } = this.props;
        const { inputContainers } = this.state;
        const equipmentMap = rwpGetters.getOutputEquipmentMap();

        history.push(
            withQueryParams({
                path: ROUTES.rpmRequest2,
                queryParams: {
                    action: constants.futureActionOther,
                    equipment: inputContainers
                        .filter((s) => !equipmentMap[s.eqCode])
                        .map((e) => e.eqCode)
                        .join(','),
                    dep: applicationData.rpsection,
                    requestSource: REQUEST_SOURCES.EMPTY_CONTAINER,
                },
            })
        );
    };

    renderPage() {
        const {
            getTranslation,
            applicationData,
            constants,
            userData,
            screenData,
            translations,
            rwpGetters,
            storeActions,
            customFields,
            customFieldsDef,
            urlParams,
            bufferZone,
            mode,
            rwpDropdowns,
            stageProgressProps,
            history,
            authorizedMenus,
        } = this.props;

        const { stages, isInStageProgress } = stageProgressProps;
        const { loadingData, activeDialog, inputContainers } = this.state;
        const rwpProperties = rwpGetters.getRWPProperties();

        const markedAsync = rwpProperties[RWP_KEYS.MARKED_ASYNC];
        const HeaderInfo = () => (
            <>
                {markedAsync && <WarningText text={getTranslation(TRANSLATION_KEYS.MARKED_SUCCESS_MESSAGE)} />}
                <EquipmentPropertiesInfoLoader
                    rwpGetters={rwpGetters}
                    getTranslation={getTranslation}
                    applicationData={applicationData}
                />
            </>
        );

        const stepProps = {
            getTranslation,
            applicationData,
            constants,
            userData,
            screenData,
            translations,
            rwpGetters,
            storeActions,
            customFields,
            customFieldsDef,
            urlParams,
            bufferZone,
            mode,
            rwpDropdowns,
            isReadOnly: this.state.isReadOnly,
            getValues: this.getValues,
            getWasteLocations: this.getWasteLocations,
            HeaderInfo,
            authorizedMenus,
            setLoading: (bool) => this.setState({ loadingData: bool }),
        };

        return (
            <BlockUi className="trecPageContainer" blocking={loadingData || rwpGetters.isBlockUI()}>
                <Wizard
                    {...this.props}
                    title={getTranslation(TRANSLATION_KEYS.WIZARD_TITLE)}
                    steps={getSteps(stepProps)}
                    stepSequence={getStepSequence(this.props)}
                    firstStepIndex={this.props.firstStepIndex || 0}
                    handleCancel={() => handleCancel(this.props)}
                    controls={(p) => (
                        <RWProcessingControls
                            {...p}
                            getTranslation={getTranslation}
                            handleGoBack={() => handleGoBack(this.props)}
                            mode={mode}
                            onStartTreatementClick={this.handleStartTreament}
                            onSaveClick={this.handleSave}
                            onFinishClick={() => this.setState({ activeDialog: { id: DIALOGS.FINISH_CONFIRMATION } })}
                            onCancelClick={() => this.setState({ activeDialog: { id: DIALOGS.CANCEL_CONFIRMATION } })}
                            onReadyForMeasurementClick={() =>
                                this.setState({ activeDialog: { id: DIALOGS.READY_FOR_MEAS_CONFIRMATION } })
                            }
                            rwpStatus={rwpProperties[RWP_KEYS.USER_STATUS]}
                            onOpenRPMClick={() => {
                                history.push(
                                    withQueryParams({
                                        path: ROUTES.rpmRequest2,
                                        queryParams: {
                                            action: constants.futureActionOther,
                                            equipment: inputContainers.map((e) => e.eqCode).join(','),
                                            dep: applicationData.rpsection,
                                            requestSource: REQUEST_SOURCES.EMPTY_CONTAINER,
                                        },
                                    })
                                );
                            }}
                            markedAsync={rwpProperties[RWP_KEYS.MARKED_ASYNC]}
                        />
                    )}
                    renderStepper={DefaultStepper}
                />
                {activeDialog && activeDialog.id && (
                    <>
                        <RWPConfirmationDialog
                            message={TRANSLATION_KEYS.CANCEL_CONFIRMATION_MESSAGE}
                            open={activeDialog.id === DIALOGS.CANCEL_CONFIRMATION}
                            onClose={this.closeDialog}
                            onConfirm={this.handleCancel}
                            getTranslation={getTranslation}
                        />
                        <RWPConfirmationDialog
                            message={TRANSLATION_KEYS.FINISH_CONFIRMATION_MESSAGE}
                            open={activeDialog.id === DIALOGS.FINISH_CONFIRMATION}
                            onClose={this.closeDialog}
                            onConfirm={this.handleFinish}
                            getTranslation={getTranslation}
                        />
                        <RWPConfirmationDialog
                            message={TRANSLATION_KEYS.READY_FOR_MEAS_CONFIRMATION_MESSAGE}
                            open={activeDialog.id === DIALOGS.READY_FOR_MEAS_CONFIRMATION}
                            onClose={this.closeDialog}
                            onConfirm={this.handleReadyForMeasurement}
                            getTranslation={getTranslation}
                        />
                        <RWPConfirmationDialog
                            message={TRANSLATION_KEYS.OPEN_RPMS_FOR_CONTAINERS}
                            open={activeDialog.id === DIALOGS.OPEN_RPMS_FOR_CONTAINERS}
                            onClose={this.closeDialog}
                            onConfirm={this.onOpenRPMClick}
                            getTranslation={getTranslation}
                        />

                        <Dialog open={activeDialog.id === DIALOGS.STAGES_PROGRESS} maxWidth="md" fullWidth>
                            <DialogTitle>{activeDialog.title}</DialogTitle>
                            <DialogContent>
                                <StageProgress stages={stages} />
                            </DialogContent>
                            <DialogActions>
                                <Button disabled={isInStageProgress} onClick={this.closeDialog} color="primary">
                                    {getTranslation(TREC_GENERAL_KEYS.CLOSE)}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
            </BlockUi>
        );
    }
}

export default withStageProgress(RWProcessing);
