import React from 'react';
import {
    getNumberOfEquipment,
    EQP_FOUND,
    EQP_NOT_PRESENT,
    EQP_PRESENT,
    EQP_TO_CHECK,
    EQP_TOTAL,
} from './BZInventoryUtils';

class BZInventoryStats extends React.Component {
    spanStyle = {
        marginRight: 5,
    };

    render() {
        const { bufferZone, data, translations, actions, getVisibleEquipment } = this.props;

        return (
            <div style={{ padding: 15 }}>
                <div>
                    <strong>{bufferZone}</strong>
                </div>
                <div>
                    <span style={this.spanStyle}>
                        {translations.TOTAL}: {getNumberOfEquipment(data, EQP_TOTAL)}
                    </span>
                    <span style={this.spanStyle}>
                        {translations.PRESENT}: {getNumberOfEquipment(data, EQP_PRESENT)}
                    </span>
                    <span style={this.spanStyle}>
                        {translations.FOUND}: {getNumberOfEquipment(data, EQP_FOUND)}
                    </span>
                    {actions ? (
                        <span style={this.spanStyle}>
                            {translations.NOT_PRESENT}: {getNumberOfEquipment(data, EQP_NOT_PRESENT)}
                        </span>
                    ) : (
                        <span style={this.spanStyle}>
                            {translations.TOCHECK}: {getNumberOfEquipment(data, EQP_TO_CHECK)}
                        </span>
                    )}
                    <span style={this.spanStyle}>
                        {translations.VISIBLE}: {getVisibleEquipment()}
                    </span>
                </div>
            </div>
        );
    }
}

export default BZInventoryStats;
