import { Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import EAMDatePicker from 'eam-components/dist/ui/components/inputs/EAMDatePicker';
import EAMDateTimePicker from 'eam-components/dist/ui/components/inputs/EAMDateTimePicker';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import { ANALYSIS_KEYS, RPAR_REQUEST_KEYS, TABLE_DATA_TYPES, TREC_GENERAL_KEYS } from 'enums/Constants';
import React from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import { showError } from 'tools/TrecNotifications';
import Tools from 'tools/Tools';

const styles = {
    tableContainerSection: {
        width: '100%',
    },
    inputStyle: {
        fontSize: '14px',
    },
};

class RPARAnalysisDetails extends WizardStep {
    state = {};

    formFields = [];

    static getDerivedStateFromProps(props, state) {
        const { rparGetters, analysisType, tableLayout } = props;
        return {
            copyOnFirstLine: props.rparGetters.getAnalysisRequestProperties()[RPAR_REQUEST_KEYS.APPLY_ALL_LINES],
            analysisTypeEquipmentMap:
                state.analysisTypeEquipmentMap ||
                RPARAnalysisDetails.applyDefaultValuesToAnalysisTypeEquipmentMap(
                    tableLayout,
                    rparGetters.getAnalysisTypeEquipments(analysisType),
                    rparGetters.getEquipments(),
                    props
                ),
        };
    }

    static applyDefaultValuesToAnalysisTypeEquipmentMap(tableLayout, analysisTypeEquipmentMap, eqps, props) {
        const { batchValues } = props;

        let batchMap =
            batchValues?.length > 1
                ? Tools.filterObjectFields(
                      Object.values(eqps).reduce(
                          (acc, el) => ({
                              ...acc,
                              [el.objParentCode || el.equipmentCode]: [
                                  ...(acc[el.objParentCode || el.equipmentCode] ?? []),
                                  el.equipmentCode,
                              ],
                          }),
                          {}
                      ),
                      (key, val) => val?.length > 1
                  )
                : {};

        batchMap = Tools.aggregateEntries(
            Object.entries(batchMap).map(([key], idx) => [key, String.fromCharCode(65 + idx)])
        );

        batchMap = Tools.applyToFields(eqps, (v) => batchMap[v.objParentCode || v.equipmentCode]);

        const defaultValue = Object.keys(analysisTypeEquipmentMap).reduce(
            (acc, eqCode) => ({
                ...acc,
                [eqCode]: [
                    ...new Set([...Object.keys(analysisTypeEquipmentMap[eqCode]), ...tableLayout.map((tl) => tl.code)]),
                ].reduce((eqAcc, key) => {
                    const tableColumnElement = tableLayout.find((e) => e.code === key) || {};
                    const value =
                        analysisTypeEquipmentMap[eqCode][key] || analysisTypeEquipmentMap[eqCode][key] === false
                            ? analysisTypeEquipmentMap[eqCode][key]
                            : typeof tableColumnElement.defaultValue === 'function'
                            ? tableColumnElement.defaultValue({ ...analysisTypeEquipmentMap[eqCode], ...eqps[eqCode] })
                            : tableColumnElement.defaultValue;
                    return {
                        ...eqAcc,
                        batch: eqAcc.batch ?? batchMap[eqCode],
                        [key]: value,
                    };
                }, {}),
            }),
            {}
        );
        return defaultValue;
    }

    canContinue = () => this.validate();

    commitChanges = (callback) => callback();

    saveChanges = () => {
        const { rparActions, analysisType } = this.props;
        const { analysisTypeEquipmentMap } = this.state;
        rparActions.setAnalysisProperties(analysisType, analysisTypeEquipmentMap);
        return true;
    };

    handleBackPressed = () => this.handleNextPressed();

    handleStateChange(key, value) {
        this.setState({
            [key]: value,
        });
    }

    validate = () => {
        let isValid = true;
        const { getTranslation } = this.props;
        Object.keys(this.formFields).forEach((key) => {
            if (this.formFields[key] && this.formFields[key].validate && !this.formFields[key].validate()) {
                isValid = false;
                showError(getTranslation(TREC_GENERAL_KEYS.REQUIRED_ERROR_MESSAGE), null, 4000);
            }
        });
        return isValid;
    };

    getTableData = (tableLayout, objectMap, analysisMap) => {
        const { rparGetters, analysisType, lang } = this.props;
        return Object.keys(analysisMap).map((eqCode) => {
            const analysis = analysisMap[eqCode];
            const readonly = !rparGetters.isAnalysisEditable(eqCode, analysisType);
            return {
                ...objectMap[eqCode],
                ...tableLayout.reduce((acc, column) => {
                    switch (column.type) {
                        case TABLE_DATA_TYPES.STATIC:
                            acc[column.code] = analysisMap[column.code];
                            break;
                        case TABLE_DATA_TYPES.SELECT:
                            acc[column.code] = this.getEAMSelect(
                                eqCode,
                                analysis,
                                column.code,
                                column.values({ analysisType }),
                                column.required,
                                readonly
                            );
                            break;
                        case TABLE_DATA_TYPES.DATE:
                            acc[column.code] = this.getEAMDatePicker(
                                eqCode,
                                analysis,
                                column.code,
                                column.required,
                                readonly,
                                lang
                            );
                            break;
                        case TABLE_DATA_TYPES.DATE_TIME:
                            acc[column.code] = this.getEAMDateTimePicker(
                                eqCode,
                                analysis,
                                column.code,
                                column.required,
                                readonly,
                                lang
                            );
                            break;
                        case TABLE_DATA_TYPES.INPUT:
                            acc[column.code] = this.getEAMInput(
                                eqCode,
                                analysis,
                                column.code,
                                column.required,
                                readonly
                            );
                            break;
                        case TABLE_DATA_TYPES.CHECKBOX:
                            acc[column.code] = this.getEAMCheckbox(
                                eqCode,
                                analysis,
                                column.code,
                                column.required,
                                readonly,
                                column.trueValues
                            );
                            break;
                        default:
                            break;
                    }
                    return acc;
                }, {}),
            };
        });
    };

    getEAMCheckbox = (eqCode, analysis, valueKey, required, readonly, trueValues = false) => (
        <EAMCheckbox
            elementInfo={{
                attribute: required ? 'R' : 'O',
                xpath: `${eqCode}.${valueKey}`,
                readonly,
            }}
            formFields={this.formFields}
            valueKey={valueKey}
            value={analysis[valueKey]}
            updateProperty={(key, value) => this.updateState(eqCode, key, value)}
            trueValue={trueValues ? true : 'true'}
            falseValue={trueValues ? false : 'false'}
        />
    );

    getEAMSelect = (eqCode, analysis, valueKey, values, required, readonly) => (
        <EAMSelect
            elementInfo={{
                attribute: required ? 'R' : 'O',
                xpath: `${eqCode}.${valueKey}`,
                readonly,
            }}
            formFields={this.formFields}
            values={values}
            valueKey={valueKey}
            value={analysis[valueKey]}
            style={styles.inputStyle}
            updateProperty={(key, val) => {
                let value = val;
                if (ANALYSIS_KEYS.CLASSIFICATION_REQUESTED === key && val) {
                    value = val[val.length - 1] === 'NO' ? ['NO'] : val.filter((v) => v !== 'NO');
                }

                return this.updateState(eqCode, key, value);
            }}
            multi={[ANALYSIS_KEYS.CLASSIFICATION_REQUESTED].includes(valueKey)}
        />
    );

    getEAMDatePicker = (eqCode, analysis, valueKey, required, readonly, lang) => (
        <EAMDatePicker
            elementInfo={{
                xpath: `${eqCode}.${valueKey}`,
                readonly,
                attribute: required ? 'R' : 'O',
            }}
            formFields={this.formFields}
            disablePast
            // clearable={false}
            keyboard={false}
            localeString={lang}
            valueKey={valueKey}
            style={styles.inputStyle}
            value={analysis[valueKey]}
            updateProperty={(key, value) => this.updateState(eqCode, key, value)}
        />
    );

    getEAMDateTimePicker = (eqCode, analysis, valueKey, required, readonly, lang) => (
        <EAMDateTimePicker
            elementInfo={{
                xpath: `${eqCode}.${valueKey}`,
                readonly,
                attribute: required ? 'R' : 'O',
            }}
            formFields={this.formFields}
            disablePast
            ampm={false}
            localeString={lang}
            // clearable={false}
            keyboard={false}
            valueKey={valueKey}
            style={styles.inputStyle}
            value={analysis[valueKey]}
            updateProperty={(key, value) => this.updateState(eqCode, key, value)}
        />
    );

    getEAMInput = (eqCode, analysis, valueKey, required, readonly) => (
        <EAMInput
            elementInfo={{
                attribute: required ? 'R' : 'O',
                xpath: `${eqCode}.${valueKey}`,
                readonly,
            }}
            formFields={this.formFields}
            valueKey={valueKey}
            style={styles.inputStyle}
            value={analysis[valueKey]}
            updateProperty={(key, value) => this.updateState(eqCode, key, value)}
        />
    );

    updateState = (entryCode, key, value) => {
        const { copyOnFirstLine, analysisTypeEquipmentMap } = this.state;
        const { analysisType, rparGetters } = this.props;
        let updatedAnalysisEquipmentMap;
        if (copyOnFirstLine) {
            updatedAnalysisEquipmentMap = Object.keys(analysisTypeEquipmentMap).reduce(
                (acc, eqCode) => ({
                    ...acc,
                    [eqCode]: {
                        ...analysisTypeEquipmentMap[eqCode],
                        ...(rparGetters.isAnalysisEditable(eqCode, analysisType) ? { [key]: value } : {}),
                    },
                }),
                {}
            );
        } else {
            updatedAnalysisEquipmentMap = {
                ...analysisTypeEquipmentMap,
                [entryCode]: {
                    ...analysisTypeEquipmentMap[entryCode],
                    [key]: value,
                },
            };
        }
        this.setState({
            analysisTypeEquipmentMap: updatedAnalysisEquipmentMap,
        });
    };

    render() {
        const { analysisType, tableLayout, rparGetters, getTranslation } = this.props;
        const { copyOnFirstLine, analysisTypeEquipmentMap } = this.state;

        const updateProperties = (key, value) => {
            this.saveChanges();
            this.props.rparActions.updateRPARequest({
                [key]: value,
            });
        };

        return (
            <Grid container spacing={0}>
                <Grid item md={12} sm={12} xs={12}>
                    <EISPanel
                        heading={getTranslation(RPAR_REQUEST_KEYS.RPANALYSISDETAILS) + getTranslation(analysisType)}
                        alwaysExpanded
                    >
                        <section style={styles.tableContainerSection}>
                            <EAMCheckbox
                                elementInfo={{
                                    label: 'OLabel',
                                    attribute: 'O',
                                    text: getTranslation(RPAR_REQUEST_KEYS.COPYCHECKBOX),
                                }}
                                valueKey={RPAR_REQUEST_KEYS.APPLY_ALL_LINES}
                                value={copyOnFirstLine}
                                updateProperty={updateProperties}
                                trueValue
                                falseValue={false}
                            />
                            <EISTable
                                headers={tableLayout.map((e) => e.header)}
                                propCodes={tableLayout.map((e) => e.code)}
                                data={this.getTableData(
                                    tableLayout,
                                    rparGetters.getEquipments(),
                                    analysisTypeEquipmentMap
                                )}
                                maxMobileSize={600}
                            />
                        </section>
                    </EISPanel>
                </Grid>
            </Grid>
        );
    }
}

export default RPARAnalysisDetails;
