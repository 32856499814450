import { Typography } from '@material-ui/core';
import { reactSelectComponents } from 'amm-tools';

const outerDivStyle = { display: 'flex', flexDirection: 'column' };

const divStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-between',
    width: '100%',
    height: '100%',
};

export const EmployeeOptionInner = ({ data }) => (
    <div style={outerDivStyle}>
        <div style={divStyle}>
            <Typography variant="subtitle1" style={{ flex: 1 }}>
                {data.cernId}
            </Typography>
            <Typography variant="subtitle2">{data.username}</Typography>
        </div>
        <Typography variant="body2" color="textSecondary">
            {data.description}
        </Typography>
        <Typography variant="body1" color="textSecondary">
            {[data.department, data.group, data.section].filter((s) => s).join('-')}
        </Typography>
    </div>
);

const EmployeeOption = (props) => (
    <reactSelectComponents.Option {...props}>
        <EmployeeOptionInner {...props} />
    </reactSelectComponents.Option>
);

export default EmployeeOption;
