import { Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import { EQUIPMENT_KEYS, RPAR_REQUEST_KEYS, RPSM_KEYS, TABLE_DATA_TYPES, TREC_GENERAL_KEYS } from 'enums/Constants';
import React from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import EamComponentsTools from 'tools/EamComponentsTools';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import Tools from 'tools/Tools';
import { showError } from 'tools/TrecNotifications';

const styles = {
    tableContainerSection: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        width: '100%',
        minWidth: '1200px',
    },
    inputStyle: {
        minWidth: '130px',
        fontSize: '13px',
    },
    autocompleteStyle: {
        minWidth: '180px',
        fontSize: '13px',
    },
    datepickerStyle: {
        minWidth: '100px',
        fontSize: '13px',
    },
};

class RPSMSampleDetails extends WizardStep {
    state = {
        copyOnFirstLine: false,
        loading: false,
    };

    originEquipmentTableColumns = [
        {
            code: EQUIPMENT_KEYS.EQUIPMENT_CODE,
            header: RPSM_KEYS.ORIGIN,
            type: TABLE_DATA_TYPES.STATIC,
        },
        {
            code: EQUIPMENT_KEYS.SERIALNO,
            header: RPSM_KEYS.OTHER_ID,
            type: TABLE_DATA_TYPES.STATIC,
        },
        {
            code: EQUIPMENT_KEYS.EQUIPMENT_DESC,
            type: TABLE_DATA_TYPES.INPUT,
            required: true,
        },
        {
            code: EQUIPMENT_KEYS.RESP_TECHNIQUE,
            header: RPSM_KEYS.RESP_TECHNIQUE,
            type: TABLE_DATA_TYPES.AUTOCOMPLETE,
            autocompleteType: WSAutocomplete.autocompleteEmployee,
            required: true,
            descKey: EQUIPMENT_KEYS.RESP_TECHNIQUE_DESC,
        },
        {
            code: EQUIPMENT_KEYS.OBJ_LOCATION,
            type: TABLE_DATA_TYPES.AUTOCOMPLETE,
            autocompleteType: WSAutocomplete.autocompleteLocation,
            required: true,
        },
    ];

    samplesTableColumns = [
        {
            code: RPSM_KEYS.ORIGIN,
            type: TABLE_DATA_TYPES.STATIC,
        },
        {
            code: RPSM_KEYS.ORIGIN_DESCRIPTION,
            type: TABLE_DATA_TYPES.STATIC,
        },
        {
            code: EQUIPMENT_KEYS.EQUIPMENT_CODE,
            header: RPSM_KEYS.SAMPLE_CODE,
            type: TABLE_DATA_TYPES.STATIC,
        },
        {
            code: EQUIPMENT_KEYS.SERIALNO,
            header: RPSM_KEYS.OTHER_ID,
            type: TABLE_DATA_TYPES.INPUT,
        },
        {
            code: EQUIPMENT_KEYS.EQUIPMENT_DESC,
            header: RPSM_KEYS.SAMPLE_DESC,
            required: true,
            type: TABLE_DATA_TYPES.INPUT,
            validate: (value) => value && this.props.applicationData.rpeqpNewDesc.toUpperCase() !== value.toUpperCase(),
        },
        {
            code: EQUIPMENT_KEYS.OBJ_PARENT_CODE, // CONTAINTER CODE
            header: RPSM_KEYS.CONTAINER_CODE,
            type: TABLE_DATA_TYPES.AUTOCOMPLETE, // AUTOCOMPLETE,
            autocompleteType: WSAutocomplete.autocompleteContainer,
            styles: {
                fontSize: '13px',
            },
            props: {
                columnsCodes: ['code'],
                columnsWidth: ['100%'],
                optionStyles: {
                    fontSize: '11px',
                    minWidth: '200px',
                },
            },
        },
        {
            code: RPSM_KEYS.SAMPLING_RESP,
            header: RPSM_KEYS.RESP_TECHNIQUE,
            type: TABLE_DATA_TYPES.AUTOCOMPLETE,
            autocompleteType: WSAutocomplete.autocompleteEmployee,
            required: true,
            descKey: EQUIPMENT_KEYS.RESP_TECHNIQUE_DESC,
        },
        {
            code: EQUIPMENT_KEYS.OBJ_LOCATION,
            type: TABLE_DATA_TYPES.AUTOCOMPLETE,
            required: true,
            autocompleteType: WSAutocomplete.autocompleteLocation,
        },
        {
            code: RPSM_KEYS.SAMPLE_LOCATION,
            type: TABLE_DATA_TYPES.AUTOCOMPLETE,
            autocompleteType: WSAutocomplete.autocompleteLocation,
        },
        {
            code: RPSM_KEYS.SAMPLING_DATE,
            type: TABLE_DATA_TYPES.DATE,
            required: true,
        },
    ];

    formFields = {};

    updateThisState(...args) {
        return Tools.updateThisState.apply(this, args);
    }

    static getDerivedStateFromProps(props, state) {
        const newSamplesMap = [];
        Object.values(props.rpsmGetters.getSamples()).forEach((sample) => {
            if (
                !sample[EQUIPMENT_KEYS.EQUIPMENT_DESC] ||
                sample[EQUIPMENT_KEYS.EQUIPMENT_DESC].toUpperCase() === props.applicationData.rpeqpNewDesc.toUpperCase()
            ) {
                newSamplesMap.push(sample[EQUIPMENT_KEYS.EQUIPMENT_CODE]);
            }
        });
        return {
            origins: props.rpsmGetters.getOrigins(),
            samples: props.rpsmGetters.getSamples(),
            newSamples: newSamplesMap,
            ...state,
        };
    }

    validate = () => Object.values(this.formFields).reduce((acc, ff) => (!ff.validate || ff.validate()) && acc, true);

    canContinue = () => {
        const { getTranslation } = this.props;
        if (!this.validate) {
            showError(getTranslation(TREC_GENERAL_KEYS.REQUIRED_ERROR_MESSAGE), null, 4000);
        }
        return this.validate();
    };

    commitChanges = (callback) => {
        callback();
    };

    saveChanges = () => {
        const { origins, samples } = this.state;
        const { rpsmActions } = this.props;
        rpsmActions.updateOrigins(origins);
        rpsmActions.updateSamples(samples);
        return true;
    };

    handleBackPressed = () => this.handleNextPressed();

    handleStateChange(key, value) {
        this.setState({
            [key]: value,
        });
    }

    propagateChanges = (path, value) => {
        if (path && path.length === 3 && path[0] === 'origins' && path[2] === EQUIPMENT_KEYS.OBJ_LOCATION) {
            const originCode = this.state.origins[path[1]][EQUIPMENT_KEYS.EQUIPMENT_CODE];
            this.updateThisState(
                ['samples'],
                Tools.applyToFields(this.state.samples, (sampl) =>
                    sampl[RPSM_KEYS.ORIGIN] === originCode
                        ? {
                              ...sampl,
                              [RPSM_KEYS.SAMPLE_LOCATION]: value,
                          }
                        : sampl
                ),
                false
            );
        }
    };

    getRequired = (column, equipment) => {
        if (column.code === RPSM_KEYS.SAMPLE_LOCATION && equipment[RPSM_KEYS.ORIGIN]) {
            return true;
        }
        return column.required;
    };

    getTableData = (tableColumns, equipmentMap, stateCode, copyOnFirstLine, lang) =>
        Object.keys(equipmentMap).map((eqCode) => {
            const equipment = equipmentMap[eqCode];
            return tableColumns.reduce((acc, column) => {
                if (
                    column.code === EQUIPMENT_KEYS.EQUIPMENT_DESC &&
                    equipment[column.code] &&
                    equipment[column.code].toUpperCase() === this.props.applicationData.rpeqpNewDesc.toUpperCase()
                ) {
                    equipment[column.code] = '';
                }
                switch (column.type) {
                    case TABLE_DATA_TYPES.STATIC:
                        acc[column.code] = equipment[column.code];
                        break;
                    case TABLE_DATA_TYPES.INPUT:
                        if (
                            !Object.values(this.state.newSamples).includes(equipment[EQUIPMENT_KEYS.EQUIPMENT_CODE]) &&
                            column.code === EQUIPMENT_KEYS.SERIALNO
                        ) {
                            acc[column.code] = equipment[column.code];
                            break;
                        }
                        acc[column.code] = EamComponentsTools.getEAMInput(
                            eqCode,
                            equipment,
                            column.code,
                            (key, value) =>
                                this.updateThisState([stateCode, eqCode, key], value, true, copyOnFirstLine),
                            this.formFields,
                            column.fieldType,
                            column.required,
                            { ...styles.inputStyle, ...column.styles },
                            { key: `column.code${eqCode}`, validate: column.validate, ...column.props }
                        );
                        break;
                    case TABLE_DATA_TYPES.AUTOCOMPLETE:
                        acc[column.code] = EamComponentsTools.getEAMAutocomplete(
                            eqCode,
                            equipment,
                            column.code,
                            (key, value) => {
                                this.updateThisState([stateCode, eqCode, key], value, true, copyOnFirstLine);
                                this.propagateChanges([stateCode, eqCode, key], value);
                            },
                            column.autocompleteType,
                            column.descKey,
                            this.formFields,
                            '',
                            this.getRequired(column, equipment),
                            { ...styles.autocompleteStyle, ...column.styles },
                            column.props
                        );
                        break;
                    case TABLE_DATA_TYPES.DATE:
                        acc[column.code] = EamComponentsTools.getEAMDatePicker(
                            eqCode,
                            equipment,
                            column.code,
                            (key, value) =>
                                this.updateThisState([stateCode, eqCode, key], value, true, copyOnFirstLine),
                            this.formFields,
                            column.defaultValue,
                            column.required,
                            { ...styles.datepickerStyle, ...column.styles },
                            { timestamp: true },
                            lang
                        );
                        break;
                    default:
                        break;
                }
                return acc;
            }, {});
        });

    computeEquipmentOrigins = () => {
        const { rpsmGetters } = this.props;
        const { origins } = this.state;
        return rpsmGetters.getOriginsWithCode().reduce(
            (acc, key) => ({
                ...acc,
                [key]: origins[key],
            }),
            {}
        );
    };

    render() {
        const { getTranslation, lang } = this.props;
        const { copyOnFirstLine, origins, samples } = this.state;
        if (!origins || !samples) return null;

        const originEquipmentTableLayout = this.originEquipmentTableColumns.map((e) => ({
            ...e,
            header: e.header ? getTranslation(e.header) : getTranslation(e.code),
        }));
        const samplesTableLayout = this.samplesTableColumns.map((e) => ({
            ...e,
            header: e.header ? getTranslation(e.header) : getTranslation(e.code),
        }));

        const originsWithCode = this.computeEquipmentOrigins();

        return (
            <Grid container spacing={0}>
                <Grid item md={12} sm={12} xs={12}>
                    {originsWithCode && !Tools.isEmpty(originsWithCode) && (
                        <EISPanel heading={getTranslation(RPSM_KEYS.PARENT_DETAILS)} alwaysExpanded>
                            <section style={styles.tableContainerSection}>
                                <div style={{ flexGrow: '1', width: '100%' }}>
                                    <EISTable
                                        headers={originEquipmentTableLayout.map((e) => e.header)}
                                        propCodes={originEquipmentTableLayout.map((e) => e.code)}
                                        data={this.getTableData(
                                            originEquipmentTableLayout,
                                            originsWithCode,
                                            'origins',
                                            false,
                                            lang
                                        )}
                                        maxMobileSize={600}
                                    />
                                </div>
                            </section>
                        </EISPanel>
                    )}
                    <EISPanel heading={getTranslation(RPSM_KEYS.SAMPLE_DETAILS)} alwaysExpanded>
                        <section style={styles.tableContainerSection}>
                            <div style={{ flexGrow: '1', width: '100%' }}>
                                <EAMCheckbox
                                    elementInfo={{
                                        label: 'OLabel',
                                        attribute: 'O',
                                        text: getTranslation(RPAR_REQUEST_KEYS.COPYCHECKBOX),
                                    }}
                                    valueKey="copyOnFirstLine"
                                    value={copyOnFirstLine}
                                    updateProperty={(key, value) => this.handleStateChange(key, value)}
                                    trueValue
                                    falseValue={false}
                                />
                                <EISTable
                                    headers={samplesTableLayout.map((e) => e.header)}
                                    propCodes={samplesTableLayout.map((e) => e.code)}
                                    data={this.getTableData(samplesTableLayout, samples, 'samples', copyOnFirstLine)}
                                    maxMobileSize={600}
                                />
                            </div>
                        </section>
                    </EISPanel>
                </Grid>
            </Grid>
        );
    }
}

export default RPSMSampleDetails;
