import * as ACTIONS from '../actions/rwpActions';
import Tools from '../tools/Tools';
import { RWP_KEYS, EQUIPMENT_KEYS } from '../ui/pages/rwprocessing/RWPConstants';

export const RWP = 'RWP';

const applyDefaultsToEquipment = (obj) => ({
    ...obj,
    [EQUIPMENT_KEYS.BQ_ALPHA_QUALIFIER]: obj[EQUIPMENT_KEYS.BQ_ALPHA_QUALIFIER] || '=',
    [EQUIPMENT_KEYS.BGO_CONTACT_QUALIFIER]: obj[EQUIPMENT_KEYS.BGO_CONTACT_QUALIFIER] || '=',
    [EQUIPMENT_KEYS.BQ_BETA_QUALIFIER]: obj[EQUIPMENT_KEYS.BQ_BETA_QUALIFIER] || '=',
    [EQUIPMENT_KEYS.AD6_CONTACT_QUALIFIER]: obj[EQUIPMENT_KEYS.AD6_CONTACT_QUALIFIER] || '=',
    [EQUIPMENT_KEYS.CONTENT_DESCRIPTION]: obj[EQUIPMENT_KEYS.CONTENT_DESCRIPTION],
});

const getInitialState = () => ({
    blockUI: false,
    applyAllLines: false,
    rwpProperties: {
        inputWaste: [],
    },
    outputEquipmentMap: {},
    rpmMap: {},
    loadedDevicesSN: false,
});

export default (state = getInitialState(), action) => {
    switch (action.type) {
        case ACTIONS.RESET_RWP:
            return getInitialState();
        case ACTIONS.SET_RWP_BLOCK_UI:
            return {
                ...state,
                blockUI: action.value,
            };
        case ACTIONS.SET_RWP_APPLY_ALL_LINES:
            return {
                ...state,
                applyAllLines: action.value,
            };
        case ACTIONS.UPDATE_RWP_PROPERTIES:
            return {
                ...state,
                rwpProperties: {
                    ...state.rwpProperties,
                    ...action.value,
                },
            };
        case ACTIONS.UPDATE_RWP_OUTPUT_EQUIPMENT:
            return {
                ...state,
                outputEquipmentMap: {
                    ...state.outputEquipmentMap,
                    ...action.eqCodeList
                        .map((eqCode) => ({
                            [eqCode]: {
                                ...state.outputEquipmentMap[eqCode],
                                ...action.object,
                            },
                        }))
                        .reduce((acc, el) => ({ ...acc, ...el }), {}),
                },
            };
        case ACTIONS.UPDATE_RWP_OUTPUT_EQUIPMENT_MAP:
            return {
                ...state,
                outputEquipmentMap: {
                    ...state.outputEquipmentMap,
                    ...Tools.applyToFieldsKV(action.value, (code, eqp) =>
                        !state.outputEquipmentMap[code] ? applyDefaultsToEquipment(eqp) : eqp
                    ),
                },
            };
        case ACTIONS.UPDATE_RWP_RPM_MAP:
            return {
                ...state,
                rpmMap: action.value,
            };
        case ACTIONS.SET_RWP_LOADED_DEVICES_SN:
            return {
                ...state,
                loadedDevicesSN: action.value,
            };
        default:
            return state;
    }
};

const rwpGetters = {
    getRWPProperties: (state) => () => state.rwpProperties,
    getOutputEquipmentMap: (state) => () => state.outputEquipmentMap,
    getOutputEquipmentList: (state) => () => state.rwpProperties[RWP_KEYS.OUTPUT_EQUIPMENT] || [],
    getRPMMap: (state) => () => state.rpmMap,
    isBlockUI: (state) => () => state.blockUI,
    isApplyAllLines: (state) => () => state.applyAllLines,
    getLoadedDevicesSN: (state) => () => state.loadedDevicesSN,
};

export const rwpGettersWithState = (state) => state && Tools.applyToFields(rwpGetters, (func) => func(state[RWP]));
