import initializeApplication from 'actions/actions';
import { logout, updateApplication } from 'actions/applicationActions';
import { setPage, updateLayout } from 'actions/layoutActions';
import { parse as queryParse } from 'query-string';
import { connect } from 'react-redux';
import Tools from 'tools/Tools';

const PAGE = 'SETTINGS';
const TRANSLATION_KEY = `TREC_${PAGE}`;

const mapStateToProps = (state, ownProps) => {
    const { action, application, layout } = state;
    const { applicationData, bufferZone, constants, menuType, screenData, userData, rwpDropdowns, authorizedMenus } =
        application;
    const { lang } = layout;
    const translations = screenData[lang].translations[TRANSLATION_KEY];
    const urlParams = queryParse(ownProps.location.search, { mapStateToProps: 'comma' });

    const { customFields } = screenData[lang];
    const { customFieldsDef } = screenData[lang];

    const wrrDropdownsTranslated = Tools.applyToFields(rwpDropdowns, (list) =>
        list.map((codeDesc) => ({
            ...codeDesc,
            desc:
                Tools.getURLParameterByName('lang') === 'DEV'
                    ? codeDesc.code
                    : codeDesc.descTranslated?.[lang] || codeDesc.desc,
        }))
    );

    const getTranslation = (code) =>
        !code || Tools.getURLParameterByName('lang') === 'DEV'
            ? code
            : translations?.[code.toUpperCase()] ||
              screenData.EN.translations[TRANSLATION_KEY]?.[code.toUpperCase()] ||
              code;

    return {
        screenData: screenData[lang],
        userData,
        applicationData,
        bufferZone,
        menuType,
        constants,
        customFields,
        customFieldsDef,
        lang,
        translations,
        masterPage: [PAGE],
        page: PAGE,
        menu: screenData[lang].menu[menuType],
        action,
        urlParams,
        getTranslation,
        dropdowns: wrrDropdownsTranslated,
        authorizedMenus,
    };
};

const Containerize = (Component) =>
    connect(
        mapStateToProps,
        {
            updateLayout,
            setPage,
            logout,
            updateApplication,
            initializeApplication,
        },
        (stateProps, dispatchProps, ownProps) => ({
            ...ownProps,
            ...stateProps,
            storeActions: dispatchProps,
            setPage: dispatchProps.setPage,
        })
    )(Component);

export default Containerize;
