import React from 'react';
import Grid from '@material-ui/core/Grid';
import EISPanel from 'react-eis-components/dist/ui/components/panel';

// For the search screens
function SearchPanel(props) {
    const { alwaysExpanded, children, title, onPanelChange } = props;
    return (
        <EISPanel heading={title} alwaysExpanded={alwaysExpanded} onPanelChange={onPanelChange}>
            <Grid container spacing={8}>
                {children}
            </Grid>
        </EISPanel>
    );
}

SearchPanel.defaultProps = {
    alwaysExpanded: false,
    title: 'Search Criteria',
    onPanelChange: undefined,
};

export default SearchPanel;
