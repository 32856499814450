import Tools from 'tools/Tools';
import {
    VACUUM_RENTAL_ACCESSORY_KEYS,
    VACUUM_RENTAL_KEYS,
    VACUUM_RENTAL_LINE_KEYS,
    VACUUM_TYPE_KEYS,
} from 'ui/pages/vaccleanerrequest/VacCleanerConstants';
import { VACUUM_ACTION_TYPES } from '../actions/vacCleanerActions';

export const VAC = 'VAC';

const addVacuumCleaner = ({ location, type, vacuum }) => ({
    [VACUUM_RENTAL_LINE_KEYS.LOCATION]: location,
    [VACUUM_RENTAL_LINE_KEYS.VAC_TYPE]: type,
    [VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES]:
        vacuum[VACUUM_TYPE_KEYS.CONDITION_OF_USE] === 'Water'
            ? [
                  {
                      [VACUUM_RENTAL_ACCESSORY_KEYS.PART_CODE]: 'HCPWVCA001',
                      [VACUUM_RENTAL_ACCESSORY_KEYS.QUANTITY]: 1,
                      [VACUUM_RENTAL_ACCESSORY_KEYS.CODE]: Tools.uuidv4(),
                  },
              ]
            : [],
    [VACUUM_RENTAL_LINE_KEYS.CODE]: Tools.uuidv4(),
});

const getInitialState = ({ userData, bufferZone }) => ({
    blockUI: false,
    userData,
    properties: {},
    locations: [],
    [VACUUM_RENTAL_KEYS.HAZARDS]: null,
    [VACUUM_RENTAL_KEYS.VACUUMS]: [],
    [VACUUM_RENTAL_KEYS.CREATOR]: { cernId: userData?.eamAccount?.employeeCode, description: null },
    [VACUUM_RENTAL_KEYS.REQUESTER]: { cernId: userData?.eamAccount?.employeeCode, description: null },
    [VACUUM_RENTAL_KEYS.CREATED_ON]: ((dat) => {
        dat.setMinutes(Math.ceil((dat.getMinutes() + 15) / 15) * 15, 0, 0);
        return dat.getTime();
    })(new Date()),
    [VACUUM_RENTAL_KEYS.START_DATE]: ((dat) => {
        dat.setMinutes(Math.ceil((dat.getMinutes() + 15) / 15) * 15, 0, 0);
        dat.setHours(dat.getHours() + 96);
        return dat;
    })(new Date()),
    [VACUUM_RENTAL_KEYS.END_DATE]: null,
    [VACUUM_RENTAL_KEYS.USAGE_LOCATION]: bufferZone?.locationCode,
});

export default (state = getInitialState({}), action) => {
    switch (action.type) {
        case VACUUM_ACTION_TYPES.RESET_VAC_RENTAL:
            return getInitialState(action.value);
        case VACUUM_ACTION_TYPES.UPDATE_WHOLE_VAC_RENTAL:
            return {
                ...state,
                ...action.value,
                [VACUUM_RENTAL_KEYS.VACUUMS]: action.value?.[VACUUM_RENTAL_KEYS.VACUUMS]
                    .sort((a, b) => a[VACUUM_RENTAL_LINE_KEYS.CODE] - b[VACUUM_RENTAL_LINE_KEYS.CODE])
                    .map((vacLine) => ({
                        ...vacLine,
                        [VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES]: vacLine[
                            VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES
                        ]?.sort((a, b) => a[VACUUM_RENTAL_ACCESSORY_KEYS.CODE] - b[VACUUM_RENTAL_ACCESSORY_KEYS.CODE]),
                    })),
            };
        case VACUUM_ACTION_TYPES.UPDATE_VAC_RENTAL:
            return {
                ...state,
                [action.key]: action.value,
            };
        case VACUUM_ACTION_TYPES.UPDATE_VAC_RENTAL_PARTIAL:
            return {
                ...state,
                [action.path]: {
                    ...state[action.path],
                    ...action.value,
                },
            };
        case VACUUM_ACTION_TYPES.UPDATE_VAC_RENTAL_LINE: {
            return {
                ...state,
                [VACUUM_RENTAL_KEYS.VACUUMS]: state[VACUUM_RENTAL_KEYS.VACUUMS].map((vacLine) =>
                    vacLine[VACUUM_RENTAL_LINE_KEYS.CODE] === action.key || !action.key
                        ? {
                              ...vacLine,
                              ...action.value,
                          }
                        : vacLine
                ),
            };
        }
        case VACUUM_ACTION_TYPES.ADD_VACUUM: {
            const { locations, vacuum } = action.data;

            const newVacuums = locations.reduce(
                (acc, location) => [
                    ...acc,
                    {
                        ...vacuum,
                        ...addVacuumCleaner({
                            vacuum,
                            location: { ...location },
                            type: vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE],
                        }),
                    },
                ],
                []
            );

            return {
                ...state,
                [VACUUM_RENTAL_KEYS.VACUUMS]: [...state[VACUUM_RENTAL_KEYS.VACUUMS], ...newVacuums],
            };
        }
        case VACUUM_ACTION_TYPES.REMOVE_VACUUM: {
            return {
                ...state,
                [VACUUM_RENTAL_KEYS.VACUUMS]: state[VACUUM_RENTAL_KEYS.VACUUMS].filter((vacuum) =>
                    action.data[VACUUM_RENTAL_LINE_KEYS.CODE]
                        ? action.data[VACUUM_RENTAL_LINE_KEYS.CODE] !== vacuum[VACUUM_RENTAL_LINE_KEYS.CODE]
                        : action.data[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE] !== vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE] ||
                          action.data[VACUUM_RENTAL_LINE_KEYS.LOCATION]?.code !==
                              vacuum[VACUUM_RENTAL_LINE_KEYS.LOCATION]?.code
                ),
            };
        }
        case VACUUM_ACTION_TYPES.SET_VACUUM_QUANTITY: {
            const { vacuum, quantity, changeQuantity } = action.data;

            const OTHERS = 'OTHERS';
            const RELEVANT = 'RELEVANT';

            const lists = Tools.groupListByFunction(state[VACUUM_RENTAL_KEYS.VACUUMS], (v) =>
                v[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE] === vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE] &&
                v[VACUUM_RENTAL_LINE_KEYS.LOCATION]?.code === vacuum[VACUUM_RENTAL_LINE_KEYS.LOCATION]?.code
                    ? RELEVANT
                    : OTHERS
            );

            const finalQuantity = quantity ?? lists.RELEVANT?.length + changeQuantity;

            const order = state[VACUUM_RENTAL_KEYS.VACUUMS]
                .map((s) => s[VACUUM_RENTAL_KEYS.CODE])
                .reduce((acc, el, i) => ({ ...acc, [el]: i + 1 }), {});

            const vacuums = [
                ...(lists.OTHERS || []),
                ...lists.RELEVANT.filter((_, i) => i < finalQuantity),
                ...Array(Math.max(finalQuantity - lists.RELEVANT?.length, 0))
                    .fill(() => 1)
                    .map(() =>
                        addVacuumCleaner({
                            vacuum,
                            location: { ...vacuum[VACUUM_RENTAL_LINE_KEYS.LOCATION] },
                            type: vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE],
                        })
                    ),
            ].sort(
                (a, b) =>
                    (order[a[VACUUM_RENTAL_LINE_KEYS.CODE]] || 9999999) -
                    (order[b[VACUUM_RENTAL_LINE_KEYS.CODE]] || 9999999)
            );

            state[VACUUM_RENTAL_KEYS.VACUUMS].map((s) => s[VACUUM_RENTAL_KEYS.CODE]);

            return {
                ...state,
                [VACUUM_RENTAL_KEYS.VACUUMS]: vacuums,
            };
        }
        case VACUUM_ACTION_TYPES.ADD_ACCESSORY: {
            const { vacuumsToAttachTo, accessory } = action.data;

            const vacuumStateCopy = state[VACUUM_RENTAL_KEYS.VACUUMS].map((vacuum) =>
                vacuumsToAttachTo
                    .map((vac) => vac[VACUUM_RENTAL_LINE_KEYS.CODE])
                    .includes(vacuum[VACUUM_RENTAL_LINE_KEYS.CODE])
                    ? {
                          ...vacuum,
                          [VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES]: [
                              ...(vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES] || []),
                              {
                                  ...accessory,
                                  [VACUUM_RENTAL_ACCESSORY_KEYS.QUANTITY]: 1,
                                  [VACUUM_RENTAL_ACCESSORY_KEYS.CODE]: Tools.uuidv4(),
                              },
                          ],
                      }
                    : vacuum
            );
            return {
                ...state,
                [VACUUM_RENTAL_KEYS.VACUUMS]: vacuumStateCopy,
            };
        }
        case VACUUM_ACTION_TYPES.REMOVE_ACCESSORY: {
            const { vacuumToRemoveFrom, accessoryCode } = action.data;

            const vacuumStateCopy = state[VACUUM_RENTAL_KEYS.VACUUMS].map((vacuum) =>
                vacuumToRemoveFrom[VACUUM_RENTAL_LINE_KEYS.CODE] === vacuum[VACUUM_RENTAL_LINE_KEYS.CODE]
                    ? {
                          ...vacuum,
                          [VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES]: [
                              ...(vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES] || []).filter(
                                  (acc) => accessoryCode !== acc[VACUUM_RENTAL_ACCESSORY_KEYS.PART_CODE]
                              ),
                          ],
                      }
                    : vacuum
            );
            return {
                ...state,
                [VACUUM_RENTAL_KEYS.VACUUMS]: vacuumStateCopy,
            };
        }
        case VACUUM_ACTION_TYPES.SET_ACCESSORY_QUANTITY: {
            const { vacuum, accessoryCode, requestedQuantity } = action.data;

            const vacuumStateCopy = state[VACUUM_RENTAL_KEYS.VACUUMS].map((vac) =>
                vacuum[VACUUM_RENTAL_LINE_KEYS.CODE] === vac[VACUUM_RENTAL_LINE_KEYS.CODE]
                    ? {
                          ...vacuum,
                          [VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES]: [
                              ...(vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_CLEANER_ACCESSORIES] || []).map((acc) =>
                                  accessoryCode === acc[VACUUM_RENTAL_ACCESSORY_KEYS.PART_CODE]
                                      ? {
                                            ...acc,
                                            [VACUUM_RENTAL_ACCESSORY_KEYS.QUANTITY]: requestedQuantity,
                                        }
                                      : acc
                              ),
                          ],
                      }
                    : vac
            );
            return {
                ...state,
                [VACUUM_RENTAL_KEYS.VACUUMS]: vacuumStateCopy,
            };
        }
        case VACUUM_ACTION_TYPES.RESET_CART: {
            return getInitialState();
        }
        case VACUUM_ACTION_TYPES.ASSIGN_VACUUM: {
            const { assetCode, vacuumType } = action.data;

            const rentalLineCode = state[VACUUM_RENTAL_KEYS.VACUUMS].find(
                (vacuum) =>
                    !vacuum[VACUUM_RENTAL_LINE_KEYS.ASSET_CODE] &&
                    vacuumType === vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE]
            )?.[VACUUM_RENTAL_KEYS.CODE];

            const vacuumStateCopy = state[VACUUM_RENTAL_KEYS.VACUUMS].map((vacuum) =>
                rentalLineCode === vacuum[VACUUM_RENTAL_LINE_KEYS.CODE]
                    ? {
                          ...vacuum,
                          [VACUUM_RENTAL_LINE_KEYS.ASSET_CODE]: assetCode,
                          [VACUUM_RENTAL_LINE_KEYS.NOTIFICATION_ENABLED]: true,
                      }
                    : vacuum
            );
            return {
                ...state,
                [VACUUM_RENTAL_KEYS.VACUUMS]: vacuumStateCopy,
            };
        }
        case VACUUM_ACTION_TYPES.ASSIGN_VACUUM_RESERVATION: {
            const { assetCode, reservationCode } = action.data;

            const vacuumStateCopy = state[VACUUM_RENTAL_KEYS.VACUUMS].map((vacuum) =>
                reservationCode === vacuum[VACUUM_RENTAL_LINE_KEYS.CODE]
                    ? {
                          ...vacuum,
                          [VACUUM_RENTAL_LINE_KEYS.ASSET_CODE]: assetCode,
                      }
                    : vacuum
            );
            return {
                ...state,
                [VACUUM_RENTAL_KEYS.VACUUMS]: vacuumStateCopy,
            };
        }
        case VACUUM_ACTION_TYPES.UNASSIGN_VACUUM: {
            const { rentalLineCode } = action.data;
            const vacuumStateCopy = state[VACUUM_RENTAL_KEYS.VACUUMS].map((vacuum) =>
                rentalLineCode === vacuum[VACUUM_RENTAL_LINE_KEYS.CODE]
                    ? {
                          ...vacuum,
                          [VACUUM_RENTAL_LINE_KEYS.ASSET_CODE]: '',
                      }
                    : vacuum
            );
            return {
                ...state,
                [VACUUM_RENTAL_KEYS.VACUUMS]: vacuumStateCopy,
            };
        }
        default:
            return state;
    }
};

const getProperties = (state) => () => state;

const isBlockUI = (state) => () => state.blockUI;

const vacCleanerGetters = {
    getProperties,
    isBlockUI,
};

export const vacCleanerGettersWithState = (state) =>
    state && Tools.applyToFields(vacCleanerGetters, (func) => func(state[VAC]));
