import React from 'react';
import Tools from '../../../tools/Tools';
import { INFORMATION_SOURCE } from './Constants';
import EquipmentTreeLoader from './EquipmentTreeLoader';

const EquipmentTreePage = (props) => {
    const { match } = props;
    const equipmentCode = match.params.equipmentID;
    const width = Tools.getURLParameterByName('width');
    const height = Tools.getURLParameterByName('height');
    const src = Tools.getURLParameterByName('src') || INFORMATION_SOURCE.EQUIPMENT_STRUCTURE;
    return <EquipmentTreeLoader equipmentCode={equipmentCode} width={width} height={height} src={src} />;
};

export default EquipmentTreePage;
