import React, { Component } from 'react';
import List from '@material-ui/core/List';
import { WorkorderIcon } from 'eam-components/dist/ui/components/icons';

class Workorders extends Component {
    mainDivStyle = {
        display: 'flex',
        height: '100%',
        width: 250,
        borderRight: '1px solid #eeeeee',
        flexDirection: 'column',
    };

    headerStyle = {
        backgroundColor: '#fafafa',
        width: '100%',
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 500,
    };

    spanStyle = {
        display: 'block',
        fontSize: 15,
        marginTop: 5,
    };

    workOrderStyle = {
        cursor: 'pointer',
        marginBottom: 10,
    };

    getWorkOrderHeadingStyle(measurement) {
        const { currentMeasurement } = this.props;
        return {
            display: 'flex',
            fontWeight: measurement.jobNumber === currentMeasurement ? '500' : 'unset',
        };
    }

    getIconStyle(measurement) {
        const { currentMeasurement } = this.props;
        return {
            color: measurement.jobNumber === currentMeasurement ? '#1f96f3' : '#e5e5e5',
        };
    }

    generateWorkOrders() {
        const { measurements, setCurrentMeasurement } = this.props;
        return measurements.map((meas) => (
            <div
                key={meas.jobNumber}
                onClick={() => setCurrentMeasurement(meas.jobNumber)}
                onKeyDown={() => setCurrentMeasurement(meas.jobNumber)}
                style={this.workOrderStyle}
            >
                <div style={this.getWorkOrderHeadingStyle(meas)}>
                    <WorkorderIcon style={this.getIconStyle(meas)} />
                    <div style={{ ...this.spanStyle, display: 'inline', paddingLeft: 5 }}>{meas.jobNumber}</div>
                </div>
                <span style={this.spanStyle}>
                    Equipment: {meas.equipment[0].equipmentCode} - {meas.equipment[0].equipmentDesc}
                </span>
                <span style={this.spanStyle}>Created: {meas.created}</span>
            </div>
        ));
    }

    generateHeader = () => {
        const { translations, measurements } = this.props;
        if (measurements.length === 1) {
            return <div style={this.headerStyle}>{translations.RPMEASUREMENT}</div>;
        } /* Multiple measurements */
        return <div style={{ ...this.headerStyle, color: '#ff1100' }}>{translations.BATCHMEASUREMENTS}</div>;
    };

    render() {
        return (
            <div style={this.mainDivStyle}>
                {this.generateHeader()}
                <List style={{ width: '100%' }}>{this.generateWorkOrders()}</List>
            </div>
        );
    }
}

export default Workorders;
