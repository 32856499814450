import { Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EAMFormLabel from 'eam-components/dist/ui/components/inputs/EAMFormLabel';
import {
    EQUIPMENT_KEYS,
    RPAR_PERFORM_KEYS,
    RPAR_REQUEST_DTO_KEYLIST,
    RPAR_REQUEST_KEYS,
    RP_MEASUREMENT_DTO_KEYLIST,
    RP_MEASUREMENT_KEYS,
} from 'enums/Constants';
import React from 'react';
import BlockUi from 'react-block-ui';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import { Link } from 'react-router-dom';
import EamComponentsTools from 'tools/EamComponentsTools';
import { getInternalLink, ROUTES, generateRoute } from 'tools/Routes';
import Tools from 'tools/Tools';
import TRECButton from '../../../components/buttons/TRECButton';

const styles = {
    inputStyle: {
        width: '100%',
        maxWidth: '100%',
    },
    requestDetails: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: '1',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-evenly',
        width: '100%',
    },
};

class RPARSummary extends WizardStep {
    state = {
        loading: true,
    };

    canContinue = () => true;

    UNSAFE_componentWillMount() {
        this.init(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.init(nextProps);
    }

    init = (props) => {
        this.setState({
            equipmentMap: props.rparGetters.getEquipments(),
            analysisTypesWithEquipmentsMap: props.rparGetters.getAnalysisTypesWithEquipments(),
            analysisRequestPropertiesMap: props.rparGetters.getAnalysisRequestProperties(),
            loading: false,
        });
    };

    saveChanges = () => true;

    commitChanges = (callback) => {
        callback();
    };

    getRequestStatusComponent = ({ getTranslation, analysisRequestPropertiesMap, values, update }) => (
        // <div key={'requeststatus'} style={{...styles.requestDetails, flex: '1'}}>
        <EAMSelect
            elementInfo={{
                text: getTranslation(RPAR_REQUEST_KEYS.STATUS),
                xpath: RPAR_REQUEST_KEYS.STATUS,
            }}
            formFields={this.formFields}
            value={analysisRequestPropertiesMap[RPAR_REQUEST_KEYS.STATUS]}
            placeholder={values.find((el) => el.code === analysisRequestPropertiesMap[RPAR_REQUEST_KEYS.STATUS]).desc}
            valueKey={RPAR_REQUEST_KEYS.STATUS}
            values={values.filter(
                (el) => analysisRequestPropertiesMap[RPAR_REQUEST_KEYS.STATUS] === el.code || el.code !== 'TX'
            )}
            updateProperty={update}
            clearable={false}
        />
    );
    // </div>

    setSchedulingDatesInStore = () => {
        const { rparActions } = this.props;
        const { analysisRequestPropertiesMap } = this.state;
        const scheduledEnd = analysisRequestPropertiesMap[RPAR_REQUEST_KEYS.SCHEDULED_END_DATE];
        const scheduledStart = analysisRequestPropertiesMap[RPAR_REQUEST_KEYS.SCHEDULED_START_DATE];

        if (scheduledStart != null && scheduledEnd != null) {
            rparActions.updateRPARequest({
                [RPAR_PERFORM_KEYS.SCHEDULED_START_DATE]: scheduledStart,
                [RPAR_PERFORM_KEYS.SCHEDULED_END_DATE]: scheduledEnd,
            });
        }
    };

    updateThisState = Tools.updateThisState;

    _renderRequestPropertiesSummary = () => {
        const { getTranslation, translatedScreenData, rparActions, lang } = this.props;
        const { analysisRequestPropertiesMap } = this.state;

        return Object.entries(this.state.analysisRequestPropertiesMap)
            .filter(([propertyKey, _]) => RPAR_REQUEST_DTO_KEYLIST.includes(propertyKey))
            .map(([propertyKey, propertyValue]) => {
                let elementInfo = {};
                let value = '';
                switch (propertyKey) {
                    case RPAR_REQUEST_KEYS.WORKORDER:
                        elementInfo = { readonly: true, text: getTranslation('RPANALYSISNUMBER') };
                        value = propertyValue;
                        break;
                    case RPAR_REQUEST_KEYS.PURPOSE:
                        elementInfo = { readonly: true, text: getTranslation(propertyKey) };
                        value = getTranslation(propertyValue);
                        break;
                    case RPAR_REQUEST_KEYS.STATUS:
                        if (!propertyValue) return null;
                        // eslint-disable-next-line no-case-declarations
                        const status = translatedScreenData.lists.jobStatuses.find((e) => e.code === propertyValue);
                        if (!status) return null;
                        return this.getRequestStatusComponent({
                            getTranslation,
                            analysisRequestPropertiesMap,
                            values: translatedScreenData.lists.jobStatuses,
                            update: (key, val) => rparActions.updateRPARequest({ [key]: val }),
                        });
                    // elementInfo = { readonly: true, text: (getTranslation(propertyKey))}
                    // value = status.desc
                    case RPAR_PERFORM_KEYS.SCHEDULED_START_DATE:
                    case RPAR_PERFORM_KEYS.SCHEDULED_END_DATE:
                        return this.state.analysisRequestPropertiesMap[RPAR_REQUEST_KEYS.WORKORDER]
                            ? EamComponentsTools.getEAMDatePicker(
                                  propertyKey,
                                  analysisRequestPropertiesMap,
                                  propertyKey,
                                  (key, val) => {
                                      this.updateThisState(
                                          ['analysisRequestPropertiesMap', key],
                                          val,
                                          true,
                                          false,
                                          this.setSchedulingDatesInStore
                                      );
                                  },
                                  this.formFields,
                                  '',
                                  false,
                                  null,
                                  {
                                      text: getTranslation(propertyKey),
                                      timestamp: true,
                                      clearable: false,
                                  },
                                  lang
                              )
                            : '';
                    case RPAR_REQUEST_KEYS.PARENT_WO:
                        return propertyValue ? (
                            <div key="parent" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                                <EAMFormLabel label={getTranslation(RPAR_REQUEST_KEYS.PARENT_WO)} />
                                {getInternalLink({
                                    route: generateRoute(ROUTES.waterRelease, { waterReleaseNumber: propertyValue }),
                                    text: propertyValue,
                                })}
                            </div>
                        ) : null;
                    default:
                        elementInfo = { readonly: true, text: getTranslation(propertyKey) };
                        value = propertyValue;
                        break;
                }
                return (
                    <div key={propertyKey} style={styles.requestDetails}>
                        <div style={{ flex: '1' }}>
                            <EAMInput
                                elementInfo={elementInfo}
                                value={value}
                                valueKey={propertyKey}
                                style={styles.inputStyle}
                            />
                        </div>
                    </div>
                );
            });
    };

    _renderEquipmentPerAnalysisSummary = () => {
        const { analysisTypesWithEquipmentsMap } = this.state;
        const { rparGetters, getTranslation } = this.props;

        return Object.keys(analysisTypesWithEquipmentsMap).map((analysisType) => {
            const analysis = analysisTypesWithEquipmentsMap[analysisType];
            return (
                <div key={analysisType}>
                    {getTranslation(analysis)}:
                    {Object.keys(rparGetters.getAnalysisTypeEquipments(analysis)).map((eqCode) => (
                        <div key={eqCode}>
                            <EAMInput elementInfo={{ readonly: true }} value={eqCode} valueKey={eqCode} />
                        </div>
                    ))}
                </div>
            );
        });
    };

    _renderRPMeasurementProperties = () => {
        const rpmeasure = Tools.applyToFields(
            Tools.filterObjectFields(
                this.state.equipmentMap,
                (key, value) => value[RP_MEASUREMENT_KEYS.RPA_MEASUREMENT]
            ),
            (eq) => Tools.filterObjectFieldsFromList(eq, RP_MEASUREMENT_DTO_KEYLIST)
        );

        if (Object.keys(rpmeasure).length === 0 && rpmeasure.constructor === Object) {
            return <div>No RP Measurement</div>;
        }
        return Object.keys(rpmeasure).map((eqCode) => (
            <div key={eqCode} style={{ flex: '1' }}>
                <span>{eqCode}: </span>
                <Link to={`/rpmperform/?wo=${rpmeasure[eqCode].rpMeasurement}`}>{rpmeasure[eqCode].rpMeasurement}</Link>
            </div>
        ));
    };

    generateEquipmentTableData = (tableColumns, equipmentMap) => {
        const { screenData } = this.props;
        return Object.keys(equipmentMap).map((eqCode) => {
            const equipment = equipmentMap[eqCode];
            return tableColumns.reduce((acc, column) => {
                switch (column.code) {
                    case EQUIPMENT_KEYS.OBJ_FAMILY:
                        acc[column.code] = (
                            screenData.lists.objFamily.find((element) => element.code === equipment[column.code]) || {}
                        ).desc;
                        break;
                    case EQUIPMENT_KEYS.MATERIAL:
                        acc[column.code] = (
                            screenData.lists.objMainMaterial.find(
                                (element) => element.code === equipment[column.code]
                            ) || {}
                        ).desc;
                        break;
                    default:
                        acc[column.code] = equipment[column.code];
                        break;
                }
                return acc;
            }, {});
        });
    };

    _renderEquipmentsDetails = () => {
        const { getTranslation } = this.props;

        const equipmentDetailsTableColumns = [
            EQUIPMENT_KEYS.EQUIPMENT_CODE,
            EQUIPMENT_KEYS.SERIALNO,
            EQUIPMENT_KEYS.EQUIPMENT_DESC,
            EQUIPMENT_KEYS.RESP_TECHNIQUE_DESC,
            EQUIPMENT_KEYS.OBJ_LOCATION,
            EQUIPMENT_KEYS.LAST_LOCATION_CODE,
        ];

        const equipmentPhysicalPropsTableColumns = [
            EQUIPMENT_KEYS.EQUIPMENT_CODE,
            EQUIPMENT_KEYS.LENGTH,
            EQUIPMENT_KEYS.WIDTH,
            EQUIPMENT_KEYS.HEIGHT,
            EQUIPMENT_KEYS.WEIGHT,
            EQUIPMENT_KEYS.EQUIPMENT_VALUE,
            EQUIPMENT_KEYS.OBJ_FAMILY,
            EQUIPMENT_KEYS.MATERIAL,
        ];

        const { equipmentMap } = this.state;
        const eqDetailsTableLayout = equipmentDetailsTableColumns.map((e) => ({
            code: e,
            header: getTranslation(e),
        }));
        const eqPropertiesTableLayout = equipmentPhysicalPropsTableColumns.map((e) => ({
            code: e,
            header: getTranslation(e),
        }));

        const eqDetailsTableHeaders = eqDetailsTableLayout.map((e) => getTranslation(e.header));
        const eqDetailsTableCodes = eqDetailsTableLayout.map((e) => e.code);
        const eqDetailsTableData = this.generateEquipmentTableData(eqDetailsTableLayout, equipmentMap);

        const eqPropertiesTableHeaders = eqPropertiesTableLayout.map((e) => getTranslation(e.header));
        const eqPropertiesTableCodes = eqPropertiesTableLayout.map((e) => e.code);
        const eqPropertiesTableData = this.generateEquipmentTableData(eqPropertiesTableLayout, equipmentMap);

        return (
            <div style={{ width: '100%', height: '100%' }}>
                <EISPanel heading={getTranslation(RPAR_REQUEST_KEYS.ANALYSIS_SAMPLE)}>
                    <EISTable
                        data={eqDetailsTableData}
                        headers={eqDetailsTableHeaders}
                        propCodes={eqDetailsTableCodes}
                        maxMobileSize={600}
                    />
                </EISPanel>

                <EISPanel heading={getTranslation(RPAR_REQUEST_KEYS.SAMPLEREVIEW)}>
                    <EISTable
                        data={eqPropertiesTableData}
                        headers={eqPropertiesTableHeaders}
                        propCodes={eqPropertiesTableCodes}
                        maxMobileSize={600}
                    />
                </EISPanel>
            </div>
        );
    };

    canRequestEDH = () => {
        const { equipmentMap } = this.state;
        return (
            Object.values(equipmentMap)
                .filter((s) => s[EQUIPMENT_KEYS.RPA_MEASUREMENT])
                .filter((s) => s[EQUIPMENT_KEYS.TYPE_OF_TRANSPORT] !== 'None').length > 0
        );
    };

    render() {
        const { getTranslation } = this.props;
        const { loading, equipmentMap } = this.state;
        const rpMeasurementCodes = [];
        Object.values(equipmentMap).forEach((equipment) => {
            rpMeasurementCodes.push(equipment[EQUIPMENT_KEYS.RPA_MEASUREMENT]);
        });

        return (
            <div>
                {loading ? (
                    <BlockUi tag="div" blocking>
                        <div>Please wait...</div>
                    </BlockUi>
                ) : (
                    <Grid container spacing={8}>
                        <Grid item md={6} sm={12} xs={12}>
                            <Grid item md={12} sm={12} xs={12}>
                                <EISPanel heading={getTranslation(RPAR_REQUEST_KEYS.REQUESTDETAILS)}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        {this._renderRequestPropertiesSummary()}
                                        <div>
                                            {this.state.analysisRequestPropertiesMap[RPAR_REQUEST_KEYS.WORKORDER] ? (
                                                <div>
                                                    <Link
                                                        to={`/rpanalysis?request=${
                                                            this.state.analysisRequestPropertiesMap[
                                                                RPAR_REQUEST_KEYS.WORKORDER
                                                            ]
                                                        }`}
                                                    >
                                                        <TRECButton>
                                                            {getTranslation(RPAR_REQUEST_KEYS.REQUESTJOBSLINK)}
                                                            {
                                                                this.state.analysisRequestPropertiesMap[
                                                                    RPAR_REQUEST_KEYS.WORKORDER
                                                                ]
                                                            }
                                                        </TRECButton>
                                                    </Link>
                                                    {this.canRequestEDH() && (
                                                        <Link
                                                            to={`/edhdocmng?job_number=${rpMeasurementCodes}`}
                                                            target="_blank"
                                                        >
                                                            <TRECButton>EDH Document Generation</TRECButton>
                                                        </Link>
                                                    )}
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </EISPanel>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                                {/* No content */}
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                                <EISPanel heading={getTranslation(RPAR_REQUEST_KEYS.RPMEASUREMENT)}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {this._renderRPMeasurementProperties()}
                                    </div>
                                </EISPanel>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                                {/* No content */}
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                                <EISPanel heading={getTranslation(RPAR_REQUEST_KEYS.EQUIPMENTFORANALYSIS)}>
                                    {this._renderEquipmentPerAnalysisSummary()}
                                </EISPanel>
                            </Grid>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            {this._renderEquipmentsDetails()}
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    }
}

export default RPARSummary;
