import React from 'react';
import EAMInput from 'eam-components/dist/ui/components/inputs/EAMInput';
import EAMAutocomplete from 'eam-components/dist/ui/components/inputs/EAMAutocomplete';
import EAMSelect from 'eam-components/dist/ui/components/inputs/EAMSelect';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import EAMDatePicker from 'eam-components/dist/ui/components/inputs/EAMDatePicker';
import EAMDateTimePicker from 'eam-components/dist/ui/components/inputs/EAMDateTimePicker';

const getEAMInput = (
    propertyKey,
    property,
    valueKey,
    updateFunction,
    formFields,
    defaultValue = '',
    required = false,
    fieldType = 'text',
    style = {},
    additionalProps = {},
    inputProps = {}
) => (
    <EAMInput
        elementInfo={{
            attribute: required ? 'R' : 'O',
            fieldType,
            xpath: `input${propertyKey}${valueKey}`,
        }}
        style={style}
        formFields={formFields}
        valueKey={valueKey}
        key={valueKey}
        value={property[valueKey] || defaultValue}
        updateProperty={(key, value) => {
            updateFunction(key, value);
        }}
        inputProps={inputProps}
        {...additionalProps}
    />
);

const getEAMSelect = (
    propertyKey,
    property,
    valueKey,
    updateFunction,
    values,
    formFields,
    defaultValue = '',
    required = false,
    style = {},
    additionalProps = {}
) => (
    <EAMSelect
        elementInfo={{
            attribute: required ? 'R' : 'O',
            xpath: `select${propertyKey}${valueKey}`,
        }}
        values={values}
        style={style}
        formFields={formFields}
        valueKey={valueKey}
        value={property[valueKey] || defaultValue}
        updateProperty={(key, value) => updateFunction(key, value)}
        {...additionalProps}
    />
);

const getEAMAutocomplete = (
    propertyKey,
    property,
    valueKey,
    updateFunction,
    autocompleteType,
    descKey,
    formFields,
    defaultValue = '',
    required = false,
    style = {},
    additionalProps = {}
) => (
    <EAMAutocomplete
        elementInfo={{
            attribute: required ? 'R' : 'O',
            xpath: `autocomplete${propertyKey}${valueKey}`,
        }}
        value={(descKey ? property[descKey] : property[valueKey]) || defaultValue}
        style={style}
        valueKey={valueKey}
        valueDesc=""
        descKey={descKey}
        formFields={formFields}
        updateProperty={(key, value) => updateFunction(key, value)}
        loadOptions={autocompleteType}
        {...additionalProps}
    />
);

const getEAMCheckbox = (
    propertyKey,
    property,
    valueKey,
    updateFunction,
    formFields,
    defaultValue = '',
    required = false,
    style = {},
    additionalProps = {}
) => (
    <EAMCheckbox
        elementInfo={{
            attribute: required ? 'R' : 'O',
            xpath: `checkbox${propertyKey}${valueKey}`,
        }}
        formFields={formFields}
        valueKey={valueKey}
        style={style}
        value={property[valueKey] || defaultValue}
        updateProperty={(key, value) => updateFunction(key, value)}
        trueValue="true"
        falseValue={false}
        {...additionalProps}
    />
);

const getEAMDatePicker = (
    propertyKey,
    property,
    valueKey,
    updateFunction,
    formFields,
    defaultValue = '',
    required = false,
    style = {},
    additionalProps = {},
    lang
) => (
    <EAMDatePicker
        elementInfo={{
            attribute: required ? 'R' : 'O',
            xpath: `datepicker${propertyKey}${valueKey}`,
            text: additionalProps && additionalProps.text,
        }}
        key={valueKey}
        keyboard={false}
        formFields={formFields}
        valueKey={valueKey}
        localeString={lang}
        style={style}
        value={property[valueKey] || defaultValue}
        updateProperty={(key, value) => updateFunction(key, value)}
        {...additionalProps}
    />
);

const getEAMDateTimePicker = (
    propertyKey,
    property,
    valueKey,
    updateFunction,
    formFields,
    defaultValue = '',
    required = false,
    style = {},
    additionalProps = {},
    lang
) => (
    <EAMDateTimePicker
        elementInfo={{
            attribute: required ? 'R' : 'O',
            xpath: `datepicker${propertyKey}${valueKey}`,
            text: additionalProps && additionalProps.text,
        }}
        keyboard={false}
        formFields={formFields}
        valueKey={valueKey}
        style={style}
        localeString={lang}
        value={property[valueKey] || defaultValue}
        updateProperty={(key, value) => updateFunction(key, value)}
        {...additionalProps}
    />
);

export default {
    getEAMInput,
    getEAMSelect,
    getEAMAutocomplete,
    getEAMCheckbox,
    getEAMDatePicker,
    getEAMDateTimePicker,
};
