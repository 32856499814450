import React from 'react';
import Grid from '@material-ui/core/Grid';
import MenuIcon from 'mdi-material-ui/Menu';
import BlockUi from 'react-block-ui';
import TRECButton from '../../components/buttons/TRECButton';
import TRECPage from '../TRECPage';
import { TRANSLATION_KEYS, RWP_STATUS } from './RWPConstants';
import { getInternalLink, generateRoute, ROUTES } from '../../../tools/Routes';
import { isCompleted } from './RWProcessingTools';
import EAMGridNoOverflow from '../../components/grid/EAMGridNoOverflow';
import gridNoOverflowClasses from '../../components/grid/EAMGridNoOverflow.module.css';

const CELL_TYPES = {
    CHECKBOX: 'selected',
    TREATMENT_ID: 'EVT_CODE',
    // OPERATOR: 'OPERATOR',
    // START_DATE: 'START_DATE',
    // END_DATE: 'END_DATE',
    USER_STATUS: 'EVT_STATUS',
    SYSTEM_STATUS: 'EVT_RSTATUS',
    INPUT_WASTE: 'INPUT_WASTE',
    OUTPUT_WASTE: 'OUTPUT_WASTE',
};

const HIDDEN_COLUMNS = {};

export default class RWProcessingSearch extends TRECPage {
    state = {
        selectedRows: [],
        dataspyId: 0, // TOOD
        gridSort: [],
        loading: false,
    };

    getRowAsAnObject = (row) =>
        row.cell.reduce(
            (acc, cell) => ({
                ...acc,
                [this.getCellType(cell)]: cell.value || '',
            }),
            {}
        );

    getCellType = (cell) => cell.t && cell.t.toUpperCase();

    cellRenderer = (cell, row) => {
        const { screenData } = this.props;

        const cellType = this.getCellType(cell);
        const rowObject = this.getRowAsAnObject(row);
        switch (cellType) {
            case CELL_TYPES.TREATMENT_ID: {
                const route =
                    isCompleted({
                        systemStatus: rowObject[CELL_TYPES.SYSTEM_STATUS],
                        userStatus: rowObject[CELL_TYPES.USER_STATUS],
                    }) || rowObject[CELL_TYPES.USER_STATUS] === RWP_STATUS.INCOMPLETE
                        ? ROUTES.rwProcessingRead
                        : ROUTES.rwProcessingEdit;
                return getInternalLink({
                    route: generateRoute(route, { jobID: cell.value }),
                    text: cell.value,
                    state: {
                        originRoute: generateRoute(ROUTES.rwProcessingSearch),
                    },
                });
            }
            case TRANSLATION_KEYS.STATUS_DESC:
                return (
                    screenData.lists.jobStatuses.find(
                        (codeDesc) => codeDesc.code === rowObject[CELL_TYPES.USER_STATUS]
                    ) || {}
                ).desc;
            default:
                return cell.value;
        }
    };

    rowStyler = () => {
        // const rowObject = this.getRowAsAnObject(row);
        let colorToApply;
        // TODO if needed
        return {
            ...colorToApply,
            maxHeight: '120px',
        };
    };

    handleMenu = () => {
        const { history } = this.props;
        history.push(ROUTES.menu);
    };

    handleFinishProcessing = () => {};

    handleMeasure = () => {};

    renderPage() {
        const { applicationData, getTranslation } = this.props;
        const { dataspyId, gridSort, loading } = this.state;
        const gridId = applicationData.rwprocessingGridID;
        const gridDataspy = applicationData.rwprocessingDefaultDataspyID || dataspyId;

        const extraColumns = [
            {
                width: '150px',
                t: TRANSLATION_KEYS.STATUS_DESC,
                headerLabel: getTranslation(TRANSLATION_KEYS.STATUS_DESC),
            },
        ];

        return (
            <BlockUi blocking={loading} className={gridNoOverflowClasses.outerBlock}>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <TRECButton onClick={this.handleMenu} color="default">
                            <MenuIcon />
                            MENU
                        </TRECButton>
                        {/* <TRECButton onClick={this.handleFinishProcessing}>
                            {getTranslation(TRANSLATION_KEYS.FINISH_PROCESSING)}
                        </TRECButton>
                        <TRECButton onClick={this.handleMeasure}>
                            {getTranslation(TRANSLATION_KEYS.MEASURE)}
                        </TRECButton> */}
                    </Grid>
                </Grid>
                <EAMGridNoOverflow
                    onRef={(grid) => {
                        this.grid = grid;
                    }}
                    gridSort={gridSort}
                    gridId={gridId}
                    cellRenderer={this.cellRenderer}
                    hiddenTags={Object.values(HIDDEN_COLUMNS)}
                    {...(gridDataspy ? { dataspyId: gridDataspy } : {})}
                    extraColumns={extraColumns}
                    rowStyler={this.rowStyler}
                    bypassAlternateRows
                    handleChangeDataSpy={(val) => this.setState({ dataspyId: val })}
                    heightFilterNotVisible="195px"
                    heightFilterVisible="240px"
                />
            </BlockUi>
        );
    }
}
