import { connect } from 'react-redux';
import { logout } from '../../../actions/applicationActions';
import { setPage, updateLayout } from '../../../actions/layoutActions';
import rpsmActions from '../../../actions/rpsmActions';
import { rpsmGettersWithState } from '../../../reducers/rpsmReducer';
import Tools from '../../../tools/Tools';
import RPSampling from './RPSampling';

const RPSM_TRANSLATION_KEY = 'TREC_RPSM';
const RPSM_MASTERPAGE = 'RPSM_CREATE';

function mapStateToProps(state) {
    const { applicationData } = state.application;
    const translations = state.application.screenData[state.layout.lang].translations[RPSM_TRANSLATION_KEY];
    const lang = Tools.getURLParameterByName('lang');
    return {
        page: RPSM_MASTERPAGE,
        masterPage: RPSM_MASTERPAGE,
        screenData: state.application.screenData[state.layout.lang],
        userData: state.application.userData,
        applicationData,
        translations,
        lang: state.layout.lang,
        getTranslation: (code) =>
            (lang === 'DEV' && code) ||
            (translations && translations[code.toUpperCase()]) ||
            (state.application.screenData.EN.translations[RPSM_TRANSLATION_KEY] &&
                state.application.screenData.EN.translations[RPSM_TRANSLATION_KEY][code.toUpperCase()]) ||
            code,
        bufferZone: state.application.bufferZone,
        menu: state.application.screenData[state.layout.lang].menu[state.application.menuType],
        rpsmGetters: rpsmGettersWithState(state),
        // rpamAnalysisTypes: applicationData.rpamanalysisTypes,
        rpasamplingStepSequence: applicationData.rpasamplingStepSequence,
    };
}

const RPSamplingContainer = connect(mapStateToProps, {
    setPage,
    updateLayout,
    logout,
    ...rpsmActions,
})(RPSampling);

export default RPSamplingContainer;
