import { logout } from 'actions/applicationActions';
import { setPage, updateLayout } from 'actions/layoutActions';
import vacCleanerActions from 'actions/vacCleanerActions';
import { parse as queryParse } from 'query-string';
import { openConfirmDialog } from 'react-eis-components/dist/actions/uiActions';
import { connect } from 'react-redux';
import { vacCleanerGettersWithState } from 'reducers/vacCleanerReducer';
import Tools from 'tools/Tools';
import { VACUUM_RENTAL_LINE_KEYS } from 'ui/pages/vaccleanerrequest/VacCleanerConstants';

const TRANSLATION_KEY = 'TREC_VAC_CLEANER';

const mapStateToProps = (state, ownProps) => {
    const { action, application, layout } = state;
    const { applicationData, bufferZone, constants, menuType, screenData, userData, rwpDropdowns, authorizedMenus } =
        application;
    const { lang } = layout;
    const translations = screenData[lang].translations[TRANSLATION_KEY];
    const urlParams = queryParse(ownProps.location.search, { mapStateToProps: 'comma' });
    const { VAC: vacuumRequest } = state;

    const { assetFields, customFields } = screenData[lang];
    const { customFieldsDef } = screenData[lang];

    const wrrDropdownsTranslated = Tools.applyToFields(rwpDropdowns, (list) =>
        list.map((codeDesc) => ({
            ...codeDesc,
            desc:
                Tools.getURLParameterByName('lang') === 'DEV'
                    ? codeDesc.code
                    : codeDesc.descTranslated?.[lang] || codeDesc.desc,
        }))
    );

    const getTranslation = (code) =>
        !code || Tools.getURLParameterByName('lang') === 'DEV'
            ? code
            : translations?.[code.toUpperCase()] ||
              screenData.EN.translations[TRANSLATION_KEY]?.[code.toUpperCase()] ||
              code;

    const getVacCount = (vaccumList, vacuum) =>
        vaccumList
            .filter((v) => v[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE] === vacuum[VACUUM_RENTAL_LINE_KEYS.VAC_TYPE])
            .filter((v) => v[VACUUM_RENTAL_LINE_KEYS.LOCATION]?.code === vacuum[VACUUM_RENTAL_LINE_KEYS.LOCATION]?.code)
            // .sort((a, b) => a[VACUUM_RENTAL_LINE_KEYS.CODE].localeCompare(b[VACUUM_RENTAL_LINE_KEYS.CODE]))
            .findIndex((v) => v[VACUUM_RENTAL_LINE_KEYS.CODE] === vacuum[VACUUM_RENTAL_LINE_KEYS.CODE]) + 1;

    return {
        screenData: screenData[lang],
        assetFields,
        userData,
        applicationData,
        bufferZone,
        bufferZones: state.application.applicationData.bufferZones,
        menuType,
        constants,
        customFields,
        customFieldsDef,
        lang,
        translations,
        masterPage: ['VAC_CLEANER'],
        page: 'VAC_CLEANER',
        menu: screenData[lang].menu[menuType],
        action,
        urlParams,
        getTranslation,
        vacCleanerGetters: vacCleanerGettersWithState(state),
        dropdowns: wrrDropdownsTranslated,
        authorizedMenus,
        vacuumRequest,
        getVacCount,
    };
};

const Containerize = (Component) =>
    connect(
        mapStateToProps,
        {
            updateLayout,
            setPage,
            logout,
            openConfirmDialog,
            ...vacCleanerActions,
        },
        (stateProps, dispatchProps, ownProps) => ({
            ...ownProps,
            ...stateProps,
            storeActions: dispatchProps,
            setPage: dispatchProps.setPage,
            openConfirmDialog: dispatchProps.openConfirmDialog,
        })
    )(Component);

export default Containerize;
