import { Grid } from '@material-ui/core';
import { WizardStep } from 'amm-tools';
import EAMCheckbox from 'eam-components/dist/ui/components/inputs/EAMCheckbox';
import { TABLE_DATA_TYPES } from 'enums/Constants';
import React from 'react';
import EISPanel from 'react-eis-components/dist/ui/components/panel';
import EISTable from 'react-eis-components/dist/ui/components/table';
import WSAutocomplete from 'tools/rest/WSAutocomplete';
import Tools from 'tools/Tools';
import InputGenerator from '../InputGenerator';
import {
    EQUIPMENT_KEYS,
    EQUIPMENT_RPM_KEYS,
    REQUEST_KEYS,
    REQUEST_SOURCES,
    RPMR_TRANSLATION_KEYS,
} from '../RPMRConstants';
import { showError, showWarning } from 'tools/TrecNotifications';

const { ATTRIBUTES } = InputGenerator;

const labelStyle = {
    width: 170,
    minWidth: 170,
};

const LOCATION_OUT_OF_SERVICE_MARK = '+';

const saveOutOfService = (code, selectedObject, setLocationInService, inputProps) =>
    setLocationInService(inputProps.object[EQUIPMENT_KEYS.CODE], selectedObject);

const getEquipmentFields = ({
    isMultipleEquipment,
    applicationData,
    assetFields,
    equipmentMap,
    fetchedEquipmentMap,
    isAllowMultipleResp,
    getTranslation,
    isVacuumAction,
    isOtherRwc,
    isVacuumCleanerEquipment,
    requestSource,
    setLocationOutOfService,
    validateLocation,
}) => [
    {
        code: EQUIPMENT_KEYS.CODE,
        type: TABLE_DATA_TYPES.STATIC,
        elementInfo: assetFields.equipmentno,
        getAttribute: () => (isMultipleEquipment ? ATTRIBUTES.READONLY_TEXT : ATTRIBUTES.HIDDEN),
    },
    {
        code: EQUIPMENT_KEYS.LOCATION,
        type: TABLE_DATA_TYPES.LOOKUP_AUTOCOMPLETE,
        keysMap: (field) => ({
            code: 'obj_code',
            mapCodeTo: field.code,
            desc: 'obj_desc',
            mapDescTo: field.descKey,
        }),
        elementInfo: assetFields.location,
        gridId: applicationData.locationRaisinGridID,
        getAttribute: (eqp) =>
            isAllowMultipleResp && (!eqp[EQUIPMENT_KEYS.ATTACHED_TO] || eqp[EQUIPMENT_KEYS.DETACH])
                ? ATTRIBUTES.REQUIRED
                : isMultipleEquipment
                ? ATTRIBUTES.READONLY_TEXT
                : ATTRIBUTES.HIDDEN,
        autocompleteType: WSAutocomplete.autocompleteTRECLocation,
        customInputProps: (inputProps) => ({
            autoComplete: {
                onChangeValue: (code, selectedObject) =>
                    saveOutOfService(code, selectedObject, setLocationOutOfService, inputProps),
            },
        }),
        validate: (eqp) => () =>
            equipmentMap[eqp[EQUIPMENT_KEYS.CODE]][EQUIPMENT_KEYS.LOCATION] && validateLocation(eqp),
    },
    {
        code: EQUIPMENT_KEYS.LAST_LOCATION_CODE,
        type: TABLE_DATA_TYPES.LOOKUP_AUTOCOMPLETE,
        keysMap: (field) => ({
            code: 'obj_code',
            mapCodeTo: field.code,
            desc: 'obj_desc',
            mapDescTo: field.descKey,
        }),
        descKey: EQUIPMENT_KEYS.LAST_LOCATION_DESC,
        elementInfo: assetFields.lastLocation,
        gridId: applicationData.locationRaisinGridID,
        getAttribute: (eqp) =>
            fetchedEquipmentMap[eqp[EQUIPMENT_KEYS.CODE]][EQUIPMENT_KEYS.LAST_LOCATION_CODE]
                ? ATTRIBUTES.READONLY
                : ATTRIBUTES.OPTIONAL,
        autocompleteType: WSAutocomplete.autocompleteTRECLocation,
    },
    // {
    //     code: EQUIPMENT_KEYS.FACILITY,
    //     type: TABLE_DATA_TYPES.SELECT,
    //     //descKey: EQUIPMENT_KEYS.LAST_LOCATION_DESC,
    //     elementInfo: {
    //         text: 'Facility',
    //         attribute: 'R',
    //     },
    //     values: applicationData.facilities,
    //     //autocompleteType: WSAutocomplete.autocompleteTRECLocation,
    // },
    {
        code: EQUIPMENT_KEYS.FACILITIES,
        type: TABLE_DATA_TYPES.SELECT,
        elementInfo: {
            text: 'Facilities',
        },
        customInputProps: () => ({
            multi: true,
        }),
        getAttribute: (eqp) =>
            isVacuumCleanerEquipment(eqp) || isVacuumAction
                ? ATTRIBUTES.HIDDEN
                : isOtherRwc
                ? ATTRIBUTES.OPTIONAL
                : requestSource === REQUEST_SOURCES.EMPTY_CONTAINER
                ? ATTRIBUTES.HIDDEN
                : ATTRIBUTES.REQUIRED,
        values: applicationData.facilities,
    },
    {
        code: EQUIPMENT_KEYS.DETACH,
        type: TABLE_DATA_TYPES.CHECKBOX,
        // descKey: EQUIPMENT_KEYS.LAST_LOCATION_DESC,
        elementInfo: {
            text: 'Detach',
        },
        values: applicationData.facilities,
        getAttribute: (eqp) =>
            eqp[EQUIPMENT_KEYS.ATTACHED_TO] && (!isMultipleEquipment || isAllowMultipleResp)
                ? ATTRIBUTES.OPTIONAL
                : ATTRIBUTES.HIDDEN,
    },
    {
        code: EQUIPMENT_KEYS.ATTACHED_TO,
        type: TABLE_DATA_TYPES.INPUT,
        elementInfo: {
            text: getTranslation(EQUIPMENT_KEYS.ATTACHED_TO),
        },
        getAttribute: (eqp) => (eqp[EQUIPMENT_KEYS.ATTACHED_TO] ? ATTRIBUTES.READONLY : ATTRIBUTES.HIDDEN),
    },
];

class RPMRLocationStep extends WizardStep {
    formFields = [];

    state = {
        areaClassification: {},
        locationsOutOfService: {},
    };

    setLocationOutOfService = (equipmentCode, value) => {
        this.setState((prevState) => ({
            ...prevState,
            locationsOutOfService: {
                ...prevState.locationsOutOfService,
                [equipmentCode]: value?.extraAttr3 === LOCATION_OUT_OF_SERVICE_MARK,
            },
        }));
    };

    UNSAFE_componentWillMount() {
        const { bufferZone, storeActions, rpmrGetters, translations } = this.props;
        storeActions.setPage('TREC_REQRPMEAS', 'STEP_LOCATION');
        const properties = rpmrGetters.getProperties();
        if (typeof properties[REQUEST_KEYS.DETACH_EQUIPMENT] !== 'boolean') {
            storeActions.updateRPMRequest({ [REQUEST_KEYS.DETACH_EQUIPMENT]: true });
        }
        const equipmentMap = rpmrGetters.getEquipmentMap();
        const equipmentsWithLocation = Object.values(equipmentMap).filter(
            (equipment) => equipment[EQUIPMENT_KEYS.LOCATION]
        );
        const [equipmentWithLocation] = equipmentsWithLocation;
        equipmentsWithLocation.forEach((equipment) => {
            WSAutocomplete.autocompleteTRECLocation(equipment[EQUIPMENT_KEYS.LOCATION]).then((response) => {
                const [value] = response.body.data;
                this.setLocationOutOfService(equipment[EQUIPMENT_KEYS.CODE], value);
            });
        });

        if (properties[REQUEST_KEYS.CURRENT_LOCATION_CODE]) return;

        const currentLocationCode = bufferZone
            ? bufferZone.locationCode
            : Object.values(equipmentMap).every(
                  (equipment) =>
                      equipment[EQUIPMENT_KEYS.LOCATION] === (equipmentWithLocation || {})[EQUIPMENT_KEYS.LOCATION]
              )
            ? equipmentWithLocation[EQUIPMENT_KEYS.LOCATION]
            : null;
        if (!rpmrGetters.isAllowMultipleResp() && !currentLocationCode && equipmentWithLocation) {
            showWarning([translations.EQPINVALID, translations.EQPSAMELOC].filter(Boolean).join(' '));
        }

        storeActions.updateRPMRequest({ [REQUEST_KEYS.CURRENT_LOCATION_CODE]: currentLocationCode });
        const eqMap = rpmrGetters.getEquipmentMap();

        if (bufferZone) {
            storeActions.updateRPMREquipmentMap(
                Tools.applyToFields(eqMap, (eqp) => ({
                    ...eqp,
                    [EQUIPMENT_KEYS.DETACH]: true,
                    [EQUIPMENT_KEYS.LOCATION]: bufferZone.locationCode,
                }))
            );
        }
    }

    canContinue = () => {
        const { rpmrGetters, translations } = this.props;

        const isValid = Object.values(this.formFields).reduce(
            (acc, field) => (!field || !field.validate || field.validate()) && acc,
            true
        );

        if (!isValid) {
            showError(translations.LOCDETINV);
        } else if (this.isAreaNotClassified() && this.hasEquipmentRadioActive()) {
            showWarning(
                translations.NONRADAREAERR.replace(
                    '{0}',
                    rpmrGetters.getProperties()[REQUEST_KEYS.FUTURE_LOCATION_CODE]
                ),
                '',
                null
            );
        }
        return isValid;
    };

    onStepChange = () => {
        this.props.storeActions.setApplyAllLines(false);
    };

    saveChanges = () => true;

    commitChanges = (callback) => callback();

    isUpdateLocation = () => {
        const { rpmrGetters, constants } = this.props;
        const { futureActionUpdateloc, futureActionVacuumDeclare } = constants;
        const action = rpmrGetters.getProperties()[REQUEST_KEYS.ACTION];
        return [futureActionUpdateloc, futureActionVacuumDeclare].includes(action);
    };

    isAreaNotClassified = () => {
        const { areaClassification } = this.state;
        return areaClassification && areaClassification.code <= 0;
    };

    hasEquipmentRadioActive = () => {
        const { rpmrGetters, constants } = this.props;
        const equipmentMap = rpmrGetters.getEquipmentMap();
        return Object.values(equipmentMap).some(
            (equipment) => equipment[EQUIPMENT_RPM_KEYS.RP_CLASSIFICATION] === constants.radioactive
        );
    };

    hasEquipmentAttached = () => {
        const { rpmrGetters } = this.props;
        const equipmentMap = rpmrGetters.getEquipmentMap();
        return Object.values(equipmentMap).some((eq) => eq[EQUIPMENT_KEYS.ATTACHED_TO]);
    };

    canDetach = () => {
        const { bufferZone } = this.props;
        return !bufferZone && this.hasEquipmentAttached();
    };

    handleFutureLocationChange = (code, selectedObject) => {
        if (!selectedObject) {
            this.setState(() => ({ areaClassification: {} }));
        } else if (selectedObject.extraAttr1) {
            this.setState({
                areaClassification: {
                    code: selectedObject.extraAttr2,
                    desc: selectedObject.extraAttr1,
                },
            });
        }
    };

    renderFutureLocation = () => {
        const { applicationData, screenData, rpmrGetters, storeActions } = this.props;
        return InputGenerator.generate({
            field: {
                code: REQUEST_KEYS.FUTURE_LOCATION_CODE,
                type: TABLE_DATA_TYPES.LOOKUP_AUTOCOMPLETE,
                keysMap: (field) => ({
                    code: 'obj_code',
                    mapCodeTo: field.code,
                    desc: 'obj_desc',
                    mapDescTo: field.descKey,
                }),
                descKey: REQUEST_KEYS.FUTURE_LOCATION_DESC,
                elementInfo: screenData.assetFields.futureLocation,
                autocompleteType: WSAutocomplete.autocompleteTRECLocation,
                getAttribute: () => ATTRIBUTES.REQUIRED,
                gridId: applicationData.locationRaisinGridID,
                customInputProps: () => ({
                    autoComplete: {
                        onChangeValue: this.handleFutureLocationChange,
                    },
                }),
            },
            updateObject: (key, val) => {
                const eqMap = rpmrGetters.getEquipmentMap();
                if (key === REQUEST_KEYS.FUTURE_LOCATION_CODE) {
                    storeActions.updateRPMREquipmentMap(
                        Tools.applyToFields(eqMap, (eqp) => ({ ...eqp, [EQUIPMENT_KEYS.LOCATION]: val }))
                    );
                }
                storeActions.updateRPMRequest({ [key]: val });
            },
            object: rpmrGetters.getProperties(),
            formFields: this.formFields,
        });
    };

    renderDetachMessage = () => {
        const { bufferZone, translations } = this.props;
        const message = bufferZone ? translations.EQPWILLDETAC : translations.PLEASESELDET;
        return this.hasEquipmentAttached() ? <p>{message}</p> : null;
    };

    renderDetachAllEquipment = () => {
        const { customFields, constants, rpmrGetters, storeActions } = this.props;
        const equipmentMap = rpmrGetters.getEquipmentMap();
        const equipmentList = rpmrGetters.getEquipmentList();
        const request = rpmrGetters.getProperties();
        return !rpmrGetters.isAllowMultipleResp() && this.canDetach() ? (
            <>
                <EAMCheckbox
                    elementInfo={{
                        ...customFields[constants.cfpEqpDetached],
                        attribute: 'R',
                    }}
                    // Only need value of the first one, since detach update is for all equipment
                    value={request[REQUEST_KEYS.DETACH_EQUIPMENT]} //
                    valueKey={EQUIPMENT_KEYS.DETACH}
                    updateProperty={(key, value) => {
                        // Remove one of the following 2 lines once we decide where this info lives
                        storeActions.updateRPMRequest({ [REQUEST_KEYS.DETACH_EQUIPMENT]: value });
                        storeActions.updateRPMREquipment(equipmentList, { [key]: value });
                    }}
                    labelStyle={labelStyle}
                    trueValue
                    falseValue={false}
                />
                {request[REQUEST_KEYS.DETACH_EQUIPMENT] ? (
                    <span style={{ color: 'grey', fontSize: '12px' }}>
                        {Object.values(equipmentMap)
                            .filter((eq) => eq[EQUIPMENT_KEYS.ATTACHED_TO])
                            .map(
                                (eq) =>
                                    `${eq[EQUIPMENT_KEYS.CODE]} will be detached from ${eq[EQUIPMENT_KEYS.ATTACHED_TO]}`
                            )
                            .join(', ')}
                    </span>
                ) : null}
            </>
        ) : null;
    };

    renderAreaClassification = () => {
        const { areaClassification } = this.state;
        return areaClassification.code ? <p>{areaClassification.desc}</p> : null;
    };

    renderCurrentLocation = () => {
        const { applicationData, screenData, bufferZone, rpmrGetters, storeActions } = this.props;
        const properties = rpmrGetters.getProperties();
        const isReadOnly = this.isUpdateLocation() || (!bufferZone && !properties[REQUEST_KEYS.DETACH_EQUIPMENT]);
        return (
            !rpmrGetters.isAllowMultipleResp() &&
            InputGenerator.generate({
                field: {
                    code: REQUEST_KEYS.CURRENT_LOCATION_CODE,
                    type: TABLE_DATA_TYPES.LOOKUP_AUTOCOMPLETE,
                    keysMap: (field) => ({
                        code: 'obj_code',
                        mapCodeTo: field.code,
                        desc: 'obj_desc',
                        mapDescTo: field.descKey,
                    }),
                    descKey: REQUEST_KEYS.CURRENT_LOCATION_DESC,
                    elementInfo: screenData.assetFields.location,
                    autocompleteType: WSAutocomplete.autocompleteTRECLocation,
                    getAttribute: () => (isReadOnly ? ATTRIBUTES.READONLY : ATTRIBUTES.REQUIRED),
                    gridId: applicationData.locationRaisinGridID,
                },
                updateObject: (key, value) => storeActions.updateRPMRequest({ [key]: value }),
                object: rpmrGetters.getProperties(),
                formFields: this.formFields,
            })
        );
    };

    renderGeneralFields = () => {
        const { rpmrGetters } = this.props;

        const multipleEquipment = rpmrGetters.isMultipleEquipment();

        return (
            <>
                {this.isUpdateLocation() ? (
                    <>
                        {multipleEquipment && this.renderFutureLocation()}
                        {this.renderAreaClassification()}
                    </>
                ) : (
                    <>
                        {this.renderDetachMessage()}
                        {this.renderDetachAllEquipment()}
                    </>
                )}
                <br />
                {this.renderCurrentLocation()}
            </>
        );
    };

    renderEquipmentFields = () => {
        const {
            applicationData,
            screenData,
            constants,
            rpmrGetters,
            storeActions,
            translations,
            getTranslation,
            isVacuumAction,
            isOtherRwc,
            isVacuumCleanerEquipment,
            requestSource,
        } = this.props;
        const equipmentList = rpmrGetters.getEquipmentList();
        const equipmentMap = rpmrGetters.getEquipmentMap();
        const isMultipleEquipment = rpmrGetters.isMultipleEquipment();
        const fetchedEquipmentMap = rpmrGetters.getFetchedEquipmentMap();
        const isAllowMultipleResp = rpmrGetters.isAllowMultipleResp();
        const validateLocation = (equipment) => !this.state.locationsOutOfService[equipment[EQUIPMENT_KEYS.CODE]];

        const equipmentFields = getEquipmentFields({
            isMultipleEquipment,
            applicationData,
            constants,
            assetFields: screenData.assetFields,
            equipmentMap,
            fetchedEquipmentMap,
            isAllowMultipleResp,
            getTranslation,
            isOtherRwc,
            isVacuumAction,
            isVacuumCleanerEquipment,
            requestSource,
            setLocationOutOfService: this.setLocationOutOfService,
            validateLocation,
        });

        const updateEquipment = (eqCode) => (key, value) => {
            const eqList = [eqCode];
            if (rpmrGetters.isApplyAllLines()) {
                equipmentList.forEach((code) => {
                    const eq = equipmentMap[code];
                    const field = equipmentFields.filter((equipmentField) => equipmentField.code === key);
                    if (
                        eq[EQUIPMENT_KEYS.CODE] !== eqCode &&
                        field.length &&
                        (!field[0].getAttribute ||
                            [ATTRIBUTES.OPTIONAL, ATTRIBUTES.REQUIRED].includes(field[0].getAttribute(eq)))
                    ) {
                        eqList.push(eq[EQUIPMENT_KEYS.CODE]);
                    }
                });
            }
            storeActions.updateRPMREquipment(eqList, { [key]: value });
        };

        const equipmentRenderFields = equipmentList.map((eqCode) =>
            equipmentFields.reduce(
                (acc, field) => ({
                    ...acc,
                    [field.code]: (
                        <div key={field.code}>
                            {InputGenerator.generate({
                                field: {
                                    ...field,
                                    validate: field.validate?.(equipmentMap[eqCode]),
                                    elementInfo: {
                                        ...(field.elementInfo || {}),
                                        xpath: field.elementInfo ? `${field.elementInfo.xpath}_${eqCode}` : null,
                                    },
                                },
                                object: equipmentMap[eqCode],
                                updateObject: updateEquipment(eqCode),
                                formFields: this.formFields,
                                hideLabel: isMultipleEquipment,
                            })}
                        </div>
                    ),
                }),
                {}
            )
        );

        return isMultipleEquipment ? (
            <>
                <EAMCheckbox
                    label={translations.EDITALL}
                    value={rpmrGetters.isApplyAllLines()}
                    updateProperty={(key, value) => storeActions.setApplyAllLines(value)}
                    trueValue
                    falseValue={false}
                />
                <EISTable
                    data={equipmentRenderFields}
                    headers={equipmentFields.map((field) => field.elementInfo.text)}
                    propCodes={equipmentFields.map((field) => field.code)}
                    maxMobileSize={600}
                />
            </>
        ) : (
            Object.values(equipmentRenderFields[0])
        );
    };

    render() {
        const { getTranslation } = this.props;
        const { LOCATION_HEADER } = RPMR_TRANSLATION_KEYS;
        return (
            <div style={{ margin: 8 }}>
                <Grid container spacing={8}>
                    <Grid item md={12} sm={12} xs={12}>
                        <EISPanel heading={getTranslation(LOCATION_HEADER)} alwaysExpanded>
                            <div style={{ width: '100%', marginTop: 0 }}>
                                {this.renderGeneralFields()}
                                {this.renderEquipmentFields()}
                            </div>
                        </EISPanel>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default RPMRLocationStep;
